import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class NeHeFISettings extends BaseFilterItemSettings {
  isNEFilter: boolean;
  fieldName: string;
  selectedIds: string[] | number[];

  constructor(init?: Readonly<NeHeFISettings>) {
    super(init);
    Object.assign(this, init);
  }
}
