import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { IALCEligibilityChangeDto } from 'src/app/common-modules/dependencies/alc/eligibility-change.data';
import { IReasonsForEligibleChangeDto } from 'src/app/common-modules/dependencies/alc/eligibility-reasons.dto';
import { ALCPrioritisationView } from 'src/app/common-modules/dependencies/alc/prioritisation-view.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IPrioritisationEligibilityData } from '../../../dependencies/alc/prioritisation-eligibility-data';

import { EligibilityService } from './eligibility.service';

const COMPONENT_SELECTOR = 'wlm-eligibility-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './eligibility-popup.component.html',
  styleUrls: ['./eligibility-popup.component.scss'],
})
export class EligibilityPopupComponent implements OnInit {
  form: UntypedFormGroup;
  selectedPrioritisations: ALCPrioritisationView[];
  referenceDate: Date;

  get selectedHierarchyElementIds(): string[] {
    return this.selectedPrioritisations.map((m) => m.hierarchyElementId);
  }

  eligibilityReasons: IReasonsForEligibleChangeDto[];
  filteredEligibilityReasons: IReasonsForEligibleChangeDto[];
  changeElegibilityOptions: { value: string; name: string }[] = [];
  isSaving = false;
  naEligibilityValue = 'null';

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EligibilityPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { prioritisations, user, referenceDate }: IPrioritisationEligibilityData,
    private eligibilityService: EligibilityService,
    public dialogService: DialogService,
    private cacheService: HttpCacheService,
    private _authenticationService: AuthenticationService,
    private sharedConstantsService: SharedConstantsService
  ) {
    this.selectedPrioritisations = prioritisations;
    this.referenceDate = referenceDate;

    this.createForm();
  }

  ngOnInit(): void {
    this.form.get('commentControl').reset();

    const reasons$ = this.eligibilityService.getAll();
    const options$ = this.sharedConstantsService.getEligibleUserMapping();

    combineLatest([reasons$, options$]).subscribe({
      next: ([reasons, options]) => {
        this.eligibilityReasons = reasons;

        this.changeElegibilityOptions = Array.from(options.keys()).map((key) => ({
          value: key,
          name: options.get(key),
        }));

        this.initializeFormValues();
      },
    });
  }

  initializeFormValues() {
    if (this.selectedPrioritisations.length === 1) {
      const { isEligibleByUser, eligibleDetectionReasonForChange, eligibleDetectionComment } =
        this.selectedPrioritisations[0];

      this.form
        .get('changeEligibilityControl')
        .setValue(isEligibleByUser ?? this.naEligibilityValue);
      this.form.get('reasonControl').setValue(eligibleDetectionReasonForChange ?? null);
      this.form.get('commentControl').setValue(eligibleDetectionComment);
    }
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;
      const eligibilityChangeData: IALCEligibilityChangeDto = {
        prioritisations: this.selectedPrioritisations,
        isEligibleByUser:
          this.form.controls.changeEligibilityControl.value === this.naEligibilityValue
            ? null
            : (this.form.controls.changeEligibilityControl.value as boolean),
        reasonId: this.form.controls.reasonControl.value as number,
        comment: this.form.controls.commentControl.value,
        userCode: this._authenticationService.userCode,
        referenceDate: this.referenceDate
      };
      this.eligibilityService.save(eligibilityChangeData).subscribe({
        next: (value) => {
          if (value.hasErrors) {
            this.isSaving = false;
            this.dialogService.showErrorMessage({ message: value.errorMessage } as Error);
          } else {
            const prioritisation$ = this.cacheService.clearContainsInUrl('alc/prioritisation');
            const ineligibles$ = this.cacheService.clearContainsInUrl(
              'alc/eligibility/ineligibles'
            );

            Promise.all([prioritisation$, ineligibles$]).then(() => {
              this.isSaving = false;
              this.dialogService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.success-message` })
              );
              this.dialogRef.close(true);
            });
          }
        },
      });
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  private createForm() {
    this.form = this.fb.group({
      changeEligibilityControl: ['', [Validators.required]],
      reasonControl: [{ value: '', disabled: true }, [Validators.required]],
      commentControl: [''],
    });

    this.form
      .get('changeEligibilityControl')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((eligibilitySelected) => this.onElegilibilityControlChange(eligibilitySelected));
  }

  private onElegilibilityControlChange(eligibilitySelected: any) {
    if (eligibilitySelected === this.naEligibilityValue) {
      this.form.get('reasonControl').reset();
      this.form.get('commentControl').reset();
      this.form.get('reasonControl').disable();
      this.form.get('commentControl').disable();
    } else {
      this.form.get('reasonControl').enable();
      this.form.get('commentControl').enable();
      const reasonFilter = eligibilitySelected === true;
      this.filteredEligibilityReasons = this.eligibilityReasons.filter(
        (f) => f.isEligible !== !reasonFilter && f.isEligibleBySystem !== true
      );
      this.form.get('reasonControl').reset();
    }
  }
}
