import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { CommonSharedComponentModule } from 'src/app/common-modules/shared-component/common-shared-component.module';
import { CommonSharedModule } from 'src/app/common-modules/shared/common-shared.module';
import { MaterialSharedModule } from 'src/app/common-modules/shared/material-shared.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { DataValidationPageComponent } from './data-validation-page/data-validation-page.component';
import { DataValidationRoutingModule } from './data-validation-routing.module';
import { DataValidationStoreService } from './state/data-validation-store.service';
import { DataValidationFilterWidgetComponent } from './widgets/data-validation-filter-widget/data-validation-filter-widget.component';
import { DataValidationChartWidgetComponent } from './widgets/data-validation-chart-widget/data-validation-chart-widget.component';
import { DataValidationPointsSelectionWidgetComponent } from './widgets/data-validation-points-selection-widget/data-validation-points-selection-widget.component';
import { DataValidationFilterComponent } from './data-validation-filter/data-validation-filter.component';
import { DataValidationPointsSelectionComponent } from './data-validation-points-selection/data-validation-points-selection.component';
import { GenericStoreService } from 'src/app/common-modules/dynamic-layout/state/generic/generic-store.service';
import { DataValidationChartComponent } from './data-validation-chart/data-validation-chart.component';
import { DataValidationValueRangesComponent } from './data-validation-value-ranges/data-validation-value-ranges.component';
import { DataValidationChartSelectionWidgetComponent } from './widgets/data-validation-chart-selection-widget/data-validation-chart-selection-widget.component';
import { DataValidationChartService } from './services/data-validation-chart.service';
import { DataValidationEstimationService } from './services/data-validation-estimation.service';
import { DataValidationDateRangesComponent } from './data-validation-date-ranges/data-validation-date-ranges.component';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';

const fieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    DataValidationPageComponent,
    DataValidationFilterWidgetComponent,
    DataValidationChartWidgetComponent,
    DataValidationPointsSelectionWidgetComponent,
    DataValidationChartSelectionWidgetComponent,
    DataValidationFilterComponent,
    DataValidationPointsSelectionComponent,
    DataValidationChartComponent,
    DataValidationValueRangesComponent,
    DataValidationDateRangesComponent,
  ],
  imports: [
    DataValidationRoutingModule,
    CommonModule,
    CommonSharedModule,
    CommonSharedComponentModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService, DataValidationStoreService],
    }),
    MaterialSharedModule,
    TranslateModule,
    WlmFiltersModule,
    WlmChartsModule,
  ],
  exports: [],
  providers: [
    DataValidationChartService,
    { provide: 'DataValidationChartService', useExisting: DataValidationChartService },
    DataValidationEstimationService,
    { provide: 'DataValidationEstimationService', useExisting: DataValidationEstimationService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldAppearance,
    },
  ],
})
export class DataValidationModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'DataValidationFilterWidgetComponent',
        widgetClass: DataValidationFilterWidgetComponent,
      },
      {
        name: 'DataValidationPointsSelectionWidgetComponent',
        widgetClass: DataValidationPointsSelectionWidgetComponent,
      },
      {
        name: 'DataValidationChartWidgetComponent',
        widgetClass: DataValidationChartWidgetComponent,
      },
      {
        name: 'DataValidationChartSelectionWidgetComponent',
        widgetClass: DataValidationChartSelectionWidgetComponent,
      },
    ]);
  }
}
