import { GenericSelector } from 'src/app/common-modules/redux/models/generic-selector';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { WidgetManagerSelectorTypes } from './widget-manager.selector-types';

export abstract class WidgetManagerSelector extends GenericSelector {
  area = StateAreas.WidgetManager;
}

export class SelectedWidgetDefinitionSelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.SelectedWidgetDefinition;
}

export class CurrentLayoutSelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.CurrentLayout;
}

export class LayoutToLoadSelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.LayoutToLoad;
}

export class LayoutKeysSelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.LayoutKeys;
}

export class CurrentLayoutIdentitySelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.CurrentLayoutIdentity;
}

export class ResetLayoutSelector extends WidgetManagerSelector {
  type = WidgetManagerSelectorTypes.ResetLayout;
}
