import { Color } from '@progress/kendo-drawing';
import { IAxis } from './axis';
import { AxisLocation } from './axis-location';
import { Type } from 'class-transformer';

export interface IHorizontalAxis extends IAxis {
  minimum: Date;
  maximum: Date;
}

export class HorizontalAxis implements IHorizontalAxis {
  id: number;
  @Type(() => Date)
  minimum: Date;
  @Type(() => Date)
  maximum: Date;
  uom: string;
  location: AxisLocation;
  dimensionTypeId: number;
  labelFormat: string;
  labelColor: Color;

  constructor(init: Partial<HorizontalAxis>) {
    Object.assign(this, init);
  }

  getName(): string {
    return this.uom;
  }
}
