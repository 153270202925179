import { Component, Inject, Injector } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';

const COMPONENT_SELECTOR = 'wlm-profile-subscription';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './profile-subscription.component.html',
  styleUrls: ['./profile-subscription.component.scss'],
})
export class ProfileSubscriptionComponent extends BaseWidgetComponent {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  profileId: string;
  loading = false;

  get componentName(): string {
    return 'ProfileSubscriptionComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.profileId = parameters.parameters.get(TabDetailParameterName.profileConfiguration);
  }

  onLoading(loading) {
    this.loading = loading;
  }
}
