import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { CURRENT_ENVIRONMENT } from '../../shared/core/injection-tokens/current-environment.token';
import {
  CT_HTTP_CACHE,
  CT_HTTP_CACHE_ABSOLUTE_EXPIRATION,
  CT_HTTP_CACHE_DURATION,
  CT_HTTP_CACHE_RELOAD,
} from '../../shared/interceptors/interceptor-context-tokens';
import { IHttpCacheResponse } from './http-cache-response';
import { HttpCacheService } from './http-cache.service';

export const CACHE_INSTANCE_HEADER = 'cache-instance';

@Injectable({ providedIn: 'root' })
export class HttpCacheInterceptor implements HttpInterceptor {
  cacheDurationDefault = 60;
  private _instanceId: string;
  private _environment;

  constructor(
    private httpCacheService: HttpCacheService,
    @Inject(CURRENT_ENVIRONMENT) environment
  ) {
    this._environment = environment;
  }

  // Create observable to get the data from the embedded database
  private getCachedResponse(url: string): Observable<IHttpCacheResponse> {
    const cachedResponsePromise: Promise<IHttpCacheResponse> = this.httpCacheService.getById(url);
    return from(cachedResponsePromise);
  }

  getAbsoluteExpiration(req: HttpRequest<any>): Date | null {
    const absoluteExpiration = req.context.get(CT_HTTP_CACHE_ABSOLUTE_EXPIRATION);
    return absoluteExpiration ? dayjs(absoluteExpiration).toDate() : null;
  }

  getKey(req: HttpRequest<any>): string {
    if (!req.body) {
      return req.urlWithParams;
    }

    return `${req.urlWithParams}/${JSON.stringify(req.body)}`;
  }

  /**
   * Only for when the interceptor is provided at a non-global scope.
   */
  setInstanceId(instanceId: string): void {
    this._instanceId = instanceId;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastCachedResponse: IHttpCacheResponse = null;

    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const hasHttpCache = req.context.get(CT_HTTP_CACHE);
    if (!hasHttpCache) {
      return next.handle(req);
    }

    const cacheDuration = Number(
      req.context.get(CT_HTTP_CACHE_DURATION) ?? this.cacheDurationDefault.toString()
    );

    const cacheAbsoluteExpiration = this.getAbsoluteExpiration(req);
    const cachekey = this.getKey(req);
    const reload: boolean = req.context.get(CT_HTTP_CACHE_RELOAD);

    // This is purely informative now.
    if (this._instanceId) {
      req = req.clone({
        setHeaders: {
          [CACHE_INSTANCE_HEADER]: this._instanceId,
        },
      });
    }

    return this.getCachedResponse(cachekey).pipe(
      mergeMap((cachedResponse: IHttpCacheResponse) => {
        if (cachedResponse) {
          lastCachedResponse = cachedResponse;
          const currentDate = dayjs();
          const expirationDate = dayjs(cachedResponse.expirationDate);
          const absoluteExpiration = dayjs(cacheAbsoluteExpiration);

          if (
            (cacheAbsoluteExpiration && absoluteExpiration > currentDate) ||
            expirationDate > currentDate
          ) {
            const response = new HttpResponse({
              status: 200,
              body: cachedResponse.body,
            });

            return of(response);
          }
        }

        // Execute the request
        return next.handle(req).pipe(
          // Save the response in cache
          tap((event) => {
            if (event instanceof HttpResponse) {
              const body = event.body;

              const r: IHttpCacheResponse = {
                url: cachekey,
                body,
                lastModified: event.headers.get('last-modified'),
                creationDate: dayjs().toDate(),
                expirationDate: dayjs().add(cacheDuration, 'minute').toDate(),
                reload,
                instanceId: this._instanceId,
              };

              // Save everything in cache
              this.httpCacheService.put(r);
            }
          }),
          // If any error occurs and a response in cache is available, return it.
          catchError((err, caught) => {
            if (err instanceof HttpErrorResponse && lastCachedResponse) {
              const response: HttpResponse<any> = new HttpResponse({
                status: 200,
                body: lastCachedResponse.body,
              });
              return of(response);
            }

            return throwError(err);
          })
        );
      })
    );
  }
}
