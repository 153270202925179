import { GenericSelector } from '../../redux/models/generic-selector';
import { StateAreas } from '../../redux/models/state-areas';
import { UomSelectorTypes } from './uom.selector-types';

export abstract class UomSelector extends GenericSelector {
  area = StateAreas.UoM;
}

export class SelectedDimensionSelector extends UomSelector {
  type = UomSelectorTypes.SelectedDimension;
}
