import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, finalize, startWith, switchMap } from 'rxjs';
import { DialogService } from '../../shared/dialogs/dialogs.service';
import { IntegrableForm, IntegrableFormParams } from '../../shared/forms/integrable-form';
import { WlmDialogSettings } from '../../shared/model/dialog/wlm-dialog-setting';
import { DynamicRenderizerComponentService } from '../../shared/services/dynamic-renderizer-component.service';
import { SpinnerService } from '../../wlm-spinner/spinner.service';
import { GenericCrudPopupSettings, GenericCrudSettings } from '../generic-crud-settings';
import { GenericCrudService } from '../generic-crud.service';
import { GenericCrudPopupContentHostDirective } from './generic-crud-popup-content.directive';

@Component({
  selector: 'wlm-generic-crud-popup',
  templateUrl: './generic-crud-popup.component.html',
  styleUrls: ['./generic-crud-popup.component.scss'],
})
export class GenericCrudPopupComponent implements OnInit {
  @ViewChild(GenericCrudPopupContentHostDirective) set queryHostDirective(
    hostDirective: GenericCrudPopupContentHostDirective
  ) {
    this._hostDirective = hostDirective;
    if (this._hostDirective) {
      this.instanceComponent();
    }
  }

  private _hostDirective: GenericCrudPopupContentHostDirective;
  private _innerComponent: IntegrableForm;
  private _innerComponentBound$ = new ReplaySubject<void>(1);
  private setLoading: (loading: boolean) => void;

  readonly hasChanges$ = this._innerComponentBound$.pipe(
    switchMap(() => this._innerComponent.hasChanges$),
    startWith(false)
  );

  readonly isValid$ = this._innerComponentBound$.pipe(
    switchMap(() => this._innerComponent.isValid$),
    startWith(false)
  );

  private readonly _dialogRef = inject(MatDialogRef<GenericCrudPopupComponent>);
  private readonly _dynamicRenderizerService = inject(DynamicRenderizerComponentService);
  private readonly _dialogsService = inject(DialogService);
  private readonly _spinnerService = inject(SpinnerService);
  readonly dialogData: {
    op: 'create' | 'update';
    popupSettings: GenericCrudPopupSettings;
    settings: GenericCrudSettings;
    service: GenericCrudService<any, any>;
    selectedItem;
  } = inject(MAT_DIALOG_DATA);

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {}

  onSubmit(): void {
    const { popupSettings } = this.dialogData;
    const model = this._innerComponent.getModel();

    if (popupSettings.beforeSaveHook) {
      this.setLoading(true);
      popupSettings
        .beforeSaveHook(model)
        .pipe(finalize(() => this.setLoading(false)))
        .subscribe({
          next: (updatedModel) => {
            // If null, just avoid making api call as some validation error has been detected.
            if (updatedModel) {
              this.performSubmit(updatedModel);
            }
          },
          error: this._dialogsService.showErrorMessage,
        });
    } else {
      this.performSubmit(model);
    }
  }

  private performSubmit(model): void {
    const { op, service } = this.dialogData;
    let apiCall$;
    if (op === 'create') {
      apiCall$ = service.create(model);
    } else {
      apiCall$ = service.update(model);
    }

    this.setLoading(true);
    apiCall$.pipe(finalize(() => this.setLoading(false))).subscribe({
      next: (result) => {
        this.showSuccess();
        this._dialogRef.close(result);
      },
      error: this._dialogsService.showErrorMessage,
    });
  }

  private instanceComponent(): void {
    if (this._hostDirective) {
      const { popupSettings, settings } = this.dialogData;

      this._dynamicRenderizerService.injectComponentIntoViewContainer(
        popupSettings.formComponent,
        this._hostDirective.viewContainerRef,
        (component) => this.bindInnerComponent(component as IntegrableForm),
        settings.injector
      );
    }
  }

  private bindInnerComponent(component: IntegrableForm): void {
    component.detectChanges(); // Prevented NG0100
    setTimeout(() => {
      this._innerComponent = component;
      this._innerComponent.setInitialModel(this.dialogData.selectedItem);
      this._innerComponent.setParams({ op: this.dialogData.op } as IntegrableFormParams);
      this.checkReadonlyEntities();

      this._innerComponentBound$.next();
      this._innerComponentBound$.complete();
    });
  }

  private checkReadonlyEntities(): void {
    if (!this.dialogData) {
      return;
    }

    const { selectedItem, popupSettings, service } = this.dialogData;
    const { readonlyEntityIds } = popupSettings;
    if (
      selectedItem &&
      readonlyEntityIds &&
      readonlyEntityIds.find((readonlyId) => readonlyId === service.getId(selectedItem))
    ) {
      this._innerComponent.setDisabled(true);
    }
  }

  private showSuccess(): void {
    this._dialogsService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
  }

  get titleKey(): string | null {
    if (!this.dialogData) {
      return null;
    }
    if (this.dialogData.popupSettings.titleKey) {
      return this.dialogData.popupSettings.titleKey;
    } else {
      return this.dialogData.op === 'create' ? 'common.create' : 'common.edit';
    }
  }

  onButtonClose(event): void {
    this._dialogRef.close();
  }
}
