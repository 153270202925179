import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NavigationMode } from 'src/app/common-modules/dependencies/navigation/navigation-mode.enum';
import { CVShortQueryDto } from 'src/app/common-modules/dependencies/shared/model/cv-short-query.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ApplicationAttributes } from 'src/app/common-modules/shared/constants/application-constants';
import { ZoneAlgorithmGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { NavigationsConstants } from 'src/app/common-modules/shared/constants/navigation-constants';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';
import { DataVisualizationTypes } from 'src/app/common-modules/shared/model/data-viz/data-visualization-types';
import { DataVisualizationDto } from 'src/app/common-modules/shared/model/data-viz/data-visualization.dto';
import { EventViewCategories } from 'src/app/common-modules/wlm-charts/core/events-chart/models/event-view-categories';

import { GridAlgorithms } from '../constants';

const COMPONENT_SELECTOR = 'wlm-dv-algorithm-navigation-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dv-algorithm-navigation-popup.component.html',
  styleUrls: ['./dv-algorithm-navigation-popup.component.scss'],
})
export class DvAlgorithmNavigationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Algorithms}.${COMPONENT_SELECTOR}`;

  maxItemsExceeded = false;
  navigateTooltip: string;
  dvNavGridSettingsName = 'AlarmsNavigation';
  hierarchyElements: string[];
  algorithms: GridAlgorithms[];
  dateOffset = 3;
  maxItemsToNavigate = ApplicationAttributes.MaxSeriesSupportedInChar;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get navigateBtnTranslationKey(): string {
    return `${this.T_SCOPE}.btn-navigate`;
  }

  public get navigateMaxItemsNavTranslationKey(): string {
    return `${this.T_SCOPE}.messages.max-items-exceeded`;
  }

  gridSettings: ZoneAlgorithmGridSettings;
  selection = new Map<string, string[]>();

  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _dialogRef: MatDialogRef<DvAlgorithmNavigationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { hierarchyElements, algorithms }
  ) {
    this.hierarchyElements = hierarchyElements;
    this.algorithms = algorithms;
  }

  ngOnInit(): void {
    const values = new Map<string, Map<string, boolean>>();

    this.hierarchyElements.forEach((he) => {
      const heValues = new Map<string, boolean>();

      this.algorithms.forEach((a) => {
        heValues.set(a, false);
      });
      values.set(he, heValues);
    });

    this.algorithms.sort((a, b) => a.localeCompare(b));

    this.gridSettings = {
      zones: this.hierarchyElements,
      algorithms: this.algorithms,
      values,
      selectable: {
        mode: 'row',
        multiple: true,
        drag: true,
      },
    };
  }

  onSelectionChange($event: Map<string, string[]>) {
    this.selection = $event;
    let totalSelected = 0;
    this.selection.forEach((v) => {
      totalSelected += v.length;
    });

    this.maxItemsExceeded = totalSelected > this.maxItemsToNavigate;
    this.navigateTooltip = this.maxItemsExceeded ? this.navigateMaxItemsNavTranslationKey : '';
  }

  navigate() {
    const cvShortQuery: CVShortQueryDto[] = [];

    this.selection.forEach((algorithms, he) => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - this.dateOffset);
      cvShortQuery.push({
        elementIds: [he],
        algorithms,
        startDate,
        endDate: new Date(),
        isValid: true,
      });
    });
    const dvData: DataVisualizationDto = {
      data: cvShortQuery,
      eventViewCategory: [EventViewCategories.Alarm],
      dataType: DataVisualizationTypes.Trend,
      elementsIds: Array.from(this.selection.keys()),
    };

    this._localStorageService.addOrUpdate(
      NavigationsConstants.NavigationModeKey,
      NavigationMode.Backend,
      true
    );

    this._localStorageService.addOrUpdate(NavigationsConstants.QueryParamskey, dvData, true);
    this.cancel();
    this._router.navigate(['dv-deprecated/data-visualization-deprecated']);
  }

  cancel() {
    this._dialogRef.close(false);
  }
}
