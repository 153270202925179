import { BiTemplateDto } from './bi-template.dto';

export class BiSaveTemplateDto extends BiTemplateDto {
  template: string;
  layoutKey?: string;
  layoutArea?: string;

  constructor(init: BiSaveTemplateDto) {
    super(init);
    Object.assign(this, init);
  }
}
