import { AfterViewChecked, Directive, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  WlmResizeObserverConfig,
  WlmResizeObserverService,
} from '../services/resize-observer.service';

@UntilDestroy()
@Directive({
  selector: '[wlmGridHorizWheelScroll]',
})
export class GridHorizWheelScrollDirective implements AfterViewChecked {
  private elementSelector = '.k-grid-content';
  private sizeChangeSelector = '.k-grid-table-wrap';
  private element: Element;
  private verticalScrollActive$ = new ReplaySubject<boolean>(1);
  private isVerticalScrollActive: boolean = null;
  private hasBeenActiveOnce = false;

  constructor(private el: ElementRef, private resizeObserver: WlmResizeObserverService) {}

  ngAfterViewChecked(): void {
    if (!this.element) {
      const element = this.el.nativeElement.querySelector(this.elementSelector);
      if (element) {
        this.element = element;
        this.init();
      }
    }
  }

  private init() {
    this.listenVerticalScrollActive();
    this.verticalScrollActive$.pipe(untilDestroyed(this)).subscribe((isActive) => {
      // Check if the state has changed.
      if (this.isVerticalScrollActive === null || this.isVerticalScrollActive !== isActive) {
        this.isVerticalScrollActive = isActive;

        // Only set horizontal scroll if vertical scroll is disabled.
        if (!this.isVerticalScrollActive) {
          this.applyHorizontalScroll();
        } else {
          this.removeHorizontalScroll();
        }
      }
    });
  }

  private listenVerticalScrollActive(): void {
    const isActiveInitial = this.checkVerticalScrollActive();
    this.elementChanged$()
      .pipe(startWith(isActiveInitial), untilDestroyed(this))
      .subscribe(() => {
        const isActive = this.checkVerticalScrollActive();
        // There is a small, non-harmful race condition when detecting that vertical scrollbar is active.
        // While checking the scrollbar, some horizontal scroll can happen. This "if" prevents that.
        if (isActive && !this.hasBeenActiveOnce) {
          this.hasBeenActiveOnce = true;
        }
        this.verticalScrollActive$.next(isActive);
      });
  }

  private checkVerticalScrollActive(): boolean {
    if (!this.element) {
      return false;
    }
    const result = this.element.scrollHeight > this.element.clientHeight;
    return result;
  }

  /**
   * Listen to any element change that may trigger the vertical scroll.
   */
  private elementChanged$(): Observable<number> {
    const dataElement = this.el.nativeElement.querySelector(this.sizeChangeSelector);
    const element$ = this.observeResize(this.element);
    const dataElement$ = this.observeResize(dataElement);
    const observables$ = [element$, dataElement$];

    return new Observable<number>((observer) => {
      observables$.forEach((obs$, index) => {
        obs$.pipe(untilDestroyed(this)).subscribe(() => observer.next(index));
      });
    });
  }

  private observeResize(element: Element): Observable<any> {
    return this.resizeObserver
      .observe(
        new WlmResizeObserverConfig({
          el: element,
          calculateHeight: false,
        })
      )
      .pipe(startWith(false));
  }

  private applyHorizontalScroll(): void {
    this.element.addEventListener('wheel', this.horizontalScrollCallback);
  }

  private removeHorizontalScroll(): void {
    this.element.removeEventListener('wheel', this.horizontalScrollCallback);
  }

  private horizontalScrollCallback = (event: WheelEvent) => {
    if (this.hasBeenActiveOnce) {
      event.preventDefault();
      this.element.scrollLeft += event.deltaY;
    }
  };
}
