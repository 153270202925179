import { HierarchyFamilyFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';

export class FamilyLevelFilterConfiguration {
  persistencyArea: string;
  hierarchyFamilyFieldName: string;
  defaultFamilyId: string;
  heFamilyFilterSettings: HierarchyFamilyFISettings;

  constructor(
    persistencyArea: string,
    hierarchyElementFamilyFieldName: string,
    defaultFamilyId: string,
    selectedFamilyId?: string
  ) {
    this.persistencyArea = persistencyArea;
    this.defaultFamilyId = defaultFamilyId;
    this.hierarchyFamilyFieldName = hierarchyElementFamilyFieldName;

    this.heFamilyFilterSettings = new HierarchyFamilyFISettings({
      fieldName: hierarchyElementFamilyFieldName,
      selectedId: selectedFamilyId,
    });
  }
}
