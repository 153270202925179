import { Params } from '@angular/router';
import { FilterAdditionalParam } from './filter-additional-param';
import { FilterGroupFieldSettings } from './filter-group-field-settings';

export class FilterGroupSettings {
  fields: { [field: string]: FilterGroupFieldSettings };
  navigationParams?: Params;
  additionalParams?: { [paramKey: string]: FilterAdditionalParam };
  persistencyArea: string;
  avoidPersistency?: boolean;

  constructor(init: Readonly<FilterGroupSettings>) {
    Object.assign(this, init);
  }
}
