<wlm-spinner-wrapper [showSpinner]="isSaving">
  <h3>{{ T_SCOPE + '.form-title' | translate }}</h3>
  <hr class="title-separator" />

  <mat-dialog-content [formGroup]="form">
    <div class="fields-container">
      <div class="rows">
        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.change-eligibility-label' | translate }}</mat-label>
          <mat-select formControlName="changeEligibilityControl">
            <mat-option *ngFor="let option of changeElegibilityOptions" [value]="option.value">{{
              option.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="reason-style">
          <mat-label>{{ T_SCOPE + '.reason-label' | translate }}</mat-label>
          <mat-select formControlName="reasonControl">
            <mat-option
              *ngFor="let reason of filteredEligibilityReasons"
              [value]="reason.reasonForEligibleChangeId"
            >
              {{ reason.reasonForEligibleChangeDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="rows">
        <mat-form-field class="comment-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.comment-label' | translate }}</mat-label>
          <textarea matInput placeholder="comment" formControlName="commentControl"></textarea>
        </mat-form-field>
      </div>
      <div class="rows">
        <mat-label class="field-label">{{
          T_SCOPE + '.selected-elements-label' | translate
        }}</mat-label>
      </div>
      <div class="rows scrollable">
        <mat-chip-grid class="chip-list">
          <mat-chip-row class="chip-item" *ngFor="let chip of selectedHierarchyElementIds">
            {{ chip }}
          </mat-chip-row>
        </mat-chip-grid>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="save()"
        [disabled]="!(form.dirty && form.valid) || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button class="mat-raised-button" (click)="close()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</wlm-spinner-wrapper>
