import { Type } from '@angular/core';
import { BaseWidgetComponent } from '../../shared/component/base-widget.component';
import { ComponentHelperService } from '../../shared/helpers/component-helper.service';

export class TabDetailPanelSettings {
  components: Type<BaseWidgetComponent>[] = [];
  titlesByKey: Map<string, string>;
  titlesByOrder: Map<number, string>;
  showHeaders = true;

  constructor() {
    this.titlesByKey = new Map<string, string>();
    this.titlesByOrder = new Map<number, string>();
  }

  public addComponent(component: Type<BaseWidgetComponent>, title: string = null) {
    const index = this.components.push(component);
    const componentName = ComponentHelperService.getWidgetName(component);
    const componentTitle = title ?? componentName;
    this.titlesByOrder.set(index, componentTitle);
    this.titlesByKey.set(componentName, componentTitle);
  }
}

export class TabDetailPanelParameters {
  parameters: Map<TabDetailParameterName, any>;
  constructor() {
    this.parameters = new Map<TabDetailParameterName, any>();
  }
  public addParameter(key: TabDetailParameterName, value: any, update = true) {
    if (this.parameters.has(key)) {
      if (update) {
        this.parameters.set(key, value);
      } else {
        throw new Error(`Parameter already exists ${key}`);
      }
    } else {
      this.parameters.set(key, value);
    }
  }
}

export enum TabDetailParameterName {
  orderNumber = 'orderNumber',
  workOrderExternalId = 'workOrderExternalId',
  workOrderId = 'workOrderId',
  activityId = 'activityId',
  chartParameters = 'chartParameters',
  hierarchyElementId = 'hierarchyElementId',
  hierarchyElementIdsTree = 'hierarchyElementIdsTree',
  hierarchyFamilyId = 'hierarchyFamilyId',
  hierarchyFamilyIdTree = 'hierarchyFamilyIdTree',
  hierarchyElementTypeFilterSetting = 'hierarchyElementTypeFilterSetting',
  // hierarchyElementTypeFilter = 'hierarchyElementTypeFilter',
  networkElementTypeFilterSetting = 'networkElementTypeFilterSetting',
  // networkElementTypeFilter = 'networkElementTypeFilter',
  startDate = 'startDate',
  endDate = 'endDate',
  mapParameters = 'mapParameters',
  dataBindingFilter = 'dataBindingFilter',
  textField = 'textField',
  elementId = 'elementId',
  hasPersistencySave = 'hasPersistencySave',
  openActivities = 'openActivities',
  activities = 'activities',
  elementName = 'elementName',
  filterExpanded = 'filterExpanded',
  signalId = 'signalId',
  Group = 'Group',
  user = 'user',
  waterBalanceType = 'waterBalanceType',
  waterBalanceChart = 'waterBalanceChart',
  dimensionType = 'dimensionType',
  basicFilters = 'basicFilters',
  timeSelectorChartSetting = 'timeSelectorChartSetting',
  alarmTypeId = 'alarmTypeId',
  profileConfiguration = 'profileConfiguration',
  algorithm = 'algorithm',
  notification = 'notification',
  notificationId = 'notificationId',
  isZone = 'isZone',
  customer = 'customer',
  networkElementId = 'networkElementId',
  dynamicFormSource = 'dynamicFormSource',
  dynamicFormLayoutKeys = 'dynamicFormLayoutKeys',
  networkElement = 'networkElement',
  configuredStateService = 'configuredStateService',
  selectedFormCategories = 'selectedFormCategories',
  campaign = 'campaign',
  systemLog = 'systemLog',
  audit = 'auditId',
  pageId = 'pageId',

  // Generic, RP-related settings.
  subtitle = 'subtitle',
  titleSidePills = 'titleSidePills',
  stylizedTitleSidePills = 'stylizedTitleSidePills',
  topAttributes = 'topAttributes',
  topValues = 'topValues',
  containerClass = 'containerClass',
  refreshAlarmChart = 'refreshAlarmChart',
  buttonWithDetails = 'buttonWithDetails',
}
