<div class="grid-filter-container">
  <mat-form-field appearance="outline" class="full-width">
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let e of selectedElements" (click)="removeChip($event, e)">
        {{ e.text }}
        <mat-icon matChipRemove (click)="removeChip($event, e)">cancel</mat-icon>
      </mat-chip-row>

      <input
        matInput
        type="text"
        [matAutocomplete]="auto"
        [formControl]="selectControl"
        [matChipInputFor]="chipList"
      />
    </mat-chip-grid>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let e of filteredElements | async" (click)="optionClicked($event, e)">
      <div [class]="isSelected(e) ? 'selected' : ''">
        <span class="option-text">{{ e.text }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
