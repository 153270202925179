import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig, ValidatorOption } from '@ngx-formly/core/lib/models';
import { LocalizationHelperService } from '../shared/localization/localization-helper.service';
import { isSvgValidator } from '../svg-editor/is-svg.validator';

export const configurableFormValidations: ValidatorOption[] = [
  {
    // Validate a string list so that none of the elements is longer than maxLength.
    name: 'maxLengthInList',
    validation: (control: AbstractControl, field: FormlyFieldConfig, { maxLength }) => {
      const value = control.value;
      if (!value || !Array.isArray(value)) {
        return null;
      }
      for (let item of value) {
        if (typeof item?.length !== 'undefined' && item.length >= maxLength) {
          return {
            maxLengthInList: {
              item,
              maxLength,
            },
          };
        }
      }
    },
  },
  {
    name: 'maxLength',
    validation: (control: AbstractControl, field: FormlyFieldConfig, { maxLength }) => {
      const value = control.value;
      if (!value || typeof value !== 'string') {
        return null;
      }
      if (value.length > maxLength) {
        return {
          maxLength: {
            maxLength,
          },
        };
      }
      return null;
    },
  },
  {
    name: 'notSvg',
    validation: (control: AbstractControl) => isSvgValidator()(control),
  },
];

export function configurableFormValidationMessagesBuilder(localization: LocalizationHelperService) {
  return [
    {
      name: 'maxLengthInList',
      message: (error: any, field: FormlyFieldConfig) => {
        return localization.get('common.validation.max-length-in-list', {
          item: error.item,
          maxLength: error.maxLength,
        });
      },
    },
    {
      name: 'maxLength',
      message: (error: any, field: FormlyFieldConfig) => {
        return localization.get('common.validation.max-length', {
          max: error.maxLength,
        });
      },
    },
    {
      name: 'notSvg',
      message: (error: any, field: FormlyFieldConfig) =>
        localization.get('common.validation.not-svg'),
    },
  ];
}
