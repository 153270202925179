<div [class]="'generic-card-content flex-style flex-style-max ' + contentClass ?? ''">
  <div *ngIf="leftTemplateRef" class="card-left">
    <ng-container
      [ngTemplateOutlet]="leftTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    >
    </ng-container>
  </div>
  <div *ngIf="!settings.isReadOnly && leftIconName" class="flex-style icon-position">
    <wlm-icon *ngIf="leftIconName" [icon]="leftIconName" [color]="settings.iconColor"></wlm-icon>
  </div>

  <div class="card-column card-container">
    <div *ngIf="!useTitleCentered" class="card-row">
      <div *ngIf="!resizeToContent || title1" class="title1 truncate">
        {{ title1 }}
      </div>
      <div class="separator">
        {{ titleSeparator }}
      </div>
      <div *ngIf="!resizeToContent || title2" class="secondary truncate">
        {{ title2 }}
      </div>
    </div>
    <div class="card-column">
      <div *ngIf="!useTitleCentered" class="subtitle">
        {{ subtitle }}
      </div>
      <div *ngIf="useTitleCentered" class="card-row">
        <div *ngIf="!resizeToContent || title1" class="title1 truncate">{{ title1 }}</div>
        <div class="separator">{{ titleSeparator }}</div>
        <div *ngIf="!resizeToContent || title2" class="secondary truncate">{{ title2 }}</div>
      </div>
      <div *ngIf="!resizeToContent || extraInformation" class="secondary">
        {{ extraInformation }}
      </div>
    </div>
  </div>
  <div *ngIf="rightTemplateRef" class="card-right">
    <ng-container
      [ngTemplateOutlet]="rightTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    >
    </ng-container>
  </div>
</div>
<div *ngIf="showMoreTemplateRef" class="show-more-container">
  <hr class="show-more-separator" />
  <ng-container
    *ngIf="isShowMoreToggled"
    [ngTemplateOutlet]="showMoreTemplateRef"
    [ngTemplateOutletContext]="{ $implicit: item }"
  >
  </ng-container>
  <div class="show-more-buttons">
    <button mat-button (click)="toggleShowMore()">
      {{ (isShowMoreToggled ? 'common.show-less' : 'common.show-more') | translate }}
    </button>
  </div>
</div>
