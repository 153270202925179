import { StateScopeSettings } from './state-scope-settings';

export abstract class GenericSelector {
  abstract readonly type: string;
  abstract readonly area: string;
  settings?: StateScopeSettings;

  constructor(settings = new StateScopeSettings({})) {
    this.settings = settings;
  }
}
