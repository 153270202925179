import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { CustomerMeterReadingDto } from '../models/customer-meter-reading.dto';

@Injectable()
export class CustomerReadingsGridService extends GridODataService<CustomerMeterReadingDto> {
  constructor(injector: Injector) {
    super(injector, '/api/customer/readings');
  }

  protected mapResponse(
    response: PagedResultDto<CustomerMeterReadingDto>
  ): PagedResultDto<CustomerMeterReadingDto> {
    return response;
  }
}
