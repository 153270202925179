import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as echarts from 'echarts';
import { LineChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TimelineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import langEN from 'echarts/lib/i18n/langEN';
import langES from 'echarts/lib/i18n/langES';
import langFR from 'echarts/lib/i18n/langFR';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/theme/macarons.js';
import { NgxEchartsModule } from 'ngx-echarts';
import { ResponsiveButtonsContainerComponent } from '../shared-component/responsive-buttons-container/responsive-buttons-container.component';
import { BaseCustomizableChartService } from '../shared/charts/base-customizable-chart.service';
import { CommonSharedModule } from '../shared/common-shared.module';
import { StaticLocales } from '../shared/localization/static-locales.enum';
import { MaterialSharedModule } from '../shared/material-shared.module';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { WlmSpinnerModule } from '../wlm-spinner/wlm-spinner.module';
import { ChartLegendComponent } from './core/chart-legend/chart-legend.component';
import { CustomWorkspaceChartComponent } from './core/custom-workspace-chart/custom-workspace-chart.component';
import { CustomizableChartComponent } from './core/customizable-chart/customizable-chart.component';
import { CustomizableChartService } from './core/customizable-chart/customizable-chart.service';
import { EditableChartComponent } from './core/editable-chart/editable-chart.component';
import { EventsChartComponent } from './core/events-chart/events-chart.component';
import { GenericChartComponent } from './core/generic-chart/generic-chart.component';
import { HistoricalChartComponent } from './core/historical-chart/historical-chart.component';
import { PieChartComponent } from './core/pie-chart/pie-chart.component';
import { GenericChartService } from './core/services/generic-chart.service';
import { TimeSelectorService } from './core/services/time-selector.service';
import { TimeSelectorChartWidgetComponent } from './core/time-selector-chart-widget/time-selector-chart-widget.component';
import { TimeSelectorChartComponent } from './core/time-selector-chart/time-selector-chart.component';
import { TimeSelectorComponent } from './core/time-selector-chart/time-selector/time-selector.component';
import { TrendChartComponent } from './core/trend-chart/trend-chart/trend-chart.component';
import { DynamicChartComponent } from './dynamic-chart/dynamic-chart.component';
import './themes/w-dark.js';
import './themes/w-white.js';

echarts.use([
  LegendComponent as any,
  MarkLineComponent as any,
  GridComponent as any,
  TitleComponent as any,
  TimelineComponent as any,
  TooltipComponent as any,
  CanvasRenderer as any,
  DataZoomComponent as any,
  LineChart as any,
]);

echarts.registerLocale(StaticLocales.ES, langES);
echarts.registerLocale(StaticLocales.EN, langEN);
echarts.registerLocale(StaticLocales.EN_GB, langEN);
echarts.registerLocale(StaticLocales.IT, langEN); // No Italian available.
echarts.registerLocale(StaticLocales.FR, langFR);

export const WLM_CHART_SETTINGS = new InjectionToken<GenericChartsModuleSettings>(
  'wlm_chart_settings'
);

@NgModule({
  declarations: [
    GenericChartComponent,
    TrendChartComponent,
    TimeSelectorChartComponent,
    TimeSelectorComponent,
    EventsChartComponent,
    ChartLegendComponent,
    PieChartComponent,
    HistoricalChartComponent,
    DynamicChartComponent,
    EditableChartComponent,
    CustomizableChartComponent,
    CustomWorkspaceChartComponent,
    TimeSelectorChartWidgetComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    TranslateModule,
    WlmSpinnerModule,
    CommonSharedModule,
    ResponsiveButtonsContainerComponent,
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],
  exports: [
    GenericChartComponent,
    TrendChartComponent,
    TimeSelectorComponent,
    TimeSelectorChartComponent,
    EventsChartComponent,
    PieChartComponent,
    HistoricalChartComponent,
    DynamicChartComponent,
    EditableChartComponent,
    CustomizableChartComponent,
    CustomWorkspaceChartComponent,
  ],
  providers: [GenericChartService, TimeSelectorService, CustomizableChartService],
})
export class GenericChartsModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'TimeSelectorChartWidgetComponent',
        widgetClass: TimeSelectorChartWidgetComponent,
      },
    ]);
  }

  static forFeature(
    externalSettings?: GenericChartsModuleSettings
  ): ModuleWithProviders<GenericChartsModule> {
    return {
      ngModule: GenericChartsModule,
      providers: [
        {
          provide: WLM_CHART_SETTINGS,
          useValue: externalSettings,
        },
        {
          provide: BaseCustomizableChartService as any,
          useClass: externalSettings.baseCustomizableChartService,
        },
      ],
    };
  }
}

export class GenericChartsModuleSettings {
  avoidConversion: boolean;
  baseCustomizableChartService: Type<BaseCustomizableChartService>;

  constructor(init: Partial<GenericChartsModuleSettings>) {
    Object.assign(this, init);
  }
}
