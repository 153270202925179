import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'wlm-configurable-form',
  templateUrl: './configurable-form.component.html',
  styleUrls: ['./configurable-form.component.scss'],
})
export class ConfigurableFormComponent implements OnInit {
  @Input() fieldConfig: FormlyFieldConfig;
  @Input() formOptions: FormlyFormOptions;

  private _formDisabled: boolean;
  public get formDisabled(): boolean {
    return this._formDisabled;
  }
  @Input() set formDisabled(value: boolean) {
    this._formDisabled = value;
    this.formDisabled ? this.form.disable() : this.form.enable();
  }

  private _initialModel = {};
  get initialModel() {
    return this._initialModel;
  }
  @Input() set initialModel(value) {
    this._initialModel = value;
    this.model = this._initialModel ?? {};
  }

  @Output() modelChange = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() hasChanges = new EventEmitter<boolean>();

  model = {};
  form = new FormGroup({});
  fullFormOptions: FormlyFormOptions = {
    formState: {},
  };

  ngOnInit(): void {}

  onModelChange(model): void {
    this.model = model;
    this.modelChange.emit(this.model);
    this.isValid.emit(this.form.valid);
    this.hasChanges.emit(this.form.dirty);
  }
}
