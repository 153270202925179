import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupDto } from 'src/app/common-modules/shared/model/roles/group.dto';
import { GroupUsersCmdDto } from 'src/app/common-modules/shared/model/roles/groupUsersCmd.dto';
import { UserDto } from 'src/app/common-modules/shared/model/roles/user.dto';
import { UsersGridODataService } from 'src/app/common-modules/shared/services/users/users.service';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-group-users-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './group-users-popup.component.html',
  styleUrls: ['./group-users-popup.component.scss'],
})
export class GroupUsersPopupComponent implements OnInit {
  @ViewChild(GenericGridComponent) public usersGrid: GenericGridComponent;
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  isSaving = false;

  userGridSettingsName = 'GroupUsersPopup';

  gridFiltersForBinding: DataBindingFilters;
  additionalFilters: Map<string, string>;
  groupName: string;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  private _groupId: number;
  public get groupId(): number {
    return this._groupId;
  }
  public set groupId(v: number) {
    this._groupId = v;
    this.loadUsers();
  }

  private _selectedUsers: UserDto[];
  public get selectedUsers(): UserDto[] {
    return this._selectedUsers;
  }
  public set selectedUsers(v: UserDto[]) {
    this._selectedUsers = v;
  }

  private _userGridSetting: GridSetting;
  public get userGridSetting(): GridSetting {
    return this._userGridSetting;
  }
  public set userGridSetting(v: GridSetting) {
    this._userGridSetting = v;
  }

  constructor(
    _gridSettingsService: GridSettingsService,
    private dialogRef: MatDialogRef<GroupUsersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { groupId, groupName }: GroupDto,
    private _userService: UsersGridODataService,
    private _dialogService: DialogService,
    private _authenticationService: AuthenticationService
  ) {
    _gridSettingsService.getGridSettingsByName(this.userGridSettingsName).subscribe({
      next: (gridSettings) => {
        this.userGridSetting = gridSettings;
      },
    });
    this.groupId = groupId;
    this.groupName = groupName;
  }

  ngOnInit(): void {
    const additionalFilters = new Map<string, string>();
    additionalFilters.set('exclude', 'true');
    additionalFilters.set('groupId', this.groupId?.toString());
    this.additionalFilters = additionalFilters;
  }

  loadUsers() {
    // this._userService.filterByGroup(this.groupId);
    const dataBinding = new DataBindingFilters();
    this.gridFiltersForBinding = dataBinding;
  }

  saveGroupUsers() {
    this.isSaving = true;
    this.usersGrid.grid.loading = true;

    const groupUsersCmd = new GroupUsersCmdDto(
      [this.groupId],
      this.selectedUsers.map((x) => x.userCode),
      this._authenticationService.userCode
    );
    const isPlural = this.selectedUsers.length > 1;

    this._userService.insertGroupUsers(groupUsersCmd).subscribe({
      next: (response) => {
        if (response.errors.length === 0) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.add-users-success' : '.add-user-success'
              }`,
            })
          );

          this.dialogRef.close(true);
        } else {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.add-users-error' : '.add-user-error'
              }`,
              icon: 'error',
            })
          );
        }
        this.usersGrid.grid.loading = false;
        this.isSaving = false;
      },
      error: (error) => {
        this.isSaving = false;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
