import { Injectable } from '@angular/core';

export interface IStorageService {
  get(key: string, defaultValue?: any, useLocalStorage?: boolean): any;
  getTyped(key: string, defaultValue?: any, useLocalStorage?: boolean): any;
  addOrUpdate(key: string, value: any, useLocalStorage?: boolean): void;
}

@Injectable()
export class LocalStorageService implements IStorageService {
  get(key: string, defaultValue?: any, useLocalStorage = true) {
    const value = useLocalStorage ? localStorage.getItem(key) : sessionStorage.getItem(key);
    try {
      return JSON.parse(value) || defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }

  addOrUpdate(key: string, value: any, useLocalStorage = true): void {
    const configJson = JSON.stringify(value);
    if (useLocalStorage) {
      localStorage.setItem(key, configJson);
    } else {
      sessionStorage.setItem(key, configJson);
    }
  }
  remove(key: string, useLocalStorage = true) {
    if (useLocalStorage) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  getTyped(key: string, defaultValue?: any, useLocalStorage = true) {
    const value = useLocalStorage ? localStorage.getItem(key) : sessionStorage.getItem(key);
    try {
      if (defaultValue) {
        let result = JSON.parse(value);
        // Avoid overriding falsey values like 0 or false.
        result = result !== null && typeof result !== 'undefined' ? result : defaultValue;
        // Using Object.setPrototypeOf to set the ES6 type of the default value to the result.
        return Object.setPrototypeOf(result, Object.getPrototypeOf(defaultValue));
      } else {
        return JSON.parse(value);
      }
    } catch (error) {
      return defaultValue;
    }
  }

  getRawValue(key: string, useLocalStorage = true) {
    return useLocalStorage ? localStorage.getItem(key) : sessionStorage.getItem(key);
  }
}
