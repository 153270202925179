import { Inject, Injectable } from '@angular/core';
import { ActionCreator, ActionReducer, createReducer, Creator } from '@ngrx/store';
import { ComponentHelperService } from '../../shared/helpers/component-helper.service';
import { StateHelperService } from '../helpers/state-helper.service';
import { GenericExtendedSelectorPayload } from '../models/generic-extended-selector';
import { GenericSelector } from '../models/generic-selector';
import { StateFullSettings } from '../models/state-full-settings';
import { StateSelector } from '../models/state-selector';
import { BaseGlobalReducer } from './base-global.reducer';
import { BaseStore } from './base.store';
import { globalInitialState } from './initial.state';

const initialState = globalInitialState;

@Injectable()
export class GlobalStoreService {
  private _storeServices: BaseStore[];
  private _actionCreators = new Map<string, ActionCreator<string, Creator<any[], object>>>();
  private _selectorCreators = new Map<string, StateSelector>();

  constructor(
    @Inject(BaseStore) storeServices: BaseStore[],
    @Inject(BaseGlobalReducer) private _globalReducers: BaseGlobalReducer[],
    private _helperService: StateHelperService
  ) {
    // Must check this because the same store service could pontentially be injected more than once.
    this._storeServices = this.uniqueStoreServices(storeServices);
  }

  joinAll(): void {
    this.joinActionCreators();
    this.joinSelectorFns();
  }

  /**
   * Merge all the reducers, the scoped ones, created by Store Services,
   * and the global ones, defined in the module.
   */
  buildReducer(): ActionReducer<any, any> {
    const reducer = this.mergeScopedReducers();

    if (!this._globalReducers) {
      return reducer;
    }

    let mergedReducer = reducer;

    this._globalReducers.forEach((currentReducer) => {
      mergedReducer = currentReducer.createReducer(mergedReducer);
    });

    return mergedReducer;
  }
  /**
   * Join all the reducers that are created by using Store Services.
   */
  private mergeScopedReducers(): ActionReducer<any, any> {
    const reducerCases = this._storeServices.reduce((reducers: any[], service) => {
      return reducers.concat(service.generateReducer());
    }, []);

    this.validateReducers(reducerCases);

    const reducer = createReducer(initialState, ...reducerCases);
    return reducer;
  }

  getSelector<T>(
    selectorQuery: GenericSelector,
    fullSettings: StateFullSettings,
    payload?: GenericExtendedSelectorPayload
  ): any {
    const creator: StateSelector = this._helperService.clone(
      this._selectorCreators.get(selectorQuery.type),
      false
    );
    if (!creator) {
      // TODO: Log error
      return null;
    }

    const selector = creator.buildSelector(fullSettings, payload);
    return selector;
  }

  getActionCreators(): Map<string, ActionCreator<string, Creator<any[], object>>> {
    return this._actionCreators;
  }

  private uniqueStoreServices(storeServices: BaseStore[]): BaseStore[] {
    const namesHash = new Map<string, BaseStore>();
    storeServices.forEach((service) => {
      const serviceName = ComponentHelperService.getServiceName(service);
      namesHash.set(serviceName, service);
    });

    return Array.from(namesHash.values());
  }

  /*
   * Validate that the same action type does not have more than one handler.
   */
  private validateReducers(reducerCases: any[]): void {
    const allActionTypes = reducerCases.reduce(
      (actionTypes: string[], reducerCase) => actionTypes.concat(reducerCase.types),
      []
    );

    const duplicatedActionTypes = this._helperService.getDuplicated(allActionTypes);

    if (duplicatedActionTypes.length) {
      throw new Error(
        `RDX006: More than one handler was found for the action types: ${duplicatedActionTypes.join(
          ', '
        )}`
      );
    }
  }

  private joinSelectorFns(): void {
    const selectorsMap = this._storeServices.reduce(
      (selectors: Map<string, any>, service) =>
        this._helperService.joinMaps([selectors, service.generateSelectors()]),
      new Map<string, any>()
    );
    this._selectorCreators = selectorsMap;
  }

  private joinActionCreators(): void {
    const actionsMap = this._storeServices.reduce(
      (actions: Map<string, ActionCreator<any>>, service) =>
        this._helperService.joinMaps([actions, service.generateActionCreators()]),
      new Map<string, ActionCreator<any>>()
    );
    this._actionCreators = actionsMap;
  }
}
