import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldTypeConfig } from '@ngx-formly/core';
// IMPORTANT: This should always be imported from material, not core.
import { FieldType } from '@ngx-formly/material';
import Color from 'color';
import { Observable, ReplaySubject, map, startWith, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wlm-color-picker-type',
  templateUrl: './color-picker-type.component.html',
  styleUrls: ['./color-picker-type.component.scss'],
})
export class ColorPickerTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private _initReady$ = new ReplaySubject<void>(1);
  readonly color$: Observable<string> = this._initReady$.pipe(
    untilDestroyed(this),
    switchMap(() =>
      this.formControl.valueChanges.pipe(
        startWith(this.formControl.value ?? this.field.defaultValue)
      )
    ),
    map((color) => this.mapToInputAcceptedFormat(color))
  );

  ngOnInit(): void {
    this._initReady$.next();
    this._initReady$.complete();
  }

  onColorPickerChange(color: string): void {
    const formattedColor = this.mapToOutputAcceptedFormat(color);
    this.formControl.setValue(formattedColor);
    this.formControl.markAsDirty();
  }

  mapToInputAcceptedFormat = (color: string): string => {
    const colorObject = new Color(color);
    const convertedColor = colorObject.rgb().string();
    return convertedColor;
  };

  mapToOutputAcceptedFormat = (color: string): string => {
    let colorObject = new Color(color);
    const convertedColor = colorObject.hexa();
    return convertedColor;
  };
}
