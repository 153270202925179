<div *ngIf="items && items.length !== 0" class="summary">
  <span class="filter-item-title title">
    <span class="filter-select-item-title">
      {{ title }}
    </span></span
  >
  <div *ngFor="let item of items" class="filter-select-item">
    <mat-checkbox checked="true" disabled="true"></mat-checkbox>
    <wlm-svg
      *ngIf="item.icon"
      class="svg-icon filter-item-option-icon"
      [style.height]="item.height + 'px'"
      [style.width]="item.width + 'px'"
      [width]="item.width"
      [height]="item.height"
      [path]="item.icon"
      [svgFill]="'#545c69'"
    ></wlm-svg>
    <span class="filter-select-item-label">{{ item.label }}</span>
  </div>
</div>
