export class RouteNameHelper {
  static readonly configurationModule = 'configuration';
  static readonly alarmsCreateTelemetry = 'alarm/create/telemetry';
  static readonly alarmsCreateAlgorithm = 'alarm/create/algorithms';
  static readonly alarms = 'alarm';
  static readonly telemetry = 'telemetry';
  static readonly notifications = 'notifications';
  static readonly neConfig = 'network-element';
  static readonly neConfigDetail = 'network-element/details';
  static readonly leakageReporting = 'dv';
  static readonly algorithms = 'algorithm';
  static readonly bi = 'bi';
  static readonly biDetails = 'bi/home';
  static readonly uom = 'units';
  static readonly logsInterfaces = 'interfaces';
  static readonly logsSystem = 'system';
  static readonly audits = 'audits';
  static readonly dataValidation = 'da/manual-data-validation';
  static readonly calendar = 'calendar';
  static readonly mergedZones = 'merged-zones';

  static getConfigurationRoute(route: string) {
    return `${this.configurationModule}/${route}`;
  }
}
