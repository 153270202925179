<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [pageId]="pageId"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="grid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      [disable$]="gridBtnsDisable$"
      (btnClick)="onClickGridBtns($event)"
      (gridSettingsChange)="onGridSettingsChanged($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-hierarchy-element-tree-family-filter
      *ngIf="filterSettings"
      [settings]="filterSettings"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="onApplyFilters(null)"
      (valid)="onValidityChange($event)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-hierarchy-element-tree-family-filter>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings && gridFilters"
      [dynamicGridSettings]="dynamicGridSettings"
      (selectedItemChanged)="getSelectedElement($event)"
      (genericGridLoaded)="getGenericGridInstance($event)"
      (gridSettingsLoaded)="getGridSettings($event)"
      [(selectedItems)]="selectedTNs"
      [dataBindingFilters]="emptyFilters"
      [additionalFilters]="gridFiltersMap"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [gridSettingsChanged$]="gridSettingsChanged$"
      [reloadGrid$]="reloadGrid$"
      [showSelectedList]="true"
      [showSelectedItems]="false"
      (isInThePastChange)="onIsInThePastChange($event)"
    ></wlm-dynamic-grid>
  </ng-container>
</wlm-page-wrapper>
