<svg
  #svgElement
  *ngIf="path"
  xmlns="http://www.w3.org/2000/svg"
  [style.height.px]="height"
  [style.width.px]="width"
>
  <g [attr.transform]="transformClass">
    <path #pathContainer [attr.fill]="svgFill" [attr.stroke]="svgStroke" [attr.d]="path" />
  </g>
</svg>

<span #svgContainer *ngIf="fullSvg"> </span>
