import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, tap } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { CrudConstants } from '../../dependencies/shared/crud-constants';
import { AppModules } from '../../shared/app-modules.enum';
import { DialogService } from '../../shared/dialogs/dialogs.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { WlmDialogSettings } from '../../shared/model/dialog/wlm-dialog-setting';
import { IUnitTypeDto } from '../../shared/model/unit/unit-type.dto';
import { HierarchyElementTypeTimeUnitsQueryDto } from '../../shared/model/uom/hierarchy-element-type-time-units.dto';
import { PendingChanges } from '../../shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '../../shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '../../shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from '../../shared/services/globals.service';
import { SpinnerService } from '../../wlm-spinner/spinner.service';
import { UomHierarchyBulkOptions } from '../models/uom-hierarchy-bulk-options';
import { UomConfigurationService } from '../services/uom-configuration.service';

const COMPONENT_SELECTOR = 'wlm-uom-configuration';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-configuration.component.html',
  styleUrls: ['./uom-configuration.component.scss'],
})
export class UomConfigurationComponent implements OnInit, IPendingChangesEmitter {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private readonly _timeAggregation = 1;

  @Input() pageId: string;

  private _hierarchyFamily: string;
  public get hierarchyFamily(): string {
    return this._hierarchyFamily;
  }
  @Input() public set hierarchyFamily(value: string) {
    if (value && this.hierarchyFamily !== value) {
      this._hierarchyFamily = value;

      if (this.dimension && this.form) {
        this.loadHeTypesUoMConfig();
      }
    }
  }

  private _dimension: number;
  public get dimension(): number {
    return this._dimension;
  }
  @Input() public set dimension(value: number) {
    if (value && this.dimension !== value) {
      this._dimension = value;

      this.setUnitTypesByDimension();

      if (this.hierarchyFamily && this.form) {
        this.loadHeTypesUoMConfig();
      }
    }
  }

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    if (this._configurationHasChanged != value) {
      this._configurationHasChanged = value;
      this.setPendingChanges(this.pageId, this.getPendingChanges());
    }
  }

  readOnlyMode = false;

  form: UntypedFormGroup;
  initialValues: any;
  originalHeTypesUomConfig: HierarchyElementTypeTimeUnitsQueryDto[];
  hierarchyConfigs: HierarchyElementTypeTimeUnitsQueryDto[];
  telemetryConfigs: HierarchyElementTypeTimeUnitsQueryDto[];

  allUnitTypes: IUnitTypeDto[];
  unitTypes: IUnitTypeDto[];

  get componentName(): string {
    return 'UomConfigurationComponent';
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _objectHelperService: ObjectHelperService,
    private _globalsService: GlobalsService,
    private _uomConfigService: UomConfigurationService,
    private _spinnerService: SpinnerService,
    private _dialogService: DialogService,
    private _authorizeService: AuthorizeService,
    private _pendingChangesService: PendingChangesManagerService
  ) {}

  ngOnInit(): void {
    this.setLoading(true);

    const permission$ = this._authorizeService.canAccess(CrudConstants.UoM, 'u');
    const unitTypes$ = this._globalsService.getUnitTypes();
    const heTypesUomConfig$ = this._uomConfigService.getUomConfigurationsByParams(
      this.hierarchyFamily,
      this.dimension,
      this._timeAggregation
    );

    combineLatest([permission$, unitTypes$, heTypesUomConfig$])
      .pipe(untilDestroyed(this))
      .subscribe(([permission, unitTypes, uomConfig]) => {
        this.readOnlyMode = !permission;

        this.allUnitTypes = unitTypes;

        this.setUomConfigurations(uomConfig);
        this.setUnitTypesByDimension();
        this.createForm();

        this.setLoading(false);
      });
  }

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    this.setLoading(true);

    if (!this.form.valid) {
      return of(true);
    }

    const modelDiff = this.buildModelDiff();

    return this._uomConfigService.saveUomConfigurations(modelDiff).pipe(
      tap((result) => {
        if (!result) {
          this.displayMessage('common.messages.saved-error', 'error');
          this.setLoading(false);
          return;
        }

        this._dialogService.showEntityActionSnackBar('save', 'configuration');
        this.setLoading(false);

        this.loadHeTypesUoMConfig();
      }),
      catchError((error) => {
        this.displayMessage('common.messages.saved-error', 'error');
        this.setLoading(false);

        return of(null);
      }),
      map((_) => true)
    );
  }

  isFormButtonDisabled() {
    return !(this.configurationHasChanged && this.form.valid);
  }

  discardChanges(): void {
    this.form.reset(this.initialValues);
  }

  onApplyBulkConfig(bulkConfig: { hierarchyType: string; unitType: number }) {
    const { hierarchyType, unitType } = bulkConfig;

    if (!hierarchyType || !unitType) {
      return;
    }

    let uomConfigIds = [];

    switch (hierarchyType) {
      case UomHierarchyBulkOptions.Hierarchy: {
        uomConfigIds = this.hierarchyConfigs.map((h) => h.hierarchyElementTypeTimeUnitsId);
        break;
      }
      case UomHierarchyBulkOptions.Telemetry: {
        uomConfigIds = this.telemetryConfigs.map((h) => h.hierarchyElementTypeTimeUnitsId);
        break;
      }
      case UomHierarchyBulkOptions.All:
        uomConfigIds = this.originalHeTypesUomConfig.map((h) => h.hierarchyElementTypeTimeUnitsId);
        break;
      default: {
        break;
      }
    }

    this.applyUnitTypes(uomConfigIds, unitType);
  }

  private applyUnitTypes(uomConfigIds: number[], unitTypeId: number) {
    uomConfigIds.forEach((id) => this.form.controls[id]?.setValue(unitTypeId));
  }

  private createForm() {
    const controls: { [key: string]: UntypedFormControl } = {};

    this.originalHeTypesUomConfig.forEach((heTypeConfig) => {
      controls[heTypeConfig.hierarchyElementTypeTimeUnitsId] = new UntypedFormControl(
        { value: heTypeConfig.unitTypeId, disabled: this.readOnlyMode },
        [Validators.required]
      );
    });

    this.form = this._formBuilder.group(controls);
    this.initialValues = this.form.value;

    this.form.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe(() => this.onFormValueChanged());
  }

  private onFormValueChanged() {
    this.configurationHasChanged = !this._objectHelperService.deepEqual(
      this.form.value,
      this.initialValues
    );
  }

  private setUnitTypesByDimension() {
    if (this.allUnitTypes) {
      this.unitTypes = this.allUnitTypes.filter((ut) => ut.dimensionTypeId === this.dimension);
    }
  }

  private setUomConfigurations(configurations: HierarchyElementTypeTimeUnitsQueryDto[]) {
    this.originalHeTypesUomConfig = configurations;

    this.hierarchyConfigs = this.originalHeTypesUomConfig.filter((config) => !config.isTelemetry);
    this.telemetryConfigs = this.originalHeTypesUomConfig.filter((config) => config.isTelemetry);
  }

  private loadHeTypesUoMConfig() {
    this.setLoading(true);

    this._uomConfigService
      .getUomConfigurationsByParams(this.hierarchyFamily, this.dimension, this._timeAggregation)
      .pipe(untilDestroyed(this))
      .subscribe((uomConfig) => {
        this.setUomConfigurations(uomConfig);
        this.createForm();

        this.configurationHasChanged = false;
        this.setLoading(false);
      });
  }

  private buildModelDiff(): HierarchyElementTypeTimeUnitsQueryDto[] {
    let modelDiff: HierarchyElementTypeTimeUnitsQueryDto[] = [];

    Object.entries(this.form.value).forEach(([key, value]) => {
      const oldConfig = this.originalHeTypesUomConfig.filter(
        (config) => config.hierarchyElementTypeTimeUnitsId === +key
      )?.[0];

      if (oldConfig && oldConfig.unitTypeId !== +value) {
        let newConfig = this._objectHelperService.clone(oldConfig);
        newConfig.unitTypeId = +value;

        modelDiff.push(newConfig);
      }
    });

    return modelDiff;
  }

  private displayMessage(messageKey: string, icon: 'success' | 'error') {
    const dialogSettings = new WlmDialogSettings({ translateKey: messageKey });
    dialogSettings.icon = icon;

    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }

  private getPendingChanges(): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: !this.isFormButtonDisabled(),
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
    this.setLoading(false);
  }
}
