<wlm-popup-wrapper [showSpinner]="isLoading" [titleKey]="titleKey" (popupClose)="onClose($event)">
  <ng-container popup-top-icons>
    <div class="buttons-container">
      <button
        mat-raised-button
        class="button-position button"
        color="primary"
        *isSuperUser
        [matTooltip]="T_SCOPE + '.buttons.save-system' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!isValid || !hasPendingChanges"
        (click)="onSaveConfig(true)"
      >
        {{ T_SCOPE + '.buttons.save-system' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        class="button-position button"
        [matTooltip]="T_SCOPE + '.buttons.save' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!isValid || !hasPendingChanges"
        (click)="onSaveConfig(false)"
      >
        {{ T_SCOPE + '.buttons.save' | translate }}
      </button>
      <button
        mat-icon-button
        class="button-position button"
        color="primary"
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!hasPendingChanges"
        (click)="onDiscardChanges()"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>
  </ng-container>

  <ng-container popup-content style="height: 100%">
    <div class="popup-container">
      <div class="main-container">
        <div class="selector">
          <wlm-local-grid
            class="selector-grid grid"
            *ngIf="gridSettings && kpiSelectorData"
            [gridSettings]="gridSettings"
            [gridData]="kpiSelectorData"
            (selectedItemChanged)="getSelectedRow($event)"
          ></wlm-local-grid>
        </div>
        <div class="configurator">
          <div class="no-result" *ngIf="!selectedKpi && gridSettings && kpiSelectorData">
            <wlm-placeholder-overlay
              [titleKey]="noSelectionTitleKey"
              [subtitleKey]="noSelectionSubtitleKey"
            ></wlm-placeholder-overlay>
          </div>
          <wlm-map-thematic-configuration
            *ngIf="selectedKpi && elementTypes?.length"
            [elementTypes]="elementTypes"
            [kpi]="selectedKpi"
            [saveThematicConfiguration$]="saveConfiguration$"
            [discardThematicConfiguration$]="discardConfiguration$"
            (saveFinished)="onSaveFinished($event)"
            (isLoadingChanged)="onLoadingChanged($event)"
            (validityChanged)="onValidChanged($event)"
            (hasPendingChanges)="onHasPendingChanges($event)"
          ></wlm-map-thematic-configuration>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container popup-actions> </ng-container>
</wlm-popup-wrapper>
