<div class="grid-filter-container">
  <mat-form-field *ngIf="filterOperators" appearance="outline">
    <mat-select [(value)]="selectedOperator" (selectionChange)="operatorChange($event)">
      <mat-option
        *ngFor="let operator of filterOperators"
        [value]="operator.value"
        (click)="preventEvent($event)"
        >{{ operator.text }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <input
      type="number"
      matInput
      [(ngModel)]="selectedValue"
      [disabled]="numTextBoxDisabled"
      autocomplete="off"
    />
  </mat-form-field>
</div>
