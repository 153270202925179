import { GChartAxisLabel } from './g-chart-axis-label';
import { GChartAxisLine } from './g-chart-axis-line';
import { GChartAxisSplitLine } from './g-chart-axis-split-line';
import { GChartAxisTick } from './g-chart-axis-tick';
import { GChartTextStyles } from './g-chart-text-styles';

export class GChartAxis {
  id?: string;
  show = true;
  position: 'top' | 'bottom' | 'left' | 'right';

  name: string; // Already translated name.
  nameLocation: 'start' | 'middle' | 'center' | 'end' = 'end';
  nameTextStyle?: GChartTextStyles;

  // Type of the axis.
  // * 'value' Numerical axis, suitable for continuous data.
  // * 'category' Category axis, suitable for discrete category data. Category data can be auto retrieved from series.data or dataset.source, or can be specified via xAxis.data.
  // * 'time' Time axis, suitable for continuous time series data. As compared to value axis, it has a better formatting for time and a different tick calculation method. For example, it decides to use month, week, day or hour for tick based on the range of span.
  // * 'log' Log axis, suitable for log data.
  type: 'value' | 'category' | 'time' | 'log' = 'value';

  offset: number;

  splitNumber?: number; // Number of segments that the axis is split into.
  splitLine = new GChartAxisSplitLine();

  // Refer to https://echarts.apache.org/en/option.html#xAxis.min for expected behavior.
  min: number | string | Function | Date;
  max: number | string | Function | Date;

  axisLabel = new GChartAxisLabel();
  axisLine = new GChartAxisLine();
  axisTick = new GChartAxisTick();

  axisWLMUnitTypeId: number;
  axisWLMDimensionTypeId: number;
  boundaryGap: boolean;

  data: any[];
  scale?: boolean;

  interval?: number | Date; //Value of the interval. Don't confuse with number of ticks

  constructor(init?: Partial<GChartAxis>) {
    Object.assign(this, init);
  }
}
