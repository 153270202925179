<ng-template #template let-anchor>
  <span
    >{{ anchor.nativeElement.getAttribute('pointTooltip') }}<br />
    {{ anchor.nativeElement.getAttribute('minTooltip') }}<br />
    {{ anchor.nativeElement.getAttribute('maxTooltip') }}
  </span>
</ng-template>
<div
  kendoTooltip
  [tooltipTemplate]="template"
  tooltipClass="kendo-tooltip"
  filter="div"
  class="container"
  *ngIf="chartReady && pointValue !== null"
>
  <div class="min-legend"></div>

  <div
    [attr.pointTooltip]="pointTooltip"
    [attr.minTooltip]="minTooltip"
    [attr.maxTooltip]="maxTooltip"
    class="range"
    [ngStyle]="{ background: rangeStyle }"
  ></div>
  <div class="max-legend"></div>
</div>
