import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppModules } from '../../shared/app-modules.enum';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { TimeControlBarDto } from '../model/time-control-bar.dto';

const COMPONENT_SELECTOR = 'wlm-time-control-bar';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './time-control-bar.component.html',
  styleUrls: ['./time-control-bar.component.scss'],
})
export class TimeControlBarComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;

  form: FormGroup;

  baseGridDateFieldName = 'baseGridDate';
  compareDateFieldName = 'compareDate';

  maxDate: Date;

  @Input() dates: TimeControlBarDto;

  @Output() datesChange = new EventEmitter<TimeControlBarDto>();
  @Output() isInThePastChange = new EventEmitter<boolean>();

  constructor(
    private readonly _dateHelperService: DateHelperService,
    private readonly _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildDateLimits();
    this.createForm();
  }

  onPreviousDay = () => this.addDaysToBaseGridDate(-1);

  onNextDay = () => this.addDaysToBaseGridDate(1);

  isNextDayBtnDisabled(): boolean {
    return this.form?.get(this.baseGridDateFieldName)?.value >= this.maxDate;
  }

  onLastDay() {
    this.form.get(this.baseGridDateFieldName).setValue(this.maxDate);
  }

  onSwitchDates() {
    const baseGridDateCtrl = this.form.get(this.baseGridDateFieldName);
    const compareDateCtrl = this.form.get(this.compareDateFieldName);

    const newBaseDate = compareDateCtrl.value;
    const newCompareDate = baseGridDateCtrl.value;

    const formValue = new TimeControlBarDto({
      [this.baseGridDateFieldName]: newBaseDate,
      [this.compareDateFieldName]: newCompareDate,
    });

    this.form.setValue(formValue);
  }

  onCleanDate() {
    this.form.get(this.compareDateFieldName).setValue(null);
  }

  private buildDateLimits() {
    const maxDate = this._dateHelperService.addDays(new Date(), -1);
    this.maxDate = this._dateHelperService.truncateDate(maxDate);
  }

  private createForm() {
    const formControls: { [key: string]: FormControl } = {};

    const baseDate = this.dates?.baseGridDate ? new Date(this.dates?.baseGridDate) : this.maxDate;
    formControls[this.baseGridDateFieldName] = new FormControl(baseDate, [Validators.required]);

    const compareDate = this.dates?.compareDate ? new Date(this.dates?.compareDate) : null;
    formControls[this.compareDateFieldName] = new FormControl(compareDate);

    this.form = this._formBuilder.group(formControls);

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onFormValueChanges(data));

    this.form.updateValueAndValidity();
  }

  private onFormValueChanges(data: TimeControlBarDto) {
    if (this.form.valid) {
      this.datesChange.emit(data);
      const isInThePast = this.checkIsInThePast(data);
      this.isInThePastChange.next(isInThePast);
    }
  }

  private checkIsInThePast(data: TimeControlBarDto): boolean {
    const currentGridDate = this._dateHelperService.getDateNoTime(data.baseGridDate);
    const maxDate = this._dateHelperService.getDateNoTime(this.maxDate);
    return +currentGridDate !== +maxDate;
  }

  private addDaysToBaseGridDate(days: number) {
    const baseGridDateCtrl = this.form.get(this.baseGridDateFieldName);

    const newDate = this._dateHelperService.addDays(new Date(baseGridDateCtrl.value), days);
    baseGridDateCtrl.setValue(newDate);
  }
}
