import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { IconModule } from 'src/app/common-modules/shared/icon/icon.module';
import { MaterialSharedModule } from 'src/app/common-modules/shared/material-shared.module';
import { ThemeTestButtonsComponent } from './theme-test-buttons/theme-test-buttons.component';
import { ThemeTestPageComponent } from './theme-test-page/theme-test-page.component';

const routes: Routes = [
  {
    path: '',
    component: ThemeTestPageComponent,
  },
];

@NgModule({
  declarations: [ThemeTestPageComponent, ThemeTestButtonsComponent],
  imports: [
    CommonModule,
    MaterialSharedModule,
    MatTabsModule,
    IconModule,
    RouterModule.forChild(routes),
  ],
})
export class ThemeTestModule {}
