import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BiFilters } from 'src/app/common-modules/dependencies/bi/bi-filters';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DefaultParamsState } from 'src/app/common-modules/dynamic-layout/state/default-params/default-params-state';
import { ResetDefaultParamsAction } from 'src/app/common-modules/dynamic-layout/state/default-params/default-params.actions';
import { AppliedDefaultParamsSelector } from 'src/app/common-modules/dynamic-layout/state/default-params/default-params.selectors';
import { AppliedExtendedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ExtendedFilters } from 'src/app/common-modules/shared/filters/component-filters/extended-filters';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WlmResizeObserverService } from 'src/app/common-modules/shared/services/resize-observer.service';
import { BaseMapComponent } from 'src/app/water-loss/features/map/base-map/base-map.component';
import { MapHelperService } from 'src/app/water-loss/features/map/map-helper.service';
import { MapParameters } from 'src/app/water-loss/features/map/map-parameters';
import { BaseBIDynamicWidgetComponent } from '../../../models/base-bi-dynamic-widget';
import { BiService } from '../../../services/bi.service';

const COMPONENT_SELECTOR = 'map-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss'],
})
export class MapWidgetComponent extends BaseBIDynamicWidgetComponent implements OnInit {
  @ViewChild('map') set setMapComponent(map: BaseMapComponent) {
    this._map = map;
    if (map) {
      this._activeStatusSubs?.unsubscribe();
      this._activeStatusSubs = this.activeStatus$
        .pipe(untilDestroyed(this))
        .subscribe((isActive) => {
          if (isActive) {
            map.resize();
          }
        });
    }
  }

  @ViewChild('mapContainer') set setmapContainer(mapContainer: ElementRef<any>) {
    if (mapContainer) {
      this._resizeContainerSubs?.unsubscribe();
      this._resizeContainerSubs = this._resizeObserverService
        .observe({
          el: mapContainer.nativeElement,
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._map?.resize();
        });
    }
  }

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  filters: BiFilters;

  biPageFilters: BiFilters;
  biDefaultFilters: BiFilters;

  mapParams: MapParameters;

  private _map: BaseMapComponent;
  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyWidgetArea}_MapFilter`;
  private _activeStatusSubs: Subscription;
  private _resizeContainerSubs: Subscription;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _biService: BiService,
    private _mapHelperService: MapHelperService,
    private _resizeObserverService: WlmResizeObserverService
  ) {
    super(injector, widgetSettings);

    this.setTitle(widgetSettings);
  }

  onWidgetInit(): void {
    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        map((pageFilters) => pageFilters?.model as BiFilters),
        map((pageBiFilters: BiFilters) => {
          if (!pageBiFilters) {
            return null;
          }
          return pageBiFilters;
        })
      )
      .subscribe({
        next: (biFilters: BiFilters) => {
          if (!biFilters) {
            return;
          }

          this.filters = globalUtilsHelper.clone(biFilters, true);

          this.setMapParams();
        },
      });

    this._state
      .select<DefaultParamsState>(new AppliedDefaultParamsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          // TODO: the widget key check should be outside this class
          if (!state || state.widgetInstanceKey !== this.getWidgetInstanceKey()) {
            return;
          }

          this.itemSettings.defaultFilters = state.filters.model;
          this.itemSettings.lockFilters = state.lockFilters;
          this.itemSettings.params = state.params;
          this.setTitle(state);

          this.biDefaultFilters = state.filters.model as BiFilters;

          this.filters = this._biService.applyFilterLocks(
            this.biDefaultFilters,
            this.biPageFilters,
            state.lockFilters
          );

          //Uncomment this when working in WB Builder story
          this.setMapParams();

          // This must be here because only the WB widget knows how to serialize its filters.
          this.updateItemSettings({
            params: null,
            defaultFilters: this.biDefaultFilters,
            lockFilters: state.lockFilters,
            widgetInstanceKey: this.getWidgetInstanceKey(),
            title: state.title,
          });

          // This is to avoid loading the same params again.
          this._state.dispatch(new ResetDefaultParamsAction(this._scopeSettings));
        },
      });
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: false, // in map widgets we do not want to load from bd
    });

    return mapParameters;
  }

  setMapParams() {
    const mapParams = this.initializeMapParameters();

    this._mapHelperService
      .getHeLevelLayersByIds(this.filters.selectedIds)
      .subscribe((gisLayers) => {
        mapParams.hierarchyElements = this.filters.selectedIds;
        mapParams.visibleLayersIds = [
          ...new Set([
            ...mapParams.visibleLayersIds.filter((x) => x !== null && x !== undefined),
            ...gisLayers,
          ]),
        ];
        this.mapParams = mapParams;
      });
  }
}
