import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractUserNames',
})
export class ExtractUserNamesPipe implements PipeTransform {
  transform(text: string): string {
    return text;
  }
}
