export class GChartItemStyle {
  color?: string;
  borderColor?: string;
  borderWidth?: number;
  borderType?: 'solid' | 'dashed' | 'dotted';

  shadowColor?: string;
  shadowOffsetX?: number;
  shadowOffsetY?: number;
  shadowBlur?: number;

  constructor(init?: Readonly<GChartItemStyle>) {
    Object.assign(this, init);
  }
}
