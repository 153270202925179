import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription, forkJoin, of, take } from 'rxjs';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { FlatTreeNode } from 'src/app/common-modules/shared/tree/flat-tree-node';
import { DIMENSION_ITEMS } from 'src/app/common-modules/uom/models/dimension-items';

import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { DvAlgorithmsSelectorItemSettings } from '../../models/dv-algorithms-selector-item-settings';
import { DvAlgorithmsSelectorSettings } from '../../models/dv-algorithms-selector-settings';

import { UntilDestroy } from '@ngneat/until-destroy';
import { FiltrableItemMapperService } from '../../../shared/services/filtrable-item-mapper.service';
import { WorkspacesHelperService } from '../../../shared/services/workspaces-helper.service';
const COMPONENT_SELECTOR = 'wlm-data-visualization-algorithms-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-algorithms-selector.component.html',
  styleUrls: ['./data-visualization-algorithms-selector.component.scss'],
})
export class DataVisualizationAlgorithmsSelectorComponent implements OnInit {
  @Input() settings: DvAlgorithmsSelectorSettings;
  @Output() algorithmsSelected = new EventEmitter<AlgorithmFiltrableItemDto[]>();

  treeNodes: FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>[];
  filterText: string;
  expandedKeys: string[] = [];
  expandedKeysHash: { [key: string]: true } = {};

  private mapToTreeNodesSubs: Subscription;
  private _dimensions = DIMENSION_ITEMS;

  constructor(
    private _dialogService: DialogService,
    private _filtrableItemMapper: FiltrableItemMapperService,
    private _workspaceHelperService: WorkspacesHelperService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.resetSubscription(this.mapToTreeNodesSubs);
      this.mapToTreeNodesSubs = this.mapToTreeNodes(this.settings).subscribe((nodes) => {
        this.treeNodes = nodes;
      });
    }
  }

  onAlgorithmSelected(item: DvAlgorithmsSelectorItemSettings): void {
    const selected = this.settings?.algorithms?.find((a) => a.algorithmId === item.id);

    if (selected) {
      this.openAddAlgorithmPopup(selected);
    }
  }

  onGroupToggle(groupName: string): void {
    if (this.expandedKeys.find((key) => key === groupName)) {
      this.expandedKeys = this.expandedKeys.filter((key) => key !== groupName);
    } else {
      this.expandedKeys = this.expandedKeys.concat([groupName]);
    }

    this.expandedKeysHash = this.expandedKeys.reduce((accum, current) => {
      accum[current] = true;
      return accum;
    }, {});
  }

  private mapToTreeNodes({
    items,
    groups,
  }: DvAlgorithmsSelectorSettings): Observable<
    FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>[]
  > {
    const nodes = [];
    const currentGroups = new Map(groups);

    items?.forEach((item) => {
      const leafNode = new FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>({
        id: globalUtilsHelper.generateGuid(),
        group: item.group,
        label: item.label,
        label2: item.family,
        label3: item.timeAggregation,
        data: item,
        isLeaf: true,
        icon: this._dimensions.find((f) => f.id === item.dimensionId)?.iconName,
      });

      nodes.push(leafNode);

      // If a group is mentioned but it does not exist in the groups config, add it to the set of declared groups.
      if (item.group && !currentGroups.has(item.group)) {
        const groupNode = new FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>({
          id: item.group,
          label: item.group,
          isLeaf: false,
        });

        nodes.push(groupNode);
        currentGroups.set(item.group, groupNode);
      }
    });

    groups.forEach((groupNode) => {
      nodes.push(groupNode);
    });

    return of(nodes);
  }

  private resetSubscription(subs: Subscription): void {
    if (subs && !subs.closed) {
      subs.unsubscribe();
    }
  }

  private openAddAlgorithmPopup(algorithm: IAlgorithmDto): void {
    const popupRef = this._workspaceHelperService.getAlgorithmElementsSelectionPopup(algorithm);

    popupRef?.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      const { algorithm, elements } = result;
      if (algorithm && elements?.length) {
        const filtrableItems$: Observable<AlgorithmFiltrableItemDto>[] = elements.map((e) =>
          this._filtrableItemMapper.getAlgorithmFiltrableItemDto(algorithm, e)
        );

        forkJoin(filtrableItems$)
          .pipe(take(1))
          .subscribe((filtrableItems) => this.algorithmsSelected.emit(filtrableItems));
      }
    });
  }
}
