<div class="spinner-container" *ngIf="canShowSpinner$ | async">
  <div
    class="k-i-loading k-loading-image"
    [ngClass]="{ 'relative-mode': mode === 'relative', 'absolute-mode': mode === 'absolute' }"
  ></div>
  <div class="spinner-additional-content">
    <div class="spinner-message" *ngIf="message && message !== ''">
      {{ message }}
    </div>
    <div class="spinner-close-button" *ngIf="showCloseButton">
      <button mat-icon-button (click)="onCloseSpinner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
