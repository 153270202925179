<div
  *ngIf="filtrableLayers && yearsSelection"
  [ngStyle]="{ display: displayed ? '' : 'none' }"
  class="content-stretch custom-map-filters"
>
  <div class="form-container wtr-checkbox-lg wtr-radio-lg">
    <mat-dialog-content>
      <div class="main-container">
        <!-- Type of View -->
        <div *ngIf="baseTypes && baseTypes.length > 0">
          <div class="filter-item-title">
            {{ T_SCOPE + '.view-types' | translate | uppercase }}
          </div>
          <div>
            <mat-radio-group [labelPosition]="'before'">
              <span class="item radio-list" *ngFor="let layer of baseTypes">
                <mat-radio-button
                  [value]="layer"
                  [checked]="layer === baseType"
                  (change)="onSelectBaseLayer($event)"
                >
                  {{ T_SCOPE + '.' + layer | translate }}
                </mat-radio-button>
              </span>
            </mat-radio-group>
          </div>

          <hr class="title-separator" />
        </div>

        <!-- Hierarchy Elements -->
        <wlm-map-filter-item-select
          [displayed]="displayed"
          [title]="T_SCOPE + '.he-items' | translate | uppercase"
          [layers]="filtrableLayers.hierarchyElements"
          [showIcon]="false"
          [selectedLayers]="layersSelection"
          (select)="onSelectNetworkElement($event)"
        ></wlm-map-filter-item-select>

        <hr class="title-separator" />

        <!-- Network Elements -->
        <wlm-map-filter-item-select
          [displayed]="displayed"
          [title]="T_SCOPE + '.ne-items' | translate | uppercase"
          [layers]="filtrableLayers.networkElements"
          [showIcon]="true"
          [selectedLayers]="layersSelection"
          (select)="onSelectNetworkElement($event)"
        ></wlm-map-filter-item-select>

        <hr class="title-separator" />

        <!-- Leak and Activity Years -->
        <div>
          <div class="filter-item-title">
            {{ T_SCOPE + '.leak-years' | translate | uppercase }}
          </div>
          <div
            class="item nested-checkbox-radio-list"
            *ngFor="let leakYear of filtrableLayers.leakYears"
          >
            <mat-checkbox
              [checked]="yearsSelection[leakYear.year]"
              (change)="onSelectYear($event, leakYear.year)"
              [labelPosition]="'before'"
            >
              {{ leakYear.description }}
            </mat-checkbox>
          </div>
        </div>

        <hr class="title-separator" />

        <!-- Activities -->
        <wlm-map-filter-item-select
          [displayed]="displayed"
          [title]="T_SCOPE + '.activity-items' | translate | uppercase"
          [layers]="filtrableLayers.activities"
          [showIcon]="true"
          [selectedLayers]="layersSelection"
          (select)="onSelectActivity($event)"
        ></wlm-map-filter-item-select>

        <hr class="title-separator" />

        <!-- Open Leaks -->
        <wlm-map-filter-item-select
          [displayed]="displayed"
          [title]="T_SCOPE + '.open-leak-items' | translate | uppercase"
          [layers]="filtrableLayers.outstandingLeaks"
          [showIcon]="true"
          [selectedLayers]="layersSelection"
          (select)="onSelectLeak($event)"
        ></wlm-map-filter-item-select>

        <hr class="title-separator" />

        <div>
          <div class="filter-item-title">
            {{ T_SCOPE + '.closed-leak-items' | translate | uppercase }}
          </div>
          <!-- Closed Leaks -->
          <wlm-map-filter-item-select
            [displayed]="displayed"
            [layers]="filtrableLayers.closedLeaks"
            [showIcon]="true"
            [selectedLayers]="layersSelection"
            (select)="onSelectLeak($event)"
          ></wlm-map-filter-item-select>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
