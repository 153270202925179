export enum PeriodTypesEnum {
  week = 1,
  month = 2,
  year = 3,
  twoYears = 4,
  threeYears = 5,
  customFromNavigation = 6,
  customFromDateRange = 7,
  financialYear = 8,
  threeMonths = 9,
}
