import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { DataVisualizationManageWorkspaceResult } from './data-visualization-manage-workspace-result';

export class DataVisualizationWorkspaceNavigation {
  workspace: DataVisualizationManageWorkspaceResult;
  filtrableItems: IFiltrableItemDto[];
  dateRange: DateRange;

  constructor(init: Partial<DataVisualizationWorkspaceNavigation>) {
    Object.assign(this, init);
  }
}
