import { Directive, ElementRef, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import {
  WlmResizeObserverConfig,
  WlmResizeObserverData,
  WlmResizeObserverService,
} from '../shared/services/resize-observer.service';
import { DynamicLayoutItemSettings } from './models/dynamic-layout-item-settings';

@UntilDestroy()
@Directive()
export abstract class BaseDynamicLayoutHeaderComponent {
  injected$ = new ReplaySubject<void>(1);
  widgetInstanceKey: string;
  getItemSetting: () => DynamicLayoutItemSettings;
  // This is necessary to be emitted for every tab header component, because if we change
  // the widths of the tabs here, the automatic dropdown of widgets may not toggle and a headers overflow may happen.
  notifyWidthChanges: () => void;
  private _currentWidth = 0;
  readonly hasManagedWidth = true;

  private readonly _resizeObserver = inject(WlmResizeObserverService);
  private readonly _element = inject(ElementRef);

  constructor() {
    this.observeWidthChanges();
  }

  private observeWidthChanges(): void {
    this._resizeObserver
      .observe(
        new WlmResizeObserverConfig({
          el: this._element.nativeElement,
          calculateWidth: true,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((data: WlmResizeObserverData) => {
        if (data.width && data.width !== this._currentWidth) {
          this._currentWidth = data.width;
          if (this.notifyWidthChanges) {
            this.notifyWidthChanges();
          }
        }
      });
  }
}
