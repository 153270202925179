export class HierarchyElementPressureSignalDto {
  hierarchyElementId: string;
  signalId: number;
  pointId: string;
  pointDescription: string;
  offset?: number;
  offsetCoordinates?: string;
  pressureTypeId: number;
  title: string;

  constructor(init: Readonly<HierarchyElementPressureSignalDto>) {
    Object.assign(this, init);
  }
}
