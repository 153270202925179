<kendo-grid-column
  [field]="field"
  [width]="width"
  [filterInput]="true"
  [format]="format"
  [headerStyle]="headerStyle"
  [style]="style"
  [locked]="locked"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <div kendoTooltip tooltipClass="kendo-tooltip">
        <wlm-column-header-template
          [columnTitle]="column"
          [titleSuffix]="currentUnitLabel"
        ></wlm-column-header-template>
      </div>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem="dataItem">
    <span class="column-cell">
      <wlm-uom-column-helper
        *ngIf="loaded"
        [wlmEstimatedEditedCellMark]="dataItem[columnSettings?.estimatedEditedField]"
        [hierarchyElementTypeId]="hierarchyElementTypeId"
        [hierarchyElementTypeIdField]="hierarchyElementTypeIdField"
        [baseTitle]="baseTitle"
        [unitFormat]="unitFormat"
        [timeAggregationId]="timeAggregationId"
        [dimensionTypeId]="dimensionTypeId"
        [decimalPositions]="decimalPositions"
        [comparisonIsActive]="comparisonIsActive"
        [comparisonField]="comparisonField"
        [dataItem]="dataItem"
        (uomUnitCalculated)="calculatedUnitChange($event)"
        (conversionFactorChange)="onConversionFactorChange($event)"
      ></wlm-uom-column-helper>
      &nbsp;
    </span>
  </ng-template>
  <ng-template
    kendoGridEditTemplate
    let-column="column"
    let-formGroup="formGroup"
    let-isNew="isNew"
    let-dataItem="dataItem"
  >
    <wlm-uom-edit-template
      [conversionFactor]="conversionFactor"
      [field]="column.field"
      [rowIdField]="gridSettings.selectByFieldName"
      [formGroup]="formGroup"
      [dataItem]="dataItem"
      [gridEditionService]="gridEditionService"
      (valueChange)="onEditionChange($event)"
    ></wlm-uom-edit-template>
  </ng-template>
  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-uom-column-filter
      [isPrimitive]="false"
      [field]="column.field"
      [currentFilter]="filter"
      [filterService]="filterService"
      [conversionFactor]="conversionFactor"
    ></wlm-uom-column-filter>
  </ng-template>
</kendo-grid-column>
