import { IActivitiesRaiseCmd } from '../shared/model/alc/activities-raise-cmd';
import { ActivityRaiseType } from '../shared/model/alc/activity-raise-type';

export class ActivitiesRaiseCmd implements IActivitiesRaiseCmd {
  type: ActivityRaiseType;
  date: Date;
  activityTypeId: number;
  activityTypeName: string;
  attributes: { [key: string]: string };
  userName: string;
  hierarchyElementIds: { [key: string]: string };
  propertyId: string;
  constructor(
    type: ActivityRaiseType,
    date: Date,
    activityTypeId: number,
    activityTypeName: string,
    attributes: { [key: string]: string },
    userName: string,
    hierarchyElementIds: { [key: string]: string },
    propertyId: string
  ) {
    this.type = type;
    this.date = date;
    this.activityTypeId = activityTypeId;
    this.activityTypeName = activityTypeName;
    this.attributes = attributes;
    this.userName = userName;
    this.hierarchyElementIds = hierarchyElementIds;
    this.propertyId = propertyId;
  }
}
