import { BaseGenericCartesianChartSettings } from '../generic-chart-settings/base-generic-cartesian-chart-settings';
import { GChartGrid } from '../generic-chart-settings/g-chart-grid';
import { GSchematicChartSerie } from './g-schematic-chart-serie';

export class GenericSchematicChartSettings extends BaseGenericCartesianChartSettings {
  type: any = 'graph';
  series: GSchematicChartSerie[];
  title?: string;
  bottom?: string;
  grid?: GChartGrid;

  constructor(init: Partial<GenericSchematicChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
