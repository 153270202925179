export interface ISerieDataPointDto {
  key: string;
  referenceDateTime: Date | string;
  value: number;
  estimated: boolean;
  edited: boolean;
  validity: number;
  adjusted: boolean;
}

export class SerieDataPointDto implements ISerieDataPointDto {
  key: string;
  referenceDateTime: Date | string;
  value: number;
  estimated: boolean;
  edited: boolean;
  validity: number;
  adjusted: boolean;

  constructor(
    key: string,
    referenceDateTime: Date | string,
    value: number,
    estimated: boolean,
    edited: boolean,
    validity: number,
    adjusted: boolean
  ) {
    this.key = key;
    this.referenceDateTime = referenceDateTime;
    this.value = value;
    this.estimated = estimated;
    this.edited = edited;
    this.validity = validity;
    this.adjusted = adjusted;
  }
}
