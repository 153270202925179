import { EnvelopesConfiguration } from 'src/app/common-modules/dependencies/alarms/envelopes-configuration';

export class EnvelopesConfigurationForm extends EnvelopesConfiguration {
  hiHiPersistencyTemporal: number;
  hiPersistencyTemporal: number;
  loPersistencyTemporal: number;
  loLoPersistencyTemporal: number;

  hiHiPersistencyFactor: number;
  hiPersistencyFactor: number;
  lowPersistencyFactor: number;
  lowLowPersistencyFactor: number;

  constructor(init?: Partial<EnvelopesConfigurationForm>) {
    super(init);
  }
}
