import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { Subject } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { networkElementTypesNEConfigurationSet } from 'src/app/common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { ElementTypeFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { HierarchyElementPathFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { IsZoneFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/is-zone-f-adapter';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { ElementTargetFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { HierarchyTreeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { NeHeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from 'src/app/common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from 'src/app/common-modules/shared/component/base-page-filters.component';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { ElementTargetsEnum } from 'src/app/common-modules/shared/model/shared/element-targets.enum';
import { PersistencyService } from 'src/app/common-modules/shared/persistency.service';
import { TreeSettingsService } from 'src/app/common-modules/shared/services/tree-settings.service';
import { INeHeFilterSettings } from '../ne-type-filter/nehe-filter-settings';
import { NEConfigFilterConfiguration } from './ne-type-tree-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-type-tree-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-type-tree-filter.component.html',
  styleUrls: ['./ne-type-tree-filter.component.scss'],
})
export class NeTypeTreeFilterComponent
  extends BasePageFiltersComponent
  implements OnInit, AfterViewInit
{
  private _neConfigFilterConfiguration: NEConfigFilterConfiguration;
  public get filterConfiguration(): NEConfigFilterConfiguration {
    return this._neConfigFilterConfiguration;
  }
  @Input() public set filterConfiguration(value: NEConfigFilterConfiguration) {
    this._neConfigFilterConfiguration = value;
    if (value) {
      this.buildKeysToComplete();
      this.setFiltersPersistencyArea();
      this.familyIdSelected = value.initialFamilyId;
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      if (v.navigationParams) {
        this.loadNavigationParams(v.navigationParams);
      }
      //this.loadPeristence();
      this.setFilterConfiguration();
    }
  }

  selectedHierarchyElements: string[] = [];
  selectedHierarchyFamilyId: string;
  selectedHETypeIds: any[] = [];
  selectedNETypeIds: any[] = [];
  selectedTarget: number[] = [];

  private _hierarchyElementTypesLevelList: IHierarchyElementTypeDto[];
  private _disabledhierarchyElementTypes: string[] = [];

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  loaded: boolean;
  familyIdSelected: string;

  neTypeFilterId = FilterAdapterEnum.NetworkElementTypeFilter;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  targetGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-target-label`,
  };

  hierarchyElementGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-he-label`,
  };

  networkElementsGroupSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    isRequired: true,
    inputLabelKey: `${this.T_SCOPE}.filters-group-ne-label`,
  });

  treeGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-tree-label`,
  };

  targetItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  familyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
  });

  hierarchyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  networkFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    id: this.neTypeFilterId,
  });
  filterListElements = networkElementTypesNEConfigurationSet;

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  get componentName(): string {
    return 'NeTypeTreeFilterComponent';
  }

  isHEFilterSelected = false;
  wasHEFilterSelectedPreviously = true;
  oldHELevelSelection: string[];
  oldFamilyIdSelected: string;

  clearHEFilter$ = new Subject<void>();
  rebuildTreeFilter$ = new Subject<void>();
  treeSettings: ITreeSettings = null;
  alternativeAdapter = FilterAdapterEnum.isZoneSelected;

  constructor(
    private _treeSettingsService: TreeSettingsService,
    private _arrayHelperService: ArrayHelperService,
    private _objectHelper: ObjectHelperService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngAfterViewInit(): void {
    this.isHEFilterSelected = true;
  }

  protected setFiltersPersistencyArea() {
    this.targetGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
    this.hierarchyElementGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
    this.networkElementsGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
    this.treeGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  protected additionalAutoloadCheck(results: FilterAdapterResult): boolean {
    return true;
  }

  ngOnInit(): void {
    this.buildTreeSettings();
    this.loaded = true;
  }

  buildTreeSettings(): void {
    this._treeSettingsService
      .buildTreeSettings({
        unselectableTypes: this._disabledhierarchyElementTypes,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  init(): void {}

  provideAdapters = (): FilterAdapterSettings[] => {
    const { heSettings, neSettings, hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.filterConfiguration;

    const neheSelectionAdapter = new IsZoneFAdapter({
      dataBindingField: this.filterConfiguration.targetSettings.fieldName,
      neheSelectionFieldName: this.filterConfiguration.targetSettings.fieldName,
    });

    const neAdapter = new ElementTypeFAdapter({
      dataBindingField: this.filterConfiguration.neSettings.elementTypeFieldName,
      elementTypeFieldName: neSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: true,
        selectedIds: neSettings.selectedElementIds,
        fieldName: neSettings.elementTypeFieldName,
      }),
      tabDetailParameterName: TabDetailParameterName.networkElementTypeFilterSetting,
    });

    neAdapter.id = this.neTypeFilterId;

    const heAdapter = new ElementTypeFAdapter({
      dataBindingField: this.filterConfiguration.heSettings.elementTypeFieldName,
      elementTypeFieldName: heSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: false,
        selectedIds: heSettings.selectedElementIds,
        fieldName: heSettings.elementTypeFieldName,
      }),
    });

    return [
      new HierarchyElementPathFAdapter({
        dataBindingField: hierarchyElementIdFieldName,
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId:
          this.filterConfiguration.initialFamilyId ?? this.filterConfiguration.defaultFamilyId,
      }),
      neAdapter,
      heAdapter,
      neheSelectionAdapter,
    ];
  };

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  /**
   * Only used to save the selected family id.
   * Will allways contain an array of one element, as it is single select.
   */
  onTargetSelect(filters: FiltersPayload): void {
    const selected = filters.getSelectedIds(this.filterConfiguration.targetSettings.fieldName)[0];
    if (selected) {
      this.isHEFilterSelected = selected === ElementTargetsEnum.HierarchyElements;

      if (this.isHEFilterSelected !== this.wasHEFilterSelectedPreviously) {
        this.clearHEFilter$.next();
        this.wasHEFilterSelectedPreviously = this.isHEFilterSelected;
        this.rebuildTree(this.filterConfiguration.defaultHETypeIds);
      }
    }
  }

  onTargetClearAll() {
    this.clearHEFilter$.next();
    this.rebuildTree(this.filterConfiguration.defaultHETypeIds);
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    this.familyIdSelected = filters.getSelectedIds(
      this.filterConfiguration.hierarchyElementFamilyFieldName
    )[0] as string;
  }

  onhierarchyLevelsListChanged(hierarchyLevelList: IHierarchyElementTypeDto[]) {
    this._hierarchyElementTypesLevelList = hierarchyLevelList;
  }

  onApplyHEFilter(filters: FiltersPayload) {
    const selectedElements = filters.getSelectedIds(
      this.filterConfiguration.heSettings.elementTypeFieldName
    ) as string[];

    this.oldHELevelSelection =
      this.oldHELevelSelection ?? this.filterConfiguration.defaultHETypeIds;

    if (!this._arrayHelperService.areSame(selectedElements, this.oldHELevelSelection)) {
      this.oldHELevelSelection = [...selectedElements];
      this.rebuildTree(selectedElements);
    }
  }

  private rebuildTree(selectedElementTypes: string[]): void {
    this.calculateDisabledNodes(selectedElementTypes);
    this.buildTreeSettings();
    this.rebuildTreeFilter$.next();
  }

  calculateDisabledNodes(selectedElements: any[]) {
    if (selectedElements?.length > 0) {
      const heIdList = this._hierarchyElementTypesLevelList.map((m) => m.hierarchyElementTypeId);
      let minLevel = heIdList.indexOf(selectedElements[0]);
      selectedElements.forEach((level) => {
        const currentLevel = heIdList.indexOf(level);
        if (currentLevel < minLevel) {
          minLevel = currentLevel;
        }
      });

      const index = minLevel;
      this._disabledhierarchyElementTypes = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeName)
        .slice(index + 1);
    }
  }

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.filterConfiguration;
    const targetFieldName = this.filterConfiguration.targetSettings.fieldName;

    this.keysToComplete = [
      targetFieldName,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    ];
  }

  // This is necessary to persist the tree values
  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  processFilterChanges(results: FilterAdapterResult) {
    const processedResult = this._objectHelper.clone(results);
    if (
      processedResult?.dbFilters?.filters?.has(this.filterConfiguration.targetSettings.fieldName)
    ) {
      const isZone = processedResult.dbFilters.filters
        .get(this.filterConfiguration.targetSettings.fieldName)
        .getSelectedFilters();
      const hasNEFilter = processedResult?.dbFilters?.filters?.has(
        this.filterConfiguration.neSettings.elementTypeFieldName
      );
      const hasHEFilter = processedResult?.dbFilters?.filters?.has(
        this.filterConfiguration.heSettings.elementTypeFieldName
      );

      if (isZone && hasNEFilter) {
        processedResult.dbFilters.filters.delete(
          this.filterConfiguration.neSettings.elementTypeFieldName
        );
      }

      if (!isZone && hasHEFilter) {
        processedResult.dbFilters.filters.delete(
          this.filterConfiguration.heSettings.elementTypeFieldName
        );
      }
    }

    this.onGlobalFilterResults(processedResult);
  }

  private loadPersistence() {
    const neTypes = this.getPersisted(
      `${this.settings.persistencyArea}-${this.settings.fields.networkElementType.fieldName}`,
      [],
      null,
      false
    );
    const heTypes = this.getPersisted(
      `${this.settings.persistencyArea}-${this.settings.fields.hierarchyElementType.fieldName}`,
      [],
      null,
      false
    );
  }

  private setFilterConfiguration() {
    const heSettings = new INeHeFilterSettings(
      false,
      this.selectedHETypeIds,
      this.settings.fields.hierarchyElementType.fieldName
    );

    const neSettings = new INeHeFilterSettings(
      true,
      [],
      this.settings.fields.networkElementType.fieldName
    );

    const targetSettings = new ElementTargetFISettings({
      fieldName: this.settings.fields.target.fieldName,
      selectedIds: this.selectedTarget,
      defaultValue: [ElementTargetsEnum.HierarchyElements],
      storageLocation: 'none',
    });

    const treeSettings = new HierarchyTreeFISettings({
      fieldName: this.settings.fields.hierarchyElementId.fieldName,
      selectedIds: this.selectedHierarchyElements,
    });

    const config = new NEConfigFilterConfiguration({
      persistencyArea: this.settings.persistencyArea,
      pagePersistencyArea: this.settings.persistencyArea,
      defaultFamilyId: this.selectedHierarchyFamilyId,
      defaultHETypeIds: this.selectedHETypeIds,
      defaultNETypeIds: this.selectedNETypeIds,
      targetSettings,
      heSettings,
      neSettings,
      treeSettings,
      hierarchyElementFamilyFieldName: this.settings.fields.hierarchyElementFamilyId.fieldName,
      hierarchyElementIdFieldName: this.settings.fields.hierarchyElementId.fieldName,
      initialFamilyId: '07cf5f7e-a084-4383-a73d-09eda922631a',
      initialHierarchyElementId: ['6828e991-8326-4e7e-b244-a026b67ac867'],
    });

    this.filterConfiguration = config;
  }

  private loadNavigationParams(queryParams: Params) {
    if (queryParams?.hierarchyElementIds) {
      this.selectedHierarchyElements = Array.isArray(queryParams.hierarchyElementIds)
        ? queryParams.hierarchyElementIds
        : [queryParams.hierarchyElementIds];
    }
    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;

    this.selectedHETypeIds = queryParams.heTypeIds;
    this.selectedNETypeIds = queryParams.neTypeFilterId;
    this.selectedTarget = queryParams.target;
  }
}
