import { Component, ViewChild, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, finalize, startWith, switchMap } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { IntegrableForm } from 'src/app/common-modules/shared/forms/integrable-form';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { IntegrationGisLayersFormComponent } from '../integration-gis-layers-form/integration-gis-layers-form.component';
import { IntegrationGisLayersService } from '../integration-gis-layers.service';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers-popup.component.html',
  styleUrls: ['./integration-gis-layers-popup.component.scss'],
})
export class IntegrationGisLayersPopupComponent {
  private _innerComponent: IntegrableForm;
  get innerComponent(): IntegrableForm {
    return this._innerComponent;
  }
  @ViewChild(IntegrationGisLayersFormComponent) set innerComponent(value: IntegrableForm) {
    this._innerComponent = value;
    if (this._innerComponent) {
      this._innerComponentBound$.next();
    }
  }

  private _innerComponentBound$ = new ReplaySubject<void>(1);
  private setLoading: (loading: boolean) => void;
  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;
  readonly titleKey = `${this.T_SCOPE}.title`;

  readonly hasChanges$ = this._innerComponentBound$.pipe(
    switchMap(() => this._innerComponent.hasChanges$),
    startWith(false)
  );

  readonly isValid$ = this._innerComponentBound$.pipe(
    switchMap(() => this._innerComponent.isValid$),
    startWith(false)
  );

  private readonly _integrationGisLayerService = inject(IntegrationGisLayersService);
  private readonly _dialogRef = inject(MatDialogRef<IntegrationGisLayersPopupComponent>);
  private readonly _dialogsService = inject(DialogService);
  private readonly _spinnerService = inject(SpinnerService);

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {}

  onSubmit(): void {
    const model = this._innerComponent.getModel();

    this.setLoading(true);
    this._integrationGisLayerService
      .upload(model)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: (result) => {
          this.showSuccess();
          this._dialogRef.close(result);
        },
        error: this._dialogsService.showErrorMessage,
      });
  }

  private showSuccess(): void {
    this._dialogsService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
  }

  onButtonClose(event): void {
    this._dialogRef.close();
  }
}
