import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseSelectorDynamicPage } from 'src/app/common-modules/dynamic-layout/layouts/base/base-selector-dynamic-page';
import { SelectorDynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/layouts/selector-dynamic-layout/selector-dynamic-layout-settings';
import { SelectorDynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/layouts/selector-dynamic-layout/selector-dynamic-layout.component';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SchematicImportPopupDimensions } from 'src/app/common-modules/shared/constants/eligibility.constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SchematicStateFields } from 'src/app/common-modules/wlm-charts/core/models/schematics/schematic-state-fields';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { SchematicBasic } from '../../../../common-modules/wlm-charts/core/models/schematics/schematic';
import { SchematicImportPopupComponent } from '../schematic-import-popup/schematic-import-popup.component';
import { SchematicsService } from '../services/schematics.service';
const COMPONENT_SELECTOR = 'wlm-schematics-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematics-page.component.html',
  styleUrls: ['./schematics-page.component.scss'],
})
export class SchematicsPageComponent
  extends BaseSelectorDynamicPage
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(SelectorDynamicLayoutComponent) layoutComponent: SelectorDynamicLayoutComponent;

  readonly T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;

  pageId: string = 'schematicsPage';
  selectedSchematic: SchematicBasic;
  schematicsMultiselection: SchematicBasic[];
  editMode = false;
  currentSchematicTitle: string;

  // Already configured service, obtained from the inner component.
  private _state: ReduxStateService;
  private setLoading: (isLoading: boolean) => void;

  constructor(
    private _dialogsService: DialogService,
    private _schematicsService: SchematicsService,
    private _spinnerService: SpinnerService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
    this.hasRightPanel = false;
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  onStateLoaded(state: ReduxStateService): void {
    this._state = state;

    this._state
      .select<boolean>(
        new GetValueSelector({
          fieldName: SchematicStateFields.editMode,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((editMode) => {
        this.editMode = editMode;
      });

    this._state
      .select<boolean>(
        new GetValueSelector({
          fieldName: SchematicStateFields.isGlobalLoading,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        this.setLoading(isLoading);
      });
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this.layoutComponent.onResetLayout();
    });
  }

  getSettings(): Observable<SelectorDynamicLayoutSettings> {
    const settings: SelectorDynamicLayoutSettings = {
      layoutKey: this.pageId,
      layoutArea: 'schematics',

      showResetIcon: true,
      labelReset: 'common.reset',

      items: {
        selectorWidget: {
          widgetName: 'SchematicsSelectorWidgetComponent',
          titleKey: `${this.T_SCOPE}.widgets.selector.title`,
        },
        receptorWidget: {
          widgetName: 'SchematicsContainerWidgetComponent',
          titleKey: `${this.T_SCOPE}.widgets.container.title`,
        },
      },
      stateFields: {
        selected: SchematicStateFields.selectedSchematic,
        selectedMany: SchematicStateFields.multiselectedSchematic,
      },
    };
    return of(settings);
  }

  onOpenImportPopup(): void {
    const config = new MatDialogConfig();
    config.width = SchematicImportPopupDimensions.Width;
    const dialogRef = this._dialogsService.openComponent(SchematicImportPopupComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.layoutComponent.refreshSelector();
      }
    });
  }

  onOpenEditMode(): void {
    this.editMode = true;
    this._state.dispatch(
      new SetValueAction({
        fieldName: SchematicStateFields.editMode,
        value: true,
      })
    );
  }

  onSelected(schematic: SchematicBasic): void {
    this.selectedSchematic = schematic;
    this.currentSchematicTitle = schematic?.name;
  }

  onSelectedMany(schematics: SchematicBasic[]): void {
    this.schematicsMultiselection = schematics;
  }

  onDeleteSchematics(): void {
    if (this.selectedSchematic) {
      this._dialogsService.showDeleteConfirmationSnackBar(1, 'schematic').subscribe((result) => {
        if (result?.result) {
          this.setLoading(true);
          this._schematicsService
            .delete(this.selectedSchematic.name)
            .pipe(finalize(() => this.setLoading(false)))
            .subscribe(() => {
              this._dialogsService.showEntityActionSnackBar('delete', 'schematic.singular');
              this.layoutComponent.refreshSelector();
            });
        }
      });
    }
  }

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'SchematicsPageComponent';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }
}
