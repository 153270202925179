import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { RouteNameHelper } from 'src/app/common-modules/dependencies/navigation/route-name-helper';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from 'src/app/common-modules/shared/helpers/navmenu-builder-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { GridPersistedElements } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { INeHeFilterSettings } from '../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { ProfileConfigurationComponent } from '../../alarms/profile-configuration/profile-configuration.component';
import { AlarmConfigurationDto } from '../../shared/model/alarms/alarm-configuration.dto';
import { DeleteAlarmsDto } from '../../shared/model/alarms/delete-alarms.dto';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { AlarmActivationsGridComponent } from '../alarm-configuration-components/alarm-activations-grid/alarm-activations-grid.component';
import { AlarmRelatedElementsComponent } from '../alarm-configuration-components/alarm-related-elements/alarm-related-elements.component';
import { AlarmsConfigurationFilterConfiguration } from '../alarm-configuration-components/alarms-configuration-filter/alarms-configuration-filter-configuration';
import { AlarmsParkingPopupComponent } from '../alarm-configuration-components/alarms-parking-popup/alarms-parking-popup.component';
import { ProfileSubscriptionComponent } from '../alarm-configuration-components/profile-subscription/profile-subscription.component';
import { AlarmsConfigurationService } from './alarms-configuration.service';

const COMPONENT_SELECTOR = 'wlm-alarms-configuration-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-configuration-page.component.html',
  styleUrls: ['./alarms-configuration-page.component.scss'],
})
export class AlarmsConfigurationPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(GenericGridComponent) public alarmsConfigurationGrid: GenericGridComponent;

  public T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMAlarmsCrud';
  }

  private hierarchyElementIdFieldName = 'hierarchyElementId';
  private hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  private hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  private networkElementTypeFieldName = 'networkElementTypeId';
  private defaultFamilyId: string;
  private defaultHETypeIds = [];
  private defaultNETypeIds = [];

  pageId: string = 'AlarmsConfigurationPage';

  alarmsConfigurationFilterConfig: AlarmsConfigurationFilterConfiguration;
  gridFilters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: GridSetting;
  gridName = 'AlarmsConfiguration';
  canLoad: boolean;
  isFirstLoad = true;
  navigations: DropdownNavigationItem[] = [];

  private _selectedAlarmConfiguration: AlarmConfigurationDto;
  public get selectedAlarmConfiguration(): AlarmConfigurationDto {
    return this._selectedAlarmConfiguration;
  }
  public set selectedAlarmConfiguration(value: AlarmConfigurationDto) {
    this.checkPendingChanges(this.pageId).subscribe((result) => {
      if (!result) {
        return;
      }

      this._selectedAlarmConfiguration = value;
      this.SendParameters(this.selectedAlarmConfiguration, null);
    });
  }

  private _selectedAlarmConfigurations: AlarmConfigurationDto[];
  public get selectedAlarmConfigurations(): AlarmConfigurationDto[] {
    return this._selectedAlarmConfigurations;
  }
  public set selectedAlarmConfigurations(value: AlarmConfigurationDto[]) {
    this._selectedAlarmConfigurations = value;
    this.hasSelectedAlarms = this._selectedAlarmConfigurations.length > 0;
  }

  hasSelectedAlarms = false;

  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  removeSelection$ = new ReplaySubject<void>();
  reloadRightPanel = true;

  private get getPersistedFamilyId(): string {
    const persistedFamily = this.getPersisted(this.hierarchyElementFamilyFieldName, undefined);

    return (persistedFamily as BasicFilter)?.value?.shift()?.value;
  }

  private get getPersistedHETypeIds(): string[] {
    const heTypesPersisted = this.getPersisted(this.hierarchyElementTypeFieldName, undefined);

    return (heTypesPersisted as BasicFilter)?.value?.map((x) => x.value);
  }

  private get getPersistedNETypeIds(): string[] {
    const neTypesPersisted = this.getPersisted(this.networkElementTypeFieldName, undefined);

    return (neTypesPersisted as BasicFilter)?.value?.map((x) => x.value);
  }

  private get getPersistedHEIds(): string[] {
    return (
      this.getPersisted(this.hierarchyElementIdFieldName, [{ value: '', label: '' }]) as BasicFilter
    ).value;
  }

  constructor(
    private _gridSettingsService: GridSettingsService,
    private _globalsService: GlobalsService,
    private _alarmsParkingDialog: MatDialog,
    private _cacheService: HttpCacheService,
    private _authenticationService: AuthenticationService,
    private _dialogService: DialogService,
    private _alarmsConfigurationService: AlarmsConfigurationService,
    private _router: Router,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _spinnerService: SpinnerService,
    private _authService: AuthorizeService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.init();
    this.loadRightPanelComponents();
    this.updateNavigationParams();
    this.subscribeRightPanelCallback();
  }

  subscribeRightPanelCallback() {
    this.rightPanelService
      .rightPanelCallbackObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (payload) => {
          if (
            payload?.has(ProfileConfigurationComponent) &&
            payload?.get(ProfileConfigurationComponent) === 'refresh'
          ) {
            this.reloadGrid(false);
          }
        },
      });
  }

  loadRightPanelComponents() {
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'c');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
    combineLatest([notificationPermission$, localization$]).subscribe(
      ([notificationPermission, ts]) => {
        const panelSettings = new TabDetailPanelSettings();
        panelSettings.addComponent(AlarmActivationsGridComponent, ts.alarms);
        panelSettings.addComponent(AlarmRelatedElementsComponent, ts.related);
        panelSettings.addComponent(ProfileConfigurationComponent, ts['profile-configuration']);

        if (notificationPermission) {
          panelSettings.addComponent(ProfileSubscriptionComponent, ts['subscription']);
        }

        this.rightPanelService.setTabSettings(panelSettings);
      }
    );
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {
    this.loadState();
    this.initGrid();
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridSettingsService
          .getGridSettingsByName(this.gridName)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (gridSettings) => {
              if (gridSettings) {
                this.setGridSettings(gridSettings);
              }
            },
          });
      }
    }
  }

  loadState() {
    this._globalsService.getDefaultHierarchyFamilyId().subscribe((familyId) => {
      this.defaultFamilyId = familyId;
      this.loadFilterConfiguration();
    });
  }

  loadFilterConfiguration() {
    const heFilterSettings = new INeHeFilterSettings(
      false,
      this.getPersistedHETypeIds ?? this.defaultHETypeIds,
      this.hierarchyElementTypeFieldName
    );

    const neFilterSettings = new INeHeFilterSettings(
      true,
      this.getPersistedNETypeIds ?? this.defaultNETypeIds,
      this.networkElementTypeFieldName
    );

    this.alarmsConfigurationFilterConfig = new AlarmsConfigurationFilterConfiguration({
      persistencyArea: this.persistencyArea,
      defaultFamilyId: this.defaultFamilyId,
      defaultHETypeIds: this.defaultHETypeIds,
      defaultNETypeIds: this.defaultNETypeIds,
      heSettings: heFilterSettings,
      neSettings: neFilterSettings,
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
      initialFamilyId: this.getPersistedFamilyId,
      initialHierarchyElementId: this.getPersistedHEIds,
    });
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    this.gridFilters = filtersParameters;
    this.persistFilters$.next();
  }

  /**
   * When all filters are available, perform autoload.
   */
  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        if (this.gridFilters?.filters) {
          this.loadGrid(null);
        }
      }
    });
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.loadGrid(gridFiltersRef);
  }

  loadGrid(gridFiltersRef: any) {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    if (newGridFilters?.filters?.size > 0) {
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  getSelectedAlarm(alarmConfiguration: AlarmConfigurationDto) {
    this.selectedAlarmConfiguration = alarmConfiguration;
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((result) => result !== null)
    );
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  private SendParameters(selectedElement: AlarmConfigurationDto, filter: TabDetailPanelParameters) {
    if (this.reloadRightPanel) {
      this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
        const parameters = filter ?? new TabDetailPanelParameters();

        parameters.addParameter(TabDetailParameterName.pageId, this.pageId);
        parameters.addParameter(TabDetailParameterName.alarmTypeId, selectedElement?.alarmTypeId);

        parameters.addParameter(
          TabDetailParameterName.elementName,
          selectedElement?.alarmTypeId
            ? ts['alarm-title'] + selectedElement.alarmTypeDescription
            : null
        );

        parameters.addParameter(
          TabDetailParameterName.profileConfiguration,
          selectedElement?.profileInfoId
        );

        this.rightPanelService.setTabParameters(parameters);
      });
    }
    this.reloadRightPanel = true;
  }

  openCreateAlarmWizard() {
    this._router.navigate([
      RouteNameHelper.getConfigurationRoute(RouteNameHelper.alarmsCreateTelemetry),
    ]);
  }

  openParkAlarmsPopup() {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '580px';
      //dialogConfig.height = '460px';
      dialogConfig.data = {
        selectedProfiles: this.selectedAlarmConfigurations.map((x) => x.profileInfoId),
      };
      const popup = this._alarmsParkingDialog.open(AlarmsParkingPopupComponent, dialogConfig);
      popup
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (data) {
            this.removeSelectionPersisted$.next();
            this.removeSelection$.next();
            const message = this.buildSuccessOperationMessage(data);
            this.dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.${message}` })
            );
            this.alarmsConfigurationGrid?.reloadGrid();
          }
        });
    });
  }

  private buildSuccessOperationMessage(data: string): string {
    let message = '';
    if (data === 'park') {
      message =
        this.selectedAlarmConfigurations.length > 1
          ? 'alarms-parking-success'
          : 'alarm-parking-success';
    } else {
      message =
        this.selectedAlarmConfigurations.length > 1
          ? 'alarms-unparking-success'
          : 'alarm-unparking-success';
    }
    return message;
  }

  deleteAlarms() {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      const deleteAlarmsDto = new DeleteAlarmsDto(
        [],
        this._authenticationService?.userCode?.split('@')[0],
        new Date()
      );

      deleteAlarmsDto.alarmTypeIds.push(
        ...this.selectedAlarmConfigurations.map((x) => x.alarmTypeId)
      );
      const isPlural = deleteAlarmsDto.alarmTypeIds.length > 1;
      const warningMessage = isPlural ? 'remove-alarms-warning' : 'remove-alarm-warning';

      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.${warningMessage}`,
      });

      this._dialogService
        .showTranslatedDialogMessage(dialogSettings)
        .subscribe((dialogRef: WLMDialogResult) => {
          if (dialogRef.result) {
            this._spinnerService.setLoading(true, this.gridName);

            this._alarmsConfigurationService.deleteAlarms(deleteAlarmsDto).subscribe({
              next: (response) => {
                if (!response) {
                  this.dialogService.showTranslatedMessage(
                    new WlmDialogSettings({
                      translateKey: `${this.T_SCOPE}.messages.remove-alarms-error`,
                      icon: 'error',
                    })
                  );

                  this._spinnerService.setLoading(false, this.gridName);
                } else {
                  this.removeSelection$.next();
                  this.removeSelectionPersisted$.next();

                  const gridData$ = this._cacheService.clearContainsInUrl('alarm/configuration');
                  const gridSelection$ = this._cacheService.clearContainsInUrl(
                    `selected/${this.persistencyArea}`
                  );

                  Promise.all([gridData$, gridSelection$]).then(() => {
                    this.alarmsConfigurationGrid?.reloadGrid();
                    const messageSuccess = isPlural
                      ? 'remove-alarms-success'
                      : 'remove-alarm-success';
                    this._dialogService.showTranslatedMessageInSnackBar(
                      new WlmDialogSettings({
                        translateKey: `${this.T_SCOPE}.messages.${messageSuccess}`,
                      })
                    );

                    this._spinnerService.setLoading(false, this.gridName);
                  });
                }
              },
              error: (error) => {
                this.dialogService.showErrorMessage(error);
                this._spinnerService.setLoading(false, this.gridName);
              },
            });
          }
        });
    });
  }

  private updateNavigationParams() {
    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: null,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.AlarmsCreationAlgorithms,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.AlarmsCreationTelemetry,
        validationType: 'none',
        paramType: 'none',
      },
    ];
  }

  private reloadGrid(resetPage: boolean = true) {
    this.loading = true;
    this._cacheService.clearContainsInUrl('alarm/configuration').then(() => {
      resetPage
        ? this.alarmsConfigurationGrid?.reloadGrid()
        : this.alarmsConfigurationGrid?.reloadAndSelectGrid();
      this.loading = false;
    });
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }
}
