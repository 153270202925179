<div kendoTooltip tooltipClass="kendo-tooltip">
  <span
    *ngIf="dataProcessed"
    class="column-cell"
    [title]="tooltip"
    [style.justify-content]="justifyContentStyle"
    [style.background]="backgroundStyle"
    [style.font-weight]="fontWeigthStyle$ | async"
    s
  >
    {{
      convertedValue$
        | async
        | number
          : (decimalPositions != null && decimalPositions != undefined
              ? '1.' + decimalPositions + '-' + decimalPositions
              : null)
        | gridCellEmpty
    }}

    <span
      class="comparison-percentage"
      *ngIf="!hideComparison && showHistorical && comparisonIsActive"
    >
      {{ dataItem[field] | comparisonPercentage : dataItem[comparisonField] }}</span
    >
    <wlm-icon
      class="comparison-icon"
      *ngIf="showHistorical && !hideComparison && comparisonIsActive"
      [icon]="dataItem[field] | comparisonImageIconName : dataItem[comparisonField]"
      [color]="dataItem[field] | comparisonImageIconColor : dataItem[comparisonField]"
    ></wlm-icon>
  </span>
</div>
