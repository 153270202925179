import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { QuillModules } from 'ngx-quill';
import { _MatQuillBase } from './mat-quill-base';

// Increasing integer for generating unique ids for mat-quill components.
let nextUniqueId = 0;

const SELECTOR = 'mat-quill';

@Component({
  selector: SELECTOR,
  exportAs: 'matQuill',
  template: `
    <ng-container *ngIf="toolbarPosition !== 'top'">
      <div quill-editor-element *ngIf="!preserve"></div>
      <pre quill-editor-element *ngIf="preserve"></pre>
    </ng-container>
    <ng-content select="[quill-editor-toolbar]"></ng-content>
    <ng-container *ngIf="toolbarPosition === 'top'">
      <div quill-editor-element *ngIf="!preserve"></div>
      <pre quill-editor-element *ngIf="preserve"></pre>
    </ng-container>
    <ng-content></ng-content>
  `,
  inputs: ['disabled'],
  providers: [{ provide: MatFormFieldControl, useExisting: MatQuill }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    `,
  ],
})
export class MatQuill extends _MatQuillBase {
  controlType = SELECTOR;
  @HostBinding() id = `${SELECTOR}-${nextUniqueId++}`;

  @Input() public set quillModules(value: QuillModules) {
    super['modules'] = value;
  }

  @Input() public set quillSanitize(value: boolean) {
    super['sanitize'] = value;
  }

  @Input() public set quillTheme(value: boolean) {
    super['sanitize'] = value;
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
