import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';

export abstract class HistoricalVersionDto {
  validFrom: Date;
  validTo: Date;
  versionId?: number;
  id: string;

  constructor(init: HistoricalVersionDto) {
    Object.assign(this, init);
    this.id = globalUtilsHelper.generateGuid();
  }

  abstract getCurrentConfig?(): any;
}
