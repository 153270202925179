export const lsHierarchyElementsTree = 'hierarchyElementsTree';
export const lsAoiList = 'aoiList';
export const lsHierarchyFamilyList = 'hierarchyFamilyList';
export const disableNodeClassName = 'disabled-node';
export const highlightedNodeClassName = 'highlighted-node';
export const minLengthToSearchTree = 2;

export enum treeSelectionMode {
  multiple = 'multiple',
  single = 'single',
}
export enum hierarchyElementTypes {
  Company = 'Company',
  DMA = 'DMA',
  DZ = 'DZ',
  EastWest = 'East-West',
  Group = 'Group',
  NetworkManagerArea = 'Network Manager Area',
  OperationalArea = 'Operational Area',
  SignalsData = 'Signals Data',
  SodconBaseType = 'Sodcon Base Type',
  SodconLNU = 'Sodcon LNU',
  SodconPCC = 'Sodcon PCC',
  SodconPHC = 'Sodcon PHC',
  WaterResourceZone = 'Water Resource Zone',
}

export enum hierarchyElementPathFields {
  Descendant = 'descendant',
  DescendantHierarchyElementName = 'descendantHierarchyElementName',
}

export enum searchDirection {
  up = 'up',
  down = 'down',
}
