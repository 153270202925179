import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-select-all-column',
  templateUrl: './select-all-column.component.html',
  styleUrls: ['./select-all-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => SelectAllColumnComponent),
    },
  ],
})
export class SelectAllColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() selectAllIsIndeterminate: boolean;
  @Input() selectAllValue: boolean;
  @Input() showSelectAllHeader = false;

  @Output() selectAllChanged = new EventEmitter<any>();
  @Output() selectAllValueChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  onSelectAllChange($event) {
    this.selectAllChanged.next($event);
  }
}
