import { EstimationFunction } from './estimation-function';
import { OffsetType } from './offset-types';

export enum DataValidationSelectionType {
  Value,
  Date,
  Both,
}

export enum DataValidationEstimationFunctionType {
  Fixed,
  PreviousValue,
  PreviousWeek,
  CopySignal,
}

export enum DataValidationOffsetType {
  Absolute,
  Percentage,
}

export const DATA_VALIDATION_OFFSET_TYPES: OffsetType[] = [
  {
    type: DataValidationOffsetType.Absolute,
    labelKey: 'data-validation.offset-types.absolute',
  },
  {
    type: DataValidationOffsetType.Percentage,
    labelKey: 'data-validation.offset-types.percentage',
  },
];

export const DATA_VALIDATION_ESTIMATION_FUNCTIONS: EstimationFunction[] = [
  {
    type: DataValidationEstimationFunctionType.Fixed,
    labelKey: 'data-validation.functions.fixed',
    functionName: 'fixed',
    selectionType: DataValidationSelectionType.Both,
  },
  {
    type: DataValidationEstimationFunctionType.PreviousValue,
    labelKey: 'data-validation.functions.previous-value',
    functionName: 'previousValue',
    selectionType: DataValidationSelectionType.Both,
  },
  {
    type: DataValidationEstimationFunctionType.PreviousWeek,
    labelKey: 'data-validation.functions.previous-week',
    functionName: 'previousWeek',
    selectionType: DataValidationSelectionType.Both,
  },
  {
    type: DataValidationEstimationFunctionType.CopySignal,
    labelKey: 'data-validation.functions.copy-signal',
    functionName: 'copySignal',
    selectionType: DataValidationSelectionType.Date,
  },
];
