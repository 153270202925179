import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'wlm-collapsible-button, [wlmCollapsibleButton]',
  templateUrl: './collapsible-button.component.html',
  styleUrls: ['./collapsible-button.component.scss'],
  host: {
    class: 'collapsible-button',
  },
})
export class CollapsibleButtonComponent {
  @Input() tooltip: string;
  private _disabled: boolean;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this._hostButton.disabled = this.disabled;
  }
  // If not provided, tooltip will be used instead.
  @Input() label: string;
  @Input() showLabel: boolean = false;

  private _hostButton: HTMLButtonElement;

  constructor(element: ElementRef) {
    this._hostButton = element.nativeElement;
  }
}
