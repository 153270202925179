import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { Observable, ReplaySubject, startWith } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DataVisualizationChartConfigPopupDimensions } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { BaseCalculateResizableChildComponent } from 'src/app/common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { CustomizableChartComponent } from 'src/app/common-modules/wlm-charts/core/customizable-chart/customizable-chart.component';
import { EventsChartComponent } from 'src/app/common-modules/wlm-charts/core/events-chart/events-chart.component';
import { EventChartPosition } from 'src/app/common-modules/wlm-charts/core/events-chart/models/event-chart-position';
import { EventChartQueryDto } from 'src/app/common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomizableChartParameters } from 'src/app/common-modules/wlm-charts/core/models/customizable-chart-parameters';
import { CustomizableChartSettings } from 'src/app/common-modules/wlm-charts/core/models/customizable-chart-settings';
import { GenericCartesianChartSettings } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { GChartClickEvent } from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-data-zoom-event';
import { SpinnerWrapperSettings } from 'src/app/common-modules/wlm-spinner/models/spinner-wrapper-settings';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { DataVisualizationChartConfigPopupComponent } from '../data-visualization-chart-config-popup/data-visualization-chart-config-popup.component';

const COMPONENT_SELECTOR = 'wlm-data-visualization-chart';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-chart.component.html',
  styleUrls: ['./data-visualization-chart.component.scss'],
})
export class DataVisualizationChartComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  T_SCOPE_CHART_BUTTONS = `${AppModules.WlmCharts}.buttons`;

  @ViewChild(EventsChartComponent) eventChart: EventsChartComponent;
  @ViewChild(CustomizableChartComponent) customizableChart: CustomizableChartComponent;

  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();
  @Output() chartConfigurationChange = new EventEmitter<ChartConfiguration>();

  private _chartConfiguration: ChartConfiguration;
  public get chartConfiguration(): ChartConfiguration {
    return this._chartConfiguration;
  }
  @Input()
  public set chartConfiguration(value: ChartConfiguration) {
    this._chartConfiguration = value;
    this.eventChartQuery = this.getEventChartQuery(value);

    if (value) {
      this.setChartSettings(value);
      this.chartConfigurationChange.emit(value);
    }
  }

  dimensionToCalculate: 'height' | 'width' = 'height';
  private readonly _eventsChartSizeChanges$ = new ReplaySubject<IElementSize>(1);

  eventChartQuery: EventChartQueryDto;
  chartSettings: CustomizableChartSettings;

  xAxisMin: Date;
  xAxisMax: Date;
  xAxisRange: DateRange;

  isLoading = false;
  spinnerWrapperSettings: SpinnerWrapperSettings = {
    isOpaque: true,
  };

  constructor(
    private _dialogService: DialogService,
    private _localization: LocalizationHelperService,
    private _logService: LogService
  ) {
    super();
  }

  ngOnInit(): void {}

  onChartClick = (event) => this.chartClickEvent.next(event);

  onChartLoading(isLoading): void {
    this.isLoading = isLoading;
  }

  onChartDataZoom(chartDataZoom: GChartDataZoomEvent): void {
    if (!this.eventChart) {
      return;
    }
    const { start, end } = chartDataZoom;
    this.eventChart.position = new EventChartPosition({
      start,
      end,
    });
  }

  onEventChartSizeChange(size: IElementSize): void {
    this._eventsChartSizeChanges$.next(size);
  }

  onMainChartSettingsChange(settings: GenericCartesianChartSettings): void {
    if (!settings || !settings.xAxes.length) {
      return;
    }

    const min = settings.xAxes[0].min;
    const max = settings.xAxes[0].max;
    if (typeof (min as Date).getMonth === 'function') {
      this.xAxisMin = min as Date;
      this.xAxisMax = max as Date;
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else if (typeof min === 'string') {
      this.xAxisMin = this._localization.localStrToDate(min as string, DateFormats.DateTime);
      this.xAxisMax = this._localization.localStrToDate(max as string, DateFormats.DateTime);
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else {
      this._logService.error({
        msg: 'Min and max properties of the main chart are not bindable to the events chart.',
      });
    }
  }

  onChartConfiguration() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      chartConfiguration: this.chartConfiguration,
    };
    dialogConfig.height = DataVisualizationChartConfigPopupDimensions.Height;
    dialogConfig.width = DataVisualizationChartConfigPopupDimensions.Width;

    const popupRef = this._dialogService.openComponent(
      DataVisualizationChartConfigPopupComponent,
      dialogConfig
    );

    popupRef?.afterClosed().subscribe((result: ChartConfiguration) => {
      if (!result) {
        return;
      }

      const chartConfiguration = plainToClass(ChartConfiguration, result);
      this.chartConfiguration = chartConfiguration;
    });
  }

  showDataPoints() {
    this.customizableChart?.showDataPoints();
  }

  exportChart() {
    this.customizableChart?.exportChart();
  }

  private setChartSettings(chartConfiguration: ChartConfiguration) {
    const { minimum, maximum } = chartConfiguration.horizontalAxis[0];

    this.chartSettings = new CustomizableChartSettings({
      chartType: ChartType.customizable,
      dataParameters: new CustomizableChartParameters({
        startDate: minimum,
        endDate: maximum,
        chartConfiguration: chartConfiguration,
      }),
    });
  }

  private getEventChartQuery(chartConfiguration: ChartConfiguration): EventChartQueryDto {
    if (!chartConfiguration) {
      return null;
    }

    const { eventsControlSettings, eventsQuery } = chartConfiguration;

    return new EventChartQueryDto({
      ...(eventsQuery as EventChartQueryDto),
      categories: eventsControlSettings.categories,
    });
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [this._eventsChartSizeChanges$.asObservable().pipe(startWith(null))];
  }

  debugName(): string {
    return COMPONENT_SELECTOR;
  }
}
