<div id="center-page">
  <div class="top-button-container">
    <button
      mat-icon-button
      [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
      color="primary"
      (click)="restore()"
    >
      <wlm-icon [icon]="'discard-changes'"></wlm-icon>
    </button>
  </div>
  <wlm-date-range-and-mode
    *ngIf="settings"
    [apply$]="dateRangeApply$"
    [empty$]="dateRangeClearAll$"
    [settings]="settings?.dateRangeAndModeSettings"
    (valid)="onDatesValid($event)"
    (valueChanges)="onDatesChange($event)"
  ></wlm-date-range-and-mode>
</div>
