import { ChartColumnCustomization } from './chart-column-customization';
import { GChartBrush } from './g-chart-brush';
import { GChartDataZoom } from './g-chart-data-zoom';
import { GChartGeo } from './g-chart-geo';
import { GChartGrid } from './g-chart-grid';
import { GChartLegend } from './g-chart-legend';
import { GChartTitle } from './g-chart-title';
import { GChartToolbox } from './g-chart-toolbox';
import { GChartTooltip } from './g-chart-tooltip';
import { GChartVisualMap } from './g-chart-visual-map';

export class GenericChartSettings {
  chartTitle?: GChartTitle;
  tooltip?: GChartTooltip;
  dataZoom?: GChartDataZoom[];
  legend?: GChartLegend;
  toolbox?: GChartToolbox;
  brush?: GChartBrush;
  animation?: boolean;
  grid?: GChartGrid;
  visualMap?: GChartVisualMap[];
  exportFileName?: string;
  geo?: GChartGeo[];
  columnCustomizations?: ChartColumnCustomization[];

  constructor(init: Readonly<GenericChartSettings>) {
    Object.assign(this, init);
  }
}
