import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { AlarmChartQueryDto } from '../../dependencies/alarms/alarm-chart-query.dto';
import { AlarmClassTypes } from '../../dependencies/alarms/alarm-class-types.enum';
import { AlarmCommentTypesDto } from '../../dependencies/alarms/alarm-comment-type.dto';
import { AlarmQueryDto } from '../../dependencies/alarms/alarm-query.dto';
import { IAlarmSeverityTypeDto } from '../../dependencies/alarms/alarm-severity-type.dto';
import { AlarmDto } from '../../dependencies/alarms/alarm.dto';
import { ProfileConfigurationDto } from '../../dependencies/alarms/profile-configuration.dto';
import { ProfileResultDto } from '../../dependencies/alarms/profile-result.dto';
import { CVChartSerieDto } from '../../dependencies/cv/models/cv-chart-serie.dto';
import { BaseService } from '../base.service';
import { DateHelperService } from '../helpers/date-helper.service';
import { MVChartSerieDto } from '../model/mv/mv-chart-serie.dto';
import { LogService } from '../wlm-log/log.service';

@Injectable()
export class AlarmsService extends BaseService {
  expiration: GetCacheOpts = { expiration: 'default', reload: true };

  constructor(
    injector: Injector,
    private _logService: LogService,
    private _dateHelper: DateHelperService
  ) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/alarm`;
  }

  getAlarms(query: AlarmQueryDto): Observable<AlarmDto[]> {
    return this.httpCacheClient.get(this.url, { avoid: true }, query);
  }

  getAlarmCommentTypes(): Observable<AlarmCommentTypesDto[]> {
    return this.httpCacheClient.get(`${this.url}/comments`, this.expiration);
  }

  getAlarmSeverityTypes(): Observable<IAlarmSeverityTypeDto[]> {
    return this.httpCacheClient.get(`${this.url}/severity-types/all`);
  }

  calculateProfiles(profiles: ProfileConfigurationDto[]): Observable<ProfileResultDto[]> {
    const data = profiles.map((profile) =>
      this.datesToApiFormat(profile, [
        'periodStartDate',
        'periodEndDate',
        'parkStartDate',
        'parkEndDate',
      ])
    );
    return this.httpCacheClient.post(`${this.apiUrl}/alarm/profile/calculate`, data, {
      avoid: true,
    });
  }

  getProfileConfig(profileId: string): Observable<ProfileConfigurationDto> {
    return this.httpCacheClient.get<ProfileConfigurationDto>(
      `${this.apiUrl}/alarm/profile/config/${profileId}`,
      {
        avoid: true,
      }
    );
  }

  // TODO: refactor into interceptor
  protected datesToApiFormat<T>(model: T, fields: string[]): T {
    const result = { ...model };
    fields.forEach((field) => {
      if (result[field] && this._dateHelper.isDateObject(result[field])) {
        result[field] = this._dateHelper.toApiFormat(result[field]);
      }
    });
    return result;
  }

  protected datesFromApiFormat<T>(model: T, fields: string[]): T {
    const result = { ...model };
    fields.forEach((field) => {
      if (result[field] && typeof result[field] === 'string') {
        result[field] = this._dateHelper.fromApiFormat(result[field]);
      }
    });
    return result;
  }

  public getChartByAlarms(
    query: AlarmChartQueryDto
  ): Observable<(MVChartSerieDto | CVChartSerieDto)[]> {
    const result$ = this.httpCacheClient.post<(MVChartSerieDto | CVChartSerieDto)[]>(
      `${this.url}/chart/`,
      query
    );
    return result$;
  }

  alarmClassIsAlgoritm(alarmClassId: number): boolean {
    return Boolean(
      [AlarmClassTypes.MNF, AlarmClassTypes.DDI, AlarmClassTypes.DL].find((i) => i === alarmClassId)
    );
  }

  alarmClassToShortName(alarmClassId: number): string {
    switch (alarmClassId) {
      case AlarmClassTypes.MNF:
        return 'MNF';
      case AlarmClassTypes.DDI:
        return 'DDI';
      case AlarmClassTypes.DL:
        return 'DL';
    }

    this._logService.error({
      msg: 'Attempting to get the shortName of an alarmClass that is not an Algorithm.',
    });
    return null;
  }

  alarmClassToDimension(alarmClassId: number): string {
    switch (alarmClassId) {
      case AlarmClassTypes.Flow:
        return 'Flow';
      case AlarmClassTypes.Pressure:
        return 'Pressure';
      case AlarmClassTypes.Level:
        return 'Level';
    }

    this._logService.error({
      msg: 'Attempting to get the dimension of an alarmClass that is not a Dimension.',
    });
    return null;
  }
}
