<mat-card class="card-legend" *ngIf="visibleThematicsIds.length && kpiInfo">
  <div class="row-legend">
    <div class="kpi-title">
      {{ translationMapping[kpiInfo.categoryKey] | translate }} -
      {{ kpiInfoValues | kpiTitle | async }}{{ unitLabel }}
    </div>
    <div class="steps-container">
      <div class="step" *ngFor="let step of currentHeLevelSteps; let i = index">
        <div [ngStyle]="{ background: step.color }" class="square"></div>
        {{ stepsLabels[i] }}
      </div>
    </div>
  </div>
</mat-card>
