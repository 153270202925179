<div
  *ngIf="settings"
  class="content-stretch bi-widget widget-selector-container custom-treeview-selector"
>
  <mat-form-field
    class="filter-width search-field custom-search-field hide-subscript"
    appearance="outline"
  >
    <mat-label>{{ 'common.search' | translate }}</mat-label>
    <input matInput [(ngModel)]="filterText" autocomplete="off" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <kendo-treeview
    [nodes]="treeNodes"
    kendoTreeViewExpandable
    kendoTreeViewFlatDataBinding
    idField="id"
    parentIdField="group"
    textField="label"
    [filter]="filterText"
    [expandedKeys]="expandedKeys"
    expandBy="id"
    class="custom-k-treeview-widgets selector-treeview"
  >
    <ng-template kendoTreeViewNodeTemplate let-dataItem>
      <wlm-widget-selector-item
        style="width: 100%"
        [node]="dataItem"
        [isExpanded]="expandedKeysHash[dataItem.id]"
        (definitionSelected)="onDefinitionSelected($event)"
        (groupToggled)="onGroupToggle($event)"
      ></wlm-widget-selector-item>
    </ng-template>
  </kendo-treeview>
</div>
