import { EventChartQueryDto } from '../events-chart/models/events-chart-query.dto';
import { ChartSettings } from './chart-settings';

export class DynamicChartSettings {
  chartSettings: ChartSettings;
  eventChartSettings: EventChartQueryDto;

  constructor(init?: Readonly<DynamicChartSettings>) {
    Object.assign(this, init);
  }
}
