import { Injectable } from '@angular/core';
import { NavKeys } from '../../dependencies/navigation/nav-keys.enum';

import { NotificationGridSetting } from '../model/grid/notification-grid-setting';

@Injectable({
  providedIn: 'root',
})
export class NotificationGridSettingService {
  getNotificationSettingsForGrid(gridName: string): NotificationGridSetting {
    switch (gridName) {
      case 'DistributionNetwork':
        return new NotificationGridSetting({
          identifierFieldName: ['hierarchyElementId'],
          zoneIdColumnName: 'hierarchyElementId',
          isNotificable: true,
          navKey: NavKeys.DistributionNetwork,
        });

      case 'Prioritisation':
        return new NotificationGridSetting({
          identifierFieldName: ['hierarchyElementId'],
          zoneIdColumnName: 'hierarchyElementId',
          isNotificable: true,
          navKey: NavKeys.Prioritisation,
        });

      case 'Activities':
        return new NotificationGridSetting({
          identifierFieldName: ['activityId'],
          zoneIdColumnName: 'hierarchyElementId',
          isNotificable: true,
          navKey: NavKeys.Activities,
        });

      case 'PressureMonitoring':
        return new NotificationGridSetting({
          identifierFieldName: ['hierarchyElementId'],
          zoneIdColumnName: 'hierarchyElementId',
          isNotificable: true,
          navKey: NavKeys.PressureMonitoring,
        });

      case 'AlarmsConfiguration':
        return new NotificationGridSetting({
          identifierFieldName: ['alarmTypeDescription'],
          zoneIdColumnName: undefined,
          isNotificable: true,
          navKey: NavKeys.AlarmsConfiguration,
        });

      case 'TransmissionNetwork':
        return new NotificationGridSetting({
          identifierFieldName: ['networkElementId'],
          zoneIdColumnName: undefined,
          isNotificable: true,
          navKey: NavKeys.TransmissionNetwork,
        });

      case 'LogsSystem':
        return new NotificationGridSetting({
          identifierFieldName: ['auditId'],
          zoneIdColumnName: undefined,
          isNotificable: true,
          navKey: NavKeys.LogsSystem,
        });

      case 'Audits':
        return new NotificationGridSetting({
          identifierFieldName: ['auditId'],
          zoneIdColumnName: undefined,
          isNotificable: true,
          navKey: NavKeys.Audits,
        });

      default:
        break;
    }
  }
}
