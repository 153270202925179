import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { ProfileSubscriptionComponent } from '../configuration/alarm-configuration-components/profile-subscription/profile-subscription.component';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { AckAlarmPopupComponent } from './active-alarms-grid/ack-alarm-popup/ack-alarm-popup.component';
import { ActiveAlarmsGridComponent } from './active-alarms-grid/active-alarms-grid.component';
import { ActiveAlarmsGridService } from './active-alarms-grid/active-alarms.service';
import { AlarmConfigComponent } from './alarm-config/alarm-config.component';
import { AlarmNameGeneratorComponent } from './alarm-name-generator/alarm-name-generator.component';
import { AlarmChartTooltipService } from './alarms-chart/alarm-chart-tooltip.service';
import { AlarmsChartComponent } from './alarms-chart/alarms-chart.component';
import { AlarmsChartService } from './alarms-chart/alarms-chart.service';
import { AlarmsSubscriptionComponent } from './alarms-subscription/alarms-subscription.component';
import { AlarmsTabWidgetComponent } from './alarms-tab-widget/alarms-tab-widget.component';
import { DvAlarmsNavigationPopupComponent } from './dv-alarms-navigation-popup/dv-alarms-navigation-popup.component';
import { EnvelopesConfigComponent } from './envelopes-config/envelopes-config.component';
import { ProfileConfigurationComponent } from './profile-configuration/profile-configuration.component';
import { ProfilesConfigurationChartService } from './profiles-chart/profiles-configuration-chart.service';
import { ProfilesSaConfigurationChartService } from './profiles-chart/profiles-sa-configuration-chart.service';
import { ProfilesService } from './profiles-chart/profiles.service';
import { SACalendarSelectionComponent } from './sa-wizard-components/sa-calendar-selection/sa-calendar-selection.component';
import { SAEnvelopesConfigurationComponent } from './sa-wizard-components/sa-envelopes-configuration/sa-envelopes-configuration.component';
import { SAPointSelectionComponent } from './sa-wizard-components/sa-point-selection/sa-point-selection.component';
import { SASummaryComponent } from './sa-wizard-components/sa-summary/sa-summary.component';
import { SaTargetSelectionComponent } from './sa-wizard-components/sa-target-selection/sa-target-selection.component';
import { SATimeFrameSelectionComponent } from './sa-wizard-components/sa-time-frame-selection/sa-time-frame-selection.component';
import { SAWizardComponent } from './sa-wizard-components/sa-wizard/sa-wizard.component';

@NgModule({
  declarations: [
    DvAlarmsNavigationPopupComponent,
    AlarmsTabWidgetComponent,
    ActiveAlarmsGridComponent,
    AckAlarmPopupComponent,
    AlarmsChartComponent,
    AlarmConfigComponent,
    EnvelopesConfigComponent,
    ProfileConfigurationComponent,
    SATimeFrameSelectionComponent,
    SAWizardComponent,
    SAPointSelectionComponent,
    SACalendarSelectionComponent,
    SAEnvelopesConfigurationComponent,
    AlarmNameGeneratorComponent,
    SASummaryComponent,
    SaTargetSelectionComponent,
    AlarmsSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    WlmSharedModule,
    WLMGridModule,
    WlmChartsModule,
    WlmFiltersModule,
    WlmSharedComponentModule,
  ],
  exports: [
    DvAlarmsNavigationPopupComponent,
    AlarmsTabWidgetComponent,
    ActiveAlarmsGridComponent,
    AckAlarmPopupComponent,
    AlarmsChartComponent,
    AlarmConfigComponent,
    EnvelopesConfigComponent,
    SAWizardComponent,
    AlarmNameGeneratorComponent,
    AlarmsSubscriptionComponent,
  ],
  providers: [
    ActiveAlarmsGridService,
    { provide: 'ActiveAlarmsGridService', useExisting: ActiveAlarmsGridService },
    AlarmChartTooltipService,
    AlarmsChartService,
    { provide: 'AlarmsChartService', useExisting: AlarmsChartService },
    ProfilesConfigurationChartService,
    {
      provide: 'ProfilesConfigurationChartService',
      useExisting: ProfilesConfigurationChartService,
    },
    ProfilesSaConfigurationChartService,
    {
      provide: 'ProfilesSaConfigurationChartService',
      useExisting: ProfilesSaConfigurationChartService,
    },
    ProfilesService,
    {
      provide: 'ProfilesService',
      useExisting: ProfilesService,
    },
  ],
})
export class AlarmsModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    AlarmsModule.injector = injector;
    widgetRegistry.register([
      {
        name: 'ActiveAlarmsGridComponent',
        widgetClass: ActiveAlarmsGridComponent,
      },
      {
        name: 'AlarmsChartComponent',
        widgetClass: AlarmsChartComponent,
      },
      {
        name: 'ProfileConfigurationComponent',
        widgetClass: ProfileConfigurationComponent,
      },
      {
        name: 'ProfileSubscriptionComponent',
        widgetClass: ProfileSubscriptionComponent,
      },
    ]);
  }
}
