import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { map } from 'rxjs';
import { AppModules } from '../../app-modules.enum';
import { IExportExcelComponent } from '../models/export-excel-component';
import { ExportExcelSettings } from '../models/export-excel-settings';
import { ExcelHelperService } from '../service/excel-helper.service';

const COMPONENT_SELECTOR = 'wlm-export-excel';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss'],
})
export class ExportExcelComponent implements OnInit {
  public T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  settings: ExportExcelSettings;

  private _excelExport: ExcelExportComponent;
  public get excelExport(): ExcelExportComponent {
    return this._excelExport;
  }
  @ViewChild(ExcelExportComponent) public set excelExport(v: ExcelExportComponent) {
    this._excelExport = v;
    if (v) {
      this._excelExport.save();
      this.settings = null;
    }
  }

  @Input() exportExcelComponent: IExportExcelComponent;
  @Input() disabled: boolean;

  constructor(private readonly _excelService: ExcelHelperService) {}

  ngOnInit(): void {}

  exportExcel() {
    this.exportExcelComponent
      .getExportExcelSettings()
      .pipe(map(this.sanitizeSettings))
      .subscribe((x) => {
        this.settings = x;
      });
  }

  private sanitizeSettings = (settings: ExportExcelSettings) => {
    this._excelService.sanitizeSettings(settings);
    return settings;
  };
}
