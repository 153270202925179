import { LarsAndSworpsSiteSignalsDto } from './lars-sworps-site-signal.dto';

export class LarsSworpsConfigurationDto {
  networkElementId: string;
  networkElementName: string;
  larsSworpSignals: LarsAndSworpsSiteSignalsDto[];

  constructor(init: Readonly<LarsSworpsConfigurationDto>) {
    Object.assign(this, init);
  }
}
