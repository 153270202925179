import { ChartSettings } from './chart-settings';
import { ChartType } from './chart-type.enum';
import { PieChartDataParameters } from './pie-chart-data-parameters';

export class PieChartSettings extends ChartSettings {
  dataParameters: PieChartDataParameters;
  chartType: ChartType = ChartType.pie;

  constructor(init: PieChartSettings) {
    super(init);
    Object.assign(this, init);
  }
}
