import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFiltersModule } from '../common-filters/common-filters.module';
import { DynamicLayoutModule } from '../dynamic-layout/dynamic-layout.module';
import { CommonSharedModule } from '../shared/common-shared.module';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { GenericChartsModule } from '../wlm-charts/generic-charts.module';
import { WLMGridModule } from '../wlm-grid/wlm-grid.module';
import { GroupEditionPopupComponent } from './groups-components/group-edition-popup/group-edition-popup.component';
import { GroupUsersPopupComponent } from './groups-components/group-users-popup/group-users-popup.component';
import { GroupsUsersComponent } from './groups-components/groups-users/groups-users.component';
import { ProfileDialogsHierarchyGridComponent } from './groups-components/profile-dialogs-hierarchy-grid/profile-dialogs-hierarchy-grid.component';
import { ProfileDialogsHierarchyComponent } from './groups-components/profile-dialogs-hierarchy/profile-dialogs-hierarchy.component';
import { GroupsPageComponent } from './groups-page/groups-page.component';
import { CreateUserComponent } from './users-components/create-user/create-user.component';
import { IdentityUsersAssignedGridComponent } from './users-components/identity-users-assigned-grid/identity-users-assigned-grid.component';
import { IdentityUsersPopupComponent } from './users-components/identity-users-popup/identity-users-popup.component';
import { UserGroupGridComponent } from './users-components/user-group-grid/user-group-grid.component';
import { UserGroupsPopupComponent } from './users-components/user-groups-popup/user-groups-popup.component';
import { UsersActivityFiltersComponent } from './users-components/users-activity-filters/users-activity-filters.component';
import { UsersActivityChartService } from './users-components/users-activity-popup/users-activity-chart.service';
import { UsersActivityPopupComponent } from './users-components/users-activity-popup/users-activity-popup.component';
import { UsersPageComponent } from './users-page/users-page.component';
import { WlmSecurityRoutingModule } from './wlm-security-routing.module';

@NgModule({
  declarations: [
    GroupsPageComponent,
    ProfileDialogsHierarchyGridComponent,
    GroupsUsersComponent,
    GroupUsersPopupComponent,
    GroupEditionPopupComponent,
    UserGroupGridComponent,
    UsersPageComponent,
    UserGroupsPopupComponent,
    IdentityUsersAssignedGridComponent,
    IdentityUsersPopupComponent,
    UsersActivityPopupComponent,
    CreateUserComponent,
    UsersActivityFiltersComponent,
    ProfileDialogsHierarchyComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    WlmSecurityRoutingModule,
    WLMGridModule,
    TranslateModule,
    GenericChartsModule,
    CommonFiltersModule,
    DynamicLayoutModule,
  ],
  exports: [ProfileDialogsHierarchyGridComponent, ProfileDialogsHierarchyComponent],
  providers: [
    UsersActivityChartService,
    { provide: 'UsersActivityChartService', useExisting: UsersActivityChartService },
    {
      provide: 'IdentityUsersAssignedGridComponent',
      useExisting: IdentityUsersAssignedGridComponent,
    },
  ],
})
export class WlmSecurityModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    WlmSecurityModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'IdentityUsersAssignedGridComponent',
        widgetClass: IdentityUsersAssignedGridComponent,
      },
      {
        name: 'UserGroupGridComponent',
        widgetClass: UserGroupGridComponent,
      },
      {
        name: 'ProfileDialogsHierarchyComponent',
        widgetClass: ProfileDialogsHierarchyComponent,
      },
      {
        name: 'GroupsUsersComponent',
        widgetClass: GroupsUsersComponent,
      },
    ]);
  }
}
