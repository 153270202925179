<wlm-spinner-wrapper [showSpinner]="isSaving">
  <h3>{{ T_SCOPE + '.form-title' | translate }}</h3>
  <hr class="title-separator" />

  <mat-dialog-content [formGroup]="form" class="fixed-subscript">
    <div class="main-container">
      <div class="column-content column-unflag">
        <mat-checkbox formControlName="ddir" (change)="onChange($event)" group="algorithms">{{
          T_SCOPE + '.ddir-label' | translate
        }}</mat-checkbox>
        <mat-checkbox formControlName="dlccmnffhr" (change)="onChange($event)" group="algorithms">{{
          T_SCOPE + '.dlccmnffhr-label' | translate
        }}</mat-checkbox>
        <mat-checkbox formControlName="mnffhr" (change)="onChange($event)" group="algorithms">{{
          T_SCOPE + '.mnffhr-label' | translate
        }}</mat-checkbox>
        <mat-checkbox formControlName="dalccr" (change)="onChange($event)" group="algorithms">{{
          T_SCOPE + '.dalccr-label' | translate
        }}</mat-checkbox>
      </div>
      <div *hasPerm="['WLMNotificationsCrud', 'c']" class="column-content column-notification">
        <mat-checkbox
          class="check-margin"
          formControlName="checkEnableNotifications"
          (change)="onEnableNotification($event)"
          >{{ T_SCOPE + '.nf-enable-label' | translate }}</mat-checkbox
        >

        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.nf-title-label' | translate }} *</mat-label>
          <input matInput formControlName="notificationTitle" autocomplete="off" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.nf-description-label' | translate }} *</mat-label>
          <textarea
            matInput
            rows="6"
            formControlName="notificationDescription"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="save()"
        [disabled]="!hasChanges || isSaving || !form.valid"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button class="mat-raised-button" (click)="close()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</wlm-spinner-wrapper>
