export class LayoutLoadOptions {
  disablePersistencyLoad?: boolean;
  disablePersistencySave?: boolean;

  constructor(
    init: LayoutLoadOptions = { disablePersistencyLoad: false, disablePersistencySave: false }
  ) {
    Object.assign(this, init);
  }
}
