import { SignalSelectionListSettings } from "../../ne-signal-selection-lists/signal-selection-list-settings";

export class LargeUserSignalSelectionPopupSettings {
    signalsListSettings: SignalSelectionListSettings;
    title: string;
    minDate: Date;
    maxDate: Date;

    constructor(init: Readonly<LargeUserSignalSelectionPopupSettings>) {
        Object.assign(this, init);
    }
}
