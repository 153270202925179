import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IdentityUserCmd } from 'src/app/common-modules/shared/model/roles/identity-users-cmd.dto';
import { UserDto } from 'src/app/common-modules/shared/model/roles/user.dto';
import { UsersIdentityMappingDto } from 'src/app/common-modules/shared/model/roles/users-identity-mapping.dto';
import { UsersIdentityService } from 'src/app/common-modules/shared/roles/identity.service';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
// prettier-ignore
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { IdentityUsersPopupComponent } from '../identity-users-popup/identity-users-popup.component';

const COMPONENT_SELECTOR = 'wlm-identity-users-assigned-grid';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './identity-users-assigned-grid.component.html',
  styleUrls: ['./identity-users-assigned-grid.component.scss'],
})
export class IdentityUsersAssignedGridComponent extends BaseWidgetComponent {
  @ViewChild(GenericGridComponent) public usersGrid: GenericGridComponent;

  gridSettings: GridSetting;
  additionalFilters: Map<string, string>;
  gridFilters: DataBindingFilters;
  user: UserDto;
  identityUsersGridName = 'IdentityUsersAssigned';
  selectedUsers: UsersIdentityMappingDto[];
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  removeSelection$ = new ReplaySubject<void>();

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    gridService: GridSettingsService,
    private _identityUsersPopup: MatDialog,
    private _authenticationService: AuthenticationService,
    private _dialogService: DialogService,
    private _usersIdentityService: UsersIdentityService
  ) {
    super(injector, widgetSettings);
    gridService.getGridSettingsByName(this.identityUsersGridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.removeSelection$.next();
    this.user = parameters.parameters.get(TabDetailParameterName.user);
  }
  init(): void {
    this.loadGrid();
  }

  private loadGrid() {
    if (this.user) {
      const additionalFilters = new Map<string, string>();
      additionalFilters.set('userCode', this.user.userCode);
      additionalFilters.set('assigned', 'true');

      this.additionalFilters = additionalFilters;
      this.gridFilters = null;
      this.gridFilters = new DataBindingFilters();
      this.usersGrid?.reloadGrid();
    } else {
      this.gridFilters = null;
    }
  }

  assignIdentityUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '765px';
    dialogConfig.height = '660px';
    dialogConfig.data = {
      userCode: this.user.userCode,
    };
    const popup = this._identityUsersPopup.open(IdentityUsersPopupComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.init();
        }
      });
  }

  removeAssignedUser(): void {
    const identityUsersCmd = new IdentityUserCmd(
      [],
      this.user.userCode,
      this._authenticationService.userCode
    );

    identityUsersCmd.userIds.push(...this.selectedUsers.map((x) => x.userId));
    const isPlural = identityUsersCmd.userIds.length > 1;
    const warningMessage = isPlural ? '.remove-users-warning' : '.remove-user-warning';

    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}${warningMessage}`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.removeAssignedUsers(identityUsersCmd);
        }
      });
  }

  private removeAssignedUsers(identityUsersCmd: IdentityUserCmd) {
    const isPlural = identityUsersCmd.userIds.length > 1;
    const successMessage = isPlural ? '.remove-users-success' : '.remove-user-success';
    const errorMessage = isPlural ? '.remove-users-error' : '.remove-user-error';
    this.usersGrid.grid.loading = true;
    this._usersIdentityService.deleteIdentityUsers(identityUsersCmd).subscribe({
      next: (response) => {
        this.usersGrid.grid.loading = false;
        if (response.errors.length) {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}${errorMessage}`, icon: 'error' })
          );
        } else {
          this.selectedUsers = [];
          this.removeSelection$.next();
          this.init();
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}${successMessage}` })
          );
        }
      },
      error: (error) => {
        this._dialogService.showTranslatedMessage(
          new WlmDialogSettings({ translateKey: `${this.T_SCOPE}${errorMessage}`, icon: 'error' })
        );
        this.usersGrid.grid.loading = false;
        this.init();
      },
    });
  }

  get componentName() {
    return 'IdentityUsersAssignedGridComponent';
  }
}
