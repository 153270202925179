import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { CurrentCalculatedValuesViewDto } from '../../../shared/model/leakage-reporting/current-cv-view.dto';
import { FlagSystemItemDto } from '../../../shared/model/leakage-reporting/flag-system-item-dto';
import { FlaggedItemsDto } from '../../../shared/model/leakage-reporting/flagged-items.dto';
import { FlaggedNotificationDto } from '../../../shared/model/leakage-reporting/flagged-notification.dto';
import { FlaggingReasonDto } from '../../../shared/model/leakage-reporting/flagging-reason.dto';
import { LeakageReportingShortNamesEnum } from '../../../shared/model/leakage-reporting/leakage-reporting-shortnames.enum';
import { LeakageReportingService } from '../../leakage-reporting.service';
import { FlaggingValidator } from '../flagging-validator';

const COMPONENT_SELECTOR = 'wlm-flag-reason-popup';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './flag-reason-popup.component.html',
  styleUrls: ['./flag-reason-popup.component.scss'],
})
export class FlagReasonPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.LeakageReporting}.${COMPONENT_SELECTOR}`;

  flaggingReasons: FlaggingReasonDto[];
  selectedCurrentCalculatedValues: CurrentCalculatedValuesViewDto[];

  form: UntypedFormGroup;
  isSaving = false;
  hasChanges = false;

  ddiRReasonControl: AbstractControl;
  dlccmnffhRReasonControl: AbstractControl;
  mnffhRReasonControl: AbstractControl;
  dalccRReasonControl: AbstractControl;
  enableNotificationControl: AbstractControl;
  notificationTitleControl: AbstractControl;
  notificationDescriptionControl: AbstractControl;

  private _ddiRReasonFieldname = 'ddiRReason';
  private _dlccmnffhRReasonFieldname = 'dlccmnffhRReason';
  private _mnffhRReasonFieldname = 'mnffhRReason';
  private _dalccRReasonFieldname = 'dalccRReason';
  private _checkEnableNotificationsFieldname = 'checkEnableNotifications';
  private _notificationTitleFieldname = 'notificationTitle';
  private _notificationDescriptionFieldname = 'notificationDescription';

  constructor(
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<FlagReasonPopupComponent>,
    private _leakageReportingService: LeakageReportingService,
    private _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) selectedRows: CurrentCalculatedValuesViewDto[]
  ) {
    this.selectedCurrentCalculatedValues = selectedRows;
  }

  ngOnInit(): void {
    this.createForm();
    this.loadFlaggingReasons();
  }

  close() {
    this._dialogRef.close(false);
  }

  save() {
    const flaggedItems = this.prepareItemsToFlag();
    const notificationData = this.prepareNotificationData();

    this.isSaving = true;

    if (flaggedItems.length) {
      const flagData = new FlaggedItemsDto({
        flaggedItems: flaggedItems,
        notification: notificationData,
      });

      this._leakageReportingService.saveFlagSystemItems(flagData).subscribe({
        next: (jobId) => {
          this.isSaving = false;
          this._dialogRef.close(jobId);
        },
        error: (error) => {
          this.isSaving = false;
          const message = `${this.T_SCOPE}.messages.flag-error`;

          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: message, icon: 'error' })
          );
        },
      });
    } else {
      this.isSaving = false;
      this._dialogRef.close(false);
    }
  }

  onSelectionChange($event) {
    let anySelection = false;
    Object.keys(this.form.controls).forEach((key) => {
      anySelection = anySelection || this.form.controls[key].value !== null;
    });
    this.hasChanges = anySelection;
  }

  onEnableNotification($event: MatCheckboxChange) {
    if ($event.checked) {
      this.notificationTitleControl.enable();
      this.notificationDescriptionControl.enable();
    } else {
      this.notificationTitleControl.setValue(null);
      this.notificationDescriptionControl.setValue(null);
      this.notificationTitleControl.disable();
      this.notificationDescriptionControl.disable();
    }
  }

  private createForm() {
    this.form = this._fb.group(
      {
        ddiRReason: [null],
        dlccmnffhRReason: [null],
        mnffhRReason: [null],
        dalccRReason: [null],
        checkEnableNotifications: [false],
        notificationTitle: [{ value: '', disabled: true }],
        notificationDescription: [{ value: '', disabled: true }],
      },
      {
        validators: FlaggingValidator.notificationFieldsValidation(),
      }
    );

    this.setFormControlVariables();
  }

  private setFormControlVariables() {
    this.ddiRReasonControl = this.form.controls[this._ddiRReasonFieldname];
    this.dlccmnffhRReasonControl = this.form.controls[this._dlccmnffhRReasonFieldname];
    this.mnffhRReasonControl = this.form.controls[this._mnffhRReasonFieldname];
    this.dalccRReasonControl = this.form.controls[this._dalccRReasonFieldname];

    this.enableNotificationControl = this.form.controls[this._checkEnableNotificationsFieldname];
    this.notificationTitleControl = this.form.controls[this._notificationTitleFieldname];
    this.notificationDescriptionControl =
      this.form.controls[this._notificationDescriptionFieldname];
  }

  private loadFlaggingReasons() {
    this._leakageReportingService.getFlaggingReasons().subscribe({
      next: (reasons) => {
        this.flaggingReasons = reasons;
      },
    });
  }

  private getFlagSystemItem(
    shortName: string,
    reasonId: number,
    lastReasonId: number,
    currentCVView: CurrentCalculatedValuesViewDto
  ) {
    return new FlagSystemItemDto({
      date: currentCVView.date,
      elementId: currentCVView.elementId,
      shortName,
      reasonId,
      lastReasonId,
    });
  }

  private prepareNotificationData() {
    const hasNotifications = this.enableNotificationControl.value as boolean;
    const notificationTitle = this.notificationTitleControl.value;
    const notificationDescription = this.notificationDescriptionControl.value;

    return hasNotifications
      ? new FlaggedNotificationDto({
          title: notificationTitle,
          description: notificationDescription,
        })
      : null;
  }

  private prepareItemsToFlag() {
    const ddiRReasonId = this.ddiRReasonControl.value as number;
    const dlccmnffhRReasonId = this.dlccmnffhRReasonControl.value as number;
    const mnffhRReasonId = this.mnffhRReasonControl.value as number;
    const dalccRReasonId = this.dalccRReasonControl.value as number;

    const flagSystemItems = [];

    this.selectedCurrentCalculatedValues.forEach((element) => {
      if (
        this.checkReasonValue(ddiRReasonId, element.ddiR_R) &&
        this.checkAlgorithmValue(element.ddiR_V)
      ) {
        flagSystemItems.push(
          this.getFlagSystemItem(
            LeakageReportingShortNamesEnum.DDIR,
            ddiRReasonId,
            element.ddiR_R,
            element
          )
        );
      }
      if (
        this.checkReasonValue(dlccmnffhRReasonId, element.dlccmnffhR_R) &&
        this.checkAlgorithmValue(element.dlccmnffhR_V)
      ) {
        flagSystemItems.push(
          this.getFlagSystemItem(
            LeakageReportingShortNamesEnum.DLCCMNFFHR,
            dlccmnffhRReasonId,
            element.dlccmnffhR_R,
            element
          )
        );
      }
      if (
        this.checkReasonValue(mnffhRReasonId, element.mnffhR_R) &&
        this.checkAlgorithmValue(element.mnffhR_V)
      ) {
        flagSystemItems.push(
          this.getFlagSystemItem(
            LeakageReportingShortNamesEnum.MNFFHR,
            mnffhRReasonId,
            element.mnffhR_R,
            element
          )
        );
      }
      if (
        this.checkReasonValue(dalccRReasonId, element.dalccR_R) &&
        this.checkAlgorithmValue(element.dalccR_V)
      ) {
        flagSystemItems.push(
          this.getFlagSystemItem(
            LeakageReportingShortNamesEnum.DALCCR,
            dalccRReasonId,
            element.dalccR_R,
            element
          )
        );
      }
    });

    return flagSystemItems;
  }

  private checkAlgorithmValue(value: number): boolean {
    return value !== undefined && value !== null;
  }

  private checkReasonValue(formValue: number, previousValue: number): boolean {
    return formValue !== null && formValue !== undefined && formValue !== previousValue;
  }
}
