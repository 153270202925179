import { Directive, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Directive()
export abstract class BaseWizardStepComponent {
  @Input() public applyPersistedValue$ = new ReplaySubject<void>();
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  protected hasToEmitPersistencyValue = true;

  abstract emitPersistedValue();

  abstract stepKey: string;

  apply() {
    this.applyPersistedValue$.next();
  }
}
