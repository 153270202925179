import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkElementsService extends GridODataService<INetworkElementDto> {
  constructor(injector: Injector) {
    super(injector, '/api/ne');
  }

  protected mapResponse(
    response: PagedResultDto<INetworkElementDto>
  ): PagedResultDto<INetworkElementDto> {
    return response;
  }

  public getNesByIds(ids: string[]): Observable<INetworkElementDto> {
    const params = {
      ids,
    };

    return this.httpCacheClient.get(`${this.baseUrl}${this.api}/ids`, null, params);
  }

  public getNeById(id: string): Observable<INetworkElementDto> {
    const params = {
      id,
    };

    return this.httpCacheClient.get(`${this.baseUrl}${this.api}/id`, { avoid: true }, params);
  }
}
