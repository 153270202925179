export const mapDefaults = {
  iconImgPath: '/assets/images/gis/default-pin.png',
  iconName: 'map-pin',
  gisLayer: {
    color: '#00ffff',
    line: {
      paint: {
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0.01,
          6,
          1,
          10,
          2,
          13,
          4,
          16,
          7,
          19,
          10,
          22,
          12,
        ],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 16.5, 0.9, 20, 0.7, 22, 0.4],
      },
    },
    symbol: {
      layout: {
        'icon-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0,
          6,
          0,
          7,
          0.5,
          10,
          0.7,
          12,
          0.8,
          15,
          1,
          18,
          1.5,
          22,
          2,
        ],
        'icon-allow-overlap': true,
      },
    },
    polygon: {
      paint: {
        'fill-opacity': 0.3,
      },
    },
  },
};
