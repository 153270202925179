import { KpiVisibilityEnum } from '../../../shared/model/kpi/kpi-visibility.enum';
import { KpiLevelSteps } from './kpi-level-steps';
import { KpiStep } from './map-thematic-kpi-step';
import { KpiSettingValue } from './map-thematic-kpi-value';

export class MapThematicKpi {
  categoryKey: string;
  values: KpiSettingValue[];

  constructor(key: string, kpisValues: KpiSettingValue[]) {
    this.categoryKey = key;
    this.values = [];

    Object.values(kpisValues).forEach((kpiValue) => {
      let steps: KpiLevelSteps[] = [];

      if (kpiValue.steps && Object.values(kpiValue.steps)?.length) {
        steps = Object.values(kpiValue.steps).map(
          (k) =>
            new KpiLevelSteps({
              level: k.level,
              description: k.description,
              steps: Object.values(k.steps).map(
                (x) => new KpiStep({ color: x.color, value: x.value })
              ),
            })
        );
      }

      const kpi = new KpiSettingValue({
        kpiType: kpiValue.kpiType,
        kpiProperty: kpiValue.kpiProperty,
        steps: steps,
        dimensionTypeId: kpiValue.dimensionTypeId,
        timeAggregationId: kpiValue.timeAggregationId,
        labelKey: kpiValue.labelKey,
        unitTypeFormat: kpiValue.unitTypeFormat,
        visibility: Array.from(
          Object.values(kpiValue.visibility).map((x) => x as KpiVisibilityEnum)
        ),
      });
      this.values.push(kpi);
    });
  }
}
