<form *ngIf="nodeForm" [formGroup]="nodeForm" class="form form-body">
  <div class="form-content">
    <h2 class="schematic-form-title">{{ nodeFormDefinition.titleKey | translate }}</h2>
    <mat-form-field appearance="outline">
      <mat-label>{{ T_SCOPE + '.labels.gis-id' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [formControlName]="nodeFormDefinition.gisIdFieldName"
        autocomplete="off"
      />
      <mat-error *ngIf="nodeForm.get(nodeFormDefinition.gisIdFieldName)?.errors?.required">{{
        'common.validation.required' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ T_SCOPE + '.labels.name' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [formControlName]="nodeFormDefinition.nameFieldName"
        autocomplete="off"
      />
      <mat-error *ngIf="nodeForm.get(nodeFormDefinition.nameFieldName)?.errors?.required">{{
        'common.validation.required' | translate
      }}</mat-error>
    </mat-form-field>

    <div class="row-fields">
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.latitude' | translate }}</mat-label>
        <input
          matInput
          type="number"
          [formControlName]="nodeFormDefinition.latitudeFieldName"
          autocomplete="off"
        />
        <mat-error *ngIf="nodeForm.get(nodeFormDefinition.latitudeFieldName)?.errors?.required">{{
          'common.validation.required' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.longitude' | translate }}</mat-label>
        <input
          matInput
          type="number"
          [formControlName]="nodeFormDefinition.longitudeFieldName"
          autocomplete="off"
        />
        <mat-error *ngIf="nodeForm.get(nodeFormDefinition.longitudeFieldName)?.errors?.required">{{
          'common.validation.required' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ T_SCOPE + '.labels.network-element' | translate }}</mat-label>
      <mat-select [formControlName]="nodeFormDefinition.networkElementFieldName">
        <mat-option *ngFor="let netype of neTypes" [value]="netype.networkElementTypeId">
          {{ netype.networkElementTypeName }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="nodeForm.get(nodeFormDefinition.networkElementFieldName)?.errors?.required"
        >{{ 'common.validation.required' | translate }}</mat-error
      >
    </mat-form-field>
  </div>
  <div class="action-buttons">
    <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
      {{ 'common.cancel' | translate | uppercase }}
    </button>
    <button
      mat-button
      class="wtr-action-button-primary btn-size"
      (click)="saveCurrentNode()"
      [disabled]="!nodeForm.valid"
    >
      {{ 'common.apply' | translate | uppercase }}
    </button>
  </div>
</form>
