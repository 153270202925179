import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { WlmElementType } from '../../shared/model/wlm/wlm-element-type';
import { KpiTitlePipe } from '../../shared/pipes/kpi-title-pipe';
import { MapHelperService } from '../map-helper.service';
import { MapLayerSourcesService } from '../map-layer-sources.service';
import { KpiTranslationKeysMapping } from '../map-thematic/models/kpi-translations-mapping';
import { MapThematicKpi } from '../map-thematic/models/map-thematic-kpi';
import { ThematicKPI } from './thematic-kpi';

const COMPONENT_SELECTOR = 'wlm-map-thematic-configuration-popup';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-thematic-configuration-popup.component.html',
  styleUrls: ['./map-thematic-configuration-popup.component.scss'],
})
export class MapThematicConfigurationPopupComponent implements OnInit {
  private _kpisTsRoute = `${AppModules.BI}.bi-widgets`;

  readonly T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  selectedKpi: ThematicKPI = null;
  kpiSelectorData: ThematicKPI[];
  gridSettings: GridSetting;
  isLoading: boolean;
  isValid = true;
  elementTypes: WlmElementType[];
  hasToReloadWhenClose = false;

  noSelectionTitleKey = `${this.T_SCOPE}.no-selection-title`;
  noSelectionSubtitleKey = `${this.T_SCOPE}.no-selection-subtitle`;

  private _saveConfiguration$ = new Subject<boolean>();
  readonly saveConfiguration$ = this._saveConfiguration$.asObservable();
  private _discardConfiguration$ = new Subject<void>();
  readonly discardConfiguration$ = this._discardConfiguration$.asObservable();
  hasPendingChanges: boolean;

  constructor(
    private _sourcesService: MapLayerSourcesService,
    private _localizationService: LocalizationHelperService,
    private _dialogRef: MatDialogRef<MapThematicConfigurationPopupComponent>,
    private _mapHelperService: MapHelperService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.initializeGrid();
    this.loadWlmElementTyoes();
    this.getThematicKpiSettings();
  }

  private loadWlmElementTyoes() {
    this._mapHelperService.getWlmElementTypes().subscribe({
      next: (elementTypes) => {
        this.elementTypes = elementTypes;
      },
      error: () => {
        this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
      },
    });
  }

  getSelectedRow(selectedKpi: ThematicKPI) {
    if (this.hasPendingChanges) {
      this.confirmPendingChanges(selectedKpi);
    } else {
      this.selectedKpi = selectedKpi;
    }
  }

  onValidChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  onLoadingChanged(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onSaveConfig(isSuperUser) {
    this._saveConfiguration$.next(isSuperUser);
  }

  onDiscardChanges() {
    this._discardConfiguration$.next();
  }

  onSaveFinished(isFinished: boolean) {
    this.reloadGrid();
    this.hasToReloadWhenClose = true;
  }

  onHasPendingChanges(hasPendingChanges: boolean) {
    this.hasPendingChanges = hasPendingChanges;
  }

  private reloadGrid() {
    this.isLoading = true;
    this.selectedKpi = null;
    this.hasPendingChanges = false;
    this.kpiSelectorData = null;
    this.getThematicKpiSettings();
  }

  private getThematicKpiSettings() {
    const kpiSettings$ = this._sourcesService.getThematicKpiSettings().pipe(untilDestroyed(this));

    const labels$ = this._localizationService.get(this._kpisTsRoute);

    return forkJoin([kpiSettings$, labels$]).subscribe({
      next: ([kpiSettings, ts]) => {
        this.kpiSelectorData = this.setThematicKpis(kpiSettings, ts);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
      },
    });
  }

  private confirmPendingChanges(selectedKpi: ThematicKPI) {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.confirm-pending-changes`,
      icon: 'warning',
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this._saveConfiguration$.next(false);
        } else {
          this.selectedKpi = selectedKpi;
        }
      });
  }

  private displayErrorMessage(messageKey: string) {
    let dialogSettings = new WlmDialogSettings({ translateKey: '' });
    dialogSettings.translateKey = messageKey;
    dialogSettings.icon = 'error';
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private initializeGrid() {
    this.getKpiSelectorGridSettings()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.gridSettings = x;
      });
  }

  private setThematicKpis(kpiSettings: MapThematicKpi[], ts: any): ThematicKPI[] {
    const kpis: ThematicKPI[] = [];

    const prefix = 'bi.bi-widgets.';
    const translationMapping = KpiTranslationKeysMapping;

    kpiSettings.forEach((kpi) => {
      kpi.values.forEach((x) => {
        const translationkey = translationMapping[kpi.categoryKey];
        const categoryLabel = get(ts, translationkey?.replace(prefix, ''));
        const categoryKey = translationkey?.replace(prefix, '');
        const kpiProperty = x.kpiProperty;
        const kpiType = x.kpiType;
        const kpiLabel$ = new KpiTitlePipe(this._localizationService).transform(x);

        const steps = x.steps;
        const timeAggregationId = x.timeAggregationId;
        const dimensionTypeId = x.dimensionTypeId;
        const unitTypeFormat = x.unitTypeFormat;
        const labelKey = x.labelKey;

        const thematicKpi = new ThematicKPI({
          categoryLabel,
          categoryKey,
          kpiProperty,
          kpiType,
          kpiLabel$,
          steps,
          timeAggregationId,
          dimensionTypeId,
          unitTypeFormat,
          labelKey,
        });

        kpis.push(thematicKpi);
      });
    });

    return kpis;
  }

  private getKpiSelectorGridSettings(): Observable<GridSetting> {
    const gridSettings: GridSetting = {
      count: true,
      pageSize: 10,
      sortable: true,
      filterable: 'menu',
      ignoreCase: true,
      resizable: true,
      reorderable: true,
      navigatable: false,
      selectable: {
        enabled: true,
        checkboxOnly: true,
      },
      showZoneColumns: false,
      showSelectAllColumn: false,
      pageable: {
        type: 'input',
        pageSizes: [10, 20, 50],
      },
      excelFileName: '',
      gridColumnSettings: [
        {
          field: 'kpiLabel',
          title: 'Thematic KPI',
          localeTitle: {
            'en-GB': 'Thematic KPI',
          },
          elementSubtitleFieldName: 'categoryLabel',
          type: 'selector',
          uniqueName: 'kpiLabel',
          visible: true,
          selectorColumnFilterFieldName: 'kpiLabel',
        },
      ],

      enableAutoResize: false,
      selectionPersistency: true,
      sort: [
        {
          field: 'kpiLabel',
          dir: 'asc',
        },
      ],
      buttons: { show: ['clear_grid_filters'] },
      useSmallPager: true,
      familyIdFilterName: '',
      hideHeader: false,
      utcDates: false,
    };

    return of(gridSettings);
  }

  onClose(data) {
    this._dialogRef.close(this.hasToReloadWhenClose);
  }
}
