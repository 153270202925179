import { Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BaseService } from './base.service';

export abstract class BaseKeyService<TKey, TModel> extends BaseService {
  private initializingSubject: BehaviorSubject<boolean>;
  private initializingSubject$: Observable<boolean>;

  private initializing = false;
  private map: Map<TKey, TModel>;

  protected abstract getElements(): Observable<TModel[]>;
  protected abstract getKey(model: TModel): TKey;

  constructor(injector: Injector) {
    super(injector);
    this.initializingSubject = new BehaviorSubject<boolean>(false);
    this.initializingSubject$ = this.initializingSubject.asObservable();
    this.init();
  }

  reload(): Observable<TModel[]> {
    this.initializingSubject.next(false);
    return this.load();
  }

  public getByKey(key: TKey): Observable<TModel> {
    const map$ = this.initializingSubject$.pipe(
      filter((init) => init),
      mergeMap(() => of(this.map.get(key)))
    );

    return map$;
  }

  public getByKeys(keys: TKey[]): Observable<TModel[]> {
    const filters$: Observable<TModel>[] = [];
    keys.forEach((key) => filters$.push(this.getByKey(key)));
    const elements$ = combineLatest(filters$).pipe(map((x) => [...x]));
    return elements$;
  }

  public getAll(): Observable<TModel[]> {
    const map$ = this.initializingSubject$.pipe(
      filter((init) => init),
      map(() => [...this.map.values()])
    );

    return map$;
  }

  public getFilteredBy(predicate: (x: TModel) => boolean): Observable<TModel[]> {
    const map$ = this.initializingSubject$.pipe(
      filter((init) => init),
      map(() => asEnumerable(this.map.values()).Where(predicate).ToArray())
    );

    return map$;
  }

  public getFirstOrDefault(predicate: (x: TModel) => boolean): Observable<TModel> {
    const map$ = this.initializingSubject$.pipe(
      filter((init) => init),
      map(() => asEnumerable(this.map.values()).FirstOrDefault(predicate))
    );

    return map$;
  }

  private init(): void {
    if (!this.mustLoad()) {
      return;
    }

    this.load();
  }

  private mustLoad(): boolean {
    return (this.map === null || this.map === undefined) && !this.initializing;
  }

  private loaded() {
    this.initializing = false;
    this.initializingSubject.next(true);
  }

  private load(): Observable<TModel[]> {
    this.initializing = true;

    const elements$ = this.getElements();
    elements$.subscribe({
      next: (result) =>
        (this.map = asEnumerable(result).ToDictionary(
          (x) => this.getKey(x),
          (x) => x
        )),
      complete: () => this.loaded(),
    });

    return elements$;
  }
}
