import { Component, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { SvgEditorComponent } from '../svg-editor/svg-editor.component';

@Component({
  selector: 'wlm-svg-editor-field-type',
  templateUrl: './svg-editor-field-type.component.html',
  styleUrls: ['./svg-editor-field-type.component.scss'],
  standalone: true,
  imports: [SvgEditorComponent, FormlyModule],
})
export class SvgEditorFieldTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  ngOnInit(): void {}

  onValueChanged(value: string): void {
    this.formControl.setValue(value);
    this.formControl.markAsDirty();
  }

  onValidityChanged(errors: ValidationErrors): void {
    this.formControl.setErrors(errors);
  }
}
