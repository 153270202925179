<div class="summary-container">
  <div
    class="summary-item alert-badge"
    *ngFor="let classType of classTypesToShow; trackBy: trackByItem"
  >
    <button
      *ngIf="!items[classType]"
      mat-icon-button
      #noDataButtonRef="matButton"
      onclick="this.blur()"
      (click)="onSelect(classType, noDataButtonRef)"
      [matTooltip]="classTypesNames[classType] ? classTypesNames[classType] : ''"
      [disabled]="disabledIfEmpty"
      class="wtr-button-regular wtr-override-button-overlay"
    >
      <mat-icon>{{ icons[classType] }}</mat-icon>
    </button>

    <button
      *ngIf="items[classType]"
      mat-icon-button
      #buttonRef="matButton"
      onclick="this.blur()"
      (click)="onSelect(classType, buttonRef)"
      [matTooltip]="
        classTypesNames[items[classType].alarmClassId]
          ? classTypesNames[items[classType].alarmClassId]
          : ''
      "
      [disableRipple]="disableRipple"
      class="wtr-button-regular wtr-override-button-overlay"
      [ngClass]="{
        'alarm-selected': items[classType].alarmClassId === selectedClassTypeId,
        'cannot-untoggle-selected':
          items[classType].alarmClassId === selectedClassTypeId && disableUntoggle
      }"
    >
      <mat-icon
        *ngIf="items[classType]"
        [matBadge]="
          items[classType].alarmCount && items[classType].alarmCount > maxAlarmsCount
            ? '...'
            : items[classType].alarmCount
        "
        [matBadgeSize]="matBadgeSize"
        [matBadgePosition]="matBadgePosition"
        [ngClass]="{
          'alert-critical': items[classType].alarmHighestSeverity === alarmSeverity.Critical,
          'alert-high': items[classType].alarmHighestSeverity === alarmSeverity.High,
          'alert-medium': items[classType].alarmHighestSeverity === alarmSeverity.Medium,
          'alert-low': items[classType].alarmHighestSeverity === alarmSeverity.Low
        }"
        >{{ icons[items[classType].alarmClassId] }}</mat-icon
      >
    </button>
  </div>
</div>
