<button
  mat-icon-button
  [class.button-with-label]="showLabel"
  [disabled]="disabled"
  [matTooltip]="(!showLabel ? tooltip : null) | translate | lowercase"
  onclick="this.blur()"
>
  <ng-content></ng-content>
  <div *ngIf="showLabel" class="button-label">{{ label ?? tooltip | translate | capitalize }}</div>
</button>
