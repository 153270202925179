export class ImageElement {
  iconName: string;
  color: string;
  text: string;
  isElse: boolean;
  isSvg: boolean;

  constructor(
    iconName: string,
    color: string = 'none',
    text: string = iconName,
    isElse: boolean = false,
    isSvg = false
  ) {
    this.iconName = iconName;
    this.color = color;
    this.text = text;
    this.isElse = isElse;
    this.isSvg = isSvg;
  }
}
