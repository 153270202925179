<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFiltersForBinding"
      [grid]="grid"
      [gridName]="dynamicGridSettings?.gridSettingName"
      [disable$]="gridBtnsDisable$"
      (btnClick)="onClickGridBtns($event)"
      (gridSettingsChange)="onGridSettingsChanged($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <div *hasPerm="['WLMCampaignCrud', 'c']">
          <button
            wlmCollapsibleButton
            *hasPerm="['ActivitiesCrud', 'r']"
            [tooltip]="T_SCOPE + '.tooltip.add-campaign'"
            [showLabel]="isContainerCollapsed"
            (click)="openAddPopup()"
          >
            <mat-icon class="material-icon">add</mat-icon>
          </button>
        </div>
        <div *hasPerm="['WLMCampaignCrud', 'u']">
          <button
            wlmCollapsibleButton
            *hasPerm="['ActivitiesCrud', 'r']"
            [disabled]="!selectedCampaign"
            [tooltip]="T_SCOPE + '.tooltip.edit-campaign'"
            [showLabel]="isContainerCollapsed"
            (click)="openEditPopup()"
          >
            <mat-icon class="material-icon" svgIcon="edit"></mat-icon>
          </button>
        </div>
        <div *hasPerm="['WLMCampaignCrud', 'd']">
          <button
            wlmCollapsibleButton
            [disabled]="!selectedCampaigns?.length"
            [tooltip]="T_SCOPE + '.tooltip.delete-campaign'"
            [showLabel]="isContainerCollapsed"
            (click)="deleteCampaigns()"
          >
            <mat-icon class="material-icon" svgIcon="delete"></mat-icon>
          </button>
        </div>
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-content>
    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings"
      [dynamicGridSettings]="dynamicGridSettings"
      (selectedItemChanged)="getSelectedCampaign($event)"
      (genericGridLoaded)="getGenericGridInstance($event)"
      (gridSettingsLoaded)="getGridSettings($event)"
      [dataBindingFilters]="gridFiltersForBinding"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [gridSettingsChanged$]="gridSettingsChanged$"
      [(selectedItems)]="selectedCampaigns"
    ></wlm-dynamic-grid>
  </ng-container>
</wlm-page-wrapper>
