import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import ICONS from '../../../assets/icons/icons.json';

@Injectable()
export class IconLoaderService {
  constructor(private _iconRegistry: MatIconRegistry, private _sanitizer: DomSanitizer) {}

  registerAllIcons(): void {
    this.registerIcons([...ICONS.previous, ...ICONS.wtr]);
  }

  getNamedSvgIcon(iconName: string): Observable<SVGElement> {
    return this._iconRegistry.getNamedSvgIcon(iconName);
  }

  getIconNames(): string[] {
    const allIcons = ICONS.wtr.reduce((all, current) => {
      return all.concat(current.icons);
    }, []);

    return allIcons;
  }

  private registerIcons(groups: any[]): void {
    groups.forEach((iconGroup) => {
      iconGroup.icons.forEach((iconName) => {
        this.registerIcon(iconName, iconGroup.path);
      });
    });
  }

  private registerIcon(iconName: string, basePath: string): void {
    const path = `${basePath}/${iconName}.svg`;
    this._iconRegistry.addSvgIcon(iconName, this._sanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
