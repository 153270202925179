import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { CalendarModeEnum } from 'src/app/common-modules/dependencies/alarms/calendar-mode.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-custom-calendar-filter-item';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './custom-calendar-filter-item.component.html',
  styleUrls: ['./custom-calendar-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => CustomCalendarFilterItemComponent),
    },
  ],
})
export class CustomCalendarFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy
{
  @Input() modeField = 'calendarMode';

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  labelsScope = 'wlm-shared.constants.generic.calendar-mode-mapping';
  defaultSelectedIds = [];
  // New version of selectedIds with enables us to also set additional params.
  // Instead of doing the params modification in the generic class, we produce the options with the
  // selected params already set.
  public idsParams: { [id: string]: { [key: string]: any } };
  @Input() set selectedIdsWithParams(idsParams: { [id: string]: { [key: string]: any } }) {
    if (!idsParams) {
      return;
    }

    this.idsParams = idsParams;
    if (!Object.keys(this.idsParams).length) {
      // For unselecting all, use standards mechanism.
      super.selectedIds = [];
    }

    // Now simulate the usual flow: set options and selected ids.
    this.data$$.next(this.getData$());
  }

  // Disable the usage of this input to avoid confusion.
  @Input() set selectedIds(_) {
    this._logService.error({
      msg: "Setting default values in this filter is done with the 'selectedIdsWithParams' input.",
    });
  }

  constructor(private _globalsService: GlobalsService, private _logService: LogService) {
    super();
  }

  ngOnInit(): void {
    // If there are selectedIds, the options will be loaded with the params included.
    if (!this.idsParams) {
      this.data$$.next(this.getData$());
    }

    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this._globalsService.getCalendars().pipe(
      map((calendars) =>
        calendars.map((c) => new FilterItemSelectOption(c.calendarId, c.calendarName))
      ),
      map((calendars) => {
        const results = calendars.map((item) => {
          // Include the params in the options.
          item.params = this.idsParams[item.value] ?? [];
          return item;
        });

        const resultSorted = this.sortCalendarsByLabel(results);

        return resultSorted;
      })
    );
  }

  private sortCalendarsByLabel(results: FilterItemSelectOption[]) {
    return results?.sort((c1, c2) => {
      if (c1?.label?.toLocaleLowerCase() > c2?.label?.toLocaleLowerCase()) {
        return 1;
      }

      if (c1?.label?.toLocaleLowerCase() < c2?.label?.toLocaleLowerCase()) {
        return -1;
      }

      return 0;
    });
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.selectedElements && this.selectedElements.length !== 0;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getStateFormatted(): Observable<string> {
    if (this.settings?.hideInputSummary) {
      return of('');
    }
    return forkJoin([
      this.localization.get(this.inputSummaryKey),
      this.localization.get(this.labelsScope),
    ]).pipe(
      map(([inputSummaryLabel, mapping]) => {
        if (this.selectedElements) {
          const labels = this.selectedElements.map((element) => {
            const mode = element.params ? element.params[this.modeField] : null;
            let suffix = '';
            if (mode === CalendarModeEnum.Inclusive) {
              suffix = `(${mapping.inclusive})`;
            }
            if (mode === CalendarModeEnum.Exclusive) {
              suffix = `(${mapping.exclusive})`;
            }

            return `${element.label} ${suffix}`;
          });

          const joined = labels.length !== 0 ? labels.join(', ') : null;
          if (joined) {
            return this.settings?.hideInputSummaryLabel ? joined : `${inputSummaryLabel}${joined}`;
          }
        }

        return null;
      })
    );
  }
}
