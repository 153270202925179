export class MVQueryDto {
  startDate!: Date;
  endDate!: Date;
  signalIds!: number[];

  get isValid(): boolean {
    const empty = this.startDate == null || this.endDate == null || this.signalIds == null;

    if (empty) {
      return false;
    }

    if (this.endDate <= this.startDate) {
      return false;
    }

    return this.signalIds.length > 0;
  }

  constructor(startDate: Date, endDate: Date, signalIds: number[]) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.signalIds = signalIds;
  }
}
