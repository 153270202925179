import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, filter, map, switchMap } from 'rxjs';

@Injectable()
export class HideNavbarsService {
  private readonly _hideTopBarParam = 'hideTopBar';
  private readonly _hideNavBarParam = 'hideNavBar';

  private _activatedRoute: ActivatedRoute;
  private _initReady$ = new ReplaySubject<void>(1);

  private readonly _queryParams$ = this._initReady$.pipe(
    switchMap(() => this._activatedRoute.queryParamMap)
  );

  readonly hideTopBar$: Observable<boolean> = this._queryParams$.pipe(
    filter((params) => params.has(this._hideTopBarParam)),
    map((params) => params.get(this._hideTopBarParam) === 'true')
  );

  readonly hideNavBar$: Observable<boolean> = this._queryParams$.pipe(
    filter((params) => params.has(this._hideNavBarParam)),
    map((params) => params.get(this._hideNavBarParam) === 'true')
  );

  init(activatedRoute: ActivatedRoute): void {
    this._activatedRoute = activatedRoute;
    this._initReady$.next();
    this._initReady$.complete();
  }
}
