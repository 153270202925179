import { Observable } from 'rxjs';

export class MapTooltipProperty {
  key: string;
  label?: string;
  labelKey?: string;
  categoryKey?: string;
  value: string;
  labelKey$?: Observable<string>;
  defaultLabelValue?: string;

  constructor(init: MapTooltipProperty) {
    Object.assign(this, init);
  }
}
