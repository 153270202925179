import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseMapper } from './base-mapper';
import { ToActiveMapper } from './mappers/to-active.mapper';
import { ToAlgorithmUoMMapper } from './mappers/to-algorithm-uom-mapper';
import { ToAnyUoMMapper } from './mappers/to-any-uom.mapper';
import { ToBooleanMapper } from './mappers/to-boolean.mapper';
import { ToFixedMapper } from './mappers/to-fixed.mapper';
import { ToJointStringsMapper } from './mappers/to-joint-strings.mapper';
import { ToReadableDateMapper } from './mappers/to-readable-date.mapper';
import { ToTranslatedStringMapper } from './mappers/to-translated-string-mapper';
import { ToUoMNameMapper } from './mappers/to-uom-name.mapper';
import { ToUoMMapper } from './mappers/to-uom.mapper';

function provideBaseMappers(services: any[]): any[] {
  return services.map((service) => ({
    provide: BaseMapper,
    multi: true,
    useClass: service,
  }));
}

const mappers = [
  ToBooleanMapper,
  ToUoMNameMapper,
  ToUoMMapper,
  ToAnyUoMMapper,
  ToReadableDateMapper,
  ToActiveMapper,
  ToFixedMapper,
  ToJointStringsMapper,
  ToAlgorithmUoMMapper,
  ToTranslatedStringMapper,
];

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule],
  providers: [...mappers, ...provideBaseMappers(mappers)],
})
export class GenericMappersModule {
  constructor() {}
}
