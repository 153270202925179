<div #gridContainer class="wtr-fx-layout-column wtr-fx-flex-grow layout" wlmGridHorizWheelScroll>
  <wlm-time-control-bar
    *ngIf="gridSettings?.showHistoricalComparison && initialControlDates"
    [dates]="initialControlDates"
    (datesChange)="onTimeControlDatesChange($event)"
    (isInThePastChange)="onIsInThePastChange($event)"
  ></wlm-time-control-bar>
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div
    kendoTooltip
    showOn="none"
    [tooltipTemplate]="template"
    filter=".k-grid td"
    class="display-adjustment"
    (mouseover)="showTooltip($event)"
  >
    <kendo-grid
      class="wtr-fx-flex-grow"
      *ngIf="
        gridFiltersForBinding &&
        gridSettings &&
        (gridSettings.showHistoricalComparison ? timeControlDates : true)
      "
      wlmGridBinding
      [ngClass]="{
        'resize-for-time-control': gridSettings.showHistoricalComparison,
        'keep-select-all-width': gridSettings.showSelectAllColumn
      }"
      [isHistorical]="gridSettings.showHistoricalComparison"
      [conditionalSorting]="applyConditionalSorting ? conditionalSorting : []"
      [additionalFilters]="additionalFilters"
      [initialColumnFilter]="initialColumnFilter"
      [timeControlDates]="timeControlDates"
      [serviceName]="gridSettings.service"
      [subscriptionTag]="subscriptionTag"
      [defaultSortDescriptors]="gridSettings.sort"
      [addHttpOptions]="addHttpOptions"
      [cacheOpts]="gridSettings.cacheOpts"
      [filters]="gridFiltersForBinding"
      [_odataService]="service"
      [count]="gridSettings.count"
      [pageSize]="gridSettings.pageSize"
      [pageable]="gridSettings.pageable"
      [sortable]="gridSettings.sortable"
      [sort]="sort"
      [filterable]="gridSettings.filterable"
      [resizable]="gridSettings.resizable"
      [reorderable]="gridSettings.reorderable"
      [navigatable]="gridSettings.navigatable"
      [kendoGridSelectBy]="gridSettings.selectByFieldName"
      [selectable]="gridSettings.selectable"
      [ignoreCase]="gridSettings.ignoreCase"
      [utcDates]="gridSettings.utcDates"
      [selectedKeys]="currentSelection"
      (cellClick)="cellClickHandler($event)"
      (totalCountChange)="setTotalCount($event)"
      (selectedKeysChange)="selectedKeysChange($event)"
      (filterChange)="filterChange($event)"
      (pageChange)="clearRowSelected()"
      (sortChange)="sortChange($event)"
      (gridDataLoaded)="ongridDataLoaded()"
      (columnLockedChange)="onColumnLockedChange($event)"
      (columnReorder)="onColumnReorderChange($event)"
      [rowClass]="rowCallback"
    >
      <wlm-select-all-column
        *ngIf="gridSettings.showSelectAllColumn"
        [backgroundColorMapping]="backgroundColorMapping"
        [gridSettings]="gridSettings"
        [useCellStyle]="useCellStyle"
        [width]="30"
        [style]="'default' | columnStyle"
        [headerStyle]="'default' | columnStyle"
        [locked]="true"
        [selectAllIsIndeterminate]="selectAllIsIndeterminate"
        [(selectAllValue)]="selectAllValue"
        (selectAllChanged)="onSelectAllChange($event)"
        [showSelectAllHeader]="gridSettings.showSelectAllHeader"
      >
      </wlm-select-all-column>
      <ng-container *ngFor="let column of visibleColumns">
        <wlm-column
          *ngIf="
            column.type !== 'date' &&
            column.type !== 'uom' &&
            column.type !== 'boolean' &&
            column.type !== 'enumerable' &&
            column.type !== 'custom' &&
            column.type !== 'currency' &&
            column.type !== 'image' &&
            column.type !== 'maxmin' &&
            column.type !== 'alarm-counter' &&
            column.type !== 'export-only' &&
            column.type !== 'duration' &&
            column.type !== 'alarm-severity' &&
            column.type !== 'user' &&
            column.type !== 'selector' &&
            column.type !== 'internal' &&
            column.type !== 'button' &&
            column.visible
          "
          [field]="column.field"
          [title]="column.title"
          [format]="column.format"
          [digitInfo]="defaultDigitsInfo"
          [currentLocale]="currentLocale"
          [width]="column.width"
          [filter]="column.type === 'hierarchy' ? 'text' : column.type"
          [backgroundColorMapping]="backgroundColorMapping"
          [gridSettings]="gridSettings"
          [comparisonIsActive]="comparisonIsActive"
          [useCellStyle]="useCellStyle"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [columnSettings]="column"
          [locked]="column.locked === true"
        >
        </wlm-column>

        <wlm-selector-column
          *ngIf="column.type === 'selector'"
          [field]="column.field"
          [title]="column.title"
          [format]="column.format"
          [width]="column.width"
          [filter]="'text'"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [columnSettings]="column"
          [locked]="column.locked === true"
        >
        </wlm-selector-column>

        <wlm-date-time-column
          *ngIf="column.type == 'date' && column.visible"
          [field]="column.field"
          [title]="column.title"
          [width]="column.width"
          [format]="column.format"
          [useCellStyle]="useCellStyle"
          [backgroundColorMapping]="backgroundColorMapping"
          [type]="column.type"
          [gridSettings]="gridSettings"
          [columnSettings]="column"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [valueDefinedAsUtc]="column.valueDefinedAsUtc"
          [includeNullFilterOperators]="column.includeNullFilterOperators"
          [locked]="column.locked === true"
        >
        </wlm-date-time-column>

        <wlm-currency-column
          *ngIf="column.type === 'currency' && column.visible && currencySymbol"
          [field]="column.field"
          [format]="column.format"
          [width]="column.width"
          filter="numeric"
          [backgroundColorMapping]="backgroundColorMapping"
          [gridSettings]="gridSettings"
          [useCellStyle]="useCellStyle"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [unitFormat]="getUnitFormat(column)"
          [baseColumnTitle]="column | columnTitle | async"
          [comparisonIsActive]="comparisonIsActive"
          [columnSettings]="column"
          [locked]="column.locked === true"
        >
        </wlm-currency-column>

        <wlm-uom-column-component
          *ngIf="column.type === 'uom' && column.visible"
          [field]="column.field"
          [title]="column.title"
          [format]="column.format"
          [filter]="column.type"
          [useCellStyle]="useCellStyle"
          [columnSettings]="column"
          [gridSettings]="gridSettings"
          [unitFormat]="getUnitFormat(column)"
          [width]="250"
          [hierarchyElementTypeIdField]="column.uomHierarchyElementTypeIdField"
          [hierarchyElementTypeId]="column.uomHierarchyElementTypeId"
          [comparisonIsActive]="comparisonIsActive"
          [algorithmShortName]="column.algorithmShortName"
          [timeAggregationId]="column.timeAggregationId"
          [dimensionTypeId]="column.dimensionTypeId"
          [decimalPositions]="column.uomDecimalPositions"
          baseTitle="{{ column.title }}"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          (headerTextChanged)="headerTextChanged()"
          [locked]="column.locked === true"
        ></wlm-uom-column-component>

        <wlm-boolean-column
          *ngIf="column.type == 'boolean' && column.visible"
          [field]="column.field"
          [title]="column.title"
          [gridSettings]="gridSettings"
          [backgroundColorMapping]="backgroundColorMapping"
          [useCellStyle]="useCellStyle"
          [width]="column.width"
          [useImages]="column.useImages"
          [columnSettings]="column"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [locked]="column.locked === true"
        >
        </wlm-boolean-column>

        <wlm-enumerable-column
          *ngIf="column.type == 'enumerable' && column.visible"
          [field]="column.field"
          [title]="column.title"
          [useCellStyle]="useCellStyle"
          [gridSettings]="gridSettings"
          [backgroundColorMapping]="backgroundColorMapping"
          [width]="column.width"
          [columnSettings]="column"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [locked]="column.locked === true"
        >
        </wlm-enumerable-column>

        <wlm-image-column
          *ngIf="column.type == 'image' && column.visible"
          [field]="column.field"
          [title]="column.title"
          [gridSettings]="gridSettings"
          [backgroundColorMapping]="backgroundColorMapping"
          [useCellStyle]="useCellStyle"
          [width]="column.width"
          [columnSettings]="column"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [locked]="column.locked === true"
        ></wlm-image-column>

        <wlm-maxmin-column-component
          *ngIf="column.type === 'maxmin' && column.visible"
          [field]="column.field"
          [format]="column.format"
          [filter]="column.type"
          [title]="column.title"
          [useCellStyle]="useCellStyle"
          [columnSettings]="column"
          [gridSettings]="gridSettings"
          [unitFormat]="getUnitFormat(column)"
          [width]="250"
          [algorithmShortName]="column.algorithmShortName"
          [hierarchyElementTypeIdField]="column.uomHierarchyElementTypeIdField"
          [hierarchyElementTypeId]="column.uomHierarchyElementTypeId"
          [hierarchyElementIdField]="column.maxminHierarchyElementIdField"
          [networkElementIdField]="column.maxminNetworkElementIdField"
          [translationPrefix]="column.maxminTranslationPrefix"
          [maxFieldName]="column.maxminMaxFieldName"
          [minFieldName]="column.maxminMinFieldName"
          [elementAttributeType]="column.elementAttributeType"
          [maxAttributeTypeId]="column.maxAttributeTypeId"
          [minAttributeTypeId]="column.minAttributeTypeId"
          [timeAggregationId]="column.timeAggregationId"
          [dimensionTypeId]="column.dimensionTypeId"
          [decimalPositions]="column.uomDecimalPositions"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          (headerTextChanged)="headerTextChanged()"
          [locked]="column.locked === true"
        ></wlm-maxmin-column-component>

        <wlm-eligibility-column
          *ngIf="column.type === 'custom' && column.field === 'isEligibleByUser' && column.visible"
          [columnSettings]="column"
          [selectedRows]="selectedRows"
          [gridSettings]="gridSettings"
          [field]="column.field"
          [title]="column.title"
          [width]="column.width"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          (eligbilityPopupClosed)="reloadGrid()"
          [locked]="column.locked === true"
          [useLatestCalculationDay]="useLatestCalculationDay"
          [elegibilityDate]="timeControlDates?.baseGridDate"
        >
        </wlm-eligibility-column>

        <kendo-grid-column
          *ngIf="
            column.type === 'custom' && column.field === 'firstDayStatusChanged' && column.visible
          "
          [field]="column.field"
          [title]="column.title"
          [format]="column.format"
          [width]="column.width"
          filter="date"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [locked]="column.locked === true"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>
              {{ dataItem[column.field] | date : column.format }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <wlm-alarm-counter-column
          *ngIf="column.type === 'alarm-counter'"
          [field]="column.field"
          [format]="column.format"
          [title]="column.title"
          [width]="column.width"
          [columnSettings]="column"
          [filterable]="false"
          [sortable]="gridSettings.sortable"
          [headerStyle]="'default' | columnStyle"
          [style]="column.type | columnStyle"
          [alarmCriticalCounterField]="column.alarmCriticalCounterField"
          [alarmHighCounterField]="column.alarmHighCounterField"
          [alarmMediumCounterField]="column.alarmMediumCounterField"
          [alarmLowCounterField]="column.alarmLowCounterField"
          [locked]="column.locked === true"
        >
        </wlm-alarm-counter-column>

        <wlm-alarm-severity-column
          *ngIf="column.type === 'alarm-severity'"
          [format]="column.format"
          [title]="column.title"
          [field]="column.field"
          [columnSettings]="column"
          [gridSettings]="gridSettings"
          [width]="column.width"
          [filterable]="true"
          [headerStyle]="'default' | columnStyle"
          [style]="column.type | columnStyle"
          [locked]="column.locked === true"
        >
        </wlm-alarm-severity-column>
        <wlm-date-duration-column
          *ngIf="column.type === 'duration'"
          [format]="column.format"
          [title]="column.title"
          [field]="column.field"
          [columnSettings]="column"
          [gridSettings]="gridSettings"
          [width]="column.width"
          [filterable]="false"
          [sortable]="true"
          [startDateFieldName]="column.durationStartDateField"
          [endDateFieldName]="column.durationEndDateField"
          [headerStyle]="'default' | columnStyle"
          [style]="column.type | columnStyle"
          [locked]="column.locked === true"
        >
        </wlm-date-duration-column>

        <wlm-user-column
          *ngIf="column.type === 'user'"
          [field]="column.field"
          [title]="column.title"
          [format]="column.format"
          [width]="column.width"
          filter="text"
          [backgroundColorMapping]="backgroundColorMapping"
          [gridSettings]="gridSettings"
          [useCellStyle]="useCellStyle"
          [style]="column.type | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [columnSettings]="column"
          [sortable]="true"
          [locked]="column.locked === true"
        >
        </wlm-user-column>
        <wlm-button-column
          *ngIf="column.type === 'button'"
          [title]="column.title"
          [iconName]="column.iconName"
          [width]="column.width"
          [backgroundColorMapping]="backgroundColorMapping"
          [gridSettings]="gridSettings"
          [useCellStyle]="useCellStyle"
          [style]="'default' | columnStyle"
          [headerStyle]="'default' | columnStyle"
          [columnSettings]="column"
          [sortable]="false"
          [locked]="column.locked === true"
        >
        </wlm-button-column>
      </ng-container>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-total="total"
        let-currentPage="currentPage"
      >
        <div class="page-info-container">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>

          <div style="display: flex; flex-direction: row" *ngIf="gridSettings?.useSmallPager">
            <div class="page-number">
              {{ currentPage }}
            </div>
            <div style="align-self: center; color: #d8d8d8">|</div>
            <div class="page-number">
              {{ totalPages }}
            </div>
          </div>
          <kendo-pager-input *ngIf="!gridSettings?.useSmallPager"></kendo-pager-input>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>

          <kendo-pager-page-sizes
            *ngIf="!gridSettings?.useSmallPager"
            class="custom-k-page-sizes"
            [pageSizes]="gridSettings?.pageable?.pageSizes"
          ></kendo-pager-page-sizes>
          <wlm-selection-menu
            class="selected-items-info"
            *ngIf="!gridSettings?.useSmallPager"
            [showSelectAllColumn]="gridSettings.showSelectAllColumn"
            [totalRows]="total"
            [selectedItems]="selectedItems"
            [itemLabelKey]="gridSettings?.itemLabelKey ?? gridSettings.selectByFieldName"
            (selectedItemsChanged)="onSelectedItemsChanged($event)"
            [showSelectedList]="showSelectedList"
            [showSelectedItems]="showSelectedItems"
          ></wlm-selection-menu>
        </div>
      </ng-template>
      <kendo-grid-messages
        *ngIf="!gridSettings?.useSmallPager"
        [pagerItemsPerPage]="''"
        [pagerItems]="''"
        [pagerOf]="'/'"
        [pagerPage]="''"
      >
      </kendo-grid-messages>
      <ng-template kendoGridNoRecordsTemplate>
        <wlm-no-results [showNoResult]="true"></wlm-no-results>
      </ng-template>
      <kendo-grid-excel
        [fileName]="gridSettings.excelFileName + '.xlsx'"
        [fetchData]="gridBinding?.allData"
      >
        <ng-container *ngFor="let column of exportableColumns">
          <kendo-excelexport-column
            [field]="column.field"
            [title]="column.title"
          ></kendo-excelexport-column>
        </ng-container>
      </kendo-grid-excel>
      <kendo-grid-pdf
        [fileName]="gridSettings.excelFileName + '.pdf'"
        paperSize="A4"
        [scale]="0.3"
        [repeatHeaders]="true"
        [landscape]="true"
      >
        <kendo-grid-pdf-margin
          top="1cm"
          left="0.5cm"
          right="0.5cm"
          bottom="1cm"
        ></kendo-grid-pdf-margin>

        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
          <div class="page-template">
            <div class="header">
              <div class="pdf-title">
                <h3>{{ gridSettings.excelFileName }}</h3>
              </div>
            </div>
            <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </ng-template>
      </kendo-grid-pdf>
    </kendo-grid>
  </div>
</div>
