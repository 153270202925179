<div
  *ngIf="form"
  [formGroup]="form"
  class="custom-map-search"
  [ngStyle]="{ opacity: autocomplete?.isOpen ? 1 : '' }"
>
  <mat-form-field class="search-field map-search-box" appearance="outline">
    <input
      matInput
      formControlName="search"
      (keydown)="onKeyDownEvent($event)"
      [matAutocomplete]="auto"
      [placeholder]="'common.search' | translate"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelectOption($event)"
      (closed)="onClose()"
      [displayWith]="displaySearchOption"
      autoActiveFirstOption
    >
      <mat-option
        *ngIf="!isLoading && coordinatesDetected"
        [value]="coordinatesFormatted"
        class="map-search-option"
      >
        <img
          class="option-img"
          aria-hidden
          alt="default"
          [src]="defaultIconPath"
          [height]="iconSize"
        />
        <span class="option-message">{{
          T_SCOPE + '.coordinates-navigation' | translate : { coordinates: coordinatesFormatted }
        }}</span>
      </mat-option>
      <mat-option
        *ngIf="!isLoading && gisElements && gisElements.length === 0 && !coordinatesDetected"
      >
        <span class="option-message">{{ 'common.messages.no-results' | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let gisElement of gisElements" class="item" [value]="gisElement">
        <span *ngIf="getIcon(gisElement); let gisIcon">
          <wlm-svg
            class="svg-icon"
            [width]="iconSize"
            [height]="iconSize"
            [path]="gisIcon.path"
            [svgFill]="gisIcon.color"
          ></wlm-svg>
        </span>
        <span *ngIf="!getIcon(gisElement)">
          <img
            class="option-img"
            aria-hidden
            alt="default"
            [src]="defaultIconPath"
            [height]="iconSize"
          />
        </span>
        <span>{{ gisElement.gisElementKey }}</span>
      </mat-option>
      <mat-option *ngIf="isLoading">
        <span class="option-message">{{ 'common.loading' | translate }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
