import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class HierarchyTreeFISettings extends BaseFilterItemSettings {
  fieldName: string;
  selectedIds: string[];

  constructor(init?: Readonly<HierarchyTreeFISettings>) {
    super(init);
    Object.assign(this, init);
  }
}
