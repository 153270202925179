import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class PrioritisationGridFilterConfiguration {
  persistencyArea: string;
  hierarchyElementIdFieldName: string;
  hierarchyElementId: string[];
  hierarchyElementFamilyFieldName: string;
  hierarchyElementFamily: string;
  filters: IFilter[];

  constructor(
    persistencyArea: string,
    hierarchyElementIdFieldName: string,
    hierarchyElementFamilyFieldName: string,
    hierarchyElementId?: string[],
    hierarchyElementFamily?: string,
    filters?: IFilter[]
  ) {
    this.persistencyArea = persistencyArea;
    this.hierarchyElementIdFieldName = hierarchyElementIdFieldName;
    this.hierarchyElementFamilyFieldName = hierarchyElementFamilyFieldName;
    this.hierarchyElementId = hierarchyElementId;
    this.hierarchyElementFamily = hierarchyElementFamily;
    this.filters = filters;
  }
}
