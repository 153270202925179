<wlm-popup-wrapper [title]="dialogData.title" (popupClose)="onButtonClose($event)">
  <ng-container popup-content>
    <wlm-he-tree-family-date-range-filter
      *ngIf="filterSettings"
      class="filter form-filter align-lock-filters"
      [settings]="filterSettings"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      [disableDatesGroup]="!lockDates"
      [disableHierarchyGroup]="!lockHierarchy"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
      (autoLoad)="onCheckAutoload()"
    >
      <div class="filter-button" date-filter-button>
        <button
          mat-icon-button
          onclick="this.blur()"
          [disabled]="!canLoad"
          (click)="onLockDates()"
          [disableRipple]="true"
          [matTooltip]="T_SCOPE + (lockDates ? '.locked-button' : '.unlocked-button') | translate"
        >
          <wlm-icon *ngIf="lockDates" icon="lock"></wlm-icon>
          <wlm-icon *ngIf="!lockDates" icon="lock_open"></wlm-icon>
        </button>
      </div>
      <div class="filter-button" he-filter-button>
        <button
          mat-icon-button
          color="primary"
          class="filter-button"
          onclick="this.blur()"
          [disabled]="!canLoad"
          (click)="onLockHierarchy()"
          [disableRipple]="true"
          [matTooltip]="
            T_SCOPE + (lockHierarchy ? '.locked-button' : '.unlocked-button') | translate
          "
        >
          <wlm-icon *ngIf="lockHierarchy" icon="lock"></wlm-icon>
          <wlm-icon *ngIf="!lockHierarchy" icon="lock_open"></wlm-icon>
        </button>
      </div>
    </wlm-he-tree-family-date-range-filter>

    <wlm-bi-default-params-form
      [type]="dialogData.paramsType"
      [params]="dialogData.params"
      style="width: 100%"
      (paramsChange)="onParamsChanged($event)"
      (isValid)="onIsFormValid($event)"
    ></wlm-bi-default-params-form>
  </ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      color="primary"
      onclick="this.blur()"
      [disabled]="!canLoad || !isFormValid"
      (click)="applyFiltersAndParams()"
    >
      {{ 'common.apply' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
