import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { forkJoin } from 'rxjs';
import { AlarmClassTypes } from 'src/app/common-modules/dependencies/alarms/alarm-class-types.enum';
import { AlarmsSummaryDto } from 'src/app/common-modules/dependencies/alarms/alarms-summary.dto';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { SharedConstantsService } from '../../../../common-modules/shared/constants/shared-constants.service';
import { AlarmSeverity } from '../../shared/alarms/alarms-severity.enum';
import { AlarmsTabFilter } from '../../shared/alarms/alarms-tab-filter';
import { ActiveAlarmsGridService } from '../active-alarms-grid/active-alarms.service';

const COMPONENT_SELECTOR = 'wlm-alarms-tab-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-tab-widget.component.html',
  styleUrls: ['./alarms-tab-widget.component.scss'],
})
export class AlarmsTabWidgetComponent implements OnInit {
  @Input() isNetworkElement = false;

  private _elementId: string;
  public get elementId(): string {
    return this._elementId;
  }
  @Input() public set elementId(value: string) {
    this._elementId = value;
    this.resetFilter();
    if (this.elementId) {
      this._initButtons();
    }
  }

  // Wether the buttons should be disabled if the counter is zero.
  @Input() disabledIfEmpty = true;
  @Input() disableUntoggle = false;
  @Input() excludeChildren = false;

  @Output() filter = new EventEmitter<AlarmsTabFilter>();
  @Output() ready = new EventEmitter<void>();
  selectedClassTypeId: number;

  items: { [classType: number]: AlarmsSummaryDto } = {};
  classTypesNames: { [key: number]: string } = {};

  alarmSeverity = AlarmSeverity;
  classTypesToShow = [];
  icons = {
    [AlarmClassTypes.MNF]: 'show_chart',
    [AlarmClassTypes.DDI]: 'login',
    [AlarmClassTypes.DL]: 'logout',
    [AlarmClassTypes.Flow]: 'waves',
    [AlarmClassTypes.Pressure]: 'radio_button_checked',
    [AlarmClassTypes.Level]: 'gradient',
    [AlarmClassTypes.Others]: 'equalizer',
  };

  allClassTypesToShow = [
    AlarmClassTypes.MNF,
    AlarmClassTypes.DDI,
    AlarmClassTypes.DL,
    AlarmClassTypes.Flow,
    AlarmClassTypes.Pressure,
    AlarmClassTypes.Level,
    AlarmClassTypes.Others,
  ];
  neClassTypesToShow = [
    AlarmClassTypes.Flow,
    AlarmClassTypes.Pressure,
    AlarmClassTypes.Level,
    AlarmClassTypes.Others,
  ];

  maxAlarmsCount = 99;
  matBadgeSize = 'small';
  matBadgePosition = 'above after';
  disableRipple = true;

  constructor(
    private _alarmsService: ActiveAlarmsGridService,
    private _sharedConstantsService: SharedConstantsService,
    private _objectHelperService: ObjectHelperService
  ) {}

  ngOnInit(): void {
    this.subscribeRefreshFromService();
  }

  private subscribeRefreshFromService() {
    this._alarmsService
      .refreshAlarmCountersObservable()
      .pipe(untilDestroyed(this))
      .subscribe((undoSelection) => {
        if (this.elementId) {
          this._initButtons(undoSelection);
        }
      });
  }

  private _initButtons(undoSelection = false): void {
    const params = this.isNetworkElement
      ? { networkElementId: this.elementId }
      : { hierarchyElementId: this.elementId };

    this.classTypesToShow = this.isNetworkElement
      ? this.neClassTypesToShow
      : this.allClassTypesToShow;

    forkJoin({
      summaries: this._alarmsService.getNonAckAlarmsSummary(params, this.excludeChildren),
      classTypesNames: this._sharedConstantsService.getAlarmClassTypesMapping(),
    }).subscribe(({ summaries, classTypesNames }) => {
      const visibleSummaries = summaries.filter((x) =>
        this.classTypesToShow.find((classId) => classId === x.alarmClassId)
      );
      this.items = {};
      visibleSummaries.forEach((summary) => (this.items[summary.alarmClassId] = summary));
      this.classTypesNames = this._objectHelperService.mapToPlainObject(classTypesNames);
      this.ready.emit();

      if (this.selectedClassTypeId) {
        const anyAlarm = asEnumerable(visibleSummaries).Any(
          (x) => x.alarmClassId == this.selectedClassTypeId
        );
        if (!anyAlarm || undoSelection) {
          this.resetFilter();
        }
      }
    });
  }

  trackByItem = (_, classType: number) => classType;

  onSelect(classType: number, buttonRef): void {
    let filter;
    if (this.selectedClassTypeId && this.selectedClassTypeId === classType) {
      // Remove the filter if it was selected.
      if (!this.disableUntoggle) {
        filter = new AlarmsTabFilter({ alarmClassId: null, isAcknowledge: null });
      }
    } else {
      filter = new AlarmsTabFilter({ alarmClassId: classType, isAcknowledge: false });
    }

    if (filter) {
      buttonRef.focus();
      this.filter.emit(filter);
      this.selectedClassTypeId = filter.alarmClassId;
    }
  }

  private resetFilter() {
    this.selectedClassTypeId = null;
    this.filter.emit(new AlarmsTabFilter({ alarmClassId: null, isAcknowledge: null }));
  }
}
