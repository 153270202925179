// prettier-ignore
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Observable } from 'rxjs';
import { IAlgorithmDto } from '../../shared/model/algorithm/algorithm.dto';
import { AlgorithmsService } from '../../shared/services/algorithms.service';

import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@UntilDestroy()
@Component({
  selector: 'wlm-uom-column-component',
  templateUrl: './uom-column.component.html',
  styleUrls: ['./uom-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => UomColumnComponent),
    },
  ],
})
export class UomColumnComponent extends WlmColumnComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @Input() algorithmShortName?: string;
  @Input() hierarchyElementTypeIdField: string;
  @Input() hierarchyElementTypeId: string;
  @Input() timeAggregationId?: number;
  @Input() dimensionTypeId?: number;
  @Input() decimalPositions?: number;
  @Input() baseTitle?: string;
  @Input() unitFormat?: string;
  @Output() conversionFactorsChange = new EventEmitter<number[]>();
  @Output() decimalPositionsChange = new EventEmitter<number>();

  algorithm: IAlgorithmDto;
  currentConversionFactor?: number;

  cellVariablesAvailable = false;
  dataProcessed = false;
  conversionFactor = 1;

  @ViewChild('cellVariables') public cellVariables: any;

  // Processed fields
  convertedValue$: Observable<string>;
  justifyContentStyle: string;
  backgroundStyle: string;
  fontWeigthStyle$: Observable<string>;
  tooltip: string;
  currentUnitLabel: string;

  loaded = false;

  constructor(private algorithmsService: AlgorithmsService, _cd: ChangeDetectorRef) {
    super(_cd);
  }

  ngOnInit() {
    if (this.algorithmShortName !== undefined) {
      this.algorithmsService.getAlgorithm(this.algorithmShortName).subscribe((algorithm) => {
        if (!algorithm) {
          console.error(`Algorithm ${this.algorithmShortName} not found or disabled`);
        }

        this.algorithm = algorithm;
        this.timeAggregationId = algorithm?.timeAggregationId;
        this.dimensionTypeId = algorithm?.dimensionTypeId;
        this.loaded = true;
        this._cd.detectChanges();
      });
    } else {
      this.loaded = true;
    }

    this.baseTitle = this.baseTitle ?? this.field.toUpperCase();
    //this.title = this.baseTitle;
    this._cd.detectChanges();
  }

  onConversionFactorChange(value) {
    this.conversionFactor = value;
  }

  calculatedUnitChange(unitLabel: string) {
    this.currentUnitLabel = unitLabel;
  }
}
