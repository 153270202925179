<div *ngIf="auditId" class="attributes-container">
  <div class="attributes-item" *ngFor="let attribute of auditAttributes">
    <mat-form-field required>
      <mat-label>{{ T_SCOPE + '.fields.' + attribute.auditAttributeTypeId | translate }}</mat-label>
      <textarea
        matInput
        readonly
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        >{{ attribute.auditAttributeValue.trim() }}</textarea
      >
    </mat-form-field>
  </div>
</div>
