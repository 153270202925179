<wlm-placeholder-overlay
  *ngIf="!(selectedDimension && selectedFamily)"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-uom-configuration
  *ngIf="selectedDimension && selectedFamily"
  [pageId]="pageId"
  [dimension]="selectedDimension"
  [hierarchyFamily]="selectedFamily"
></wlm-uom-configuration>
