import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { EditableChartDateRange } from 'src/app/common-modules/wlm-charts/core/models/editable-chart/editable-chart-date-range';
import { EditableChartRangeUpdate } from 'src/app/common-modules/wlm-charts/core/models/editable-chart/editable-chart-range-update';
import { EditableChartValueRange } from 'src/app/common-modules/wlm-charts/core/models/editable-chart/editable-chart-value-range';
import { DataValidationStateFields } from '../../state/data-validation-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-validation-chart-selection-widget';
export const DATA_VALIDATION_CHART_SELECTION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-chart-selection-widget.component.html',
  styleUrls: ['./data-validation-chart-selection-widget.component.scss'],
})
export class DataValidationChartSelectionWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  selectedPoint: number;

  widgetInstanceKey: string;

  get componentName(): string {
    return 'DataValidationChartSelectionWidgetComponent';
  }

  chartStartDate: Date;
  chartEndDate: Date;
  valueRanges: EditableChartValueRange[];
  dateRanges: EditableChartDateRange[];
  valueCartToggle: boolean = false;
  dateCartToggle: boolean = false;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _dateHelperService: DateHelperService
  ) {
    super(injector, widgetSettings);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    this.listenExternalChanges(DataValidationStateFields.selectedPoint).subscribe((point: any) => {
      if (point) {
        this.selectedPoint = point;
        return;
      }

      this.selectedPoint = null;
    });

    this.listenExternalChanges(DataValidationStateFields.selectedValueRange).subscribe(
      (selectedValueRanges: EditableChartRangeUpdate) => {
        //update changes only from external components
        if (selectedValueRanges?.sender !== this.componentName) {
          this.valueRanges = selectedValueRanges?.range as EditableChartValueRange[];
        }
      }
    );

    this.listenExternalChanges(DataValidationStateFields.toggleValueRangeCart).subscribe(
      (toggle: any) => {
        if (toggle !== null && toggle !== undefined) {
          this.valueCartToggle = toggle;

          if (toggle) {
            this._state.dispatch(
              new SetValueAction({
                fieldName: DataValidationStateFields.toggleDateRangeCart,
                value: !toggle,
              })
            );
          }
        }
      }
    );

    this.listenExternalChanges(DataValidationStateFields.selectedDateRange).subscribe(
      (selectedValueRanges: EditableChartRangeUpdate) => {
        //update changes only from external components
        if (selectedValueRanges?.sender !== this.componentName) {
          this.dateRanges = selectedValueRanges?.range as EditableChartDateRange[];
        }
      }
    );

    this.listenExternalChanges(DataValidationStateFields.toggleDateRangeCart).subscribe(
      (toggle: any) => {
        if (toggle !== null && toggle !== undefined) {
          this.dateCartToggle = toggle;

          if (toggle) {
            this._state.dispatch(
              new SetValueAction({
                fieldName: DataValidationStateFields.toggleValueRangeCart,
                value: !toggle,
              })
            );
          }
        }
      }
    );

    this._state
      .select(new AppliedFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (filters: DataBindingFilters) => this.onApplyChartFilters(filters),
      });
  }

  onValueRangesChanged(ranges) {
    const rangeInfo = new EditableChartRangeUpdate({
      range: ranges,
      sender: this.componentName,
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.selectedValueRange,
        value: rangeInfo,
      })
    );
  }

  onApplySelection(selection) {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.selectedValidationToApply,
        value: selection,
      })
    );
  }

  onDateRangesChanged(ranges) {
    const rangeInfo = new EditableChartRangeUpdate({
      range: ranges,
      sender: this.componentName,
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.selectedDateRange,
        value: rangeInfo,
      })
    );
  }

  private onApplyChartFilters(chartFilters: DataBindingFilters) {
    const dateRange = chartFilters?.filters?.get('dateRange');
    if (!dateRange) {
      return;
    }

    const dates = dateRange.getFiltersValues();

    this.chartStartDate = dates.get('startDate');
    this.chartEndDate = this._dateHelperService.addSeconds(dates.get('endDate'), -1);
  }

  private listenExternalChanges(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
