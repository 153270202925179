<mat-form-field
  class="chip-list custom-chip-inline"
  [appearance]="fieldAppearance"
  [ngClass]="{
    'ng-invalid mat-form-field-invalid': required && inputCtrl.touched && !elements.length
  }"
>
  <mat-label>{{ title }}<span *ngIf="required"> *</span></mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let element of elements"
      [removable]="removable"
      (removed)="remove(element.value)"
    >
      {{ element.label }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input
      [placeholder]="placeHolderText"
      #elementInput
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (input)="onInputChange($event)"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      class="custom-mat-option"
      *ngFor="let element of filteredElements"
      [value]="element.value"
    >
      <span>{{ element.label }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <wlm-validation-messages
      [showErrors]="{
        required: required && inputCtrl.touched && !elements.length
      }"
    ></wlm-validation-messages>
  </mat-error>
</mat-form-field>
