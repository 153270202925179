import { BaseGenericCartesianChartSettings } from './base-generic-cartesian-chart-settings';
import { GCartesianCustomChartSerie } from './g-cartesian-custom-chart-series';

export class GenericCartesianCustomChartSettings extends BaseGenericCartesianChartSettings {
  series: GCartesianCustomChartSerie[];

  constructor(init: Readonly<GenericCartesianCustomChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
