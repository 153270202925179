import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { DynamicFormGroupCategories } from 'src/app/common-modules/dependencies/ne-configuration/dynamic-form-group-categories';
import { DynamicFormAdditionalSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSourceSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { FieldsByCategory } from 'src/app/common-modules/dynamic-forms/models/fields-by-category';
import { DynamicFormHelperService } from 'src/app/common-modules/dynamic-forms/services/dynamic-form-helper.service';
import { DynamicFormService } from 'src/app/common-modules/dynamic-forms/services/dynamic-form.service';
import {
  SetFormAdditionalSettingsAction,
  SetFormDefinitionsAction,
  SetFormUIValuesAction,
} from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.actions';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';

/**
 * Encapsulates the code of loading a form group and dispatching the correct actions.
 */

@Injectable()
export class AttributeFormGroupService {
  private _state: ReduxStateService;
  private _scopeSettings: StateScopeSettings;
  private _initialized = false;
  private _previousFormDefinition: DynamicFormGroupCategories;
  setLoading: (loading: boolean) => void;

  constructor(
    private readonly _dynamicFormService: DynamicFormService,
    private readonly _dynamicFormHelperService: DynamicFormHelperService,
    private readonly _logService: LogService,
    private readonly _spinnerService: SpinnerService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  /**
   * The init method must be called before building the form group.
   */
  init({
    state,
    scopeSettings,
  }: {
    state: ReduxStateService;
    scopeSettings: StateScopeSettings;
  }): void {
    this._state = state;
    this._scopeSettings = scopeSettings;
    this._initialized = true;
  }

  /**
   * If the response includes isCached = true, we should not trigger a reload for the form group.
   */
  dispatchFormGroup(
    source: DynamicFormSourceSettings,
    additionalSettings: DynamicFormAdditionalSettings
  ): Observable<{
    visibleCategories: string[];
    isCached: boolean;
  }> {
    if (!this._initialized) {
      this._logService.error({
        msg: 'The service must be initialized before loading the form group.',
      });
      return of(null);
    }

    this.setLoading(true);
    // Dispatch it because the individual forms also need this info.
    this.dispatchAdditionalSettings(additionalSettings);
    const isCached = this.isCachedSource(source);

    return this.loadFormDefinitions(source, isCached).pipe(
      finalize(() => this.setLoading(false)),
      switchMap((formSettings) => {
        // Send the configs to ReactiveState instead of hardcoding them in the layout.
        this.dispatchFormDefinitions(formSettings);

        // Send the layout the categories so that they can load its own config.
        const visibleFieldsByCategories = this.getVisibleFieldsByCategories(
          formSettings.fieldsByCategories
        );

        return this.loadValues(source, additionalSettings, visibleFieldsByCategories).pipe(
          map((values: { [fieldName: string]: any }) => {
            this.dispatchValues(values);

            const visibleCategories = visibleFieldsByCategories.map((item) => item.categoryKey);
            return { visibleCategories, isCached };
          })
        );
      })
    );
  }

  private loadFormDefinitions(
    source: DynamicFormSourceSettings,
    isCached: boolean
  ): Observable<DynamicFormGroupCategories> {
    if (isCached) {
      return of(this._previousFormDefinition);
    }
    return this._dynamicFormService
      .getFormByEntityType(source.entityType, source.elementTypeId, source.entitySubtypes)
      .pipe(
        map((fieldsByCategories: FieldsByCategory[]) => {
          const definitions = new DynamicFormGroupCategories({
            formSettings: { ...source },
            fieldsByCategories,
          });
          this._previousFormDefinition = globalUtilsHelper.clone(definitions, true);
          return definitions;
        })
      );
  }

  private isCachedSource = (source: DynamicFormSourceSettings) =>
    source.sourceKey && source.sourceKey === this._previousFormDefinition?.formSettings?.sourceKey;

  private loadValues(
    source: DynamicFormSourceSettings,
    additionalSettings: DynamicFormAdditionalSettings,
    fieldsByCategories: FieldsByCategory[]
  ): Observable<{ [fieldName: string]: any }> {
    const { entityType } = source;
    const elementId = additionalSettings.entityId;
    const apiFields = this._dynamicFormHelperService.hashApiFields(fieldsByCategories);

    return this._dynamicFormHelperService.buildFormDependencies(source).pipe(
      switchMap((dependencies) => {
        return this._dynamicFormService.getValues(entityType, elementId, apiFields, dependencies);
      })
    );
  }

  private dispatchFormDefinitions(formSettings: DynamicFormGroupCategories): void {
    const action = new SetFormDefinitionsAction(formSettings, this._scopeSettings);
    this._state.dispatch(action);
  }

  private dispatchValues(values: { [fieldName: string]: any }): void {
    const action = new SetFormUIValuesAction(values, this._scopeSettings);
    this._state.dispatch(action);
  }

  private dispatchAdditionalSettings(settings: DynamicFormAdditionalSettings): void {
    const action = new SetFormAdditionalSettingsAction(settings, this._scopeSettings);
    this._state.dispatch(action);
  }

  private getVisibleFieldsByCategories(fieldsByCategories: FieldsByCategory[]): FieldsByCategory[] {
    const visibleItems: FieldsByCategory[] = [];

    fieldsByCategories.forEach((fieldsByCategory) => {
      const oneFieldMustBeShown = Object.values(fieldsByCategory.fields).some(
        (field) => !field.hideInForm
      );
      if (oneFieldMustBeShown) {
        visibleItems.push(globalUtilsHelper.serializedClone(fieldsByCategory));
      }
    });

    return visibleItems;
  }
}
