export interface IAlgorithmDto {
  algorithmId: string;
  algorithmFamilyId: string;
  algorithmFamilyName: string;
  algorithmShortName: string;
  algorithmDescription: string;
  timeAggregationId?: number;
  dimensionTypeId: number;
  entityTypeId?: number;
  customEntityTypeId?: number;
  algorithmExecutionGroupId?: number;
  sortOrder?: number;
  enable: boolean;
}

export class AlgorithmDto implements IAlgorithmDto {
  algorithmId: string;
  algorithmFamilyId: string;
  algorithmFamilyName: string;
  algorithmShortName: string;
  algorithmDescription: string;
  timeAggregationId?: number;
  dimensionTypeId: number;
  entityTypeId?: number;
  customEntityTypeId?: number;
  algorithmExecutionGroupId?: number;
  sortOrder?: number;
  enable: boolean;

  constructor(partial: Partial<AlgorithmDto>) {
    Object.assign(this, partial);
  }
}
