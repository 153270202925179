import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { EnvelopesConfiguration } from 'src/app/common-modules/dependencies/alarms/envelopes-configuration';
import { ProfileConfigurationDto } from 'src/app/common-modules/dependencies/alarms/profile-configuration.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { SaAlgorithmElementDto } from 'src/app/common-modules/shared/model/algorithm/sa-algorithm-element.dto';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { ElementTargetsEnum } from 'src/app/common-modules/shared/model/shared/element-targets.enum';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { DateRangeAndMode } from '../../../../../common-modules/common-filters/components/derivated/date-range-and-mode/date-range-and-mode';
import { IHierarchyElementDto } from '../../../../../common-modules/dependencies/he/hierarchy-element.dto';
import { RouteNameHelper } from '../../../../../common-modules/dependencies/navigation/route-name-helper';
import { INetworkElementDto } from '../../../../../common-modules/dependencies/ne/network-element.dto';
import { AlarmNameSegment } from '../../../shared/model/alarms/alarm-name-part';
import { SaWizardSteps } from '../../models/sa-wizard-steps.enum';
import { SaveProfileDto } from '../../models/save-profile.dto';
import { ProfilesService } from '../../profiles-chart/profiles.service';
import { SACalendarData } from '../models/sa-calendar-data';
import { SAProfileSummary } from '../models/sa-profile-summary';
import { SAWizardMode } from '../models/sa-wizard-mode.enum';
import { SAWizardSettings } from '../models/sa-wizard-settings';

const COMPONENT_SELECTOR = 'wlm-sa-wizard';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-wizard.component.html',
  styleUrls: ['./sa-wizard.component.scss'],
})
export class SAWizardComponent implements OnInit, IPendingChangesEmitter, OnDestroy {
  @ViewChild(MatStepper) stepper: MatStepper;
  @Output() public showSpinner = new EventEmitter<boolean>();

  private _sAWizardSettings: SAWizardSettings;
  public get sAWizardSettings(): SAWizardSettings {
    return this._sAWizardSettings;
  }
  @Input() public set sAWizardSettings(v: SAWizardSettings) {
    this._sAWizardSettings = v;
  }
  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(v: Observable<boolean>) {
    this._resetHandler$ = v;

    this.resetHandler$?.pipe(untilDestroyed(this)).subscribe((reset) => {
      if (reset) {
        this.resetWizard();
      }
    });
  }

  @Input() pageId: string;

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  public get stepsNumber(): number {
    return this.sAWizardSettings?.mode === this.telemetryMode ? 5 : 6;
  }

  saPersistencyArea = 'SAWizard';
  persistencyAreaTelemetry = 'telemetry';
  hierarchyElementIdFieldName = 'hierarchyElementId';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  networkElementTypeIdFieldName = 'networkElementTypeId';
  dimensionTypeIdFieldName: 'dimensionTypeId';

  timeFrameForm: UntypedFormGroup;
  timeFrame: DateRangeAndMode;
  pointsForm: UntypedFormGroup;
  points: ISignalTelemetryNullableViewDto[];
  calendar: SACalendarData;
  calendarForm: UntypedFormGroup;
  summaryForm: UntypedFormGroup;
  algorithmsForm: UntypedFormGroup;
  targetsForm: UntypedFormGroup;

  // Steps blockers
  blockCalendarStep = true;
  blockEnvelopesStep = true;
  blockTargetsStep = true;
  blockAlgTelemetryStep = true;
  blockProfileNameStep = true;

  pointsToCalculate: ISignalTelemetryNullableViewDto[];

  algorithms: IAlgorithmDto[];
  targets: IHierarchyElementDto[] | INetworkElementDto[];
  targetsByAlgorithms: SaAlgorithmElementDto[];
  profileConfiguration: ProfileConfigurationDto;

  // Array of profilesConfig generated for each point selected
  profilesConfigurationFinal: ProfileConfigurationDto[];

  profileSummary: SAProfileSummary[];

  envConfig: EnvelopesConfiguration;
  envConfigForm: UntypedFormGroup;
  envsIsValid: boolean;

  alarmNameForm: UntypedFormGroup;
  nameSegments: AlarmNameSegment[];
  timeFrameIsValid: boolean;
  profilesAreSaved = false;
  heAlgorithmTypeSelected: boolean;

  //MODE:
  telemetryMode = SAWizardMode.Telemetry;
  algorithmMode = SAWizardMode.Algorithm;

  resetCompleteHandler$ = new ReplaySubject<void>();
  // TODO: check if this applies in SA WIZARD
  algorithmStepApplyPersistedValue$ = new ReplaySubject<void>();

  stepApplied$ = new BehaviorSubject<string>(null);

  // grid persistencyArea for target step
  persistencyAreaHE = 'sa-target-he';
  persistencyAreaNE = 'sa-target-ne';

  subscribeToNewProfile: boolean = false;

  get componentName() {
    return 'SAWizardComponent';
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _profileService: ProfilesService,
    private _router: Router,
    private _cacheService: HttpCacheService,
    private _dialogService: DialogService,
    private _pendingChangesService: PendingChangesManagerService
  ) {}

  ngOnInit(): void {
    this.clearPersistedTargets();
    this.initializeForm();
    this.profileConfiguration = new ProfileConfigurationDto();
    this.subscribeResetComponentsComplete();
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private subscribeResetComponentsComplete() {
    let notificationCounter = 0;
    this.resetCompleteHandler$.pipe(untilDestroyed(this)).subscribe(() => {
      // count steps notifications
      notificationCounter += 1;
      if (notificationCounter === this.stepsNumber) {
        this.showSpinner.emit(false);
        notificationCounter = 0;
      }
    });
  }

  applyTimeFrame() {
    this.timeFrameForm.get('timeFrame').setValue(this.timeFrame);
    this.timeFrameForm.updateValueAndValidity();

    this.blockAlgTelemetryStep = this.timeFrameForm.invalid;

    Object.assign(this.profileConfiguration, this.timeFrame);
    this.stepApplied$.next(SaWizardSteps.TimeFrame);
    this.stepper.next();
  }

  applyPoints() {
    this.pointsForm.get('points').setValue(this.points);
    this.pointsToCalculate = this.points;

    this.blockCalendarStep = this.pointsForm.invalid;
    this.stepApplied$.next(SaWizardSteps.Points);
    this.stepper.next();
  }

  applyAlgorithms() {
    this.algorithmsForm.get('algorithms').setValue(this.algorithms); // TODO: set algorithm selection
    Object.assign(this.profileConfiguration, this.algorithms); // TODO: Probably not working as expected
    if (this.targetsByAlgorithms) {
      // updates targets by algorithms if apply
      this.setTargetsByAlgorithms();
    }

    this.blockTargetsStep = this.algorithmsForm.invalid;
    this.stepApplied$.next(SaWizardSteps.Algorithms);
    this.stepper.next();
  }

  applyTargetElements() {
    this.setTargetsByAlgorithms();
    this.blockCalendarStep = this.targetsForm.invalid;
    this.stepApplied$.next(SaWizardSteps.Targets);
    this.stepper.next();
  }

  private setTargetsByAlgorithms() {
    this.targetsByAlgorithms = [];
    this.targetsForm.get('targets').setValue(this.targets);
    this.algorithms.forEach((algorithm) => {
      this.targets.forEach((target) => {
        this.targetsByAlgorithms.push(
          new SaAlgorithmElementDto({
            algorithmShortName: algorithm.algorithmShortName,
            targetName: null,
            hierarchyElement: this.heAlgorithmTypeSelected ? target : undefined,
            networkElement: this.heAlgorithmTypeSelected ? undefined : target,
            dimenstionTypeId: algorithm.dimensionTypeId,
            timeAggregationId: algorithm.timeAggregationId,
            algorithmId: algorithm.algorithmId,
          })
        );
      });
    });
  }

  applyCalendar() {
    this.calendarForm.get('calendar').setValue(this.calendar);
    Object.assign(this.profileConfiguration, this.calendar);

    this.blockEnvelopesStep = this.calendarForm.invalid;
    this.stepApplied$.next(SaWizardSteps.Calendar);
    this.stepper.next();
  }

  timeFrameChanged(data: DateRangeAndMode) {
    this.timeFrame = data;
  }

  timeFrameValidChanged(isValid: boolean) {
    this.timeFrameIsValid = isValid;
  }

  pointsChanged(points: ISignalTelemetryNullableViewDto[]) {
    if (points.length) {
      this.points = points;
    } else {
      this.points = null;
    }
  }

  envConfChanged(data: EnvelopesConfiguration) {
    this.envConfig = data;
  }

  envIsValidChanged(envIsValid: boolean) {
    this.envsIsValid = envIsValid;
  }

  onSelectedTargetChange(targets: IHierarchyElementDto[] | INetworkElementDto[]) {
    this.targets = targets;
  }

  applyEnvConf() {
    this.envConfigForm.get('envelopesConfig').setValue(this.envConfig);
    this.envConfigForm.get('envsIsValid').setValue(this.envsIsValid);
    this.blockProfileNameStep = this.envConfigForm.invalid;
    Object.assign(this.profileConfiguration, this.envConfig);
    this.envConfigForm.updateValueAndValidity();

    this.setPendingChanges(this.pageId, this.getPendingChanges(true));
    this.stepper.next();
  }

  applyAlarmName() {
    this.showSpinner.emit(true);
    this.alarmNameForm.get('nameSegments').setValue(this.nameSegments);
    this.profilesConfigurationFinal = [];

    this.getProfileConfigs();
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    return this._profileService.convertDefaultUnitsMany(this.profilesConfigurationFinal, 'to').pipe(
      switchMap((convertedProfiles) => {
        const saveProfileDefinition = new SaveProfileDto({
          profileConfigurations: convertedProfiles,
          subscribe: this.subscribeToNewProfile,
        });
        return this._profileService.saveProfilesConfigurations(saveProfileDefinition);
      }),
      switchMap((jobId) => this._profileService.getSavedProfiles(jobId)),
      tap((profiles) => {
        this.profileSummary = profiles;
        this._cacheService.clearContainsInUrl('alarm/configuration').then((success) => {
          this.stepper.next();

          this.profilesAreSaved = true;
          this.setPendingChanges(this.pageId, this.getPendingChanges(false));

          this.blockSteps();
          this.showSpinner.emit(false);
        });
      }),
      catchError((error) => {
        this._dialogService.showErrorMessage(error);
        this.showSpinner.emit(false);

        return of(null);
      }),
      map((_) => true)
    );
  }

  closeWizard() {
    this._router.navigate([RouteNameHelper.getConfigurationRoute(RouteNameHelper.alarms)]);
  }

  getAlarmName(alarmedElement: ISignalTelemetryNullableViewDto | SaAlgorithmElementDto): string {
    let name = '';
    if (this.nameSegments?.length > 0) {
      this.nameSegments.forEach((segment, index) => {
        const segmentValue =
          segment.field !== '' ? alarmedElement[segment.field] : segment.fieldNameKey;
        const separator = index + 1 < this.nameSegments.length ? ' - ' : '';
        name += `${segmentValue}${separator}`;
      });
    }

    return name;
  }

  onalarmNameSegmentsChange(segments: AlarmNameSegment[]) {
    this.nameSegments = segments;
  }

  calendarChanged(data: SACalendarData) {
    this.calendar = data;
  }

  resetWizard() {
    if (this.stepper) {
      this.showSpinner.emit(true);
      this.stepper.selectedIndex = 0;
      this.stepper.steps.forEach((step) => (step.completed = false));
      this.profilesAreSaved = false;
      this.blockSteps();
      this.initializeForm();
      this.setPendingChanges(this.pageId, this.getPendingChanges(false));
    }
  }

  blockSteps() {
    this.blockAlgTelemetryStep = true;
    this.blockCalendarStep = true;
    this.blockEnvelopesStep = true;
    this.blockTargetsStep = true;
    this.blockProfileNameStep = true;
  }

  private initializeForm() {
    this.timeFrameForm = this._formBuilder.group({
      timeFrame: ['', Validators.required],
    });

    this.pointsForm = this._formBuilder.group({
      points: ['', Validators.required],
    });

    this.envConfigForm = this._formBuilder.group({
      envelopesConfig: ['', Validators.required],
      envsIsValid: ['', Validators.requiredTrue],
    });

    this.calendarForm = this._formBuilder.group({
      calendar: ['', Validators.required],
    });

    this.alarmNameForm = this._formBuilder.group({
      nameSegments: ['', Validators.required],
    });

    this.summaryForm = this._formBuilder.group({
      summary: ['', Validators.required],
    });

    this.algorithmsForm = this._formBuilder.group({
      algorithms: ['', Validators.required],
    });

    this.targetsForm = this._formBuilder.group({
      targets: ['', Validators.required],
    });

    this.timeFrameForm.updateValueAndValidity();
    this.pointsForm.updateValueAndValidity();
    this.envConfigForm.updateValueAndValidity();
    this.calendarForm.updateValueAndValidity();
  }

  private getProfileConfigs() {
    this.pointsToCalculate?.forEach((point) => {
      // New object with base info
      const profileConfig = this.getNewProfileConfig();

      // Update object with point related values
      profileConfig.profileName = this.getAlarmName(point);
      profileConfig.signalId = point.signalId;
      profileConfig.dimensionTypeId = point.dimensionTypeId;
      profileConfig.timeAggregationId = TimeAggregationEnum.Base as number;
      profileConfig.name = point.pointId;
      profileConfig.description = point.pointDescription;

      this.profilesConfigurationFinal.push(profileConfig);
    });

    this.targetsByAlgorithms?.forEach((target) => {
      // New object with base info
      const profileConfig = this.getNewProfileConfig();

      profileConfig.profileName = this.getAlarmName(target);
      profileConfig.name = target.targetName;
      profileConfig.description = target.algorithmShortName;
      profileConfig.dimensionTypeId = target.dimenstionTypeId;
      profileConfig.timeAggregationId = target.timeAggregationId;
      profileConfig.algorithmId = target.algorithmId;
      profileConfig.hierarchyElementId = target.hierarchyElement?.hierarchyElementId;
      profileConfig.networkElementId = target.networkElement?.networkElementId;
      profileConfig.hierarchyElementTypeId = target.hierarchyElement?.hierarchyElementTypeId;

      this.profilesConfigurationFinal.push(profileConfig);
    });
  }

  private getNewProfileConfig() {
    const profileConfig = new ProfileConfigurationDto();
    Object.assign(profileConfig, this.profileConfiguration);
    return profileConfig;
  }

  onAlgorithmsChanged(algorithms: IAlgorithmDto[]) {
    this.algorithms = algorithms;
  }

  onTargetChanged(targetId: ElementTargetsEnum): void {
    const oldValue = this.heAlgorithmTypeSelected;
    if (typeof targetId === 'undefined' || targetId === null) {
      // HE by default.
      this.heAlgorithmTypeSelected = true;
    } else {
      this.heAlgorithmTypeSelected = targetId === ElementTargetsEnum.HierarchyElements;
    }

    if (oldValue !== this.heAlgorithmTypeSelected) {
      this.clearPersistedTargets();
    }
  }

  private clearPersistedTargets() {
    this._cacheService
      .clearContainsInUrl('sa-target') // clear selected rows in cache
      .then((success) => {});
  }

  onSubscriptionChange(subscribe) {
    this.subscribeToNewProfile = subscribe;
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.clearPersistedTargets();
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
