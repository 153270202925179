import { IChartDataParameters } from './chart-data-parameters';

export class PieChartDataParameters implements IChartDataParameters {
  queryParams: any;
  dataService: string;
  startDate: Date;
  endDate: Date;

  public constructor(init?: Readonly<PieChartDataParameters>) {
    Object.assign(this, init);
  }
}
