<div id="center-page">
  <div id="center-container">
    <wlm-he-family-level-filter
      *ngIf="filterSettings"
      [ngClass]="filterCssClass"
      [settings]="filterSettings"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="onFiltersChanged($event)"
      (apply)="onApply()"
      (autoLoad)="onAutoload()"
    ></wlm-he-family-level-filter>

    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings && filters"
      [dynamicGridSettings]="dynamicGridSettings"
      (genericGridLoaded)="getGenericGridInstance($event)"
      (gridSettingsLoaded)="getGridSettings($event)"
      (selectedItemsChange)="onSelectedItemsChanged($event)"
      [dataBindingFilters]="emptyFilters"
      [additionalFilters]="gridFiltersMap"
      [reloadGrid$]="reloadGrid$"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [gridSettingsChanged$]="gridSettingsChanged$"
    ></wlm-dynamic-grid>
  </div>
</div>
