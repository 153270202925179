<form [formGroup]="activitiesForm" class="form-size">
  <wlm-dynamic-grid
    class="generic-grid"
    *ngIf="dynamicGridSettings"
    [dynamicGridSettings]="dynamicGridSettings"
    [dataBindingFilters]="gridFiltersForBinding"
    (genericGridLoaded)="getGenericGridInstance($event)"
    (gridSettingsLoaded)="getGridSettings($event)"
    [removeSelectionPersisted$]="removeSelectionPersisted$"
    [gridSettingsChanged$]="gridSettingsChanged$"
    [selectedItemsByFilter]="initialSelectedByFilter"
    (selectedItemsChange)="onSelectedActivities($event)"
  ></wlm-dynamic-grid>
</form>
