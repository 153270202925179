import { Injectable } from '@angular/core';
import { ReplaySubject, startWith } from 'rxjs';
import { NecGlobalValues } from './nec-global-values';

@Injectable()
export class NecGlobalValuesManagerService {
  private _globalValues$ = new ReplaySubject<NecGlobalValues>();
  public readonly globalValues$ = this._globalValues$.asObservable().pipe(startWith(null));

  setGlobalValues(values: NecGlobalValues): void {
    this._globalValues$.next(values);
  }
}
