import { Pipe, PipeTransform } from '@angular/core';

/**
 * Set to uppercase only the first letter of the string.
 * For titles, also consider the native TitleCasePipe.
 */

@Pipe({
  name: 'sentence',
})
export class CapitalizeSentencePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !value.length) {
      return value;
    }
    const result = value
      .replace(/([(A-Z)\d]+)(?=[A-Z][a-z])/g, "$1 ")
      .replace(/([a-z])(([(A-Z)\d]+))/g, "$1 $2");

    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
