import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { DateRangeFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { HierarchyElementPathFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from 'src/app/common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from 'src/app/common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { PersistencyService } from 'src/app/common-modules/shared/persistency.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { TreeSettingsService } from 'src/app/common-modules/shared/services/tree-settings.service';

import { HeFamilyDateRangeFilterConfiguration } from './he-family-date-range-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-he-tree-family-date-range-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './he-tree-family-date-range-filter.component.html',
  styleUrls: ['./he-tree-family-date-range-filter.component.scss'],
  providers: [PersistencyService],
})
export class HeTreeFamilyDateRangeFilterComponent
  extends BasePageFiltersComponent
  implements OnInit
{
  private _leaksFilterConfiguration: HeFamilyDateRangeFilterConfiguration;
  public get filterConfiguration(): HeFamilyDateRangeFilterConfiguration {
    return this._leaksFilterConfiguration;
  }
  public set filterConfiguration(value: HeFamilyDateRangeFilterConfiguration) {
    this._leaksFilterConfiguration = value;
    if (value) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      this.loadNavigationParams(v.navigationParams);

      this.persistencyArea = v.persistencyArea;
      this.setDateFielsKeys();
      this.loadState();
    }
  }
  @Input() containerClass: string;

  selectedHierarchyElements: any;
  selectedHierarchyFamilyId: any;
  startDateFromNavigation: Date;
  endDateFromNavigation: Date;

  startDateKey: string;
  endDateKey: string;

  defaultHierarchyElementId = '';

  // tslint:disable-next-line: max-line-length
  // startDateKey = `${this.dateFieldName}#StartDate`; // <-- This formatting is necessary due to daterange uses the same fieldname in this page
  // endDateKey = `${this.dateFieldName}#EndDate`;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    disableSelectAll: true,
    disableSearch: true,
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
    disableSelectAll: true,
  };
  dateRangeItemSettings = new BaseFilterItemSettings({
    storageLocation: 'session',
    hideInputSummaryLabel: true,
  });
  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  @Input() set disableDatesGroup(disabled: boolean) {
    this.bf1Settings.disableFilter = disabled;
    this.bf1Settings = globalUtilsHelper.clone(this.bf1Settings);
  }

  @Input() set disableHierarchyGroup(disabled: boolean) {
    this.bf2Settings.disableFilter = disabled;
    this.bf2Settings = globalUtilsHelper.clone(this.bf2Settings);
  }

  constructor(
    private treeSettingsService: TreeSettingsService,
    private adaptersService: FiltersAdapterService,
    private _dateHelperService: DateHelperService,
    private _globalsService: GlobalsService,
    _persistencyService: PersistencyService
  ) {
    super(_persistencyService);
  }

  ngOnInit(): void {
    this.buildTreeSettings();
  }

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.filterConfiguration;
    this.keysToComplete = [hierarchyElementFamilyFieldName, hierarchyElementIdFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const {
      startDateFieldName,
      endDateFieldName,
      dateRange,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    } = this.filterConfiguration;
    const adapters = [
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        startDateFieldName,
        endDateFieldName,
        inclusive: dateRange?.inclusive,
        allowsNullDate: dateRange?.allowsNullDate,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea() {
    this.bf1Settings.persistencyArea = this.filterConfiguration.persistencyArea;
    this.bf2Settings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.filterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.filterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  loadState() {
    const filters = [];
    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = this.settings.avoidPersistency
        ? null
        : (
            this.getPersisted(this.settings.fields.hierarchyElementId.fieldName, [
              { value: this.defaultHierarchyElementId, label: '' },
            ]) as BasicFilter
          ).value;
    }
    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.settings.avoidPersistency
        ? null
        : this.getPersisted(this.settings.fields.hierarchyFamilyId.fieldName, undefined);
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }
    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
      .subscribe((familyId) => {
        if (!this.selectedHierarchyFamilyId) {
          this.selectedHierarchyFamilyId = familyId;
        }

        const defaultDateRange = this._dateHelperService.createDefaultDateRange();

        const startDate = this.settings.avoidPersistency
          ? this.startDateFromNavigation
          : this.loadPersistedDate(this.startDateKey, this.startDateFromNavigation, false, true);

        const hasEndDatePersisted = this.settings.avoidPersistency
          ? false
          : this.getPersisted(this.endDateKey, null, null, false) != null;
        let endDate = this.loadPersistedDate(
          this.endDateKey,
          this.endDateFromNavigation,
          true,
          true
        );

        if (!hasEndDatePersisted && !this.settings.additionalParams.inclusiveEndDateParam) {
          endDate = this._dateHelperService.addDays(endDate, 1);
        }

        this.filterConfiguration = new HeFamilyDateRangeFilterConfiguration(
          this.settings.persistencyArea,
          this.settings.fields.hierarchyElementId.fieldName,
          this.settings.fields.hierarchyFamilyId.fieldName,
          this.settings.fields.startDate.fieldName,
          this.settings.fields.endDate.fieldName,
          this.selectedHierarchyElements,
          this.selectedHierarchyFamilyId,
          new DateRange(
            this._dateHelperService.ensureDateObject(startDate),
            this._dateHelperService.ensureDateObject(endDate),
            this.settings.fields.startDate.fieldName,
            this.settings.fields.endDate.fieldName,
            true
          ),
          defaultDateRange,
          filters,
          this.settings.additionalParams.sendRootLevel?.value
        );
      });
  }

  private loadNavigationParams(queryParams: Params) {
    if (queryParams?.hierarchyElementIds) {
      this.selectedHierarchyElements = Array.isArray(queryParams.hierarchyElementIds)
        ? queryParams.hierarchyElementIds
        : [queryParams.hierarchyElementIds];
    }
    this.selectedHierarchyFamilyId = queryParams?.hierarchyFamilyId;

    this.startDateFromNavigation =
      queryParams?.startDate ??
      this._dateHelperService.getDefaultStartDate(
        this.settings.additionalParams.offsetStartDate.value
      );
    this.endDateFromNavigation =
      queryParams?.endDate ?? this._dateHelperService.getDefaultEndDate();
  }

  private setDateFielsKeys() {
    this.startDateKey = `${this.settings.fields.startDate.fieldName}`; // <-- This formatting is necessary due to daterange uses the same fieldname in this page
    this.endDateKey = `${this.settings.fields.endDate.fieldName}`;

    if (this.settings.fields.startDate.fieldName == this.settings.fields.endDate.fieldName) {
      this.startDateKey += '#StartDate';
      this.endDateKey += '#EndDate';
    }
  }
}
