<div class="ranges-container" *ngIf="rangesPerLevel">
  <div class="level-ranges" *ngFor="let level of levels">
    <h3>{{ levelsDescription[level] }}</h3>
    <wlm-range-color
      [rangeColors]="rangesPerLevel[level]"
      [rangeLabel]="rangeLabelsPerLevel[level]"
      (valueChanged)="onRangeValueChanged($event, level)"
      (isValidChanged)="onValidityChanged($event, level)"
    ></wlm-range-color>
  </div>
</div>
