<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'" [showPageSpinner]="isLoading">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <span *hasPerm="['WLMMergedZonesCrud', 'c']">
        <button
          mat-icon-button
          [matTooltip]="'common.clear-filter' | translate | lowercase"
          onclick="this.blur()"
          (click)="onResetFilters()"
        >
          <wlm-icon [icon]="'reset-filters'"></wlm-icon>
        </button>
        <button
          wlmCollapsibleButton
          [tooltip]="T_SCOPE + '.buttons.create'"
          (click)="openCreatePopup()"
        >
          <wlm-icon icon="add"></wlm-icon>
        </button>
      </span>
      <span *hasPerm="['WLMMergedZonesCrud', 'u']">
        <button
          wlmCollapsibleButton
          *hasPerm="['ActivitiesCrud', 'r']"
          [disabled]="!selectedMergedZone"
          [tooltip]="T_SCOPE + '.buttons.edit'"
          (click)="openEditPopup()"
        >
          <wlm-icon icon="edit"></wlm-icon>
        </button>
      </span>
      <span *hasPerm="['WLMMergedZonesCrud', 'd']">
        <button
          wlmCollapsibleButton
          [disabled]="!selectedMergedZone"
          [tooltip]="T_SCOPE + '.buttons.delete'"
          (click)="onDelete()"
        >
          <wlm-icon icon="delete"></wlm-icon>
        </button>
      </span>

      <button
        *ngIf="dynamicLayoutSettings?.showResetIcon"
        [matTooltip]="dynamicLayoutSettings?.labelReset | lowercase"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="dynamicLayoutSettings"
      [settings]="dynamicLayoutSettings"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
