import { ChangeDetectorRef, ViewRef, inject } from '@angular/core';
import { Observable, ReplaySubject, UnaryFunction, takeUntil } from 'rxjs';

export function wlmTakeUntilDestroyed<T>(
  viewRef?: ViewRef
): UnaryFunction<Observable<T>, Observable<T>> {
  // This can only be injected automatically if the operator is run in an injection context.
  viewRef = viewRef ?? (inject(ChangeDetectorRef) as ViewRef);
  const unsubscribe$ = new ReplaySubject<void>(1);

  viewRef.onDestroy(() => {
    unsubscribe$.next();
    unsubscribe$.complete();
  });

  return (observable: Observable<T>) => observable.pipe(takeUntil(unsubscribe$));
}
