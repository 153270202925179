<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [pageId]="pageId"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="grid"
      [gridName]="gridName"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <span *hasPerm="['WLMNotificationsCrud', 'u']">
          <button
            wlmCollapsibleButton
            [disabled]="!selectedNotifications?.length"
            [tooltip]="T_SCOPE + (isAnyUnread ? '.read' : '.unread')"
            [showLabel]="isContainerCollapsed"
            (click)="readUnreadNotifications()"
          >
            <wlm-icon
              [icon]="isAnyUnread ? 'read-select-notification' : 'unread-select-notification'"
            ></wlm-icon>
          </button>
          <button
            wlmCollapsibleButton
            [disabled]="!selectedNotification"
            [tooltip]="T_SCOPE + '.edit'"
            [showLabel]="isContainerCollapsed"
            (click)="editNotification()"
          >
            <mat-icon svgIcon="edit"></mat-icon>
          </button>
          <button
            wlmCollapsibleButton
            [disabled]="disableCloseButton"
            [tooltip]="T_SCOPE + '.close'"
            [showLabel]="isContainerCollapsed"
            (click)="closeNotifications()"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
          <button
            wlmCollapsibleButton
            *ngIf="isAnyUnfollow || !selectedNotifications?.length"
            [disabled]="disableUnfollowButton"
            [tooltip]="T_SCOPE + '.follow'"
            [showLabel]="isContainerCollapsed"
            (click)="followNotifications()"
          >
            <mat-icon svgIcon="follow-select-notification"></mat-icon>
          </button>
          <button
            wlmCollapsibleButton
            *ngIf="!isAnyUnfollow && selectedNotifications?.length"
            [disabled]="disableUnfollowButton"
            [tooltip]="T_SCOPE + '.unfollow'"
            [showLabel]="isContainerCollapsed"
            (click)="unfollowNotifications()"
          >
            <mat-icon svgIcon="unfollow-select-notification"></mat-icon>
          </button>
        </span>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters *isSuperUser>
    <wlm-notification-grid-filter
      *ngIf="notificationFilterConfig"
      [filterConfiguration]="notificationFilterConfig"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="loadNotifications()"
      (autoLoad)="onCheckAutoload()"
    ></wlm-notification-grid-filter>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      class="wtr-fx-flex-grow generic-grid"
      #notificationGrid
      [persistencyArea]="persistencyArea"
      [usePersistence]="true"
      [additionalFilters]="additionalFilters"
      [gridSettings]="gridSettings"
      [showSelectedList]="true"
      [showSelectedItems]="false"
      [gridFiltersForBinding]="gridFiltersForBinding"
      (selectedItemChanged)="getSelectedNotification($event)"
      [(selectedItems)]="selectedNotifications"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [removeSelection$]="removeSelection$"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
