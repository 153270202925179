import { Component, Input, inject } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { NETypesCrudService } from '../../ne-types/ne-types-crud.service';
import { IntegrationGisLayersDto } from '../integration-gis-layers.dto';

@Component({
  selector: 'wlm-integration-gis-layers-form',
  templateUrl: './integration-gis-layers-form.component.html',
  styleUrls: ['./integration-gis-layers-form.component.scss'],
})
export class IntegrationGisLayersFormComponent extends IntegrableForm {
  @Input() initialModel: IntegrationGisLayersDto;
  model: IntegrationGisLayersDto;

  private readonly _neTypesCrudService = inject(NETypesCrudService);

  onModelChange(model: IntegrationGisLayersDto): void {
    this.model = globalUtilsHelper.clone(model, true);
    this.model.layersFile = model.layersFile;
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'layersFile',
          type: 'file-upload',
          className: 'col-12',
          props: {
            label: 'GIS Layers (json)',
            required: true,
          },
        },
        {
          key: 'networkElementTypeId',
          type: 'select',
          className: 'col-12',
          props: {
            label: 'Network Element Type',
            required: true,
            valueProp: 'networkElementTypeId',
            labelProp: 'networkElementTypeName',
            options: this._neTypesCrudService.getAll(),
          },
        },
      ],
    },
  ];
}
