export class GCartesianChartSerieTooltip {
  name: string;
  color: string;
  dataPointValue: string;
  unit: string;

  constructor(init: Partial<GCartesianChartSerieTooltip>) {
    Object.assign(this, init);
  }
}
