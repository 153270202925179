// prettier-ignore
import { AfterContentChecked, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { LogData } from 'src/app/common-modules/shared/wlm-log/log-data';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { GenericChartComponent } from 'src/app/common-modules/wlm-charts/core/generic-chart/generic-chart.component';
import { IChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/chart-data-parameters';
import { BaseCrudGenericChartComponent } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/base-crud-generic-chart.component';
import { GChartLegendItem } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-legend-item';
import { GChartClickEvent } from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-click-event';
import {
  GChartDataZoomEvent,
  GChartDataZoomItemEvent,
} from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-data-zoom-event';
import { GenericSchematicChartSettings } from 'src/app/common-modules/wlm-charts/core/models/schematics/generic-schematic-chart-settings';
import { SchematicChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/schematics/schematic-chart-data-parameters';

import {
  Schematic,
  SchematicCategory,
  SchematicNode,
  SchematicNodeProcessed,
} from '../../../../common-modules/wlm-charts/core/models/schematics/schematic';
import { SchematicOperationsEnum } from '../../../../common-modules/wlm-charts/core/models/schematics/schematic-operations.enum';
import { BaseMapComponent } from '../../map/base-map/base-map.component';
import { MapParameters } from '../../map/map-parameters';
import { MapBounds } from '../../map/models/map-bounds';

import { BaseSchematicChartService } from '../services/base-schematic-chart.service';
import { ISchematicChartComponent } from './schematic-chart-component.interface';

type TGraphSerie = { id: string; type: 'graph'; data: any; symbolSize?: number };
type TAxisBounds = { min: number; max: number };

const COMPONENT_SELECTOR = 'wlm-schematic-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematic-chart.component.html',
  styleUrls: ['./schematic-chart.component.scss'],
})
export class SchematicChartComponent
  extends BaseCrudGenericChartComponent<
    SchematicChartDataParameters,
    Schematic,
    Schematic,
    BaseSchematicChartService
  >
  implements OnInit, AfterContentChecked, ISchematicChartComponent
{
  private _chart: GenericChartComponent;
  get chart(): GenericChartComponent {
    return this._chart;
  }
  @ViewChild(GenericChartComponent) set chart(value: GenericChartComponent) {
    this._chart = value;
    if (this.chart) {
      this._chart$.next(this.chart);
      this._chart$.complete();
    }
  }
  @ViewChild('chartContainer') set chartContainer(value: ElementRef) {
    this.chartContainerElement = value?.nativeElement;
  }
  @ViewChild(BaseMapComponent) mapComponent: BaseMapComponent;

  @Input() toggleMapOpacity$ = new Subject<void>();

  // Elevate the loaded schematic for comodity.
  @Output() loadedSchematic = new EventEmitter<Schematic>();
  @Output() updatedDataPoints = new EventEmitter<Map<string, SchematicNodeProcessed>>();
  @Output() externalLoading = new EventEmitter<boolean>();
  @Output() isAllSynced = new EventEmitter<boolean>();
  @Output() modelChange = new EventEmitter<Schematic>();
  @Output() lastClickedPositionChange = new EventEmitter<number[]>();
  @Output() notifyNodeInfo = new EventEmitter<SchematicNode>();

  chartContainerElement: HTMLElement;
  mapParameters: MapParameters;
  chartBounds: any;
  initialSynced = false;
  syncedHeight = 'inherit';
  syncedWidth = 'inherit';
  mapOpacityEnabled = false;
  readonly currentDebounceTime = 0;

  private _chart$ = new ReplaySubject(1);
  private _dragNodeResizeSubs: Subscription;
  private _mainResizeSubs: Subscription;
  private _updatedDataPointsHash: Map<string, SchematicNodeProcessed>;
  private _originalGraphSerie;
  private _currentGraphSerie;
  private _xAxisBounds: TAxisBounds;
  private _yAxisBounds: TAxisBounds;
  private _editMode = false;
  private _model: Schematic;
  private _initialModel: Schematic;
  private _lastClickedPosition: number[];
  private _currentOperation: SchematicOperationsEnum;
  private _selectedChartElement: any;
  private _selectedRelationNodes: any[] = [];
  private _dragModeEnabled: boolean = false;
  private _nodeToHighlight: SchematicNode;

  private readonly _necesaryNodesInARelation = 2;
  private readonly _enableLog = false;
  private readonly _initialMarginProportion = 20;
  private readonly _highlightColor = '#aa00ff';
  private readonly _highlightedSymbolSize = 30;
  private readonly _commonSymbolSize = 20;

  constructor(
    injector: Injector,
    protected _objectHelperService: ObjectHelperService,
    private _log: LogService,
    private _dialogService: DialogService
  ) {
    super(injector, _objectHelperService);
  }

  ngOnInit(): void {
    this.mapParameters = MapParameters.getparameter({
      visibleLayersIds: null,
      leakYears: null,
      visibleThematicsIds: null,
      center: null,
      zoom: null,
      showFilters: false,
      settingArea: null,
      settingKey: null,
      navigatedElement: null,
      loadFromPersistency: false,
    });

    if (this.toggleMapOpacity$) {
      this.toggleMapOpacity$.pipe(untilDestroyed(this)).subscribe(() => this.setMapOpacity());
    }
  }

  private setMapOpacity(): void {
    this.mapOpacityEnabled = !this.mapOpacityEnabled;
    this._model.hideMap = this.mapOpacityEnabled;

    this.modelChange.emit(this._model);
  }

  onChartInitHandler(event): void {
    this.initSeries();
    this.onChartInit(event);
  }

  // In the case that chart settings are re-set on an already existing instance, remove current generic settings
  // and prepare to re-sync when new generic settings are available.
  onSetChartSettings(): void {
    this.markForResync();
    this.genericChartSettings = null;
    this.attemptInitialSync();
  }

  ngAfterContentChecked(): void {
    this.attemptInitialSync();
  }

  private markForResync(): void {
    this.initialSynced = false;
    this.isAllSynced.emit(false);
  }

  getGenericChartSettings(
    dataParameters?: SchematicChartDataParameters
  ): Observable<GenericSchematicChartSettings> {
    this.setLoading(false);
    return this.getSerieData(dataParameters ?? this.chartSettings.dataParameters).pipe(
      finalize(() => {
        this.setLoading(false);
        this.externalLoading.emit(false);
      }),
      switchMap((data: Schematic) => {
        this._initialModel = globalUtilsHelper.clone(data);
        this.loadedSchematic.emit(this._initialModel);
        this._model = data;
        this.mapOpacityEnabled = false; // <-- to force set opacity when map is loaded
        this.dataLoaded = data.nodes.length !== 0;
        return this._dataService.mapDataToGenericSettings(data).pipe(
          tap((settings) => {
            this._xAxisBounds = this.calculateAxisBounds(settings.series[0].data, 'x');
            this._yAxisBounds = this.calculateAxisBounds(settings.series[0].data, 'y');

            this.markForResync();
            this.attemptInitialSync();
          })
        );
      }),
      map((result) => result as GenericSchematicChartSettings)
    );
  }

  /**
   * Toggle a mode in which the graph nodes can be dragged around,
   * by adding graphic elements with listeners.
   */
  enableDragMode(): void {
    if (!this.chart) {
      return;
    }

    const nativeSettings = this.chart.getNativeSettings();
    const chartInstance = this.chart.getChartInstance();

    if (!nativeSettings || !chartInstance) {
      return;
    }

    this._updatedDataPointsHash = new Map<string, SchematicNodeProcessed>();

    this.generateDragGraphics(this._currentGraphSerie);
  }

  private initSeries(): void {
    const { graphSerie } = this.separateFirstGraphSerie();

    if (!graphSerie) {
      this._log.error({
        msg: "Trying to enable graph drag node in a chart that does not have any 'graph' serie.",
      });
      return;
    }

    if (!graphSerie.id) {
      graphSerie.id = globalUtilsHelper.generateGuid();
    }
    this._currentGraphSerie = graphSerie;
    this._originalGraphSerie = globalUtilsHelper.clone(graphSerie);
  }

  setSchematicOperation(mode: SchematicOperationsEnum): void {
    this._currentOperation = mode;

    if (this._currentOperation === SchematicOperationsEnum.DragDrop) {
      this._dragModeEnabled = true;
    }

    if (this._currentOperation !== SchematicOperationsEnum.DragDrop && this._dragModeEnabled) {
      this.disableDragMode();
      this._dragModeEnabled = false;
    }

    if (this._currentOperation === SchematicOperationsEnum.CreateRelation) {
      this._selectedRelationNodes = [];
    }
  }

  /**
   * Generate invisible drag graphic elements that, when dragged, update the position of real nodes.
   */
  private generateDragGraphics(graphSerieInput): void {
    const chartInstance = this.chart.getChartInstance();
    const graphSerie = globalUtilsHelper.clone(graphSerieInput, true);

    // if (graphSerie) {
    //   // We have changed the series, so we have to check if axes bounds have changed too.
    //   this.updateAxesBoundsFromData(graphSerie.data);
    // }

    this.setOption(
      {
        // Set a temporal cartesian chart serie to represent the dragging
        series: [graphSerie],
        graphic: graphSerie.data.map((dataItem: SchematicNodeProcessed, dataIndex) => {
          const originalSymbolSize = dataItem.symbolSize ?? graphSerie.symbolSize ?? 10;

          const coordinates = dataItem.value;
          const newPosition = chartInstance.convertToPixel('grid', coordinates);
          const graphic: any = {
            type: 'circle',
            shape: {
              r: originalSymbolSize / 2,
            },
            position: newPosition,
            invisible: true,
            draggable: true,
            z: 100,
            ondrag: this.chart.getCurryFnHelper(onGraphNodePointDragging, dataIndex),
          };

          return graphic;
        }),
      },
      {
        replaceMerge: 'series', // so there is only one serie
      }
    );

    const componentContext = this;

    function onGraphNodePointDragging(dataIndex) {
      // Caution: the "this" variable in this function needs to be binded to the contect of the function,
      // which is the graphic object, instead of the context of the component classes.
      const coordinates = chartInstance.convertFromPixel('grid', this.position);
      const dataItem: SchematicNodeProcessed = graphSerie.data[dataIndex];
      dataItem.value = coordinates as any;

      componentContext._updatedDataPointsHash.set(dataItem.id, dataItem);
      componentContext.updatedDataPoints.emit(componentContext._updatedDataPointsHash);

      componentContext.setOption({
        series: [graphSerie],
      });
    }

    chartInstance.on('dataZoom', (event) => {
      this.updateDragHandlerPosition(graphSerie, { includeChanges: true });
    });

    const resize$ = this.chart.getResizeObserver();

    if (resize$) {
      this._dragNodeResizeSubs?.unsubscribe();
      this._dragNodeResizeSubs = resize$.subscribe(() => {
        this.updateDragHandlerPosition(graphSerie, { includeChanges: true });
        // this.markForResync();
      });
    }
  }

  saveNode(nodeExtended) {
    const node = nodeExtended.node;
    const categoryName = nodeExtended.categoryName;
    const existingNode = this._model.nodes.find((n) => node.id === n.id);

    let nodeToInsert;
    if (existingNode) {
      nodeToInsert = { ...existingNode, ...node }; // merge
    } else {
      nodeToInsert = node; // simple assign
    }

    // insert the new/edited node
    const newNodeList = this._model.nodes.filter((n) => node.id !== n.id);
    newNodeList.push(nodeToInsert);
    this._model.nodes = newNodeList;

    // categories must be recalculated in case of add a new category o remove existing one by edition
    const categoriesInUse = [...new Set(newNodeList.map((m) => m.category))];

    const allCategories = [
      ...new Set([...this._model.categories, ...[{ name: categoryName, id: node.category }]]),
    ];

    this._model.categories = allCategories.filter((f) => categoriesInUse.includes(f.id));

    // the processed nodes must be recalculated in case the category list has changed
    let newProcessedNodeList = this.getCurrentProcessedNodesFromModel();

    const graphSerie = this._currentGraphSerie;

    // necessary to update the legend if new category is added
    const { newLegend, newTooltip } = this.updateLegendAndTooltip(); //--> this update the legend also

    // Update native serie (processedNode)
    this._currentGraphSerie.categories = this._model.categories;
    this._currentGraphSerie.data = newProcessedNodeList;

    // Notify update model (node)
    this.modelChange.emit(this._model);

    // Update chart
    this.setOption({
      series: [
        {
          ...graphSerie,
          data: newProcessedNodeList,
          categories: this._model.categories,
          tooltip: newTooltip,
        },
      ],
      legend: newLegend,
    });
  }

  private getCurrentProcessedNodesFromModel() {
    let newProcessedNodeList = [];
    let processedNode;
    let categoryEntry;

    this._model.nodes.forEach((node) => {
      processedNode = this._dataService.mapNodeToProcessedFromSerie(node, this._model.categories);
      categoryEntry = this._model.categories.find((f) => f.id === node.category);
      processedNode = this._dataService.extendNode(categoryEntry, processedNode);

      newProcessedNodeList.push(processedNode);
    });
    return newProcessedNodeList;
  }

  updateLegendAndTooltip() {
    const { legend, tooltip } = this.chart.getNativeSettings();

    const data = this._model.categories.map((category: SchematicCategory) => {
      const defaultLegendItem: GChartLegendItem = {
        name: category.name,
      };
      const extended = this._dataService.extendLegendItem(category, defaultLegendItem);
      return extended;
    });

    const newTooltip = this._dataService.updateTooltipWithCategories(
      tooltip,
      this._model.categories
    );

    const newLegend = { ...legend, data: data };

    return { newLegend, newTooltip };
  }

  private onDeleteNode(dataItem) {
    const graphSerie = this._currentGraphSerie;
    const newNodes = this._model.nodes.filter((n) => dataItem.id !== n.id);

    const categoriesInUse = [...new Set(newNodes.map((m) => m.category))];

    // Update model (node)
    this._model.categories = this._model.categories.filter((f) => categoriesInUse.includes(f.id));
    this._model.nodes = newNodes;

    const newRelations = graphSerie.links.filter(
      (f) => f.source !== dataItem.id && f.target !== dataItem.id
    );
    this._model.links = newRelations;

    // the processed nodes must be recalculated in case the category list has changed
    let newProcessedNodeList = this.getCurrentProcessedNodesFromModel();

    const { newLegend, newTooltip } = this.updateLegendAndTooltip();

    // Update native serie (processedNode)
    this._currentGraphSerie.categories = this._model.categories;
    this._currentGraphSerie.data = newProcessedNodeList;
    this._currentGraphSerie.links = newRelations;

    this.modelChange.emit(this._model);

    // Update chart
    this.setOption({
      series: [
        {
          ...graphSerie,
          data: newProcessedNodeList,
          categories: this._model.categories,
          links: newRelations,
          tooltip: newTooltip,
        },
      ],
      legend: newLegend,
    });
  }

  /**
   * Updates the axes max and min values by calculating it from the nodes's values.
   */
  private updateAxesBoundsFromData(nodes: SchematicNodeProcessed[]): void {
    // When points are dragged, possible mins and maxs of the axes may change -> recalculate them
    const xAxisBounds = this.calculateAxisBounds(nodes, 'x');
    const yAxisBounds = this.calculateAxisBounds(nodes, 'y');

    this.updateAxesBounds(xAxisBounds, yAxisBounds);
  }

  private updateAxesBounds(xAxisBounds: TAxisBounds, yAxisBounds: TAxisBounds): void {
    const defaultDataZooms = this._dataService.buildDataZooms();

    const xZoom = defaultDataZooms.find((dz) => dz.id === 'insideX');
    const yZoom = defaultDataZooms.find((dz) => dz.id === 'insideY');
    xZoom.startValue = xAxisBounds.min;
    xZoom.endValue = xAxisBounds.max;
    yZoom.startValue = yAxisBounds.min;
    yZoom.endValue = yAxisBounds.max;

    this.setOption(
      {
        dataZoom: [xZoom, yZoom],
      },
      {
        replaceMerge: 'dataZoom',
      }
    );
  }

  /**
   * Helper method for updateAxesBoundsFromData.
   */
  private updateAxesHelper(axis, bounds: TAxisBounds): void {
    if (axis) {
      if (Array.isArray(axis)) {
        axis.forEach((item) => {
          item.min = bounds.min;
          item.max = bounds.max;
        });
      } else {
        axis.min = bounds.min;
        axis.max = bounds.max;
      }
    }
  }

  /**
   * Disable drag and drop mode by deleting the graphic elements and clearing helper data.
   */
  disableDragMode(): void {
    const dummyGraphic = {
      type: 'circle',
      shape: {
        r: 0,
      },
      invisible: true,
      draggable: false,
    };
    this.setOption({
      graphic: dummyGraphic,
    });

    // TODO: unbind drag and dataZoom events
    this._originalGraphSerie = null;
    this._updatedDataPointsHash?.clear();
    this.updatedDataPoints.emit(this._updatedDataPointsHash);
    this._dragNodeResizeSubs?.unsubscribe();
    this._mainResizeSubs?.unsubscribe();

    setTimeout(() => {
      this.markForResync();
      this.attemptInitialSync();
    });
  }

  setEditMode(mode: boolean): void {
    this._editMode = mode;
  }

  /**
   * Resets any drag and drop changes without closing the chart.
   */
  resetDragChanges(): void {
    const chartInstance = this.chart?.getChartInstance();

    if (!chartInstance || !this._originalGraphSerie) {
      return;
    }

    this.generateDragGraphics(this._originalGraphSerie);

    this.updateDragHandlerPosition(this._originalGraphSerie, {
      includeChanges: false,
    });

    this._updatedDataPointsHash?.clear();
    this.updatedDataPoints.emit(this._updatedDataPointsHash);
  }

  /**
   * Refresh the position of all the nodes by using a serie's data as reference.
   */
  private updateDragHandlerPosition(graphSerie, settings: { includeChanges?: boolean }): void {
    if (!graphSerie || !this.chart) {
      return;
    }

    const chartInstance = this.chart.getChartInstance();

    const newGraphic = graphSerie.data.map((dataItem) => {
      let coordinates = dataItem.value;

      if (settings?.includeChanges && this._updatedDataPointsHash?.has(dataItem.id)) {
        coordinates = this._updatedDataPointsHash.get(dataItem.id).value;
      } else {
        coordinates = dataItem.value;
      }

      return {
        position: chartInstance.convertToPixel('grid', coordinates),
      };
    });

    this.setOption({
      graphic: newGraphic,
    });
  }

  /**
   * Extract the single graph serie from a schematic chart.
   */
  private separateFirstGraphSerie(): {
    graphSerie: TGraphSerie;
    restOfSeries: any[];
  } {
    const nativeSettings = this.chart?.getNativeSettings();

    if (!nativeSettings) {
      return null;
    }

    const { series } = nativeSettings;

    let graphSerie: any;
    if (Array.isArray(series)) {
      graphSerie = series.find((serie) => serie.type === 'graph') as any;
    } else {
      graphSerie = series?.type === 'graph' ? (series as any) : null;
    }

    let restOfSeries;
    if (Array.isArray(series)) {
      restOfSeries = series.filter((serie) => serie.name !== graphSerie.name);
    } else {
      restOfSeries = graphSerie ? [] : [graphSerie];
    }

    return {
      graphSerie,
      restOfSeries,
    };
  }

  onUpdatedDataPoints(hash: Map<string, SchematicNodeProcessed>): void {
    this.updatedDataPoints.emit(hash);
  }

  onChartDataZoomHandler(event: GChartDataZoomEvent): void {
    this.syncChartAndMap(event.items);
    // Continue elevating event
    this.onChartDataZoom(event);
  }

  onChartClickEventHandler(event: GChartClickEvent): void {
    if (this._editMode) {
      const dataType = event?.nativeEvent.dataType;

      if (this._currentOperation === SchematicOperationsEnum.EditNode && dataType === 'node') {
        this.getNodeInfo(event.nativeEvent.data);
      }

      if (this._currentOperation === SchematicOperationsEnum.DeleteNode && dataType === 'node') {
        this._selectedChartElement = event.nativeEvent.data;
        this.confirmDeleteNode();
      }

      if (
        this._currentOperation === SchematicOperationsEnum.CreateRelation &&
        dataType === 'node'
      ) {
        this.createRelationWithNode(event.nativeEvent.data);
      }

      if (
        this._currentOperation === SchematicOperationsEnum.DeleteRelation &&
        dataType === 'edge'
      ) {
        this._selectedChartElement = event.nativeEvent.data;
        this.confirmDeleteRelation();
      }
    }

    // Continue elevating event
    this.onChartClick(event);
  }

  private confirmDeleteRelation() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${COMPONENT_SELECTOR}.messages.confirm-delete-relation`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.onDeleteRelation(this._selectedChartElement);
        }
      });
  }

  onDeleteRelation(relation: any) {
    const graphSerie = this._currentGraphSerie;

    const newRelations = graphSerie.links.filter(
      (f) => f.source !== relation.source && f.target !== relation.target
    );

    // Update native serie
    this._currentGraphSerie.links = newRelations;

    // Update model (node)
    this._model.links = newRelations;

    // Update chart
    this.setOption({
      series: [{ ...graphSerie, links: newRelations }],
    });

    this.modelChange.emit(this._model);
  }

  private createRelationWithNode(data: any) {
    if (!this._selectedRelationNodes.includes(data)) {
      this._selectedRelationNodes.push(data);
    }

    if (this._selectedRelationNodes.length === this._necesaryNodesInARelation) {
      const graphSerie = this._currentGraphSerie;

      const newRelation = {
        source: this._selectedRelationNodes[0].id,
        target: this._selectedRelationNodes[1].id,
        itemStyle: this._selectedRelationNodes[0].itemStyle,
      };

      const inverseRelation = {
        source: this._selectedRelationNodes[1].id,
        target: this._selectedRelationNodes[0].id,
        itemStyle: this._selectedRelationNodes[1].itemStyle,
      };

      const existRelation = graphSerie.links.find(
        (f) => f.source === newRelation.source && f.target === newRelation.target
      );
      const existInverseRelation = graphSerie.links.find(
        (f) => f.source === inverseRelation.source && f.target === inverseRelation.target
      );

      if (!existRelation && !existInverseRelation) {
        // Update native serie (processedNode)
        this._currentGraphSerie.links.push(newRelation);

        // Update model (relation)
        this._model.links.push(newRelation);

        // Update chart
        this.setOption({
          series: [{ ...graphSerie, links: this._model.links }],
        });

        this.log({
          msg: 'New relation created',
          payload: {
            chartSettings: this.chart?.getChartInstance()?.getOption(),
          },
        });

        this.modelChange.emit(this._model);
      } else {
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({
            translateKey: `${this.T_SCOPE}.${COMPONENT_SELECTOR}.messages.relation-already-exists`,
          })
        );
      }

      this._selectedRelationNodes = [];
    }
  }

  private confirmDeleteNode() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${COMPONENT_SELECTOR}.messages.confirm-delete-node`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.onDeleteNode(this._selectedChartElement);
        }
      });
  }

  private getNodeInfo(event: any) {
    if (event?.id) {
      const selectedNode = this._model.nodes.find((n) => n.id === event.id);

      if (selectedNode) {
        this.notifyNodeInfo.emit(selectedNode);
      }
    }
  }

  onClickEventHandler(event) {
    // if mode "create node" / "edit node" -> send coordinates to form

    const allowedOperations = [
      SchematicOperationsEnum.CreateNode,
      SchematicOperationsEnum.EditNode,
      SchematicOperationsEnum.CreateRelation,
    ];

    if (this._editMode && allowedOperations.includes(this._currentOperation)) {
      const chartInstance = this.chart.getChartInstance();
      const pixelCoordinates = [event.offsetX, event.offsetY];
      this._lastClickedPosition = chartInstance.convertFromPixel('grid', pixelCoordinates);

      this.lastClickedPositionChange.emit(this._lastClickedPosition);
    }
  }

  /**
   * Initial step of the sync between chart and map.
   * Set the min/max values of the graph's data in the chart to obtain the main bounds.
   */
  private attemptInitialSync(): void {
    if (
      !this.initialSynced &&
      //this.genericChartSettings &&
      this.chart?.getChartInstance()?.getOption() &&
      this.mapComponent?.map
    ) {
      this.log({ msg: 'Start sync chart & map' });

      const xBounds = this.applyMarginBounds(this._xAxisBounds);
      const yBounds = this.applyMarginBounds(this._yAxisBounds);

      // Send chart bounds to map
      const chartInitialBounds: MapBounds = [xBounds.min, yBounds.min, xBounds.max, yBounds.max];

      this.log({
        msg: 'Sync step 1: Fitting map with chart initial bounds',
        payload: chartInitialBounds,
      });

      this.mapComponent.fitBounds(chartInitialBounds, { animate: false }, { initialFit: true });

      this.initialSynced = true;

      // Listen for any resize changes

      const resize$ = this.chart.getResizeObserver();

      if (resize$) {
        this._mainResizeSubs?.unsubscribe();
        this._mainResizeSubs = resize$.subscribe((newSize) => {
          //Map must get its new size before resyncing. This will trigger onMapMoveEndEvent with second flow.

          this.syncedHeight = `${newSize.height}px`;
          this.syncedWidth = `${newSize.width}px`;

          setTimeout(() => {
            // keep this comment to continue adjust investigation
            //if (!this._editMode) {
            this.mapComponent.resize({ isMapResize: true });
            //}
          });
        });
      }
    }
  }

  private applyMarginBounds(boundsInput: TAxisBounds): TAxisBounds {
    const bounds = { ...boundsInput };
    const boundsSize = bounds.max - bounds.min;
    const boundsMargin = boundsSize / this._initialMarginProportion;

    bounds.min = bounds.min - boundsMargin;
    bounds.max = bounds.max + boundsMargin;

    return bounds;
  }

  onMapLoaded() {
    if (this._model.hideMap && !this.mapOpacityEnabled) {
      this.toggleMapOpacity$.next();
    }
  }

  /**
   * Second step of the sync between chart and map.
   * Take the main bounds that the map has calculated, and only in initial phase, assing them as
   * the graph's main bounds.
   */
  onMapMoveEndEvent(event): void {
    if (event.initialFit) {
      const mapCalculatedBounds = this.mapComponent.getBounds();
      const calculatedBoundsX = { min: mapCalculatedBounds[0], max: mapCalculatedBounds[2] };
      const calculatedBoundsY = { min: mapCalculatedBounds[1], max: mapCalculatedBounds[3] };

      this.log({
        msg: 'Sync step 2: set map calculated bounds as chart bounds',
        payload: {
          bounds: [
            calculatedBoundsX.min,
            calculatedBoundsY.min,
            calculatedBoundsX.max,
            calculatedBoundsY.max,
          ],
          chartSettings: this.chart?.getChartInstance()?.getOption(),
        },
      });

      this.updateAxesBounds(calculatedBoundsX, calculatedBoundsY);

      setTimeout(() => {
        this.isAllSynced.emit(true);
      });
    } else if (event.fromDataZoom) {
      const mapCalculatedBounds = this.mapComponent.getBounds();
      const calculatedBoundsX = { min: mapCalculatedBounds[0], max: mapCalculatedBounds[2] };
      const calculatedBoundsY = { min: mapCalculatedBounds[1], max: mapCalculatedBounds[3] };

      // Update zoom bounds with the new map bounds
      const defaultDataZooms = this._dataService.buildDataZooms();
      defaultDataZooms[0].startValue = calculatedBoundsX.min;
      defaultDataZooms[0].endValue = calculatedBoundsX.max;

      defaultDataZooms[1].startValue = calculatedBoundsY.min;
      defaultDataZooms[1].endValue = calculatedBoundsY.max;

      this.setOption(
        {
          dataZoom: defaultDataZooms,
        },
        {
          replaceMerge: 'dataZoom',
        }
      );

      return;
    } else if (event.isMapResize) {
      this.log({ msg: 'Sync map & chart after map resize' });
      // If no initial fit, then the map was resized so it syncs with the chart. In this case, resync again.
      this.markForResync();
      this.attemptInitialSync();
    } else if (event.zoomToNode) {
      this.updateChartAfterMapFlyTo();
    } else {
      // Case for editor bar close.
      this.markForResync();
      this.attemptInitialSync();
    }
  }

  /**
   * Calculate max and min of cartesian axes for a group of nodes.
   */
  private calculateAxisBounds = (nodes: SchematicNodeProcessed[], axis: 'x' | 'y'): TAxisBounds => {
    const getCoordinate = axis === 'x' ? this.getXCoordinate : this.getYCoordinate;
    const values = nodes.map((node) => getCoordinate(node));
    const max = Math.max.apply(null, values);
    const min = Math.min.apply(null, values);

    return {
      max,
      min,
    };
  };

  private updateChartAfterMapFlyTo() {
    //Get new map bounds after flyTo
    const mapCalculatedBounds = this.mapComponent.getBounds();
    const calculatedBoundsX = { min: mapCalculatedBounds[0], max: mapCalculatedBounds[2] };
    const calculatedBoundsY = { min: mapCalculatedBounds[1], max: mapCalculatedBounds[3] };

    // Update zoom bounds with the new map bounds
    const defaultDataZooms = this._dataService.buildDataZooms();
    defaultDataZooms[0].startValue = calculatedBoundsX.min;
    defaultDataZooms[0].endValue = calculatedBoundsX.max;

    defaultDataZooms[1].startValue = calculatedBoundsY.min;
    defaultDataZooms[1].endValue = calculatedBoundsY.max;

    this.highlightNode();
    const graphSerie = this._currentGraphSerie;

    // Update chart
    this.setOption(
      {
        series: [
          {
            ...graphSerie,
            data: graphSerie.data,
          },
        ],
        dataZoom: defaultDataZooms,
      },
      {
        replaceMerge: 'dataZoom',
      }
    );
  }

  highlightNode() {
    if (this._nodeToHighlight) {
      this._currentGraphSerie.data.forEach((node) => {
        if (node.id === this._nodeToHighlight.id) {
          node.itemStyle = {
            ...node.itemStyle,
            borderColor: this._highlightColor,
          };
          node.symbolSize = this._highlightedSymbolSize;
        } else {
          node.itemStyle = { color: node.itemStyle.color };
          node.symbolSize = this._commonSymbolSize;
        }
      });

      this._nodeToHighlight = null;
    }
  }

  /**
   * Translate current chart dataZoom coordinates to the map.
   */
  syncChartAndMap(dataZoomItems: { [dataZoomName: string]: GChartDataZoomItemEvent } = null): void {
    if (!this.mapComponent?.map) {
      return;
    }

    const items = this.chart.getCurrentDataZooms();
    if (items) {
      const mapBounds = this.buildMapBoundCoordinates(items);
      this.chartBounds = mapBounds;
      this.mapComponent.fitBounds(mapBounds, { animate: false }, { fromDataZoom: true });
    }
  }

  /**
   * Extract coordinates from dataZoom objects and create map bounds model.
   */
  private buildMapBoundCoordinates(dataZoomItems: {
    [dataZoomName: string]: GChartDataZoomItemEvent;
  }): MapBounds {
    const itemsList = Object.values(dataZoomItems);
    // Here we are assuming that if there are more than one zoom for the same axis, their values are synced.
    const xAxisZoom = itemsList.find((item) => item.xAxisIndex === 0);
    const yAxisZoom = itemsList.find((item) => item.yAxisIndex === 0);

    const result: MapBounds = [
      xAxisZoom.startValue,
      yAxisZoom.startValue,
      xAxisZoom.endValue,
      yAxisZoom.endValue,
    ];

    return result;
  }

  protected getSerieData(params: any): Observable<any> {
    return this._dataService.getData(params);
  }

  /**
   * Updates chart with native-only settings. Used to update series points locations or axes bounds.
   */
  private setOption(
    option: any,
    additionalParams: {
      notMerge?: boolean;
      lazyUpdate?: boolean;
      replaceMerge?: string | string[];
    } = null
  ): void {
    const chartInstance = this.chart?.getChartInstance();
    const additional = {
      lazyUpdate: true,
      ...additionalParams,
    };
    try {
      chartInstance?.setOption(option, additional);
    } catch (error) {
      setTimeout(() => {
        chartInstance?.setOption(option, additional);
      });
    }
  }

  getXCoordinate = (node: SchematicNodeProcessed): number => {
    return this._dataService.getXCoordinate(node);
  };

  getYCoordinate = (node: SchematicNodeProcessed): number => {
    return this._dataService.getYCoordinate(node);
  };

  setDateParams(startDate: Date, endDate: Date) {}

  getParams(): IChartDataParameters {
    return null;
  }

  setParams(newParams: IChartDataParameters) {}

  private log(data: LogData): void {
    this._log.info(data, !this._enableLog);
  }

  zoomToNode(node: SchematicNode): void {
    const options = {
      center: [node.y, node.x],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    };
    this._nodeToHighlight = node;
    this.mapComponent.flyTo(options, { zoomToNode: true });
  }
}
