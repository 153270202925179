<wlm-spinner-wrapper [showSpinner]="showSpinner">
  <div class="vi-container">
    <div class="items-align">
      <img src="assets/images/water-drop.gif" height="70px" />
      <span class="title">Water Loss</span>
    </div>

    <div *ngFor="let product of productsVersion | keyvalue">
      <wlm-version-info-item
        [label]="T_SCOPE + '.' + product.key | translate"
        [value]="
          !product?.value || product?.value === ''
            ? (T_SCOPE + '.unknown' | translate)
            : product.value
        "
      >
      </wlm-version-info-item>
    </div>

    <wlm-version-info-item
      [label]="T_SCOPE + '.release-date' | translate"
      [value]="releaseDate ? (releaseDate | date) : null"
    ></wlm-version-info-item>

    <wlm-version-info-item
      [label]="T_SCOPE + '.max-users' | translate"
      [value]="versionInfo?.maxUsers"
    ></wlm-version-info-item>

    <wlm-version-info-item
      [label]="T_SCOPE + '.capacity' | translate"
      [value]="versionInfo?.capacity"
    ></wlm-version-info-item>

    <wlm-version-info-item
      [label]="T_SCOPE + '.expiration-date' | translate"
      [value]="canExpire ? versionInfo?.expiration : (T_SCOPE + '.expiration-none' | translate)"
    ></wlm-version-info-item>

    <div class="vi-item">
      <div class="vi-label">
        <h4>{{ T_SCOPE + '.enabled-modules' | translate }}</h4>
      </div>
      <div class="vi-value">
        <div class="modules-container">
          <h4 *ngFor="let module of versionInfo?.enabledModules">
            {{ moduleTranslateKeys[module] | translate }}
          </h4>
        </div>
      </div>
    </div>

    <div *ngIf="!showSpinner" class="footer-position">
      <div class="second-footer">
        <div class="copyright-and-legal">
          <p>&copy; {{ copyrightText }}</p>
          <p>
            <a [href]="legalDocumentLink" target="_blank" class="legal-document-link">{{
              T_SCOPE + '.legal-document' | translate
            }}</a>
          </p>
        </div>
        <div class="close-button">
          <button mat-raised-button class="mat-raised-button" (click)="close()">
            {{ 'common.close' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</wlm-spinner-wrapper>
