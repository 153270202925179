import { Subscription } from 'rxjs';

export class SubscriptionManager {
  private _subscriptions: Subscription[] = [];

  public set subs(value: Subscription[]) {
    this._subscriptions = this._subscriptions.concat(value);
  }

  public set addSub(value: Subscription) {
    this._subscriptions.push(value);
  }

  public get subs(): Subscription[] {
    return this._subscriptions;
  }

  public unsubscribe(): void {
    for (const subs of this._subscriptions) {
      if (subs && !subs.closed) {
        subs.unsubscribe();
      }
    }
    this._subscriptions = [];
  }
}
