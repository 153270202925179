import { IGisLayer } from './gis-layer';
import { IGisLayerYear } from './gis-layer-year';

export class FiltrableGisLayer {
  hierarchyElements: IGisLayer[];
  networkElements: IGisLayer[];
  outstandingLeaks: IGisLayer[];
  closedLeaks: IGisLayer[];
  leakYears: IGisLayerYear[];
  activities: IGisLayer[];

  public static getLayers(layers: FiltrableGisLayer): IGisLayer[] {
    const ne = this.flatGisLayer(layers.networkElements);

    return [].concat(
      layers.hierarchyElements,
      ne,
      layers.outstandingLeaks,
      layers.closedLeaks,
      layers.activities
    );
  }

  public static flatGisLayer(gisLayers: IGisLayer[]): IGisLayer[] {
    let result = [];

    gisLayers.forEach((layer) => {
      result.push({ ...layer });
      if (!!layer.sublayers && !!layer.sublayers.length) {
        result.push(...layer.sublayers);
      }
    });

    return result;
  }
}
