import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericMappersModule } from '../../services/generic-mappers/generic-mappers.module';
import { LabelValueListComponent } from './label-value-list.component';

@NgModule({
  declarations: [LabelValueListComponent],
  exports: [LabelValueListComponent],
  imports: [CommonModule, GenericMappersModule],
})
export class LabelValueListModule {
  constructor() {}
}
