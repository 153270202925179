import { SortDescriptor } from '@progress/kendo-data-query';

export class DragListSettings {
  pageSize: number;
  displayFieldName: string;
  idFieldName?: string;
  orderBy: SortDescriptor[];
  dataService: string;
  useQueryParams = false;
  oDataFilters?: Map<string, any>;
  cacheOptions = { avoid: true };
  isReadOnly = false;
  allowDrop = true;
  useGlobalSpinner = true;
  scrollId: string = 'none';
  disableDropItems = false;
  disableFilter = false;
  disableSelection = false;

  constructor(init?: Partial<DragListSettings>) {
    Object.assign(this, init);
  }
}
