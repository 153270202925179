<kendo-treelist
  [data]="gridSettings.zones"
  [height]="treelistHeight"
  kendoTreeListExpandable
  [resizable]="resizable"
>
  <kendo-treelist-column [expandable]="expandable" [width]="selectionColumnWidth">
    <!-- Temporarily commented until select all feature returns -->
    <!-- <ng-template kendoTreeListHeaderTemplate let-dataItem>
      <mat-checkbox
        (change)="selectAllChanged($event)"
        [checked]="selectAllValue"
        [indeterminate]="selectAllIndeterminateValue"
      ></mat-checkbox>
    </ng-template> -->
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [checked]="rowSelection.get(dataItem)"
        [indeterminate]="rowIndeterminate.get(dataItem)"
        (change)="rowSelectionChanged($event, dataItem)"
      ></mat-checkbox>
    </ng-template>
  </kendo-treelist-column>

  <kendo-treelist-column [expandable]="expandable" title="Zone" [width]="zoneColumnWidth">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      {{ dataItem }}
    </ng-template>
  </kendo-treelist-column>
  <ng-container *ngFor="let algorithm of gridSettings.algorithms">
    <kendo-treelist-column [expandable]="expandable" [title]="algorithm | uppercase" [width]="algorithmColumnWidth">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <mat-checkbox
          [checked]="gridSettings.values.get(dataItem).get(algorithm)"
          (change)="algorithmSelectionChanged($event, dataItem, algorithm)"
        ></mat-checkbox>
      </ng-template>
    </kendo-treelist-column>
  </ng-container>
</kendo-treelist>
