import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UoMService } from '../../../uom/uom.service';
import { LogScope } from '../../../wlm-log/log-scope';
import { LogService } from '../../../wlm-log/log.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToAnyUomMapperParams } from '../mapper-params/to-any-uom-mapper-params';
import { ToUomMapperParams } from '../mapper-params/to-uom-mapper-params';

@Injectable()
export class ToAnyUoMMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToAnyUoM;
  params: ToUomMapperParams;

  constructor(private _uomService: UoMService, private _logService: LogService) {
    super();
  }

  map = (value: number, model: any, params: ToAnyUomMapperParams = null): Observable<string> => {
    if (typeof value === 'undefined') {
      return value;
    }
    const byIds = this.canGetUoMByIds(params);
    const byFn = this.canGetUoMByFn(params);

    if (!byIds && !byFn) {
      this._logService.error({ msg: `Must have some settings to convert to UoM.` });
      return of(String(value));
    }

    if (byFn) {
      return this.calculateByFn(value, model, params);
    }

    if (byIds) {
      return this.calculateByIds(value, params);
    }
  };

  private calculateByFn(
    value: number,
    model: any,
    params: ToAnyUomMapperParams
  ): Observable<string> {
    const uomToId = params.toUoMPropertyFn(model);

    if (typeof uomToId === 'undefined') {
      this._logService.error({
        scope: LogScope.Mappers,
        msg: `The field does not have a valid UoM.`,
        payload: model,
      });
      return of(String(value));
    }

    return this.calculate(value, { ...params, unitTypeToId: uomToId });
  }

  private calculateByIds(value: number, params: ToAnyUomMapperParams): Observable<string> {
    return this.calculate(value, { ...params, unitTypeToId: params.unitTypeToId });
  }

  private calculate = (
    value,
    ids: { dimensionTypeId; unitTypeFromId?; unitTypeToId? }
  ): Observable<string> => {
    return this._uomService.getAllNoExceptions().pipe(
      map((unitTypes) => {
        let unit;
        let conversionFactor;

        if (ids.unitTypeFromId === ids.unitTypeToId) {
          unit = unitTypes.find(
            (ut) =>
              ut.dimensionTypeId === ids.dimensionTypeId && ut.unitTypeToId === ids.unitTypeToId
          );

          conversionFactor = 1;
        } else {
          unit = unitTypes.find(
            (ut) =>
              ut.dimensionTypeId === ids.dimensionTypeId &&
              ut.unitTypeFromId === ids.unitTypeFromId &&
              ut.unitTypeToId === ids.unitTypeToId
          );

          conversionFactor = unit.conversionFactor;
        }

        if (!unit) {
          this._logService.error({
            scope: LogScope.Mappers,
            msg: `No unit could be found for settings`,
            payload: ids,
          });
          return String(value);
        }

        let convertedValue = this._uomService.uomMultiply(
          String(value),
          String(conversionFactor),
          false,
          ids.dimensionTypeId
        );

        convertedValue = `${convertedValue} ${unit.unitTypeToDescription}`;
        return convertedValue;
      })
    );
  };

  private canGetUoMByIds = (params: ToAnyUomMapperParams) => {
    const result =
      this.defined(params.dimensionTypeId) &&
      this.defined(params.unitTypeFromId) &&
      this.defined(params.unitTypeToId);
    return result;
  };

  private canGetUoMByFn = (params: ToAnyUomMapperParams) => {
    const result =
      this.defined(params.dimensionTypeId) &&
      this.defined(params.unitTypeFromId) &&
      this.defined(params.toUoMPropertyFn);
    return result;
  };

  private defined = (value) => typeof value !== 'undefined' && value !== null;
}
