import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  networkElementTypesDvAlgorithms,
  networkElementTypesDvLarsSworpsAlgorithms,
} from 'src/app/common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { NetworkElementWizardStepConfiguration } from 'src/app/common-modules/dependencies/shared/ne-wizard-step-configuration';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';

const COMPONENT_SELECTOR = 'wlm-data-visualization-ne-algorithm-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-ne-algorithm-popup.component.html',
  styleUrls: ['./data-visualization-ne-algorithm-popup.component.scss'],
})
export class DataVisualizationNeAlgorithmPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  private readonly _hierarchyElementIdFieldName = 'hierarchyElementId';
  private readonly _hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  private readonly _networkElementTypeIdFieldName = 'networkElementTypeId';

  algorithm: IAlgorithmDto;
  defaultFamily: string;

  configReady = false;
  gridName: string;
  filterConfig: NetworkElementWizardStepConfiguration;

  selectedElements: INetworkElementDto[];

  persistedHeFamilyId: string;
  persistedHeIds: string[];
  persistedNeTypeIds: number[];

  get componentName(): string {
    return 'DataVisualizationNeAlgorithmPopupComponent';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) { algorithm }: any,
    private _dialogRef: MatDialogRef<DataVisualizationNeAlgorithmPopupComponent>,
    private _globalsService: GlobalsService,
    private _localStorageService: LocalStorageService
  ) {
    this.algorithm = algorithm;
  }

  ngOnInit(): void {
    this.loadPersistedState();
    this.setGridName();

    this._globalsService.getDefaultHierarchyFamilyId().subscribe({
      next: (family) => {
        this.defaultFamily = family;

        this.filterConfig = this.getNetworkElementFilterConfiguration(this.defaultFamily);

        this.configReady = true;
      },
    });
  }

  onNetworkElementsChanged(selectedNes: INetworkElementDto[]) {
    this.selectedElements = selectedNes;
  }

  cancel(): void {
    this._dialogRef.close();
  }

  add(): void {
    this._dialogRef.close({
      algorithm: this.algorithm,
      elements: this.selectedElements,
    });
  }

  private loadPersistedState() {
    // Persisted HeFamilyId
    const heFamilyId = this.getPersisted(
      `${this.persistencyKey()}-${this._hierarchyElementFamilyFieldName}`,
      null,
      false
    );

    this.persistedHeFamilyId = heFamilyId?.value ? heFamilyId?.value[0]?.value : null;

    // Persisted HeIds
    const heIds = this.getPersisted(
      `${this.persistencyKey()}-${this._hierarchyElementIdFieldName}`,
      null,
      false
    );

    this.persistedHeIds = heIds?.value ? heIds?.value : null;

    // Persisted NeTypeIds
    const neIds = this.getPersisted(
      `${this.persistencyKey()}-${this._networkElementTypeIdFieldName}`,
      null,
      false
    );

    this.persistedNeTypeIds = neIds?.value ? neIds?.value?.map((x) => x.value) : null;
  }

  private getNetworkElementFilterConfiguration(
    defaultFamilyId: string
  ): NetworkElementWizardStepConfiguration {
    return {
      persistencyArea: this.persistencyKey(),
      hierarchyElementIdFieldName: this._hierarchyElementIdFieldName,
      hierarchyElementFamilyFieldName: this._hierarchyElementFamilyFieldName,
      networkElementTypeIdFieldName: this._networkElementTypeIdFieldName,
      defaultNetworkElementId: [],
      defaultNetworkElementTypeId: [],
      defaultFamilyId,
      initialFamilyId: this.persistedHeFamilyId ?? defaultFamilyId,
      initialNetworkElementId: this.persistedHeIds ?? [],
      initialNetworkElementTypeId: this.persistedNeTypeIds ?? [],
      hideFilterButtons: true,
      cssClass: 'remove-filters-background',
      filterListElements:
        this.algorithm.entityTypeId === EntityTypes.larsAndSworpsSite
          ? networkElementTypesDvLarsSworpsAlgorithms
          : networkElementTypesDvAlgorithms,
    };
  }

  private setGridName() {
    switch (this.algorithm.entityTypeId) {
      case EntityTypes.larsAndSworpsSite:
        this.gridName = 'NeLarsAndSworpsSites';
        break;

      case EntityTypes.networkElement:
      default:
        this.gridName = 'NetworkElementsSelection';
        break;
    }
  }

  private getPersisted(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    return this._localStorageService.getTyped(key, defaultValue, useLocalStorage);
  }

  private persistencyKey(): string {
    return `${this.componentName}-${this.algorithm?.entityTypeId ?? 0}`;
  }
}
