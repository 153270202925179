import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, ReplaySubject } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { MapThematicKpi } from '../map-thematic/models/map-thematic-kpi';

const COMPONENT_SELECTOR = 'wlm-map-tab-panel';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-tab-panel.component.html',
  styleUrls: ['./map-tab-panel.component.scss'],
})
export class MapTabPanelComponent implements OnInit {
  @Input() displayed: boolean;

  private _baseType: string;
  public get baseType(): string {
    return this._baseType;
  }
  @Input() public set baseType(value: string) {
    this._baseType = value;
    this.baseTypeChange.emit(value);
  }

  private _visibleLayersIds: number[];
  public get visibleLayersIds(): number[] {
    return this._visibleLayersIds;
  }
  @Input() public set visibleLayersIds(value: number[]) {
    this._visibleLayersIds = value;
    this.visibleLayersIdsChange.emit(value);
  }

  private _leakYears: number[];
  public get leakYears(): number[] {
    return this._leakYears;
  }
  @Input() public set leakYears(value: number[]) {
    this._leakYears = value;
    this.leakYearsChange.emit(value);
  }

  private _visibleThematicsIds: string[];
  public get visibleThematicsIds(): string[] {
    return this._visibleThematicsIds;
  }
  @Input() public set visibleThematicsIds(value: string[]) {
    this._visibleThematicsIds = value;
    this.visibleThematicsIdsChange.emit(value);
  }
  @Input() thematics: MapThematicKpi[];

  @Output() baseTypeChange = new EventEmitter<string>();
  @Output() visibleLayersIdsChange = new EventEmitter<number[]>();
  @Output() leakYearsChange = new EventEmitter<number[]>();
  @Output() allFiltersLoaded = new EventEmitter<void>();
  @Output() visibleThematicsIdsChange = new EventEmitter<string[]>();
  @Output() kpiInfoChange = new EventEmitter<MapThematicKpi>();

  mapFilterLoad$ = new ReplaySubject<boolean>();
  mapThematicLoad$ = new ReplaySubject<boolean>();

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {
    combineLatest([this.mapFilterLoad$, this.mapThematicLoad$])
      .pipe(untilDestroyed(this))
      .subscribe(([mapFilterLoad, mapThematicLoad]) => {
        if (mapFilterLoad && mapThematicLoad) {
          this.allFiltersLoaded.emit();
        }
      });
  }

  onInternalLoadMapFilter() {
    this.mapFilterLoad$.next(true);
  }

  onInternalLoadThematicFilter() {
    this.mapThematicLoad$.next(true);
  }

  onInternalKpiInfoChange(value: MapThematicKpi) {
    this.kpiInfoChange.emit(value);
  }
}
