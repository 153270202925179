import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { globalUtilsHelper } from '../shared/helpers/global-utils-helper';
import { BaseSpinnerOverlayComponent } from './base-spinner-overlay.component';
import { SpinnerSettings } from './models/spinner-settings';
import { SpinnerOverlayLogoComponent } from './spinner-overlay-logo/spinner-overlay-logo.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private overlayRef: OverlayRef = null;
  // Contains urls/keys that are currently loading.
  private loadingMap = new Map<string, boolean>();
  // Main loading handler. Allows the user to listen to loading changes.
  private loadingSubs$ = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubs$.asObservable();

  constructor(private readonly _overlay: Overlay) {}

  /**
   * Setting the loading flag requires a key to track all processes. Usually can be an URL.
   * When no url is available, this method facilitates the generation of a random key.
   */
  generateKey(): string {
    return globalUtilsHelper.generateGuid();
  }

  /**
   * Main method. Sets if an url (or a generic key) is being loaded, and keeps track of them.
   * @param loading If the current url/key is loading or not.
   * @param key The current url/key.
   */
  setLoading(loading: boolean, key: string, settings?: SpinnerSettings): void {
    if (!key) {
      throw new Error(
        'The request URL/KEY must be provided to the LoadingService.setLoading function'
      );
    }
    if (loading) {
      // Save the loading key.
      this.loadingMap.set(key, loading);
      this.loadingSubs$.next(true);
      // Show the overlay.
      this.show(settings, key);
    } else if (!loading && this.loadingMap.has(key)) {
      // Set the key as loaded, and forget it.
      this.loadingMap.delete(key);
    }
    // Check if all urls finished loading.
    if (this.loadingMap.size === 0) {
      this.loadingSubs$.next(false);
      // Hide the overlay.
      this.hide(settings);
    }
  }

  buildSetLoadingFn = (key: string = null, settings?: SpinnerSettings) => {
    if (!key) {
      key = this.generateKey();
    }
    return (loading: boolean) => this.setLoading(loading, key, settings);
  };

  /**
   * Ensures the spinner is closed by fimishing all pending keys.
   */
  forceCloseLoading(): void {
    this.loadingMap.clear();
    this.loadingSubs$.next(false);
    this.hide();
  }

  /**
   * Shows a spinner overlay. Should be triggered from setLoading.
   */
  private show(settings: SpinnerSettings, spinnerKey: string) {
    if (!this.overlayRef) {
      // Returns an OverlayRef (which is a PortalHost)
      this.overlayRef = this._overlay.create();
      // Create ComponentPortal that can be attached to a PortalHost
      const overlayComponent: Type<BaseSpinnerOverlayComponent> =
        settings?.overlayType === 'logo' ? SpinnerOverlayLogoComponent : SpinnerOverlayComponent;

      const spinnerOverlayPortal = new ComponentPortal(overlayComponent);
      const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
      // Set spinnerKey so the overlays can self-close.
      component.instance.setSpinnerKey(spinnerKey);
    }
  }

  /**
   * Hides the spinner overlay. Should be triggered from setLoading.
   */
  private hide(settings?: SpinnerSettings) {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }
}
