<div class="component-container">
  <h3>{{ titleKey | translate }}</h3>

  <hr class="title-separator" />

  <mat-dialog-content *ngIf="form" [formGroup]="form" class="form-container">
    <mat-form-field class="max-width" appearance="outline">
      <mat-label>{{ T_SCOPE + '.home-page' | translate }}</mat-label>
      <mat-select [formControlName]="formControlKey">
        <mat-option *ngFor="let navOption of navOptions" [value]="navOption.key">{{
          navOption.title
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <div class="buttons-container">
    <button *isSuperUser mat-raised-button color="primary" (click)="saveConfiguration(true)">
      {{ 'common.save-system' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="saveConfiguration(false)">
      {{ 'common.save-user' | translate }}
    </button>
    <button mat-raised-button (click)="close()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</div>
