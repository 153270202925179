import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comparisonImageIconName',
})
export class ComparisonImageIconName implements PipeTransform {
  constructor() {}

  transform(value: number, compValue: number): string {
    if (this.checkIfUndefined(value) || this.checkIfUndefined(compValue)) {
      return '';
    } else if (value == compValue) {
      return 'equals';
    }
    return value > compValue ? 'arrow_upward_alt' : 'arrow_downward_alt';
  }

  checkIfUndefined(value) {
    return value == null || value == undefined;
  }
}
