<div #chartContainer class="schematic-chart-container overlap-container chart-map-sync">
  <wlm-base-map
    class="map-resize overlap-inner"
    *ngIf="genericChartSettings && dataLoaded"
    [mapParameters]="mapParameters"
    (moveEndEvent)="onMapMoveEndEvent($event)"
    (mapLoaded)="onMapLoaded()"
    [style.height]="syncedHeight"
    [style.width]="syncedWidth"
    [toggleMapOpacity$]="toggleMapOpacity$"
  ></wlm-base-map>
  <wlm-generic-chart
    class="overlap-outer"
    *ngIf="genericChartSettings && dataLoaded"
    [settings]="genericChartSettings"
    [loadingHandler$]="loadingHandler$"
    [disableFixedSize]="true"
    [listenResizeElement]="chartContainerElement"
    [chartDataZoomDebounceTime]="currentDebounceTime"
    (chartInitEvent)="onChartInitHandler($event)"
    (chartDataZoomEvent)="onChartDataZoomHandler($event)"
    (chartLegendSelectedEvent)="onChartLegendSelected($event)"
    (chartLoaded)="onChartLoaded($event)"
    (chartFinished)="onChartFinished($event)"
    (updatedDataPoints)="onUpdatedDataPoints($event)"
    (chartClickEvent)="onChartClickEventHandler($event)"
    (clickEvent)="onClickEventHandler($event)"
  ></wlm-generic-chart>

  <wlm-no-results [showNoResult]="!dataLoaded && !isLoading"> </wlm-no-results>
</div>
