import { Injector } from '@angular/core';
import { ChartSettings } from '../chart-settings';
import { ChartType } from '../chart-type.enum';
import { HistoricalChartDataParameters } from './historical-chart-data-parameters';

export class HistoricalChartSettings extends ChartSettings {
  chartType: ChartType = ChartType.historical;
  dataParameters: HistoricalChartDataParameters;
  chartHeight = '400px';

  validationService?: string;
  injector?: Injector;

  constructor(init: Partial<HistoricalChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
