<wlm-placeholder-overlay
  *ngIf="!selectedInterface"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-logs-interfaces-container
  *ngIf="selectedInterface"
  [selectedInterface]="selectedInterface"
  (refreshSelector)="onRefreshSelector()"
></wlm-logs-interfaces-container>
