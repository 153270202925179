export enum FieldValidatorValueModes {
  // The selected value corresponds to the "name" property of other field.
  Model = 'Model',
  Fixed = 'Fixed',
  Expression = 'Expression',
}

export enum FieldValidatorParams {
  Strict = 'Strict',
}
