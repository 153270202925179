import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FlaggingValidator {
  static notificationFieldsValidation(): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const enabledNotifications = form.get('checkEnableNotifications').value;
      const notificationTitle = form.get('notificationTitle')?.value;
      const notificationDescription = form.get('notificationDescription').value;

      if (enabledNotifications) {
        const hasBlanksResult = this.hasBlanks(notificationTitle, notificationDescription);
        const fieldsAreEmpty = hasBlanksResult || !notificationTitle || !notificationDescription;

        return fieldsAreEmpty ? { notificationsFields: true } : null;
      }

      return null;
    };
  }
  static hasBlanks(notificationTitle: string, notificationDescription: string) {
    if (notificationTitle == null || notificationDescription == null) {
      return true;
    }

    return notificationTitle.trim().length === 0 || notificationDescription.trim().length === 0;
  }
}
