import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { HierarchyPageComponent } from './hierarchy-page/hierarchy-page.component';

const routes: Routes = [
  {
    path: '',
    component: HierarchyPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.Hierarchy,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HierarchyRoutingModule {
  static injector: Injector;

  constructor(injector: Injector) {
    HierarchyRoutingModule.injector = injector;
  }
}
