import Dexie, { IndexableType } from 'dexie';

export abstract class BaseDatabase<TModel = any, TKey = IndexableType> extends Dexie {
  constructor(db: string, tableDef: string, version?: number) {
    super(db);

    this.version(version ?? 1).stores({
      dbTable: tableDef,
    });
  }

  public dbTable: Dexie.Table<TModel, TKey>;
}
