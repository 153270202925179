<div *ngIf="dateRangeForm">
  <form [formGroup]="dateRangeForm" [class]="formClass">
    <mat-form-field dateWithTimeFormat class="date-time-picker">
      <input
        matInput
        [ngxMatDatetimePicker]="pickerStart"
        formControlName="start"
        [min]="minDate"
        [max]="maxDate"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerStart
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="enableMeridian"
      >
        <ng-template>
          <span>{{ 'common.accept' | translate }}</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <div class="separator">{{ rangeSeparator }}</div>

    <mat-form-field dateWithTimeFormat class="date-time-picker">
      <input
        matInput
        [ngxMatDatetimePicker]="pickerEnd"
        formControlName="end"
        [min]="minDate"
        [max]="maxDate"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerEnd
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="enableMeridian"
      >
        <ng-template>
          <span>{{ 'common.accept' | translate }}</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </form>
</div>
