import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHierarchyElementDto } from 'src/app/common-modules/dependencies/he/hierarchy-element.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { HierarchyChildrenConfigurationDto } from '../models/hierarchy-children-configuration.dto';

const endpointUrl = '/api/he';
@Injectable({
  providedIn: 'root',
})
export class HierarchyChildrenConfiguratorService extends GridODataService<IHierarchyElementDto> {
  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available-children`);
  }

  protected mapResponse(
    response: PagedResultDto<IHierarchyElementDto>
  ): PagedResultDto<IHierarchyElementDto> {
    return response;
  }

  getChildrenByElementId(
    elementId: string,
    hierarchyFamilyId: string
  ): Observable<IHierarchyElementDto[]> {
    const params = { hierarchyFamilyId };
    return this.httpCacheClient.get(
      `${this.baseUrl}${endpointUrl}/${elementId}`,
      { avoid: true },
      params
    );
  }

  saveHierarchyChildrenConfiguration(
    childrenConfiguration: HierarchyChildrenConfigurationDto
  ): Observable<boolean> {
    return this.httpCacheClient
      .post(`${this.baseUrl}${endpointUrl}`, childrenConfiguration)
      .pipe(map(() => true));
  }
}
