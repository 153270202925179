import { Component, forwardRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { UsersCachedService } from 'src/app/common-modules/shared/services/users/users-cached.service';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-user-column';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './user-column.component.html',
  styleUrls: ['./user-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => UserColumnComponent),
    },
  ],
})
export class UserColumnComponent extends WlmColumnComponent implements OnInit {
  constructor(private _usersCachedService: UsersCachedService) {
    super();
  }

  ngOnInit(): void {
    this._usersCachedService
      .getCachedData()
      .pipe(
        untilDestroyed(this),
        map((usersHash) => {
          const activeUsersHash = {};
          Object.keys(usersHash).forEach((key) => {
            const user = usersHash[key];
            if (user.isActive) {
              activeUsersHash[key] = `${user.name} ${user.surname}`;
            }
          });
          return activeUsersHash;
        })
      )
      .subscribe((activeUsersHash) => {
        this.elements = new Map(Object.entries(activeUsersHash));
      });
  }
}
