import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { MergedZonesStateFields } from '../../models/merged-zones-state.fields';
import { MergedZonesDto } from '../../models/merged-zones.dto';
import { MergedZonesService } from '../../services/merged-zones.service';

const COMPONENT_SELECTOR = 'wlm-merged-zones-configurator-widget';
export const MZ_CONFIGURATOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-configurator-widget.component.html',
  styleUrls: ['./merged-zones-configurator-widget.component.scss'],
  providers: [ReduxStateService],
})
export class MergedZonesConfiguratorWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'MergedZonesConfiguratorWidgetComponent';
  }

  private readonly _hierarchyLevelFieldName = 'HierarchyElementTypeIds';
  private readonly _hierarchyFamilyFieldName = 'HierarchyFamilyId';

  readonly phTitleKey = `${this.T_SCOPE}.must-select-title`;
  readonly phSubtitleKey = `${this.T_SCOPE}.must-select-subtitle`;

  selectedMergedZone: MergedZonesDto;
  selectedHeFamilyId: string;
  selectedHeTypeId: string;
  pageId: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _mergedZonesService: MergedZonesService
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.listenState(MergedZonesStateFields.selectedMergedZone).subscribe(
      (selectedMergedZone: MergedZonesDto) => {
        this.selectedMergedZone = selectedMergedZone;
      }
    );

    this._state
      .select(new AppliedFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dbFilter: DataBindingFilters) => {
          if (!dbFilter) {
            return;
          }

          this.selectedHeFamilyId = this._mergedZonesService.getFieldValuesFromFilter(
            dbFilter,
            this._hierarchyFamilyFieldName
          )?.[0];

          this.selectedHeTypeId = this._mergedZonesService.getFieldValuesFromFilter(
            dbFilter,
            this._hierarchyLevelFieldName
          )?.[0];
        },
      });
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private dispatchReload(value: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: MergedZonesStateFields.reloadMergedZones,
        value,
      })
    );
  }

  onMergedZoneUpdated() {
    this.dispatchReload(false);
    this.dispatchReload(true);
  }
}
