<wlm-popup-wrapper [titleKey]="titleKey" [showSpinner]="isLoading" (popupClose)="onExit($event)">
  <ng-container popup-content>
    <wlm-schematic-import (isValid)="onIsValid($event)" (saved)="onSaved()"></wlm-schematic-import>
  </ng-container>

  <ng-container popup-actions>
    <button
      mat-raised-button
      class="wtr-button-primary"
      (click)="onImport()"
      [disabled]="!isValid || isLoading"
    >
      {{ 'common.save' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
