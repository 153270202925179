import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { LocalStorageService } from '../../shared/local-storage.service';

import { DynamicSettings } from '../models/dynamic-settings';
import { DynamicSettingsDelete } from '../models/dynamic-settings-delete';
import { DynamicSettingsSave } from '../models/dynamic-settings-save';
import { PersistLayoutService } from './persist-layout.service';

@Injectable()
export class PersistLayoutLocalService extends PersistLayoutService {
  constructor(
    injector: Injector,
    private _localStorage: LocalStorageService,
    private _authService: AuthenticationService
  ) {
    super(injector);
  }

  save(settings: DynamicSettingsSave): Observable<boolean> {
    const key = this.buildKey(settings);
    this._localStorage.addOrUpdate(key, settings.settingValue);
    return of(true);
  }

  load(settings: DynamicSettings): Observable<any> {
    const key = this.buildKey(settings);
    const data = this._localStorage.get(key);
    if (typeof data === 'undefined') {
      return new Observable((o) => {
        o.next();
        o.complete();
      });
    }
    const parsedData = JSON.parse(data);
    return of(parsedData);
  }

  delete(settings: DynamicSettingsDelete): Observable<boolean> {
    settings.settingsToDelete.forEach((toDelete) => {
      const key = this.buildKey(toDelete);
      this._localStorage.remove(key);
    });
    return of(true);
  }

  private buildKey(settings: DynamicSettings): string {
    const key = [settings.settingArea, settings.settingKey, this._authService.userCode].join('.');
    return key;
  }
}
