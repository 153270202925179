import { Injectable } from '@angular/core';
import { IHierarchyElementPathDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-path.dto';
import * as constants from '../../../../../../common-modules/dependencies/shared/hierarchy-tree-filter-constants';

@Injectable({
  providedIn: 'root',
})
export class HierarchyTreeFilterHelperService {
  constructor() { }

  public contains(text: string, term: string): boolean {
    const searchPattern = RegExp(term, 'i');
    return searchPattern.test(text);
  }

  public searchParents(
    element: IHierarchyElementPathDto,
    items: IHierarchyElementPathDto[],
    displayableField: constants.hierarchyElementPathFields
  ): IHierarchyElementPathDto[] {
    if (element === undefined || element.ancestor === undefined) {
      return [];
    }

    let parentList = this.filterHierarchyElementArray(
      items,
      element.ancestor,
      displayableField,
      true
    );
    parentList = this.mergeHierarchyElementArrays(
      parentList,
      this.searchParents(parentList[0], items, displayableField),
      displayableField
    );
    return parentList;
  }

  public filterHierarchyElementArray(
    arrayToFilter: IHierarchyElementPathDto[],
    searchTerm: string,
    displayableField: constants.hierarchyElementPathFields,
    exactMatch: boolean = false,
    searchAncestor: boolean = false
  ): IHierarchyElementPathDto[] {
    if (!arrayToFilter) {
      return [];
    }

    if (exactMatch) {
      return arrayToFilter.filter(
        (hierarchyElement) =>
          (searchAncestor
            ? hierarchyElement.ancestor
            : this.getDisplayableField(hierarchyElement, displayableField)) === searchTerm
      );
    } else {
      return arrayToFilter.filter((hierarchyElement) =>
        this.contains(
          searchAncestor
            ? hierarchyElement.ancestor
            : this.getDisplayableField(hierarchyElement, displayableField),
          searchTerm
        )
      );
    }
  }

  public mergeHierarchyElementArrays(
    firstHierarchyElementList: IHierarchyElementPathDto[],
    secondHierarchyElementList: IHierarchyElementPathDto[],
    displayableField: constants.hierarchyElementPathFields
  ): IHierarchyElementPathDto[] {
    const ids = new Set(
      firstHierarchyElementList.map((d) => this.getDisplayableField(d, displayableField))
    );
    const merged = [
      ...firstHierarchyElementList,
      ...secondHierarchyElementList.filter(
        (d) => !ids.has(this.getDisplayableField(d, displayableField))
      ),
    ];
    return merged;
  }

  public search(
    items: IHierarchyElementPathDto[],
    searchTerm: string,
    displayableField: constants.hierarchyElementPathFields
  ): IHierarchyElementPathDto[] {
    let filteredArray = this.filterHierarchyElementArray(items, searchTerm, displayableField);
    let parentsArray: IHierarchyElementPathDto[] = [];

    filteredArray.forEach((element) => {
      parentsArray = this.mergeHierarchyElementArrays(
        parentsArray,
        this.searchParents(element, items, constants.hierarchyElementPathFields.Descendant),
        constants.hierarchyElementPathFields.Descendant
      );
    });

    filteredArray = this.mergeHierarchyElementArrays(filteredArray, parentsArray, displayableField);
    return filteredArray;
  }

  public mergeStringLists(firstList: string[], secondList: string[]): string[] {
    const resultArray = [];
    const arr = firstList.concat(secondList);
    let len = arr.length;
    const assoc = {};

    while (len--) {
      const item = arr[len];

      if (!assoc[item]) {
        resultArray.unshift(item);
        assoc[item] = true;
      }
    }

    return resultArray;
  }

  public getDisplayableField(
    hierarchyElementPathDto: IHierarchyElementPathDto,
    displayableField: constants.hierarchyElementPathFields
  ): string {
    return displayableField === constants.hierarchyElementPathFields.Descendant
      ? hierarchyElementPathDto.descendant
      : hierarchyElementPathDto.descendantHierarchyElementName;
  }
}
