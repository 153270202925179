import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { BaseMapComponent } from './base-map/base-map.component';
import { MapPageComponent } from './map-page/map-page.component';
import { MiniMapComponent } from './mini-map/mini-map.component';

const routes: Routes = [
  {
    path: 'base',
    component: BaseMapComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.Map,
    },
  },
  {
    path: 'mini',
    component: MiniMapComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.Map,
    },
  },
  {
    path: '',
    component: MapPageComponent,
    canDeactivate: [LeavePageGuard],
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.Map,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MapRoutingModule {}
