export const signalRNotifyEvent = 'notifyevent';
export const signalRConnectEvent = 'connected';

export enum Criticality {
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export const AllGroups: string[] = [
  'LeakageAnalyst',
  'SodconAnalyst',
  'DeliveryAnalyst',
  'LeakageManager',
  'Read Only',
  'NoiseLoggerPlanner',
  'CustomerSupport',
];

export const AllUsers: string[] = [
  'aAhmed2',
  'aBlack2',
  'aBurling',
  'aCullum2',
  'aDawson2',
  'aDurrant',
  'aEmery',
  'AFOX',
  'aGomezgil',
  'aGoolch',
  'aGray2',
  'aGutierrez',
  'aHinchley',
  'aJackson3',
  'aJones11',
  'aKlejbuk',
  'aLeathers',
  'aMaddocks',
  'aNugent',
  'aNunez2',
  'aPenrose',
  'aPrieto',
  'aRippon',
  'aRoberts4',
  'aRose2',
  'aSegura',
  'aSmith3',
  'aTaylor9',
  'aThirtle',
  'aTreharne',
  'bDobbs',
  'bFryers',
  'bMorenoparo',
  'bPawsey',
  'bTyson',
  'cAbraham2',
  'cBarber3',
  'cBrown16',
  'cBull',
  'CCLAMP',
  'cGolding',
  'cIsidoro',
  'cJefferies3',
  'cLewis7',
  'jSantos',
];
