<div class="main-card">
  <div class="large-user-info">
    <div *ngIf="!settings.isReadOnly">
      <button
        mat-icon-button
        *hasPerm="['WLMNetworkCrud', 'u']"
        class="delete-button"
        [matTooltip]="T_SCOPE + '.delete-button-tooltip' | translate | lowercase"
        (click)="delete()"
        onclick="this.blur()"
      >
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
    </div>
    <div class="flex-style flex-style-max" *ngFor="let field of settings.fields">
      <div class="card-row">
        <div class="field-box">
          <div class="title-box">
            {{ T_SCOPE + '.' + settings.fieldLabels[field] | translate }}
          </div>
          <div>{{ item[field] }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="!settings.isReadOnly">
      <button
        mat-icon-button
        *hasPerm="['WLMNetworkCrud', 'u']"
        class="edit-button"
        [matTooltip]="T_SCOPE + '.edit-button-tooltip' | translate | lowercase"
        (click)="edit()"
        onclick="this.blur()"
      >
        <mat-icon svgIcon="edit-pencil"></mat-icon>
      </button>
    </div>
  </div>

  <div class="separator"></div>

  <div class="signal-info" *ngFor="let signal of item.signals; let i = index">
    <div [ngClass]="i == item.signals.length - 1 ? 'signal-border-reduced' : 'signal-border'"></div>
    <div class="flex-style" *ngFor="let detailField of settings.detailFields">
      <div
        [ngClass]="i == item.signals.length - 1 ? 'signal-field-box' : 'signal-field-box item-line'"
      >
        <div *ngIf="i == 0" class="title-box title-point">
          {{ T_SCOPE + '.' + settings.fieldLabels[detailField] | translate }}
        </div>
        <div class="point-data">{{ signal[detailField] }}</div>
      </div>
    </div>
  </div>
</div>
