import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ExportPdfDocument } from '../models/export-pdf-document';

@Injectable({
  providedIn: 'root',
})
export class GenericExportService {
  constructor() {}

  previousHeight = 0;
  topMargin = 20;

  private readonly _titleFontSize = 10;
  private readonly _yTitleOffset = 25;
  private readonly _defaultItemFontSize = 13;
  private readonly _defaultPageSize = 'a4';
  private readonly _pageMargin = 6;

  exportPdfDocument(document: ExportPdfDocument): void {
    if (document?.items) {
      var doc = new jsPDF(document.orientation);
      this.topMargin = document.items[0].yCoords;

      const pageHeight = doc.internal.pageSize.height - this.topMargin;

      if (document.title) {
        doc.setFontSize(this._titleFontSize);
        var xOffset =
          doc.internal.pageSize.width / 2 -
          (doc.getStringUnitWidth(document.title) * (this._titleFontSize - this._pageMargin)) / 2;
        doc.text(document.title, xOffset, this._yTitleOffset);
      }

      this.previousHeight = 0;
      document.items.forEach((item) => {
        if (item.yCoords - this.previousHeight >= pageHeight) {
          doc.addPage(this._defaultPageSize, document.orientation);
          this.previousHeight += pageHeight;
        }

        if (item.type === 'image') {
          doc.addImage(
            item.data,
            'png',
            item.xCoords,
            item.yCoords - this.previousHeight,
            document.width,
            document.height
          );
        }

        if (item.type === 'text') {
          const fontSize = item.fontSize ?? this._defaultItemFontSize;

          doc.setFontSize(fontSize);

          doc.text(item.data, item.xCoords, item.yCoords - this.previousHeight, item.textOptions);
        }
      });
      doc.save(document.filename);
    }
  }
}
