export enum MapFeatureTypes {
  Line = 'line',
  Point = 'symbol',
  Polygon = 'fill',
}

export const mapFeatureTypesMapping = new Map<string, string>([
  ['linestring', MapFeatureTypes.Line],
  ['point', MapFeatureTypes.Point],
  ['polygon', MapFeatureTypes.Polygon],
]);
