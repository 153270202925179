export class SimpleColumn {
  columnId: string;
  columnTitle: string;
  value: any;
  valueAddon?: string;

  constructor(init: SimpleColumn) {
    Object.assign(this, init);
  }
}
