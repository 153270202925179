import { Injectable } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, map, of, take } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ChartHelperService } from 'src/app/common-modules/shared/charts/chart-helper.service';
import { FiltrableItemManagerService } from 'src/app/common-modules/shared/charts/filtrable-item-manager.service';
import { AxisLocation } from 'src/app/common-modules/shared/charts/model/axes/axis-location';
import {
  HorizontalAxis,
  IHorizontalAxis,
} from 'src/app/common-modules/shared/charts/model/axes/horizontal-axis';
import {
  IVerticalAxis,
  VerticalAxis,
} from 'src/app/common-modules/shared/charts/model/axes/vertical-axis';
import { ChartCustomization } from 'src/app/common-modules/shared/charts/model/chart/chart-customization';
import {
  IPlotableSerie,
  PlotableSerie,
} from 'src/app/common-modules/shared/charts/model/series/plotable-serie';
import { SerieCustomization } from 'src/app/common-modules/shared/charts/model/series/serie-customization';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { EventsControlSettings } from 'src/app/common-modules/shared/model/data-viz/events-control-settings.dto';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { IFiltrableType } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-type';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { EventViewCategories } from 'src/app/common-modules/wlm-charts/core/events-chart/models/event-view-categories';
import { EventViewCategory } from 'src/app/common-modules/wlm-charts/core/events-chart/models/event-view-category';
import { EventChartQueryDto } from 'src/app/common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { ChartSerieType } from 'src/app/common-modules/wlm-charts/core/models/chart-serie-type';
import { ChartSerieTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { chartUtilsHelper } from '../helpers/chart-utils-helpers';
import { AlgorithmFiltrableType } from '../model/filtrable-items/types/algorithm-filtrable-type';
import { SignalFiltrableType } from '../model/filtrable-items/types/signal-filtrable-type';
import { ChartConfiguration } from './model/chart-configuration';

@Injectable()
export class ChartConfigurationService {
  private readonly _chartDefaultsKey = `${AppModules.WlmCharts}.defaults`;
  private readonly _naUnitTypeId = 50;

  constructor(
    private _uomService: UoMService,
    private _arrayHelperService: ArrayHelperService,
    private _chartHelperService: ChartHelperService,
    private _dateHelperService: DateHelperService,
    private _filtrableItemManagerService: FiltrableItemManagerService,
    private _localizationHelperService: LocalizationHelperService
  ) {}

  getChartConfigurationFromFiltrableItems(
    filtrableItems: IFiltrableItemDto[]
  ): Observable<ChartConfiguration> {
    const minStartDate = this._arrayHelperService.sortObjects<IFiltrableItemDto>(
      filtrableItems.slice(),
      (i) => new Date(i.startDate).getTime(),
      true
    )?.[0].startDate;

    let maxEndDate = this._arrayHelperService.sortObjects<IFiltrableItemDto>(
      filtrableItems.slice(),
      (i) => new Date(i.endDate).getTime(),
      false
    )?.[0].endDate;

    maxEndDate = this._dateHelperService.addDays(new Date(maxEndDate), 1);

    const eventsControlSettings = this.getDefaultEventsControlSettings();
    const eventsQuery = this.getEventQuery(filtrableItems, eventsControlSettings);

    const defaultLabels$ = this._localizationHelperService.get(this._chartDefaultsKey);
    const uomByFiltrableItems$ = filtrableItems.map((i) => this.getUomFromFiltrableItem(i));

    return forkJoin([defaultLabels$, ...uomByFiltrableItems$]).pipe(
      take(1),
      map((result) => {
        const [defaultLabels, ...uomByFiltrableItems] = result;

        const itemsUomConversions = uomByFiltrableItems.reduce((res, obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            res.set(key, value);
          });
          return res;
        }, new Map<string, UnitTypeConversionViewDto>());

        const customization = this.getDefaultCustomization(defaultLabels);
        const horizontalAxis = this.getDefaultHorizontalAxis(minStartDate, maxEndDate);
        const verticalAxis = this.getDefaultVerticalAxis(itemsUomConversions);
        const seriesConfiguration = this.getDefaultPlotableSerie(
          filtrableItems,
          itemsUomConversions,
          verticalAxis,
          horizontalAxis[0]?.id
        );

        return new ChartConfiguration({
          eventsControlSettings,
          horizontalAxis,
          verticalAxis: Array.from(verticalAxis.values()),
          seriesConfiguration,
          customization,
          eventsQuery,
        });
      })
    );
  }

  getChartSerieTypes(): ChartSerieType[] {
    const T_SCOPE_SERIE_TYPE = `${AppModules.WlmCharts}.serie-types`;
    const serieTypes = [];

    Object.entries(ChartSerieTypeEnum).forEach(([key, value]) =>
      serieTypes.push(
        new ChartSerieType({
          value: ChartSerieTypeEnum[key],
          labelKey: `${T_SCOPE_SERIE_TYPE}.${value}`,
        })
      )
    );

    return serieTypes;
  }

  getEventCategories(): EventViewCategory[] {
    const eventTypes = [];

    Object.entries(EventViewCategories).forEach(([key, value]) =>
      eventTypes.push(
        new EventViewCategory({
          value: EventViewCategories[key],
        })
      )
    );

    return eventTypes;
  }

  private getDefaultVerticalAxis(
    itemsUomConversions: Map<string, UnitTypeConversionViewDto>
  ): Map<number, IVerticalAxis> {
    const verticalAxis = new Map<number, IVerticalAxis>();

    Array.from(itemsUomConversions.values()).forEach((uom) => {
      const unitTypeToId = uom?.unitTypeToId ?? this._naUnitTypeId;
      const unitTypeToDescription = uom?.unitTypeToDescription ?? '';

      if (!verticalAxis.has(unitTypeToId)) {
        const location = verticalAxis.size % 2 == 0 ? AxisLocation.Left : AxisLocation.Rigth;

        const axis = new VerticalAxis({
          id: verticalAxis.size,
          showUoM: true,
          dimensionTypeId: uom?.dimensionTypeId ?? DimensionTypesEnum.NA,
          unitTypeToId,
          unitTypeToDescription,
          location,
        });

        verticalAxis.set(unitTypeToId, axis);
      }
    });

    return verticalAxis;
  }

  private getDefaultHorizontalAxis(startDate: Date, endDate: Date): IHorizontalAxis[] {
    return [
      new HorizontalAxis({
        id: 0,
        maximum: endDate,
        minimum: startDate,
        location: AxisLocation.Bottom,
      }),
    ];
  }

  private getDefaultPlotableSerie(
    filtrableItems: IFiltrableItemDto[],
    itemsConversions: Map<string, UnitTypeConversionViewDto>,
    verticalAxis: Map<number, IVerticalAxis>,
    horizontalAxisId: number
  ): IPlotableSerie[] {
    const plotableSeries = filtrableItems.map((i, index) => {
      const unitTypeFromId =
        itemsConversions.get(i.filtrableType.key)?.unitTypeFromId ?? this._naUnitTypeId;
      const hexColor = this._chartHelperService.getColorByIndex(index);

      const unitTypeToId =
        itemsConversions.get(i.filtrableType.key)?.unitTypeToId ?? this._naUnitTypeId;
      const verticalAxisId = verticalAxis.get(unitTypeToId)?.id;

      const showInUtc = this.showInUtc(i.filtrableType);
      const showAlwaysInTooltip = this.showAlwaysInTooltip(i.filtrableType);

      const serieCustomization = new SerieCustomization({
        name: i.filtrableType.title,
        title: i.filtrableType.title,
        type: ChartSerieTypeEnum.Line,
        hexColor,
        unitTypeFromId,
        horizontalAxisId,
        verticalAxisId,
        showInUtc,
        showAlwaysInTooltip: showAlwaysInTooltip,
      });

      return new PlotableSerie(serieCustomization, i);
    });

    return plotableSeries;
  }

  private showInUtc(filtrableType: IFiltrableType): boolean {
    switch (filtrableType.type) {
      case FiltrableItemTypeEnum.Algorithm:
        const { algorithmShortName, timeAggregationId } = filtrableType as AlgorithmFiltrableType;
        return chartUtilsHelper.showInUtc(algorithmShortName, timeAggregationId);

      case FiltrableItemTypeEnum.Signal:
      default:
        return false;
    }
  }

  private showAlwaysInTooltip(filtrableType: IFiltrableType): boolean {
    switch (filtrableType.type) {
      case FiltrableItemTypeEnum.Algorithm:
        const { timeAggregationId } = filtrableType as AlgorithmFiltrableType;

        return chartUtilsHelper.showAlwaysInTooltip(timeAggregationId);

      case FiltrableItemTypeEnum.Signal:
      default:
        return false;
    }
  }

  private getDefaultCustomization(defaultLabels: any): ChartCustomization {
    return new ChartCustomization({
      name: defaultLabels?.name ?? null,
      title: defaultLabels?.title ?? null,
      showLabels: false,
      showLegend: true,
      showTimeBar: true,
      isEventChartExpanded: false,
      isPopup: false,
      showChart: true,
      timeBarMinSelectionRange: 1,
      timeBarSelectedPeriodMode: 2,
      timeBarVisiblePeriodMode: 3,
    });
  }

  private getDefaultEventsControlSettings(): EventsControlSettings {
    const categories = Object.values(EventViewCategories);
    return new EventsControlSettings({ categories });
  }

  private getUomFromFiltrableItem(
    item: IFiltrableItemDto
  ): Observable<{ [id: string]: UnitTypeConversionViewDto }> {
    switch (item.filtrableType.type) {
      case FiltrableItemTypeEnum.Algorithm:
        return this.getUomFromAlgorithm(item.filtrableType as AlgorithmFiltrableType);

      case FiltrableItemTypeEnum.Signal:
        return this.getUomFromSignal(item.filtrableType as SignalFiltrableType);

      default:
        return of(null);
    }
  }

  private getUomFromAlgorithm(
    filtrableType: AlgorithmFiltrableType
  ): Observable<{ [id: string]: UnitTypeConversionViewDto }> {
    const { key, dimensionTypeId, timeAggregationId, element } = filtrableType;

    const elementTypeId =
      filtrableType?.entityType === EntityTypes.hierarchyElement ? element.elementTypeId : null;

    const result: { [id: string]: UnitTypeConversionViewDto } = {};

    return this._uomService.getByParams(dimensionTypeId, timeAggregationId, elementTypeId).pipe(
      take(1),
      map((uom) => {
        result[key] = uom;
        return result;
      })
    );
  }

  private getUomFromSignal(
    filtrableType: SignalFiltrableType
  ): Observable<{ [id: string]: UnitTypeConversionViewDto }> {
    const { key, dimensionTypeId } = filtrableType;

    const result: { [id: string]: UnitTypeConversionViewDto } = {};

    return this._uomService.getBySignal(dimensionTypeId).pipe(
      take(1),
      map((uom) => {
        result[key] = uom;
        return result;
      })
    );
  }

  private getEventQuery(
    filtrableItems: IFiltrableItemDto[],
    eventChartSetting: EventsControlSettings
  ): EventChartQueryDto {
    if (!eventChartSetting.categories?.length) {
      return null;
    }

    const eventQueries = filtrableItems.map((i) => {
      const service = this._filtrableItemManagerService.getService(i.filtrableType.type);
      return service.getEventQuery(i) as EventChartQueryDto;
    });

    const iterableEventQueryes = asEnumerable(eventQueries);

    const itemsWithElements = iterableEventQueryes.Where((x) => x?.elementsIds?.length > 0);
    const itemsWithSignals = iterableEventQueryes.Where((x) => x?.signalIds?.length > 0);

    const maxEndDate = this._dateHelperService.getMaxDate(eventQueries.map((x) => x.endDate));

    const eventChartQuery = new EventChartQueryDto({
      endDate: this._dateHelperService.addDays(new Date(maxEndDate), 1),
      startDate: this._dateHelperService.getMinDate(eventQueries.map((x) => x.startDate)),
      elementsIds: itemsWithElements.Any()
        ? itemsWithElements.SelectMany((x) => x.elementsIds).ToArray()
        : null,
      signalIds: itemsWithSignals.Any()
        ? itemsWithSignals.SelectMany((x) => x?.signalIds).ToArray()
        : null,
      categories: eventChartSetting.categories,
    });

    return eventChartQuery;
  }
}
