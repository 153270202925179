<wlm-signal-historical-form
  *ngIf="settings && formModel"
  [settings]="settings"
  [formModel]="formModel"
  [additionalFieldsTemplate]="additionalFields"
  (formChanges)="onFormChange($event)"
  (canSave)="onCanSave($event)"
  (canReset)="onCanReset($event)"
></wlm-signal-historical-form>

<ng-template #additionalFields>
  <mat-form-field class="mat-field customer-class-type-field">
    <mat-label>{{ T_SCOPE + '.customer-class-type-name' | translate }}</mat-label>
    <input type="text" matInput [formControl]="customerClassTypeCtrl" autocomplete="off" />
  </mat-form-field>
</ng-template>
