interface IHierarchyPrefix {
  Level: string;
  LevelName: string;
}

export const HierarchyColumnPrefix: Readonly<IHierarchyPrefix> = {
  Level: 'Level',
  LevelName: 'LevelName',
};

interface IHierarchyElementAttributes {
  ExitLevel: number;
  InterventionLevel: number;
  DmaComments: number;
}

export const HierarchyElementAttributes: Readonly<IHierarchyElementAttributes> = {
  ExitLevel: 14,
  InterventionLevel: 15,
  DmaComments: 56,
};

interface IHierarchyElementTypes {
  Group: string;
  DMA: string;
  DZ: string;
  Customer: string;
  SignalsData: string;
}

export const IHierarchyElementTypes: Readonly<IHierarchyElementTypes> = {
  Group: 'c0f2369e-b31c-4515-8595-f94417aa9ab0',
  DMA: '6828e991-8326-4e7e-b244-a026b67ac867',
  DZ: 'efd92639-f72a-464a-aaba-53e78e0ba129',
  Customer: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
  SignalsData: '00000000-0000-0000-0000-000000000000',
};

export const NetworkElementTypes = {
  DMA: 1,
  DZ: 2,
  Group: 9,
};

export const DefaultHierarchyElementTypes = [
  IHierarchyElementTypes.DMA,
  IHierarchyElementTypes.DZ,
  IHierarchyElementTypes.Group,
];

export const ReadonlyHierarchyElementTypes = [
  IHierarchyElementTypes.DMA,
  IHierarchyElementTypes.DZ,
  IHierarchyElementTypes.Group,
  IHierarchyElementTypes.Customer,
  IHierarchyElementTypes.SignalsData,
];

export const ReadonlyNetworkElementTypes = [
  NetworkElementTypes.DMA,
  NetworkElementTypes.DZ,
  NetworkElementTypes.Group,
];
