// prettier-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { FilterSelection } from 'src/app/common-modules/common-filters/models/filters-selection';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FiltersPayloadStatus } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';

const COMPONENT_SELECTOR = 'wlm-default-filter-item-select';

/**
 * Default implementation for the body (the #main template) of a base filter item.
 */

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './default-filter-item-select.component.html',
  styleUrls: ['./default-filter-item-select.component.scss'],
})
export class DefaultFilterItemSelectComponent implements OnInit, OnChanges {
  private _options: FilterItemSelectOption[] = [];
  public get options(): FilterItemSelectOption[] {
    return this._options;
  }
  @Input() public set options(value: FilterItemSelectOption[]) {
    this._options = value;
    this.setItemsLimit();
  }
  @Input() title: string;
  @Input() clearAll$: Observable<void>;
  @Input() selectAll$: Observable<void>;
  @Input() restoreStatus$: Observable<any[]>;
  @Input() saveInitStatus$: Observable<any[]>;
  @Input() filterText: string;
  @Input() mode: 'single' | 'multiple' = 'multiple';
  @Input() debugKey: string;
  @Input() selectedIds: any[];
  @Input() defaultSelectedIds: any[];
  @Input() settings: BaseFilterItemSettings;
  @Output() select = new EventEmitter<FilterSelection>();
  @Output() initRestore = new EventEmitter<void>();
  @Output() initSaveInitialValues = new EventEmitter<void>();

  private _selectedArray: FilterItemSelectOption[] = [];
  public get selectedArray(): FilterItemSelectOption[] {
    return this._selectedArray;
  }
  public set selectedArray(value: FilterItemSelectOption[]) {
    this._selectedArray = value;
  }

  selected: { [value: string]: boolean } = {};
  iconSize = '20';
  selectCount = 0;
  selectAllCheckbox = false;
  allOptions: FilterItemSelectOption[];
  // Used to save the initial status. Then could be used to restore the filter
  initialStatus: any[];
  allOptionsAreSelected: boolean;
  elementsLimit: number;

  public hashedHelper: any = {};
  constructor(protected settingsService: SettingsService) {}

  ngOnInit(): void {
    if (this.clearAll$) {
      this.clearAll$.pipe(untilDestroyed(this)).subscribe(() => this.clearAll());
    }
    if (this.selectAll$) {
      this.selectAll$.pipe(untilDestroyed(this)).subscribe(() => this.selectAll());
    }
    if (this.restoreStatus$) {
      this.restoreStatus$.pipe(untilDestroyed(this)).subscribe((_) => this.restoreStatus());
    }
    if (this.saveInitStatus$) {
      this.saveInitStatus$.pipe(untilDestroyed(this)).subscribe(() => this.saveInitStatus());
    }
  }

  setItemsLimit() {
    this.elementsLimit = this.settings?.itemsLimit ?? this.options.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.options) {
      this.allOptions = [...this.options];
    }
    if (changes && (changes.options || changes.selectedIds)) {
      this.setInitialSelected();
    }

    if (changes && changes.filterText) {
      this.onfilterTextChange();
    }
  }

  onToggle(option: FilterItemSelectOption): void {
    this.onSet(option, true);
  }

  onToggleMany(options: FilterItemSelectOption[], status = FiltersPayloadStatus.Normal): void {
    this.onSetMany(options, true, status);
  }

  onSet(option: FilterItemSelectOption, toggle = false): void {
    this.onSetMany([option], toggle);
  }

  onSetMany(
    options: FilterItemSelectOption[],
    toggle = false,
    status = FiltersPayloadStatus.Normal
  ): void {
    if (options.length === 0) {
      return;
    }

    // If single selection, only the first element is considered. Disable all the others.
    if (this.mode === 'single') {
      const option = options[0];
      const newValue = toggle ? !this.selected[option.value] : true;
      if (!this.validate(option)) {
        return;
      }

      this.selected = {};
      this.selectedArray = [];
      if (newValue) {
        this.selected[option.value] = true;
        this.selectedArray = [option];
      }
    } else {
      // For multiple selection, check if every value is valid.
      options.forEach((option) => {
        const newValue = toggle ? !this.selected[option.value] : true;
        if (this.validate(option)) {
          this.selected[option.value] = newValue;
          if (this.selected[option.value]) {
            // Whether we toggle or not, check we are not inserting a duplicated option.
            if (!this.selectedArray.find((opt) => opt.value === option.value)) {
              this.selectedArray = [...this.selectedArray, { ...option }];
            }
          } else {
            this.selectedArray = this.selectedArray.filter((item) => item.value !== option.value);
          }
        }
      });
      this.selectAllCheckbox = this.selectedArray.length === this.options.length;
    }
    // Important: only emit selected event after all elements have been selected.
    this.emitSelect(status);
  }

  /**
   * Update an option that has already been set as selected, and emits selection.
   * The option must be already selected.
   */
  onUpdateOption(current: FilterItemSelectOption): void {
    if (this.selected[current.value]) {
      const allExceptCurrent = this.selectedArray.filter(
        (selected) => selected.value !== current.value
      );
      this.selectedArray = allExceptCurrent.concat([current]);
      this.emitSelect();
    }
  }

  /**
   * Set the selectedIds received from input as selected elements.
   */
  setInitialSelected(): void {
    if (
      !this.selectedIds ||
      this.selectedIds.length === 0 ||
      !this.options ||
      this.options.length === 0
    ) {
      // Clean up additional helper variables.
      this.resetSelection();
      return;
    }
    if (this.mode === 'single' && this.selectedIds.length > 1) {
      if (!this.settingsService.production) {
        throw Error('In single mode, only one item can be selected.');
      }
      return;
    }
    this.clearAll(true);

    const itemsToToggle = [];
    this.selectedIds.forEach((key) => {
      const item = this.options.find((opt) => opt.value === key);
      if (item) {
        itemsToToggle.push(item);
      }
    });

    this.onToggleMany(itemsToToggle, FiltersPayloadStatus.Initial);
  }

  protected resetSelection(): void {
    this.selected = {};
    this.selectedArray = [];
    this.selectAllCheckbox = false;
    // Additionally delete any possible subclasses data.
    this.hashedHelper = {
      calendarModeHash: {},
    };
  }

  clearAll(avoidDefault: boolean = false): void {
    if (!avoidDefault && this.defaultSelectedIds) {
      // Restore all options, that could have been filtered by text.
      this.options = [...this.allOptions];
      this.selectedIds = this.defaultSelectedIds;
      this.selectedArray = this.options.filter((elem) => this.selectedIds.includes(elem.value));
      this.selectAllCheckbox = this.selectedIds.length === this.options.length;
      this.options.forEach((element) => {
        this.selected[element.value] = this.selectedIds.includes(element.value);
      });
      this.hashedHelper = {
        calendarModeHash: {},
      };
    } else {
      this.resetSelection();
    }

    // If options had preconfigured selection params, delele them (See custom calendar filter).
    if (this.options.find((element) => element.params)) {
      this.options.forEach((element) => {
        element.params = {};
      });
      this.options = [...this.options];
    }

    this.emitSelect();
  }

  selectAll(): void {
    if (this.mode === 'single' || this.settings.hideSelectAllCheckbox) {
      return;
    }
    this.selected = {};
    this.options.forEach((option) => {
      this.selected[option.value] = true;
    });
    this.selectedArray = [...this.options];
    this.selectAllCheckbox = true;
    this.emitSelect();
  }

  saveInitStatus(): void {
    this.initSaveInitialValues.emit();
    this.initialStatus = [];
    this.initialStatus = [...this.selectedArray];
  }

  restoreStatus(): void {
    this.initRestore.emit();
    this.selectedIds = [...this.initialStatus.map((x) => x.value)];
    this.selectedArray = [...this.initialStatus];
    this.selected = {};
    this.selectedArray.forEach((option) => {
      this.selected[option.value] = true;
    });
    this.selectAllCheckbox = this.selectedArray.length === this.options.length;
    this.emitSelect();
  }

  toggleSelectAll() {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    this.selectAllCheckbox ? this.selectAll() : this.clearAll();
  }

  emitSelect(status: FiltersPayloadStatus = FiltersPayloadStatus.Normal): void {
    this.selectCount = this.selectedArray.length;
    this.options = [...this.options];
    const selection = new FilterSelection({
      selection: this.selectedArray,
      status,
    });
    this.select.emit(selection);
  }

  getDisabledState(option: FilterItemSelectOption) {
    if (this.settings?.required) {
      if (this.selected[option.value]) {
        let isLastSelected = true;
        this.options.forEach((item) => {
          if (item.value !== option.value && this.selected[item.value]) {
            isLastSelected = false;
            return;
          }
        });
        return isLastSelected;
      }
    }
    return false;
  }

  initHashedHelper(hashedHelper): void {
    this.hashedHelper = hashedHelper;
  }

  /**
   * Applies the specified validation.
   */
  private validate(option: FilterItemSelectOption): boolean {
    if (this.settings?.required) {
      const newValue = !this.selected[option.value];
      if (newValue === false) {
        if (this.mode === 'single') {
          // If the toggled value was true in a single select, do not allow to set it to false.
          return false;
        } else if (this.mode === 'multiple') {
          if (this.selectedArray.length === 1 && this.selectedArray[0].value === option.value) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * When the filterText is emitted, update the available options.
   */
  private onfilterTextChange(): void {
    if (this.filterText) {
      this.filterText = this.filterText.toLowerCase();
      // Only show the options that match the filter.
      this.options = this.allOptions.filter((option) =>
        option.label.toLowerCase().includes(this.filterText)
      );
    } else {
      this.options = [...this.allOptions];
    }
  }
}
