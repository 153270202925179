import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseSelectorDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { MergedZonesSelectorComponent } from '../../components/merged-zones-selector/merged-zones-selector.component';
import { MergedZonesStateFields } from '../../models/merged-zones-state.fields';
import { MergedZonesDto } from '../../models/merged-zones.dto';
import { MergedZonesService } from '../../services/merged-zones.service';

const COMPONENT_SELECTOR = 'wlm-merged-zones-selector-widget';
export const MZ_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-selector-widget.component.html',
  styleUrls: ['./merged-zones-selector-widget.component.scss'],
})
export class MergedZonesSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  @ViewChild(MergedZonesSelectorComponent) selectorComponent: MergedZonesSelectorComponent;

  get componentName(): string {
    return 'MergedZonesSelectorWidgetComponent';
  }

  get selectedFieldName(): string {
    return MergedZonesStateFields.selectedMergedZone;
  }

  filters: Map<string, any>;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService,
    private readonly _mergedZonesService: MergedZonesService
  ) {
    super(injector, widgetSettings, pendingChangesService);

    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.emitWidgetInit();

    this._state
      .select(new AppliedFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dbFilter: DataBindingFilters) => {
          if (!dbFilter) {
            return;
          }
          this.filters = this._mergedZonesService.getFiltersMap(dbFilter);
        },
      });

    this._state
      .select<boolean>(
        new GetValueSelector({
          fieldName: MergedZonesStateFields.reloadMergedZones,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.selectorComponent.reload();
        }
      });
  }

  onSelectedRow(data: MergedZonesDto): void {
    this.checkPendingChanges(this.pageId).subscribe((_) => {
      this.setSelected(data);
    });
  }
}
