import { IChartDataParameters } from '../chart-data-parameters';

export class EditableChartDataParameters implements IChartDataParameters {
  startDate: Date;
  endDate: Date;
  queryParams: any;
  dataService: string;
  scopeKey?: string;

  public constructor(init?: Readonly<EditableChartDataParameters>) {
    Object.assign(this, init);
  }
}
