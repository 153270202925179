<kendo-drawer-container>
  <kendo-drawer
    #drawer
    id="navbar"
    [width]="200"
    [miniWidth]="60"
    [mode]="'push'"
    [items]="modulesLink"
    [mini]="true"
    [autoCollapse]="false"
    [(expanded)]="expanded"
    [class.menu-expanded]="expanded"
    [class.menu-collapsed]="!expanded"
    [class.hide-nav-bar]="hideNavBar$ | async"
    [ngClass]="expanded ? 'navbar-kendo-drawer' : 'navbar-kendo-drawer-collapsed'"
  >
    <ng-template kendoDrawerHeaderTemplate>
      <div class="header-navigation-bar-icon-container" routerLink="/">
        <div class="header-navigation-bar-icon">
          <img src="/assets/images/wtr-main-icon.svg" height="32px" alt="main logo" />
          <div class="app-title">WL</div>
        </div>
      </div>
    </ng-template>

    <ng-template kendoDrawerItemTemplate let-link>
      <wlm-menu-link
        *ngIf="!link?.menuLinks"
        [menuLink]="link"
        [routerLink]="link.urlPath"
        class="top-level-menu-link nav-module-link-bold"
        [isExpanded]="expanded"
        [matTooltip]="!link?.menuLinks && !expanded ? link?.title : null"
      ></wlm-menu-link>

      <wlm-module-link-expandable
        #moduleComponent
        [moduleLink]="link"
        class="module-link-expandable"
        *ngIf="link?.menuLinks && expanded"
      ></wlm-module-link-expandable>

      <wlm-module-menu-link
        class="module-menu"
        [moduleLink]="link"
        *ngIf="link?.menuLinks && !expanded"
      ></wlm-module-menu-link>
    </ng-template>

    <ng-template kendoDrawerFooterTemplate>
      <div class="bottom-left" [ngClass]="{ 'bottom-left-collapsed': !expanded }">
        <div class="bottom-left-button">
          <button mat-icon-button [disableRipple]="true" (click)="drawer.toggle()">
            <wlm-icon *ngIf="!expanded" icon="chevron_right"></wlm-icon>
            <wlm-icon *ngIf="expanded" icon="chevron_left"></wlm-icon>
          </button>
        </div>
      </div>
    </ng-template>
  </kendo-drawer>

  <kendo-drawer-content
    id="router-content"
    [class.content-collapsed]="expanded"
    [class.content-expanded]="!expanded"
  >
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
