<div *ngIf="form" class="main-container">
  <form [formGroup]="form" class="form-container">
    <button
      mat-icon-button
      [matTooltip]="T_SCOPE + '.previous-day' | translate | lowercase"
      onclick="this.blur()"
      (click)="onPreviousDay()"
    >
      <mat-icon svgIcon="key-arrow-left"></mat-icon>
    </button>
    <mat-form-field class="hide-subscript hide-border">
      <input
        matInput
        class="base-date-input"
        [matDatepicker]="baseDatePicker"
        [formControlName]="baseGridDateFieldName"
        [max]="maxDate"
        autocomplete="off"
      />
      <mat-datepicker-toggle matPrefix [for]="baseDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #baseDatePicker></mat-datepicker>
    </mat-form-field>
    <button
      mat-icon-button
      [matTooltip]="T_SCOPE + '.next-day' | translate | lowercase"
      onclick="this.blur()"
      [disabled]="isNextDayBtnDisabled()"
      (click)="onNextDay()"
    >
      <mat-icon svgIcon="key-arrow-right"></mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="T_SCOPE + '.last-day' | translate | lowercase"
      onclick="this.blur()"
      [disabled]="isNextDayBtnDisabled()"
      (click)="onLastDay()"
    >
      <mat-icon svgIcon="key-arrow-right-double"></mat-icon>
    </button>
    <button
      mat-icon-button
      class="switch-button"
      [matTooltip]="T_SCOPE + '.switch-dates' | translate | lowercase"
      [disabled]="form?.get(compareDateFieldName)?.value === null"
      onclick="this.blur()"
      (click)="onSwitchDates()"
    >
      <mat-icon svgIcon="replace-point-configuration"></mat-icon>
    </button>
    <mat-form-field class="hide-subscript hide-border">
      <input
        matInput
        [matDatepicker]="compareDatePicker"
        [formControlName]="compareDateFieldName"
        [max]="maxDate"
        autocomplete="off"
      />
      <mat-datepicker-toggle matPrefix [for]="compareDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #compareDatePicker></mat-datepicker>
    </mat-form-field>
    <button
      *ngIf="form?.get(compareDateFieldName)?.value !== null"
      mat-icon-button
      [matTooltip]="T_SCOPE + '.clean-date' | translate | lowercase"
      onclick="this.blur()"
      (click)="onCleanDate()"
    >
      <mat-icon svgIcon="clean-cache"></mat-icon>
    </button>
  </form>
</div>
