import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';

export class AllowDropCallbackData {
  drag: CdkDrag;
  dropList: CdkDropList;
}

export class DragListCustomSettings {
  hideFilter = false;
  emptyLegendKey: string;
  useCallback = false;
  beforeDropCallback?: (selectedItem: any) => Observable<any[]>;
  isReadOnly = false;
  useSingleContainer = false;
  shrinkContainerHeight? = false;
  allowDropCallback?: (dropData: AllowDropCallbackData) => boolean;

  constructor(init?: Partial<DragListCustomSettings>) {
    Object.assign(this, init);
  }
}
