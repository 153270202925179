<div [formGroup]="form" class="content-stretch overflow" *ngIf="settings && initialModel">
  <div class="form-content content-top-spacer">
    <div class="form-item point-id-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-id' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointId" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-item point-desc-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-description' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointDescription" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="form-item date-range-field">
      <wlm-date-range-filter
        *ngIf="dateRangeValueSettings"
        [disableAutoTruncate]="disableAutoTruncate"
        [displayHorizontal]="dateRangeSettings.displayHorizontal"
        [smallFields]="dateRangeSettings.smallFields"
        [fieldAppearance]="fieldAppearance"
        [minDate]="settings.minDate"
        [maxDate]="settings.maxDate"
        [resetEndDate$]="resetDates$"
        [resetStartDate$]="resetDates$"
        [valueSettings]="dateRangeValueSettings"
        (dateRangeChanged)="onDateRangeChanged($event)"
        (hasError)="onDatesHasError($event)"
      ></wlm-date-range-filter>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="additionalFieldsTemplate"> </ng-container>
</div>
