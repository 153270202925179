<wlm-spinner-wrapper [showSpinner]="isSaving">
  <div class="form-container">
    <h3>{{ formTitle | translate }}</h3>
    <hr class="title-separator" />

    <mat-dialog-content [formGroup]="form">
      <div class="container">
        <div class="columns">
          <mat-form-field class="comment-field" appearance="outline">
            <mat-label>{{ T_SCOPE + '.current-point' | translate }}</mat-label>
            <input matInput formControlName="pointIdSource" />
          </mat-form-field>
          <mat-form-field class="comment-field" appearance="outline">
            <mat-label>{{ T_SCOPE + '.new-point' | translate }}</mat-label>
            <input
              matInput
              [placeholder]="T_SCOPE + '.new-point' | translate"
              formControlName="pointIdDestination"
              autocomplete="off"
              (keypress)="removePointIdResponse()"
            />
            <mat-error *ngIf="form.get('pointIdDestination')?.errors?.required">{{
              T_SCOPE + '.messages.point-required' | translate
            }}</mat-error>
            <mat-error *ngIf="form.get('pointIdDestination')?.errors?.pointReplacement">{{
              pointIdReplacementResponse?.message
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <div class="buttons-container">
        <button
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="!form.valid || isSaving"
        >
          Save
        </button>

        <button mat-raised-button (click)="close()">Close</button>
      </div>
    </mat-dialog-actions>
  </div>
</wlm-spinner-wrapper>
