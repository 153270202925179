<div class="interface-selection-container" cdkDropListGroup>
  <wlm-select-drag-list-virtual
    *ngIf="settings"
    class="select-drag-list-virtual"
    [settings]="settings"
    [itemsData]="items"
    [queryParams]="queryParams"
    (selectedItemChange)="onSelectInterface($event)"
  >
    <ng-template let-rowDetail #card>
      <wlm-generic-card [item]="rowDetail" [settings]="cardSettings">
        <ng-template #right>
          <wlm-historical-bars [historicalSettings]="rowDetail.historicalBarSettings">
          </wlm-historical-bars>
        </ng-template>
      </wlm-generic-card>
    </ng-template>
  </wlm-select-drag-list-virtual>
</div>
