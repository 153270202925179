<ng-template #main>
  <div class="filter-item-title title">{{ T_SCOPE + '.title' | translate }}</div>

  <div *ngIf="isLoading" class="default-spinner-location is-loading">
    <span class="k-i-loading k-loading-image"></span>
  </div>
  <div *ngIf="treeIsEmpty && hierarchyFamilyId !== null" class="empty-tree">
    <p>{{ T_SCOPE + '.cannot-load-error' | translate }}</p>
  </div>
  <div *ngIf="!isLoading && treeSettings" class="treeview">
    <kendo-treeview
      name="treeview"
      id="treeview"
      class="filter-item-treeview"
      [nodes]="filteredTreeNodes"
      kendoTreeViewExpandable
      [(expandedKeys)]="expandedKeys"
      [expandBy]="treeSettings.displayableField"
      [selectedKeys]="selectedKeys"
      [selectBy]="'descendant'"
      kendoTreeViewFlatDataBinding
      idField="descendant"
      parentIdField="ancestor"
      [kendoTreeViewSelectable]="selection"
      (selectionChange)="handleSelection($event)"
      (nodeClick)="handleClick($event)"
      [isSelected]="isItemSelected"
      kendoTreeViewLoadMore
      pageSize="10"
    >
      <ng-template kendoTreeViewNodeTemplate let-dataItem>
        <div
          [ngClass]="{
            'disabled-node': !processedNodeIsSelectable[dataItem.descendant]
          }"
        >
          <span
            [innerHTML]="
              dataItem.descendantDescription
                | highlightSearch : [filterText, !processedNodeIsSelectable[dataItem.descendant]]
            "
          ></span>
        </div>
      </ng-template>
      <ng-template kendoTreeViewLoadMoreButtonTemplate>
        <button mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </ng-template>
    </kendo-treeview>
  </div>
</ng-template>
<ng-template #summary>
  <div *ngIf="summaryItemsKeys && summaryItemsKeys.length !== 0">
    <div class="filter-item-title summary-main-title">{{ T_SCOPE + '.title' | translate }}</div>
    <ul class="summary-list">
      <li *ngFor="let title of summaryItemsKeys" class="summary-groups">
        <div class="summary-title">
          <div class="ellipsis-left">{{ title }}</div>
        </div>
        <div *ngFor="let item of summaryItems[title]" class="filter-select-item">
          <mat-checkbox checked="true" (change)="onSelectedChanged($event, item)"></mat-checkbox>
          <span class="filter-select-item-label">{{ item.label }}</span>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
