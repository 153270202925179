import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';

const COMPONENT_SELECTOR = 'wlm-bi-filters-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-filters-widget.component.html',
  styleUrls: ['./bi-filters-widget.component.scss'],
  providers: [ReduxStateService],
})
export class BiFiltersWidgetComponent extends BaseDynamicWidgetComponent implements OnInit {
  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {}

  get componentName(): string {
    return 'BiFiltersWidgetComponent';
  }
}
