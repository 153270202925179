import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { startWith } from 'rxjs';
import { INetworkElementTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-type.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';

@UntilDestroy()
@Component({
  selector: 'wlm-ne-type-form',
  templateUrl: './ne-type-form.component.html',
  styleUrls: ['./ne-type-form.component.scss'],
})
export class NeTypeFormComponent extends IntegrableForm {
  @Input() initialModel: INetworkElementTypeDto = null;
  model: INetworkElementTypeDto;

  onModelChange(model: INetworkElementTypeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'networkElementTypeId',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Network Element Type Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'networkElementTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'text',
            label: 'Network Element Type Name',
            required: true,
          },
        },
        {
          key: 'isZone',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Zone',
            required: true,
          },
        },
        {
          key: 'isNetworkElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Network Element',
            required: true,
          },
        },
        {
          key: 'isGisElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is GIS Element',
            required: true,
          },
        },
      ],
    },
    {
      wrappers: ['section'],
      props: { label: 'Map Attributes' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'gisLayerId',
          type: 'input',
          className: 'col-5',
          props: {
            type: 'number',
            label: 'GIS Layer Id',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(field, 'networkElementTypeId', (field) => field.parent.parent),
          },
        },
        {
          key: 'description',
          type: 'input',
          className: 'col-5',
          props: {
            label: 'GIS Layer Description',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(field, 'networkElementTypeName', (field) => field.parent.parent),
          },
        },
        {
          key: 'gisOrder',
          type: 'input',
          className: 'col-2',
          props: {
            type: 'number',
            label: 'GIS Order',
          },
        },
        {
          key: 'iconPath',
          type: 'svg-editor',
          className: 'col-12',
          props: {
            label: 'Icon',
            previewLabel: 'Icon Preview',
            previewPlaceholder: 'Introduce valid SVG content',
          },
          validators: {
            validation: [{ name: 'notSvg' }],
          },
        },
      ],
    },
  ];

  private syncWithField(
    field: FormlyFieldConfig,
    sourceFieldName: string,
    getSourceFieldParent?: (field: FormlyFieldConfig) => FormlyFieldConfig
  ): void {
    const sourceForm = getSourceFieldParent ? getSourceFieldParent(field) : field.parent;
    const sourceFormControl = sourceForm.get(sourceFieldName).formControl;

    sourceFormControl.valueChanges
      .pipe(untilDestroyed(this), startWith(sourceFormControl.value))
      .subscribe((value) => {
        field.formControl.setValue(value);
      });
  }
}
