(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        // WLM: Using 'echarts/lib/echarts' instead of 'echarts' as explained here https://www.npmjs.com/package/ngx-echarts
        define(['exports', 'echarts/lib/echarts'], factory);
    } else if (
        typeof exports === 'object' &&
        typeof exports.nodeName !== 'string'
    ) {
        // CommonJS
        factory(exports, require('echarts/lib/echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
})(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    };
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    
    var theme = {
        legend: {
            backgroundColor: 'rgba(255,255,255, 0.8)'
        },
    };

    echarts.registerTheme('w-white', theme);
});
