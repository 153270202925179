<div class="filters">
    <div class="filters-group">
        <div class="filters-sub-group right-space">
            <wlm-base-filter
              *ngIf="config"
              [settings]="bfDateSettings"
              [clearAll$]="clearAll$"
              (filterText)="onFilterText($event)"
              (filter)="onFilterGroupDate($event)"
              (apply)="onApplyFilters($event)"
            >
              <wlm-date-range-filter-item
                [startFieldName]="config.startDateFieldName"
                [endFieldName]="config.endDateFieldName"
                [dateRange]="config.dateRange"
                [defaultDateRange]="config.defaultDateRange"
                [allowsDateNull]="config.allowsDateNull"
                [settings]="dateRangeItemSettings"
              ></wlm-date-range-filter-item>
            </wlm-base-filter>
        </div>
    </div>
</div>