import { Component } from '@angular/core';

@Component({
  selector: 'wlm-sap-codes-and-groups-crud',
  templateUrl: './sap-codes-and-groups-crud.component.html',
  styleUrls: ['./sap-codes-and-groups-crud.component.scss']
})
export class SapCodesAndGroupsCrudComponent {

}
