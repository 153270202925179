<div *ngIf="mergedZone && queryParams" class="hierarchy-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="hierarchy-column">
    <div class="hierarchy-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-zones' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedElements"
        (somethingChanged)="onDragListChange()"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="hierarchy-column">
    <div class="top-title">
      <div>
        <button
          [disabled]="!configurationHasChanged"
          *hasPerm="['WLMMergedZonesCrud', 'u']"
          mat-icon-button
          class="button-margin"
          (click)="discard()"
          color="primary"
          [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        >
          <wlm-icon [icon]="'discard-changes'"></wlm-icon>
        </button>
        <button
          [disabled]="!configurationHasChanged"
          mat-icon-button
          *hasPerm="['WLMMergedZonesCrud', 'u']"
          class="button-margin"
          (click)="onSave()"
          color="primary"
          [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
        >
          <wlm-icon [icon]="'save'"></wlm-icon>
        </button>
      </div>
    </div>
    <div class="ne-component-title middle-title">
      {{ T_SCOPE + '.merged-zones' | translate }}
    </div>
    <wlm-drag-list-custom
      class="drag-list"
      [objectsToOrder]="mergedZoneElements"
      [settings]="settingsCustom"
      (somethingChanged)="onDragListChange()"
      (droppedElement)="onDroppedElement($event)"
    >
      <ng-template let-rowDetail #card>
        <wlm-drag-list-card
          class="card-width"
          [item]="rowDetail"
          [settings]="cardSettings"
        ></wlm-drag-list-card>
      </ng-template>
    </wlm-drag-list-custom>
  </div>
</div>
