<div *ngIf="items$" class="legend-container">
  <div class="legend-row">
    <div *ngFor="let item of items$ | async" class="legend-item">
      <mat-icon
        *ngIf="item.icon != null && !item.isSvgIcon"
        class="material-icons-outlined"
        [ngStyle]="{ color: item.color }"
        >{{ item.icon }}</mat-icon
      >
      <mat-icon
        *ngIf="item.icon != null && item.isSvgIcon"
        [svgIcon]="item.icon"
        [ngStyle]="{ color: item.color }"
      ></mat-icon>

      <span class="legend-label">{{ item.label }}</span>
    </div>
  </div>
</div>
