import { CalendarExceptionDtoBase } from './calendar-exception-base.dto';
import { CalendarExceptionTypesEnum } from './exception-list.enum';

export class CalendarExceptionCardDto extends CalendarExceptionDtoBase {
  type: CalendarExceptionTypesEnum;
  title: string;
  value: string;

  constructor(init: Partial<CalendarExceptionCardDto>) {
    super(init);
    Object.assign(this, init);
  }
}
