<wlm-ne-selection-grid
  [neSelectionConfiguration]="targetSettings?.neConfiguration"
  [resetHandler$]="resetHandler$"
  [resetCompleteHandler$]="resetCompleteHandler$"
  [hideComponent]="heTypeSelected"
  [persistencyArea]="persistencyAreaNE"
  (selectedNetworkElementsChange)="onTargetChanged($event)"
></wlm-ne-selection-grid>

<wlm-hierarchy-elements-selection-grid
  class="form-control"
  [hierarchyElementFilterConfiguration]="targetSettings?.heConfiguration"
  [resetHandler$]="resetHandler$"
  [resetCompleteHandler$]="resetCompleteHandler$"
  [hideComponent]="!heTypeSelected"
  (selectedHeElementsChange)="onTargetChanged($event)"
>
</wlm-hierarchy-elements-selection-grid>
