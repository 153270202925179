<div class="filter-container">
  <wlm-base-filter-container
    *ngIf="adaptersReady"
    class="filters"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="processFilterChanges($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <!-- TARGET FILTER -->
    <wlm-base-filter
      [settings]="targetGroupSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
      (apply)="onTargetSelect($event)"
      (clearAll)="onTargetClearAll()"
    >
      <wlm-element-target-filter-item
        [fieldName]="neConfigFilterConfiguration.targetSettings.fieldName"
        [filterText]="filterText"
        [settings]="targetItemSettings"
        [selectedIds]="neConfigFilterConfiguration.targetSettings.selectedIds"
        [defaultSelectedIds]="neConfigFilterConfiguration.targetSettings.defaultValue"
        [alternativeAdapter]="alternativeAdapter"
      ></wlm-element-target-filter-item>
    </wlm-base-filter>

    <!-- HIERARCHY LEVELS FILTER -->
    <wlm-base-filter
      *ngIf="loaded"
      (filterText)="onFilterText($event)"
      (apply)="onApplyHEFilter($event)"
      [clearAll$]="clearHEFilter$"
      [ngClass]="isHEFilterSelected ? 'show-component' : 'hide-component'"
      [settings]="hierarchyElementGroupSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-hierarchy-family-filter-item
        *ngIf="loaded"
        [selectedIds]="[familyIdSelected]"
        [fieldName]="neConfigFilterConfiguration.hierarchyElementFamilyFieldName"
        [filterText]="filterText"
        [restoreStatusOrder]="1"
        [settings]="familyFilterItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>

      <wlm-hierarchy-elements-filter-item
        [filterText]="filterText"
        [fieldName]="neConfigFilterConfiguration.heSettings.elementTypeFieldName"
        [familyId]="familyIdSelected"
        [defaultSelectedElements]="neConfigFilterConfiguration.defaultHETypeIds"
        [restoreStatusOrder]="2"
        [selectedIds]="neConfigFilterConfiguration.heSettings?.selectedElementIds"
        [settings]="hierarchyFilterItemSettings"
        (hierarchyLevelsListChanged)="onhierarchyLevelsListChanged($event)"
      ></wlm-hierarchy-elements-filter-item>
    </wlm-base-filter>

    <!-- NETWORK ELEMENTS FILTER -->
    <wlm-base-filter
      *ngIf="loaded"
      (filterText)="onFilterText($event)"
      [ngClass]="isHEFilterSelected ? 'hide-component' : 'show-component filter-right-margin'"
      [settings]="networkElementsGroupSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-network-elements-filter-item
        [filterText]="filterText"
        [fieldName]="neConfigFilterConfiguration.neSettings.elementTypeFieldName"
        [defaultSelectedElements]="neConfigFilterConfiguration.defaultNETypeIds"
        [selectedIds]="neConfigFilterConfiguration.neSettings?.selectedElementIds"
        [settings]="networkFilterItemSettings"
        [filterListElements]="filterListElements"
        mode="single"
      ></wlm-network-elements-filter-item>
    </wlm-base-filter>

    <!-- TREE WITH FAMILY FILTER -->
    <wlm-base-filter
      *ngIf="loaded && treeSettings !== null && !isHEFilterSelected"
      [settings]="treeGroupSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-family-filter-item
        *ngIf="!isHEFilterSelected"
        [selectedIds]="[familyIdSelected]"
        [fieldName]="neConfigFilterConfiguration.hierarchyElementFamilyFieldName"
        [filterText]="filterText"
        [restoreStatusOrder]="3"
        [settings]="familyFilterItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>

      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="neConfigFilterConfiguration.hierarchyElementIdFieldName"
        [hierarchyFamilyIdFieldName]="neConfigFilterConfiguration.hierarchyElementFamilyFieldName"
        [hierarchyFamilyId]="familyIdSelected"
        [initialSelectedKeys]="neConfigFilterConfiguration.initialHierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [rebuildTree$]="rebuildTreeFilter$"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>

    <!-- TREE WITHOUT FAMILY FILTER  -->
    <wlm-base-filter
      *ngIf="loaded && treeSettings !== null && isHEFilterSelected"
      [settings]="treeGroupSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="neConfigFilterConfiguration.hierarchyElementIdFieldName"
        [hierarchyFamilyIdFieldName]="neConfigFilterConfiguration.hierarchyElementFamilyFieldName"
        [hierarchyFamilyId]="familyIdSelected"
        [initialSelectedKeys]="neConfigFilterConfiguration.initialHierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [rebuildTree$]="rebuildTreeFilter$"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
</div>
