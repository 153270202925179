import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from './filter';

export class BasicFilter implements IFilter {
  fieldName: string;
  value: any;
  additionalFields: any;
  operator: string;
  ignoreCase?: boolean;

  constructor(
    fieldName: string,
    value: any,
    additionalFields: any = {},
    operator: string = 'eq',
    ignoreCase: boolean = false
  ) {
    this.fieldName = fieldName;
    this.value = value;
    this.additionalFields = additionalFields;
    this.operator = operator;
    this.ignoreCase = ignoreCase;
  }
  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.fieldName, this.value]]);
  }

  getSelectedFilters() {
    return this.value;
  }

  getFilters(): CompositeFilterDescriptor {
    let basicFilterDescriptor: FilterDescriptor;
    basicFilterDescriptor = {
      field: this.fieldName,
      operator: this.operator,
      value: this.value,
      ignoreCase: this.ignoreCase,
    };
    return {
      filters: [basicFilterDescriptor],
      logic: 'and',
    };
  }
}
