<div *ngIf="responsive && showHamburguer" class="position-centered">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="menu-with-buttons">
    <ng-container [ngTemplateOutlet]="contentsTemplate"> </ng-container>
  </mat-menu>
</div>
<div
  *ngIf="!responsive || !isButtonsRequiredSizeCalculated || !showHamburguer"
  #container
  [class.buttons-horizontal-container]="responsive"
>
  <ng-container [ngTemplateOutlet]="contentsTemplate"> </ng-container>
</div>
