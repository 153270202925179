import { Component, Input } from '@angular/core';

@Component({
  selector: 'wlm-version-info-item',
  templateUrl: './version-info-item.component.html',
  styleUrls: ['./version-info-item.component.scss'],
})
export class VersionInfoItemComponent {
  @Input() label: string;
  @Input() value: any;
}
