import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PointReplacementDto,
  PointReplacementResponseDto,
} from 'src/app/common-modules/dependencies/signals/models/point-replacement.dto';
import { BaseService } from '../../shared/base.service';
import { SignalTelemetryNullableViewDto } from '../../shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { SignalTelemetryDto } from '../../shared/model/telemetry/signal-telemetry.dto';
import { SignalConfigurationDto } from './models/signal-configuration.dto';
import { SignalsConfiguredQueryDto } from './models/signals-configured-query.dto';

@Injectable({ providedIn: 'root' })
export class SignalsService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/telemetry`;
  }

  public getSignalsConfigurationForElement(
    query: SignalsConfiguredQueryDto
  ): Observable<SignalConfigurationDto[]> {
    return this.httpCacheClient.post<SignalConfigurationDto[]>(
      `${this.url}/signal/configuration`,
      query
    );
  }

  public replaceSignalConfiguration(
    query: PointReplacementDto
  ): Observable<PointReplacementResponseDto> {
    return this.httpCacheClient.post<PointReplacementResponseDto>(
      `${this.url}/signal/replace`,
      query
    );
  }

  public createSignalTelemetry(signalTelemetry: SignalTelemetryDto): Observable<boolean> {
    return this.httpCacheClient.post(`${this.url}/signal`, signalTelemetry);
  }

  public editSignalTelemetry(signalTelemetry: SignalTelemetryDto): Observable<boolean> {
    return this.httpCacheClient.post(
      `${this.url}/signal/${signalTelemetry.signalId}`,
      signalTelemetry
    );
  }

  public getPointInfo(pointId: string): Observable<SignalTelemetryDto> {
    return this.httpCacheClient.get(`${this.url}/signal/pointInfo/${pointId}`, {
      avoid: true,
    });
  }

  getSignalsByIds(signalIds: number[]): Observable<SignalTelemetryNullableViewDto[]> {
    const query = {
      signalIds,
    };
    return this.httpCacheClient.get(`${this.url}/signal/ids`, { avoid: false }, query);
  }

  public getSignalsConfigurationDetails(signalId: number): Observable<SignalConfigurationDto[]> {
    return this.httpCacheClient.get<SignalConfigurationDto[]>(
      `${this.url}/signal/configuration/${signalId}`,
      {
        avoid: true,
      }
    );
  }
}
