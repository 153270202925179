import { Component, OnInit } from '@angular/core';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { IconLoaderService } from 'src/app/common-modules/shared/icon-loader.service';

@Component({
  selector: 'wlm-theme-test-page',
  templateUrl: './theme-test-page.component.html',
  styleUrls: ['./theme-test-page.component.scss'],
})
export class ThemeTestPageComponent extends BasePageComponent implements OnInit {
  readonly textSample = 'Text Sample';

  readonly colorItems = [];

  readonly textItems = [
    { label: 'h1, .wtr-headline', class: '' },
    { label: 'h2, .wtr-title', class: '' },
    { label: 'h3, .wtr-subheading', class: '' },
  ];

  iconNames: string[] = [];

  constructor(private _iconLoaderService: IconLoaderService) {
    super();
    this.hasRightPanel = false;
    this.iconNames = this._iconLoaderService.getIconNames();
  }

  public get titleTranslationKey(): string {
    return 'Theme Test';
  }

  public get persistencyArea(): string {
    return null;
  }

  public get pageCrud(): string {
    return null;
  }

  public get navigations(): DropdownNavigationItem[] {
    return null;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
