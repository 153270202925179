import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoldenLayoutHostModule } from '../golden-layout-host/golden-layout-host.module';
import { matDateFormatsProvider } from '../shared/constants/custom-providers';
import { WidgetManagerModule } from '../widget-manager/widget-manager.module';
import { BeforeTabTitleComponent } from './components/before-tab-title/before-tab-title.component';
import {
  DYNAMIC_LAYOUT_EXTERNAL_SETTINGS,
  DynamicLayoutExternalSettings,
} from './dynamic-layout-external-settings';
import { DynamicLayoutComponent } from './dynamic-layout/dynamic-layout.component';
import { DynamicLayoutBuilderService } from './layouts/dynamic-layout-builder-service';
import { SelectorDynamicLayoutComponent } from './layouts/selector-dynamic-layout/selector-dynamic-layout.component';
import { DynamicLayoutHeadersService } from './services/dynamic-layout-headers.service';
import { DynamicLayoutStyleLevelsService } from './services/dynamic-layout-style-levels.service';
import { DynamicParamsService } from './services/dynamic-params.service';
import { GoldenLayoutHelperService } from './services/golden-layout-helper.service';
import { LayoutTemplatesService } from './services/layout-templates.service';
import { PersistLayoutBackendService } from './services/persist-layout-backend.service';
import { PersistLayoutLocalService } from './services/persist-layout-local.service';
import { PersistLayoutService } from './services/persist-layout.service';

@NgModule({
  declarations: [DynamicLayoutComponent, SelectorDynamicLayoutComponent, BeforeTabTitleComponent],
  imports: [CommonModule, WidgetManagerModule, GoldenLayoutHostModule],
  exports: [DynamicLayoutComponent, SelectorDynamicLayoutComponent],
  providers: [
    // GoldenLayoutService,
    PersistLayoutLocalService,
    PersistLayoutBackendService,
    DynamicParamsService,
    {
      provide: PersistLayoutService,
      useExisting: PersistLayoutBackendService,
    },
    matDateFormatsProvider,
    LayoutTemplatesService,
    DynamicLayoutBuilderService,
    DynamicLayoutHeadersService,
    GoldenLayoutHelperService,
    DynamicLayoutStyleLevelsService,
  ],
})
export class DynamicLayoutModule {
  static forFeature(
    externalSettings: DynamicLayoutExternalSettings
  ): ModuleWithProviders<DynamicLayoutModule> {
    return {
      ngModule: DynamicLayoutModule,
      providers: [
        {
          provide: DYNAMIC_LAYOUT_EXTERNAL_SETTINGS,
          useValue: externalSettings,
        },
        {
          provide: PersistLayoutService,
          useExisting:
            externalSettings.persistency === 'local'
              ? PersistLayoutLocalService
              : PersistLayoutBackendService,
        },
      ],
    };
  }
}
