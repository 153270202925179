import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Type } from 'class-transformer';
import { environment } from 'src/environments/environment';
import { IFilter } from '../../filters/component-filters/filter';

export class DateRange implements IFilter {
  @Type(() => Date)
  start: Date;
  @Type(() => Date)
  end?: Date;

  startFieldName: string;
  endFieldName: string;
  inclusive = environment.endDateLowerEqual;
  allowsNullDate = false;

  constructor(
    start: Date,
    end?: Date,
    startFieldName: string = null,
    endFieldName: string = null,
    allowsNullDate?: boolean,
    inclusive?: boolean
  ) {
    if (start) {
      this.start = new Date(start);
    }
    if (end) {
      this.end = new Date(end);
    }
    this.startFieldName = startFieldName;
    this.endFieldName = endFieldName;
    this.allowsNullDate = allowsNullDate;
    this.inclusive = inclusive ?? this.inclusive;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([
      [this.startFieldName, this.start],
      [this.endFieldName, this.end],
    ]);
  }

  public cloneWithValues(start: Date, end: Date): DateRange {
    const cloned = new DateRange(
      start,
      end,
      this.startFieldName,
      this.endFieldName,
      this.allowsNullDate,
      this.inclusive
    );
    return cloned;
  }

  public clone(): DateRange {
    return new DateRange(
      this.start,
      this.end,
      this.startFieldName,
      this.endFieldName,
      this.allowsNullDate,
      this.inclusive
    );
  }

  getSelectedFilters() {
    return new DateRange(this.start, this.end);
  }

  getOrIsNullCompositeFilter(
    fieldName: string,
    baseFilter: FilterDescriptor
  ): CompositeFilterDescriptor {
    const compositeFilter: CompositeFilterDescriptor = { filters: [], logic: 'or' };
    compositeFilter.filters.push(this.getOrIsNullFilter(fieldName));
    compositeFilter.filters.push(baseFilter);
    return compositeFilter;
  }

  getOrIsNullFilter(fieldName: string): FilterDescriptor {
    let isNullFilter: FilterDescriptor;

    isNullFilter = {
      field: fieldName,
      operator: 'isnull',
    };
    return isNullFilter;
  }

  getDatesFilter(): CompositeFilterDescriptor {
    const compositeFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
    const sameField = this.startFieldName === this.endFieldName;

    let filterStartDate: FilterDescriptor;
    filterStartDate = {
      field: this.startFieldName,
      operator: 'ge',
      value: this.start,
    };

    if (this.allowsNullDate) {
      const isnullfilter = this.getOrIsNullCompositeFilter(this.startFieldName, filterStartDate);
      compositeFilter.filters.push(isnullfilter);
    } else {
      compositeFilter.filters.push(filterStartDate);
    }

    if (this.end) {
      let filterEndDate: FilterDescriptor;

      filterEndDate = {
        field: this.endFieldName,
        operator: !this.inclusive && sameField ? 'lt' : 'le',
        value: new Date(this.end),
      };
      if (this.allowsNullDate) {
        const isnullfilter = this.getOrIsNullCompositeFilter(this.endFieldName, filterEndDate);
        compositeFilter.filters.push(isnullfilter);
      } else {
        compositeFilter.filters.push(filterEndDate);
      }
    }

    return compositeFilter;
  }

  getRangeFilters(): CompositeFilterDescriptor {
    const compositeFilterStartAndEnd: CompositeFilterDescriptor = { filters: [], logic: 'and' };

    const compisiteFilterEndDateGreaterThanStart: CompositeFilterDescriptor = {
      filters: [],
      logic: 'or',
    };

    const compositeFilterStartLessThanEnd: CompositeFilterDescriptor = {
      filters: [],
      logic: 'and',
    };

    const compositeFilterFinal: CompositeFilterDescriptor = { filters: [], logic: 'or' };

    let filterStartDate: FilterDescriptor;
    filterStartDate = {
      field: this.startFieldName,
      operator: 'lte',
      value: this.start,
    };

    let filterEndDateNotNull: FilterDescriptor;
    filterEndDateNotNull = {
      field: this.endFieldName,
      operator: 'isnull',
    };

    let filterEndDate: FilterDescriptor;
    filterEndDate = {
      field: this.endFieldName,
      operator: 'gte',
      value: this.start,
    };

    if (this.end) {
      const filterStartDateLessThanEnd: FilterDescriptor = {
        field: this.startFieldName,
        operator: 'lt',
        value: new Date(this.end),
      };
      compositeFilterStartLessThanEnd.filters.push(filterStartDateLessThanEnd);
    }

    const filterStartDateGreaterThanStart: FilterDescriptor = {
      field: this.startFieldName,
      operator: 'gte',
      value: this.start,
    };

    compositeFilterStartLessThanEnd.filters.push(filterStartDateGreaterThanStart);

    compisiteFilterEndDateGreaterThanStart.filters.push(filterEndDate, filterEndDateNotNull);

    compositeFilterStartAndEnd.filters.push(
      compisiteFilterEndDateGreaterThanStart,
      filterStartDate
    );

    compositeFilterFinal.filters.push(compositeFilterStartAndEnd, compositeFilterStartLessThanEnd);

    if (this.allowsNullDate) {
      compositeFilterFinal.filters.push(this.getOrIsNullFilter(this.startFieldName));
    }

    return compositeFilterFinal;
  }

  getFilters() {
    if (this.startFieldName === this.endFieldName) {
      return this.getDatesFilter();
    } else {
      return this.getRangeFilters();
    }
  }
}
