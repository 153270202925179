import { GenericAction } from 'src/app/common-modules/redux/models/generic-action';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';

export enum GenericActionTypes {
  SetValue = '[Generic] Set Value',
  ResetState = '[Generic] Reset State',
}

export class SetValueActionPayload {
  fieldName: string;
  value: unknown;
}

export class ResetStateActionPayload {
  fieldNames: string[];
}

export class SetValueAction extends GenericAction {
  area = StateAreas.Generic;
  type = GenericActionTypes.SetValue;

  constructor(public payload: SetValueActionPayload, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetStateAction extends GenericAction {
  area = StateAreas.Generic;
  type = GenericActionTypes.ResetState;

  constructor(public payload: ResetStateActionPayload, settings = new StateScopeSettings({})) {
    super(settings);
  }
}
