import { EnvelopePersistencyFactor } from './envelope-persistency-factor.enum';

export class EnvelopesConfiguration {
  mode: number;

  hiHi: number | null;
  hi: number | null;
  lowLow: number | null;
  low: number | null;

  hiHiSeverity: number | null;
  hiSeverity: number | null;
  lowLowSeverity: number | null;
  lowSeverity: number | null;

  hiHiRelative: number | null;
  hiRelative: number | null;
  lowLowRelative: number | null;
  lowRelative: number | null;

  hiHiPersistency: number | null;
  hiPersistency: number | null;
  lowLowPersistency: number | null;
  lowPersistency: number | null;

  defaultPersistencyFactor: EnvelopePersistencyFactor;

  constructor(init?: Partial<EnvelopesConfiguration>) {
    Object.assign(this, init);
  }
}
