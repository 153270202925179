import { NavKeys } from './nav-keys.enum';

export abstract class BaseLink {
  title: string;
  svgIcon: string;
  isSvg: boolean;
  key?: NavKeys;

  constructor(init?: Partial<BaseLink>) {
    Object.assign(this, init);
  }
}
