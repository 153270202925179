import { Injectable, Injector } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpCacheService } from '../../cache/http-cache/http-cache.service';
import { BaseService } from '../../shared/base.service';
import { HierarchyElementTypeTimeUnitsQueryDto } from '../../shared/model/uom/hierarchy-element-type-time-units.dto';
import { UoMService } from '../../shared/uom/uom.service';

@Injectable()
export class UomConfigurationService extends BaseService {
  constructor(
    injector: Injector,
    private _uomService: UoMService,
    private _cacheService: HttpCacheService
  ) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/unit/hierarchyTypeTimeUnit`;
  }

  getUomConfigurationsByParams(
    hierarchyFamilyId: string,
    dimensionTypeId: number,
    timeAggregation: number
  ): Observable<HierarchyElementTypeTimeUnitsQueryDto[]> {
    const url = `${this.url}/${hierarchyFamilyId}/${timeAggregation}/${dimensionTypeId}`;

    return this.httpCacheClient.get<HierarchyElementTypeTimeUnitsQueryDto[]>(url, {
      avoid: true,
    });
  }

  saveUomConfigurations(
    heTypeTimeUnitsConfig: HierarchyElementTypeTimeUnitsQueryDto[]
  ): Observable<boolean> {
    return this.httpCacheClient.post<boolean>(`${this.url}/save`, heTypeTimeUnitsConfig).pipe(
      switchMap((res) => {
        return from(this._cacheService.clearContainsInUrl('unit')).pipe(
          switchMap(() => {
            return this._uomService.reload().pipe(
              map(() => {
                return res;
              })
            );
          })
        );
      })
    );
  }
}
