/**
 * Scopes are a logical way to group data inside the store.
 * The developer can define as many as they want, as long as they are consistent between components.
 */

export enum NECScopes {
  Main = 'NECData',
  AttributeForms = 'AttributeForms',
  BI = 'BI',
}
