<div class="select" *ngIf="options && options.length !== 0">
  <span class="filter-select-item filter-item-title" (click)="toggleSelectAll()">
    <mat-checkbox
      *ngIf="mode === 'multiple' && !settings.hideSelectAllCheckbox"
      class="select-all"
      [checked]="selectAllCheckbox"
      (click)="$event.preventDefault()"
      [disabled]="selectAllCheckbox"
    ></mat-checkbox>
    <span
      class="filter-select-item-title"
      [ngClass]="{
      'filter-select-item-title-check-all': mode === 'multiple' && !settings.hideSelectAllCheckbox,
    }"
    >
      {{ title }}
    </span>
    <span
      *ngIf="selectCount && mode === 'multiple' && !settings.hideSelectAllCheckbox"
      class="select-counter"
      >({{ selectCount }})</span
    >
  </span>
  <div
    *ngFor="let option of options | slice : 0 : elementsLimit; let i = index"
    class="filter-select-item"
    [class.first-item]="i === 0"
    (click)="onToggle(option)"
  >
    <mat-checkbox
      class="checkbox"
      [checked]="selected[option.value]"
      [disabled]="getDisabledState(option)"
    ></mat-checkbox>
    <wlm-svg
      *ngIf="option.icon"
      class="svg-icon filter-item-option-icon"
      [style.height]="option.height + 'px'"
      [style.width]="option.width + 'px'"
      [width]="option.width || iconSize"
      [height]="option.height || iconSize"
      [path]="option.icon"
    ></wlm-svg>
    <span
      class="filter-select-item-label"
      [innerHtml]="option.label | highlightSearch : filterText"
    ></span>
  </div>
</div>
