<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background">
    <div class="filters-group">
      <div class="filters-sub-group">
        <wlm-base-filter
          *ngIf="treeSettings !== null"
          [settings]="bf1Settings"
          [persistItems$]="persistFilters$"
        >
          <wlm-date-range-filter-item
            [allowsDateNull]="activityFilterConfiguration.allowsDateNull"
            [startFieldName]="activityFilterConfiguration.startDateFieldName"
            [endFieldName]="activityFilterConfiguration.endDateFieldName"
            [dateRange]="activityFilterConfiguration.dateRange"
            [defaultDateRange]="activityFilterConfiguration.defaultDateRange"
            [settings]="dateRangeItemSettings"
          ></wlm-date-range-filter-item>
        </wlm-base-filter>
      </div>
      <div class="filters-sub-group margin-center">
        <wlm-base-filter
          *ngIf="treeSettings !== null"
          [settings]="bf2Settings"
          [persistItems$]="persistFilters$"
          (filterText)="onFilterText($event)"
        >
          <wlm-activity-types-filter-item
            [repairFieldName]="activityFilterConfiguration.repairFieldName"
            [detectionFieldName]="activityFilterConfiguration.detectionFieldName"
            [filters]="activityFilterConfiguration.filters"
            [filterText]="filterText"
            [settings]="activityTypesItemSettings"
            [defaultSelectedIds]="activityFilterConfiguration.defaultActivityTypesSelected"
          ></wlm-activity-types-filter-item>
          <wlm-activity-only-open-filter-item
            [fieldName]="activityFilterConfiguration.activityStatusFieldName"
            [filters]="activityFilterConfiguration.filters"
            [filterText]="filterText"
            [settings]="activityStatusItemSettings"
            [defaultSelectedIds]="activityFilterConfiguration.defaultStatusSelected"
          ></wlm-activity-only-open-filter-item>
        </wlm-base-filter>
      </div>
      <div class="filters-sub-group">
        <wlm-base-filter
          *ngIf="treeSettings !== null"
          [settings]="bf3Settings"
          [persistItems$]="persistFilters$"
          (filterText)="onFilterText($event)"
        >
          <wlm-hierarchy-family-filter-item
            [fieldName]="activityFilterConfiguration.hierarchyElementFamilyFieldName"
            [selectedIds]="[activityFilterConfiguration.hierarchyElementFamily]"
            [filterText]="filterText"
            [settings]="hierarchyFamilyItemSettings"
            (select)="onFamilyIdSelect($event)"
          >
          </wlm-hierarchy-family-filter-item>
          <wlm-hierarchy-tree-filter-item
            [hierarchyElementIdFieldName]="activityFilterConfiguration.hierarchyElementIdFieldName"
            [hierarchyFamilyIdFieldName]="
              activityFilterConfiguration.hierarchyElementFamilyFieldName
            "
            [hierarchyFamilyId]="hierarchyElementFamilyId"
            [initialSelectedKeys]="activityFilterConfiguration.hierarchyElementId"
            [filterText]="filterText"
            [(treeSettings)]="treeSettings"
            [settings]="hierarchyTreeItemSettings"
            (filterReady)="onTreeFilterReady()"
          ></wlm-hierarchy-tree-filter-item>
        </wlm-base-filter>
      </div>
    </div>
  </div>
</wlm-base-filter-container>
