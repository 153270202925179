import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitTypeConversionViewDto } from '../../../model/uom/unit-type-conversion-view.dto';
import { UoMService } from '../../../uom/uom.service';
import { LogScope } from '../../../wlm-log/log-scope';
import { LogService } from '../../../wlm-log/log.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToUomMapperParams } from '../mapper-params/to-uom-mapper-params';

/**
 * Only allows conversions to the user's configured units.
 * To convert from any to any unit, use ToAnyUoMMapper.
 */

@Injectable()
export class ToUoMMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToUoM;
  params: ToUomMapperParams;

  constructor(private _uomService: UoMService, private _logService: LogService) {
    super();
  }

  map = (value: number, model: any, params: ToUomMapperParams = null): Observable<string> => {
    if (typeof value === 'undefined') {
      return value;
    }

    let unit$: Observable<UnitTypeConversionViewDto>;

    if (this.canGetOnlyByDimension(params)) {
      unit$ = this._uomService.getBySignal(params.dimensionTypeId);
    } else if (this.canGetByFromUnit(params)) {
      unit$ = this._uomService.getByFromUnit(
        params.dimensionTypeId,
        params.unitTypeFromId,
        params.timeAggregationId,
        params.hierarchyElementTypeId
      );
    } else if (this.canGetByDimensionAndTimeAggregation(params)) {
      unit$ = this._uomService.getByParams(
        params.dimensionTypeId,
        params.timeAggregationId,
        params.hierarchyElementTypeId
      );
    } else {
      this._logService.error({
        scope: LogScope.Mappers,
        msg: `Must have some valid combinations of settings to convert to UoM.`,
        payload: params,
      });
      return of(String(value));
    }

    return unit$.pipe(
      map((unit) => {
        if (!unit) {
          this._logService.error({
            scope: LogScope.Mappers,
            msg: `No unit could be found for settings.`,
            payload: params,
          });
          return String(value);
        }

        if (value === null) {
          return "";
        }

        let convertedValue = this._uomService.uomMultiply(
          String(value),
          String(unit.conversionFactor),
          false,
          params.dimensionTypeId
        );

        convertedValue = `${convertedValue} ${unit.unitTypeToDescription}`;
        return convertedValue;
      })
    );
  };

  private canGetOnlyByDimension = (params: { dimensionTypeId }) => {
    const result = Object.keys(params).length === 1 && this.defined(params.dimensionTypeId);
    return result;
  };

  private canGetByDimensionAndTimeAggregation = (params) => {
    const result = this.defined(params.dimensionTypeId) && this.defined(params.timeAggregationId);
    return result;
  };

  private canGetByFromUnit = (params: ToUomMapperParams) => {
    const result =
      this.canGetByDimensionAndTimeAggregation(params) && this.defined(params.unitTypeFromId);
    return result;
  };

  private defined = (value) => typeof value !== 'undefined' && value !== null;
}
