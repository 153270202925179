import { Subscription } from 'rxjs';

export class AutoUnsubscribe {
  private subscription: Subscription;
  constructor() {}

  set(subscription: Subscription): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    this.subscription = subscription;
  }
}
