import { Component, ElementRef, Inject, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { SizeCalculatorService } from 'src/app/common-modules/shared/services/size-calculator.service';
import { TimeSelectorChartSettings } from '../models/time-selector-settings';

@Component({
  selector: 'wlm-time-selector-chart-widget',
  templateUrl: './time-selector-chart-widget.component.html',
  styleUrls: ['./time-selector-chart-widget.component.scss'],
})
export class TimeSelectorChartWidgetComponent extends BaseWidgetComponent implements OnInit {
  timeSelectorChartSetting: TimeSelectorChartSettings;

  size$: Observable<IElementSize>;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _element: ElementRef,
    private readonly _sizeCalculatorService: SizeCalculatorService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit() {
    super.ngOnInit();
    this.size$ = this._sizeCalculatorService.listenElementSize$(this._element.nativeElement);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.timeSelectorChartSetting = parameters.parameters.get(
      TabDetailParameterName.timeSelectorChartSetting
    );
  }

  init(): void {}

  get componentName(): string {
    return 'TimeSelectorChartWidgetComponent';
  }
}
