export class FlatTreeNode<T extends number | string, T2> {
  id: T;
  group?: T;
  label: string;
  label2?: string;
  label3?: string;
  isLeaf: boolean;
  data?: T2;
  icon?: string;
  isSvgIcon?: boolean;

  constructor(init: FlatTreeNode<T, T2>) {
    Object.assign(this, init);
  }
}
