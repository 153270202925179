<wlm-popup-wrapper [showCloseButton]="false" [showSpinner]="isLoading" [titleKey]="titleKey">
  <ng-container popup-content>
    <mat-horizontal-stepper
      class="campaign-wizard"
      [linear]="true"
      #stepper
      (selectionChange)="onSelectedIndexChanged($event)"
    >
      <!-- INFO STEP -->
      <mat-step
        [stepControl]="infoForm"
        [errorMessage]="infoErrorMessage"
        [completed]="infoFormStatus"
      >
        <ng-template matStepLabel>{{ T_SCOPE + '.title.info' | translate }}</ng-template>
        <wlm-campaigns-creation-info
          *ngIf="infoForm"
          [campaignNameFieldName]="campaignNameFieldName"
          [campaignDescriptionFieldName]="campaignDescriptionFieldName"
          [modeFieldName]="modeFieldName"
          [hierarchyElementIdFieldName]="hierarchyElementIdFieldName"
          [heFamilyIdFieldName]="heFamilyIdFieldName"
          [hePathFieldName]="hePathFieldName"
          [reductionComponentAutomaticFieldName]="reductionComponentAutomaticFieldName"
          [reductionComponentManualFieldName]="reductionComponentManualFieldName"
          [reductionComponents]="reductionComponents"
          [baseLineFieldName]="baseLineFieldName"
          [systemInputFieldName]="systemInputFieldName"
          [goalCalculationDays]="
            selectedCampaign?.goalCalculationDays ?? defaultGoalCalculationDays
          "
          [infoForm]="infoForm"
          (baselineLoaded)="baselineLoaded($event)"
          (loadingChange)="onLoadingChanged($event)"
        ></wlm-campaigns-creation-info>
      </mat-step>

      <!-- GOALS STEP -->
      <mat-step
        [stepControl]="goalForm"
        [errorMessage]="goalsErrorMessage"
        [completed]="goalForm?.valid"
      >
        <ng-template matStepLabel>{{ T_SCOPE + '.title.goals' | translate }}</ng-template>
        <wlm-campaigns-creation-goals
          *ngIf="goalForm"
          [goalTargetFieldName]="goalTargetFieldName"
          [goalProgressFieldName]="goalProgressFieldName"
          [campaignBudgetFieldName]="campaignBudgetFieldName"
          [statusFieldName]="statusFieldName"
          [targetDateFieldName]="targetDateFieldName"
          [goalForm]="goalForm"
          [isAutomatic]="isAutomatic"
        ></wlm-campaigns-creation-goals>
      </mat-step>
      <!-- ACTIVITIES STEP -->
      <mat-step
        [stepControl]="activitiesForm"
        [errorMessage]="activitiesErrorMessage"
        [completed]="activitiesForm?.valid"
      >
        <ng-template matStepLabel>{{ T_SCOPE + '.title.activities' | translate }}</ng-template>
        <wlm-campaigns-creation-activities
          *ngIf="activitiesForm"
          [activitiesFieldName]="activitiesFieldName"
          [familyId]="selectedFamilyId"
          [campaignId]="campaignId"
          [activitiesForm]="activitiesForm"
        ></wlm-campaigns-creation-activities>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
  <ng-container popup-actions>
    <div class="action-buttons-container">
      <div class="cancel-button-container">
        <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
      </div>
      <button
        mat-button
        class="wtr-action-button-regular btn-size"
        (click)="back()"
        [disabled]="disableBack"
      >
        {{ 'common.back' | translate | uppercase }}
      </button>
      <button
        *ngIf="!disableNext"
        mat-button
        class="wtr-action-button-primary btn-size"
        (click)="next()"
        [disabled]="disableNext"
      >
        {{ 'common.next' | translate | uppercase }}
      </button>
      <button
        *ngIf="disableNext"
        mat-button
        class="wtr-action-button-primary btn-size"
        (click)="saveCampaign()"
      >
        {{ 'common.save' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
