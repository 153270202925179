import { Component, Inject, Injector } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { CustomerPremisesViewDto } from '../../shared/model/customer/customer-premises-view.dto';

const COMPONENT_SELECTOR = 'wlm-customer-detail-single-rp-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-detail-single-rp-widget.component.html',
  styleUrls: ['./customer-detail-single-rp-widget.component.scss'],
})
export class CustomerDetailSingleRpWidgetComponent extends BaseWidgetComponent {
  customer: CustomerPremisesViewDto;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.customer = parameters.parameters.get(TabDetailParameterName.customer);
  }

  init(): void {}

  get componentName(): string {
    return 'CustomerDetailSingleRpWidgetComponent';
  }
}
