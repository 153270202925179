import { GChartSerieLabel } from './g-chart-serie-label';

export class GChartSerieDataSection {
  // Area value
  value: number;
  // Area name
  name: string;
  label?: GChartSerieLabel;

  constructor(init?: Readonly<GChartSerieDataSection>) {
    Object.assign(this, init);
  }
}
