import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericCardSettings } from 'src/app/common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from 'src/app/common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { DIMENSION_ITEMS } from 'src/app/common-modules/uom/models/dimension-items';

const COMPONENT_SELECTOR = 'wlm-data-validation-points-selection';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-points-selection.component.html',
  styleUrls: ['./data-validation-points-selection.component.scss'],
})
export class DataValidationPointsSelectionComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  @Input() widgetId: string;

  private _filters: DataBindingFilters;

  public get filters(): DataBindingFilters {
    return this._filters;
  }
  @Input()
  public set filters(value: DataBindingFilters) {
    if (!value) {
      return;
    }

    this._filters = value;
    this.prepareListsSettings();
  }

  @Output() selectedItemChange = new EventEmitter<any>();

  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _selectedFieldName = 'signalId';

  private _serviceName = 'TelemetryPointsService';
  private _pagesize = 50;
  private _dimensions = DIMENSION_ITEMS;

  private _telemetryCardSettings: { titleField: string; subtitleField: string };

  settings: DragListSettings;
  cardSettings: GenericCardSettings<ISignalTelemetryNullableViewDto>;
  queryParams: Map<string, any>;
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();

  constructor(private _settingsService: SettingsService) {
    this._telemetryCardSettings = this._settingsService.telemetryCardSettings;
  }

  ngOnInit(): void {}

  onSelectItem(selectedItem: any) {
    this.selectedItemChange.emit(selectedItem);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.filters,
      useQueryParams: true,
      displayFieldName: this._pointDescriptionFieldName,
      isReadOnly: false,
      scrollId: this.widgetId,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._pointIdFieldName, this._pointDescriptionFieldName],
    });

    this.cardSettings = new GenericCardSettings<ISignalTelemetryNullableViewDto>({
      title1Fn: (model) =>
        this._telemetryCardSettings.titleField == 'description'
          ? model.pointDescription
          : model.pointId,
      subtitleFn: (model) =>
        this._telemetryCardSettings.subtitleField == 'id' ? model.pointId : model.pointDescription,
      leftIconNameFn: (model) =>
        this._dimensions.find((f) => f.id === model.dimensionTypeId)?.iconName,
    });

    this.queryParams = new Map<string, any>();
  }
}
