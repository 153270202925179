import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { IDynamicSummaryApi } from 'src/app/common-modules/shared/model/dynamic-summary/dynamic-summary-api';
import { SummaryLatestCalculationDayDto } from 'src/app/common-modules/shared/model/dynamic-summary/summary-latest-calculation-day.dto';
import {
  DynamicPageResultDto,
  PagedResultDto,
} from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { ITransmissionNetworkDto } from '../../shared/model/transmission-network/transmission-network.dto';

@Injectable()
export class TransmissionNetworkService
  extends GridODataService<ITransmissionNetworkDto>
  implements IDynamicSummaryApi
{
  constructor(injector: Injector, private readonly _dateHelperService: DateHelperService) {
    super(injector, '/api/summary/dynamic/networkelements/TransmissionNetwork');
  }

  protected mapResponse(
    dynamicResponse: DynamicPageResultDto<ITransmissionNetworkDto>
  ): PagedResultDto<ITransmissionNetworkDto> {
    const responseItems = dynamicResponse.items.map((x) => x.properties);
    const pageResponse: PagedResultDto<ITransmissionNetworkDto> = {
      totalCount: dynamicResponse.totalCount,
      items: responseItems,
    };
    return pageResponse;
  }

  getLatestCalculationDay(): Observable<Date> {
    return this.httpCacheClient
      .get<SummaryLatestCalculationDayDto>(
        `${this.baseUrl}/api/summary/dynamic/TransmissionNetwork/latestcalculationday`,
        {
          avoid: true,
        }
      )
      .pipe(map((result) => this._dateHelperService.fromApiFormat(result.latestCalculationDay)));
  }
}
