import { Injectable } from '@angular/core';
import { WtrColors } from './wtr-colors';

@Injectable({ providedIn: 'root' })
export class StylesService {
  /**
   * Receives a value of the WtrColor enum and maps it
   * to the predefined CSS classes that apply color changes.
   */
  getColorClass(color: WtrColors): string {
    return color ? `wtr-${color}` : null;
  }

  /**
   * Receives a value of the WtrColor enum and maps it
   * to the predefined CSS classes that apply background-color changes.
   */
  getBgColorClass(color: WtrColors): string {
    return color ? `wtr-${color}-bg` : null;
  }
}
