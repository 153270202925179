import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// prettier-ignore
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { WlmNavigationService } from 'src/app/common-modules/dependencies/navigation/wlm-navigation.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../config/settings.service';
import { AuthenticationService } from './authentication.service';
import { AuthorizeService } from './authorize.service';
import { InitialRedirectService } from './initial-redirect.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private _disablePermissions = false;

  constructor(
    private oauthService: OAuthService,
    private authorizeService: AuthorizeService,
    private navigationService: WlmNavigationService,
    private settingsService: SettingsService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _initialRedirectService: InitialRedirectService,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.data?.superUser) {
      return this.authorizeService.isSuperUser();
    }
    const permissions = this._getPermissions(route.data);
    return this._handle(permissions).pipe(
      tap((allowed) => this._log('canActivate', allowed, state.url, permissions))
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.data?.superUser) {
      return this.authorizeService.isSuperUser();
    }
    const permissions = this._getPermissions(route.data);
    return this._handle(permissions).pipe(
      tap((allowed) => this._log('canActivateChild', allowed, state.url, permissions))
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    if (route.data?.superUser) {
      return this.authorizeService.isSuperUser();
    }
    const permissions = this._getPermissions(route.data);
    return this._handle(permissions).pipe(
      tap((allowed) => this._log('canLoad', allowed, route.path, permissions))
    );
  }

  private _handle(permissions: [string, string][]): Observable<boolean> {
    // Wait for configuration to be available.
    return this.settingsService.ready$.pipe(
      take(1),
      switchMap(() => {
        // First, check for valid token.
        if (this._authenticationService.mustLogIn()) {
          this.configureOAuth();
          return of(false);
        } else {
          // Second, check for valid access.
          if (!permissions) {
            return of(true);
          } else {
            return this.authorizeService.canAccessMultiple(permissions);
          }
        }
      })
    );
  }

  /**
   * First check if permissions are specified. They have priority.
   * If no permission, check if a navKey is avaliable and get the permissions from it.
   */
  private _getPermissions(data: any): [string, string][] {
    if (!data) {
      return data;
    }
    if (data.permissions) {
      return data.permissions;
    }
    if (data.navKey) {
      if (!this._disablePermissions) {
        return this.navigationService.getNavLinkPermissions(data.navKey);
      }
    }
    return [];
  }

  private configureOAuth(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.oauthService.configure(this.settingsService.oAuthConfig);

      this._initialRedirectService.saveCurrentRoute();
      this._authenticationService.customLoadDiscoveryDocumentAndTryLogin();
    }
  }

  /**
   * Helps to debug in development. Shows which guard is being used and the result permission.
   */
  private _log(methodName: string, allowed: boolean, route: string, permissions: any): void {
    // Log info as a side effect (only in development).
    if (!environment.production) {
      // console.log(
      //   `Auth Guard (${methodName}): ${
      //     allowed ? '\u2705' : '\u274C'
      //   } for route '${route}' with permissions: `,
      //   permissions
      // );
    }
  }
}
