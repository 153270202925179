import { Pipe, PipeTransform } from '@angular/core';

/**
 * For this pipe to work, the highlight-search must be defined in the main styles.scss.
 * It also must be used inside innerHtml.
 * If the args[1] is set to true, the transformation is skipped.
 */

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args || args[1]) {
      return value;
    }

    const regex = new RegExp(args[0], 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(regex, `<span class='highlight-search'>${match[0]}</span>`);
  }
}
