<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <button
          wlmCollapsibleButton
          *hasPerm="['PrioritisationCrud', 'u']"
          [tooltip]="T_SCOPE + '.manage-eligibility'"
          [disabled]="validateBulkEligibility() || !useLatestCalculationDay"
          [showLabel]="isContainerCollapsed"
          (click)="editEligibility($event)"
        >
          <mat-icon class="manage-elegibility-icon" svgIcon="manage-elegibility"></mat-icon>
        </button>
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-prioritisation-filter
      *ngIf="configReady"
      [prioritisationFilterConfiguration]="prioritisationFilterConfiguration"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="loadGrid()"
      (autoLoad)="onCheckAutoload()"
    ></wlm-prioritisation-filter>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      class="wtr-fx-flex-grow generic-grid"
      #genericGrid
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [removeSelection$]="removeSelection$"
      (selectedItemChanged)="getSelectedPrioritisation($event)"
      [(selectedItems)]="selectedsPrioritisation"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      (notifyUseLatestCalculationDay)="onNotifyUseLatestCalculationDay($event)"
      (timeControlChange)="onTimeControlChange($event)"
      (isInThePastChange)="onIsInThePastChange($event)"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
