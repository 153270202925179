import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { QuillModule } from 'ngx-quill';
import { MatQuill } from './mat-quill';

// https://github.com/KillerCodeMonkey/ngx-quill-example/blob/master/src/app/mat-quill/mat-quill-module.ts

@NgModule({
  declarations: [MatQuill],
  exports: [MatQuill],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, QuillModule, MatFormFieldModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MatQuillModule {}
