import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { IInterfacesLogging } from 'src/app/common-modules/dependencies/interfaces/interfaces-logging';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { LabelValueListItem } from 'src/app/common-modules/shared/core/label-value-list/label-value-list-item';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { MapperFunctions } from 'src/app/common-modules/shared/services/generic-mappers/mapper-functions';
import { ToReadableDateMapperParams } from 'src/app/common-modules/shared/services/generic-mappers/mapper-params/to-readable-date-mapper-params';
import { ToTranslatedStringMapperParams } from 'src/app/common-modules/shared/services/generic-mappers/mapper-params/to-translated-string-mapper-params';
import { LogsInterfacesService } from 'src/app/common-modules/shared/services/logs-interfaces.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-popup.component.html',
  styleUrls: ['./logs-interfaces-popup.component.scss'],
})
export class LogsInterfacesPopupComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;
  readonly COMMON_SCOPE = `${AppModules.Logs}.common`;

  readonly _labelsKey = `${this.T_SCOPE}.fields`;

  isLoading: boolean = false;
  interfacesLogging: IInterfacesLogging;
  logMessage: string;

  readonly headerFields: LabelValueListItem[] = [
    {
      label: `${this._labelsKey}.interfaceName`,
      propertyFn: (model: IInterfacesLogging) => model.interfaceName,
      mappers: [MapperFunctions.ToTranslatedString],
      mappersParams: {
        [MapperFunctions.ToTranslatedString]: {
          labelKeyPrefix: `${AppModules.Logs}.wlm-logs-interfaces-selector`,
        } as ToTranslatedStringMapperParams,
      },
    },
    {
      label: `${this._labelsKey}.startDate`,
      propertyFn: (model: IInterfacesLogging) => model.startDate,
      mappers: [MapperFunctions.ToReadableDate],
      mappersParams: {
        [MapperFunctions.ToReadableDate]: {
          format: DateFormats.DateTime,
          toUtc: false,
        } as ToReadableDateMapperParams,
      },
    },
    {
      label: `${this._labelsKey}.endDate`,
      propertyFn: (model: IInterfacesLogging) => model.endDate,
      mappers: [MapperFunctions.ToReadableDate],
      mappersParams: {
        [MapperFunctions.ToReadableDate]: {
          format: DateFormats.DateTime,
          toUtc: false,
        } as ToReadableDateMapperParams,
      },
    },
  ];

  get componentName(): string {
    return 'LogsInterfacesPopupComponent';
  }

  constructor(
    private _dialogRef: MatDialogRef<LogsInterfacesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) dataItem: IInterfacesLogging,
    private _spinnerService: SpinnerService,
    private _logsInterfacesService: LogsInterfacesService
  ) {
    this.interfacesLogging = dataItem;
  }

  ngOnInit(): void {}

  onClickDownload() {
    this.setLoading(true);

    const fileName = this.interfacesLogging.compressedName
      ? this.interfacesLogging.compressedName
      : this.interfacesLogging.fileName;

    this._logsInterfacesService
      .download(this.interfacesLogging.interfaceName, [fileName])
      .pipe(
        finalize(() => this.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (result) => {
          const blob = new Blob([result], { type: result.type });
          const url = window.URL.createObjectURL(blob);

          const downloadName = this.getDownloadName(fileName);

          globalUtilsHelper.downloadFile(url, downloadName);
        },
      });
  }

  close() {
    this._dialogRef.close(false);
  }

  private getDownloadName(fileName: string) {
    const parts = fileName.split('\\');

    if (parts.length > 1) {
      return parts[parts.length - 1];
    }

    return fileName;
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }
}
