import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegrationPageComponent } from './pages/integration-page/integration-page.component';

const routes: Routes = [
  {
    path: '',
    component: IntegrationPageComponent,
    data: {},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegrationRoutingModule {
  constructor() {}
}
