import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

const COMPONENT_SELECTOR = 'wlm-file-upload';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() requiredFileType: string;
  @Input() multiple = false;
  @Input() labelKey: string;
  @Input() showProgress = false;

  isDisabled: boolean;
  displayCtrl = new UntypedFormControl();
  uploadProgress = null;
  readonly fieldAppearance = 'outline';

  private nativeOnChange: (value: File | File[]) => void;
  private nativeOnTouched: (value: File | File[]) => void;

  constructor() {}

  onFileSelected(event): void {
    this.writeMainCtrl(event.target.files);

    let fileName;
    if (this.multiple) {
      const files: File[] = event.target.files;
      fileName = files.join(', ');
    } else {
      const file: File = event.target.files[0];
      fileName = file.name;
    }

    this.displayCtrl.setValue(fileName);
  }

  /**
   * Receive the value that comes from the generic control.
   */
  writeValue(model: File | File[]): void {
    if (!model) {
      return;
    }
    let fileName;
    if (this.multiple) {
      fileName = (model as File[]).join(', ');
    } else {
      fileName = (model as File).name;
    }

    this.displayCtrl.setValue(fileName);
  }

  /**
   * Propagates the changes so that the binded form or ngModel can receive them.
   */
  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.nativeOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onOpenFileUpload(event, fileUploadInput): void {
    event.stopPropagation();
    fileUploadInput.click();
  }

  private writeMainCtrl(files: File[]): void {
    if (this.multiple) {
      this.nativeOnChange(files);
    } else {
      const file: File = files[0];
      this.nativeOnChange(file);
    }
  }
}
