import { DimensionTypesEnum } from '../model/shared/dimension-types';

interface IDimensions {
  MinColumnWidth: number;
  MedColumnWidth: number;
  LargeColumnWidth: number;
}

export const Dimensions: Readonly<IDimensions> = {
  MinColumnWidth: 100,
  MedColumnWidth: 150,
  LargeColumnWidth: 220,
};

interface IColumnStyle {
  columnStyleMap: Map<string, string>;
  type: string;
}

export const ColumnStyles: Readonly<IColumnStyle[]> = [
  {
    type: 'numeric',
    columnStyleMap: new Map<string, string>([
      ['max-width', '400px'],
      ['border-style', 'solid'],
      ['border-width', '0 1px 1px 0'],
      ['justify-content', 'flex-end'],
    ]),
  },
  {
    type: 'currency',
    columnStyleMap: new Map<string, string>([
      ['max-width', '400px'],
      ['border-style', 'solid'],
      ['border-width', '0 1px 1px 0'],
      ['justify-content', 'flex-end'],
    ]),
  },
  {
    type: 'uom',
    columnStyleMap: new Map<string, string>([
      ['max-width', '400px'],
      ['border-style', 'solid'],
      ['border-width', '0 1px 1px 0'],
      ['justify-content', 'flex-end'],
    ]),
  },
  {
    type: 'default',
    columnStyleMap: new Map<string, string>([
      ['max-width', '400px'],
      ['border-style', 'solid'],
      ['border-width', '0 1px 1px 0'],
    ]),
  },
];

interface IDimension {
  Height: string;
  Width: string;
}

export const AlarmAckPopupDimensions: Readonly<IDimension> = {
  Height: '250px',
  Width: '400px',
};

export const AlarmsNavPopupDimensions: Readonly<IDimension> = {
  Height: '670px',
  Width: '1290px',
};

export const AlgorithmsNavPopupDimensions: Readonly<IDimension> = {
  Height: '590px',
  Width: '800px',
};

export const NotificationEditPopupDimensions: Readonly<IDimension> = {
  Height: '610px',
  Width: '60vw',
};

export const VersionInfoPopupDimensions: Readonly<IDimension> = {
  Height: '380px',
  Width: '500px',
};

export const SignalSelectionPopupDimensions: Readonly<IDimension> = {
  Height: '600px',
  Width: '900px',
};

export const SignalSelectionPopupLargeDimensions: Readonly<IDimension> = {
  Height: '650px',
  Width: '900px',
};

export const UnflagReasonPopupDimensions: Readonly<IDimension> = {
  Height: '430px',
  Width: '650px',
};

export const FlagReasonPopupDimensions: Readonly<IDimension> = {
  Height: '450px',
  Width: '650px',
};

export const CustomerPopupDimensions: Readonly<IDimension> = {
  Height: '650px',
  Width: '900px',
};

export const ConfigurationHomePopupDimensions: Readonly<IDimension> = {
  Height: '250px',
  Width: '650px',
};

export const LogsInterfacesPopupDimensions: Readonly<IDimension> = {
  Height: '80vh',
  Width: '80vw',
};

export const PointCreationPopupDimensions: Readonly<IDimension> = {
  Height: '500px',
  Width: '450px',
};

export const DataVisualizationAddAlgorithmPopupDimensions: Readonly<IDimension> = {
  Height: '800px',
  Width: '650px',
};

export const DataVisualizationChartConfigPopupDimensions: Readonly<IDimension> = {
  Height: '800px',
  Width: '700px',
};

export const DataVisualizationSaveConfigPopupDimensions: Readonly<IDimension> = {
  Height: '800px',
  Width: '900px',
};

export const DataVisualizationManageTemplatePopupDimensions: Readonly<IDimension> = {
  Height: '800px',
  Width: '950px',
};

export const CalendarPopupDimensions: Readonly<IDimension> = {
  Height: '300px',
  Width: '400px',
};

export const MapConfigurationsPopupDimensions: Readonly<IDimension> = {
  Height: '860px',
  Width: '1400px',
};

export const ExtendedTooltipDimensions: Readonly<IDimension> = {
  Height: '350px',
  Width: '600px',
};

export const TelemetryDimensionTypes: DimensionTypesEnum[] = [
  DimensionTypesEnum.Flow,
  DimensionTypesEnum.Pressure,
  DimensionTypesEnum.Level,
  DimensionTypesEnum.Temperature,
  DimensionTypesEnum.Volume,
  DimensionTypesEnum.Length,
];

export const WBDimensionTypes: DimensionTypesEnum[] = [
  DimensionTypesEnum.Flow,
  DimensionTypesEnum.Volume,
];
