import { BoundarySignalDto } from './boundary-signal.dto';

export class BoundaryConfigurationDto {
  hierarchyElementId: string;
  signals: BoundarySignalDto[];

  constructor(init: Readonly<BoundaryConfigurationDto>) {
    Object.assign(this, init);
  }
}
