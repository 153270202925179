import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { WaterBalanceTypesEnum } from 'src/app/common-modules/dependencies/water-balance/water-balance-types';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WaterBalanceTypesService } from '../../../../water-loss/features/water-balance/services/water-balance-types.service';
import { BaseFilterItemComponent } from '../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-water-balance-types-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-types-filter-item.component.html',
  styleUrls: ['./water-balance-types-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => WaterBalanceTypesFilterItemComponent),
    },
  ],
})
export class WaterBalanceTypesFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy
{
  @Input() wbTypeName: string;
  @Input() filterText: string;
  @Input() set selectedId(value: any) {
    this.selectedIds = this.ensureArray(value);
  }
  @Input() set defaultSelectedId(value: any) {
    this.defaultSelectedIds = this.ensureArray(value);
  }

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  defaultSelectedIds: WaterBalanceTypesEnum[] = [];
  mode = 'single';
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;

  constructor(
    private waterBalanceTypesService: WaterBalanceTypesService,
    private localizationService: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this.waterBalanceTypesService.getWaterBalanceTypes().pipe(
      switchMap((items) => {
        return this.localizationService.get('common.water-balance-types').pipe(
          map((ts) => {
            return items.map((item) => new FilterItemSelectOption(item.value, ts[item.label]));
          })
        );
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return true;
  }

  ensureArray(value: any): any[] {
    if (typeof value !== 'undefined' && value !== null) {
      return Array.isArray(value) ? [...value] : [value];
    } else {
      return [];
    }
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.ArraySingleValue;
  }
}
