import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { IInterfacesLoggingSelector } from 'src/app/common-modules/dependencies/logs/models/interfaces-logging-selector';
import { GenericCardSettings } from 'src/app/common-modules/shared-component/generic-card/generic-card-settings';
import { HistoricalBarSettings } from 'src/app/common-modules/shared-component/historical-bars/models/historical-bar-settings';
import { ListItem } from 'src/app/common-modules/shared-component/selection-list/models/list-item';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from 'src/app/common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { LogsInterfacesService } from '../../../../../common-modules/shared/services/logs-interfaces.service';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-selector.component.html',
  styleUrls: ['./logs-interfaces-selector.component.scss'],
})
export class LogsInterfacesSelectorComponent implements OnInit {
  @Output() selectedChange = new EventEmitter<IInterfacesLoggingSelector>();

  interfaceTypeNames: { [id: number]: string };
  items: ListItem[];

  get componentName(): string {
    return 'LogsInterfacesSelectorComponent';
  }

  settings: DragListSettings;
  cardSettings: GenericCardSettings<ListItem>;
  queryParams: Map<string, any> = new Map<string, any>();
  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;

  private _pagesize = 50;
  private _tsLocation = 'logs.wlm-logs-interfaces-selector';
  private _selectedFieldName = 'id';
  private _interfaces: IInterfacesLoggingSelector[] = [];

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _logsInterfacesService: LogsInterfacesService,
    private readonly _localization: LocalizationHelperService,
    private readonly _dateHelper: DateHelperService
  ) {}

  ngOnInit(): void {
    this.loadSelectorItems();
  }

  loadSelectorItems(): void {
    this.setLoading(true);
    this.interfaceTypeNames = {};
    this._interfaces = [];

    this._logsInterfacesService
      .getSelectorItems()
      .pipe(
        finalize(() => this.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.setSelectorItems(result);

        this.prepareListsSettings();
      });
  }

  onSelectInterface(item: ListItem) {
    if (!item) {
      return;
    }

    const interfaceTypeName = this.interfaceTypeNames[item.id];
    const selectedInterface = this._interfaces.find(
      (interf) => interf.interfaceTypeName === interfaceTypeName
    );

    if (selectedInterface) {
      this.selectedChange.emit(selectedInterface);
    }
  }

  private setSelectorItems(interfaces: IInterfacesLoggingSelector[]) {
    this._interfaces = interfaces;
    this.items = interfaces.map((item, index) => {
      this.interfaceTypeNames[index + 1] = item.interfaceTypeName;

      const historicalSettings = new HistoricalBarSettings(
        `${this.T_SCOPE}.last-executions`,
        item.lastExecutions
      );

      return new ListItem({
        id: index + 1,
        labelKey: `${this.T_SCOPE}.${item.interfaceTypeName}`,
        historicalBarSettings: historicalSettings,
        lastExecutionDate: this._dateHelper.fromApiFormat(item.lastExecutionDate),
      });
    });
  }

  private prepareListsSettings() {
    this._localization
      .get(`${this._tsLocation}`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        this.settings = new SelectDragListSettings({
          isLocal: true,
          pageSize: this._pagesize,
          disableFilter: true,
          isReadOnly: false,
          scrollId: this.componentName,
          selectedFieldName: this._selectedFieldName,
        });

        this.cardSettings = new GenericCardSettings<ListItem>({
          title1Fn: (model) => ts[model.labelKey.replace(`${this._tsLocation}.`, '')],
          subtitleFn: (model) =>
            model.lastExecutionDate ? this._localization.formatDate(model.lastExecutionDate) : '',
          leftIconNameFn: (model) => model.iconName,
        });
      });
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }
}
