import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlmResizeObserverService } from '../../shared/services/resize-observer.service';
import { SpinnerWrapperSettings } from '../models/spinner-wrapper-settings';

const COMPONENT_SELECTOR = 'wlm-spinner-wrapper';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './spinner-wrapper.component.html',
  styleUrls: ['./spinner-wrapper.component.scss'],
})
export class SpinnerWrapperComponent implements OnInit {
  @Input() showSpinner = false;
  @Input() settings: SpinnerWrapperSettings;

  // Enable relative positioning, when the observed content cannot display an absolute spinner until content is projected.
  @Input() enableRelative = false;
  @Input() expandVertical = true;
  @Input() useOverflow = false;
  @Input() containerClass: string;

  /**
   * Change the spinner mode to absolute when the container has any size.
   * This is so the spinner can center itself when there is content,
   * while showing at an acceptable position when there is no height.
   * NOTE: This is disabled by default.
   */
  @ViewChild('content') public set content(element: ElementRef) {
    if (this.enableRelative && element && !this.contentLoaded) {
      const subs$ = this._resizeObserverService
        .observe({
          el: element.nativeElement,
          calculateHeight: true,
        })
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (data.height !== 0) {
            this.spinnerMode = 'absolute';
            this.contentLoaded = true;
            subs$.unsubscribe();
          }
        });
    }
  }

  // Spinner is shown as relative when no content is projected.
  spinnerMode: 'relative' | 'absolute';
  contentLoaded = false;

  constructor(private readonly _resizeObserverService: WlmResizeObserverService) {}

  ngOnInit(): void {
    // Makes sense to do this on init instead of on property setter because enableRelative
    // only affects to the spinnerMode initial value.
    this.spinnerMode = this.enableRelative ? 'relative' : 'absolute';
  }
}
