import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { CalendarModeEnum } from 'src/app/common-modules/dependencies/alarms/calendar-mode.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { DefaultFilterItemSelectComponent } from '../../../core/default/default-filter-item-select/default-filter-item-select.component';

const COMPONENT_SELECTOR = 'wlm-custom-calendar-filter-item-select';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './custom-calendar-filter-item-select.component.html',
  styleUrls: ['./custom-calendar-filter-item-select.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' },
    },
  ],
})
export class CustomCalendarFilterItemSelectComponent
  extends DefaultFilterItemSelectComponent
  implements OnInit, OnChanges
{
  @Input() titleKey: string;
  @Input() modeField: string;
  @Input() labelsScope: string;
  @Input() idsParams: { [id: string]: { [key: string]: CalendarModeEnum } };

  calendarModes = CalendarModeEnum;
  readonly calendarModeHash;

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;

  constructor(settingsService: SettingsService) {
    super(settingsService);
    super.initHashedHelper({
      calendarModeHash: {},
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes && changes.options) {
      this.applySelectedParams();
    }
  }

  onToggleWithMode(event, option: FilterItemSelectOption, mode: CalendarModeEnum): void {
    const previousMode = this.hashedHelper.calendarModeHash[option.value];
    if (previousMode === mode) {
      // If selecting the same mode, unselect the option.
      this.hashedHelper.calendarModeHash[option.value] = null;
      this.onToggle(option);
    } else {
      option.params = {
        [this.modeField]: mode,
      };
      this.hashedHelper.calendarModeHash[option.value] = mode;
      if (!previousMode) {
        // If the mode was not selected before, select the option.
        this.onToggle(option);
      } else {
        // Change the mode of the selected option (cannot use onToggle).
        this.onUpdateOption(option);
      }
    }
  }

  /**
   * When options are selected, find the mode to select with and automatically set them.
   * Only set the params as selected elements when they are previously registered in idsParams.
   */
  private applySelectedParams(): void {
    // First step is to reset previous selection.
    super.resetSelection();

    const selectedIds = Object.keys(this.idsParams);
    if (!selectedIds.length) {
      return;
    }
    selectedIds.forEach((optionId) => {
      const option = this.options.find((o) => o.value === optionId);
      const params = this.idsParams[optionId];
      const mode = params[this.modeField];
      if (option && mode) {
        this.onSetWithMode(option, mode);
      }
    });
    // Copy so the ngFor is re-rendered.
    this.options = [...this.options];
    this.allOptions = [...this.options];
  }

  private onSetWithMode(option: FilterItemSelectOption, mode: CalendarModeEnum): void {
    this.onSet(option);
    this.hashedHelper.calendarModeHash[option.value] = mode;
  }
}
