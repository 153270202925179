<wlm-spinner-wrapper [showSpinner]="!isReady && elementId" class="spinner-inherit-size">
  <div class="active-alarms-grid-container">
    <div class="main-container" *ngIf="elementId">
      <wlm-alarms-tab-widget
        class="widget-position"
        [isNetworkElement]="isNetworkElement"
        [elementId]="elementId"
        (filter)="onTabFilter($event)"
        (ready)="onWidgetReady($event)"
      ></wlm-alarms-tab-widget>
      <div class="filter-buttons-container">
        <wlm-grid-buttons
          *ngIf="gridSettings"
          [(gridSettings)]="gridSettings"
          [gridFilters]="filters"
          [grid]="alarmsGrid"
          [responsive]="false"
        >
          <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
            <button
              wlmCollapsibleButton
              *hasPerm="['WLMAlarmsCrud', 'u']"
              [disabled]="disableAck"
              [tooltip]="T_SCOPE + '.ack-button-tooltip'"
              [showLabel]="isContainerCollapsed"
              (click)="onAckClicked($event, true)"
            >
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button
              wlmCollapsibleButton
              *hasPerm="['WLMAlarmsCrud', 'u']"
              [disabled]="disableUndoAck"
              [tooltip]="T_SCOPE + '.undoack-button-tooltip'"
              [showLabel]="isContainerCollapsed"
              (click)="onAckClicked($event, false)"
            >
              <mat-icon>thumb_down</mat-icon>
            </button>
          </ng-template>
        </wlm-grid-buttons>
      </div>
    </div>
    <wlm-generic-grid
      class="grid"
      #alarmsGrid
      *ngIf="elementId"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="filters"
      [additionalFilters]="gridFiltersMap"
      [removeSelection$]="removeSelection$"
      [(selectedItems)]="selectedAlarms"
      [showSelectedList]="true"
      [showSelectedItems]="false"
      (gridDataLoaded)="onGridDataLoaded()"
    ></wlm-generic-grid>
  </div>
</wlm-spinner-wrapper>
