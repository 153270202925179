import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicFormsModule } from 'src/app/common-modules/dynamic-forms/dynamic-forms.module';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { FiltersStoreService } from 'src/app/common-modules/dynamic-layout/state/filters/filters-store.service';
import { WidgetManagerStoreService } from 'src/app/common-modules/dynamic-layout/state/widget-manager/widget-manager.store-service';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { AlgorithmsConfigurationPageComponent } from './algorithms-configuration-page/algorithms-configuration-page.component';
import { NeConfigAttributeFormGroupComponent } from './ne-config-attribute-form-group/ne-config-attribute-form-group.component';
import { NeConfigAttributeFormComponent } from './ne-config-attribute-form/ne-config-attribute-form.component';
import { NeConfigDetailsPageComponent } from './ne-config-details-page/ne-config-details-page.component';
import { NeConfigFilterComponent } from './ne-config-filter/ne-config-filter.component';
import { NeConfigPageComponent } from './ne-config-page/ne-config-page.component';
import { NeConfigPlaceholderComponent } from './ne-config-placeholder/ne-config-placeholder.component';
import { NeConfigSignalsGroupComponent } from './ne-config-signals-group/ne-config-signals-group.component';
import { NeConfigurationBoundaryComponent } from './ne-configuration-boundary/ne-configuration-boundary.component';
import { NeConfigurationBoundaryService } from './ne-configuration-boundary/ne-configuration-boundary.service';
import { NeConfigurationElementsNetworkElementsComponent } from './ne-configuration-elements-network-elements/ne-configuration-elements-network-elements.component';
import { NeConfigurationEneService } from './ne-configuration-elements-network-elements/ne-configuration-elements-network-elements.service';
import { LargeUserAssignedCardComponent } from './ne-configuration-large-user/large-user-assigned-card/large-user-assigned-card.component';
import { NeConfigurationLargeUserComponent } from './ne-configuration-large-user/ne-configuration-large-user.component';
import { NeConfigurationLargeUserService } from './ne-configuration-large-user/ne-configuration-large-user.service';
import { NeConfigurationLarsSworpsHistoricalFormComponent } from './ne-configuration-lars-sworps-historical-form/ne-configuration-lars-sworps-historical-form.component';
import { LarsSworpsHistoricalValidationService } from './ne-configuration-lars-sworps-historical/lars-sworps-historical-validations.service';
import { LarsSworpsHistoricalService } from './ne-configuration-lars-sworps-historical/lars-sworps-historical.service';
import { NeConfigurationLarsSworpsHistoricalCreationPopupComponent } from './ne-configuration-lars-sworps-historical/ls-historical-creation-popup/ne-configuration-lars-sworps-historical-creation-popup.component';
import { NeConfigurationLarsSworpsHistoricalComponent } from './ne-configuration-lars-sworps-historical/ne-configuration-lars-sworps-historical.component';
import { NeConfigurationLarsSworpsSwitcherComponent } from './ne-configuration-lars-sworps-switcher/ne-configuration-lars-sworps-switcher.component';
import { LarsSworpsMessagesService } from './ne-configuration-lars-sworps/lars-sworps-messages.service';
import { NeConfigurationLarsSworpsComponent } from './ne-configuration-lars-sworps/ne-configuration-lars-sworps.component';
import { NeConfigurationLarsSworpsService } from './ne-configuration-lars-sworps/ne-configuration-lars-sworps.service';
import { NeConfigurationNoBoundaryComponent } from './ne-configuration-no-boundary/ne-configuration-no-boundary.component';
import { NeConfigurationNoBoundaryService } from './ne-configuration-no-boundary/no-configuration-no-boundary.service';
import { NeConfigurationPressureComponent } from './ne-configuration-pressure/ne-configuration-pressure.component';
import { NeConfigurationPressureService } from './ne-configuration-pressure/ne-configuration-pressure.service';
import { NeConfigurationSelectionComponent } from './ne-configuration-selection/ne-configuration-selection.component';
import { NeConfigurationSelectionService } from './ne-configuration-selection/ne-configuration-selection.service';
import { NeSignalSelectionListsComponent } from './ne-signal-selection-lists/ne-signal-selection-lists.component';
import { AttributeFormGroupRpWidgetComponent } from './rp-widgets/attribute-form-group-rp-widget/attribute-form-group-rp-widget.component';

import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { BaseFormLabelOverwriteService } from 'src/app/common-modules/dynamic-forms/services/base-form-label-overwrite.service';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { SmartMetersModule } from '../../smart-meters/smart-meters.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { GlobalValueLabelOverwriteService } from './global-value/global-value-label-overwrite.service';
import { NecGlobalValuesManagerService } from './global-value/global-values-manager.service';
import { NeConfigurationBoundaryHistoricalFormComponent } from './ne-configuration-boundary-historical-form/ne-configuration-boundary-historical-form.component';
import { NeConfigurationBoundaryHistoricalCreationPopupComponent } from './ne-configuration-boundary-historical/boundary-historical-creation-popup/ne-configuration-boundary-historical-creation-popup.component';
import { BoundaryHistoricalMessagesService } from './ne-configuration-boundary-historical/boundary-historical-messages.service';
import { BoundaryHistoricalValidationService } from './ne-configuration-boundary-historical/boundary-historical-validations.service';
import { BoundaryHistoricalService } from './ne-configuration-boundary-historical/boundary-historical.service';
import { NeConfigurationBoundaryHistoricalComponent } from './ne-configuration-boundary-historical/ne-configuration-boundary-historical.component';
import { NeConfigurationBoundaryNoBoundaryComponent } from './ne-configuration-boundary-no-boundary/ne-configuration-boundary-no-boundary.component';

import { NeConfigurationLargeUserHistoricalFormComponent } from './ne-configuration-large-user-historical-form/ne-configuration-large-user-historical-form.component';
import { NeConfigurationLargeUserHistoricalCreationPopupComponent } from './ne-configuration-large-user-historical/large-user-historical-creation-popup/ne-configuration-large-user-historical-creation-popup.component';
import { LargeUserHistoricalMessagesService } from './ne-configuration-large-user-historical/large-user-historical-messages.service';
import { LargeUserHistoricalValidationService } from './ne-configuration-large-user-historical/large-user-historical-validations.service';
import { LargeUserHistoricalService } from './ne-configuration-large-user-historical/large-user-historical.service';
import { NeConfigurationLargeUserHistoricalComponent } from './ne-configuration-large-user-historical/ne-configuration-large-user-historical.component';

import { AlgorithmsConfigAttributeFormWidgetComponent } from './widgets/algorithms-config-attribute-form-widget/algorithms-config-attribute-form-widget.component';
import { AttributeFormGroupService } from './widgets/ne-config-attribute-form-group-widget/attribute-form-group.service';
import { NeConfigAttributeFormGroupWidgetComponent } from './widgets/ne-config-attribute-form-group-widget/ne-config-attribute-form-group-widget.component';
import { NeConfigAttributeFormWidgetComponent } from './widgets/ne-config-attribute-form-widget/ne-config-attribute-form-widget.component';
import { NeConfigFilterWidgetComponent } from './widgets/ne-config-filter-widget/ne-config-filter-widget.component';
import { NeConfigSignalsGroupWidgetComponent } from './widgets/ne-config-signals-group-widget/ne-config-signals-group-widget.component';
import { NeConfigurationBoundaryWidgetComponent } from './widgets/ne-configuration-boundary-widget/ne-configuration-boundary-widget.component';
import { NeConfigurationElementsNetworkElementsWidgetComponent } from './widgets/ne-configuration-elements-network-elements-widget/ne-configuration-elements-network-elements-widget.component';
import { NeConfigurationLargeUserWidgetComponent } from './widgets/ne-configuration-large-user-widget/ne-configuration-large-user-widget.component';
import { NeConfigurationLarsSworpsWidgetComponent } from './widgets/ne-configuration-lars-sworps-widget/ne-configuration-lars-sworps-widget.component';
import { NeConfigurationPressureWidgetComponent } from './widgets/ne-configuration-pressure-widget/ne-configuration-pressure-widget.component';
import { NeConfigurationSelectionWidgetComponent } from './widgets/ne-configuration-selection-widget/ne-configuration-selection-widget.component';

@NgModule({
  declarations: [
    NeConfigFilterComponent,
    NeConfigPageComponent,
    NeConfigDetailsPageComponent,
    NeConfigurationSelectionComponent,
    NeConfigurationSelectionWidgetComponent,
    NeConfigFilterWidgetComponent,
    NeConfigurationBoundaryWidgetComponent,
    NeConfigurationBoundaryComponent,
    NeConfigurationLarsSworpsWidgetComponent,
    NeConfigurationLarsSworpsComponent,
    NeConfigurationLargeUserComponent,
    NeConfigurationLargeUserWidgetComponent,
    LargeUserAssignedCardComponent,
    NeConfigAttributeFormComponent,
    NeConfigAttributeFormGroupComponent,
    NeConfigAttributeFormGroupWidgetComponent,
    NeConfigAttributeFormWidgetComponent,
    NeConfigSignalsGroupComponent,
    NeConfigSignalsGroupWidgetComponent,
    NeConfigurationPressureWidgetComponent,
    NeConfigurationPressureComponent,
    NeConfigurationNoBoundaryComponent,
    NeConfigPlaceholderComponent,
    NeConfigurationLarsSworpsHistoricalComponent,
    NeConfigurationLarsSworpsHistoricalFormComponent,
    NeConfigurationLarsSworpsSwitcherComponent,
    NeSignalSelectionListsComponent,
    NeConfigurationLarsSworpsHistoricalCreationPopupComponent,
    AlgorithmsConfigurationPageComponent,
    AlgorithmsConfigAttributeFormWidgetComponent,
    NeConfigurationElementsNetworkElementsComponent,
    NeConfigurationElementsNetworkElementsWidgetComponent,
    AttributeFormGroupRpWidgetComponent,
    NeConfigurationBoundaryHistoricalComponent,
    NeConfigurationBoundaryHistoricalFormComponent,
    NeConfigurationBoundaryHistoricalCreationPopupComponent,
    NeConfigurationLargeUserHistoricalComponent,
    NeConfigurationLargeUserHistoricalFormComponent,
    NeConfigurationLargeUserHistoricalCreationPopupComponent,
    NeConfigurationBoundaryNoBoundaryComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    TranslateModule,
    WlmFiltersModule,
    WLMGridModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [FiltersStoreService, WidgetManagerStoreService],
    }),
    DynamicFormsModule.forFeature({
      providers: [
        { provide: BaseFormLabelOverwriteService, useExisting: GlobalValueLabelOverwriteService },
      ],
    }),
    WlmChartsModule,
    SmartMetersModule.forFeature({
      scopeSettings: new StateScopeSettings({
        scope: NECScopes.Main,
      }),
    }),
  ],
  providers: [
    NeConfigurationSelectionService,
    { provide: 'NeConfigurationSelectionService', useExisting: NeConfigurationSelectionService },
    ReduxStateService, // Must still provide this service here.
    FiltersStoreService,
    WidgetManagerStoreService,
    NeConfigurationBoundaryService,
    { provide: 'NeConfigurationBoundaryService', useExisting: NeConfigurationBoundaryService },
    NeConfigurationLarsSworpsService,
    { provide: 'NeConfigurationLarsSworpsService', useExisting: NeConfigurationLarsSworpsService },
    NeConfigurationLargeUserService,
    { provide: 'NeConfigurationLargeUserService', useExisting: NeConfigurationLargeUserService },

    NeConfigurationPressureService,
    { provide: 'NeConfigurationPressureService', useExisting: NeConfigurationPressureService },
    NeConfigurationNoBoundaryService,
    { provide: 'NeConfigurationNoBoundaryService', useExisting: NeConfigurationNoBoundaryService },
    LarsSworpsHistoricalService,
    { provide: 'LarsSworpsHistoricalService', useExisting: LarsSworpsHistoricalService },
    {
      provide: 'LarsSworpsHistoricalValidationService',
      useExisting: LarsSworpsHistoricalValidationService,
    },
    LarsSworpsHistoricalValidationService,
    LarsSworpsMessagesService,
    NeConfigurationEneService,
    { provide: 'NeConfigurationEneService', useExisting: NeConfigurationEneService },
    AttributeFormGroupService, // Refactor move
    BoundaryHistoricalMessagesService,
    { provide: 'BoundaryHistoricalService', useExisting: BoundaryHistoricalService },
    BoundaryHistoricalService,
    BoundaryHistoricalValidationService,
    {
      provide: 'BoundaryHistoricalValidationService',
      useExisting: BoundaryHistoricalValidationService,
    },
    LargeUserHistoricalMessagesService,
    { provide: 'LargeUserHistoricalService', useExisting: LargeUserHistoricalService },
    LargeUserHistoricalService,
    LargeUserHistoricalValidationService,
    {
      provide: 'LargeUserHistoricalValidationService',
      useExisting: LargeUserHistoricalValidationService,
    },
    NecGlobalValuesManagerService,
    GlobalValueLabelOverwriteService,
  ],
})
export class NeConfigurationModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    NeConfigurationModule.injector = injector;

    widgetRegistry.register([
      { name: 'NeConfigFilterWidgetComponent', widgetClass: NeConfigFilterWidgetComponent },
      {
        name: 'NeConfigurationSelectionWidgetComponent',
        widgetClass: NeConfigurationSelectionWidgetComponent,
      },
      {
        name: 'NeConfigAttributeFormGroupWidgetComponent',
        widgetClass: NeConfigAttributeFormGroupWidgetComponent,
      },
      {
        name: 'NeConfigSignalsGroupWidgetComponent',
        widgetClass: NeConfigSignalsGroupWidgetComponent,
      },
      {
        name: 'NeConfigurationBoundaryWidgetComponent',
        widgetClass: NeConfigurationBoundaryWidgetComponent,
      },
      {
        name: 'NeConfigurationLarsSworpsWidgetComponent',
        widgetClass: NeConfigurationLarsSworpsWidgetComponent,
      },
      {
        name: 'NeConfigurationLargeUserWidgetComponent',
        widgetClass: NeConfigurationLargeUserWidgetComponent,
      },
      {
        name: 'NeConfigurationPressureWidgetComponent',
        widgetClass: NeConfigurationPressureWidgetComponent,
      },
      {
        name: 'NeConfigAttributeFormWidgetComponent',
        widgetClass: NeConfigAttributeFormWidgetComponent,
      },
      {
        name: 'NeConfigurationElementsNetworkElementsWidgetComponent',
        widgetClass: NeConfigurationElementsNetworkElementsWidgetComponent,
      },
      {
        name: 'AttributeFormGroupRpWidgetComponent',
        widgetClass: AttributeFormGroupRpWidgetComponent,
      },
    ]);
  }
}
