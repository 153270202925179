import { Component, Input, ViewContainerRef, inject } from '@angular/core';
import { DynamicRenderizerComponentService } from '../services/dynamic-renderizer-component.service';
import { ComponentToRender } from './component-to-render';

@Component({
  selector: 'wlm-component-renderer',
  templateUrl: './component-renderer.component.html',
  styleUrls: ['./component-renderer.component.scss'],
  standalone: true,
})
export class ComponentRendererComponent {
  private _componentToRender: ComponentToRender<any>;
  get componentToRender(): ComponentToRender<any> {
    return this._componentToRender;
  }
  @Input() set componentToRender(value: ComponentToRender<any>) {
    this._componentToRender = value;

    if (this.componentToRender) {
      this._dynamicRenderizerService.injectComponentIntoViewContainer(
        this.componentToRender.component,
        this._viewContainerRef,
        this.componentToRender.propertySetter,
        this.componentToRender.featureInjector
      );
    }
  }

  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _dynamicRenderizerService = inject(DynamicRenderizerComponentService);
}
