<div class="filter-container">
  <wlm-base-filter-container
    *ngIf="adaptersReady"
    class="filters"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="processFilterChanges($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <wlm-base-filter
      (filterText)="onFilterText($event)"
      [settings]="networkElementsGroupSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-network-elements-filter-item
        [filterText]="filterText"
        [fieldName]="filterConfiguration.neSettings.elementTypeFieldName"
        [defaultSelectedElements]="filterConfiguration.defaultNETypeIds"
        [selectedIds]="filterConfiguration.neSettings?.selectedElementIds"
        [settings]="networkFilterItemSettings"
        [filterListElements]="filterListElements"
        mode="single"
      ></wlm-network-elements-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
</div>
