import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from '../../../shared/filters/component-filters/filter';

export class HierarchyFamilyFilter implements IFilter {
  public familyId: string;
  public elementFieldName: string;

  constructor(fieldName: string, familyId: string) {
    this.familyId = familyId;
    this.elementFieldName = fieldName;
  }
  getFilters(): CompositeFilterDescriptor {
    const mainCompositeFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
    let currentFilter: FilterDescriptor;
    currentFilter = {
      field: this.elementFieldName,
      operator: 'eq',
      value: this.familyId,
    };
    mainCompositeFilter.filters = [currentFilter];

    return mainCompositeFilter;
  }

  getSelectedFilters() {
    return new HierarchyFamilyFilter(this.elementFieldName, this.familyId);
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.elementFieldName, this.familyId]]);
  }
}
