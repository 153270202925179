export class DateRangeAndMode {
  periodIsFixed: boolean;
  periodDays: number | null;
  periodStartDate: Date | null;
  periodEndDate: Date | null;

  constructor(init?: DateRangeAndMode) {
    Object.assign(this, init);
  }
}
