import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogService } from '../wlm-log/log.service';
import { BaseInputDirective } from './base-input.directive';

/**
 * Directive that limit the max value of a numeric input.
 */
@Directive({
  selector: 'input[wlmMaxNumberDigits]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaxNumberDigitsDirective),
      multi: true,
    },
  ],
})
export class MaxNumberDigitsDirective extends BaseInputDirective {
  @Input() public set wlmMaxNumberDigits(value: string) {
    if (!value || isNaN(+value)) {
      this.logService.error({
        msg: 'The directive "wlmMaxNumberDigits" must receive a number.',
      });
    }
    this.maxNumber = +value;
  }
  private maxNumber: number;
  private lastValidValue: number;

  constructor(elRef: ElementRef, private logService: LogService) {
    super(elRef);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    this.onEvent(event?.data);
  }

  protected viewToForm(data: any): any {
    const value = +this.inputValue;
    if (isNaN(value)) {
      this.logService.error({
        msg: 'The directive "wlmMaxNumberDigits" can only receive numeric values.',
      });
      return null;
    }

    let newValue;
    if (this.isValid(value)) {
      newValue = value;
      this.lastValidValue = value;
    } else {
      newValue = this.lastValidValue;
    }

    this.inputValue = newValue;
    return this.inputValue;
  }

  protected formToView(data: any) {}

  private isValid(value: number): boolean {
    return value.toString().length <= this.maxNumber;
  }
}
