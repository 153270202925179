import { Component } from '@angular/core';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';

const COMPONENT_SELECTOR = 'wlm-ne-config-placeholder';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-placeholder.component.html',
  styleUrls: ['./ne-config-placeholder.component.scss'],
})
export class NeConfigPlaceholderComponent {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  titleKey = `${this.T_SCOPE}.must-select-ne-title`;
  subtitleKey = `${this.T_SCOPE}.must-select-ne-subtitle`;

  constructor() {}
}
