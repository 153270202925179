import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BehaviorSubject, Observable, Subscription, combineLatest, map } from 'rxjs';
import { IElementSize } from '../../shared/model/element-size';
import {
  WlmResizeObserverConfig,
  WlmResizeObserverService,
} from '../../shared/services/resize-observer.service';
import { ResponsiveManagerService } from '../../shared/services/responsive-manager.service';
import { SizeCalculatorService } from '../../shared/services/size-calculator.service';

const COMPONENT_SELECTOR = 'wlm-responsive-buttons-container';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './responsive-buttons-container.component.html',
  styleUrls: ['./responsive-buttons-container.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule],
})
export class ResponsiveButtonsContainerComponent implements OnInit {
  @Input() contentsTemplate: TemplateRef<any>;
  @Input() responsive = true;

  private _containerInstanceId: string;
  get containerInstanceId(): string {
    return this._containerInstanceId;
  }
  // It is mandatory to set an id that is used by observables service
  // to identify the container component and listen to its size changed event.
  // The id should be unique per instance of component.
  @Input() set containerInstanceId(value: string) {
    this._containerInstanceId = value;
    this.listenShowHamburguer();
  }

  private _fixedSizeElements: ElementRef<HTMLElement>[] = [];
  get fixedSizeElements(): ElementRef<HTMLElement>[] {
    return this._fixedSizeElements;
  }
  @Input() set fixedSizeElements(value: ElementRef<HTMLElement>[]) {
    this._fixedSizeElements = value;
    this.listenShowHamburguer();
  }

  @ViewChild('container') set setContainer(container: ElementRef<any>) {
    this.listenButtonContainerSizeChanges(container);
  }

  showHamburguer = false;
  buttonsRequiredSize$ = new BehaviorSubject<IElementSize>(null);
  isButtonsRequiredSizeCalculated = false;
  private _buttonsRequiredSizeSubs: Subscription;
  private _mainResizeSubs: Subscription;
  // Add a bit of margin to the widths subtraction to have smoother transitions.
  private readonly _hamburguerAverageMargin = 16; // px

  constructor(
    private readonly _responsiveManagerService: ResponsiveManagerService,
    private readonly _resizeObserver: WlmResizeObserverService,
    private readonly _destroyRef: DestroyRef,
    private readonly _sizeCalculatorService: SizeCalculatorService,
    private _element: ElementRef<any>
  ) {}

  ngOnInit(): void {
    this.hideHostUntilCalculated();
  }

  private listenButtonContainerSizeChanges(buttonsContainer: ElementRef<any>): void {
    if (buttonsContainer) {
      this._buttonsRequiredSizeSubs?.unsubscribe();
      this._buttonsRequiredSizeSubs = this.buildWidthChange$(
        buttonsContainer.nativeElement
      ).subscribe((data: IElementSize) => {
        if (data.width) {
          this.buttonsRequiredSize$.next(data);
          this.isButtonsRequiredSizeCalculated = true;
          this.displayHost();
        }
      });
    }
  }

  private listenShowHamburguer(): void {
    if (this.containerInstanceId && this.fixedSizeElements) {
      const containerSize$ = this.getContainerSize$();
      const fixedSizes$ = this.fixedSizeElements.map((ref) =>
        this.buildWidthChange$(ref.nativeElement)
      );

      const availableSize$ = this._sizeCalculatorService.listenCalculateRemainingSize$({
        containerSize$,
        fixedSizes$,
        dimensionToCalculate: 'width',
        debugName: COMPONENT_SELECTOR,
      });

      this._mainResizeSubs?.unsubscribe();
      this._mainResizeSubs = combineLatest([this.buttonsRequiredSize$, availableSize$])
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe(([buttonsRequiredSize, availableSize]) => {
          this.calculateShowHamburguer(buttonsRequiredSize, availableSize);
        });
    }
  }

  private getContainerSize$(): Observable<IElementSize> {
    return this._responsiveManagerService.listenSizeChanged$(this.containerInstanceId);
  }

  private calculateShowHamburguer(
    buttonsRequiredSize: IElementSize,
    availableSize: IElementSize
  ): void {
    const buttonsRequiredWidth = buttonsRequiredSize?.width ?? 0;
    const availableWidth = availableSize?.width ?? 0;

    this.showHamburguer = availableWidth - this._hamburguerAverageMargin < buttonsRequiredWidth;
  }

  private hideHostUntilCalculated(): void {
    this._element.nativeElement.classList.add('hide-until-calculated');
  }

  private displayHost(): void {
    this._element.nativeElement.classList.remove('hide-until-calculated');
  }

  private buildWidthChange$ = (element: HTMLElement): Observable<IElementSize> =>
    this._resizeObserver
      .observe(
        new WlmResizeObserverConfig({
          el: element,
          calculateWidth: true,
        })
      )
      .pipe(
        map(
          (result) =>
            ({
              width: result.width,
              height: result.height,
            } as IElementSize)
        )
      );
}
