export class AdditionalHttpOpts {
  showSpinner?: boolean;
  mapRequestDates? = false;
  mapResponseDates? = false;
  requestDatesNoUtc?: string[];
  responseDatesKeepString?: string[];
  // Allow some level of validation for fieldNames that are passed as strings.
  // If a field is renamed from "currentDate" to "date", ie, and it was not updated here, a message will be shown.
  // Not that this does not make sense with optional fields.
  warnIfMissing?: string[];
  observe?: 'body' | 'response';
  responseType?: string;

  constructor(init: Partial<AdditionalHttpOpts>) {
    Object.assign(this, init);
  }
}
