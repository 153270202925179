import { Injectable } from '@angular/core';
import { BasicFilter } from './filters/component-filters/basic-filter';
import { DateHelperService } from './helpers/date-helper.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class PersistencyService {
  constructor(
    private _localStorageService: LocalStorageService,
    private _dateHelperService: DateHelperService
  ) {}

  getPersistedData(
    persistencyArea: string,
    key: string,
    defaultValue?: any,
    useLocalStorage?: boolean
  ): any {
    const persisted = this._localStorageService.getTyped(
      `${persistencyArea}-${key}`,
      defaultValue,
      useLocalStorage
    );
    return persisted;
  }

  getPersisted(
    persistencyArea: string,
    key: string,
    defaultValue?: any,
    forcedDefaultValue?: any,
    useLocalStorage?: boolean
  ) {
    useLocalStorage = useLocalStorage === undefined ? true : useLocalStorage;
    if (forcedDefaultValue) {
      return forcedDefaultValue;
    } else {
      return this.getPersistedData(persistencyArea, key, defaultValue, useLocalStorage);
    }
  }

  persist(persistencyArea: string, key: string, value: any, useLocalStorage?: boolean): any {
    // delete key if value is null or undefined
    if (value == null) {
      return this.removePersist(persistencyArea, key);
    }

    return this._localStorageService.addOrUpdate(
      `${persistencyArea}-${key}`,
      value,
      useLocalStorage
    );
  }

  removePersist(persistencyArea: string, key: string, useLocalStorage?: boolean) {
    return this._localStorageService.remove(`${persistencyArea}-${key}`, useLocalStorage);
  }

  loadPersistedDate(
    persistencyArea: string,
    dateFieldName: string,
    defaultDate: Date,
    inclusiveDatePersisted: boolean = false,
    truncateDate: boolean = false
  ): Date {
    const defaultDateBasicFilter = new BasicFilter(dateFieldName, defaultDate);

    const key = dateFieldName;
    const persistedValue = (
      this.getPersisted(persistencyArea, key, defaultDateBasicFilter, null, false) as BasicFilter
    ).value;

    const persistedDate = truncateDate
      ? this._dateHelperService.truncateDate(new Date(persistedValue))
      : new Date(persistedValue);

    return inclusiveDatePersisted
      ? this._dateHelperService.addDays(persistedDate, -1)
      : persistedValue;
  }
}
