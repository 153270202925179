<mat-chip-grid #chipList>
  <mat-chip-row
    *ngFor="let item of items; let i = index"
    [removable]="removable"
    [class.invalid-chip]="!isValidChip(item)"
    (removed)="remove(i)"
  >
    {{ item }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip-row>
  <input
    matInput
    [formControl]="itemControl"
    [formlyAttributes]="field"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [matChipInputAddOnBlur]="addOnBlur"
    (matChipInputTokenEnd)="add($event)"
    (blur)="onBlur()"
  />
</mat-chip-grid>
