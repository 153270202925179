<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.buttons.open-interfaces-editor' | translate | lowercase"
        onclick="this.blur()"
        (click)="openEditInterfacesPopup()"
      >
        <wlm-icon [icon]="'edit'"></wlm-icon>
      </button>
      <button
        *ngIf="settings?.showResetIcon"
        mat-icon-button
        [matTooltip]="settings?.labelReset | translate | lowercase"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-selector-dynamic-layout [settings]="settings"></wlm-selector-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
