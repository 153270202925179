export class GChartAxisTick {
  show = true;
  interval?: number | 'auto';
  lenght?: number;
  inside?: boolean;
  alignWithLabel?: boolean;

  public constructor(init?: Readonly<GChartAxisTick>) {
    Object.assign(this, init);
  }
}
