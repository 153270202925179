import { Injectable } from '@angular/core';
import { BaseDatabase } from '../base.database';
import { IHttpCacheResponse } from './http-cache-response';

const TABLE_INDEX_DEFINITION = 'url, creationDate, expirationDate';

@Injectable({ providedIn: 'root' })
export class HttpCacheDatabase extends BaseDatabase<IHttpCacheResponse, string> {
  constructor() {
    super('HttpCacheDatabase', TABLE_INDEX_DEFINITION, 2);
  }
}
