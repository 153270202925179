import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { instanceToInstance, plainToClass, plainToInstance } from 'class-transformer';
import { Observable, ReplaySubject, combineLatest, forkJoin, of } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutWidgetSelection } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-widget-selection';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetStateAction,
  SetValueAction,
} from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import {
  DataVisualizationManageTemplatePopupDimensions,
  DataVisualizationSaveConfigPopupDimensions,
} from 'src/app/common-modules/shared/constants/dimensions.constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { ChartWorkspaceTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { ChartConfigurationService } from '../../../shared/charts/chart-configuration.service';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { AlgorithmFiltrableItemService } from '../../../shared/model/filtrable-items/services/algorithm-filtrable-item.service';
import { SignalFiltrableItemService } from '../../../shared/model/filtrable-items/services/signal-filtrable-item.service';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { FiltrableItemMapperService } from '../../../shared/services/filtrable-item-mapper.service';
import { WorkspacesHelperService } from '../../../shared/services/workspaces-helper.service';
import { DataVisualizationCartSelectedWorkspace } from '../../models/data-visualization-cart-selected-workspace';
import { DataVisualizationManageWorkspaceResult } from '../../models/data-visualization-manage-workspace-result';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';
import { DataVisualizationWorkspaceNavigation } from '../../models/data-visualization-workspace-navigation';
import { DV_ALGORITHM_VALUES_COMPONENT_INSTANCE } from '../../widgets/data-visualization-algorithm-values-widget/data-visualization-algorithm-values-widget.component';
import { DV_ALGORITHMS_SELECTOR_COMPONENT_INSTANCE } from '../../widgets/data-visualization-algorithms-selector-widget/data-visualization-algorithms-selector-widget.component';
import { DV_CART_COMPONENT_INSTANCE } from '../../widgets/data-visualization-cart-widget/data-visualization-cart-widget.component';
import { DV_CHART_COMPONENT_INSTANCE } from '../../widgets/data-visualization-chart-widget/data-visualization-chart-widget.component';
import { DV_POINT_VALUES_COMPONENT_INSTANCE } from '../../widgets/data-visualization-point-values-widget/data-visualization-point-values-widget.component';
import { DV_POINTS_SELECTOR_COMPONENT_INSTANCE } from '../../widgets/data-visualization-points-selector-widget/data-visualization-points-selector-widget.component';
import { DataVisualizationManageWorkspacePopupComponent } from '../data-visualization-manage-workspace-popup/data-visualization-manage-workspace-popup.component';
import { DataVisualizationSaveConfigPopupComponent } from '../data-visualization-save-config-popup/data-visualization-save-config-popup.component';

const COMPONENT_SELECTOR = 'wlm-data-visualization-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-page.component.html',
  styleUrls: ['./data-visualization-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class DataVisualizationPageComponent
  extends BasePageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  isSuperUser: boolean;

  private _workspace: WorkspaceDto;
  public get workspace(): WorkspaceDto {
    return this._workspace;
  }
  public set workspace(value: WorkspaceDto) {
    this._workspace = value;
    this.persist(this.workspacePersistencyKey, value, true);
  }

  private readonly _pageSettingsKeys = {
    layoutKey: 'LayoutMain',
    layoutArea: 'DataVisualization',
    widgetPage: 'dataVisualizationPage',
    widgetModule: 'dataVisualizationModule',
  };

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);

  // Default instance
  private readonly _standardInstanceKeys = {
    [StateAreas.Generic]: 'shared',
  };

  private readonly _pointsSelectorWidgetName = 'DataVisualizationPointsSelectorWidgetComponent';
  private readonly _algorithmsSelectorWidgetName =
    'DataVisualizationAlgorithmsSelectorWidgetComponent';
  private readonly _cartWidgetName = 'DataVisualizationCartWidgetComponent';
  private readonly _chartWidgetName = 'DataVisualizationChartWidgetComponent';
  private readonly _algorithmValuesWidgetName = 'DataVisualizationAlgorithmValuesWidgetComponent';
  private readonly _pointValuesWidgetName = 'DataVisualizationPointValuesWidgetComponent';

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._pageSettingsKeys.widgetModule,
    page: this._pageSettingsKeys.widgetPage,
    scopeInstanceKeys: this._standardInstanceKeys,
  });

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  settings: DynamicLayoutSettings;

  chartConfiguration: ChartConfiguration;
  cartFiltrableItems: IFiltrableItemDto[];

  constructor(
    private _authenticationService: AuthenticationService,
    private _authorizeService: AuthorizeService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _dialogService: DialogService,
    private _workspacesHelperService: WorkspacesHelperService,
    private _filtrableItemMapper: FiltrableItemMapperService,
    private readonly _chartConfigurationService: ChartConfigurationService,
    private readonly _algorithmFiltrableService: AlgorithmFiltrableItemService,
    private readonly _signalFiltrableService: SignalFiltrableItemService,
    private route: ActivatedRoute,
    private readonly _cd: ChangeDetectorRef
  ) {
    super();

    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.pageId = this._pageSettingsKeys.widgetPage;
    const persisted = this.getPersisted(this.workspacePersistencyKey);
    this.workspace = persisted;

    const isSuperUser$ = this._authorizeService.isSuperUser().pipe(untilDestroyed(this));
    const titles$ = this.buildTitles().pipe(untilDestroyed(this));

    combineLatest([isSuperUser$, titles$]).subscribe({
      next: ([isSuperUser, titles]) => {
        this.isSuperUser = isSuperUser;

        this.buildPage(titles);
      },
    });

    this.listenState(DataVisualizationStateFields.chartConfigurationUpdate).subscribe(
      (chartConfig: ChartConfiguration) => (this.chartConfiguration = chartConfig)
    );

    this.listenState(DataVisualizationStateFields.cartSelectionUpdate).subscribe(
      (cartFiltrableItems: IFiltrableItemDto[]) => (this.cartFiltrableItems = cartFiltrableItems)
    );

    this.listenState(DataVisualizationStateFields.removedWorkspace).subscribe((value) => {
      if (value && this.workspace?.workspaceId === value) {
        const workspaceUpdated = instanceToInstance(this.workspace);
        workspaceUpdated.workspaceId = 0;

        this.workspace = workspaceUpdated;
      }
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      const queryParams = this.getQueryParams(params) as DataVisualizationWorkspaceNavigation;
      if (queryParams && Object.keys(queryParams)?.length) {
        this.workspace = queryParams.workspace?.workspace;

        const algorithmFiltrableItems = queryParams.filtrableItems
          .filter((x) => x.filtrableType.type == FiltrableItemTypeEnum.Algorithm)
          .map((x) => plainToClass(AlgorithmFiltrableItemDto, x));

        const signalFiltrableItem = queryParams.filtrableItems
          .filter((x) => x.filtrableType.type == FiltrableItemTypeEnum.Signal)
          .map((x) => plainToClass(SignalFiltrableItemDto, x));

        const filtrableItems: IFiltrableItemDto[] = [
          ...algorithmFiltrableItems,
          ...signalFiltrableItem,
        ];

        this._chartConfigurationService
          .getChartConfigurationFromFiltrableItems(filtrableItems)
          .subscribe((chartConfiguration) => {
            this.chartConfiguration = chartConfiguration;
            this.sendChartConfiguration(chartConfiguration);
            this.sendCartItems(filtrableItems, plainToClass(DateRange, queryParams.dateRange));
            this._cd.detectChanges();
          });
      }
    });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'DataVisualizationCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  public get workspacePersistencyKey(): string {
    return `${this.persistencyArea}-workspace`;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  onLayoutInit(): void {}

  onNewConfiguration() {
    this.workspace = null;
    this.cartFiltrableItems = null;
    this.chartConfiguration = null;

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.clearAll,
        value: new Date().getTime(),
      })
    );
  }

  onSaveConfiguration(isEditMode: boolean): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      isEditMode: isEditMode,
      workspace: this.workspace,
      chartConfig: this.chartConfiguration,
      cartFiltrableItems: this.cartFiltrableItems,
    };

    dialogConfig.height = DataVisualizationSaveConfigPopupDimensions.Height;
    dialogConfig.width = DataVisualizationSaveConfigPopupDimensions.Width;

    const popupRef = this._dialogService.openComponent(
      DataVisualizationSaveConfigPopupComponent,
      dialogConfig
    );

    popupRef?.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.workspace = result;
    });
  }

  onManageTemplatesAndWorkspaces(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      workspace: this.workspace,
      chartConfig: this.chartConfiguration,
      cartFiltrableItems: this.cartFiltrableItems,
    };

    dialogConfig.height = DataVisualizationManageTemplatePopupDimensions.Height;
    dialogConfig.width = DataVisualizationManageTemplatePopupDimensions.Width;

    const popupRef = this._dialogService.openComponent(
      DataVisualizationManageWorkspacePopupComponent,
      dialogConfig
    );

    popupRef?.afterClosed().subscribe((result: DataVisualizationManageWorkspaceResult) => {
      if (!result) {
        return;
      }

      const dateRange = this._workspacesHelperService.getConfiguredDateRange(result.workspace);

      let loadProcess$: Observable<boolean> = null;

      if (result.workspace.workspaceTypeId == ChartWorkspaceTypeEnum.Workspace) {
        loadProcess$ = this.processWorkspaceLoad(result, dateRange);
      } else if (result.workspace.workspaceTypeId == ChartWorkspaceTypeEnum.Template) {
        loadProcess$ = this.processTemplateLoad(result, dateRange);
      }

      loadProcess$?.subscribe((loaded) => {
        if (loaded) {
          this.workspace = result.workspace;
        }
      });
    });
  }

  onResetLayout(): void {
    this._dynamicLayoutService.resetAll();
  }

  isSaveButtonEnabled() {
    return (
      this.workspace?.workspaceId &&
      this.cartFiltrableItems?.length &&
      this.chartConfiguration &&
      (this.isSuperUser || this.workspace.userCode === this._authenticationService.userCode)
    );
  }

  onActiveWidgetsChange(widgets: DynamicLayoutWidgetSelection[]): void {
    // Do something here
  }

  private openElementsPopups(
    entityTypesAlgorithms: Map<number, IAlgorithmDto[]>,
    filtrableItems: AlgorithmFiltrableItemDto[]
  ): Observable<AlgorithmFiltrableItemDto[]> {
    const entityTypes = Array.from(entityTypesAlgorithms.keys());
    if (!entityTypes.length) {
      return of(filtrableItems);
    }

    const nextEntityType = entityTypes[0];
    const algorithms = entityTypesAlgorithms.get(nextEntityType);

    const popupRef = this._workspacesHelperService.getAlgorithmElementsSelectionPopup(
      algorithms[0]
    );

    return popupRef.afterClosed().pipe(
      switchMap((result) => {
        entityTypesAlgorithms.delete(nextEntityType);

        if (!result) {
          return this.openElementsPopups(entityTypesAlgorithms, filtrableItems);
        }

        const { elements } = result;

        if (!elements?.length) {
          return this.openElementsPopups(entityTypesAlgorithms, filtrableItems);
        }

        const algorithmsFiltrableItems$ = [];
        algorithms.forEach((algorithm) => {
          const algorithmFiltrableItems$: Observable<AlgorithmFiltrableItemDto>[] = elements.map(
            (e) => this._filtrableItemMapper.getAlgorithmFiltrableItemDto(algorithm, e)
          );

          algorithmsFiltrableItems$.push(...algorithmFiltrableItems$);
        });

        return forkJoin(algorithmsFiltrableItems$).pipe(
          take(1),
          switchMap((algorithmFiltrableItems) => {
            filtrableItems.push(...algorithmFiltrableItems);
            return this.openElementsPopups(entityTypesAlgorithms, filtrableItems);
          })
        );
      })
    );
  }

  private setChartConfigurationDate(chartConfiguration: ChartConfiguration, dateRange: DateRange) {
    chartConfiguration.seriesConfiguration.forEach((serieConfiguration) => {
      const serieType = serieConfiguration.serieDefinition.filtrableType.type;

      if (serieType === FiltrableItemTypeEnum.Algorithm) {
        this._algorithmFiltrableService.setDateRange(
          serieConfiguration.serieDefinition as AlgorithmFiltrableItemDto,
          dateRange.start,
          dateRange.end
        );
      } else if (serieType === FiltrableItemTypeEnum.Signal) {
        this._signalFiltrableService.setDateRange(
          serieConfiguration.serieDefinition as SignalFiltrableItemDto,
          dateRange.start,
          dateRange.end
        );
      }
    });
  }

  private buildPage(titles: Map<string, string>) {
    this.settings = new DynamicLayoutSettings({
      layoutKey: this._pageSettingsKeys.layoutKey,
      layoutArea: this._pageSettingsKeys.layoutArea,
      widgetPage: this._pageSettingsKeys.widgetPage,
      widgetModule: this._pageSettingsKeys.widgetModule,
      currentUser: this._authenticationService.userCode,
      ignoreParamsOnPersistencyCheck: true,
      structure: [],
      defaultStructure: [
        {
          type: LayoutNodeTypes.Row,
          content: [
            {
              type: LayoutNodeTypes.Column,
              height: 100,
              width: 20,
              content: [
                {
                  type: LayoutNodeTypes.Stack,
                  content: [
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_ALGORITHMS_SELECTOR_COMPONENT_INSTANCE,
                    },
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_POINTS_SELECTOR_COMPONENT_INSTANCE,
                    },
                  ],
                },
              ],
            },
            {
              type: LayoutNodeTypes.Column,
              height: 100,
              width: 20,
              content: [
                {
                  type: LayoutNodeTypes.Stack,
                  content: [
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_CART_COMPONENT_INSTANCE,
                    },
                  ],
                },
              ],
            },
            {
              type: LayoutNodeTypes.Column,
              height: 100,
              width: 60,
              content: [
                {
                  type: LayoutNodeTypes.Stack,
                  content: [
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_CHART_COMPONENT_INSTANCE,
                    },
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_ALGORITHM_VALUES_COMPONENT_INSTANCE,
                    },
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_POINT_VALUES_COMPONENT_INSTANCE,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      items: [
        {
          componentName: this._algorithmsSelectorWidgetName,
          widgetInstanceKey: DV_ALGORITHMS_SELECTOR_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._algorithmsSelectorWidgetName),
          disableReorder: true,
        },
        {
          componentName: this._pointsSelectorWidgetName,
          widgetInstanceKey: DV_POINTS_SELECTOR_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._pointsSelectorWidgetName),
          disableReorder: true,
        },
        {
          componentName: this._cartWidgetName,
          widgetInstanceKey: DV_CART_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._cartWidgetName),
          disableReorder: true,
        },
        {
          componentName: this._chartWidgetName,
          widgetInstanceKey: DV_CHART_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._chartWidgetName),
        },
        {
          componentName: this._algorithmValuesWidgetName,
          widgetInstanceKey: DV_ALGORITHM_VALUES_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._algorithmValuesWidgetName),
        },
        {
          componentName: this._pointValuesWidgetName,
          widgetInstanceKey: DV_POINT_VALUES_COMPONENT_INSTANCE,
          scopeInstanceKeys: this._standardInstanceKeys,
          title: titles.get(this._pointValuesWidgetName),
        },
      ],
      minItemHeight: 70,
      minItemWidth: 300,
      showCloseIcon: false,
      disableReorder: false,
      showPopoutIcon: false,
      labelReset: 'common.reset-layout',
    });
    this._settings$.next(this.settings);

    this.onLayoutInit();
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${AppModules.DataVisualization}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._pointsSelectorWidgetName, ts['wlm-data-visualization-points-selector-widget']],
          [
            this._algorithmsSelectorWidgetName,
            ts['wlm-data-visualization-algorithms-selector-widget'],
          ],
          [this._cartWidgetName, ts['wlm-data-visualization-cart-widget']],
          [this._chartWidgetName, ts['wlm-data-visualization-chart-widget']],
          [this._pointValuesWidgetName, ts['wlm-data-visualization-point-values-widget']],
          [this._algorithmValuesWidgetName, ts['wlm-data-visualization-algorithm-values-widget']],
        ]);
        return titles;
      })
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private processTemplateLoad(
    loadResult: DataVisualizationManageWorkspaceResult,
    dateRange: DateRange
  ): Observable<boolean> {
    const entityTypeAlgorithms = this._workspacesHelperService.getAlgorithmsByEntityTypes(
      loadResult.chartConfiguration,
      loadResult.entityTypes
    );

    return this.openElementsPopups(entityTypeAlgorithms, []).pipe(
      switchMap((algorithmFiltrableItems) => {
        if (!algorithmFiltrableItems?.length) {
          this.showWarningMessage(`${this.T_SCOPE}.messages.no-elements-selected`);
          return of(false);
        }

        return this._workspacesHelperService
          .getIncludeSignals(algorithmFiltrableItems, loadResult.workspace)
          .pipe(
            concatMap((signalsItems) => {
              // Modify FiltrableItemsDates
              const filtrableItems: IFiltrableItemDto[] = [
                ...algorithmFiltrableItems,
                ...signalsItems,
              ];
              const cartFiltrableItems = this._workspacesHelperService.setFiltrableItemDate(
                filtrableItems,
                dateRange
              );

              // Get Default ChartConfiguration
              return this._chartConfigurationService
                .getChartConfigurationFromFiltrableItems(cartFiltrableItems)
                .pipe(
                  concatMap((chartConfiguration) => {
                    this.chartConfiguration = chartConfiguration;
                    this.sendChartConfiguration(chartConfiguration);

                    this.cartFiltrableItems = cartFiltrableItems;
                    this.sendCartItems(cartFiltrableItems, dateRange);

                    return of(true);
                  })
                );
            })
          );
      })
    );
  }

  private processWorkspaceLoad(
    loadResult: DataVisualizationManageWorkspaceResult,
    dateRange: DateRange
  ): Observable<boolean> {
    const chartConfiguration = plainToInstance(ChartConfiguration, loadResult.chartConfiguration);
    const cartItems = loadResult.cartFiltrableItems;

    this.setChartConfigurationDate(chartConfiguration, dateRange);
    const cartFiltrableItems = this._workspacesHelperService.setFiltrableItemDate(
      cartItems,
      dateRange
    );

    this.chartConfiguration = chartConfiguration;
    this.sendChartConfiguration(chartConfiguration);

    this.cartFiltrableItems = cartFiltrableItems;
    this.sendCartItems(cartFiltrableItems, dateRange);

    return of(true);
  }

  private sendChartConfiguration(chartConfiguration: ChartConfiguration) {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.plotChartConfiguration,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.plotChartConfiguration,
        value: chartConfiguration,
      })
    );
  }

  private sendCartItems(filtrableItems: IFiltrableItemDto[], dateRange: DateRange) {
    const algorithmFiltrableItems = filtrableItems.filter(
      (fi) => fi.filtrableType.type == FiltrableItemTypeEnum.Algorithm
    );

    const signalFiltrableItems = filtrableItems?.filter(
      (fi) => fi.filtrableType.type == FiltrableItemTypeEnum.Signal
    );

    const workspaceToLoad = new DataVisualizationCartSelectedWorkspace({
      dateRange,
      points: signalFiltrableItems as SignalFiltrableItemDto[],
      algorithms: algorithmFiltrableItems as AlgorithmFiltrableItemDto[],
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.selectedCartWorkspace,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.selectedCartWorkspace,
        value: workspaceToLoad,
      })
    );
  }

  private showWarningMessage(messageKey: string) {
    const dialogSettings = new WlmDialogSettings({
      translateKey: messageKey,
      icon: 'warning',
    });
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  ngOnDestroy() {
    this._state.dispatch(
      new ResetStateAction({ fieldNames: Object.keys(DataVisualizationStateFields) })
    );

    super.ngOnDestroy();
  }
}
