<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div class="popup-container" cdkDropListGroup>
    <div class="header-container">
      <h3>{{ signalsPopupSettings.title }} - {{ T_SCOPE + '.suffix-title' | translate }}</h3>

      <div>
        <button
          mat-icon-button
          class="close-top-button"
          (click)="close()"
          onclick="this.blur()"
          color="primary"
          [matTooltip]="'common.close' | translate | lowercase"
        >
          <mat-icon class="material-icons" color="primary">close</mat-icon>
        </button>
      </div>
    </div>

    <div class="signal-container">
      <div class="signal-column">
        <div class="signal-group-available">
          <div class="ne-component-title top-title-position">
            {{ T_SCOPE + '.available-title' | translate }}
          </div>
          <wlm-drag-list-virtual
            class="drag-list-virtual"
            [settings]="settings"
            [queryParams]="queryParams"
            [refreshList$]="refreshList$"
            [(excludeList)]="excludedSignals"
            (filterChange)="onfilterChange()"
            (listLoaded)="onAvailableSignalsLoaded()"
          >
            <ng-template let-rowDetail #card>
              <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
            </ng-template>
          </wlm-drag-list-virtual>
        </div>
      </div>
      <div class="signal-column assigned-column">
        <div class="signal-group">
          <div class="ne-component-title top-title-position">
            {{ T_SCOPE + '.configured-points' | translate }}
          </div>
          <wlm-drag-list-custom
            class="drag-list"
            [objectsToOrder]="configuredSignals"
            [settings]="settingsCustom"
            (objectsToOrderChanged)="onSignalsChanged($event)"
            (droppedElement)="onDroppedElement($event)"
          >
            <ng-template let-rowDetail #card>
              <wlm-drag-list-card
                class="card-width"
                [item]="rowDetail"
                [settings]="cardSettings"
              ></wlm-drag-list-card>
            </ng-template>
          </wlm-drag-list-custom>
        </div>
      </div>
    </div>

    <div class="button-container">
      <button mat-raised-button color="warn" class="cancel-button" (click)="close()">
        {{ 'common.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!configuredSignals.length"
        (click)="saveConfiguration()"
      >
        {{
          (signalsPopupSettings?.showSaveButton ? 'common.save' : 'common.apply')
            | translate
            | uppercase
        }}
      </button>
    </div>
  </div>
</wlm-spinner-wrapper>
