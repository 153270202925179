import { DataBindingFilters } from './data-binding-filters';

export class ExtendedFilters {
  dataBindingFilters: DataBindingFilters;
  model: { [fieldName: string]: any };

  constructor(init: ExtendedFilters) {
    Object.assign(this, init);
  }
}
