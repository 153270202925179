<div class="ranges-container" *ngIf="showCart">
  <div class="title-container">
    <mat-icon svgIcon="select-horizontally"></mat-icon>
    <span class="title">{{ T_SCOPE + '.title' | translate }}</span>
  </div>

  <form [formGroup]="rangeForm">
    <div
      *ngIf="!dateRangeValue?.length"
      class="ranges-cart message-container no-data-label message"
    >
      {{ T_SCOPE + '.messages.cart-empty-text' | translate }}
    </div>
    <div *ngIf="dateRangeValue?.length" class="ranges-cart">
      <div class="cart-item mf-small-infix" *ngFor="let range of dateRangeValue; let i = index">
        <wlm-date-time-range
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dateRange]="range"
          [displayHorizontal]="true"
          (dateRangeErrors)="onDateRangeErrors($event, i)"
          (dateRangeChanged)="onDateRangeChanged($event, i)"
        ></wlm-date-time-range>
        <div>
          <button
            mat-icon-button
            (click)="onRemoveRange(i)"
            onclick="this.blur()"
            [matTooltip]="'common.remove' | translate | lowercase"
          >
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="errors-container">
      <div *ngFor="let error of errorList">
        <mat-error *ngIf="error === 'required'"
          >* {{ 'common.validation.required' | translate }}</mat-error
        >
        <mat-error *ngIf="error?.toString() === 'invalidRangePair'"
          >* {{ 'common.validation.end-date-minor' | translate }}</mat-error
        >
        <mat-error *ngIf="error === 'matDatetimePickerMin'"
          >*
          {{
            'common.validation.start-date-min' | translate : { min: minDate | date : 'dateTime' }
          }}
        </mat-error>
        <mat-error *ngIf="error === 'matDatetimePickerMax'"
          >*
          {{
            'common.validation.end-date-max' | translate : { max: maxDate | date : 'dateTime' }
          }}</mat-error
        >
      </div>
    </div>

    <div class="functions-container function-validation-fields">
      <div class="title-container">
        <span class="title">{{ T_SCOPE + '.estimation-functions' | translate }}</span>
      </div>
      <!--Function selector-->
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.fields.estimation-function' | translate }}</mat-label>
        <mat-select [formControlName]="estimationFunctionFieldName">
          <mat-option *ngFor="let fn of estimationFunctions" [value]="fn.type">
            {{ fn.labelKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--Fixed value input-->
      <div *ngIf="fixedEstimationSelected">
        <mat-form-field appearance="outline">
          <mat-label>{{ fixedValueLabel }}</mat-label>

          <input
            matInput
            type="number"
            [formControlName]="fixedValueFieldName"
            autocomplete="off"
          />
          <mat-error *ngIf="rangeForm.get(fixedValueFieldName)?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div class="row-item" *ngIf="alternativePointEstimationSelected">
        <!--Alternative point input-->
        <mat-form-field appearance="outline" class="item">
          <mat-label>{{ T_SCOPE + '.fields.alt-point' | translate }}</mat-label>
          <input
            matInput
            type="text"
            [formControlName]="alternativePointFieldName"
            autocomplete="off"
          />
          <mat-error *ngIf="rangeForm.get(alternativePointFieldName)?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
        </mat-form-field>

        <!--Alternative point date picker-->
        <mat-form-field dateWithTimeFormat class="item">
          <mat-label>{{ T_SCOPE + '.fields.alt-point-date' | translate }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="altDatePicker"
            [formControlName]="alternativePointDateFieldName"
            autocomplete="off"
          />
          <mat-datepicker-toggle matSuffix [for]="altDatePicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #altDatePicker [defaultTime]="defaultTime">
            <ng-template>
              <span>{{ 'common.accept' | translate }}</span>
            </ng-template>
          </ngx-mat-datetime-picker>
          <mat-error *ngIf="rangeForm.get(alternativePointDateFieldName)?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row-item" *ngIf="alternativePointEstimationSelected">
        <!--Offset type selector-->
        <mat-form-field appearance="outline" class="item">
          <mat-label>{{ T_SCOPE + '.fields.offset-type' | translate }}</mat-label>
          <mat-select [formControlName]="offsetTypeFieldName">
            <mat-option *ngFor="let offset of offsetTypes" [value]="offset.type">
              {{ offset.labelKey | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--Offset value input-->
        <mat-form-field appearance="outline" class="item">
          <mat-label>{{ offsetValueLabel }}</mat-label>
          <input
            matInput
            type="number"
            [formControlName]="offsetValueFieldName"
            autocomplete="off"
          />
          <mat-error *ngIf="rangeForm.get(offsetValueFieldName)?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="action-buttons-container">
    <button
      mat-button
      class="wtr-action-button-primary btn-size"
      (click)="onApplyFunction()"
      [disabled]="isLoading || rangeForm?.pristine || !rangeForm?.valid || !dateRanges?.length"
    >
      {{ 'common.apply' | translate | uppercase }}
    </button>
  </div>
</div>
