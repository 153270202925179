import { Injectable } from '@angular/core';
import { DefaultHistoricalValidationService } from 'src/app/common-modules/wlm-charts/core/services/default-historical-validations.service';
import { LarsAndSworpsSiteSignalVersionsDto } from '../../shared/model/signals/lars-sworps-site-signal-versions.dto';
import { LarsAndSworpsSiteSignalsDto } from '../../shared/model/signals/lars-sworps-site-signal.dto';

@Injectable()
export class LarsSworpsHistoricalValidationService extends DefaultHistoricalValidationService<LarsAndSworpsSiteSignalsDto> {
  validateCurrentConfiguration(
    config1: LarsAndSworpsSiteSignalsDto,
    config2: LarsAndSworpsSiteSignalsDto
  ): boolean {
    if (config1.pointId !== config2.pointId || config1 === config2) {
      return false;
    }

    if (config1.isLars !== config2.isLars && config1.isSubtraction === config2.isSubtraction) {
      return false;
    }

    return true;
  }

  getCurrentFromHistorical(
    config: LarsAndSworpsSiteSignalVersionsDto
  ): LarsAndSworpsSiteSignalsDto {
    if (!config) {
      return null;
    }
    const current: LarsAndSworpsSiteSignalsDto = {
      signalId: config.signalId,
      siteId: config.siteId,
      isSubtraction: config.isSubtraction,
      isLars: config.isLars,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      title: '',
    };
    return current;
  }
}
