import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonSharedModule } from 'src/app/common-modules/shared/common-shared.module';
import { FileUploadModule } from 'src/app/common-modules/shared/file-upload/file-upload.module';
import { EditInterfacesPopupComponent } from './edit-interfaces-popup/edit-interfaces-popup.component';
import { InterfacesService } from './interfaces.service';
import { SendInterfacesCsvRequestPopupComponent } from './send-interfaces-csv-request-popup/send-interfaces-csv-request-popup.component';
import { SendInterfacesRequestPopupComponent } from './send-interfaces-request-popup/send-interfaces-request-popup.component';
import { InterfaceRequestService } from './services/interface-request.service';

@NgModule({
  declarations: [
    EditInterfacesPopupComponent,
    SendInterfacesRequestPopupComponent,
    SendInterfacesCsvRequestPopupComponent,
  ],
  exports: [
    EditInterfacesPopupComponent,
    SendInterfacesRequestPopupComponent,
    SendInterfacesCsvRequestPopupComponent,
  ],
  imports: [CommonModule, CommonSharedModule, FileUploadModule],
  providers: [InterfaceRequestService, InterfacesService],
})
export class InterfacesModule {}
