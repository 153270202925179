export class LatLongCoordinates {
  latitude: number;
  longitude: number;
  formattedValue: string;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.formattedValue = `${latitude}, ${longitude}`;
  }
}
