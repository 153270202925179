import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { ElementTargetFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { HierarchyTreeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutKeys } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-keys';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetFiltersAction,
  SetFiltersSettingsAction,
} from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { ElementTargetsEnum } from 'src/app/common-modules/shared/model/shared/element-targets.enum';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { INeHeFilterSettings } from '../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { NEConfigFilterConfiguration } from '../ne-config-filter/ne-config-filter-configuration';
import { NEC_SIGNALS_GROUP_COMPONENT_INSTANCE } from '../ne-config-signals-group/ne-config-signals-group.component';
import { NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE } from '../widgets/ne-config-attribute-form-group-widget/ne-config-attribute-form-group-widget.component';
import { NEC_FILTER_COMPONENT_INSTANCE } from '../widgets/ne-config-filter-widget/ne-config-filter-widget.component';
import { NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE } from '../widgets/ne-configuration-elements-network-elements-widget/ne-configuration-elements-network-elements-widget.component';
import { NEC_SELECTION_COMPONENT_INSTANCE } from '../widgets/ne-configuration-selection-widget/ne-configuration-selection-widget.component';

const COMPONENT_SELECTOR = 'wlm-ne-config-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-page.component.html',
  styleUrls: ['./ne-config-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class NeConfigPageComponent
  extends BasePageComponent
  implements IPendingChangesChecker, OnInit, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  pageId: string;
  settings: DynamicLayoutSettings;
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _targetFieldName = 'isZone';
  private _persistencyArea = 'ne-config';
  private _hierarchyElementIdFieldName = 'hierarchyElementId';
  private _hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  private _hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  private _networkElementTypeFieldName = 'networkElementTypeId';
  private _defaultFamilyId = '07cf5f7e-a084-4383-a73d-09eda922631a';
  private _defaultHETypeIds = ['6828e991-8326-4e7e-b244-a026b67ac867'];
  private _defaultNETypeIds = [];

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);
  private _filtersSettings: NEConfigFilterConfiguration;

  private readonly _layoutKey = 'LayoutMain';
  private readonly _layoutArea = 'NetworkElementConfiguration';
  private readonly _widgetPage = 'necPage';
  private readonly _widgetModule = 'necModule';
  private readonly _filtersInstance1 = 'filtersInstance1';
  private readonly _necInstance1 = 'necInstance1';

  private readonly _filterWidgetName = 'NeConfigFilterWidgetComponent';
  private readonly _selectionWidgetName = 'NeConfigurationSelectionWidgetComponent';
  private readonly _attributesFormGroupWidgetName = 'NeConfigAttributeFormGroupWidgetComponent';
  private readonly _signalsGroupWidgetName = 'NeConfigSignalsGroupWidgetComponent';
  private readonly _eneGroupWidgetName = 'NeConfigurationElementsNetworkElementsWidgetComponent';

  constructor(
    public readonly injector: Injector,
    private readonly _authenticationService: AuthenticationService,
    private readonly _localization: LocalizationHelperService,
    private readonly _state: ReduxStateService,
    private readonly _dynamicLayoutService: DynamicLayoutService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super();

    this._state.configure(
      new StateWidgetSettings({
        module: this._widgetModule,
        page: this._widgetPage,
        scopeInstanceKeys: {
          [StateAreas.NEConfig]: this._necInstance1,
          [StateAreas.Filters]: this._filtersInstance1,
        },
      })
    );

    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.pageId = this._widgetPage;

    this.setFilterConfiguration();
    this.buildTitles().subscribe((titles) => {
      this.settings = new DynamicLayoutSettings({
        layoutKey: this._layoutKey,
        layoutArea: this._layoutArea,
        currentUser: this._authenticationService.userCode,
        widgetPage: this._widgetPage,
        widgetModule: this._widgetModule,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Column,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                height: 15,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: NEC_FILTER_COMPONENT_INSTANCE,
                  },
                ],
              },
              {
                type: LayoutNodeTypes.Row,
                content: [
                  {
                    type: LayoutNodeTypes.Stack,
                    width: 25,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: NEC_SELECTION_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                  {
                    type: LayoutNodeTypes.Stack,
                    width: 75,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE,
                      },
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: NEC_SIGNALS_GROUP_COMPONENT_INSTANCE,
                      },
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        // We do not specify the class here because we would like to be able to parse the whole "items" section from JSON.
        items: [
          {
            componentName: this._filterWidgetName,
            widgetInstanceKey: NEC_FILTER_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
            },
            title: titles.get(this._filterWidgetName),
          },
          {
            componentName: this._selectionWidgetName,
            widgetInstanceKey: NEC_SELECTION_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
              [StateAreas.NEConfig]: this._necInstance1,
            },
            title: titles.get(this._selectionWidgetName),
          },
          {
            componentName: this._attributesFormGroupWidgetName,
            widgetInstanceKey: NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            params: {
              innerLayoutKeys: {
                widgetModule: this._widgetModule,
                widgetPage: this._widgetPage,
                settingArea: this._layoutArea,
                settingKey: 'LayoutAttributesGroup',
                scopeInstanceKeys: {
                  [StateAreas.NEConfig]: this._necInstance1,
                },
              } as DynamicLayoutKeys,
            },
            title: titles.get(this._attributesFormGroupWidgetName),
          },
          {
            componentName: this._signalsGroupWidgetName,
            widgetInstanceKey: NEC_SIGNALS_GROUP_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            params: {
              innerLayoutKeys: {
                widgetModule: this._widgetModule,
                widgetPage: this._widgetPage,
                settingArea: this._layoutArea,
                settingKey: 'LayoutSignalsGroup',
                scopeInstanceKeys: {
                  [StateAreas.NEConfig]: this._necInstance1,
                },
              } as DynamicLayoutKeys,
            },
            title: titles.get(this._signalsGroupWidgetName),
          },
          {
            componentName: this._eneGroupWidgetName,
            widgetInstanceKey: NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            title: titles.get(this._eneGroupWidgetName),
          },
        ],
        minItemHeight: 70,
        minItemWidth: 200,
        showCloseIcon: false,
      });
      this._settings$.next(this.settings);

      this.onLayoutInit();
    });
  }

  onLayoutInit(): void {
    this.dispatchConfiguredFilters(this._filtersInstance1);
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  private dispatchConfiguredFilters(scopeInstanceKey: string): void {
    // We use dispachConfigured here because it is a special case when actions are emitted outside a widget.
    // By default, the filters and selection widget are sharing the same scope instance, so they will communicate.
    const newAction = new SetFiltersSettingsAction(this._filtersSettings);
    const filterFullSettings = new StateFullSettings({
      widget: new StateWidgetSettings({
        module: this._widgetModule,
        page: this._widgetPage,
        scopeInstanceKeys: {
          [StateAreas.Filters]: scopeInstanceKey,
        },
      }),
      area: newAction.area,
      scope: NECScopes.Main,
    });
    this._state.dispatchConfigured(newAction, filterFullSettings);
  }

  onResetFilters(): void {
    this.checkPendingChanges(this.pageId).subscribe((_) =>
      this._state.dispatch(
        new ResetFiltersAction(
          null,
          new StateScopeSettings({
            scope: NECScopes.Main,
          })
        )
      )
    );
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe((_) => this._dynamicLayoutService.resetAll());
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'ConfigurationNetworkElementsCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._filterWidgetName, ts['wlm-ne-config-page']],
          [this._selectionWidgetName, ts['wlm-ne-configuration-selection']],
          [this._attributesFormGroupWidgetName, ts['wlm-ne-configuration-attributes-group']],
          [this._signalsGroupWidgetName, ts['wlm-ne-configuration-signals-group']],
          [this._eneGroupWidgetName, ts['wlm-ne-configuration-elements-network-elements']],
        ]);
        return titles;
      })
    );
  }

  // TODO: get values from persistency
  private setFilterConfiguration() {
    const heSettings = new INeHeFilterSettings(
      false,
      this._defaultHETypeIds,
      this._hierarchyElementTypeFieldName
    );

    const neSettings = new INeHeFilterSettings(true, [], this._networkElementTypeFieldName);

    const targetSettings = new ElementTargetFISettings({
      fieldName: this._targetFieldName,
      selectedIds: [ElementTargetsEnum.HierarchyElements],
      defaultValue: [ElementTargetsEnum.HierarchyElements],
      storageLocation: 'none',
    });

    const treeSettings = new HierarchyTreeFISettings({
      fieldName: this._hierarchyElementIdFieldName,
      selectedIds: [],
    });

    const config = new NEConfigFilterConfiguration({
      persistencyArea: this._persistencyArea,
      pagePersistencyArea: this._persistencyArea,
      defaultFamilyId: this._defaultFamilyId,
      defaultHETypeIds: this._defaultHETypeIds,
      defaultNETypeIds: this._defaultNETypeIds,
      targetSettings,
      heSettings,
      neSettings,
      treeSettings,
      hierarchyElementFamilyFieldName: this._hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName: this._hierarchyElementIdFieldName,
      initialFamilyId: '07cf5f7e-a084-4383-a73d-09eda922631a',
      initialHierarchyElementId: ['6828e991-8326-4e7e-b244-a026b67ac867'],
    });

    this._filtersSettings = config;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
