import { GChartVisualMapPiece } from './g-chart-visual-map-piece';

export class GChartVisualMap {
  // If the visual map legent is shown.
  show? = false;
  dimension? = 0;
  seriesIndex?: number[];
  pieces: GChartVisualMapPiece[];

  constructor(init?: Readonly<GChartVisualMap>) {
    Object.assign(this, init);
  }
}
