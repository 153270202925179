<div [formGroup]="form" class="content-stretch overflow" *ngIf="settings && initialModel">
  <div class="form-content content-top-spacer">
    <div class="form-item point-id-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-id' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointId" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-item point-desc-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-description' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointDescription" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="form-item date-range-field">
      <wlm-date-range-filter
        *ngIf="dateRangeValueSettings"
        [disableAutoTruncate]="disableAutoTruncate"
        [displayHorizontal]="dateRangeSettings.displayHorizontal"
        [smallFields]="dateRangeSettings.smallFields"
        [fieldAppearance]="fieldAppearance"
        [minDate]="settings.minDate"
        [maxDate]="settings.maxDate"
        [resetEndDate$]="resetDates$"
        [resetStartDate$]="resetDates$"
        [valueSettings]="dateRangeValueSettings"
        (dateRangeChanged)="onDateRangeChanged($event)"
        (hasError)="onDatesHasError($event)"
      ></wlm-date-range-filter>
    </div>
  </div>
  <div class="form-content">
    <div class="form-item">
      <mat-radio-group
        [formControlName]="fields.isLars"
        class="radio-group-content"
        aria-label="Is LARS or SWORPS"
      >
        <mat-radio-button [value]="true" class="separate-item">{{
          T_SCOPE + '.is-lars' | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="false">{{
          T_SCOPE + '.is-sworps' | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-item">
      <mat-radio-group
        [formControlName]="fields.isSubtraction"
        class="radio-group-content"
        aria-label="Is Addition or Subtraction"
      >
        <mat-radio-button [value]="false" class="separate-item">{{
          T_SCOPE + '.is-addition' | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="true">{{
          T_SCOPE + '.is-subtraction' | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
