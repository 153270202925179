<wlm-spinner-wrapper [showSpinner]="isLoading" [enableRelative]="true">
  <wlm-popup-wrapper
    [showCloseButton]="false"
    [showSpinner]="isLoading"
    [titleKey]="T_SCOPE + (isEditMode ? '.edit-title' : '.create-title')"
  >
    <ng-container popup-content>
      <div class="form-group main" [formGroup]="form" *ngIf="form">
        <div class="form-col">
          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.name' | translate }}</mat-label>
            <input matInput [formControlName]="nameFieldName" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.description' | translate }}</mat-label>
            <input matInput [formControlName]="descriptionFieldName" />
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container popup-actions>
      <div class="action-buttons-container">
        <div class="button-container-padding">
          <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
            {{ 'common.cancel' | translate | uppercase }}
          </button>
        </div>
        <div>
          <button
            mat-button
            class="wtr-action-button-primary btn-size"
            [disabled]="!hasValidChanges"
            (click)="save()"
          >
            {{ 'common.save' | translate | uppercase }}
          </button>
        </div>
      </div>
    </ng-container>
  </wlm-popup-wrapper>
</wlm-spinner-wrapper>
