import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comparisonPercentage',
})
export class ComparisonPercentage implements PipeTransform {
  private readonly _decimalNumbers = 2;
  constructor() { }

  transform(value: number, compValue: number): string {
    const comparison = this.getComparisonPercentage(value, compValue);
    if (!comparison && comparison !== 0) {
      return '(N/A)';
    } else {
      const formattedValue = Number.isInteger(comparison) ? comparison : comparison.toFixed(this._decimalNumbers);

      return `(${formattedValue}%)`;
    }
  }

  getComparisonPercentage(value: number, compValue: number) {
    if (value == 0 && compValue == 0) {
      return 0;
    }
    if (value && compValue) {
      return ((value - compValue) / value) * 100;
    }
  }
}
