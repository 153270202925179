export class HierarchyChildrenConfigurationDto {
  hierarchyFamilyId: string;
  ancestor: string;
  ancestorTypeId: string;
  descendantTypeId?: string;
  descendants: string[];

  constructor(init: Readonly<HierarchyChildrenConfigurationDto>) {
    Object.assign(this, init);
  }
}
