export enum WlmGlobalSettings {
  Interfaces = 1,
  InterfaceGis = 14,
  InterfacesSchema = 11,
  InterfacesGisSchema = 15,
  InterfacesValidation = 12,
  GisSettings = 14,
  GisSettingsSchema = 15,
  GisSettingsAttributes = 16
}
