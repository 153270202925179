export class DeleteAlarmsDto {
  alarmTypeIds: string[];
  userCode: string;
  operationTime: Date;

  constructor(alarmTypeIds: string[], userCode: string, operationTime: Date) {
    this.alarmTypeIds = alarmTypeIds;
    this.userCode = userCode;
    this.operationTime = operationTime;
  }
}
