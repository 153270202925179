import { Inject, Injectable } from '@angular/core';
import { BaseFiltrableItemService } from './base-filtrable-item.service';
import { FiltrableItemTypeEnum } from '../model/filtrable-items/types/filtrable-item-type-enum';

@Injectable({
  providedIn: 'root',
})
export class FiltrableItemManagerService {
  constructor(@Inject(BaseFiltrableItemService) private _services: BaseFiltrableItemService[]) {}

  getService(type: FiltrableItemTypeEnum): BaseFiltrableItemService | undefined {
    return this._services.find((service) => service.type === type);
  }
}
