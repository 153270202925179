import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizeService } from './services/authorize.service';

@Directive({
  selector: '[hasPerm]',
})
export class HasPermissionDirective implements OnInit {
  @Input() public set hasPerm(value: [string, string]) {
    this._accessKey = value[0];
    this._permissionsCode = value[1];
  }

  // The section to check permissions for.
  private _accessKey: string;
  // Code with the permissions to test. Like "crud", "cr", etc.
  private _permissionsCode: string;

  constructor(
    private _authorizeService: AuthorizeService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    this._authorizeService
      .canAccess(this._accessKey, this._permissionsCode)
      .subscribe((allowed) => {
        if (allowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          // If the user does not have access to the content, the HTML elements are deleted.
          this.viewContainer.clear();
        }
      });
  }
}
