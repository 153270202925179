import { SchematicLink, SchematicNodeProcessed } from './schematic';

export class GSchematicChartSerie {
  type = 'graph';
  // title of the chart
  name: string;
  layout? = 'none';
  data: SchematicNodeProcessed[];
  links: SchematicLink[];
  categories: { name: string }[];
  roam? = false;

  label? = {
    show: true,
    position: 'right',
    formatter: '{b}',
  };

  labelLayout? = {
    hideOverlap: true,
  };

  scaleLimit? = {};

  lineStyle? = {
    color: 'source',
    curveness: 0.000000000000000000001,
  };

  symbol?: string;
  symbolKeepAspect? = false;

  coordinateSystem?: string;
  geoIndex?: number;
  xAxisIndex?: number;
  yAxisIndex?: number;

  symbolSize = 20;

  constructor(init: Partial<GSchematicChartSerie>) {
    Object.assign(this, init);
  }
}
