import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DynamicLayoutConfigurableItemSettings } from '../../dynamic-layout/models/dynamic-layout-configurable-item-settings';
import { DynamicLayoutItemSettings } from '../../dynamic-layout/models/dynamic-layout-item-settings';
import { BasicFilter } from '../../shared/filters/component-filters/basic-filter';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { LocalStorageService } from '../../shared/local-storage.service';
import { WidgetManagerService } from '../../widget-manager/services/widget-manager.service';
import { StateWidgetSettings } from '../models/state-widget-settings';
import { ReduxStateService } from '../redux-state.service';

@Directive()
export abstract class BaseDynamicWidgetComponent implements OnInit, OnDestroy {
  protected persistencyWidgetArea: string;
  protected persistencyArea: string;

  protected itemSettings: DynamicLayoutItemSettings;

  itemSettingsUpdated$ = new ReplaySubject<DynamicLayoutConfigurableItemSettings>(1);
  hasPendingUpdates = false;

  private _activeStatus$ = new ReplaySubject<boolean>(1);
  readonly activeStatus$ = this._activeStatus$.asObservable().pipe(distinctUntilChanged());
  private _isFirstActive = false;
  private _isFirstActive$ = new ReplaySubject<void>(1);
  readonly isFirstActive$ = this._isFirstActive$.asObservable();

  private _localStorageService: LocalStorageService;
  protected _state: ReduxStateService;
  protected _widgetManager: WidgetManagerService;
  protected _cd: ChangeDetectorRef;
  public rootHtmlElement: HTMLElement;
  private refsToDestroy = new Map<string, ComponentRef<any>>();

  constructor(injector: Injector, protected _settings: StateWidgetSettings) {
    this._localStorageService = injector.get(LocalStorageService);
    this._state = injector.get(ReduxStateService);
    this._widgetManager = injector.get(WidgetManagerService);
    this._cd = injector.get(ChangeDetectorRef);

    this.rootHtmlElement = injector.get(ElementRef).nativeElement;

    this._state.configure(this._settings);
    this.persistencyWidgetArea = this._settings.widgetInstanceKey;
    this.itemSettings = globalUtilsHelper.clone(this._settings.itemSettings, true);
    this.rootHtmlElement.classList.add('widget_position');
  }

  ngOnInit(): void {
    this.onWidgetInit();
  }

  hasDefaultFilters() {
    return this._settings.hasDefaultFilters;
  }

  getWidgetInstanceKey(): string {
    return this._settings.widgetInstanceKey;
  }

  toggleActiveStatus(status: boolean): void {
    this._activeStatus$.next(status);

    if (status && !this._isFirstActive) {
      this._isFirstActive = true;
      this._isFirstActive$.next();
      this._isFirstActive$.complete();
    }
  }

  abstract get componentName(): string;

  /**
   * Custom lifecycle hook when actions and selectors can start being used.
   * Useful in case we want to perform certain operations before allowing the usage of the store.
   */
  abstract onWidgetInit(): void;

  // TODO: Refactor into service

  protected getPersistedData(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    const persisted = this._localStorageService.getTyped(`${key}`, defaultValue, useLocalStorage);
    return persisted;
  }

  protected updateItemSettings(itemSettings: DynamicLayoutConfigurableItemSettings): void {
    this.itemSettingsUpdated$.next(itemSettings);
    this.hasPendingUpdates = true;
  }

  protected getPersisted(
    key: string,
    defaultValue?: any,
    forcedDefaultValue?: any,
    useLocalStorage?: boolean
  ) {
    useLocalStorage = useLocalStorage === undefined ? true : useLocalStorage;
    if (forcedDefaultValue) {
      return forcedDefaultValue;
    } else {
      return this.getPersistedData(key, defaultValue, useLocalStorage);
    }
  }

  protected persist(key: string, value: any, useLocalStorage?: boolean): any {
    // delete key if value is null or undefined
    if (value == null) {
      return this.removePersist(key);
    }

    return this._localStorageService.addOrUpdate(
      `${this.persistencyArea}-${key}`,
      value,
      useLocalStorage
    );
  }

  protected removePersist(key: string, useLocalStorage?: boolean) {
    return this._localStorageService.remove(`${this.persistencyArea}-${key}`, useLocalStorage);
  }

  protected getPersistedFromFilter<T>(
    fieldName: string,
    defaultValue: T,
    extractValueFn: (filter: BasicFilter) => T = undefined
  ): T {
    const persisted = this.getPersisted(fieldName, undefined) as BasicFilter;
    if (typeof persisted === 'undefined' || persisted === null) {
      return defaultValue;
    }

    if (typeof extractValueFn !== 'undefined') {
      return extractValueFn(persisted);
    }

    return persisted.value?.map((x) => x.value);
  }

  /**
   * Methods for compatibility with golden-layout-host
   */

  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.rootHtmlElement.style.left = this.numberToPixels(left);
    this.rootHtmlElement.style.top = this.numberToPixels(top);
    this.rootHtmlElement.style.width = this.numberToPixels(width);
    this.rootHtmlElement.style.height = this.numberToPixels(height);
  }

  setVisibility(visible: boolean) {
    if (visible) {
      this.rootHtmlElement.style.display = '';
    } else {
      this.rootHtmlElement.style.display = 'none';
    }
  }

  setZIndex(value: string) {
    this.rootHtmlElement.style.zIndex = value;
  }

  private numberToPixels(value: number): string {
    return value.toString(10) + 'px';
  }

  private destroyLinkedComponents(): void {
    for (let componentRef of this.refsToDestroy.values()) {
      componentRef.destroy();
    }
  }

  setToDestroy(key: string, value: ComponentRef<any>): void {
    this.refsToDestroy.set(key, value);
  }

  ngOnDestroy(): void {
    this.destroyLinkedComponents();
  }
}
