import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigurableFormModule } from 'src/app/common-modules/configurable-form/configurable-form.module';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { GenericCrudModule } from 'src/app/common-modules/generic-crud/generic-crud.module';
import { SvgEditorFieldTypeComponent } from 'src/app/common-modules/svg-editor/svg-editor-field-type/svg-editor-field-type.component';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { MapModule } from '../map/map.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { ActivityTypesCrudService } from './activity-types/activity-types-crud.service';
import { ActivityTypesCrudComponent } from './activity-types/activity-types-crud/activity-types-crud.component';
import { ActivityTypesFormComponent } from './activity-types/activity-types-form/activity-types-form.component';
import { AppAttributesCrudService } from './app-attributes/app-attributes-crud.service';
import { AppAttributesCrudComponent } from './app-attributes/app-attributes-crud/app-attributes-crud.component';
import { AppAttributesFormComponent } from './app-attributes/app-attributes-form/app-attributes-form.component';
import { BillingClassCrudComponent } from './billing-classes/billing-class-crud/billing-class-crud.component';
import { BillingClassFormComponent } from './billing-classes/billing-class-form/billing-class-form.component';
import { BillingClassesCrudService } from './billing-classes/billing-classes-crud.service';
import { CustomerClassTypeCrudComponent } from './class-types/customer-class-type-crud/customer-class-type-crud.component';
import { CustomerClassTypeFormComponent } from './class-types/customer-class-type-form/customer-class-type-form.component';
import { CustomerClassTypesCrudService } from './class-types/customer-class-types-crud.service';
import { FamiliesAndRelationsCrudService } from './families-and-relations/families-and-relations-crud.service';
import { FamiliesAndRelationsCrudComponent } from './families-and-relations/families-and-relations-crud/families-and-relations-crud.component';
import { FamiliesAndRelationsFormComponent } from './families-and-relations/families-and-relations-form/families-and-relations-form.component';
import { FamilyRelationsFieldTypeComponent } from './families-and-relations/family-relations-field-type/family-relations-field-type.component';
import { FamilyRelationsSelectorComponent } from './families-and-relations/family-relations-selector/family-relations-selector.component';
import { IntegrationGisLayersFormComponent } from './gis-layers/integration-gis-layers-form/integration-gis-layers-form.component';
import { IntegrationGisLayersGridComponent } from './gis-layers/integration-gis-layers-grid/integration-gis-layers-grid.component';
import { IntegrationGisLayersMapComponent } from './gis-layers/integration-gis-layers-map/integration-gis-layers-map.component';
import { IntegrationGisLayersMappingComponent } from './gis-layers/integration-gis-layers-mapping/integration-gis-layers-mapping.component';
import { IntegrationGisLayersPopupComponent } from './gis-layers/integration-gis-layers-popup/integration-gis-layers-popup.component';
import { IntegrationGisLayersComponent } from './gis-layers/integration-gis-layers.component';
import { IntegrationGisLayersService } from './gis-layers/integration-gis-layers.service';
import { IntegrationGisLayersGridWidgetComponent } from './gis-layers/widgets/integration-gis-layers-grid-widget/integration-gis-layers-grid-widget.component';
import { IntegrationGisLayersMapWidgetComponent } from './gis-layers/widgets/integration-gis-layers-map-widget/integration-gis-layers-map-widget.component';
import { IntegrationGisLayersMappingWidgetComponent } from './gis-layers/widgets/integration-gis-layers-mapping-widget/integration-gis-layers-mapping-widget.component';
import { HeTypeCrudComponent } from './he-types/he-type-crud/he-type-crud.component';
import { HeTypeFormComponent } from './he-types/he-type-form/he-type-form.component';
import { HETypesCrudService } from './he-types/he-types-crud.service';
import { IntegrationRoutingModule } from './integration-routing.module';
import { LeakTypeGroupsCrudService } from './leak-types/leak-type-groups-crud.service';
import { LeakTypeGroupsCrudComponent } from './leak-types/leak-type-groups-crud/leak-type-groups-crud.component';
import { LeakTypeGroupsFormComponent } from './leak-types/leak-type-groups-form/leak-type-groups-form.component';
import { LeakTypesAndGroupsCrudComponent } from './leak-types/leak-types-and-groups-crud/leak-types-and-groups-crud.component';
import { LeakTypesCrudService } from './leak-types/leak-types-crud.service';
import { LeakTypesCrudComponent } from './leak-types/leak-types-crud/leak-types-crud.component';
import { LeakTypesFormComponent } from './leak-types/leak-types-form/leak-types-form.component';
import { MaterialTypesCrudService } from './material-types/material-types-crud.service';
import { MaterialTypesCrudComponent } from './material-types/material-types-crud/material-types-crud.component';
import { MaterialTypesFormComponent } from './material-types/material-types-form/material-types-form.component';
import { NeTypeCrudComponent } from './ne-types/ne-type-crud/ne-type-crud.component';
import { NeTypeFormComponent } from './ne-types/ne-type-form/ne-type-form.component';
import { NETypesCrudService } from './ne-types/ne-types-crud.service';
import { IntegrationPageComponent } from './pages/integration-page/integration-page.component';
import { SapCodeGroupsCrudService } from './sap-codes/sap-code-groups-crud.service';
import { SapCodeGroupsCrudComponent } from './sap-codes/sap-code-groups-crud/sap-code-groups-crud.component';
import { SapCodeGroupsFormComponent } from './sap-codes/sap-code-groups-form/sap-code-groups-form.component';
import { SapCodesAndGroupsCrudComponent } from './sap-codes/sap-codes-and-groups-crud/sap-codes-and-groups-crud.component';
import { SapCodesCrudService } from './sap-codes/sap-codes-crud.service';
import { SapCodesCrudComponent } from './sap-codes/sap-codes-crud/sap-codes-crud.component';
import { SapCodesFormComponent } from './sap-codes/sap-codes-form/sap-codes-form.component';
import { IntegrationUserService } from './users/integration-user.service';

@NgModule({
  declarations: [
    IntegrationPageComponent,
    HeTypeFormComponent,
    NeTypeFormComponent,
    NeTypeCrudComponent,
    HeTypeCrudComponent,
    BillingClassCrudComponent,
    BillingClassFormComponent,
    AppAttributesCrudComponent,
    AppAttributesFormComponent,
    MaterialTypesCrudComponent,
    MaterialTypesFormComponent,
    ActivityTypesCrudComponent,
    ActivityTypesFormComponent,
    SapCodesCrudComponent,
    SapCodesFormComponent,
    SapCodeGroupsCrudComponent,
    SapCodeGroupsFormComponent,
    SapCodesAndGroupsCrudComponent,
    LeakTypesCrudComponent,
    LeakTypesFormComponent,
    LeakTypeGroupsFormComponent,
    LeakTypeGroupsCrudComponent,
    LeakTypesAndGroupsCrudComponent,
    FamiliesAndRelationsCrudComponent,
    FamiliesAndRelationsFormComponent,
    FamilyRelationsFieldTypeComponent,
    FamilyRelationsSelectorComponent,
    CustomerClassTypeCrudComponent,
    CustomerClassTypeFormComponent,
    IntegrationGisLayersComponent,
    IntegrationGisLayersPopupComponent,
    IntegrationGisLayersFormComponent,
    IntegrationGisLayersGridComponent,
    IntegrationGisLayersMapComponent,
    IntegrationGisLayersMappingComponent,
    IntegrationGisLayersGridWidgetComponent,
    IntegrationGisLayersMapWidgetComponent,
    IntegrationGisLayersMappingWidgetComponent,
  ],
  imports: [
    CommonModule,
    GenericCrudModule,
    MatStepperModule,
    MatButtonModule,
    ConfigurableFormModule,
    TranslateModule,
    IntegrationRoutingModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    DynamicLayoutModule,
    WLMGridModule,
    MapModule,
  ],
  providers: [
    HETypesCrudService,
    { provide: 'HETypesCrudService', useExisting: HETypesCrudService },
    NETypesCrudService,
    { provide: 'NETypesCrudService', useExisting: NETypesCrudService },
    CustomerClassTypesCrudService,
    { provide: 'CustomerClassTypesCrudService', useExisting: CustomerClassTypesCrudService },
    BillingClassesCrudService,
    { provide: 'BillingClassesCrudService', useExisting: BillingClassesCrudService },
    AppAttributesCrudService,
    { provide: 'AppAttributesCrudService', useExisting: AppAttributesCrudService },
    MaterialTypesCrudService,
    { provide: 'MaterialTypesCrudService', useExisting: MaterialTypesCrudService },
    ActivityTypesCrudService,
    { provide: 'ActivityTypesCrudService', useExisting: ActivityTypesCrudService },
    SapCodeGroupsCrudService,
    { provide: 'SapCodeGroupsCrudService', useExisting: SapCodeGroupsCrudService },
    SapCodesCrudService,
    { provide: 'SapCodesCrudService', useExisting: SapCodesCrudService },
    LeakTypesCrudService,
    { provide: 'LeakTypesCrudService', useExisting: LeakTypesCrudService },
    LeakTypeGroupsCrudService,
    { provide: 'LeakTypeGroupsCrudService', useExisting: LeakTypeGroupsCrudService },
    FamiliesAndRelationsCrudService,
    { provide: 'FamiliesAndRelationsCrudService', useExisting: FamiliesAndRelationsCrudService },
    IntegrationUserService,
    { provide: 'IntegrationUserService', useExisting: IntegrationUserService },
    IntegrationGisLayersService,
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useValue: {
        types: [
          {
            name: 'het-sorter-selector',
            component: FamilyRelationsFieldTypeComponent,
          },
          {
            name: 'svg-editor',
            component: SvgEditorFieldTypeComponent,
          },
        ],
      },
    },
  ],
})
export class IntegrationModule {
  static injector: Injector;

  constructor(injector: Injector, private _widgetRegistry: WidgetRegistryService) {
    IntegrationModule.injector = injector;
    this._widgetRegistry.register([
      {
        name: 'IntegrationGisLayersGridWidgetComponent',
        widgetClass: IntegrationGisLayersGridWidgetComponent,
      },
      {
        name: 'IntegrationGisLayersMapWidgetComponent',
        widgetClass: IntegrationGisLayersMapWidgetComponent,
      },
      {
        name: 'IntegrationGisLayersMappingWidgetComponent',
        widgetClass: IntegrationGisLayersMappingWidgetComponent,
      },
    ]);
  }
}
