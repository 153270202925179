import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';

export class FilterConditionalOverride {
  onChangedFieldName: string;
  targetFieldName: string;
  // If targetIsFromPersistency returns true, the sideAffectsAndOverrideFn will be executed,
  // side effects will be performed (like rebuilding the tree), but the modifications in the payload will not apply,
  // as persistency values take priority.
  targetIsFromPersistencyFn: (payload: FiltersPayload) => boolean;
  overridePayloadFn: (
    payload: FiltersPayload,
    eventType: 'apply' | 'filter',
    changes?: Map<string, boolean>
  ) => FiltersPayload;

  constructor(init: FilterConditionalOverride) {
    Object.assign(this, init);
  }
}
