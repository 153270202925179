<div [formGroup]="form" class="content-stretch overflow" *ngIf="settings && initialModel">
  <div class="form-content content-top-spacer">
    <div class="form-item point-id-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-id' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointId" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-item point-desc-field" *ngIf="settings?.showPointFields">
      <mat-form-field class="mat-field content-stretch" [appearance]="fieldAppearance">
        <mat-label>{{ T_SCOPE + '.point-description' | translate }}</mat-label>
        <input matInput [formControlName]="fields.pointDescription" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="form-item date-range-field">
      <wlm-date-range-filter
        *ngIf="dateRangeValueSettings"
        [disableAutoTruncate]="disableAutoTruncate"
        [displayHorizontal]="dateRangeSettings.displayHorizontal"
        [smallFields]="dateRangeSettings.smallFields"
        [fieldAppearance]="fieldAppearance"
        [minDate]="settings.minDate"
        [maxDate]="settings.maxDate"
        [resetEndDate$]="resetDates$"
        [resetStartDate$]="resetDates$"
        [valueSettings]="dateRangeValueSettings"
        (dateRangeChanged)="onDateRangeChanged($event)"
        (hasError)="onDatesHasError($event)"
      ></wlm-date-range-filter>
    </div>
  </div>
  <div class="form-content">
    <div class="form-item">
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.propertyId' | translate }}</mat-label>
        <input
          matInput
          formControlName="customerSearch"
          (keydown)="onKeyDownEvent($event)"
          [matAutocomplete]="auto"
          [placeholder]="'common.search' | translate"
        />
        <mat-icon matSuffix class="default-icon-color">search</mat-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelectOption($event)"
          class="autocomplete-options"
        >
          <mat-option *ngFor="let customer of customersLocated" [value]="customer.propertyId">
            <span>{{ customer.propertyId }}</span>
          </mat-option>
          <mat-option *ngIf="searchingCustomer">
            <span class="option-message">{{ 'common.loading' | translate }}</span>
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="form.get('customerSearch').invalid">
          {{ 'common.validation.max-length' | translate : { max: cityMaxLength } }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
