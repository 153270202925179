export class HistoricalEvent {
  group: string;
  startDate: Date;
  endDate: Date;
  visibleStartDate: Date;
  visibleEndDate: Date;
  originalStartDate: Date;
  originalEndDate: Date;
  id: string;
  label: string;
  additionalParams: { [key: string]: any };
  tooltip: string;

  constructor(init: Readonly<HistoricalEvent>) {
    Object.assign(this, init);
  }
}
