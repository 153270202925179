<mat-tab-group>
  <mat-tab label="Colors">
    <div class="container">
      <div class="row">
        <div class="col-12" *ngFor="let colorItem of colorItems">
          <div class="row">
            <div class="col">
              <div [class]="'color-box ' + colorItem.class"></div>
            </div>
            <div class="col">
              {{ colorItem.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Text & Headers">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>h1, .wtr-headline, icons</h1>
        </div>
        <div class="col-12">
          <h2>h2, .wtr-title</h2>
        </div>
        <div class="col-12">
          <h3>h3, .wtr-subheading</h3>
        </div>
        <div class="col-12" *ngFor="let textItem of textItems"></div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Buttons, Form Fields">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Outline form field</mat-label>
        <input matInput placeholder="Placeholder" />
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
    </p>

    <section>
      <div class="example-label">Basic</div>
      <div class="example-button-row">
        <button mat-button>Basic</button>
        <button mat-button color="primary">Primary</button>
        <button mat-button color="accent">Accent</button>
        <button mat-button color="warn">Warn</button>
        <button mat-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Raised</div>
      <div class="example-button-row">
        <button mat-raised-button>Basic</button>
        <button mat-raised-button color="primary">Primary</button>
        <button mat-raised-button color="accent">Accent</button>
        <button mat-raised-button color="warn">Warn</button>
        <button mat-raised-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Stroked</div>
      <div class="example-button-row">
        <button mat-stroked-button>Basic</button>
        <button mat-stroked-button color="primary">Primary</button>
        <button mat-stroked-button color="accent">Accent</button>
        <button mat-stroked-button color="warn">Warn</button>
        <button mat-stroked-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Flat</div>
      <div class="example-button-row">
        <button mat-flat-button>Basic</button>
        <button mat-flat-button color="primary">Primary</button>
        <button mat-flat-button color="accent">Accent</button>
        <button mat-flat-button color="warn">Warn</button>
        <button mat-flat-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Icon</div>
      <div class="example-button-row">
        <div class="example-flex-container">
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
          <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
            <mat-icon>favorite</mat-icon>
          </button>
          <button
            mat-icon-button
            disabled
            aria-label="Example icon button with a open in new tab icon"
          >
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </mat-tab>

  <mat-tab label="Buttons v2">
    <h3>Default buttons</h3>
    <wlm-theme-test-buttons> </wlm-theme-test-buttons>

    <h3>Primary buttons</h3>
    <wlm-theme-test-buttons internalClass="wtr-button-primary"> </wlm-theme-test-buttons>

    <h3>Regular buttons</h3>
    <wlm-theme-test-buttons internalClass="wtr-button-regular"> </wlm-theme-test-buttons>
  </mat-tab>

  <mat-tab label="Icons">
    <div class="row">
      <div class="col-3" *ngFor="let iconName of iconNames">
        <wlm-icon [icon]="iconName"></wlm-icon> &nbsp; {{ iconName }}
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
