/**
 * Represents a data point inside series.
 * New equivalent of ISerieDataPointDto.
 */

export class GChartSerieDataPoint {
  // In cartesian, this field is mapped to the selected Y axis of the series.
  pointCategory: Date | number | string;
  // In cartesian, this field is mapped to the selected X axis of the series.
  pointValue: number;

  color?: string;
  hasToShowSymbol?: boolean;
  symbolSize?: number;

  pointLabel?: string;

  alwaysShowSymbol? = false;

  extraProperties?: { [key: string]: any };

  constructor(init?: Readonly<GChartSerieDataPoint>) {
    Object.assign(this, init);
  }
}
