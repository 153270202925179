import { GChartSerieDataSection } from './g-chart-serie-data-section';

/**
 * Represents series that can be displayed in a pie chart.
 */

export class GPieChartSerie {
  type: 'pie';
  // title of the chart
  name: string;
  //  percentage format eg: 50%
  radius: string;
  // Center coordinates
  center: string[];

  dataPoints: GChartSerieDataSection[];

  yAxisWLMDimensionTypeId?: number;
  // Unit types of the associated to dataPoints value field.
  yAxisWLMUnitTypeIdFrom?: number;
  yAxisWLMUnitTypeIdTo?: number;
  // used to format value in labels
  decimalPositions?: number;

  // Not mapped data.
  additionalParams?: { [key: string]: any };

  constructor(init: GPieChartSerie) {
    Object.assign(this, init);
  }
}
