export class AddGridSettingsCmd {
  gridSettingKey: string;
  gridSettingsValue: string;
  saveAsDefault: boolean;

  constructor(gridSettingsKey: string, gridSettingsValue: string, saveAsDefault = false) {
    this.gridSettingKey = gridSettingsKey;
    this.gridSettingsValue = gridSettingsValue;
    this.saveAsDefault = saveAsDefault;
  }
}
