import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { ChartWorkspaceTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { DataVisualizationConfigFormComponent } from '../data-visualization-config-form/data-visualization-config-form.component';
import { WorkspaceService } from '../data-visualization-workspace-selector/workspace.service';

const COMPONENT_SELECTOR = 'wlm-data-visualization-save-config-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-save-config-popup.component.html',
  styleUrls: ['./data-visualization-save-config-popup.component.scss'],
})
export class DataVisualizationSaveConfigPopupComponent implements OnInit {
  @ViewChild(DataVisualizationConfigFormComponent)
  dvConfigForm: DataVisualizationConfigFormComponent;

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  isLoading = false;

  isEditMode: boolean;
  workspace: WorkspaceDto;
  chartConfiguration: ChartConfiguration;
  chartFiltrableItems: IFiltrableItemDto[];

  cartFiltrableItems: IFiltrableItemDto[];

  private _saveConfig$ = new Subject<void>();
  readonly saveConfig$ = this._saveConfig$.asObservable();

  private _conflictError$ = new Subject<void>();
  readonly conflictError$ = this._conflictError$.asObservable();

  hasValidChanges = true;
  saveAsSystem = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) { isEditMode, workspace, chartConfig, cartFiltrableItems }: any,
    private _dialogRef: MatDialogRef<DataVisualizationSaveConfigPopupComponent>,
    private _workspaceService: WorkspaceService,
    private _dialogService: DialogService
  ) {
    this.isEditMode = isEditMode;
    this.workspace = workspace;
    this.chartConfiguration = chartConfig;
    this.cartFiltrableItems = cartFiltrableItems;
  }

  ngOnInit(): void {}

  onHasValidChanges(hasValidChanges: boolean) {
    this.hasValidChanges = hasValidChanges;
  }

  cancel(): void {
    this._dialogRef.close();
  }

  save(saveAsSystem: boolean): void {
    this.saveAsSystem = saveAsSystem;

    this._saveConfig$.next();
  }

  onSaveConfig(workspace: WorkspaceDto) {
    this.isLoading = true;

    let save$: Observable<WorkspaceDto>;

    if (this.isEditMode) {
      save$ = this._workspaceService.updateWorkspace(workspace);
    } else {
      save$ = this._workspaceService.createWorkspace(workspace, this.saveAsSystem);
    }

    save$.pipe(untilDestroyed(this)).subscribe({
      next: (response) => {
        const messageKey =
          response.workspaceTypeId === ChartWorkspaceTypeEnum.Template
            ? 'template-saved'
            : 'workspace-saved';

        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.${messageKey}` })
        );

        this.isLoading = false;
        this._dialogRef.close(response);
      },
      error: (error) => {
        this.isLoading = false;

        if (error?.status === HttpStatusCode.Conflict) {
          return this._conflictError$.next();
        }

        this._dialogService.showErrorMessage(error);
        this._dialogRef.close(null);
      },
    });
  }

  isSaveAsSystemVisible() {
    return (
      !this.isEditMode &&
      this.dvConfigForm?.form?.value?.workspaceTypeId === ChartWorkspaceTypeEnum.Template
    );
  }
}
