<wlm-spinner-wrapper [showSpinner]="allChartsLoading">
  <wlm-no-results
    [showNoResult]="elementId && !ageChartHasData && !diameterChartHasData && !materialChartHasData"
  ></wlm-no-results>
  <div class="wtr-fx-layout-column pipe-pie-chart-container" *ngIf="elementId">
    <div
      *ngIf="ageChartHasData && diameterChartHasData && materialChartHasData"
      class="top-buttons"
    >
      <button
        mat-icon-button
        [disabled]="!showMaterialChart && !showDiameterChart && !showAgeChart"
        [matTooltip]="T_SCOPE + '.export-to-pdf' | translate | lowercase"
        (click)="exportToPdf()"
      >
        <mat-icon class="material-icons-outlined" svgIcon="export-to-pdf"></mat-icon>
      </button>
    </div>
    <div *ngIf="materialChartHasData" class="chart-title">
      {{ T_SCOPE + '.length-pipes-material-groups' | translate }}
    </div>
    <div fxFlex *ngIf="showMaterialChart">
      <wlm-pie-chart
        #materialChart
        [loading]="loading"
        [chartSettings]="chartSettingMaterial"
        [disableFixedSize]="disableFixedSize"
        (hasData)="onMaterialChartHasData($event)"
        (loadingEvent)="onChartLoaded($event, 0)"
      ></wlm-pie-chart>
    </div>

    <div *ngIf="diameterChartHasData" class="chart-title">
      {{ T_SCOPE + '.length-pipes-diameter-groups' | translate }}
    </div>
    <div fxFlex *ngIf="showDiameterChart">
      <wlm-pie-chart
        #diameterChart
        [loading]="loading"
        [chartSettings]="chartSettingDiameter"
        [disableFixedSize]="disableFixedSize"
        (hasData)="onDiameterChartHasData($event)"
        (loadingEvent)="onChartLoaded($event, 1)"
      ></wlm-pie-chart>
    </div>

    <div *ngIf="ageChartHasData" class="chart-title">
      {{ T_SCOPE + '.length-pipes-age-groups' | translate }}
    </div>
    <div fxFlex *ngIf="showAgeChart">
      <wlm-pie-chart
        #ageChart
        [loading]="loading"
        [chartSettings]="chartSettingAge"
        [disableFixedSize]="disableFixedSize"
        (hasData)="onAgeChartHasData($event)"
        (loadingEvent)="onChartLoaded($event, 2)"
      ></wlm-pie-chart>
    </div>
  </div>
</wlm-spinner-wrapper>
