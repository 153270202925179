import { Injectable, Injector } from '@angular/core';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class AlgorithmSelectionService extends GridODataService<IAlgorithmDto> {
  constructor(injector: Injector) {
    super(injector, '/api/alg/algorithm');
  }

  protected mapResponse(response: PagedResultDto<IAlgorithmDto>): PagedResultDto<IAlgorithmDto> {
    return response;
  }
}
