import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { HierarchyElementFiltersDataDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from 'src/app/common-modules/dependencies/navigation/w-navigate-by';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { NotificationRelatedComponent } from 'src/app/common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-disable';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from 'src/app/common-modules/shared/helpers/navmenu-builder-helper.service';
import { DynamicGridSettings } from 'src/app/common-modules/shared/model/grid/dynamic-grid-settings';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { RightPanelAttributesService } from 'src/app/common-modules/shared/services/right-panel-attributes-builder.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { PeriodTypesEnum } from 'src/app/common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from 'src/app/common-modules/wlm-charts/core/models/time-selector-settings';
import { TimeSelectorChartWidgetComponent } from 'src/app/common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { BaseGridPageComponent } from 'src/app/common-modules/wlm-grid/base-grid/base-grid-page.component';
import { DynamicGridComponent } from 'src/app/common-modules/wlm-grid/dynamic-grid/dynamic-grid.component';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { PrioritisationGridFilterValidations } from '../../alc/prioritisation-grid/prioritisation-grid-filter-validations';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { IPressureMonitoringDto } from '../../shared/model/pressure-monitoring/pressure-monitoring.dto';

const COMPONENT_SELECTOR = 'wlm-pressure-monitoring-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './pressure-monitoring-page.component.html',
  styleUrls: ['./pressure-monitoring-page.component.scss'],
})
export class PressureMonitoringPageComponent
  extends BaseGridPageComponent
  implements OnInit, OnDestroy
{
  private readonly _chartWorkspaceName = 'PressureMonitoringDetailsChart';

  dynamicGridLoaded$: Subject<boolean> = new Subject<boolean>();

  private _dynamicGrid: DynamicGridComponent;
  public get dynamicGrid(): DynamicGridComponent {
    return this._dynamicGrid;
  }
  @ViewChild(DynamicGridComponent) set dynamicGrid(value: DynamicGridComponent) {
    this._dynamicGrid = value;
    if (value) {
      this.dynamicGridLoaded$.next(true);
    }
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'PressureMonitoringPageCrud';
  }

  private _navigations: DropdownNavigationItem[];
  public get navigations(): DropdownNavigationItem[] {
    return this._navigations;
  }

  public set navigations(v: DropdownNavigationItem[]) {
    this._navigations = v;
  }

  private _selectedPMs: IPressureMonitoringDto[];
  public get selectedPMs(): IPressureMonitoringDto[] {
    return this._selectedPMs;
  }
  public set selectedPMs(value: IPressureMonitoringDto[]) {
    this._selectedPMs = value;
    this.updateNavigationParams();
  }

  gridFilters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: IGridSettings;
  gridName = 'PressureMonitoring';
  dynamicGridSettings: DynamicGridSettings;
  grid: GenericGridComponent;

  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  removeSelection$ = new ReplaySubject<void>();

  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  autoLoad: boolean;

  // Filters fields names
  hierarchyElementIdFieldName = 'ancestors';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  filterSettings: FilterGroupSettings;

  filterFields = ['hierarchyElementsIds'];
  filterDetailsParameters: TabDetailPanelParameters;

  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsSubscription$: Subscription;

  canLoad: boolean;

  selectedPM: IPressureMonitoringDto;

  gridSettingsReady$ = new ReplaySubject<IGridSettings>();

  readonly T_SCOPE = `${AppModules.PressureManagement}.${COMPONENT_SELECTOR}`;

  constructor(
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _route: ActivatedRoute,
    private _globalsService: GlobalsService,
    private _authService: AuthorizeService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService,
    private readonly _rightPanelAttributesService: RightPanelAttributesService
  ) {
    super();
  }

  ngOnInit(): void {
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      super.ngOnInit();

      const queryParams = params ? this.getQueryParams(params) : null;
      this.readNavigationParameters(queryParams)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          let params = { ...queryParams };

          if (filterData?.hierarchyFamilyId) {
            params[this.hierarchyElementFamilyFieldName] = filterData.hierarchyFamilyId;
          }

          this.setFilterSettings(params);

          this.updateNavigationParams();
          this.initializeRightPanelComponents();
          this.initializeGridSettings();

          this.dynamicGridLoaded$.subscribe((loaded) => {
            if (!loaded) {
              return;
            }

            if (this.grid) {
              this.clearGridFilters(this.grid, params);
            }

            this.dynamicGrid.genericGridLoaded.subscribe((grid) => {
              this.clearGridFilters(grid, params);
            });
          });
        });
    });
  }

  private initializeGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      disableAutoLoad: false,
      gridSettingName: 'PressureMonitoring',
      persistencyArea: this.persistencyArea,
      type: 'generic',
      usePersistence: true,
    });
  }

  private initializeRightPanelComponents() {
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
    combineLatest([localization$, notificationPermission$]).subscribe(
      ([ts, notificationPermission]) => {
        const panelSettings = new TabDetailPanelSettings();
        panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);

        if (notificationPermission) {
          panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
        }

        this.rightPanelService.setTabSettings(panelSettings);
      }
    );
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  getSelectedPM(selectedPM: IPressureMonitoringDto) {
    this.selectedPM = selectedPM;
    this.sendParameters(selectedPM);
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.Filter:
        this.onApplyFiltersFromPage();
        break;
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onApplyFiltersFromPage(): void {
    this.triggerApply$.next();
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new PrioritisationGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(this.filterFields, filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
      this.persistFilters$.next();
    } else {
      this.canLoad = false;
    }
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.loadGrid(gridFiltersRef);
  }

  onValidityChange(valid: boolean): void {
    this.canLoad = valid;
  }

  onCheckAutoload(): void {
    if (this.gridSettingsSubscription$ && !this.gridSettingsSubscription$.closed) {
      this.gridSettingsSubscription$.unsubscribe();
    }
    this.gridSettingsSubscription$ = this.gridSettingsReady$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings.disableAutoLoad && this.gridFilters?.filters) {
          this.loadGrid();
        }
      });
  }

  loadGrid(gridFiltersRef?: any) {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    if (newGridFilters?.filters?.size > 0) {
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedPMs,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const hierarchyElementId =
      typeof params?.hierarchyElementIds === 'string'
        ? params?.hierarchyElementIds
        : params?.hierarchyElementIds?.[0];

    const hierarchyFamilyId = params?.hierarchyFamilyId;

    if (hierarchyElementId && !hierarchyFamilyId) {
      return this._globalsService.getHeFiltersDataById(hierarchyElementId);
    }

    return of(null);
  }

  private sendParameters(selectedElement) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = new TabDetailPanelParameters();

      parameters.addParameter(
        TabDetailParameterName.hierarchyElementId,
        selectedElement?.hierarchyElementId
      );
      parameters.addParameter(TabDetailParameterName.notification, selectedElement);

      let timeSelectorChartSetting = null;

      if (selectedElement?.hierarchyElementId) {
        const wlmElement = this.getWlmElement(selectedElement);

        timeSelectorChartSetting = new TimeSelectorChartSettings({
          chartSetting: new CustomWorkspaceChartSettings({
            chartType: ChartType.customizable,
            dataParameters: new CustomWorkspaceChartDataParameters({
              startDate: new Date(),
              endDate: new Date(),
              workspaceName: this._chartWorkspaceName,
              elements: [wlmElement],
            }),
            exportFileName: `${this.titleService.getTitle()} ${
              selectedElement?.hierarchyElementId
            }`,
          }),
          includeDefaultPeriods: true,
          defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
        });
      }

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      this.buildRightPanelHeader(selectedElement)
        .pipe(untilDestroyed(this))
        .subscribe((rightPanelHeader) => {
          // Default
          if (!rightPanelHeader && selectedElement) {
            rightPanelHeader = selectedElement.hierarchyElementId
              ? ts['zone-title'] + selectedElement.hierarchyElementId
              : null;
          }
          parameters.addParameter(TabDetailParameterName.elementName, rightPanelHeader);

          this.includeAdditionalParams(parameters);
          this.rightPanelService.setTabParameters(parameters);
        });
    });
  }

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedPMs.map((x) => this.getWlmElement(x));

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements,
      this._chartWorkspaceName
    );
  };

  private getWlmElement(selectedElement: IPressureMonitoringDto): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null,
      EntityTypes.hierarchyElement
    );
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedPMs?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'he-family',
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  private setFilterSettings(params: Params) {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementIdFieldName,
      }),
      hierarchyElementFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementFamilyFieldName,
      }),
      hierarchyElementTypes: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementTypeFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
    });
  }

  private clearGridFilters(grid: GenericGridComponent, params: Params) {
    if (Object.keys(params)?.length) {
      grid.clearFilters();
    }
  }

  private buildRightPanelHeader(selectedElement: IPressureMonitoringDto): Observable<string> {
    return this._rightPanelAttributesService.getRightPanelHeaderFromSettings(
      this.gridSettings,
      selectedElement
    );
  }
}
