import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { BaseService } from 'src/app/common-modules/shared/base.service';

const endpointUrl = '/api/servermodules';
@Injectable()
export class ServerModulesService extends BaseService {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector);
  }

  launchHierarchySummaryMod() {
    return this.httpClient.put(`${this.apiUrl}/he/summary/launchmodule`, null);
  }
}
