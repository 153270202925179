import { Injectable } from '@angular/core';

@Injectable()
export class DynamicLayoutStyleLevelsService {
  private readonly _maxGoldenLayoutLevels = 3;
  private readonly _nativeLayoutSelector = '.golden-layout-host';
  currentLayoutLevel: number = 1;
  private _nextLevelClassFound = false;
  private _currentLevelAssigned = false;

  findNextLevelClass(currentElement: Element): void {
    const parentLayoutElement = currentElement?.closest(this._nativeLayoutSelector);
    if (parentLayoutElement) {
      const parentClasses: DOMTokenList = parentLayoutElement.classList;

      for (let level = 1; level <= this._maxGoldenLayoutLevels; level++) {
        if (parentClasses.contains(this.getDynamicLayoutLevelClass(level))) {
          this.currentLayoutLevel = level + 1;

          break;
        }
      }
    }

    this._nextLevelClassFound = true;
  }

  setSpecificLevel(level: number): void {
    this.currentLayoutLevel = level;
    this._nextLevelClassFound = true;
  }

  applyCurrentLevel(currentElement: Element): void {
    if (this._nextLevelClassFound && !this._currentLevelAssigned) {
      const currentLayoutElement = currentElement?.querySelector(this._nativeLayoutSelector);
      if (currentLayoutElement) {
        const currentLevelClass = this.getDynamicLayoutLevelClass(this.currentLayoutLevel);

        currentLayoutElement.classList.add(currentLevelClass);

        this._currentLevelAssigned = true;
      }
    }
  }

  private getDynamicLayoutLevelClass = (level: number) => `dl-level-${level}`;
}
