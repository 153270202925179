import { Pipe, PipeTransform } from '@angular/core';
import { ImageElement } from '../../shared/model/grid/image-element';

@Pipe({
  name: 'imageIconName',
})
export class ImageIconNamePipe implements PipeTransform {
  constructor() {}

  transform(imageElements: Map<any, ImageElement>, cellValue: any): string {
    const value = cellValue as string;
    return imageElements?.get(value) === undefined
      ? ''
      : (imageElements?.get(value) as ImageElement).iconName;
  }
}
