import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import {
  IFamilyAndRelationsApiDto,
  IFamilyAndRelationsDto,
  IRelationsItemDto,
} from './family-and-relations.dto';

@Injectable()
export class FamiliesAndRelationsCrudService
  extends BaseService
  implements GenericCrudService<IFamilyAndRelationsDto, string>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/hierarchy-family-relations`;
  }

  getAll(): Observable<IFamilyAndRelationsDto[]> {
    return this.httpCacheClient
      .get<IFamilyAndRelationsApiDto[]>(this._entityUrl)
      .pipe(map((elements) => elements.map((element) => this.toFrontendDto(element))));
  }

  create(entity: IFamilyAndRelationsDto): Observable<IFamilyAndRelationsDto> {
    const backendDto = this.toBackendDto(entity);
    return this.httpCacheClient
      .post<IFamilyAndRelationsApiDto>(this._entityUrl, backendDto)
      .pipe(map((entity) => this.toFrontendDto(entity)));
  }

  update(entity: IFamilyAndRelationsDto): Observable<IFamilyAndRelationsDto> {
    const backendDto = this.toBackendDto(entity);
    return this.httpCacheClient
      .put<IFamilyAndRelationsApiDto>(this._entityUrl, backendDto)
      .pipe(map((entity) => this.toFrontendDto(entity)));
  }

  delete(id: string): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: IFamilyAndRelationsDto): string {
    return entity.hierarchyFamilyId;
  }

  private toBackendDto(frontendDto: IFamilyAndRelationsDto): IFamilyAndRelationsApiDto {
    const apiDto: IFamilyAndRelationsApiDto = {
      hierarchyFamilyId: frontendDto.hierarchyFamilyId,
      hierarchyFamilyName: frontendDto.hierarchyFamilyName,
      hierarchyRelations: this.arrayToRelationItems(frontendDto.hierarchyElementTypes),
    };
    return apiDto;
  }

  private toFrontendDto(apiDto: IFamilyAndRelationsApiDto): IFamilyAndRelationsDto {
    const hierarchyElementTypes = this.relationItemsToArray(apiDto.hierarchyRelations);
    const frontendDto: IFamilyAndRelationsDto = {
      hierarchyFamilyId: apiDto.hierarchyFamilyId,
      hierarchyFamilyName: apiDto.hierarchyFamilyName,
      hierarchyElementTypes,
      hierarchyElementTypeNames: hierarchyElementTypes.map((het) => het.hierarchyElementTypeName),
    };
    return frontendDto;
  }

  private relationItemsToArray(relationItems: IRelationsItemDto[]): IHierarchyElementTypeDto[] {
    if (!relationItems) {
      return [];
    }
    const rootElement = relationItems.find((item) => !item.parentElementType).childElementType;
    const results = [rootElement];
    let currentElement = rootElement;
    while (results.length !== relationItems.length) {
      let newCurrentRelation = relationItems.find(
        (item) =>
          item.parentElementType?.hierarchyElementTypeId === currentElement.hierarchyElementTypeId
      );
      if (newCurrentRelation) {
        currentElement = newCurrentRelation.childElementType;
      }
      results.push(currentElement);
    }
    return results;
  }

  private arrayToRelationItems(
    hierarchyElementTypes: IHierarchyElementTypeDto[]
  ): IRelationsItemDto[] {
    if (!hierarchyElementTypes) {
      return [];
    }

    const results = [];
    hierarchyElementTypes.forEach((hierarchyElementType, index) => {
      let parentElementType: IHierarchyElementTypeDto = null;

      if (index !== 0) {
        parentElementType = hierarchyElementTypes[index - 1];
      }

      const relationItem: IRelationsItemDto = {
        parentElementType,
        childElementType: hierarchyElementType,
      };

      results.push(relationItem);
    });
    return results;
  }
}
