import { IChartDataParameters } from './chart-data-parameters';
import { ChartType } from './chart-type.enum';

export abstract class ChartSettings {
  abstract chartType: ChartType;
  dataParameters: IChartDataParameters;
  exportFileName?: string;

  constructor(init: Partial<ChartSettings>) {
    Object.assign(this, init);
  }
}
