<div *ngIf="currentNotification" class="wtr-fx-layout-column">
  <div class="filter-buttons-container">
    <button
      *ngIf="showSaveButton"
      [disabled]="isSaveDisabled()"
      mat-icon-button
      (click)="onSave()"
      class="button-position"
      [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
    >
      <wlm-icon [icon]="'save'"></wlm-icon>
    </button>

    <button
      *ngIf="showSaveButton"
      [disabled]="!this.canSave"
      mat-icon-button
      (click)="resetChanges()"
      class="button-position"
      [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
    >
      <wlm-icon [icon]="'discard-changes'"></wlm-icon>
    </button>
  </div>

  <wlm-notification-details-form
    class="notification-details"
    *ngIf="notificationDetails"
    [discussionId]="discussionId"
    [notification]="currentNotification"
    [submit$]="submit$"
    [restore$]="restore$"
    (loaded)="onFormLoad($event)"
    (valid)="onFormValid($event)"
    (submitResult)="onSubmitResult($event)"
    (canSave)="onCanSave($event)"
  ></wlm-notification-details-form>
</div>
