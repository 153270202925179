import { Component, Inject, Injector, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { IAlarmHistoryDto } from 'src/app/common-modules/dependencies/alarms/alarm-history.dto';
import { AlarmTriggerPointPipe } from 'src/app/common-modules/wlm-grid/pipes/alarm-trigger-point.pipe';
// prettier-ignore

import { Images } from 'src/assets/icons/previous-icons/images';
import { IAlarmSeverityTypeDto } from '../../dependencies/alarms/alarm-severity-type.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '../../dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '../../redux/models/state-widget-settings';
import { AppModules } from '../../shared/app-modules.enum';
import { BaseWidgetComponent } from '../../shared/component/base-widget.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { DateFormats } from '../../shared/localization/date-formats.enum';
import { DateTimeColumnComponent } from '../date-time-column-component/date-time-column.component';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';
import { HistoricalAlarmsGridService } from './historical-alarms-grid.service';

const flatten = (filter) => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

const greaterOrEqualOperator = 'ge';
const lessOrEqualOperator = 'le';

const COMPONENT_SELECTOR = 'wlm-historical-alarms-grid';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './historical-alarms-grid.component.html',
  styleUrls: ['./historical-alarms-grid.component.scss'],
})
export class HistoricalAlarmsGridComponent extends BaseWidgetComponent {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  filters: DataBindingFilters;
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;

  T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;

  private _selectedsAlarms: IAlarmHistoryDto[];
  public get selectedsAlarms(): IAlarmHistoryDto[] {
    return this._selectedsAlarms;
  }
  public set selectedsAlarms(value: IAlarmHistoryDto[]) {
    this._selectedsAlarms = value;
  }

  private _elementId: string;
  public get elementId(): string {
    return this._elementId;
  }
  @Input() public set elementId(v: string) {
    this._elementId = v;
    this.init();
  }

  @Input() startDate = null;
  @Input() endDate = null;

  filter: CompositeFilterDescriptor;
  columnStyle = new Map<string, string>();

  public currentSelection: string[] = [];
  public columnKey = 'alarmId';
  public cellContent: string;
  public dateComponent: DateTimeColumnComponent;

  alarmSeverities: IAlarmSeverityTypeDto[];
  gridSettings: GridSetting;

  public selectedStartDate: FilterDescriptor;

  public alarmsFilteredKeys: string[];

  private _severityColorsToggle = true;
  get severityColorsToggle(): boolean {
    return this._severityColorsToggle;
  }
  set severityColorsToggle(value) {
    this._severityColorsToggle = value;
    this.genericGrid.gridBinding.rebind();
    this.setEnableColorIcon = value ? Images.IconSetColorDisabled : Images.IconSetColorEnabled;
  }
  setEnableColorIcon: string = Images.IconSetColorDisabled;

  clearFiltersIcon: string = Images.IconClearFilter;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _triggerPipe: AlarmTriggerPointPipe,
    private readonly _route: ActivatedRoute,
    public readonly alarmService: HistoricalAlarmsGridService,
    private readonly _gridSettingService: GridSettingsService
  ) {
    super(injector, widgetSettings);
    this._gridSettingService.getGridSettingsByName('HistoricalAlarm').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.elementId = parameters?.parameters?.get(TabDetailParameterName.elementId);
  }
  init(): void {
    if (this.elementId !== null || this.startDate !== null || this.endDate !== null) {
      this.setDataBindingFilter();
    }
  }

  private setDataBindingFilter() {
    const auxFilter = new DataBindingFilters();
    if (this.elementId) {
      auxFilter.addOrUpdateBasicFilter('elementId', this.elementId);
    }
    if (this.startDate && this.startDate?.toString() !== new Date(null).toString()) {
      auxFilter.addOrUpdateBasicFilter('startDateTime', this.startDate, greaterOrEqualOperator);
    }
    if (this.endDate && this.endDate?.toString() !== new Date(null).toString()) {
      auxFilter.addOrUpdateBasicFilter('endDateTime', this.endDate, lessOrEqualOperator);
    }
    this.filters = auxFilter;
  }

  private readNavigationParameters() {
    this.elementId = this.readNavigationParameter('elementId');
    this.startDate = new Date(this.readNavigationParameter('startDate'));
    this.endDate = new Date(this.readNavigationParameter('endDate'));
  }

  private readNavigationParameter(parameterName: string): any {
    return this._route.snapshot.paramMap.get(parameterName);
  }

  setSeverityColors() {
    this.severityColorsToggle = !this.severityColorsToggle;
  }

  getColumnData(field: string, value: any, type: string): any {
    return type === 'formatted-text'
      ? this._triggerPipe.transform(value)
      : type === 'date'
      ? this.dateComponent.getColumnData(field, value, DateFormats.DateTime, type)
      : value;
  }

  public onSelectedKeysChange() {}

  get componentName() {
    return 'HistoricalAlarmsGridComponent';
  }
}
