export class DragListCardSettings {
  fieldLabels: { [key: string]: string };
  fields: string[];
  iconName: string;
  isSvg: boolean;
  isReadOnly = false;
  //Name of the field used to mark the card as error
  errorField?: string;

  constructor(init?: Partial<DragListCardSettings>) {
    Object.assign(this, init);
  }
}
