<ng-template #template let-anchor>
  <span
    >{{ anchor.nativeElement.getAttribute('selector-title') }}<br />
    {{ anchor.nativeElement.getAttribute('selector-subtitle') }}
  </span>
</ng-template>

<kendo-grid-column
  [field]="columnSettings.selectorColumnFilterFieldName"
  [width]="width"
  [format]="format"
  [headerStyle]="headerStyle"
  [style]="style"
  [locked]="locked"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div
      kendoTooltip
      [tooltipTemplate]="template"
      filter="span"
      tooltipClass="kendo-tooltip"
      *ngIf="columnSettings"
    >
      <span
        class="column-cell"
        *ngIf="(dataItem[field] | typeof) == 'string'"
        [attr.selector-title]="dataItem[field] | selectorField: '&nbsp;'"
        [attr.selector-subtitle]="
          dataItem[columnSettings.elementSubtitleFieldName] | selectorField: '- -'
        "
      >
        <div class="column-main-field">
          {{ dataItem[field] | selectorField: '&nbsp;' }}
        </div>

        <div class="column-subtitle-field">
          {{ dataItem[columnSettings.elementSubtitleFieldName] | selectorField: '- -' }}
        </div>
      </span>
      <span
        class="column-cell"
        *ngIf="(dataItem[field] | typeof) !== 'string'"
        [attr.selector-title]="dataItem[field] | async | selectorField: '&nbsp;'"
        [attr.selector-subtitle]="
          dataItem[columnSettings.elementSubtitleFieldName] | selectorField: '- -'
        "
      >
        <div class="column-main-field">
          {{ dataItem[field] | async | selectorField: '&nbsp;' }}
        </div>
        <div class="column-subtitle-field">
          {{ dataItem[columnSettings.elementSubtitleFieldName] | selectorField: '- -' }}
        </div>
      </span>
    </div>
  </ng-template>

  <!-- TODO: Uncomment this to use custom filter. Not working right now. -->

  <!-- <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >

  <wlm-selector-column-filter
      [isPrimitive]="false"
      [mainField]="column.field"
      [secondaryField]="columnSettings.elementSubtitleFieldName"
      [currentFilter]="filter"
      [filterService]="filterService"
    ></wlm-selector-column-filter>
    
  </ng-template> -->

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-text-column-filter
      [field]="column.field"
      [filter]="filter"
      [filterService]="filterService"
    ></wlm-text-column-filter>
  </ng-template>
</kendo-grid-column>
