<h4 class="remove-top-padding">
  {{ T_SCOPE + '.title' | translate }}
</h4>
<div *ngIf="availableItems" class="family-relations-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="family-relations-source">
    <div class="source-list">
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="currentAvailableItems"
        [settings]="sourceListSettings"
        (droppedElement)="onSourceDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-generic-card
            class="single-label-card"
            contentClass="generic-card-small"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-generic-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
  <div class="family-relations-target">
    <!-- Top fixed elements -->
    <div>
      <wlm-drag-list-custom [objectsToOrder]="topItemsData" [settings]="fixedListSettings">
        <ng-template let-rowDetail #card>
          <wlm-generic-card
            class="single-label-card"
            contentClass="generic-card-small"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-generic-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <div class="target-list">
      <!-- Draggable elements -->
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="selectedItems"
        [settings]="targetListSettings"
        (droppedElement)="onTargetDroppedElement($event)"
        (reorderedElements)="onReorderedElements($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-generic-card
            class="single-label-card"
            contentClass="generic-card-small"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-generic-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <!-- Bottom fixed elements -->
    <div>
      <wlm-drag-list-custom [objectsToOrder]="bottomItemsData" [settings]="fixedListSettings">
        <ng-template let-rowDetail #card>
          <wlm-generic-card
            class="single-label-card"
            contentClass="generic-card-small"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-generic-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
