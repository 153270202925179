<wlm-no-results [showNoResult]="elementId && !loading && !customerGroup?.length"></wlm-no-results>
<wlm-spinner-wrapper [expandVertical]="false" [showSpinner]="loading">
  <div class="customer-group-container" *ngIf="elementId">
    <div class="customer-group">
      <mat-grid-list cols="3" rowHeight="40px">
        <mat-grid-tile
          *ngFor="let tile of totalCount"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          ><div class="tile-text">{{ tile.text }}</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="customer-group">
      <mat-grid-list cols="3" rowHeight="40px">
        <mat-grid-tile
          *ngFor="let tile of partialCount"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
        >
          <div class="tile-text">{{ tile.text }}</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</wlm-spinner-wrapper>
