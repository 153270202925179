<div
  *ngIf="thematics && form"
  [ngStyle]="{ display: displayed ? '' : 'none' }"
  class="content-stretch custom-map-filters"
>
  <mat-card>
    <div class="form-container hide-subscript wtr-checkbox-lg wtr-radio-lg">
      <mat-dialog-content [formGroup]="form">
        <div class="main-container">
          <!-- Hierarchy Element Type -->
          <div>
            <mat-form-field class="he-type-field">
              <mat-label>{{ T_SCOPE + '.he-type' | translate }}</mat-label>
              <mat-select required formControlName="heType">
                <mat-option *ngFor="let he of hierarchyElements" [value]="he.id">
                  {{ he.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <hr class="title-separator" />

          <!-- Enable Toggle -->
          <div class="item thematic-map-toggle">
            <mat-slide-toggle
              [labelPosition]="'before'"
              [matTooltip]="T_SCOPE + '.thematic-map' | translate"
              [checked]="this.form.valid"
              (change)="onChangeEnableThematics($event)"
            >
              {{ T_SCOPE + '.thematic-map' | translate | uppercase }}
            </mat-slide-toggle>
          </div>

          <!-- Thematic KPIs -->
          <mat-radio-group [labelPosition]="'before'" formControlName="kpi">
            <div *ngFor="let thematic of thematics">
              <div class="filter-item-title">
                {{ translationMapping[thematic.categoryKey] | translate | uppercase }}
              </div>
              <div>
                <mat-radio-button
                  class="item radio-list"
                  *ngFor="let kpi of thematic.values"
                  [value]="kpi"
                  (change)="radioChange(thematic.categoryKey)"
                >
                  {{ kpi | kpiTitle | async }}
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </mat-dialog-content>
    </div>
  </mat-card>
</div>
