<div id="center-page">
  <div class="filter-buttons-container">
    <button
      mat-icon-button
      class="button-position"
      *ngIf="gridSettings"
      [matTooltip]="T_SCOPE + '.clear-all-label' | translate | lowercase"
      (click)="onClickGridBtns($event)"
      onclick="this.blur()"
    >
      <mat-icon svgIcon="reset-filters"></mat-icon>
    </button>
  </div>
  <div id="center-container">
    <wlm-algorithm-selection-grid-filter
      [algorithmFilterConfiguration]="algorithmFilterConfiguration"
      [clearAll$]="clearAll$"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="loadGrid()"
    ></wlm-algorithm-selection-grid-filter>

    <wlm-local-grid
      class="generic-grid"
      #algorithmsGrid
      *ngIf="gridSettings"
      [persistencyArea]="algorithmFilterConfiguration.persistencyArea"
      [storageLocation]="algorithmFilterConfiguration.algorithmSettings.storageLocation"
      [gridSettings]="gridSettings"
      [gridData]="data"
    ></wlm-local-grid>
  </div>
</div>
