import { Component, OnInit, inject } from '@angular/core';
import { BaseSpinnerOverlayComponent } from '../base-spinner-overlay.component';
import { SpinnerService } from '../spinner.service';

const COMPONENT_SELECTOR = 'wlm-spinner-overlay';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayComponent extends BaseSpinnerOverlayComponent implements OnInit {
  private readonly _spinnerService = inject(SpinnerService);

  ngOnInit(): void {}

  onSelfClose(): void {
    this._spinnerService.forceCloseLoading();
  }
}
