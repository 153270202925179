import { Injectable, Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { AlgorithmsService } from 'src/app/common-modules/shared/services/algorithms.service';
import { DvAlgorithmsSelectorItemSettings } from '../models/dv-algorithms-selector-item-settings';

@Injectable()
export class DataVisualizationService extends BaseService {
  private readonly _algNamesTsKey = 'algorithms.algorithm-names';
  private readonly _algFamilyNamesTsKey = 'algorithms.algorithm-family-names';

  constructor(
    injector: Injector,
    private _arrayHelperService: ArrayHelperService,
    private _localizationService: LocalizationHelperService,
    private _sharedConstants: SharedConstantsService,
    private _algorithmsService: AlgorithmsService
  ) {
    super(injector);
  }

  getAlgorithmSelectorSettings(
    algsByFamily: Map<string, IAlgorithmDto[]>
  ): Observable<DvAlgorithmsSelectorItemSettings[]> {
    const algNamesTranslations$ = this._localizationService.get(this._algNamesTsKey);
    const algFamiliesTranslations$ = this._localizationService.get(this._algFamilyNamesTsKey);
    const timeAggregations$ = this._sharedConstants.getTimeAggregationArray();

    return combineLatest([algNamesTranslations$, algFamiliesTranslations$, timeAggregations$]).pipe(
      map(([algNames, algFamilyNames, timeAggregations]) => {
        const tsWidgetLabel = (key) => {
          try {
            const ts = algNames[key];
            return ts ?? key;
          } catch {
            return key;
          }
        };

        const tsFamilyName = (key, defaultValue) => {
          try {
            const ts = algFamilyNames[key];
            return ts ?? defaultValue;
          } catch {
            return defaultValue;
          }
        };

        const tsTimeAggregation = (key) => {
          try {
            const ts = timeAggregations.find((t) => t.value === key);
            return ts?.label ?? '';
          } catch {
            return '';
          }
        };

        let selectorItems: DvAlgorithmsSelectorItemSettings[] = [];

        algsByFamily.forEach((af) => {
          if (!af.length) {
            return;
          }

          const { algorithmFamilyId, algorithmFamilyName } = af[0];
          const familyName = tsFamilyName(algorithmFamilyId.toUpperCase(), algorithmFamilyName);

          const items = af.map((a) => {
            return {
              id: a.algorithmId,
              label: `${tsWidgetLabel(a.algorithmShortName)} - ${a.algorithmShortName}`,
              family: familyName,
              timeAggregation: tsTimeAggregation(a.timeAggregationId),
              group: familyName,
              dimensionId: a.dimensionTypeId,
            } as DvAlgorithmsSelectorItemSettings;
          });

          selectorItems = selectorItems.concat(items);
        });

        return selectorItems;
      })
    );
  }

  getAlgorithmsByFamily(): Observable<Map<string, IAlgorithmDto[]>> {
    const orderByFamilyKey = (item: IAlgorithmDto) => item.algorithmFamilyName;
    const orderByDescriptionKey = (item: IAlgorithmDto) => item.algorithmDescription;

    return this._algorithmsService.getAlgorithms().pipe(
      map((array) => {
        array = this._arrayHelperService.sortObjects(array, orderByFamilyKey);

        return asEnumerable(array)
          .GroupBy((a) => a.algorithmFamilyName)
          .ToDictionary(
            (grp) => grp.key,
            (grp) =>
              this._arrayHelperService.sortObjects(Array.from(grp.values()), orderByDescriptionKey)
          );
      })
    );
  }
}
