import { GChartTextStyles } from './g-chart-text-styles';
import { GChartTooltipAxisPointer } from './g-chart-tooltip-axis-pointer';

export class GChartTooltip {
  show? = true;
  triggerOn?: 'mousemove' | 'click' | 'mousemove|click' | 'none' = 'mousemove';
  hideDelay? = 300;
  alwaysShowContent? = false;
  className?: string;
  formatter?: string | Function;
  textStyle?: GChartTextStyles;
  trigger?: 'axis' | 'item' = 'axis';
  axisPointer? = new GChartTooltipAxisPointer({ type: 'line' });
  borderColor?: string;
  position?: 'top';
  confine? = true;
  backgroundColor?: string;

  constructor(init: Partial<GChartTooltip>) {
    Object.assign(this, init);
  }
}
