// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { EligibilityPopupComponent } from 'src/app/common-modules/shared/component/eligibility-popup/eligibility-popup.component';
import { EligibilityPopupDimensions } from 'src/app/common-modules/shared/constants/eligibility.constants';
import { ALCPrioritisationView } from '../../dependencies/alc/prioritisation-view.dto';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-eligibility-column';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './eligibility-column.component.html',
  styleUrls: ['./eligibility-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => EligibilityColumnComponent),
    },
  ],
})
export class EligibilityColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() selectedRows: ALCPrioritisationView[] = [];

  private _useLatestCalculationDay: boolean = true;
  public get useLatestCalculationDay(): boolean {
    return this._useLatestCalculationDay;
  }
  @Input() public set useLatestCalculationDay(value: boolean) {
    this._useLatestCalculationDay = value;
    this.checkDisabledButton();
  }

  @Input() elegibilityDate: Date;

  @Output() eligbilityPopupClosed = new EventEmitter<boolean>();

  isDisabled = false;
  hasPermission = false;

  buttonStyleWidth = 'width: 80px;';

  private readonly _relationRatio = 8;

  constructor(private _editEligibilityDialog: MatDialog, private _authService: AuthorizeService) {
    super();
  }

  getLabelText(dataitem, field): string {
    const value = dataitem[field];
    return this.elements.get(value === undefined || value === null ? 'null' : value);
  }

  updateEligibility(data) {
    this.openEligibilityPopup(data as ALCPrioritisationView);
  }

  openEligibilityPopup(itemRow) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = EligibilityPopupDimensions.Width;
    dialogConfig.data = {
      prioritisations: itemRow === undefined ? this.selectedRows : [itemRow],
      user: '',
      referenceDate: this.elegibilityDate
    };

    const popup = this._editEligibilityDialog.open(EligibilityPopupComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.eligbilityPopupClosed.emit(true);
        }
      });
  }

  ngOnInit() {
    this._authService.canAccess('PrioritisationCrud', 'u').subscribe({
      next: (can) => {
        this.hasPermission = can;
        this.checkDisabledButton();
      },
    });
    this.calculateElegibilityButtonWidth();
  }

  private calculateElegibilityButtonWidth() {
    if (this.elements?.size) {
      const longestTextLength = Array.from(this.elements.values()).reduce((a, b) =>
        a.length < b.length ? b : a
      ).length;
      const adjustedWidth = longestTextLength * this._relationRatio;
      this.buttonStyleWidth = `width: ${adjustedWidth}px;`;
    }
  }

  private checkDisabledButton() {
    this.isDisabled = !(this.hasPermission && this.useLatestCalculationDay);
  }
}
