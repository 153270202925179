<form *ngIf="form" [formGroup]="form" style="width: 100%" class="fixed-subscript">
  <div class="row">
    <div class="col-xs-4">
      <mat-form-field
        *ngIf="filteredFieldsHash['waterBalanceType']"
        [appearance]="fieldAppearance"
        class="field-stretch field-no-padding-bottom"
      >
        <mat-label>{{ 'common.water-balance-types-label' | translate }}</mat-label>
        <mat-select formControlName="waterBalanceType">
          <mat-option *ngFor="let wbType of waterBalanceTypes" [value]="wbType.value">
            {{ wbType.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          <wlm-validation-messages
            [control]="form.controls.waterBalanceType"
          ></wlm-validation-messages>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-4">
      <mat-form-field
        *ngIf="filteredFieldsHash['dimensionTypeId']"
        [appearance]="fieldAppearance"
        class="field-stretch field-no-padding-bottom"
      >
        <mat-label>{{ 'common.dimension-types-label' | translate }} </mat-label>
        <mat-select formControlName="dimensionTypeId">
          <mat-option *ngFor="let dimension of dimensionTypes" [value]="dimension.value">
            {{ dimension.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          <wlm-validation-messages
            [control]="form.controls.dimensionTypeId"
          ></wlm-validation-messages>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-4">
      <mat-form-field
        *ngIf="filteredFieldsHash['currencyTypeId']"
        [appearance]="fieldAppearance"
        class="field-stretch field-no-padding-bottom"
      >
        <mat-label>{{ 'common.currency-types-label' | translate }}</mat-label>
        <mat-select formControlName="currencyTypeId">
          <mat-option *ngFor="let currencyType of currencyTypes" [value]="currencyType.value">
            {{ currencyType.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          <wlm-validation-messages
            [control]="form.controls.currencyTypeId"
          ></wlm-validation-messages>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
