export class HistoricalBarSettings {
  titleKey: string;
  items: boolean[];
  limit: number;

  constructor(titleKey: string, items: boolean[], limit: number = 7) {
    this.titleKey = titleKey;
    this.items = items;
    this.limit = limit;
  }
}
