<wlm-grid-buttons
  *ngIf="gridSettings && gridFiltersForBinding"
  [(gridSettings)]="gridSettings"
  [gridFilters]="gridFiltersForBinding"
  [grid]="grid"
  [responsive]="false"
>
  <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
    <wlm-nav-dropdown
      class="nav-dropdown"
      [isContainerCollapsed]="isContainerCollapsed"
      [navigationsItems]="navigations"
    ></wlm-nav-dropdown>
  </ng-template>
</wlm-grid-buttons>

<wlm-dynamic-grid
  class="generic-grid"
  *ngIf="settings && gridFiltersForBinding"
  [dynamicGridSettings]="settings"
  [dataBindingFilters]="gridFiltersForBinding"
  [removeSelectionPersisted$]="removeSelectionPersisted$"
  [removeSelection$]="removeSelection$"
  [(selectedItems)]="selectedActivities"
  (genericGridLoaded)="onGridLoaded($event)"
  (gridSettingsLoaded)="getGridSettings($event)"
></wlm-dynamic-grid>
