<div class="filter-buttons-container">
  <wlm-grid-buttons
    *ngIf="gridSettings && alarmTypeId"
    [(gridSettings)]="gridSettings"
    [gridFilters]="filters"
    [grid]="alarmsRelatedElementsGrid"
  >
  </wlm-grid-buttons>
</div>

<wlm-generic-grid
  class="grid"
  #alarmsRelatedElementsGrid
  *ngIf="alarmTypeId"
  [gridSettings]="gridSettings"
  [gridFiltersForBinding]="filters"
  [showSelectedList]="true"
  [showSelectedItems]="false"
></wlm-generic-grid>
