<wlm-popup-wrapper [titleKey]="T_SCOPE + '.title'" [showSpinner]="isSaving" (popupClose)="close()">
  <ng-container popup-content>
    <div class="json-container fix-field-label-top-overlap">
      <mat-form-field class="field-stretch">
        <mat-label>{{ T_SCOPE + '.select-settings' | translate }}</mat-label>
        <mat-select [formControl]="selectorCtrl">
          @for (item of selectorOptions; track item) {
          <mat-option [value]="item.value">{{ item.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <wlm-json-editor
        class="json-editor"
        [validationSchema]="schema"
        [initialData]="settingsData"
        (onJsonHasError)="onJsonHasError($event)"
      ></wlm-json-editor></div
  ></ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      class="wtr-action-button-primary"
      onclick="this.blur()"
      [disabled]="hasErrors"
      (click)="save()"
    >
      {{ 'common.save-button-tooltip' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
