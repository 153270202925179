import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

import { IAudit } from '../models/audit';
import { IAuditAttribute } from '../models/audit-attribute';

@Injectable({
  providedIn: 'root',
})
export class AuditsService extends GridODataService<IAudit> {
  get apiUrl() {
    return `${this.baseUrl}/api/log/audit`;
  }

  constructor(injector: Injector) {
    super(injector, '/api/log/audit');
  }

  protected mapResponse(response: PagedResultDto<IAudit>): PagedResultDto<IAudit> {
    return response;
  }

  getAuditAttributes(auditId: string): Observable<IAuditAttribute[]> {
    return this.httpCacheClient.get(`${this.apiUrl}/${auditId}/attributes`, {
      avoid: false,
    });
  }
}
