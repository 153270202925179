<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters" [ngClass]="containerClass ?? ''">
    <div class="pickers-container">
      <ng-content select="[date-filter-button]"> </ng-content>
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bf1Settings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-date-range-filter-item
          [startFieldName]="filterConfiguration.startDateFieldName"
          [endFieldName]="filterConfiguration.endDateFieldName"
          [dateRange]="filterConfiguration.dateRange"
          [defaultDateRange]="filterConfiguration.defaultDateRange"
          [settings]="dateRangeItemSettings"
        ></wlm-date-range-filter-item>
      </wlm-base-filter>
    </div>

    <div class="tree-filter">
      <ng-content select="[he-filter-button]"> </ng-content>
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bf2Settings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-hierarchy-family-filter-item
          [fieldName]="filterConfiguration.hierarchyElementFamilyFieldName"
          [selectedIds]="[filterConfiguration.hierarchyElementFamily]"
          [filterText]="filterText"
          [settings]="hierarchyFamilyItemSettings"
          (select)="onFamilyIdSelect($event)"
        >
        </wlm-hierarchy-family-filter-item>
        <wlm-hierarchy-tree-filter-item
          [hierarchyElementIdFieldName]="filterConfiguration.hierarchyElementIdFieldName"
          [hierarchyFamilyIdFieldName]="filterConfiguration.hierarchyElementFamilyFieldName"
          [hierarchyFamilyId]="hierarchyElementFamilyId"
          [initialSelectedKeys]="filterConfiguration.hierarchyElementIds"
          [filterText]="filterText"
          [sendRootLevel]="filterConfiguration.sendRootLevel"
          [(treeSettings)]="treeSettings"
          [settings]="hierarchyTreeItemSettings"
          (filterReady)="onTreeFilterReady()"
        ></wlm-hierarchy-tree-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
