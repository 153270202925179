import { NotificationAttributeDto } from './notification-attribute.dto';

export class NotificationDto {
  notificationId: string;
  notificationTitle: string;
  notificationDescription: string;
  notificationStatus: number;
  notificationType: number;
  criticality: number;
  creationDate: Date;
  createdBy: string;
  targetDate: Date;
  discussionId: string | null;
  closuredBy: string;
  closuredDate: Date | null;
  lastUpdate: Date;
  to: string[] | null;
  groups: string[] | null;
  read: boolean | null;
  follow: boolean | null;
  hierarchyElements?: string[];
  networkElements?: string[];
  alarms?: string[];
  points?: string[];
  attributes?: NotificationAttributeDto[];

  constructor(init?: Partial<NotificationDto>) {
    Object.assign(this, init);
  }
}
