import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHierarchyFamilyDto } from 'src/app/common-modules/dependencies/he/hierarchy-family.dto';
import { HierarchyFamilyFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/hierarchy-family-filter';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';

const COMPONENT_SELECTOR = 'wlm-hierarchy-family-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-family-filter.component.html',
  styleUrls: ['./hierarchy-family-filter.component.scss'],
})
export class HierarchyFamilyFilterComponent implements OnInit {
  hierarchyFamilies: IHierarchyFamilyDto[];
  public errorMessage: string;

  private _hierarchyFamilyId: string;
  public get hierarchyFamilyId(): string {
    return this._hierarchyFamilyId;
  }
  @Input() public set hierarchyFamilyId(v: string) {
    this._hierarchyFamilyId = v;
    this.hierarchyFamilyIdChange.emit(this.hierarchyFamilyId);
    this.hierarchyFamilyFilterChanged.emit(
      new HierarchyFamilyFilter(this.hierarchyFamilyFieldName, this.hierarchyFamilyId)
    );
  }

  @Output() hierarchyFamilyIdChange = new EventEmitter<string>();
  @Input() hierarchyFamilyFilter: HierarchyFamilyFilter;
  @Output() hierarchyFamilyFilterChanged = new EventEmitter<HierarchyFamilyFilter>();
  @Input() hierarchyFamilyFieldName = 'hierarchyFamilyId';

  selectedHierarchyFamilyId: string;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  constructor(private globalService: GlobalsService) {}

  ngOnInit(): void {
    this.initializeFamilies();
  }

  initializeFamilies() {
    this.globalService.getHierarchyFamilies().subscribe({
      next: (hierarchyFamilies) => {
        this.hierarchyFamilies = hierarchyFamilies;
        if (this.hierarchyFamilyId) {
          this.selectedHierarchyFamilyId = this.hierarchyFamilyId;
        }
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  public familyChange(newHierarchyFamilyId: any): void {
    this.hierarchyFamilyId = newHierarchyFamilyId?.value;
  }
}
