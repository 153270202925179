import { Injectable, Injector } from '@angular/core';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateSelectorBuilder } from 'src/app/common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from 'src/app/common-modules/redux/store/base.store';

import { DefaultParamsState } from './default-params-state';
import { DefaultParamsActionTypes } from './default-params.action-types';
import { ApplyDefaultParamsAction, ResetDefaultParamsAction } from './default-params.actions';
import { DefaultParamsSelectorTypes } from './default-params.selector-types';

@Injectable()
export class DefaultParamsStoreService extends BaseStore {
  readonly serviceName = 'DefaultParamsStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        DefaultParamsActionTypes.ApplyDefaultParams,
        (state: DefaultParamsState, action: ApplyDefaultParamsAction) => {
          state.widgetInstanceKey = action.payload.widgetInstanceKey;
          state.filters = action.payload.filters;
          state.params = action.payload.params;
          state.lockFilters = action.payload.lockFilters;
          state.title = action.payload.title;
        },
      ],
      [
        DefaultParamsActionTypes.ResetDefaultParams,
        (state: DefaultParamsState, _: ResetDefaultParamsAction) => {
          state.widgetInstanceKey = null;
          state.filters = null;
          state.params = null;
          state.lockFilters = null;
        },
      ],
    ]);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        DefaultParamsSelectorTypes.GetAppliedDefaultParams,
        {
          fn: (state: DefaultParamsState) => {
            return state;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return [
      createAction(
        DefaultParamsActionTypes.ApplyDefaultParams,
        props<{ payload: DefaultParamsState; settings: StateFullSettings }>()
      ),
      createAction(
        DefaultParamsActionTypes.ResetDefaultParams,
        props<{ payload: null; settings: StateFullSettings }>()
      ),
    ];
  }
}
