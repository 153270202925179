<wlm-popup-wrapper
  [titleKey]="'common.edit-grid-settings'"
  [showSpinner]="isSaving"
  (popupClose)="close()"
>
  <ng-container popup-content>
    <div class="json-container">
      <wlm-json-editor
        class="json-editor"
        *ngIf="gridSettings"
        [initialData]="gridSettings"
        (onJsonHasError)="onJsonHasError($event)"
      ></wlm-json-editor></div
  ></ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      class="wtr-action-button-primary"
      onclick="this.blur()"
      [disabled]="hasErrors"
      (click)="save()"
    >
      {{ 'common.save-button-tooltip' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
