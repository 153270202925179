import { ChartSettings } from './chart-settings';
import { ChartType } from './chart-type.enum';
import { CustomWorkspaceChartDataParameters } from './custom-workspace-chart-data-parameters';

export class CustomWorkspaceChartSettings extends ChartSettings {
  dataParameters: CustomWorkspaceChartDataParameters;
  chartType: ChartType = ChartType.customizable;

  public constructor(init?: Partial<CustomWorkspaceChartSettings>) {
    super(init);

    Object.assign(this, init);
  }
}
