import { Component, Input, OnInit } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-no-results';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements OnInit {
  T_SCOPE = `common.messages`;

  @Input() showNoResult: boolean;

  constructor() {}

  ngOnInit(): void {}
}
