import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';

const COMPONENT_SELECTOR = 'wlm-autocomplete-standalone';

type TOption = any;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './autocomplete-standalone.component.html',
  styleUrls: ['./autocomplete-standalone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteStandaloneComponent {
  @Input() elements: TOption[];
  @Input() labelFn: (option: TOption) => string;
  @Input() valueFn: (option: TOption) => any;

  @Output() optionSelected = new EventEmitter<TOption>();
  @ViewChild('autocomplete') matAutocomplete: MatAutocomplete;

  readonly matTooltipShowDelay = 1000;

  onOptionClicked(event: Event, element: TOption): void {
    event.stopPropagation();
    this.optionSelected.emit(element);
  }

  isSelected(element: TOption): boolean {
    return this.elements.some((e) => this.valueFn(e) === this.valueFn(element));
  }
}
