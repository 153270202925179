import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHierarchyElementTypes } from 'src/app/common-modules/dependencies/he/hierarchy.constants';
import { SignalsService } from 'src/app/common-modules/dependencies/signals/signals.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import {
  DataValidationEstimationFunctionType,
  DataValidationOffsetType,
  DataValidationSelectionType,
  DATA_VALIDATION_ESTIMATION_FUNCTIONS,
  DATA_VALIDATION_OFFSET_TYPES,
} from 'src/app/common-modules/wlm-charts/core/models/data-validation/data-validation-functions';
import { EstimationFunction } from 'src/app/common-modules/wlm-charts/core/models/data-validation/estimation-function';
import { OffsetType } from 'src/app/common-modules/wlm-charts/core/models/data-validation/offset-types';
import { EditableChartDateRange } from 'src/app/common-modules/wlm-charts/core/models/editable-chart/editable-chart-date-range';
import { EditableChartSelection } from 'src/app/common-modules/wlm-charts/core/models/editable-chart/editable-chart-selection';
import { BrushMode } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-brush';
import { DataValidationChartService } from '../services/data-validation-chart.service';

const COMPONENT_SELECTOR = 'wlm-data-validation-date-ranges';
@UntilDestroy()
@Component({
  selector: 'wlm-data-validation-date-ranges',
  templateUrl: './data-validation-date-ranges.component.html',
  styleUrls: ['./data-validation-date-ranges.component.scss'],
})
export class DataValidationDateRangesComponent implements OnInit {
  @Input() minDate;
  @Input() maxDate;

  private _valueRanges: EditableChartDateRange[];

  get dateRanges(): EditableChartDateRange[] {
    return this._valueRanges;
  }

  @Input() set dateRanges(value: EditableChartDateRange[]) {
    this._valueRanges = value;
    if (value) {
      this.updateRangesInForm();
      this.clearErrorCollections();
    }
  }

  private _showCart: boolean;

  get showCart(): boolean {
    return this._showCart;
  }
  @Input() set showCart(value: boolean) {
    this._showCart = value;
    this.loadUomLabels();

    if (!this._showCart) {
      this.dateRanges = [];
      this.dateRangeValue = [];
    }
  }

  @Input() selectedPoint: any;

  @Output() dateRangesChanged = new EventEmitter<EditableChartDateRange[]>();
  @Output() applyDateSelection = new EventEmitter<EditableChartSelection>();

  T_SCOPE = `${AppModules.DataValidation}.${COMPONENT_SELECTOR}`;

  estimationFunctions: EstimationFunction[];
  offsetTypes: OffsetType[];

  isLoading = false;
  rangeForm: UntypedFormGroup;

  dateRangeValue: DateRange[] = [];

  fixedValueLabel: string;
  offsetValueLabel: string;
  offsetLabelTranslation: string;
  valueUnitLabel: string;
  percentageUnitLabel: string;

  estimationFunctionFieldName = 'estimationFunction';
  fixedValueFieldName = 'fixedValue';
  rangeStartFieldname = 'rangeStart';
  rangeEndFieldname = 'rangeEnd';
  alternativePointFieldName = 'copySignalId';
  alternativePointDateFieldName = 'fromDate';
  offsetTypeFieldName = 'offsetPercentage';
  offsetValueFieldName = 'offsetValue';
  errorList: Set<string> = new Set<string>();
  pickerStartIds: string[] = [];
  pickerEndIds: string[] = [];
  allowsDateNull = false;
  rangeErrors: Map<number, string[]> = new Map<number, string[]>();

  get fixedEstimationSelected() {
    return (
      this.rangeForm?.get(this.estimationFunctionFieldName)?.value ===
      DataValidationEstimationFunctionType.Fixed
    );
  }

  get alternativePointEstimationSelected() {
    return (
      this.rangeForm?.get(this.estimationFunctionFieldName)?.value ===
      DataValidationEstimationFunctionType.CopySignal
    );
  }

  defaultTime = [0, 0, 0];

  private readonly _minDateOffset = 12;
  private readonly _defaultTimeAggregation = 1;

  private get fixedValueCtrl() {
    return this.rangeForm.get(this.fixedValueFieldName);
  }

  private get alternativePointCtrl() {
    return this.rangeForm.get(this.alternativePointFieldName);
  }

  private get alternativePointDateCtrl() {
    return this.rangeForm.get(this.alternativePointDateFieldName);
  }

  private get offsetTypeCtrl() {
    return this.rangeForm.get(this.offsetTypeFieldName);
  }

  private get offsetValueCtrl() {
    return this.rangeForm.get(this.offsetValueFieldName);
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogService: DialogService,
    private _dataValidationChartService: DataValidationChartService,
    private _translateService: TranslateService,
    private _signalsService: SignalsService,
    private _dateHelperService: DateHelperService,
    private _uomService: UoMService
  ) {
    this.estimationFunctions = DATA_VALIDATION_ESTIMATION_FUNCTIONS.filter(
      (fn) => fn.selectionType !== DataValidationSelectionType.Value
    );
    this.offsetTypes = DATA_VALIDATION_OFFSET_TYPES;

    this.createForm();
  }

  ngOnInit(): void {
    this.setDefaultDateLimits();
  }

  onDateRangeErrors(errors, index) {
    if (!errors) {
      return;
    }

    const errorKeys = Object.keys(errors)?.map((key) => key.toString());

    this.rangeErrors.set(index, [...errorKeys]);

    const allErrorsReported = Array.from(this.rangeErrors.values()).reduce(
      (acc, actual) => acc.concat(actual),
      []
    );

    this.errorList = new Set(allErrorsReported);
  }

  onDateRangeChanged(updatedRange, index) {
    this.dateRangeValue[index] = updatedRange;
    this.dateRanges = this.getDateRangeFromDateRangeValue();
    this.dateRangesChanged.emit(this.dateRanges);
  }

  onRemoveRange(index: number) {
    this.dateRangeValue.splice(index, 1);
    this.dateRanges = this.getDateRangeFromDateRangeValue();

    this.dateRangesChanged.emit(this.dateRanges);
  }

  onApplyFunction() {
    this.getEditableChartSelectionModel()
      .pipe(untilDestroyed(this))
      .subscribe((selection) => {
        const alternativePoint = selection.formValues[this.alternativePointFieldName];
        if (alternativePoint) {
          this.validateAlternativePoint(selection, alternativePoint);
        } else {
          this.applyDateSelection.emit(selection);
          this.dateRanges = [];
        }
      });
  }

  private validateAlternativePoint(
    cartSelection: EditableChartSelection,
    alternativePoint: string
  ) {
    this._signalsService
      .getPointInfo(alternativePoint)
      .pipe(untilDestroyed(this))
      .subscribe((pointInfo) => {
        if (!pointInfo) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.invalid-alt-point`,
              icon: 'error',
            })
          );
        } else if (pointInfo.dimensionTypeId !== this.selectedPoint.dimensionTypeId) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.point-dimension-mismatch`,
              icon: 'error',
            })
          );
        } else {
          cartSelection.formValues[this.alternativePointFieldName] = pointInfo.signalId;
          this.applyDateSelection.emit(cartSelection);
          this.dateRanges = [];
        }
      });
  }

  private getEditableChartSelectionModel(): Observable<EditableChartSelection> {
    const functionType = this.rangeForm.get(this.estimationFunctionFieldName).value;
    const estimationFunction = this.estimationFunctions.find((f) => f.type === functionType);
    const dateRanges = this.getDateRangesFromDateRangeValues();

    //only this function types require UoM conversions
    if (
      functionType === DataValidationEstimationFunctionType.Fixed ||
      functionType === DataValidationEstimationFunctionType.CopySignal
    ) {
      return this.convertFromUoMAndSetSelectionModel(dateRanges, estimationFunction);
    } else {
      const selection = new EditableChartSelection({
        selectionType: BrushMode.Horizontal,
        ranges: dateRanges,
        rangesConverted: dateRanges,
        estimationFunction: estimationFunction,
        formValues: {},
      });

      return of(selection);
    }
  }

  private convertFromUoMAndSetSelectionModel(
    dateRanges: EditableChartDateRange[],
    estimationFunction: EstimationFunction
  ): Observable<EditableChartSelection> {
    const fixedValue = this.fixedValueCtrl.value;
    const alternativePoint = this.alternativePointCtrl.value;
    const fromDate = this.alternativePointDateCtrl.value;
    const offsetType = this.offsetTypeCtrl.value;
    const offsetValue = this.offsetValueCtrl.value;

    return this.convertValuesFromUoM(fixedValue, offsetType, offsetValue).pipe(
      map(([fixedValueConverted, offsetConverted]) => {
        const formValues = {
          fixedValue: fixedValueConverted,
          copySignalId: alternativePoint,
          fromDate: fromDate,
          offsetPercentage: offsetType === DataValidationOffsetType.Percentage,
          offsetValue: offsetConverted,
        };

        return new EditableChartSelection({
          selectionType: BrushMode.Horizontal,
          ranges: dateRanges,
          rangesConverted: dateRanges,
          estimationFunction: estimationFunction,
          formValues: formValues,
        });
      })
    );
  }

  private convertValuesFromUoM(
    fixedValue: number,
    offsetType: DataValidationOffsetType,
    offset: number
  ) {
    const dimensionTypeId = this._dataValidationChartService.dimensionTypeId;
    const percentageTypeId = DimensionTypesEnum.Percentage;

    const fixedValueConverted$ =
      fixedValue !== null && fixedValue !== undefined
        ? this._dataValidationChartService.convertValueFromUoM(fixedValue, dimensionTypeId)
        : of(null);

    const offsetConverted$ =
      offsetType === DataValidationOffsetType.Absolute
        ? this._dataValidationChartService.convertValueFromUoM(offset, dimensionTypeId)
        : offsetType === DataValidationOffsetType.Percentage
        ? this._dataValidationChartService.convertValueFromUoM(offset, percentageTypeId)
        : of(null);

    return forkJoin([fixedValueConverted$, offsetConverted$]);
  }

  private getDateRangesFromDateRangeValues() {
    const rangeValues: EditableChartDateRange[] = [];
    this.dateRangeValue.forEach((range) => {
      rangeValues.push(
        new EditableChartDateRange({
          startDate: range.start,
          endDate: range.end,
        })
      );
    });

    return rangeValues;
  }

  private clearErrorCollections() {
    this.errorList = new Set<string>();
    this.rangeErrors = new Map<number, string[]>();
  }

  private getDateRangeFromDateRangeValue() {
    const rangeValues: EditableChartDateRange[] = [];
    this.dateRangeValue.forEach((range) => {
      rangeValues.push(
        new EditableChartDateRange({
          startDate: range?.start,
          endDate: range?.end,
        })
      );
    });

    return rangeValues;
  }

  private createForm() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    const functionSelectorControl = this.createFunctionSelectorControl();
    formControls[this.estimationFunctionFieldName] = functionSelectorControl;
    formControls[this.fixedValueFieldName] = new UntypedFormControl(null);

    formControls[this.alternativePointFieldName] = new UntypedFormControl(null);
    formControls[this.alternativePointDateFieldName] = new UntypedFormControl(null);
    formControls[this.offsetTypeFieldName] = this.createOffsetTypeControl();
    formControls[this.offsetValueFieldName] = new UntypedFormControl(null);

    this.rangeForm = this._formBuilder.group(formControls);

    this.checkFunctionSelectorEnabledState();
  }

  private createOffsetTypeControl() {
    const newControl = new UntypedFormControl(null);
    newControl.valueChanges.pipe(untilDestroyed(this)).subscribe((offsetType) => {
      if (offsetType === DataValidationOffsetType.Percentage) {
        this.offsetValueLabel = `${this.offsetLabelTranslation}${this.percentageUnitLabel}`;
      }

      if (offsetType === DataValidationOffsetType.Absolute) {
        this.offsetValueLabel = `${this.offsetLabelTranslation}${this.valueUnitLabel}`;
      }
    });
    return newControl;
  }

  private checkFunctionSelectorEnabledState() {
    if (this.dateRangeValue.length) {
      this.rangeForm.get(this.estimationFunctionFieldName).enable();
    } else {
      this.rangeForm.get(this.estimationFunctionFieldName).setValue(null);
      this.rangeForm.get(this.estimationFunctionFieldName).disable();
      this.clearFunctionFields();
    }
  }

  private createFunctionSelectorControl() {
    const newControl = new UntypedFormControl(null, [Validators.required]);

    newControl.valueChanges.pipe(untilDestroyed(this)).subscribe((selectedFunction) => {
      if (selectedFunction === DataValidationEstimationFunctionType.Fixed) {
        this.rangeForm.get(this.fixedValueFieldName).setValidators([Validators.required]);
        this.rangeForm.get(this.alternativePointFieldName).clearValidators();
        this.rangeForm.get(this.alternativePointDateFieldName).clearValidators();
      } else if (selectedFunction === DataValidationEstimationFunctionType.CopySignal) {
        this.rangeForm.get(this.alternativePointFieldName).setValidators([Validators.required]);
        this.rangeForm.get(this.alternativePointDateFieldName).setValidators([Validators.required]);
        this.rangeForm.get(this.fixedValueFieldName).clearValidators();
        this.rangeForm.get(this.alternativePointFieldName).setValue(this.selectedPoint.pointId);
      } else {
        this.rangeForm.get(this.fixedValueFieldName).clearValidators();
        this.rangeForm.get(this.alternativePointFieldName).clearValidators();
        this.rangeForm.get(this.alternativePointDateFieldName).clearValidators();
        this.clearFunctionFields();
      }

      this.rangeForm.get(this.fixedValueFieldName).updateValueAndValidity();
    });

    return newControl;
  }

  private clearFunctionFields() {
    this.rangeForm.get(this.fixedValueFieldName).setValue(null);
    this.rangeForm.get(this.alternativePointFieldName).setValue(null);
    this.rangeForm.get(this.alternativePointDateFieldName).setValue(null);
    this.rangeForm.get(this.offsetTypeFieldName).setValue(null);
    this.rangeForm.get(this.offsetValueFieldName).setValue(null);
  }

  private updateRangesInForm() {
    this.dateRangeValue = [];

    this.dateRanges.forEach((range, index) => {
      const newRange = new DateRange(
        range.startDate,
        range.endDate,
        `${this.rangeStartFieldname}${index}`,
        `${this.rangeEndFieldname}${index}`,
        this.allowsDateNull
      );

      this.dateRangeValue.push(newRange);
    });

    this.checkFunctionSelectorEnabledState();
  }

  private loadUomLabels() {
    const fixedValueLabel$ = this._translateService.get(`${this.T_SCOPE}.fields.value`);
    const offsetValueLabel$ = this._translateService.get(`${this.T_SCOPE}.fields.offset-value`);
    const unit$ = this._dataValidationChartService.getChartUnitLabel();
    const percentageConversionInfo$ = this._uomService.getByParams(
      DimensionTypesEnum.Percentage,
      this._defaultTimeAggregation,
      IHierarchyElementTypes.DMA
    );

    combineLatest([offsetValueLabel$, fixedValueLabel$, unit$, percentageConversionInfo$])
      .pipe(untilDestroyed(this))
      .subscribe(([offsetValueLabel, fixedValueLabel, unit, percentageConversionInfo]) => {
        const unitLabel = unit ? ` [${unit}]` : '';
        const percentageLabel = percentageConversionInfo?.unitTypeToDescription;
        this.fixedValueLabel = `${fixedValueLabel}${unitLabel}`;
        this.offsetValueLabel = offsetValueLabel;
        this.offsetLabelTranslation = offsetValueLabel;
        this.valueUnitLabel = unitLabel;
        this.percentageUnitLabel = percentageLabel ? ` [${percentageLabel}]` : '';
      });
  }

  private setDefaultDateLimits() {
    if (this.maxDate) {
      this.maxDate = this._dateHelperService.getDefaultEndDate();
    }

    if (!this.minDate) {
      this.minDate = this._dateHelperService.getDefaultStartDate(this._minDateOffset);
    }
  }
}
