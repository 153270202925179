import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UsersCachedService } from '../services/users/users-cached.service';
import { LogData } from '../wlm-log/log-data';
import { LogService } from '../wlm-log/log.service';

@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  constructor(private _usersCachedService: UsersCachedService, private _logService: LogService) {}

  transform(userCode: string): Observable<string> {
    if (!userCode) {
      return of('');
    }

    return this._usersCachedService.getCachedData().pipe(
      map((usersHash) => {
        const user = usersHash[userCode];
        const userName = user ? `${user?.name} ${user?.surname}` : '';
        if (!userName.trim() && userCode.toLowerCase() !== 'system') {
          this.logNoUserName(userCode);
        }
        return userName;
      }),
      take(1)
    );
  }
  private logNoUserName(userCode: string): void {
    this._logService.error(
      new LogData({
        msg: `The user code ${userCode} did not have a matching user name.`,
      })
    );
  }
}
