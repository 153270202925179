<h3>{{ T_SCOPE + '.form-title' | translate }}</h3>
<hr class="title-separator" />

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="rows">
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.close-type-label' | translate }}</mat-label>
        <mat-select formControlName="closeTypeControl">
          <mat-option *ngFor="let option of activityCloseTypes" [value]="option.value">{{
            option.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.dry-hole-type-label' | translate }}</mat-label>
        <mat-select formControlName="dryHoleTypeControl" [disabled]="dryHoleTypeDisabled">
          <mat-option *ngFor="let option of dryHoleTypes" [value]="option.value">{{
            option.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="rows">
      <mat-form-field class="comment-field" appearance="outline">
        <mat-label>{{ T_SCOPE + '.comment-label' | translate }}</mat-label>
        <textarea matInput placeholder="comment" formControlName="commentControl"> </textarea>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons-container">
    <button mat-raised-button class="mat-raised-button" (click)="close()">
      {{ 'common.close' | translate }}
    </button>

    <button mat-raised-button class="mat-raised-button mat-primary" (click)="save()">
      {{ 'common.save' | translate }}
    </button>
  </div>
</mat-dialog-actions>
