<wlm-popup-wrapper [titleKey]="T_SCOPE + '.title'" [showSpinner]="isSaving" (popupClose)="close()">
  <ng-container popup-content>
    <div class="json-container">
      <wlm-json-editor
        class="json-editor"
        [initialData]="initialData"
        (onJsonHasError)="onJsonHasError($event)"
      ></wlm-json-editor></div
  ></ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      class="wtr-action-button-primary"
      onclick="this.blur()"
      [disabled]="hasErrors"
      (click)="sendRequest()"
    >
      {{ T_SCOPE + '.buttons.send-request' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
