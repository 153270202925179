<kendo-grid-column [field]="field" [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span *ngIf="!useImages" class="column-cell">
      {{ elements | enumField : dataItem[field] }}
    </span>
    <span *ngIf="useImages" class="image-position">
      <mat-icon *ngIf="dataItem[field]" class="true-definition">done</mat-icon>
      <mat-icon *ngIf="dataItem[field] === false" class="false-definition">clear</mat-icon>
      <div *ngIf="dataItem[field] === null || dataItem[field] === undefined">
        {{ dataItem[field] | gridCellEmpty }}
      </div>
    </span>
  </ng-template>

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-boolean-column-filter
      [field]="column.field"
      [filterService]="filterService"
      [filter]="filter"
      [elements]="elements"
    ></wlm-boolean-column-filter>
  </ng-template>
</kendo-grid-column>
