import { Component, OnInit } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-collapsible-summary-container';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './collapsible-summary-container.component.html',
  styleUrls: ['./collapsible-summary-container.component.scss'],
})
export class CollapsibleSummaryContainerComponent implements OnInit {
  collapsed = false;

  constructor() {}

  ngOnInit(): void {}
}
