<div class="selection-menu-container">
  <div *ngIf="!showSelectAllColumn">
    {{ totalRows }}
  </div>
  <div *ngIf="showSelectedList && showSelectAllColumn">
    {{ totalRows }}
    <button
      mat-button
      class="button-no-side-padding wtr-button-primary selection-menu-button"
      [matMenuTriggerFor]="selectedItemsMenu"
      [disabled]="!selectedItems?.length"
    >
      &nbsp;({{ selectedItems ? selectedItems.length : 0 }} {{ T_SCOPE + '.selected' | translate }})
    </button>
  </div>

  <div *ngIf="!showSelectedList && showSelectAllColumn">
    {{ totalRows }} ({{ selectedItems?.length ? selectedItems.length : 0 }}
    {{ T_SCOPE + '.selected' | translate }})
  </div>
  <mat-menu #selectedItemsMenu="matMenu">
    <div class="menu-container" (click)="stopPropagation($event)">
      <div class="buttons-container">
        <button mat-button class="wtr-button-primary" (click)="removeSelection()">
          {{ T_SCOPE + '.clear' | translate }}
        </button>
        <button
          *ngIf="showSelectedItems"
          mat-button
          class="wtr-button-primary"
          (click)="applySelection()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
      <div
        *ngIf="showSelectedItems && showSelectAllColumn"
        class="items-container"
        [style.overflow]="selectedItems?.length > maxItemsWithoutScroll ? 'auto' : 'hidden'"
      >
        <div
          class="selected-item"
          *ngFor="let selectableItem of selectableItems; trackBy: trackBySelectedItem"
        >
          <span class="checkbox">
            <input type="checkbox" [(ngModel)]="selectableItem.isSelected" kendoCheckBox />
          </span>
          <span class="item-label">{{ selectableItem.item[itemLabelKey] }}</span>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
