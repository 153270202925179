<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background">
    <div class="pickers-container">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bf1Settings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-date-range-filter-item
          [startFieldName]="leaksFilterConfiguration.startDateFieldName"
          [endFieldName]="leaksFilterConfiguration.endDateFieldName"
          [dateRange]="leaksFilterConfiguration.dateRange"
          [defaultDateRange]="leaksFilterConfiguration.defaultDateRange"
          [settings]="dateRangeItemSettings"
        ></wlm-date-range-filter-item>
      </wlm-base-filter>
    </div>

    <div class="tree-filter">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bf2Settings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-hierarchy-family-filter-item
          [fieldName]="leaksFilterConfiguration.hierarchyElementFamilyFieldName"
          [selectedIds]="[leaksFilterConfiguration.hierarchyElementFamily]"
          [filterText]="filterText"
          [settings]="hierarchyFamilyItemSettings"
          (select)="onFamilyIdSelect($event)"
        >
        </wlm-hierarchy-family-filter-item>
        <wlm-hierarchy-tree-filter-item
          [hierarchyElementIdFieldName]="leaksFilterConfiguration.hierarchyElementIdFieldName"
          [hierarchyFamilyIdFieldName]="leaksFilterConfiguration.hierarchyElementFamilyFieldName"
          [hierarchyFamilyId]="hierarchyElementFamilyId"
          [initialSelectedKeys]="leaksFilterConfiguration.hierarchyElementIds"
          [filterText]="filterText"
          [(treeSettings)]="treeSettings"
          [settings]="hierarchyTreeItemSettings"
          (filterReady)="onTreeFilterReady()"
        ></wlm-hierarchy-tree-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
