import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Directive({
  selector: '[wlmInterceptClick]',
})
export class InterceptClickDirective implements OnInit, OnDestroy {
  @Input() set wlmInterceptClick(params: [any, (model: any) => Observable<boolean>]) {
    this._model = params[0];
    this._interceptFn = params[1];
  }

  private _subscription = new Subscription();
  private _model: any;
  private _interceptFn: (model: any) => Observable<boolean>;

  constructor(private _elRef: ElementRef) {}

  ngOnInit() {
    const el: HTMLElement = this._elRef.nativeElement;
    // Stop the "parent -> child" propagation and check if the element should actually be clicked.
    this._subscription = fromEvent(el, 'click', { capture: true })
      .pipe(
        // Stop from the click being triggered by bubbling from the children.
        filter((event: MouseEvent) => {
          const isTarget = event.target !== el;
          return isTarget;
        }),
        tap((event: MouseEvent) => event.stopPropagation()),
        switchMap(() => this._interceptFn(this._model).pipe(take(1)))
      )
      .subscribe((clickEnabled: boolean) => {
        if (clickEnabled) {
          // Perform click on the actual element (s).
          const children = el.children;
          for (var i = children.length; i--; ) {
            (children[i] as HTMLElement).click();
          }
        }
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
