import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { defer, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsService } from '../../shared/config/settings.service';
import { LogService } from '../../shared/wlm-log/log.service';
import { BaseDatabaseService } from '../base-database.service';
import { IHttpCacheResponse } from './http-cache-response';
import { HttpCacheDatabase } from './http-cache.database';

@Injectable({ providedIn: 'root' })
export class HttpCacheService extends BaseDatabaseService<IHttpCacheResponse, string> {
  constructor(
    public db: HttpCacheDatabase,
    private _settingsService: SettingsService,
    private _log: LogService
  ) {
    super(db);
  }

  public clearExpired = async (date: Date = new Date()): Promise<string[]> => {
    const urlsToClean = await this.db.dbTable.where('expirationDate').below(date).primaryKeys();
    const urlsToUpdate = await this.db.dbTable
      .where('expirationDate')
      .below(date)
      .and((x) => x.reload)
      .primaryKeys();

    if (!urlsToClean.length) {
      return;
    }

    console.log(`Clearing Expired above: ${date}`, urlsToClean);
    await this.clearKeys(urlsToClean);
    return urlsToUpdate;
  };

  /**
   * For backwards compatibility.
   */
  public clearContainsInUrl = async (url: string): Promise<string[]> => {
    return this.clearByPayload(url);
  };

  /**
   * Allows to clear urls and return urls to reload based on a payload,
   * which can have different types.
   */
  clearByPayload = async (payload): Promise<string[]> => {
    const isSelectedFn = await this.buildIsSelectedFn(payload);
    const keysToDelete = await this.getSelectedKeys(payload, isSelectedFn);
    const keysToReload = await this.getSelectedKeys(payload, isSelectedFn, true);
    console.log(`Clear Related to: ${payload}`, keysToDelete);
    await this.clearKeys(keysToDelete);
    return keysToReload;
  };

  private buildIsSelectedFn = async (
    payload: any
  ): Promise<(obj: IHttpCacheResponse, payload) => boolean> => {
    if (typeof payload?.instanceId !== 'undefined') {
      return this.isSelectedByInstanceId;
    }
    return this.isSelectedByUrl;
  };

  private isSelectedByUrl = (obj: IHttpCacheResponse, url: string) => obj.url.indexOf(url) >= 0;

  private isSelectedByInstanceId = (obj: IHttpCacheResponse, { instanceId }) => {
    if (!obj.instanceId) {
      this._log.warn({
        msg: 'Requested cache clear by instance but it was not found in endpoint.',
        payload: obj,
      });
    }
    return obj.instanceId === instanceId;
  };

  public setCacheItemsProperties<T>(
    url: string,
    newValues: Map<string, any>,
    cacheDuration: number = null,
    additionalParams: { instanceId?: string } = {}
  ): Observable<string> {
    const cacheData$ = defer(() => this.getById(url));
    return cacheData$.pipe(
      switchMap((items) => {
        if (items) {
          const castedItems = items.body as T[];
          newValues.forEach((value, key) => {
            castedItems.forEach((x) => {
              if (x[key]) {
                x[key] = value;
              }
            });
          });
          if (castedItems?.length) {
            const r: IHttpCacheResponse = {
              url,
              body: castedItems,
              lastModified: null,
              creationDate: dayjs().toDate(),
              expirationDate: dayjs()
                .add(this.cacheDurationOrDefault(cacheDuration), 'minute')
                .toDate(),
              reload: false,
              instanceId: additionalParams?.instanceId,
            };
            return defer(() => this.put(r));
          }
        }
        return of(null);
      })
    );
  }

  /**
   * Allows to select the cached urls to clear and reload by custom functions.
   */
  public getSelectedKeys = async (
    payload,
    isSelectedFn: (obj: IHttpCacheResponse, payload) => boolean,
    reloadFiltered?: boolean
  ): Promise<string[]> => {
    const filter: (obj: IHttpCacheResponse) => boolean = reloadFiltered
      ? (x) => isSelectedFn(x, payload) && x.reload
      : (x) => isSelectedFn(x, payload);

    const keysToUpdate = await this.db.dbTable.filter(filter).primaryKeys();
    return keysToUpdate;
  };

  private cacheDurationOrDefault(cacheDuration: number = null): number {
    let result;
    if (typeof cacheDuration !== 'undefined') {
      result = cacheDuration;
    }
    result = this._settingsService.defaultCacheDurationMinutes ?? 1440;
    return result;
  }

  private clearKeys = async (keysToClear: string[]): Promise<number> => {
    if (!keysToClear.length) {
      return -1;
    }

    for (const key of keysToClear) {
      await this.db.dbTable.delete(key);
    }

    return keysToClear.length;
  };
}
