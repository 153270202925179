<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div class="popup-container" cdkDropListGroup>
    <div class="header-container">
      <h3>{{ T_SCOPE + '.title' | translate }}</h3>

      <div>
        <button
          mat-icon-button
          class="close-top-button"
          (click)="close()"
          onclick="this.blur()"
          color="primary"
          [matTooltip]="'common.close' | translate | lowercase"
        >
          <mat-icon class="material-icons" color="primary">close</mat-icon>
        </button>
      </div>
    </div>

    <div class="form-container">
      <wlm-ne-configuration-boundary-historical-form
        [settings]="formSettings"
        [formModel]="initialFormModel"
        (canSave)="onCanSaveChanged($event)"
        (formChanges)="onEdit($event)"
        (onIsValidChanged)="onIsValidChanged($event)"
      ></wlm-ne-configuration-boundary-historical-form>
    </div>

    <div class="signal-container">
      <wlm-ne-signal-selection-lists
        class="signal-list"
        *ngIf="popupSettings?.signalsListSettings"
        (onLoadingChanged)="onLoadingChanged($event)"
        (configuredSignalsChanged)="onSignalsChange($event)"
        [signalListSettings]="popupSettings.signalsListSettings"
      ></wlm-ne-signal-selection-lists>
    </div>

    <div class="button-container">
      <button mat-raised-button color="warn" class="cancel-button" (click)="close()">
        {{ 'common.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!configuredSignals.length || !canSave"
        (click)="saveConfiguration()"
      >
        {{ 'common.apply' | translate | uppercase }}
      </button>
    </div>
  </div>
</wlm-spinner-wrapper>
