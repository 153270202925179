import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'wlm-custom-blank-field',
  templateUrl: './custom-blank-field.component.html',
  styleUrls: ['./custom-blank-field.component.scss']
})
export class CustomBlankFieldComponent extends FieldType<any>{

}
