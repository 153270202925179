<wlm-hierarchy-element-tree-family-filter
  *ngIf="filterSettings && heCtrl && familyCtrl"
  class="remove-filters-background"
  [settings]="filterSettings"
  [fieldLabelKey]="fieldLabelKey"
  [disabled]="disabled"
  (filtersChanged)="getDataBindingFilters($event)"
  (apply)="onApplyFilters(null)"
  (selectedNodesChange)="onSelectedNodesChanged($event)"
  (autoLoad)="onCheckAutoload()"
></wlm-hierarchy-element-tree-family-filter>
<div class="custom-subscript"></div>
