<p>call-back works!</p>

<button (click)="toggleVisibility()">Toggle Right Panel Visibility</button>
<button (click)="toggleCollapsed()">Toggle Right Panel Expanded</button>
<button (click)="toggleOverlay()">Toggle Right Panel Overlay</button>
<button (click)="toggleHover()">Toggle Right Panel Overlay 2</button>

<p>
  Lorem ipsum dolor sit amet consectetur adipiscing elit, praesent condimentum sodales nam placerat
  pulvinar, netus ornare per commodo mollis imperdiet. Torquent rutrum luctus per arcu iaculis eros
  vitae ultrices montes, integer rhoncus potenti at volutpat suspendisse nunc habitant eget magna,
  fringilla ornare facilisis porttitor tempor sagittis sapien libero. Taciti fames suscipit
  habitasse scelerisque faucibus volutpat dapibus libero torquent primis iaculis per, sem morbi nibh
  hac dui urna phasellus condimentum vivamus orci. Vulputate mattis vehicula suspendisse magna
  tristique imperdiet, proin dictum at ornare in gravida scelerisque, nulla inceptos nisl diam
  senectus.
</p>
<p>
  Laoreet vulputate congue pellentesque mi ornare magnis cras class, a erat lobortis pretium gravida
  pharetra tellus suscipit donec, purus neque sociis nostra non aliquam pulvinar. Himenaeos eget
  quam pulvinar ligula mauris pharetra quis suscipit nulla tincidunt, vel nisl congue nostra
  imperdiet id mi hendrerit consequat. Felis nam vulputate tempor euismod a torquent ullamcorper,
  phasellus cursus tortor quisque fringilla vivamus curabitur, mi ultricies commodo laoreet cum
  inceptos. Auctor neque interdum sem mus mattis congue rutrum, per class faucibus facilisis
  tristique tortor fringilla, est imperdiet dictum ultrices commodo augue.
</p>

<p>
  Lorem ipsum dolor sit amet consectetur adipiscing elit, praesent condimentum sodales nam placerat
  pulvinar, netus ornare per commodo mollis imperdiet. Torquent rutrum luctus per arcu iaculis eros
  vitae ultrices montes, integer rhoncus potenti at volutpat suspendisse nunc habitant eget magna,
  fringilla ornare facilisis porttitor tempor sagittis sapien libero. Taciti fames suscipit
  habitasse scelerisque faucibus volutpat dapibus libero torquent primis iaculis per, sem morbi nibh
  hac dui urna phasellus condimentum vivamus orci. Vulputate mattis vehicula suspendisse magna
  tristique imperdiet, proin dictum at ornare in gravida scelerisque, nulla inceptos nisl diam
  senectus.
</p>
<p>
  Laoreet vulputate congue pellentesque mi ornare magnis cras class, a erat lobortis pretium gravida
  pharetra tellus suscipit donec, purus neque sociis nostra non aliquam pulvinar. Himenaeos eget
  quam pulvinar ligula mauris pharetra quis suscipit nulla tincidunt, vel nisl congue nostra
  imperdiet id mi hendrerit consequat. Felis nam vulputate tempor euismod a torquent ullamcorper,
  phasellus cursus tortor quisque fringilla vivamus curabitur, mi ultricies commodo laoreet cum
  inceptos. Auctor neque interdum sem mus mattis congue rutrum, per class faucibus facilisis
  tristique tortor fringilla, est imperdiet dictum ultrices commodo augue.
</p>

<p>
  Lorem ipsum dolor sit amet consectetur adipiscing elit, praesent condimentum sodales nam placerat
  pulvinar, netus ornare per commodo mollis imperdiet. Torquent rutrum luctus per arcu iaculis eros
  vitae ultrices montes, integer rhoncus potenti at volutpat suspendisse nunc habitant eget magna,
  fringilla ornare facilisis porttitor tempor sagittis sapien libero. Taciti fames suscipit
  habitasse scelerisque faucibus volutpat dapibus libero torquent primis iaculis per, sem morbi nibh
  hac dui urna phasellus condimentum vivamus orci. Vulputate mattis vehicula suspendisse magna
  tristique imperdiet, proin dictum at ornare in gravida scelerisque, nulla inceptos nisl diam
  senectus.
</p>
<p>
  Laoreet vulputate congue pellentesque mi ornare magnis cras class, a erat lobortis pretium gravida
  pharetra tellus suscipit donec, purus neque sociis nostra non aliquam pulvinar. Himenaeos eget
  quam pulvinar ligula mauris pharetra quis suscipit nulla tincidunt, vel nisl congue nostra
  imperdiet id mi hendrerit consequat. Felis nam vulputate tempor euismod a torquent ullamcorper,
  phasellus cursus tortor quisque fringilla vivamus curabitur, mi ultricies commodo laoreet cum
  inceptos. Auctor neque interdum sem mus mattis congue rutrum, per class faucibus facilisis
  tristique tortor fringilla, est imperdiet dictum ultrices commodo augue.
</p>

<p>
  Lorem ipsum dolor sit amet consectetur adipiscing elit, praesent condimentum sodales nam placerat
  pulvinar, netus ornare per commodo mollis imperdiet. Torquent rutrum luctus per arcu iaculis eros
  vitae ultrices montes, integer rhoncus potenti at volutpat suspendisse nunc habitant eget magna,
  fringilla ornare facilisis porttitor tempor sagittis sapien libero. Taciti fames suscipit
  habitasse scelerisque faucibus volutpat dapibus libero torquent primis iaculis per, sem morbi nibh
  hac dui urna phasellus condimentum vivamus orci. Vulputate mattis vehicula suspendisse magna
  tristique imperdiet, proin dictum at ornare in gravida scelerisque, nulla inceptos nisl diam
  senectus.
</p>
<p>
  Laoreet vulputate congue pellentesque mi ornare magnis cras class, a erat lobortis pretium gravida
  pharetra tellus suscipit donec, purus neque sociis nostra non aliquam pulvinar. Himenaeos eget
  quam pulvinar ligula mauris pharetra quis suscipit nulla tincidunt, vel nisl congue nostra
  imperdiet id mi hendrerit consequat. Felis nam vulputate tempor euismod a torquent ullamcorper,
  phasellus cursus tortor quisque fringilla vivamus curabitur, mi ultricies commodo laoreet cum
  inceptos. Auctor neque interdum sem mus mattis congue rutrum, per class faucibus facilisis
  tristique tortor fringilla, est imperdiet dictum ultrices commodo augue.
</p>

<p>
  Lorem ipsum dolor sit amet consectetur adipiscing elit, praesent condimentum sodales nam placerat
  pulvinar, netus ornare per commodo mollis imperdiet. Torquent rutrum luctus per arcu iaculis eros
  vitae ultrices montes, integer rhoncus potenti at volutpat suspendisse nunc habitant eget magna,
  fringilla ornare facilisis porttitor tempor sagittis sapien libero. Taciti fames suscipit
  habitasse scelerisque faucibus volutpat dapibus libero torquent primis iaculis per, sem morbi nibh
  hac dui urna phasellus condimentum vivamus orci. Vulputate mattis vehicula suspendisse magna
  tristique imperdiet, proin dictum at ornare in gravida scelerisque, nulla inceptos nisl diam
  senectus.
</p>
<p>
  Laoreet vulputate congue pellentesque mi ornare magnis cras class, a erat lobortis pretium gravida
  pharetra tellus suscipit donec, purus neque sociis nostra non aliquam pulvinar. Himenaeos eget
  quam pulvinar ligula mauris pharetra quis suscipit nulla tincidunt, vel nisl congue nostra
  imperdiet id mi hendrerit consequat. Felis nam vulputate tempor euismod a torquent ullamcorper,
  phasellus cursus tortor quisque fringilla vivamus curabitur, mi ultricies commodo laoreet cum
  inceptos. Auctor neque interdum sem mus mattis congue rutrum, per class faucibus facilisis
  tristique tortor fringilla, est imperdiet dictum ultrices commodo augue.
</p>
