import { Injectable } from '@angular/core';

@Injectable()
export class ChartHelperService {
  private _serieColors = [
    '#54A71D',
    '#0BC3B4',
    '#D2D000',
    '#2724DF',
    '#00B5C2',
    '#D27B1B',
    '#FFE300',
    '#FF42D6',
    '#42F8FF',
    '#FFFD42',
    '#42FFAD',
    '#42BAFF',
    '#FFC142',
    '#FF4242',
  ];

  constructor() {}

  getColorByIndex(index: number): string {
    if (this._serieColors.length <= index) {
      return this.getRandomColor();
    }

    return this._serieColors[index];
  }

  getRandomColor() {
    let c = '';
    while (c.length < 6) {
      // Should not generate any security risk to generate a color this way.
      c += Math.random().toString(16).substr(-6).substr(-1);
    }
    return ('#' + c).toUpperCase();
  }
}
