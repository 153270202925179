import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { LocalizationHelperService } from '../../localization/localization-helper.service';

const COMPONENT_SELECTOR = 'wlm-validation-messages';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent implements OnInit {
  private _control: UntypedFormControl;
  public get control(): UntypedFormControl {
    return this._control;
  }
  @Input()
  public set control(value: UntypedFormControl) {
    this._control = value;

    this.subscription?.unsubscribe();

    this.subscription = this._control.statusChanges.subscribe((status) => {
      if (status === 'INVALID' && value.errors.custom) {
        this.buildCustomValidationMessage();
      }
    });
  }

  subscription: Subscription;
  @Input() showErrors: { [key: string]: boolean } = {};
  @Input() addMarginBottom = true;

  translatedCustomField: string;

  constructor(private readonly _localizationHelperService: LocalizationHelperService) {}

  ngOnInit(): void {}

  buildCustomValidationMessage() {
    this._localizationHelperService
      .get(this.control.errors.message.field)
      .pipe(untilDestroyed(this))
      .subscribe((field) => (this.translatedCustomField = field));
  }
}
