import { Component } from '@angular/core';

@Component({
  selector: 'wlm-leak-types-and-groups-crud',
  templateUrl: './leak-types-and-groups-crud.component.html',
  styleUrls: ['./leak-types-and-groups-crud.component.scss']
})
export class LeakTypesAndGroupsCrudComponent {

}
