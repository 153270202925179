<form [formGroup]="goalForm" class="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ T_SCOPE + '.labels.goal-target' | translate }}</mat-label>
    <input matInput type="number" [formControlName]="goalTargetFieldName" autocomplete="off" />
    <mat-error *ngIf="goalForm.get(goalTargetFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ T_SCOPE + '.labels.goal-progress' | translate }}</mat-label>
    <input matInput type="number" [formControlName]="goalProgressFieldName" autocomplete="off" />
    <mat-error *ngIf="goalForm.get(goalProgressFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="selected-padding">
    <mat-label>{{ T_SCOPE + '.labels.status' | translate }}</mat-label>
    <mat-select [formControlName]="statusFieldName" [class]="selectedStyle">
      <mat-option *ngFor="let status of statuses" [value]="status.value">
        <div [class]="status.class">
          {{ status.labelKey | translate }}
        </div>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="goalForm.get(statusFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{
      T_SCOPE + '.labels.campaign-budget' | translate: { currency: currencyLabel }
    }}</mat-label>
    <input matInput type="number" [formControlName]="campaignBudgetFieldName" autocomplete="off" />
    <mat-error *ngIf="goalForm.get(campaignBudgetFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="date-time-picker-align-text">
    <mat-label>{{ T_SCOPE + '.labels.target-date' | translate }}</mat-label>
    <input
      matInput
      [formControlName]="targetDateFieldName"
      [matDatepicker]="picker"
      autocomplete="off"
      class="date-position"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker [touchUi]="false"></mat-datepicker>
  </mat-form-field>
</form>
