import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { IActivityOperationDto } from '../shared/model/alc/activity-operation.dto';

@Injectable()
export class ActivityOperationService extends GridODataService<IActivityOperationDto> {
  constructor(injector: Injector) {
    super(injector, '/api/alc/activity/operation');
  }

  protected mapResponse(
    response: PagedResultDto<IActivityOperationDto>
  ): PagedResultDto<IActivityOperationDto> {
    return response;
  }
}
