import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { ActivitiesPageComponent } from './activities-page/activities-page.component';
import { AlcLeaksPageComponent } from './alc-leaks-page/alc-leaks-page.component';
import { CampaignsPageComponent } from './campaigns-page/campaigns-page.component';
import { PrioritisationPageComponent } from './prioritisation-page/prioritisation-page.component';
import { RaiseActivityPageComponent } from './raise-activity-page/raise-activity-page.component';

const routes: Routes = [
  {
    path: 'activities/grid',
    component: ActivitiesPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Activities,
    },
  },
  {
    path: 'activity',
    component: RaiseActivityPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.ActivityRaising,
    },
  },
  {
    path: 'prioritisation/grid',
    component: PrioritisationPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Prioritisation,
    },
  },
  {
    path: 'leaks/grid',
    component: AlcLeaksPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Leaks,
    },
  },
  {
    path: 'campaigns/grid',
    component: CampaignsPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Campaigns,
    },
  },
  { path: '**', redirectTo: 'prioritisation/grid' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlcRoutingModule {}
