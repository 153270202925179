<wlm-spinner-wrapper [showSpinner]="isSaving">
  <h3>{{ titleTranslationKey | translate }}</h3>
  <hr class="title-separator" />

  <mat-dialog-content [formGroup]="form" class="fixed-subscript">
    <div class="new-group-container">
      <div class="rows">
        <mat-form-field class="field-no-padding-bottom group-field-name" appearance="outline">
          <mat-label>{{ T_SCOPE + '.name' | translate }}</mat-label>
          <input matInput autocomplete="off" formControlName="groupName" />
          <mat-error *ngIf="form.get('groupName')?.errors?.required">
            {{ T_SCOPE + '.messages.name-required' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('groupName')?.errors?.nameAlreadyExists">
            {{ T_SCOPE + '.messages.name-exists' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="field-no-padding-bottom group-field-description"
          appearance="outline"
        >
          <mat-label>{{ T_SCOPE + '.description' | translate }}</mat-label>
          <input matInput autocomplete="off" formControlName="groupDescription" />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="save()"
        [disabled]="!form.valid || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button (click)="close()">{{ 'common.close' | translate }}</button>
    </div>
  </mat-dialog-actions>
</wlm-spinner-wrapper>
