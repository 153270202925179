<mat-expansion-panel
  id="module-expander"
  class="nav-link-expansion-panel"
  *ngIf="moduleLink"
  hideToggle="true"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header
    class="nav-module-link-container"
    collapsedHeight="48px"
    expandedHeight="48px"
  >
    <mat-panel-title>
      <div class="link-title-container">
        <wlm-icon [icon]="moduleLink.svgIcon"></wlm-icon>
        <span class="nav-module-link-text">
          {{ moduleLink.title }}
        </span>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <wlm-icon *ngIf="!panelOpenState" icon="arrow_drop_down"></wlm-icon>
      <wlm-icon *ngIf="panelOpenState" icon="arrow_drop_up"></wlm-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-selection-list [multiple]="false" class="module-link-selection-list">
    <div
      [wRouterLink]="menuLink.urlPath"
      *ngFor="let menuLink of moduleLink?.menuLinks"
      routerLinkActive="active-link"
    >
      <div class="navigation-container">
        <span class="menu-link-indicator" routerLinkActive="menu-link-indicator-active"> </span>
        <span class="nav-link-container">
          <wlm-icon [icon]="menuLink.svgIcon"></wlm-icon>
          <span class="nav-link-text">{{ menuLink.title }}</span>
        </span>
      </div>
    </div>
  </mat-selection-list>
</mat-expansion-panel>
