import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export function customTrace(tagName: string) {
  return function <T>(source: Observable<T>) {
    if (!environment.rxjsLog) {
      return source;
    }

    if (
      !source ||
      typeof source === 'number' ||
      typeof source === 'string' ||
      typeof source === 'boolean'
    ) {
      console.error(`The tagged stream ${tagName} has an invalid source.`);
    }

    return source.pipe(
      tap({
        next(value) {
          console.log(`%c[${tagName}: Next]`, consoleStyles('#009688'), value);
        },
        error(error) {
          console.log(`%[${tagName}: Error]`, consoleStyles('#E91E63'), error);
        },
        complete() {
          console.log(`%c[${tagName}]: Complete`, consoleStyles('#00BCD4'));
        },
      })
    );
  };
}

function consoleStyles(bgColor: string): string {
  return `background: ${bgColor}; color: #fff; padding: 3px`;
}
