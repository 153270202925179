import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { GenericStoreService } from 'src/app/common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { MaterialSharedModule } from 'src/app/common-modules/shared/material-shared.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { MergedZonesConfiguratorComponent } from './components/merged-zones-configurator/merged-zones-configurator.component';
import { MergedZonesCreatePopupComponent } from './components/merged-zones-create-popup/merged-zones-create-popup.component';
import { MergedZonesFormComponent } from './components/merged-zones-form/merged-zones-form.component';
import { MergedZonesSelectorComponent } from './components/merged-zones-selector/merged-zones-selector.component';
import { MergedZonesPageComponent } from './merged-zones-page/merged-zones-page.component';
import { MergedZonesRoutingModule } from './merged-zones-routing.module';
import { MergedZonesHeAvailableService } from './services/merged-zones-he-available.service';
import { MergedZonesSelectionService } from './services/merged-zones-selection.service';
import { MergedZonesService } from './services/merged-zones.service';
import { MergedZonesConfiguratorWidgetComponent } from './widgets/merged-zones-configurator-widget/merged-zones-configurator-widget.component';
import { MergedZonesFilterWidgetComponent } from './widgets/merged-zones-filter-widget/merged-zones-filter-widget.component';
import { MergedZonesSelectorWidgetComponent } from './widgets/merged-zones-selector-widget/merged-zones-selector-widget.component';

@NgModule({
  declarations: [
    MergedZonesPageComponent,
    MergedZonesFilterWidgetComponent,
    MergedZonesSelectorWidgetComponent,
    MergedZonesConfiguratorWidgetComponent,
    MergedZonesConfiguratorComponent,
    MergedZonesSelectorComponent,
    MergedZonesFormComponent,
    MergedZonesCreatePopupComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    MaterialSharedModule,
    MergedZonesRoutingModule,
    WLMGridModule,
    TranslateModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
  ],
  providers: [
    { provide: 'MergedZonesSelectionService', useExisting: MergedZonesSelectionService },
    { provide: 'MergedZonesService', useExisting: MergedZonesService },
    { provide: 'MergedZonesHeAvailableService', useExisting: MergedZonesHeAvailableService },
  ],
})
export class MergedZonesModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'MergedZonesFilterWidgetComponent',
        widgetClass: MergedZonesFilterWidgetComponent,
      },
      {
        name: 'MergedZonesSelectorWidgetComponent',
        widgetClass: MergedZonesSelectorWidgetComponent,
      },
      {
        name: 'MergedZonesConfiguratorWidgetComponent',
        widgetClass: MergedZonesConfiguratorWidgetComponent,
      },
    ]);
  }
}
