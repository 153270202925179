<wlm-spinner-wrapper [showSpinner]="isLoading" class="notif-d-container">
  <wlm-notification-details
    *ngIf="notificationId && notification"
    class="widget-position-nested"
    [pageId]="pageId"
    [notification]="notification"
    (submitResult)="onSubmitResult($event)"
    (isLoading)="onLoadedChanged($event)"
  >
  </wlm-notification-details>
</wlm-spinner-wrapper>
