import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[wlmSimpleTest]',
})
export class SimpleTestDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event) {
    console.log(event.value, this.elRef.nativeElement.value);
  }
}
