import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { HomePageService } from '../../shared/services/home-page.service';

@Injectable()
export class IntegrationUserService extends BaseService {
  private _entityUrl: string;

  constructor(
    injector: Injector,
    private readonly _homePageService: HomePageService,
    private readonly _dialogService: DialogService
  ) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/user`;
  }

  /**
   * Ensure that an integrator user exists in the DB and, if it was just created, set integration mode.
   */
  ensureIntegrationSetup(continueCallback: () => void): void {
    this.getIfUserExists().subscribe({
      next: (exists) => {
        if (exists) {
          continueCallback();
        } else {
          this.create().subscribe({
            next: (created) => {
              this.setIntegrationHomePage().subscribe({
                next: () => continueCallback(),
                error: (e) => this.onAnyError(e, continueCallback),
              });
            },
            error: (e) => this.onAnyError(e, continueCallback),
          });
        }
      },
      error: (e) => this.onAnyError(e, continueCallback),
    });
  }

  private create(): Observable<boolean> {
    return this.httpCacheClient.post<boolean>(this._entityUrl, {});
  }

  private getIfUserExists(): Observable<boolean> {
    return this.httpCacheClient.get<boolean>(this._entityUrl);
  }

  private setIntegrationHomePage(): Observable<void> {
    return this._homePageService.change(NavKeys.Integration);
  }

  private onAnyError = (error, continueCallback) => {
    this._dialogService.showErrorMessage(error);
    continueCallback();
  };
}
