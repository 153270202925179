import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { FieldValidationUIOptions } from '../models/field-validation-ui-options';
import { FieldValidatorApiTypes } from '../models/field-validator-api-types';
import { FieldValidatorUITypes } from '../models/field-validator-ui-types';
import {
  FieldValidatorParams,
  FieldValidatorValueModes,
} from '../models/field-validator-value-modes';

export class DynamicFormValidatorsService {
  static readonly apiToUITypes = new Map<FieldValidatorApiTypes, FieldValidatorUITypes>([
    [FieldValidatorApiTypes.Min, FieldValidatorUITypes.Min],
    [FieldValidatorApiTypes.Max, FieldValidatorUITypes.Max],
  ]);

  static minValidator = (min: number, params?: FieldValidatorParams[]): ValidatorFn => {
    if (params?.includes(FieldValidatorParams.Strict)) {
      return (control) => {
        if (
          DynamicFormValidatorsService.isEmptyOrNullValue(control.value) ||
          DynamicFormValidatorsService.isEmptyOrNullValue(min)
        ) {
          return null;
        }
        const value = parseFloat(control.value);

        return !isNaN(value) && value <= min
          ? { min: { min: min + 1, actual: control.value } }
          : null;
      };
    }

    return Validators.min(min);
  };

  static maxValidator = (max: number, params?: FieldValidatorParams[]): ValidatorFn => {
    if (params?.includes(FieldValidatorParams.Strict)) {
      return (control) => {
        if (
          DynamicFormValidatorsService.isEmptyOrNullValue(control.value) ||
          DynamicFormValidatorsService.isEmptyOrNullValue(max)
        ) {
          return null;
        }
        const value = parseFloat(control.value);

        return !isNaN(value) && value >= max
          ? { max: { max: max - 1, actual: control.value } }
          : null;
      };
    }

    return Validators.max(max);
  };

  private static readonly _nativeValidators = new Map<FieldValidatorUITypes, any>([
    [FieldValidatorUITypes.Min, DynamicFormValidatorsService.minValidator],
    [FieldValidatorUITypes.Max, DynamicFormValidatorsService.maxValidator],
  ]);

  private static readonly _nativeValidatorTypes = new Map<FieldValidatorUITypes, any>([
    [FieldValidatorUITypes.Min, 'number'],
    [FieldValidatorUITypes.Max, 'number'],
  ]);

  static build(validatorType: FieldValidatorUITypes) {
    const context = DynamicFormValidatorsService;

    if (!context._nativeValidators.has(validatorType)) {
      throw new Error(
        `The validator type '${validatorType}' does not correspond to a native validator`
      );
    }

    const nativeValidator = context._nativeValidators.get(validatorType);

    return (
      control: AbstractControl,
      field: FormlyFieldConfig,
      options: FieldValidationUIOptions[] = []
    ): ValidationErrors | null | Promise<ValidationErrors | null> => {
      let fixedErrors;
      let modelErrors;

      const fixedOption = options.find((option) => option.mode === FieldValidatorValueModes.Fixed);
      if (fixedOption) {
        fixedErrors = context.buildFixed(
          validatorType,
          fixedOption.data,
          fixedOption.params,
          control,
          nativeValidator
        );
      }

      // Check if there is a model validator, as they override fixed ones.
      const modelOption = options.find((option) => option.mode === FieldValidatorValueModes.Model);
      if (modelOption) {
        modelErrors = context.buildModel(
          validatorType,
          modelOption.data,
          modelOption.params,
          control,
          nativeValidator
        );
      }

      let errors;
      if (fixedErrors && modelErrors) {
        errors = context.buildCombined([fixedErrors, modelErrors], validatorType);
      } else if (fixedErrors) {
        errors = fixedErrors;
      } else if (modelErrors) {
        errors = modelErrors;
      }

      return errors;
    };
  }

  private static buildFixed = (
    validatorType: FieldValidatorUITypes,
    value: any,
    params: FieldValidatorParams[],
    control: AbstractControl,
    nativeValidator: any
  ): ValidationErrors | null => {
    const context = DynamicFormValidatorsService;

    const converted = context.convertValueToType(value, validatorType);

    const validateResult = nativeValidator(converted, params)(control);
    // If validator gives the fixed value, just use standard validator.
    return validateResult;
  };

  private static buildModel = (
    validatorType: FieldValidatorUITypes,
    data: any,
    params: FieldValidatorParams[],
    control: AbstractControl,
    nativeValidator: any
  ): Promise<ValidationErrors | null> => {
    const context = DynamicFormValidatorsService;

    const dependingControlName = data as string;
    const dependingControl: AbstractControl = control.parent?.controls[dependingControlName];
    if (!dependingControl) {
      return null;
    }

    const dependingValue = dependingControl.value;
    if (dependingValue === null || typeof dependingValue === 'undefined') {
      return null;
    }
    const dependingConvertedValue = context.convertValueToType(dependingValue, validatorType);

    let errors = null;
    // Only apply depending validators if the other field is not empty.
    if (dependingConvertedValue !== null && typeof dependingConvertedValue !== 'undefined') {
      errors = nativeValidator(dependingConvertedValue, params)(control);

      if (errors) {
        errors[validatorType]['model'] = dependingControlName;
      }
    }

    return errors;
  };

  private static buildCombined(errors: any[], validatorType: FieldValidatorUITypes) {
    const context = DynamicFormValidatorsService;

    let result;
    if (validatorType === FieldValidatorUITypes.Min) {
      result = context.combineErrorsByFn(errors, validatorType, Math.max);
    } else if (validatorType === FieldValidatorUITypes.Max) {
      result = context.combineErrorsByFn(errors, validatorType, Math.min);
    }

    return result;
  }

  private static combineErrorsByFn<T>(
    errors: any[],
    validatorType: FieldValidatorUITypes,
    combineValuesFn: (value1: T, value2: T) => T
  ) {
    return errors.reduce((combined, current) => {
      let value;
      if (typeof combined[validatorType] === 'undefined') {
        value = current[validatorType][validatorType];
      } else {
        value = combineValuesFn(
          combined[validatorType][validatorType],
          current[validatorType][validatorType]
        );
      }
      return { ...combined, ...current, ...{ [validatorType]: { [validatorType]: value } } };
    }, {});
  }

  static convertValueToType = (value: any, validatorType: FieldValidatorUITypes): any => {
    if (value === null || typeof value === 'undefined' || value === 'null') {
      return null;
    }

    const context = DynamicFormValidatorsService;

    let convertedValue = value;
    if (context._nativeValidatorTypes.get(validatorType) === 'number') {
      convertedValue = Number(value);
      if (isNaN(convertedValue)) {
        throw new Error(`Trying to apply value '${value}' to a '${validatorType}' fixed validator`);
      }
    }
    return convertedValue;
  };

  static updateModelErrors = (initialErrors, form: UntypedFormGroup): void => {
    const context = DynamicFormValidatorsService;

    if (!initialErrors) {
      return initialErrors;
    }

    const errors = globalUtilsHelper.serializedClone(initialErrors);

    Object.entries(errors).forEach(([currentField, currentFieldErrors]) => {
      Object.entries(currentFieldErrors).forEach(([validatorName, validatorData]) => {
        const dependingField = validatorData['model'];

        if (!globalUtilsHelper.isNullUndefined(dependingField)) {
          let dependingValue = validatorData[validatorName] ?? form.get(dependingField).value;

          if (!globalUtilsHelper.isNullUndefined(dependingValue)) {
            dependingValue = context.convertValueToType(dependingValue, validatorName as any);

            if (!globalUtilsHelper.isNullUndefined(dependingValue)) {
              let updatedErrors;

              if (validatorName == FieldValidatorUITypes.Max) {
                updatedErrors = context.updateMaxError(currentFieldErrors, dependingValue);
              }
              if (validatorName == FieldValidatorUITypes.Min) {
                updatedErrors = context.updateMinError(currentFieldErrors, dependingValue);
              }

              if (updatedErrors) {
                if (Object.keys(updatedErrors).length === 0) {
                  updatedErrors = null;
                }

                const currentControl = form.get(currentField);

                currentControl.markAsDirty();
                currentControl.markAsTouched();
                currentControl.setErrors(updatedErrors);
              }
            }
          }
        }
      });
    });
  };

  private static updateMinError = (initialFieldErrors, dependingValue): any => {
    const context = DynamicFormValidatorsService;
    const currentFieldErrors = globalUtilsHelper.serializedClone(initialFieldErrors);
    const errorName = FieldValidatorUITypes.Min;

    const minError = currentFieldErrors[errorName];
    minError[errorName] = dependingValue;

    const currentValue = context.convertValueToType(minError['actual'], errorName);

    // Delete the error if it does not apply anymore.
    if (currentValue === null || minError[errorName] <= currentValue) {
      delete currentFieldErrors[errorName];
    }

    return currentFieldErrors;
  };

  private static updateMaxError = (initialFieldErrors, dependingValue): any => {
    const context = DynamicFormValidatorsService;
    const currentFieldErrors = globalUtilsHelper.serializedClone(initialFieldErrors);
    const errorName = FieldValidatorUITypes.Max;

    const maxError = currentFieldErrors[errorName];
    maxError[errorName] = dependingValue;

    const currentValue = context.convertValueToType(maxError['actual'], errorName);

    // Delete the error if it does not apply anymore.
    if (currentValue === null || maxError[errorName] >= currentValue) {
      delete currentFieldErrors[errorName];
    }

    return currentFieldErrors;
  };

  private static isEmptyOrNullValue = (value) => {
    return value == null || value.length === 0;
  };
}
