import { ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export abstract class BaseInputDirective implements ControlValueAccessor {
  private writeToForm: (value: any) => void;

  protected previousValue: string;

  constructor(protected elRef: ElementRef) {}

  protected viewToForm(data: any): any {
    return data;
  }

  protected formToView(data: any): any {
    return data;
  }

  protected get inputValue() {
    return this.elRef.nativeElement.value;
  }

  protected set inputValue(value) {
    this.elRef.nativeElement.value = value;
  }

  onEvent(value): void {
    if (!this.writeToForm) {
      return;
    }

    let newValue;

    // If the previous value included a minus/point sign, do not allow to include another.
    if (
      (value === '-' && this.previousValue?.includes('-')) ||
      (value === '.' && this.previousValue?.includes('.'))
    ) {
      newValue = this.previousValue || null;
      this.inputValue = newValue;
      this.writeToForm(newValue);
      return;
    }

    if (value === '-' && this.inputValue === '') {
      // If the value is just a minus sign, allow the user to keep writing befone applying transformations.
      return;
    }

    newValue = this.viewToForm(this.inputValue);
    this.previousValue = newValue;
    this.writeToForm(newValue);
  }

  writeValue(value: any) {
    this.inputValue = this.formToView(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.writeToForm = fn;
  }

  registerOnTouched(fn: any): void {}
}
