import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BaseService } from '../../base.service';
import { Dimensions } from '../../constants/dimensions.constants';
import { GridSetting } from '../../constants/grid.constants';
import { AddGridSettingsCmd } from '../../model/grid/AddGridSettingsCmd';
import { AddGridSettingsResponse } from '../../model/grid/AddGridSettingsResponse';
import { GridSettingSaveStateDto } from '../../model/grid/grid-setting-save-state.dto';

@Injectable({ providedIn: 'root' })
export class GridSettingsService extends BaseService {
  // Default attributes for a column. Can be overriden with spread operator.
  columnDefault = {
    type: 'text',
    visible: true,
    width: Dimensions.MedColumnWidth,
  };

  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/settings/grid`;
  }

  public getGridSettingsByName(
    gridName: string,
    loadDefaultGridSettings: boolean = false
  ): Observable<GridSetting> {
    let gridSettingType = 'user';

    if (loadDefaultGridSettings) {
      gridSettingType = 'template';
    }

    return this.httpCacheClient
      .get(`${this.url}/${gridName}/${gridSettingType}`, {
        avoid: true,
      })
      .pipe(
        map((gridsetting: GridSetting) => {
          this.setDefaultValues(gridsetting);
          return gridsetting;
        })
      );
  }

  private setDefaultValues(gridsetting: GridSetting) {
    gridsetting.enableAutoResize = gridsetting.enableAutoResize === false ? false : true;
    gridsetting.gridColumnSettings.forEach((column) => {
      column.isNotificationDetail = column.isNotificationDetail === false ? false : true;
    });
    gridsetting.ignoreCase = true;
  }

  saveGridSettings(addGridSettingsCmd: AddGridSettingsCmd): Observable<AddGridSettingsResponse> {
    return this.httpCacheClient.post(`${this.url}`, addGridSettingsCmd, {
      avoid: true,
    });
  }

  getGridSettingsSaveState(gridName: string): Observable<GridSettingSaveStateDto> {
    return this.httpCacheClient.get(`${this.url}/${gridName}/save-state`, {
      avoid: true,
    });
  }
}
