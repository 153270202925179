import { Component, DestroyRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, switchMap, tap } from 'rxjs';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { JsonEditorComponent } from 'src/app/common-modules/shared/json-editor/json-editor.component';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { InterfacesService } from '../interfaces.service';

export enum EditInterfacesOptions {
  Interfaces = 'interfaces',
  GIS = 'gis',
}

const COMPONENT_SELECTOR = 'wlm-edit-interfaces-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './edit-interfaces-popup.component.html',
  styleUrl: './edit-interfaces-popup.component.scss',
})
export class EditInterfacesPopupComponent {
  @ViewChild(JsonEditorComponent) public jsonEditor: JsonEditorComponent;
  settingsData: any;
  isSaving = false;
  hasErrors = false;
  readonly T_SCOPE = `${AppModules.Interfaces}.${COMPONENT_SELECTOR}`;
  selectorOptions: SelectOption<EditInterfacesOptions>[] = [];
  readonly selectorCtrl = new FormControl(EditInterfacesOptions.Interfaces);
  private setLoading: (loading: boolean) => void;

  private readonly _settingsLoaders = {
    [EditInterfacesOptions.Interfaces]: () => this.loadInterfaces(),
    [EditInterfacesOptions.GIS]: () => this.loadGIS(),
  };
  schema;

  constructor(
    private readonly _dialogRef: MatDialogRef<EditInterfacesPopupComponent>,
    private readonly _interfacesService: InterfacesService,
    private readonly _dialogService: DialogService,
    private readonly _localization: LocalizationHelperService,
    private readonly _destroyRef: DestroyRef,
    private readonly _log: LogService,
    private readonly _spinnerService: SpinnerService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.buildSelectorOptions();
    this.listenSelectorChanges();
  }

  save(): void {
    this.setLoading(true);
    const settings = this.jsonEditor.stringifyJson ?? this.jsonEditor.visibleData;

    this._interfacesService
      .saveInterfaces(settings)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: (response) => {
          this._dialogService.showEntityActionSnackBar('save', 'interface.plural');
          this.close(true);
        },
        error: this._dialogService.showErrorMessage,
      });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  close(success = false) {
    this._dialogRef.close(success);
  }

  onJsonHasError(hasErrors) {
    this.hasErrors = hasErrors;
  }

  private buildSelectorOptions(): void {
    this.selectorCtrl.setValue(EditInterfacesOptions.Interfaces);
    this._localization.get(`${this.T_SCOPE}.settings-options`).subscribe((translations) => {
      this.selectorOptions = Object.entries(translations).map(
        (item) =>
          new SelectOption<EditInterfacesOptions>({
            value: item[0] as EditInterfacesOptions,
            label: item[1] as string,
          })
      );
    });
  }

  private listenSelectorChanges(): void {
    if (this.selectorCtrl.value) {
      this.loadSettings(this.selectorCtrl.value);
    }
    this.selectorCtrl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value: EditInterfacesOptions) => this.loadSettings(value));
  }

  private loadSettings(value: EditInterfacesOptions): void {
    this.setLoading(true);
    const loaderFn = this._settingsLoaders[value];
    if (!loaderFn) {
      this._log.error({ msg: `Unable to load JSON for ${value}.` });
      this.setLoading(false);
    } else {
      const loader$ = loaderFn();
      if (!loader$?.subscribe) {
        this._log.error({ msg: `Loader ${value} must return an observable.` });
        this.setLoading(false);
      } else {
        loader$.subscribe({
          next: (settingsData) => {
            this.settingsData = settingsData;
            this.setLoading(false);
          },
          error: (error) => {
            this._dialogService.showErrorMessage(error);
            this.setLoading(false);
          },
        });
      }
    }
  }

  private loadInterfaces = () =>
    this._interfacesService.getInterfacesSchema().pipe(
      tap((schema) => (this.schema = schema)),
      switchMap(() => this._interfacesService.getInterfaces())
    );

  private loadGIS = () =>
    this._interfacesService.getInterfaceGisSchema().pipe(
      tap((schema) => (this.schema = schema)),
      switchMap(() => this._interfacesService.getInterfaceGis())
    );
}
