import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { IAppAttributeDto } from './app-attribute.dto';

@Injectable()
export class AppAttributesCrudService
  extends BaseService
  implements GenericCrudService<IAppAttributeDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/application-attributes`;
  }

  getAll(): Observable<IAppAttributeDto[]> {
    return this.httpCacheClient.get<IAppAttributeDto[]>(this._entityUrl);
  }

  create(entity: IAppAttributeDto): Observable<IAppAttributeDto> {
    throw new Error('Unsupported operation.');
  }

  update(entity: IAppAttributeDto): Observable<IAppAttributeDto> {
    return this.httpCacheClient.post(`${this._entityUrl}/${entity.attributeId}`, entity);
  }

  delete(id: number): Observable<boolean> {
    throw new Error('Unsupported operation.');
  }

  getId(entity: IAppAttributeDto): number {
    return entity.attributeId;
  }
}
