import { GChartItemStyle } from '../generic-chart-settings/g-chart-item-style';
import { GChartLineStyle } from '../generic-chart-settings/g-chart-line-style';

export class BaseSchematicNode {
  id: string;
  name: string;
  symbolSize?: number;
}

export class SchematicNodeProcessed extends BaseSchematicNode {
  // When processed, category refers to the position of the category in the legend categories array.
  category?: number;
  symbol?: string;
  itemStyle?: GChartItemStyle;
  value: [number, number]; // Represent the coordinates
}

export class SchematicNode extends BaseSchematicNode {
  // When coming from the api, category refers to the category.id
  category?: string;
  x: number;
  y: number;
}

export class SchematicNodeModified {
  node: SchematicNode;
  categoryName?: string;
}

export class SchematicLink {
  source: string;
  target: string;
  lineStyle?: GChartLineStyle;
}

export class SchematicCategory {
  id: any;
  name: string;
}

export class SchematicBasic {
  name: string;
  description?: string;
}

export class Schematic extends SchematicBasic {
  nodes: SchematicNode[];
  links: SchematicLink[];
  categories?: SchematicCategory[];
  hideMap?: boolean;
}

export class SchematicProcessed extends SchematicBasic {
  nodes: SchematicNodeProcessed[];
  links: SchematicLink[];
  categories?: SchematicCategory[];
}

export class SchematicSaveDto extends SchematicBasic {
  nodes: string;
  links: string;
}

export class SchematicSave extends SchematicBasic {
  nodes: File;
  links: File;
}
