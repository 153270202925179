import { FieldValidationUIOptions } from './field-validation-ui-options';

export class FieldValidationUI {
  name: string;
  options: FieldValidationUIOptions[];

  constructor(init: FieldValidationUI) {
    Object.assign(this, init);
  }
}
