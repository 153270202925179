import { LargeUserSignalDto } from '../../dependencies/shared/model/large-user-signal.dto';

export class NeSignalSelectionPopupConfiguration {
  currentConfiguration: LargeUserSignalDto[];
  excludedConfiguration: LargeUserSignalDto[];
  selectedItem: any;
  queryFieldNames: string[];
  title: string;
  oDataService: string;
  oDataFilters?: Map<string, any>;
  showSaveButton? = false;

  constructor(init: Readonly<NeSignalSelectionPopupConfiguration>) {
    Object.assign(this, init);
  }
}
