import { AppNavigationsSettings } from '../common-modules/dependencies/navigation/app-navigations-settings';
import { MenuLinkConf } from '../common-modules/dependencies/navigation/menu-link-conf';
import { ModuleLinkConf } from '../common-modules/dependencies/navigation/module-link-conf';
import { NavKeys } from '../common-modules/dependencies/navigation/nav-keys.enum';
import { RouteNameHelper } from '../common-modules/dependencies/navigation/route-name-helper';
import { AppModules } from '../common-modules/shared/app-modules.enum';
import { waterLossAppPermissions } from './water-loss-permissions';

const T_SCOPE = `${AppModules.WlmNavigation}.wlm-navigation-service`;

const waterLossRoutePermissions = waterLossAppPermissions.routePermissions;

export const waterLossAppNavigationPaths = {
  [NavKeys.Integration]: '/integration',
};

export const waterLossAppNavigations: AppNavigationsSettings = {
  moduleLinkKeys: [
    NavKeys.LeakageCalculationModule,
    NavKeys.PressureManagementModule,
    NavKeys.AlcModule,
    NavKeys.WaterBalanceModule,
    NavKeys.BiModule,
    NavKeys.DataAccessModule,
    NavKeys.MonitoringModule,
    NavKeys.ConfigurationModule,
    NavKeys.AdministrationModule,
    NavKeys.LogsModule,
  ],

  hiddenLinks: new Map<NavKeys, MenuLinkConf>([
    [
      NavKeys.AlarmsCreationTelemetry,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.telemetry`,
        urlPath: '/configuration/alarm/create/telemetry',
        svgIcon: 'telemetry_point',
        permissions: waterLossRoutePermissions.get(NavKeys.AlarmsCreationTelemetry),
      }),
    ],
    [
      NavKeys.AlarmsCreationAlgorithms,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.algorithms`,
        urlPath: '/configuration/alarm/create/algorithms',
        svgIcon: 'algorithms',
        permissions: waterLossRoutePermissions.get(NavKeys.AlarmsCreationAlgorithms),
      }),
    ],
    [
      NavKeys.NetworkElementDetails,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.ne-config`,
        urlPath: `/configuration/${RouteNameHelper.neConfigDetail}`,
        svgIcon: 'network',
        permissions: waterLossRoutePermissions.get(NavKeys.NetworkElementDetails),
      }),
    ],
    [
      NavKeys.BIHome,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.bi`,
        urlPath: `${RouteNameHelper.biDetails}`,
        svgIcon: 'business_intelligence',
        permissions: waterLossRoutePermissions.get(NavKeys.BIHome),
      }),
    ],
    [
      NavKeys.Integration,
      new MenuLinkConf({
        urlPath: '/integration',
        permissions: waterLossRoutePermissions.get(NavKeys.Integration),
      }),
    ],
  ]),

  // Contains all the links and permissions needed for each route. Each route is identified by a nav key.
  links: new Map<NavKeys, MenuLinkConf | ModuleLinkConf>([
    [
      NavKeys.Home,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.home`,
        shortTitleTranslateKey: `${T_SCOPE}.home-short`,
        urlPath: '',
        svgIcon: 'home_group',
        permissions: waterLossRoutePermissions.get(NavKeys.Home),
      }),
    ],
    [
      NavKeys.LeakageCalculationModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.leakage-calculation`,
        shortTitleTranslateKey: `${T_SCOPE}.leakage-calculation-short`,
        svgIcon: 'leakage_calculation_group',
        menuLinksKeys: [NavKeys.DistributionNetwork, NavKeys.TransmissionNetwork],
        permissions: waterLossRoutePermissions.get(NavKeys.LeakageCalculationModule),
      }),
    ],
    [
      NavKeys.DistributionNetwork,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.dn`,
        shortTitleTranslateKey: `${T_SCOPE}.dn-short`,
        urlPath: '/dn/distribution-network/grid',
        svgIcon: 'distribution_network',
        permissions: waterLossRoutePermissions.get(NavKeys.DistributionNetwork),
      }),
    ],
    [
      NavKeys.TransmissionNetwork,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.tn`,
        shortTitleTranslateKey: `${T_SCOPE}.tn-short`,
        urlPath: '/tn/transmission-network/grid',
        svgIcon: 'transmission-network',
        permissions: waterLossRoutePermissions.get(NavKeys.TransmissionNetwork),
      }),
    ],
    [
      NavKeys.PressureManagementModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.pressure-management`,
        shortTitleTranslateKey: `${T_SCOPE}.pressure-management-short`,
        menuLinksKeys: [NavKeys.PressureMonitoring],
        svgIcon: 'pressure_management_group',
        permissions: waterLossRoutePermissions.get(NavKeys.PressureManagementModule),
      }),
    ],
    [
      NavKeys.PressureMonitoring,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.pressure-monitoring`,
        urlPath: '/pressure-management/pressure-monitoring',
        svgIcon: 'pressure_monitoring',
        permissions: waterLossRoutePermissions.get(NavKeys.PressureMonitoring),
      }),
    ],
    [
      NavKeys.AlcModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.alc`,
        shortTitleTranslateKey: `${T_SCOPE}.alc-short`,
        svgIcon: 'active_leakage_control_group',
        menuLinksKeys: [
          NavKeys.Prioritisation,
          NavKeys.ActivityRaising,
          NavKeys.Activities,
          NavKeys.Leaks,
          NavKeys.Campaigns,
        ],
        permissions: waterLossRoutePermissions.get(NavKeys.AlcModule),
      }),
    ],
    [
      NavKeys.Prioritisation,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.prioritisation`,
        urlPath: '/alc/prioritisation/grid',
        svgIcon: 'prioritisation',
        permissions: waterLossRoutePermissions.get(NavKeys.Prioritisation),
      }),
    ],
    [
      NavKeys.Campaigns,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.campaigns`,
        urlPath: '/alc/campaigns/grid',
        svgIcon: 'campaign',
        permissions: waterLossRoutePermissions.get(NavKeys.Campaigns),
      }),
    ],
    [
      NavKeys.ActivityRaising,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.activity-raising`,
        urlPath: '/alc/activity',
        svgIcon: 'activity_raising',
        permissions: waterLossRoutePermissions.get(NavKeys.ActivityRaising),
      }),
    ],
    [
      NavKeys.Activities,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.activities`,
        urlPath: '/alc/activities/grid',
        svgIcon: 'activities',
        permissions: waterLossRoutePermissions.get(NavKeys.Activities),
      }),
    ],
    [
      NavKeys.Leaks,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.leaks`,
        urlPath: '/alc/leaks/grid',
        svgIcon: 'leaks',
        permissions: waterLossRoutePermissions.get(NavKeys.Leaks),
      }),
    ],
    [
      NavKeys.WaterBalanceModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.water-balance`,
        shortTitleTranslateKey: `${T_SCOPE}.water-balance-short`,
        svgIcon: 'water_balance_group',
        menuLinksKeys: [NavKeys.WaterBalance],
        permissions: waterLossRoutePermissions.get(NavKeys.WaterBalanceModule),
      }),
    ],
    [
      NavKeys.WaterBalance,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.water-balance`,
        urlPath: '/water-balance',
        svgIcon: 'water_balance',
        permissions: waterLossRoutePermissions.get(NavKeys.WaterBalance),
      }),
    ],
    [
      NavKeys.BiModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.bi`,
        shortTitleTranslateKey: `${T_SCOPE}.bi-short`,
        svgIcon: 'business_intelligence_group',
        menuLinksKeys: [NavKeys.Bi],
        permissions: waterLossRoutePermissions.get(NavKeys.BiModule),
      }),
    ],
    [
      NavKeys.Bi,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.bi-dashboard`,
        urlPath: '/bi',
        svgIcon: 'business_intelligence',
        permissions: waterLossRoutePermissions.get(NavKeys.Bi),
      }),
    ],
    [
      NavKeys.DataAccessModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.data-access`,
        shortTitleTranslateKey: `${T_SCOPE}.data-access-short`,
        svgIcon: 'data_access_group',
        menuLinksKeys: [
          NavKeys.Map,
          NavKeys.DataValidation,
          NavKeys.DataVisualization,
          NavKeys.LeakageReporting,
          NavKeys.CustomerDetails,
          NavKeys.Schematics,
        ],
        permissions: waterLossRoutePermissions.get(NavKeys.DataAccessModule),
      }),
    ],
    [
      NavKeys.Map,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.map`,
        urlPath: '/map',
        svgIcon: 'layers',
        permissions: waterLossRoutePermissions.get(NavKeys.Map),
      }),
    ],
    [
      NavKeys.DataValidation,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.data-validation`,
        urlPath: '/da/manual-data-validation',
        svgIcon: 'data_validation',
        permissions: waterLossRoutePermissions.get(NavKeys.DataValidation),
      }),
    ],
    [
      NavKeys.DataVisualization,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.dv`,
        urlPath: '/dv/data-visualization',
        svgIcon: 'data_visualization',
        permissions: waterLossRoutePermissions.get(NavKeys.DataVisualization),
      }),
    ],
    [
      NavKeys.LeakageReporting,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.leakage-reporting`,
        urlPath: '/dv/leakage-reporting/grid',
        svgIcon: 'leakage_reporting',
        permissions: waterLossRoutePermissions.get(NavKeys.LeakageReporting),
      }),
    ],
    [
      NavKeys.CustomerDetails,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.customer-details`,
        urlPath: '/customer/detail',
        svgIcon: 'customer-details',
        permissions: waterLossRoutePermissions.get(NavKeys.CustomerDetails),
      }),
    ],
    [
      NavKeys.Schematics,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.schematics`,
        urlPath: '/schematics',
        svgIcon: 'schematics',
        permissions: waterLossRoutePermissions.get(NavKeys.Schematics),
      }),
    ],
    [
      NavKeys.MonitoringModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.monitoring`,
        svgIcon: 'monitoring_group',
        shortTitleTranslateKey: `${T_SCOPE}.monitoring-short`,
        menuLinksKeys: [NavKeys.Notifications],
        permissions: waterLossRoutePermissions.get(NavKeys.MonitoringModule),
      }),
    ],
    [
      NavKeys.Notifications,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.notifications`,
        urlPath: '/notifications',
        svgIcon: 'notifications',
        permissions: waterLossRoutePermissions.get(NavKeys.Notifications),
      }),
    ],
    [
      NavKeys.ConfigurationModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.configuration`,
        shortTitleTranslateKey: `${T_SCOPE}.configuration-short`,
        svgIcon: 'configuration',
        menuLinksKeys: [
          NavKeys.TelemetryPoints,
          NavKeys.AlarmsConfiguration,
          NavKeys.NetworkElementConfig,
          NavKeys.Algorithms,
          NavKeys.Hierarchy,
          NavKeys.UoM,
          NavKeys.Calendar,
          NavKeys.MergedZones,
        ],
        permissions: waterLossRoutePermissions.get(NavKeys.ConfigurationModule),
      }),
    ],
    [
      NavKeys.TelemetryPoints,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.telemetry-points`,
        urlPath: '/configuration/telemetry',
        svgIcon: 'telemetry_point',
        permissions: waterLossRoutePermissions.get(NavKeys.TelemetryPoints),
      }),
    ],
    [
      NavKeys.AlarmsConfiguration,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.alarms-configuration`,
        urlPath: '/configuration/alarm',
        svgIcon: 'alarms',
        permissions: waterLossRoutePermissions.get(NavKeys.AlarmsConfiguration),
      }),
    ],
    [
      NavKeys.NetworkElementConfig,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.ne-config`,
        urlPath: `/configuration/${RouteNameHelper.neConfig}`,
        svgIcon: 'network',
        permissions: waterLossRoutePermissions.get(NavKeys.NetworkElementConfig),
      }),
    ],
    [
      NavKeys.Algorithms,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.algorithms-configuration`,
        urlPath: '/configuration/algorithm',
        svgIcon: 'algorithms',
        permissions: waterLossRoutePermissions.get(NavKeys.Algorithms),
      }),
    ],
    [
      NavKeys.Hierarchy,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.hierarchy`,
        urlPath: '/configuration/hierarchy',
        svgIcon: 'hierarchy',
        permissions: waterLossRoutePermissions.get(NavKeys.Hierarchy),
      }),
    ],
    [
      NavKeys.UoM,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.uom`,
        urlPath: `/configuration/${RouteNameHelper.uom}`,
        svgIcon: 'uom',
        permissions: waterLossRoutePermissions.get(NavKeys.UoM),
      }),
    ],
    [
      NavKeys.Calendar,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.calendar`,
        urlPath: `/configuration/${RouteNameHelper.calendar}`,
        svgIcon: 'calendar',
        permissions: waterLossRoutePermissions.get(NavKeys.Calendar),
      }),
    ],
    [
      NavKeys.MergedZones,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.merged-zones`,
        urlPath: `/configuration/${RouteNameHelper.mergedZones}`,
        svgIcon: 'merged-zone',
        permissions: waterLossRoutePermissions.get(NavKeys.MergedZones),
      }),
    ],
    [
      NavKeys.AdministrationModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.administration`,
        shortTitleTranslateKey: `${T_SCOPE}.administration-short`,
        svgIcon: 'administration_group',
        menuLinksKeys: [NavKeys.Users, NavKeys.Groups],
        permissions: waterLossRoutePermissions.get(NavKeys.AdministrationModule),
      }),
    ],
    [
      NavKeys.Users,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.users`,
        urlPath: '/roles/users',
        svgIcon: 'person',
        permissions: waterLossRoutePermissions.get(NavKeys.Users),
      }),
    ],
    [
      NavKeys.Groups,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.groups`,
        urlPath: '/roles/groups',
        svgIcon: 'groups',
        permissions: waterLossRoutePermissions.get(NavKeys.Groups),
      }),
    ],
    [
      NavKeys.LogsModule,
      new ModuleLinkConf({
        titleTranslateKey: `${T_SCOPE}.logs`,
        shortTitleTranslateKey: `${T_SCOPE}.logs-short`,
        svgIcon: 'logs',
        menuLinksKeys: [NavKeys.LogsInterfaces, NavKeys.LogsSystem, NavKeys.Audits],
        permissions: waterLossRoutePermissions.get(NavKeys.LogsModule),
      }),
    ],
    [
      NavKeys.LogsInterfaces,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.logs-interfaces`,
        urlPath: `logs/${RouteNameHelper.logsInterfaces}`,
        svgIcon: 'interfaces',
        permissions: waterLossRoutePermissions.get(NavKeys.LogsInterfaces),
      }),
    ],
    [
      NavKeys.LogsSystem,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.logs-system`,
        urlPath: `logs/${RouteNameHelper.logsSystem}`,
        svgIcon: 'system',
        permissions: waterLossRoutePermissions.get(NavKeys.LogsSystem),
      }),
    ],
    [
      NavKeys.Audits,
      new MenuLinkConf({
        titleTranslateKey: `${T_SCOPE}.audits`,
        urlPath: `logs/${RouteNameHelper.audits}`,
        svgIcon: 'audits',
        permissions: waterLossRoutePermissions.get(NavKeys.Audits),
      }),
    ],
  ]),
};
