export class GroupUsersCmdDto {
  groupsIds: number[];
  users: string[];
  userCode: string;

  constructor(groupId: number[], userCodes: string[], userCode: string) {
    this.groupsIds = groupId;
    this.users = userCodes;
    this.userCode = userCode;
  }
}
