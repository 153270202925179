<div *ngIf="selectedNE" class="lu-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="lu-column">
    <div class="lu-group">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [reloadList$]="reloadAvailableList$"
        [(excludeList)]="excludedLargeUsers"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="lu-column">
    <div class="lu-group">
      <div class="top-title">
        <div class="ne-component-title top-title-position">
          {{ T_SCOPE + '.lu-in-title' | translate }}
        </div>

        <div>
          <button
            [disabled]="!configurationHasChanged"
            mat-icon-button
            *hasPerm="['WLMNetworkCrud', 'u']"
            class="button-margin"
            (click)="save()"
            color="primary"
            [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'save'"></wlm-icon>
          </button>
          <button
            [disabled]="!configurationHasChanged"
            *hasPerm="['WLMNetworkCrud', 'u']"
            mat-icon-button
            class="button-margin"
            (click)="discard()"
            color="primary"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="configuredLargeUsers"
        [hideFilter]="true"
        [settings]="settingsCustom"
        [removeDraggedItemFromSource$]="removeDraggedItemFromSource$"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-large-user-assigned-card
            class="card-width"
            [item]="rowDetail"
            [settings]="assignedCardSettings"
          ></wlm-large-user-assigned-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
