import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ArrayHelperService } from '../../shared/helpers/array-helper.service';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { StringHelperService } from '../../shared/helpers/string-helper.service';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { UnitTypeConversionViewDto } from '../../shared/model/uom/unit-type-conversion-view.dto';
import { customTrace } from '../../shared/rxjs/operators/custom-trace.operator';
import { UoMService } from '../../shared/uom/uom.service';

import { DynamicFormSettings } from '../models/dynamic-form-settings';
import { DynamicFormUIValues } from '../models/dynamic-form-ui-values';
import { FieldDataType } from '../models/field-data-type';
import { FieldDefinition } from '../models/field-definition';
import { FieldInputSubTypes } from '../models/field-input-subtypes';
import { FieldInputTypes } from '../models/field-input-types';
import { FieldParseDependencies } from '../models/field-parse-dependencies';
import { FieldsByCategory } from '../models/fields-by-category';
import { FormValuesDataSource, FormValuesDataSourceItem } from '../models/form-values-data-source';
import { SettingsDataSource } from '../models/settings-data-source.enum';
import { SettingsEntityType } from '../models/settings-entity-type';

@Injectable()
export class DynamicFormHelperService {
  constructor(
    private _objectHelperService: ObjectHelperService,
    private _arrayHelperService: ArrayHelperService,
    private _dateHelperService: DateHelperService,
    private _stringHelperService: StringHelperService,
    private _uomService: UoMService,
    private _utilsService: UtilsHelperService
  ) {}

  clone = this._objectHelperService.clone;
  deepEqual = this._objectHelperService.deepEqual;
  sortObjects = this._arrayHelperService.sortObjects;
  fromApiFormat = this._dateHelperService.fromApiFormat;
  groupByFn = this._objectHelperService.groupByFn;
  groupArraysByFnSingle = this._objectHelperService.groupArraysByFnSingle;
  deepDiff = this._objectHelperService.deepDiff;
  strReplaceAll = this._stringHelperService.replaceAll;

  buildFormDependencies(settings: DynamicFormSettings): Observable<FieldParseDependencies> {
    const hierarchyElementTypeId =
      settings.entityType === SettingsEntityType.HierarchyElement ? settings.elementTypeId : null;

    const result$ = this._uomService.getAll().pipe(
      take(1),
      map((unitTypes) => {
        const dependencies = new FieldParseDependencies({
          hierarchyElementTypeId,
          unitTypes,
        });
        return dependencies;
      })
    );

    return result$;
  }

  parseUnitConversion(
    source: FieldDefinition,
    dependencies: FieldParseDependencies
  ): UnitTypeConversionViewDto {
    const conversion = this._uomService.getBySignalSync(
      source.dimensionTypeId,
      source.unitTypeId,
      dependencies.unitTypes,
      source.timeAggregationId,
      dependencies.hierarchyElementTypeId
    );

    return conversion;
  }

  /**
   * If unitTypeId is not specified, then convert to the default.
   */
  fieldHasUoM(source: FieldDefinition): boolean {
    return (
      typeof source.dimensionTypeId !== 'undefined' || typeof source.unitTypeId !== 'undefined'
    );
  }

  /**
   * Using currying to build functions. The mapValueFn is "fixed" in all calls to toUoM or fromUoM.
   */
  private singleUoMConvertFnBuilder = (mapValueFn: (value: string, factor: string) => string) => {
    return (
      source: FieldDefinition,
      value: string,
      dependencies: FieldParseDependencies
    ): string => {
      if (this.fieldHasUoM(source)) {
        const conversion = this.parseUnitConversion(source, dependencies);
        // Depending on the value Fn, we can convert to or from UOM.
        const mappedValue = mapValueFn(value, conversion.conversionFactor?.toString());
        return mappedValue;
      } else {
        return value;
      }
    };
  };

  toUoM = this.singleUoMConvertFnBuilder((value: string, factor: string) => {
    if (typeof value === 'undefined' || value === null) {
      return null;
    }
    const result = this._utilsService.uomMultiply(String(value), factor);
    return result;
  });

  fromUoM = this.singleUoMConvertFnBuilder((value: string, factor: string) => {
    if (typeof value === 'undefined' || value === null) {
      return null;
    }
    const result = this._utilsService.uomDivide(String(value), factor);
    return result;
  });

  /**
   * Also using currying, with an additional level of depth.
   */
  private multipleUoMConvertFnBuilder = (
    mapFnName: string,
    mapFn: (source: FieldDefinition, value: string, dependencies: FieldParseDependencies) => string
  ) => {
    return (
      model: { [fieldName: string]: any },
      fieldsByName: { [name: string]: FieldDefinition },
      dependencies: FieldParseDependencies
    ): Observable<{ [fieldName: string]: any }> => {
      return of(
        this.mapValues(model, fieldsByName, (value: string, field: FieldDefinition) => {
          let convertedValue = this.clone(value);
          if (typeof value !== 'undefined' && value !== null && this.fieldHasUoM(field)) {
            convertedValue = mapFn(field, value, dependencies);
          }

          if (typeof value !== 'undefined' && value !== null && this.fieldIsPercentage(field)) {
            convertedValue =
              mapFnName == 'fromUoM'
                ? this.convertFromPercentage(value)
                : this.convertToPercentage(value);
          }
          return convertedValue;
        })
      ).pipe(customTrace(`Dynamic Form - Apply UOM Mapping Function - ${mapFnName}`));
    };
  };

  allToUoM = this.multipleUoMConvertFnBuilder('toUoM', this.toUoM);

  allFromUoM = this.multipleUoMConvertFnBuilder('fromUoM', this.fromUoM);

  convertFromPercentage(value) {
    return (+value / 100).toString();
  }

  convertToPercentage(value) {
    return (+value * 100).toString();
  }

  fieldIsPercentage(source: FieldDefinition): boolean {
    return source?.input?.subtype === FieldInputSubTypes.Percent;
  }

  mapValues(
    model: { [fieldName: string]: any },
    fieldsByName: { [name: string]: FieldDefinition },
    mapValueFn: (value: any, field: FieldDefinition) => any
  ): { [fieldName: string]: any } {
    const convertedModel = {};

    for (let fieldName in model) {
      const field = fieldsByName[fieldName];
      if (field) {
        const value = model[fieldName];
        const convertedValue = mapValueFn(this.clone(value), field);
        convertedModel[fieldName] = convertedValue;
      }
    }
    return convertedModel;
  }

  parseUnitLabel(source: FieldDefinition, dependencies: FieldParseDependencies): string {
    if (this.fieldHasUoM(source)) {
      const conversion = this.parseUnitConversion(source, dependencies);
      if (!conversion) {
        throw new Error(`No conversion factor was found.`);
      }
      return conversion.unitTypeToDescription;
    }

    if (source.input.subtype === FieldInputSubTypes.Percent) {
      return '%';
    }

    return undefined;
  }

  convertModelToDataTypes(
    model: DynamicFormUIValues,
    fieldsByName: { [name: string]: FieldDefinition }
  ): DynamicFormUIValues {
    const convertedModel = {};
    for (let fieldName in model) {
      const field = fieldsByName[fieldName];
      const value = model[fieldName];
      let convertedValue = this.convertToDataTypes(value, field);
      convertedModel[fieldName] = convertedValue;
    }
    return convertedModel;
  }

  convertToDataTypes(value: any, field: FieldDefinition) {
    let convertedValue = this.clone(value);
    const fieldIsSelector = field.input.type === FieldInputTypes.Selector;
    switch (field.dataType) {
      case FieldDataType.Int:
      case FieldDataType.Double:
        convertedValue = this.convertToNumeric(value, fieldIsSelector);
        break;
      case FieldDataType.DateTime: {
        const dateValue = this.fromApiFormat(value);
        convertedValue = this.isValidDate(dateValue) ? dateValue : value;
        break;
      }
      case FieldDataType.Bool: {
        convertedValue = this.convertToBool(value, fieldIsSelector);
        break;
      }
    }
    return convertedValue;
  }

  private isValidDate(d: any) {
    const isNotANumber = isNaN(d);
    const isDateInstance = d instanceof Date;

    return isDateInstance && !isNotANumber;
  }

  private convertToNumeric(value: string | number, fieldIsSelector: boolean): number | string {
    const isEmptyValue =
      value === null ||
      value === 'null' ||
      typeof value === 'undefined' ||
      (typeof value === 'string' && value.trim().length === 0);

    if (isEmptyValue) {
      return fieldIsSelector ? 'null' : null;
    }
    return Number(value);
  }

  private convertToBool(strValue: string, fieldIsSelector: boolean): boolean | string {
    const value = strValue?.toLowerCase();
    if (value === 'true' || value === '1') {
      return true;
    }
    if (value === 'false' || value === '0') {
      return false;
    }
    return fieldIsSelector ? 'null' : null;
  }

  private getMappedId = (dataSource: SettingsDataSource, identifier: any) => {
    let strIdentifier;

    if (typeof identifier === 'object') {
      strIdentifier = this.normalizeIdentifierObject(identifier);
    } else {
      try {
        const parsedIdentifier = JSON.parse(identifier);
        if (typeof parsedIdentifier === 'object') {
          strIdentifier = this.normalizeIdentifierObject(parsedIdentifier);
        } else {
          // The value is serializable to JSON but it it not an object (like a number).
          strIdentifier = String(parsedIdentifier);
        }
      } catch (_) {
        // The value cannot be converted to JSON (like a random string).
        strIdentifier = String(identifier);
      }
    }
    return `${dataSource}__${strIdentifier}`;
  };

  private normalizeIdentifierObject(identifier: any): string {
    // Normalize the object, sort the properties so they match and join again.
    const lowerKeysObj = this._objectHelperService.lowerCapitalizeKeys(identifier);
    const result = JSON.stringify(lowerKeysObj, Object.keys(lowerKeysObj).sort());
    return result;
  }

  private getMappedIdFromField = (field: FieldDefinition) =>
    this.getMappedId(field.source.dataSource, field.source.identifier);

  private getControlName = (field: FieldDefinition) => field.name;

  fromHashedDataSourceToName = (
    model: FormValuesDataSource,
    fieldsHash: { [mappedId: string]: FieldDefinition[] }
  ): DynamicFormUIValues => {
    const hashedModel: { [mappedId: string]: any } = {};
    const mappedModel: { [fieldName: string]: any } = {};

    Object.entries(model).forEach(([dataSource, content]) => {
      content.values.forEach((value) => {
        const mappedId = this.getMappedId(dataSource as any, value.key);
        hashedModel[mappedId] = this.clone(value.value);
      });
    });

    // The deleteIfNull fields may not appear in the model if they have null value
    Object.values(fieldsHash).forEach((fields) => {
      const field = fields[0];
      if (field?.source.deleteIfNull) {
        const mappedId = this.getMappedId(field.source.dataSource, field.source.identifier);
        if (typeof hashedModel[mappedId] === 'undefined') {
          hashedModel[mappedId] = null;
        }
      }
    });

    Object.entries(hashedModel).forEach(([mappedId, value]) => {
      const fields = fieldsHash[mappedId];
      if (fields) {
        // If there are multiple fields for the same mappedId, they will all receive the same value.
        fields.forEach((field) => {
          const controlName = this.getControlName(field);
          mappedModel[controlName] = value;
        });
      }
    });
    return mappedModel;
  };

  fromNameToHashedDataSource = (
    model: DynamicFormUIValues,
    fieldsHashByMappedId: { [mappedId: string]: FieldDefinition[] }
  ): FormValuesDataSource => {
    const mappedModel = {};

    const fieldsHashByName = this.groupFieldsByName(fieldsHashByMappedId);

    Object.entries(model).forEach(([controlName, value]) => {
      const field = fieldsHashByName[controlName];
      if (!field) {
        throw new Error(`No field could be found for control name: ${controlName}`);
      }

      if (!this.isValidValue(value)) {
        return;
      }

      const { dataSource, identifier } = field.source;
      if (typeof mappedModel[dataSource] === 'undefined') {
        mappedModel[dataSource] = {
          values: [],
        };
      }
      if (this.isValidValue(value)) {
        mappedModel[dataSource].values.push({
          key: identifier,
          value: this.clone(value),
        } as FormValuesDataSourceItem);
      }
    });

    return mappedModel;
  };

  hashApiFields(fieldsByCategories: FieldsByCategory[]): {
    [key: string]: FieldDefinition[];
  } {
    const allFields = fieldsByCategories.reduce((accum, fieldByCategory) => {
      return accum.concat(Object.values(fieldByCategory.fields));
    }, []);

    const results = this.groupByFn(allFields, this.getMappedIdFromField);
    return results;
  }

  groupFieldsByName(fieldsHash: { [key: string]: FieldDefinition[] }): {
    [name: string]: FieldDefinition;
  } {
    const fieldsByName = this.groupArraysByFnSingle(Object.values(fieldsHash), this.getControlName);
    return fieldsByName;
  }

  /**
   * null is a valid value, but not undefined or NaN.
   */
  isValidValue(value: any): boolean {
    const isValid =
      typeof value !== 'undefined' &&
      (typeof value == 'string' || (typeof value != 'string' && !isNaN(value)));
    return isValid;
  }
}
