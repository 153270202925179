/**
 * Enumerates all possible navigation keys in the app. Is used for permissions.
 * Each route has an associated value in this enum.
 * Note: /users and /users?id=1 are both the same route.
 */

export enum NavKeys {
  Home = 'home',
  LeakageCalculationModule = 'leakage-calculation',
  DistributionNetwork = 'distribution-network',
  TransmissionNetwork = 'transmission-network',
  PressureMonitoring = 'pressure-monitoring',
  AlcModule = 'alc',
  Prioritisation = 'prioritisation',
  ActivityRaising = 'activity-raising',
  Activities = 'activities',
  Leaks = 'leaks',
  Campaigns = 'campaigns',
  AdministrationModule = 'administration',
  Users = 'users',
  Groups = 'groups',
  ConfigurationModule = 'configuration',
  TelemetryPoints = 'telemetry-points',
  WaterBalanceModule = 'water-balance-module',
  WaterBalance = 'water-balance',
  DataAccessModule = 'data-access-module',
  DataVisualization = 'data-visualization',
  AlarmsConfiguration = 'alarms-configuration',
  AlarmsCreationTelemetry = 'alarm-creation-telemetry',
  AlarmsCreationAlgorithms = 'alarm-creation-algorithm',
  AlarmsCreation = 'alarm-creation',
  MonitoringModule = 'monitoring-module',
  Notifications = 'notifications',
  NetworkElementConfig = 'ne-config',
  NetworkElementDetails = 'ne-config-details',
  LeakageReporting = 'leakage-reporting',
  Algorithms = 'algorithms-configuration',
  PressureManagementModule = 'pressure-module',
  BiModule = 'bi-module',
  Bi = 'bi',
  Map = 'map',
  Hierarchy = 'hierarchy',
  CustomerDetails = 'customer-details',
  BIHome = 'bi-home',
  UoM = 'uom',
  Schematics = 'schematics',
  LogsModule = 'logs-module',
  LogsInterfaces = 'logs-interfaces',
  LogsSystem = 'logs-system',
  Audits = 'audits',
  DataValidation = 'data-validation',
  Calendar = 'calendars',
  MergedZones = 'merged-zones',
  Integration = 'integration',
}
