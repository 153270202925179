export class UserDto {
  userCode: string;
  surname: string;
  name: string;
  groupIds: number[];
  isActive: boolean;
  isSuperUser: boolean;
  lastAccess: Date;

  public get getFullName(): string {
    return `${this.name} ${this.surname}`;
  }

  public constructor(init?: Partial<UserDto>) {
    Object.assign(this, init);
  }
}
