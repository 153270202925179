interface IAttributes {
  ApplicationLanguage: number;
  IsSundayFirstDayOfWeek: number;
  NightlyScheduleHour: number;
  FiscalYearStartday: number;
  FiscalYearStartMonth: number;
  JuneReturnReportDay: number;
  JuneReturnReportMonth: number;
  RerunAlgorithmsExitTime: number;
  CompanyName: number;
  AppName: number;
  TechnicianChargeAccount: number;
  ComputerizedMaintenanceManagementSystem: number;
  CompanyHierarchyElemName: number;
  Currency: number;
  SmartMeterCustomersAlias: number;
  SmartMeterCustomersShortAlias: number;
  SkillCardPrefix: number;
  SupportPhone: number;
  SupportEmail: number;
  ComputerizedMaintenanceManagementSystemShortAlias: number;
  HierarchyFamilyTargetType: number;
  HierarchyElementTargetType: number;
  ShowInLocalTime: number;
  DaysForRaisingALeak: number;
  DaysForRelinkAnActivity: number;
  SmartAlarmsBatchSize: number;
  MaxItemsForNavigation: number;
  MaxItemsForRaiseActivities: number;
  MaxSeriesSupportedInChar: number;
}

export const ApplicationAttributes: Readonly<IAttributes> = {
  ApplicationLanguage: 1,
  IsSundayFirstDayOfWeek: 2,
  NightlyScheduleHour: 3,
  FiscalYearStartday: 4,
  FiscalYearStartMonth: 5,
  JuneReturnReportDay: 6,
  JuneReturnReportMonth: 7,
  RerunAlgorithmsExitTime: 8,
  CompanyName: 9,
  AppName: 10,
  TechnicianChargeAccount: 11,
  ComputerizedMaintenanceManagementSystem: 12,
  CompanyHierarchyElemName: 13,
  Currency: 14,
  SmartMeterCustomersAlias: 15,
  SmartMeterCustomersShortAlias: 16,
  SkillCardPrefix: 17,
  SupportPhone: 18,
  SupportEmail: 19,
  ComputerizedMaintenanceManagementSystemShortAlias: 20,
  HierarchyFamilyTargetType: 21,
  HierarchyElementTargetType: 22,
  ShowInLocalTime: 23,
  DaysForRaisingALeak: 24,
  DaysForRelinkAnActivity: 29,
  SmartAlarmsBatchSize: 31,
  MaxItemsForNavigation: 20,
  MaxItemsForRaiseActivities: 20,
  MaxSeriesSupportedInChar: 10,
};
