<div class="grid-filter-container">
  <mat-form-field appearance="outline" class="full-width">
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let e of selectedElements" (click)="removeChip($event, e)">
        {{ e.text }}
        <mat-icon matChipRemove (click)="removeChip($event, e)">cancel</mat-icon>
      </mat-chip-row>

      <input
        matInput
        type="text"
        [matAutocomplete]="auto.matAutocomplete"
        [formControl]="selectControl"
        [matChipInputFor]="chipList"
      />
    </mat-chip-grid>
  </mat-form-field>

  <wlm-autocomplete-standalone
    #auto
    [elements]="filteredElements"
    [labelFn]="autocompleteLabelFn"
    [valueFn]="autocompleteValueFn"
    (optionSelected)="optionClicked($event)"
  >
  </wlm-autocomplete-standalone>
</div>
