import { HistoricalBarSettings } from '../../historical-bars/models/historical-bar-settings';

export class ListItem {
  id: number;
  labelKey: string;
  iconName?: string;
  historicalBarSettings?: HistoricalBarSettings;
  lastExecutionDate?: Date;

  constructor(init: Readonly<ListItem>) {
    Object.assign(this, init);
  }
}
