import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { IHierarchyElementAttributeSaveDto } from '../../../shared/model/hierarchy/hierarchy-element-attribute-save.dto';
import { OperationResponseDto } from '../../../../../common-modules/dependencies/shared/model/responses/operation-response.dto';

@Injectable({ providedIn: 'root' })
export class HierarchyElementAttributesService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/he/attribute`;
  }

  save(heAttributesSave: IHierarchyElementAttributeSaveDto): Observable<OperationResponseDto> {
    return this.httpCacheClient.post(`${this.url}/save`, heAttributesSave, {
      avoid: true,
    });
  }
}
