<div class="container-fluid">
  <div class="container-content" [class.hide-container]="!collapsed">
    <ng-content select="[collapsed]"></ng-content>
  </div>
  <div class="container-content" [class.hide-container]="collapsed">
    <ng-content select="[opened]"></ng-content>
  </div>

  <div class="collapse-icon">
    <mat-icon *ngIf="collapsed" (click)="collapsed = false">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="!collapsed" (click)="collapsed = true">keyboard_arrow_up</mat-icon>
  </div>

  <div class="collapse-bar"></div>

  <ng-content select="[behind-content]"></ng-content>
</div>
