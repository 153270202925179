import { IndexableType } from 'dexie';
import { BaseDatabase } from './base.database';

export abstract class BaseDatabaseService<TModel = any, TKey = IndexableType> {
  constructor(public db: BaseDatabase<TModel, TKey>) {}

  public getAll = async (): Promise<TModel[]> => {
    return await this.db.dbTable.toArray();
  };

  public getById = async (id: TKey): Promise<TModel> => {
    return await this.db.dbTable.get(id);
  };

  public bulkAdd = async (elements: TModel[]): Promise<TKey> => {
    return await this.db.dbTable.bulkAdd(elements);
  };

  public add = async (element: TModel): Promise<TKey> => {
    return await this.db.dbTable.add(element);
  };

  public put = async (element: TModel): Promise<TKey> => {
    return await this.db.dbTable.put(element);
  };

  public clear = async (): Promise<void> => {
    return await this.db.dbTable.clear();
  };
}
