<wlm-spinner-wrapper [showSpinner]="showPageSpinner">
  <div id="center-page" class="page-background">
    <div class="top-container" #topcontainer>
      <div class="title-position" id="titlecontainer">
        <h1 *ngIf="titleKey">{{ titleKey | translate : titleKeyParams }}</h1>
        <h1 *ngIf="title">{{ title }}</h1>
      </div>
      <div class="filter-buttons-container" id="buttoncontainer">
        <ng-content select="[page-top-buttons]"></ng-content>
      </div>
    </div>

    <div id="center-container">
      <ng-content select="[page-filters]"></ng-content>
      <ng-content select="[page-content]"></ng-content>
    </div>
  </div>
</wlm-spinner-wrapper>
