import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { ActivityStatusFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/activity-status-filter';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { NotificationGridSettingService } from 'src/app/common-modules/shared/notifications/notification-gridsetting.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { PeriodTypesEnum } from 'src/app/common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from 'src/app/common-modules/wlm-charts/core/models/time-selector-settings';
import { GridPersistedElements } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { WNavigateSettings } from 'src/app/common-modules/dependencies/navigation/w-navigate-by';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { NavMenuBuilderHelperService } from 'src/app/common-modules/shared/helpers/navmenu-builder-helper.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { TimeSelectorChartWidgetComponent } from 'src/app/common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { Images } from 'src/assets/icons/previous-icons/images';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { NavigationElement } from '../../map/navigation-element';
import { NavigationElementType } from '../../map/navigation-element-type';
import { ALCActivityDto } from '../../shared/model/alc/alc-activity.dto';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { ActivityConfirmationGridComponent } from '../activities-components/activity-confirmation-grid/activity-confirmation-grid.component';
import { ActivityGridFilterConfiguration } from '../activities-components/activity-grid-filter/activity-grid-filter-configuration';
import { ActivityManualClosureComponent } from '../activities-components/activity-manual-closure/activity-manual-closure/activity-manual-closure.component';
import { ActivityNotificationDetailComponent } from '../activities-components/activity-notification-detail/activity-notification-detail.component';
import { ActivityOperationGridComponent } from '../activities-components/activity-operation-grid/activity-operation-grid.component';
import { ActivitiesGridFilterValidations } from '../activities-grid/activities-grid-filter-validations';

const COMPONENT_SELECTOR = 'wlm-activities-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activities-page.component.html',
  styleUrls: ['./activities-page.component.scss'],
})
export class ActivitiesPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) public activitiesGrid: GenericGridComponent;

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private readonly _mapParametersLayers = [
    1, 777001, 777002, 777003, 777011, 777012, 777101, 777102, 777104, 777111, 777112, 888210,
  ];
  private readonly _commonLeakYears = [0, -1];

  private readonly _chartWorkspaceName = 'ActivitiesDetailsChart';

  gridFilters: DataBindingFilters;
  private filterDetailsParameters: TabDetailPanelParameters;
  public T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;
  gridSettings: GridSetting;

  protected titleService: Title;
  protected dialogService: DialogService;
  protected localStorageService: LocalStorageService;
  protected rightPanelService: RightPanelService;

  panelOpenState = true;
  activityParams: string;
  selectedHierarchyElements: string[];
  selectedHierarchyFamilyId: string;
  selectedactivityStatus: boolean[];

  navigations: DropdownNavigationItem[] = [];

  selectedHierarchyElementsFromNavigation: string[];
  selectedHierarchyFamilyIdFromNavigation: string;

  startDateFromNavigation: Date;
  endDateFromNavigation: Date;

  activityGridFilterConfiguration: ActivityGridFilterConfiguration;
  gridFiltersForBinding: DataBindingFilters;
  canNavigateToActivity = false;
  closeActivityIcon: string = Images.IconPersistenceWindowArrow;
  selectedActivityParams: TabDetailPanelParameters;
  startDate: Date;
  endDate: Date;
  useInclusiveEndDate = true;

  public clearFiltersIcon: string = Images.IconClearFilter;
  public initializingSubject$: Observable<boolean>;

  private _hasBeenSelected = false;

  configReady = false;

  gridName = 'Activities';
  canLoad: boolean;
  autoLoad: boolean;
  clearAll$ = new Subject<void>();
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  persistFilters$ = new Subject<void>();

  // Filters fieldNames
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  startDateFieldName = 'StartDate';
  endDateFieldName = 'EndDate';
  repairFieldName = 'IsRepairActivity';
  detectionFieldName = 'IsDetectionActivity';
  statusFieldName = 'ilpmsStatus';
  defaultHierarchyElementId = '';
  activityIdFieldName = 'activityId';

  offsetStartDate = 3; // 3 months

  private _selectedActivities: ALCActivityDto[];
  hasDateFromNavigation = false;
  activityIdsFromNavigation: string[];

  public get selectedActivities(): ALCActivityDto[] {
    return this._selectedActivities;
  }

  public set selectedActivities(value: ALCActivityDto[]) {
    this._selectedActivities = value;
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'ActivitiesPageCrud';
  }

  public get closeActivityDisabled(): boolean {
    const enabled =
      this.selectedActivities?.length > 0 &&
      this.selectedActivities?.every(
        (x) => x.isProactive && !x.sapstatus && x.isRepairActivity && x.ilpmsstatus
      );
    return !enabled;
  }

  private _selectedActivity: ALCActivityDto;
  public get selectedActivity(): ALCActivityDto {
    return this._selectedActivity;
  }
  public set selectedActivity(v: ALCActivityDto) {
    this._selectedActivity = v;
    this.updateNavigationParams();
  }

  constructor(
    private closeActivityDialog: MatDialog,
    private route: ActivatedRoute,
    private _gridService: GridSettingsService,
    private _globalsService: GlobalsService,
    private filtersAdapterService: FiltersAdapterService,
    private _notificationGridSettingService: NotificationGridSettingService,
    private _mapHelperService: MapHelperService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService,
    private readonly _navMenuBuilderHelperService: NavMenuBuilderHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params);

      this.init();

      this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
        const panelSettings = new TabDetailPanelSettings();
        panelSettings.addComponent(ActivityOperationGridComponent, ts.operations);
        panelSettings.addComponent(ActivityConfirmationGridComponent, ts.confirmations);
        panelSettings.addComponent(ActivityNotificationDetailComponent, ts.notifications);
        panelSettings.addComponent(MapRelatedComponent, ts.map);
        panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);

        this.rightPanelService.setTabSettings(panelSettings);
        this.panelOpenState = this.getPersisted(TabDetailParameterName.filterExpanded);
      });
    });
  }

  init(): void {
    this.loadState();
    this.updateNavigationParams();
    this.initGrid();
  }

  private applyGridFiltersByParams() {
    if (this.activityIdsFromNavigation?.length) {
      this.activitiesGrid.clearFilters();
      this.activitiesGrid.applyColumnFilter(
        this.getActivitiesFilter(this.activityIdsFromNavigation)
      );
      this.activitiesGrid.reloadGrid();
    }
  }

  getActivitiesFilter(activityIds: string[]): FilterDescriptor[] {
    const filters = [];
    activityIds.forEach((activityId) => {
      const filter = {
        field: this.activityIdFieldName,
        operator: 'eq',
        value: activityId,
      };
      filters.push(filter);
    });

    return filters;
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            if (gridSettings) {
              gridSettings.notificationSetting =
                this._notificationGridSettingService.getNotificationSettingsForGrid(this.gridName);

              this.setGridSettings(gridSettings);
            }
          },
        });
      }
    }
  }

  loadState() {
    const filters = [];

    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = (
        this.getPersisted(this.hierarchyElementIdFieldName, [
          { value: this.defaultHierarchyElementId, label: '' },
        ]) as BasicFilter
      ).value;
    }

    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.getPersisted(this.hierarchyElementFamilyFieldName, undefined);
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }

    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
      .subscribe((familyId) => {
        this.selectedHierarchyFamilyId = familyId;

        const startDate = this.hasDateFromNavigation
          ? this.startDateFromNavigation
          : this.loadPersistedDate(
              this.startDateFieldName,
              this.startDateFromNavigation,
              false,
              true
            );

        const hasEndDatePersisted =
          this.getPersisted(this.endDateFieldName, null, null, false) != null;

        let endDate = this.hasDateFromNavigation
          ? this.endDateFromNavigation
          : this.loadPersistedDate(this.endDateFieldName, this.endDateFromNavigation, true, true);

        if (!hasEndDatePersisted) {
          endDate = this.dateHelperService.addDays(endDate, 1);
        }

        const gridFiltersForBinding = this.getPersisted(
          TabDetailParameterName.dataBindingFilter,
          undefined
        ) as DataBindingFilters;

        let dataBindingCasted = new DataBindingFilters();
        if (gridFiltersForBinding?.exportableFilter) {
          dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
          dataBindingCasted.reloadFromSerialization();
        } else {
          dataBindingCasted = null;
        }

        const statusFilter = this.getPersistedStatus();

        if (statusFilter) {
          filters.push(statusFilter);
        }

        const activityTypesFilter = this.getPersistedTypes();
        if (activityTypesFilter) {
          filters.push(activityTypesFilter);
        }

        this.loadPage(dataBindingCasted, startDate, endDate, filters);
      });
  }

  getPersistedTypes() {
    let defaultBasicFilter = new BasicFilter(this.repairFieldName, true);
    const repairPersisted = this.getPersisted(
      this.repairFieldName,
      defaultBasicFilter
    ) as BasicFilter;

    defaultBasicFilter = new BasicFilter(this.detectionFieldName, true);
    const detectionPersisted = this.getPersisted(
      this.detectionFieldName,
      defaultBasicFilter
    ) as BasicFilter;

    const typesCustomFilter = this.filtersAdapterService.activityCategoryTypesAdapter(
      new Map([
        [this.repairFieldName, repairPersisted],
        [this.detectionFieldName, detectionPersisted],
      ]),
      this.repairFieldName,
      this.detectionFieldName
    );
    return typesCustomFilter;
  }

  getPersistedStatus(): ActivityStatusFilter {
    const key = this.statusFieldName;
    const defaultStatusBasicFilter = new BasicFilter(this.statusFieldName, [
      { value: 1, label: 'Open' },
      { value: 0, label: 'Closed' },
    ]);

    const persistedStatus = this.getPersisted(key, defaultStatusBasicFilter) as BasicFilter;

    const statusCustomFilter = this.filtersAdapterService.activityOnlyOpenAdapter(
      new Map([[this.statusFieldName, persistedStatus]]),
      this.statusFieldName
    );

    return statusCustomFilter;
  }

  private loadPage(
    gridFiltersForBinding: DataBindingFilters,
    startDate: any,
    endDate: any,
    filters: IFilter[]
  ) {
    const defaultDateRange = this.dateHelperService.createDefaultDateRange();

    const configuration = new ActivityGridFilterConfiguration(
      this.persistencyArea,
      this.hierarchyElementIdFieldName,
      this.hierarchyElementFamilyFieldName,
      this.startDateFieldName,
      this.endDateFieldName,
      this.repairFieldName,
      this.detectionFieldName,
      this.statusFieldName,
      [0, 1],
      [0, 1],
      defaultDateRange,
      this.selectedHierarchyElements,
      this.selectedHierarchyFamilyId,
      new DateRange(startDate, endDate, null, null, null, this.useInclusiveEndDate),
      filters
    );

    this.activityGridFilterConfiguration = configuration;
    if (gridFiltersForBinding) {
      this.autoLoad = true;
    }

    this.configReady = true;
  }

  ngOnDestroy() {
    this.persist(TabDetailParameterName.dataBindingFilter, this.gridFiltersForBinding);
    this.persist(TabDetailParameterName.hierarchyElementId, '');
    this.persist(TabDetailParameterName.elementName, null);

    super.ngOnDestroy();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  setNavigationParameters(parameters: TabDetailPanelParameters): void {}

  readNavigationParameters(params: Params) {
    const queryParams = this.getQueryParams(params);
    this.useGridPersistence = queryParams.hierarchyElementIds === undefined;
    this.selectedHierarchyElements =
      typeof queryParams.hierarchyElementIds === 'string'
        ? [queryParams.hierarchyElementIds]
        : queryParams.hierarchyElementIds;
    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;

    this.startDateFromNavigation = queryParams?.startDate
      ? new Date(queryParams.startDate)
      : this.dateHelperService.getDefaultStartDate(this.offsetStartDate);
    this.endDateFromNavigation = queryParams?.endDate
      ? new Date(queryParams.endDate)
      : this.dateHelperService.getDefaultEndDate();
    this.autoLoad = this.selectedHierarchyElements?.length > 0;

    if (queryParams.activityIds?.length) {
      this.setNavigationByIds(queryParams);
    }
  }

  closeActivity() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '480px';
    dialogConfig.data = { activities: this.selectedActivities, user: '' };

    const popup = this.closeActivityDialog.open(ActivityManualClosureComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.activitiesGrid.clearRowSelected();
          this.loadGrid();
        }
      });
  }

  private setNavigationByIds(queryParams: Params) {
    const { startDate, endDate, activityIds } = queryParams;
    this.startDateFromNavigation = new Date(startDate);
    this.endDateFromNavigation = new Date(endDate);
    this.hasDateFromNavigation = true;
    this.activityIdsFromNavigation = Array.isArray(activityIds) ? activityIds : [activityIds];
    this.selectedHierarchyElements = [];
    this.useGridPersistence = false;
  }

  loadGrid(forcedDataBinding: DataBindingFilters = null) {
    let newGridFilters = new DataBindingFilters();
    if (forcedDataBinding) {
      newGridFilters = forcedDataBinding;
    } else {
      newGridFilters.filters = this.gridFilters?.filters;
    }

    const filterValidation = new ActivitiesGridFilterValidations();
    if (
      newGridFilters?.filters &&
      filterValidation.validateFilters(['hierarchyElementsIds'], newGridFilters)
    ) {
      this.gridFiltersForBinding = newGridFilters;
    }

    this.persistFilters$.next();
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();
    if (
      filtersParameters &&
      filterValidation.validateFilters(['hierarchyElementsIds'], filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  getSelectedActivity(selectedActivity: ALCActivityDto) {
    this.selectedActivity = selectedActivity;
    if (selectedActivity != null) {
      this._hasBeenSelected = true;
    }
    this.SendParameters(selectedActivity, this.filterDetailsParameters);
    this.canNavigateToActivity = true;
  }

  private SendParameters(selectedElement: ALCActivityDto, filter: TabDetailPanelParameters) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = filter ?? new TabDetailPanelParameters();

      parameters.addParameter(TabDetailParameterName.workOrderId, selectedElement?.workOrderId);
      parameters.addParameter(
        TabDetailParameterName.hierarchyElementId,
        selectedElement?.hierarchyElementId
      );
      parameters.addParameter(
        TabDetailParameterName.elementName,
        selectedElement?.activityExternalId
          ? ts['order-number-title'] + selectedElement?.activityExternalId
          : null
      );
      parameters.addParameter(
        TabDetailParameterName.hierarchyFamilyId,
        selectedElement?.hierarchyFamilyId
      );

      let timeSelectorChartSetting = null;

      if (selectedElement?.hierarchyElementId) {
        const wlmElement = this.getWlmElement(selectedElement);

        timeSelectorChartSetting = new TimeSelectorChartSettings({
          chartSetting: new CustomWorkspaceChartSettings({
            chartType: ChartType.customizable,
            dataParameters: new CustomWorkspaceChartDataParameters({
              startDate: new Date(),
              endDate: new Date(),
              workspaceName: this._chartWorkspaceName,
              elements: [wlmElement],
            }),
            exportFileName: `${this.titleService.getTitle()} ${
              selectedElement?.hierarchyElementId
            }`,
          }),
          includeDefaultPeriods: true,
          defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
        });
      }

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      const mapParameters = this.initializeMapParameters();
      if (this._hasBeenSelected) {
        mapParameters.navigationParam = selectedElement?.activityId
          ? new NavigationElement({
              elementId: selectedElement?.activityId,
              type: NavigationElementType.Activities,
              hierarchyElementId: selectedElement?.hierarchyElementId,
            })
          : null;
        mapParameters.visibleLayersIds = [
          ...new Set([...mapParameters.visibleLayersIds, ...this._mapParametersLayers]),
        ];
        mapParameters.leakYears = [
          ...new Set([...mapParameters.leakYears, ...this._commonLeakYears]),
        ];
      }
      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      this.selectedActivityParams = parameters;
      this.rightPanelService.setTabParameters(parameters);
    });
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  /**
   * When all filters are available, perform autoload.
   */
  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        this.loadGrid();
      }

      this.applyGridFiltersByParams();
    });
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return of(true);
  }
  private updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: [this.selectedActivity],
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.DataVisualization,
        validationType: 'custom',
        customValidation: this.selectedActivity == null,
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  private onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const element = this.getWlmElement(this.selectedActivity);

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      [element],
      this._chartWorkspaceName
    );
  };

  private getWlmElement(selectedElement: ALCActivityDto): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null,
      EntityTypes.hierarchyElement
    );
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings?.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings?.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings?.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }
}
