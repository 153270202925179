<h3 *ngIf="titleKey">{{ titleKey | translate }}</h3>
<hr class="title-separator" />
<div class="form-container">
  <wlm-notification-form
    *ngIf="selectedNotification"
    [mode]="formMode"
    [model]="selectedNotification"
    [submit$]="submit$"
    (titleKey)="titleKey = $event"
    (loaded)="onFormLoad($event)"
    (valid)="onFormValid($event)"
    (submitResult)="onSubmitResult()"
    (canSave)="onCanSave($event)"
  ></wlm-notification-form>
</div>
<mat-dialog-actions>
  <div class="buttons-container">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!formLoaded || !formValid || !canSave"
      (click)="onSubmit()"
    >
      {{ 'common.save' | translate }}
    </button>
    <button mat-raised-button (click)="onClose()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
