export class BiTemplateDto {
  bidashboardTemplateId: number;
  templateName: string;
  isSystemTemplate: boolean;
  isSystemDefault: boolean;
  isHomepageDefault: boolean;
  isDashboardDefault: boolean;

  constructor(init: BiTemplateDto) {
    Object.assign(this, init);
  }
}
