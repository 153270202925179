export enum HistoricalChartDimensions {
  Id = 0,
  StartDate = 1,
  EndDate = 2,
  Label = 3,
  Color = 4,
  AdditionalParams = 5,
  VisibleStartDate = 6,
  VisibleEndDate = 7,
  Tooltip = 8,
}
