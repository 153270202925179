import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLayoutModule } from '../dynamic-layout/dynamic-layout.module';
import { GenericStoreService } from '../dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from '../redux/redux-state.module';
import { ComponentRendererComponent } from '../shared/component-renderer/component-renderer.component';
import { LabelValueListModule } from '../shared/core/label-value-list/label-value-list.module';
import { IconModule } from '../shared/icon/icon.module';
import { GlDetailPanelComponent } from './tab-panel/gl-detail-panel/gl-detail-panel.component';

@NgModule({
  declarations: [GlDetailPanelComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    DynamicLayoutModule.forFeature({
      persistency: 'local',
    }),
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
    LabelValueListModule,
    IconModule,
    ComponentRendererComponent,
  ],
  exports: [GlDetailPanelComponent],
})
export class RightPanelModule {}
