export enum FilterAdapterEnum {
  DateRange = 'DateRange',
  HierarchyElementPathFilter = 'HierarchyElementPathFilter',
  ElementTypeFilter = 'ElementTypeFilter',
  ActivityStatus = 'ActivityStatus',
  ActivityCategory = 'ActivityCategory',
  ArraySingleValue = 'ArraySinglesValue',
  NetworkElementTypeFilter = 'NetworkElementTypeFilter',
  Dimension = 'Dimension',
  Algorithm = 'Algorithm',
  ElementTarget = 'ElementTarget',
  TimeAggregation = 'TimeAggregation',
  User = 'User',
  isZoneSelected = 'isZoneSelected',
  Family = 'Family',
}
