import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationMode } from 'src/app/common-modules/dependencies/navigation/navigation-mode.enum';
import { NavigationsConstants } from 'src/app/common-modules/shared/constants/navigation-constants';

import { DropdownNavigationItem } from '../../dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
} from '../../dependencies/navigation/tab-detail-component';
import { WlmNavigationService } from '../../dependencies/navigation/wlm-navigation.service';
import { CommonSharedModule } from '../common-shared.module';

import { DialogService } from '../dialogs/dialogs.service';
import { BasicFilter } from '../filters/component-filters/basic-filter';
import { DateHelperService } from '../helpers/date-helper.service';
import { LocalStorageService } from '../local-storage.service';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { RightPanelSettings } from '../navigation/right-panel-settings';
import { RightPanelService } from '../navigation/right-panel.service';
import { NotificationSelectionService } from '../notifications/notification-selection.service';
import { BaseComponent } from './base.component';

@Directive()
export abstract class BasePageComponent extends BaseComponent implements OnInit, OnDestroy {
  public abstract get titleTranslationKey(): string;
  public abstract get persistencyArea(): string;
  public abstract get pageCrud(): string;
  public abstract get navigations(): DropdownNavigationItem[];

  private isDestroyed$ = new Subject<void>();

  protected hasRightPanel = true;
  protected pageId: string;

  protected get getSessionId(): string {
    return this.localStorageService.getRawValue('session_state', false);
  }

  protected titleService: Title;
  protected dialogService: DialogService;
  protected localStorageService: LocalStorageService;
  protected rightPanelService: RightPanelService;
  protected navigationService: WlmNavigationService;
  protected notificationSelectionService: NotificationSelectionService;
  protected dateHelperService: DateHelperService;

  protected localization: LocalizationHelperService;

  rightPanelChanges$: Observable<RightPanelSettings>;
  rightPanelVisibilityChanges$: Observable<boolean>;
  rightPanelKey = 'rightPanel';
  rightPanelVisibilityKey = 'rightPanel-visibility';
  rightPanelWidthKey = 'rightPanel-width';
  useGridPersistence = true;

  constructor() {
    super();
    this.localization = CommonSharedModule.injector.get(LocalizationHelperService);
    this.titleService = CommonSharedModule.injector.get(Title);
    this.rightPanelService = CommonSharedModule.injector.get(RightPanelService);
    this.dialogService = CommonSharedModule.injector.get(DialogService);
    this.localStorageService = CommonSharedModule.injector.get(LocalStorageService);
    this.navigationService = CommonSharedModule.injector.get(WlmNavigationService);
    this.dateHelperService = CommonSharedModule.injector.get(DateHelperService);
    this.notificationSelectionService = CommonSharedModule.injector.get(
      NotificationSelectionService
    );
  }

  ngOnInit(): void {
    this.resetRightPanelAttributes();
    this.rightPanelService.setCurrentPageKey(this.persistencyArea);
    this.rightPanelService.setCurrentPageId(this.pageId);
    this.setTranslateTitle(this.titleTranslationKey);
    this.initRightPanelPersistence();
  }

  ngOnDestroy(): void {
    this.rightPanelService.setTabSettings(new TabDetailPanelSettings());
    this.notificationSelectionService.cleanNotificationDetails();

    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  /**
   * Receives a translation key for the title, gets the translation and sets it in the page.
   * @param newTitleKey The translation key of the title.
   */
  setTranslateTitle(newTitleKey: string) {
    this.localization.get(newTitleKey).subscribe((pageTitle) => {
      const newTitle = `WL - ${pageTitle}`;
      this.titleService.setTitle(newTitle);
    });
  }

  /**
   * Depending on navigation mode, gets the parameter from url params or gets from navigationService where previously where saved.
   * @param queryParams Params from url
   * @returns List of params
   */
  getQueryParams(queryParams: Params): Params {
    const navigationMode = this.localStorageService.get(NavigationsConstants.NavigationModeKey);
    const queryParmsStoraged = this.localStorageService.get(NavigationsConstants.QueryParamskey);

    this.localStorageService.remove(NavigationsConstants.NavigationModeKey);
    this.localStorageService.remove(NavigationsConstants.QueryParamskey);
    const results = this.isQueryParamStoraged(navigationMode, queryParmsStoraged)
      ? queryParmsStoraged
      : queryParams;

    return results;
  }

  isQueryParamStoraged(navigationMode: NavigationMode, queryParmsStoraged: any): boolean {
    return (
      navigationMode !== null &&
      typeof navigationMode !== 'undefined' &&
      queryParmsStoraged !== null &&
      typeof queryParmsStoraged !== 'undefined' &&
      navigationMode === NavigationMode.Backend
    );
  }

  protected getPersistedData(
    key: string,
    defaultValue?: any,
    useLocalStorage?: boolean,
    useCurrentKey = false
  ): any {
    const searchKey = useCurrentKey ? key : `${this.persistencyArea}-${key}`;

    const persisted = this.localStorageService.getTyped(searchKey, defaultValue, useLocalStorage);
    return persisted;
  }

  protected getPersisted(
    key: string,
    defaultValue?: any,
    forcedDefaultValue?: any,
    useLocalStorage?: boolean
  ) {
    useLocalStorage = useLocalStorage === undefined ? true : useLocalStorage;
    if (forcedDefaultValue) {
      return forcedDefaultValue;
    } else {
      return this.getPersistedData(key, defaultValue, useLocalStorage);
    }
  }

  protected persist(key: string, value: any, useLocalStorage?: boolean): any {
    // delete key if value is null or undefined
    if (value == null) {
      return this.removePersist(key);
    }

    return this.localStorageService.addOrUpdate(
      `${this.persistencyArea}-${key}`,
      value,
      useLocalStorage
    );
  }

  protected removePersist(key: string, useLocalStorage?: boolean) {
    return this.localStorageService.remove(`${this.persistencyArea}-${key}`, useLocalStorage);
  }

  protected loadPersistedDate(
    dateFieldName: string,
    defaultDate: Date,
    inclusiveDatePersisted: boolean = false,
    truncateDate: boolean = false
  ): Date {
    const defaultDateBasicFilter = new BasicFilter(dateFieldName, defaultDate);

    const key = dateFieldName;
    const persistedValue = (
      this.getPersisted(key, defaultDateBasicFilter, null, false) as BasicFilter
    ).value;

    const persistedDate = truncateDate
      ? this.dateHelperService.truncateDate(new Date(persistedValue))
      : new Date(persistedValue);

    return inclusiveDatePersisted
      ? this.dateHelperService.addDays(persistedDate, -1)
      : persistedValue;
  }

  private manageSizePersistency() {
    this.rightPanelChanges$ = this.rightPanelService.rightPanelSizeChanges.asObservable();
    this.rightPanelChanges$.pipe(takeUntil(this.isDestroyed$)).subscribe((setting) => {
      if (setting) {
        this.persist(this.rightPanelWidthKey, setting);
      }
    });

    this.rightPanelService.rightPanelInitialize.next(
      this.getPersisted(this.rightPanelWidthKey, null)
    );
  }

  private manageVisibilityPersistency() {
    this.rightPanelVisibilityChanges$ =
      this.rightPanelService.rightPanelVisibilityChanged.asObservable();
    this.rightPanelVisibilityChanges$.pipe(takeUntil(this.isDestroyed$)).subscribe((visibility) => {
      this.persist(this.rightPanelVisibilityKey, visibility);
      this.rightPanelService.setVisible(visibility);
    });

    this.rightPanelService.rightPanelVisibilityChanged.next(
      this.getPersisted(this.rightPanelVisibilityKey, true)
    );
  }

  private initRightPanelPersistence() {
    this.rightPanelService.setHasRightPanel(this.hasRightPanel);
    if (!this.hasRightPanel) {
      this.rightPanelService.setVisible(false);
      return;
    }
    this.manageVisibilityPersistency();
    this.manageSizePersistency();
  }

  protected resetRightPanelAttributes(): void {
    this.rightPanelService.setTabParameters(new TabDetailPanelParameters());
  }
}
