import { GChartTextStyles } from './g-chart-text-styles';

export class GChartTitle {
  title?: string = '';
  position?: 'left' | 'center' | 'right' = 'center';
  textStyle?: GChartTextStyles;

  constructor(init: Partial<GChartTitle>) {
    Object.assign(this, init);
  }
}
