import { Params } from '@angular/router';
import { BaseLink } from './base-link';
import { WNavigateSettings } from './w-navigate-by';

/**
 * The MenuLink, as it is consumed by Kendo Drawer.
 */
export class MenuLink extends BaseLink {
  urlPath: string;
  disabled?: boolean;
  disabledKeyTooltip = 'disabled-default-tooltip';
  queryParams?: Params;
  customNavMethod?: (data: WNavigateSettings) => any;

  constructor(init?: Partial<MenuLink>) {
    super(init);
    Object.assign(this, init);
  }
}
