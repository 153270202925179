import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { LargeUserSignalDto } from 'src/app/common-modules/dependencies/shared/model/large-user-signal.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { NeSignalSelectionPopupConfiguration } from 'src/app/common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup-configuration';
import { NeSignalSelectionPopupResult } from 'src/app/common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup-result';
import { NeSignalSelectionPopupComponent } from 'src/app/common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup/ne-signal-selection-popup.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { SignalSelectionPopupDimensions } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { LargeUserAssignedDto } from '../../shared/model/signals/large-user-assigned.dto';
import { LargeUserAvailableDto } from '../../shared/model/signals/large-user-available.dto';
import { LargeUserAssignedCardSettings } from './large-user-assigned-card/large-user-assigned-card-settings';
import { NeConfigurationLargeUserService } from './ne-configuration-large-user.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-large-user';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-large-user.component.html',
  styleUrls: ['./ne-configuration-large-user.component.scss'],
})
export class NeConfigurationLargeUserComponent extends BaseWidgetComponent implements OnInit {
  private _selectedNE: INetworkElementDto;
  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();
      this.loadLargeUsers();
    }
  }

  @Input() pageId?: string;
  @Input() widgetId: string;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'NeConfigurationLargeUserComponent';
  }

  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  assignedCardSettings: LargeUserAssignedCardSettings;
  queryParams: Map<string, any>;
  configuredLargeUsers: LargeUserAssignedDto[] = [];
  originalConfiguredLargeUsers: LargeUserAssignedDto[] = [];
  originalConfiguredSignals: LargeUserSignalDto[];
  excludedLargeUsers: LargeUserAvailableDto[] = [];
  refreshList$ = new Subject<void>();
  reloadAvailableList$ = new Subject<void>();
  removeDraggedItemFromSource$ = new Subject<boolean>();
  isReadOnly = true;

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  public get getAllSignalConfigured(): LargeUserSignalDto[] {
    return this.configuredLargeUsers
      .map((configuration) => configuration.signals)
      .reduce((combined, current) => combined.concat(current), []);
  }

  private _titleFieldName = 'title';
  private _propertyIdFieldName = 'propertyId';
  private _organizationNameFieldName = 'organizationName';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _elementIdParamName = 'elementId';
  private _isPointOfInterestFieldName = 'isPointOfInterest';
  private _dimensionTypeIdFieldName = 'dimensionTypeId';
  private _largeUserIdFieldName = 'largeUserId';
  private _signalIdFieldName = 'signalId';
  private _hierarchyElementIdFieldName = 'hierarchyElementId';
  private _serviceName = 'NeConfigurationLargeUserService';
  private _availableSignalsServiceName = 'LargeUserAvailableSignalsService';
  private _pagesize = 50;
  private _oDataFilterForSignalPopup: Map<string, any>;
  private _largeUserInEdition: LargeUserAssignedDto;
  private _largeUserDeleted: LargeUserAssignedDto;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _largeUserService: NeConfigurationLargeUserService,
    private readonly _signalsPopup: MatDialog,
    private readonly _dialogService: DialogService,
    private readonly _spinnerService: SpinnerService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
    this.setOdataFiltersForSignalPopup();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  onDroppedElement(newLargeUsersAssigned: LargeUserAssignedDto) {
    if (newLargeUsersAssigned) {
      this.configuredLargeUsers.push(newLargeUsersAssigned);

      const largeUserToExclude = this.getLargeUserAvailableFromAssigned(newLargeUsersAssigned);
      this.updateExcludedList(largeUserToExclude, true);
      this.removeDraggedItemFromSource$.next(true);
      this.compareConfigurations();
    }
  }

  updateExcludedList(largeUserToExclude: LargeUserAvailableDto, addToExcludedList: boolean) {
    let newExcludedLargeUserList = [...this.excludedLargeUsers];
    if (addToExcludedList) {
      newExcludedLargeUserList.push(largeUserToExclude);
    } else {
      newExcludedLargeUserList = newExcludedLargeUserList.filter(
        (x) => x.largeUserId != largeUserToExclude.largeUserId
      );
    }

    this.excludedLargeUsers = [...newExcludedLargeUserList];
  }

  getLargeUserAvailableFromAssigned(newLargeUsersAssigned: LargeUserAssignedDto) {
    return new LargeUserAvailableDto({
      customerId: newLargeUsersAssigned.customerId,
      largeUserId: newLargeUsersAssigned.largeUserId,
      networkElementId: newLargeUsersAssigned.hierarchyElementId,
      organizationName: newLargeUsersAssigned.organizationName,
      propertyId: newLargeUsersAssigned.propertyId,
      title: `${newLargeUsersAssigned.propertyId} - ${newLargeUsersAssigned.organizationName}`,
    });
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._elementIdParamName, this.selectedNE?.elementId);
    this.queryParams = newParams;
  }

  loadLargeUsers() {
    this.setSpinner(true);
    this._largeUserService
      .getLargeUsersAssignedByZone(this.selectedNE.hierarchyElementId)
      .subscribe({
        next: (largeUserAssigned) => {
          if (largeUserAssigned) {
            this.configuredLargeUsers = this.getSortedConfiguration(largeUserAssigned);

            const largeUsersCopy = this._objectHelperService.clone(this.configuredLargeUsers);

            this.originalConfiguredLargeUsers = this._arrayHelperService.sortArrayObjectAscending(
              largeUsersCopy,
              this._largeUserIdFieldName
            );

            const signalsCopy = this._objectHelperService.clone(this.getAllSignalConfigured);

            this.originalConfiguredSignals = this._arrayHelperService.sortArrayObjectAscending(
              signalsCopy,
              this._pointIdFieldName
            );

            //Reset large users
            this.configurationHasChanged = false;
            this.excludedLargeUsers = [];

            this.setSpinner(false);
          }
        },
      });
  }

  getSortedConfiguration(largeUserAssigned: LargeUserAssignedDto[]): LargeUserAssignedDto[] {
    if (!largeUserAssigned.length) {
      return largeUserAssigned;
    }

    largeUserAssigned.forEach((x) => {
      x.signals = this._arrayHelperService.sortArrayObjectCaseInsensitive(
        x.signals,
        this._pointDescriptionFieldName
      );
    });

    return this._arrayHelperService.sortArrayObjectCaseInsensitive(
      largeUserAssigned,
      this._organizationNameFieldName
    );
  }

  prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      orderBy: [{ field: this._organizationNameFieldName, dir: 'asc' }],
      cacheOptions: { avoid: true },
      allowDrop: false,
      scrollId: this.widgetId,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: `${this.T_SCOPE}.messages.drag-list-empty-message`,
      useCallback: true,
      beforeDropCallback: this.openSignalSelectionPopup,
      isReadOnly: this.isReadOnly,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._propertyIdFieldName, this._organizationNameFieldName],

      fieldLabels: {
        propertyId: this._propertyIdFieldName,
        organizationName: this._organizationNameFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });

    this.assignedCardSettings = new LargeUserAssignedCardSettings({
      fields: [this._propertyIdFieldName, this._organizationNameFieldName],
      detailFields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        propertyId: this._propertyIdFieldName,
        organizationName: this._organizationNameFieldName,
        pointId: this._pointIdFieldName,
        pointDescription: this._pointDescriptionFieldName,
      },
      deleteCallback: this.delete,
      editCallback: this.edit,
    });
  }

  getPopupConfigurationFromLargeUserAvailable(largeUser: LargeUserAvailableDto): any {
    return new NeSignalSelectionPopupConfiguration({
      currentConfiguration: [],
      excludedConfiguration: this.getAllSignalConfigured,
      oDataService: this._availableSignalsServiceName,
      queryFieldNames: [this._hierarchyElementIdFieldName],
      selectedItem: this.selectedNE,
      title: largeUser.propertyId,
      oDataFilters: this._oDataFilterForSignalPopup,
    });
  }

  getPopupConfigurationFromLargeUserAssigned(largeUserAssigned: LargeUserAssignedDto): any {
    return new NeSignalSelectionPopupConfiguration({
      currentConfiguration: [...largeUserAssigned.signals],
      excludedConfiguration: this.getAllSignalConfigured,
      oDataService: this._availableSignalsServiceName,
      queryFieldNames: [this._hierarchyElementIdFieldName],
      selectedItem: largeUserAssigned.hierarchyElementId,
      title: largeUserAssigned.propertyId,
      oDataFilters: this._oDataFilterForSignalPopup,
    });
  }

  openSignalSelectionPopup = (largeUser: LargeUserAvailableDto): Observable<any> => {
    const dialogConfig = this.getPopupDefinition();
    dialogConfig.data = this.getPopupConfigurationFromLargeUserAvailable(largeUser);

    return this._signalsPopup
      .open(NeSignalSelectionPopupComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .pipe(
        map((value) => {
          const result = value as NeSignalSelectionPopupResult;
          if (result.hasToSave && result.newConfiguration?.length) {
            const popupResult = new LargeUserAssignedDto({
              propertyId: largeUser.propertyId,
              hierarchyElementId: this._selectedNE.hierarchyElementId,
              hierarchyElementName: this.selectedNE.hierarchyElementName,
              customerId: largeUser.customerId,
              largeUserId: largeUser.largeUserId,
              organizationName: largeUser.organizationName,
              signals: result.newConfiguration,
            });

            return popupResult;
          } else {
            return null;
          }
        })
      );
  };

  edit = (largeUserAssigned: LargeUserAssignedDto): void => {
    this._largeUserInEdition = largeUserAssigned;

    const dialogConfig = this.getPopupDefinition();
    dialogConfig.data = this.getPopupConfigurationFromLargeUserAssigned(largeUserAssigned);

    this._signalsPopup
      .open(NeSignalSelectionPopupComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data: NeSignalSelectionPopupResult) => {
        if (data.hasToSave) {
          const index = this.configuredLargeUsers.findIndex(
            (x) => x.largeUserId === this._largeUserInEdition.largeUserId
          );
          if (index > -1) {
            this.configuredLargeUsers[index].signals = data.newConfiguration;
            this.compareConfigurations();
          }
        }
      });
  };

  delete = (largeUserAssigned: LargeUserAssignedDto): void => {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.confirm-delete`,
      icon: 'warning',
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          const isNewLargeUser =
            this.excludedLargeUsers.findIndex(
              (x) => x.largeUserId === largeUserAssigned.largeUserId
            ) > -1;

          if (isNewLargeUser) {
            this.deleteLargeUserLocally(largeUserAssigned);
          } else {
            this.deleteLargeUserByApi(largeUserAssigned);
          }
          this.compareConfigurations();
        }
      });
  };

  private deleteLargeUserByApi(largeUserAssigned: LargeUserAssignedDto) {
    this._largeUserDeleted = largeUserAssigned;
    this.setSpinner(true);

    this._largeUserService.deleteLargeUserConfiguration(largeUserAssigned).subscribe({
      next: (result) => {
        if (result) {
          this.configuredLargeUsers = this.configuredLargeUsers.filter(
            (x) => x.largeUserId != this._largeUserDeleted.largeUserId
          );

          this.originalConfiguredLargeUsers = this.originalConfiguredLargeUsers.filter(
            (x) => x.largeUserId != this._largeUserDeleted.largeUserId
          );

          this.reloadAvailableList$.next();

          const messageKey = `${this.T_SCOPE}.messages.delete-success`;
          this.displayMessage(messageKey, 'success');
        } else {
          const messageKey = `${this.T_SCOPE}.messages.delete-error`;
          this.displayMessage(messageKey, 'error');
        }

        this.setSpinner(false);
      },
      error: (error) => {
        this.setSpinner(false);
        const messageKey = `${this.T_SCOPE}.messages.delete-error`;
        this.displayMessage(messageKey, 'error');
      },
    });
  }

  private displayMessage(messageKey: string, icon: 'success' | 'error', isSnackbar = true) {
    const dialogSettings = new WlmDialogSettings({ translateKey: messageKey });
    dialogSettings.icon = icon;

    if (isSnackbar) {
      this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
    } else {
      this._dialogService.showTranslatedMessage(dialogSettings);
    }
  }

  private setSpinner(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, this.componentName);
  }

  private deleteLargeUserLocally(largeUserAssigned: LargeUserAssignedDto) {
    this.configuredLargeUsers = this.configuredLargeUsers.filter(
      (x) => x.largeUserId != largeUserAssigned.largeUserId
    );

    const largeUserToExclude = this.getLargeUserAvailableFromAssigned(largeUserAssigned);
    this.updateExcludedList(largeUserToExclude, false);

    const messageKey = `${this.T_SCOPE}.messages.delete-success`;
    this.displayMessage(messageKey, 'success');
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    if (this.configuredLargeUsers.length) {
      this.setSpinner(true);
      networkElement = networkElement === undefined ? this.selectedNE : networkElement;

      this._largeUserService.saveLargeUserConfiguration(this.configuredLargeUsers).subscribe({
        next: (result) => {
          if (!result) {
            const messageKey = 'common.messages.saved-error';
            this.displayMessage(messageKey, 'error');
            return;
          } else {
            this._dialogService.showEntityActionSnackBar('save', 'configuration');
          }

          this.setSpinner(false);
          this.loadLargeUsers();
        },
        error: (error) => {
          this.showErrorSavingDialog(error);
          this._spinnerService.setLoading(false, this.componentName);
          return;
        },
      });

      return of(true);
    }
  }

  private showErrorSavingDialog(error) {
    const errorMessageKey = `${this.T_SCOPE}.messages.save-error`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey });
    dialogSettings.icon = 'error';

    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private getPopupDefinition() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = SignalSelectionPopupDimensions.Width;
    dialogConfig.height = SignalSelectionPopupDimensions.Height;
    return dialogConfig;
  }

  private setOdataFiltersForSignalPopup() {
    this._oDataFilterForSignalPopup = new Map<string, any>();
    this._oDataFilterForSignalPopup.set(this._isPointOfInterestFieldName, true);
    this._oDataFilterForSignalPopup.set(this._dimensionTypeIdFieldName, 1);
  }

  compareConfigurations() {
    let largeUsersCopy = this._objectHelperService.clone(this.configuredLargeUsers);

    largeUsersCopy = this._arrayHelperService.sortArrayObjectAscending(
      largeUsersCopy,
      this._largeUserIdFieldName
    );

    let signalsCopy = this._objectHelperService.clone(this.getAllSignalConfigured);

    signalsCopy = this._arrayHelperService.sortArrayObjectAscending(
      signalsCopy,
      this._pointIdFieldName
    );

    if (
      this.listHasChanged(
        largeUsersCopy,
        this.originalConfiguredLargeUsers,
        this._largeUserIdFieldName
      )
    ) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(signalsCopy, this.originalConfiguredSignals, this._signalIdFieldName)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  listHasChanged(current: any[], original: any[], field: string) {
    if (current.length !== original.length) {
      return true;
    }

    for (let i = 0, l = current.length; i < l; i++) {
      if (current[i][field] !== original[i][field]) {
        return true;
      }
    }

    return false;
  }

  discard() {
    this.configuredLargeUsers = this._objectHelperService.clone(this.originalConfiguredLargeUsers);

    this.excludedLargeUsers = [];
    this.reloadAvailableList$.next();

    this.configurationHasChanged = false;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
