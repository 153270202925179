import { Component, Input } from '@angular/core';
import { MenuLink } from 'src/app/common-modules/dependencies/navigation/menu-link';
import { WlmNavigationService } from 'src/app/common-modules/dependencies/navigation/wlm-navigation.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { MapTooltip } from '../models/map-tooltip';

const COMPONENT_SELECTOR = 'wlm-map-tooltip-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-tooltip-item.component.html',
  styleUrls: ['./map-tooltip-item.component.scss'],
})
export class MapTooltipItemComponent {
  @Input() model: MapTooltip;

  readonly T_SCOPE = `${AppModules.Map}.wlm-map-tooltip`;

  constructor(private readonly _navigateService: WlmNavigationService) {}

  onClickNavigation(event: MouseEvent, menuLink: MenuLink) {
    const { urlPath, queryParams, customNavMethod } = menuLink;

    this._navigateService.navigate({
      urlPath,
      queryParams,
      customNavMethod,
      event,
    });
  }
}
