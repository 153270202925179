import { Component, Input, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { AppModules } from '../../app-modules.enum';

const COMPONENT_SELECTOR = 'wlm-export-as-image-button';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './export-as-image-button.component.html',
  styleUrls: ['./export-as-image-button.component.scss'],
})
export class ExportAsImageButtonComponent implements OnInit {
  @Input() elementIdToExport: string;
  @Input() fileName: string;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {}

  exportToImage() {
    const elementToExport = document.querySelector(`#${this.elementIdToExport}`) as HTMLElement;
    html2canvas(elementToExport, {
      width: elementToExport.scrollWidth,
      height: elementToExport.scrollHeight,
      scrollX: 0,
      scrollY: 0,
      scale: 4, // Increase this number to increase the resolution
    }).then((canvas) => {
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const imageToSave = document.createElement('a');
      imageToSave.href = image;
      imageToSave.download = `${this.fileName}.png`;
      imageToSave.click();
    });
  }
}
