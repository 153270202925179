import { CalculationMode } from './campaign-calculation-mode.enum';
import { CampaignStatus } from './campaign-status.enum';

export class ALCCampaignDto {
  campaignId: string | null;
  campaignName: string | null;
  campaignDescription: string | null;
  calculationMode: CalculationMode;
  campaignStatus: CampaignStatus;
  goalProgress: number | null;
  goalBaseline: number | null;
  systemInput: number | null;
  goalTarget: number | null;
  goalCalculationDays: number | null;
  kpiType: string | null;
  kpiProperty: string | null;
  dimensionTypeId: number | null;
  campaignBudget: number | null;
  creationDate: Date;
  openDate: Date | null;
  closedDate: Date | null;
  deletedDate: Date | null;
  targetDate: Date | null;
  completionTime: number | null;
  estimatedCost: number | null;
  actualCost: number | null;
  reductionComponent: string;
  activities: string[];
  activityMaxCreationDate: Date | null;
  activityMinCreationDate: Date | null;
  hierarchyFamilyId: string;
  hierarchyFamilyName: string;
  hierarchyElementId: string;
  hierarchyElementTypeId: string;
  hierarchyElementName: string;
  level0Id: string;
  level0TypeId: string | null;
  level0Name: string;
  level1Id: string;
  level1TypeId: string | null;
  level1Name: string;
  level2Id: string;
  level2TypeId: string | null;
  level2Name: string;
  level3Id: string;
  level3TypeId: string | null;
  level3Name: string;
  level4Id: string;
  level4TypeId: string | null;
  level4Name: string;
  level5Id: string;
  level5TypeId: string | null;
  level5Name: string;
  level6Id: string;
  level6TypeId: string | null;
  level6Name: string;
  level7Id: string;
  level7TypeId: string | null;
  level7Name: string;
  level8Id: string;
  level8TypeId: string | null;
  level8Name: string;
  level9Id: string;
  level9TypeId: string | null;
  level9Name: string;
  level10Id: string;
  level10TypeId: string | null;
  level10Name: string;
  level11Id: string;
  level11TypeId: string | null;
  level11Name: string;

  constructor(init: Partial<ALCCampaignDto>) {
    Object.assign(this, init);
  }
}
