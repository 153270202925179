import { HttpContextToken } from '@angular/common/http';
import dayjs from 'dayjs';

export const DEFAULT_CACHE_ABSOLUTE_EXPIRATION = dayjs().add(1, 'day').set('hour', 2).toDate(); // Tomorrow 2AM;
export const DEFAULT_CACHE_DURATION = 60; //minutes

// Interceptor tokens are a new feature of Angular 12, which allows to pass context to interceptors without needing to set headers.
export const CT_DATES_REQ_NO_UTC = new HttpContextToken<string[]>(() => []);
export const CT_DATES_RES_STR = new HttpContextToken<string[]>(() => []);
export const CT_DATES_MAP_REQ = new HttpContextToken<boolean>(() => false);
export const CT_DATES_MAP_RES = new HttpContextToken<boolean>(() => false);

export const CT_HTTP_CACHE = new HttpContextToken<boolean>(() => true);
export const CT_HTTP_CACHE_ABSOLUTE_EXPIRATION = new HttpContextToken<string>(() => null);
export const CT_HTTP_CACHE_RELOAD = new HttpContextToken<boolean>(() => true);
export const CT_HTTP_CACHE_DURATION = new HttpContextToken<number>(() => null);

export const CT_GLOBAL_SPINNER = new HttpContextToken<boolean>(() => false);
