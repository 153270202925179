<wlm-customizable-chart
  *ngIf="chartSettings"
  [chartSettings]="chartSettings"
  [size]="calculatedSize$ | async"
  (loadingEvent)="onChartLoading($event)"
  (chartInitEvent)="onChartInit($event)"
  (chartDataZoomEvent)="onChartDataZoom($event)"
  (chartLegendSelectedEvent)="onChartLegendSelected($event)"
  (genericChartSettingsChange)="onMainChartSettingsChange($event)"
  (chartLoaded)="onChartLoaded($event)"
  (chartFinished)="onChartFinished($event)"
></wlm-customizable-chart>

<hr
  fixedSizeElement
  *ngIf="eventChartQuery?.categories?.length && chartSettings"
  class="separator"
/>

<wlm-events-chart
  *ngIf="eventChartQuery?.categories?.length && xAxisRange"
  [xAxisRange]="xAxisRange"
  [eventQuery]="eventChartQuery"
  (chartClick)="onChartClick($event)"
  (sizeChanged)="onEventChartSizeChange($event)"
></wlm-events-chart>
