import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CommonSharedModule } from '../common-shared.module';
import { ObjectHelperService } from '../helpers/object-helper.service';
import { FormModes } from '../model/shared/form-mode.enum';
import { SubscriptionManager } from '../observables/subscription-manager';
import { BaseComponent } from './base.component';

@UntilDestroy()
@Directive()
export abstract class BaseFormComponent<T, TResult> extends BaseComponent {
  @Input() mode: FormModes;
  @Input() set model(model: T) {
    if (!model) {
      return;
    }
    this._model = this.preprocessModel(model);
    this.initialModel = this._objectHelper.clone(this._model);
    this.canSave.emit(false);
    if (this.form) {
      this.assignModelToForm().subscribe();
    }
  }
  get model(): T {
    return this._model;
  }
  private _model: T;
  @Input() submit$: Observable<void>;

  @Input() restore$: Observable<void>;
  @Output() titleKey = new EventEmitter<string>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() submitResult = new EventEmitter<TResult>();
  @Output() canSave = new EventEmitter<boolean>();

  initialModel: T;
  form: UntypedFormGroup;
  fieldAppearance = 'outline';
  private subsManager = new SubscriptionManager();

  private _dependenciesLoaded = false;
  public get dependenciesLoaded() {
    return this._dependenciesLoaded;
  }
  public set dependenciesLoaded(value) {
    this._dependenciesLoaded = value;
    this.loaded.emit(this.dependenciesLoaded);
  }
  private _isValid = false;
  public get isValid() {
    return this._isValid;
  }
  public set isValid(value) {
    this._isValid = value;
    this.valid.emit(this.isValid);
  }

  protected _objectHelper: ObjectHelperService;

  constructor() {
    super();
    this._objectHelper = CommonSharedModule.injector.get(ObjectHelperService);

    this.mode = FormModes.Create;
  }

  protected initForm(): void {
    this.subsManager.unsubscribe();

    if (this.submit$) {
      this.subsManager.subs = [
        this.submit$
          .pipe(
            untilDestroyed(this),
            switchMap(() => this.performSubmit())
          )
          .subscribe(),
      ];
    }
    if (this.restore$) {
      this.subsManager.subs = [
        this.restore$
          .pipe(
            untilDestroyed(this),
            switchMap(() => this.performRestore())
          )
          .subscribe(),
      ];
    }

    this.isValid = false;
    this.dependenciesLoaded = false;

    this.createForm();
    this.subsManager.subs = [this.assignModelToForm().subscribe()];
    this.subsManager.subs = [
      this.loadDependencies()
        .pipe(
          take(1),
          switchMap(() => {
            this.dependenciesLoaded = true;
            return this.checkValid();
          }),
          tap((valid) => {
            this.isValid = valid;
          })
        )
        .subscribe(),
    ];

    this.subsManager.subs = [
      this.form.valueChanges
        .pipe(
          untilDestroyed(this),
          switchMap(() => this.checkValid()),
          tap((valid) => {
            this.isValid = valid;
          })
        )
        .subscribe(),
    ];

    this.subsManager.subs = [
      this.form.valueChanges
        .pipe(
          untilDestroyed(this),
          map(() => this.form.dirty),
          filter((isDirty) => isDirty),
          tap((_) => {
            this.canSave.emit(true);
          })
        )
        .subscribe(),
    ];
  }

  protected abstract createForm(): void;
  protected abstract loadDependencies(): Observable<void>;
  protected abstract preprocessModel(model: T): T;
  protected abstract assignModelToForm(): Observable<void>;
  protected abstract checkValid(): Observable<boolean>;
  protected abstract beforeSubmit(): Observable<T>;
  protected abstract submitFn(model: T): Observable<TResult>;
  protected abstract afterSubmit(result: TResult): Observable<void>;

  protected performSubmit(): Observable<any> {
    return this.checkValid().pipe(
      filter((valid) => valid),
      switchMap(() => this.beforeSubmit()),
      switchMap((modelToSubmit) => this.submitFn(modelToSubmit)),
      switchMap((result) => this.afterSubmit(result))
    );
  }

  protected performRestore(): Observable<any> {
    this._model = this._objectHelper.clone(this.initialModel);
    if (this.form) {
      return this.assignModelToForm();
    }
    return of();
  }
}
