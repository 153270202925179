import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { AlgorithmFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/algorithm-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class AlgorithmFAdapter extends FilterAdapterSettings {
  id: FilterAdapterEnum = FilterAdapterEnum.Algorithm;

  algorithmIdFieldName: string;

  constructor(init: { dataBindingField: string; algorithmIdFieldName: string }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const algorithmFilter = filters.data.get(this.algorithmIdFieldName);
    if (algorithmFilter) {
      const result = new AlgorithmFilter(
        algorithmFilter.value ? [...algorithmFilter.value] : [],
        algorithmFilter.fieldName
      );
      return result;
    }
    return null;
  }

  buildResults(algorithmFilter: AlgorithmFilter): FilterAdapterResult {
    if (algorithmFilter) {
      this.filtersResult.filters.set(this.dataBindingField, algorithmFilter);
      this.panelParams.addParameter(TabDetailParameterName.algorithm, algorithmFilter.elementIds);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
