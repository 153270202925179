import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { GenericCardSettings } from 'src/app/common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from 'src/app/common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { ChartWorkspaceTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { WorkspaceService } from './workspace.service';

const COMPONENT_SELECTOR = 'wlm-data-visualization-workspace-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-workspace-selector.component.html',
  styleUrls: ['./data-visualization-workspace-selector.component.scss'],
})
export class DataVisualizationWorkspaceSelectorComponent implements OnInit {
  @Output() workspaceSelected = new EventEmitter<any>();

  @Input() chartConfigType: ChartWorkspaceTypeEnum;
  @Input() removeSelection$: Observable<void>;
  @Input() selectedWorkspaceName: string;
  @Input() currentUser: string;

  private _workspaceFieldName = 'workspaceName';
  private _selectedFieldName = 'workspaceId';

  private _pagesize = 0;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<WorkspaceDto>;
  queryParams: Map<string, any> = new Map<string, any>();

  availableWorkspaces: WorkspaceDto[];
  selectedWorkspace: WorkspaceDto;
  filters = new DataBindingFilters();
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();
  isLoading = false;

  constructor(
    private _localizationService: LocalizationHelperService,
    private _workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    this.loadWorkspaces();
  }

  onSelectItem(selectedWorkspace: WorkspaceDto) {
    this.workspaceSelected.emit(selectedWorkspace);
  }

  private loadWorkspaces() {
    this.isLoading = true;
    this._workspaceService.getTemplatesByUsers().subscribe({
      next: (workspaces) => {
        this.availableWorkspaces = workspaces.filter(
          (x) => x.workspaceTypeId == this.chartConfigType
        );
        this.selectedWorkspace = this.availableWorkspaces.find(
          (x) =>
            x.workspaceName.toLocaleLowerCase() ==
              this.selectedWorkspaceName?.toLocaleLowerCase() && x.userCode == this.currentUser
        );

        if (!this.selectedWorkspace) {
          this.selectedWorkspace = this.availableWorkspaces.find(
            (x) =>
              x.workspaceName.toLocaleLowerCase() == this.selectedWorkspaceName?.toLocaleLowerCase()
          );
        }
        this.reloadList$.next();

        this.prepareListsSettings();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      isLocal: true,
      pageSize: this._pagesize,
      orderBy: [{ field: this._workspaceFieldName, dir: 'asc' }],
      useQueryParams: false,
      oDataFiltersExtended: this.filters,
      displayFieldName: this._workspaceFieldName,
      isReadOnly: false,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._workspaceFieldName],
      disableSelection: false,
    });

    this._localizationService
      .get(`${AppModules.DataVisualization}.labels`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        const getWorkspaceSubtitleFn = (model: WorkspaceDto) => {
          try {
            const localDate = this._localizationService.convertToLocalDate(model.lastUpdate);
            return `${ts['last-update']} ${this._localizationService.formatDate(localDate)}`;
          } catch {
            return '-';
          }
        };

        const getWorkspaceExtraInfoFn = (model: WorkspaceDto) =>
          `${model.isPublic ? ts['public'] : ts['private']} - ${model.userCode}`;

        this.cardSettings = new GenericCardSettings<WorkspaceDto>({
          title1Fn: (model) => `${model.workspaceName}`,
          subtitleFn: (model) => getWorkspaceSubtitleFn(model),
          extraInfoFn: (model) => getWorkspaceExtraInfoFn(model),
        });
      });

    this.queryParams = new Map<string, any>();
  }
}
