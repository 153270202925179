import { Injectable } from '@angular/core';
import { localeIncludes } from 'locale-includes';
import { LocalizationHelperService } from '../localization/localization-helper.service';
@Injectable({
  providedIn: 'root',
})
export class StringHelperService {
  private readonly _successComparisonResult = 0;
  constructor(private _localization: LocalizationHelperService) {}

  capitalizeFirstLetter(str: string) {
    return str.length > 1 ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str;
  }

  lowerFirstLetter(str: string) {
    return str.length > 1 ? `${str.charAt(0).toLowerCase()}${str.slice(1)}` : str;
  }

  /**
   * Get a generic random string using the crypto API.
   */
  getRandomString(): string {
    const ms = +new Date();
    const crypto = window.crypto;
    const typedArray = new Uint8Array(8);
    crypto.getRandomValues(typedArray);
    const result = typedArray.toString() + ms;
    return result;
  }

  compareStringCaseInsensitive(str1: string, str2: string): boolean {
    const areEqual =
      str1.localeCompare(str2, undefined, { sensitivity: 'base' }) ===
      this._successComparisonResult;
    return areEqual;
  }

  localeIncludes(str: string, searchStr: string): boolean {
    return localeIncludes(str, searchStr, {
      usage: 'search',
      locale: this._localization.currentLocale,
      sensitivity: 'base',
    });
  }

  replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  };

  //How to use: formatString("{0} is {1} {2}", "This", "formatting", "hack");
  //output: "This is formatting hack"
  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  private escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
