import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import 'quill-mention';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { CommentItemComponent } from './components/comment-item/comment-item.component';
import { CommentThreadComponent } from './components/comment-thread/comment-thread.component';
import { MatQuillModule } from './dependencies/mat-quill/mat-quill-module';
import { ExtractUserNamesPipe } from './pipes/extract-user-names.pipe';

const exported = [CommentItemComponent, CommentThreadComponent, ExtractUserNamesPipe];

@NgModule({
  declarations: [...exported],
  imports: [
    CommonModule,
    WlmSharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatQuillModule,
  ],
  exports: [...exported],
})
export class CommentsModule {}
