import { Component, OnInit, inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { AppModules } from '../../shared/app-modules.enum';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { BaseSpinnerOverlayComponent } from '../base-spinner-overlay.component';
import { SpinnerSplashService } from '../spinner-splash.service';

@Component({
  selector: 'wlm-spinner-overlay-logo',
  templateUrl: './spinner-overlay-logo.component.html',
  styleUrls: ['./spinner-overlay-logo.component.scss'],
})
export class SpinnerOverlayLogoComponent extends BaseSpinnerOverlayComponent implements OnInit {
  readonly localizationScope = `${AppModules.WlmShared}.products-version`;

  currentYear = new Date().getFullYear();
  productsVersion: { [key: string]: string };
  appName: string;
  logo: string;

  private readonly _objectHelperService = inject(ObjectHelperService);
  private readonly _spinnerSplashService = inject(SpinnerSplashService);

  ngOnInit(): void {
    const appName$ = this._spinnerSplashService.getAppName();
    const logo$ = this._spinnerSplashService.getLogo();
    const versionInfo$ = this._spinnerSplashService.getVersion();

    combineLatest([appName$, logo$, versionInfo$]).subscribe(([appName, logo, versionInfo]) => {
      this.appName = appName;
      this.logo = logo;

      const sorted = this._objectHelperService.sortObjectKeys(versionInfo.productsVersion);
      this.productsVersion = sorted as { [key: string]: string };
    });
  }
}
