import { ElementRef, Injectable, inject } from '@angular/core';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { globalUtilsHelper } from '../helpers/global-utils-helper';
import { IElementSize } from '../model/element-size';
import { LogService } from '../wlm-log/log.service';
import { WlmResizeObserverService } from './resize-observer.service';

@Injectable({ providedIn: 'root' })
export class SizeCalculatorService {
  private readonly _resizeObserverService = inject(WlmResizeObserverService);
  private readonly _log = inject(LogService);
  private readonly _logEnabled = false;

  /**
   *
   * @param dimensionToCalculate The dimension to consider when calculating remaining size. Can be width or height.
   * @param containerElement The element that contains the fixed and the expanding elements, so its size is monitored.
   * @param containerSize$ Alternative way of passing the side of the container element (must be one or the other).
   * @param fixedSizeElements Elements that have a fixed height or width, depending on the dimension, like top bars, footers, etc.
   * @param fixedSizes$ Additional way of adding fixed elements sizes. All elements in fixedSizeElements and fixedSizes$ are considered.
   * @returns The remaining size for an expanding child to take, considering the size of its container and its fixed siblings.
   */
  listenCalculateRemainingSize$({
    containerElement,
    containerSize$,
    fixedSizeElements,
    fixedSizes$,
    dimensionToCalculate,
    debugName,
  }: {
    containerElement?: ElementRef<HTMLElement>;
    containerSize$?: Observable<IElementSize>;
    fixedSizeElements?: ElementRef<HTMLElement>[];
    fixedSizes$?: Observable<IElementSize>[];
    dimensionToCalculate: 'width' | 'height';
    debugName?: string;
  }): Observable<IElementSize> {
    if ((containerElement && containerSize$) || (!containerElement && !containerSize$)) {
      this._log.error({
        msg: 'Either specify the containerElement or an observable that emits its size',
      });
    }

    const finalContainerSize$ =
      containerSize$ ?? this.listenElementSize$(containerElement.nativeElement);

    const finalFixedSizes$ = [
      ...(fixedSizeElements ?? []).map((element) => this.listenElementSize$(element.nativeElement)),
      ...(fixedSizes$ ?? []),
    ];

    return combineLatest([finalContainerSize$, ...finalFixedSizes$]).pipe(
      map(([containerSize, ...fixedElementSizes]) => {
        fixedElementSizes = fixedElementSizes.filter(Boolean);
        this.logSizes(debugName, dimensionToCalculate, containerSize, fixedElementSizes);
        return this.calculateRemainingSize(
          containerSize,
          fixedElementSizes,
          dimensionToCalculate,
          debugName
        );
      })
    );
  }

  private calculateRemainingSize(
    containerSize: IElementSize,
    fixedElementsSizes: IElementSize[],
    dimensionToCalculate: 'width' | 'height',
    debugName: string
  ): IElementSize {
    const containerSpace = containerSize[dimensionToCalculate];
    const fixedSpaces: number[] = fixedElementsSizes.map((size) =>
      size ? size[dimensionToCalculate] : 0
    );
    const fixedSpace: number = fixedSpaces.reduce((accum, current) => accum + current, 0);
    const remainingSpace = Math.max(containerSpace - fixedSpace, 0);

    const fixedDimension = dimensionToCalculate === 'width' ? 'height' : 'width';
    const result: unknown = {
      [fixedDimension]: containerSize[fixedDimension],
      [dimensionToCalculate]: remainingSpace,
    };
    this.logResult(debugName, result as IElementSize);
    return result as IElementSize;
  }

  listenElementSize$(nativeElement: HTMLElement): Observable<IElementSize> {
    return this._resizeObserverService
      .observe({
        el: nativeElement,
      })
      .pipe(
        startWith(this.getElementSize(nativeElement)),
        map(() => this.getElementSize(nativeElement))
      );
  }

  private getElementSize = globalUtilsHelper.getElementSize;

  private logSizes(debugName, dimensionToCalculate, containerSize, fixedElementSizes): void {
    if (this._logEnabled) {
      const childrenSizes = fixedElementSizes.map((size) => size[dimensionToCalculate]).join(', ');
      this._log.info({
        msg: `[${debugName}] Container ${dimensionToCalculate}: ${containerSize[dimensionToCalculate]}, children: [${childrenSizes}]`,
      });
    }
  }

  private logResult(debugName, size: IElementSize) {
    if (this._logEnabled) {
      this._log.info({
        msg: `[${debugName}] Calculated size: ${size.height} height, ${size.width} width`,
      });
    }
  }
}
