<kendo-treelist
  *ngIf="userCode || group"
  kendoTreeListExpandable
  class="treelist"
  [ngClass]="{ 'treelist-full-size': fullSize }"
  [loading]="isLoading"
  [kendoTreeListFlatBinding]="profileDialogsPaths"
  idField="dialogId"
  expandBy="dialogName"
  parentIdField="ancestorId"
  [resizable]="true"
>
  <kendo-treelist-column
    [expandable]="true"
    field="dialogName"
    title="{{ T_SCOPE + '.headers.area' | translate }}"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span>
        {{ dataItem.title }}
      </span>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column
    field="canCreate"
    title="{{ T_SCOPE + '.headers.create' | translate }}"
    [width]="100"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [disabled]="!editingMode"
        class="checkbox-crud"
        [checked]="dataItem.canCreate"
        (change)="crudChanged($event, dataItem, 'canCreate')"
      >
      </mat-checkbox>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column
    field="canRead"
    title="{{ T_SCOPE + '.headers.read' | translate }}"
    [width]="100"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [disabled]="!editingMode"
        class="checkbox-crud"
        [checked]="dataItem.canRead"
        (change)="crudChanged($event, dataItem, 'canRead')"
      >
      </mat-checkbox>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column
    field="canUpdate"
    title="{{ T_SCOPE + '.headers.update' | translate }}"
    [width]="100"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [disabled]="!editingMode"
        class="checkbox-crud"
        [checked]="dataItem.canUpdate"
        (change)="crudChanged($event, dataItem, 'canUpdate')"
      >
      </mat-checkbox>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column
    field="canDelete"
    title="{{ T_SCOPE + '.headers.delete' | translate }}"
    [width]="100"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [disabled]="!editingMode"
        class="checkbox-crud"
        [checked]="dataItem.canDelete"
        (change)="crudChanged($event, dataItem, 'canDelete')"
      >
      </mat-checkbox>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>
