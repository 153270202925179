import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SelectableSettings, TreeViewComponent } from '@progress/kendo-angular-treeview';
import { IHierarchyElementPathDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-path.dto';
import { IHierarchyFamilyDto } from 'src/app/common-modules/dependencies/he/hierarchy-family.dto';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseComponent } from 'src/app/common-modules/shared/component/base.component';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import * as constants from '../../../../../../common-modules/dependencies/shared/hierarchy-tree-filter-constants';
import { ITreeSettings } from '../../../../../../common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { HierarchyElementPathFilter } from '../../../../../../common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { HierarchyTreeFilterHelperService } from './hierarchy-tree-filter-helper.service';

const COMPONENT_SELECTOR = 'wlm-hierarchy-tree-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-tree-filter.component.html',
  styleUrls: ['./hierarchy-tree-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchyTreeFilterComponent),
      multi: true,
    },
  ],
})
export class HierarchyTreeFilterComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @ViewChild(TreeViewComponent) public treeControl: TreeViewComponent;
  @Input() selectedNodes: IHierarchyElementPathDto[];
  @Output() selectedNodesChange = new EventEmitter<IHierarchyElementPathDto[]>();
  @Input() treeSettings: ITreeSettings;
  @Output() treeSettingsChange = new EventEmitter<ITreeSettings>();

  @Output() selectedFiltersChange = new EventEmitter<HierarchyElementPathFilter>();
  @Input() hierarchyFamilyIdFieldName: string;
  @Input() hierarchyElementIdFieldName: string;
  @Input() initialSelectedKeys: string[];

  inputFilter: ElementRef;
  treeForm: UntypedFormGroup;
  myFormGroup = new UntypedFormGroup({
    myFormControl: new UntypedFormControl(),
    searchBox: new UntypedFormControl(),
    familyFilter: new UntypedFormControl(),
  });

  hierarchyFamilies: IHierarchyFamilyDto[];
  errorMessage: string;
  searchOptions: string[];
  treeNodes: IHierarchyElementPathDto[];
  filteredTreeNodes: IHierarchyElementPathDto[];
  expandedKeys: any[] = [];
  selectedKeys: any[] = [];
  isDisabled: boolean;
  oldUnselectableTypes: string[];
  isLoading = false;
  treeIsEmpty = false;
  removableChip = true;
  toggleLabel: string;
  toggleTooltip: string;
  // focusDelay = 1000;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  private _isChecked = false;
  public get isChecked(): boolean {
    return this._isChecked;
  }
  public set isChecked(value: boolean) {
    this._isChecked = value;
  }

  private _hierarchyFamilyId: string;
  get hierarchyFamilyId(): string {
    return this._hierarchyFamilyId;
  }
  @Input() set hierarchyFamilyId(value) {
    this._hierarchyFamilyId = value;
    this.initializeTree(this._hierarchyFamilyId);
  }

  private _searchValue: string;
  get searchValue(): string {
    return this._searchValue;
  }
  set searchValue(value) {
    this._searchValue = value;
    if (value === '') {
      this.initializeTree(this.hierarchyFamilyId);
      this.expandedKeys = [];
      return;
    }

    this.initSearch(value);
  }

  private initSearch(value: string, focusElement = true) {
    const selected = this.filterTreeBySearchValue(value);
    if (focusElement) {
      this.setNodeFocus(selected);
    }
  }

  private setNodeFocus(selected: IHierarchyElementPathDto) {
    const index = this.buildIndex(selected);

    setTimeout(() => {
      this.treeControl.focus(index);
    }, this.treeSettings.focusDelay);
  }

  get selection(): SelectableSettings {
    return { mode: this.treeSettings.selectionMode };
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(
    private globalService: GlobalsService,
    private hierarchyTreeFilterHelperService: HierarchyTreeFilterHelperService,
    private ngZone: NgZone,
    private localization: LocalizationHelperService
  ) {
    super();
  }
  init(): void {
    this.ngOnInit();
  }
  mapInitParameters(parameters: TabDetailPanelParameters) {
    throw new Error('Method not implemented.');
  }
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.treeSettings.unselectableTypes = [];
      this.treeSettings.unselectableTypes = Object.keys(constants.hierarchyElementTypes).map(
        (type) => {
          return constants.hierarchyElementTypes[type];
        }
      );
    } else {
      this.treeSettings.unselectableTypes = this.oldUnselectableTypes;
    }
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.initializeTree(this.hierarchyFamilyId);
    this.treeSettingsChange.emit(this.treeSettings);
    this.oldUnselectableTypes = this.treeSettings.unselectableTypes;
  }

  initializeTree(filteredHierarchyFamilyId: any) {
    if (filteredHierarchyFamilyId === undefined) {
      return;
    }
    this.isLoading = true;
    this.treeIsEmpty = false;
    this.globalService.getHierarchyElementPaths().subscribe({
      next: (hierarchyElements) => {
        this.treeNodes = hierarchyElements.filter((element) =>
          element.hierarchyFamilyIds.includes(filteredHierarchyFamilyId)
        );
        this.filteredTreeNodes = this.loadTreeFromAOIList();
        this.searchOptions = this.filteredTreeNodes.map((e) => e.descendantDescription).sort();
        this.treeSettings.autocompleteSetting.itemSource = this.searchOptions.filter(
          (v, i, a) => a.indexOf(v) === i // <-- to get unique values
        );
        if (this.filteredTreeNodes.filter((n) => n.ancestor === undefined).length === 0) {
          this.treeIsEmpty = true;
        }

        if (this.initialSelectedKeys?.length > 0) {
          if (typeof this.initialSelectedKeys === 'string') {
            this.initSearch(this.initialSelectedKeys, false);
          } else {
            this.initialSelectedKeys.forEach((k) => {
              this.initSearch(k, false);
            });
          }
          this.setNodeFocus(this.selectedNodes[0]);
          this.initialSelectedKeys = [];
        }
        this.isLoading = false;
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  private loadTreeFromAOIList(): IHierarchyElementPathDto[] {
    let aoiDefined = this.treeSettings.aoiList;
    if (aoiDefined === null || aoiDefined === undefined || aoiDefined.length === 0) {
      return this.treeNodes;
    }

    this.treeSettings.aoiList.forEach((e) => {
      aoiDefined = this.hierarchyTreeFilterHelperService.mergeStringLists(
        aoiDefined,
        this.getHierarchyNodes(this.treeNodes, e, constants.searchDirection.up)
      );
      aoiDefined = this.hierarchyTreeFilterHelperService.mergeStringLists(
        aoiDefined,
        this.getHierarchyNodes(this.treeNodes, e, constants.searchDirection.down)
      );
    });

    let treeFilteredByAOI: IHierarchyElementPathDto[] = [];
    treeFilteredByAOI = this.treeNodes.filter((e) => aoiDefined.indexOf(e.descendant) > -1);

    return treeFilteredByAOI;
  }

  private getHierarchyNodes(
    tree: IHierarchyElementPathDto[],
    initialNode: string,
    direction: string
  ): string[] {
    if (initialNode === undefined || initialNode === null) {
      return [];
    }
    let result = [initialNode];
    let nextNode: IHierarchyElementPathDto[];
    if (direction === constants.searchDirection.up) {
      nextNode = tree.filter((e) => e.descendant === initialNode);
      return nextNode.length > 0
        ? result.concat(
            this.getHierarchyNodes(
              tree,
              tree.filter((e) => e.descendant === initialNode)[0].ancestor,
              direction
            )
          )
        : result;
    } else {
      nextNode = tree.filter((e) => e.ancestor === initialNode);

      if (nextNode.length === 0) {
        return result;
      }

      nextNode.forEach((node) => {
        const locatedNode = this.getHierarchyNodes(tree, node.descendant, direction);
        result = result.concat(locatedNode);
      });

      return result;
    }
  }

  private filterTreeBySearchValue(value: string): IHierarchyElementPathDto {
    const searchResult = this.hierarchyTreeFilterHelperService.search(
      this.treeNodes,
      value,
      this.treeSettings.displayableField
    );
    const selected: IHierarchyElementPathDto = searchResult
      .filter(
        (x) =>
          this.hierarchyTreeFilterHelperService.getDisplayableField(
            x,
            this.treeSettings.displayableField
          ) === value
      )
      .pop();
    this.setSelectedNodes(selected);
    this.expandedKeys = this.expandedKeys.concat(
      searchResult.map((x) =>
        this.hierarchyTreeFilterHelperService.getDisplayableField(
          x,
          this.treeSettings.displayableField
        )
      )
    );
    return selected;
  }

  public familyChange(event: any): void {
    this.initializeTree(event.value);
  }

  public handleSelection(event: any): void {
    const item = event.dataItem as IHierarchyElementPathDto;

    this.selectedKeys =
      this.selectedNodes === undefined ? [] : this.selectedNodes.map((n) => n.descendant);
    this.setSelectedNodes(item);
  }

  public setSelectedNodes(item: IHierarchyElementPathDto) {
    if (
      this.treeSettings.unselectableTypes.indexOf(item.descendantHierarchyElementTypeName) > -1 ||
      this.treeSettings?.unselectableNodes?.indexOf(item.descendant) > -1
    ) {
      this.selectedKeys = this.getSelectedKeyBranch(item, false);
    } else {
      if (this.selectedKeys.indexOf(item.descendant) > -1) {
        this.selectedKeys = this.getSelectedKeyBranch(item, false);
        // } else if (this.treeSettings.useSelectRecursive && this.treeSettings.projectableTypes) {
        //   this.manageHierarchicalSelection(item);
      } else {
        this.selectedKeys =
          this.treeSettings.selectionMode === constants.treeSelectionMode.single
            ? [item.descendant]
            : this.getSelectedKeyBranch(item);
      }
    }

    this.selectedNodes = this.getSelectedNodes();

    this.filterByProyectedTypes();

    this.notifyChanges();
  }

  private notifyChanges() {
    this.selectedNodesChange.emit(this.selectedNodes);

    if (this.hierarchyElementIdFieldName) {
      this.emitHierarchyElementPathFilter();
    }

    this.onChange(this.selectedNodes);
    this.onTouch();
  }

  manageHierarchicalSelection(item: IHierarchyElementPathDto) {
    const retrieved = this.getSelectedKeyBranch(item);
    const newElements = retrieved.filter((f) => this.selectedKeys.indexOf(f) === -1);
    this.selectedKeys = this.selectedKeys.filter((f) => retrieved.indexOf(f) < 0);
    this.selectedKeys = this.selectedKeys.concat(newElements);
  }

  filterByProyectedTypes() {
    if (this.treeSettings.projectableTypes && this.treeSettings.useSelectRecursive) {
      this.selectedNodes = this.selectedNodes.filter(
        (f) => this.treeSettings.projectableTypes.indexOf(f.descendantHierarchyElementTypeName) > -1
      );
      this.selectedKeys = this.selectedNodes.map((n) => n.descendant);
    }
  }

  private emitHierarchyElementPathFilter() {
    const heFilter = new HierarchyElementPathFilter(
      // tslint:disable-next-line: no-string-literal
      this.hierarchyFamilyId,
      this.selectedNodes.map((n) => n.descendant),
      this.hierarchyFamilyIdFieldName,
      this.hierarchyElementIdFieldName
    );
    this.selectedFiltersChange.emit(heFilter);
  }

  private getSelectedKeyBranch(item: IHierarchyElementPathDto, add: boolean = true): any[] {
    if (this.treeSettings.useSelectRecursive) {
      const nodeDescendants = this.getHierarchyNodes(
        this.filteredTreeNodes,
        item.descendant,
        constants.searchDirection.down
      );
      const selectedNodes = this.filteredTreeNodes.filter(
        (n) => nodeDescendants.indexOf(n.descendant) > -1
      );
      const selectableNodes = selectedNodes
        .filter(
          (n) =>
            this.treeSettings.unselectableTypes.indexOf(n.descendantHierarchyElementTypeName) ===
              -1 || this.treeSettings?.unselectableNodes?.indexOf(n.descendant) === -1
        )
        .map((m) => m.descendant);

      return add
        ? this.hierarchyTreeFilterHelperService.mergeStringLists(this.selectedKeys, selectableNodes)
        : this.selectedKeys.filter((n) => selectableNodes.indexOf(n) < 0);
    }

    return add
      ? this.selectedKeys.concat([item.descendant])
      : this.selectedKeys.filter((e) => e !== item.descendant);
  }

  public getSelectedNodes() {
    return this.treeNodes.filter((e) => {
      return this.selectedKeys.indexOf(e.descendant) > -1;
    });
  }

  public clearSelection() {
    this.selectedKeys = [];
  }
  public isItemSelected = (dataItem: any) =>
    this.selectedKeys.indexOf((dataItem as IHierarchyElementPathDto).descendant) > -1;

  public handleClick(event: any): void {
    event.originalEvent.preventDefault();
  }

  public setNodeStyle({ descendantHierarchyElementTypeName, descendant }: any): any {
    let className = '';
    if (
      this.treeSettings.unselectableTypes.indexOf(descendantHierarchyElementTypeName) > -1 ||
      this.treeSettings?.unselectableNodes?.indexOf(descendant) > -1
    ) {
      className = constants.disableNodeClassName;
    }
    if (this.selectedKeys.indexOf(descendant) > -1) {
      className = constants.highlightedNodeClassName;
    }
    return className;
  }

  removeChip(selectedNodeValue): void {
    const node = this.selectedNodes.filter((item) => item.descendant === selectedNodeValue).pop();

    this.setSelectedNodes(node);
  }
  ClearAllChips(event: any) {
    this.selectedKeys = [];
    this.selectedNodes = [];
    this.notifyChanges();
  }

  setUseSelectRecursive(e: MatSlideToggleChange) {
    this.treeSettings.useSelectRecursive = e.checked;
  }

  public buildIndex(currentNode: any, base: string = ''): string {
    if (!currentNode) {
      return null;
    }

    const sameLevelItems = this.filteredTreeNodes.filter(
      (node) => node.ancestor === currentNode.ancestor
    );
    const levelIndex = sameLevelItems.indexOf(currentNode);
    const merged = `${levelIndex}${base ? '_' : ''}${base}`;

    if (currentNode.ancestor === null || currentNode.ancestor === undefined) {
      return merged;
    } else {
      const parent = this.filteredTreeNodes.find(
        (nodes) => nodes.descendant === currentNode.ancestor
      );
      return this.buildIndex(parent, merged);
    }
  }
}
