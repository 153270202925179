import { TreelistData } from './treelist-data';

export class TreelistSettings {
  columnKeys: Set<string>;
  data: TreelistData[];
  disabledNodes?: Map<string, string[]>;

  constructor(init?: Partial<TreelistSettings>) {
    Object.assign(this, init);
  }
}
