import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { InterfaceRequestDto } from '../dtos/interface-request.dto';

@Injectable()
export class InterfaceRequestService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/interface`;
  }

  sendJsonRequest(interfaceName: string, request: InterfaceRequestDto): Observable<number> {
    return this.httpCacheClient.post(`${this.url}/${interfaceName}/request`, request);
  }

  sendCsvRequest(interfaceName: string, request: FormData): Observable<number> {
    return this.httpCacheClient.post(`${this.url}/${interfaceName}/request/csv`, request);
  }

  getRequestSample(interfaceName: string): Observable<string> {
    return this.httpCacheClient.get(`${this.url}/${interfaceName}/sample`);
  }
}
