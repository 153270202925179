<div class="buttons-container" [class.vertical-mode]="showHamburguer">
  <button
    wlmCollapsibleButton
    *ngIf="show[options.Filter]"
    [tooltip]="tooltipFilter"
    [disabled]="disableStatus[options.Filter] || !canLoad"
    [showLabel]="showHamburguer"
    (click)="onClickFilter()"
  >
    <mat-icon svgIcon="filter-outline"></mat-icon>
  </button>

  <div *ngIf="firstSlotContent" class="slot-wrapper">
    <div *ngFor="let template of firstSlotContent" class="slot-wrapper-inner">
      <ng-container
        *ngTemplateOutlet="template; context: { isContainerCollapsed: showHamburguer }"
      ></ng-container>
    </div>
  </div>

  <div class="slot-separator"></div>

  <button
    wlmCollapsibleButton
    *ngIf="show[options.ClearFilters]"
    [tooltip]="tooltipClearFilters"
    [disabled]="disableStatus[options.ClearFilters]"
    [showLabel]="showHamburguer"
    (click)="onClickClearFilters()"
  >
    <mat-icon svgIcon="reset-filters"></mat-icon>
  </button>

  <div *ngIf="secondSlotContent" class="slot-wrapper">
    <div *ngFor="let template of secondSlotContent" class="slot-wrapper-inner">
      <ng-container
        *ngTemplateOutlet="template; context: { isContainerCollapsed: showHamburguer }"
      ></ng-container>
    </div>
  </div>

  <div class="slot-separator"></div>

  <button
    wlmCollapsibleButton
    *ngIf="show[options.ClearGridFilters]"
    [tooltip]="tooltipClearGridFilters"
    [disabled]="
      disableStatus[options.ClearGridFilters] ||
      (grid && !grid?.canClearFilters()) ||
      (localGridComponent && !localGridComponent?.canClearFilters())
    "
    [showLabel]="showHamburguer"
    (click)="onClickClearGridFilters()"
  >
    <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
  </button>

  <div class="slot-separator"></div>

  <div *ngIf="thirdSlotContent" class="slot-wrapper">
    <div *ngFor="let template of thirdSlotContent" class="slot-wrapper-inner">
      <ng-container
        *ngTemplateOutlet="template; context: { isContainerCollapsed: showHamburguer }"
      ></ng-container>
    </div>
  </div>

  <div
    *ngIf="show[options.ExportExcel] || show[options.ManageColumns]"
    class="slot-separator"
  ></div>

  <wlm-export-excel-button
    *ngIf="show[options.ExportExcel]"
    [visibleColumns]="gridSettings?.gridColumnSettings"
    [disabled]="disableStatus[options.ExportExcel]"
    [grid]="grid"
    [localGrid]="localGridComponent"
    [showLabel]="showHamburguer"
  ></wlm-export-excel-button>

  <wlm-export-pdf-button
    *ngIf="show[options.ExportExcel]"
    [disabled]="disableStatus[options.ExportExcel]"
    [exportPdfComponent]="currentGrid"
    [showLabel]="showHamburguer"
  ></wlm-export-pdf-button>

  <span *isSuperUser="">
    <button
      wlmCollapsibleButton
      *ngIf="show[options.EditGridSettings]"
      [tooltip]="'common.edit-grid-settings'"
      [showLabel]="showHamburguer"
      (click)="editGridSettings()"
    >
      <mat-icon svgIcon="json"></mat-icon>
    </button>
  </span>

  <button
    wlmCollapsibleButton
    *ngIf="show[options.ManageColumns]"
    [tooltip]="tooltipManageColumn"
    [disabled]="disableStatus[options.ManageColumns]"
    [showLabel]="showHamburguer"
    (click)="onClickManageColumns()"
  >
    <mat-icon class="material-icons-round" svgIcon="grid-settings"></mat-icon>
  </button>
</div>
