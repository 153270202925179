import { CalendarExceptionTypesEnum, DaysOfWeekEnum } from './exception-list.enum';

export class CreateCalendarExceptionDto {
  calendarId: string;
  type: CalendarExceptionTypesEnum;
  dayOfWeek: DaysOfWeekEnum;
  startMonth?: number;
  endMonth?: number;
  startDay?: number;
  endDay?: number;
  startYear?: number;
  endYear?: number;

  constructor(init: Partial<CreateCalendarExceptionDto>) {
    Object.assign(this, init);
  }
}
