import { BaseGenericCartesianChartSettings } from './base-generic-cartesian-chart-settings';
import { GCartesianChartSerie } from './g-cartesian-chart-series';

export class GenericCartesianChartSettings extends BaseGenericCartesianChartSettings {
  series: GCartesianChartSerie[];
  useCategoryAxis? = false;
  xAxisType?: 'category' | 'value' | 'default';
  useDataZoom? = true;
  categoryAxisTitle?: string;

  constructor(init: Partial<GenericCartesianChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
