import { Injectable, Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { RuntimeCacheService } from '../auth/services/runtime-cache.service';
import { IAlgorithmDto } from '../model/algorithm/algorithm.dto';

@Injectable({ providedIn: 'root' })
export class AlgorithmsService extends RuntimeCacheService<Map<string, IAlgorithmDto>> {
  expiration: GetCacheOpts = { expiration: 'default', reload: true };

  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/alg/algorithm/all`;
  }

  getAlgorithm(shortName: string): Observable<IAlgorithmDto> {
    return this.getCachedData().pipe(map((data) => data.get(shortName)));
  }

  getAlgorithms(): Observable<IAlgorithmDto[]> {
    return this.httpCacheClient.get<IAlgorithmDto[]>(this.url, this.expiration);
  }

  /**
   * Specify how the data to cache is obtained.
   */
  requestData(): Observable<Map<string, IAlgorithmDto>> {
    return this.getAlgorithms().pipe(
      map((array) =>
        asEnumerable(array).ToDictionary(
          (item) => item.algorithmShortName?.toUpperCase(),
          (item) => item
        )
      )
    );
  }
}
