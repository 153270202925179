import { Injectable, Type } from '@angular/core';
import { BaseDynamicWidgetComponent } from '../redux/components/base-dynamic-widget.component';

/**
 * Service that is shared across all the application, and registers all available widgets that can be instantiated.
 */

@Injectable({
  providedIn: 'root',
})
export class WidgetRegistryService {
  private registry = new Map<string, Type<BaseDynamicWidgetComponent>>();

  register(items: { name: string; widgetClass: Type<BaseDynamicWidgetComponent> }[]): void {
    items.forEach(({ name, widgetClass }) => {
      this.registry.set(name, widgetClass);
    });
  }

  get(widgetName: string): Type<BaseDynamicWidgetComponent> {
    if (!this.registry.has(widgetName)) {
      throw new Error(
        `The widget ${widgetName} is not registered in the global widget registry. To solve this, register it in its module constructor.`
      );
    }
    const widget = this.registry.get(widgetName);
    return widget;
  }
}
