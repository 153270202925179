import { State } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { StateSyncSettings } from './state-sync-settings';

export abstract class StateSyncService {
  settings: StateSyncSettings;

  constructor(settings: StateSyncSettings) {
    this.settings = settings;
  }

  sendState<T>(state: State<T>): void {
    if (this.settings) {
      this.sendStateImpl<T>(state);
    }
  }

  /**
   * This method was intended to be async, but currently async reducers are not supported
   * (https://github.com/ngrx/platform/issues/1649).
   * Considered using effects, but the restoring of the state must be done before any other action.
   */
  getState<T>(): State<T> {
    return this.settings ? this.getStateImpl<T>() : null;
  }

  /**
   * Notifies state changes.
   */
  listenState$<T>(): Observable<State<T>> {
    return this.settings ? this.listenStateImpl$<T>() : of(null);
  }

  protected abstract listenStateImpl$<T>(): Observable<State<T>>;

  protected abstract sendStateImpl<T>(state: State<T>): void;

  protected abstract getStateImpl<T>(): State<T>;
}
