<wlm-widget-info
  [filters]="filters"
  [paramsTitle]="paramsTitle"
  [baseTitle]="baseTitle"
  (sizeChanged)="onWidgetInfoSizeChanged($event)"
></wlm-widget-info>
<wlm-dynamic-chart
  style="overflow: auto"
  *ngIf="chartSettings"
  [dynamicChartSettings]="chartSettings"
  [dateParamsChanged$]="setDateParams$"
  [containerSize$]="calculatedSize$"
></wlm-dynamic-chart>
