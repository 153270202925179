import { NgModule } from '@angular/core';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

@NgModule({
  declarations: [],
  imports: [
    LayoutModule,
    TreeViewModule,
    GridModule,
    TooltipModule,
    DropDownsModule,
    DateTimePickerModule,
    InputsModule,
    ExcelModule,
    SchedulerModule,
    TreeListModule,
    ExcelExportModule,
    PDFModule,
  ],
  exports: [
    LayoutModule,
    TreeViewModule,
    GridModule,
    TooltipModule,
    DropDownsModule,
    DateTimePickerModule,
    InputsModule,
    ExcelModule,
    SchedulerModule,
    TreeListModule,
    ExcelExportModule,
    PDFModule,
  ],
})
export class KendoSharedModule {}
