export class SaveEditedValuesDto {
  shortName: string;
  date: Date;
  elementId: string;
  value: number;
  oldValue?: number;

  constructor(init?: Partial<SaveEditedValuesDto>) {
    Object.assign(this, init);
  }
}
