<ng-template #main>
  <wlm-default-filter-item-select
    *ngIf="elementList"
    [title]="titleKey | translate"
    [options]="elementList"
    [clearAll$]="clearAll$"
    [selectAll$]="selectAll$"
    [restoreStatus$]="restoreState$"
    [saveInitStatus$]="saveInitStatus$"
    [filterText]="filterText"
    [selectedIds]="selectedIds"
    [defaultSelectedIds]="defaultSelectedIds"
    [settings]="settings"
    [mode]="mode"
    (select)="onSelected($event)"
  ></wlm-default-filter-item-select>
</ng-template>
<ng-template #summary>
  <wlm-default-filter-item-summary
    *ngIf="!settings?.formMode && selectedElements && selectedElements.length !== 0"
    [title]="titleKey | translate"
    [items]="selectedElements"
  >
  </wlm-default-filter-item-summary>
</ng-template>
