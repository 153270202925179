export class LargeUserAvailableDto {
  networkElementId: string;
  customerId: string;
  propertyId: string;
  organizationName: string;
  largeUserId: number;
  title: string;

  constructor(init: Readonly<LargeUserAvailableDto>) {
    Object.assign(this, init);
  }
}
