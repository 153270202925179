<wlm-he-tree-family-date-range-filter
  *ngIf="filterSettings"
  class="bi-filter"
  [settings]="filterSettings"
  [clearAll$]="clearAll$"
  [persistFilters$]="persistFilters$"
  containerClass="filter-container-self-space"
  (filtersChanged)="getDataBindingFilters($event)"
  (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
  (apply)="applyFilters()"
  (autoLoad)="onCheckAutoload()"
></wlm-he-tree-family-date-range-filter>
