<div *ngIf="form" [formGroup]="form" class="config-container extends-subscript">
  <div class="zoom-group">
    <mat-form-field appearance="outline">
      <mat-label>{{ T_SCOPE + '.labels.min-zoom' | translate }}</mat-label>
      <input matInput type="number" [formControlName]="minZoomFieldName" />
      <mat-error *ngIf="form.get(minZoomFieldName)?.errors">
        {{ T_SCOPE + '.validation.invalid-range' | translate : { min: minZoom, max: maxZoom } }}
      </mat-error>
      <mat-error *ngIf="form.get(minZoomFieldName)?.errors">
        {{ T_SCOPE + '.validation.max-zoom' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ T_SCOPE + '.labels.max-zoom' | translate }}</mat-label>
      <input matInput type="number" [formControlName]="maxZoomFieldName" />
      <mat-error *ngIf="form.get(maxZoomFieldName)?.errors">
        {{ T_SCOPE + '.validation.invalid-range' | translate : { min: minZoom, max: maxZoom } }}
      </mat-error>
      <mat-error *ngIf="form.get(maxZoomFieldName)?.errors">
        {{ T_SCOPE + '.validation.min-zoom' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
