import { NavKeys } from '../../dependencies/navigation/nav-keys.enum';

interface INavigationsConstants {
  NavigationModeKey: string;
  QueryParamskey: string;
  MaxQueryParamsByUrl: number;
  defaultHomeNavKey: NavKeys;
}

export const NavigationsConstants: Readonly<INavigationsConstants> = {
  NavigationModeKey: 'navigationMode',
  QueryParamskey: 'queryParams',
  MaxQueryParamsByUrl: 10,
  defaultHomeNavKey: NavKeys.DistributionNetwork,
};
