export class DataValidationStateFields {
  static readonly selectedPoint = 'selectedPoint';
  static readonly selectedValueRange = 'selectedValueRange';
  static readonly selectedValueRangeUpdate = 'selectedValueRangeUpdate';
  static readonly selectedDateRange = 'selectedDateRange';
  static readonly selectedDateRangeUpdate = 'selectedDateRangeUpdate';
  static readonly selectedValidationToApply = 'selectedValidationToApply';
  static readonly toggleValueRangeCart = 'toggleValueRangeCart';
  static readonly toggleDateRangeCart = 'toggleDateRangeCart';
}
