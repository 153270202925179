import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';
import { IGisLayer } from 'src/app/common-modules/dependencies/map/gis-layer';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';

@Pipe({
  name: 'gisLayerName',
})
export class GisLayerNamePipe implements PipeTransform {
  private readonly T_SCOPE = `${AppModules.Map}.gis-layers.layers`;
  private readonly _localization = inject(LocalizationHelperService);

  transform(layer: IGisLayer): Observable<string> {
    if (!layer) {
      return of('');
    }

    if (layer.isZone) {
      return of(layer.description);
    }

    const labelKey = `${this.T_SCOPE}.${layer.gisLayerId}`;
    return this._localization.get(labelKey).pipe(
      take(1),
      map((label) => (label === labelKey ? layer.description : label))
    );
  }
}
