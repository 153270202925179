import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

import { BiTemplateGridDto } from '../models/bi-template-grid.dto';

const url = '/api/bi/dashboard';

// Must be provided in root because the generic grid cannot receive a custom injector (and BiModule is lazy loaded).
@Injectable({
  providedIn: 'root',
})
export class BiGridService extends GridODataService<BiTemplateGridDto> {
  constructor(injector: Injector) {
    super(injector, url);
  }

  protected mapResponse(
    response: PagedResultDto<BiTemplateGridDto>
  ): PagedResultDto<BiTemplateGridDto> {
    return response;
  }
}
