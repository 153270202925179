<div *ngIf="dateRangeForm">
  <form [formGroup]="dateRangeForm" class="date-range-form-gap" [class]="formClass">
    <mat-form-field
      [appearance]="fieldAppearance"
      class="date-picker"
      [ngClass]="{ 'mf-small': smallFields }"
    >
      <mat-label>{{ T_SCOPE + '.start-date-label' | translate }}</mat-label>
      <input
        matInput
        [ngStyle]="{
          display: showCurrentStart ? 'none' : 'inherit'
        }"
        [matDatepicker]="startDatePicker"
        [min]="minDate"
        [max]="maxDate"
        formControlName="start"
        [errorStateMatcher]="settings?.startDateErrorStateMatcher"
      />

      <input
        matInput
        *ngIf="showCurrentStart"
        [disabled]="isReadOnly"
        [value]="currentStartLabelKey | translate"
        readonly
      />
      <button
        *ngIf="showCurrentStart"
        matSuffix
        mat-icon-button
        [disabled]="isReadOnly"
        class="datepicker-icon-button"
        (click)="startDatePicker.open()"
        onclick="this.blur()"
      >
        <mat-icon>today</mat-icon>
      </button>

      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
        [ngStyle]="{
          display: showCurrentStart ? 'none' : 'inherit'
        }"
      >
      </mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error *ngIf="dateRangeForm.get('start')?.errors?.required">{{
        'common.validation.required' | translate
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('start')?.errors?.matDatepickerMax">{{
        'common.validation.max-date' | translate : { max: maxDate | date : 'date' }
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('start')?.errors?.matDatepickerMin">{{
        'common.validation.min-date' | translate : { min: minDate | date : 'date' }
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('start')?.errors?.dateOlderThanError">
        {{ 'common.validation.start-date-range-older' | translate }}
      </mat-error>
      <mat-error *ngIf="dateRangeForm.get('start')?.errors?.dateOlderEqualThanError">
        {{ 'common.validation.start-date-range-older-equal' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      [appearance]="fieldAppearance"
      class="date-picker"
      [ngClass]="{ 'mf-small': smallFields }"
    >
      <mat-label>{{ T_SCOPE + '.end-date-label' | translate }}</mat-label>
      <input
        matInput
        [ngStyle]="{
          display: showCurrentEnd ? 'none' : 'inherit'
        }"
        [matDatepicker]="endDatePicker"
        [min]="minDate"
        [max]="maxDate"
        formControlName="end"
        [errorStateMatcher]="settings?.endDateErrorStateMatcher"
      />

      <input
        matInput
        *ngIf="showCurrentEnd"
        [disabled]="isReadOnly"
        [value]="currentEndLabelKey | translate"
        readonly
      />
      <button
        *ngIf="showCurrentEnd"
        matSuffix
        mat-icon-button
        [disabled]="isReadOnly"
        class="datepicker-icon-button"
        (click)="endDatePicker.open()"
        onclick="this.blur()"
      >
        <mat-icon>today</mat-icon>
      </button>

      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
        [ngStyle]="{
          display: showCurrentEnd ? 'none' : 'inherit'
        }"
      >
      </mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <mat-error *ngIf="dateRangeForm.get('end')?.errors?.required">{{
        'common.validation.required' | translate
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('end')?.errors?.matDatepickerMax">{{
        'common.validation.max-date' | translate : { max: maxDate | date : 'date' }
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('end')?.errors?.matDatepickerMin">{{
        'common.validation.min-date' | translate : { min: minDate | date : 'date' }
      }}</mat-error>
      <mat-error *ngIf="dateRangeForm.get('end')?.errors?.dateOlderThanError">
        {{ 'common.validation.end-date-range-older' | translate }}
      </mat-error>
      <mat-error *ngIf="dateRangeForm.get('end')?.errors?.dateOlderEqualThanError">
        {{ 'common.validation.end-date-range-older-equal' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
