import { Component, Injector, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ISapCodeGroupDto } from 'src/app/common-modules/dependencies/alc/sap-code-group.dto';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ValidationHelperService } from 'src/app/common-modules/shared/helpers/validation-helper.service';
import { SapCodeGroupsCrudService } from '../sap-code-groups-crud.service';
import { SapCodeGroupsFormComponent } from '../sap-code-groups-form/sap-code-groups-form.component';

const COMPONENT_SELECTOR = 'wlm-sap-code-groups-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sap-code-groups-crud.component.html',
  styleUrls: ['./sap-code-groups-crud.component.scss'],
})
export class SapCodeGroupsCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _service = inject(SapCodeGroupsCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'SapCodeGroupsCrudService',
      grid: {
        gridSettingsName: 'SapCodeGroupsCrud',
      },
      create: {
        formComponent: SapCodeGroupsFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: SapCodeGroupsFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: ISapCodeGroupDto): Observable<ISapCodeGroupDto | null> => {
      return this._service.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.sap-code-group-id`
            );
            return null;
          } else if (validation.isDuplicatedValue) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.sap-code-group-value`
            );
            return null;
          }
          return model;
        })
      );
    };
}
