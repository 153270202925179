import { Component, Injector, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { HeTypeFormComponent } from '../he-type-form/he-type-form.component';
import { HETypesCrudService } from '../he-types-crud.service';

@Component({
  selector: 'wlm-he-type-crud',
  templateUrl: './he-type-crud.component.html',
  styleUrls: ['./he-type-crud.component.scss'],
})
export class HeTypeCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private _heTypesCrudService = inject(HETypesCrudService);

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'HETypesCrudService',
      grid: {
        gridSettingsName: 'HierarchyElementTypesCrud',
      },
      create: {
        formComponent: HeTypeFormComponent,
      },
      update: {
        formComponent: HeTypeFormComponent,
        disableHook: this.disableHook,
      },
      delete: {
        disableHook: this.disableHook,
      },
    });
  }

  private disableHook = (model: IHierarchyElementTypeDto): Observable<boolean> => {
    const isReadonly = this._heTypesCrudService.isReadonlyType(model.hierarchyElementTypeId);
    return of(isReadonly);
  };
}
