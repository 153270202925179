import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, combineLatest, map } from 'rxjs';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutStructure } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-structure';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetStateAction,
  SetValueAction,
} from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { ChartWorkspaceTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { WorkspacesHelperService } from '../../../shared/services/workspaces-helper.service';
import { DataVisualizationManageWorkspaceResult } from '../../models/data-visualization-manage-workspace-result';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';
import { DV_WORKSPACE_EDITOR_COMPONENT_INSTANCE } from '../../widgets/data-visualization-workspace-editor-widget/data-visualization-workspace-editor-widget.component';
import { DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE } from '../../widgets/data-visualization-workspace-selector-widget/data-visualization-workspace-selector-widget.component';
import { WorkspaceService } from '../data-visualization-workspace-selector/workspace.service';

const COMPONENT_SELECTOR = 'wlm-data-visualization-manage-workspace-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-manage-workspace-popup.component.html',
  styleUrls: ['./data-visualization-manage-workspace-popup.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class DataVisualizationManageWorkspacePopupComponent implements OnInit {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  settings: DynamicLayoutSettings;
  selectedWorkspace: WorkspaceDto;
  cartItems: IFiltrableItemDto[];
  chartConfiguration: ChartConfiguration;
  hasError: boolean;

  selectedWorkspaceName: string;
  navigationMode: boolean;

  currentUserCode: string;
  isSuperUser: boolean;
  isLoading = false;

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);

  private readonly _workspaceEditorWidgetName = 'DataVisualizationWorkspaceEditorWidgetComponent';
  private readonly _workspaceSelectorWidgetName =
    'DataVisualizationWorkspaceSelectorWidgetComponent';

  private readonly _templateSelectorWidgetNameKey = `${this._workspaceSelectorWidgetName}-template`;
  private readonly _workspaceSelectorWidgetNameKey = `${this._workspaceSelectorWidgetName}-workspace`;

  private readonly _pageSettingsKeys = {
    layoutKey: null,
    layoutArea: null,
    widgetPage: 'dataVisualizationManageWorkspace',
    widgetModule: 'dataVisualizationModule',
  };

  private _defaultInstanceKey = 'shared';

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._pageSettingsKeys.widgetModule,
    page: this._pageSettingsKeys.widgetPage,
    scopeInstanceKeys: {
      [StateAreas.Generic]: this._defaultInstanceKey,
    },
  });

  private readonly _selectorLayoutItems: DynamicLayoutStructure[] = [
    {
      type: LayoutNodeTypes.Component,
      widgetInstanceKey: `${DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE}#${ChartWorkspaceTypeEnum.Template}`,
    },
  ];

  private readonly _workspaceSelectorItem: DynamicLayoutStructure = {
    type: LayoutNodeTypes.Component,
    widgetInstanceKey: `${DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE}#${ChartWorkspaceTypeEnum.Workspace}`,
  };

  private readonly _dvMainPageSettingsKeys = new StateFullSettings({
    widget: new StateWidgetSettings({
      module: this._pageSettingsKeys.widgetModule,
      page: 'dataVisualizationPage',
      scopeInstanceKeys: {
        [StateAreas.Generic]: this._defaultInstanceKey,
      },
    }),
    area: StateAreas.Generic,
    scope: this._defaultInstanceKey,
  });

  constructor(
    private readonly _dialogRef: MatDialogRef<DataVisualizationManageWorkspacePopupComponent>,
    private readonly _state: ReduxStateService,
    private readonly _dynamicLayoutService: DynamicLayoutService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _localization: LocalizationHelperService,
    private readonly _workspaceHelperService: WorkspacesHelperService,
    private readonly _workspaceService: WorkspaceService,
    private readonly _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) { selectedWorkspace, navigationMode }: any
  ) {
    this.selectedWorkspaceName = selectedWorkspace;
    this.navigationMode = navigationMode;
    this.currentUserCode = this._authenticationService.userCode;

    this._state.configure(this._stateWidgetSettings);
  }

  ngOnInit(): void {
    if (!this.navigationMode) {
      this._selectorLayoutItems.push(this._workspaceSelectorItem);
    }

    const isSuperUser$ = this._authorizeService.isSuperUser().pipe(untilDestroyed(this));
    const titles$ = this.buildTitles().pipe(untilDestroyed(this));

    combineLatest([isSuperUser$, titles$]).subscribe({
      next: ([isSuperUser, titles]) => {
        this.isSuperUser = isSuperUser;

        this.buildPage(titles);
      },
      error: (error) => this._dialogService.showErrorMessage(error),
    });

    this.listenState(DataVisualizationStateFields.editedWorkspace).subscribe((value) => {
      if (!value) {
        return;
      }
      this.selectedWorkspace = value?.workspace;
      this.chartConfiguration = value?.chartConfiguration;
      this.cartItems = value?.cartItems;

      this.load();
    });

    this.listenState(DataVisualizationStateFields.selectedWorkspace).subscribe(
      (value) => (this.selectedWorkspace = value)
    );

    this.listenState(DataVisualizationStateFields.editedWorkspaceHasError).subscribe(
      (value) => (this.hasError = value)
    );
  }

  cancel() {
    this.close(null);
  }

  close(response): void {
    this._state.dispatch(
      new ResetStateAction({ fieldNames: Object.keys(DataVisualizationStateFields) })
    );

    this._dialogRef.close(response);
  }

  loadSelectedWorkspace() {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.loadWorkspace,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.loadWorkspace,
        value: true,
      })
    );
  }

  isVisibleDeleteButton() {
    return (
      this.selectedWorkspace?.workspaceId &&
      (this.isSuperUser || this.selectedWorkspace.userCode === this.currentUserCode)
    );
  }

  deleteSelectedWorkspace() {
    const typeName =
      this.selectedWorkspace.workspaceTypeId === ChartWorkspaceTypeEnum.Template
        ? 'ws-template'
        : 'workspace';

    this._dialogService.showDeleteConfirmationSnackBar(1, typeName).subscribe((result) => {
      if (result?.result) {
        this.isLoading = true;

        this._workspaceService
          .delete(this.selectedWorkspace.workspaceId)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (response) => {
              if (!response) {
                return;
              }

              this._dialogService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({
                  translateKey: `${this.T_SCOPE}.messages.${typeName}-deleted`,
                })
              );

              this.isLoading = false;

              this.reloadPopUp();
            },
            error: (error) => {
              this.isLoading = false;

              this._dialogService.showErrorMessage(error);
            },
          });
      }
    });
  }

  load(): void {
    const entityTypes = this._workspaceHelperService.getTemplateEntityTypes(
      this.chartConfiguration
    );

    const response = new DataVisualizationManageWorkspaceResult({
      workspace: this.selectedWorkspace,
      cartFiltrableItems: this.cartItems,
      chartConfiguration: this.chartConfiguration,
      entityTypes: entityTypes,
    });

    this.close(response);
  }

  private reloadPopUp() {
    // DV Main Page has different setting keys configured
    // so we need to dispatch this action to the correct state path
    this._state.dispatchConfigured(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.removedWorkspace,
        value: this.selectedWorkspace.workspaceId,
      }),
      this._dvMainPageSettingsKeys
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.selectedWorkspace,
        value: null,
      })
    );

    this.settings = { ...this.settings };
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${AppModules.DataVisualization}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [
            this._templateSelectorWidgetNameKey,
            ts['wlm-data-visualization-workspace-selector-widget-template'],
          ],
          [
            this._workspaceSelectorWidgetNameKey,
            ts['wlm-data-visualization-workspace-selector-widget-workspace'],
          ],
          [this._workspaceEditorWidgetName, ts['wlm-data-visualization-workspace-editor-widget']],
        ]);
        return titles;
      })
    );
  }

  private buildPage(titles: Map<string, string>) {
    this.settings = new DynamicLayoutSettings({
      layoutKey: this._pageSettingsKeys.layoutKey,
      layoutArea: this._pageSettingsKeys.layoutArea,
      widgetPage: this._pageSettingsKeys.widgetPage,
      widgetModule: this._pageSettingsKeys.widgetModule,
      currentUser: this.currentUserCode,
      ignoreParamsOnPersistencyCheck: true,
      structure: [],
      defaultStructure: [
        {
          type: LayoutNodeTypes.Row,
          content: [
            {
              type: LayoutNodeTypes.Column,
              height: 100,
              width: 30,
              content: [
                {
                  type: LayoutNodeTypes.Stack,
                  content: this._selectorLayoutItems,
                },
              ],
            },
            {
              type: LayoutNodeTypes.Column,
              height: 100,
              width: 70,
              content: [
                {
                  type: LayoutNodeTypes.Stack,
                  content: [
                    {
                      type: LayoutNodeTypes.Component,
                      widgetInstanceKey: DV_WORKSPACE_EDITOR_COMPONENT_INSTANCE,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      items: [
        ...this.getItems(
          this.navigationMode,
          titles.get(this._templateSelectorWidgetNameKey),
          titles.get(this._workspaceSelectorWidgetNameKey)
        ),
        {
          componentName: this._workspaceEditorWidgetName,
          widgetInstanceKey: DV_WORKSPACE_EDITOR_COMPONENT_INSTANCE,
          scopeInstanceKeys: {
            [StateAreas.Generic]: this._defaultInstanceKey,
          },
          title: titles.get(this._workspaceEditorWidgetName),
          disableReorder: true,
        },
      ],
      minItemHeight: 70,
      minItemWidth: 200,
      showCloseIcon: false,
      disableReorder: false,
      showPopoutIcon: false,
      showMaximiseIcon: false,
    });
    this._settings$.next(this.settings);
  }

  private getItems(navigationMode: boolean, templateTitle: string, workspaceTitle: string) {
    const selectorItems = [
      {
        componentName: this._workspaceSelectorWidgetName,
        widgetInstanceKey: `${DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE}#${ChartWorkspaceTypeEnum.Template}`,
        scopeInstanceKeys: {
          [StateAreas.Generic]: this._defaultInstanceKey,
        },
        title: templateTitle,
        disableReorder: true,
        params: {
          type: ChartWorkspaceTypeEnum.Template,
          selectedWorkspaceName: this.selectedWorkspaceName,
          currentUser: this.currentUserCode,
        },
      },
    ];

    if (!navigationMode) {
      selectorItems.push({
        componentName: this._workspaceSelectorWidgetName,
        widgetInstanceKey: `${DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE}#${ChartWorkspaceTypeEnum.Workspace}`,
        scopeInstanceKeys: {
          [StateAreas.Generic]: this._defaultInstanceKey,
        },
        title: workspaceTitle,
        disableReorder: true,
        params: {
          type: ChartWorkspaceTypeEnum.Workspace,
          selectedWorkspaceName: this.selectedWorkspaceName,
          currentUser: this.currentUserCode,
        },
      });
    }

    return selectorItems;
  }
}
