import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FiltersAdapterService } from '../dependencies/wlm-filters/filters-adapter.service';
import { CommonSharedModule } from '../shared/common-shared.module';
import { MaterialSharedModule } from '../shared/material-shared.module';
import { ActivityOnlyOpenFilterItemComponent } from './components/activities/activity-only-open-filter-item/activity-only-open-filter-item.component';
import { ActivityTypesFilterItemComponent } from './components/activities/activity-types-filter-item/activity-types-filter-item.component';
import { AlgorithmFilterItemComponent } from './components/algorithms/algorithm-filter-item/algorithm-filter-item.component';
import { TimeAggregationFilterItemComponent } from './components/algorithms/time-aggregation-filter-item/time-aggregation-filter-item.component';
import { BaseFilterContainerComponent } from './components/core/base-filter-container/base-filter-container.component';
import { DefaultFilterItemSelectComponent } from './components/core/default/default-filter-item-select/default-filter-item-select.component';
import { DefaultFilterItemSummaryComponent } from './components/core/default/default-filter-item-summary/default-filter-item-summary.component';
import { DateRangeAndModeComponent } from './components/derivated/date-range-and-mode/date-range-and-mode.component';
import { HierarchyElementsFilterItemComponent } from './components/hierarchy/hierarchy-elements-filter-item/hierarchy-elements-filter-item.component';
import { HierarchyFamilyFilterItemComponent } from './components/hierarchy/hierarchy-family-filter-item/hierarchy-family-filter-item.component';
import { HierarchyTreeFilterItemComponent } from './components/hierarchy/hierarchy-tree-filter-item/hierarchy-tree-filter-item.component';
import { NetworkElementsFilterItemComponent } from './components/network/network-elements-filter-item/network-elements-filter-item.component';
import { CustomCalendarFilterItemSelectComponent } from './components/utils/custom-calendar-filter-item/custom-calendar-filter-item-select/custom-calendar-filter-item-select.component';
import { CustomCalendarFilterItemComponent } from './components/utils/custom-calendar-filter-item/custom-calendar-filter-item.component';
import { DayTypeFilterItemComponent } from './components/utils/day-type-filter-item/day-type-filter-item.component';
import { DimensionTypesFilterItemComponent } from './components/utils/dimension-types-filter-item/dimension-types-filter-item.component';
import { ElementTargetFilterItemComponent } from './components/utils/element-target-filter-item/element-target-filter-item.component';
import { SeasonsFilterItemComponent } from './components/utils/seasons-filter-item/seasons-filter-item.component';
import { UserFilterItemComponent } from './components/utils/user-filter-item/user-filter-item.component';
import { WaterBalanceParamsFormComponent } from './components/water-balance-params-form/water-balance-params-form.component';
import { WaterBalanceTypesFilterItemComponent } from './components/water-balance-types-filter-item/water-balance-types-filter-item.component';
import { BaseFilterComponent } from './core/base-filter/base-filter.component';
import { AlgorithmSelectionGridFilterComponent } from './filters/algorithm-selection-grid-filter/algorithm-selection-grid-filter.component';
import { DateRangeFilterItemComponent } from './filters/date-range-filter-item/date-range-filter-item.component';
import { DateRangeSingleFilterComponent } from './filters/date-range-single-filter/date-range-single-filter.component';
import { FamilyLevelFilterComponent } from './filters/family-level-filter/family-level-filter.component';
import { HeFamilyLevelFilterComponent } from './filters/he-family-level-filter/he-family-level-filter.component';
import { HeTreeFamilyDateRangeFilterComponent } from './filters/he-tree-family-date-range-filter/he-tree-family-date-range-filter.component';
import { HierarchyElementTreeFamilyFilterComponent } from './filters/hierarchy-element-tree-family-filter/hierarchy-element-tree-family-filter.component';
import { HierarchyElementsSelectionFilterComponent } from './filters/hierarchy-elements-selection-filter/hierarchy-elements-selection-filter.component';
import { NeTypeFilterComponent } from './filters/ne-type-filter/ne-type-filter.component';
import { NeTypeTreeFilterComponent } from './filters/ne-type-tree-filter/ne-type-tree-filter.component';
import { HeTreeFamilyFormElementComponent } from './form-elements/he-tree-family-form-element/he-tree-family-form-element.component';

const exported = [
  BaseFilterComponent,
  BaseFilterContainerComponent,
  DateRangeFilterItemComponent,
  FamilyLevelFilterComponent,
  AlgorithmSelectionGridFilterComponent,
  ActivityTypesFilterItemComponent,
  ActivityOnlyOpenFilterItemComponent,
  DefaultFilterItemSelectComponent,
  DefaultFilterItemSummaryComponent,
  NeTypeTreeFilterComponent,
  NeTypeFilterComponent,
  HierarchyFamilyFilterItemComponent,
  HierarchyTreeFilterItemComponent,
  HierarchyElementsSelectionFilterComponent,
  NetworkElementsFilterItemComponent,
  HierarchyElementsFilterItemComponent,
  WaterBalanceTypesFilterItemComponent,
  DimensionTypesFilterItemComponent,
  AlgorithmFilterItemComponent,
  DayTypeFilterItemComponent,
  SeasonsFilterItemComponent,
  CustomCalendarFilterItemComponent,
  CustomCalendarFilterItemSelectComponent,
  DateRangeAndModeComponent,
  HierarchyElementTreeFamilyFilterComponent,
  HeTreeFamilyDateRangeFilterComponent,
  HeFamilyLevelFilterComponent,
  TimeAggregationFilterItemComponent,
  ElementTargetFilterItemComponent,
  UserFilterItemComponent,
  WaterBalanceParamsFormComponent,
  // previously in shared components
  HeFamilyLevelFilterComponent,
  HeTreeFamilyDateRangeFilterComponent,
  HierarchyElementsSelectionFilterComponent,
  NeTypeFilterComponent,
  NeTypeTreeFilterComponent,
  DateRangeSingleFilterComponent,
  HeTreeFamilyFormElementComponent,
];

@NgModule({
  declarations: exported,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialSharedModule,
    TranslateModule,
    CommonSharedModule,
  ],
  exports: exported,
  providers: [FiltersAdapterService],
})
export class CommonFiltersModule {}
