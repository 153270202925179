<wlm-spinner-wrapper [showSpinner]="isLoading" [enableRelative]="true">
  <wlm-popup-wrapper
    [showCloseButton]="false"
    [showSpinner]="false"
    [titleKey]="T_SCOPE + (isEditMode ? '.save-title' : '.save-as-title')"
  >
    <ng-container popup-content>
      <wlm-data-visualization-config-form
        [isEditMode]="isEditMode"
        [workspace]="workspace"
        [chartConfiguration]="chartConfiguration"
        [cartFiltrableItems]="cartFiltrableItems"
        [saveConfig$]="saveConfig$"
        [conflictError$]="conflictError$"
        (hasValidChanges)="onHasValidChanges($event)"
        (save)="onSaveConfig($event)"
      ></wlm-data-visualization-config-form>
    </ng-container>
    <ng-container popup-actions>
      <div class="action-buttons-container">
        <div class="button-container-padding">
          <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
            {{ 'common.cancel' | translate | uppercase }}
          </button>
        </div>
        <div class="button-container-padding">
          <button
            mat-button
            class="wtr-action-button-primary btn-size"
            [disabled]="!hasValidChanges"
            (click)="save(false)"
          >
            {{ 'common.save' | translate | uppercase }}
          </button>
        </div>
        <div *ngIf="isSaveAsSystemVisible()">
          <button
            *isSuperUser
            mat-button
            class="wtr-action-button-primary btn-size"
            [disabled]="!hasValidChanges"
            (click)="save(true)"
          >
            {{ T_SCOPE + '.save-system-template' | translate | uppercase }}
          </button>
        </div>
      </div>
    </ng-container>
  </wlm-popup-wrapper>
</wlm-spinner-wrapper>
