import { NetworkElementsSignalsDto } from './network-elements-signals.dto';

export class NoBoundaryConfigurationDto {
  networkElementId: string;
  signals: NetworkElementsSignalsDto[];

  constructor(init: Readonly<NoBoundaryConfigurationDto>) {
    Object.assign(this, init);
  }
}
