import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-hierarchy-family-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-family-filter-item.component.html',
  styleUrls: ['./hierarchy-family-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => HierarchyFamilyFilterItemComponent),
    },
  ],
})
export class HierarchyFamilyFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() fieldName = 'hierarchyFamilyId';
  @Input() filterText: string;
  @Input() defaultSelectedElements: any[];
  @Input() set restoreStatusOrder(value: number) {
    this.restoreOrder = value;
  }
  @Input() disableClear = true; // So the Clear All button affects the tree but not this filter.

  // Higher Order Observable.
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  mode = 'single';
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;

  constructor(private globalsService: GlobalsService) {
    super();

    this.settings = {
      required: true,
    };
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this.globalsService.getHierarchyFamilies().pipe(
      map((items) => {
        return items.map(
          (item) => new FilterItemSelectOption(item.hierarchyFamilyId, item.hierarchyFamilyName)
        );
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  // To be valid, at least one item must be selected.
  isValid(): boolean {
    return this.isValidSelect;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.Family;
  }
}
