import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { DynamicSettingsSave } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings-save';
import { DynamicSettingsService } from 'src/app/common-modules/shared/config/dynamic-settings.service';
import { HomePageConfigurationDto } from '../../navigation/home-configuration-popup/home-pages-configuration.dto';

@Injectable()
export class HomePageService {
  private readonly _dynamicSettingsService = inject(DynamicSettingsService);
  private readonly _homePageSettingArea = 'HomePage';
  private readonly _homePageSettingKey = 'DefaultPage';
  private readonly _defaultHomePage = NavKeys.DistributionNetwork;

  change(navKey: NavKeys, saveAsDefault = false): Observable<void> {
    const integrationPageSerialized = JSON.stringify(
      new HomePageConfigurationDto({ homePage: navKey })
    );

    const dynamicSettings = new DynamicSettingsSave({
      settingArea: this._homePageSettingArea,
      settingKey: this._homePageSettingKey,
      saveAsDefault,
      settingValue: integrationPageSerialized,
      componentTypeId: 7,
    });

    return this._dynamicSettingsService.saveDynamicSettings(dynamicSettings);
  }

  changeToDefaultPage(saveAsDefault = false): Observable<void> {
    return this.change(this._defaultHomePage, saveAsDefault);
  }
}
