import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NeConfigObservableService {
  private _savePendingChanges$ = new Subject<void>();
  private _saveComplete$ = new Subject<void>();

  constructor() {}

  getSavePendingChangesAsObservable(): Observable<void> {
    return this._savePendingChanges$.asObservable();
  }

  getSaveCompleteAsObservable(): Observable<void> {
    return this._saveComplete$.asObservable();
  }

  setSavePendingChanges() {
    this._savePendingChanges$.next();
  }

  notifySaveCompleted() {
    this._saveComplete$.next();
  }
}
