<wlm-family-level-filter
  *ngIf="filterSettings"
  class="remove-filters-background filter-margin"
  [settings]="filterSettings"
  [clearAll$]="clearAll$"
  [persistFilters$]="persistFilters$"
  (filtersChanged)="getDataBindingFilters($event)"
  (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
  (apply)="applyFilters()"
  (autoLoad)="onCheckAutoload()"
></wlm-family-level-filter>
