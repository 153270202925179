import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { FlatTreeNode } from 'src/app/common-modules/shared/tree/flat-tree-node';
import { DvAlgorithmsSelectorItemSettings } from './dv-algorithms-selector-item-settings';

export class DvAlgorithmsSelectorSettings {
  algorithms: IAlgorithmDto[];
  items: DvAlgorithmsSelectorItemSettings[];
  groups: Map<string, FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>>;

  constructor(init: DvAlgorithmsSelectorSettings) {
    Object.assign(this, init);
  }
}
