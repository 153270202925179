import { TPermissions } from '../../shared/auth/services/authorize.service';

export abstract class BaseLinkConf {
  titleTranslateKey: string;
  svgIcon: string;
  permissions: TPermissions;
  isSvg = true; // Currently ignored flag
  shortTitleTranslateKey?: string;

  constructor(init?: Partial<BaseLinkConf>) {
    Object.assign(this, init);
  }
}
