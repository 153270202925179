import { Pipe, PipeTransform } from '@angular/core';
import { WtrColors } from '../../shared/styles/wtr-colors';

@Pipe({
  name: 'comparisonImageIconColor',
})
export class ComparisonImageIconColor implements PipeTransform {
  constructor() {}

  transform(value: number, compValue: number): WtrColors {
    if (value == compValue) {
      return WtrColors.NeutralColorBlack2;
    }
    return value > compValue ? WtrColors.ErrorColor : WtrColors.SuccessColor;
  }
}
