import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGisLayerYear } from 'src/app/common-modules/dependencies/map/gis-layer-year';
import { MenuLink } from 'src/app/common-modules/dependencies/navigation/menu-link';
import { NavItem } from 'src/app/common-modules/dependencies/navigation/nav-item';
import { WNavigateSettings } from 'src/app/common-modules/dependencies/navigation/w-navigate-by';
import { NetworkElementAttributeTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-attribute-type.dto';
import { FieldDataType } from 'src/app/common-modules/dynamic-forms/models/field-data-type';
import { FieldDefinition } from 'src/app/common-modules/dynamic-forms/models/field-definition';
import { FieldDisplayType } from 'src/app/common-modules/dynamic-forms/models/field-display-type';
import { SettingsDataSource } from 'src/app/common-modules/dynamic-forms/models/settings-data-source.enum';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import { DynamicSettingsSave } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings-save';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { DynamicSettingsService } from 'src/app/common-modules/shared/config/dynamic-settings.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IGisLayerDto } from 'src/app/common-modules/shared/model/gis/gis-layer.dto';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { WlmNavigationService } from '../../../common-modules/dependencies/navigation/wlm-navigation.service';
import { IApplicationAttributeDto } from '../../../common-modules/dependencies/shared/model/application-attribute.dto';
import { ApplicationAttributes } from '../../../common-modules/shared/constants/application-constants';
import { DataVisualizationNavigationService } from '../data-visualization-shared/services/data-visualization-navigation.service';
import { SettingsComponentType } from '../shared/config/settings-component-type';
import { NavItemsConfiguration } from '../shared/model/navigation/navitem-configuration';
import { WlmElementType } from '../shared/model/wlm/wlm-element-type';
import { MapSettings } from './map-filter/models/map-filter-settings';
import { MapParameters } from './map-parameters';
import { MapTooltipProperty } from './map-tooltip/models/map-tooltip-property';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MapHelperService {
  private readonly _neAttributesPrefix = 'a-';
  private readonly _algorithmsPrefix = 'al-';
  private readonly _datesSuffix = '-date';

  private readonly _customerRelatedLayers = ['13', '1000', '1002', '40'];
  private readonly _customerRelatedProperties = ['class', 'consumption'];
  private readonly _customerRelatedUoMProperties = {
    consumption: {
      dimensionTypeId: 1,
      timeAggregationId: 2,
      hierarchyElementTypeId: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
    },
  };

  private readonly _uomDecimals = 3;

  private readonly _companyGisLayerId = 9;

  private readonly _thematicZoneLayers = [1, 2, 5];
  private readonly _transmissionRelatedLayers = ['10', '5001', '5002', '11', '12', '15', '16'];

  private readonly _leaksRelatedProperties = ['type-name', 'flow', 'n-flow', 'o-date', 'c-date'];
  private readonly _leaksRelatedUoMProperties = {
    flow: {
      dimensionTypeId: 1,
      timeAggregationId: 2,
      hierarchyElementTypeId: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
    },
    'n-flow': {
      dimensionTypeId: 1,
      timeAggregationId: 2,
      hierarchyElementTypeId: 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF',
    },
  };

  private readonly _activitiesRelatedProperties = ['type-name', 'start-date', 'end-date'];

  private _heTypesByNeTypes: Map<number, string> = new Map<number, string>();
  private _neTypesLayerIds: number[];
  private _neAttributeTypes: NetworkElementAttributeTypeDto[];
  private _neAttributeFields: { [key: string]: FieldDefinition };
  private _pendingMapParameter: MapParameters;
  private _filtrableLeakYears: IGisLayerYear[];
  private _currentFiscalYear: number;

  constructor(
    private _globalsService: GlobalsService,
    private _uomService: UoMService,
    private _navigationService: WlmNavigationService,
    private _dynamicSettingsService: DynamicSettingsService,
    private _popupDialog: MatDialog,
    private _dateHelperService: DateHelperService,
    private _localizationHelperService: LocalizationHelperService,
    private _localStorageService: LocalStorageService,
    private _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    const neAttributeFields$ = this._dynamicSettingsService.getCustomSettingsByDataSource(
      SettingsDataSource.NetworkElementAttribute
    );
    const heTypesByNeTypes$ = this._globalsService.getHierarchyElementTypes();
    const neTypes$ = this._globalsService.getNetworkElementTypes();
    const neAttributeTypes$ = this._globalsService.getNetworkElementAttributesType();
    const appAttributes$ = this._globalsService.getApplicationAttributes();

    combineLatest([
      neAttributeFields$,
      heTypesByNeTypes$,
      neTypes$,
      neAttributeTypes$,
      appAttributes$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([neAttributes, heTypes, neTypes, neAttributeTypes, appAttributes]) => {
        this.setCurrentFiscalYear(appAttributes);

        if (neAttributes?.categories && neAttributes.categories?.length > 0) {
          const fields = neAttributes.categories
            .map((cat) => cat.fields)
            .reduce((acc, current) => {
              Object.entries(current).forEach(([key, value]) => (acc[key] = value));
              return acc;
            }, {} as { [key: string]: FieldDefinition });

          this._neAttributeFields = fields;
        }

        const items = heTypes.filter((f) => f.networkElementTypeId !== undefined);
        items.forEach((i) =>
          this._heTypesByNeTypes.set(i.networkElementTypeId, i.hierarchyElementTypeId)
        );

        this._neTypesLayerIds = neTypes
          .filter((f) => !f.isZone && f.isNetworkElement)
          ?.map((ne) => ne.gisLayerId);

        this._neAttributeTypes = neAttributeTypes;
      });
  }

  setPendingMapParameters(value: MapParameters) {
    this._pendingMapParameter = value;
  }

  setFiltrableGisLeakYears(leakYears: IGisLayerYear[]) {
    this._filtrableLeakYears = leakYears;
  }

  getPendingMapParameters(): MapParameters {
    return this._pendingMapParameter;
  }

  getMapTooltipNavigations(navItemConfigs: NavItemsConfiguration[], params: any): MenuLink[] {
    let navigations: MenuLink[] = [];

    const navItems = navItemConfigs.map(
      (config) =>
        new NavItem(
          config.key,
          config.customValidation ? config.customValidation : false,
          params,
          config.customtooltip,
          config.customNavMethod,
          config.titleKey
        )
    );

    this._navigationService.generateMenuLinks(navItems).subscribe((menuLinks: MenuLink[]) => {
      navigations = menuLinks;
    });

    return navigations;
  }
  getMapTooltipProperties(properties: { [key: string]: string }): Observable<MapTooltipProperty[]> {
    let mapTooltipProperties: MapTooltipProperty[] = [];

    const neTypeId = properties.type;
    const heTypeId = this._heTypesByNeTypes.get(+neTypeId);

    const algoritmConversionValues$ = this.getAlgorithmConversionValues(heTypeId, properties);

    return algoritmConversionValues$.pipe(
      map((algoritmConversionValue) => {
        Object.entries(properties).forEach(([key, value]) => {
          // Algorithms
          if (algoritmConversionValue?.size && key.startsWith(this._algorithmsPrefix)) {
            const algorithmName = key.replace(this._algorithmsPrefix, '');

            const uomFactor = algoritmConversionValue.get(algorithmName);
            const convertedValue = this._uomService.getConvertedValue(
              uomFactor,
              +value,
              this._uomDecimals
            );
            mapTooltipProperties.push(
              new MapTooltipProperty({
                key,
                label: algorithmName.toUpperCase(),
                value: convertedValue,
              })
            );

            // Network Element Attributes
          } else if (key.startsWith(this._neAttributesPrefix)) {
            const tooltipProperty = this.getNeTooltipPropertyFromFieldsDefinition(key, value);
            if (tooltipProperty) {
              mapTooltipProperties.push(tooltipProperty);
            } else {
            }
            // Customer properties
          } else if (this._customerRelatedProperties.some((prop) => prop === key)) {
            const tooltipProperty = this.getUoMProperty(
              key,
              value,
              this._customerRelatedUoMProperties[key]
            );
            if (tooltipProperty) {
              mapTooltipProperties.push(tooltipProperty);
            }
            // Leaks properties
          } else if (
            this._leaksRelatedProperties.some((prop) => prop === key) ||
            this._activitiesRelatedProperties.some((prop) => prop === key)
          ) {
            const date = key.endsWith(this._datesSuffix)
              ? this._dateHelperService.fromApiFormat(value)
              : null;

            if (this._dateHelperService.isDateObject(date)) {
              mapTooltipProperties.push(
                new MapTooltipProperty({
                  key: key,
                  labelKey: key,
                  value: this._localizationHelperService.getLocalizedDateFromApi(
                    date,
                    DateFormats.Date
                  ),
                })
              );
            } else {
              const tooltipProperty = this.getUoMProperty(
                key,
                value,
                this._leaksRelatedUoMProperties[key]
              );

              if (tooltipProperty) {
                mapTooltipProperties.push(tooltipProperty);
              }
            }
          }
        });

        return mapTooltipProperties;
      })
    );
  }

  validateLayersParameters(
    mapParameters: MapParameters,
    zoneLayers: IGisLayerDto[]
  ): MapParameters {
    if (!zoneLayers?.length || !mapParameters.visibleThematicsIds?.length) {
      return mapParameters;
    }

    const zoneLayerIds = zoneLayers.map((layer) => layer.gisLayerId);

    // Thematic layers have priority over zone layers when coming from parameters
    mapParameters.visibleLayersIds = mapParameters.visibleLayersIds.filter(
      (layerId) => !zoneLayerIds.includes(layerId)
    );

    return mapParameters;
  }

  getHeNavItemsConfigurations(hierarchyElementId: string): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.DistributionNetwork,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.TransmissionNetwork,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.Prioritisation,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.ActivityRaising,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.Activities,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.Leaks,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.PressureMonitoring,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.CustomerDetails,
        validationType: 'none',
        paramType: 'none',
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'none',
        paramType: 'none',
        customNavMethod: (navigateSettings: WNavigateSettings) =>
          this.onManageTemplatesAndWorkspaces(
            hierarchyElementId,
            EntityTypes.hierarchyElement,
            navigateSettings
          ),
      },
      {
        key: NavKeys.NetworkElementDetails,
        validationType: 'none',
        paramType: 'none',
      },
    ];
  }

  getNeNavItemsConfigurations(neTypeId: string, neId: string): NavItemsConfiguration[] {
    // Customers can only navigate to the customer details page
    if (this.isCustomerRelated(neTypeId)) {
      return [
        {
          key: NavKeys.CustomerDetails,
          validationType: 'none',
          paramType: 'none',
        },
      ];
    }

    let navigations = [];

    navigations.push({
      key: NavKeys.DataVisualization,
      validationType: 'none',
      paramType: 'none',
      customNavMethod: (navigateSettings: WNavigateSettings) =>
        this.onManageTemplatesAndWorkspaces(neId, EntityTypes.networkElement, navigateSettings),
    });

    if (this.canNavigateToTransmissionNetwork(neTypeId)) {
      navigations.push({
        key: NavKeys.TransmissionNetwork,
        validationType: 'custom',
        customValidation: neId === undefined,
        paramType: 'none',
      });
    }

    if (this._neTypesLayerIds.some((layerId) => layerId === +neTypeId)) {
      navigations.push({
        key: NavKeys.NetworkElementDetails,
        validationType: 'custom',
        customValidation: neId === undefined,
        paramType: 'none',
      });
    }

    return navigations as NavItemsConfiguration[];
  }

  getLeaksNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Leaks,
        validationType: 'none',
        paramType: 'none',
      },
    ];
  }

  getActivitiesNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Activities,
        validationType: 'none',
        paramType: 'none',
      },
    ];
  }

  isCustomerRelated(neTypeId: string): any {
    return this._customerRelatedLayers.some((l) => l === neTypeId);
  }

  isThematicZone(gisLayerId: number): any {
    return this._thematicZoneLayers.some((l) => l === gisLayerId);
  }

  getFiltrableYearValue(year: number): number {
    if (this._filtrableLeakYears.some((ly) => ly.year === year)) {
      return year - this._currentFiscalYear;
    }

    return -this._currentFiscalYear;
  }

  getCenterFromGeometry(coordinates: any): [number, number] {
    const center = [coordinates[0], coordinates[1]];

    if (Array.isArray(coordinates[0]) && Array.isArray(coordinates[1])) {
      return [center[0][0], center[0][1]];
    }

    return center as [number, number];
  }

  getHeLevelLayerById(heId: string): Observable<number> {
    const heps$ = this._globalsService.getHierarchyElementPaths();
    const layerMap$ = this.getHeLevelLayerMapping();

    return combineLatest([heps$, layerMap$]).pipe(
      map(([heps, layers]) => {
        const heType = heps.find((x) => x.descendant == heId);
        if (!heType) {
          return;
        }
        const layerId = layers.get(heType.descendantHierarchyElementTypeId.toLowerCase());
        return layerId;
      })
    );
  }

  getHeLevelLayersByIds(heIds: string[]): Observable<number[]> {
    const layers$: Observable<number>[] = [];
    heIds.forEach((heId) => {
      layers$.push(this.getHeLevelLayerById(heId));
    });

    return combineLatest(layers$).pipe(
      map((layers) => {
        if (!layers.length) {
          return [];
        }
        const heLayers = asEnumerable(layers)
          .Where((x) => x !== null && x !== this._companyGisLayerId)
          .Distinct()
          .ToArray();
        return heLayers;
      })
    );
  }

  getHeLevelLayerMapping(): Observable<Map<string, number>> {
    const heLevels$ = this._globalsService.getHierarchyRelationLevels();
    const neTypes$ = this._globalsService.getNetworkElementTypes();

    return combineLatest([heLevels$, neTypes$]).pipe(
      map((heNeTypesData) => {
        const heLevels = heNeTypesData[0];
        const neTypes = heNeTypesData[1];

        return new Map(
          heLevels.map((m) => {
            return [
              m.hierarchyElementTypeId.toLocaleLowerCase(),
              neTypes.find((net) => net.networkElementTypeId === m.networkElementTypeId)
                ?.gisLayerId,
            ];
          })
        );
      })
    );
  }

  getWlmElementTypes(): Observable<WlmElementType[]> {
    const heLevels$ = this._globalsService.getHierarchyRelationLevels();
    const neTypes$ = this._globalsService.getNetworkElementTypes();

    return combineLatest([heLevels$, neTypes$]).pipe(
      map(([heNeTypesData, neTypes]) => {
        return heNeTypesData.map((heType) => {
          const neType = neTypes.find(
            (f) => f.networkElementTypeId === heType.networkElementTypeId
          );
          return new WlmElementType({
            hierarchyElementTypeId: heType.hierarchyElementTypeId,
            networkElementTypeId: neType.networkElementTypeId,
            gisLayerId: neType.gisLayerId,
          });
        });
      })
    );
  }

  //Save persisted data to DB
  persistMapSettings(settings: MapSettings, settingArea: string, settingKey: string) {
    if (!settingArea || !settingKey || !settings) {
      return;
    }

    const dynamicSettings = new DynamicSettingsSave({
      settingArea,
      settingKey,
      settingValue: JSON.stringify(settings),
      saveAsDefault: false,
      componentTypeId: SettingsComponentType.Map,
      createComponentIfNoExists: true,
    });

    this._dynamicSettingsService.saveDynamicSettings(dynamicSettings).subscribe({
      next: () => { },
    });
  }

  //Get persisted data from DB
  getPersistedMapSettings(settingArea: string, settingKey: string): Observable<any> {
    const settings = new DynamicSettings({
      settingArea,
      settingKey,
    });

    return this._dynamicSettingsService.loadDynamicSettings(settings);
  }

  //Set persisted data to local/session storage
  persistMapSettingsLocally(settings: MapSettings, settingKey: string, useLocalStorage = true) {
    this._localStorageService.addOrUpdate(`${settingKey}`, settings, useLocalStorage);
  }

  //Get persisted data from local/session storage
  getPersistedData(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    return this._localStorageService.getTyped(key, defaultValue, useLocalStorage);
  }

  getDatedLayerIdsByYearFilters(layerId: number, years: number[]): number[] {
    let layerIds = [];

    years.forEach((year) => {
      const datedLayerId = this.getDatedLayerIdByYearFilter(layerId, year);
      if (datedLayerId) {
        layerIds.push(datedLayerId);
      }
    });

    return layerIds;
  }

  getDatedLayerIdByYearFilter(layerId: number, leakYear: number): number {
    if (!this._filtrableLeakYears?.length) {
      return;
    }

    const index = -leakYear;
    const year = this._filtrableLeakYears[index]?.year;

    if (!year) {
      return layerId;
    }

    const yearFormatted = (year % 100).toString().padStart(2, '0');
    return +`${layerId}${yearFormatted}`;
  }

  onManageTemplatesAndWorkspaces = (
    elementId: string,
    entityTypeId: EntityTypes,
    navigateSettings: WNavigateSettings
  ): void => {
    const element = new WlmElementExtended(elementId, null, null, null, null, entityTypeId);

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(navigateSettings, [
      element,
    ]);
  };

  private getNeTooltipPropertyFromFieldsDefinition(
    neAttributeTypeId: string,
    value: string
  ): MapTooltipProperty {
    const fieldKey = neAttributeTypeId.replace(
      this._neAttributesPrefix,
      `${SettingsDataSource.NetworkElementAttribute}-`
    );
    const field = this._neAttributeFields[fieldKey];

    if (field?.display == FieldDisplayType.None) {
      return;
    }

    //In case the field doesn't exists in the attribute json, look for the ne attribute name
    if (!field) {
      const neTypeIdSplitted = neAttributeTypeId.split('-');

      if (neTypeIdSplitted?.length <= 1) {
        return;
      }

      const neTypeId = neTypeIdSplitted[1];
      const attributeName = this._neAttributeTypes.find(
        (x) => x.networkElementAttributeTypeId == +neTypeId
      );

      if (!attributeName) {
        return;
      }

      let neToolProperty = new MapTooltipProperty({
        key: neAttributeTypeId,
        label: attributeName.networkElementAttributeTypeName,
        value: value,
      });

      return neToolProperty;
    }

    let neToolProperty = new MapTooltipProperty({
      key: neAttributeTypeId,
      label: field.name,
      labelKey: field.labelKey,
      value: value,
    });

    const { dimensionTypeId, timeAggregationId, unitTypeId } = field;
    if (dimensionTypeId && timeAggregationId) {
      this._uomService
        .getByParams(dimensionTypeId, timeAggregationId)
        .pipe(untilDestroyed(this))
        .subscribe((uomFactor) => {
          neToolProperty.value = this._uomService.getConvertedValue(uomFactor, +value, 2);
        });
    } else if (unitTypeId) {
      this._uomService
        .getUnitName(unitTypeId)
        .pipe(untilDestroyed(this))
        .subscribe((uomName) => {
          if (uomName) {
            neToolProperty.value = `${value} ${uomName}`;
          }
        });
    }

    if (field.dataType === FieldDataType.DateTime) {
      const date = this._dateHelperService.fromApiFormat(value);
      const dateIsValid = this._dateHelperService.isDateObject(date);

      if (dateIsValid) {
        const formattedDate = this._localizationHelperService.getLocalizedDateFromApi(
          date,
          DateFormats.Date
        );
        neToolProperty.value = formattedDate ? formattedDate : value;
      }
    }

    return neToolProperty;
  }

  private getUoMProperty(key: string, value: string, uomProperty: any): MapTooltipProperty {
    if (uomProperty) {
      this._uomService
        .getByParams(
          uomProperty.dimensionTypeId,
          uomProperty.timeAggregationId,
          uomProperty.hierarchyElementTypeId
        )
        .subscribe({
          next: (uomFactor) => {
            value = this._uomService.getConvertedValue(uomFactor, +value, this._uomDecimals);
          },
        });
    }

    return new MapTooltipProperty({
      key,
      labelKey: key,
      value: value,
    });
  }

  private canNavigateToTransmissionNetwork(neTypeId: string): boolean {
    return this._transmissionRelatedLayers.some((l) => l === neTypeId);
  }

  private getAlgorithmConversionValues(
    heTypeId: string,
    properties: { [key: string]: string }
  ): Observable<Map<string, UnitTypeConversionViewDto>> {
    const algorithms = Object.keys(properties)
      .filter((f) => f.startsWith(this._algorithmsPrefix))
      .map((m) => m.replace(this._algorithmsPrefix, ''));

    if (!algorithms.length) {
      return of(null);
    }

    let conversionInfo$ = [];
    algorithms.forEach((algorithm) => {
      conversionInfo$.push(this._uomService.getByAlgorithmShortName(algorithm, heTypeId));
    });

    return combineLatest(conversionInfo$).pipe(
      map((conversionInfo: UnitTypeConversionViewDto[]) => {
        const conversionMap = new Map<string, UnitTypeConversionViewDto>();

        algorithms.forEach((algorithm, index) => {
          conversionMap.set(algorithm, conversionInfo[index]);
        });

        return conversionMap;
      })
    );
  }

  private setCurrentFiscalYear(appAttributes: IApplicationAttributeDto[]) {
    const fiscalYearStartDay = appAttributes.find(
      (att) => att.attributeId === ApplicationAttributes.FiscalYearStartday
    )?.attributeValue;
    const fiscalYearStartMonth = appAttributes.find(
      (x) => x.attributeId === ApplicationAttributes.FiscalYearStartMonth
    )?.attributeValue;

    if (!fiscalYearStartDay || !fiscalYearStartMonth) {
      this._currentFiscalYear = new Date().getFullYear();
      return;
    }

    this._currentFiscalYear = this._dateHelperService.getCurrentFiscalYear(
      +fiscalYearStartDay,
      +fiscalYearStartMonth
    );
  }
}
