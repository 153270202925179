import { ChangeDetectorRef, ViewRef, inject } from '@angular/core';
import { Observable, concat } from 'rxjs';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { GetValueSelector } from '../../dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { wlmTakeUntilDestroyed } from '../observables/custom-take-until-destroyed';
import { IBaseComponent } from './base-component.interface';

export class TabPanelFields {
  static readonly ParamsKeys = 'TabPanelParams';
}

/**
 * Adds fields that are useful for using the component as a widget, like inside in a GL tab.
 * This must be used instead of component.name, as this will break in production.
 */

export abstract class BaseWidgetComponent
  extends BaseDynamicWidgetComponent
  implements IBaseComponent
{
  loading = false;
  private readonly _viewRef: ViewRef = inject(ChangeDetectorRef) as ViewRef;

  abstract mapInitParameters(parameters: TabDetailPanelParameters);
  abstract init(): void;

  onWidgetInit(): void {
    // Concat makes the observables emit in sequence (do not listen to params until firstActive is completed).
    concat(this.isFirstActive$, this.selectTabPanelParams$())
      .pipe(wlmTakeUntilDestroyed(this._viewRef))
      .subscribe((serialized: TabDetailPanelParameters) => {
        if (serialized) {
          this.setNavigationParameters(serialized);
          this._cd.detectChanges();
        }
      });
  }

  setNavigationParameters(params: TabDetailPanelParameters) {
    this.mapInitParameters(params);
    this.init();
  }

  startLoading(): void {
    this.loading = true;
  }

  endLoading(): void {
    this.loading = false;
  }

  private selectTabPanelParams$(): Observable<TabDetailPanelParameters> {
    return this._state.select<TabDetailPanelParameters>(
      new GetValueSelector({
        fieldName: TabPanelFields.ParamsKeys,
      })
    );
  }
}
