export enum WaterBalanceKPIEnum {
  SystemInputVolume = 0,
  AuthorizedConsumption = 1,
  WaterLosses = 2,
  BilledAuthorizedConsumption = 3,
  UnbilledAuthorizedConsumption = 4,
  AparentLosses = 5,
  RealLosses = 6,
  BilledMeteredConsumption = 7,
  BilledUnmeteredConsumption = 8,
  UnbilledMeteredConsumption = 9,
  UnbilledUnmeteredConsumption = 10,
  UnauthorizedConsumption = 11,
  CustomerMeteringInaccuracies = 12,
  DataHandlingErrors = 13,
  LeakageTransmissionMains = 14,
  LeakageDistributionNetwork = 15,
  LeakageOverflowsUtilityStorageTanks = 16,
  RevenueWater = 17,
  NonRevenueWater = 18,
  WaterLossPerMainLength = 19,
  WaterLossPercentageDI = 21,
  WaterLossPerConnection = 20,
  RealLossPerMainLength = 22,
  RealLossPerConnection = 23,
  RealLossPercentageDI = 24,
  ApparentLossPerMainLength = 25,
  ApparentLossPerConnection = 26,
  ApparentLossPercentageDI = 27,
  HydraulicPerformance = 28,
  ILI = 29,
  CARL = 30,
  UARL = 31
}
