<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  class="filters"
  [provideAdapters]="provideAdapters"
  [filtersOverrides]="filtersOverrides"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
  (clearAll)="onGlobalClearAll()"
>
  <div class="filters filters-background">
    <div class="target-bf" *ngIf="enableAdditionalFilters">
      <wlm-base-filter
        [settings]="targetBaseSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-element-target-filter-item
          [fieldName]="algorithmFilterConfiguration.targetSettings.fieldName"
          [filterText]="filterText"
          [settings]="targetItemSettings"
          [selectedIds]="algorithmFilterConfiguration.targetSettings.selectedIds"
          [defaultSelectedIds]="algorithmFilterConfiguration.targetSettings.defaultValue"
        ></wlm-element-target-filter-item>
      </wlm-base-filter>
    </div>

    <div class="aggregation-bf margin-center" *ngIf="enableAdditionalFilters">
      <wlm-base-filter
        [settings]="aggregationBaseSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-time-aggregation-filter-item
          [fieldName]="algorithmFilterConfiguration.timeAggregationSettings.fieldName"
          [filterText]="filterText"
          [settings]="aggregationItemSettings"
          [allowedIds]="allowedTimeAggregations"
          [selectedIds]="algorithmFilterConfiguration.timeAggregationSettings.selectedIds"
          [defaultSelectedIds]="algorithmFilterConfiguration.timeAggregationSettings.defaultValue"
        ></wlm-time-aggregation-filter-item>
      </wlm-base-filter>
    </div>

    <div class="algorithm-bf">
      <wlm-base-filter
        [settings]="algorithmBaseSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-algorithm-filter-item
          [filterText]="filterText"
          [(treeSettings)]="treeSettings"
          [settings]="algorithmItemSettings"
          [initialSelectedKeys]="algorithmFilterConfiguration?.algorithmSettings.selectedIds"
          [query]="algorithmQuery"
        ></wlm-algorithm-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
