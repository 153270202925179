import { RippleGlobalOptions } from '@angular/material/core';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';

export const materialFormFieldDefaults: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
  subscriptSizing: 'fixed',
};

export const materialGlobalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};
