import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { necSelectNetworkElement } from '../../helpers/select-network-element';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-elements-network-elements-widget';
export const NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-elements-network-elements-widget.component.html',
  styleUrls: ['./ne-configuration-elements-network-elements-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigurationElementsNetworkElementsWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  currentNE: INetworkElementDto;
  pageId: string;
  widgetId: string;

  T_SCOPE = `${AppModules.Configuration}.common`;

  titleKey = `${this.T_SCOPE}.configuration-not-available-title`;
  subtitleKey = `${this.T_SCOPE}.configuration-not-available-subtitle`;

  get componentName(): string {
    return 'NeConfigurationElementsNetworkElementsWidgetComponent';
  }

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.widgetId = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => (this.currentNE = selectedNE)
    );
  }
}
