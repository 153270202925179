<div *ngIf="!settings?.disableFilter" class="search-container hide-subscript">
  <mat-form-field
    class="filter-width custom-search-field custom-search-field-size"
    appearance="outline"
  >
    <mat-label>{{ 'common.search' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="filterValue"
      (input)="filterList($event)"
      (keydown)="onKeyDownEvent($event)"
      autocomplete="off"
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
<div class="drag-list-container">
  <cdk-virtual-scroll-viewport
    tabIndex="0"
    class="scroll-wrapper"
    [itemSize]="height"
    [cdkDropListData]="items"
    [id]="dragListId"
    [cdkDropListDisabled]="settings.isReadOnly"
    (keydown.ArrowDown)="navigateByItems($event, 'Down')"
    (keydown.ArrowUp)="navigateByItems($event, 'Up')"
    cdkDropList
  >
    <div
      *cdkVirtualFor="let item of items; let i = index"
      cdkDrag
      #card
      [class]="
        settings.disableSelection
          ? 'no-selectable-list'
          : 'list ' + (isSelected(item) ? 'selected' : '')
      "
      [cdkDragData]="i"
      [cdkDragDisabled]="true"
      (click)="onClickItem(item)"
    >
      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
