import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarDto } from 'src/app/common-modules/dependencies/shared/model/calendar.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { CalendarService } from '../../service/calendar.service';

const COMPONENT_SELECTOR = 'wlm-calendar-creation-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-creation-popup.component.html',
  styleUrls: ['./calendar-creation-popup.component.scss'],
})
export class CalendarCreationPopupComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isLoading = false;

  isEditMode: boolean;
  selectedCalendar: CalendarDto;

  nameFieldName = 'calendarName';
  descriptionFieldName = 'calendarDescription';

  hasValidChanges = false;

  editedCalendar: CalendarDto;

  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) { isEditMode, selectedCalendar }: any,
    private readonly _dialogRef: MatDialogRef<CalendarCreationPopupComponent>,
    private readonly _calendarService: CalendarService,
    private readonly _dialogService: DialogService,
    private readonly _formBuilder: FormBuilder
  ) {
    this.isEditMode = isEditMode;
    this.selectedCalendar = selectedCalendar;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  onHasValidChanges(hasValidChanges: boolean) {
    this.hasValidChanges = hasValidChanges;
  }

  private initializeForm() {
    const formControls: { [key: string]: FormControl } = {};
    const calendarName = this.selectedCalendar?.calendarName ?? '';
    formControls[this.nameFieldName] = new FormControl(calendarName, [Validators.required]);

    const calendarDescription = this.selectedCalendar?.calendarDescription ?? null;
    formControls[this.descriptionFieldName] = new FormControl(calendarDescription);

    this.form = this._formBuilder.group(formControls);

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.onFormValueChanges());
  }

  cancel(): void {
    this._dialogRef.close();
  }

  closeSuccess(): void {
    this._dialogRef.close(true);
  }

  save(): void {
    this.isLoading = true;
    if (!this.isEditMode) {
      this.createCalendar();
    } else {
      this.editCalendar();
    }
  }

  private createCalendar() {
    const newCalendar = new CalendarDto({
      ...this.form.getRawValue(),
    });

    this._calendarService.createCalendar(newCalendar).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.showSuccessMessage('messages.creation-success');
        this.closeSuccess();
      },
      error: () => {
        this.isLoading = false;
        this.showErrorMessage('messages.creation-error');
        this.cancel();
      },
    });
  }

  private editCalendar() {
    const editedCalendar = new CalendarDto({
      ...this.form.getRawValue(),
      calendarId: this.selectedCalendar.calendarId,
    });

    this._calendarService.editCalendar(editedCalendar).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.showSuccessMessage('messages.edit-success');
        this.closeSuccess();
      },
      error: () => {
        this.isLoading = false;
        this.showErrorMessage('messages.edit-error');
        this.cancel();
      },
    });
  }

  private showErrorMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: 'error',
    });
    this._dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private showSuccessMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: 'success',
    });
    this._dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private onFormValueChanges() {
    this.hasValidChanges = this.form.valid && this.form.dirty;
  }
}
