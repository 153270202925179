import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { AlgorithmFiltrableItemDto } from '../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { SignalFiltrableItemDto } from '../../shared/model/filtrable-items/signal-filtrable-item.dto';

export class DataVisualizationCartSettings {
  dateRange: DateRange;
  algorithms: AlgorithmFiltrableItemDto[] = [];
  points: SignalFiltrableItemDto[] = [];
  itemsSelection: { [id: string]: boolean } = {};

  constructor(init: Partial<DataVisualizationCartSettings>) {
    Object.assign(this, init);
  }
}
