import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WlmNavigationService } from 'src/app/common-modules/dependencies/navigation/wlm-navigation.service';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { DynamicSettingsService } from 'src/app/common-modules/shared/config/dynamic-settings.service';
import { NavigationsConstants } from 'src/app/common-modules/shared/constants/navigation-constants';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';

@UntilDestroy()
@Component({
  selector: 'wlm-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends BasePageComponent implements OnInit {
  get titleTranslationKey(): string {
    return '';
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'HomePageCrud';
  }
  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  componentArea = 'HomePage';
  componentKey = 'DefaultPage';

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _settingService: DynamicSettingsService,
    private readonly _router: Router,
    private readonly _navService: WlmNavigationService,
    private readonly _logService: LogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setLoading(true);
    this.getUserHomePage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (config) => {
          this.setLoading(false);

          const homeKey = config?.homePage
            ? config.homePage
            : NavigationsConstants.defaultHomeNavKey;

          const url = this._navService.getNavItemUrl(homeKey as NavKeys);
          this._router.navigate([url]);
        },
        error: (error) => {
          this._logService.error({
            msg: `Error saving home page configuration`,
            payload: error,
          });
          this.setLoading(false);
        },
      });
  }

  getUserHomePage(): Observable<any> {
    const dataSetting = new DynamicSettings({
      settingArea: this.componentArea,
      settingKey: this.componentKey,
    });
    return this._settingService.loadDynamicSettings(dataSetting);
  }

  private setLoading(loading: boolean): void {
    this._spinnerService.setLoading(loading, this.pageCrud);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}
}
