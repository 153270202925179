import { Component, OnInit } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NotificationRelatedComponent } from 'src/app/common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-disable';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DynamicGridSettings } from 'src/app/common-modules/shared/model/grid/dynamic-grid-settings';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { AuditAttributesWidgetComponent } from '../components/widgets/audit-attributes-widget/audit-attributes-widget.component';
import { IAudit } from '../models/audit';

const COMPONENT_SELECTOR = 'wlm-audits-page';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './audits-page.component.html',
  styleUrls: ['./audits-page.component.scss'],
})
export class AuditsPageComponent extends BasePageComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMAuditsCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  get componentName(): string {
    return 'AuditsPageComponent';
  }

  grid: GenericGridComponent;
  dynamicGridSettings: DynamicGridSettings;
  additionalFilters: Map<string, any>;
  emptyFilters: DataBindingFilters = new DataBindingFilters();
  gridSettings: IGridSettings;
  gridName = 'Audits';

  reloadGrid$ = new Subject<void>();
  removeSelection$ = new Subject<void>();
  removeSelectionPersisted$ = new Subject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();

  selectedAudit: IAudit;

  constructor(
    private _localizationHelperService: LocalizationHelperService,
    private _authService: AuthorizeService
  ) {
    super();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  ngOnInit(): void {
    super.ngOnInit();

    this.setRightPanel();
    this.initGridSettings();
  }

  getSelectedElement(selectedAudit: IAudit) {
    this.selectedAudit = selectedAudit;

    this.sendParameters(selectedAudit);
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  private sendParameters(selectedAudit: IAudit) {
    const parameters = new TabDetailPanelParameters();

    if (selectedAudit) {
      const formattedDate = this._localizationHelperService.getLocalizedDateFromApi(
        selectedAudit.auditTimestamp,
        DateFormats.DateTime,
        true
      );

      parameters.addParameter(
        TabDetailParameterName.elementName,
        `${formattedDate} - ${selectedAudit.auditUserCode}`
      );
    }

    parameters.addParameter(TabDetailParameterName.audit, selectedAudit?.auditId);
    parameters.addParameter(TabDetailParameterName.notification, selectedAudit);

    this.rightPanelService.setTabParameters(parameters);
  }

  private initGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      gridSettingName: this.gridName,
      persistencyArea: this.persistencyArea,
      usePersistence: true,
      type: 'generic',
      disableAutoLoad: false,
    });
  }

  private setRightPanel(): void {
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);

    combineLatest([notificationPermission$, localization$]).subscribe(
      ([notificationPermission, ts]) => {
        const panelSettings = new TabDetailPanelSettings();

        panelSettings.addComponent(AuditAttributesWidgetComponent, ts['attributes']);

        if (notificationPermission) {
          panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
        }

        this.rightPanelService.setTabSettings(panelSettings);
      }
    );
  }
}
