import { GChartTextStyles } from './g-chart-text-styles';

export class GChartDataZoom {
  id?: string;
  type?: 'inside' | 'slider' = 'slider';
  show? = true;
  // The start percentage of the window out of the data extent, in the range of 0 ~ 100.
  start?: number;
  startValue?: number | string | Date;
  // The end percentage of the window out of the data extent, in the range of 0 ~ 100.
  end?: number;
  endValue?: number | string | Date;
  // The index of the associated X axis/axes.
  xAxisIndex?: number | number[] | string;
  // The index of the associated Y axis/axes.
  yAxisIndex?: number | number[] | string;
  filterMode?: 'filter' | 'weakFilter' | 'empty' | 'none';
  height?: number;
  minSpan?: number;
  width?: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  handleIcon?: string;
  handleSize?: number | string;
  moveHandleSize?: any;
  showDetail?: boolean;
  brushSelect?: boolean;
  zoomOnMouseWheel?: boolean;
  moveOnMouseMove?: boolean;
  zoomLock?: boolean;
  brushStyle?: any;
  labelFormatter?: string | Function;
  textStyle?: GChartTextStyles;
  rangeMode?: any[];

  constructor(init: Readonly<GChartDataZoom>) {
    Object.assign(this, init);
  }
}
