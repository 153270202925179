<div class="map-tooltip">
  <div *ngIf="model" class="map-tooltip-container">
    <div *ngIf="model" class="map-tooltip-header">
      <h3 class="map-tooltip-title">{{ model.title }}</h3>
      <ng-content select="[paginator]"></ng-content>
    </div>
    <table aria-describedby="properties">
      <thead></thead>
      <tbody>
        <tr *ngFor="let property of model.properties" class="property-row">
          <th [id]="property.key">
            <span *ngIf="property.labelKey$ !== undefined && !property?.categoryKey">
              {{ property.labelKey$ | async }}
            </span>
            <span *ngIf="property.labelKey$ !== undefined && property?.categoryKey">
              {{ property.categoryKey | translate }} - {{ property.labelKey$ | async }}
            </span>
            <span *ngIf="property.labelKey && !property?.categoryKey">{{
              T_SCOPE + '.' + property.labelKey | translate
            }}</span>
            <span *ngIf="property.labelKey && property?.categoryKey">
              {{ property.categoryKey | translate }} - {{ property.labelKey | translate }}</span
            >
            <span *ngIf="!property.labelKey">{{ property.label }}</span>
          </th>
          <td>
            {{ property.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="model?.navigations?.length">
    <hr />
    <div class="navigation-title">
      {{ T_SCOPE + '.navigations' | translate | uppercase }}
    </div>
    <div class="item">
      <div *ngFor="let navigation of model.navigations">
        <button
          mat-icon-button
          [disabled]="navigation.disabled"
          (click)="onClickNavigation($event, navigation)"
          [matTooltip]="navigation.title"
        >
          <mat-icon
            *ngIf="!navigation.isSvg"
            [ngClass]="{ 'link-icon-disabled': navigation.disabled }"
            class="link-icon material-icons-outlined"
          >
            {{ navigation.svgIcon }}
          </mat-icon>
          <mat-icon
            *ngIf="navigation.isSvg"
            [ngClass]="{ 'link-icon-disabled': navigation.disabled }"
            class="link-icon"
            [svgIcon]="navigation.svgIcon"
          ></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
