import { Component, Inject, Injector } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { necSelectNetworkElement } from '../../features/ne-configuration/helpers/select-network-element';
import { SmartMetersExternalSettings } from '../models/smart-meters-external-settings';
import { SMART_METERS_EXTERNAL_SETTINGS } from '../smart-meters-tokens';

const COMPONENT_SELECTOR = 'wlm-smart-meters-config-widget';
export const SMART_METERS_CONFIG_DEFAULT_KEY = COMPONENT_SELECTOR;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meters-config-widget.component.html',
  styleUrls: ['./smart-meters-config-widget.component.scss'],
})
export class SmartMetersConfigWidgetComponent extends BaseDynamicWidgetComponent {
  selectedNE: INetworkElementDto;
  pageId: string;

  // Defaults, can be overriden by external settings.
  private _scopeSettings = new StateScopeSettings({
    scope: 'shared',
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    @Inject(SMART_METERS_EXTERNAL_SETTINGS) private _externalSettings: SmartMetersExternalSettings
  ) {
    super(injector, widgetSettings);
    this._scopeSettings = this._externalSettings.scopeSettings;
    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => (this.selectedNE = selectedNE)
    );
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }
}
