import { Injectable } from '@angular/core';
import { LayoutNodeTypes } from '../models/layout-node-types';

@Injectable()
export class LayoutTemplatesService {
  buildMasterDetailLayout(instanceKeys: {
    filterWidget?: string;
    selectorWidgets: string[];
    receptorWidgets: string[];
  }): any {
    const content = [];

    if (instanceKeys.filterWidget) {
      const filterLayout = {
        type: LayoutNodeTypes.Stack,
        height: 15,
        content: [
          {
            type: LayoutNodeTypes.Component,
            widgetInstanceKey: instanceKeys.filterWidget,
          },
        ],
      };
      content.push(filterLayout);
    }

    const restOfLayout = {
      type: LayoutNodeTypes.Row,
      content: [
        {
          type: LayoutNodeTypes.Stack,
          // height: 85,
          width: 25,
          content: instanceKeys.selectorWidgets.map((selectorKey) => ({
            type: LayoutNodeTypes.Component,
            widgetInstanceKey: selectorKey,
          })),
        },
        {
          type: LayoutNodeTypes.Stack,
          // height: 85,
          width: 75,
          content: instanceKeys.receptorWidgets.map((selectorKey) => ({
            type: LayoutNodeTypes.Component,
            widgetInstanceKey: selectorKey,
          })),
        },
      ],
    };

    content.push(restOfLayout);

    const layout = [
      {
        type: LayoutNodeTypes.Column,
        content,
      },
    ];

    return layout;
  }
}
