<div id="center-page">
  <div class="top-container">
    <h3>{{ titleTranslationKey | translate }}</h3>
  </div>
  <hr class="separator" />

  <div id="center-container">
    <wlm-zone-algorithm-grid
      [gridSettings]="gridSettings"
      (selectionChange)="onSelectionChange($event)"
    >
    </wlm-zone-algorithm-grid>
    <div class="buttons-container">
      <div [matTooltip]="navigateTooltip | translate: { value: maxItemsToNavigate } | lowercase">
        <button
          mat-raised-button
          color="primary"
          (click)="navigate()"
          [disabled]="!selection?.size || maxItemsExceeded"
        >
          {{ navigateBtnTranslationKey | translate }}
        </button>
      </div>

      <button mat-raised-button (click)="cancel()">
        {{ 'common.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
