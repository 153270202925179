import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { ElementTargetsEnum } from 'src/app/common-modules/shared/model/shared/element-targets.enum';

export class AlgorithmFilterItemQuery {
  targetIds?: ElementTargetsEnum[];
  timeAggregationIds?: TimeAggregationEnum[];

  constructor(init: AlgorithmFilterItemQuery) {
    Object.assign(this, init);
  }
}
