<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [grid]="userGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <span *isSuperUser="">
          <button
            wlmCollapsibleButton
            *ngIf="!selectedUser || (selectedUser && !selectedUser.isSuperUser)"
            [disabled]="!selectedUser || selectedUser?.isSuperUser"
            [tooltip]="T_SCOPE + '.set-super-user'"
            [showLabel]="isContainerCollapsed"
            (click)="warnToggleSuperUser()"
          >
            <mat-icon class="material-icons-round" svgIcon="set-as-super-user"></mat-icon>
          </button>

          <button
            wlmCollapsibleButton
            *ngIf="selectedUser && selectedUser.isSuperUser"
            [tooltip]="T_SCOPE + '.remove-super-user'"
            [showLabel]="isContainerCollapsed"
            (click)="warnToggleSuperUser()"
          >
            <mat-icon class="material-icons-round" svgIcon="desactivate-super-user"></mat-icon>
          </button>
        </span>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'c']"
          [tooltip]="T_SCOPE + '.create-user'"
          [showLabel]="isContainerCollapsed"
          (click)="showCreateUserPopup()"
        >
          <mat-icon svgIcon="create-user"></mat-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'u']"
          [disabled]="!selectedUser || selectedUser?.isActive"
          [tooltip]="T_SCOPE + '.activate-user'"
          [showLabel]="isContainerCollapsed"
          (click)="reactivateUser()"
        >
          <mat-icon class="material-icons-round" svgIcon="reactivate-user"></mat-icon>
        </button>

        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'u']"
          [disabled]="!this.selectedUser?.isActive"
          [tooltip]="T_SCOPE + '.remove-user'"
          [showLabel]="isContainerCollapsed"
          (click)="deleteSelectedUser()"
        >
          <mat-icon class="material-icons-round" svgIcon="desactivate-user"></mat-icon>
        </button>

        <button
          wlmCollapsibleButton
          [tooltip]="T_SCOPE + '.activity-chart'"
          [showLabel]="isContainerCollapsed"
          (click)="showActivityChart()"
        >
          <mat-icon svgIcon="activity-chart"></mat-icon>
        </button>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      #userGrid
      class="generic-grid-no-filter"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      (selectedItemChanged)="onSelectedUser($event)"
      [subscriptionTag]="gridName"
    >
    </wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
