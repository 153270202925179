import { Component, Input, OnInit } from '@angular/core';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';

const COMPONENT_SELECTOR = 'wlm-date-duration-column-value';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-duration-column-value.component.html',
  styleUrls: ['./date-duration-column-value.component.scss'],
})
export class DateDurationColumnValueComponent implements OnInit {
  @Input() startDateFieldName: string;
  @Input() endDateFieldName: string;

  private _dataItem: any;
  public get dataItem(): any {
    return this._dataItem;
  }
  @Input() public set dataItem(v: any) {
    this._dataItem = v;
    this.processData();
  }

  durationValue: number;

  constructor(private _dateHelper: DateHelperService) {}

  ngOnInit(): void {}

  processData() {
    if (this.dataItem) {
      this.durationValue = this.getDuration(this.dataItem);
    } else {
      this.durationValue = undefined;
    }
  }

  private getDuration(dataItem: any): number {
    const startDateValue = this._dateHelper.convertUTCStoredDateToLocal(
      new Date(dataItem[this.startDateFieldName])
    );
    const endDateValue = dataItem[this.endDateFieldName]
      ? new Date(dataItem[this.endDateFieldName])
      : undefined;

    const defaultEndDate = this._dateHelper.truncateDate(new Date());

    return this._dateHelper.getDurationInHours(startDateValue, endDateValue ?? defaultEndDate);
  }
}
