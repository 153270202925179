<div *ngIf="hierarchyTypes && form" class="uom-bulk-container custom-small-field">
  <div [formGroup]="form" class="uom-group">
    <div class="uom-component-title top-title-position">{{ T_SCOPE + '.title' | translate }}</div>
    <div class="uom-group-configuration">
      <div class="uom-item">
        <mat-form-field appearance="outline" class="uom-type-select">
          <mat-label>{{ T_SCOPE + '.all' | translate }}</mat-label>
          <mat-select [formControlName]="hierarchyTypeFieldname" required appearance="outline">
            <mat-option *ngFor="let ht of hierarchyTypes" [value]="ht">
              {{ T_SCOPE + '.' + ht | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="uom-unit-select">
          <mat-label>{{ T_SCOPE + '.unit' | translate }}</mat-label>
          <mat-select [formControlName]="unitTypeFieldName" required appearance="outline">
            <mat-option *ngFor="let ut of unitTypes" [value]="ut.unitTypeId">
              {{ ut.unitTypeDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!(form.dirty && form.valid)"
          (click)="onApplyAll()"
        >
          {{ 'common.apply-all' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
