import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ReadonlyNetworkElementTypes } from 'src/app/common-modules/dependencies/he/hierarchy.constants';
import { INetworkElementTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-type.dto';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { INetworkElementTypeValidationDto } from './network-element-type-validation.dto';

@Injectable()
export class NETypesCrudService
  extends BaseService
  implements GenericCrudService<INetworkElementTypeDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/network-element-types`;
  }

  getAll(): Observable<INetworkElementTypeDto[]> {
    return this.httpCacheClient.get<INetworkElementTypeDto[]>(this._entityUrl);
  }

  create(entity: INetworkElementTypeDto): Observable<INetworkElementTypeDto> {
    return this.httpCacheClient.post<INetworkElementTypeDto>(this._entityUrl, entity);
  }

  update(entity: INetworkElementTypeDto): Observable<INetworkElementTypeDto> {
    return this.httpCacheClient.put<INetworkElementTypeDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: INetworkElementTypeDto): number {
    return entity.networkElementTypeId;
  }

  isReadonlyType = (networkyElementTypeId: number) =>
    !!(
      networkyElementTypeId &&
      ReadonlyNetworkElementTypes.find((type) => type === networkyElementTypeId)
    );

  validate(
    entity: INetworkElementTypeDto,
    isCreate?: boolean
  ): Observable<INetworkElementTypeValidationDto> {
    return this.httpCacheClient.post<INetworkElementTypeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
