import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-calendar-configuration';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-configuration.component.html',
  styleUrls: ['./calendar-configuration.component.scss'],
})
export class CalendarConfigurationComponent {
  @Input() calendarId: string;

  isLoading = false;

  reloadDetails$: Subject<void> = new Subject();

  onIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  onExceptionCreated($event) {
    this.reloadDetails$.next();
  }
}
