import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { GenericStoreService } from 'src/app/common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { CommonSharedComponentModule } from 'src/app/common-modules/shared-component/common-shared-component.module';
import { CommonSharedModule } from 'src/app/common-modules/shared/common-shared.module';
import { UomModule } from 'src/app/common-modules/uom/uom.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { AlarmsModule } from '../alarms/alarms.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { NeConfigurationModule } from '../ne-configuration/ne-configuration.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { AlarmActivationsGridComponent } from './alarm-configuration-components/alarm-activations-grid/alarm-activations-grid.component';
import { AlarmActivationsService } from './alarm-configuration-components/alarm-activations-grid/alarm-activations.service';
import { AlarmRelatedElementsComponent } from './alarm-configuration-components/alarm-related-elements/alarm-related-elements.component';
import { AlarmRelatedElementsService } from './alarm-configuration-components/alarm-related-elements/alarm-related-elements.service';
import { AlarmsConfigurationFilterComponent } from './alarm-configuration-components/alarms-configuration-filter/alarms-configuration-filter.component';
import { AlarmsParkingPopupComponent } from './alarm-configuration-components/alarms-parking-popup/alarms-parking-popup.component';
import { ProfileSubscriptionComponent } from './alarm-configuration-components/profile-subscription/profile-subscription.component';
import { AlarmsConfigurationPageComponent } from './alarms-configuration-page/alarms-configuration-page.component';
import { AlarmsConfigurationService } from './alarms-configuration-page/alarms-configuration.service';
import { CalendarPageComponent } from './calendars/calendar-page/calendar-page.component';
import { CalendarConfigurationComponent } from './calendars/components/calendar-configuration/calendar-configuration.component';
import { CalendarCreationPopupComponent } from './calendars/components/calendar-creation-popup/calendar-creation-popup.component';
import { CalendarExceptionCardComponent } from './calendars/components/calendar-exception-card/calendar-exception-card.component';
import { CalendarExceptionCreatorComponent } from './calendars/components/calendar-exception-creator/calendar-exception-creator.component';
import { CalendarExceptionDetailComponent } from './calendars/components/calendar-exception-detail/calendar-exception-detail.component';
import { CalendarSelectorComponent } from './calendars/components/calendar-selector/calendar-selector.component';
import { SeasonSelectorComponent } from './calendars/components/season-selector/season-selector.component';
import { CalendarService } from './calendars/service/calendar.service';
import { SeasonService } from './calendars/service/season.service';
import { CalendarConfigurationWidgetComponent } from './calendars/widgets/calendar-configuration-widget/calendar-configuration-widget.component';
import { CalendarSelectorWidgetComponent } from './calendars/widgets/calendar-selector-widget/calendar-selector-widget.component';
import { SeasonSelectorWidgetComponent } from './calendars/widgets/season-selector-widget/season-selector-widget.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { NotificationConfigurationService } from './notification-configuration.service';
import { PointConfigurationService } from './point-configuration.service';
import { PointConfigurationComponent } from './point-configuration/point-configuration.component';
import { PointCreationPopupComponent } from './point-creation-popup/point-creation-popup.component';
import { PointValidationGroupService } from './point-validation-group.service';
import { PointValidationGroupComponent } from './point-validation-group/point-validation-group.component';
import { ReplaceSignalPopupComponent } from './replace-signal-popup/replace-signal-popup.component';
import { SAWizardPageComponent } from './sa-wizard-page/sa-wizard-page.component';
import { TelemetryPageComponent } from './telemetry-page/telemetry-page.component';
import { TelemetryPointsService } from './telemetry-points.service';

@NgModule({
  declarations: [
    TelemetryPageComponent,
    PointConfigurationComponent,
    PointValidationGroupComponent,
    ReplaceSignalPopupComponent,
    AlarmsConfigurationPageComponent,
    AlarmsConfigurationFilterComponent,
    AlarmsParkingPopupComponent,
    AlarmActivationsGridComponent,
    AlarmRelatedElementsComponent,
    SAWizardPageComponent,
    ProfileSubscriptionComponent,
    PointCreationPopupComponent,
    CalendarPageComponent,
    CalendarSelectorWidgetComponent,
    SeasonSelectorWidgetComponent,
    CalendarConfigurationWidgetComponent,
    CalendarSelectorComponent,
    SeasonSelectorComponent,
    CalendarConfigurationComponent,
    CalendarExceptionCreatorComponent,
    CalendarCreationPopupComponent,
    CalendarExceptionDetailComponent,
    CalendarExceptionCardComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    CommonSharedModule,
    CommonSharedComponentModule,
    WLMGridModule,
    ConfigurationRoutingModule,
    TranslateModule,
    WlmChartsModule,
    WlmFiltersModule,
    AlarmsModule,
    NeConfigurationModule,
    UomModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
  ],
  providers: [
    TelemetryPointsService,
    PointConfigurationService,
    PointValidationGroupService,
    AlarmsConfigurationService,
    AlarmActivationsService,
    AlarmRelatedElementsService,
    NotificationConfigurationService,
    CalendarService,
    SeasonService,
  ],
})
export class ConfigurationModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    ConfigurationModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'CalendarConfigurationWidgetComponent',
        widgetClass: CalendarConfigurationWidgetComponent,
      },
      {
        name: 'CalendarSelectorWidgetComponent',
        widgetClass: CalendarSelectorWidgetComponent,
      },
      {
        name: 'SeasonSelectorWidgetComponent',
        widgetClass: SeasonSelectorWidgetComponent,
      },
      {
        name: 'PointConfigurationComponent',
        widgetClass: PointConfigurationComponent,
      },
      {
        name: 'AlarmActivationsGridComponent',
        widgetClass: AlarmActivationsGridComponent,
      },
      {
        name: 'AlarmRelatedElementsComponent',
        widgetClass: AlarmRelatedElementsComponent,
      },
      {
        name: 'ProfileSubscriptionComponent ',
        widgetClass: ProfileSubscriptionComponent,
      },
    ]);
  }
}
