import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectorField',
})
export class SelectorFieldPipe implements PipeTransform {
  constructor() {}

  transform(fieldValue: string, defaultValue: string): string {
    return fieldValue === undefined ? defaultValue : fieldValue;
  }
}
