<div class="spinner-wrapper"></div>
<div *ngIf="productsVersion && logo && appName" class="splash-container">
  <div class="container">
    <img class="image" alt="Schneider Electric" src="assets/images/splash/default-bg.png" />
    <div class="overlay main-section">
      <img class="logo" [alt]="logo" [src]="'assets/images/splash/' + logo" />
      <h1 class="wtr-headline">{{ appName }}</h1>
      <div class="products-version">
        <span *ngFor="let product of productsVersion | keyvalue; last as isLast">
          {{ localizationScope + '.' + product.key | translate }}:
          {{ product.value ? product.value : '-' }}
          <ng-container *ngIf="!isLast">&nbsp;|&nbsp;</ng-container>
        </span>
      </div>
    </div>
    <div class="overlay secondary-section">
      <span>{{ localizationScope + '.copyright-1' | translate }}</span>
      <span>{{ localizationScope + '.copyright-2' | translate : { year: currentYear } }}</span>
    </div>
  </div>
</div>
