<form>
  <mat-form-field class="hierarchy-family-filter">
    <mat-label #familyLabel class="filter-title">{{ T_SCOPE + '.title' | translate }}</mat-label>
    <mat-select
      #familySelect
      (selectionChange)="familyChange($event)"
      [(value)]="selectedHierarchyFamilyId"
    >
      <mat-option *ngFor="let family of hierarchyFamilies" [value]="family.hierarchyFamilyId">
        {{ family.hierarchyFamilyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
