import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';
import { IAutocompleteSettings } from './autocomplete-settings';

@UntilDestroy()
@Component({
  selector: 'wlm-automplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger)
  private trigger: MatAutocompleteTrigger;
  private debounceTime: 300;
  autocompleteFormGroup = new UntypedFormGroup({ searchBoxControl: new UntypedFormControl() });
  @Input() autocompleteSettings: IAutocompleteSettings;
  @Input() filterValue: string;
  @Output() filterValueChange = new EventEmitter<string>();

  filteredOptions: string[];
  isLoading = false;
  isClearingSelection: boolean;

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.autocompleteSettings.itemSource;
    this.autocompleteFormGroup
      .get('searchBoxControl')
      .valueChanges.pipe(
        tap(() => (this.isLoading = true)),
        debounceTime(this.debounceTime),
        untilDestroyed(this)
      )
      .subscribe((e) => {
        this.isLoading = true;
        this.applyFilter(e);
        this.isLoading = false;
      });
  }

  applyFilter(value: string) {
    if (this.isClearingSelection) {
      this.isClearingSelection = false;
      return;
    }

    this.filterValue = value;
    if (value.length === 0) {
      this.filteredOptions = [];
      this.filterValueChange.emit(this.filterValue);
      this.isLoading = false;
      return;
    }

    this.filteredOptions = [];
    const searchPattern = RegExp(value, 'i');
    this.filteredOptions = this.autocompleteSettings.itemSource.filter((option) =>
      searchPattern.test(option)
    );
    this.isLoading = false;
  }

  onFilterOptionSelected(value: string): void {
    this.filterValue = value;
    this.filterValueChange.emit(this.filterValue);
    this.autocompleteFormGroup.get('searchBoxControl').setValue('');
    this.isClearingSelection = true;
    this.filteredOptions = [];
  }
}
