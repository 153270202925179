import { Inject, Injectable, Optional } from '@angular/core';
import { State } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/local-storage.service';
import { R_STATE_SYNC_SETTINGS } from '../redux-state.module';
import { StateSyncSettings } from './state-sync-settings';
import { StateSyncService } from './state-sync.service';

@Injectable()
export class LocalStorageStateSyncService extends StateSyncService {
  constructor(
    @Optional() @Inject(R_STATE_SYNC_SETTINGS) settings: StateSyncSettings,
    private _localStorageService: LocalStorageService
  ) {
    super(settings);
  }

  sendStateImpl<T>(state: State<T>): void {
    this._localStorageService.addOrUpdate(this.settings.syncKey, state);
  }

  getStateImpl<T>(): State<T> {
    const result = this._localStorageService.get(this.settings.syncKey);
    return result;
  }

  listenStateImpl$<T>(): Observable<State<T>> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      // listen for state changes
      filter((evt: StorageEvent) => evt.key === this.settings.syncKey),
      filter((evt: StorageEvent) => evt.newValue !== null),
      map((evt: StorageEvent) => {
        // deserialize the new state from local storage
        const newState = JSON.parse(evt.newValue);
        return newState;
      })
    );
  }
}
