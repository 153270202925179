// prettier-ignore
import { DatePipe, FormatWidth, getLocaleDateFormat, getLocaleTimeFormat, registerLocaleData } from '@angular/common';
/**
 * IMPORTANT: Every time a new locale is added to the environment config, the angular locale must be added. #18799
 */
import en from '@angular/common/locales/en'; // Must not use en-US, angular does not recognize it.
import enGB from '@angular/common/locales/en-GB';
import es from '@angular/common/locales/es'; // Must not use es-ES, angular does not recognize it.
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import tr from '@angular/common/locales/tr';
import zhHans from '@angular/common/locales/zh-Hans';

import { Injectable, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IntlService, parseDate } from '@progress/kendo-angular-intl';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { DateHelperService } from '../helpers/date-helper.service';
import { globalUtilsHelper } from '../helpers/global-utils-helper';
import { LocalStorageService } from '../local-storage.service';
import { LogService } from '../wlm-log/log.service';
import { DateFormats } from './date-formats.enum';
import { DateFormatsService } from './date-formats.service';
import { ExtendDatePipe } from './extend-date.pipe';
import { ExtendDecimalPipe } from './extend-decimal.pipe';
import { StaticLocales } from './static-locales.enum';

const STATIC_LOCALES = {
  [StaticLocales.ES]: es,
  [StaticLocales.EN_GB]: enGB,
  [StaticLocales.EN]: en,
  [StaticLocales.IT]: it,
  [StaticLocales.FR]: fr,
  [StaticLocales.TR]: tr,
  [StaticLocales.ZH_CN]: zhHans,
};

const DATE_INPUT_FORMAT_DEFAULT = 'dd/MM/yyyy';
const DATE_INPUT_FORMATS = {
  [StaticLocales.TR]: 'dd.MM.yyyy',
};

const DATE_TIME_INPUT_FORMAT_DEFAULT = 'dd/MM/yyyy HH:mm';
const DATE_TIME_INPUT_FORMATS = {
  [StaticLocales.TR]: 'dd.MM.yyyy HH:mm',
};

/**
 * Main translation service, which encapsulates configurations and tries to provide an uniform interface.
 */

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LocalizationHelperService extends BaseService {
  /**
   * Get the current locale.
   * As the page reloads when lang is changed, this method can be sync.
   */
  public get currentLocale(): string {
    return this._currentLocale;
  }

  // Date formats are now obtained by DateFormatsService, like dateFormatsService.getFormat(DateFormats.Date)
  private dateFormat: string;
  private timeFormat: string;
  private get dateTimeFormat(): string {
    return `${this.dateFormat} ${this.timeFormat}`;
  }

  private _isAvailable$ = new ReplaySubject<void>();
  public isAvailable$ = this._isAvailable$.asObservable();

  private _currentLocale: string;
  private get _defaultLocale(): string {
    return this.settingsService.localization.defaultLocale;
  }
  private get _availableLocales(): string[] {
    return this.settingsService.localization.availableLocales;
  }
  private get _forcedHourFormat(): string {
    return this.settingsService.localization.forcedHourFormat;
  }
  private get _forcedDateFormat(): string {
    return this.settingsService.localization.forcedDateFormat;
  }

  // The Local Storage key for the currently selected lang, which must always be in sync with TranslateService.
  private _lsKey = 'currentLocale';

  constructor(
    injector: Injector,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private dateFormatsService: DateFormatsService,
    private intlService: IntlService,
    private dateHelper: DateHelperService,
    private _logService: LogService
  ) {
    super(injector);
  }

  /**
   * Initial config method. Must be called only once in the app.
   */
  public config(): void {
    if (!this.validateDoubleConfig()) {
      return;
    }

    // Set fallback locale.
    this.translate.setDefaultLang(this._defaultLocale);

    // Add all available locales for ngx-translate.
    this.translate.addLangs(this._availableLocales);

    // Add all available locales for Angular i18n.
    this.registerLocaleModules(STATIC_LOCALES);

    // If the locale is not saved in LS yet, save the default.
    this._currentLocale = this.localStorageService.get(this._lsKey, null);
    if (!this._currentLocale) {
      this.localStorageService.addOrUpdate(this._lsKey, this._defaultLocale);
      this._currentLocale = this._defaultLocale;
    }

    // Use the selected locale.
    this.translate.use(this._currentLocale);

    // Setup date formats for the pipe to consume.
    this.loadDateTimeFormats(this._currentLocale);

    // Keep LS always in sync with the user selected locale.
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.lang !== this._currentLocale) {
        this.refreshCurrentLocale(data.lang);
      }
    });

    this._isAvailable$.next();
    this._isAvailable$.complete();
  }

  /*
   * When the user selects a new locale, the page is reloaded to prevent async bugs (having to rebind grids, for example).
   * Like this, we can still use a simulated sync behavior when getting the currentLocale.
   */
  private refreshCurrentLocale(newLocale: string): void {
    this.localStorageService.addOrUpdate(this._lsKey, newLocale);
    this.reloadPage();
  }

  /**
   * Gets the translations for a given scope.
   * Should be used instead of TranslateService for decoupling and uniformity.
   * @param scope The key of the translation object (json) we are interested into, like "module.component".
   * @param interpolateParams For inserting values inside the translation strings.
   */
  public get = (scope: string | Array<string>, interpolateParams?): Observable<any> => {
    const result = this.translate.get(scope, interpolateParams);
    return result;
  };

  getOrDefault = (scope: string | Array<string>, defaultValue: string): Observable<string> => {
    const initialScope = Array.isArray(scope) ? scope.join('.') : scope;
    return this.get(scope).pipe(map((result) => (result === initialScope ? defaultValue : result)));
  };

  /**
   * Selects an available locale. The page refreshes and the new locale is set.
   * If locale is not configured, it does nothing.
   * Should be used instead of TranslateService for decoupling and uniformity.
   * @param locale The new locale to use.
   */
  public useLocale = (locale: string) => {
    const found = this._availableLocales.find((lo) => locale === lo);
    if (found) {
      this.translate.use(locale);
    } else {
      console.error(`The locale ${locale} is not available in the system.`);
    }
  };

  public getDateTimeFormat = () => {
    return this.dateTimeFormat;
  };

  formatDateRange(startInput: Date, endInput: Date): Observable<string> {
    const start = this.dateHelper.ensureDateObject(startInput);
    const end = this.dateHelper.ensureDateObject(endInput);

    const startFormatted = this.formatDate(start, DateFormats.Date);
    const endFormatted = this.formatDate(end, DateFormats.Date);
    return this.get('common.date-range', { start: startFormatted, end: endFormatted });
  }

  /**
   * Gets all the available locales.
   * Should be used instead of TranslateService for decoupling and uniformity.
   */
  public getLocales = (): string[] => this.translate.getLangs();

  /**
   * Helper method. Maps the translation observable to a different one.
   */
  public localizeSwitch(scope: string, callback, interpolateParams?: any): Observable<any> {
    return this.get(scope, interpolateParams).pipe(switchMap((ts) => of(callback(ts))));
  }

  /**
   * Sets the date and time formats corresponding to the selected locale.
   * Currently, native Angular locales has support for this while ngx-translate not, so we use Angular native methods.
   */
  private loadDateTimeFormats(locale: string) {
    // "FormatWidth" could be extracted to use it with "formatDate".
    const localDateFormat = getLocaleDateFormat(locale, FormatWidth.Short);
    this.dateFormat = this._forcedDateFormat ?? localDateFormat;
    const localTimeFormat = getLocaleTimeFormat(locale, FormatWidth.Short);
    this.timeFormat = this._forcedHourFormat ?? localTimeFormat;

    // Make formats available for ExtendDatePipe
    this.dateFormatsService.setFormat(DateFormats.Date, this.dateFormat);
    this.dateFormatsService.setFormat(DateFormats.Time, this.timeFormat);
    this.dateFormatsService.setFormat(DateFormats.DateTime, this.dateTimeFormat);
    // Also save current locale because ExtendDatePipe needs it.
    this.dateFormatsService.currentLocale = this.currentLocale;
  }

  /**
   * Uses the date pipe to perform formatting, which is overriden with locale config.
   */
  public formatDate(
    date: Date,
    formatKey: DateFormats = DateFormats.DateTime,
    toUtc = false
  ): string {
    const pipe = this.getDatePipe();
    if (toUtc) {
      return pipe.transform(this.dateHelper.toApiFormat(date), formatKey, '+0000');
    }
    return pipe.transform(date, formatKey);
  }

  public formatDates(dates: Date[], formatKey: DateFormats = DateFormats.DateTime): string[] {
    return dates.map((date) => this.getDatePipe().transform(date, formatKey));
  }

  /**
   * Unlike other dates, input dates can only be specified in one format per language.
   */
  parseInputDate(dateStr: string | null, includeTime?: boolean): Date {
    if (!dateStr || this.dateHelper.isDateObject(dateStr)) {
      return dateStr as any as Date;
    }
    const dateFormat = this.getCurrentDateInputFormat(includeTime);
    const date = parseDate(dateStr, dateFormat);
    return date;
  }

  formatInputDate(date: Date | null, includeTime?: boolean): string {
    if (!date || typeof date === 'string') {
      return date as any as string;
    }
    const datePipe = new DatePipe(this.currentLocale);
    const dateFormat = this.getCurrentDateInputFormat(includeTime);
    const dateStr = dateFormat ? datePipe.transform(date, dateFormat) : datePipe.transform(date);
    return dateStr;
  }

  private getCurrentDateInputFormat(includeTime?: boolean): string {
    let dateFormat = '';
    if (includeTime) {
      dateFormat = DATE_TIME_INPUT_FORMATS[this.currentLocale] ?? DATE_TIME_INPUT_FORMAT_DEFAULT;
    } else {
      dateFormat = DATE_INPUT_FORMATS[this.currentLocale] ?? DATE_INPUT_FORMAT_DEFAULT;
    }
    return dateFormat;
  }

  private getDatePipe(): ExtendDatePipe {
    return new ExtendDatePipe(this.dateFormatsService);
  }

  private getDecimalPipe(): ExtendDecimalPipe {
    return new ExtendDecimalPipe(this.dateFormatsService, this.settingsService);
  }

  /**
   * Parse a localized string Date into a Date object.
   */
  public localStrToDate(dateStr: string, formatKey: DateFormats = DateFormats.DateTime) {
    const format = this.dateFormatsService.getFormat(formatKey);
    const result = this.intlService.parseDate(dateStr, format, this.currentLocale);
    return result;
  }

  public localStrArrayToDate(dateStrs: string[], formatKey: DateFormats = DateFormats.DateTime) {
    const format = this.dateFormatsService.getFormat(formatKey);
    const result = dateStrs.map((dateStr) => this.intlService.parseDate(dateStr, format));
    return result;
  }

  /**
   * Uses the number pipe to perform formatting, which is overriden with locale config.
   */
  public formatNumber(num: number, digitsInfo?: string, disableLocale?: boolean): string {
    return this.getDecimalPipe().transform(num, digitsInfo, disableLocale);
  }

  public convertToLocalDate(dateString: string): Date {
    const date = new Date(dateString + 'Z');
    return date;
  }

  /**
   * Validates that all the locales in environment have their static locale module loaded.
   */
  private validateDoubleConfig(): boolean {
    const found = this._availableLocales.find((envLocale) => !STATIC_LOCALES[envLocale]);
    if (found) {
      console.error(
        `The locale ${found} was specified in configuration, but no equivalent module is loaded from @angular/common/locales.`
      );
      return false;
    }
    return true;
  }

  /**
   * Register all static locales to be used with angular.
   */
  private registerLocaleModules(angularLocales): void {
    const locales = Object.keys(angularLocales);
    locales.forEach((locale) => {
      registerLocaleData(angularLocales[locale], locale);
    });
  }

  private reloadPage = () => window.location.reload();

  getLocalizedDateFromApi(columnValue: any, columnFormat: string, valueDefinedAsUtc = true) {
    if (!columnValue || columnValue === '0001-01-01T00:00:00') {
      return '';
    }

    const convertedDate = valueDefinedAsUtc
      ? this.dateHelper.fromApiFormat(columnValue)
      : columnValue;

    const convertedColumnValue = this.formatDate(
      convertedDate,
      (columnFormat as DateFormats) ?? DateFormats.DateTime
    );

    return convertedColumnValue ?? columnValue;
  }

  getDateSeparator(): string {
    for (let index = 0; index < this.dateFormat.length; index++) {
      if (!/^[a-zA-Z()]+$/.test(this.dateFormat[index])) {
        return this.dateFormat[index];
      }
    }

    return null;
  }

  formatAttributeValue(value: any, format: string, units?: string): string {
    let formatted = value;

    if (value === null || typeof value === 'undefined') {
      return null;
    }

    value = globalUtilsHelper.commaNumericStringToNumber(value);

    if (!isNaN(+value)) {
      const numericFormat = globalUtilsHelper.translateNumericFormat(format);

      if (numericFormat) {
        formatted = this.formatNumber(+value, numericFormat);
      } else {
        formatted = value;
      }
    }

    if (units) {
      formatted += ` ${units}`;
    }

    return formatted;
  }
}
