export class INeHeFilterSettings {
  isNEFilter: boolean;
  selectedElementIds?: any[];
  elementTypeFieldName?: string;

  constructor(isNEFilter: boolean, selectedElementIds?: any[], elementTypeFieldName?: string) {
    this.isNEFilter = isNEFilter;
    this.selectedElementIds = selectedElementIds;
    this.elementTypeFieldName = elementTypeFieldName;
  }
}
