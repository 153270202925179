<form>
  <mat-form-field class="white-background-input-field input-dimension" appearance="outline">
    <input
      type="text"
      [placeholder]="'common.search' | translate"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="onSelectOption($event)"
    >
      <mat-option
        class="input-autocomplete-list"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.name }}
      </mat-option>
      <mat-option *ngIf="showResultsNotFound" class="input-autocomplete-list">
        <span class="option-message">{{ 'common.messages.no-results' | translate }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
