import { ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import {
  hierarchyElementTypes,
  treeSelectionMode,
} from 'src/app/common-modules/dependencies/shared/hierarchy-tree-filter-constants';
import { FilterAdditionalParam } from 'src/app/common-modules/dependencies/wlm-filters/filter-additional-param';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { MergedZonesDto } from '../../models/merged-zones.dto';

const COMPONENT_SELECTOR = 'wlm-merged-zones-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-form.component.html',
  styleUrls: ['./merged-zones-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MergedZonesFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MergedZonesFormComponent),
      multi: true,
    },
  ],
})
export class MergedZonesFormComponent implements OnInit, ControlValueAccessor {
  private _isUpdate: boolean;
  get isUpdate(): boolean {
    return this._isUpdate;
  }
  @Input() set isUpdate(value: boolean) {
    this._isUpdate = value;
    this.disableFieldByMode();
  }

  @Input() conflictError$: Observable<void>;
  @Input() conflictNameError$: Observable<void>;

  form: FormGroup;
  initialModel: MergedZonesDto;
  treeFilterSettings: FilterGroupSettings;

  nativeOnChange: (model: Partial<MergedZonesDto>) => void;
  nativeOnTouched: () => void;

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  readonly treeFields = {
    hierarchyElementParentId: 'hierarchyElementParentId',
    hierarchyFamilyId: 'hierarchyFamilyId',
  };
  private readonly _editDisabledFields = ['hierarchyElementId'];

  private _isDisabled: boolean;
  get isDisabled(): boolean {
    return this._isDisabled;
  }
  set isDisabled(value: boolean) {
    this._isDisabled = value;

    if (this.isDisabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
    this.disableFieldByMode();
  }

  constructor(private readonly _fb: FormBuilder, private readonly _cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.bindConflictError();
    this.buildForm();
  }

  // Required by NG_VALIDATORS
  validate(control: FormControl) {
    return this.buildFieldErrors();
  }

  private buildFieldErrors(): { [field: string]: ValidationErrors } {
    const errors = {};
    for (let controlName in this.form.controls) {
      const control = this.form.controls[controlName];
      if (control.errors) {
        errors[controlName] = globalUtilsHelper.clone(control.errors, true);
      }
    }
    return errors;
  }

  private buildForm(): void {
    const V = Validators;
    this.form = this._fb.group({
      hierarchyElementId: [null, [V.required]],
      hierarchyElementName: [null, [V.required]],
      hierarchyFamilyId: [null, []],
      hierarchyElementParentId: [null, [V.required]],
      comment: [null, []],
    });

    this.disableFieldByMode();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((model) => {
      let completeModel = model;
      if (this.initialModel) {
        completeModel = { ...this.initialModel, ...model };
      }
      if (this.nativeOnChange) {
        this.nativeOnChange(completeModel);
        this.disableFieldByMode();
      }
    });

    this.buildTreeFilter();
  }

  private disableFieldByMode(): void {
    this._editDisabledFields.forEach((field) => {
      const control = this.form?.get(field);

      if (control) {
        if (this.isUpdate) {
          if (control.enabled) {
            control.disable();
          }
        } else {
          if (control.disabled) {
            control.enable();
          }
        }
      }
    });
  }

  private buildTreeFilter(): void {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.treeFields.hierarchyElementParentId,
      }),
      hierarchyElementFamilyId: new FilterGroupFieldSettings({
        fieldName: this.treeFields.hierarchyFamilyId,
      }),
    };

    const params: { [field: string]: any } = {
      hierarchyElementIds: this.heCtrl?.value,
      hierarchyFamilyId: this.familyCtrl?.value,
    };

    const additionalParams = {
      selectionMode: new FilterAdditionalParam({ value: treeSelectionMode.single }),
      unselectableTypes: new FilterAdditionalParam({ value: [hierarchyElementTypes.DMA] }),
    };

    this.treeFilterSettings = null;
    this._cd.detectChanges();

    this.treeFilterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: COMPONENT_SELECTOR,
      avoidPersistency: true,
      additionalParams,
    });
  }

  get heCtrl() {
    return this.form.controls[this.treeFields.hierarchyElementParentId];
  }

  get familyCtrl() {
    return this.form.controls[this.treeFields.hierarchyFamilyId];
  }

  writeValue(initialModel: MergedZonesDto): void {
    this.initialModel = globalUtilsHelper.clone(initialModel, true);
    if (initialModel) {
      for (let controlName in this.form.controls) {
        const control = this.form.controls[controlName];
        const value = this.initialModel[controlName] ?? null;
        control.setValue(value, { emitEvent: false });
      }
    }
    this.disableFieldByMode();
    this.buildTreeFilter();
  }

  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.nativeOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private bindConflictError() {
    this.conflictError$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.form?.controls?.hierarchyElementId?.setErrors({
        custom: true,
        message: {
          key: 'common.validation.not-unique',
          field: `${this.T_SCOPE}.labels.hierarchy-element-id`,
        },
      });
      this.form?.updateValueAndValidity();
      this.form?.controls?.hierarchyElementId?.markAsTouched();
    });

    this.conflictNameError$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.form?.controls?.hierarchyElementName?.setErrors({
        custom: true,
        message: {
          key: 'common.validation.not-unique',
          field: `${this.T_SCOPE}.labels.hierarchy-element-name`,
        },
      });
      this.form?.updateValueAndValidity();
      this.form?.controls?.hierarchyElementId?.markAsTouched();
    });
  }
}
