import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { AuthenticationService } from '../auth/services/authentication.service';
import { PagedResultDto } from '../model/paged-result.dto';
import { GroupCmdDto } from '../model/roles/group-cmd.dto';
import { GroupResponseDto } from '../model/roles/group-response.dto';
import { GroupDto } from '../model/roles/group.dto';
import { ProfileCrudDto } from '../model/roles/profileCrud.dto';
import { ProfileCrudsResponse } from '../model/roles/profileCrudsResponse';
import { ProfileDialogsPathsDto } from '../model/roles/profileDialogsPaths.dto';
import { UserPermissionsDto } from '../model/roles/user-permissions.dto';
import { GridODataService } from '../odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends GridODataService<GroupDto> {
  expiration: GetCacheOpts = { expiration: 'default', reload: true };

  constructor(injector: Injector, private authenticationService: AuthenticationService) {
    super(injector, `/api/roles/groups`);
  }

  get apiUrl() {
    return `${this.baseUrl}/api/roles/groups`;
  }

  protected mapResponse(response: PagedResultDto<GroupDto>): PagedResultDto<GroupDto> {
    return response;
  }

  /**
   * Get the dialogs (permissions) of the current user.
   * This method must always be executed with a valid token.
   */
  getProfileDialogsPathsByCurrentUser(): Observable<UserPermissionsDto> {
    //this.authenticationService.ensureTokenAvailable(); // Check if we still need it
    return this.httpCacheClient
      .get(`${this.apiUrl}/current-user`, {
        avoid: true,
      })
      .pipe(retryWhen<any>((error) => error.pipe(delay(300), take(10))));
  }

  getProfileDialogsPathsByGroupId(groupId: number): Observable<ProfileDialogsPathsDto[]> {
    return this.httpCacheClient.get(`${this.apiUrl}/${groupId}`, {
      avoid: true,
    });
  }

  getProfileDialogsPathsByUserCode(userCode: string): Observable<UserPermissionsDto> {
    return this.httpCacheClient.get(`${this.apiUrl}/usercode/${userCode}`, {
      avoid: true,
    });
  }

  setProfileCrud(profileCrud: ProfileCrudDto): Observable<ProfileCrudsResponse> {
    return this.httpCacheClient.post(`${this.apiUrl}/profile-crud`, profileCrud, {
      avoid: true,
    });
  }

  saveGroup(profileCrud: GroupCmdDto): Observable<GroupResponseDto> {
    return this.httpCacheClient.post(`${this.apiUrl}/group-update`, profileCrud, {
      avoid: true,
    });
  }

  deleteGroup(groupToDelete: GroupCmdDto): Observable<GroupResponseDto> {
    return this.httpCacheClient.post(`${this.apiUrl}/group-delete`, groupToDelete, {
      avoid: true,
    });
  }
}
