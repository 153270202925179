import { Injectable } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LogService } from '../wlm-log/log.service';
import { ObservablesService } from './observables.service';

export class WlmResizeObserverConfig {
  el: Element;
  debounce? = 100; // ms
  // Whether we should calculate the max height (disable for performance).
  calculateHeight?: boolean;
  calculateWidth?: boolean;

  constructor(init?: Partial<WlmResizeObserverConfig>) {
    Object.assign(this, init);
  }
}

type ResizeObserverNative = [ResizeObserverEntry[], ResizeObserver];

export class WlmResizeObserverData {
  // The native payload of the service.
  native: ResizeObserverNative;
  // Max height of all observed elements.
  height: number;
  // Max width of all observed elements.
  width: number;
  constructor(init?: Partial<WlmResizeObserverData>) {
    Object.assign(this, init);
  }
}

@Injectable()
export class WlmResizeObserverService {
  constructor(private _logService: LogService, private _observablesService: ObservablesService) {}

  /**
   * Map resize observer to observable and apply optional debounce.
   */
  observe(config: WlmResizeObserverConfig): Observable<WlmResizeObserverData> {
    if (!config.el) {
      this._logService.error({ msg: 'Cannot observe resize of a null element.' });
      return of(null);
    }
    const subject$ = new Subject<WlmResizeObserverData>();
    const ro = new ResizeObserver((...native) =>
      subject$.next(this.mapToCustomModel(native as ResizeObserverNative, config))
    );

    ro.observe(config.el);

    return subject$.asObservable().pipe(debounceTime(config.debounce || 100));
  }

  /**
   * Observes children resize and calculate the remaining widht for the responsive element defined
   * The overflow will be informed in the ObservablesService with the provided key
   */
  observeChildren(parentElement: Element, responsiveChildId: string, key: string) {
    const parentObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const allChildrenExceptResponsive = (Array.from(entry.target.children) as Element[]).filter(
          (f) => f.id !== responsiveChildId
        );
        let totalChildrenWidth = 0;
        allChildrenExceptResponsive.forEach((child) => {
          totalChildrenWidth += child.clientWidth;
        });

        const remainingWidth = entry.contentRect.width - totalChildrenWidth;

        this.notifyChildrenOverflow(remainingWidth, key);
      });
    });

    parentObserver.observe(parentElement);
  }

  private notifyChildrenOverflow(remainingWidth: number, key: string) {
    this._observablesService.emit(key, remainingWidth);
  }

  private mapToCustomModel(
    native: ResizeObserverNative,
    config: WlmResizeObserverConfig
  ): WlmResizeObserverData {
    const result = new WlmResizeObserverData({
      native,
      height: config.calculateHeight ? this.calculateHeight(native) : undefined,
      width: config.calculateWidth ? this.calculateWidth(native) : undefined,
    });
    return result;
  }

  private calculateHeight(native: ResizeObserverNative): number {
    if (!native || !native[0]) {
      return 0;
    }
    const entries = native[0];
    const heights = entries.map((entry) => entry.contentRect.height);
    return Math.max(...heights);
  }

  private calculateWidth(native: ResizeObserverNative): number {
    if (!native || !native[0]) {
      return null;
    }
    const entries = native[0];
    const width = entries.map((entry) => entry.contentRect.width);
    return Math.max(...width);
  }
}
