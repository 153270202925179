<div class="integration-container">
  <div class="stepper-container">
    <mat-vertical-stepper
      [linear]="false"
      [selectedIndex]="currentStep"
      (selectionChange)="onStepChange($event)"
    >
      <mat-step
        *ngFor="let stepId of stepsPositionArray"
        [stepControl]="stepControls[stepId]"
        [completed]="stepsCompleted[stepId]"
      >
        <ng-template matStepLabel>{{
          T_SCOPE + '.steps.' + stepTranslations[stepId] | translate
        }}</ng-template>
        <div class="step-header">
          <button mat-raised-button color="primary" matStepperNext>
            {{ T_SCOPE + '.buttons.next' | translate }}
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
    <div class="complete-button-container">
      <div class="inner-complete-button-container">
        <button mat-raised-button color="primary" (click)="onCompleteIntegration()">
          {{ T_SCOPE + '.buttons.complete' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="steps-content-container">
    <div *ngIf="currentStep === stepPositions[steps.AppAttributes]" class="single-step">
      <wlm-app-attributes-crud></wlm-app-attributes-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.NetworkElementTypes]" class="single-step">
      <wlm-ne-type-crud></wlm-ne-type-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.HierarchyElementTypes]" class="single-step">
      <wlm-he-type-crud></wlm-he-type-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.MaterialTypes]" class="single-step">
      <wlm-material-types-crud></wlm-material-types-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.CustomerClassTypes]" class="single-step">
      <wlm-customer-class-type-crud></wlm-customer-class-type-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.BillingClasses]" class="single-step">
      <wlm-billing-class-crud></wlm-billing-class-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.ActivityTypes]" class="single-step">
      <wlm-activity-types-crud></wlm-activity-types-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.SapCodeGroups]" class="single-step">
      <wlm-sap-code-groups-crud></wlm-sap-code-groups-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.SapCodes]" class="single-step">
      <wlm-sap-codes-crud></wlm-sap-codes-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.LeakTypeGroups]" class="single-step">
      <wlm-leak-type-groups-crud></wlm-leak-type-groups-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.LeakTypes]" class="single-step">
      <wlm-leak-types-crud></wlm-leak-types-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.FamiliesAndRelations]" class="single-step">
      <wlm-families-and-relations-crud></wlm-families-and-relations-crud>
    </div>
    <div *ngIf="currentStep === stepPositions[steps.GisLayers]" class="single-step">
      <wlm-integration-gis-layers></wlm-integration-gis-layers>
    </div>
  </div>
</div>
