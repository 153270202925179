import { CommonModule } from '@angular/common';
import { Component, ContentChild, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialSharedModule } from '../../shared/material-shared.module';
import { SelectOption } from '../../shared/model/shared/select-option';

const COMPONENT_SELECTOR = 'wlm-enhanced-input-select';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './enhanced-input-select.component.html',
  styleUrls: ['./enhanced-input-select.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialSharedModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnhancedInputSelectComponent),
      multi: true,
    },
  ],
})
export class EnhancedInputSelectComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() fieldAppearance: string;
  @Input() fieldClass: string;
  @Input() floatLabel: string;
  @Input() placeholder: string;
  @Input() validators: ValidatorFn | ValidatorFn[];
  private _options: SelectOption<string>[] = [];
  get options(): SelectOption<string>[] {
    return this._options;
  }
  @Input() set options(value: SelectOption<string>[]) {
    this._options = value;
    this.buildSelectedOptions();
  }

  @ContentChild('option') optionTemplateRef: TemplateRef<any>;
  @ContentChild(MatSelect) matSelect: MatSelect;

  selectCtrl = new UntypedFormControl();
  selectedOptions: SelectOption<string>[] = [];
  selectedValues = [];
  isExternalDisabled = false;

  private nativeOnChange: (value) => void;
  private nativeOnTouched: (value) => void;

  constructor() {}

  ngOnInit(): void {
    if (this.validators) {
      this.selectCtrl.setValidators(this.validators);
    }

    this.selectCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      this.selectedValues = values;
      this.buildSelectedOptions();

      if (this.nativeOnChange) {
        this.nativeOnChange(values);
      }
    });
  }

  openSelect(): void {
    this.matSelect?.open();
  }

  writeValue(value: string[]): void {
    this.selectCtrl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.nativeOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isExternalDisabled = isDisabled;
  }

  onFieldClick(event): void {
    if (this.isExternalDisabled) {
      event.stopPropagation();
    }
  }

  private buildSelectedOptions(): void {
    if (this.selectedValues && this.options) {
      this.selectedOptions = this.options.filter((option) =>
        this.selectedValues.find((value) => value === option.value)
      );
    }
  }
}
