// prettier-ignore
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { AppModules } from '../app-modules.enum';
import { DateRangeFilterSettings } from './date-range-filter-settings';
import { DateValidators } from './date-validator';

const COMPONENT_SELECTOR = 'wlm-date-range-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('endDatePicker') endDatePicker: MatDatepicker<Date>;
  @ViewChild('startDatePicker') startDatePicker: MatDatepicker<Date>;

  @Input() startFieldName: string;
  @Input() endFieldName: string;
  @Input() allowsDateNull: boolean;
  @Input() displayHorizontal = false;
  @Input() smallFields = false;
  @Input() fieldAppearance = 'outline';

  private _disableStartDate = false;
  public get disableStartDate() {
    return this._disableStartDate;
  }
  @Input() public set disableStartDate(value) {
    this._disableStartDate = value;
    this.disableDatePicker(value, 'start');
  }

  private _disableEndDate = false;
  public get disableEndDate() {
    return this._disableEndDate;
  }
  @Input() public set disableEndDate(value) {
    this._disableEndDate = value;
    this.disableDatePicker(value, 'end');
  }

  @Input() resetStartDate$: Observable<void>;
  @Input() resetEndDate$: Observable<void>;

  @Output() dateRangeChanged = new EventEmitter<DateRange>();

  @Output() hasError = new EventEmitter<boolean>();

  private _dateRange: DateRange;
  public get dateRange(): DateRange {
    return this._dateRange;
  }
  @Input() public set dateRange(v: DateRange) {
    this._dateRange = v;
    this.dateRangeValues = v;

    this.initializeFormGroup();
  }

  /**
   * Alternative way of setting the main value, which also sets additional settings.
   * Must be injected after all the rest of the config inputs.
   */
  @Input() set valueSettings(valueSettings: {
    value: DateRange;
    settings: DateRangeFilterSettings;
  }) {
    this.settings = valueSettings.settings;
    this.isReadOnly = this.settings.isReadOnly;
    this.dateRange = valueSettings.value;
  }

  @Input() minDate = new Date(1990, 1, 1);
  @Input() maxDate = new Date();
  @Input() disableAutoTruncate = false;

  formClass: string;
  showCurrentStart = false;
  showCurrentEnd = false;
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  currentStartLabelKey = `common.current-dates.start`;
  currentEndLabelKey = `common.current-dates.end`;
  dateRangeForm: UntypedFormGroup;
  dateRangeValues: DateRange;
  isReadOnly = false;
  settings: DateRangeFilterSettings;

  constructor(
    private _adapter: DateAdapter<any>,
    public localization: LocalizationHelperService,
    private _dateHelper: DateHelperService
  ) {}

  ngOnInit(): void {
    this._adapter.setLocale(this.localization.currentLocale);
    this.formClass = this.displayHorizontal ? 'date-range-form-horizontal' : 'date-range-form';
  }

  ngAfterViewInit(): void {
    this.subscribeResets();
  }

  private initializeFormGroup() {
    const startControl = new UntypedFormControl(
      { value: this.initialStartDate(), disabled: this.settings?.isReadOnly },
      [Validators.required]
    );
    const endControl = new UntypedFormControl(
      { value: this.initialEndDate(), disabled: this.settings?.isReadOnly },
      [Validators.required]
    );

    this.dateRangeForm = new UntypedFormGroup(
      {
        start: startControl,
        end: endControl,
      },
      {
        validators: DateValidators.dateLessThan(
          'start',
          'end',
          this._dateHelper.ensureDateObject,
          this.settings?.disableEqualDates
        ),
      }
    );

    this.dateRangeForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.dateRangeChange();
      },
    });

    this.checkCurrent();
  }

  subscribeResets() {
    if (this.resetStartDate$) {
      this.resetStartDate$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.resetDatePicker('start'));
    }

    if (this.resetEndDate$) {
      this.resetEndDate$.pipe(untilDestroyed(this)).subscribe(() => this.resetDatePicker('end'));
    }
  }

  public dateRangeChange(): void {
    const isValid = this.checkIsValid();

    this.hasError.emit(!isValid);
    this.checkCurrent();

    if (isValid) {
      let start = this.startCtrl?.value;
      let end = this.endCtrl?.value;

      start = this.disableAutoTruncate ? start : this._dateHelper.truncateDate(start);
      end = this.disableAutoTruncate ? end : this._dateHelper.truncateDate(end);

      this.dateRangeValues = new DateRange(
        start,
        end,
        this.startFieldName,
        this.endFieldName,
        this.allowsDateNull
      );
      this.dateRangeChanged.emit(this.dateRangeValues);
    }
  }

  private checkCurrent(): void {
    const start = this.startCtrl?.value;
    const end = this.endCtrl?.value;

    this.showCurrentStart = this._dateHelper.isCurrentStartDate(start);
    this.showCurrentEnd = this._dateHelper.isCurrentEndDate(end);
  }

  private checkIsValid(): boolean {
    let isValid = false;

    if (this.settings?.disableValidationUntilTouched) {
      // In this special case, we consider values are valid if they have not been modified by the user, wether they pass validations or not.
      isValid =
        (this.startCtrl.valid || this.startCtrl.pristine) &&
        (this.endCtrl.valid || this.endCtrl.pristine);
    } else {
      isValid = this.dateRangeForm.valid;
    }
    return isValid;
  }

  disableDatePicker(disable: boolean, pickerName: 'start' | 'end') {
    if (disable) {
      this.dateRangeForm.get(pickerName)?.disable();
    } else {
      this.dateRangeForm.get(pickerName)?.enable();
    }
  }

  resetDatePicker(pickerName: 'start' | 'end') {
    this.dateRangeForm.get(pickerName)?.reset();
  }

  private initialStartDate(): Date {
    let start = this._dateHelper.ensureDateObject(this.dateRangeValues?.start);

    if (this.settings?.disableValidationUntilTouched) {
      return start;
    }
    if (start && start < this.minDate) {
      start = this.minDate;
    }
    return start;
  }

  private initialEndDate(): Date {
    let end = this._dateHelper.ensureDateObject(this.dateRangeValues?.end);
    if (end?.toString() === new Date(null).toString()) {
      end = null; // Was '' before.
    }

    if (this.settings?.disableValidationUntilTouched) {
      return end;
    }

    if (end > this.maxDate) {
      end = this.maxDate;
    }
    return end;
  }

  private get startCtrl() {
    return this.dateRangeForm.get('start');
  }

  private get endCtrl() {
    return this.dateRangeForm.get('end');
  }
}
