import { Injectable } from '@angular/core';
import * as echarts from 'echarts';
import { Observable, of } from 'rxjs';
import { ColorHelperService } from 'src/app/common-modules/shared/helpers/color-helper.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { GCartesianCustomChartSerie } from '../models/generic-chart-settings/g-cartesian-custom-chart-series';
import { GChartAxis } from '../models/generic-chart-settings/g-chart-axis';
import { GChartAxisLabel } from '../models/generic-chart-settings/g-chart-axis-label';
import { GChartAxisLine } from '../models/generic-chart-settings/g-chart-axis-line';
import { GChartAxisSplitLine } from '../models/generic-chart-settings/g-chart-axis-split-line';
import { GChartAxisTick } from '../models/generic-chart-settings/g-chart-axis-tick';
import { GChartCustomItemStyle } from '../models/generic-chart-settings/g-chart-custom-item-style';
import { GChartDataZoom } from '../models/generic-chart-settings/g-chart-data-zoom';
import { GChartItemStyle } from '../models/generic-chart-settings/g-chart-item-style';
import { GChartTooltip } from '../models/generic-chart-settings/g-chart-tooltip';
import { GenericCartesianCustomChartSettings } from '../models/generic-chart-settings/generic-cartesian-custom-chart-settings';
import { ItemStylesByKeyModes } from '../models/generic-chart-settings/item-styles-by-key-modes';
import { ItemStylesByKeyPriorities } from '../models/generic-chart-settings/item-styles-by-key-priorities';
import { HistoricalChartDataParameters } from '../models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartDimensions } from '../models/historical-chart-settings/historical-chart-dimensions';
import { HistoricalChartResultSettings } from '../models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalEvent } from '../models/historical-chart-settings/historical-event';

// If the service is going to have attributes, it should be instantiate with DI at component level, not at module level.
@Injectable({
  providedIn: 'root',
})
export class HistoricalChartSettingsService {
  translations = {
    startDate: null,
    endDate: null,
  };

  private _eventHeight = 25;
  private _eventWindow = 8;
  // estimation of the space required to show the x axis and the dataZoom bar.
  private _verticalToolingSpace = 30;
  private _dataZoomSize = 30;
  private _chartGrid = {
    y: '30',
    y2: '70',
  };

  private _basicEventStyles: GChartItemStyle = {};

  private _errorColor: string;
  private _selectedColor: string;
  private _editedColor: string;
  private _defaultColor: string;

  private _errorEventStyles: GChartItemStyle;
  private _editedEventStyles: GChartItemStyle;
  private _selectedEventStyles: GChartItemStyle;
  private _defaultEventStyles: GChartItemStyle;

  private _defaultBackground = 'rgba(255,255,255,0.7)';

  constructor(private _dateHelper: DateHelperService, private _colorService: ColorHelperService) {
    this.setEventColors();
  }

  getHistoricalChart(
    resultSettings: HistoricalChartResultSettings,
    dataParameters: HistoricalChartDataParameters
  ): Observable<GenericCartesianCustomChartSettings> {
    if (!resultSettings) {
      return of(null);
    }
    const { events } = resultSettings;
    const processedEvents = this.getProcessedEvents(events);
    const processedSeries = this.getProcessedSeries(processedEvents);
    const chartSettings = new GenericCartesianCustomChartSettings({
      type: 'custom',
      xAxes: this.buildXAxes(dataParameters),
      yAxes: this.buildYAxes(),
      series: [
        new GCartesianCustomChartSerie({
          type: 'custom',
          data: processedSeries,
          dimensions: this.buildDimensions(),
          renderItem: this.renderEventItem,
          itemStyle: this._defaultEventStyles,
          itemDimensionKey: String(HistoricalChartDimensions.Id),
          labelLayout: { hideOverlap: true },
          selectedMode: true,
          encode: {
            x: [HistoricalChartDimensions.StartDate, HistoricalChartDimensions.EndDate],
            y: HistoricalChartDimensions.Id,
          },
        }),
      ],
      tooltip: new GChartTooltip({
        trigger: 'item',
        borderColor: '#fff',
        formatter: (params) => {
          return this.renderTooltip(params);
        },
        backgroundColor: this._defaultBackground,
      }),
      dataZoom: this.buildDataZoom(processedSeries),
      grid: this._chartGrid,
      animation: false,
    });
    return of(chartSettings);
  }

  getCustomChartSerie(events: HistoricalEvent[]): GCartesianCustomChartSerie {
    return null;
  }

  buildCustomItemStyles(eventsIds: string[], mode: ItemStylesByKeyModes): GChartCustomItemStyle[] {
    const styles = eventsIds.map((itemId) => {
      let itemStyle;
      let priority;
      if (mode === 'error') {
        itemStyle = this._errorEventStyles;
        priority = ItemStylesByKeyPriorities.Error;
      } else if (mode === 'edited') {
        itemStyle = this._editedEventStyles;
        priority = ItemStylesByKeyPriorities.Edited;
      } else if (mode === 'selected') {
        itemStyle = this._selectedEventStyles;
        priority = ItemStylesByKeyPriorities.Selected;
      }

      return new GChartCustomItemStyle({
        itemId,
        mode,
        itemStyle,
        priority,
      });
    });
    return styles;
  }

  private buildDimensions() {
    const dims = [
      HistoricalChartDimensions.Id,
      HistoricalChartDimensions.StartDate,
      HistoricalChartDimensions.EndDate,
      HistoricalChartDimensions.Label,
      HistoricalChartDimensions.Color,
      HistoricalChartDimensions.AdditionalParams,
      HistoricalChartDimensions.VisibleStartDate,
      HistoricalChartDimensions.VisibleEndDate,
      HistoricalChartDimensions.Tooltip,
    ];
    const strDims = dims.map(String);
    return strDims;
  }

  /**
   * Process the events to prepare them for the chart.
   */
  private getProcessedEvents(events: HistoricalEvent[]): HistoricalEvent[] {
    // Format dates.
    let formattedEvents = events.map((event, index) => {
      const formatted: HistoricalEvent = {
        ...event,
        startDate: this._dateHelper.fromApiFormat(event.startDate as any),
        endDate: event.endDate,
      };
      return formatted;
    });
    // TODO: Sort by group, then by startDate.
    formattedEvents = formattedEvents.sort(
      (a, b) => +new Date(b.startDate) - +new Date(a.startDate)
    );
    return formattedEvents;
  }

  /**
   * Converts the events to the format accepted by the chart.
   */
  private getProcessedSeries(formattedEvents: HistoricalEvent[]): any[][] {
    const result: any[][] = [];
    formattedEvents.forEach((event) => {
      const processed: any[] = [];
      processed[HistoricalChartDimensions.Id] = event.id;
      processed[HistoricalChartDimensions.StartDate] = event.startDate;
      processed[HistoricalChartDimensions.EndDate] = event.endDate;
      processed[HistoricalChartDimensions.VisibleStartDate] = event.visibleStartDate;
      processed[HistoricalChartDimensions.VisibleEndDate] = event.visibleEndDate;
      processed[HistoricalChartDimensions.Label] = event.label;
      processed[HistoricalChartDimensions.AdditionalParams] = event.additionalParams;
      processed[HistoricalChartDimensions.Tooltip] = event.tooltip;
      result.push(processed);
    });
    return result;
  }

  getProcessed(events: HistoricalEvent[]): any[][] {
    const processedEvents = this.getProcessedEvents(events);
    const processedSeries = this.getProcessedSeries(processedEvents);
    return processedSeries;
  }

  private renderEventItem = (params, api) => {
    const categoryIndex = api.value(HistoricalChartDimensions.Id);
    const dateStartNumber = api.value(HistoricalChartDimensions.VisibleStartDate);
    const dateEndNumber = api.value(HistoricalChartDimensions.VisibleEndDate);
    const startCoords = api.coord([dateStartNumber, categoryIndex]);
    const endCoords = api.coord([dateEndNumber, categoryIndex]);
    const eventLength = endCoords[0] - startCoords[0];
    // Get the heigth corresponds to length 1 on y axis.
    const x = startCoords[0];
    const y = endCoords[1];

    const eventLabel = api.value(HistoricalChartDimensions.Label) + '';
    const eventLabelWidth = echarts.format.getTextRect(eventLabel).width;
    const text = eventLength > eventLabelWidth + 40 && x + eventLength >= 80 ? eventLabel : '';

    const shapeType = 'rect';
    const shape = this.clipRectByRect(params, {
      x,
      y,
      width: eventLength,
      height: this._eventHeight,
    });

    const event = {
      type: shapeType,
      ignore: !shape,
      shape,
      style: api.style({}),
    };

    const labelOverlay = {
      type: shapeType,
      ignore: !shape,
      shape,
      style: {
        fill: 'transparent',
        stroke: 'transparent',
        text,
        textFill: '#FFF',
      },
    };

    return {
      type: 'group',
      children: [event, labelOverlay],
    };
  };

  private renderTooltip(params) {
    const eventTooltip = params.value[HistoricalChartDimensions.Tooltip];
    return eventTooltip;
  }

  private buildXAxes(dataParameters: HistoricalChartDataParameters): GChartAxis[] {
    const { startDate, endDate } = dataParameters;
    const axes = [
      new GChartAxis({
        type: 'time',
        position: 'top',
        boundaryGap: false,
        min: startDate,
        max: endDate,
      }),
      new GChartAxis({
        type: 'time',
        position: 'bottom',
        boundaryGap: false,
        splitLine: new GChartAxisSplitLine({ show: false }),
        min: startDate,
        max: endDate,
        axisLabel: new GChartAxisLabel({
          show: false,
        }),
        axisTick: new GChartAxisTick({ show: false }),
      }),
    ];

    return axes;
  }

  private buildYAxes(): GChartAxis[] {
    const defaultYAxis = new GChartAxis({
      type: 'category',
      axisTick: new GChartAxisTick({ show: false }),
      splitLine: new GChartAxisSplitLine({ show: false }),
      axisLine: new GChartAxisLine({ show: true }),
      axisLabel: new GChartAxisLabel({ show: false }),
    });

    return [defaultYAxis, defaultYAxis];
  }

  private buildDataZoom(processedEvents: any[][]): GChartDataZoom[] {
    return [
      new GChartDataZoom({
        id: 'sliderX',
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'weakFilter',
        height: this._dataZoomSize,
        handleSize: this._dataZoomSize,
        moveHandleSize: 0,
        showDetail: false,
      }),
      new GChartDataZoom({
        id: 'sliderY',
        brushSelect: false,
        endValue: processedEvents.length - this._eventWindow,
        handleSize: 0,
        moveHandleSize: 20,
        showDetail: false,
        startValue: processedEvents.length - 1,
        top: this._verticalToolingSpace,
        type: 'slider',
        yAxisIndex: 0,
        zoomLock: true,
        filterMode: 'filter',
      }),
    ];
  }
  private clipRectByRect = (params, rect) => {
    return echarts.graphic.clipRectByRect(rect, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    });
  };

  private setEventColors() {
    this._errorColor = this._colorService.getErrorColor();
    this._selectedColor = this._colorService.getPrimaryColor();
    this._editedColor = this._colorService.getWarningColor();
    this._defaultColor = this._colorService.getSecondaryColor();

    this._errorEventStyles = {
      ...this._basicEventStyles,
      color: this._errorColor,
    };

    this._editedEventStyles = {
      ...this._basicEventStyles,
      color: this._editedColor,
    };

    this._selectedEventStyles = {
      ...this._basicEventStyles,
      color: this._selectedColor,
    };
    this._defaultEventStyles = {
      ...this._basicEventStyles,
      color: this._defaultColor,
    };
  }
}
