import { EventChartQueryDto } from '../events-chart/models/events-chart-query.dto';
import { ChartSettings } from './chart-settings';
import { PeriodTypesEnum } from './period-types.enum';
import { TimeSelectorPeriod } from './time-selector-period';

export class TimeSelectorChartSettings {
  chartSetting!: ChartSettings;
  includeDefaultPeriods!: boolean;
  eventChartSetting?: EventChartQueryDto;
  customPeriods?: TimeSelectorPeriod[];
  fiscalYears?: number;
  defaultSelectedPeriodType?: PeriodTypesEnum;

  public constructor(init?: Readonly<TimeSelectorChartSettings>) {
    Object.assign(this, init);
  }
}
