import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { ArrayValuesFAdapter } from './array-values-f-adapter';

export class TimeAggregationFAdapter extends ArrayValuesFAdapter {
  constructor(init: {
    dataBindingField: string;
    fieldName: string;
    tabParameter: TabDetailParameterName;
    multiple: boolean;
  }) {
    super(init);
    this.id = FilterAdapterEnum.TimeAggregation;
  }
}
