import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  IterableDiffers,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '../../app-modules.enum';
import { BaseComponent } from '../../component/base.component';
import { AllowDropCallbackData, DragListCustomSettings } from './drag-list-custom-settings';

const COMPONENT_SELECTOR = 'wlm-drag-list-custom';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './drag-list-custom.component.html',
  styleUrls: ['./drag-list-custom.component.scss'],
})
export class DragListCustomComponent extends BaseComponent implements OnInit {
  @ContentChild('card', { static: false }) cardTemplateRef: TemplateRef<any>;

  private _objectsToOrder: any[];

  public get objectsToOrder(): any[] {
    return this._objectsToOrder;
  }
  @Input() public set objectsToOrder(v: any[]) {
    this._objectsToOrder = v;
    this.objectsFiltered = v;
    this.objectsToOrderChanged.emit(this.objectsFiltered);
  }

  @Input() previewItemClass: string;

  @Input() selectedObject: any;

  @Input() hideFilter = false;

  private _settings: DragListCustomSettings;
  public get settings(): DragListCustomSettings {
    return this._settings;
  }
  @Input() public set settings(value: DragListCustomSettings) {
    this._settings = value;
    this.emptyLegendKey = this.settings?.emptyLegendKey
      ? this.settings?.emptyLegendKey
      : `${this.T_SCOPE}.messages.empty-message`;
    this.defineCssClasses();
  }

  @Input() removeDraggedItemFromSource$ = new Subject<boolean>();

  @Output() objectsToOrderChanged = new EventEmitter<any[]>();

  @Output() selectedObjectChange = new EventEmitter<any>();

  @Output() droppedElement = new EventEmitter<any>();
  @Output() reorderedElements = new EventEmitter<any[]>();

  @Output() somethingChanged = new EventEmitter<void>();

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  filterValue: string;
  objectsFiltered: any[];
  iterableDiffer: any;
  emptyLegendKey: string;
  itemClass: string[] = [];

  private _lastDropEventArgs: CdkDragDrop<any[]>;

  constructor(iterableDiffers: IterableDiffers) {
    super();
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    if (this.removeDraggedItemFromSource$) {
      this.removeDraggedItemFromSource$
        .pipe(untilDestroyed(this))
        .subscribe((result) => this.removeDraggedItemFromSource(this._lastDropEventArgs, result));
    }
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.objectsToOrder);
    if (changes) {
      this.filterList(null);
    }
  }

  removeDraggedItemFromSource(
    _lastDropEventArgs: CdkDragDrop<any[], any[], any>,
    mustRemove: boolean
  ): void {
    if (mustRemove && this._lastDropEventArgs) {
      const deleted = [];
      transferArrayItem(
        this._lastDropEventArgs.previousContainer.data,
        deleted,
        this._lastDropEventArgs.item.data,
        0
      );
    } else {
      this._lastDropEventArgs = null;
    }
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reorderedElements.emit(event.container.data);
    } else {
      const newItem = event.previousContainer.data[event.item.data];

      if (this.settings.useCallback) {
        this.settings.beforeDropCallback(newItem).subscribe({
          next: (result) => {
            this._lastDropEventArgs = event;
            this.droppedElement.emit(result);
          },
        });
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.item.data,
          event.currentIndex
        );

        this.droppedElement.emit(newItem);
      }
    }
    this.somethingChanged.emit();
  }

  defineCssClasses() {
    this.itemClass = [];
    const newItemClass = this.settings.useSingleContainer
      ? ['item-box']
      : ['item-box', 'item-box-border'];

    if (!this.settings.isReadOnly) {
      newItemClass.push('cursor-draggable');
    }
    this.itemClass = newItemClass;
  }

  selectItem(item: any) {
    this.selectedObject = item === this.selectedObject ? undefined : item;
    this.selectedObjectChange.emit(this.selectedObject);
  }

  filterList($event) {
    if (this.filterValue === '' || this.filterValue == undefined) {
      this.objectsFiltered = this.objectsToOrder;
      this.objectsToOrderChanged.emit(this.objectsFiltered);
      return;
    }

    const filterTolower = this.filterValue?.toLowerCase();
    this.objectsFiltered = this.objectsToOrder.filter((f) =>
      f.title?.toLowerCase().includes(filterTolower)
    );
    this.objectsToOrderChanged.emit(this.objectsFiltered);
  }

  allowDrop = (drag: CdkDrag, dropList: CdkDropList) => {
    if (this.settings.allowDropCallback) {
      return this.settings.allowDropCallback({
        drag,
        dropList,
      } as AllowDropCallbackData);
    }

    return true;
  };
}
