<div class="account-card">
  <mat-card class="card-structure">
    <div class="header-structure">
      <div mat-card-avatar class="avatar">
        <img mat-card-image src="../../../assets/images/avatar-person.svg" alt="user photo" />
      </div>
      <div class="title-subtitle">
        <mat-card-title>{{ this.authService.userCode }}</mat-card-title>
        <mat-card-subtitle [matTooltip]="this.authService.user | async">
          <div class="subtitle-item">{{ this.authService.user | async }}</div></mat-card-subtitle
        >
        <mat-card-subtitle [matTooltip]="this.authService.email">
          <div class="subtitle-item">{{ this.authService.email }}</div></mat-card-subtitle
        >
      </div>
    </div>
    <div>
      <mat-divider></mat-divider>
    </div>
    <div class="actions">
      <button mat-menu-item (click)="navigatetoUserPage()">
        <span>{{ T_SCOPE + '.personal-info' | translate }}</span>
        <mat-icon>perm_identity</mat-icon>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="langMenu">
        <span>{{ T_SCOPE + '.language' | translate }}</span>
        <div>
          <mat-icon>translate</mat-icon>
          <mat-menu #langMenu="matMenu" class="lang-menu">
            <button
              mat-menu-item
              *ngFor="let lang of langs"
              (click)="setLocale(lang.locale)"
              class="lang-option"
              [ngClass]="{ 'lang-selected': lang.locale === currentLocale }"
            >
              <img width="32" height="32" [src]="'assets/images/flags/' + lang.locale + '.png'" />
              <span>
                {{ lang.name }}
              </span>
            </button>
          </mat-menu>
        </div>
      </button>
      <button mat-menu-item (click)="openVersionPopup()">
        <span>{{ T_SCOPE + '.version-info' | translate }}</span>

        <mat-icon class="material-icons-outlined">info</mat-icon>
      </button>
      <button mat-menu-item (click)="openHomePageConfigurationPopup()">
        <span>{{ T_SCOPE + '.home-configuration' | translate }}</span>
        <mat-icon>home</mat-icon>
      </button>
      <button
        *hasPerm="['WLMNotificationsCrud', 'r']"
        mat-menu-item
        (click)="openNotificationConfigurationPopup()"
      >
        <span>{{ T_SCOPE + '.notification-configuration' | translate }}</span>
        <mat-icon>notifications</mat-icon>
      </button>
      <button *isSuperUser="" mat-menu-item (click)="goToIntegrationPage()">
        <span>{{ T_SCOPE + '.integration' | translate }}</span>
        <wlm-icon [icon]="'integration'"></wlm-icon>
      </button>
      <button mat-menu-item (click)="cleanCache()">
        <span>{{ T_SCOPE + '.clean-cache' | translate }}</span>
        <mat-icon>cached</mat-icon>
      </button>

      <button mat-menu-item class="menu-option-slider" (click)="toggleDarkTheme()">
        <span>{{ T_SCOPE + '.dark-theme' | translate }}</span>
        <mat-slide-toggle color="primary" [checked]="darkThemeSliderChecked"></mat-slide-toggle>
      </button>

      <button mat-menu-item (click)="logOut()">
        <span>{{ T_SCOPE + '.sign-out' | translate }}</span>
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </mat-card>
</div>
