import { EventEmitter, Injector } from '@angular/core';
// prettier-ignore
import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor, SortDescriptor, State, toODataString } from '@progress/kendo-data-query';
import { asEnumerable } from 'linq-es2015';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdditionalHttpOpts } from '../../cache/http-cache/additional-http-options';
import { GetCacheOpts, HttpCacheClient } from '../../cache/http-cache/http-cache.client';
import { SettingsService } from '../config/settings.service';
import { AdditionalFilter } from '../filters/component-filters/aditional-filter';
import { DataBindingFilters } from '../filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '../helpers/array-helper.service';
import { DateHelperService } from '../helpers/date-helper.service';
import { globalUtilsHelper } from '../helpers/global-utils-helper';
import { DynamicPageResultDto, PagedResultDto } from '../model/paged-result.dto';

export abstract class GridODataService<TResponse> extends BehaviorSubject<
  PagedResultDto<TResponse>
> {
  public loading: boolean;
  protected settingsService: SettingsService;
  protected httpCacheClient: HttpCacheClient;
  protected dateHelper: DateHelperService;
  protected _arrayHelperService: ArrayHelperService;

  loadingChanged = new EventEmitter<boolean>();

  constructor(injector: Injector, protected api: string) {
    super(null);
    this.settingsService = injector.get(SettingsService);
    this.httpCacheClient = injector.get(HttpCacheClient);
    this.dateHelper = injector.get(DateHelperService);
    this._arrayHelperService = injector.get(ArrayHelperService);
  }

  get baseUrl() {
    return this.settingsService.apis.default.url;
  }

  get apiUrl() {
    return `${this.baseUrl}/api`;
  }

  protected mapResponse(
    response: PagedResultDto<TResponse> | DynamicPageResultDto<TResponse>
  ): PagedResultDto<TResponse> {
    return response as PagedResultDto<TResponse>;
  }

  public query(
    columns: string[] = null,
    state: State,
    filters: DataBindingFilters,
    count: boolean,
    additionalFilters?: Map<string, any>,
    cache: GetCacheOpts = { minutes: 'default' },
    addHttpOptions: AdditionalHttpOpts = {},
    subscriptionTag = 'default',
    ignoreCase: boolean = false,
    utcDates: boolean = false,
    defaultSortDescriptors: SortDescriptor[] = [],
    isHistorical = false,
    baseDate: Date = null,
    comparisonDate: Date = null,
    conditionalSorting: SortDescriptor[] = []
  ): Subscription {
    const query$ = this.getQuery(
      columns,
      state,
      filters,
      count,
      additionalFilters,
      cache,
      addHttpOptions,
      ignoreCase,
      utcDates,
      defaultSortDescriptors,
      isHistorical,
      baseDate,
      comparisonDate,
      conditionalSorting
    ).subscribe({
      next: (x) => {
        x.subscriptionTag = subscriptionTag;
        super.next(x);
      },
      error: (err) => {
        this.loadingChanged.emit(false);
      },
    });
    return query$;
  }

  public getQuery(
    columns: string[] = null,
    state: State,
    filters: DataBindingFilters,
    count: boolean,
    additionalFilters?: Map<string, any>,
    cache: GetCacheOpts = { minutes: 'default' },
    addHttpOptions: AdditionalHttpOpts = {},
    ignoreCase: boolean = false,
    utcDates: boolean = false,
    defaultSortDescriptors: SortDescriptor[] = [],
    isHistorical = false,
    baseDate: Date = null,
    comparisonDate: Date = null,
    conditionalSorting: SortDescriptor[] = []
  ): Observable<PagedResultDto<TResponse>> {
    return this.fetch(
      this.api,
      columns,
      state,
      filters,
      count,
      cache,
      addHttpOptions,
      additionalFilters,
      ignoreCase,
      utcDates,
      defaultSortDescriptors,
      isHistorical,
      baseDate,
      comparisonDate,
      conditionalSorting
    );
  }

  getQueryByFilterOnly(
    state: State,
    filters: DataBindingFilters
  ): Observable<PagedResultDto<TResponse>> {
    const queryStr = `${this.getODataString(state, filters, true, true)}`;

    this.loadingChanged.emit(true);
    this.loading = true;

    return this.httpCacheClient
      .get<PagedResultDto<TResponse>>(`${this.baseUrl}${this.api}?${queryStr}`)
      .pipe(
        map((response) => this.mapResponse(response)),
        tap(() => {
          this.loading = false;
          this.loadingChanged.emit(false);
        })
      );
  }

  protected fetch(
    api: string,
    columns: string[] = null,
    state: State,
    filters: DataBindingFilters,
    count: boolean,
    cache: GetCacheOpts = { minutes: 'default' },
    addHttpOptions: AdditionalHttpOpts = {},
    additionalFilters: Map<string, any>,
    ignoreCase: boolean = false,
    utcDates: boolean = false,
    defaultSortDescriptors: SortDescriptor[] = [],
    isHistorical = false,
    baseDate: Date = null,
    comparisonDate: Date = null,
    conditionalSorting: SortDescriptor[] = []
  ): Observable<PagedResultDto<TResponse>> {
    let queryState = globalUtilsHelper.clone(state, false);

    queryState = queryState || { skip: 0, take: 100 };

    if (
      (!queryState?.sort?.length ||
        asEnumerable(queryState.sort).All((x) => x.dir == undefined || x.dir == null)) &&
      defaultSortDescriptors?.length
    ) {
      queryState.sort = defaultSortDescriptors;
    }

    if (conditionalSorting.length) {
      queryState.sort = this._arrayHelperService.onlyUnique([
        ...conditionalSorting,
        ...queryState.sort,
      ]);
    }

    const queryStr = `${this.getODataString(
      queryState,
      filters,
      count,
      ignoreCase,
      utcDates
    )}${this.getSelect(columns)}`;
    this.loadingChanged.emit(true);
    this.loading = true;
    const additionalQueryParams = this.getAdditionalQueryString(additionalFilters);

    const historicalParams = {
      isHistorical,
    };

    if (baseDate) {
      historicalParams['baseDate'] = this.dateHelper.toApiFormatNoUTC(baseDate);
    }
    if (comparisonDate) {
      historicalParams['comparisonDate'] = this.dateHelper.toApiFormatNoUTC(comparisonDate);
    }

    return this.httpCacheClient
      .get<PagedResultDto<TResponse>>(
        `${this.baseUrl}${api}?${additionalQueryParams}${queryStr}`,
        isHistorical ? { avoid: true } : cache,
        historicalParams,
        addHttpOptions
      )
      .pipe(
        map((response) => this.mapResponse(response)),
        tap(() => {
          this.loading = false;
          this.loadingChanged.emit(false);
        })
      );
  }

  private getAdditionalQueryString(additionalFilters: Map<string, any>) {
    if (!additionalFilters?.size) {
      return '';
    }

    const additionalFilterss: AdditionalFilter[] =
      this.getAdditionalFiltersValues(additionalFilters);

    const result = additionalFilterss.map((filter) => `${filter.field}=${filter.value}`).join('&');
    return `${result}&`;
  }

  private getAdditionalFiltersValues(additionalFilters: Map<string, any>) {
    const additionalFilterss: AdditionalFilter[] = [];

    const keys = Array.from(additionalFilters?.keys());

    keys.forEach((key) => {
      var filterValue = additionalFilters.get(key);
      if (Array.isArray(filterValue)) {
        Array.from(filterValue).forEach((value) => {
          additionalFilterss.push(new AdditionalFilter({ field: key, value: value }));
        });
      } else {
        additionalFilterss.push(new AdditionalFilter({ field: key, value: filterValue }));
      }
    });

    return additionalFilterss;
  }

  private getODataString(
    state: State,
    dataBindingFilters: DataBindingFilters,
    count: boolean,
    ignoreCase: boolean = false,
    utcDates: boolean = false
  ): string {
    const compositeFilters = this.getCompositeFilters(dataBindingFilters);

    const newFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

    if (state?.filter && state?.filter?.filters?.length > 0) {
      const extendedStateFilters = this.getStateFilters(state?.filter?.filters);
      newFilter.filters.push(extendedStateFilters);
    }

    if (compositeFilters?.length) {
      newFilter.filters.push(...compositeFilters);
    }

    newFilter.filters.forEach((f) => {
      f['ignoreCase'] = ignoreCase;
    });

    const newState: State = {};
    newState.filter = newFilter;

    if (state) {
      newState.group = state.group;
      newState.skip = state.skip;
      newState.sort = state.sort;
      newState.take = state.take;
    }

    let odataString = toODataString(newState, { utcDates: utcDates });

    odataString = this.formatGuid(odataString);

    if (count) {
      odataString = `${odataString}&$count=true`;
    }

    if (ignoreCase) {
      odataString = `${odataString}&ignoreCase=true`;
    }

    return odataString;
  }

  private getStateFilters(
    filters: (CompositeFilterDescriptor | FilterDescriptor)[]
  ): CompositeFilterDescriptor {
    const extendedFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };

    filters.forEach((filter) => {
      if (!isCompositeFilterDescriptor(filter)) {
        extendedFilters.filters.push({ ...filter });
        return;
      }

      const compositeFilter = filter as CompositeFilterDescriptor;
      const filterDescriptors = compositeFilter.filters as FilterDescriptor[];

      const dateEqFilter = filterDescriptors?.find(
        (f) => f.operator === 'eq' && this.dateHelper.isDateObject(f.value)
      );

      if (dateEqFilter) {
        const dateEqNewFilter = this.getEqFilterForDates(dateEqFilter);
        extendedFilters.filters.push(dateEqNewFilter);
      } else {
        extendedFilters.filters.push({ ...filter });
      }
    });

    return extendedFilters;
  }

  private getEqFilterForDates(filter: FilterDescriptor): CompositeFilterDescriptor {
    const endDate = this.dateHelper.addDays(filter.value, 1);

    return {
      logic: 'and',
      filters: [
        { field: filter.field, operator: 'gte', value: filter.value },
        { field: filter.field, operator: 'lt', value: endDate },
      ],
    };
  }

  private formatGuid(odataString: string): string {
    const guidRegExp = new RegExp(
      "({){0,1}'[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'(}){0,1}",
      'g'
    );
    const guidAvoidRegExp = new RegExp(
      "(contains\\(elementId,|(elementId)(\\s)(\\w+)(\\s))'[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'",
      'g'
    );

    const matches = odataString.match(guidRegExp);
    const matchesAvoid = odataString.match(guidAvoidRegExp);
    if (matches && (!matchesAvoid || matchesAvoid?.length === 0)) {
      matches.forEach((m) => {
        if (m) {
          const r = m.replace(/'/g, '');
          odataString = odataString.replace(m, r);
        }
      });
    }
    return odataString;
  }

  private getCompositeFilters(dataBindingFilters: DataBindingFilters): CompositeFilterDescriptor[] {
    const compositeFilters = asEnumerable(dataBindingFilters.filters.values())
      .Select((f) => f.getFilters())
      .Where((f) => f !== undefined && f != null)
      .ToArray();

    return compositeFilters;
  }

  private getSelect(columns: string[]): string {
    columns = columns ?? [];

    if (columns.length === 0) {
      return '';
    }

    return `&$select=${columns.join(',')}`;
  }
}
