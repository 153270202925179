import { ChartSerieTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-serie-type.enum';

export interface ISerieCustomization {
  name: string;
  title: string;
  type: ChartSerieTypeEnum;
  hexColor: string;
  verticalAxisId: number;
  horizontalAxisId: number;
  unitTypeFromId: number;
  showInUtc: boolean;
  showAlwaysInTooltip: boolean;
}

export class SerieCustomization implements ISerieCustomization {
  name: string;
  title: string;
  type: ChartSerieTypeEnum;
  hexColor: string;
  verticalAxisId: number;
  horizontalAxisId: number;
  unitTypeFromId: number;
  showInUtc: boolean;
  showAlwaysInTooltip: boolean;

  constructor(init: Partial<SerieCustomization>) {
    Object.assign(this, init);
  }
}
