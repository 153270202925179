import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { IActivityTypeDto } from 'src/app/common-modules/dependencies/alc/activity-type.dto';
import { ISapCodeGroupDto } from 'src/app/common-modules/dependencies/alc/sap-code-group.dto';
import { ISapCodeDto } from 'src/app/common-modules/dependencies/alc/sap-code.dto';
import { HierarchyElementFiltersDataDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { IHierarchyElementPathDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-path.dto';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { ApplicationAttributes } from 'src/app/common-modules/shared/constants/application-constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { ICanLeavePage } from 'src/app/common-modules/shared/navigation/can-component-deactivate';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { TreeSettingsService } from 'src/app/common-modules/shared/services/tree-settings.service';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import * as constants from '../../../../common-modules/dependencies/shared/hierarchy-tree-filter-constants';
import { ITreeSettings } from '../../../../common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { EligibilityService } from '../../../../common-modules/shared/component/eligibility-popup/eligibility.service';
import { CustomerDetailsService } from '../../customer/services/customer-details.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { CoordinatesSystem } from '../../shared/gis/coordinates-system.enum';
import { CoordinatesSystemService } from '../../shared/gis/coordinates-system.service';
import { IActivitiesRaiseCmd } from '../../shared/model/alc/activities-raise-cmd';
import { IActivityRaiseCmd } from '../../shared/model/alc/activity-raise-cmd';
import { ActivityRaiseType } from '../../shared/model/alc/activity-raise-type';
import { CustomerShortDto } from '../../shared/model/customer/customer-short.dto';
import { ActivitiesRaiseCmd } from '../activities-raise-cmd';
import { ActivityRaiseCmd } from '../activity-raise-cmd';
import { ActivityRaiseService } from '../activity-raise.service';
import { RaiseActivityTreeFilterConfiguration } from './raise-activity-components/raise-activity-tree-filter/raise-activity-tree-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-raise-activity-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './raise-activity-page.component.html',
  styleUrls: ['./raise-activity-page.component.scss'],
})
export class RaiseActivityPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesEmitter, IPendingChangesChecker, OnDestroy, ICanLeavePage {
  pageId: string = 'RaiseActivityPage';

  activity: IActivityRaiseCmd;
  activities: IActivitiesRaiseCmd;
  errorMessage: Error;
  private _activityTypeId: number;
  activityTypes: IActivityTypeDto[];
  unselectableTypes: string[] = [];
  sapCodes: ISapCodeDto[];
  sapCodeGroups: ISapCodeGroupDto[];
  visibleLayersIds: number[] = [1];

  selectedHierarchyElementIds: string[];
  selectedHierarchyFamilyId: string;

  newActivityFormGroup: UntypedFormGroup;
  public mask: any = {
    mask: '000000-000000',
    lazy: false,
  };

  maskLat: any = {
    mask: Number,
    lazy: false,
    scale: 6,
    signed: true,
    radix: '.',
    mapToRadix: [','],
  };

  maskLong: any = {
    mask: Number,
    lazy: false,
    scale: 6,
    signed: true,
    radix: '.',
    mapToRadix: [','],
  };

  // Fields for coordinates system
  coordinatesSystem = CoordinatesSystem.EastingNorthing;
  eastingNorthingSystem = CoordinatesSystem.EastingNorthing;
  latLongSystem = CoordinatesSystem.LatLong;

  houseNumberMaxLength = 10;
  streetMaxLength = 60;
  districtMaxLength = 40;
  cityMaxLength = 40;
  eastingNorthingMaxLength = 10;
  longitudeMaxValue = 180;
  latitudeMaxValue = 90;
  maximumSelectedZones = ApplicationAttributes.MaxItemsForRaiseActivities;
  isSaving = false;
  convertingCoordinates = false;
  searchingCustomer = false;
  clearAll$ = new Subject<void>();
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  autoloadPerformed = false;

  public T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  raiseActivityTreeFilterConfiguration: RaiseActivityTreeFilterConfiguration;

  treeSettings: ITreeSettings = null;

  private _isMaximumZonesExceeded = false;
  public get isMaximumZonesExceeded(): boolean {
    if (this.selectedNodes === null) {
      return false;
    }
    this._isMaximumZonesExceeded = this.selectedNodes?.length > this.maximumSelectedZones;
    return this._isMaximumZonesExceeded;
  }
  private _isMultipleSelection = false;
  public get isMultipleSelection(): boolean {
    return this._isMultipleSelection;
  }
  public set isMultipleSelection(value) {
    this._isMultipleSelection = value;
  }

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
  }

  private _selectedNodes: IHierarchyElementPathDto[];
  public get selectedNodes(): IHierarchyElementPathDto[] {
    return this._selectedNodes;
  }
  public set selectedNodes(value: IHierarchyElementPathDto[]) {
    this._selectedNodes = value ?? [];

    this.newActivityFormGroup.get('selectedNodes').setValue(this.selectedNodes);

    if (this.selectedNodes.length > 1 && this.isMultipleSelection === false) {
      this.isMultipleSelection = true;
      this.disableAddressFields();
    } else if (this.selectedNodes.length === 1 && this.isMultipleSelection === true) {
      this.isMultipleSelection = false;
      this.enableAddressFields();
    }

    if (this.newActivityFormGroup.get('selectedNodes')) {
      (this.newActivityFormGroup.get('selectedNodes') as AbstractControl).updateValueAndValidity();
    }
  }

  private _hasChanges = false;
  public get hasChanges() {
    return this._hasChanges;
  }
  public set hasChanges(value) {
    this._hasChanges = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges());
  }

  public get isLoading(): boolean {
    return (
      this.activityTypes?.length < 0 ||
      this.sapCodes?.length < 0 ||
      this.sapCodeGroups?.length < 0 ||
      this.isSaving ||
      this.convertingCoordinates
    );
  }

  get activityTypeId(): number {
    return this._activityTypeId;
  }
  set activityTypeId(value) {
    this._activityTypeId = value;
    const selectedActivity = this.activityTypes.find(
      (c) => c.activityTypeId === this._activityTypeId
    );

    const sapCode = selectedActivity?.sapCode?.codeValue;
    const sapCodeGroup = selectedActivity?.sapCode?.sapCodeGroup?.codeGroupValue;
    const notificationText = selectedActivity?.activityTypeName;
    this.newActivityFormGroup.controls.notificationShortTextControl.setValue(notificationText);
    this.newActivityFormGroup.controls.sapCodeControl.setValue(sapCode);
    this.newActivityFormGroup.controls.sapCodeGroupControl.setValue(sapCodeGroup);
  }

  public get pageCrud(): string {
    return 'ActivityRaisingPageCrud';
  }

  get componentName(): string {
    return 'RaiseActivityPageComponent';
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  customersLocated: CustomerShortDto[];

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private _commonVisibleLayerIds = [
    1, 777003, 777002, 777011, 777012, 777001, 777111, 777112, 777101, 777102, 777104,
  ];
  private _commonLeakYears = [0, -1];

  private readonly _enterKey = 'Enter';
  private readonly _minLengthToSearch = 3;

  constructor(
    public dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private globalService: GlobalsService,
    private activityRaiseService: ActivityRaiseService,
    private ngZone: NgZone,
    private eligibilityService: EligibilityService,
    private cacheService: HttpCacheService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private treeSettingsService: TreeSettingsService,
    private _globalsService: GlobalsService,
    private _mapHelperService: MapHelperService,
    private _coordinatesSystemService: CoordinatesSystemService,
    private _pendingChangesService: PendingChangesManagerService,
    private _rightPanelService: RightPanelService,
    private _customerDetailsService: CustomerDetailsService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeRightPanelCallback();
    this.setCoordinatesSystem();
    this.createForm();
    this.loadActivityTypes();
    this.loadASapCodes();
    this.loadSapCodeGroups();

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          if (filterData?.hierarchyFamilyId) {
            this.selectedHierarchyFamilyId = filterData.hierarchyFamilyId;
          }

          this.buildTreeSettings();
        });
    });
  }

  init(): void {
    this.loadState();
    this.endLoading();
    this.ngZone.run(() => {
      this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
        const panelSettings = new TabDetailPanelSettings();
        panelSettings.showHeaders = false;
        panelSettings.addComponent(MapRelatedComponent, ts.map);
        this.rightPanelService.setTabSettings(panelSettings);

        this.updateDetailsParameters(this.selectedHierarchyElementIds);
      });
    });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private subscribeRightPanelCallback() {
    this._rightPanelService
      .rightPanelCallbackObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (payload) => {
          if (payload?.has(MapRelatedComponent)) {
            var coordinates = payload?.get(MapRelatedComponent);
            this.updateCoordinatesInFormFields(coordinates);
          }
        },
      });
  }

  private updateCoordinatesInFormFields(coords) {
    if (this.coordinatesSystem === CoordinatesSystem.EastingNorthing) {
      this.convertingCoordinates = true;
      this._coordinatesSystemService
        .getLatLongToUTMFormatted(coords.lat, coords.lng)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (payload) => {
            this.newActivityFormGroup.controls.eastingNorthingControl.setValue(payload);
            this.convertingCoordinates = false;
          },
          error: (error) => {
            this.dialogService.showErrorMessage(error);
            this.convertingCoordinates = false;
          },
        });
    } else {
      this.newActivityFormGroup.controls.latitudeControl.setValue(coords.lat.toString());
      this.newActivityFormGroup.controls.longitudeControl.setValue(coords.lng.toString());
    }
  }

  private updateDetailsParameters(selectedIds: string[]) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = new TabDetailPanelParameters();
      let title = null;
      // On page load, map params are only loaded if they come from persistence
      const mapParameters = this.initializeMapParameters();

      if (selectedIds && selectedIds.length) {
        mapParameters.hierarchyElements = selectedIds;
        mapParameters.visibleLayersIds = [
          ...new Set([...mapParameters.visibleLayersIds, ...this._commonVisibleLayerIds]),
        ];
        mapParameters.leakYears = [
          ...new Set([...mapParameters.leakYears, ...this._commonLeakYears]),
        ];

        title = ts['map-title'];
      }

      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);
      parameters.addParameter(TabDetailParameterName.elementName, title);

      this.rightPanelService.setTabParameters(parameters);
    });
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  public get selectedHierarchyElements(): string[] {
    return this?.selectedNodes?.map((x) => x.descendant) ?? [];
  }

  disableAddressFields(): void {
    this.newActivityFormGroup.controls.streetControl.reset();
    this.newActivityFormGroup.controls.street2Control.reset();
    this.newActivityFormGroup.controls.houseNumberControl.reset();
    this.newActivityFormGroup.controls.districtControl.reset();
    this.newActivityFormGroup.controls.cityControl.reset();
    this.newActivityFormGroup.controls.eastingNorthingControl?.reset();
    this.newActivityFormGroup.controls.longitudeControl?.reset();
    this.newActivityFormGroup.controls.latitudeControl?.reset();
    this.newActivityFormGroup.controls.streetControl.disable();
    this.newActivityFormGroup.controls.street2Control.disable();
    this.newActivityFormGroup.controls.houseNumberControl.disable();
    this.newActivityFormGroup.controls.districtControl.disable();
    this.newActivityFormGroup.controls.cityControl.disable();
    this.newActivityFormGroup.controls.eastingNorthingControl?.disable();
    this.newActivityFormGroup.controls.longitudeControl?.disable();
    this.newActivityFormGroup.controls.latitudeControl?.disable();
  }

  enableAddressFields(): void {
    this.newActivityFormGroup.controls.streetControl.enable();
    this.newActivityFormGroup.controls.street2Control.enable();
    this.newActivityFormGroup.controls.houseNumberControl.enable();
    this.newActivityFormGroup.controls.districtControl.enable();
    this.newActivityFormGroup.controls.cityControl.enable();
    this.newActivityFormGroup.controls.eastingNorthingControl.enable();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) { }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  getFiltersDetailsParameters(filters: Map<string, BasicFilter>) {
    if (filters.has('HierarchyFamilyId')) {
      const filter = filters.get('HierarchyFamilyId');
      this.selectedHierarchyFamilyId = filter.value ? filter.value[0]?.value : null;
    }
  }

  loadState() {
    const filters = [];

    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.getPersisted(
        this.hierarchyElementFamilyFieldName,
        undefined,
        null,
        false
      );
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }

    // If selected elements are available, then they are coming from navigation, so they have priority over persisted ones.
    if (!this.selectedHierarchyElementIds || this.selectedHierarchyElementIds.length === 0) {
      this.selectedHierarchyElementIds = (
        this.getPersisted(
          this.hierarchyElementIdFieldName,
          [{ value: '', label: '' }],
          null,
          false
        ) as BasicFilter
      ).value;
    }

    this.globalService
      .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
      .subscribe((familyId) => {
        this.selectedHierarchyFamilyId = familyId;

        this.loadPage(filters);
      });
  }

  private loadPage(filters: IFilter[]) {
    const configuration = new RaiseActivityTreeFilterConfiguration({
      persistencyArea: this.persistencyArea,
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
      hierarchyElementId: this.selectedHierarchyElementIds,
      hierarchyElementFamily: this.selectedHierarchyFamilyId,
      filters: filters,
    });
    this.raiseActivityTreeFilterConfiguration = configuration;
  }

  loadActivityTypes() {
    this.globalService.getActivityTypes().subscribe({
      next: (activityTypes) => {
        this.activityTypes = activityTypes.filter((act) => act.isActivityRaising);
      },
      error: (error) => {
        this.dialogService.showErrorMessage(error);
      },
    });
  }

  loadASapCodes() {
    this.globalService.getSapCodes().subscribe({
      next: (sapCodes) => {
        this.sapCodes = sapCodes;
      },
      error: (error) => {
        this.dialogService.showErrorMessage(error);
      },
    });
  }

  loadSapCodeGroups() {
    this.globalService.getSapCodeGroups().subscribe({
      next: (sapCodeGroups) => {
        this.sapCodeGroups = sapCodeGroups;
      },
      error: (error) => {
        this.dialogService.showErrorMessage(error);
      },
    });
  }

  onApplyFilter(): void {
    // On Apply, the map always receives the data from the selectedNodes.
    this.updateDetailsParameters(this.selectedHierarchyElements);
  }

  /**
   * Build the tree settings when the translations are ready.
   */
  buildTreeSettings(): void {
    forkJoin([
      this.eligibilityService.getIneligibleZones(),
      this.globalService.getHierarchyElementTypes(),
    ]).subscribe(([zones, heTypes]) => {
      this.unselectableTypes = heTypes
        .filter(
          (heType) =>
            heType.networkElementTypeId !== undefined &&
            heType.hierarchyElementTypeName !== constants.hierarchyElementTypes.DMA
        )
        .map((x) => x.hierarchyElementTypeName);

      this.treeSettingsService
        .buildTreeSettings({
          unselectableNodes: zones,
          unselectableTypes: this.unselectableTypes,
          focusDelay: 1000,
          defaultSelectedNodes: [],
        })
        .subscribe((settings) => {
          this.treeSettings = settings;
          this.validateIneligibleZones(zones);
          this.init();
        });
    });
  }

  validateIneligibleZones(zones: string[]) {
    if (
      this.selectedHierarchyElementIds?.length > 0 &&
      this.selectedHierarchyElementIds?.some((x) => zones.includes(x))
    ) {
      this.dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.has-ineligible-zones` })
      );
    }
  }

  // selectedNodesChange
  onFilter(filters): void {
    const nodes = filters;
    this.newActivityFormGroup.get('selectedNodes').setValue(nodes);
  }

  onKeyDownEvent($event: KeyboardEvent) {
    if ($event.key == this._enterKey && this.isValidSearch()) {
      this.searchCustomers(this.newActivityFormGroup.controls.customerSearchControl.value);
    }
  }

  onSelectOption($event) {
    this.newActivityFormGroup.controls.propertyIdControl.setValue($event?.option?.value);
  }

  private isValidSearch() {
    const searchText = this.newActivityFormGroup.controls.customerSearchControl.value?.trim();

    return searchText?.length >= this._minLengthToSearch;
  }

  private searchCustomers(searchText: string) {
    this.searchingCustomer = true;
    this.customersLocated = [];
    this.newActivityFormGroup.controls.customerSearchControl.setValue(null);
    this.newActivityFormGroup.controls.propertyIdControl.setValue(null);

    this._customerDetailsService
      .getCustomerByPropertyId(searchText)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.customersLocated = result;
        this.searchingCustomer = false;
      });
  }

  isSaveDisabled() {
    return (
      !this.newActivityFormGroup.valid ||
      this.isMaximumZonesExceeded ||
      !this.checkIfLatLongIsValid()
    );
  }

  onSave() {
    this.save().subscribe(() => { });
  }

  save(): Observable<boolean> {
    this.isSaving = true;

    const type = ActivityRaiseType.Detection;
    const activityTypeId = this.activityTypeId;
    const activityTypeName = this.newActivityFormGroup.controls.notificationShortTextControl.value;
    const attributes: { [key: string]: string } = {};
    attributes.CodeGroup = this.newActivityFormGroup.controls.sapCodeGroupControl.value;
    attributes.Code = this.newActivityFormGroup.controls.sapCodeControl.value;
    attributes.Notification = this.newActivityFormGroup.controls.notificationLongTextControl.value;
    attributes.NotificationShort =
      this.newActivityFormGroup.controls.notificationShortTextControl.value;
    const userName = this.authenticationService?.userCode?.split('@')[0];
    const date = new Date(Date.now());
    const propertyId = this.newActivityFormGroup.controls.propertyIdControl.value;

    let translations: any;

    return this.localization.get(`${this.T_SCOPE}.messages`).pipe(
      switchMap((translationsResult) => {
        translations = translationsResult;

        if (this.selectedNodes?.length === 1 || propertyId) {
          this.activity = this.getActivityRaiseCmd(
            type,
            activityTypeId,
            activityTypeName,
            attributes,
            userName,
            date,
            propertyId
          );

          return this.activityRaiseService.raise(this.activity);
        } else {
          const hierarchyElementIds: { [key: string]: string } = {};

          this.selectedNodes.forEach((element) => {
            const zone = element.descendant;
            hierarchyElementIds[zone] = element.ancestor;
          });

          this.activities = new ActivitiesRaiseCmd(
            type,
            date,
            activityTypeId,
            activityTypeName,
            attributes,
            userName,
            hierarchyElementIds,
            propertyId
          );

          return this.activityRaiseService.raiseBulk(this.activities);
        }
      }),
      tap((activitiesRaisingResult) => {
        let message;
        let isError = false;
        if (activitiesRaisingResult.success.length > 0) {
          if (activitiesRaisingResult.success.length > 1) {
            message = translations['success-multiple'];
          } else {
            message = translations['success-single'];
          }
          message = this.addArrayToMessage(message, activitiesRaisingResult.success);

          if (activitiesRaisingResult.errors.length > 0) {
            message = message + '\n' + '\n' + translations['error-selected'];
            message = this.addArrayToMessage(message, activitiesRaisingResult.errors);
            isError = true;
          }
        } else {
          if (activitiesRaisingResult.errors.length > 0) {
            message = translations['error-multiple'];
            message = this.addArrayToMessage(message, activitiesRaisingResult.errors);
            isError = true;
          }
        }

        this.handleSaveSuccess(message, isError);
      }),
      catchError((error) => {
        this.isSaving = false;
        const errorMessage = error?.error?.Message
          ? error.error.Message
          : error.error
            ? error.error.message
            : error.statusText;
        this.dialogService.showErrorMessage({ name: error.name, message: errorMessage });

        return of(null);
      }),
      map((_) => true)
    );
  }

  /**
   * Shows success message and clears form.
   */
  handleSaveSuccess(message: any, isError: boolean): void {
    const prioritisation$ = this.cacheService.clearContainsInUrl('alc/prioritisation');
    const activity$ = this.cacheService.clearContainsInUrl('alc/activity');
    const icon = isError ? "error" : "success";

    forkJoin([prioritisation$, activity$]).subscribe(() => {
      this.dialogService.showMessage(message, icon);
      this.clearAll$.next(); // Clears the filters.
      this.newActivityFormGroup.reset();
      this.newActivityFormGroup.controls.selectedNodes.setValue([]);
      this.selectedNodes = [];
      this.isSaving = false;
    });
  }

  /**
   * Adds an array of elements into a message, with jumplines, and replacing brackets in the process.
   */
  private addArrayToMessage(inputMessage: string, array: any[]): string {
    let message = inputMessage;
    array.forEach((element) => {
      message = message + '\n' + element;
      message = message.replace('[', '');
      message = message.replace(']', '');
    });
    return message;
  }

  private setCoordinatesSystem() {
    this.coordinatesSystem =
      (this._coordinatesSystemService.coordinatesSystem as CoordinatesSystem) ??
      CoordinatesSystem.LatLong;
  }

  private createForm(): void {
    this.newActivityFormGroup = this.fb.group(
      {
        selectedNodes: [''],
        houseNumberControl: ['', Validators.maxLength(this.houseNumberMaxLength)],
        streetControl: ['', Validators.maxLength(this.streetMaxLength)],
        street2Control: [''],
        districtControl: ['', Validators.maxLength(this.districtMaxLength)],
        cityControl: ['', Validators.maxLength(this.cityMaxLength)],
        eastingNorthingControl: ['', Validators.minLength(this.eastingNorthingMaxLength)],
        longitudeControl: [
          undefined,
          [Validators.max(this.longitudeMaxValue), Validators.min(-this.longitudeMaxValue)],
        ],
        latitudeControl: [
          undefined,
          [Validators.max(this.latitudeMaxValue), Validators.min(-this.latitudeMaxValue)],
        ],
        orderNumberControl: [{ value: '', disabled: true }],
        notificationNumberControl: [{ value: '', disabled: true }],
        notificationShortTextControl: [{ value: '', disabled: true }],
        notificationLongTextControl: [''],
        activityTypeControl: ['', [Validators.required]],
        sapCodeControl: ['', [Validators.required]],
        sapCodeGroupControl: ['', [Validators.required]],
        propertyIdControl: [{ value: '', disabled: false }],
        customerSearchControl: [''],
      },
      { validators: this.customGroupValidator.bind(this) }
    );

    this.newActivityFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.hasChanges = this.newActivityFormGroup.dirty;

      if (
        !this.newActivityFormGroup.controls.customerSearchControl.value &&
        this.newActivityFormGroup.controls.propertyIdControl.value
      ) {
        this.newActivityFormGroup.controls.propertyIdControl.setValue('');
        this.customersLocated = [];
      }
    });
  }

  /*Validates if form has at least one of these:
    - HE node/s selected
    - PropertyId selected from combo */
  customGroupValidator(group: FormGroup) {
    const nodes = group.get('selectedNodes').value;
    const propertyId = group.get('propertyIdControl').value;

    if (!nodes?.length && (!propertyId || propertyId === '')) {
      return { requiredAlternative: true };
    }

    return null;
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const queryParams = this.getQueryParams(params);

    this.selectedHierarchyElementIds =
      typeof queryParams.hierarchyElementIds === 'string'
        ? [queryParams.hierarchyElementIds]
        : queryParams.hierarchyElementIds;
    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;

    if (this.selectedHierarchyElementIds?.[0] && !this.selectedHierarchyFamilyId) {
      return this._globalsService.getHeFiltersDataById(this.selectedHierarchyElementIds[0]);
    }

    return of(null);
  }

  applyTreeSelection(event) {
    this.selectedNodes = event;
  }

  getSelectedKeys(): string[] {
    return this.selectedNodes ? this.selectedNodes.map((n) => n.descendant) : [];
  }

  private checkIfValueIsValid(value): boolean {
    const valid = value !== undefined && value !== null && value !== '';
    return valid;
  }

  checkIfLatLongIsValid() {
    if (this.coordinatesSystem === CoordinatesSystem.EastingNorthing) {
      return true;
    }
    const latValue = this.newActivityFormGroup.controls.latitudeControl.value;

    const longValue = this.newActivityFormGroup.controls.longitudeControl.value;

    if (
      (this.checkIfValueIsValid(latValue) && !this.checkIfValueIsValid(longValue)) ||
      (this.checkIfValueIsValid(longValue) && !this.checkIfValueIsValid(latValue))
    ) {
      return false;
    }

    if (
      (this.checkIfValueIsValid(latValue) && this.checkIfValueIsValid(longValue)) ||
      (!this.checkIfValueIsValid(longValue) && !this.checkIfValueIsValid(latValue))
    ) {
      return true;
    }
  }

  private getPendingChanges(): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: !this.isSaveDisabled(),
      saveFn: () => this.save(),
    };
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return this.checkPendingChanges(this.pageId);
  }

  private getActivityRaiseCmd(
    type: ActivityRaiseType,
    activityTypeId: number,
    activityTypeName: string,
    attributes: { [key: string]: string },
    userName: string,
    date: Date,
    propertyId: string
  ): ActivityRaiseCmd {
    const street = this.newActivityFormGroup.controls.streetControl.value;
    const district = this.newActivityFormGroup.controls.districtControl.value;
    const city = this.newActivityFormGroup.controls.cityControl.value;
    const selectedElement = this.newActivityFormGroup.controls.selectedNodes?.value[0];
    const hierarchyElementId = selectedElement?.descendant;
    const parentId = selectedElement?.ancestor;
    const street2 = this.newActivityFormGroup.controls.street2Control.value;
    const houseNumber = this.newActivityFormGroup.controls.houseNumberControl.value;

    let easting;
    let northing;

    let latitude;
    let longitude;

    if (this.coordinatesSystem === CoordinatesSystem.EastingNorthing) {
      const eastingNorthing = this.newActivityFormGroup.controls.eastingNorthingControl.value;
      if (eastingNorthing !== '' && eastingNorthing != null) {
        const length = eastingNorthing.length;
        easting = parseInt(eastingNorthing.substr(0, length / 2), 10);
        northing = parseInt(eastingNorthing.substr(length / 2, eastingNorthing.length - 1), 10);
      }
    } else {
      const latValue = this.newActivityFormGroup.controls.latitudeControl.value;
      const longValue = this.newActivityFormGroup.controls.longitudeControl.value;

      if (this.checkIfValueIsValid(latValue) && this.checkIfValueIsValid(longValue)) {
        latitude = parseFloat(latValue);
        longitude = parseFloat(longValue);
      }
    }

    return new ActivityRaiseCmd(
      type,
      activityTypeId,
      activityTypeName,
      street,
      district,
      city,
      attributes,
      userName,
      date,
      hierarchyElementId,
      parentId,
      null,
      street2,
      houseNumber,
      easting ?? latitude,
      northing ?? longitude,
      propertyId
    );
  }
}
