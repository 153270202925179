<wlm-page-wrapper
  [titleKey]="T_SCOPE + (currentDashboardId ? '.title-with-current' : '.title')"
  [titleKeyParams]="{
    dashboardTitle: currentDashboardTitle
  }"
>
  <ng-container page-top-buttons>
    <div class="buttons-background button-position vertical-align-buttons">
      <button
        *ngIf="pageSettings?.showResetIcon"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onClickResetMainLayout()"
        [matTooltip]="T_SCOPE + '.buttons.reset-main-layout' | translate | lowercase"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>

  <ng-container page-content>
    <div id="center-page" class="nested-dynamic-layout fit-height" *ngIf="pageSettings">
      <wlm-dynamic-layout [settings]="pageSettings"></wlm-dynamic-layout>
    </div>
  </ng-container>
</wlm-page-wrapper>
