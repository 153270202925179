import { Component } from '@angular/core';
import { MapTooltip } from './models/map-tooltip';

const COMPONENT_SELECTOR = 'wlm-map-tooltip';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss'],
})
export class MapTooltipComponent {
  private _models: MapTooltip[];
  get models(): MapTooltip[] {
    return this._models;
  }
  set models(value: MapTooltip[]) {
    this._models = value;
    if (this.models?.length) {
      this.setCurrentModel(0);
    }
  }

  currentModelIndex: number;
  currentModel: MapTooltip;

  setNext(): void {
    this.setCurrentModel(this.currentModelIndex + 1);
  }

  setPrevious(): void {
    this.setCurrentModel(this.currentModelIndex - 1);
  }

  private setCurrentModel(index: number): void {
    if (index >= this.models.length) {
      index = 0;
    }

    if (index < 0) {
      index = this.models.length - 1;
    }
    this.currentModelIndex = index;
    this.currentModel = this.models[this.currentModelIndex];
  }
}
