import { Pipe, PipeTransform } from '@angular/core';

// TODO: This could be refactored to the standard DecimalPipe, and apply i18n (#18898)
@Pipe({
  name: 'uomColumnRoundDecimals',
})
export class UomColumnRoundDecimalsPipe implements PipeTransform {
  transform(value: string, decimals: number): string {
    return value === '' || isNaN(parseFloat(value))
      ? value
      : parseFloat(value).toFixed(decimals).toString();
  }
}
