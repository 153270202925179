import { ChartSettings } from './chart-settings';
import { ChartType } from './chart-type.enum';
import { TrendChartDataParameters } from './trend-chart-data-parameters';

export class TrendChartSettings extends ChartSettings {
  dataParameters: TrendChartDataParameters;
  chartType: ChartType = ChartType.trend;

  constructor(init: TrendChartSettings) {
    super(init);
    Object.assign(this, init);
  }
}
