<mat-tab-group
  class="map-tab-panel map-filters-tabs"
  animationDuration="0ms"
  [disablePagination]="true"
  [ngStyle]="{ display: displayed ? '' : 'none' }"
>
  <mat-tab class="map-tab-panel-item" label="{{ T_SCOPE + '.filters-tab' | translate }}"
    ><wlm-map-filter
      [displayed]="displayed"
      [(baseType)]="baseType"
      [(visibleLayersIds)]="visibleLayersIds"
      [(leakYears)]="leakYears"
      (onLoad)="onInternalLoadMapFilter()"
    ></wlm-map-filter
  ></mat-tab>
  <mat-tab class="map-tab-panel-item" label="{{ T_SCOPE + '.thematics-tab' | translate }}"
    ><wlm-map-thematic
      [displayed]="displayed"
      [thematics]="thematics"
      [(visibleThematicsIds)]="visibleThematicsIds"
      (onLoad)="onInternalLoadThematicFilter()"
      (kpiInfoChange)="onInternalKpiInfoChange($event)"
    ></wlm-map-thematic
  ></mat-tab>
</mat-tab-group>
