import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { GenericCardSettings } from '../../shared-component/generic-card/generic-card-settings';
import { ListItem } from '../../shared-component/selection-list/models/list-item';
import { AppModules } from '../../shared/app-modules.enum';
import { DragListSettings } from '../../shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '../../shared/core/select-drag-list-virtual/select-drag-list-settings';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { DIMENSION_ITEMS } from '../models/dimension-items';

const COMPONENT_SELECTOR = 'wlm-uom-selection';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-selection.component.html',
  styleUrls: ['./uom-selection.component.scss'],
})
export class UomSelectionComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  @Input() widgetInstanceKey: string;
  @Output() selectedChange = new EventEmitter<number>();

  dimensions = DIMENSION_ITEMS;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<ListItem>;

  get componentName(): string {
    return 'UomSelectionComponent';
  }
  queryParams: Map<string, any> = new Map<string, any>();
  private _pagesize = 50;
  private _tsLocation = 'common.dimension-types';
  private _selectedFieldName = 'id';

  constructor(private localization: LocalizationHelperService) { }

  ngOnInit(): void {
    this.prepareListsSettings();
  }

  init(): void { }

  mapInitParameters(parameters: TabDetailPanelParameters) { }

  onSelectDimension(dimension: ListItem) {
    this.selectedChange.emit(dimension?.id);
  }

  private prepareListsSettings() {
    this.localization
      .get(`${this._tsLocation}`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        this.settings = new SelectDragListSettings({
          isLocal: true,
          pageSize: this._pagesize,
          disableFilter: true,
          isReadOnly: false,
          scrollId: this.widgetInstanceKey,
          selectedFieldName: this._selectedFieldName,
        });

        this.cardSettings = new GenericCardSettings<ListItem>({
          title1Fn: (model) => ts[model.labelKey.replace(`${this._tsLocation}.`, '')],
          leftIconNameFn: (model) => model.iconName,
          useTitleCentered: true,
        });
      });
  }
}
