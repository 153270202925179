<div class="container">
  <div class="title" (click)="onToggle()">
    <div>
      <h4>{{ titleKey | translate }}</h4>
    </div>
    <div class="collapse-icon">
      <mat-icon *ngIf="show">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="!show">keyboard_arrow_up</mat-icon>
    </div>
  </div>
</div>
<hr class="title-separator" />
<div class="content" [hidden]="!show">
  <ng-content></ng-content>
</div>
