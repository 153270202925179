<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background">
    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bfSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-family-filter-item
        [fieldName]="prioritisationFilterConfiguration.hierarchyElementFamilyFieldName"
        [selectedIds]="[prioritisationFilterConfiguration.hierarchyElementFamily]"
        [filterText]="filterText"
        [settings]="hierarchyFamilyItemSettings"
        [restoreStatusOrder]="1"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="
          prioritisationFilterConfiguration.hierarchyElementIdFieldName
        "
        [hierarchyFamilyIdFieldName]="
          prioritisationFilterConfiguration.hierarchyElementFamilyFieldName
        "
        [hierarchyFamilyId]="hierarchyElementFamilyId"
        [initialSelectedKeys]="prioritisationFilterConfiguration.hierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [restoreStatusOrder]="2"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>
  </div>
</wlm-base-filter-container>
