import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { PagedResultDto } from '../../model/paged-result.dto';
import { GroupDto } from '../../model/roles/group.dto';
import { GroupUsersCmdDto } from '../../model/roles/groupUsersCmd.dto';
import { GroupUsersResponse } from '../../model/roles/groupUsersResponse';
import { UserDto } from '../../model/roles/user.dto';
import { SelectOption } from '../../model/shared/select-option';
import { GridODataService } from '../../odata/grid-odata.service';

const API_BASE = '/api/roles/users';

@Injectable()
export class UsersGridODataService extends GridODataService<UserDto> {
  constructor(injector: Injector, private dialogService: DialogService) {
    super(injector, API_BASE);
  }

  protected mapResponse(response: PagedResultDto<UserDto>): PagedResultDto<UserDto> {
    return response;
  }

  insertGroupUsers(groupUsersCmd: GroupUsersCmdDto): Observable<GroupUsersResponse> {
    return this.httpCacheClient.post(
      `${this.baseUrl}${this.api}/group-user/insert`,
      groupUsersCmd,
      {
        avoid: true,
      }
    );
  }

  removeUsersFromGroups(groupUsersToRemoveDto: GroupUsersCmdDto): Observable<GroupUsersResponse> {
    return this.httpCacheClient.post(
      `${this.baseUrl}${this.api}/group-user/delete`,
      groupUsersToRemoveDto,
      {
        avoid: true,
      }
    );
  }

  /**
   * Toggles super user privileges to a specific user.
   * Automatically shows backend error messages.
   */
  toggleSuperUser(userCode: string): Observable<UserDto> {
    return this.httpCacheClient
      .post<UserDto>(
        `${this.baseUrl}${this.api}/toggle-super-user`,
        { userCode },
        {
          avoid: true,
        }
      )
      .pipe(
        // Catch errors obtained in the Http request.
        // TODO: Discuss in refinement the possibility of an error interceptor.
        catchError<any, any>((error) => {
          this.dialogService.showErrorMessage(error);
          return of(error);
        })
      );
  }

  createUser(newUser: UserDto): Observable<UserDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${this.api}`, newUser, {
      avoid: true,
    });
  }

  deleteUser(userToRemove: UserDto): Observable<UserDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${this.api}/delete`, userToRemove, {
      avoid: true,
    });
  }

  reactivateUser(userToReactivate: UserDto): Observable<UserDto> {
    return this.httpCacheClient.post(
      `${this.baseUrl}${this.api}/reactivate-user`,
      userToReactivate,
      {
        avoid: true,
      }
    );
  }

  getUsers(onlyActive = false): Observable<UserDto[]> {
    return this.httpCacheClient
      .get<any>(
        `${this.baseUrl}${this.api}`,
        {
          avoid: true,
        },
        {
          onlyActive,
        }
      )
      .pipe(
        map((data) => {
          const items = data.items;
          return items;
        })
      );
  }

  getUsersSelectOptions(): Observable<SelectOption<string>[]> {
    return this.getUsers().pipe(
      map((users) =>
        SelectOption.mapMany<string, UserDto>(
          users.filter((u) => u.isActive),
          (u) => u.userCode,
          (u) => `${u.name} ${u.surname}`
        )
      )
    );
  }

  getGroups(): Observable<GroupDto[]> {
    return this.httpCacheClient
      .get<any>(`${this.baseUrl}/api/roles/groups`, {
        avoid: true,
      })
      .pipe(map((data) => data.items));
  }
}
