import { Component, Inject, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { SelectNetworkElementConfigAction } from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.actions';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-selection-widget';
export const NEC_SELECTION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-selection-widget.component.html',
  styleUrls: ['./ne-configuration-selection-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigurationSelectionWidgetComponent
  extends BaseDynamicWidgetComponent
  implements IPendingChangesChecker {
  filters: Map<string, any>;

  widgetInstanceKey: string;

  pageId: string;

  // Not all actions/selectors must have the same action settings.
  private _filtersScopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });
  private _necScopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _pendingChangesService: PendingChangesManagerService,
    private _objectHelperService: ObjectHelperService
  ) {
    super(injector, widgetSettings);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  /**
   * Custom lifecycle method where to start using this._state.
   */
  onWidgetInit(): void {
    this._state
      .select(new AppliedFiltersSelector(this._filtersScopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dbFilter: DataBindingFilters) => {
          if (!dbFilter) {
            return;
          }

          const iFilters = Array.from(dbFilter.filters.values()).map((x) => x.getFiltersValues());
          const filtersToApply = this._objectHelperService.joinMaps(iFilters);
          this.filters = this._objectHelperService.clone(filtersToApply);

          // Set NE as unsetted
          this._state.dispatch(new SelectNetworkElementConfigAction(null, this._necScopeSettings));
        },
      });
  }

  get componentName(): string {
    return 'NeConfigurationSelectionWidgetComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) { }

  init(): void { }

  onSelectedNEChange(selectedNE: INetworkElementDto) {
    this.checkPendingChanges(this.pageId).subscribe((_) =>
      this._state.dispatch(new SelectNetworkElementConfigAction(selectedNE, this._necScopeSettings))
    );
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(untilDestroyed(this));
  }
}
