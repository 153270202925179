<button
  mat-icon-button
  *hasPerm="['WLMNotificationsCrud', 'r']"
  color=""
  [disabled]="!activeNotificationsCount"
  [matTooltip]="T_SCOPE | translate"
  [disabled]="!activeNotificationsCount"
  (click)="onNotificationsClick($event)"
  class="notification-badge"
>
  <mat-icon
    [matBadge]="
      activeNotificationsCount > maxActiveNotificationsCount ? '...' : activeNotificationsCount
    "
    matBadgeColor="warn"
    matBadgeSize="medium"
    class="top-bar-icon"
    >notifications</mat-icon
  >
</button>
