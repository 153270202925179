import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicFormAdditionalSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-settings';
import { DynamicFormUIValues } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-ui-values';
import { FieldDefinition } from 'src/app/common-modules/dynamic-forms/models/field-definition';

const COMPONENT_SELECTOR = 'wlm-ne-config-attribute-form';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-attribute-form.component.html',
  styleUrls: ['./ne-config-attribute-form.component.scss'],
})
export class NeConfigAttributeFormComponent implements OnInit {
  @Input() pageId?: string;
  @Input() formSettings: DynamicFormSettings;
  @Input() values: DynamicFormUIValues;
  @Input() apiFields: { [key: string]: FieldDefinition };
  @Input() additionalSettings: DynamicFormAdditionalSettings;

  @Output() formHasChanges = new EventEmitter<{ [key: string]: boolean }>();

  constructor() {}

  ngOnInit(): void {}

  onformHasChanges(hasChanges: boolean) {
    const changesMap = {};
    changesMap[this.formSettings.fieldsByCategory.categoryKey] = hasChanges;

    this.formHasChanges.emit(changesMap);
  }
}
