export enum SignalConfigurationSubcategory {
  BoundaryIn = 'Boundary In',
  BoundaryOut = 'Boundary Out',
  AZP = 'AZP',
  CPP = 'CPP',
  MPP = 'MPP',
  Inlet = 'Inlet',
  Outlet = 'Outlet',
  NoBoundary = 'No Boundary',
  LarsAddition = 'Lars Addition',
  LarsSubtraction = 'Lars Subtraction',
  SworpsAddition = 'Sworps Addition',
  SworpsSubtraction = 'Sworps Subtraction',
  Default = 'default',
}
