import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { PressureMonitoringPageComponent } from './pressure-monitoring-page/pressure-monitoring-page.component';

const routes: Routes = [
  {
    path: 'pressure-monitoring',
    component: PressureMonitoringPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.PressureMonitoring,
    },
  },
  { path: '**', redirectTo: 'distribution-network/grid' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PressureManagementRoutingModule {}
