<form [formGroup]="myFormGroup">
  <div class="tree-border">
    <div class="columns-definition">
      <div class="tr-buttons">
        <!-- <div *ngIf="selectedKeys.length > 0"><button mat-button>Clear selected</button></div> -->
        <div *ngIf="treeSettings.showRecursiveToggle" class="toggle-position">
          <mat-slide-toggle
            [labelPosition]="'before'"
            [matTooltip]="T_SCOPE + '.select-descendants' | translate"
            [checked]="treeSettings.useSelectRecursive"
            (change)="setUseSelectRecursive($event)"
            >{{ T_SCOPE + '.select-descendants' | translate }}</mat-slide-toggle
          >
        </div>
      </div>
      <div class="container">
        <div class="left-panel">
          <wlm-hierarchy-family-filter
            class="hetree-hefamily-filter"
            [(hierarchyFamilyId)]="hierarchyFamilyId"
          ></wlm-hierarchy-family-filter>

          <wlm-automplete
            [autocompleteSettings]="treeSettings.autocompleteSetting"
            [(filterValue)]="searchValue"
          ></wlm-automplete>
          <div *ngIf="treeSettings.showSelectedNodesChips" class="scrollable">
            <mat-chip
              *ngIf="selectedKeys.length > 0"
              class="chip-item clear-all-chips clear-all-chip"
              [removable]="removableChip"
              (removed)="ClearAllChips($event)"
              >{{ 'common.clear-all' | translate }}
              <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
            </mat-chip>
            <mat-chip-set>
              <mat-chip
                class="chip-item"
                *ngFor="let chip of selectedKeys"
                [removable]="removableChip"
                (removed)="removeChip(chip)"
              >
                {{ chip }}
                <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
              </mat-chip>
            </mat-chip-set>
          </div>
        </div>

        <div *ngIf="isLoading" class="spinner-location is-loading">
          <span class="k-i-loading k-loading-image"></span>
        </div>

        <div *ngIf="treeIsEmpty && hierarchyFamilyId !== null" class="empty-tree">
          <p>The hierarchy tree could not be built</p>
        </div>
        <div *ngIf="!isLoading" class="treeview">
          <kendo-treeview
            name="treeview"
            id="treeview"
            class="filter-item-treeview"
            [nodes]="filteredTreeNodes"
            kendoTreeViewExpandable
            [(expandedKeys)]="expandedKeys"
            [expandBy]="treeSettings.displayableField"
            [selectedKeys]="selectedKeys"
            [selectBy]="'descendant'"
            kendoTreeViewFlatDataBinding
            idField="descendant"
            parentIdField="ancestor"
            [kendoTreeViewSelectable]="selection"
            (selectionChange)="handleSelection($event)"
            (nodeClick)="handleClick($event)"
            [isSelected]="isItemSelected"
          >
            <ng-template kendoTreeViewNodeTemplate let-dataItem>
              <div [ngClass]="setNodeStyle(dataItem)">
                {{ dataItem.descendantDescription }}
              </div>
            </ng-template>
          </kendo-treeview>
        </div>
      </div>
    </div>
  </div>
</form>
