<div>
  <wlm-base-filter-container
    *ngIf="treeSettings && adaptersReady"
    class="filters filters-background"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="onGlobalFilterResults($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <wlm-base-filter
      *ngIf="loaded"
      (filterText)="onFilterText($event)"
      [settings]="bf1Settings"
      [persistItems$]="persistFilters$"
    >
      <wlm-hierarchy-family-filter-item
        *ngIf="loaded"
        [selectedIds]="[familyIdSelected]"
        [fieldName]="hfFieldName"
        [defaultSelectedElements]="alarmsConfigurationFilterConfig.defaultFamilyId"
        [filterText]="filterText"
        [restoreStatusOrder]="1"
        [settings]="familyFilterItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>

      <wlm-hierarchy-elements-filter-item
        [filterText]="filterText"
        [fieldName]="alarmsConfigurationFilterConfig.heSettings.elementTypeFieldName"
        [familyId]="familyIdSelected"
        [defaultSelectedElements]="alarmsConfigurationFilterConfig.defaultHETypeIds"
        [restoreStatusOrder]="2"
        [mode]="mode"
        [selectedIds]="alarmsConfigurationFilterConfig.heSettings?.selectedElementIds"
        [settings]="hierarchyFilterItemSettings"
      ></wlm-hierarchy-elements-filter-item>
    </wlm-base-filter>

    <!-- NETWORK ELEMENTS FILTER -->
    <wlm-base-filter
      *ngIf="loaded"
      (filterText)="onFilterText($event)"
      [settings]="bf2Settings"
      [persistItems$]="persistFilters$"
    >
      <wlm-network-elements-filter-item
        [filterText]="filterText"
        [fieldName]="alarmsConfigurationFilterConfig.neSettings.elementTypeFieldName"
        [defaultSelectedElements]="alarmsConfigurationFilterConfig.defaultNETypeIds"
        [selectedIds]="alarmsConfigurationFilterConfig.neSettings?.selectedElementIds"
        [settings]="networkFilterItemSettings"
        [filterListElements]="filterListElements"
        [mode]="mode"
      ></wlm-network-elements-filter-item>
    </wlm-base-filter>

    <!-- HIERARCHY ELEMENTS FILTER -->
    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bf3Settings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="alarmsConfigurationFilterConfig.hierarchyElementIdFieldName"
        [hierarchyFamilyIdFieldName]="
          alarmsConfigurationFilterConfig.hierarchyElementFamilyFieldName
        "
        [hierarchyFamilyId]="treeFamilyId"
        [initialSelectedKeys]="alarmsConfigurationFilterConfig?.initialHierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [rebuildTree$]="rebuildTreeFilter$"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
  <!-- HIERARCHY LEVELS FILTER -->
</div>
