import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { BaseGCartesianChartSeries } from '../models/generic-chart-settings/base-g-cartesian-chart-series';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GCartesianCustomChartSerie } from '../models/generic-chart-settings/g-cartesian-custom-chart-series';
import { GChartAxis } from '../models/generic-chart-settings/g-chart-axis';
import { GChartBrush } from '../models/generic-chart-settings/g-chart-brush';
import { GChartCustomItemStyle } from '../models/generic-chart-settings/g-chart-custom-item-style';
import { GChartDataZoom } from '../models/generic-chart-settings/g-chart-data-zoom';
import { GChartGrid } from '../models/generic-chart-settings/g-chart-grid';
import { GChartItemStyle } from '../models/generic-chart-settings/g-chart-item-style';
import { GChartLegend } from '../models/generic-chart-settings/g-chart-legend';
import { GChartTextStyles } from '../models/generic-chart-settings/g-chart-text-styles';
import { GChartTitle } from '../models/generic-chart-settings/g-chart-title';
import { GChartToolbox } from '../models/generic-chart-settings/g-chart-toolbox';
import { GChartTooltip } from '../models/generic-chart-settings/g-chart-tooltip';
import { GPieChartSerie } from '../models/generic-chart-settings/g-pie-chart-serie';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericCartesianCustomChartSettings } from '../models/generic-chart-settings/generic-cartesian-custom-chart-settings';
import { GenericPieChartSettings } from '../models/generic-chart-settings/generic-pie-chart-settings';
import { GSchematicChartSerie } from '../models/schematics/g-schematic-chart-serie';
import { GenericSchematicChartSettings } from '../models/schematics/generic-schematic-chart-settings';

/**
 * Converts generic settings to settings that can be consumed by Echarts.
 */

@Injectable({ providedIn: 'root' })
export class EchartsSettingsMapperService {
  deepSetIfDef: Function;
  private readonly _defaultBarMaxWidth = 20; // px

  constructor(private _objectHelperService: ObjectHelperService) {
    this.deepSetIfDef = this._objectHelperService.deepSetIfDefined;
  }

  mapSettings(
    settings:
      | GenericCartesianChartSettings
      | GenericCartesianCustomChartSettings
      | GenericPieChartSettings
      | GenericSchematicChartSettings
  ): EChartsOption {
    if (!settings) {
      return;
    }

    const eChartOpts: EChartsOption = {};

    // Map generic settings
    this.mapTitle(eChartOpts, 'title', settings.chartTitle);
    this.mapTooltip(eChartOpts, 'tooltip', settings.tooltip);
    this.mapDataZoom(eChartOpts, 'dataZoom', settings.dataZoom);
    this.mapLegend(eChartOpts, 'legend', settings.legend);
    this.mapGrid(
      eChartOpts,
      'grid',
      settings.grid,
      settings.dataZoom?.some((x) => x?.type == 'slider')
    );
    this.mapToolbox(eChartOpts, 'toolbox', settings.toolbox);
    this.mapBrush(eChartOpts, 'brush', settings.brush);
    this.deepSetIfDef(eChartOpts, 'visualMap', settings.visualMap);
    this.deepSetIfDef(eChartOpts, 'animation', settings.animation);
    this.deepSetIfDef(eChartOpts, 'geo', this._objectHelperService.clone(settings.geo, true));

    // Map axes
    if (settings.type !== 'pie') {
      this.mapAxes(eChartOpts, 'xAxis', (settings as GenericCartesianChartSettings).xAxes);
      this.mapAxes(eChartOpts, 'yAxis', (settings as GenericCartesianChartSettings).yAxes);
    }

    // Map series (required)

    const clonedSeries = settings?.series?.map((serie) => {
      // Cannot cloneDeep custom series because of the renderItem function.

      const canClone = this._isSchematicSerie(serie);
      return canClone ? (this._objectHelperService.clone(serie, true) as any) : serie;
    });

    this.mapSeries(eChartOpts, 'series', clonedSeries);

    return eChartOpts;
  }

  private mapLegend(eChartOpts: EChartsOption, path: string, legend: GChartLegend) {
    if (this._defined(legend)) {
      this.deepSetIfDef(eChartOpts, `${path}.data`, legend.data);
      this.deepSetIfDef(eChartOpts, `${path}.bottom`, legend.bottom);
      this.deepSetIfDef(eChartOpts, `${path}.itemHeight`, legend.itemHeight);
      this.deepSetIfDef(eChartOpts, `${path}.textStyle.fontSize`, legend.fontSize);
      this.deepSetIfDef(eChartOpts, `${path}.textStyle.padding`, legend.textPadding);
      this.deepSetIfDef(eChartOpts, `${path}.formatter`, legend.formatter);
      this.deepSetIfDef(eChartOpts, `${path}.type`, 'scroll');
      this.deepSetIfDef(eChartOpts, `${path}.backgroundColor`, legend.backgroundColor);
    }
  }

  private mapGrid(
    eChartOpts: EChartsOption,
    path: string,
    gridObj: GChartGrid,
    hasDataZoomSlider: boolean
  ) {
    if (hasDataZoomSlider || gridObj) {
      this.deepSetIfDef(eChartOpts, `${path}.y`, gridObj?.y || '30');
      this.deepSetIfDef(eChartOpts, `${path}.y2`, gridObj?.y2 || '120');
      this.deepSetIfDef(eChartOpts, `${path}.left`, gridObj?.left);
      this.deepSetIfDef(eChartOpts, `${path}.right`, gridObj?.right);
      this.deepSetIfDef(eChartOpts, `${path}.top`, gridObj?.top);
      this.deepSetIfDef(eChartOpts, `${path}.bottom`, gridObj?.bottom);
    } else {
      this.deepSetIfDef(eChartOpts, `${path}.bottom`, '70x');
      this.deepSetIfDef(eChartOpts, `${path}.top`, '30px');
    }
  }

  private mapTextStyles(eChartOpts: EChartsOption, path: string, stylesObj: GChartTextStyles) {
    if (this._defined(stylesObj)) {
      this.deepSetIfDef(eChartOpts, `${path}.color`, stylesObj.color);
      this.deepSetIfDef(eChartOpts, `${path}.fontStyle`, stylesObj.fontStyle);
      this.deepSetIfDef(eChartOpts, `${path}.fontWeight`, stylesObj.fontWeight);
      this.deepSetIfDef(eChartOpts, `${path}.fontFamily`, stylesObj.fontFamily);
      this.deepSetIfDef(eChartOpts, `${path}.fontSize`, stylesObj.fontSize);
      this.deepSetIfDef(eChartOpts, `${path}.lineHeight`, stylesObj.lineHeight);
      this.deepSetIfDef(eChartOpts, `${path}.padding`, stylesObj.padding);
    }
  }

  private mapTitle(eChartOpts: EChartsOption, path: string, chartTitle: GChartTitle): void {
    if (this._defined(chartTitle) && chartTitle.title) {
      this.deepSetIfDef(eChartOpts, `${path}.text`, chartTitle.title);
      this.deepSetIfDef(eChartOpts, `${path}.left`, chartTitle.position);
      this.deepSetIfDef(eChartOpts, `${path}.textStyle`, chartTitle.textStyle);
    }
  }

  private mapTooltip(eChartOpts: EChartsOption, path: string, tooltip: GChartTooltip): void {
    if (this._defined(tooltip)) {
      this.deepSetIfDef(eChartOpts, `${path}.show`, tooltip.show);
      this.deepSetIfDef(eChartOpts, `${path}.triggerOn`, tooltip.triggerOn);
      this.deepSetIfDef(eChartOpts, `${path}.hideDelay`, tooltip.hideDelay);
      this.deepSetIfDef(eChartOpts, `${path}.alwaysShowContent`, tooltip.alwaysShowContent);
      this.deepSetIfDef(eChartOpts, `${path}.className`, tooltip.className);
      this.deepSetIfDef(eChartOpts, `${path}.formatter`, tooltip.formatter);
      this.deepSetIfDef(eChartOpts, `${path}.trigger`, tooltip.trigger);
      this.deepSetIfDef(eChartOpts, `${path}.axisPointer`, tooltip.axisPointer);
      this.deepSetIfDef(eChartOpts, `${path}.borderColor`, tooltip.borderColor);
      this.deepSetIfDef(eChartOpts, `${path}.position`, tooltip.position);
      this.deepSetIfDef(eChartOpts, `${path}.confine`, tooltip.confine);
      this.deepSetIfDef(eChartOpts, `${path}.backgroundColor`, tooltip.backgroundColor);
      this.mapTextStyles(eChartOpts, `${path}.textStyle`, tooltip.textStyle);
    }
  }

  private mapToolbox(eChartOpts: EChartsOption, path: string, toolbox: GChartToolbox): void {
    if (this._defined(toolbox)) {
      this.deepSetIfDef(eChartOpts, `${path}.show`, toolbox.show);
      this.deepSetIfDef(eChartOpts, `${path}.left`, toolbox.left);
      this.deepSetIfDef(eChartOpts, `${path}.right`, toolbox.right);
      this.deepSetIfDef(eChartOpts, `${path}.top`, toolbox.top);
      this.deepSetIfDef(eChartOpts, `${path}.bottom`, toolbox.bottom);
      this.deepSetIfDef(eChartOpts, `${path}.itemSize`, toolbox.itemSize);
    }
  }

  private mapBrush(eChartOpts: EChartsOption, path: string, brush: GChartBrush): void {
    if (this._defined(brush)) {
      this.deepSetIfDef(eChartOpts, `${path}.toolbox`, brush.toolbox);
      this.deepSetIfDef(eChartOpts, `${path}.xAxisIndex`, brush.xAxisIndex);
    }
  }

  private mapDataZoom(eChartOpts: EChartsOption, path: string, dataZoom: GChartDataZoom[]): void {
    if (this._defined(dataZoom) && dataZoom?.length) {
      const mappedDataZoom = dataZoom.map((dz) => {
        const mapped = {};
        this.deepSetIfDef(mapped, 'id', dz.id);
        this.deepSetIfDef(mapped, 'type', dz.type);
        this.deepSetIfDef(mapped, 'show', dz.show);
        this.deepSetIfDef(mapped, 'start', dz.start);
        this.deepSetIfDef(mapped, 'end', dz.end);
        this.deepSetIfDef(mapped, 'startValue', dz.startValue);
        this.deepSetIfDef(mapped, 'endValue', dz.endValue);
        this.deepSetIfDef(mapped, 'xAxisIndex', dz.xAxisIndex);
        this.deepSetIfDef(mapped, 'yAxisIndex', dz.yAxisIndex);
        this.deepSetIfDef(mapped, 'filterMode', dz.filterMode);
        this.deepSetIfDef(mapped, 'height', dz.height);
        this.deepSetIfDef(mapped, 'width', dz.width);
        this.deepSetIfDef(mapped, 'top', dz.top);
        this.deepSetIfDef(mapped, 'bottom', dz.bottom);
        this.deepSetIfDef(mapped, 'right', dz.right);
        this.deepSetIfDef(mapped, 'left', dz.left);
        this.deepSetIfDef(mapped, 'handleIcon', dz.handleIcon);
        this.deepSetIfDef(mapped, 'handleSize', dz.handleSize);
        this.deepSetIfDef(mapped, 'moveHandleSize', dz.moveHandleSize);
        this.deepSetIfDef(mapped, 'showDetail', dz.showDetail);
        this.deepSetIfDef(mapped, 'brushSelect', dz.brushSelect);
        this.deepSetIfDef(mapped, 'zoomOnMouseWheel', dz.zoomOnMouseWheel);
        this.deepSetIfDef(mapped, 'moveOnMouseMove', dz.moveOnMouseMove);
        this.deepSetIfDef(mapped, 'zoomLock', dz.zoomLock);
        this.deepSetIfDef(mapped, 'brushStyle', dz.brushStyle);
        this.deepSetIfDef(mapped, 'labelFormatter', dz.labelFormatter);
        this.deepSetIfDef(mapped, 'rangeMode', dz.rangeMode);
        this.deepSetIfDef(mapped, 'minSpan', dz.minSpan);

        // this.deepSetIfDef(mapped, 'textStyle.rich.fontSize', 5);
        this.deepSetIfDef(mapped, 'textStyle.fontSize', dz.textStyle?.fontSize);

        return mapped;
      });
      this.deepSetIfDef(eChartOpts, path, mappedDataZoom);
    }
  }

  public mapSeries(eChartOpts: EChartsOption, path: string, series: GCartesianChartSerie[]): void {
    const eChartSeries = series.map((serie) => this.buildSingleSerie(serie));
    this.deepSetIfDef(eChartOpts, path, eChartSeries);
  }

  /**
   * Converts the dataPoint custom objects to the ECharts pre-defined array syntax.
   * Axes are already linked.
   * If the series are of tipe "custom":
   *  - data is not formatted
   *  - dimensions and renderItem are added.
   */
  private buildSingleSerie(
    serie: BaseGCartesianChartSeries | GPieChartSerie | GSchematicChartSerie
  ): any {
    let result = {};
    this.deepSetIfDef(result, 'name', serie.name);
    this.deepSetIfDef(result, 'type', serie.type);
    this.deepSetIfDef(
      result,
      'symbolKeepAspect',
      (serie as GSchematicChartSerie)?.symbolKeepAspect
    );

    if (this._isCustomSerie(serie)) {
      this.setCommonCartesianMappings(result, serie);
      const customSerie = serie as GCartesianCustomChartSerie;
      let formattedData = this.formatDataByDimensions(customSerie.data, customSerie.dimensions);
      // Modify by ref and add styles, if necessary.
      formattedData = this.applyStylesByKey(formattedData, customSerie);

      this.deepSetIfDef(result, 'data', formattedData);
      this.deepSetIfDef(result, 'dimensions', customSerie.dimensions);
      this.deepSetIfDef(result, 'renderItem', customSerie.renderItem);
      this.deepSetIfDef(result, 'selectedMode', customSerie.selectedMode);
    }

    if (this._isPieSerie(serie)) {
      const pieSerie = serie as GPieChartSerie;
      this.deepSetIfDef(result, 'radius', pieSerie.radius);
      this.deepSetIfDef(result, 'center', pieSerie.center);
      const formattedData = pieSerie.dataPoints;
      this.deepSetIfDef(result, 'data', formattedData);
    }

    if (this._isSchematicSerie(serie)) {
      result = this._objectHelperService.clone(serie, false);
    }

    if (this._isMapSerie(serie)) {
      result = this._objectHelperService.clone(serie, true);
    }

    if (
      !this._isCustomSerie(serie) &&
      !this._isPieSerie(serie) &&
      !this._isSchematicSerie(serie) &&
      !this._isMapSerie(serie)
    ) {
      const cartesianSerie = serie as GCartesianChartSerie;
      this.setCommonCartesianMappings(result, serie);

      const formattedData = (serie as GCartesianChartSerie).dataPoints.map((point) => ({
        value: [point.pointCategory, point.pointValue],
        itemStyle: {
          color: point.color,
        },
        symbol: point.hasToShowSymbol ? undefined : cartesianSerie?.symbol,
        symbolSize: this.checkShowSymbol(cartesianSerie?.largeNumberOfPoints, point.hasToShowSymbol)
          ? point.symbolSize
          : 0,
        hasToShowSymbol: point.hasToShowSymbol,
        alwaysShowSymbol: point.alwaysShowSymbol,
        largeNumberOfPoints: cartesianSerie.largeNumberOfPoints,
        extraProperties: point.extraProperties,
        pointLabel: point.pointLabel,
      }));
      this.deepSetIfDef(result, 'data', formattedData);
      this.deepSetIfDef(result, 'markPoint', { data: cartesianSerie.markPoints });
      this.deepSetIfDef(result, 'label', cartesianSerie.label);
      this.deepSetIfDef(result, 'large', cartesianSerie?.largeData ?? false);
      // this.deepSetIfDef(result, 'symbol', cartesianSerie?.symbol);
    }

    if (this._isBarSerie(serie)) {
      this.deepSetIfDef(result, 'barMaxWidth', this._defaultBarMaxWidth);
    }

    return result;
  }

  checkShowSymbol(serieWithLargeNumberOfPoints: boolean, showAlwaysPoint: boolean) {
    if (!serieWithLargeNumberOfPoints) {
      return true;
    }
    if (showAlwaysPoint) {
      return true;
    }

    return false;
  }

  private formatDataByDimensions<T>(items: T[], dimensions: string[]): any[] {
    const results = items.map((item) => {
      const formattedItem = [];
      dimensions.forEach((_, dimensionKey) => {
        formattedItem.push(item[dimensionKey]);
      });
      return formattedItem;
    });
    return results;
  }

  private applyStylesByKey(data: any[][], serie: GCartesianCustomChartSerie): any {
    const { itemStyleByKey, itemDimensionKey } = serie;
    if (typeof itemStyleByKey !== 'undefined' && typeof itemDimensionKey !== 'undefined') {
      const keyDimension = serie.dimensions.find((dimension) => dimension === itemDimensionKey);

      const resultData = data.map((item) => {
        const key = item[keyDimension];
        if (itemStyleByKey.has(key)) {
          const itemStylesByMode: Map<string, GChartCustomItemStyle> = itemStyleByKey.get(key);
          const itemStyle = this.getItemStyleFromCustomStyles(itemStylesByMode);
          if (typeof itemStyle !== 'undefined') {
            const resultItem = {
              value: item,
              itemStyle,
            };
            return resultItem;
          }
        }
        // If it does not have an itemStyle, just return the original item.
        return item;
      });
      return resultData;
    }
    return data;
  }

  /**
   * As one item can currently have only one itemStyle property, we have to merge all the configs in prioritized order.
   */
  private getItemStyleFromCustomStyles(
    styles: Map<string, GChartCustomItemStyle>
  ): GChartItemStyle {
    let jointItemStyle: GChartItemStyle = {};

    const sortedStyles = Array.from(styles.values()).sort((a, b) => b.priority - a.priority);
    sortedStyles.forEach((style) => Object.assign(jointItemStyle, style.itemStyle));

    return jointItemStyle;
  }

  private setCommonCartesianMappings(result: {}, serie: BaseGCartesianChartSeries) {
    this.deepSetIfDef(result, 'xAxisIndex', serie.xAxisIndex);
    this.deepSetIfDef(result, 'yAxisIndex', serie.yAxisIndex);
    this.deepSetIfDef(result, 'labelLayout.hideOverlap', serie.labelLayout?.hideOverlap);
    this.deepSetIfDef(result, 'encode', serie.encode);
    this.deepSetIfDef(result, 'lineStyle', serie.lineStyle);
    this.deepSetIfDef(result, 'itemStyle', serie.itemStyle);

    if (serie.markArea) {
      this.deepSetIfDef(result, 'markArea', serie.markArea);
    }
  }

  /**
   * Map the array of axes. All mapped axes must keep the same position in the result array.
   */
  private mapAxes(eChartOpts: EChartsOption, path: string, axes: GChartAxis[]): void {
    const mappedAxes = axes.map((axis) => {
      const mappedAxis = {};
      this.deepSetIfDef(mappedAxis, 'id', axis.id);
      this.deepSetIfDef(mappedAxis, 'show', axis.show);
      this.deepSetIfDef(mappedAxis, 'position', axis.position);
      this.deepSetIfDef(mappedAxis, 'name', axis.name);
      this.deepSetIfDef(mappedAxis, 'nameLocation', axis.nameLocation);
      this.mapTextStyles(mappedAxis, 'nameTextStyle', axis.nameTextStyle);
      this.deepSetIfDef(mappedAxis, 'type', axis.type);
      this.deepSetIfDef(mappedAxis, 'splitNumber', axis.splitNumber);
      this.deepSetIfDef(mappedAxis, 'min', axis.min);
      this.deepSetIfDef(mappedAxis, 'max', axis.max);
      this.deepSetIfDef(mappedAxis, 'axisLabel', axis.axisLabel);
      this.deepSetIfDef(mappedAxis, 'axisLine', axis.axisLine);
      this.deepSetIfDef(mappedAxis, 'axisTick', axis.axisTick);
      this.deepSetIfDef(mappedAxis, 'splitLine', axis.splitLine);
      this.deepSetIfDef(mappedAxis, 'boundaryGap', axis.boundaryGap);
      this.deepSetIfDef(mappedAxis, 'offset', axis.offset);
      this.deepSetIfDef(mappedAxis, 'data', axis.data);
      this.deepSetIfDef(mappedAxis, 'scale', axis.scale);
      this.deepSetIfDef(mappedAxis, 'interval', axis.interval);

      return mappedAxis;
    });
    this.deepSetIfDef(eChartOpts, path, mappedAxes);
  }

  private _defined = (value) => typeof value !== 'undefined' && value !== null;

  private _isCustomSerie(serie: BaseGCartesianChartSeries): boolean {
    return serie.type === 'custom';
  }

  private _isPieSerie(serie: BaseGCartesianChartSeries): boolean {
    return serie.type === 'pie';
  }

  private _isSchematicSerie(serie: BaseGCartesianChartSeries): boolean {
    return serie.type === 'graph';
  }

  private _isMapSerie(serie): boolean {
    return serie.type === 'map';
  }

  private _isBarSerie(serie): boolean {
    return serie.type === 'bar';
  }
}
