import { Component, Inject, Injector, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { IntegrationGisLayersService } from '../../integration-gis-layers.service';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-map-widget';

export const INTEGRATION_GIS_LAYER_MAP_WIDGET_INSTANCE = COMPONENT_SELECTOR + '#1';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers-map-widget.component.html',
  styleUrls: ['./integration-gis-layers-map-widget.component.scss'],
  providers: [ReduxStateService],
})
export class IntegrationGisLayersMapWidgetComponent extends BaseDynamicWidgetComponent {
  private readonly _integrationGisLayersService = inject(IntegrationGisLayersService);
  readonly geojsonFeatures$: Observable<GeoJSON.FeatureCollection>;
  readonly selectedElementTypeId$: Observable<number[]>;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
    this.geojsonFeatures$ = this._integrationGisLayersService.getGeojsonFeatures();
    this.selectedElementTypeId$ = this._integrationGisLayersService
      .getElementTypeId()
      .pipe(map((elementTypeId) => (elementTypeId ? [elementTypeId] : [])));
  }

  onWidgetInit(): void {}

  get componentName(): string {
    return 'IntegrationGisLayersMapWidgetComponent';
  }
}
