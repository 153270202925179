import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { BiFilters } from 'src/app/common-modules/dependencies/bi/bi-filters';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { ToJointStringsMapper } from 'src/app/common-modules/shared/services/generic-mappers/mappers/to-joint-strings.mapper';

const COMPONENT_SELECTOR = 'wlm-widget-info';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.scss'],
})
export class WidgetInfoComponent implements OnInit, OnChanges {
  @Input() filters: BiFilters;
  @Input() paramsTitle: string;
  @Input() baseTitle: string;
  @Output() sizeChanged = new EventEmitter<IElementSize>();

  formattedDateRange: string;
  formattedHierarchyNames: string;
  panelOpenState = false;
  private displayTitle: string;
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;
  private readonly _defaultHeight = 36; // px of height when closed

  constructor(
    private _dateHelperService: DateHelperService,
    private _localization: LocalizationHelperService,
    private _toJointStringsMapper: ToJointStringsMapper
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setFields();
  }

  ngOnInit(): void {
    this.emitDefaultSize();
  }

  emitDefaultSize(): void {
    this.sizeChanged.emit({
      height: this._defaultHeight,
      width: undefined,
    });
  }

  private setFields() {
    if (!this.filters) {
      return;
    }

    this.buildTitle();

    forkJoin([this.formatDates(), this.formatHierarchyNames()]).subscribe(
      ([formattedDateRange, formattedHierarchyNames]) => {
        this.formattedDateRange = formattedDateRange;
        this.formattedHierarchyNames = formattedHierarchyNames;
      }
    );
  }

  private buildTitle(): void {
    if (!this.baseTitle) {
      this.displayTitle = this.paramsTitle;
    } else if (this.paramsTitle) {
      this.displayTitle = this.paramsTitle !== this.baseTitle ? this.paramsTitle : null;
    }
  }

  private formatDates(): Observable<string> {
    const startDate = this._dateHelperService.ensureDateObject(
      this.filters.selectedDateRange.start
    );

    const endDate = this._dateHelperService.addDays(
      this._dateHelperService.ensureDateObject(this.filters.selectedDateRange.end),
      -1
    );

    return this._localization.formatDateRange(startDate, endDate);
  }

  private formatHierarchyNames(): Observable<string> {
    let elementLabels;
    if (this.filters.selectedElements) {
      elementLabels = this.filters.selectedElements.map((element) => element.label);
    } else {
      elementLabels = this.filters.selectedIds;
    }
    return this._toJointStringsMapper.map(elementLabels, this.filters, {
      stringSeparator: ', ',
    });
  }
}
