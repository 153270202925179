<wlm-placeholder-overlay
  *ngIf="!chartConfiguration"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-data-visualization-chart
  *ngIf="chartConfiguration"
  [chartConfiguration]="chartConfiguration"
  [containerSize$]="size$"
  (chartConfigurationChange)="onChartConfigurationChange($event)"
></wlm-data-visualization-chart>
