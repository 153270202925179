import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import * as $ from 'jquery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { translateModuleConfigurator } from './translate-module.configurator';
import { CustomerModule } from './water-loss/features/customer/customer.module';
import { WaterLossModule } from './water-loss/water-loss.module';

// Golden Layoyt requires to have JQuery as global in the window object.
window['$'] = $;

// Translation json configuration moved to translate-module.configurator.ts
@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    TranslateModule.forRoot(translateModuleConfigurator),
    //AppHubModule,
    WaterLossModule,

    // WlmNavigationModule,
    // WlmSharedModule,
    // ALCModule,
    // HomeModule,
    // WlmSecurityModule,
    // DragDropModule,
    // FlexLayoutModule,
    // RightPanelModule,
    // CustomerModule,
    // WlmFiltersModule,
    // WaterBalanceModule,
    // DataVisualizationModule,
    // AlarmsModule,
    // AlgorithmsModule,
    // ReduxStateModule,
    // DynamicLayoutModule,
    // ScrollingModule,
    // FormlyModule,
    // FormlyMaterialModule,
    // FormlyMatDatepickerModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [CustomerModule, TranslateModule],
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
