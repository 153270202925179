import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { IHierarchyElementDto } from 'src/app/common-modules/dependencies/he/hierarchy-element.dto';
import { HierarchyService } from 'src/app/common-modules/dependencies/he/hierarchy.service';
import { INetworkElementLarsAndSworpsSitesDto } from 'src/app/common-modules/dependencies/ne/network-element-lars-sworps-sites.dto';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { NetworkElementsService } from 'src/app/common-modules/shared-component/combined-grids/ne-selection-grid/ne-selection.service';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IAlgorithmDto } from 'src/app/common-modules/shared/model/algorithm/algorithm.dto';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { DIMENSION_ITEMS } from 'src/app/common-modules/uom/models/dimension-items';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { IDeletedZonesDto } from '../model/deleted-zones/deleted-zones.dto';
import { AlgorithmFiltrableItemDto } from '../model/filtrable-items/algorithm-filtrable-item.dto';
import { SignalFiltrableItemDto } from '../model/filtrable-items/signal-filtrable-item.dto';
import { AlgorithmFiltrableType } from '../model/filtrable-items/types/algorithm-filtrable-type';
import { SignalFiltrableType } from '../model/filtrable-items/types/signal-filtrable-type';

@UntilDestroy()
@Injectable()
export class FiltrableItemMapperService {
  private readonly _tsLocation = 'common.network-element-types';
  private _dimensions = DIMENSION_ITEMS;

  constructor(
    private readonly _localizationService: LocalizationHelperService,
    private readonly _hierarchyService: HierarchyService,
    private readonly _neService: NetworkElementsService
  ) {}

  getAlgorithmFiltrableItemDto(
    algorithm: IAlgorithmDto,
    element: any
  ): Observable<AlgorithmFiltrableItemDto> {
    switch (algorithm.entityTypeId) {
      case EntityTypes.hierarchyElement:
        return this.getAlgorithmFiltrableItemFromHe(
          algorithm,
          element as IHierarchyElementDto | IDeletedZonesDto
        );

      case EntityTypes.networkElement:
        return this.getAlgorithmFiltrableItemFromNe(algorithm, element as INetworkElementDto);

      case EntityTypes.larsAndSworpsSite:
        return this.getAlgorithmFiltrableItemFromNe(
          algorithm,
          element as INetworkElementLarsAndSworpsSitesDto,
          element.siteId
        );

      default:
        return of(null);
    }
  }

  getSignalFiltrableItemDto(signal: ISignalTelemetryNullableViewDto): SignalFiltrableItemDto {
    const { signalId, pointId, pointDescription, isConfigured, dimensionTypeId } = signal;
    const iconName = this.getIconName(dimensionTypeId);
    const title = `${pointId} - ${pointDescription}`;

    const filtrableType = new SignalFiltrableType(
      title,
      signalId,
      pointId,
      pointDescription,
      dimensionTypeId,
      isConfigured,
      iconName
    );

    return new SignalFiltrableItemDto({
      startDate: null,
      endDate: null,
      filtrableType: filtrableType,
    });
  }

  getAlgorithmFiltrableItemDtoByWlmElement(
    algorithm: IAlgorithmDto,
    element: WlmElementExtended
  ): Observable<AlgorithmFiltrableItemDto> {
    const { elementId, elementTypeId, name, typeName, alternativeId } = element;

    const elementName = algorithm.entityTypeId === EntityTypes.hierarchyElement ? elementId : name;
    const title = `${elementName} - ${algorithm.algorithmShortName}`;

    const filtrableType = this.getAlgorithmFiltrableType(
      algorithm,
      title,
      elementId,
      elementTypeId,
      name,
      typeName,
      alternativeId
    );

    return of(
      new AlgorithmFiltrableItemDto({
        startDate: null,
        endDate: null,
        filtrableType: filtrableType,
      })
    );
  }

  private getAlgorithmFiltrableItemFromHe(
    algorithm: IAlgorithmDto,
    element: IHierarchyElementDto | IDeletedZonesDto
  ): Observable<AlgorithmFiltrableItemDto> {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementTypeName } = element;
    const title = `${hierarchyElementId} - ${algorithm.algorithmShortName}`;

    const filtrableType = this.getAlgorithmFiltrableType(
      algorithm,
      title,
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementId,
      hierarchyElementTypeName,
      null
    );

    return of(
      new AlgorithmFiltrableItemDto({
        startDate: null,
        endDate: null,
        filtrableType: filtrableType,
      })
    );
  }

  setAlgorithmFiltrableItemElement(
    algorithm: IAlgorithmDto,
    element: WlmElementExtended
  ): Observable<AlgorithmFiltrableItemDto> {
    switch (algorithm.entityTypeId) {
      case EntityTypes.hierarchyElement:
        return this._hierarchyService.getByKey(element.elementId).pipe(
          take(1),
          switchMap((hierarchyElement) => {
            return this.getAlgorithmFiltrableItemFromHe(
              algorithm,
              hierarchyElement as IHierarchyElementDto | IDeletedZonesDto
            );
          })
        );

      case EntityTypes.networkElement:
        return this._neService.getNeById(element.elementId).pipe(
          take(1),
          switchMap((ne) => {
            return this.getAlgorithmFiltrableItemFromNe(algorithm, ne);
          })
        );

      default:
        return of(null);
    }
  }

  private getAlgorithmFiltrableItemFromNe(
    algorithm: IAlgorithmDto,
    element: INetworkElementLarsAndSworpsSitesDto | INetworkElementDto,
    siteId?: string
  ): Observable<AlgorithmFiltrableItemDto> {
    return this._localizationService.get(this._tsLocation).pipe(
      untilDestroyed(this),
      map((ts) => {
        const { networkElementId, networkElementTypeId, networkElementName } = element;
        const title = `${networkElementName} - ${algorithm.algorithmShortName}`;

        const filtrableType = this.getAlgorithmFiltrableType(
          algorithm,
          title,
          networkElementId,
          networkElementTypeId.toString(),
          networkElementName,
          ts[networkElementTypeId],
          siteId ?? null
        );

        return new AlgorithmFiltrableItemDto({
          startDate: null,
          endDate: null,
          filtrableType: filtrableType,
        });
      })
    );
  }

  private getAlgorithmFiltrableType(
    algorithm: IAlgorithmDto,
    title: string,
    elementId: string,
    elementTypeId: string,
    name: string,
    typeName: string,
    alternativeId: string
  ) {
    const iconName = this.getIconName(algorithm?.dimensionTypeId);

    const {
      algorithmShortName,
      algorithmDescription,
      dimensionTypeId,
      timeAggregationId,
      entityTypeId,
    } = algorithm;

    return new AlgorithmFiltrableType(
      title,
      algorithmShortName,
      algorithmDescription,
      dimensionTypeId,
      timeAggregationId,
      iconName,
      elementId,
      elementTypeId,
      name,
      typeName,
      entityTypeId,
      alternativeId
    );
  }

  private getIconName = (dimensionTypeId) =>
    this._dimensions.find((d) => d.id === dimensionTypeId)?.iconName;
}
