import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { BoundaryConfigurationDto } from '../../shared/model/signals/boundary-configuration.dto';
import { BoundarySignalDto } from '../../shared/model/signals/boundary-signal.dto';

import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { NeConfigurationBoundaryService } from './ne-configuration-boundary.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-boundary';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-boundary.component.html',
  styleUrls: ['./ne-configuration-boundary.component.scss'],
})
export class NeConfigurationBoundaryComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter
{
  private _selectedNE: INetworkElementDto;
  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }

  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();
      this.loadSignals();
    }
  }

  @Input() widgetId: string;
  @Input() pageId: string;
  @Output() hasChangesEvent = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'NeConfigurationBoundaryComponent';
  }

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.hasChangesEvent.emit(this.configurationHasChanged);
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  reloadList$ = new Subject<void>();
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  signalsIn: BoundarySignalDto[] = [];
  signalsOut: BoundarySignalDto[] = [];
  excludedSignals: BoundarySignalDto[] = [];
  refreshList$ = new Subject<void>();
  isReadOnly = true;

  private _originalSignalsIn: BoundarySignalDto[] = [];
  private _originalSignalsOut: BoundarySignalDto[] = [];
  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _elementIdParamName = 'elementId';
  private _isZoneParamName = 'isZone';
  private _serviceName = 'NeConfigurationBoundaryService';
  private _pagesize = 50;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _boundaryService: NeConfigurationBoundaryService,
    private readonly _dialogService: DialogService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  loadSignals() {
    this._spinnerService.setLoading(true, this.componentName);
    this._boundaryService.getBoundarySignalsByZone(this.selectedNE.hierarchyElementId).subscribe({
      next: (boundaryConfiguration) => {
        if (boundaryConfiguration) {
          const sortedBoundaryConfiguration =
            this._arrayHelperService.sortArrayObjectCaseInsensitive(
              boundaryConfiguration,
              this._pointDescriptionFieldName
            );

          this.signalsIn = sortedBoundaryConfiguration.filter((x) => x.isIn === true);
          this._originalSignalsIn = sortedBoundaryConfiguration.filter((x) => x.isIn === true);
          this.signalsOut = sortedBoundaryConfiguration.filter((x) => x.isIn === false);
          this._originalSignalsOut = sortedBoundaryConfiguration.filter((x) => x.isIn === false);

          this.configurationHasChanged = false;
          this.excludedSignals = [];
        }
        this._spinnerService.setLoading(false, this.componentName);
      },
    });
  }

  prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.widgetId,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._elementIdParamName, this.selectedNE?.elementId);
    newParams.set(this._isZoneParamName, this.selectedNE?.isZone);

    this.queryParams = newParams;
  }

  compareSignals() {
    if (this.listHasChanged(this.signalsIn, this._originalSignalsIn)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.signalsOut, this._originalSignalsOut)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  listHasChanged(current: BoundarySignalDto[], original: BoundarySignalDto[]) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  onDroppedElement($event) {
    const newExcludedSignals = [...this.excludedSignals];
    newExcludedSignals.push($event);
    this.excludedSignals = [...newExcludedSignals];
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    this._spinnerService.setLoading(true, this.componentName);
    networkElement = networkElement === undefined ? this.selectedNE : networkElement;
    this.signalsIn.forEach((signal) => {
      signal.hierarchyElementId = networkElement.elementId;
      signal.isIn = true;
    });

    this.signalsOut.forEach((signal) => {
      signal.hierarchyElementId = networkElement.elementId;
      signal.isIn = false;
    });

    const allSignals = this.signalsIn.concat(this.signalsOut);

    const newConfiguration = new BoundaryConfigurationDto({
      hierarchyElementId: networkElement.elementId,
      signals: allSignals,
    });

    return this._boundaryService.saveBoundaryConfiguration(newConfiguration).pipe(
      tap((result) => {
        let dialogSettings = new WlmDialogSettings({ translateKey: '' });
        if (!result.success) {
          dialogSettings.translateKey = `${this.T_SCOPE}.messages.${result.errorMessage}`;
          dialogSettings.icon = 'error';
          this._dialogService.showTranslatedMessage(dialogSettings);
          this._spinnerService.setLoading(false, this.componentName);
          return;
        } else {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
        }
        this._spinnerService.setLoading(false, this.componentName);
        this.updateOriginalLists();
        this.reloadList$.next();
      }),
      catchError((error) => {
        this.showErrorSavingDialog(error);
        this._spinnerService.setLoading(false, this.componentName);
        return of(null);
      }),
      map((_) => true)
    );
  }

  private showErrorSavingDialog(error) {
    const errorMessageKey = `${this.T_SCOPE}.messages.save-error-000`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey });
    dialogSettings.icon = 'error';

    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  updateOriginalLists() {
    this._originalSignalsIn = [...this.signalsIn];
    this._originalSignalsOut = [...this.signalsOut];
    this.configurationHasChanged = false;
  }

  discard() {
    this.signalsIn = [...this._originalSignalsIn];
    this.signalsOut = [...this._originalSignalsOut];
    this.excludedSignals = [];
    this.reloadList$.next();

    this.configurationHasChanged = false;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
