import { DynamicSettings } from './dynamic-settings';

export class DynamicSettingsSave extends DynamicSettings {
  settingValue: any;
  saveAsDefault? = false;
  componentTypeId?: number;
  createComponentIfNoExists? = false;

  constructor(init: DynamicSettingsSave) {
    super(init);
    Object.assign(this, init);
  }
}
