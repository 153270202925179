import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseResponsiveWidgetComponent } from 'src/app/common-modules/shared/component/base-responsive-widget.component';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { TrendChartSettings } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-settings';
import { WaterBalanceChartConfiguration } from '../../shared/model/water-balance/water-balance-chart-configuration';
// prettier-ignore

const COMPONENT_SELECTOR = 'wlm-water-balance-chart';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-chart.component.html',
  styleUrls: ['./water-balance-chart.component.scss'],
})
export class WaterBalanceChartComponent extends BaseResponsiveWidgetComponent implements OnInit {
  dimensionToCalculate: 'height' | 'width' = 'height';

  waterBalanceChartConfiguration: WaterBalanceChartConfiguration;
  chartSettings: TrendChartSettings;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initWidgetResponsive();
  }

  get componentName() {
    return 'WaterBalanceChartComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.waterBalanceChartConfiguration = parameters.parameters.get(
      TabDetailParameterName.waterBalanceChart
    );
  }

  init(): void {
    if (!this.waterBalanceChartConfiguration) {
      return;
    }
    this.chartSettings = {
      dataParameters: {
        dataService: 'WaterBalanceChartService',
        endDate: this.waterBalanceChartConfiguration.endDate,
        startDate: this.waterBalanceChartConfiguration.startDate,
        queryParams: this.waterBalanceChartConfiguration,
      },
      chartType: ChartType.trend,
    };
  }

  onChartLoading(isLoading): void {
    this.loading = isLoading;
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [];
  }
}
