import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import {
  ButtonTogglerItemSettings,
  ButtonTogglerSettings,
} from 'src/app/common-modules/shared-component/button-toggler/button-toggler-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { ICanLeavePage } from 'src/app/common-modules/shared/navigation/can-component-deactivate';
import { NeConfigurationLarsSworpsHistoricalComponent } from '../ne-configuration-lars-sworps-historical/ne-configuration-lars-sworps-historical.component';
import { NeConfigurationLarsSworpsComponent } from '../ne-configuration-lars-sworps/ne-configuration-lars-sworps.component';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-lars-sworps-switcher';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-lars-sworps-switcher.component.html',
  styleUrls: ['./ne-configuration-lars-sworps-switcher.component.scss'],
})
export class NeConfigurationLarsSworpsSwitcherComponent implements OnInit, ICanLeavePage {
  @Input() selectedNE: INetworkElementDto;
  @Input() pageId: string;
  @Input() widgetId: string;

  @ViewChild(NeConfigurationLarsSworpsHistoricalComponent)
  historical: NeConfigurationLarsSworpsHistoricalComponent;
  @ViewChild(NeConfigurationLarsSworpsComponent) current: NeConfigurationLarsSworpsComponent;
  @ViewChild(MatButtonToggleGroup) toogleGroup: MatButtonToggleGroup;

  buttonTogglerSettings: ButtonTogglerSettings;
  toggleViewMode: 'current' | 'historical' = 'current';
  historicalMode = 'historical';
  currentMode = 'current';

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  isReadOnly: boolean;

  get activeComponent(): string {
    return (this.current ?? this.historical).constructor?.name;
  }

  constructor(private _dialogService: DialogService, private _authorizeService: AuthorizeService) {
    this.buttonTogglerSettings = this.buildButtonTogglerSettings();
  }

  ngOnInit(): void {
    this.checkReadOnlyAccess();
  }

  //TODO: Move logic to NePageComponent
  canLeavePage(): Observable<boolean> {
    const historicalHasChanges = this.historical?.hasChanges;
    const currentHasChanges = this.current?.configurationHasChanged;
    if (historicalHasChanges || currentHasChanges) {
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.confirm-save-pending-changes`,
        icon: 'warning',
      });

      return this._dialogService.showTranslatedDialogMessage(dialogSettings).pipe(
        map((payload: WLMDialogResult) => {
          return payload.result;
        })
      );
    }
    return of(true);
  }

  private buildButtonTogglerSettings(): ButtonTogglerSettings {
    return new ButtonTogglerSettings({
      buttons: [
        new ButtonTogglerItemSettings({
          value: 'current',
          labelKey: 'current',
          beforeToggle: this.beforeToggle,
        }),
        new ButtonTogglerItemSettings({
          value: 'historical',
          labelKey: 'historical',
        }),
      ],
    });
  }

  private beforeToggle = (value: 'current' | 'historical'): Observable<boolean> => {
    return of(false);
  };

  onChange(event: MatButtonToggleChange) {
    const historicalHasChanges = this.historical?.hasChanges;
    const currentHasChanges = this.current?.configurationHasChanged;

    if (historicalHasChanges || currentHasChanges) {
      this.toogleGroup.value = this.toggleViewMode;
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.confirm-save-pending-changes`,
        icon: 'warning',
      });

      this._dialogService
        .showTranslatedDialogMessage(dialogSettings)
        .pipe(
          map((payload: WLMDialogResult) => {
            return payload.result;
          })
        )
        .subscribe((response) => {
          if (!response) {
          } else {
            this.toggleViewMode = event.value;
          }
        });
    } else {
      this.toggleViewMode = event.value;
    }
  }

  checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }
}
