export class SelectOption<T> {
  value: T;
  label: string;

  constructor(init?: SelectOption<T>) {
    Object.assign(this, init);
  }

  static mapMany<T, T1>(
    elements: T1[],
    mapValue: (element: T1) => T,
    mapLabel: (element: T1) => string
  ): SelectOption<T>[] {
    return elements.map(
      (element) =>
        new SelectOption<T>({
          value: mapValue(element),
          label: mapLabel(element),
        })
    );
  }
}
