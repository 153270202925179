import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, map } from 'rxjs';
import { SettingsService } from '../../shared/config/settings.service';
import { SpinnerService } from '../spinner.service';

const COMPONENT_SELECTOR = 'wlm-spinner';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() message: string;
  @Input() fromOverlay = false;
  @Input() mode: 'relative' | 'absolute' = 'absolute';
  @Output() selfClose = new EventEmitter<void>();

  // Avoid showing localized spinner if the global overlay spinner is being shown.
  readonly isSpinnerOverlayActive$: Observable<boolean>;
  readonly canShowSpinner$: Observable<boolean>;
  readonly _destroyRef = inject(DestroyRef);
  showCloseButton = false;
  private _showSelfClose = false;
  private _showSelfCloseOnMs = null;

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _settingsService: SettingsService
  ) {
    this.isSpinnerOverlayActive$ = this._spinnerService.loading$;

    // The spinner used in the overlay should always show when invoked.
    // If not from overlay (standalone or in wrapper), hide while the overlay is active.
    this.canShowSpinner$ = this.isSpinnerOverlayActive$.pipe(
      map((isSpinnerOverlayActive) => {
        return this.fromOverlay || !isSpinnerOverlayActive;
      })
    );
  }

  ngOnInit(): void {
    this.startSelfCloseTimer();
  }

  onCloseSpinner(): void {
    this.selfClose.emit();
  }

  private startSelfCloseTimer(): void {
    this._settingsService.ready$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._showSelfClose = this._settingsService.spinner?.showSelfClose;
      this._showSelfCloseOnMs = this._settingsService.spinner?.showSelfCloseOnMs;

      if (this._showSelfClose) {
        setTimeout(() => {
          this.showCloseButton = true;
        }, this._showSelfCloseOnMs);
      }
    });
  }
}
