import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IElementSize } from '../model/element-size';
import { ObservablesService } from './observables.service';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveManagerService {
  private readonly _observablesService: ObservablesService = inject(ObservablesService);

  emitSizeChanged(instanceId: string, size: IElementSize): void {
    const key = this.buildSizeChangeKey(instanceId);
    this._observablesService.emit(key, size);
  }

  listenSizeChanged$(instanceId: string): Observable<IElementSize> {
    const key = this.buildSizeChangeKey(instanceId);
    return this._observablesService.listen(key);
  }

  private buildSizeChangeKey = (instanceId: string) => `${instanceId}#SIZE_CHANGED`;
}
