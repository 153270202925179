import { SettingsEntityType } from 'src/app/common-modules/dynamic-forms/models/settings-entity-type';
import { DynamicFormSaveMetadata } from './dynamic-form-save-metadata';

export class DynamicFormSave {
  entityType: SettingsEntityType;
  elementId: any;
  elementName: string;
  values: { [key: string]: any };
  metadata: DynamicFormSaveMetadata[];

  constructor(init: DynamicFormSave) {
    Object.assign(this, init);
  }
}
