import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { MergedZonesPageComponent } from './merged-zones-page/merged-zones-page.component';

const routes: Routes = [
  {
    path: '',
    component: MergedZonesPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.MergedZones,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MergedZonesRoutingModule {
  static injector: Injector;

  constructor(injector: Injector) {
    MergedZonesRoutingModule.injector = injector;
  }
}
