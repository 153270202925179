// prettier-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { WidgetDefinitionSettings } from 'src/app/common-modules/dynamic-layout/models/widget-definition-settings';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { FlatTreeNode } from 'src/app/common-modules/shared/tree/flat-tree-node';

import { WidgetSelectorSettings } from './widget-selector-settings';

const COMPONENT_SELECTOR = 'wlm-widget-selector';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './widget-selector.component.html',
  styleUrls: ['./widget-selector.component.scss'],
})
export class WidgetSelectorComponent implements OnInit, OnChanges {
  @Input() settings: WidgetSelectorSettings;
  @Output() definitionSelected = new EventEmitter<WidgetDefinitionSettings>();

  treeNodes: FlatTreeNode<string, WidgetDefinitionSettings>[];
  filterText: string;
  expandedKeys: string[] = [];
  expandedKeysHash: { [key: string]: true } = {};

  private mapToTreeNodesSubs: Subscription;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.resetSubscription(this.mapToTreeNodesSubs);
      this.mapToTreeNodesSubs = this.mapToTreeNodes(this.settings).subscribe((nodes) => {
        this.treeNodes = nodes;
      });
    }
  }

  onDefinitionSelected(definition: WidgetDefinitionSettings): void {
    this.definitionSelected.emit(definition);
  }

  onGroupToggle(groupName: string): void {
    if (this.expandedKeys.find((key) => key === groupName)) {
      this.expandedKeys = this.expandedKeys.filter((key) => key !== groupName);
    } else {
      this.expandedKeys = this.expandedKeys.concat([groupName]);
    }

    this.expandedKeysHash = this.expandedKeys.reduce((accum, current) => {
      accum[current] = true;
      return accum;
    }, {});
  }

  private mapToTreeNodes({
    definitions,
    groups,
  }: WidgetSelectorSettings): Observable<FlatTreeNode<string, WidgetDefinitionSettings>[]> {
    const nodes = [];
    const currentGroups = new Map(groups);

    definitions?.forEach((definition) => {
      const leafNode = new FlatTreeNode<string, WidgetDefinitionSettings>({
        id: globalUtilsHelper.generateGuid(),
        group: definition.group,
        label: definition.label,
        data: definition,
        isLeaf: true,
        icon: definition.icon,
        isSvgIcon: definition.isSvgIcon,
      });

      nodes.push(leafNode);

      // Is a group is mentioned but it does not exist in the groups config, add it to the set of declared groups.
      if (definition.group && !currentGroups.has(definition.group)) {
        const groupNode = new FlatTreeNode<string, WidgetDefinitionSettings>({
          id: definition.group,
          label: definition.group,
          isLeaf: false,
        });

        nodes.push(groupNode);
        currentGroups.set(definition.group, groupNode);
      }
    });

    groups.forEach((groupNode) => {
      nodes.push(groupNode);
    });

    return of(nodes);
  }

  private resetSubscription(subs: Subscription): void {
    if (subs && !subs.closed) {
      subs.unsubscribe();
    }
  }
}
