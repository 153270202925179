import { WLMElement } from './element';

export class WlmElementExtended extends WLMElement {
  name: string;
  typeName: string;
  alternativeId: string;
  entityType?: number;

  constructor(
    elementId: string,
    elementTypeId: string,
    name: string,
    typeName: string,
    alternativeId: string,
    entityType?: number
  ) {
    super(elementId, elementTypeId);

    this.name = name;
    this.typeName = typeName;
    this.alternativeId = alternativeId;
    this.entityType = entityType;
  }
}
