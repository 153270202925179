import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGridSettings } from '../constants/grid.constants';
import { LabelValueListItem } from '../core/label-value-list/label-value-list-item';
import { GridColumnHelperService } from '../helpers/grid-column-helper.service';
import { MapperFunctions } from './generic-mappers/mapper-functions';
import { ToAlgorithmUoMMapperParams } from './generic-mappers/mapper-params/to-algorithm-uom-mapper-params';

@Injectable()
export class RightPanelAttributesService {
  private _baseTranslateKey: string;
  private _hierarchyElementTypeIdField: string;

  private readonly _algorithmDecimals = 3;

  init(data: { baseTranslateKey: string; hierarchyElementTypeIdField: string }): void {
    this._baseTranslateKey = data.baseTranslateKey;
    this._hierarchyElementTypeIdField = data.hierarchyElementTypeIdField;
  }

  constructor(private readonly _gridColumnHelperService: GridColumnHelperService) { }

  getRightPanelHeaderFromSettings(gridSettings: IGridSettings, element: any): Observable<string> {
    const header = gridSettings?.rightPanelHeaderSetting?.header;
    if (!header || !element) {
      return of(null);
    }

    const columnSettings = this._gridColumnHelperService.getColumnSettingsByField(
      gridSettings,
      header.field
    );

    const label$ = header.label ? of(header.label) : this._gridColumnHelperService.getColumnTranslatedTitle(columnSettings);

    return forkJoin([label$, this._gridColumnHelperService.processRowDataValues(element, [columnSettings])])
      .pipe(
        map(([label, columns]) => {
          const headerColumn = columns?.[0];

          if (!headerColumn) {
            return null;
          }

          return `${label}: ${headerColumn.value ?? ''}${headerColumn.valueAddon ?? ''}`;
        })
      );
  }

  buildAlgorithmAttribute = (data: {
    shortName: string;
    propertyFn: (values) => any;
  }): LabelValueListItem => {
    return {
      label: `${this._baseTranslateKey}.${data.shortName}`,
      propertyFn: data.propertyFn,
      mappers: [MapperFunctions.ToAlgorithmUoM],
      mappersParams: {
        [MapperFunctions.ToAlgorithmUoM]: {
          algorithmShortName: data.shortName,
          hierarchyElementTypeIdField: this._hierarchyElementTypeIdField,
          decimals: this._algorithmDecimals,
        } as ToAlgorithmUoMMapperParams,
      },
    };
  };
}
