import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivitiesGridFilterValidations } from 'src/app/common-modules/dependencies/alc/activities-grid-filter-validations';
import { BiFilters } from 'src/app/common-modules/dependencies/bi/bi-filters';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { FilterAdditionalParam } from 'src/app/common-modules/dependencies/wlm-filters/filter-additional-param';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { ExtendedFilters } from 'src/app/common-modules/shared/filters/component-filters/extended-filters';
import { DynamicLayoutItemLockedFilters } from '../../models/dynamic-layout-item-locked-filters';
import { DynamicParamsService } from '../../services/dynamic-params.service';
import { DefaultParamsState } from '../../state/default-params/default-params-state';
import { ApplyDefaultParamsAction } from '../../state/default-params/default-params.actions';
import { BiDefaultParamsPopupParams } from '../models/bi-default-params-popup-params';

const COMPONENT_SELECTOR = 'wlm-bi-default-params-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-default-params-popup.component.html',
  styleUrls: ['./bi-default-params-popup.component.scss'],
  providers: [ReduxStateService],
})
export class BiDefaultParamsPopupComponent implements OnInit {
  canLoad: boolean;
  filterDetailsParameters: TabDetailPanelParameters;
  autoLoad: boolean;
  persistFilters$ = new Subject<void>();
  filterSettings: FilterGroupSettings;

  // Filters fieldNames
  readonly fieldNames = {
    hierarchyFamilyId: 'HierarchyFamilyId',
    hierarchyElementId: 'HierarchyElementId',
    startDate: 'StartDate',
    endDate: 'EndDate',
  };

  offsetStartDate = 3;

  dialogData: BiDefaultParamsPopupParams;
  clearAll$ = new Subject<void>();
  lockDates = false;
  lockHierarchy = false;

  paramsToApply: any;

  private filters: DataBindingFilters;

  pageSettingsKeys = {
    layoutKey: 'LayoutMain',
    layoutArea: 'BI',
    widgetPage: 'bi',
    widgetModule: 'bi',
  };
  sharedScopeInstance = {
    [StateAreas.DefaultParams]: 'shared',
    [StateAreas.Filters]: 'shared',
  };

  params: Params;
  isFormValid = false;

  private _stateWidgetSettings = new StateWidgetSettings({
    module: this.pageSettingsKeys.widgetModule,
    page: this.pageSettingsKeys.widgetPage,
    scopeInstanceKeys: this.sharedScopeInstance,
  });

  get componentName(): string {
    return 'BiDefaultParamsPopupComponent';
  }

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  private readonly filterHierarchyField = 'hierarchyElementsIds';
  private readonly filterDateRangeField = 'dateRange';
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;

  constructor(
    private _dialogRef: MatDialogRef<BiDefaultParamsPopupComponent>,
    private _state: ReduxStateService,
    private _dynamicParamsService: DynamicParamsService,
    @Inject(MAT_DIALOG_DATA) dialogData: BiDefaultParamsPopupParams
  ) {
    this.dialogData = dialogData;
    this.lockDates = Boolean(this.dialogData.lockFilters?.dates);
    this.lockHierarchy = Boolean(this.dialogData.lockFilters?.hierarchy);
    this.params = this._dynamicParamsService.getConfigurableParams(
      dialogData.paramsType,
      dialogData.params
    );
    this._state.configure(this._stateWidgetSettings);
  }

  ngOnInit(): void {
    const biFilters = this.dialogData.defaultFilters;
    let params: Params = null;
    if (biFilters) {
      params = {
        hierarchyElementIds: biFilters.selectedIds,
        hierarchyFamilyId: biFilters.selectedFamily,
        startDate: biFilters.selectedDateRange.start,
        endDate: biFilters.selectedDateRange.end,
      };
    }
    this.setFilterSettings(params);
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters([this.filterHierarchyField], filtersParameters)
    ) {
      this.filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  applyFiltersAndParams() {
    if (this.canLoad) {
      this.buildResult().subscribe((result) => {
        this._state.dispatch(new ApplyDefaultParamsAction(result, this._scopeSettings));
        this.persistFilters$.next();
        this.onClose(true);
      });
    }
  }

  onIsFormValid(valid: boolean): void {
    this.isFormValid = valid;
  }

  onParamsChanged(params: any) {
    this.paramsToApply = params;
  }

  // TODO: this method should be refactored, either inside the own filter group or in an independent service.
  private buildExtendedFilters(): ExtendedFilters {
    if (!this.filters) {
      return null;
    }

    const biFilters: Partial<BiFilters> = {};

    const dateRange: any = this.filters.filters.get(this.filterDateRangeField);
    if (dateRange) {
      biFilters.selectedDateRange = dateRange;
    }

    const heFilter: any = this.filters.filters.get(this.filterHierarchyField);
    if (heFilter) {
      biFilters.selectedFamily = heFilter.hierarchyFamilyId;
      biFilters.selectedIds = heFilter.elementIds;
      biFilters.selectedElements = heFilter.elementsWithNames;
    }

    const extendedFilters = new ExtendedFilters({
      dataBindingFilters: this.filters,
      model: biFilters as BiFilters,
    });
    return extendedFilters;
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    this.filterDetailsParameters = value;
    if (this.autoLoad && this.canLoad) {
      this.applyFiltersAndParams();
      this.autoLoad = false;
    }
  }

  onCheckAutoload(): void {}

  onLockDates = () => (this.lockDates = !this.lockDates);

  onLockHierarchy = () => (this.lockHierarchy = !this.lockHierarchy);

  private setFilterSettings(params: Params) {
    // TODO: code duplicated in bi filters. Refactor in bi service.
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.fieldNames.hierarchyElementId,
      }),
      hierarchyFamilyId: new FilterGroupFieldSettings({
        fieldName: this.fieldNames.hierarchyFamilyId,
      }),
      startDate: new FilterGroupFieldSettings({
        fieldName: this.fieldNames.startDate,
      }),
      endDate: new FilterGroupFieldSettings({
        fieldName: this.fieldNames.endDate,
      }),
    };

    const additionalParams = {
      offsetStartDate: new FilterAdditionalParam({ value: this.offsetStartDate }),
      sendRootLevel: new FilterAdditionalParam({ value: true }),
      inclusiveEndDateParam: new FilterAdditionalParam({ value: true }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      additionalParams,
      navigationParams: params,
      persistencyArea: this.getPersistencyArea(),
      avoidPersistency: true,
    });
  }

  onButtonClose(_) {
    this.onClose(false);
  }

  onClose(success: boolean): void {
    this._dialogRef.close(success);
  }

  private buildResult(): Observable<DefaultParamsState> {
    const extendedFilters = this.buildExtendedFilters();
    const lockFilters: DynamicLayoutItemLockedFilters = {
      dates: this.lockDates,
      hierarchy: this.lockHierarchy,
    };
    const { baseTitle, widgetInstanceKey, paramsType } = this.dialogData;
    return this._dynamicParamsService
      .getWidgetTitleByParams(baseTitle, paramsType, this.paramsToApply)
      .pipe(
        map((fullTitle) => {
          const result: DefaultParamsState = {
            params: this.paramsToApply,
            filters: extendedFilters,
            widgetInstanceKey,
            lockFilters,
            title: fullTitle,
            baseTitle,
          };
          return result;
        })
      );
  }

  private getPersistencyArea(): string {
    return `bi-popup-${this.dialogData.widgetInstanceKey}`;
  }
}
