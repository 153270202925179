<div *ngIf="node.isLeaf" class="selector-node leaf-node padding-left">
  <div class="selector-item">
    <div *ngIf="node.icon" class="icon-position">
      <wlm-icon [icon]="node.icon" [color]="iconColor"></wlm-icon>
    </div>
    <div class="selector-label-container">
      <div class="selector-label line-item title1" [matTooltip]="node.label">{{ node.label }}</div>
      <div class="selector-label line-item subtitle">{{ node.label2 | uppercase }}</div>
      <div class="selector-label secondary">{{ node.label3 }}</div>
    </div>
    <div class="selector-button">
      <div>
        <button
          mat-icon-button
          color="primary"
          (click)="onAlgorithmSelected(node.data)"
          [matTooltip]="'common.add' | translate | lowercase"
          [disableRipple]="true"
          onclick="this.blur()"
        >
          <mat-icon class="material-icon">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!node.isLeaf"
  class="selector-node normal-node"
  [ngClass]="{
    'node-expanded': isExpanded
  }"
  (click)="onGroupToggle()"
>
  <div class="normal-node-label secondary">
    {{ node.label }}
  </div>
  <div>
    <wlm-icon *ngIf="!isExpanded" icon="arrow_drop_down"></wlm-icon>
    <wlm-icon *ngIf="isExpanded" icon="arrow_drop_up"></wlm-icon>
  </div>
</div>
