<wlm-spinner-wrapper
  class="inherit-size"
  [showSpinner]="isLoading"
  [settings]="spinnerWrapperSettings"
>
  <div *ngIf="chartConfiguration" class="charts-container">
    <div class="topbar" fixedSizeElement>
      <div class="topbar-item">
        <ng-content select="[topbar-first]"></ng-content>
      </div>
      <div class="topbar-item topbar-buttons" topbar-last>
        <button
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          (click)="exportChart()"
          onclick="this.blur()"
          [matTooltip]="T_SCOPE_CHART_BUTTONS + '.export-image' | translate | lowercase"
        >
          <mat-icon svgIcon="export-chart-image"></mat-icon>
        </button>

        <button
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          [disabled]="customizableChart && !customizableChart?.dataLoaded"
          (click)="showDataPoints()"
          onclick="this.blur()"
          [matTooltip]="T_SCOPE_CHART_BUTTONS + '.show-hide-datapoints' | translate | lowercase"
        >
          <mat-icon svgIcon="data_points"></mat-icon>
        </button>

        <button
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          (click)="onChartConfiguration()"
          onclick="this.blur()"
          [matTooltip]="T_SCOPE_CHART_BUTTONS + '.chart-configuration' | translate | lowercase"
        >
          <mat-icon svgIcon="chart-configuration"></mat-icon>
        </button>
      </div>
    </div>
    <wlm-customizable-chart
      *ngIf="chartSettings"
      [chartSettings]="chartSettings"
      [size]="calculatedSize$ | async"
      (loadingEvent)="onChartLoading($event)"
      (chartDataZoomEvent)="onChartDataZoom($event)"
      (genericChartSettingsChange)="onMainChartSettingsChange($event)"
    ></wlm-customizable-chart>

    <hr *ngIf="eventChartQuery && chartSettings && !isLoading" class="separator" />

    <wlm-events-chart
      *ngIf="eventChartQuery && xAxisRange"
      [xAxisRange]="xAxisRange"
      [eventQuery]="eventChartQuery"
      (chartClick)="onChartClick($event)"
      (sizeChanged)="onEventChartSizeChange($event)"
    ></wlm-events-chart>
  </div>
</wlm-spinner-wrapper>
