import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SmartMeterProcessedChanges } from './smart-meters-change.dto';

const COMPONENT_SELECTOR = 'wlm-smart-meters-changes';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meters-changes.component.html',
  styleUrls: ['./smart-meters-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartMetersChangesComponent {
  @Input() processedChanges: SmartMeterProcessedChanges[] = [];
}
