<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div class="logs-interfaces-popup-container">
    <div class="popup-title-container">
      <h3>{{ T_SCOPE + '.title' | translate }}</h3>

      <button
        mat-icon-button
        [disabled]="!(interfacesLogging.compressedName || interfacesLogging.fileName)"
        [matTooltip]="COMMON_SCOPE + '.download' | translate | lowercase"
        (click)="onClickDownload()"
      >
        <!-- TODO: Change for correct icon when available -->
        <mat-icon class="material-icons-outlined">download</mat-icon>
      </button>
    </div>

    <div class="log-header-container">
      <wlm-label-value-list
        [properties]="headerFields"
        [values]="interfacesLogging"
      ></wlm-label-value-list>
    </div>

    <hr class="title-separator" />

    <div class="log-main-container">
      <div class="log-message">
        {{ interfacesLogging.exception }}
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <div class="buttons-container">
      <button mat-raised-button class="mat-raised-button" (click)="close()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</wlm-spinner-wrapper>
