import { Injector, Type } from '@angular/core';
import { DynamicLayoutItemSettings } from './models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from './models/dynamic-layout-settings';
export * from './dynamic-layout.tokens';

export class DynamicLayoutExtendHeadersSettings {
  component: Type<any>;
  shouldRender?: (
    itemSettings: DynamicLayoutItemSettings,
    settings?: DynamicLayoutSettings
  ) => boolean;
  flagName: string;
  position?: 'before' | 'after' = 'after';
  featureInjectorFn?: () => Injector;
}

export class DynamicLayoutExternalSettings {
  extendHeaders?: DynamicLayoutExtendHeadersSettings[];
  persistency?: 'local' | 'backend';
}
