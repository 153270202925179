import { DynamicFormSettings } from '../../dynamic-forms/models/dynamic-form-settings';
import { FieldsByCategory } from '../../dynamic-forms/models/fields-by-category';

export class DynamicFormGroupCategories {
  formSettings: DynamicFormSettings;
  fieldsByCategories: FieldsByCategory[];

  constructor(init: DynamicFormGroupCategories) {
    Object.assign(this, init);
  }
}
