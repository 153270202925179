import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class ArrayValuesFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.ArraySingleValue;
  fieldName: string;
  tabParameter: TabDetailParameterName;
  multiple: boolean;

  constructor(init: {
    dataBindingField: string;
    fieldName: string;
    tabParameter: TabDetailParameterName;
    multiple: boolean;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const filter = filters.data.get(this.fieldName);

    if (!filter) {
      return null;
    }

    const basicFilter = new BasicFilter(
      filter.fieldName,
      filter.value,
      filter.additionalFields,
      filter.operator,
      filter.ignoreCase
    );

    if (
      !basicFilter?.value ||
      !Array.isArray(basicFilter.value) ||
      basicFilter.value.length === 0
    ) {
      return null;
    }

    if (this.multiple && basicFilter?.value.length > 1) {
      basicFilter.value = filter.value.map((item) => item.value);
    } else if (
      filter.value.length === 1 &&
      filter.value[0].value !== null &&
      typeof filter.value[0].value !== 'undefined'
    ) {
      basicFilter.value = filter.value[0].value;
    }
    return basicFilter;
  }

  buildResults(arrayFilter: BasicFilter): FilterAdapterResult {
    if (arrayFilter) {
      this.filtersResult.filters.set(this.dataBindingField, arrayFilter);
      if (this.tabParameter) {
        this.panelParams.addParameter(this.tabParameter, arrayFilter);
      }
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
