import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationHelperService } from '../../../localization/localization-helper.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToTranslatedStringMapperParams } from '../mapper-params/to-translated-string-mapper-params';

@Injectable()
export class ToTranslatedStringMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToTranslatedString;

  constructor(private _localization: LocalizationHelperService) {
    super();
  }

  map = (value: string, model: any, params: ToTranslatedStringMapperParams): Observable<string> => {
    const labelKey = `${params.labelKeyPrefix}.${value}`;
    return this._localization.get(labelKey);
  };
}
