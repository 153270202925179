import { Injectable } from '@angular/core';
import { SettingsService } from '../config/settings.service';
import { LogData } from './log-data';
import { LogScope } from './log-scope';

@Injectable({ providedIn: 'root' })
export class LogService {
  constructor(private settingsService: SettingsService) {}

  info(data: LogData, disabled?: boolean): void {
    this._log(data, console.log, disabled);
  }

  error(data: LogData, disabled?: boolean): void {
    this._log(data, console.error, disabled);
  }

  warn(data: LogData, disabled?: boolean): void {
    this._log(data, console.warn, disabled);
  }

  private _log(data: LogData, logFn, disabled?: boolean): void {
    if (!disabled && this._logEnabled()) {
      logFn(this._logFormatter(data.msg, data.scope), data.payload);
    }
  }

  private _logEnabled() {
    return this.settingsService.log;
  }

  private _logFormatter(message: string, scope: LogScope) {
    const now = new Date().toLocaleString();
    const result = [now, scope, message].filter(Boolean).join(' : ');
    return result;
  }
}
