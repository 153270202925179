export class DynamicFormSaveMetadata {
  previous: any;
  label: string;
  name: string;
  category: string;
  identifier: string;

  constructor(init: DynamicFormSaveMetadata) {
    Object.assign(this, init);
  }
}
