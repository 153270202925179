import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutBuilderService } from 'src/app/common-modules/dynamic-layout/layouts/dynamic-layout-builder-service';
import { SimpleDynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings-item';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutStructure } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-structure';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { ResetFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import { ResetStateAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ServerModulesService } from '../../shared/services/server-modules.service';
import { HierarchyStateFields } from '../models/hierarchy-state-fields';
import { HIERARCHY_CHILDREN_CONFIG_COMPONENT_INSTANCE } from '../widgets/hierarchy-children-configurator-widget/hierarchy-children-configurator-widget.component';
import { HIERARCHY_FILTER_COMPONENT_INSTANCE } from '../widgets/hierarchy-filter-widget/hierarchy-filter-widget.component';
import { HIERARCHY_SELECTOR_COMPONENT_INSTANCE } from '../widgets/hierarchy-selector-widget/hierarchy-selector-widget.component';

const COMPONENT_SELECTOR = 'wlm-hierarchy-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-page.component.html',
  styleUrls: ['./hierarchy-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class HierarchyPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  readonly pageId: string = 'HierarchyPage';

  private readonly _simpleSettings: SimpleDynamicLayoutSettings = {
    layoutKey: this.pageId,
    layoutArea: 'Main',
    scopeInstanceKeys: {
      [StateAreas.Generic]: 'shared',
    },
  };

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._simpleSettings.layoutArea,
    page: this._simpleSettings.layoutKey,
    scopeInstanceKeys: this._simpleSettings.scopeInstanceKeys,
  });

  private readonly _filterWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'HierarchyFilterWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-hierarchy-filter`,
    id: HIERARCHY_FILTER_COMPONENT_INSTANCE,
  };

  private readonly _selectorWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'HierarchySelectorWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-hierarchy-selector`,
    id: HIERARCHY_SELECTOR_COMPONENT_INSTANCE,
  };

  private readonly _configurationWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'HierarchyChildrenConfiguratorWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-hierarchy-children-configuration`,
    id: HIERARCHY_CHILDREN_CONFIG_COMPONENT_INSTANCE,
  };

  private readonly _defaultWidgets = [
    this._filterWidget,
    this._selectorWidget,
    this._configurationWidget,
  ];

  private readonly _defaultStructure: DynamicLayoutStructure[] = [
    {
      type: LayoutNodeTypes.Column,
      content: [
        {
          type: LayoutNodeTypes.Stack,
          height: 10,
          content: [
            {
              type: LayoutNodeTypes.Component,
              widgetInstanceKey: HIERARCHY_FILTER_COMPONENT_INSTANCE,
            },
          ],
        },
        {
          type: LayoutNodeTypes.Row,
          content: [
            {
              type: LayoutNodeTypes.Stack,
              height: 90,
              width: 25,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: HIERARCHY_SELECTOR_COMPONENT_INSTANCE,
                },
              ],
            },
            {
              type: LayoutNodeTypes.Stack,
              height: 85,
              width: 75,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: HIERARCHY_CHILDREN_CONFIG_COMPONENT_INSTANCE,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  dynamicLayoutSettings: DynamicLayoutSettings;
  isLoading = false;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMHierarchyCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  constructor(
    private readonly _dynamicLayoutBuilderService: DynamicLayoutBuilderService,
    private readonly _dynamicLayoutService: DynamicLayoutService,
    private readonly _serverModulesService: ServerModulesService,
    private readonly _pendingChangesService: PendingChangesManagerService,
    private readonly _state: ReduxStateService
  ) {
    super();

    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initialLoad();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  onResetFilters(): void {
    this.checkPendingChanges(this.pageId).subscribe(() =>
      this._state.dispatch(new ResetFiltersAction(null))
    );
  }

  launchHierarchyModule(): void {
    this._serverModulesService.launchHierarchySummaryMod().subscribe({
      next: (response) => {
        if (response) {
          this.showSnackBarMessage('message.hierarchy-mod-launched', 'success');
        }
      },
      error: (error) => {
        this.showSnackBarMessage('message.hierarchy-mod-error', 'error');
      },
    });
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._state.dispatch(new ResetStateAction({ fieldNames: Object.keys(HierarchyStateFields) }));

      this._dynamicLayoutService.resetAll();
    });
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  private initialLoad() {
    this.buildDynamicLayoutSettings().subscribe((settings) => {
      this.dynamicLayoutSettings = settings;
    });
  }

  private buildDynamicLayoutSettings(): Observable<DynamicLayoutSettings> {
    const simpleSettings = globalUtilsHelper.clone(this._simpleSettings, true);
    simpleSettings.items = this._defaultWidgets;

    return this._dynamicLayoutBuilderService.build(simpleSettings).pipe(
      map((dynamicLayoutSettings) => {
        dynamicLayoutSettings.showResetIcon = true;
        dynamicLayoutSettings.defaultStructure = this._defaultStructure;
        return dynamicLayoutSettings;
      })
    );
  }

  private showSnackBarMessage(messageKey: string, type: any) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: type,
    });
    this.dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  ngOnDestroy(): void {
    this._state.dispatch(new ResetStateAction({ fieldNames: Object.keys(HierarchyStateFields) }));

    super.ngOnDestroy();
  }
}
