import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ICanLeavePage } from './can-component-deactivate';

@Injectable({
  providedIn: 'root',
})
export class LeavePageGuard  {
  canDeactivate(
    component: ICanLeavePage,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return component.canLeavePage ? component.canLeavePage() : true;
  }
}
