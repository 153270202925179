// prettier-ignore
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, Subscription, combineLatest, race } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FilterConditionalOverride } from 'src/app/common-modules/common-filters/models/filter-conditional-override';
import { FilterContainerIndexedPayload } from 'src/app/common-modules/common-filters/models/filter-container-indexed-palyoad';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseFilterSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { SubscriptionManager } from 'src/app/common-modules/shared/observables/subscription-manager';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseFilterComponent } from '../../../core/base-filter/base-filter.component';

const COMPONENT_SELECTOR = 'wlm-base-filter-container';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './base-filter-container.component.html',
  styleUrls: ['./base-filter-container.component.scss'],
  host: { class: 'base-filter-container' },
})
export class BaseFilterContainerComponent {
  /**
   * Function that returns the current adapters.
   * Not receiving adapters directly because they may be binded to properties that change over time.
   * Ie: if an adapter receives a heFamilyId which depends on a filter, it is easier to get adapters again instead of
   * relying on updating heFamilyId by ref.
   */
  @Input() provideAdapters: () => FilterAdapterSettings[];
  // Specifies the required keys to emit the completed event.
  @Input() keysToComplete: string[] = [];
  // Allows to conditionally override filter payloads.
  @Input() filtersOverrides: FilterConditionalOverride[] = [];
  // Main config of the component.
  @Input() settings: BaseFilterSettings;
  // Notifies the component when to clear all filters.
  @Input() clearAll$ = new Subject<void>();
  // Notifies the component when to trigger the "apply" event from an external source.
  @Input() apply$ = new Subject<void>();
  // For filters combinations that are hierarchical, it does not make sense to coordinate apply events.
  @Input() disableApplyCoordination = false;
  @Input() disableClearAllCoordination = false;
  @Input() disableListenValidCoordination = false;

  @Output() applyResults = new EventEmitter<FilterAdapterResult>();
  @Output() filterResults = new EventEmitter<FilterAdapterResult>();
  @Output() clearAll = new EventEmitter<void>();
  // A filter is completed when all specified filters have emitted (Emitted filter event, not apply event).
  @Output() filterComplete = new EventEmitter<void>();
  @Output() valid = new EventEmitter<boolean>();
  // TODO: Pending
  @Output() ready = new EventEmitter<any>();

  @ContentChildren(BaseFilterComponent, { descendants: true }) public set onFilterComponents(
    query: QueryList<BaseFilterComponent[]>
  ) {
    this.filterComponents = Array.from(query).reduce((accum, current) => accum.concat(current), []);
    if (this.filterComponents && this.filterComponents.length) {
      this.init();
    }
  }

  private filterComponents: BaseFilterComponent[];
  private previousFiltersPayload = new FiltersPayload({});
  private subsManager = new SubscriptionManager();
  private lastChangedFields = new Map<string, boolean>();
  private lastAdaptedResults: FilterAdapterResult;

  constructor(private _objectHelper: ObjectHelperService) {}

  private init(): void {
    // Unsubscribe from all at the same time.
    this.subsManager.unsubscribe();
    this.bindTriggers();
    this.listenEvents();
  }

  private bindTriggers(): void {
    this.subsManager.subs = this.bindApplyTrigger();
    this.subsManager.subs = this.bindClearAllTrigger();
  }

  private bindApplyTrigger(): Subscription[] {
    const subscriptions = [
      this.subscribeTo(this.apply$, () => {
        const inputs$ = this.applyInputs$;
        if (inputs$.length) {
          // Emit apply in the first component. The flow will trigger the rest of components.
          inputs$[0].next();
        }
      }),
    ];
    return subscriptions;
  }

  /**
   * The global clearAll trigger propagates to all the children.
   */
  private bindClearAllTrigger(): Subscription[] {
    const subscriptions = [
      this.subscribeTo(this.clearAll$, () => {
        this.clearAllInputs$.forEach((input$) => input$.next());
      }),
    ];
    return subscriptions;
  }

  private listenEvents(): void {
    this.subsManager.subs = this.listenApply();
    this.subsManager.subs = this.listenClearAll();
    this.subsManager.subs = this.listenFilter();
    this.subsManager.subs = this.listenValid();
  }

  private listenApply(): Subscription[] {
    return this.listenUntilAllComplete({
      outputs$: this.applyOutputs$,
      emitGlobal: (data) => this.emitGlobalApply(data),
      mustCoordinate: !this.disableApplyCoordination,
      mustRestart: true,
      inputs$: this.applyInputs$,
      eventName: 'apply',
    });
  }

  /**
   * Listen and append all emitted filters. Direclty emit them each time.
   * If called again, discard previous subscribe and start listening again.
   */
  private listenFilter(): Subscription[] {
    const subscriptions = [];
    const subscription = combineLatest(this.filterOutputs$)
      .pipe(untilDestroyed(this))
      .subscribe((filters) => {
        const joined = FiltersPayload.joinMany(filters);
        this.emitFilter(joined);
      });
    subscriptions.push(subscription);
    return subscriptions;
  }

  /**
   * For debug-only purposes.
   */
  private simplifyFiltersPayload(filters: FiltersPayload): any {
    const values = Array.from(filters.data.entries()).map((entry) => {
      const value = entry[1].value;
      if (value && value.getTime) return value.toISOString();
      return value;
    });

    return values;
  }

  private emitFilter(payload: FiltersPayload): void {
    if (this.previousFiltersPayload) {
      const hasNotChanged = this._objectHelper.deepEqual(payload, this.previousFiltersPayload);
      if (hasNotChanged) {
        return;
      }
    }

    // This is saved as an attribute because the apply event needs this info but cannot calculate it itself.
    this.lastChangedFields = this.calculateFieldChanges(payload, this.previousFiltersPayload);
    const processedPayload = this.overrideFiltersByConfig(payload, 'filter');
    // Apply adapters and obtain DbFilters and TabParams.
    this.applyAdapters(processedPayload, this.filterResults);
    this.previousFiltersPayload = processedPayload.clone();
  }

  private listenClearAll(): Subscription[] {
    const subscriptions = this.listenUntilAllComplete({
      outputs$: this.clearAllOutputs$,
      emitGlobal: (_) => this.emitGlobalClearAll(),
      mustCoordinate: !this.disableClearAllCoordination,
      mustRestart: true,
      eventName: 'clearAll',
    });
    // Each time a single clear all is toggled, the corresponding base filter emits apply$.
    this.clearAllOutputs$.forEach((clearAll$, index) => {
      const subscription = clearAll$.pipe(untilDestroyed(this)).subscribe(() => {
        const apply$ = this.applyInputs$[index];
        if (apply$) {
          apply$.next();
        }
        subscriptions.push(subscription);
      });
    });
    return subscriptions;
  }

  private debugResults(results: FilterAdapterResult): void {
    // console.log('Changed fields: ', results.changedFields.entries());
  }

  private calculateFieldChanges(
    current: FiltersPayload,
    previous: FiltersPayload
  ): Map<string, boolean> {
    const changed = new Map<string, boolean>();
    // tslint:disable-next-line: forin
    Array.from(current.data.keys()).forEach((key) => {
      const hasPrevious = previous.lastChange.has(key);
      let hasChanged;
      if (!hasPrevious) {
        hasChanged = true;
      } else {
        const currentTime = current.lastChange.get(key);
        const previousTime = previous.lastChange.get(key);
        hasChanged = currentTime !== previousTime;
      }
      changed.set(key, hasChanged);
    });
    return changed;
  }

  private listenValid(): Subscription[] {
    return this.listenUntilAllComplete({
      outputs$: this.validOutputs$,
      emitGlobal: (data) => this.emitGlobalValid(data),
      mustCoordinate: !this.disableListenValidCoordination,
      mustRestart: false,
      eventName: 'valid',
    });
  }

  /**
   * Emit the global apply, when all sub-events have been emitted.
   */
  private emitGlobalApply(payloads: FiltersPayload[]): void {
    let payload = FiltersPayload.joinMany(payloads);
    payload = this.overrideFiltersByConfig(payload, 'apply');
    // Additionally, apply adapters and obtain DbFilters and TabParams.
    this.applyAdapters(payload, this.applyResults);
  }

  private overrideFiltersByConfig(
    payload: FiltersPayload,
    eventType: 'apply' | 'filter'
  ): FiltersPayload {
    let newPayload = payload.clone();
    this.filtersOverrides?.forEach((override) => {
      if (this.allowSideEffects(override)) {
        if (this.allowOverride(override, newPayload)) {
          newPayload = override.overridePayloadFn(
            newPayload.clone(),
            eventType,
            this.lastChangedFields
          );
        } else {
          override.overridePayloadFn(newPayload.clone(), eventType, this.lastChangedFields);
        }
      }
    });
    return newPayload;
  }

  private allowSideEffects(override: FilterConditionalOverride): boolean {
    const allow =
      this.lastChangedFields.get(override.onChangedFieldName) &&
      !this.lastChangedFields.get(override.targetFieldName);
    return allow;
  }

  private allowOverride(override: FilterConditionalOverride, payload: FiltersPayload): boolean {
    const targetHasInitialValue =
      payload.status.get(override.targetFieldName) === FiltersPayloadStatus.Initial;
    const isFromPersistency = override.targetIsFromPersistencyFn(payload);
    const allow = !targetHasInitialValue || !isFromPersistency;
    return allow;
  }

  private emitGlobalClearAll(): void {
    this.clearAll.next();
  }

  private emitGlobalValid(eachValid: boolean[]): void {
    const allValid = eachValid.filter(Boolean).length === eachValid.length;
    this.valid.next(allValid);
  }

  /**
   * Check if all the conditions for the completed event to emit have been met.
   */
  private filtersAreCompleted(filters: FiltersPayload): boolean {
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.keysToComplete.length; index++) {
      const key = this.keysToComplete[index];
      if (!filters.data.has(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * When a BF emits the apply event, manually trigger the same event in the rest of the BFs.
   */
  private coordinateEvents(
    outputs$: Observable<any>[],
    inputs$: Subject<void>[],
    eventName: string
  ): Subscription {
    const outputsWithIndexes$ = this.mapWithIndex$(outputs$);
    const subscription = race(outputsWithIndexes$)
      .pipe(take(1))
      .subscribe((winner: FilterContainerIndexedPayload) => {
        inputs$?.forEach((input$, index) => {
          if (index !== winner.index) {
            input$.next();
          }
        });
      });
    return subscription;
  }

  private listenNoCoordinate(
    outputs$: Observable<any>[],
    emitGlobal: (data: any) => void
  ): Subscription[] {
    const subscription = combineLatest(outputs$)
      .pipe(untilDestroyed(this), take(1))
      .subscribe((outputs) => {
        emitGlobal(outputs);
      });
    return [subscription];
  }

  /**
   * Listen to all the same events of all components. When all are emitted, emit global event.
   * When a global event has been emitted, start listening again.
   * In case of apply, also allow to coordinate.
   */
  private listenUntilAllComplete({
    outputs$,
    emitGlobal,
    inputs$,
    mustCoordinate = false,
    mustRestart = false,
    eventName,
  }: {
    outputs$: Observable<any>[];
    emitGlobal: (data: any) => void;
    inputs$?: Subject<any>[];
    mustCoordinate?: boolean;
    mustRestart?: boolean;
    eventName?: string;
  }): Subscription[] {
    const subscriptions = [];
    if (mustCoordinate) {
      subscriptions.push(this.coordinateEvents(outputs$, inputs$, eventName));
    }

    const subscription = combineLatest(outputs$)
      .pipe(
        untilDestroyed(this),
        filter((outputs: FiltersPayload[]) => {
          if (mustCoordinate) {
            const result = outputs.length === this.filterComponents.length;
            return result;
          }
          return true;
        }),
        take(1)
      )
      .subscribe((outputs) => {
        // When all have emitted, emit global event.
        emitGlobal(outputs);

        if (mustRestart) {
          // Then restart listening to all observables (and start race again).
          this.listenUntilAllComplete({
            outputs$,
            inputs$,
            emitGlobal,
            mustCoordinate,
            mustRestart,
            eventName,
          });
        }
      });

    subscriptions.push(subscription);
    return subscriptions;
  }

  private get applyInputs$(): Subject<void>[] {
    return this.filterComponents.map((item) => item.apply$);
  }

  private get applyOutputs$(): Observable<FiltersPayload>[] {
    return this.filterComponents.map((item) => item.apply.asObservable());
  }

  private get clearAllInputs$(): Subject<void>[] {
    return this.filterComponents.map((item) => item.clearAll$);
  }

  private get clearAllOutputs$(): Observable<void>[] {
    return this.filterComponents.map((item) => item.clearAll.asObservable());
  }

  private get filterOutputs$(): Observable<FiltersPayload>[] {
    return this.filterComponents.map((item) => item.filteredCoordinateOutput$.asObservable());
  }

  private get validOutputs$(): Observable<boolean>[] {
    return this.filterComponents.map((item) => item.valid.asObservable());
  }

  /**
   * Make the events emit also their position in the array.
   */
  private mapWithIndex$(
    observables$: Observable<any>[]
  ): Observable<FilterContainerIndexedPayload>[] {
    return observables$.map((apply, index) =>
      apply.pipe(
        map(
          (payload) =>
            new FilterContainerIndexedPayload({
              payload,
              index,
            })
        )
      )
    );
  }

  private subscribeTo(subject$: Subject<any>, callback: () => void): Subscription {
    if (subject$) {
      return subject$.pipe(untilDestroyed(this)).subscribe(callback);
    }
    return new Subscription();
  }

  /**
   * RxJS combineLatest does not fire until at least each observable has emitted once.
   * This is not valid for observables with no initial values or EventEmitters, so
   * this method simulates that behavior.
   * @param defaultResultsValue Suitable initial value for the join object/array.
   * @param pipeOperatorFn Optionally apply RxJS operators on each observable.
   */
  private combineLatestWithoutInitial<T>(
    observables$: Observable<T>[],
    eventName: string = null,
    pipeOperatorFn: (obs$: Observable<T>) => Observable<T> = null
  ): Observable<T[]> {
    return new Observable<T[]>((observer) => {
      const results = [];
      // Listen to each observable individually.
      observables$.forEach((obs$, index) => {
        // Apply optional operators.
        const current$ = pipeOperatorFn ? pipeOperatorFn(obs$) : obs$;
        current$.pipe(untilDestroyed(this)).subscribe((result) => {
          results[index] = result;
          const resultsNoUndef = results.filter((res) => typeof res !== 'undefined');
          observer.next(resultsNoUndef);
        });
      });
    });
  }

  private applyAdapters(filters: FiltersPayload, emitter$: EventEmitter<any>): void {
    if (!this.provideAdapters) {
      return;
    }

    // We build the adapters just when we actually need them, so their references are updated.
    const adapters = this.provideAdapters();
    const filterItems = this.getAllFilterItems();
    const allowedIds: FilterAdapterEnum[] = filterItems.reduce((list, component) => {
      if ((component as any).getAdapter) {
        const adapter = (component as any).getAdapter();
        return list.concat([adapter]);
      }
      return list;
    }, []);

    // We apply only the adapters that match one of the current filter items.
    const adaptersToApply = [];
    allowedIds.forEach((allowedId: FilterAdapterEnum) => {
      const configured = adapters.find((adapter) => adapter.id === allowedId);
      if (configured) {
        adaptersToApply.push(configured);
      }
    });
    const adapterResults = adaptersToApply.map((adapter) => adapter.process(filters));
    const joinedResults = this.buildAdapterResults(adapterResults, filters, this.lastChangedFields);

    this.debugResults(joinedResults);

    // if (this.lastAdaptedResults) {
    //   const areSame = joinedResults.equals(this.lastAdaptedResults);
    //   if (!areSame) {
    emitter$.emit(joinedResults);
    //   }
    // }
    // this.lastAdaptedResults = joinedResults.clone();
  }

  private buildAdapterResults(
    results: FilterAdapterResult[],
    payload: FiltersPayload,
    changedFields: Map<string, boolean>
  ): FilterAdapterResult {
    const dbFilters = new DataBindingFilters();
    const tabParams = new TabDetailPanelParameters();

    const areCompleted = this.filtersAreCompleted(payload);

    dbFilters.filters = this._objectHelper.joinMaps<string, IFilter>(
      results.map((result) => result.dbFilters.filters)
    );
    results.forEach((result) => {
      Array.from(result.tabParams.parameters.entries()).forEach(([key, value]) => {
        tabParams.addParameter(key as TabDetailParameterName, value);
      });
    });

    const faResult = new FilterAdapterResult({
      dbFilters,
      tabParams,
    });

    if (areCompleted !== undefined && areCompleted !== null) {
      faResult.completed = areCompleted;
    }
    if (payload) {
      faResult.payload = payload;
    }
    if (changedFields) {
      faResult.changedFields = changedFields;
    }

    return faResult;
  }

  private getAllFilterItems(): BaseFilterItemComponent[] {
    const filterItems = this.filterComponents.reduce((list, baseFilter) => {
      return list.concat(baseFilter.getActiveItems());
    }, []);
    return filterItems;
  }
}
