import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { ICustomerGroupDto } from '../../shared/model/customer/customer-group.dto';

@Injectable({ providedIn: 'root' })
export class CustomerGroupService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }
  getCustomerGroup(zoneId: string): Observable<ICustomerGroupDto[]> {
    return this.getCached<ICustomerGroupDto[]>(`customer/summary/${zoneId}`);
  }
}
