<div
  [ngClass]="settings?.errorField && item[settings.errorField] ? 'error-style' : ''"
  class="flex-style flex-style-max"
>
  <div *ngIf="!settings.isReadOnly" class="flex-style icon-position">
    <mat-icon *ngIf="settings.isSvg" [svgIcon]="settings.iconName"></mat-icon>
    <mat-icon *ngIf="!settings.isSvg">{{ settings.iconName }}</mat-icon>
  </div>
  <div class="flex-style flex-style-max" *ngFor="let field of settings.fields">
    <div class="card-row">
      <div class="field-box">
        <div class="title-box">
          {{ T_SCOPE + '.' + settings.fieldLabels[field] | translate }}
        </div>
        <div>{{ item[field] }}</div>
      </div>
    </div>
  </div>
</div>
