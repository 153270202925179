export class ElementTypeCheckDto {
  // networkElementType: INetworkElementTypeDto;
  elementName: string;
  elementId: string | number;
  pathIcon?: string;
  selected: boolean;

  constructor(
    elementName: string,
    elementId: string | number,
    selected: boolean,
    pathIcon?: string
  ) {
    this.elementName = elementName;
    this.elementId = elementId;
    this.selected = selected;
    this.pathIcon = pathIcon;
  }
}
