export class HistoricalFormSettings {
  minDate?: Date;
  maxDate?: Date;
  showPointFields?: boolean;
  editOnlyDates?: boolean;
  emitAtFormInitialization?: boolean;
  isReadOnly?: boolean;

  constructor(init: HistoricalFormSettings) {
    Object.assign(this, init);
  }
}
