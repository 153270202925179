<div *hasPerm="['WLMNotificationsCrud', 'c']">
  <button
    mat-raised-button
    color="primary"
    [ngClass]="isSubscribed ? 'disabled-style' : ''"
    class="fixed-width"
    (click)="setSubscription($event)"
  >
    {{ isSubscribed ? (unsubscribeText | translate) : (subscribeText | translate) }}
  </button>
</div>
