export enum SettingsComponentType {
  Grid = 1,
  Layout = 2,
  Fields = 3,
  Selectors = 4,
  Notification = 5,
  Map = 6,
  HomePage = 7,
  Schematics = 8,
  MapSettings = 9,
  Interfaces = 10,
}
