import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { AlarmsModule } from '../alarms/alarms.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { NeConfigAttributeFormWidgetComponent } from '../ne-configuration/widgets/ne-config-attribute-form-widget/ne-config-attribute-form-widget.component';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { TransmissionNetworkPageComponent } from './transmission-network-page/transmission-network-page.component';
import { TransmissionNetworkService } from './transmission-network-page/transmission-network.service';
import { TransmissionNetworkRoutingModule } from './transmission-network-routing.module';

@NgModule({
  declarations: [TransmissionNetworkPageComponent],
  imports: [
    CommonModule,
    TransmissionNetworkRoutingModule,
    WlmSharedModule,
    WLMGridModule,
    WlmChartsModule,
    TranslateModule,
    WlmFiltersModule,
    WlmSharedComponentModule,
    AlarmsModule,
  ],
  providers: [TransmissionNetworkService],
})
export class TransmissionNetworkModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    TransmissionNetworkModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'NeConfigAttributeFormWidgetComponent',
        widgetClass: NeConfigAttributeFormWidgetComponent,
      },
    ]);
  }
}
