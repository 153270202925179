import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class IsZoneFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.isZoneSelected;
  neheSelectionFieldName: string;

  constructor(init: { dataBindingField: string; neheSelectionFieldName: string }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const neheSelectionFilter = filters.data.get(this.neheSelectionFieldName);
    if (neheSelectionFilter) {
      if (neheSelectionFilter.value) {
        const ishe = neheSelectionFilter.value?.some((x) => x.value === 1);

        const result = new BasicFilter(this.neheSelectionFieldName, ishe);
        return result;
      }
    }
    return null;
  }

  buildResults(neheSelection: BasicFilter): FilterAdapterResult {
    if (neheSelection) {
      this.filtersResult.filters.set(this.dataBindingField, neheSelection);
      this.panelParams.addParameter(TabDetailParameterName.isZone, neheSelection);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
