import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuntimeCacheService } from '../../auth/services/runtime-cache.service';
import { UserDto } from '../../model/roles/user.dto';
import { UsersGridODataService } from './users.service';

export type UsersHash = { [key: string]: UserDto };

@Injectable({ providedIn: 'root' })
export class UsersCachedService extends RuntimeCacheService<UsersHash> {
  constructor(injector: Injector, private _usersService: UsersGridODataService) {
    super(injector);
  }

  protected requestData(): Observable<UsersHash> {
    return this._usersService.getUsers().pipe(map(this.hashUsers));
  }

  private hashUsers(users: UserDto[]): UsersHash {
    const hash: UsersHash = {};
    users.forEach((user) => {
      hash[user.userCode] = user;
    });
    return hash;
  }
}
