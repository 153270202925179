<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div
    class="filters filters-background"
    [ngClass]="{
      error: !neTypesFilterIsValid
    }"
  >
    <div class="ne-type-filter">
      <wlm-base-filter
        (filterText)="onFilterText($event)"
        [settings]="bf1Settings"
        [persistItems$]="persistFilters$"
      >
        <wlm-network-elements-filter-item
          [filterText]="filterText"
          [fieldName]="filterConfiguration.networkElementTypeIdFieldName"
          [selectedIds]="filterConfiguration.initialNetworkElementTypeId"
          [defaultSelectedElements]="filterConfiguration.defaultNetworkElementTypeId"
          [settings]="neFilterItemSettings"
          [filterListElements]="filterListElements"
          [mode]="mode"
        ></wlm-network-elements-filter-item>
      </wlm-base-filter>
    </div>

    <div class="he-filter">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bf2Settings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-hierarchy-family-filter-item
          [fieldName]="filterConfiguration.hierarchyElementFamilyFieldName"
          [selectedIds]="[filterConfiguration.initialFamilyId]"
          [defaultSelectedElements]="[filterConfiguration.defaultFamilyId]"
          [filterText]="filterText"
          [settings]="hierarchyFamilyItemSettings"
          (select)="onFamilyIdSelect($event)"
        >
        </wlm-hierarchy-family-filter-item>
        <wlm-hierarchy-tree-filter-item
          [hierarchyElementIdFieldName]="filterConfiguration.hierarchyElementIdFieldName"
          [hierarchyFamilyIdFieldName]="filterConfiguration.hierarchyElementFamilyFieldName"
          [hierarchyFamilyId]="hierarchyElementFamilyId"
          [initialSelectedKeys]="filterConfiguration.initialNetworkElementId"
          [filterText]="filterText"
          [(treeSettings)]="treeSettings"
          [settings]="hierarchyTreeItemSettings"
          (filterReady)="onTreeFilterReady()"
        ></wlm-hierarchy-tree-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
