import { DynamicLayoutItemSettings } from '../../dynamic-layout/models/dynamic-layout-item-settings';

export class StateWidgetSettings {
  module? = 'root';
  page?: string;
  widgetInstanceKey?: string;
  scopeInstanceKeys?: { [area: string]: string };
  targetInstances?: { [key: string]: string } = {};
  // Additional data for component configuration.
  params?: any;

  hasDefaultFilters? = false;
  itemSettings?: DynamicLayoutItemSettings;

  // If this property is set, display it instead of the current title.
  title?: string;
  // If this property is set, then the title should be built with some information received by the user.
  baseTitle?: string;

  constructor(init: StateWidgetSettings) {
    Object.assign(this, init);
  }
}
