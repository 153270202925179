<div class="cart-container">
  <div class="cart-groups">
    <div class="cart-group">
      <mat-expansion-panel
        id="module-expander"
        class="nav-link-expansion-panel"
        hideToggle="true"
        [expanded]="true"
        (opened)="algorithmsPanelOpenState = true"
        (closed)="algorithmsPanelOpenState = false"
      >
        <mat-expansion-panel-header
          class="nav-module-link-container"
          collapsedHeight="48px"
          expandedHeight="48px"
        >
          <mat-panel-title> {{ T_SCOPE + '.algorithms' | translate }} </mat-panel-title>
          <mat-panel-description>
            <wlm-icon *ngIf="!algorithmsPanelOpenState" icon="arrow_drop_down"></wlm-icon>
            <wlm-icon *ngIf="algorithmsPanelOpenState" icon="arrow_drop_up"></wlm-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <wlm-data-visualization-cart-group *ngIf="algorithms?.length" [items]="algorithms">
          <ng-template let-rowDetail #card>
            <wlm-generic-card [item]="rowDetail" [settings]="algorithmCardSettings">
              <ng-template #left>
                <div class="card-template left">
                  <mat-checkbox
                    class="custom-checkbox"
                    [checked]="isItemChecked(rowDetail)"
                    (change)="onCheckItem($event, rowDetail)"
                    onclick="this.blur()"
                  >
                  </mat-checkbox>
                </div>
              </ng-template>
              <ng-template #right>
                <div class="card-template right">
                  <button
                    mat-icon-button
                    (click)="onRemoveAlgorithm(rowDetail)"
                    onclick="this.blur()"
                    [matTooltip]="'common.remove' | translate | lowercase"
                  >
                    <mat-icon svgIcon="delete"></mat-icon>
                  </button>
                  <wlm-icon *ngIf="getIcon(rowDetail)" [icon]="getIcon(rowDetail)"></wlm-icon>
                </div>
              </ng-template>
            </wlm-generic-card>
          </ng-template>
        </wlm-data-visualization-cart-group>
      </mat-expansion-panel>
    </div>
    <div class="cart-group">
      <mat-expansion-panel
        id="module-expander"
        class="nav-link-expansion-panel"
        hideToggle="true"
        [expanded]="true"
        (opened)="signalsPanelOpenState = true"
        (closed)="signalsPanelOpenState = false"
      >
        <mat-expansion-panel-header
          class="nav-module-link-container"
          collapsedHeight="48px"
          expandedHeight="48px"
        >
          <mat-panel-title> {{ T_SCOPE + '.telemtry-points' | translate }} </mat-panel-title>
          <mat-panel-description>
            <wlm-icon *ngIf="!signalsPanelOpenState" icon="arrow_drop_down"></wlm-icon>
            <wlm-icon *ngIf="signalsPanelOpenState" icon="arrow_drop_up"></wlm-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <wlm-data-visualization-cart-group *ngIf="points?.length" [items]="points">
          <ng-template let-rowDetail #card>
            <wlm-generic-card [item]="rowDetail" [settings]="pointCardSettings">
              <ng-template #left>
                <div class="card-template left">
                  <mat-checkbox
                    class="custom-checkbox"
                    [checked]="isItemChecked(rowDetail)"
                    (change)="onCheckItem($event, rowDetail)"
                    onclick="this.blur()"
                  >
                  </mat-checkbox>
                </div>
              </ng-template>
              <ng-template #right>
                <div class="card-template right">
                  <button
                    mat-icon-button
                    (click)="onRemovePoint(rowDetail)"
                    onclick="this.blur()"
                    [matTooltip]="'common.remove' | translate | lowercase"
                  >
                    <mat-icon svgIcon="delete"></mat-icon>
                  </button>
                  <wlm-icon
                    *ngIf="getIcon(rowDetail)"
                    [icon]="getIcon(rowDetail)"
                    [color]="iconColor"
                  ></wlm-icon>
                </div>
              </ng-template>
              <ng-template #showMore>
                <wlm-data-visualization-item-details
                  [signalId]="rowDetail?.filtrableType?.signalId"
                ></wlm-data-visualization-item-details>
              </ng-template>
            </wlm-generic-card>
          </ng-template>
        </wlm-data-visualization-cart-group>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="cart-form">
    <wlm-date-range-filter
      *ngIf="initialDateRange"
      [dateRange]="initialDateRange"
      [displayHorizontal]="false"
      [disableStartDate]="false"
      [disableEndDate]="false"
      [resetEndDate$]="resetEndDate$"
      [resetStartDate$]="resetStartDate$"
      (dateRangeChanged)="onDateRangeChanged($event)"
      (hasError)="onDateRangeError($event)"
    ></wlm-date-range-filter>
    <div class="action-buttons-container">
      <button
        mat-button
        class="wtr-action-button-primary btn-size"
        (click)="onClickPlot()"
        [disabled]="isButtonDisabled"
      >
        {{ 'common.load' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
