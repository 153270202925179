import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '../dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../shared/auth/services/auth.guard';

import { GroupsPageComponent } from './groups-page/groups-page.component';
import { UsersPageComponent } from './users-page/users-page.component';

const routes: Routes = [
  {
    path: 'groups',
    component: GroupsPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Groups,
    },
  },
  {
    path: 'users',
    component: UsersPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.Users,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WlmSecurityRoutingModule {}
