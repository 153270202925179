import { SmartMeterConfigDto } from './smart-meter-config.dto';
import { SmartMeterSignalVersionOperationDto } from './smart-meter-signal-version-operation';

export class SmartMeterSignalVersionSaveDto {
  hierarchyElementId: string;
  hierarchyElementName: string;
  minDate: string;
  smartMeterSignalVersionOperations: SmartMeterSignalVersionOperationDto[];
  smartMeterSignals: SmartMeterConfigDto[];
  trackedDates: string[];
  trackedSignals: number[];

  constructor(init: SmartMeterSignalVersionSaveDto) {
    Object.assign(this, init);
  }
}
