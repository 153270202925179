import { Injectable } from '@angular/core';
import { BaseCustomizableChartService } from 'src/app/common-modules/shared/charts/base-customizable-chart.service';
import { IChartConfiguration } from 'src/app/common-modules/shared/charts/model/chart/chart-configuration';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { WorkspacesHelperService } from '../services/workspaces-helper.service';
import { FiltrableItemMapperService } from '../services/filtrable-item-mapper.service';
import { ChartConfigurationService } from './chart-configuration.service';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, map, switchMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { DataVisualizationChartConfigPopupDimensions } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataVisualizationChartConfigPopupComponent } from '../../data-visualization/components/data-visualization-chart-config-popup/data-visualization-chart-config-popup.component';
import { ChartConfiguration } from './model/chart-configuration';
import { plainToClass } from 'class-transformer';
import { WorkspaceService } from '../../data-visualization/components/data-visualization-workspace-selector/workspace.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WlmCustomizableChartService extends BaseCustomizableChartService {
  constructor(
    private _workspaceService: WorkspaceService,
    private _workspacesHelperService: WorkspacesHelperService,
    private _filtrableItemMapperService: FiltrableItemMapperService,
    private _chartConfigurationService: ChartConfigurationService,
    private _dialogService: DialogService
  ) {
    super();
  }

  getChartConfiguration(
    workspaceName: string,
    elements: WlmElementExtended[]
  ): Observable<IChartConfiguration> {
    return this._workspaceService.getTemplateByName(workspaceName).pipe(
      untilDestroyed(this),
      switchMap((workspace) => {
        if (!workspace) {
          return null;
        }

        return this.setChartConfigurationFromWorkspace(workspace, elements);
      })
    );
  }

  updateChartConfiguration(
    chartConfiguration: IChartConfiguration
  ): Observable<IChartConfiguration> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      chartConfiguration: chartConfiguration,
    };
    dialogConfig.height = DataVisualizationChartConfigPopupDimensions.Height;
    dialogConfig.width = DataVisualizationChartConfigPopupDimensions.Width;

    const popupRef = this._dialogService.openComponent(
      DataVisualizationChartConfigPopupComponent,
      dialogConfig
    );

    return popupRef?.afterClosed().pipe(
      map((result: ChartConfiguration) => {
        if (!result) {
          return;
        }

        const chartConfiguration = plainToClass(ChartConfiguration, result);
        return chartConfiguration;
      })
    );
  }

  private setChartConfigurationFromWorkspace(
    workspace: WorkspaceDto,
    elements: WlmElementExtended[]
  ): Observable<IChartConfiguration> {
    const definition = this._workspacesHelperService.getWorkspaceDefinition(workspace.definition);
    if (!definition) {
      return;
    }

    const dateRange = this._workspacesHelperService.getConfiguredDateRange(workspace);
    const chartConfiguration = this._workspacesHelperService.getChartConfiguration(definition)?.[0];
    const entityTypes = this._workspacesHelperService.getTemplateEntityTypes(chartConfiguration);

    const entityTypeAlgorithms = this._workspacesHelperService.getAlgorithmsByEntityTypes(
      chartConfiguration,
      entityTypes
    );

    const algorithms = Array.from(entityTypeAlgorithms.values()).reduce(
      (acc, value) => acc.concat(value),
      []
    );

    var algorithmfiltrableItems$ = asEnumerable(algorithms)
      .SelectMany((a) =>
        asEnumerable(elements).Select((e) =>
          this._filtrableItemMapperService.getAlgorithmFiltrableItemDtoByWlmElement(a, e)
        )
      )
      .ToArray();

    let algFiltrableItemsResult = [];

    return forkJoin(algorithmfiltrableItems$).pipe(
      untilDestroyed(this),
      take(1),
      switchMap((algorithmFiltrableItems) => {
        algFiltrableItemsResult = algorithmFiltrableItems;

        return this._workspacesHelperService.getIncludeSignals(algorithmFiltrableItems, workspace);
      }),
      switchMap((signalsItems) => {
        // Modify FiltrableItemsDates
        const filtrableItems: IFiltrableItemDto[] = [...algFiltrableItemsResult, ...signalsItems];
        const configuredFiltrableItems = this._workspacesHelperService.setFiltrableItemDate(
          filtrableItems,
          dateRange
        );

        // Get Default ChartConfiguration
        return this._chartConfigurationService.getChartConfigurationFromFiltrableItems(
          configuredFiltrableItems
        );
      })
    );
  }
}
