import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchyElementFiltersDataDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from 'src/app/common-modules/dependencies/navigation/w-navigate-by';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { DynamicFormSourceSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { SettingsEntityType } from 'src/app/common-modules/dynamic-forms/models/settings-entity-type';
import { DynamicLayoutKeys } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-keys';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { NotificationRelatedComponent } from 'src/app/common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-disable';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from 'src/app/common-modules/shared/helpers/navmenu-builder-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DynamicGridSettings } from 'src/app/common-modules/shared/model/grid/dynamic-grid-settings';
import { ICanLeavePage } from 'src/app/common-modules/shared/navigation/can-component-deactivate';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { PeriodTypesEnum } from 'src/app/common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from 'src/app/common-modules/wlm-charts/core/models/time-selector-settings';
import { TimeSelectorChartWidgetComponent } from 'src/app/common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { BaseGridPageComponent } from 'src/app/common-modules/wlm-grid/base-grid/base-grid-page.component';
import { DynamicGridComponent } from 'src/app/common-modules/wlm-grid/dynamic-grid/dynamic-grid.component';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { ActiveAlarmsGridComponent } from '../../alarms/active-alarms-grid/active-alarms-grid.component';
import { AlarmsChartComponent } from '../../alarms/alarms-chart/alarms-chart.component';
import { PrioritisationGridFilterValidations } from '../../alc/prioritisation-grid/prioritisation-grid-filter-validations';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { AttributeFormGroupRpWidgetComponent } from '../../ne-configuration/rp-widgets/attribute-form-group-rp-widget/attribute-form-group-rp-widget.component';
import { AttributeFormGroupCategories } from '../../shared/config/attribute-form-group-categories';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { ITransmissionNetworkDto } from '../../shared/model/transmission-network/transmission-network.dto';

const COMPONENT_SELECTOR = 'wlm-transmission-network-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './transmission-network-page.component.html',
  styleUrls: ['./transmission-network-page.component.scss'],
  providers: [ReduxStateService],
})
export class TransmissionNetworkPageComponent
  extends BaseGridPageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy, ICanLeavePage
{
  dynamicGridLoaded$: Subject<boolean> = new Subject<boolean>();

  private _dynamicGrid: DynamicGridComponent;
  public get dynamicGrid(): DynamicGridComponent {
    return this._dynamicGrid;
  }
  @ViewChild(DynamicGridComponent) set dynamicGrid(value: DynamicGridComponent) {
    this._dynamicGrid = value;
    if (value) {
      this.dynamicGridLoaded$.next(true);
    }
  }

  T_SCOPE = `${AppModules.TransmissionNetwork}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'TransmissionNetworkCrud';
  }

  private _selectedTNs: ITransmissionNetworkDto[];
  public get selectedTNs(): ITransmissionNetworkDto[] {
    return this._selectedTNs;
  }
  public set selectedTNs(value: ITransmissionNetworkDto[]) {
    this._selectedTNs = value;
    this.updateNavigationParams();
  }

  private _singleSelectedTN: ITransmissionNetworkDto;
  public get singleSelectedTN(): ITransmissionNetworkDto {
    return this._singleSelectedTN;
  }
  public set singleSelectedTN(value: ITransmissionNetworkDto) {
    this.checkPendingChanges(this.pageId).subscribe((result) => {
      if (!result) {
        return;
      }

      this._singleSelectedTN = value;
      this._hasRowSelected = this.singleSelectedTN !== null;

      this.notificationSelectionService.setZonesSelected([this.singleSelectedTN?.networkElementId]);
      this.SendParameters(this.singleSelectedTN, this.filterDetailsParameters);
    });
  }

  pageId: string = 'TransmissionNetworkPage';
  navigations: DropdownNavigationItem[] = [];

  filterSettings: FilterGroupSettings;
  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  autoLoad: boolean;

  // Filters fields names
  hierarchyElementIdFieldName = 'hierarchyElementId';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';

  filterFields = ['hierarchyElementsIds'];
  filterDetailsParameters: TabDetailPanelParameters;

  gridFilters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  emptyFilters: DataBindingFilters;
  gridFiltersMap: Map<string, any>;
  gridSettings: IGridSettings;
  gridName = 'TransmissionNetwork';
  dynamicGridSettings: DynamicGridSettings;
  grid: GenericGridComponent;

  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  reloadGrid$ = new Subject<void>();
  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsSubscription$: Subscription;
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();
  canLoad: boolean;

  private _hasRowSelected = false;
  private readonly _categoriesLabelsPath = `${AppModules.Configuration}.wlm-ne-config-attribute-form-group.forms`;
  private readonly _attributesLabelsPath = `${AppModules.Configuration}.attributes`;
  private readonly _selectedFormCategories = [AttributeFormGroupCategories.Info];
  private readonly _formsLayoutKeys: DynamicLayoutKeys = {
    widgetModule: 'necModule',
    widgetPage: this.pageId,
    settingArea: null,
    settingKey: null,
    scopeInstanceKeys: {
      [StateAreas.NEConfig]: 'necInstance1',
    },
  };
  private readonly _area = 'ne-config';
  private readonly _entityNameKey = 'common.entities.configuration';
  private _neTypeLayerMap: Map<number, number> = new Map<number, number>();

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;

  private readonly _chartWorkspaceName = 'TransmissionNetworkDetailsChart';

  constructor(
    private _objectHelperService: ObjectHelperService,
    private _route: ActivatedRoute,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _authService: AuthorizeService,
    private _state: ReduxStateService,
    private _globalsService: GlobalsService,
    private _mapHelperService: MapHelperService,
    private _pendingChangesService: PendingChangesManagerService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    super();

    const fullState = this.buildFullSettings();
    this._state.configure(fullState.widget);
  }

  ngOnInit(): void {
    this.subscribeLayersMap();
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      super.ngOnInit();

      const queryParams = params ? this.getQueryParams(params) : null;
      this.readNavigationParameters(queryParams)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          let params = { ...queryParams };

          if (filterData?.hierarchyFamilyId) {
            params[this.hierarchyElementFamilyFieldName] = filterData.hierarchyFamilyId;
          }

          this.updateNavigationParams();

          this.initializeRightPanelComponents();
          this.initializeGridSettings(params);

          if (this.grid) {
            this.applyGridFiltersByParams(this.grid, params);
          }

          this.dynamicGridLoaded$.subscribe((loaded) => {
            if (!loaded) {
              return;
            }

            if (this.grid) {
              this.applyGridFiltersByParams(this.grid, params);
            }

            this.dynamicGrid.genericGridLoaded.subscribe((grid) => {
              this.applyGridFiltersByParams(grid, params);
            });
          });
        });
    });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((result) => result !== null)
    );
  }

  subscribeLayersMap() {
    this._globalsService.getTransmissionNetworkElementTypes().subscribe((neTypes) => {
      this._neTypeLayerMap = new Map(
        neTypes.map((type) => {
          return [type.networkElementTypeId, type.gisLayerId];
        })
      );
    });
  }

  getNeIdFilter(neId: string): FilterDescriptor[] {
    const filter = { field: 'networkElementId', operator: 'eq', value: neId };
    return [filter];
  }

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new PrioritisationGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(this.filterFields, filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
      this.persistFilters$.next();
    } else {
      this.canLoad = false;
    }
  }

  onValidityChange(valid: boolean): void {
    this.canLoad = valid;
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.gridFiltersMap = this.setFiltersMap(this.gridFilters);
    this.loadGrid();
  }

  onCheckAutoload(): void {
    if (this.gridSettingsSubscription$ && !this.gridSettingsSubscription$.closed) {
      this.gridSettingsSubscription$.unsubscribe();
    }
    this.gridSettingsSubscription$ = this.gridSettingsReady$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings.disableAutoLoad && this.gridFilters?.filters) {
          this.gridFiltersMap = this.setFiltersMap(this.gridFilters);
          this.loadGrid();
        }
      });
  }

  loadGrid() {
    this.reloadGrid$.next();
  }

  getSelectedElement(selectedTN: ITransmissionNetworkDto) {
    this.singleSelectedTN = selectedTN;
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.Filter:
        this.onApplyFiltersFromPage();
        break;
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onApplyFiltersFromPage(): void {
    this.triggerApply$.next();
  }

  updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedTNs,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const hierarchyElementId =
      typeof params?.hierarchyElementIds === 'string'
        ? params?.hierarchyElementIds
        : params?.hierarchyElementIds?.[0];

    const hierarchyFamilyId = params?.hierarchyFamilyId;

    if (hierarchyElementId && !hierarchyFamilyId) {
      return this._globalsService.getHeFiltersDataById(hierarchyElementId);
    }

    return of(null);
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return this.checkPendingChanges(this.pageId);
  }

  private SendParameters(
    selectedElement: ITransmissionNetworkDto,
    filter: TabDetailPanelParameters
  ) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = filter ?? new TabDetailPanelParameters();
      parameters.addParameter(
        TabDetailParameterName.elementName,
        selectedElement?.networkElementName
          ? ts['element-name'] + selectedElement.networkElementName
          : null
      );

      parameters.addParameter(
        TabDetailParameterName.networkElementId,
        selectedElement?.networkElementId
      );

      parameters.addParameter(TabDetailParameterName.notification, selectedElement);

      const mapParameters = this.initializeMapParameters();

      if (this._hasRowSelected) {
        mapParameters.networkElements = selectedElement?.networkElementId
          ? [selectedElement?.networkElementId]
          : [];
        mapParameters.visibleLayersIds = [
          ...new Set([
            ...mapParameters.visibleLayersIds,
            ...[this._neTypeLayerMap.get(selectedElement?.networkElementTypeId)],
          ]),
        ];
      }

      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      let timeSelectorChartSetting = null;

      if (selectedElement?.networkElementId) {
        const wlmElement = this.getWlmElement(selectedElement);

        timeSelectorChartSetting = new TimeSelectorChartSettings({
          chartSetting: new CustomWorkspaceChartSettings({
            chartType: ChartType.customizable,
            dataParameters: new CustomWorkspaceChartDataParameters({
              startDate: new Date(),
              endDate: new Date(),
              workspaceName: this._chartWorkspaceName,
              elements: [wlmElement],
            }),
            exportFileName: `${this.titleService.getTitle()} ${
              selectedElement?.networkElementName
            }`,
          }),
          includeDefaultPeriods: true,
          defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
        });
      }

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      parameters.addParameter(TabDetailParameterName.dynamicFormLayoutKeys, this._formsLayoutKeys);

      if (selectedElement) {
        const { networkElementId, networkElementTypeId, networkElementName } = selectedElement;
        const networkElement = { networkElementId, networkElementTypeId, networkElementName };

        parameters.addParameter(TabDetailParameterName.networkElement, networkElement);

        const attributesSource = this.buildAttributesSource(networkElement);
        parameters.addParameter(TabDetailParameterName.dynamicFormSource, attributesSource);
      } else {
        parameters.addParameter(TabDetailParameterName.networkElement, null);
        parameters.addParameter(TabDetailParameterName.dynamicFormSource, null);
      }

      parameters.addParameter(
        TabDetailParameterName.selectedFormCategories,
        this._selectedFormCategories
      );

      parameters.addParameter(TabDetailParameterName.configuredStateService, this._state);

      this.includeAdditionalParams(parameters);
      this.rightPanelService.setTabParameters(parameters);
    });
  }

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedTNs.map(
      (x) =>
        new WlmElementExtended(
          x.networkElementId,
          x.networkElementTypeId.toString(),
          x.networkElementName,
          x.networkElementTypeName,
          null,
          EntityTypes.networkElement
        )
    );

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements,
      this._chartWorkspaceName
    );
  };

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      networkElements: persistedMapsettings ? persistedMapsettings.networkElements : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  private setFiltersMap(dataBindingFilters: DataBindingFilters): Map<string, any> {
    const iFilters = Array.from(dataBindingFilters.filters.values()).map((x) =>
      x.getFiltersValues()
    );
    const filtersToApply = this._objectHelperService.joinMaps(iFilters);
    return this._objectHelperService.clone(filtersToApply);
  }

  private initializeGridSettings(params: Params = null) {
    this.dynamicGridSettings = new DynamicGridSettings({
      disableAutoLoad: false,
      gridSettingName: this.gridName,
      persistencyArea: this.persistencyArea,
      type: 'generic',
      usePersistence: true,
    });
    this.emptyFilters = new DataBindingFilters();

    this.setFilterSettings(params);
  }

  private initializeRightPanelComponents() {
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
    const alarmsPermission$ = this._authService.canAccess('WLMAlarmsCrud', 'r');
    const networkPermission$ = this._authService.canAccess('WLMNetworkCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
    combineLatest([
      notificationPermission$,
      alarmsPermission$,
      networkPermission$,
      localization$,
    ]).subscribe(([notificationPermission, alarmsPermission, networkPermission, ts]) => {
      const panelSettings = new TabDetailPanelSettings();

      if (alarmsPermission) {
        panelSettings.addComponent(ActiveAlarmsGridComponent, ts.alarms);
        panelSettings.addComponent(AlarmsChartComponent, ts.chart);
      }

      panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);

      if (notificationPermission) {
        panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
      }

      panelSettings.addComponent(MapRelatedComponent, ts.map);

      if (networkPermission) {
        panelSettings.addComponent(AttributeFormGroupRpWidgetComponent, ts.info);
      }

      this.rightPanelService.setTabSettings(panelSettings);
    });
  }

  private setFilterSettings(params: Params = null) {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementIdFieldName,
      }),
      hierarchyElementFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementFamilyFieldName,
      }),
      hierarchyElementTypes: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementTypeFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
      avoidPersistency: params?.networkElementId ? true : false,
    });
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedTNs?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'custom',
        customParamsKey: ['networkElementId'],
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'custom',
        customValidation: !this.selectedTNs?.length,
        paramType: 'custom',
        customParamsKey: ['networkElementId'],
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  private buildAttributesSource(
    networkElement: Partial<INetworkElementDto>
  ): DynamicFormSourceSettings {
    const result = new DynamicFormSourceSettings({
      entityType: SettingsEntityType.NetworkElement,
      elementTypeId: networkElement.networkElementTypeId,
      categoriesLabelsPath: this._categoriesLabelsPath,
      attributesLabelsPath: this._attributesLabelsPath,
      formState: {},
      entityNameKey: this._entityNameKey,
    });
    return result;
  }

  private buildFullSettings(newAction: any = {}) {
    return new StateFullSettings({
      widget: new StateWidgetSettings({
        module: this._formsLayoutKeys.widgetModule,
        page: this._formsLayoutKeys.widgetPage,
        scopeInstanceKeys: this._formsLayoutKeys.scopeInstanceKeys,
      }),
      area: newAction?.area ?? this._area,
    });
  }

  private applyGridFiltersByParams(grid: GenericGridComponent, params: Params) {
    if (params?.networkElementId) {
      grid.clearFilters();
      grid.applyColumnFilter(this.getNeIdFilter(params?.networkElementId));
      grid.reloadGrid();
    }
  }

  private getWlmElement(selectedElement: ITransmissionNetworkDto): WlmElementExtended {
    const { networkElementId, networkElementTypeId, networkElementName, networkElementTypeName } =
      selectedElement;

    return new WlmElementExtended(
      networkElementId,
      networkElementTypeId?.toString(),
      networkElementName,
      networkElementTypeName,
      null
    );
  }
}
