import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';

import { PagedResultDto } from '../model/paged-result.dto';
import { IdentityUserCmd } from '../model/roles/identity-users-cmd.dto';
import { UsersIdentityResponse } from '../model/roles/identity-users-response.dto';
import { UsersIdentityMappingDto } from '../model/roles/users-identity-mapping.dto';
import { GridODataService } from '../odata/grid-odata.service';

const url = '/api/roles/users-identity';
@Injectable({
  providedIn: 'root',
})
export class UsersIdentityService extends GridODataService<UsersIdentityMappingDto> {
  expiration: GetCacheOpts = { expiration: 'default', reload: true };
  constructor(injector: Injector) {
    super(injector, url);
  }

  get url() {
    return `${this.baseUrl}${url}`;
  }

  protected mapResponse(
    response: PagedResultDto<UsersIdentityMappingDto>
  ): PagedResultDto<UsersIdentityMappingDto> {
    return response;
  }

  insertIdentityUsers(identityUsersCmd: IdentityUserCmd): Observable<UsersIdentityResponse> {
    return this.httpCacheClient.post(`${this.url}/insert`, identityUsersCmd, {
      avoid: true,
    });
  }

  deleteIdentityUsers(identityUsersCmd: IdentityUserCmd): Observable<UsersIdentityResponse> {
    return this.httpCacheClient.post(`${this.url}/delete`, identityUsersCmd, {
      avoid: true,
    });
  }
}
