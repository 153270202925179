<wlm-popup-wrapper [titleKey]="titleKey" [showSpinner]="isLoading" (popupClose)="onClose($event)">
  <ng-container popup-top-icons>
    <wlm-grid-buttons
      *ngIf="dynamicGridSettings"
      [(gridSettings)]="dynamicGridSettings"
      [gridFilters]="gridFiltersForBinding"
      [grid]="grid"
      [responsive]="false"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <span class="buttons-container">
          <button
            wlmCollapsibleButton
            class="button-position"
            *isSuperUser
            [disabled]="!selectedItem || !selectedItem?.isSystemTemplate"
            [tooltip]="T_SCOPE + '.buttons.toggle-system-default'"
            [showLabel]="true"
            (click)="onToggleSystemDefault()"
          >
            <mat-icon>create</mat-icon>
          </button>
          <button
            wlmCollapsibleButton
            class="button-position"
            *hasPerm="['WLMBusinessIntelligenceCrud', 'u']"
            [disabled]="!selectedItem"
            [tooltip]="T_SCOPE + '.buttons.toggle-dashboard-default'"
            [showLabel]="true"
            (click)="onToggleDashboardDefault()"
          >
            <mat-icon>create</mat-icon>
          </button>
          <!-- Delete button for super users -->
          <span *isSuperUser>
            <button
              wlmCollapsibleButton
              class="button-position"
              *hasPerm="['WLMBusinessIntelligenceCrud', 'd']"
              [disabled]="!selectedItem"
              [tooltip]="T_SCOPE + '.delete'"
              [showLabel]="isContainerCollapsed"
              (click)="onDeleteAnyTemplate()"
            >
              <mat-icon class="material-icon">remove</mat-icon>
            </button>
          </span>
          <!-- Delete button for normal users -->
          <span *isNormalUser>
            <button
              wlmCollapsibleButton
              class="button-position"
              *hasPerm="['WLMBusinessIntelligenceCrud', 'd']"
              [disabled]="!selectedItem || (selectedItem && !isTemplateOwner)"
              [tooltip]="T_SCOPE + '.delete'"
              [showLabel]="isContainerCollapsed"
              (click)="onDeleteMyTemplates()"
            >
              <mat-icon class="material-icon">remove</mat-icon>
            </button>
          </span>
        </span>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container popup-content>
    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings"
      [dynamicGridSettings]="dynamicGridSettings"
      (selectedItemChanged)="onSelectedItem($event)"
      (genericGridLoaded)="onGridLoaded($event)"
      [dataBindingFilters]="gridFiltersForBinding"
    ></wlm-dynamic-grid>
  </ng-container>

  <ng-container popup-actions>
    <button
      mat-raised-button
      color="primary"
      *hasPerm="['WLMBusinessIntelligenceCrud', 'r']"
      onclick="this.blur()"
      [disabled]="!selectedItem"
      (click)="onLoadTemplate()"
    >
      {{ T_SCOPE + '.load' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
