import { Component, Input } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ISapCodeGroupDto } from 'src/app/common-modules/dependencies/alc/sap-code-group.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';

@Component({
  selector: 'wlm-sap-code-groups-form',
  templateUrl: './sap-code-groups-form.component.html',
  styleUrls: ['./sap-code-groups-form.component.scss'],
})
export class SapCodeGroupsFormComponent extends IntegrableForm {
  @Input() initialModel: ISapCodeGroupDto;
  model: ISapCodeGroupDto;

  onModelChange(model: ISapCodeGroupDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'codeGroupId',
          type: 'input',
          className: 'col-12',
          props: {
            type: 'number',
            label: 'Code Group Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'codeGroupValue',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Code Group Value',
            required: true,
          },
          validators: {
            validation: [{ name: 'maxLength', options: { maxLength: 4 } }],
          },
        },
        {
          key: 'isProactive',
          type: 'checkbox',
          className: 'col-12 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Proactive?',
            required: true,
          },
        },
      ],
    },
  ];
}
