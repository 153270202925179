<wlm-page-wrapper
  [titleKey]="T_SCOPE + (currentDashboardId ? '.title-with-current' : '.title')"
  [titleKeyParams]="{
    dashboardTitle: currentDashboardTitle
  }"
>
  <ng-container page-top-buttons>
    <div class="buttons-background button-position vertical-align-buttons">
      <button
        mat-icon-button
        color="primary"
        (click)="onNewEmptyTemplate()"
        onclick="this.blur()"
        *hasPerm="['WLMBusinessIntelligenceCrud', 'c']"
        [matTooltip]="T_SCOPE + '.buttons.new-empty-template' | translate"
      >
        <wlm-icon icon="add"></wlm-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="onCreateTemplate()"
        onclick="this.blur()"
        [disabled]="!currentTemplateLayout"
        *hasPerm="['WLMBusinessIntelligenceCrud', 'c']"
        [matTooltip]="T_SCOPE + '.buttons.create-current-template' | translate"
      >
        <wlm-icon icon="save_as"></wlm-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="onSaveTemplateDefinition()"
        onclick="this.blur()"
        [disabled]="!currentTemplateLayout || !currentDashboardId"
        *hasPerm="['WLMBusinessIntelligenceCrud', 'c']"
        [matTooltip]="T_SCOPE + '.buttons.save-current-template' | translate"
      >
        <wlm-icon icon="save"></wlm-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="onManageTemplate()"
        onclick="this.blur()"
        *hasPerm="['WLMBusinessIntelligenceCrud', 'r']"
        [matTooltip]="T_SCOPE + '.buttons.manage-current-template' | translate"
      >
        <mat-icon svgIcon="manage-templates-workspaces"></mat-icon>
      </button>
      <button
        *ngIf="pageSettings?.showResetIcon"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onClickResetMainLayout()"
        [matTooltip]="T_SCOPE + '.buttons.reset-main-layout' | translate | lowercase"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>

  <ng-container page-content>
    <wlm-dynamic-layout *ngIf="pageSettings" [settings]="pageSettings"></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
