import { Injectable, OnDestroy } from '@angular/core';
import { fromWorker } from 'observable-webworker';
import { ReplaySubject, Subscription, interval } from 'rxjs';
import { SettingsService } from '../../shared/config/settings.service';
import { HttpCacheSyncWorkerParams, HttpCacheSyncWorkerResponse } from './http-cache-sync.worker';
import { HttpCacheClient } from './http-cache.client';
import { HttpCacheService } from './http-cache.service';

@Injectable({ providedIn: 'root' })
export class HttpCacheManager implements OnDestroy {
  // TODO: Move to settings
  public refreshEnabled = true;
  intervalSubscription: Subscription;
  signalRSubscription: Subscription;
  private _workerInput$ = new ReplaySubject<HttpCacheSyncWorkerParams>(1);
  private _instanceRegistered$ = new ReplaySubject<string>(1);
  readonly instanceRegistered$ = this._instanceRegistered$.asObservable();

  constructor(
    private _service: HttpCacheService,
    private _cacheClient: HttpCacheClient,
    private _settingsService: SettingsService
  ) {}

  ngOnDestroy(): void {
    this.stop();
  }

  public start(minutes: number): void {
    this.runClearExpiredInterval(minutes);
    this.runCacheSignalRWorker();
  }

  runClearExpiredInterval(minutes: number) {
    const source = interval(minutes * 60 * 1000);
    this.intervalSubscription = source.subscribe(() => {
      this._service.clearExpired().then((urls) => {
        this.refreshUrls(urls);
      });
    });
  }

  refreshUrls(urls: string[]) {
    if (!this.refreshEnabled || !urls?.length) {
      return;
    }

    console.log(`URLs to refresh:`, urls);

    urls.forEach((x, i) => {
      const client = this._cacheClient;
      console.log(`Caching: ${x}`);
      client.get(x).subscribe({
        next: () => console.log(`Cached: ${x}`),
      });
    });
  }

  runCacheSignalRWorker() {
    const response$ = fromWorker<HttpCacheSyncWorkerParams, HttpCacheSyncWorkerResponse>(
      () => new Worker(new URL('./http-cache-sync.worker.ts', import.meta.url), { type: 'module' }),
      this._workerInput$.asObservable()
    );

    this.emitInitializeWorker();

    this.signalRSubscription = response$.subscribe((response: HttpCacheSyncWorkerResponse) => {
      if (response?.updated) {
        this._service.clearByPayload(response.updated.payload).then((urls) => {
          this.refreshUrls(urls);
        });
      } else if (response?.instanceRegistered) {
        this._instanceRegistered$.next(response.instanceRegistered.instanceId);
      }
    });
  }

  /**
   * Notify the worker so it will initialize signalR.
   */
  private emitInitializeWorker(): void {
    this._workerInput$.next({
      initialize: {
        hubUrl: this.hubUrl,
      },
    });
  }

  /**
   * Notify the worker so it will emit a signalR event for registering an instance.
   */
  emitRegisterInstanceWorker(instanceId: string): void {
    this._workerInput$.next({
      registerInstance: {
        instanceId,
      },
    });
  }

  public stop(): void {
    this.intervalSubscription.unsubscribe();
    this.signalRSubscription.unsubscribe();
  }

  private get hubUrl(): string {
    return `${this._settingsService.apis.default.url}/hub/cache`;
  }
}
