<div class="component-container">
  <h3>{{ titleKey | translate }}</h3>

  <hr class="title-separator" />

  <wlm-treelist
    class="water-grid treelist-container"
    *ngIf="treeListSettings"
    [treeListSettings]="treeListSettings"
    (valueChanged)="onValueChanged($event)"
  ></wlm-treelist>

  <div class="buttons-container" *ngIf="treeListSettings">
    <button mat-raised-button color="primary" (click)="saveConfiguration()">
      {{ 'common.save' | translate }}
    </button>
    <button mat-raised-button (click)="close()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</div>
