<wlm-page-wrapper [titleKey]="titleTranslationKey">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [pageId]="pageId"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="distributionNetworkGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-hierarchy-elements-selection-filter
      *ngIf="configReady"
      [config]="filterConfig"
      [clearAll$]="clearAll$"
      [apply$]="triggerApply$"
      [gridSettings]="gridSettings"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="onApplyFilters(distributionNetworkGrid)"
      (valid)="onValidityChange($event)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-hierarchy-elements-selection-filter>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      class="generic-grid"
      #distributionNetworkGrid
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      (selectedItemChanged)="getSelectedDistribution($event)"
      [(selectedItems)]="selectedDistributions"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      (isInThePastChange)="onIsInThePastChange($event)"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
