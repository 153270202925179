import { DestroyRef, Directive, ElementRef, ViewChildren, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { FixedSizeElementDirective } from '../core/responsive/fixed-size-element.directive';
import { IElementSize } from '../model/element-size';
import { SizeCalculatorService } from '../services/size-calculator.service';
import { BaseWidgetComponent } from './base-widget.component';

@Directive()
export abstract class BaseResponsiveWidgetComponent extends BaseWidgetComponent {
  private _containerSize$: Observable<IElementSize>;
  get containerSize$(): Observable<IElementSize> {
    return this._containerSize$;
  }
  set containerSize$(value: Observable<IElementSize>) {
    this._containerSize$ = value;
    this.tryInitResize();
  }

  private _fixedSizeElements: ElementRef<HTMLElement>[];
  @ViewChildren(FixedSizeElementDirective) set queryFixedSizeElements(
    value: FixedSizeElementDirective[]
  ) {
    this._fixedSizeElements = value.map((dir) => dir.element);
    this.tryInitResize();
  }

  abstract dimensionToCalculate: 'height' | 'width';

  private readonly _calculatedSize$ = new ReplaySubject<IElementSize>(1);
  readonly calculatedSize$ = this._calculatedSize$.asObservable();
  private _subscription: Subscription;

  private readonly _sizeCalculatorService = inject(SizeCalculatorService);
  private readonly _destroyRef = inject(DestroyRef);
  protected readonly _elementRef = inject(ElementRef);

  protected initWidgetResponsive(): void {
    this.containerSize$ = this._sizeCalculatorService.listenElementSize$(
      this._elementRef.nativeElement
    );
  }

  private tryInitResize(): void {
    if (this.containerSize$ && this._fixedSizeElements) {
      const size$ = this._sizeCalculatorService.listenCalculateRemainingSize$({
        containerSize$: this.containerSize$,
        fixedSizeElements: this._fixedSizeElements,
        fixedSizes$: this.fixedSizes$(),
        dimensionToCalculate: this.dimensionToCalculate,
        debugName: this.debugName(),
      });

      this._subscription?.unsubscribe();
      this._subscription = size$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((size) => {
        this._calculatedSize$.next(size);
      });
    }
  }

  abstract fixedSizes$(): Observable<IElementSize>[];

  debugName(): string {
    const tagName = this._elementRef.nativeElement.tagName.toLowerCase();
    return tagName;
  }
}
