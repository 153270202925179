import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class TimeAggregationFISettings extends BaseFilterItemSettings {
  fieldName: string;
  selectedIds: number[];

  constructor(init: TimeAggregationFISettings) {
    super(init);
    Object.assign(this, init);
  }
}
