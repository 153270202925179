import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { IActivitiesRaiseCmd } from '../shared/model/alc/activities-raise-cmd';
import { IActivitiesRaiseResponse } from '../shared/model/alc/activities-raise-response';
import { IActivityRaiseCmd } from '../shared/model/alc/activity-raise-cmd';

@Injectable({
  providedIn: 'root',
})
export class ActivityRaiseService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/interface/wo/raise`;
  }

  public raise(activity: IActivityRaiseCmd): Observable<IActivitiesRaiseResponse> {
    return this.httpCacheClient.post(`${this.url}/activity`, activity, { avoid: true });
  }

  public raiseBulk(activity: IActivitiesRaiseCmd): Observable<IActivitiesRaiseResponse> {
    return this.httpCacheClient.post(`${this.url}/activities`, activity, { avoid: true });
  }
}
