import { LargeUserSignalFlatDto } from './large-user-signal-flat.dto';
import { LargeUserSignalVersionOperationDto } from './large-user-signal-version-operation.dto';

export class LargeUserSignalVersionSaveDto {
  hierarchyElementId: string;
  hierarchyElementName: string;
  minDate: string;
  largeUserSignalVersionOperations: LargeUserSignalVersionOperationDto[];
  largeUserSignals: LargeUserSignalFlatDto[];
  trackedDates: string[];
  trackedSignals: number[];

  constructor(init: LargeUserSignalVersionSaveDto) {
    Object.assign(this, init);
  }
}
