<div id="center-page">
  <div *ngIf="userGridSetting" class="top-container">
    <h3>{{ titleTranslationKey | translate }}</h3>
    <div class="filter-buttons-container">
      <!-- <button mat-icon-button matTooltip="add users to group" (click)="saveGroupUsers()">
            <mat-icon>add</mat-icon>
          </button> -->
      <button
        mat-icon-button
        class="button-position mat-icon-no-overlay"
        [disabled]="!usersGrid?.canClearFilters()"
        (click)="usersGrid?.clearFilters()"
        [matTooltip]="'common.clear-filter' | translate | lowercase"
      >
        <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
      </button>
    </div>
  </div>
  <hr class="separator" />

  <div id="center-container">
    <wlm-generic-grid
      class="user-grid"
      *ngIf="gridFiltersForBinding"
      [additionalFilters]="additionalFilters"
      [gridSettings]="userGridSetting"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [(selectedItems)]="selectedUsers"
      [subscriptionTag]="userGridSettingsName"
    ></wlm-generic-grid>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="saveGroupUsers()"
        [disabled]="!selectedUsers?.length || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button [disabled]="isSaving" (click)="cancel()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </div>
</div>
