<div
  [ngStyle]="{ 'min-width': settings?.collapsedMinWidth, 'max-width': settings?.collapsedMaxWidth }"
  class="base-filter fix-field-label-overlap"
>
  <!-- MAIN INPUT -->
  <mat-form-field
    appearance="outline"
    [matMenuTriggerFor]="filterMenu"
    (menuOpened)="onMenuOpen()"
    #filterMenuTrigger="matMenuTrigger"
    [ngStyle]="{
      'min-width': settings?.collapsedMinWidth,
      'max-width': settings?.collapsedMaxWidth
    }"
    [ngClass]="{ 'input-error': !areAllValid, 'filter-disabled': settings?.disableFilter }"
    class="field-style"
    (click)="onFormFieldClick(filterInput)"
    [subscriptSizing]="subscriptSizing"
  >
    <mat-label>{{
      (settings?.inputLabelKey ? settings?.inputLabelKey : T_SCOPE + '.title') | translate
    }}</mat-label>
    <input
      matInput
      [placeholder]="
        (settings?.inputPlaceholderKey ? settings?.inputPlaceholderKey : T_SCOPE + '.placeholder')
          | translate
      "
      [formControl]="filterCtrl"
      #filterInput
      autocomplete="off"
      [readonly]="settings?.disableSearch"
      [required]="!menuIsOpen && settings?.isRequired"
    />
    <mat-error
      class="field-error"
      *ngIf="(filterCtrl.touched || filterCtrl.dirty) && filterCtrl.invalid && settings?.isRequired"
    >
      {{ 'common.validation.required' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- POPUP AREA -->
  <mat-menu #filterMenu="matMenu" (closed)="onMenuClose()" class="panel-disable-fixed">
    <div class="menu-container" (click)="stopPropagation($event)">
      <div class="filter-actions" *ngIf="!settings?.formMode">
        <button
          mat-button
          class="wtr-button-primary"
          *ngIf="!settings?.disableClear"
          (click)="onClearAll()"
        >
          {{
            (settings?.clearAllTextKey ? settings?.clearAllTextKey : T_SCOPE + '.clear-all')
              | translate
          }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          *ngIf="!settings?.disableSelectAll"
          (click)="onSelectAll()"
        >
          {{
            (settings?.selectAllTextKey ? settings?.selectAllTextKey : T_SCOPE + '.select-all')
              | translate
          }}
        </button>
        <button
          mat-button
          class="apply-btn wtr-button-primary"
          *ngIf="!settings?.disableApplyFilters"
          (click)="onApplyFilters()"
          [disabled]="!areAllValid"
        >
          {{
            (settings?.applyFiltersTextKey
              ? settings?.applyFiltersTextKey
              : T_SCOPE + '.apply-filters'
            ) | translate
          }}
        </button>
      </div>
      <div
        [ngStyle]="{
          'min-width': settings?.expandedMinWidth,
          'max-width': settings?.expandedMaxWidth,
          'min-height': settings?.expandedMinHeight,
          'max-height': settings?.expandedMaxHeight
        }"
      >
        <!-- BASE FILTER SUMMMARY -->
        <div class="summaries-container" *ngIf="templateSummaries && !settings?.formMode">
          <div class="row">
            <div class="col-12" *ngFor="let template of templateSummaries">
              <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>
          </div>
        </div>
        <mat-divider *ngIf="!settings?.formMode"></mat-divider>
        <!-- BASE FILTERS -->
        <div class="filters-container" *ngIf="templates">
          <div class="row">
            <div class="col-12" *ngFor="let template of templates">
              <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
