import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicSettingsSave } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings-save';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';

import { BiSaveTemplateDto } from '../../models/bi-save-template.dto';
import { CreateDashboardResultDto } from '../../models/create-template-result.dto';
import { BiSaveTemplateFormComponent } from '../bi-save-template-form/bi-save-template-form.component';

const COMPONENT_SELECTOR = 'wlm-bi-save-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-save-popup.component.html',
  styleUrls: ['./bi-save-popup.component.scss'],
})
export class BiSavePopupComponent implements OnInit {
  @ViewChild(BiSaveTemplateFormComponent) formComponent: BiSaveTemplateFormComponent;

  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;
  dynamicSettingsSave: DynamicSettingsSave;
  readonly styles = {
    contentInline: true,
  };
  titleKey = `${this.T_SCOPE}.title`;
  isLoading = true;
  isValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) dynamicSettingsSave: DynamicSettingsSave,
    private _dialogRef: MatDialogRef<BiSavePopupComponent>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.dynamicSettingsSave = dynamicSettingsSave;
  }

  ngOnInit(): void {}

  saveForm(): void {
    const defaultModel: Partial<BiSaveTemplateDto> = {
      layoutArea: this.dynamicSettingsSave.settingArea,
      layoutKey: this.dynamicSettingsSave.settingKey,
    };
    this.formComponent.save(defaultModel, this.dynamicSettingsSave.settingValue);
  }

  onFormIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    this._changeDetectorRef.detectChanges();
  }

  onFormIsValid(isValid: boolean): void {
    this.isValid = isValid;
  }

  onFormHasSaved(data: CreateDashboardResultDto): void {
    this.onClose(data);
  }

  onClose(data: CreateDashboardResultDto = null): void {
    this._dialogRef.close(data);
  }
}
