import { Directive, Input } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { navigationMustOpenNewTab } from '../../dependencies/navigation/wlm-navigation.service';

/**
 * Inherits standard routerLink capabilities and enables to open in separate windows on ctrl+click.
 */

@Directive({
  selector: '[wRouterLink]',
  inputs: ['routeParams: routerLink', 'target: target'],
  host: {
    '(click)': 'onClickWithEvent($event)',
    '[attr.href]': 'visibleHref',
    '[class.router-link-active]': 'isRouteActive',
  },
})
export class WRouterLinkDirective extends RouterLinkWithHref {
  @Input() wRouterLink: string | any[];

  onClickWithEvent(event: MouseEvent): any {
    if (navigationMustOpenNewTab(event)) {
      // Set routerLink to null to avoid reloading the current page.
      this.routerLink = null;
      this.openNewTab();
      return true;
    }

    // Proceed with standard behavior.
    this.routerLink = this.wRouterLink;
    const { button, ctrlKey, shiftKey, altKey, metaKey } = event;
    return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
  }

  private openNewTab(): void {
    if (!this.wRouterLink) {
      return;
    }

    const link =
      typeof this.wRouterLink === 'string' ? this.wRouterLink : this.wRouterLink.join('/');
    window.open(link, '_blank');
  }
}
