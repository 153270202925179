import { Component, Inject, Injector } from '@angular/core';
import { ALCCampaignDto } from 'src/app/common-modules/dependencies/alc/alc-campaing.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';

const COMPONENT_SELECTOR = 'wlm-related-activities-widget';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './related-activities-widget.component.html',
  styleUrls: ['./related-activities-widget.component.scss'],
})
export class RelatedActivitiesWidgetComponent extends BaseWidgetComponent {
  selectedCampaign: ALCCampaignDto;

  get componentName(): string {
    return 'RelatedActivitiesWidgetComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.selectedCampaign = parameters.parameters.get(TabDetailParameterName.campaign);
  }
  init(): void {}
}
