<kendo-grid-column [field]="field" [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="image-position">
      <mat-icon
        *ngIf="!(imageElements | imageIconIsSvg : dataItem[field])"
        [style.color]="imageElements | imageIconColor : dataItem[field]"
      >
        {{ imageElements | imageIconName : dataItem[field] }}
      </mat-icon>

      <wlm-icon
        *ngIf="imageElements | imageIconIsSvg : dataItem[field]"
        [icon]="imageElements | imageIconName : dataItem[field]"
        [size]="'lg'"
      ></wlm-icon>
    </span>
  </ng-template>

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-image-column-filter
      [field]="column.field"
      [filterService]="filterService"
      [filter]="filter"
      [elements]="imageElements"
    ></wlm-image-column-filter>
  </ng-template>
</kendo-grid-column>
