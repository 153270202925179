<kendo-grid-checkbox-column [width]="width">
  <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
    <span class="whole-cell-select">
      <input class="k-checkbox" [kendoGridSelectionCheckbox]="idx" />
    </span>
  </ng-template>
  <ng-template kendoGridHeaderTemplate>
    <div class="select-all-column-id select-all-width">
      <input
        *ngIf="showSelectAllHeader"
        type="checkbox"
        [width]="40"
        [indeterminate]="selectAllIsIndeterminate"
        [(ngModel)]="selectAllValue"
        kendoCheckBox
        (click)="onSelectAllChange($event)"
      />
      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
    </div>
  </ng-template>
</kendo-grid-checkbox-column>
