import { BaseLinkConf } from './base-link-conf';

export class MenuLinkConf extends BaseLinkConf {
  urlPath: string;

  constructor(init?: Partial<MenuLinkConf>) {
    super(init);
    Object.assign(this, init);
  }
}
