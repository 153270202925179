import { FieldValidatorParams, FieldValidatorValueModes } from './field-validator-value-modes';

export class FieldValidationUIOptions {
  mode?: FieldValidatorValueModes = FieldValidatorValueModes.Fixed;
  data?: any;
  params?: FieldValidatorParams[];

  constructor(init: FieldValidationUIOptions) {
    Object.assign(this, init);
  }
}
