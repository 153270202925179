<wlm-page-wrapper
  [titleKey]="T_SCOPE + (workspace?.workspaceName ? '.title-with-current' : '.title')"
  [titleKeyParams]="{
    workspaceName: workspace?.workspaceName
  }"
>
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        color="primary"
        (click)="onNewConfiguration()"
        onclick="this.blur()"
        [matTooltip]="T_SCOPE + '.new-configuration' | translate"
      >
        <wlm-icon icon="add"></wlm-icon>
      </button>
      <button
        mat-icon-button
        *hasPerm="['WLMDataVisualizationCrud', 'c']"
        [matTooltip]="T_SCOPE + '.save-config-as' | translate | lowercase"
        onclick="this.blur()"
        (click)="onSaveConfiguration(false)"
        [disabled]="!cartFiltrableItems?.length || !chartConfiguration"
      >
        <mat-icon svgIcon="save_as"></mat-icon>
      </button>
      <button
        mat-icon-button
        *hasPerm="['WLMDataVisualizationCrud', 'u']"
        [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
        onclick="this.blur()"
        (click)="onSaveConfiguration(true)"
        [disabled]="!isSaveButtonEnabled()"
      >
        <mat-icon svgIcon="save"></mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.manage-templates-workspaces' | translate | lowercase"
        onclick="this.blur()"
        (click)="onManageTemplatesAndWorkspaces()"
      >
        <mat-icon svgIcon="manage-templates-workspaces"></mat-icon>
      </button>
      <button
        *ngIf="settings?.showResetIcon"
        mat-icon-button
        [matTooltip]="settings?.labelReset | translate | lowercase"
        color="primary"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="settings"
      [settings]="settings"
      (activeWidgets)="onActiveWidgetsChange($event)"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
