import { Injectable } from '@angular/core';
import { diff } from 'deep-object-diff';
import get from 'lodash.get';
import set from 'lodash.set';

import { globalUtilsHelper } from './global-utils-helper';

@Injectable({
  providedIn: 'root',
})
export class ObjectHelperService {
  /**
   * Sets a value in an object by following a properties path.
   * If the object path does not exist, it is created.
   * Does not currently support accessing array elements.
   * @example deepSet({ hand: { fingers: 5 }}, 'hand.fingers', 4)
   */
  deepSet(obj: object, fullPath: string = '', value = null): void {
    set(obj, fullPath, value);
  }

  deepSetIfDefined = (obj: object, fullPath: string = '', value): void => {
    if (typeof value !== 'undefined') {
      this.deepSet(obj, fullPath, value);
    }
  };

  deepGet(obj: object, fullPath: string): any {
    const result = get(obj, fullPath);
    return result;
  }

  camelCase(string: string) {
    return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return '';
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  /**
   * Get only the unique objects, by performing deep comparison.
   */
  deepDistinct = (values) => {
    return [
      ...new Set<string>(values.map((value) => JSON.stringify(this.sortObjectKeys(value)))),
    ].map((value) => {
      return JSON.parse(value);
    });
  };

  /**
   * Return a copy of the object with all its properties sorted in alphabetical order.
   * The copying method is naive, as it only considers objects, arrays and primitives.
   */
  sortObjectKeys = (obj: object) => {
    if (!obj) {
      return obj;
    }
    const keys = Object.keys(obj);
    const sortedObj = keys.sort().reduce((currentObj, currentKey) => {
      // Avoid sharing references when values are objects or arrays.
      if (typeof obj[currentKey] === 'object') {
        currentObj[currentKey] = { ...obj[currentKey] };
      } else if (Array.isArray(obj[currentKey])) {
        currentObj[currentKey] = [...obj[currentKey]];
      } else {
        currentObj[currentKey] = obj[currentKey];
      }
      return currentObj;
    }, {});
    return sortedObj;
  };

  groupBy = (array: any[], groupByKey: string): { [key: string]: any[] } => {
    return array.reduce((accum, item) => {
      (accum[item[groupByKey]] = accum[item[groupByKey]] || []).push(item);
      return accum;
    }, {});
  };

  groupByFn = globalUtilsHelper.groupByFn;

  groupArraysByFn = (
    array: { [key: string]: any }[][],
    fn: (item) => any
  ): { [key: string]: any[] } => {
    return array.reduce((accum, innerArray: any[]) => {
      innerArray.forEach((innerItem) => {
        (accum[fn(innerItem)] = accum[fn(innerItem)] || []).push(innerItem);
      });
      return accum;
    }, {});
  };

  /**
   * Same as groupByFn, but expects each key to have only one value.
   */
  groupByFnSingle = (array: any[], fn: (item) => any) => {
    const grouped = this.groupByFn(array, fn);
    const groupedSingle = {};
    Object.entries(grouped).forEach(([key, group]) => {
      if (group.length > 1) {
        throw new Error(`Cannot group by single if each key has more than one result.`);
      }
      groupedSingle[key] = group[0];
    });
    return groupedSingle;
  };

  /**
   * Keeps only the first element of each inner array, so it should only be used when we are interested in the
   * properties shared by all elements inside a group.
   */
  groupArraysByFnSingle = (array: { [key: string]: any }[][], fn: (item) => any) => {
    const grouped = this.groupArraysByFn(array, fn);
    const groupedSingle = {};
    Object.entries(grouped).forEach(([key, innerArray]) => {
      if (innerArray.length > 0) {
        const firstElement = innerArray[0];
        const key = fn(firstElement);
        groupedSingle[key] = firstElement;
      }
    });
    return groupedSingle;
  };

  deepEqualMaps(map1: Map<any, any>, map2: Map<any, any>) {
    const obj1 = this.mapToPlainObject(map1);
    const obj2 = this.mapToPlainObject(map2);

    return this.deepEqual(obj1, obj2);
  }

  deepEqual = (a, b) => {
    const isEqual = globalUtilsHelper.deepEqual(a, b);
    return isEqual;
  };

  cloneWithoutNulls<T>(model: T): T {
    if (typeof model === 'undefined' || model === null) {
      return model;
    }
    const cloned = this.clone(model);
    Object.keys(cloned).forEach((key) => {
      if (typeof cloned[key] === 'undefined' || cloned[key] === null) {
        delete cloned[key];
      }
    });
    return cloned;
  }

  /**
   * Convert an ES6 Map to a plain JS object.
   */
  mapToPlainObject = globalUtilsHelper.mapToPlainObject;

  mapToPlainObjectRecursive = globalUtilsHelper.mapToPlainObjectRecursive;

  /**
   * Sorts an array of object by a selected property
   */
  sortObjectArray(
    array: any[],
    sortByProperty: string,
    direction: 'ascending' | 'descending' = 'ascending'
  ): any[] {
    if (direction === 'ascending') {
      return array.sort((firstItem, secondItem) =>
        firstItem[sortByProperty].localeCompare(secondItem[sortByProperty])
      );
    } else {
      return array.sort((firstItem, secondItem) =>
        secondItem[sortByProperty].localeCompare(firstItem[sortByProperty])
      );
    }
  }

  /**
   * Combine two arrays without repeated elements
   */
  getCombinedArrays(firstArray: any[], secondArray: any[]): any[] {
    return firstArray.concat(
      secondArray.filter((x) => firstArray.every((y) => !this.deepEqual(y, x)))
    );
  }

  getCombinedArraysByProperty<T>(firstArray: T[], secondArray: T[], propFn: (item: T) => any): T[] {
    return firstArray.concat(
      secondArray.filter((x) => firstArray.every((y) => propFn(x) !== propFn(y)))
    );
  }

  joinMaps<T1, T2>(maps: Map<T1, T2>[]): Map<T1, T2> {
    const joined = maps.reduce((accum, current) => accum.concat([...current]), []);
    const joinedInMap = new Map<T1, T2>(joined);
    return joinedInMap;
  }

  /**
   * If useInstanceClone is false, instance of classes will be copied as reference instead of deep cloning.
   * This was the default behavior, and changing it to default true could potentially break the application.
   * If true, the copy is always done in strict mode (which is not always possible).
   */
  clone<T>(obj: T, useInstanceClone = false): T {
    return globalUtilsHelper.clone(obj, useInstanceClone);
  }

  serializedClone<T>(obj: T): T {
    if (obj === null || typeof obj === 'undefined') {
      return obj;
    }
    return globalUtilsHelper.serializedClone(obj);
  }

  // TODO: look for a optimized library
  cloneMerge<T>(obj: T, newProps: { [key: string]: any }): T {
    return {
      ...this.clone(obj),
      ...this.clone(newProps),
    };
  }

  cloneOnlyKeys<T>(data: T, keysToClone: string[]): T {
    const cloned = this.clone<T>(data);
    Object.keys(cloned).forEach((key) => {
      if (!keysToClone.find((k) => key === k)) {
        delete cloned[key];
      }
    });
    return cloned;
  }

  /**
   * Applies a function recursively inside an object / array.
   */
  applyRecursive(data, applyFn: (item, path: string) => void): void {
    this.applyRecursiveFn(data, '', applyFn);
  }

  deepDiff = diff;

  /**
   * lower-capitalized all the keys that are at the top level of an object.
   */
  lowerCapitalizeKeys(obj: any): any {
    if (!obj) {
      return obj;
    }

    const mappedObj = {};
    for (let key in obj) {
      const lowecaseKey = this.lowerFirstLetter(key);
      mappedObj[lowecaseKey] = this.clone(obj[key]);
    }

    return mappedObj;
  }

  lowerCapitalizeKeysDeep = (object) => {
    if (!object) {
      return object;
    }
    return Object.keys(object).reduce((acc, key) => {
      let val = object[key];
      if (typeof val === 'object') {
        val = this.lowerCapitalizeKeysDeep(val);
      }
      const lowecaseKey = this.lowerFirstLetter(key);
      acc[lowecaseKey] = val;
      return acc;
    }, {});
  };

  lowerFirstLetter(str: string) {
    return str.length > 1 ? `${str.charAt(0).toLowerCase()}${str.slice(1)}` : str.toLowerCase();
  }

  private applyRecursiveFn(data, path: string, applyFn: (item, path: string) => void): void {
    const isObject = typeof data === 'object' && !this.isDateObject(data);

    if (!data) {
      return;
    }

    if (isObject) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          const isArray = Array.isArray(data);
          let newPath = isArray ? `${path}[${property}]` : `${path}.${property}`;
          newPath = newPath.startsWith('.') ? newPath.substring(1) : newPath;
          this.applyRecursiveFn(data[property], newPath, applyFn);
        }
      }
    } else {
      applyFn(data, path);
    }
  }

  /**
   * Needed to break recursive dependency with DatesHelperService.
   */
  private isDateObject = (date) =>
    date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}
