<div id="center-page">
  <div class="top-button-container">
    <button
      mat-icon-button
      [matTooltip]="T_SCOPE + '.reset-tooltip' | translate | lowercase"
      color="primary"
      (click)="restore()"
    >
      <wlm-icon [icon]="'reset-layout'"></wlm-icon>
    </button>
  </div>
  <mat-form-field class="form-field custom-chip-inline" appearance="outline">
    <mat-label>{{ T_SCOPE + '.profile-name-field' | translate }}</mat-label>
    <mat-chip-grid
      #chipList
      class="chip-list"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
    >
      <mat-chip-row
        class="chip"
        cdkDrag
        *ngFor="let alarmSegment of alarmSegments"
        [removable]="true"
        (removed)="remove(alarmSegment)"
      >
        {{ alarmSegment.fieldNameKey | translate }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        #suffixInput
        autocomplete="off"
        [attr.maxlength]="maxInputLength"
        [disabled]="disableInput"
        [placeholder]="placeHolderText | translate"
        [matChipInputFor]="chipList"
        (matChipInputTokenEnd)="addKeywordFromInput($event)"
      />
    </mat-chip-grid>
  </mat-form-field>

  <p>
    {{ selectedFormatLabelKey | translate }}:
    <span *ngFor="let alarmSegment of alarmSegments; index as i">
      <strong
        ><span>{{ alarmSegment.fieldNameKey | translate }}</span
        ><span *ngIf="i < alarmSegments.length - 1">&nbsp;-&nbsp;</span></strong
      >
    </span>
  </p>

  <wlm-alarms-subscription
    (subscriptionChange)="onSubscriptionChange($event)"
  ></wlm-alarms-subscription>
</div>
