import { Injectable } from '@angular/core';
import { BasicFilter } from '../../shared/filters/component-filters/basic-filter';
import { DateRange } from '../../shared/model/date/date-range';
import { FilterAdapterSettings } from './filter-adapter-settings';
import { FilterAdapterEnum } from './filter-adapter.enum';
import { FiltersPayload } from './filters-payload';
import { ActivityCategoryTypesFilter } from './i-filters/activity-category-types-filter';
import { ActivityStatusFilter } from './i-filters/activity-status-filter';
import { AlgorithmFilter } from './i-filters/algorithm-filter';
import { ElementTypeFilter } from './i-filters/element-type-filter';
import { HierarchyElementPathFilter } from './i-filters/hierarchy-element-path-filter';
import { HierarchyFamilyFilter } from './i-filters/hierarchy-family-filter';

/**
 * Encapsulates the logic that translates the filters from the standard BasicFilter format that
 * BaseFilterComponent handles to each specific filter previously used by the application.
 */

@Injectable()
export class FiltersAdapterService {
  // Helps to handle when a date range has the same field name in both fields.
  private dateRangeConfig = {
    startHelperKey: 'StartDate',
    endHelperKey: 'EndDate',
    divider: '#',
  };

  apply(settings: FilterAdapterSettings, filters: FiltersPayload) {
    switch (settings.id) {
      case FilterAdapterEnum.DateRange:
        this.dateRangeAdapterConfigured(settings, filters);
    }
  }

  dateRangeAdapterConfigured(settings: FilterAdapterSettings, filters: FiltersPayload): void {}

  /**
   * Handles the case where the StartDate and EndDate field are the same (previously managed with convertDateRangeToBasicFilters).
   * If both are "MyDate", then the keys are converted to "MyDate#StartDate" and "MyDate#EndDate".
   */
  dateRangeAdapter(
    filters: Map<string, BasicFilter>,
    startDateFieldName: string,
    endDateFieldName: string,
    inclusive?: boolean,
    allowsNullDate?: boolean
  ): DateRange {
    const sameKeys = startDateFieldName === endDateFieldName;
    const { divider, startHelperKey, endHelperKey } = this.dateRangeConfig;
    const startKey = startDateFieldName + (sameKeys ? `${divider}${startHelperKey}` : '');
    const endKey = endDateFieldName + (sameKeys ? `${divider}${endHelperKey}` : '');

    const startDateFilter = filters.get(startKey);
    const endDateFilter = filters.get(endKey);

    if (startDateFilter && endDateFilter) {
      const dateRange = new DateRange(
        startDateFilter?.value,
        endDateFilter?.value,
        startDateFieldName,
        endDateFieldName,
        allowsNullDate,
        inclusive
      );
      return dateRange;
    }

    return null;
  }

  activityCategoryTypesAdapter(
    filters: Map<string, BasicFilter>,
    repairFieldName: string,
    detectionFieldName: string
  ): ActivityCategoryTypesFilter {
    const repairFilter = filters.get(repairFieldName);
    const detectionFilter = filters.get(detectionFieldName);
    if (repairFilter && detectionFilter) {
      const actCategoryTypesFilter = new ActivityCategoryTypesFilter(
        repairFieldName,
        detectionFieldName
      );
      actCategoryTypesFilter.includeRepair = repairFilter?.value;
      actCategoryTypesFilter.includeDetection = detectionFilter?.value;
      return actCategoryTypesFilter;
    }
    return null;
  }

  activityOnlyOpenAdapter(
    filters: Map<string, BasicFilter>,
    activityStatusFieldName: string
  ): ActivityStatusFilter {
    const actStatusFilter = filters.get(activityStatusFieldName);
    if (actStatusFilter) {
      if (actStatusFilter.value) {
        const openIsSetted = actStatusFilter.value?.some((x) => x.value === 1);
        const closedIsSetted = actStatusFilter.value?.some((x) => x.value === 0);

        const result = new ActivityStatusFilter(
          [openIsSetted, closedIsSetted],
          activityStatusFieldName
        );
        return result;
      }
    }
    return null;
  }

  /**
   * Combines the family filter and the tree filter.
   */
  treeFilterAdapter(
    filters: Map<string, BasicFilter>,
    hierarchyElementFamilyFieldName: string,
    hierarchyElementIdFieldName: string,
    familyId: any,
    familyFilterNotIncluded = false
  ): HierarchyElementPathFilter {
    const heFamilyFilter = filters.get(hierarchyElementFamilyFieldName);
    const heElementFilter = filters.get(hierarchyElementIdFieldName);
    if (
      heElementFilter &&
      (familyFilterNotIncluded || (!familyFilterNotIncluded && heFamilyFilter))
    ) {
      const result = new HierarchyElementPathFilter(
        familyId,
        heElementFilter.value,
        heFamilyFilter?.fieldName ?? hierarchyElementFamilyFieldName,
        heElementFilter.fieldName
      );
      return result;
    }
    return null;
  }

  hierarchyFamilyAdapter(
    filters: Map<string, BasicFilter>,
    hfFieldName: string
  ): HierarchyFamilyFilter {
    const familyFilter = filters.get(hfFieldName);
    if (familyFilter) {
      // Convert from BasicFilter to HierarchyFamilyFilter.
      // This filter will always contain one value at most, but returns an array for convention.
      const familyId = this.getArraySingleValue(familyFilter);
      const result = new HierarchyFamilyFilter(familyFilter.fieldName, familyId);
      return result;
    }
    return null;
  }

  networkElementHierarchyElementAdapter(
    filters: Map<string, BasicFilter>,
    elementTypeFieldName: any
  ): ElementTypeFilter {
    const neheFilter = filters.get(elementTypeFieldName);
    if (neheFilter) {
      // Convert from BasicFilter to ElementTypeFilter.
      const selectedIds = neheFilter.value ? neheFilter.value.map((item) => item.value) : [];
      const result = new ElementTypeFilter(neheFilter.fieldName, selectedIds);
      return result;
    }
    return null;
  }

  /**
   * Converts a DateRange filter payload to Filter Items. It considers the case when the dateFields are the same.
   * In this case, as each filter is later keyed in a Map, we differentiate the keys.
   * The user must later use the dateRangeAdapter method to undo this.
   */
  convertDateRangeToBasicFilters(range: DateRange): BasicFilter[] {
    let filters;
    if (range.startFieldName === range.endFieldName) {
      const { divider, startHelperKey, endHelperKey } = this.dateRangeConfig;
      filters = [
        new BasicFilter(`${range.startFieldName}${divider}${startHelperKey}`, range.start),
        new BasicFilter(`${range.endFieldName}${divider}${endHelperKey}`, range.end),
      ];
    } else {
      filters = [
        new BasicFilter(range.startFieldName, range.start),
        new BasicFilter(range.endFieldName, range.end),
      ];
    }
    return filters;
  }

  /**
   * Helper method. Useful to extract the value of a filter with an array of one element.
   */
  getArraySingleValue(filter: BasicFilter): any {
    if (
      filter &&
      filter.value &&
      filter.value.length === 1 &&
      filter.value[0].value !== null &&
      typeof filter.value[0].value !== 'undefined'
    ) {
      return filter.value[0].value;
    }
    return null;
  }

  getFamilyId(filters: FiltersPayload, fieldName: string, defaultId: string): string {
    if (filters) {
      const heFamilyFilter = filters.data.get(fieldName);
      if (heFamilyFilter) {
        const familyId = this.getArraySingleValue(heFamilyFilter);
        return familyId ?? defaultId;
      } else {
        return defaultId;
      }
    }
  }

  algorithmFilterAdapter(
    filters: Map<string, BasicFilter>,
    algorithmShortNameFieldName: string
  ): AlgorithmFilter {
    const algorithmsFilter = filters.get(algorithmShortNameFieldName);
    if (algorithmsFilter) {
      const result = new AlgorithmFilter(algorithmsFilter.value, algorithmShortNameFieldName);
      return result;
    }
    return null;
  }
}
