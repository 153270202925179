import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetManagerService } from './services/widget-manager.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [WidgetManagerService],
})
export class WidgetManagerModule {}
