import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-date-duration-column';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-duration-column.component.html',
  styleUrls: ['./date-duration-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => DateDurationColumnComponent),
    },
  ],
})
export class DateDurationColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() startDateFieldName: string;
  @Input() endDateFieldName: string;

  constructor(private _dateHelper: DateHelperService) {
    super();
  }

  ngOnInit(): void {}
}
