import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AppModules } from '../../shared/app-modules.enum';
import { SelectedItem } from './selected-item';

const COMPONENT_SELECTOR = 'wlm-selection-menu';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './selection-menu.component.html',
  styleUrls: ['./selection-menu.component.scss'],
})
export class SelectionMenuComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() selectedItemsChanged = new EventEmitter<any[]>();

  @Input() itemLabelKey: string;
  @Input() showSelectedList: boolean;
  @Input() showSelectedItems: boolean;
  @Input() showSelectAllColumn: boolean;

  maxItemsWithoutScroll = 8;

  selectableItems: SelectedItem[];
  T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;

  private _selectedItems: any[] = [];
  public get selectedItems(): any[] {
    return this._selectedItems;
  }
  @Input() public set selectedItems(v: any[]) {
    this._selectedItems = v;
    this.setSelectedItems();
  }

  @Input() totalRows = 0;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Prevents any event from propagating.
   */
  stopPropagation($event): void {
    $event.stopPropagation();
  }

  trackBySelectedItem(item): string {
    return item[this.itemLabelKey];
  }

  applySelection() {
    const selectedItems = this.selectableItems?.filter((x) => x.isSelected).map((x) => x.item);
    this.selectedItemsChanged.emit(selectedItems);
    this.trigger.closeMenu();
  }

  removeSelection() {
    this.selectedItemsChanged.emit([]);
    this.trigger.closeMenu();
  }

  private setSelectedItems() {
    this.selectableItems = this.selectedItems?.map(
      (x) =>
        new SelectedItem({
          isSelected: true,
          item: x,
        })
    );
  }
}
