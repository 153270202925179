import { ChartSerieTypeEnum } from '../chart-serie-type.enum';
import { BaseGCartesianChartSeries } from './base-g-cartesian-chart-series';
import { GCartesianChartLabel } from './g-cartesian-chart-label';
import { GChartSerieDataPoint } from './g-chart-serie-data-point';
import { GChartSerieMarkPoint } from './g-chart-serie-mark-point';
import { TooltipFilterMethodEnum } from './tooltip-filter-method-enum';

/**
 * Represents series that can be displayed in a cartesian plane.
 * IMPORTANT: currently x/yAxisIndex are directly derived from the value of x/yAxisWLMDimensionTypeId.
 */

export class GCartesianChartSerie extends BaseGCartesianChartSeries {
  // Only charts that can be represented in cartesian axes can be selected.
  type: ChartSerieTypeEnum = ChartSerieTypeEnum.Line;
  dataPoints: GChartSerieDataPoint[];
  // Dimension type of the associated to dataPoints value field.
  xAxisWLMDimensionTypeId?: number;
  // Dimension type of the associated to dataPoints category field.
  yAxisWLMDimensionTypeId?: number;
  // Unit types of the associated to dataPoints value field.
  yAxisWLMUnitTypeIdTo?: number;
  yAxisWLMUnitTypeIdFrom?: number;
  // For some cases, we do not want to do any conversion or showing an unit type label, but a custom label instead.
  yAxisWLMUnitCustomLabel?: string;
  yAxisCustomDimension?: string;
  // These values are not directly mapped to the series, but extracted to the axes.
  xMin?: any;
  xMax?: any;
  largeData?: boolean;
  // Wether the date values must be shown in UTC.
  showInUtc? = false;
  // Not mapped data.
  additionalParams?: { [key: string]: any };
  symbol?: 'none';
  largeNumberOfPoints?: boolean;
  // Allows to specify the amount of decimals we want to show for the points.
  decimalPositions?: number;
  isCategorySerie?: boolean;
  showAlwaysInTooltip?: boolean;
  showAlwaysFilterMethod?: TooltipFilterMethodEnum;
  markPoints?: GChartSerieMarkPoint[];
  label?: GCartesianChartLabel;

  constructor(init: GCartesianChartSerie) {
    super(init);
    Object.assign(this, init);
  }
}
