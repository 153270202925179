<div class="header-container">
  <div>
    <div class="wtr-subheading">{{ layer?.networkElementTypeName }}</div>
  </div>
  <div class="buttons-container">
    <button
      mat-icon-button
      color="primary"
      [disabled]="!isValid"
      [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
      (click)="save()"
    >
      <wlm-icon [icon]="'save'"></wlm-icon>
    </button>
    <button
      wlmCollapsibleButton
      [tooltip]="'common.delete'"
      [disabled]="!isValid"
      (click)="onDeleteConfirm($event)"
    >
      <wlm-icon icon="delete"></wlm-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menuAddOptional" *ngIf="fields?.length">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menuAddOptional="matMenu">
      <button
        mat-menu-item
        *ngFor="let attribute of attributesOptional"
        (click)="toggleOptional(attribute)"
      >
        <mat-icon *ngIf="isOptionalAdded(attribute)" color="warn">remove</mat-icon>
        <mat-icon *ngIf="!isOptionalAdded(attribute)" color="accent">add</mat-icon>
        <span>{{ attribute.networkElementAttributeTypeName | sentence }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<form *ngIf="fields?.length" class="form-container" #form="ngForm">
  <mat-form-field class="field-stretch field-no-padding-bottom">
    <mat-label>GIS Key</mat-label>
    <mat-select name="gisKey" [(ngModel)]="gisKey">
      <mat-option *ngFor="let field of fields" [value]="field.field">
        {{ field.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field class="field-stretch field-no-padding-bottom">
    <mat-label>Layer Number</mat-label>
    <mat-select name="layerNumber" [(ngModel)]="layerNumber">
      <mat-option *ngFor="let layerNumber of layerNumbers" [value]="layerNumber">
        {{ layerNumber }}
      </mat-option>
    </mat-select>
  </mat-form-field> -->

  <hr *ngIf="attributesMandatory?.length" />

  <mat-form-field
    class="field-stretch field-no-padding-bottom"
    *ngFor="let option of attributesMandatory"
  >
    <mat-label>{{ option.networkElementAttributeTypeName | sentence }}</mat-label>
    <mat-select
      name="att-{{ option.networkElementAttributeTypeId }}"
      [(ngModel)]="attributeValues[option.networkElementAttributeTypeId.toString()]"
    >
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let field of fields" [value]="field.field">
        {{ field.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <hr *ngIf="attributesDefault?.length" />

  <mat-form-field
    class="field-stretch field-no-padding-bottom"
    *ngFor="let option of attributesDefault"
  >
    <mat-label>{{ option.networkElementAttributeTypeName | sentence }}</mat-label>
    <mat-select
      name="att-{{ option.networkElementAttributeTypeId }}"
      [(ngModel)]="attributeValues[option.networkElementAttributeTypeId.toString()]"
    >
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let field of fields" [value]="field.field">
        {{ field.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <hr *ngIf="attributesOptionalAdded?.length" />

  <div *ngFor="let option of attributesOptionalAdded" class="extra-attributes">
    <mat-form-field>
      <mat-label>{{ option.networkElementAttributeTypeName | sentence }}</mat-label>
      <mat-select
        name="att-{{ option.networkElementAttributeTypeId }}"
        [(ngModel)]="attributeValues[option.networkElementAttributeTypeId.toString()]"
      >
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let field of fields" [value]="field.field">
          {{ field.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        name="att-{{ option.networkElementAttributeTypeId }}-description"
        matInput
        placeholder="Description"
        [(ngModel)]="
          attributeValues[option.networkElementAttributeTypeId.toString() + descriptionAttribute]
        "
      />
    </mat-form-field>
  </div>
</form>
