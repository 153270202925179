import { EventViewCategories } from 'src/app/common-modules/wlm-charts/core/events-chart/models/event-view-categories';

export interface IEventsControlSettings {
  categories: EventViewCategories[];
  showLegend: boolean;
  timelineWidth: number;
}

export class EventsControlSettings implements IEventsControlSettings {
  categories: EventViewCategories[];
  showLegend: boolean;
  timelineWidth: number;

  constructor(init: Partial<EventsControlSettings>) {
    Object.assign(this, init);
  }
}
