import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from "@angular/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from "@angular/material/core";
import { MatCalendar } from "@angular/material/datepicker";

import * as _moment from "moment";
import { default as _rollupMoment } from "moment";

import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MMM",
  },
  display: {
    dateInput: "DD/MMM",
    monthYearLabel: "MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@UntilDestroy()
@Component({
  selector: "wlm-day-month-filter",
  templateUrl: "./day-month-filter.component.html",
  styleUrls: ["./day-month-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DayMonthFilterComponent),
      multi: true,
    },
  ],
})
export class DayMonthFilterComponent implements OnInit, ControlValueAccessor {
  @Input() titleKey: string;

  private _errorMessageKey: string;
  get errorMessageKey(): string {
    return this._errorMessageKey;
  }
  @Input() set errorMessageKey(value: string) {
    this._errorMessageKey = value;
    const error = value ? { dateError: true } : null;
    this.date.setErrors(error);
    this.date.updateValueAndValidity();
  }
  isDisabled = false;
  dayMonthHeader = DayMonthHeader;
  date = new FormControl({ value: _moment.utc(), disabled: false });

  errorMessage: string;

  private nativeOnChange: (value: Date) => void;
  private nativeOnTouched: (value: Date) => void;

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.nativeOnTouched = fn;

    const currentValue = this.date.value;
    if (currentValue) {
      this.nativeOnChange(currentValue?.toDate());
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.isDisabled = isDisabled;
    }
  }

  setError(errorMessage: string): void {
    this.errorMessage = errorMessage;
  }

  ngOnInit(): void {
    this.date.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      if (this.nativeOnChange) {
        this.nativeOnChange(values?.toDate());
      }
    });
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: "day-month-header",
  styles: [
    `
      .day-month-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .day-month-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }

      .day-month-double-arrow .mat-icon {
        margin: -22%;
      }
    `,
  ],
  template: `
    <div class="day-month-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="day-month-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayMonthHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      -1
    );
  }

  nextClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      1
    );
  }
}
