import { Injectable } from '@angular/core';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { LogService } from '../wlm-log/log.service';

@Injectable({
  providedIn: 'root',
})
export class ArrayHelperService {
  constructor(private _logService: LogService) {}

  areSame<T>(array1: T[], array2: T[]): boolean {
    const array1Length = array1?.length ?? 0;
    const array2Length = array2?.length ?? 0;
    if (array1Length !== array2Length) {
      return false;
    }

    if (array1Length === array2Length && array1Length === 0) {
      return true;
    }

    if (array1Length === 0 || array2Length === 0) {
      return false;
    }

    return (
      array1.filter((x) => array2.includes(x)).length === array2.length &&
      array2.filter((x) => array1.includes(x)).length === array1.length
    );
  }

  areSameByProperty<T>(array1: T[], array2: T[], selectProperty: (item: T) => any): boolean {
    const array1Length = array1?.length ?? 0;
    const array2Length = array2?.length ?? 0;
    if (array1Length !== array2Length) {
      return false;
    }

    if (array1Length === array2Length && array1Length === 0) {
      return true;
    }

    if (array1Length === 0 || array2Length === 0) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      const prop1 = selectProperty(array1[i]);
      const prop2 = selectProperty(array2[i]);

      if (prop1 !== prop2) {
        return false;
      }
    }

    return true;
  }

  /**
   * Given an array and a percentage, get the index corresponding to that percentage.
   */
  indexFromPercentage(array: any[], percentage: number): number {
    if (!array || percentage < 0 || percentage > 100) {
      this._logService.error({ msg: 'Invalid usage of indexFromPercentage.' });
      return null;
    }
    const actualIndex = Math.floor((array.length - 1) * percentage);
    return actualIndex;
  }

  sortArrayObjectAscending(array: any[], propertyName: string): any[] {
    return array?.sort((a, b) => 0 - (a[propertyName] > b[propertyName] ? -1 : 1));
  }

  sortObjects<T>(array: T[], selectProperty: (item: T) => any, ascending = true): T[] {
    const factor = ascending ? 1 : -1;

    return array?.sort((a, b) => {
      if (typeof selectProperty(a) === 'number' && typeof selectProperty(b) === 'number') {
        return (selectProperty(a) - selectProperty(b)) * factor;
      }
      if (typeof selectProperty(a) === 'string' && typeof selectProperty(b) === 'string') {
        return ascending
          ? 0 - (selectProperty(a) > selectProperty(b) ? -1 : 1)
          : 0 - (selectProperty(a) < selectProperty(b) ? -1 : 1);
      }
      throw new Error('Array properties do not have the same type.');
    });
  }

  sortArrayObjectCaseInsensitive(array: any[], propertyName: string, ascending = true): any[] {
    return ascending
      ? array?.sort(
          (a, b) =>
            0 -
            ((a[propertyName] ?? '').toLowerCase() > (b[propertyName] ?? '').toLowerCase() ? -1 : 1)
        )
      : array?.sort(
          (a, b) =>
            0 -
            ((a[propertyName] ?? '').toLowerCase() < (b[propertyName] ?? '').toLowerCase() ? -1 : 1)
        );
  }

  sortArrayObjectDescending(array: any[], propertyName: string): any[] {
    return array?.sort((a, b) => 0 - (a[propertyName] > b[propertyName] ? 1 : -1));
  }

  /**
   * Remove duplicated elements.
   * CAUTION: Does not perform deep comparison, it should only be used with primitives.
   */
  onlyUnique = (array: any[]): any[] => {
    if (!array) {
      this._logService.error({ msg: 'Invalid usage of onlyUnique.' });
      return array;
    }
    return array.filter((value, index, self) => self.indexOf(value) === index);
  };

  onlyUniqueByFn<T>(array: T[], equalityFn: (a: T, b: T) => boolean): T[] {
    if (!array) {
      this._logService.error({ msg: 'Invalid usage of onlyUniqueByFn.' });
      return array;
    }
    return array.filter(
      (valueA, index, self) => self.findIndex((valueB) => equalityFn(valueA, valueB)) === index
    );
  }

  /**
   * Get only unique dates.
   */
  onlyUniqueDates = (array: Date[]): Date[] => {
    return this.onlyUniqueByFn(
      array,
      (date1: Date, date2: Date) => date1.getTime() === date2.getTime()
    );
  };

  getDuplicated = <T>(array: T[]): T[] => {
    // [ 'A', 'A', 'B']
    if (!array || !Array.isArray(array)) {
      this._logService.error({ msg: 'Invalid usage of getDuplicated.' });
      return array;
    }
    // [ 'A', 'A']
    const duplicatedValues = array.filter((value, index, self) => self.indexOf(value) !== index);
    // [ 'A' ]
    return this.onlyUnique(duplicatedValues);
  };

  maxValue(arr: number[] | Date[]): number | Date {
    if (!arr || !arr.length) {
      return null;
    }
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (max < arr[i]) {
        max = arr[i];
      }
    }
    return max;
  }

  minValue(arr: number[] | Date[]): number | Date {
    if (!arr || !arr.length) {
      return null;
    }
    let min = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (min > arr[i]) {
        min = arr[i];
      }
    }
    return min;
  }

  /**
   * Get the difference between two arrays, without considering the position of the elements.
   */
  arrayDiff<T>(array1: T[], array2: T[]): T[] {
    const diff = new Set<T>();

    array1.forEach((item1) => diff.add(item1));

    array2.forEach((item2) => {
      if (diff.has(item2)) {
        diff.delete(item2);
      } else {
        diff.add(item2);
      }
    });

    return [...diff];
  }

  findLastIndex = (arr, fn) =>
    (arr
      .map((val, i) => [i, val])
      .filter(([i, val]) => fn(val, i, arr))
      .pop() || [-1])[0];

  getDuplicatedItems = <T>(array1: T[], array2: T[]): T[] => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      this._logService.error({ msg: 'Invalid usage of getDuplicatedItems.' });
      return null;
    }

    const duplicatedItems: T[] = [];

    array1.forEach((a) => {
      if (array2.some((b) => globalUtilsHelper.deepEqual(a, b))) {
        duplicatedItems.push(a);
      }
    });

    return duplicatedItems;
  };

  getDuplicatedItemsByProperty = <T>(
    array1: T[],
    array2: T[],
    selectProperty: (item: T) => any
  ): T[] => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      this._logService.error({ msg: 'Invalid usage of getDuplicatedItemsByProperty.' });
      return null;
    }

    const duplicatedItems: T[] = [];

    array1.forEach((a) => {
      if (array2.some((b) => selectProperty(a) === selectProperty(b))) {
        duplicatedItems.push(a);
      }
    });

    return duplicatedItems;
  };
}
