import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { AuthenticationService } from '../../../../common-modules/shared/auth/services/authentication.service';
import { NotificationSelectionService } from '../../../../common-modules/shared/notifications/notification-selection.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ACKAlarmsDto } from 'src/app/common-modules/dependencies/alarms/ack-alarms.dto';
import { AlarmsActiveDto } from 'src/app/common-modules/dependencies/alarms/alarms-active.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { AlarmAckPopupDimensions } from '../../../../common-modules/shared/constants/dimensions.constants';
import { AlarmsTabFilter } from '../../shared/alarms/alarms-tab-filter';
import { AckAlarmPopupComponent } from './ack-alarm-popup/ack-alarm-popup.component';
import { ActiveAlarmsGridService } from './active-alarms.service';

const COMPONENT_SELECTOR = 'wlm-active-alarms-grid';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './active-alarms-grid.component.html',
  styleUrls: ['./active-alarms-grid.component.scss'],
})
export class ActiveAlarmsGridComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) alarmsGrid: GenericGridComponent;

  hierarchyElementId: string;
  networkElementId: string;
  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;
  gridSettings: IGridSettings;
  filters: DataBindingFilters;
  disableAck = true;
  disableUndoAck = true;
  isReady = false;
  removeSelection$ = new ReplaySubject<void>();
  gridFiltersMap: Map<string, any> = new Map<string, any>();
  alreadyChanged = false;
  isNetworkElement = false;

  public get elementId(): string {
    return this.isNetworkElement ? this.networkElementId : this.hierarchyElementId;
  }

  private _selectedAlarms: AlarmsActiveDto[];
  public get selectedAlarms(): AlarmsActiveDto[] {
    return this._selectedAlarms;
  }
  public set selectedAlarms(value: AlarmsActiveDto[]) {
    this._selectedAlarms = value;
    this._notificationSelectionService.setAlarmsSelected(
      value.map((alarm) => alarm.alarmTypeDescription)
    );

    this.disableAck = value?.length === 0 || value?.every((x) => x.isAcknowledge);
    this.disableUndoAck = value?.length === 0 || value?.every((x) => !x.isAcknowledge);
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gridSettingsService: GridSettingsService,
    private readonly _ackAlarmDialog: MatDialog,
    private readonly _dialogService: DialogService,
    private readonly _activeAlarmsGridService: ActiveAlarmsGridService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _cacheService: HttpCacheService,
    private readonly _notificationSelectionService: NotificationSelectionService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _rightPanelService: RightPanelService
  ) {
    super(injector, widgetSettings);
  }

  ngOnDestroy(): void {
    this._notificationSelectionService.setAlarmsSelected(null);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getGridSettings();

    this.networkElementId = null;
    this.hierarchyElementId = null;
  }

  onTabFilter(filter: AlarmsTabFilter) {
    this._toggleFilterField('AlarmClassId', filter.alarmClassId);
    this._toggleFilterField('IsAcknowledge', filter.isAcknowledge);

    if (this.alarmsGrid) {
      this.alarmsGrid.gridFiltersForBinding = this.filters;
      this.alarmsGrid.reloadGrid();
    }
  }

  /**
   * Add or delete a value to a filter, depending if it is null or not.
   */
  private _toggleFilterField(keyName: string, value: any): void {
    if (value !== null) {
      this.filters.addOrUpdateBasicFilter(keyName, value);
    } else {
      this.filters.filters.delete(keyName);
    }
  }

  private disableAckButtons(value: boolean = true): void {
    this.disableAck = value;
    this.disableUndoAck = value;
  }

  getGridSettings() {
    this._gridSettingsService.getGridSettingsByName('Alarms').subscribe({
      next: (setting) => {
        this.gridSettings = setting;
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.removeSelection$.next();
    this.isReady = false;
    this.hierarchyElementId = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
    this.networkElementId = parameters.parameters.get(TabDetailParameterName.networkElementId);
    this.isNetworkElement = !this.hierarchyElementId;

    //When using networkElementId, the grid must hide the 'zone' column
    const processedSetting = this.changeColumnsVisibility(
      this.gridSettings,
      ['zone'],
      !this.isNetworkElement
    );
    this.gridSettings = processedSetting;
  }

  init(): void {
    this._setDataBidingFilters();
  }

  private _setDataBidingFilters() {
    if (this.hierarchyElementId) {
      const filter = new DataBindingFilters();
      this.gridFiltersMap = new Map<string, any>();
      filter.addOrUpdateBasicFilter('hierarchyElementId', this.hierarchyElementId);
      this.filters = filter;
    }

    if (this.networkElementId) {
      this.gridFiltersMap.set('networkElementId', this.networkElementId);
      this.filters = new DataBindingFilters();
      this.alarmsGrid?.reloadGrid();
    }
  }

  onAckClicked($event: MouseEvent, isAck: boolean) {
    if (isAck) {
      this.launchAckPopup();
    } else {
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.confirm-undo-ack`,
      });

      this._dialogService
        .showTranslatedDialogMessage(dialogSettings)
        .subscribe((dialogRef: WLMDialogResult) => {
          if (dialogRef.result) {
            this.processUndoAck();
          }
        });
    }
  }

  private changeColumnsVisibility(
    setting: IGridSettings,
    fieldsToHide: string[],
    visible = false
  ): IGridSettings {
    if (!fieldsToHide?.length) {
      return setting;
    }

    const newSetting = this._objectHelperService.clone(setting);
    if (!newSetting) {
      return setting;
    }

    fieldsToHide.forEach((field) => {
      newSetting.gridColumnSettings.find((f) => f.field === field).visible = visible;
    });

    return newSetting;
  }

  private processUndoAck() {
    const ackAlarms: ACKAlarmsDto = {
      alarmIds: this.selectedAlarms.filter((x) => x.isAcknowledge).map((x) => x.alarmId),
      userCode: this._authenticationService?.userCode?.split('@')[0],
      isACK: false,
      commentId: null,
      ackTime: null,
    };
    this.isReady = false;
    this._activeAlarmsGridService.setAckAlarms(ackAlarms).subscribe({
      next: (value) => {
        if (!value) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.unack-save-error` })
          );
          this.isReady = true;
        } else {
          this._cacheService.clearContainsInUrl('alarm').then(() => {
            this._activeAlarmsGridService.setRefreshAlarmCounters();
            this.removeSelection$.next();
            this.isReady = true;
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.undoack-save-success` })
            );
            this.selectedAlarms.forEach((x) => (x.isAcknowledge = false));
            this.disableAckButtons();
            this.alarmsGrid?.reloadGrid();

            const map = new Map<any, string>();
            this._rightPanelService.rightPanelCallback.next(
              map.set(ActiveAlarmsGridComponent, 'refresh')
            );
          });
        }
      },
    });
  }

  private launchAckPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = AlarmAckPopupDimensions.Width;
    dialogConfig.height = AlarmAckPopupDimensions.Height;
    dialogConfig.data = this.selectedAlarms.filter((x) => !x.isAcknowledge).map((x) => x.alarmId);
    const popup = this._ackAlarmDialog.open(AckAlarmPopupComponent, dialogConfig);
    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this._activeAlarmsGridService.setRefreshAlarmCounters();
          this.removeSelection$.next();
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.ack-save-success` })
          );
          this.selectedAlarms.forEach((x) => (x.isAcknowledge = true));
          this.disableAckButtons();
          this.alarmsGrid?.reloadGrid();
          const map = new Map<any, string>();
          map.set(ActiveAlarmsGridComponent, 'refresh');
          this._rightPanelService.rightPanelCallback.next(map);
        }
      });
  }

  get componentName() {
    return 'ActiveAlarmsGridComponent';
  }

  onWidgetReady($event) {
    this.isReady = true;
  }

  onGridDataLoaded() {
    this.isReady = true;
  }
}
