export class SACalendarData {
  calendarIsWeek: boolean;
  calendarIsWeekend: boolean;
  calendars: { [key: string]: boolean };
  seasons: string[];

  constructor(init?: SACalendarData) {
    Object.assign(this, init);
  }
}
