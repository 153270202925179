import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CollapsibleSummaryContainerComponent } from './collapsible-summary-container.component';

@NgModule({
  declarations: [CollapsibleSummaryContainerComponent],
  exports: [CollapsibleSummaryContainerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
})
export class CollapsibleSummaryContainerModule {
  constructor() {}
}
