import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';

import { BiSaveTemplateDto } from '../../models/bi-save-template.dto';
import { CreateDashboardResultDto } from '../../models/create-template-result.dto';
import { BiService } from '../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-bi-save-template-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-save-template-form.component.html',
  styleUrls: ['./bi-save-template-form.component.scss'],
})
export class BiSaveTemplateFormComponent implements OnInit {
  @Output() isValid = new EventEmitter<boolean>();
  @Output() hasSaved = new EventEmitter<CreateDashboardResultDto>();
  @Output() isLoading = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  fieldAppearance = 'outline';
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;

  constructor(
    private _fb: UntypedFormBuilder,
    private _biService: BiService,
    private _dialogsService: DialogService,
    private _objectHelperService: ObjectHelperService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.isLoading.emit(false);
  }

  private buildForm(): void {
    const V = Validators;
    this.form = this._fb.group({
      templateName: [null, [V.required]],
      isSystemTemplate: [false, []],
      isHomepageDefault: [false, []],
      isDashboardDefault: [false, []],
    });

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
      this.isValid.emit(status === 'VALID');
    });
  }

  save(defaultModel: Partial<BiSaveTemplateDto>, initialTemplate: DynamicLayoutSettings): void {
    this.isLoading.emit(true);

    const template = this._objectHelperService.serializedClone(initialTemplate);

    template.title = this.form.get('templateName').value;

    if (this.form.valid) {
      const model: BiSaveTemplateDto = {
        ...defaultModel,
        ...this.form.getRawValue(),
        template: JSON.stringify(template),
        layoutArea: defaultModel.layoutArea,
        layoutKey: defaultModel.layoutKey,
        bidashboardTemplateId: null,
      };

      this._biService
        .createTemplate({ data: model })
        .pipe(finalize(() => this.isLoading.emit(false)))
        .subscribe({
          next: (createResult: CreateDashboardResultDto) => {
            this.showDialogResult('save-success', 'success');
            this.hasSaved.emit(createResult);
          },
          error: (error) => {
            this._dialogsService.showErrorMessage(error);
          },
        });
    }
  }

  private showDialogResult(messageKey: string, icon: 'success' | 'error') {
    const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey, icon });

    this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
