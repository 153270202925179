import { EstimationFunction } from '../data-validation/estimation-function';
import { BrushMode } from '../generic-chart-settings/g-chart-brush';
import { EditableChartDateRange } from './editable-chart-date-range';
import { EditableChartValueRange } from './editable-chart-value-range';

export class EditableChartSelection {
  selectionType: BrushMode.Horizontal | BrushMode.Vertical;
  ranges: EditableChartValueRange[] | EditableChartDateRange[];
  rangesConverted: EditableChartValueRange[] | EditableChartDateRange[];
  estimationFunction: EstimationFunction;
  formValues: { [key: string]: any };
  element?: unknown;

  constructor(init: Partial<EditableChartSelection>) {
    Object.assign(this, init);
  }
}
