import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LeaksService } from 'src/app/common-modules/dependencies/alc/services/alc.leaks.service';
import { TabDetailPanelParameters, TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { GridColumnHelperService } from 'src/app/common-modules/shared/helpers/grid-column-helper.service';
import { GridHelperService } from 'src/app/common-modules/shared/helpers/grid-helper.service';
import { SimpleColumn } from 'src/app/common-modules/shared/model/shared/simple-column';

const COMPONENT_SELECTOR = 'wlm-alc-leaks-parent-activity';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alc-leaks-parent-activity.component.html',
  styleUrl: './alc-leaks-parent-activity.component.scss'
})
export class AlcLeaksParentActivityComponent extends BaseWidgetComponent implements OnInit {

  selectedActivityId: string;
  processedRow: any;
  gridSettings: GridSetting;
  processedColumns: SimpleColumn[];
  loading = false;

  private readonly removeNonExportableColumns = true;
  private readonly removeNonNotificableColumns = true;


  get componentName(): string {
    return 'AlcLeaksParentActivityComponent';
  }

  constructor(readonly injector: Injector,
    private _leakService: LeaksService,
    private _gridHelperService: GridHelperService,
    private _gridColumnHelper: GridColumnHelperService,
    private readonly _gridService: GridSettingsService,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.selectedActivityId = parameters.parameters.get(TabDetailParameterName.activityId);
    this.processedColumns = [];

    if (this.selectedActivityId) {
      this.ProcessParentActivity();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._gridService.getGridSettingsByName('Activities').subscribe({
      next: (gridSettings) => {
        this.gridSettings = gridSettings;
        this._gridHelperService.preProcessRowDataValues(this.gridSettings, this.removeNonExportableColumns, this.removeNonNotificableColumns);
        this.gridSettings.gridColumnSettings = this.gridSettings.gridColumnSettings.filter(
          (x) => x.isNotificationDetail && x.visible
        );

      },
    });
  }

  init(): void {

  }

  private ProcessParentActivity() {
    this.loading = true;
    this._leakService.getParentActivity(this.selectedActivityId).pipe(untilDestroyed(this)).subscribe({
      next: (parent) => {
        if (parent) {
          this._gridColumnHelper
            .processRowDataValues(parent, this.gridSettings.gridColumnSettings)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: (simpleColumns) => {
                this.processedColumns = simpleColumns;
                this.loading = false;
              },
            });
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
}
