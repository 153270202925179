import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { ChartCustomLegendItem } from '../models/chart-custom-legend-item';

const COMPONENT_SELECTOR = 'wlm-chart-legend';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit {
  items$: Observable<ChartCustomLegendItem[]>;
  @Input() set items(values: ChartCustomLegendItem[]) {
    this.items$ = this.translateItems(values);
  }

  constructor(private localization: LocalizationHelperService) {}

  ngOnInit(): void {}

  private translateItems(items: ChartCustomLegendItem[]): Observable<ChartCustomLegendItem[]> {
    const obs$ = items.map((item) =>
      item.label ? of(item.label) : this.localization.get(item.labelKey)
    );
    return forkJoin(obs$).pipe(
      map((translated) => {
        const results = [...items];
        results.forEach((item, index) => {
          item.label = translated[index];
        });
        return results;
      })
    );
  }

  trackByItem(item: ChartCustomLegendItem): string {
    return item.labelKey ?? item.label;
  }
}
