import { Injectable, Injector } from '@angular/core';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { IHierarchyElementDto } from 'src/app/common-modules/dependencies/he/hierarchy-element.dto';

const API_BASE = '/api/merged-zones';

@Injectable({
  providedIn: 'root',
})
export class MergedZonesHeAvailableService extends GridODataService<IHierarchyElementDto> {
  constructor(injector: Injector) {
    super(injector, `${API_BASE}/available-he`);
  }

  protected mapResponse(
    response: PagedResultDto<IHierarchyElementDto>
  ): PagedResultDto<IHierarchyElementDto> {
    return response;
  }
}
