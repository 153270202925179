<div *ngIf="form && initialDateRange" [formGroup]="form" class="container">
  <div class="form-group config-types-group">
    <mat-radio-group class="radio-position" [formControlName]="configModeFieldName">
      <mat-radio-button
        color="primary"
        *ngFor="let configType of chartConfigTypes | keyvalue"
        [value]="configType.value"
        [checked]="configType.value === form.controls[configModeFieldName].value"
        [disabled]="isEditMode"
        onclick="this.blur()"
      >
        {{ T_SCOPE_CHARTS + '.chart-config-types.' + configType.key | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <hr class="separator" />

  <div class="form-group main">
    <div class="form-col">
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.name' | translate }}</mat-label>
        <input matInput [formControlName]="nameFieldName" />

        <mat-error *ngIf="form.get(nameFieldName)?.errors?.notUnique">{{
          T_SCOPE + '.validation.notUnique' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.description' | translate }}</mat-label>
        <input matInput [formControlName]="descriptionFieldName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.time-mode' | translate }}</mat-label>
        <mat-select required appearance="outline" [formControlName]="timeModeFieldName">
          <mat-option *ngFor="let mode of timeModes | keyvalue" [value]="mode.value">
            {{ T_SCOPE + '.labels.' + mode.key | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="checkDateMode(dateSelectModeEnum.Fixed)">
        <div class="form-row">
          <mat-radio-group class="radio-position" [formControlName]="isGenericDateFieldName">
            <mat-radio-button color="primary" [value]="false">
              {{ T_SCOPE + '.labels.current-dates' | translate }}
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="true">
              {{ T_SCOPE + '.labels.generic-dates' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="form.controls[isGenericDateFieldName].value" class="form-row date-range-row">
          <wlm-date-range-filter
            [dateRange]="initialDateRange"
            [displayHorizontal]="false"
            [disableStartDate]="false"
            [disableEndDate]="false"
            [resetEndDate$]="resetEndDate$"
            [resetStartDate$]="resetStartDate$"
            (dateRangeChanged)="onDateRangeChanged($event)"
            (hasError)="onDateRangeError($event)"
          ></wlm-date-range-filter>
        </div>
      </div>

      <div *ngIf="checkDateMode(dateSelectModeEnum.Rolling)" class="row hide-subscript">
        <div class="col-5">
          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.time-units' | translate }}</mat-label>
            <mat-select required appearance="outline" [formControlName]="timeUnitFieldName">
              <mat-option *ngFor="let unit of timeUnits | keyvalue" [value]="unit.value">
                {{ unit.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.offset' | translate }}</mat-label>
            <input matInput type="number" [formControlName]="offsetFieldName" autocomplete="off" />
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="remove-margin" appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.period' | translate }}</mat-label>
            <input matInput type="number" [formControlName]="periodFieldName" autocomplete="off" />
            <mat-error *ngIf="form.get(periodFieldName)?.errors?.maxPeriod">{{
              'common.validation.max-value' | translate : { max: form.get(offsetFieldName)?.value }
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="privacity-and-include-row">
        <div class="privacity-container">
          <div class="section title section-privacity">
            {{ T_SCOPE + '.labels.privacity' | translate }}
          </div>

          <mat-radio-group class="radio-position" [formControlName]="isPublicFieldName">
            <mat-radio-button color="primary" [value]="false">
              {{ T_SCOPE + '.labels.private' | translate }}
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="true">
              {{ T_SCOPE + '.labels.public' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="checkConfigMode(chartConfigTypeEnum.Template)" class="include-container">
          <div class="section title">
            {{ T_SCOPE + '.labels.include' | translate }}
          </div>

          <div class="include-checkboxes">
            <div class="include-checkboxes-column">
              <div class="subtitle">
                {{ T_SCOPE + '.labels.include-he' | translate }}
              </div>
              <span class="category" *ngFor="let category of signalHECategories | keyvalue">
                <mat-checkbox
                  class="custom-checkbox"
                  [formControlName]="category.value"
                  onclick="this.blur()"
                  >{{ T_SCOPE + '.labels.' + category.value | translate }}
                </mat-checkbox>
              </span>
            </div>
            <div class="include-checkboxes-column">
              <div class="subtitle">
                {{ T_SCOPE + '.labels.include-ne' | translate }}
              </div>
              <span class="category" *ngFor="let category of signalNECategories | keyvalue">
                <mat-checkbox
                  class="custom-checkbox"
                  [formControlName]="category.value"
                  onclick="this.blur()"
                  >{{ T_SCOPE + '.labels.' + category.value | translate }}
                </mat-checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-col">
      <div class="cart-group">
        <wlm-data-visualization-cart-group
          [title]="T_SCOPE + '.labels.algorithms' | translate"
          [items]="algorithms"
        >
          <ng-template let-rowDetail #card>
            <wlm-generic-card [item]="rowDetail" [settings]="algorithmCardSettings">
              <ng-template #right>
                <div class="card-template right">
                  <wlm-icon *ngIf="getIcon(rowDetail)" [icon]="getIcon(rowDetail)"></wlm-icon>
                </div>
              </ng-template>
            </wlm-generic-card>
          </ng-template>
        </wlm-data-visualization-cart-group>
      </div>
      <div *ngIf="checkConfigMode(chartConfigTypeEnum.Workspace)" class="cart-group">
        <wlm-data-visualization-cart-group
          [title]="T_SCOPE + '.labels.telemetry-points' | translate"
          [items]="points"
        >
          <ng-template let-rowDetail #card>
            <wlm-generic-card [item]="rowDetail" [settings]="pointCardSettings">
              <ng-template #right>
                <div class="card-template right">
                  <wlm-icon *ngIf="getIcon(rowDetail)" [icon]="getIcon(rowDetail)"></wlm-icon>
                </div>
              </ng-template>
            </wlm-generic-card>
          </ng-template>
        </wlm-data-visualization-cart-group>
      </div>
    </div>
  </div>
</div>
