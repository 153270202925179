<div id="center-page">
  <div *ngIf="userGridSetting" class="top-container">
    <h3>{{ T_SCOPE + '.title' | translate }}</h3>
    <div class="filter-buttons-container">
      <button
        mat-icon-button
        class="button-position mat-icon-no-overlay"
        [disabled]="!identityUsersGrid?.canClearFilters()"
        (click)="identityUsersGrid?.clearFilters()"
        [matTooltip]="'common.clear-filter' | translate | lowercase"
      >
        <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
      </button>
    </div>
  </div>
  <hr class="separator" />

  <div id="center-container" class="identity-popup-container">
    <wlm-generic-grid
      class="user-grid"
      *ngIf="gridFiltersForBinding"
      [additionalFilters]="additionalFilters"
      [gridSettings]="userGridSetting"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [(selectedItems)]="selectedUsers"
      [subscriptionTag]="userGridSettingsName"
      [showSelectedList]="true"
      [showSelectedItems]="false"
    ></wlm-generic-grid>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="saveIdentityUsers()"
        [disabled]="!selectedUsers?.length || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button [disabled]="isSaving" (click)="cancel()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </div>
</div>
