import { ISerieDataPointDto } from '../model/chart/serie-data-point.dto';
import { IFiltrableItemQueryDto } from '../model/filtrable-items/filtrable-item-query.dto';
import { IFiltrableItemDto } from '../model/filtrable-items/filtrable-item.dto';
import { FiltrableItemTypeEnum } from '../model/filtrable-items/types/filtrable-item-type-enum';
import { IFiltrableType } from '../model/filtrable-items/types/filtrable-type';
import { IEventChartQuery } from './model/events/event-chart-query';

export abstract class BaseFiltrableItemService {
  abstract type: FiltrableItemTypeEnum;

  abstract setDateRange(filtrableItem: IFiltrableItemDto, startDate: Date, endDate: Date): void;
  abstract getQuery(filtrableItem: IFiltrableItemDto): IFiltrableItemQueryDto;
  abstract getDataPoints(filtrableType: IFiltrableType, data: any): ISerieDataPointDto[];
  abstract getEventQuery(filtrableItem: IFiltrableItemDto): IEventChartQuery;
}
