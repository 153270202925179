import { KpiSettingValue } from './map-thematic-kpi-value';

export class MapThematicTooltipInfo {
  //The kpi category key used for translation in HTML
  categoryNameKey: string;
  //The kpi name key used for translation in HTML
  thematicNameKey: string;
  kpiSetting: KpiSettingValue;
  //The kpi key used to get the value from the properties object
  thematicKey: string;
  unitLabel: string;
  conversionFactor: number;

  constructor(init?: Readonly<MapThematicTooltipInfo>) {
    Object.assign(this, init);
  }
}
