import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { AlgorithmFiltrableItemDto } from '../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { SignalFiltrableItemDto } from '../../shared/model/filtrable-items/signal-filtrable-item.dto';

export class DataVisualizationCartSelectedWorkspace {
  dateRange: DateRange;
  points: SignalFiltrableItemDto[];
  algorithms: AlgorithmFiltrableItemDto[];

  constructor(init: Partial<DataVisualizationCartSelectedWorkspace>) {
    Object.assign(this, init);
  }
}
