import { EditableChartDateRange } from './editable-chart-date-range';
import { EditableChartValueRange } from './editable-chart-value-range';

export class EditableChartRangeUpdate {
  range: EditableChartValueRange[] | EditableChartDateRange[];
  sender: string;

  constructor(init: Partial<EditableChartRangeUpdate>) {
    Object.assign(this, init);
  }
}
