import { IActivityRaiseCmd } from '../shared/model/alc/activity-raise-cmd';
import { ActivityRaiseType } from '../shared/model/alc/activity-raise-type';

export class ActivityRaiseCmd implements IActivityRaiseCmd {
  type: ActivityRaiseType;
  activityTypeId: number;
  activityTypeName: string;
  street: string;
  district: string;
  city: string;
  attributes: { [key: string]: string };
  userName: string;
  date: Date;
  hierarchyElementId: string;
  hierarchyParentId: string;
  networkElementId: string;
  street2: string;
  houseNumber: string;
  latitude: number;
  longitude: number;
  propertyId: string;

  constructor(
    type: ActivityRaiseType,
    activityTypeId: number,
    activityTypeName: string,
    street: string,
    district: string,
    city: string,
    attributes: { [key: string]: string },
    userName: string,
    date: Date,
    hierarchyElementId: string,
    hierarchyParentId: string,
    networkElementId: string,
    street2: string,
    houseNumber: string,
    latitude: number,
    longitude: number,
    propertyId: string
  ) {
    this.type = type;
    this.activityTypeId = activityTypeId;
    this.activityTypeName = activityTypeName;
    this.street = street;
    this.district = district;
    this.city = city;
    this.attributes = attributes;
    this.userName = userName;
    this.date = date;
    this.hierarchyElementId = hierarchyElementId;
    this.hierarchyParentId = hierarchyParentId;
    this.networkElementId = networkElementId;
    this.street2 = street2;
    this.houseNumber = houseNumber;
    this.latitude = latitude;
    this.longitude = longitude;
    this.propertyId = propertyId;
  }
}
