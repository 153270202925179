import { Injectable, Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseService } from '../../shared/base.service';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { MVAlarmsQueryDto as ChartAlarmsQueryDto } from '../../shared/model/mv/mv-alarm-query.dto';
import { MVChartSerieDto } from '../../shared/model/mv/mv-chart-serie.dto';
import { IMVDto } from '../../shared/model/mv/mv-dto';
import { MVQueryDto } from '../../shared/model/mv/mv-query.dto';
import { MVThresholdDto } from '../../shared/model/mv/mv-threshold.dto';
import { UoMService } from '../../shared/uom/uom.service';

@Injectable({ providedIn: 'root' })
export class MVService extends BaseService {
  constructor(
    injector: Injector,
    private _dateHelper: DateHelperService,
    private readonly _uomService: UoMService,
    private readonly _utilsHelperService: UtilsHelperService
  ) {
    super(injector);
  }

  public getMV(query: MVQueryDto): Observable<IMVDto[]> {
    const result$ = this.httpCacheClient.post<IMVDto[]>(`${this.apiUrl}/mv`, query);

    const updatedDate$ = result$.pipe(
      map((x) => {
        x.forEach((y) => (y.measureDate = this.getDate(y)));
        return x;
      })
    );

    return updatedDate$;
  }

  public getChart(query: MVQueryDto): Observable<MVChartSerieDto[]> {
    const queryParams = {
      startDate: this._dateHelper.toApiFormat(query.startDate),
      endDate: this._dateHelper.toApiFormat(query.endDate),
      signalIds: query.signalIds,
    };
    const result$ = this.httpCacheClient.post<MVChartSerieDto[]>(
      `${this.apiUrl}/mv/chart`,
      queryParams
    );
    return result$;
  }

  public getShortConverted(query: MVQueryDto): Observable<IMVDto[]> {
    return this.getChart(query).pipe(
      switchMap((signalsSeries) => {
        if (!signalsSeries.length) {
          return of([]);
        }
        const signalsValuesConverted$: Observable<IMVDto[]>[] = [];
        signalsSeries.forEach((signalSerie) => {
          const signalValues$ = this._uomService.getBySignal(signalSerie.dimensionTypeId).pipe(
            take(1),
            map((conversion) => {
              const signalValues = signalSerie.points.map((value) => {
                const mvshort: IMVDto = {
                  signalId: signalSerie.signalId,
                  id: signalSerie.toString(),
                  pointDescription: signalSerie.pointDescription,
                  pointId: signalSerie.pointId,
                  arrivalDateTime: value.arrivalDateTime,
                  dimensionTypeId: signalSerie.dimensionTypeId,
                  estimatedEdited: value.estimatedEdited,
                  fieldQuality: value.fieldQuality,
                  measureDate: this._dateHelper.fromApiFormat(value.measureTimestamp),
                  measureTimestamp: value.measureTimestamp,
                  sourceSystemId: signalSerie.sourceSystemId,
                  trendTypeId: 1,
                  validity: value.validity,
                  value: +this._utilsHelperService.uomMultiply(
                    String(value.value),
                    String(conversion?.conversionFactor ?? 1)
                  ),
                  estimationFunctionId: value.estimationFunctionId,
                  // referenceTime: this._dateHelper.fromApiFormat(value.referenceTime),
                  validationFunctionId: value.validationFunctionId,
                  unitTypeId: conversion.unitTypeToId,
                };
                return mvshort;
              });

              return signalValues;
            })
          );
          signalsValuesConverted$.push(signalValues$);
        });

        return forkJoin(signalsValuesConverted$).pipe(
          map((signalValuesConverted) => {
            return asEnumerable(signalValuesConverted)
              .SelectMany((x) => x)
              .ToArray();
          })
        );
      })
    );
  }

  public getChartByAlarms(query: ChartAlarmsQueryDto): Observable<MVChartSerieDto[]> {
    const result$ = this.httpCacheClient.post<MVChartSerieDto[]>(
      `${this.apiUrl}/mv/chart/alarms`,
      query
    );
    return result$;
  }

  public getThresholds = (
    alarmTypeId: string,
    startDate: string,
    endDate: string
  ): Observable<MVThresholdDto[]> => {
    const query = {
      alarmTypeId,
      startDate,
      endDate,
    };
    return this.httpCacheClient.post(`${this.apiUrl}/mv/threshold`, query);
  };

  public update(signalId: number, measuredValues: IMVDto[]): Observable<boolean> {
    return this.httpCacheClient.post(`${this.apiUrl}/mv/${signalId}`, measuredValues);
  }

  private getDate(cv: IMVDto): Date {
    cv.measureTimestamp = `${cv.measureTimestamp}Z`;
    return new Date(cv.measureTimestamp);
  }
}
