import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MvEstimationsService } from 'src/app/common-modules/dependencies/mv/mv-estimations.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { EstimatedEditedEnum } from 'src/app/common-modules/shared/model/algorithm/estimated-edited.enum';
import { IMVDto } from 'src/app/common-modules/shared/model/mv/mv-dto';
import { ApplyMvEstimationResponse } from '../models/apply-mv-estimation-response';

@Injectable()
export class DataValidationEstimationService extends BaseService {
  private readonly _previousValueDays = 1;
  private readonly _previousWeekDays = 7;

  constructor(
    injector: Injector,
    private _mvEstimationsService: MvEstimationsService,
    private _dateHelperService: DateHelperService
  ) {
    super(injector);
  }

  applyFn(
    methodName: string,
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    extraProperties?: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    return this[methodName](measuredValues, localMvs, extraProperties);
  }

  fixed(
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    extraProperties: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    const { signalId, fixedValue, startDate, endDate } = extraProperties;

    if (!signalId || typeof fixedValue === 'undefined' || fixedValue === null) {
      return of(new ApplyMvEstimationResponse());
    }

    measuredValues.forEach((mv) => {
      mv.estimatedEdited = EstimatedEditedEnum.Edited;
      mv.value = fixedValue;
      mv.validity = 0;
      mv.validationFunctionId = null;
      mv.estimationFunctionId = null;
    });

    let isCompleted = true;
    if (startDate && endDate) {
      const measuredTimestamps = this.getMeasuredTimestampsFromRange(startDate, endDate);
      isCompleted =
        measuredTimestamps.length > 0 && measuredTimestamps.length === measuredValues.length;
    }

    return of(
      new ApplyMvEstimationResponse({
        measuredValues,
        isCompleted: isCompleted,
      })
    );
  }

  previousValue(
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    extraProperties?: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    return this.previousDate(measuredValues, localMvs, this._previousValueDays, extraProperties);
  }

  previousWeek(
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    extraProperties?: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    return this.previousDate(measuredValues, localMvs, this._previousWeekDays, extraProperties);
  }

  copySignal(
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    extraProperties?: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    const signalId = extraProperties?.signalId;
    if (!signalId) {
      return of(new ApplyMvEstimationResponse());
    }

    const request = {
      ...extraProperties,
      measuredValues: measuredValues,
      localMeasuredValues: localMvs,
    };

    return this._mvEstimationsService.copySignal(signalId, request);
  }

  private previousDate(
    measuredValues: IMVDto[],
    localMvs: IMVDto[],
    numberOfDays: number,
    extraProperties?: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    const signalId = extraProperties?.signalId;
    if (!signalId) {
      return of(new ApplyMvEstimationResponse());
    }

    const request = {
      ...extraProperties,
      numberOfDays: numberOfDays,
      measuredValues: measuredValues,
      localMeasuredValues: localMvs,
    };

    return this._mvEstimationsService.setPreviousValue(signalId, request);
  }

  private getMeasuredTimestampsFromRange(
    startDate: Date,
    endDate: Date,
    timeLapse: number = 15
  ): Date[] {
    // Round to nearest upper minute time lapse
    const adjustedStartDate = this._dateHelperService.roundDateToNearestUpperMinute(
      startDate,
      timeLapse
    );
    const adjustedEndDate = this._dateHelperService.roundDateToNearestUpperMinute(
      endDate,
      timeLapse
    );

    const measuredTimestamps: Date[] = [];
    for (
      let date = adjustedStartDate;
      date < adjustedEndDate;
      date = new Date(date.getTime() + timeLapse * 60000)
    ) {
      measuredTimestamps.push(date);
    }

    if (endDate.getTime() === adjustedEndDate.getTime()) {
      measuredTimestamps.push(endDate);
    }

    return measuredTimestamps;
  }
}
