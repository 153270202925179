<div *ngIf="form && modesOptions" class="hide-subscript custom-small-field fix-field-align-suffix">
  <div class="row remove-side-margins">
    <div class="col-1">
      <div class="env-label env-header env-mode">{{ T_SCOPE + '.mode' | translate }}</div>
    </div>
    <div class="col-5 main-fields main-fields-column">
      <mat-form-field class="mat-field mf-padding-small env-input" [appearance]="fieldAppearance">
        <mat-select [(ngModel)]="mode" (ngModelChange)="onModeChange()" [disabled]="disabled">
          <mat-option *ngFor="let option of modesOptions" [value]="option.value">{{
            option.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2 env-label env-header main-fields-column">
      {{ T_SCOPE + '.severity' | translate }}
    </div>
    <div class="col-4 env-label env-header main-fields-column">
      {{ T_SCOPE + '.persistency' | translate }}
    </div>
  </div>
  <div [formGroup]="form">
    <div class="row remove-side-margins" *ngFor="let envelopeName of envelopeNames">
      <div class="col-1">
        <div class="env-label env-name">{{ envelopeName | capitalize }}</div>
      </div>
      <div class="col-5 main-fields main-fields-column">
        <!-- Flat Relative mode -->
        <mat-form-field
          *ngIf="mode === envelopeModes.FlatRelative"
          class="mat-field mf-padding-small env-input"
          [class.env-input-flat-relative]="mode === envelopeModes.FlatRelative"
          [appearance]="fieldAppearance"
        >
          <mat-select [formControlName]="envelopeName + 'Relative'">
            <mat-option *ngFor="let option of flatRelativeOptions" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Main value field -->
        <mat-form-field
          class="mat-field mf-padding-small mf-reset-width env-input"
          [class.env-input-flat-relative]="mode === envelopeModes.FlatRelative"
          [appearance]="fieldAppearance"
        >
          <input
            matInput
            [formControlName]="envelopeName"
            autocomplete="off"
            [wlmInputValidate]="mainFieldValidators"
          />
          <span matSuffix [ngClass]="{ 'suffix-disabled': disabled }">{{ mainFieldSuffix }}</span>
        </mat-form-field>
      </div>
      <div class="col-2 main-fields-column">
        <mat-form-field class="mat-field mf-padding-small env-input" [appearance]="fieldAppearance">
          <mat-select [formControlName]="envelopeName + 'Severity'">
            <mat-option *ngFor="let severity of severitiesOptions" [value]="severity.value">{{
              severity.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4 main-fields-column">
        <mat-form-field
          class="mat-field mf-padding-small mf-reset-width env-input env-input-pers-temporal"
          [appearance]="fieldAppearance"
        >
          <input
            matInput
            [formControlName]="envelopeName + 'PersistencyTemporal'"
            autocomplete="off"
            [wlmInputValidate]="[
              [
                customValidators.onlyNumeric,
                customValidators.onlyPositiveNumber,
                customValidators.maxDigits(4)
              ]
            ]"
          />
        </mat-form-field>
        <mat-form-field
          class="mat-field mf-padding-small env-input env-input-pers-factor"
          [appearance]="fieldAppearance"
        >
          <mat-select [formControlName]="envelopeName + 'PersistencyFactor'">
            <mat-option *ngFor="let factor of persistencyOptions" [value]="factor.value">{{
              factor.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
