import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { IntegrationGisLayersService } from '../integration-gis-layers.service';

@Component({
  selector: 'wlm-integration-gis-layers-grid',
  templateUrl: './integration-gis-layers-grid.component.html',
  styleUrls: ['./integration-gis-layers-grid.component.scss'],
})
export class IntegrationGisLayersGridComponent implements OnInit {
  private readonly _service = inject(IntegrationGisLayersService);
  private readonly _destroyRef = inject(DestroyRef);

  elements: any[];
  gridSettings: GridSetting;

  ngOnInit(): void {
    this._service
      .getElements()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((elements) => (this.elements = elements));

    this._service
      .getGridSettings()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((gridSettings) => (this.gridSettings = gridSettings));
  }
}
