import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FiltrableGisLayer } from 'src/app/common-modules/dependencies/map/filtrable-gis-layer';
import { IGisLayer } from 'src/app/common-modules/dependencies/map/gis-layer';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseComponent } from 'src/app/common-modules/shared/component/base.component';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { ICoordinate } from 'src/app/common-modules/shared/model/gis/coordinate.dto';
import { IGisCollectionDto } from 'src/app/common-modules/shared/model/gis/gis-collection.dto';
import { IGisElementDto } from 'src/app/common-modules/shared/model/gis/gis-element.dto';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { GisService } from '../shared/gis/gis.service';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { MapSettings } from './map-filter/models/map-filter-settings';
import { MapParameters } from './map-parameters';
import { MapFeatureTypes, mapFeatureTypesMapping } from './models/map-feature-types';

@Directive()
export abstract class BaseMap extends BaseComponent implements OnDestroy {
  private _map: mapboxgl.Map;
  private _mapInitializedSubscription: Subscription;

  private _gisService: GisService;
  protected _globals: GlobalsService;
  protected _arrayHelperService: ArrayHelperService;
  protected _settingsService: SettingsService;
  protected mapInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected persistFilters$: Subject<void> = new Subject<void>();
  protected _baseType: string;
  protected _visibleLayersIds: number[] = [];
  protected _center: number[];
  protected _zoom: number;
  protected _showPanel: boolean = false;
  protected _showFilters: boolean = false;
  protected _zoomEnabled: boolean = true;
  protected _panningEnabled: boolean = true;
  protected _leakYears: number[] = [];
  protected _hierarchyElements: string[] = [];
  protected _heCollection: IGisCollectionDto;
  protected _visibleThematicsIds: string[] = [];
  protected _neCollection: IGisCollectionDto;
  protected _geojsonFeatures: GeoJSON.FeatureCollection;
  private _filtrableGisLayers: FiltrableGisLayer;
  private _previousLoadedLayerIds: number[] = [];

  protected get _featuresType(): MapFeatureTypes {
    const features = this._geojsonFeatures?.features;
    let type: string =
      features?.length !== 0 && features[0].geometry ? features[0].geometry.type : null;

    if (!type) {
      return null;
    }

    type = type.toLowerCase();
    for (let item of mapFeatureTypesMapping.entries()) {
      if (type.includes(item[0])) {
        return item[1] as MapFeatureTypes;
      }
    }

    return null;
  }

  public abstract mapParameters: MapParameters;

  // Navigated element
  private _navigatedElement: IGisElementDto;
  public get navigatedElement(): IGisElementDto {
    return this._navigatedElement;
  }
  public set navigatedElement(value: IGisElementDto) {
    this._navigatedElement = value;
    this.navigatedElementChange.emit(value);
  }
  @Output()
  public navigatedElementChange = new EventEmitter<IGisElementDto>();

  // Map
  public get map(): mapboxgl.Map {
    return this._map;
  }
  public set map(v: mapboxgl.Map) {
    this._map?.remove();
    this._map = v;

    this.mapInitialized.next(v !== undefined);
  }

  // Base Type
  public get baseType(): string {
    return this._baseType;
  }
  @Input()
  public set baseType(value: string) {
    if (this._baseType === value || value === undefined || value === '') {
      return;
    }
    this._baseType = value;
    this.baseTypeChange.emit(value);
  }
  @Output()
  public baseTypeChange = new EventEmitter<string>();

  // Visible Layers
  public get visibleLayersIds(): number[] {
    return this._visibleLayersIds;
  }
  @Input()
  public set visibleLayersIds(value: number[]) {
    if (value === null || value === undefined || !this.map) {
      return;
    }

    if (!this._arrayHelperService.areSame(this._visibleLayersIds, value)) {
      this._visibleLayersIds = value;
    }

    this.persistFilters$.next();

    this.visibleLayersIdsChange.emit(value);
  }
  @Output()
  public visibleLayersIdsChange = new EventEmitter<number[]>();

  // Visible Thematics
  public get visibleThematicsIds(): string[] {
    return this._visibleThematicsIds;
  }
  @Input()
  public set visibleThematicsIds(value: string[]) {
    if (value === null || value === undefined || !this.map) {
      return;
    }

    this._visibleThematicsIds = value;
    this.persistFilters$.next();

    this.visibleThematicsIdsChange.emit(value);
  }
  @Output()
  public visibleThematicsIdsChange = new EventEmitter<string[]>();

  // Center
  public get center(): number[] {
    return this._center;
  }
  @Input()
  public set center(value: number[]) {
    if (this._center === value) {
      return;
    }

    this._center = value;
    this.centerChange.emit(value);
  }
  @Output()
  public centerChange = new EventEmitter<number[]>();

  // Zoom
  public get zoom(): number {
    return this._zoom;
  }
  @Input()
  public set zoom(value: number) {
    if (this._zoom === value) {
      return;
    }

    this._zoom = value;
    this.zoomChange.emit(value);
  }
  @Output()
  public zoomChange = new EventEmitter<number>();

  // Boundaries
  @Input()
  public set boundaries(value: LngLatBoundsLike) {
    this.boundariesChange.emit(value);
  }
  @Output()
  public boundariesChange = new EventEmitter<LngLatBoundsLike>();

  public set boundariesCoordinates(value: ICoordinate[]) {
    if (value.length !== 5) {
      return;
    }

    const bounds: LngLatBoundsLike = [value[0].x, value[0].y, value[3].x, value[2].y];
    this.boundaries = bounds;
  }

  // Hierarchy Elements
  public get hierarchyElements(): string[] {
    return this._hierarchyElements;
  }
  @Input()
  public set hierarchyElements(value: string[]) {
    if (value === null || value === undefined) {
      return;
    }

    if (this._arrayHelperService.areSame(this._hierarchyElements, value)) {
      return;
    }

    if (value.length === 0) {
      if (this.heCollection !== null) {
        this.heCollection = null;
      }
    } else {
      this._gisService.getByHierarchyElements(value, { avoid: true }).subscribe((x) => {
        this.heCollection = x;
      });
    }

    this._hierarchyElements = value;
  }
  @Output()
  public heCollectionChange = new EventEmitter<IGisCollectionDto>();

  protected set heCollection(value: IGisCollectionDto) {
    this._heCollection = value;
    this.heCollectionChange.emit(value);
    this.boundariesCoordinates = this._heCollection?.bb ?? [];
  }

  //Network Elements
  private _networkElements: string[];
  public get networkElements(): string[] {
    return this._networkElements;
  }

  @Input() public set networkElements(value: string[]) {
    if (value === null || value === undefined) {
      return;
    }

    if (this._arrayHelperService.areSame(this._networkElements, value)) {
      return;
    }

    if (value.length === 0) {
      if (this.neCollection !== null) {
        this.neCollection = null;
      }
    } else {
      this._gisService.getByNetworkElements(value, { avoid: true }).subscribe((x) => {
        this.neCollection = x;
      });
    }

    this._networkElements = value;
  }

  private _networkElementTypes: number[];
  get networkElementTypes(): number[] {
    return this._networkElementTypes;
  }
  @Input() set networkElementTypes(value: number[]) {
    if (!value || this._arrayHelperService.areSame(this._networkElementTypes, value)) {
      return;
    }

    this._networkElementTypes = value;

    this._globals
      .getFiltrableGisLayers()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (layers) => {
          this._filtrableGisLayers = layers;

          const availableLayers = this._filtrableGisLayers.networkElements.concat(
            this._filtrableGisLayers.hierarchyElements
          );
          const selectedLayers = availableLayers.filter((layer) =>
            this.networkElementTypes.find((netId) => netId === layer.networkElementTypeId)
          );

          if (selectedLayers.length) {
            this.loadGisLayers(selectedLayers);
          }
        },
      });
  }

  @Output()
  public neCollectionChange = new EventEmitter<IGisCollectionDto>();

  protected set neCollection(value: IGisCollectionDto) {
    this._neCollection = value;
    this.neCollectionChange.emit(value);
    this.boundariesCoordinates = this._neCollection?.bb ?? [];
  }

  // ShowPanel
  public get showPanel(): boolean {
    return this._showPanel;
  }
  @Input()
  public set showPanel(value: boolean) {
    if (this._showPanel === value) {
      return;
    }

    this._showPanel = value;
    this.showPanelChange.emit(value);
  }
  @Output()
  public showPanelChange = new EventEmitter<boolean>();

  // ShowFilters
  public get showFilters(): boolean {
    return this._showFilters;
  }
  @Input()
  public set showFilters(value: boolean) {
    if (this._showFilters === value) {
      return;
    }

    this._showFilters = value;
    this.showFilterChange.emit(value);
  }
  @Output()
  public showFilterChange = new EventEmitter<boolean>();

  // ZoomEnabled
  public get zoomEnabled(): boolean {
    return this._zoomEnabled;
  }
  @Input()
  public set zoomEnabled(value: boolean) {
    if (this._zoomEnabled === value) {
      return;
    }

    this._zoomEnabled = value;
    this.zoomEnabledChange.emit(value);
  }
  @Output()
  public zoomEnabledChange = new EventEmitter<boolean>();

  // PanningEnabled
  public get panningEnabled(): boolean {
    return this._panningEnabled;
  }
  @Input()
  public set panningEnabled(value: boolean) {
    if (this._panningEnabled === value) {
      return;
    }

    this._panningEnabled = value;
    this.panningEnabledChange.emit(value);
  }
  @Output()
  public panningEnabledChange = new EventEmitter<boolean>();

  // Leak Years
  public get leakYears(): number[] {
    return this._leakYears;
  }
  @Input()
  public set leakYears(value: number[]) {
    if (this._leakYears === value) {
      return;
    }

    if (this._arrayHelperService.areSame(this._leakYears, value)) {
      return;
    }

    this._leakYears = value;
    this.persistFilters$.next();

    this.leakYearsChange.emit(value);
  }
  @Output()
  public leakYearsChange = new EventEmitter<number[]>();

  @Input() public set geojsonFeatures(value: GeoJSON.FeatureCollection) {
    if (
      value === null ||
      value === undefined ||
      globalUtilsHelper.deepEqual(this._geojsonFeatures, value)
    ) {
      return;
    }

    if (value.features.length === 0) {
      this._geojsonFeatures = null;
    } else {
      this._geojsonFeatures = value;
    }

    this.geojsonFeaturesChange.emit(this._geojsonFeatures);
    // this.boundariesCoordinates = this._geojsonFeatures ?.bb? ?? [];
  }

  @Output() geojsonFeaturesChange = new EventEmitter<GeoJSON.FeatureCollection>();

  // GeoJsonFeatures
  public get geojsonFeatures(): GeoJSON.FeatureCollection {
    return this._geojsonFeatures;
  }

  // Map Settings
  public get mapSettings(): MapSettings {
    const { lat, lng } = this.map.getCenter();

    const mapSettings: MapSettings = {
      baseType: this.baseType,
      visibleLayersIds: this.visibleLayersIds,
      visibleThematicsIds: this.visibleThematicsIds,
      center: [lat, lng],
      zoom: this.map.getZoom(),
      leakYears: this.leakYears,
    };

    return mapSettings;
  }

  constructor() {
    super();

    this._gisService = WlmSharedModule.injector.get(GisService);
    this._arrayHelperService = WlmSharedModule.injector.get(ArrayHelperService);
    this._settingsService = WlmSharedModule.injector.get(SettingsService);
    this._globals = WlmSharedModule.injector.get(GlobalsService);

    this.center = this._settingsService.map.center;
    this.zoom = this._settingsService.map.zoom;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {
    if (this._mapInitializedSubscription && this._mapInitializedSubscription.closed) {
      this._mapInitializedSubscription.unsubscribe();
    }
    this._mapInitializedSubscription = this.mapInitialized
      .pipe(untilDestroyed(this, 'ngOnDestroy'))
      .subscribe({
        next: (isInitialized) => {
          if (!isInitialized) {
            return;
          }
          this.setMapParametersEvents();
        },
      });
  }

  private setMapParametersEvents() {
    if (!this.mapParameters) {
      return;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.baseType)) {
      this.baseType = this.mapParameters.baseType;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.center)) {
      this.center = this.mapParameters.center;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.panningEnabled)) {
      this.panningEnabled = this.mapParameters.panningEnabled;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.showPanel)) {
      this.showPanel = this.mapParameters.showPanel;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.showFilters)) {
      this.showFilters = this.mapParameters.showFilters;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.visibleLayersIds)) {
      this.visibleLayersIds = this.mapParameters.visibleLayersIds;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.visibleThematicsIds)) {
      this.visibleThematicsIds = this.mapParameters.visibleThematicsIds;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.zoom)) {
      this.zoom = this.mapParameters.zoom;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.zoomEnabled)) {
      this.zoomEnabled = this.mapParameters.zoomEnabled;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.hierarchyElements)) {
      this.hierarchyElements = this.mapParameters.hierarchyElements;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.networkElements)) {
      this.networkElements = this.mapParameters.networkElements;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.networkElementTypes)) {
      this.networkElementTypes = this.mapParameters.networkElementTypes;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.leakYears)) {
      this.leakYears = this.mapParameters.leakYears;
    }

    if (!globalUtilsHelper.isNullUndefined(this.mapParameters.geojsonFeatures)) {
      this.geojsonFeatures = this.mapParameters.geojsonFeatures;
    }

    this.navigatedElement = this.mapParameters.navigatedElement;
  }

  private loadGisLayers(layers: IGisLayer[]): void {
    let layerIds = layers.map((layer) => layer.gisLayerId);

    for (let layer of layers) {
      if (!!layer.sublayers?.length) {
        const sublayerIds = layer.sublayers.map((sub) => sub.gisLayerId);
        layerIds = layerIds.concat(sublayerIds);
      }
    }

    let visibleLayersIds = this.visibleLayersIds ? [...this.visibleLayersIds] : [];

    // Remove sublayers for the cases in which there was already some selected
    // Remove also previously selected layers by this same method.
    visibleLayersIds = visibleLayersIds.filter(
      (id) => !layerIds.includes(id) && !this._previousLoadedLayerIds.includes(id)
    );

    this._previousLoadedLayerIds = [...layerIds];
    this.visibleLayersIds = visibleLayersIds.concat(layerIds);
  }

  ngOnDestroy(): void {
    this.map = null;
  }
}
