import { Injectable, Injector } from '@angular/core';
import { NotificationDto } from '../../shared/model/notifications/notification.dto';
import { PagedResultDto } from '../../shared/model/paged-result.dto';
import { GridODataService } from '../../shared/odata/grid-odata.service';

const endPoint = '/notification/elements';
@Injectable({ providedIn: 'root' })
export class NotificationRelatedService extends GridODataService<NotificationDto> {
  protected get url() {
    return `${this.apiUrl}${endPoint}`;
  }

  constructor(injector: Injector) {
    const apiEndpoint = `/api${endPoint}`;
    super(injector, apiEndpoint);
  }

  protected mapResponse(
    response: PagedResultDto<NotificationDto>
  ): PagedResultDto<NotificationDto> {
    return response;
  }
}
