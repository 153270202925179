<wlm-placeholder-overlay
  *ngIf="!selectedSignalId"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-data-validation-chart
  *ngIf="selectedSignalId"
  [pageId]="pageId"
  [signalId]="selectedSignalId"
  [valueRanges]="valueRanges"
  [dateRanges]="dateRanges"
  [validationToApply]="validationToApply"
  [containerSize$]="chartSize$"
  (chartFiltersChange)="onChartFiltersChange($event)"
  (valueRangesChange)="onValueRangesChanged($event)"
  (dateRangesChange)="onDateRangesChanged($event)"
  (toggleValueRangesCart)="onToggleValueRangesCart($event)"
  (toggleDateRangesCart)="onToggleDateRangesCart($event)"
></wlm-data-validation-chart>
