import { Component, ElementRef, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EChartsOption } from 'echarts';
import { Observable, Subject } from 'rxjs';
import { AlarmQueryDto } from 'src/app/common-modules/dependencies/alarms/alarm-query.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { GChartInitEvent } from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from 'src/app/common-modules/wlm-charts/core/models/generic-events/g-chart-legend-selected-event';
import { PeriodTypesEnum } from 'src/app/common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorPeriod } from 'src/app/common-modules/wlm-charts/core/models/time-selector-period';
import { TimeSelectorChartSettings } from 'src/app/common-modules/wlm-charts/core/models/time-selector-settings';
import { TrendChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { TrendChartSettings } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-settings';
import { AlarmDto } from '../../../../common-modules/dependencies/alarms/alarm.dto';
import { AlarmsService } from '../../../../common-modules/shared/services/alarms.service';

import { AlarmsTabFilter } from '../../shared/alarms/alarms-tab-filter';
// prettier-ignore

import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { SizeCalculatorService } from 'src/app/common-modules/shared/services/size-calculator.service';
import { GenericChartUnselectedSeries } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-unselected-series';
import { ActiveAlarmsGridComponent } from '../active-alarms-grid/active-alarms-grid.component';
import { ActiveAlarmsGridService } from '../active-alarms-grid/active-alarms.service';
import { AlarmsChartParams } from './alarm-chart-params';
import { AlarmsChartService } from './alarms-chart.service';

const COMPONENT_SELECTOR = 'wlm-alarms-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-chart.component.html',
  styleUrls: ['./alarms-chart.component.scss'],
  providers: [ActiveAlarmsGridService],
})
export class AlarmsChartComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  filters: DataBindingFilters;
  timeSelectorChartSettings: TimeSelectorChartSettings;
  params: AlarmsChartParams = {};
  hierarchyElementId: string;
  networkElementId: string;
  widgetReady = false;
  chartUpdating = false;
  isNetworkElement = false;
  size$: Observable<IElementSize>;

  public get elementId(): string {
    return this.isNetworkElement ? this.networkElementId : this.hierarchyElementId;
  }

  scopeKey: string;
  settingsLoaded$ = new Subject<void>();
  chartFinishedRendering$ = new Subject<void>();

  get componentName() {
    return 'AlarmsChartComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private _alarmsChartService: AlarmsChartService,
    private _alarmsService: AlarmsService,
    private _activeAlarmsGridService: ActiveAlarmsGridService,
    private _rightPanelService: RightPanelService,
    private readonly _element: ElementRef,
    private readonly _sizeCalculatorService: SizeCalculatorService
  ) {
    super(injector, widgetSettings);
    this.scopeKey = this.generateScopeKey();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeRightPanelCallback();
    this.size$ = this._sizeCalculatorService.listenElementSize$(this._element.nativeElement);
  }

  mapInitParameters(parameters: TabDetailPanelParameters): void {
    this.widgetReady = false;
    // Read params from navigation
    this.hierarchyElementId = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
    this.networkElementId = parameters.parameters.get(TabDetailParameterName.networkElementId);
    this.isNetworkElement = !this.hierarchyElementId;

    this.params = {};
    this.updateParams({
      elementId: this.isNetworkElement ? this.networkElementId : this.hierarchyElementId,
      isNetworkElement: this.isNetworkElement,
    });
  }

  private resetAlarmsChart() {
    this._activeAlarmsGridService.setRefreshAlarmCounters(true);

    this.params = {};
    this.updateParams({
      elementId: this.isNetworkElement ? this.networkElementId : this.hierarchyElementId,
      isNetworkElement: this.isNetworkElement,
    });
  }

  subscribeRightPanelCallback() {
    this._rightPanelService
      .rightPanelCallbackObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (payload) => {
          if (
            payload?.has(ActiveAlarmsGridComponent) &&
            payload?.get(ActiveAlarmsGridComponent) == 'refresh'
          ) {
            this.resetAlarmsChart();
          }
        },
      });
  }

  init(): void {}

  onTabFilter(filter: AlarmsTabFilter): void {
    this.updateParams({
      alarmClassId: filter.alarmClassId,
    });
  }

  private updateParams(params: Partial<AlarmsChartParams>): void {
    Object.assign(this.params, params);
    this.resetChart();
    if (this.params.alarmClassId && this.params.elementId) {
      this.widgetReady = false;
      this.getAlarms().subscribe({
        next: (alarms) => {
          this.params.alarms = alarms;
          this.buildTimeSelectorChart();
        },
        error: (error) => {
          console.error(error);
          this.widgetReady = true;
        },
      });
    }
  }

  private buildTimeSelectorChart() {
    const customPeriod = this.getAlarmsCustomPeriod(this.getAlarmsPeriod());
    this.timeSelectorChartSettings = new TimeSelectorChartSettings({
      chartSetting: new TrendChartSettings({
        chartType: ChartType.trend,
        dataParameters: new TrendChartDataParameters({
          startDate: new Date(2019, 0, 1), // TODO: remove defaults
          endDate: new Date(2019, 3, 1),
          queryParams: this.params,
          dataService: 'AlarmsChartService',
          scopeKey: this.scopeKey,
        }),
      }),
      includeDefaultPeriods: true,
      fiscalYears: 2,
      customPeriods: [customPeriod],
      defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
    });
  }

  onChartInit(event: GChartInitEvent) {
    this.widgetReady = true;
    this.loadThresholdsOnInit(event.nativeSeries, event.mergeOptionsFn);
  }

  onChartLegendSelected(event: GChartLegendSelectedEvent): void {
    const selected = event.event.selected;
    const selectedNames = Object.keys(selected).filter((key) => key && selected[key]);
    this.loadThresholdsOnSelect(event.nativeSeries, selectedNames, event.mergeOptionsFn);
  }

  /**
   * On init, load thresholds for the first time and directly apply them, if possible.
   */
  loadThresholdsOnInit(nativeSeries, mergeOptionsFn): void {
    if (this.chartUpdating) {
      return;
    }
    this.chartUpdating = true;

    this._alarmsChartService.loadThresholds(null, this.scopeKey, (settings: EChartsOption) => {
      mergeOptionsFn(settings);
      this.waitForUpdateFinished();
    });
  }

  loadThresholdsOnSelect(nativeSeries, selectedNames, mergeOptionsFn): void {
    if (this.chartUpdating) {
      return;
    }
    this.chartUpdating = true;

    this._alarmsChartService.loadThresholds(
      selectedNames,
      this.scopeKey,
      (thresholdSettings: EChartsOption) => {
        // The config for the color is added to the series processed in the previous step.
        const piecesSettings = this._alarmsChartService.updateColoredPieces(
          selectedNames,
          thresholdSettings.series as any[],
          this.scopeKey
        );
        const mixedSettings = { ...thresholdSettings, ...piecesSettings };
        mergeOptionsFn(mixedSettings);
        this.waitForUpdateFinished();
      }
    );
  }

  /**
   * To know that a chart has updated its settings and rendered, we must subscribe in sequence.
   * We cannot just subscribe to finishedRendering, because this also triggers every time we hover a legend, ie.
   */
  waitForUpdateFinished(): void {
    const loadedSubs = this.settingsLoaded$.pipe(untilDestroyed(this)).subscribe(() => {
      const renderSubs = this.chartFinishedRendering$.pipe(untilDestroyed(this)).subscribe(() => {
        this.chartUpdating = false;
        loadedSubs.unsubscribe();
        renderSubs.unsubscribe();
      });
    });
  }

  onChartLoaded(loaded: boolean): void {
    this.widgetReady = loaded;
    if (loaded) {
      this.settingsLoaded$.next();
    }
  }

  /**
   * Event fired when the chart has finished rendering.
   */
  onChartFinished = (event) => {
    this.chartFinishedRendering$.next();
  };

  onSerieNamesUnselected(serieNamesUnselected: GenericChartUnselectedSeries): void {
    this._alarmsChartService.setSerieNamesUnselected(serieNamesUnselected);
  }

  onWidgetReady($event) {
    this.widgetReady = true;
  }

  /**
   * Generate an unique key, used to differentiate data scopes inside AlarmsChartService
   * when two alarms charts are active in the same page.
   */
  generateScopeKey(): string {
    return `${this.componentName}_${new Date().getTime()}`;
  }

  ngOnDestroy(): void {
    this._alarmsChartService.resetScope(this.scopeKey);
  }

  private getAlarms(): Observable<AlarmDto[]> {
    return this._alarmsService.getAlarms(
      new AlarmQueryDto({
        elementsIds: [this.params.elementId],
        alarmClassId: this.params.alarmClassId,
        excludeChildren: true,
        isNetworkElement: this.params.isNetworkElement,
      })
    );
  }

  private getAlarmsCustomPeriod(dateRange: DateRange): TimeSelectorPeriod {
    const alarmTrendChartPeriod = new TimeSelectorPeriod({
      endDate: dateRange.end,
      startDate: dateRange.start,
      periodType: PeriodTypesEnum.customFromDateRange,
      keyLabel: 'auto',
    });

    return alarmTrendChartPeriod;
  }

  private getAlarmsPeriod(): DateRange {
    return this._alarmsChartService.getAlarmsPeriod(this.params.alarms, this.params.alarmClassId);
  }

  private resetChart() {
    this.timeSelectorChartSettings = null;
  }
}
