import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, finalize, map } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutBuilderService } from 'src/app/common-modules/dynamic-layout/layouts/dynamic-layout-builder-service';
import { SimpleDynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings-item';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutStructure } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-structure';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { ResetFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import {
  ResetStateAction,
  SetValueAction,
} from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import {
  MergedZonesCreatePopupComponent,
  MergedZonesCreatePopupData,
} from '../components/merged-zones-create-popup/merged-zones-create-popup.component';
import { MergedZonesStateFields } from '../models/merged-zones-state.fields';
import { MergedZonesDto } from '../models/merged-zones.dto';
import { MergedZonesService } from '../services/merged-zones.service';
import { MZ_CONFIGURATOR_COMPONENT_INSTANCE } from '../widgets/merged-zones-configurator-widget/merged-zones-configurator-widget.component';
import { MZ_FILTER_COMPONENT_INSTANCE } from '../widgets/merged-zones-filter-widget/merged-zones-filter-widget.component';
import { MZ_SELECTOR_COMPONENT_INSTANCE } from '../widgets/merged-zones-selector-widget/merged-zones-selector-widget.component';

const COMPONENT_SELECTOR = 'wlm-merged-zones-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-page.component.html',
  styleUrls: ['./merged-zones-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class MergedZonesPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  selectedMergedZone: MergedZonesDto;

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  readonly pageId: string = 'MergedZonesPage';

  private readonly _simpleSettings: SimpleDynamicLayoutSettings = {
    layoutKey: this.pageId,
    layoutArea: 'Main',
    scopeInstanceKeys: {
      [StateAreas.Generic]: 'shared',
    },
  };

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._simpleSettings.layoutArea,
    page: this._simpleSettings.layoutKey,
    scopeInstanceKeys: this._simpleSettings.scopeInstanceKeys,
  });

  private readonly _filterWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'MergedZonesFilterWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-merged-zones-filter`,
    id: MZ_FILTER_COMPONENT_INSTANCE,
  };

  private readonly _selectorWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'MergedZonesSelectorWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-merged-zones-selector`,
    id: MZ_SELECTOR_COMPONENT_INSTANCE,
  };

  private readonly _configuratorWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'MergedZonesConfiguratorWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-merged-zones-configurator`,
    id: MZ_CONFIGURATOR_COMPONENT_INSTANCE,
  };

  private readonly _defaultWidgets = [
    this._filterWidget,
    this._selectorWidget,
    this._configuratorWidget,
  ];

  private readonly _defaultStructure: DynamicLayoutStructure[] = [
    {
      type: LayoutNodeTypes.Column,
      content: [
        {
          type: LayoutNodeTypes.Stack,
          height: 10,
          content: [
            {
              type: LayoutNodeTypes.Component,
              widgetInstanceKey: MZ_FILTER_COMPONENT_INSTANCE,
            },
          ],
        },
        {
          type: LayoutNodeTypes.Row,
          content: [
            {
              type: LayoutNodeTypes.Stack,
              height: 90,
              width: 25,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: MZ_SELECTOR_COMPONENT_INSTANCE,
                },
              ],
            },
            {
              type: LayoutNodeTypes.Stack,
              height: 85,
              width: 75,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: MZ_CONFIGURATOR_COMPONENT_INSTANCE,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  dynamicLayoutSettings: DynamicLayoutSettings;
  isLoading = false;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMMergedZonesCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  constructor(
    private readonly _dynamicLayoutBuilderService: DynamicLayoutBuilderService,
    private readonly _dynamicLayoutService: DynamicLayoutService,
    private readonly _pendingChangesService: PendingChangesManagerService,
    private readonly _state: ReduxStateService,
    private readonly _dialogService: DialogService,
    private readonly _mergedZonesService: MergedZonesService
  ) {
    super();

    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initialLoad();
    this.listenSelectors();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  private listenSelectors(): void {
    this._state
      .select<MergedZonesDto>(
        new GetValueSelector({
          fieldName: MergedZonesStateFields.selectedMergedZone,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((selected) => {
        this.selectedMergedZone = selected;
      });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  openCreatePopup(): void {
    this.openCreateEditPopup();
  }

  openEditPopup(): void {
    if (this.selectedMergedZone) {
      const model = globalUtilsHelper.clone(this.selectedMergedZone, true);
      const data: MergedZonesCreatePopupData = {
        isUpdate: true,
        model,
      };
      this.openCreateEditPopup(data);
    }
  }

  private openCreateEditPopup(data?: MergedZonesCreatePopupData): void {
    const config = new MatDialogConfig();
    if (data) {
      config.data = data;
    }

    const additionalSettings = {
      autoHeight: true,
    };

    this._dialogService
      .openComponent(MergedZonesCreatePopupComponent, config, additionalSettings)
      .afterClosed()
      .subscribe((mergedZone) => {
        if (mergedZone) {
          this.reloadSelector();
        }
      });
  }

  onDelete(): void {
    this._dialogService
      .showDeleteConfirmationSnackBar(1, 'merged-zone')
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.deleteCalendarAction();
        }
      });
  }

  private deleteCalendarAction() {
    this.isLoading = true;
    this._mergedZonesService
      .delete(this.selectedMergedZone)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this._dialogService.showEntityActionSnackBar('delete', 'merged-zone.singular');
          this.reloadSelector();
        },
        error: (error) => {
          this._dialogService.showErrorMessage(error);
        },
      });
  }

  onResetFilters(): void {
    this.checkPendingChanges(this.pageId).subscribe(() =>
      this._state.dispatch(new ResetFiltersAction(null))
    );
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._state.dispatch(
        new ResetStateAction({ fieldNames: Object.keys(MergedZonesStateFields) })
      );

      this._dynamicLayoutService.resetAll();
    });
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  private initialLoad() {
    this.buildDynamicLayoutSettings().subscribe((settings) => {
      this.dynamicLayoutSettings = settings;
    });
  }

  private buildDynamicLayoutSettings(): Observable<DynamicLayoutSettings> {
    const simpleSettings = globalUtilsHelper.clone(this._simpleSettings, true);
    simpleSettings.items = this._defaultWidgets;

    return this._dynamicLayoutBuilderService.build(simpleSettings).pipe(
      map((dynamicLayoutSettings) => {
        dynamicLayoutSettings.showResetIcon = true;
        dynamicLayoutSettings.defaultStructure = this._defaultStructure;
        return dynamicLayoutSettings;
      })
    );
  }

  private reloadSelector(): void {
    this.dispatchReload(null);
    this.dispatchReload(true);
  }

  private dispatchReload(value: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: MergedZonesStateFields.reloadMergedZones,
        value,
      })
    );
  }

  ngOnDestroy(): void {
    this._state.dispatch(new ResetStateAction({ fieldNames: Object.keys(MergedZonesStateFields) }));

    super.ngOnDestroy();
  }
}
