<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [pageId]="pageId"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="alarmsConfigurationGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <wlm-nav-dropdown
          class="nav-dropdown"
          *hasPerm="['WLMAlarmsCrud', 'c']"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
          [label]="T_SCOPE + '.create-alarm-tooltip'"
          [matTooltip]="T_SCOPE + '.create-alarm-tooltip' | translate | lowercase"
          icon="add"
          [showLabel]="false"
        ></wlm-nav-dropdown>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAlarmsCrud', 'd']"
          [disabled]="!hasSelectedAlarms"
          [tooltip]="T_SCOPE + '.delete-alarm-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="deleteAlarms()"
        >
          <mat-icon class="material-icon" svgIcon="delete"></mat-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAlarmsCrud', 'u']"
          [disabled]="!hasSelectedAlarms"
          [tooltip]="T_SCOPE + '.park-alarm-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="openParkAlarmsPopup()"
        >
          <mat-icon class="material-icon" svgIcon="park-select-alarms"></mat-icon>
        </button>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-alarms-configuration-filter
      *ngIf="alarmsConfigurationFilterConfig"
      [alarmsConfigurationFilterConfig]="alarmsConfigurationFilterConfig"
      [clearAll$]="clearAll$"
      [apply$]="triggerApply$"
      [persistFilters$]="persistFilters$"
      [disableAutoload]="gridSettings?.disableAutoLoad"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="onApplyFilters(alarmsConfigurationGrid)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-alarms-configuration-filter>
  </ng-container>
  <ng-container page-content>
    <wlm-generic-grid
      class="wtr-fx-flex-grow generic-grid"
      #alarmsConfigurationGrid
      [persistencyArea]="persistencyArea"
      [gridSettings]="gridSettings"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [removeSelection$]="removeSelection$"
      (selectedItemChanged)="getSelectedAlarm($event)"
      [(selectedItems)]="selectedAlarmConfigurations"
      [showSelectedList]="true"
      [showSelectedItems]="false"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
