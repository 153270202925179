import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UoMService } from '../../../uom/uom.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';

@Injectable()
export class ToUoMNameMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToUoMName;

  constructor(private _uomService: UoMService) {
    super();
  }

  map = (unitId: number): Observable<string | null> => {
    if (!unitId) {
      return of(unitId as any);
    }
    return this._uomService.getUnitName(unitId);
  };
}
