<wlm-spinner-wrapper [showSpinner]="isSaving">
  <h3>{{ formTitle | translate }}</h3>

  <hr class="title-separator" />

  <mat-dialog-content [formGroup]="form" class="fixed-subscript">
    <div class="fields-container">
      <div class="rows">
        <mat-form-field class="comment-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.user-name-label' | translate }}</mat-label>
          <input
            matInput
            formControlName="userCode"
            placeholder="{{ T_SCOPE + '.user-name-label' | translate | lowercase }}"
            (keypress)="removeUserValidation()"
            autocomplete="off"
            required
            [maxlength]="maxSize"
          />
          <mat-error *ngIf="form.get('userCode')?.errors?.required">
            {{ 'common.validation.required' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('userCode')?.errors?.userCodeAlreadyExists">{{
            T_SCOPE + '.user-name-exists-validation' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="comment-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.name-label' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ T_SCOPE + '.name-label' | translate | lowercase }}"
            formControlName="userName"
            autocomplete="off"
            required
            [maxlength]="maxSize"
          />
          <mat-error *ngIf="form.get('userName')?.errors?.required">
            {{ 'common.validation.required' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="comment-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.surname-label' | translate }}</mat-label>
          <input
            matInput
            formControlName="userSurname"
            placeholder="{{ T_SCOPE + '.surname-label' | translate | lowercase }}"
            autocomplete="off"
            required
            [maxlength]="maxSize"
          />
          <mat-error *ngIf="form.get('userSurname')?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="save()"
        [disabled]="!form.valid || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>
      <button mat-raised-button (click)="closeWithoutSaving()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</wlm-spinner-wrapper>
