import { ISerieDataPointDto } from '../../../model/chart/serie-data-point.dto';
import { IPlotableSerie } from '../series/plotable-serie';

export interface IChartSerie {
  serieDefinition: IPlotableSerie;
  serieValues: ISerieDataPointDto[];
}

export class ChartSerie implements IChartSerie {
  serieDefinition: IPlotableSerie;
  serieValues: ISerieDataPointDto[];

  constructor(
    serieDefinition: IPlotableSerie,
    serieValues: ISerieDataPointDto[],
    showAlwaysInTooltip?: boolean
  ) {
    this.serieDefinition = serieDefinition;
    this.serieValues = serieValues;
  }
}
