import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WlmFiltersModule } from 'src/app/water-loss/features/wlm-filters/wlm-filters.module';
import { DynamicLayoutModule } from '../dynamic-layout/dynamic-layout.module';
import { ReduxStateModule } from '../redux/redux-state.module';
import { CommonSharedComponentModule } from '../shared-component/common-shared-component.module';
import { CommonSharedModule } from '../shared/common-shared.module';
import { MaterialSharedModule } from '../shared/material-shared.module';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { UomConfigurationService } from './services/uom-configuration.service';
import { UomStoreService } from './state/uom-store.service';
import { UomBulkConfigurationComponent } from './uom-configuration/uom-bulk-configuration/uom-bulk-configuration.component';
import { UomConfigurationComponent } from './uom-configuration/uom-configuration.component';
import { UomPageComponent } from './uom-page/uom-page.component';
import { UomSelectionComponent } from './uom-selection/uom-selection.component';
import { UomConfigurationWidgetComponent } from './widgets/uom-configuration-widget/uom-configuration-widget.component';
import { UomFilterWidgetComponent } from './widgets/uom-filter-widget/uom-filter-widget.component';
import { UomSelectionWidgetComponent } from './widgets/uom-selection-widget/uom-selection-widget.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

const fieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    UomPageComponent,
    UomFilterWidgetComponent,
    UomConfigurationComponent,
    UomBulkConfigurationComponent,
    UomConfigurationWidgetComponent,
    UomSelectionComponent,
    UomSelectionWidgetComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    CommonSharedComponentModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [UomStoreService],
    }),
    MaterialSharedModule,
    TranslateModule,
    WlmFiltersModule,
  ],
  exports: [],
  providers: [
    UomConfigurationService,
    { provide: 'UomConfigurationService', useExisting: UomConfigurationService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldAppearance,
    },
  ],
})
export class UomModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      { name: 'UomFilterWidgetComponent', widgetClass: UomFilterWidgetComponent },
      {
        name: 'UomSelectionWidgetComponent',
        widgetClass: UomSelectionWidgetComponent,
      },
      {
        name: 'UomConfigurationWidgetComponent',
        widgetClass: UomConfigurationWidgetComponent,
      },
    ]);
  }
}
