<mat-icon
  *ngIf="iconName != null && !svgIcon"
  class="material-icons-outlined inner-icon"
  [ngClass]="colorClass"
  >{{ iconName }}</mat-icon
>
<mat-icon
  *ngIf="iconName != null && svgIcon"
  [svgIcon]="iconName"
  class="inner-icon"
  [ngClass]="colorClass"
></mat-icon>
