<div *ngIf="selectedNE" class="ls-container" cdkDropListGroup>
  <div class="ls-column" *ngIf="!isReadOnly">
    <div class="ls-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedSignals"
        (somethingChanged)="compareSignals()"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="ls-column">
    <div class="ls-top-group top-title-position custom-top-title-margin">
      <div class="toggle-button-container">
        <mat-button-toggle-group [(value)]="toggleSelectorValue">
          <mat-button-toggle value="lars">{{
            T_SCOPE + '.lars-selector' | translate | uppercase
          }}</mat-button-toggle>
          <mat-button-toggle value="sworps">{{
            T_SCOPE + '.sworps-selector' | translate | uppercase
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="ls-group">
      <div class="top-title title-alignment">
        <div *ngIf="toggleSelectorValue == 'lars'" class="ne-component-title">
          {{ T_SCOPE + '.lars-addition-title' | translate }}
        </div>
        <div *ngIf="toggleSelectorValue == 'sworps'" class="ne-component-title">
          {{ T_SCOPE + '.sworps-addition-title' | translate }}
        </div>

        <div>
          <button
            [disabled]="!configurationHasChanged"
            mat-icon-button
            *hasPerm="['WLMNetworkCrud', 'u']"
            (click)="onSave()"
            onclick="this.blur()"
            color="primary"
            [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'save'"></wlm-icon>
          </button>
          <button
            [disabled]="!configurationHasChanged"
            mat-icon-button
            *hasPerm="['WLMNetworkCrud', 'u']"
            (click)="discard()"
            onclick="this.blur()"
            color="primary"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>
      </div>

      <!--LARS Addition-->
      <wlm-drag-list-custom
        *ngIf="toggleSelectorValue == 'lars'"
        class="drag-list"
        [objectsToOrder]="larsAddition"
        [settings]="larsAdditionSettings"
        (objectsToOrderChanged)="onSignalsChanged($event)"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event, larsSworpsLists.LarsAddition)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>

      <!--SWORPS Addition-->
      <wlm-drag-list-custom
        *ngIf="toggleSelectorValue == 'sworps'"
        class="drag-list"
        [objectsToOrder]="sworpsAddition"
        [settings]="sworpsAdditionSettings"
        (objectsToOrderChanged)="onSignalsChanged($event)"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event, larsSworpsLists.SworpsAddition)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <div class="ls-group">
      <div
        *ngIf="toggleSelectorValue == 'lars'"
        class="ne-component-title top-title-position middle-title-position"
      >
        {{ T_SCOPE + '.lars-substraction-title' | translate }}
      </div>
      <div
        *ngIf="toggleSelectorValue == 'sworps'"
        class="ne-component-title top-title-position middle-title-position"
      >
        {{ T_SCOPE + '.sworps-substraction-title' | translate }}
      </div>

      <!--LARS Substraction-->
      <wlm-drag-list-custom
        *ngIf="toggleSelectorValue == 'lars'"
        class="drag-list"
        [objectsToOrder]="larsSubstraction"
        [settings]="larsSubtractionSettings"
        (objectsToOrderChanged)="onSignalsChanged($event)"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event, larsSworpsLists.LarsSubtraction)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>

      <!--SWORPS Substraction-->
      <wlm-drag-list-custom
        *ngIf="toggleSelectorValue == 'sworps'"
        class="drag-list"
        [objectsToOrder]="sworpsSubstraction"
        [settings]="sworpsSubtractionSettings"
        (objectsToOrderChanged)="onSignalsChanged($event)"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event, larsSworpsLists.SworpsSubtraction)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
