import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumField',
})
export class EnumFieldPipe implements PipeTransform {
  constructor() {}

  transform(enumElements: Map<any, string>, cellValue: any): string {
    const value = cellValue;

    if (cellValue == undefined || cellValue == null) {
      return '';
    }

    return enumElements?.get(value) === undefined
      ? `Unknown value (${value})`
      : enumElements?.get(value).toString();
  }
}
