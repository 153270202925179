import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { RPButtonWithDetails } from '../../right-panel/rp-button-with-details';
import { BasePageComponent } from '../../shared/component/base-page.component';
import { GridInThePastInfoComponent } from '../grid-in-the-past-info/grid-in-the-past-info.component';

@Directive()
export abstract class BaseGridPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  private gridIsInThePast = false;

  ngOnInit(): void {
    super.ngOnInit();
  }

  // When the grid is in the past, must send a specific setting to the RP to render a button with details.
  protected onIsInThePastChange(isInThePast: boolean): void {
    this.gridIsInThePast = isInThePast;
    const parameters = new TabDetailPanelParameters();
    this.includeAdditionalParams(parameters);
    this.rightPanelService.setTabParameters(parameters);
  }

  protected includeAdditionalParams(parameters: TabDetailPanelParameters): void {
    const buttonsWithDetails: RPButtonWithDetails[] = [];
    if (this.gridIsInThePast) {
      buttonsWithDetails.push({
        icon: 'version_info',
        component: GridInThePastInfoComponent,
      });
    }
    parameters.addParameter(TabDetailParameterName.buttonWithDetails, buttonsWithDetails);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
