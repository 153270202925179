import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { ALCCampaignDto } from 'src/app/common-modules/dependencies/alc/alc-campaing.dto';
import { CalculationMode } from 'src/app/common-modules/dependencies/alc/campaign-calculation-mode.enum';
import { CampaignStatus } from 'src/app/common-modules/dependencies/alc/campaign-status.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { ThematicKPI } from '../../../map/map-thematic-configuration-popup/thematic-kpi';

import { AlcCampaignsService } from '../../campaigns-page/alc-campaigns.service';

const COMPONENT_SELECTOR = 'wlm-campaigns-creation-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './campaigns-creation-popup.component.html',
  styleUrls: ['./campaigns-creation-popup.component.scss'],
})
export class CampaignsCreationPopupComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  private readonly _defaultFaimilyId = '07cf5f7e-a084-4383-a73d-09eda922631a';

  isLoading = false;
  isLinear: boolean = true;
  infoForm: UntypedFormGroup;
  infoErrorMessage: string;
  goalForm: UntypedFormGroup;
  goalsErrorMessage: string;
  activitiesForm: UntypedFormGroup;
  activitiesErrorMessage: string;
  titleKey: string;
  selectedCampaign: ALCCampaignDto;

  defaultGoalCalculationDays = 30;

  //info fields
  campaignNameFieldName = 'campaignName';
  campaignDescriptionFieldName = 'campaignDescription';
  hierarchyElementIdFieldName = 'hierarchyElementId';
  modeFieldName = 'calculationMode';
  campaignBudgetFieldName = 'campaignBudget';

  reductionComponentAutomaticFieldName = 'reductionComponentAutomatic';
  reductionComponentManualFieldName = 'reductionComponentManual';
  baseLineFieldName = 'baseLine';
  systemInputFieldName = 'systemInput';
  heFamilyIdFieldName = 'hierarchyFamilyId';
  hePathFieldName = 'hierarchyElementPath';

  //goal fields
  goalTargetFieldName = 'goalTarget';
  goalProgressFieldName = 'goalProgress';
  statusFieldName = 'statusFieldName';
  targetDateFieldName = 'targetDate';

  //activities
  activitiesFieldName = 'activities';

  get selectedFamilyId(): string {
    return this.selectedCampaign?.hierarchyFamilyId ?? this._defaultFaimilyId;
  }

  get campaignId(): string {
    return this.selectedCampaign?.campaignId;
  }

  isAutomatic: boolean = true;
  disableBack: boolean = true;
  disableNext: boolean = false;
  disableSave = false;
  isEditing: boolean = false;
  infoFormStatus: boolean = false;

  reductionComponents: ThematicKPI[];

  constructor(
    @Inject(MAT_DIALOG_DATA) { selectedCampaign }: any,

    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<CampaignsCreationPopupComponent>,
    private _alcCampaignsService: AlcCampaignsService,
    private _cacheService: HttpCacheService,
    private _dialogsService: DialogService,
    private _dateHelper: DateHelperService,
    private _cd: ChangeDetectorRef
  ) {
    this.selectedCampaign = selectedCampaign;
    this.isEditing = selectedCampaign !== null;
    this.isAutomatic = this.selectedCampaign
      ? this.selectedCampaign?.calculationMode == CalculationMode.Automatic
      : true;
    this.titleKey = this.isEditing ? `${this.T_SCOPE}.title.edit` : `${this.T_SCOPE}.title.add`;
  }

  ngOnInit(): void {
    this.initializeActivitiesForm();
    this.initializeGoalForm();
    this.getReductionComponentsKpis();
  }

  onSelectedIndexChanged(index) {
    this.validateNavigationButtons(index?.selectedIndex);
  }

  initializeInfoForm() {
    const infoFormControls: { [key: string]: UntypedFormControl } = {};

    infoFormControls[this.campaignNameFieldName] = new UntypedFormControl(
      this.selectedCampaign?.campaignName,
      [Validators.required]
    );
    infoFormControls[this.campaignDescriptionFieldName] = new UntypedFormControl(
      this.selectedCampaign?.campaignDescription,
      [Validators.required]
    );
    const heId = this.selectedCampaign?.hierarchyElementId;
    infoFormControls[this.hierarchyElementIdFieldName] = new UntypedFormControl(
      heId ? [heId] : [],
      [Validators.required, Validators.minLength(1)]
    );
    infoFormControls[this.heFamilyIdFieldName] = new UntypedFormControl(
      this.selectedCampaign?.hierarchyFamilyId,
      [Validators.required]
    );

    infoFormControls[this.hePathFieldName] = new UntypedFormControl(
      this.selectedCampaign?.hierarchyElementTypeId,
      [Validators.required]
    );
    infoFormControls[this.baseLineFieldName] = new UntypedFormControl(
      this.convertToPercentaje(this.selectedCampaign?.goalBaseline),
      [Validators.required]
    );
    infoFormControls[this.systemInputFieldName] = new UntypedFormControl(null);
    infoFormControls[this.modeFieldName] = new UntypedFormControl(
      this.selectedCampaign?.calculationMode ?? CalculationMode.Automatic,
      [Validators.required]
    );

    const [reductionComponentAutomatic, reductionComponentManual] =
      this.getReductionComponentValues();

    infoFormControls[this.reductionComponentAutomaticFieldName] = new UntypedFormControl(
      reductionComponentAutomatic,
      [Validators.required]
    );
    infoFormControls[this.reductionComponentManualFieldName] = new UntypedFormControl(
      reductionComponentManual,
      [Validators.required]
    );

    this.infoForm = this._formBuilder.group(infoFormControls);
    this.suscribeToCalculationModeChanges();
    this.subscribeToFormStatusChanges();
  }

  // this is done to prevent the console error message "Expression has changed after it was checked"
  private subscribeToFormStatusChanges() {
    this.infoForm.statusChanges.subscribe((data) => {
      this.infoFormStatus = data === 'VALID';
      this._cd.detectChanges();
    });
  }

  private getReductionComponentValues() {
    let reductionAutomatic = null;
    let reductionManual = null;

    if (this.isEditing) {
      if (this.selectedCampaign.calculationMode == CalculationMode.Automatic) {
        reductionAutomatic = this.reductionComponents.find(
          (f) =>
            f.dimensionTypeId === this.selectedCampaign.dimensionTypeId &&
            f.kpiProperty === this.selectedCampaign.kpiProperty &&
            f.kpiType === this.selectedCampaign.kpiType
        );
      } else {
        reductionManual = this.selectedCampaign.reductionComponent;
      }
    }

    return [reductionAutomatic, reductionManual];
  }

  initializeGoalForm() {
    const goalFormControls: { [key: string]: UntypedFormControl } = {};

    goalFormControls[this.goalTargetFieldName] = new UntypedFormControl(
      this.convertToPercentaje(this.selectedCampaign?.goalTarget),
      [Validators.required]
    );

    let goalProgress = this.convertToPercentaje(this.selectedCampaign?.goalProgress);

    goalFormControls[this.goalProgressFieldName] = new UntypedFormControl(
      { value: goalProgress, disabled: this.isAutomatic },
      [Validators.required]
    );
    goalFormControls[this.statusFieldName] = new UntypedFormControl(
      this.selectedCampaign?.campaignStatus,
      [Validators.required]
    );
    goalFormControls[this.targetDateFieldName] = new UntypedFormControl(
      this.selectedCampaign
        ? this._dateHelper.fromApiFormat(this.selectedCampaign?.targetDate.toString())
        : null,
      [Validators.required]
    );
    goalFormControls[this.campaignBudgetFieldName] = new UntypedFormControl(
      this.selectedCampaign?.campaignBudget,
      [Validators.required]
    );

    this.goalForm = this._formBuilder.group(goalFormControls);
  }

  initializeActivitiesForm() {
    const activitiesFormControls: { [key: string]: UntypedFormControl } = {};

    const activities = this.selectedCampaign?.activities ?? [];
    activitiesFormControls[this.activitiesFieldName] = new UntypedFormControl(activities);

    this.activitiesForm = this._formBuilder.group(activitiesFormControls);
  }

  cancel(): void {
    this._dialogRef.close();
  }

  back(): void {
    this.stepper.previous();
    this.validateNavigationButtons();
  }

  next(): void {
    this.stepper.next();
    this.validateNavigationButtons();
  }

  baselineLoaded(baseLine: number) {
    this.goalForm.controls[this.goalProgressFieldName].setValue(baseLine);
  }

  private suscribeToCalculationModeChanges() {
    this.infoForm.controls[this.modeFieldName].valueChanges.subscribe((mode) => {
      this.isAutomatic = mode === CalculationMode.Automatic;

      if (!this.isAutomatic) {
        this.infoForm?.controls[this.baseLineFieldName].setValue(null);
        this.goalForm?.controls[this.goalProgressFieldName].setValue(null);
      }
    });
  }

  onLoadingChanged(isLoading) {
    setTimeout(() => {
      this.isLoading = isLoading;
    }, 0);
  }

  private validateNavigationButtons(index?: number) {
    const selectedIndex = index !== undefined ? index : this.stepper?.selectedIndex;
    this.disableBack = selectedIndex === 0;
    this.disableNext = selectedIndex === this.stepper?.steps?.length - 1;
  }

  saveCampaign() {
    if (this.validateForms()) {
      this.isLoading = true;
      try {
        const campaign = this.generateCampaignDto();
        let save$: Observable<any>;
        if (this.isEditing) {
          save$ = this._alcCampaignsService.editCampaign(campaign);
        } else {
          save$ = this._alcCampaignsService.saveCampaign(campaign);
        }
        save$.subscribe({
          next: (campaignSaved) => {
            const campaignCache$ = this._cacheService.clearContainsInUrl('alc/campaign');
            const activitiesCache$ = this._cacheService.clearContainsInUrl('alc/activity');

            Promise.all([campaignCache$, activitiesCache$]).then((success) => {
              this.isLoading = false;
              const dialogSettings = this.setDialogSettingsConfiguration(
                'messages.campaign-save-success',
                'success'
              );
              this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);

              this._dialogRef.close(true);
            });
          },
          error: (error) => {
            const dialogSettings = this.setDialogSettingsConfiguration(
              'messages.campaign-save-error',
              'error'
            );
            this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
            this.isLoading = false;
          },
        });
      } catch (error) {
        const dialogSettings = this.setDialogSettingsConfiguration(
          'messages.campaign-save-model-error',
          'error'
        );
        this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
        this.isLoading = false;
      }
    }
  }

  validateForms(): boolean {
    return this.infoForm.valid && this.goalForm.valid && this.activitiesForm.valid;
  }

  generateCampaignDto() {
    const selectedReductionComponent = this.infoForm.controls[
      this.reductionComponentAutomaticFieldName
    ].value as ThematicKPI;

    const status = this.goalForm.controls[this.statusFieldName].value;

    const openDate =
      this.selectedCampaign?.campaignStatus !== status && status == CampaignStatus.Open
        ? new Date()
        : this.selectedCampaign?.openDate;

    const closedDate =
      this.selectedCampaign?.campaignStatus !== status && status == CampaignStatus.Closed
        ? new Date()
        : this.selectedCampaign?.closedDate;

    const campaign = new ALCCampaignDto({
      ...this.selectedCampaign,
      activities: this.activitiesForm.controls[this.activitiesFieldName].value,
      calculationMode: this.infoForm.controls[this.modeFieldName].value,
      campaignBudget: this.goalForm.controls[this.campaignBudgetFieldName].value,
      campaignDescription: this.infoForm.controls[this.campaignDescriptionFieldName].value,
      campaignId: this.isEditing ? this.selectedCampaign?.campaignId : null,
      campaignName: this.infoForm.controls[this.campaignNameFieldName].value,
      campaignStatus: status,
      creationDate: this.isEditing ? this.selectedCampaign?.creationDate : new Date(),
      closedDate,
      openDate,
      targetDate: this.goalForm.controls[this.targetDateFieldName].value,
      deletedDate: this.selectedCampaign?.deletedDate,
      dimensionTypeId: this.isAutomatic ? selectedReductionComponent.dimensionTypeId : null,
      kpiProperty: this.isAutomatic ? selectedReductionComponent.kpiProperty : null,
      kpiType: this.isAutomatic ? selectedReductionComponent.kpiType : null,
      estimatedCost: this.selectedCampaign?.estimatedCost,
      goalBaseline: this.convertFromPercentage(
        this.infoForm.controls[this.baseLineFieldName].value
      ),
      systemInput: this.isAutomatic
        ? this.infoForm.controls[this.systemInputFieldName].value
        : null,
      goalCalculationDays:
        this.selectedCampaign?.goalCalculationDays ?? this.defaultGoalCalculationDays,
      goalProgress: this.convertFromPercentage(
        this.goalForm.controls[this.goalProgressFieldName].value
      ),
      goalTarget: this.convertFromPercentage(
        this.goalForm.controls[this.goalTargetFieldName].value
      ),
      hierarchyElementId: this.infoForm.controls[this.hierarchyElementIdFieldName].value,
      hierarchyFamilyId: this.infoForm.controls[this.heFamilyIdFieldName].value,
      reductionComponent: this.isAutomatic
        ? selectedReductionComponent.kpiLabel
        : this.infoForm.controls[this.reductionComponentManualFieldName].value,
    });

    return campaign;
  }

  private getReductionComponentsKpis() {
    this.isLoading = true;
    this._alcCampaignsService.getKpiSettings().subscribe({
      next: (kpis) => {
        this.reductionComponents = kpis;
        this.initializeInfoForm();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private convertFromPercentage(percentage: number) {
    return percentage * 0.01;
  }

  private convertToPercentaje(toPercentage: number) {
    if (toPercentage >= 0) {
      return toPercentage * 100;
    } else {
      return null;
    }
  }

  private setDialogSettingsConfiguration(
    messageKey: string,
    messageType: 'success' | 'error'
  ): WlmDialogSettings {
    const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
    const dialogSettings = new WlmDialogSettings({
      translateKey: errorMessageKey,
      icon: messageType,
    });
    return dialogSettings;
  }
}
