import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-enumerable-column';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './enumerable-column.component.html',
  styleUrls: ['./enumerable-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => EnumerableColumnComponent),
    },
  ],
})
export class EnumerableColumnComponent extends WlmColumnComponent implements OnInit {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
