<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background">
    <div class="dimension-type-filter">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bfDimensionSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-dimension-types-filter-item
          [fieldName]="telemetryFilterConfiguration.dimensionTypeIdFieldName"
          [selectedId]="telemetryFilterConfiguration.dimensionTypeId"
          [includeOnlyTypeIds]="telemetryFilterConfiguration.dimensionTypeIds"
          [settings]="dimensionTypeItemSettings"
          [filterText]="filterText"
        ></wlm-dimension-types-filter-item>
      </wlm-base-filter>
    </div>
    <div class="ne-type-filter">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bfNeSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-network-elements-filter-item
          [fieldName]="telemetryFilterConfiguration.networkElementTypeIdFieldName"
          [defaultSelectedElements]="telemetryFilterConfiguration.defaultNetworkElementTypeIds"
          [settings]="neTypeItemSettings"
          [filterText]="filterText"
          [selectedIds]="telemetryFilterConfiguration.networkElementTypeIds"
          [filterListElements]="neTypesToFilter"
          [mode]="telemetryFilterConfiguration.neTypeMode"
        ></wlm-network-elements-filter-item>
      </wlm-base-filter>
    </div>
    <div class="he-filter">
      <wlm-base-filter
        *ngIf="treeSettings !== null"
        [settings]="bfSettings"
        [persistItems$]="persistFilters$"
        (filterText)="onFilterText($event)"
      >
        <wlm-hierarchy-family-filter-item
          [fieldName]="telemetryFilterConfiguration.hierarchyElementFamilyFieldName"
          [selectedIds]="[telemetryFilterConfiguration.hierarchyElementFamily]"
          [filterText]="filterText"
          [settings]="hierarchyFamilyItemSettings"
          [restoreStatusOrder]="1"
          (select)="onFamilyIdSelect($event)"
        >
        </wlm-hierarchy-family-filter-item>
        <wlm-hierarchy-tree-filter-item
          [hierarchyElementIdFieldName]="telemetryFilterConfiguration.hierarchyElementIdFieldName"
          [hierarchyFamilyIdFieldName]="
            telemetryFilterConfiguration.hierarchyElementFamilyFieldName
          "
          [hierarchyFamilyId]="hierarchyElementFamilyId"
          [initialSelectedKeys]="telemetryFilterConfiguration.hierarchyElementId"
          [filterText]="filterText"
          [(treeSettings)]="treeSettings"
          [settings]="hierarchyTreeItemSettings"
          [restoreStatusOrder]="2"
          (filterReady)="onTreeFilterReady()"
        ></wlm-hierarchy-tree-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
