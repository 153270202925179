import { CommonModule } from '@angular/common';
import { InjectionToken, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DelegatePersistLayoutService } from '../dynamic-layout/delegate-persist-layout/delegate-persist-layout.service';
import { DefaultParamsStoreService } from '../dynamic-layout/state/default-params/default-params-store.service';
import { FiltersStoreService } from '../dynamic-layout/state/filters/filters-store.service';
import { NEConfigStoreService } from '../dynamic-layout/state/ne-config/ne-config-store.service';
import { WidgetManagerStoreService } from '../dynamic-layout/state/widget-manager/widget-manager.store-service';
import { MaterialSharedModule } from '../shared/material-shared.module';
import { UomStoreService } from '../uom/state/uom-store.service';
import { WidgetManagerModule } from '../widget-manager/widget-manager.module';
import { StateHelperService } from './helpers/state-helper.service';
import { REACTIVE_STATE_ROOT, WLM_GLOBAL_REDUCER_TOKEN } from './models/state-constants';
import { ReduxStateModuleSettings } from './redux-state-module-settings';
import { ReduxStateService } from './redux-state.service';
import { BaseGlobalReducer } from './store/base-global.reducer';
import { BaseStore } from './store/base.store';
import { GlobalStoreService } from './store/global-store.service';
import { LocalStorageStateSyncService } from './sync/local-storage-state-sync.service';
import { StateSyncSettings } from './sync/state-sync-settings';
import { StateSyncEffects } from './sync/state-sync.effects';
import { StateSyncService } from './sync/state-sync.service';
import { SyncStateGlobalReducer } from './sync/sync-state-global.reducer';
import { GenericStoreService } from '../dynamic-layout/state/generic/generic-store.service';

function provideMultiple(base, classes: any[]): any {
  return classes.map((service) => ({
    provide: base,
    multi: true,
    useClass: service,
  }));
}

export const R_STATE_MODULE_SETTINGS = new InjectionToken<ReduxStateModuleSettings>(
  'R_STATE_MODULE_SETTINGS'
);

export const R_STATE_SYNC_SETTINGS = new InjectionToken<StateSyncSettings>('R_STATE_SYNC_SETTINGS');

const defaultProviders = [
  GlobalStoreService,
  {
    provide: WLM_GLOBAL_REDUCER_TOKEN,
    deps: [GlobalStoreService],
    useFactory: (reducer: GlobalStoreService) => reducer.buildReducer(),
  },
  ReduxStateService,
  StateHelperService,
  ...provideMultiple(BaseStore, [
    FiltersStoreService,
    NEConfigStoreService,
    WidgetManagerStoreService,
    UomStoreService,
    DefaultParamsStoreService,
    GenericStoreService,
  ]),
  ...provideMultiple(BaseGlobalReducer, [SyncStateGlobalReducer]),
  {
    provide: StateSyncService,
    useClass: LocalStorageStateSyncService,
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialSharedModule,
    FormsModule,
    StoreModule.forFeature(REACTIVE_STATE_ROOT, WLM_GLOBAL_REDUCER_TOKEN),
    EffectsModule.forFeature([StateSyncEffects]),
    WidgetManagerModule,
  ],
  exports: [],
  providers: defaultProviders,
})
export class ReduxStateModule {
  static injector: Injector;

  constructor(injector: Injector, globalStoreService: GlobalStoreService) {
    ReduxStateModule.injector = injector;
    globalStoreService.joinAll();
  }

  /**
   * Allows an external module to import this module with the perconfigured storeServices.
   */
  static forFeature(config: ReduxStateModuleSettings): ModuleWithProviders<ReduxStateModule> {
    const storeServices = config.storeServices ? config.storeServices : [];
    const moduleDefinition = {
      ngModule: ReduxStateModule,
      providers: [
        ...defaultProviders,
        ...provideMultiple(BaseStore, storeServices),
        {
          provide: R_STATE_MODULE_SETTINGS,
          useValue: config,
        },
        {
          provide: R_STATE_SYNC_SETTINGS,
          useValue: config?.sync,
        },
      ],
    };

    if (config.delegatePersistLayout) {
      moduleDefinition.providers.push({
        provide: DelegatePersistLayoutService,
        useClass: config.delegatePersistLayout.service,
      });
    }

    return moduleDefinition;
  }

  static rootDependencies() {
    return [StoreModule.forRoot({}), EffectsModule.forRoot()];
  }
}
