<wlm-page-wrapper [titleKey]="titleTranslationKey">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMTelemetryPointsCrud', 'c']"
          [tooltip]="T_SCOPE + '.create-point'"
          [showLabel]="isContainerCollapsed"
          (click)="openAddPopup()"
        >
          <mat-icon class="material-icon">add</mat-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMTelemetryPointsCrud', 'u']"
          [disabled]="!selectedTelemetryPoint?.pointId"
          [tooltip]="T_SCOPE + '.edit-point'"
          [showLabel]="isContainerCollapsed"
          (click)="openEditPopup()"
        >
          <wlm-icon [icon]="'edit'"></wlm-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMReplaceSignalCrud', 'u']"
          [disabled]="!selectedTelemetryPoint?.pointId"
          [tooltip]="T_SCOPE + '.replace-point'"
          [showLabel]="isContainerCollapsed"
          (click)="openReplaceSignalPopup()"
        >
          <mat-icon class="replace-signal-icon" svgIcon="replace-point-configuration"></mat-icon>
        </button>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-telemetry-filter
      *ngIf="configReady"
      [storageLocation]="storageLocation"
      [telemetryFilterConfiguration]="telemetryFilterConfiguration"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="loadGrid()"
      (autoLoad)="onCheckAutoload()"
    ></wlm-telemetry-filter>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      #genericGrid
      class="wtr-fx-flex-grow generic-grid"
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      (selectedItemChanged)="getSelectedTelemetryPoint($event)"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
