import mapboxgl from 'mapbox-gl';

export class MapMarker extends mapboxgl.Marker {
  layerId?: number;

  constructor(layerId?: number, options?: mapboxgl.MarkerOptions) {
    super(options);

    this.layerId = layerId;
  }
}
