import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthorizeService } from '../../../../../common-modules/shared/auth/services/authorize.service';

@UntilDestroy()
@Directive({
  selector: '[isNormalUser]',
})
export class IsNormalUserDirective implements OnInit {
  constructor(
    private _authorizeService: AuthorizeService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    this._authorizeService
      .isSuperUser()
      .pipe(untilDestroyed(this))
      .subscribe((isSuperUser) => {
        if (isSuperUser) {
          this.hideContent();
        } else {
          this.showContent();
        }
      });
  }

  private showContent(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hideContent(): void {
    this.viewContainer.clear();
  }
}
