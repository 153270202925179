import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wlm-alarm-severity-counter',
  templateUrl: './alarm-severity-counter.component.html',
  styleUrls: ['./alarm-severity-counter.component.scss'],
})
export class AlarmSeverityCounterComponent implements OnInit {
  @Input() alarmCounter: number;
  @Input() severityColor: string;
  @Input() lightText = false;

  maxCounterToShow = 99;
  constructor() {}

  ngOnInit(): void {}
}
