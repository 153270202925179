import { Component, Input, OnDestroy } from '@angular/core';
import { SpinnerService } from '../spinner.service';

const COMPONENT_SELECTOR = 'wlm-spinner-overlay-invoker';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './spinner-overlay-invoker.component.html',
  styleUrls: ['./spinner-overlay-invoker.component.scss'],
})
export class SpinnerOverlayInvokerComponent implements OnDestroy {
  private _showSpinner: boolean;
  @Input() set showSpinner(value: boolean) {
    if (this._showSpinner !== value) {
      this.setLoadingFn(value);
    }
    this._showSpinner = value;
  }

  private setLoadingFn: (loading: boolean) => void;

  constructor(private readonly _spinnerService: SpinnerService) {
    this.setLoadingFn = this._spinnerService.buildSetLoadingFn();
  }

  ngOnDestroy(): void {
    this.setLoadingFn(false);
  }
}
