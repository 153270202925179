<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'" [showPageSpinner]="isLoading">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="'common.clear-filter' | translate | lowercase"
        onclick="this.blur()"
        (click)="onResetFilters()"
      >
        <mat-icon svgIcon="reset-filters"></mat-icon>
      </button>
      <button
        mat-icon-button
        *isSuperUser
        [matTooltip]="T_SCOPE + '.buttons.launch-summary' | translate | lowercase"
        onclick="this.blur()"
        (click)="launchHierarchyModule()"
      >
        <mat-icon svgIcon="hierarchy"></mat-icon>
      </button>
      <button
        *ngIf="dynamicLayoutSettings?.showResetIcon"
        [matTooltip]="dynamicLayoutSettings?.labelReset | lowercase"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="dynamicLayoutSettings"
      [settings]="dynamicLayoutSettings"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
