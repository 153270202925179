<div class="main-container">
  <div id="center-page">
    <div class="top-container" fixedSizeElement>
      <h3>{{ T_SCOPE + '.title' | translate }}</h3>
    </div>
    <hr class="separator" fixedSizeElement />
    <div id="center-container" class="users-activity-content">
      <wlm-users-activity-filters
        *ngIf="filtersConfig"
        fixedSizeElement
        [config]="filtersConfig"
        [clearAll$]="clearAll$"
        (apply)="onApplyFilters()"
        (filter)="onFilter($event)"
        (filtersDetailParametersChanged)="filtersParametersChanged($event)"
      ></wlm-users-activity-filters>

      <div class="chart-container">
        <wlm-trend-chart
          *ngIf="usersActivityChartConfiguration"
          [chartSettings]="chartSettings"
          [size]="calculatedSize$ | async"
          [disableInnerLoading]="false"
        ></wlm-trend-chart>
      </div>
      <div class="buttons-container" fixedSizeElement>
        <button mat-raised-button (click)="cancel()">
          {{ 'common.close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
