import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseSelectorDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { WorkspaceDefinitionDto } from 'src/app/common-modules/shared/model/data-viz/workspace-definition';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { WorkspacesHelperService } from '../../../shared/services/workspaces-helper.service';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-workspace-editor-widget';
export const DV_WORKSPACE_EDITOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-workspace-editor-widget.component.html',
  styleUrls: ['./data-visualization-workspace-editor-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationWorkspaceEditorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  private _selectedWorkspace: WorkspaceDto;
  public get selectedWorkspace(): WorkspaceDto {
    return this._selectedWorkspace;
  }
  public set selectedWorkspace(value: WorkspaceDto) {
    this._selectedWorkspace = value;
    if (value?.definition) {
      this.setWorkspaceDefinition(value);
    }
  }

  private _workspaceDefinition: WorkspaceDefinitionDto;
  public get workspaceDefinition(): WorkspaceDefinitionDto {
    return this._workspaceDefinition;
  }
  public set workspaceDefinition(value: WorkspaceDefinitionDto) {
    this._workspaceDefinition = value;
    if (value) {
      this.setChartConfiguration(value);
      this.setCartConfiguration(value);
    }
  }

  private _saveConfig$ = new Subject<void>();
  readonly saveConfig$ = this._saveConfig$.asObservable();

  chartConfiguration: ChartConfiguration;
  cartItems: IFiltrableItemDto[];

  phTitleKey = `${this.T_SCOPE}.must-select-title`;
  phSubtitleKey = `${this.T_SCOPE}.must-select-subtitle`;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService,
    private readonly _workspacesHelperService: WorkspacesHelperService
  ) {
    super(injector, widgetSettings, pendingChangesService);
  }

  onWidgetInit(): void {
    this.listenState(DataVisualizationStateFields.selectedWorkspace).subscribe((value) => {
      this.selectedWorkspace = value;

      this.updateItemSettings({
        title: this.selectedWorkspace?.workspaceName ?? this._settings.title,
        widgetInstanceKey: this.getWidgetInstanceKey(),
      });
    });

    this.listenState(DataVisualizationStateFields.loadWorkspace).subscribe((value) => {
      if (value) {
        this._saveConfig$.next();
      }
    });
  }

  onSave(workspace: WorkspaceDto) {
    this.onWorkspaceEdited(workspace);
  }

  get selectedFieldName(): string {
    return DataVisualizationStateFields.editedWorkspace;
  }

  get componentName(): string {
    return 'DataVisualizationWorkspaceEditorWidgetComponent';
  }

  onWorkspaceEdited(selectedWorkspace: WorkspaceDto): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: {
          workspace: selectedWorkspace,
          chartConfiguration: this.chartConfiguration,
          cartItems: this.cartItems,
        },
      })
    );
  }

  onErrorChanged(isValid: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.editedWorkspaceHasError,
        value: !isValid,
      })
    );
  }

  private setWorkspaceDefinition(value: WorkspaceDto) {
    const workspaceDefinition = this._workspacesHelperService.getWorkspaceDefinition(
      value.definition
    );
    this.workspaceDefinition = workspaceDefinition;
  }

  private setChartConfiguration(workspaceDefinition: WorkspaceDefinitionDto) {
    const chartConfiguration =
      this._workspacesHelperService.getChartConfiguration(workspaceDefinition);
    this.chartConfiguration = chartConfiguration[0];
  }

  private setCartConfiguration(workspaceDefinition: WorkspaceDefinitionDto) {
    const cartItems = this._workspacesHelperService.getCartFiltrableitems(workspaceDefinition);
    this.cartItems = cartItems;
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
