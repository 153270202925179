<button
  color="primary"
  mat-icon-button
  (click)="exportExcel()"
  [disabled]="disabled"
  [matTooltip]="T_SCOPE + '.tooltip' | translate | lowercase"
  class="wtr-button-primary"
>
  <mat-icon svgIcon="export-excel"></mat-icon>
</button>

<kendo-excelexport
  *ngIf="settings"
  [data]="settings.data"
  [collapsible]="true"
  [fileName]="settings.fileName"
>
  <kendo-excelexport-column
    *ngFor="let column of settings?.columns"
    [field]="column.fieldName"
    [locked]="column.locked"
    [title]="column.title"
    [cellOptions]="{ format: column.format }"
    [width]="200"
  >
  </kendo-excelexport-column>
</kendo-excelexport>
