import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { LocalStorageService } from '../../shared/local-storage.service';
import { DynamicGridSettings } from '../../shared/model/grid/dynamic-grid-settings';

import { GridSetting, IGridSettings } from '../../shared/constants/grid.constants';
import { NotificationGridSettingService } from '../../shared/notifications/notification-gridsetting.service';
import { GridPersistedElements } from '../generic-grid/generic-grid-constants';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';
import { LocalGridComponent } from '../local-grid/local-grid.component';
import { CellEditedInfoDto } from '../model/cell-edited-info.dto';

const COMPONENT_SELECTOR = 'wlm-dynamic-grid';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.scss'],
})
export class DynamicGridComponent implements OnInit {
  private _genericGrid;
  public get genericGrid() {
    return this._genericGrid;
  }
  @ViewChild(GenericGridComponent) public set genericGrid(value) {
    this._genericGrid = value;
    if (value) {
      this.genericGridLoaded.emit(value);
    }
  }

  private _dynamicGridSettings: DynamicGridSettings;
  public get dynamicGridSettings(): DynamicGridSettings {
    return this._dynamicGridSettings;
  }
  @Input() public set dynamicGridSettings(settings: DynamicGridSettings) {
    this._dynamicGridSettings = settings;

    this.setPersistency(settings);
    this.instanceGrid(settings);
  }

  //Inputs to load info

  private _dataBindingFilters: DataBindingFilters;
  public get dataBindingFilters(): DataBindingFilters {
    return this._dataBindingFilters;
  }
  @Input() public set dataBindingFilters(value: DataBindingFilters) {
    this._dataBindingFilters = value;
    this.loadGrid();
  }

  @Input() localData: any[];

  @Input() additionalFilters: Map<string, any>;

  //Input actions
  @Input() removeSelectionPersisted$: Observable<void> = new Observable<void>();
  @Input() reloadGrid$: Observable<void>;
  @Input() removeSelection$: Observable<void>;
  @Input() loadGrid$: Observable<any>;
  @Input() gridSettingsChanged$: Observable<IGridSettings> = new Observable<IGridSettings>();

  private _selectedItems: any[];
  public get selectedItems(): any[] {
    return this._selectedItems;
  }
  @Input()
  public set selectedItems(value: any[]) {
    if (value) {
      this._selectedItems = value;
      this.selectedItemsChange.emit(value);
    }
  }

  @Input() showSelectedItems = true;
  @Input() showSelectedList = true;
  @Input() selectedItemsByFilter: DataBindingFilters;
  @Input() entityPermission: string;

  //Outputs
  @Output() selectedItemChanged = new EventEmitter<any>();
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Output() selectedPersistedItemsChanged = new EventEmitter<void>();
  @Output() gridSettingsLoaded = new EventEmitter<IGridSettings>();

  @Output() localGridLoaded = new EventEmitter<LocalGridComponent>();
  @Output() genericGridLoaded = new EventEmitter<GenericGridComponent>();
  @Output() editedValuesChange = new EventEmitter<Map<any, Map<string, CellEditedInfoDto>>>();
  @Output() gridDataLoaded = new EventEmitter<void>();
  @Output() isInThePastChange = new EventEmitter<boolean>();

  //props
  gridSettings: IGridSettings;
  gridFilters: DataBindingFilters;
  gridSettingsReady$ = new EventEmitter<IGridSettings>();
  persistencyArea: string;
  usePersistency: boolean = true;
  alwaysPersistedKeys: string[] = [GridPersistedElements.Settings];

  constructor(
    private _gridSettingsService: GridSettingsService,
    private _notificationGridSettingService: NotificationGridSettingService,
    private _storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.initializeActions();
  }

  initializeActions() {
    //DONt remove it. It will be use when will continue the implementation of this component
    // this.loadGrid$.pipe(untilDestroyed(this)).subscribe((filters: DataBindingFilters) => {
    //   if (filters) {
    //     this.dataBindingFilters = filters;
    //   }
    // });

    this.gridSettingsChanged$
      ?.pipe(untilDestroyed(this))
      .subscribe((gridSettings: IGridSettings) => {
        this.gridSettings = gridSettings;
      });

    this.reloadGrid$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.genericGrid?.reloadGrid();
    });
  }

  instanceGrid(settings: DynamicGridSettings) {
    if (settings?.gridSettingName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridSettingsService
          .getGridSettingsByName(settings.gridSettingName)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (gridSettings) => {
              if (gridSettings) {
                gridSettings.notificationSetting =
                  this._notificationGridSettingService.getNotificationSettingsForGrid(
                    settings.gridSettingName
                  );

                this.setGridSettings(gridSettings);
              }
            },
          });
      }
    }
  }

  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        this.loadGrid();
      }
    });
  }

  getSelectedItem(selectedItem: any) {
    this.selectedItemChanged.emit(selectedItem);
  }

  onGridDataLoaded() {
    if (this.selectedItemsByFilter && this.genericGrid) {
      this.genericGrid.loadSelectedItemsByKeys(this.selectedItemsByFilter);
      this.selectedItemsByFilter = undefined;
    }

    this.gridDataLoaded.emit();
  }

  onIsInThePastChange(isInThePast: boolean): void {
    this.isInThePastChange.emit(isInThePast);
  }

  private loadGrid() {
    if (this.gridSettings) {
      this.gridFilters = this.dataBindingFilters;
    }
  }

  private setPersistency(settings: DynamicGridSettings) {
    this.persistencyArea = settings.persistencyArea;
    this.usePersistency = settings.usePersistence;
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
    this.gridSettingsLoaded.emit(this.gridSettings);

    if (this.dataBindingFilters) {
      this.loadGrid();
    }
  }

  private getPersisted(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    const storageKey = `${this.persistencyArea}-${key}`;
    if (this.persistencyArea && (this.usePersistency || this.alwaysPersistedKeys.includes(key))) {
      return this._storage.getTyped(storageKey, defaultValue, useLocalStorage);
    } else {
      this._storage.remove(storageKey, useLocalStorage);
    }

    return defaultValue;
  }
}
