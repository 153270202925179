import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { GridColumnHelperService } from '../../shared/helpers/grid-column-helper.service';
import { GridColumnSetting } from '../../shared/model/grid/grid-column-setting';

@Pipe({
  name: 'columnTitle',
})
export class ColumnTitlePipe implements PipeTransform {
  constructor(private _gridColumnHelper: GridColumnHelperService) { }

  transform(gridColumnSetting: GridColumnSetting): Observable<string> {
    return this._gridColumnHelper.getColumnTranslatedTitle(gridColumnSetting);
  }
}
