import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { LocalizationHelperService } from '../localization/localization-helper.service';

/**
 * This adapter is necessary to solve the case when the user manually writes a date in a date picker via input.
 * This process is different in some locales, as en-GF uses mm/dd/yyy and es-ES uses dd/mm/yy
 */

@Injectable()
export class CustomDatePickerAdapter extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) locale: string, private _localization: LocalizationHelperService) {
    super(locale, new Platform({}));
  }

  parse(value: string | number): Date | null {
    const result = this._localization.parseInputDate(value as string);
    return result;
  }

  format(date: Date, displayFormat: Object): string {
    const result = this._localization.formatInputDate(date);
    return result;
  }
}
