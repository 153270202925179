import { Injectable, Injector } from '@angular/core';
import { INetworkElementLarsAndSworpsSitesDto } from 'src/app/common-modules/dependencies/ne/network-element-lars-sworps-sites.dto';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkElementsLarsAndSworpsSitesService extends GridODataService<INetworkElementLarsAndSworpsSitesDto> {
  constructor(injector: Injector) {
    super(injector, '/api/ne/larssworps');
  }

  protected mapResponse(
    response: PagedResultDto<INetworkElementLarsAndSworpsSitesDto>
  ): PagedResultDto<INetworkElementLarsAndSworpsSitesDto> {
    return response;
  }
}
