import { CalendarExceptionTypesEnum } from './exception-list.enum';

export abstract class CalendarExceptionDtoBase {
  calendarId: string;
  exceptionId: string;
  abstract type: CalendarExceptionTypesEnum;

  constructor(init: Partial<CalendarExceptionDtoBase>) {
    Object.assign(this, init);
  }
}
