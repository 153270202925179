import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from '../../app-modules.enum';
import { IExportPdfComponent } from '../../exports/models/export-pdf-component';

const COMPONENT_SELECTOR = 'wlm-export-pdf-button';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './export-pdf-button.component.html',
  styleUrls: ['./export-pdf-button.component.scss'],
})
export class ExportPdfButtonComponent implements OnInit {
  @Input() exportPdfComponent: IExportPdfComponent;
  @Input() disabled: boolean;
  @Input() showLabel: boolean;

  get isEmpty(): boolean {
    return this.exportPdfComponent?.isEmpty();
  }

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {}

  exportToPdf() {
    this.exportPdfComponent?.exportToPdf();
  }
}
