<wlm-spinner-wrapper
  [showSpinner]="isLoading"
  [settings]="spinnerWrapperSettings"
  class="inherit-size"
>
  <div *ngIf="signalId" class="chart-container mf-small-infix">
    <div fixedSizeElement class="controls-container">
      <wlm-date-range-single-filter
        *ngIf="filterSettings"
        [settings]="filterSettings"
        [maxRangeSelected]="maxDaysFilterRange"
        [clearAll$]="clearAll$"
        [persistFilters$]="persistFilters$"
        (filtersChanged)="getDataBindingFilters($event)"
        (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
        (apply)="applyFilters()"
        (autoLoad)="onCheckAutoload()"
      ></wlm-date-range-single-filter>

      <div class="buttons-container">
        <button
          *hasPerm="['WLMDataValidationCrud', 'u']"
          mat-icon-button
          [matTooltip]="T_SCOPE + '.buttons.date-range' | translate | lowercase"
          (click)="activeHorizontalBrush()"
          onclick="this.blur()"
          color="primary"
        >
          <mat-icon
            [class]="isHorizontalBrushModeOn ? 'btn-selected' : ''"
            svgIcon="select-horizontally"
          ></mat-icon>
        </button>
        <button
          *hasPerm="['WLMDataValidationCrud', 'u']"
          mat-icon-button
          [matTooltip]="T_SCOPE + '.buttons.value-range' | translate | lowercase"
          (click)="activeVerticalBrush()"
          onclick="this.blur()"
          color="primary"
        >
          <mat-icon
            [class]="isVerticalBrushModeOn ? 'btn-selected' : ''"
            svgIcon="select-vertically"
          ></mat-icon>
        </button>
        <button
          *hasPerm="['WLMDataValidationCrud', 'u']"
          mat-icon-button
          [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
          (click)="onSave()"
          onclick="this.blur()"
          color="primary"
          [disabled]="!this.hasChanges"
        >
          <wlm-icon [icon]="'save'"></wlm-icon>
        </button>
        <button
          *hasPerm="['WLMDataValidationCrud', 'u']"
          mat-icon-button
          [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          (click)="discardChanges()"
          onclick="this.blur()"
          color="primary"
          [disabled]="!hasChanges"
        >
          <wlm-icon [icon]="'discard-changes'"></wlm-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          (click)="showDataPoints()"
          [matTooltip]="T_SCOPE + '.buttons.show-hide-datapoints' | translate | lowercase"
        >
          <mat-icon svgIcon="data_points"></mat-icon>
        </button>
      </div>
    </div>

    <wlm-editable-chart
      *ngIf="chartSettings"
      [chartSettings]="chartSettings"
      [activateBrushMode$]="activateBrush$"
      [updatePoints$]="updatePoints$"
      [discardAll$]="discardAll$"
      [saveAll$]="saveAll$"
      [updateRange$]="updateRange$"
      [applyValidationFunction$]="applyValidationFunction$"
      [size]="calculatedSize$ | async"
      (loadingEvent)="onChartLoading($event)"
      (selectPoint)="onSelectPoint($event)"
      (selectRange)="onSelectRange($event)"
      (hasChanges)="onHasChanges($event)"
      (saveCompleted)="onSaveCompleted($event)"
      (chartLoaded)="onChartLoaded($event)"
    ></wlm-editable-chart>
    <div
      fixedSizeElement
      class="chart-point-form-container"
      *hasPerm="['WLMDataValidationCrud', 'u']"
    >
      <form [formGroup]="form" class="chart-point-form">
        <div class="field">
          <span class="label" *ngIf="selectedChartPoint?.measureTimestamp">
            {{ selectedChartPointDate | date }}
          </span>
        </div>
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>{{ chartPointLabel$ | async }}</mat-label>
            <input matInput type="number" [formControlName]="chartPointFieldName" />
          </mat-form-field>
        </div>
      </form>
      <button
        mat-button
        class="wtr-action-button-primary btn-size-small"
        [disabled]="isLoading || form.pristine || !form.valid"
        (click)="onApplyForm()"
      >
        {{ 'common.apply' | translate | uppercase }}
      </button>
    </div>
  </div>
</wlm-spinner-wrapper>
