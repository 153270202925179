import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { HierarchyElementPathFilter } from '../../../../../common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { TelemetryGridFilterConfiguration } from '../../../wlm-filters/telemetry-filter/telemetry-filter-configuration';

import { SATelemetrySelectorSettings } from '../models/sa-telemetry-selector-settings';

const COMPONENT_SELECTOR = 'wlm-sa-point-selection';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-point-selection.component.html',
  styleUrls: ['./sa-point-selection.component.scss'],
})
export class SAPointSelectionComponent implements OnInit {
  @ViewChild(GenericGridComponent) genericGrid: GenericGridComponent;
  @Output() selectedPointsChange = new EventEmitter<ISignalTelemetryNullableViewDto[]>();

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(value: Observable<boolean>) {
    this._resetHandler$ = value;
    this.subscribeToResetWizard(this.resetHandler$);
  }
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  private _settings: SATelemetrySelectorSettings;
  public get settings(): SATelemetrySelectorSettings {
    return this._settings;
  }
  @Input() public set settings(v: SATelemetrySelectorSettings) {
    this._settings = v;
    this.telemetryFilterConfiguration = v?.telemetryFilterSettings;
  }

  private _telemetryFilterConfiguration: TelemetryGridFilterConfiguration;
  public get telemetryFilterConfiguration(): TelemetryGridFilterConfiguration {
    return this._telemetryFilterConfiguration;
  }
  public set telemetryFilterConfiguration(value: TelemetryGridFilterConfiguration) {
    this._telemetryFilterConfiguration = value;
    if (value) {
      this.configReady = true;
      this.initializeFilter();
    }
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'TelemetryPointsSelectionCrud';
  }

  configReady = false;
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  gridFilters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: GridSetting;
  gridName = 'TelemetryPointsSelection';

  // Filters fieldNames
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  selectedHierarchyElements: any;
  selectedHierarchyFamilyId: any;
  defaultHierarchyElementId = '';
  filterKey = 'hierarchyElementsIds';
  filterFields = [this.filterKey];
  autoloadFilter = true;
  persistencyLocation = 'session';
  selectedPoints: ISignalTelemetryNullableViewDto[];
  stepKey = 'telemetry-step';
  removeSelection$ = new ReplaySubject<void>();
  isReady: boolean;

  constructor(
    private _gridSettingService: GridSettingsService,
    private _cacheService: HttpCacheService
  ) {}

  ngOnInit(): void {
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        gridSettings.selectionPersistency = false;
        this.gridSettings = gridSettings;
      },
    });
  }

  initializeFilter() {
    const familyFilter = new BasicFilter(
      this.hierarchyElementFamilyFieldName,
      this.telemetryFilterConfiguration?.defaultFamilyId
    );
    const filters = new Map<string, IFilter>();
    filters.set(this.hierarchyElementFamilyFieldName, familyFilter);
    const newGridFilters = new DataBindingFilters();
    newGridFilters.filters = filters;
    this.gridFiltersForBinding = newGridFilters;
  }

  getDataBindingFilters(dataBindingFilters: DataBindingFilters) {
    if (dataBindingFilters && this.validateFilters(this.filterFields, dataBindingFilters)) {
      this.gridFilters = dataBindingFilters;

      if (this.telemetryFilterConfiguration.hierarchyElementId && this.autoloadFilter) {
        const heValue = (
          dataBindingFilters.filters.get(this.filterKey) as HierarchyElementPathFilter
        ).elementIds;

        if (
          JSON.stringify(heValue) ===
          JSON.stringify(this.telemetryFilterConfiguration.hierarchyElementId)
        ) {
          this.autoloadFilter = false;
        }
      }
    }
  }

  validateFilters(filterFields: string[], filterParameters: DataBindingFilters): boolean {
    let filtersAreValid = true;
    filterFields.forEach((filterName) => {
      filtersAreValid = filtersAreValid && filterParameters?.filters?.get(filterName) !== undefined;
    });

    return filtersAreValid;
  }

  loadGrid() {
    if (this.gridFilters) {
      const newGridFilters = new DataBindingFilters();
      newGridFilters.filters = this.gridFilters?.filters ?? new Map<string, IFilter>();
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  onSelectedItemsChange($event) {
    this.selectedPoints = $event;
    this.selectedPointsChange.emit(this.selectedPoints);
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onIsGridReady(isReady: boolean) {
    this.isReady = isReady;
  }

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this._cacheService
            .clearContainsInUrl(this.telemetryFilterConfiguration.persistencyArea) // clear selected rows in cache
            .then((success) => {
              this.clearAll$.next(); // notify all filters to perform clearAll
              this.genericGrid?.clearFilters();
              this.selectedPoints = [];
              this.removeSelection$.next();
              this.selectedPointsChange.emit(this.selectedPoints);
              this.resetCompleteHandler$.next();
            });
        }
      });
    }
  }
}
