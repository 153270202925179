import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationHelperService } from '../localization/localization-helper.service';

/**
 * Encapsulates and unifies validation functions and error messages.
 */

@Injectable()
export class ValidationService {
  private T_SCOPE = `common.validation`;
  constructor(private localization: LocalizationHelperService) {}

  /**
   * Obtains one of the mesages available in the ValidationMessages enum.
   * Messages are automatically translated to the selected language,
   * and they accept interpolation params.
   */
  getMessage(messageKey: string, interpolateParams?: any): Observable<string> {
    return this.localization.localizeSwitch(
      `${this.T_SCOPE}.${messageKey}`,
      (message) => message,
      interpolateParams
    );
  }
}
