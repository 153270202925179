<kendo-grid-column [field]="field" [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="column-cell">
      <wlm-date-duration-column-value
        [startDateFieldName]="startDateFieldName"
        [endDateFieldName]="endDateFieldName"
        [dataItem]="dataItem"
      >
      </wlm-date-duration-column-value>
    </span>
  </ng-template>
</kendo-grid-column>
