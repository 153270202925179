import { DynamicLayoutIdentity } from './dynamic-layout-identity';
import { DynamicLayoutItemSettings } from './dynamic-layout-item-settings';
import { DynamicLayoutStructure } from './dynamic-layout-structure';

export class DynamicLayoutSettings extends DynamicLayoutIdentity {
  // The combination of layoutKey and layoutArea uniquely identifies a dynamic layout setting.
  // They are also called "persistency settings".
  layoutKey: string;
  layoutArea: string;

  // The user code that owns the dynamic layout setting.
  currentUser: string;

  // The list of components (widgets) that the dynamic layout will render.
  items: DynamicLayoutItemSettings[] = [];

  // Redux communication settings. Can usually be used in default mode.
  widgetPage: string;
  widgetModule?: string = 'root';

  // Allows to override the persietency behavior by defining a new service.
  persistLayoutService?: string;
  ignoreParamsOnPersistencyCheck?: boolean;

  // More golden layout specific settings.

  disableReorder? = false;
  showPopoutIcon? = false;
  showMaximiseIcon? = true;
  showCloseIcon? = false; // Use showCloseWidgetIcon if this not works.
  showCloseWidgetIcon? = false;
  showResetIcon? = true;
  borderWidth? = 5;
  minItemHeight? = 10;
  minItemWidth? = 10;
  showStackCloseIcon? = false;
  hasHeaders?: boolean;
  labelClose? = 'close';
  labelMaximise? = 'maximise';
  labelMinimise? = 'minimise';
  labelPopout? = 'open in new window';
  labelReset? = 'reset layout';

  // Defines how we want the widgets to display initially, and when we reste the layout.
  defaultStructure?: DynamicLayoutStructure[];
  structure?: DynamicLayoutStructure[];

  // Useful when we want to break loops of actions and selectors.
  generatedId?: string;

  // When defined, the persistency is delegated to another component, typically another Dynamic Layout in the upper level.
  delegatePersistency?: {
    // id of the component that holds this settings.
    currentId: string;
    // id of the component that will handle the persistency.
    delegateToId: string;
  };

  debugName?: string;

  constructor(init: DynamicLayoutSettings) {
    super();
    Object.assign(this, init);
  }
}
