import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ObservablesService {
  private _map = new Map<string, Subject<any>>();

  emit(key: string, value): void {
    const subject$ = this.getOrCreateKey(key);
    subject$.next(value);
  }

  listen<T>(key: string): Observable<T> {
    const subject$ = this.getOrCreateKey(key);
    return subject$.asObservable();
  }

  private getOrCreateKey(key: string): Subject<any> {
    let subject$;
    if (this._map.has(key)) {
      subject$ = this._map.get(key);
    } else {
      // TODO: enable different subject implementations.
      subject$ = new ReplaySubject(1);
      this._map.set(key, subject$);
    }

    return subject$;
  }
}
