<kendo-grid-column
  [field]="field"
  [style]="style"
  [width]="width"
  [locked]="locked"
  matTooltipDisabled="true"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <wlm-alarm-counter
      [criticalAlarmsCounter]="getAlarmCounter(dataItem, alarmCriticalCounterField)"
      [highAlarmsCounter]="getAlarmCounter(dataItem, alarmHighCounterField)"
      [mediumAlarmsCounter]="getAlarmCounter(dataItem, alarmMediumCounterField)"
      [lowAlarmsCounter]="getAlarmCounter(dataItem, alarmLowCounterField)"
      [alarmSeverities]="alarmSeverities"
    ></wlm-alarm-counter>
  </ng-template>
</kendo-grid-column>
