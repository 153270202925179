import { CurrencyTypesEnum } from '../../shared/model/shared/currency-types.enum';
import { DimensionTypesEnum } from '../../shared/model/shared/dimension-types';
import { WaterBalanceTypesEnum } from './water-balance-types';

export class WaterBalanceParams {
  waterBalanceType?: WaterBalanceTypesEnum;
  startDate?: Date;
  endDate?: Date;
  dimensionTypeId?: DimensionTypesEnum;
  familyId?: string;
  hierarchyElementIds?: string[];
  kpis: number[];
  currencyTypeId?: CurrencyTypesEnum;

  constructor(init?: Partial<WaterBalanceParams>) {
    Object.assign(this, init);
  }
}
