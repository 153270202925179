<ng-template #menuInternal>
  <wlm-grid-buttons-internal
    [showHamburguer]="responsive && showHamburguer"
    [grid]="grid"
    [localGrid]="localGrid"
    [gridSettings]="gridSettings"
    [disable$]="disable$"
    [pageId]="pageId"
    [gridFilters]="gridFilters"
    [gridName]="gridName"
    [canLoad]="canLoad"
    [firstSlotContent]="firstSlotContent"
    [secondSlotContent]="secondSlotContent"
    [thirdSlotContent]="thirdSlotContent"
    (btnClick)="onbtnClick($event)"
    (btnCallback)="onbtnCallback($event)"
    (gridSettingsChange)="ongridSettingsChange($event)"
  >
  </wlm-grid-buttons-internal>
</ng-template>

<div *ngIf="responsive && showHamburguer" class="position-centered">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container [ngTemplateOutlet]="menuInternal"> </ng-container>
  </mat-menu>
</div>
<div
  *ngIf="!responsive || !isButtonsRequiredWidthCalculated || !showHamburguer"
  #gridButtonsInternalContainer
  [class.buttons-horizontal-container]="responsive"
>
  <ng-container [ngTemplateOutlet]="menuInternal"> </ng-container>
</div>
