import { GenericExtendedSelectorPayload } from './generic-extended-selector';
import { StateFullSettings } from './state-full-settings';
import { StateSelectorBuilder } from './state-selector-builder';

/**
 * It is the result of processing a StateSelectorBuilder.
 */

export class StateSelector {
  buildSelector: (settings: StateFullSettings, payload?: GenericExtendedSelectorPayload) => any;
  dependencies: string[];

  constructor(
    builder: StateSelectorBuilder,
    fn: (settings: StateFullSettings, payload?: GenericExtendedSelectorPayload) => any
  ) {
    Object.assign(this, builder);
    this.buildSelector = fn;
  }
}
