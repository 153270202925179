export enum DetectionStatus {
  Planned = 1, // planned in scheduled pending to be completed, doesn't appear in zones to be planned
  ToBeScheduled = 2, // appears in zones to be planned
  NotApplicable = 3, // planned and completed, so ready to be eligible again
}

export enum DetectionStatusText {
  'Planned' = 1, // planned in scheduled pending to be completed, doesn't appear in zones to be planned
  'To Be Scheduled' = 2, // appears in zones to be planned
  'Not Applicable' = 3, // planned and completed, so ready to be eligible again
}

export enum ConstPeriodicityEligibleReasonEnum {
  NoElegible = 0,
  TimeElapsedsincealldataavailable = 1,
  TimeElapsedsincelastleakagesurvey = 2,
  NoPeriodicity = 99,
}

export enum ConstPrioritisationElegibleReasonEnum {
  CostIndex = 3,
  Effectivness = 4,
  InterventionLevel = 5,
  MetersWithFault = 6,
  OpenALCTasks = 7,
  OutstandingBurstMains = 8,
  NoPrioritization = 100,
}

export enum ConstByUserReason {
  MNFAboveInterventionLevel = 31,
  TimeElapsedSinceMeterFault = 32,
  TimeElapsedSinceLastLeakage = 33,
  DetectionEfficiencyCost = 34,
  DetectionEfficiencyVolume = 35,
  LowPressure = 36,
  RiseInDZFlowUnableToReport = 37,
  OtherState = 38,
  IITInvestigation = 39,
  Breach = 40,
  OutstandingJob = 41,
  Legitimitate = 42,
  Parked = 43,
  DataWrong = 44,
}

interface IDimension {
  Height: string;
  Width: string;
}

export const EligibilityPopupDimensions: Readonly<IDimension> = {
  Height: '490px',
  Width: '780px',
};

export const SchematicImportPopupDimensions: Partial<IDimension> = {
  Width: '600px',
};
