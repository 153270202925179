import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { EditableChartPointDto } from '../models/editable-chart/editable-chart-point.dto';
import { EditableChartSelection } from '../models/editable-chart/editable-chart-selection';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GChartSerieDataPoint } from '../models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { BaseCartesianChartService } from './base-cartesian-chart.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseEditableChartService extends BaseCartesianChartService {
  protected editedChartPointSize: number = 10;

  protected arrayHelperService: ArrayHelperService;
  protected dateHelperService: DateHelperService;

  constructor(injector: Injector) {
    super(injector);

    this.arrayHelperService = injector.get('ArrayHelperService');
    this.dateHelperService = injector.get('DateHelperService');
  }

  abstract updateChartSerieByPoints(serie: GCartesianChartSerie, points: EditableChartPointDto[]);

  abstract saveData(
    currentData: EditableChartPointDto[],
    originalData: EditableChartPointDto[],
    element: unknown
  ): Observable<boolean>;

  abstract getDataByRangeAndFunction(
    data: EditableChartPointDto[],
    chartSelection: EditableChartSelection,
    element?: unknown
  ): Observable<EditableChartPointDto[]>;

  abstract setSettings(settings: GenericCartesianChartSettings): void;

  public updateVerticalAxisByDataPoints(
    settings: GenericCartesianChartSettings,
    points: GChartSerieDataPoint[]
  ) {
    const newMax = Math.max(...points.map((p) => p.pointValue));
    const newMin = Math.min(...points.map((p) => p.pointValue));

    const yAxes = settings.yAxes?.[0];
    if (yAxes) {
      yAxes.max = newMax;
      yAxes.min = newMin;
    }
  }

  extendsGenericCartesianChartSettings(
    settings: GenericCartesianChartSettings
  ): GenericCartesianChartSettings {
    if (!settings) {
      return null;
    }

    settings.series?.forEach((serie) => delete serie.symbol);

    return {
      ...settings,
      toolbox: {
        show: false,
      },
      brush: {
        toolbox: ['lineX', 'lineY', 'keep', 'clear'],
        xAxisIndex: 0,
      },
    };
  }
}
