<wlm-generic-chart
  *ngIf="genericChartSettings || isLoading"
  [settings]="genericChartSettings"
  [loadingHandler$]="loadingHandler$"
  [genericChartService]="genericChartService"
  [exportChart$]="exportChart$"
  [showDataPoints$]="showDataPoints$"
  [size]="size"
  (chartInitEvent)="onChartInit($event)"
  (chartDataZoomEvent)="onChartDataZoom($event)"
  (chartLegendSelectedEvent)="onChartLegendSelected($event)"
  (chartLoaded)="onChartLoaded($event)"
  (chartFinished)="onChartFinished($event)"
  (serieNamesUnselected)="onSerieNamesUnselected($event)"
></wlm-generic-chart>

<wlm-no-results [showNoResult]="!dataLoaded && !isLoading"> </wlm-no-results>
