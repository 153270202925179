import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicFormsModule } from 'src/app/common-modules/dynamic-forms/dynamic-forms.module';
import { LabelValueListModule } from 'src/app/common-modules/shared/core/label-value-list/label-value-list.module';
import { MultiplePipePieChartService } from 'src/app/common-modules/shared/pipes/multiple-pipe-pie-chart.service';
import { PipePieChartService } from 'src/app/common-modules/shared/pipes/new-pipe-pie-chart.service';
import { PipeGridODataService } from 'src/app/common-modules/shared/pipes/pipe-grid-odata.service';
import { GenericMappersModule } from 'src/app/common-modules/shared/services/generic-mappers/generic-mappers.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { HistoricalAlarmsGridService } from 'src/app/common-modules/wlm-grid/historical-alarms/historical-alarms-grid.service';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { PointConfigurationService } from '../configuration/point-configuration.service';
import { TelemetryPointsService } from '../configuration/telemetry-points.service';
import { CustomerModule } from '../customer/customer.module';
import { DistributionNetworkService } from '../dn/distribution-network-page/distribution-network.service';
import { MapModule } from '../map/map.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { PipePieChartComponent } from '../shared/pipes/pipe-pie-chart/pipe-pie-chart.component';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { TransmissionNetworkService } from '../tn/transmission-network-page/transmission-network.service';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { ActivityConfirmationGridComponent } from './activities-components/activity-confirmation-grid/activity-confirmation-grid.component';
import { ActivityGridFilterComponent } from './activities-components/activity-grid-filter/activity-grid-filter.component';
import { ActivityManualClosureComponent } from './activities-components/activity-manual-closure/activity-manual-closure/activity-manual-closure.component';
import { ActivityNotificationDetailComponent } from './activities-components/activity-notification-detail/activity-notification-detail.component';
import { ActivityOpenFilterComponent } from './activities-components/activity-open-filter/activity-open-filter.component';
import { OperationsBindingDirective } from './activities-components/activity-operation-grid/activity-operation-grid-binding.directive';
import { ActivityOperationGridComponent } from './activities-components/activity-operation-grid/activity-operation-grid.component';
import { ActivityTypeFilterComponent } from './activities-components/activity-type-filter/activity-category-type-filter.component';
import { ActivityCategoryTypesService } from './activities-components/activity-type-filter/activity-category-types.service';
import { ActivitiesGridService } from './activities-grid/activities-grid.service';
import { ActivitiesPageComponent } from './activities-page/activities-page.component';
import { ActivityConfirmationService } from './activity-confirmation.service';
import { ActivityOperationService } from './activity-operation.service';
import { ActivityRaiseService } from './activity-raise.service';
import { AlcLeaksActivitiesPanelComponent } from './alc-leaks-page/alc-leaks-activities-panel/alc-leaks-activities-panel.component';
import { AlcLeaksGridFilterComponent } from './alc-leaks-page/alc-leaks-grid-filter/alc-leaks-grid-filter.component';
import { AlcLeaksPageComponent } from './alc-leaks-page/alc-leaks-page.component';
import { AlcLeaksParentActivityComponent } from './alc-leaks-page/alc-leaks-parent-activity/alc-leaks-parent-activity.component';
import { AlcRoutingModule } from './alc-routing.module';
import { CampaignsCreationActivitiesComponent } from './campaigns-components/campaigns-creation-activities/campaigns-creation-activities.component';
import { CampaignsCreationGoalsComponent } from './campaigns-components/campaigns-creation-goals/campaigns-creation-goals.component';
import { CampaignsCreationInfoComponent } from './campaigns-components/campaigns-creation-info/campaigns-creation-info.component';
import { CampaignsCreationPopupComponent } from './campaigns-components/campaigns-creation-popup/campaigns-creation-popup.component';
import { RelatedActivitiesWidgetComponent } from './campaigns-components/related-activities-widget/related-activities-widget.component';
import { RelatedActivitiesComponent } from './campaigns-components/related-activities/related-activities.component';
import { AlcCampaignsService as ALCCampaignsService } from './campaigns-page/alc-campaigns.service';
import { CampaignsPageComponent } from './campaigns-page/campaigns-page.component';
import { PrioritisationFilterComponent } from './prioritisation-components/prioritisation-filter/prioritisation-filter.component';
import { PrioritisationGridService } from './prioritisation-grid/prioritisation-grid.service';
import { PrioritisationPageComponent } from './prioritisation-page/prioritisation-page.component';
import { RaiseActivityTreeFilterComponent } from './raise-activity-page/raise-activity-components/raise-activity-tree-filter/raise-activity-tree-filter.component';
import { RaiseActivityPageComponent } from './raise-activity-page/raise-activity-page.component';

@NgModule({
  declarations: [
    RaiseActivityPageComponent,
    ActivityNotificationDetailComponent,
    ActivityOperationGridComponent,
    ActivityConfirmationGridComponent,
    OperationsBindingDirective,
    ActivityTypeFilterComponent,
    ActivityGridFilterComponent,
    ActivityOpenFilterComponent,
    ActivityManualClosureComponent,
    PrioritisationFilterComponent,
    ActivitiesPageComponent,
    PrioritisationPageComponent,
    AlcLeaksPageComponent,
    AlcLeaksGridFilterComponent,
    AlcLeaksActivitiesPanelComponent,
    RaiseActivityTreeFilterComponent,
    PipePieChartComponent,
    CampaignsPageComponent,
    CampaignsCreationPopupComponent,
    CampaignsCreationInfoComponent,
    CampaignsCreationGoalsComponent,
    CampaignsCreationActivitiesComponent,
    RelatedActivitiesWidgetComponent,
    RelatedActivitiesComponent,
    AlcLeaksParentActivityComponent
  ],
  imports: [
    CommonModule,
    AlcRoutingModule,
    WlmSharedModule,
    MapModule,
    WlmChartsModule,
    CustomerModule,
    WLMGridModule,
    TranslateModule,
    WlmFiltersModule,
    DynamicFormsModule,
    LabelValueListModule,
    WlmSharedComponentModule,
    GenericMappersModule,
  ],
  providers: [
    ActivityRaiseService,
    { provide: 'ActivityRaiseService', useExisting: ActivityRaiseService },
    ActivityOperationService,
    { provide: 'ActivityOperationService', useExisting: ActivityOperationService },
    ActivityConfirmationService,
    { provide: 'ActivityConfirmationService', useExisting: ActivityConfirmationService },
    ActivityCategoryTypesService,
    { provide: 'ActivityCategoryTypesService', useExisting: ActivityCategoryTypesService },
    ActivitiesGridService,
    { provide: 'ActivitiesGridService', useExisting: ActivitiesGridService },
    PrioritisationGridService,
    { provide: 'PrioritisationGridService', useExisting: PrioritisationGridService },
    ALCCampaignsService,
    { provide: 'ALCCampaingsService', useExisting: ALCCampaignsService },
    HistoricalAlarmsGridService,
    { provide: 'HistoricalAlarmsGridService', useExisting: HistoricalAlarmsGridService },
    PipeGridODataService,
    { provide: 'PipeGridODataService', useExisting: PipeGridODataService },
    DistributionNetworkService,
    { provide: 'DistributionNetworkService', useExisting: DistributionNetworkService },
    TelemetryPointsService,
    { provide: 'TelemetryPointsService', useExisting: TelemetryPointsService },
    PointConfigurationService,
    { provide: 'PointConfigurationService', useExisting: PointConfigurationService },
    PipePieChartService,
    { provide: 'PipePieChartService', useExisting: PipePieChartService },
    MultiplePipePieChartService,
    { provide: 'MultiplePipePieChartService', useExisting: MultiplePipePieChartService },
    TransmissionNetworkService,
    { provide: 'TransmissionNetworkService', useExisting: TransmissionNetworkService },
  ],
})
export class ALCModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    ALCModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'ActivityOperationGridComponent',
        widgetClass: ActivityOperationGridComponent,
      },
      {
        name: 'AlcLeaksActivitiesPanelComponent',
        widgetClass: AlcLeaksActivitiesPanelComponent,
      },
      {
        name: 'RelatedActivitiesWidgetComponent',
        widgetClass: RelatedActivitiesWidgetComponent,
      },
      {
        name: 'ActivityConfirmationGridComponent',
        widgetClass: ActivityConfirmationGridComponent,
      },
      {
        name: 'ActivityNotificationDetailComponent',
        widgetClass: ActivityNotificationDetailComponent,
      },
      {
        name: 'PipePieChartComponent',
        widgetClass: PipePieChartComponent,
      },
      {
        name: 'AlcLeaksParentActivityComponent',
        widgetClass: AlcLeaksParentActivityComponent,
      },

    ]);
  }
}
