import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { SignalConfigurationSubcategory } from 'src/app/common-modules/dependencies/signals/models/signal-configuration-subcategory';
import { SignalConfigurationDto } from 'src/app/common-modules/dependencies/signals/models/signal-configuration.dto';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';

@Component({
  selector: 'wlm-data-visualization-item-details-row',
  templateUrl: './data-visualization-item-details-row.component.html',
  styleUrls: ['./data-visualization-item-details-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataVisualizationItemDetailsRowComponent implements OnChanges {
  @Input() category: string;
  @Input() subcategory: string;
  @Input() items: SignalConfigurationDto[];
  text$: Observable<string>;

  private readonly _localization = inject(LocalizationHelperService);

  ngOnChanges(changes: SimpleChanges): void {
    if (this.category && this.items) {
      this.calculateValue();
    }
  }

  private calculateValue(): void {
    let label$ = of('');
    if (this.subcategory !== SignalConfigurationSubcategory.Default) {
      label$ = this._localization.getOrDefault(
        `common.signals.config.subcategories.${this.subcategory}`,
        this.subcategory
      );
    }
    const value = this.items
      .map((item) => item.elementName)
      .sort()
      .join(', ');

    this.text$ = label$.pipe(map((label) => (label ? `<b>${label}</b> - ${value}` : value)));
  }
}
