import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseFormComponent } from 'src/app/common-modules/shared/component/base-form.component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DateValidators } from 'src/app/common-modules/shared/date-range-filter/date-validator';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { NotificationAttributeDto } from 'src/app/common-modules/shared/model/notifications/notification-attribute.dto';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { UserDto } from 'src/app/common-modules/shared/model/roles/user.dto';
import { FormModes } from 'src/app/common-modules/shared/model/shared/form-mode.enum';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';
import { NotificationSelectionService } from 'src/app/common-modules/shared/notifications/notification-selection.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { UsersGridODataService } from 'src/app/common-modules/shared/services/users/users.service';
import { NotificationService } from '../../../monitoring/notification/services/notification.service';
import { AlarmSeverity } from '../../alarms/alarms-severity.enum';

const COMPONENT_SELECTOR = 'wlm-notification-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss'],
})
export class NotificationFormComponent
  extends BaseFormComponent<NotificationDto, NotificationDto>
  implements OnInit
{
  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;
  fieldScope = `${this.T_SCOPE}.fields`;

  // Selector options
  allUsers: SelectOption<string>[];
  allGroups: SelectOption<string>[];
  severities: SelectOption<number>[];
  attributes: NotificationAttributeDto[];
  // Combines selected users from "users" and "usersCC".
  selectedUserIds: string[] = [];

  attributesGridSettings: IGridSettings;
  attributesGridName = 'ColumnValues';
  defaultTargetDate = new Date();
  currentUser: UserDto;
  focusOnUsers = true;

  constructor(
    private _fb: UntypedFormBuilder,
    private _usersService: UsersGridODataService,
    private _sharedConstants: SharedConstantsService,
    private _notificationSelectionService: NotificationSelectionService,
    private _notificationsService: NotificationService,
    private _dialogService: DialogService,
    private _dateHelper: DateHelperService,
    private _gridSettings: GridSettingsService,
    private _globalsService: GlobalsService,
    private _adapter: DateAdapter<any>,
    private _localization: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this._adapter.setLocale(this._localization.currentLocale);
    this.loading = true;
    // Initialize the form.
    this.initForm();
    this.coordinateUserSelectors();
  }

  protected loadDependencies(): Observable<void> {
    return forkJoin([
      this._usersService.getUsersSelectOptions(),
      // this._usersService.getGroups(),
      this._sharedConstants.getSeveritiesArray(),
      this._notificationSelectionService.getCurrentSelection().pipe(take(1)),
      this._gridSettings.getGridSettingsByName(this.attributesGridName),
      this._globalsService.getCurrentUser(),
    ]).pipe(
      tap(([users, /*groups,*/ severities, attributes, gridSettings, currentUser]) => {
        this.allUsers = users;
        // this.allGroups = SelectOption.mapMany<string, GroupDto>(
        //   groups,
        //   (g) => g.groupName,
        //   (g) => g.groupName
        // );
        this.severities = severities;
        let currentAttributes = attributes?.attributes;
        if (this.mode === FormModes.Edit) {
          currentAttributes = this.model?.attributes;
        }

        this.attributes = currentAttributes;
        gridSettings.pageSize = this.attributes?.length ?? 10;
        gridSettings.hideHeader = true;
        // in case we decide to use pager
        // gridSettings.pageable = {
        //   type: 'input',
        //   pageSizes: [10, 20, 50],
        // };
        this.attributesGridSettings = gridSettings;
        this.currentUser = currentUser;
        this.setCurrentUserAsDefaultRecipient(this.form?.get('to').value ?? []);

        this.loading = false;
        this.loaded.next(this.loading);
      }),
      map((_) => null)
    );
  }

  protected createForm(): void {
    const V = Validators;

    if (this.mode === FormModes.Edit) {
      this.defaultTargetDate = this.model.creationDate ?? this.defaultTargetDate;
    }

    this.form = this._fb.group(
      {
        notificationTitle: [null, [V.required, V.maxLength(100)]],
        criticality: [null, [V.required]],
        targetDate: [null],
        notificationDescription: [null, [V.required, V.maxLength(1000)]],
        to: [[], [V.required]],
        groups: [[]],
      },
      {
        validators: DateValidators.dateNewerEqThanValue('targetDate', this.defaultTargetDate),
      }
    );
  }

  protected preprocessModel(model: NotificationDto): NotificationDto {
    return model;
  }

  protected assignModelToForm(): Observable<void> {
    const returnedTargetDate =
      this.model.targetDate ?? this.model.creationDate ?? this.defaultTargetDate;

    const targetDate = this._dateHelper.fromApiFormat(returnedTargetDate.toString());

    this.setCurrentUserAsDefaultRecipient(this.model.to);
    this.form.patchValue({
      notificationTitle: this.model.notificationTitle ?? null,
      criticality: this.model.criticality ?? AlarmSeverity.Medium,
      targetDate,
      notificationDescription: this.model.notificationDescription ?? null,
      // groups: this.model.groups ?? [],
    });

    if (this.mode === FormModes.Edit) {
      this.form.markAllAsTouched();
    }
    return of();
  }

  private setCurrentUserAsDefaultRecipient(currentRecipients: string[]): void {
    const to =
      currentRecipients && currentRecipients.length
        ? currentRecipients
        : this.currentUser
        ? [this.currentUser.userCode]
        : [];
    this.form?.get('to')?.setValue(to);
  }

  protected beforeSubmit(): Observable<NotificationDto> {
    const targetDate = this._dateHelper.ensureDateObject(this.form.get('targetDate').value);
    const data = new NotificationDto({
      ...this.model,
      ...this.form.getRawValue(),
      targetDate,
      attributes: this.model.attributes ?? this.attributes,
    });
    return of(data);
  }

  protected submitFn(model: NotificationDto): Observable<NotificationDto> {
    if (this.mode === FormModes.Create) {
      return this._notificationsService.create(model);
    } else {
      return this._notificationsService.update(model);
    }
  }

  protected afterSubmit(result: NotificationDto): Observable<void> {
    this.submitResult.next(result);
    const dialogSettings = new WlmDialogSettings({
      icon: 'success',
      translateKey:
        this.mode === FormModes.Create
          ? `${this.T_SCOPE}.create-success`
          : `${this.T_SCOPE}.update-success`,
    });
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
    return of();
  }

  protected checkValid(): Observable<boolean> {
    return of(this.form.valid);
  }

  private coordinateUserSelectors(): void {
    const controlA = this.form.get('to');
    controlA.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selectedUserIds = value;
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
