/**
 * Helper class. Can be mapped to markArea and to visualMap
 */

export class GChartColoredPiece {
  start: string;
  end: string;
  color: string;
  entityId?: any;

  constructor(init?: Readonly<GChartColoredPiece>) {
    Object.assign(this, init);
  }
}
