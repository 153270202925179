import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VersionInfoDto } from 'src/app/common-modules/dependencies/navigation/version-info.dto';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionInfoService extends BaseService {
  varsionMapping: Map<string, string> = new Map<string, string>([
    ['5.1.', '5.1.'],
    ['5.2.', '2023.1.'],
    ['5.3.', '2023.2.'],
    ['5.4.', '2023.2.1.'],
    ['5.5.', '2024.1.']
  ]);

  constructor(injector: Injector) {
    super(injector);
  }

  get endpoint() {
    return `version`;
  }

  public getVersion(): Observable<VersionInfoDto> {
    return this.get<VersionInfoDto>(this.endpoint).pipe(
      map((versions: VersionInfoDto) => {
        versions.productsVersion['1-ui'] = this.getVersionMapped(environment.version);
        versions.productsVersion['2-server'] = this.getVersionMapped(
          versions.productsVersion['2-server']
        );
        versions.productsVersion['3-database'] = this.getVersionMapped(
          versions.productsVersion['3-database']
        );

        return versions;
      })
    );
  }

  private getVersionMapped(version: string): string {
    for (const [prefix, mappedValue] of this.varsionMapping) {
      if (version.startsWith(prefix)) {
        return version.replace(prefix, mappedValue);
      }
    }

    return version;
  }
}
