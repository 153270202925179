<wlm-spinner-wrapper
  [showSpinner]="schematic && !isAllSynced"
  [settings]="spinnerWrapperSettings"
  class="inherit-size"
>
  <div class="schematic-header">
    <wlm-buttons-group *ngIf="schematic && editMode" class="editor-buttons">
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.disable-map' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="currentOperation !== operationMode.None"
        (click)="onDisableMap()"
      >
        <mat-icon class="material-icon">map</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.create-node' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None && currentOperation !== operationMode.CreateNode
        "
        (click)="onCreateNode()"
      >
        <mat-icon class="material-icon">circle</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.create-relation' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None &&
          currentOperation !== operationMode.CreateRelation
        "
        (click)="onCreateRelation()"
      >
        <mat-icon class="material-icon">linear_scale</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.edit-nodes' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None && currentOperation !== operationMode.EditNode
        "
        (click)="onEditNode()"
      >
        <mat-icon class="material-icon">edit_road</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.drag-nodes' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None && currentOperation !== operationMode.DragDrop
        "
        (click)="onDrag()"
      >
        <mat-icon class="material-icon">animation</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.delete-nodes' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None && currentOperation !== operationMode.DeleteNode
        "
        (click)="onDeleteNode()"
      >
        <mat-icon class="material-icon">block</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.delete-relations' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="
          currentOperation !== operationMode.None &&
          currentOperation !== operationMode.DeleteRelation
        "
        (click)="onDeleteRelation()"
      >
        <mat-icon class="material-icon">mobiledata_off</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!hasChanges"
        (click)="onSave()"
      >
        <wlm-icon [icon]="'save'"></wlm-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!hasChanges"
        (click)="onReset()"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.close-editor' | translate | lowercase"
        onclick="this.blur()"
        (click)="onCloseEditor()"
      >
        <wlm-icon icon="cancel"></wlm-icon>
      </button>
    </wlm-buttons-group>
  </div>
  <div *ngIf="nodeFormVisible" class="node-form">
    <wlm-schematics-create-node-form
      [nodeFormDefinition]="nodeFormDefinition"
      [nodeForm]="nodeForm"
      (saveNode)="onSaveNode($event)"
      (cancelForm)="onCancelForm()"
    ></wlm-schematics-create-node-form>
  </div>
  <div *ngIf="schematic && loadedSchematic" class="search-container">
    <wlm-schematic-search
      class="search-box"
      [nodes]="updatedModel?.nodes"
      (optionSelected)="onOptionSelected($event)"
    ></wlm-schematic-search>
  </div>

  <div *ngIf="schematic" class="inherit-size">
    <wlm-schematic-chart
      [chartSettings]="chartSettings"
      [toggleMapOpacity$]="toggleMapOpacity$"
      (loadedSchematic)="onLoadedSchematic($event)"
      (updatedDataPoints)="onUpdatedDataPoints($event)"
      (chartLoaded)="onChartFinished($event)"
      (externalLoading)="onExternalLoading($event)"
      (isAllSynced)="onIsAllSynced($event)"
      (lastClickedPositionChange)="onLastClickedPositionChange($event)"
      (modelChange)="onModelChange($event)"
      (notifyNodeInfo)="onNotifyNodeInfo($event)"
    ></wlm-schematic-chart>
  </div>
  <wlm-placeholder-overlay
    *ngIf="!schematic"
    class="no-schematic-selected"
    [titleKey]="T_SCOPE + '.placeholder-title'"
  ></wlm-placeholder-overlay>
</wlm-spinner-wrapper>
