<div id="center-page">
  <div class="top-container">
    <h3>{{ T_SCOPE + '.title' | translate }}</h3>

    <div class="filter-buttons-container"></div>
  </div>

  <hr class="separator" />

  <div id="center-container">
    <div>
      <kendo-scrollview
        #sv
        [data]="items"
        [width]="width"
        [height]="height"
        [arrows]="true"
        [animate]="true"
        [pageable]="true"
        [endless]="true"
      >
        <ng-template let-item="item">
          <img
            class="center"
            src="{{ item.url }}"
            alt="{{ item.title }}"
            [ngStyle]="{ height: '100%', minWidth: 'auto' }"
            draggable="false"
          />
          <a href="{{ item.attribution }}"></a>
        </ng-template>
      </kendo-scrollview>

      <p>
        Water Loss is one of the most significant sustainability issues facing today’s water
        industry. Billions of gallons of water are lost through leaking pipes every day while water
        shortages continue to grow across the globe. Leaks tend to occur again and again, usually
        with more frequency over time. The current short-term approach for most utilities is to
        periodically conduct a network survey and to find the leaks. Once found, the leaks are
        repaired, and the resulting efficiency improvements are measured to evaluate the benefits of
        the repairs. On the other hand, if hydraulic conditions in the network do not change or
        structural interventions are not put in place, the problem is likely to repeat itself in
        short order. This is the reason why investment in pressure management implementation and
        water mains and service connections replacement should be considered. Such actions will help
        reduce the “baseline leakage level”. AVEVA Water Loss Management is the AVEVA’s solution
        aimed at optimising leakage management, it allows utilities to leverage the water network
        data, which in-turn detects leaks and improve response time. AVEVA Water Loss Management is
        a Big Data Platform which supports the management of the entire water loss cycle. It is
        based on the International Water Association (IWA) approach for water loss reduction and
        control and UK water industry best practices (UKWIR).
      </p>
    </div>
  </div>
</div>
