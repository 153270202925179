<div *ngIf="selectedNE" class="pressure-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="pressure-column">
    <div class="pressure-group">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [reloadList$]="reloadAvailableList$"
        [(excludeList)]="excludedPressureSignals"
        (somethingChanged)="onAvailableSomethingChanged()"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="pressure-column data-column">
    <div class="pressure-group top-group-adjust">
      <div class="top-title">
        <div class="ne-component-title top-title-position">
          {{ T_SCOPE + '.azp-title' | translate | uppercase }}
        </div>

        <div>
          <button
            [disabled]="!configurationHasChanged || hasInvalidOffsetValue"
            mat-icon-button
            *hasPerm="['WLMNetworkCrud', 'u']"
            class="button-margin"
            (click)="onSave()"
            color="primary"
            [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'save'"></wlm-icon>
          </button>
          <button
            [disabled]="!configurationHasChanged"
            *hasPerm="['WLMNetworkCrud', 'u']"
            mat-icon-button
            class="button-margin"
            (click)="discard()"
            color="primary"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>
      </div>
      <!-- drag list azp-->
      <wlm-drag-list-custom
        class="list-resized"
        [objectsToOrder]="configuredAzp"
        [hideFilter]="true"
        [settings]="azpListSettings"
        (droppedElement)="onDroppedElement($event, signalType.AZP)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <div *ngIf="!pressureSettings.hideCPP" class="pressure-group group-adjust">
      <mat-divider></mat-divider>
      <div class="top-title">
        <div class="ne-component-title title-position">
          {{ T_SCOPE + '.cpp-title' | translate | uppercase }}
        </div>
      </div>
      <!-- drag list cpp-->
      <div [ngClass]="cppOffset ? 'disable-list' : ''">
        <wlm-drag-list-custom
          class="list-resized"
          [objectsToOrder]="configuredCpp"
          [hideFilter]="true"
          [settings]="cppListSettings"
          (droppedElement)="onDroppedElement($event, signalType.CPP)"
        >
          <ng-template let-rowDetail #card>
            <wlm-drag-list-card
              class="card-width"
              [item]="rowDetail"
              [settings]="cardSettings"
            ></wlm-drag-list-card>
          </ng-template>
        </wlm-drag-list-custom>
      </div>
      <div class="offset-controls">
        <div class="ne-component-title offset-title-position">
          <div>{{ T_SCOPE + '.offset-title' | translate | uppercase }} {{ offsetUnit }}</div>
        </div>
        <mat-form-field
          class="mat-field mf-padding-small mf-reset-width custom-small-field offset-field"
          appearance="outline"
        >
          <input
            matInput
            class="offset-align"
            [(ngModel)]="cppOffset"
            [disabled]="disableCppOffset || isReadOnly"
            autocomplete="off"
            placeholder="{{ T_SCOPE + '.cpp-offset-placeholder' | translate }}"
            [wlmInputValidate]="[
              [customValidators.onlyNumeric, customValidators.onlyNegativeNumber]
            ]"
          />
          <mat-hint class="offset-hint-position" align="end"
            >{{ T_SCOPE + '.cpp-offset-hint' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="!pressureSettings.hideMPP" class="pressure-group group-adjust">
      <mat-divider></mat-divider>
      <div class="top-title">
        <div class="ne-component-title title-position">
          {{ T_SCOPE + '.mpp-title' | translate | uppercase }}
        </div>
      </div>
      <!-- drag list mpp-->
      <div [ngClass]="mppOffset ? 'disable-list' : ''">
        <wlm-drag-list-custom
          class="list-resized"
          [objectsToOrder]="configuredMpp"
          [hideFilter]="true"
          [settings]="mppListSettings"
          (droppedElement)="onDroppedElement($event, signalType.MPP)"
        >
          <ng-template let-rowDetail #card>
            <wlm-drag-list-card
              class="card-width"
              [item]="rowDetail"
              [settings]="cardSettings"
            ></wlm-drag-list-card>
          </ng-template>
        </wlm-drag-list-custom>
      </div>
      <div class="offset-controls">
        <div class="ne-component-title offset-title-position">
          {{ T_SCOPE + '.offset-title' | translate | uppercase }} {{ offsetUnit }}
        </div>
        <mat-form-field
          class="mat-field mf-padding-small mf-reset-width env-input env-input-pers-temporal custom-small-field offset-field"
          appearance="outline"
        >
          <input
            matInput
            class="offset-align"
            [(ngModel)]="mppOffset"
            [disabled]="disableMppOffset || isReadOnly"
            autocomplete="off"
            placeholder="{{ T_SCOPE + '.mpp-offset-placeholder' | translate }}"
            [wlmInputValidate]="[
              [customValidators.onlyNumeric, customValidators.onlyPositiveNumber]
            ]"
          />
          <mat-hint class="offset-hint-position" align="end"
            >{{ T_SCOPE + '.mpp-offset-hint' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
