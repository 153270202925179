import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { SignalInfoDto } from 'src/app/common-modules/dependencies/shared/model/signal-info.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

const endpointUrl = '/api/large-user';
@Injectable()
export class LargeUserAvailableSignalsService extends GridODataService<SignalInfoDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available-signals`);
  }

  protected mapResponse(response: PagedResultDto<SignalInfoDto>): PagedResultDto<SignalInfoDto> {
    return response;
  }
}
