import { Component, Input } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { IAppAttributeDto } from '../app-attribute.dto';

@Component({
  selector: 'wlm-app-attributes-form',
  templateUrl: './app-attributes-form.component.html',
  styleUrls: ['./app-attributes-form.component.scss'],
})
export class AppAttributesFormComponent extends IntegrableForm {
  @Input() initialModel: IAppAttributeDto;
  model: IAppAttributeDto;

  onModelChange(model: IAppAttributeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  formOptions: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'attributeId',
          type: 'input',
          className: 'col-3',
          props: {
            label: 'Attribute Id',
            disabled: true,
          },
        },
        {
          key: 'attributeName',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Attribute Name',
            disabled: true,
          },
        },
        {
          key: 'attributeDataTypeId',
          type: 'input',
          className: 'col-3',
          props: {
            label: 'Attribute Type Id',
            disabled: true,
          },
        },
        {
          key: 'attributeValue',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Attribute Value',
            required: true,
          },
        },
      ],
    },
  ];
}
