import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { DayTypesEnum } from 'src/app/common-modules/shared/model/shared/day-types.enum';
import { DateRangeAndMode } from '../../../../common-modules/common-filters/components/derivated/date-range-and-mode/date-range-and-mode';
import { DateRangeAndModeSettings } from '../../../../common-modules/common-filters/components/derivated/date-range-and-mode/date-range-and-mode.settings';
import { CalendarModeEnum } from '../../../../common-modules/dependencies/alarms/calendar-mode.enum';
import { BaseFilterItemSettings } from '../../../../common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FiltersPayload } from '../../../../common-modules/dependencies/wlm-filters/filters-payload';

import { DateRangeFISettings } from 'src/app/common-modules/common-filters/models/date-range-fi-settings';
import { EnvelopesConfiguration } from 'src/app/common-modules/dependencies/alarms/envelopes-configuration';
import { ProfileConfigurationDto } from 'src/app/common-modules/dependencies/alarms/profile-configuration.dto';
import { ProfileResultDto } from 'src/app/common-modules/dependencies/alarms/profile-result.dto';
import { BaseFilterSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { ConstantsValues } from 'src/app/common-modules/shared/constants/constants-values';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { EnvelopeMode } from '../../../../common-modules/dependencies/alarms/envelope-modes.enum';
import { ProfilesService } from '../profiles-chart/profiles.service';

const COMPONENT_SELECTOR = 'wlm-alarm-config';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-config.component.html',
  styleUrls: ['./alarm-config.component.scss'],
})
export class AlarmConfigComponent implements OnInit, IPendingChangesEmitter {
  @Input() pageId: string;

  @Input() public set defaultModel(model: ProfileConfigurationDto) {
    if (!model) {
      return;
    }

    this._profileService.convertDefaultUnits(model, 'from').subscribe((convertedModel) => {
      this.model = convertedModel;
      this.processModel(this.model);
      this.initialModel = this._objectHelper.clone(this.model);
      this.fillForm();
    });
  }

  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }
  @Input() public set disabled(value) {
    this._disabled = value;
    this.setDisableFilterSetting(value);
  }

  @Output() configSave = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() isDefault = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;
  // Main model of the form, where all data is gathered.
  model: Partial<ProfileConfigurationDto> = {};
  initialModel: Partial<ProfileConfigurationDto>;

  // Specify which model properties will be populated.
  startDateName = 'periodStartDate';
  endDateName = 'periodEndDate';
  periodIsFixedName = 'periodIsFixed';
  periodDaysName = 'periodDays';
  seasonsName = 'seasons';
  calendarName = 'calendars';
  // This name  will be mapped to 'calendarIsWeek' and 'calendarIsWeeked'.
  dayTypeName = 'dayTypes';

  baseFilterSettings = new BaseFilterSettings({
    formMode: true, // Disable all action buttons.
    disableSearch: true,
  });
  itemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'none',
    required: true,
    hideSelectAllCheckbox: false,
  });

  // Date selector config. Includes field names and defaults config.
  dateSelectorSettings = new DateRangeAndModeSettings({
    dateRangeSettings: new DateRangeFISettings({
      ...(this.itemSettings as any),
      disableRestoreOnFixed: true,
    }),
  });

  // Seasons filter config.
  seasonsBaseSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.seasons-title`,
  };
  seasonsItemSettings = { ...this.itemSettings, required: false };
  seasonsSelectedIds: string[] = [];

  // Day types filter config.
  dayBaseSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.day-title`,
  };
  dayItemSettings = { ...this.itemSettings, required: false };
  dayTypesSelectedIds: number[] = [];

  // Calendar filter config.
  calendarBaseSettings = {
    ...this.baseFilterSettings,
    expandedMinWidth: '275px',
    expandedMaxWidth: '375px',
    inputLabelKey: `${this.T_SCOPE}.calendar-title`,
    disableSelectAll: true,
  };
  calendarModeField = 'calendarMode';
  calendarItemSettings = new BaseFilterItemSettings({ ...this.itemSettings, required: false });
  selectedCalendarsWithParams: { [key: string]: { [key: string]: any } } = {};

  // Envelopes config
  defaultEnvelopes: EnvelopesConfiguration;
  envelopesAreDefault = true;
  uoMParams: { dimensionTypeId: number; timeAggregationId: number; hierarchyElementTypeId: string };

  private _signalTypeId = ConstantsValues.emptyGuid;

  private _formIsDefault = true;
  public get formIsDefault() {
    return this._formIsDefault;
  }
  public set formIsDefault(value) {
    if (this._formIsDefault !== value) {
      const isValid = !value && this.isValid;
      this.setPendingChanges(this.pageId, this.getPendingChanges(isValid));
    }
    this._formIsDefault = value;
  }

  private _isValid = false;
  public get isValid() {
    return this._isValid;
  }
  public set isValid(value) {
    if (this._isValid !== value) {
      const isValid = value && !this.formIsDefault;
      this.setPendingChanges(this.pageId, this.getPendingChanges(isValid));
    }

    this._isValid = value;
  }

  dateRangeApply$ = new Subject<void>();
  dateRangeClearAll$ = new Subject<void>();
  seasonsClearAll$ = new Subject<void>();
  seasonsApply$ = new Subject<void>();
  daysClearAll$ = new Subject<void>();
  daysApply$ = new Subject<void>();
  calendarsClearAll$ = new Subject<void>();
  calendarsApply$ = new Subject<void>();
  envelopesClearAll$ = new Subject<void>();

  datesValid = false;
  seasonsValid = true;
  dayTypesValid = false;
  calendarValid = true; // Not mandatory
  envelopesValid = false;

  modesToRemove: EnvelopeMode[] = [];

  private _isLoading = false;

  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
    this.loading.emit(this.isLoading);
  }

  get componentName(): string {
    return 'AlarmConfigComponent';
  }

  constructor(
    private _profileService: ProfilesService,
    private _dialogService: DialogService,
    private _objectHelper: ObjectHelperService,
    private _datesHelper: DateHelperService,
    private _settingsService: SettingsService,
    private _pendingChangesService: PendingChangesManagerService
  ) {}

  ngOnInit(): void {}

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }
  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  /**
   * Updates the form with the model's data.
   */
  fillForm(): void {
    this.initDatesPeriod();
    this.initSeasons();
    this.initDayTypes();
    this.initCalendars();
    this.initEnvelopes();
  }

  private setDisableFilterSetting(value: boolean) {
    this.seasonsBaseSettings.disableFilter = value;
    this.dayBaseSettings.disableFilter = value;
    this.calendarBaseSettings.disableFilter = value;
  }

  onDatesChange(data: DateRangeAndMode): void {
    this.model[this.startDateName] = data.periodStartDate;
    this.model[this.endDateName] = data.periodEndDate;
    this.model[this.periodIsFixedName] = data.periodIsFixed;
    this.model[this.periodDaysName] = data.periodDays;
    this.processModel(this.model);
    this.datesValid = true;
    this.notifyChanges();
  }

  onFilterSeasons(filters: FiltersPayload): void {
    const seasons = filters.data.get(this.seasonsName)?.value?.map((v) => v.value);
    const selectedSeasons = seasons ?? [];
    this.model[this.seasonsName] = selectedSeasons;
    this.seasonsValid = true;
    this.notifyChanges();
  }

  onFilterDayTypes(filters: FiltersPayload): void {
    const types = filters.data.get(this.dayTypeName)?.value?.map((v) => v.value);
    const selected = types ?? [];
    this.model.calendarIsWeekend = Boolean(selected.find((type) => type === DayTypesEnum.Weekend));
    this.model.calendarIsWeek = Boolean(selected.find((type) => type === DayTypesEnum.WorkingDay));
    this.dayTypesValid = true;
    this.notifyChanges();
  }

  onFilterCalendar(filters: FiltersPayload): void {
    const hash: { [key: string]: boolean } = {};
    const selected = filters.data.get(this.calendarName)?.value ?? [];
    selected.forEach((calendar) => {
      const calendarMode = calendar.params ? calendar.params[this.calendarModeField] : null;
      hash[calendar.value] = calendarMode === CalendarModeEnum.Inclusive;
    });

    this.model[this.calendarName] = hash;
    this.notifyChanges();
  }

  onEnvelopesConfig(data: EnvelopesConfiguration): void {
    Object.assign(this.model, data);
    this.envelopesValid = true;
    this.notifyChanges();
  }

  onEnvelopesValid = (valid) => {
    this.envelopesValid = valid;
    this.notifyChanges();
  };

  onDatesValid = (valid) => {
    this.datesValid = valid;
    this.notifyChanges();
  };

  notifyChanges(): void {
    this.isValid = this.checkValid();
    this.checkIsDefault();
  }

  checkValid(): boolean {
    return (
      this.datesValid &&
      this.seasonsValid &&
      this.dayTypesValid &&
      this.calendarValid &&
      this.envelopesValid
    );
  }

  /**
   * Check if the form current values are the same as the default model.
   * Emit an event with the result.
   */
  private checkIsDefault(): void {
    const model = this._objectHelper.cloneWithoutNulls(this.model);
    const initialModel = this._objectHelper.cloneWithoutNulls(this.initialModel);
    const areSame = this._objectHelper.deepEqual(model, initialModel);
    this.formIsDefault = areSame && this.envelopesAreDefault;
    this.isDefault.next(this.formIsDefault);
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(hasToReload = true): Observable<boolean> {
    if (!this.checkValid()) {
      return of(true);
    }

    this.isLoading = true;

    return this._profileService
      .convertDefaultUnits(this.model as ProfileConfigurationDto, 'to')
      .pipe(
        switchMap((convertedProfile) => {
          return this._profileService.saveProfileConfiguration(convertedProfile);
        }),
        finalize(() => (this.isLoading = false)),
        switchMap((result) => this._profileService.convertDefaultUnits(result, 'from')),
        map((r) => r as ProfileResultDto),
        tap((result) => {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.save-success`,
              icon: 'success',
            })
          );

          this.initialModel = this._objectHelper.clone(this.model);
          this.formIsDefault = true;
          this.isDefault.next(this.formIsDefault);
          // Results units are still in default.
          this.configSave.emit({ result, hasToReload });
        }),
        catchError((error) => {
          this._dialogService.showErrorMessage(error);

          return of(null);
        }),
        map(() => true)
      );
  }

  /**
   * Restore the initial model and fill the form again.
   */
  onReset(): void {
    this.model = this._objectHelper.clone(this.initialModel);
    this.fillForm();
  }

  /**
   * Left in case that empty-type reset is desired again.
   */
  private clearAll(): void {
    this.dateRangeClearAll$.next();
    this.daysClearAll$.next();
    this.seasonsClearAll$.next();
    this.calendarsClearAll$.next();
    this.envelopesClearAll$.next();
  }

  initDatesPeriod(): void {
    const periodEndDate =
      this._settingsService.increaseEndDate && this.model?.periodEndDate
        ? this._datesHelper.addDays(this.model.periodEndDate, -1)
        : this.model.periodEndDate;

    this.dateSelectorSettings.dateRangeSettings.dateRange = new DateRange(
      this.model.periodStartDate,
      periodEndDate,
      this.startDateName,
      this.endDateName
    );

    this.dateSelectorSettings.periodDays = this.model.periodDays;
    this.dateSelectorSettings.periodIsFixed = this.model.periodIsFixed;

    this.dateSelectorSettings = { ...this.dateSelectorSettings };
  }

  initSeasons(): void {
    this.seasonsSelectedIds = this.model.seasons ?? [];
  }

  initDayTypes(): void {
    let ids = [DayTypesEnum.WorkingDay, DayTypesEnum.Weekend];
    if (!this.model.calendarIsWeek) {
      ids = ids.filter((id) => id !== DayTypesEnum.WorkingDay);
    }
    if (!this.model.calendarIsWeekend) {
      ids = ids.filter((id) => id !== DayTypesEnum.Weekend);
    }
    this.dayTypesSelectedIds = ids;
  }

  initCalendars(): void {
    if (this.model.calendars) {
      this.selectedCalendarsWithParams = Object.entries(this.model.calendars).reduce(
        (accum, [key, value]) => {
          accum[key] = {
            [this.calendarModeField]: value
              ? CalendarModeEnum.Inclusive
              : CalendarModeEnum.Exclusive,
          };
          return accum;
        },
        {}
      );
    } else {
      this.selectedCalendarsWithParams = {};
    }
  }

  initEnvelopes(): void {
    this.defaultEnvelopes = this.model as EnvelopesConfiguration;
    this.setUoMParams(
      this.model.dimensionTypeId,
      this.model.timeAggregationId ?? TimeAggregationEnum.Base,
      this.model.hierarchyElementTypeId ?? this._signalTypeId
    );
  }

  onEnvelopesAreDefault(areDefault: boolean): void {
    this.envelopesAreDefault = areDefault;
    this.checkIsDefault();
  }

  /**
   * Adjust data to ensure that form changes detection works correctly.
   */
  private processModel(model: Partial<ProfileConfigurationDto>): void {
    if (model.periodIsFixed) {
      model[this.periodDaysName] = undefined;
      model[this.startDateName] = this._datesHelper.setTime(model[this.startDateName]) as any;
      model[this.endDateName] = this._datesHelper.setTime(model[this.endDateName]) as any;
    } else {
      model[this.startDateName] = undefined;
      model[this.endDateName] = undefined;
    }
  }

  private getPendingChanges(isValid: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: isValid,
      saveFn: () => this.save(),
    };
  }

  private setUoMParams(
    dimensionTypeId: number,
    timeAggregationId: number,
    hierarchyElementTypeId: string
  ) {
    this.uoMParams = { dimensionTypeId, timeAggregationId, hierarchyElementTypeId };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
