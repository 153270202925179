export class PipeChartDto {
  elementId: string;
  category: string;
  value: number;
  order: string;

  constructor(elementId: string, category: string, value: number, order?: string) {
    this.elementId = elementId;
    this.category = category;
    this.value = value;
    this.order = order;
  }
}
