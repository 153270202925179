import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { CommonSharedModule } from '../shared/common-shared.module';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { LocalizationHelperService } from '../shared/localization/localization-helper.service';
import { formlyConfigBuilder } from './config-builder.formly';
import { ConfigurableFormComponent } from './configurable-form/configurable-form.component';
import { configurableFormTypes } from './types.formly';
import { ChipsSelectorComponent } from './types/chips-selector/chips-selector.component';
import { ColorPickerTypeComponent } from './types/color-picker-type/color-picker-type.component';
import { FileUploadTypeComponent } from './types/file-upload-type/file-upload-type.component';

import { SwitcherTypeComponent } from './types/switcher-type/switcher-type.component';

import { configurableFormValidations } from './validations.formly';
import { configurableFormWrappers } from './wrappers.formly';
import { SectionWrapperComponent } from './wrappers/section-wrapper/section-wrapper.component';

@NgModule({
  declarations: [
    ConfigurableFormComponent,
    SectionWrapperComponent,
    ChipsSelectorComponent,
    ColorPickerTypeComponent,
    FileUploadTypeComponent,
    SwitcherTypeComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyModule.forRoot({
      wrappers: configurableFormWrappers,
      types: configurableFormTypes,
      validators: configurableFormValidations,
      // Validation messages included in formlyConfigBuilder
    }),

    FormlyMaterialModule,
    FormlyMatFormFieldModule,
    FormlyMatDatepickerModule,
    FileUploadModule,
  ],
  exports: [ConfigurableFormComponent],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: formlyConfigBuilder,
      deps: [LocalizationHelperService],
    },
  ],
})
export class ConfigurableFormModule {}
