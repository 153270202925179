<div id="center-page">
  <div class="filter-buttons-container">
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="true"
      (btnClick)="onClickGridBtns($event)"
    >
    </wlm-grid-buttons>
  </div>
  <div id="center-container">
    <wlm-telemetry-filter
      *ngIf="configReady"
      [telemetryFilterConfiguration]="telemetryFilterConfiguration"
      [storageLocation]="persistencyLocation"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="loadGrid()"
      (autoLoad)="onCheckAutoload()"
    ></wlm-telemetry-filter>
    <wlm-generic-grid
      class="generic-grid"
      [gridSettings]="gridSettings"
      [persistencyArea]="telemetryFilterConfiguration.persistencyArea"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [removeSelection$]="removeSelection$"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      (selectedPersistedItemsChanged)="onselectedPersistedItemsChanged()"
      [(selectedItems)]="selectedPoints"
      storageLocation="session"
    ></wlm-generic-grid>
  </div>
</div>
