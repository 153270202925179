<form [formGroup]="autocompleteFormGroup">
  <mat-form-field class="autocomplete-filter">
    <mat-label class="filter-title">{{ autocompleteSettings.searchBoxTitle }}</mat-label>
    <input
      #treeFilter
      matInput
      [value]="filterValue"
      placeholder="{{ autocompleteSettings.placeHolderText }}"
      [matAutocomplete]="auto"
      formControlName="searchBoxControl"
    />

    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="onFilterOptionSelected($event.option.value)"
    >
      <mat-option *ngIf="isLoading" class="is-loading">
        <span class="k-i-loading k-icon"></span>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          {{ option }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>
