<div *ngIf="group" class="grid-container">
  <div class="filter-buttons-container">
    <button
      mat-icon-button
      class="button-position"
      [disabled]="!usersGrid?.canClearFilters()"
      (click)="usersGrid?.clearFilters()"
      [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
    >
      <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
    </button>

    <button
      mat-icon-button
      *hasPerm="['WLMAdministrationCrud', 'u']"
      [matTooltip]="T_SCOPE + '.add' | translate | lowercase"
      onclick="this.blur()"
      (click)="addUserToGroup()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      *hasPerm="['WLMAdministrationCrud', 'u']"
      [matTooltip]="T_SCOPE + '.remove' | translate | lowercase"
      [disabled]="!selectedUsers?.length"
      (click)="removeUser()"
    >
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </div>
  <wlm-generic-grid
    class="wtr-fx-flex-grow height-minus-buttons"
    [additionalFilters]="additionalFilters"
    [gridSettings]="userGridSetting"
    [gridFiltersForBinding]="gridFiltersForBinding"
    (selectedItemChanged)="getSelectedUser($event)"
    [(selectedItems)]="selectedUsers"
    [removeSelection$]="removeSelection$"
    [subscriptionTag]="userGridSettingsName"
  ></wlm-generic-grid>
</div>
