export class AlarmChartQueryDto {
  startDate: Date;
  endDate: Date;
  signalIds: number[];
  algorithms: AlgorithmQuery[];

  constructor(init: Partial<AlarmChartQueryDto>) {
    Object.assign(this, init);
  }
}

export class AlgorithmQuery {
  elementId: string;
  algorithmShortName: string;

  constructor(init: Partial<AlgorithmQuery>) {
    Object.assign(this, init);
  }
}
