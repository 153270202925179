import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
} from '../../dependencies/navigation/tab-detail-component';
import { RightPanelSettings } from './right-panel-settings';

@Injectable()
export class RightPanelService {
  private visibilityInternal = new BehaviorSubject<boolean>(true);
  private collapsedInternal = new BehaviorSubject<boolean>(false);
  private hoverInternal = new BehaviorSubject<boolean>(false);
  private overlayInternal = new BehaviorSubject('side');
  private tabSettingInternal = new BehaviorSubject<TabDetailPanelSettings>(null);
  private tabParameterInternal = new BehaviorSubject<TabDetailPanelParameters>(null);
  private resizedInteral = new BehaviorSubject(null);
  private currentPageKeyInternal = new BehaviorSubject<string>(null);
  private hasRightPanelInternal = new BehaviorSubject<boolean>(true);
  private currentPageId = new BehaviorSubject(null);

  public rightPanelSizeChanges = new Subject<RightPanelSettings>();
  public rightPanelInitialize = new Subject<RightPanelSettings>();
  public rightPanelVisibilityChanged = new Subject<boolean>();
  public rightPanelCallback = new BehaviorSubject<Map<any, any>>(null);

  public get visibility(): boolean {
    return this.visibilityInternal?.value;
  }

  public get TabSettings(): TabDetailPanelSettings {
    return this.tabSettingInternal?.value;
  }

  public get hover(): boolean {
    return this.hoverInternal?.value;
  }

  public get hasRightPanel(): boolean {
    return this.hasRightPanelInternal?.value;
  }

  setResized(rightPanelSettings: RightPanelSettings) {
    this.resizedInteral.next(null);
    this.rightPanelSizeChanges.next(rightPanelSettings);
  }

  setTabSettings(value: TabDetailPanelSettings) {
    this.tabSettingInternal.next(value);
  }

  setTabParameters(value: TabDetailPanelParameters) {
    this.tabParameterInternal.next(value);
  }

  setVisible(value: boolean) {
    this.visibilityInternal.next(value);
  }

  setHasRightPanel(value: boolean) {
    this.hasRightPanelInternal.next(value);
  }

  loadRPComponent(components: string[]) { }

  toggleVisibility() {
    this.setVisible(!this.visibilityInternal.value);
    this.rightPanelVisibilityChanged.next(this.visibility);
  }

  setCollapsed(value: boolean) {
    this.collapsedInternal.next(value);
  }

  toggleCollapsed() {
    this.setHover(!this.collapsedInternal.value);
  }

  setHover(value: boolean) {
    this.hoverInternal.next(value);
  }

  setCurrentPageKey(value: string): void {
    this.currentPageKeyInternal.next(value);
  }

  getHover(): boolean {
    return this.hoverInternal.value;
  }

  toggleHover() {
    this.setHover(!this.hoverInternal.value);
  }

  toggleOverlay() {
    const rightPanelElement = document.getElementById('right-panel');

    if (rightPanelElement.classList.contains('overlay')) {
      rightPanelElement.classList.remove('overlay');
      this.overlayInternal.next('side');
    } else {
      rightPanelElement.classList.add('overlay');
      this.overlayInternal.next('over');
    }
  }

  /**
   * Set the key used for checking pending changes.
   * @param value key used in PendingChangesManagerService
   */
  setCurrentPageId(value: string) {
    this.currentPageId.next(value);
  }

  resizedObservable(): Observable<any> {
    return this.resizedInteral.asObservable();
  }

  visibilityObservable(): Observable<boolean> {
    return this.visibilityInternal.asObservable();
  }

  hasRightPanelObservable(): Observable<boolean> {
    return this.hasRightPanelInternal.asObservable();
  }

  collapsedObservable(): Observable<boolean> {
    return this.collapsedInternal.asObservable();
  }

  HoverObservable(): Observable<boolean> {
    return this.hoverInternal.asObservable();
  }

  overlayObservable(): Observable<string> {
    return this.overlayInternal.asObservable();
  }

  tabSettingObservable(): Observable<TabDetailPanelSettings> {
    return this.tabSettingInternal.asObservable();
  }

  tabParameterObservable(): Observable<TabDetailPanelParameters> {
    return this.tabParameterInternal.asObservable();
  }

  currentPageKeyObservable(): Observable<string> {
    return this.currentPageKeyInternal.asObservable();
  }

  rightPanelCallbackObservable(): Observable<Map<any, string>> {
    return this.rightPanelCallback.asObservable();
  }

  currentPageIdObservable(): Observable<string> {
    return this.currentPageId.asObservable();
  }
}
