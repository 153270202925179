<ng-template #main>
  <div *ngIf="dateRangeForm">
    <div class="filter-item-title date-title">{{ titleKey | translate }}</div>
    <form [formGroup]="dateRangeForm" [class]="formClass" class="fixed-subscript">
      <mat-form-field appearance="outline" class="date-picker date-start">
        <mat-label class="filter-title">{{ T_SCOPE + '.start-date-label' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [min]="minDate"
          [max]="maxDate"
          formControlName="start"
          autocomplete="off"
        />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"> </mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="dateRangeForm.get('start')?.errors?.required">{{
          'common.validation.required' | translate
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.get('start')?.errors?.matDatepickerMax">{{
          'common.validation.max-date' | translate : { max: maxDate | date : 'date' }
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.get('start')?.errors?.matDatepickerMin">{{
          'common.validation.min-date' | translate : { min: minDate | date : 'date' }
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.errors?.dateOlderThanError">
          {{ 'common.validation.end-date-minor' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            dateRangeForm.errors?.dateMaximumPeriodError &&
            !dateRangeForm.errors?.dateOlderThanError
          "
        >
          {{ 'common.validation.max-range' | translate : { maxRange: maxRangeSelected } }}
        </mat-error>
        <mat-error *ngIf="dateRangeForm.errors?.invalidDateRangeError">
          {{ 'common.validation.invalid-date-range' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="date-picker">
        <mat-label class="filter-title">{{ T_SCOPE + '.end-date-label' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          [min]="minDate"
          [max]="maxDate"
          formControlName="end"
          autocomplete="off"
        />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"> </mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="dateRangeForm.get('end')?.errors?.required">{{
          'common.validation.required' | translate
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.get('end')?.errors?.matDatepickerMax">{{
          'common.validation.max-date' | translate : { max: maxDate | date : 'date' }
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.get('end')?.errors?.matDatepickerMin">{{
          'common.validation.min-date' | translate : { min: minDate | date : 'date' }
        }}</mat-error>
        <mat-error *ngIf="dateRangeForm.errors?.dateOlderThanError">
          {{ 'common.validation.end-date-minor' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            dateRangeForm.errors?.dateMaximumPeriodError &&
            !dateRangeForm.errors?.dateOlderThanError
          "
        >
          {{ 'common.validation.max-range' | translate : { maxRange: maxRangeSelected } }}
        </mat-error>
        <mat-error *ngIf="dateRangeForm.errors?.invalidDateRangeError">
          {{ 'common.validation.invalid-date-range' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</ng-template>
<ng-template #summary>
  <wlm-default-filter-item-summary
    *ngIf="summaryItems.length !== 0"
    [title]="titleKey | translate"
    [items]="summaryItems"
  ></wlm-default-filter-item-summary>
</ng-template>
