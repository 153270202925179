import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { plainToClass } from 'class-transformer';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin } from 'rxjs';
import { CVService } from 'src/app/common-modules/dependencies/cv/cv.service';
import { ICVShortDto } from 'src/app/common-modules/dependencies/cv/models/cv-short-view.dto';
import { CVShortQueryDto } from 'src/app/common-modules/dependencies/shared/model/cv-short-query.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { BaseFiltrableItemService } from 'src/app/common-modules/shared/charts/base-filtrable-item.service';
import { FiltrableItemManagerService } from 'src/app/common-modules/shared/charts/filtrable-item-manager.service';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { AlgorithmFiltrableItemQueryDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item-query.dto';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { DataVisualizationCartSelection } from '../../models/data-visualization-cart-selection';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-algorithm-values-widget';
export const DV_ALGORITHM_VALUES_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-algorithm-values-widget.component.html',
  styleUrls: ['./data-visualization-algorithm-values-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationAlgorithmValuesWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  get componentName(): string {
    return 'DataVisualizationAlgorithmValuesWidgetComponent';
  }

  gridSettings: GridSetting;
  gridData: any[];
  isLoading = false;
  gridSettingsName = 'CalculatedValuesGrid';

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _filtrableItemManagerService: FiltrableItemManagerService,
    private _gridSettingsService: GridSettingsService,
    private _cvService: CVService
  ) {
    super(injector, widgetSettings);
    const algorithms = this.setAlgorithmFiltrableItems(widgetSettings.itemSettings?.params);
    this.loadGrid(algorithms);
  }

  onWidgetInit(): void {
    this.setGridSettings();

    this.listenState(DataVisualizationStateFields.plotCartSelection).subscribe(
      (cartSelection: DataVisualizationCartSelection) => {
        if (cartSelection) {
          this.isLoading = true;

          const algorithmFiltrableItems =
            this.getAlgorithmFiltrableItemsFromCartSelection(cartSelection);

          this.onCartPlot(algorithmFiltrableItems);
          this.loadGrid(algorithmFiltrableItems);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.plotChartConfiguration).subscribe(
      (chartConfiguration: ChartConfiguration) => {
        if (chartConfiguration) {
          this.isLoading = true;

          const algorithmFiltrableItems =
            this.getAlgorithmFiltrableItemsFromChartConfiguration(chartConfiguration);

          this.onCartPlot(algorithmFiltrableItems);
          this.loadGrid(algorithmFiltrableItems);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.clearAll).subscribe((value) => {
      if (value) {
        this.loadGrid([]);
      }
    });
  }

  private loadGrid(algorithmFiltrableItems: AlgorithmFiltrableItemDto[]) {
    if (!algorithmFiltrableItems?.length) {
      this.gridData = [];
      this.isLoading = false;
      return;
    }
    const service = this._filtrableItemManagerService.getService(FiltrableItemTypeEnum.Algorithm);

    const queries$: Observable<ICVShortDto[]>[] = [];

    algorithmFiltrableItems.forEach((algorithmFiltrableItem) => {
      const query$ = this.getCVShort(service, algorithmFiltrableItem);
      queries$.push(query$);
    });

    forkJoin(queries$).subscribe({
      next: (values) => {
        const gridData = asEnumerable(values)
          .SelectMany((x) => x)
          .ToArray();

        this.gridData = gridData;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private getAlgorithmFiltrableItemsFromChartConfiguration(
    chartConfiguration: ChartConfiguration
  ): AlgorithmFiltrableItemDto[] {
    return chartConfiguration.seriesConfiguration
      .map((s) => s.serieDefinition)
      .filter((sd) => sd.filtrableType.type == FiltrableItemTypeEnum.Algorithm)
      .map((sd) => sd as AlgorithmFiltrableItemDto);
  }

  private getAlgorithmFiltrableItemsFromCartSelection(
    cartSelection: DataVisualizationCartSelection
  ): AlgorithmFiltrableItemDto[] {
    return cartSelection.visibleItems
      .filter((x) => x.filtrableType.type == FiltrableItemTypeEnum.Algorithm)
      .map((x) => x as AlgorithmFiltrableItemDto);
  }

  private getCVShort(
    service: BaseFiltrableItemService,
    algorithmFiltrableItem: AlgorithmFiltrableItemDto
  ): Observable<ICVShortDto[]> {
    const query = service.getQuery(algorithmFiltrableItem) as AlgorithmFiltrableItemQueryDto;

    const query$ = this._cvService.getShortConverted(
      new CVShortQueryDto(
        query.startDate,
        query.endDate,
        query.extraParams.elementIds,
        query.extraParams.algorithms
      )
    );
    return query$;
  }

  private setGridSettings() {
    this._gridSettingsService
      .getGridSettingsByName(this.gridSettingsName)
      .subscribe((gridSettings) => {
        this.gridSettings = gridSettings;
      });
  }

  private onCartPlot(algorithmFiltrableItems: AlgorithmFiltrableItemDto[]) {
    this.updateItemSettings({
      title: this._settings.title,
      params: algorithmFiltrableItems,
      widgetInstanceKey: this.getWidgetInstanceKey(),
    });
  }

  private setAlgorithmFiltrableItems(params: any[]) {
    if (!params) {
      return;
    }

    const algorithmFiltrableItems = plainToClass(AlgorithmFiltrableItemDto, params);
    return algorithmFiltrableItems;
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
