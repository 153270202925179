import { Observable, Subscription, tap } from 'rxjs';
import { BaseDynamicWidgetComponent } from '../components/base-dynamic-widget.component';

export function applyOnActiveWidget<T>(
  context: BaseDynamicWidgetComponent,
  assignFn: (payload: T) => void
) {
  let activeStatusSubs: Subscription;

  return function (source$: Observable<T>) {
    return source$.pipe(
      tap((payload) => {
        activeStatusSubs?.unsubscribe();
        activeStatusSubs = context.activeStatus$.subscribe((isActive) => {
          if (isActive) {
            assignFn(payload);
          }
        });
      })
    );
  };
}
