import { Component, Input } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ILeakageTypeGroupDto } from 'src/app/common-modules/dependencies/alc/leakage-type-group.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';

@Component({
  selector: 'wlm-leak-type-groups-form',
  templateUrl: './leak-type-groups-form.component.html',
  styleUrls: ['./leak-type-groups-form.component.scss'],
})
export class LeakTypeGroupsFormComponent extends IntegrableForm {
  @Input() initialModel: ILeakageTypeGroupDto;
  model: ILeakageTypeGroupDto;

  onModelChange(model: ILeakageTypeGroupDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'leakageTypeGroupId',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Leakage Type Group Id',
            type: 'number',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'leakageTypeGroupName',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Leakage Type Group Name',
            required: true,
          },
        },
      ],
    },
  ];
}
