import { Injectable, Injector } from '@angular/core';
import { SignalConfigurationDto } from 'src/app/common-modules/dependencies/signals/models/signal-configuration.dto';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class PointConfigurationService extends GridODataService<SignalConfigurationDto> {
  constructor(injector: Injector) {
    super(injector, '/api/telemetry/signal/configuration');
  }

  protected mapResponse(
    response: PagedResultDto<SignalConfigurationDto>
  ): PagedResultDto<SignalConfigurationDto> {
    return response;
  }
}
