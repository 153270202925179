import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { KpiTranslationKeysMapping } from '../../map/map-thematic/models/kpi-translations-mapping';
import { KpiSettingValue } from '../../map/map-thematic/models/map-thematic-kpi-value';
import { KpiTypeEnum } from '../model/kpi/kpi-type.enum';

@Pipe({
  name: 'kpiTitle',
})
export class KpiTitlePipe implements PipeTransform {
  translationMapping = KpiTranslationKeysMapping;

  constructor(private _localizationHelper: LocalizationHelperService) {}

  transform(kpiSettingValue: KpiSettingValue): Observable<string> {
    const translations = [this.translationMapping[kpiSettingValue.kpiProperty] + '.menu-title'];

    if (kpiSettingValue.kpiType != KpiTypeEnum.Algorithm.toString()) {
      translations.push(this.translationMapping[kpiSettingValue.kpiType]);

      if (
        kpiSettingValue.dimensionTypeId &&
        kpiSettingValue.dimensionTypeId !== DimensionTypesEnum.Percentage
      ) {
        translations.push(
          this.translationMapping[DimensionTypesEnum[kpiSettingValue.dimensionTypeId]]
        );
      }
    }

    return this._localizationHelper.get(translations).pipe(
      map((ts) => {
        let title = '';
        Object.keys(ts).forEach((x) => {
          title += `${ts[x]} `;
        });

        return title;
      })
    );
  }
}
