import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { ICustomerClassTypeDto } from '../../shared/model/customer/customer-class-type.dto';
import { ICustomerClassTypeValidationDto } from './customer-class-type-validation.dto';

@Injectable()
export class CustomerClassTypesCrudService
    extends BaseService
    implements GenericCrudService<ICustomerClassTypeDto, string>
{
    private _entityUrl: string;

    constructor(injector: Injector) {
        super(injector);
        this._entityUrl = `${this.apiUrl}/integration/customer-class-types`;
    }

    getAll(): Observable<ICustomerClassTypeDto[]> {
        return this.httpCacheClient.get<ICustomerClassTypeDto[]>(this._entityUrl);
    }

    create(entity: ICustomerClassTypeDto): Observable<ICustomerClassTypeDto> {
        return this.httpCacheClient.post<ICustomerClassTypeDto>(this._entityUrl, entity);
    }

    update(entity: ICustomerClassTypeDto): Observable<ICustomerClassTypeDto> {
        return this.httpCacheClient.put<ICustomerClassTypeDto>(this._entityUrl, entity);
    }

    delete(id: string): Observable<boolean> {
        return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
    }

    getId(entity: ICustomerClassTypeDto): string {
        return entity.customerClassTypeId;
    }

    validate(entity: ICustomerClassTypeDto, isCreate?: boolean): Observable<ICustomerClassTypeValidationDto> {
        return this.httpCacheClient.post<ICustomerClassTypeValidationDto>(
            `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
            entity
        );
    }
}
