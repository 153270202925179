import { AlgorithmFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/algorithm-fi-settings';
import { ElementTargetFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { TimeAggregationFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/time-aggregation-fi-settings';

export class AlgorithmWizardStepSettings {
  algorithmSettings: AlgorithmFISettings;
  targetSettings?: ElementTargetFISettings;
  timeAggregationSettings?: TimeAggregationFISettings;
  enableAdditionalFilters? = false;
  persistencyArea?: string;

  constructor(data: Readonly<AlgorithmWizardStepSettings>) {
    Object.assign(this, data);
  }
}
