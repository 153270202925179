export class GChartDataZoomItemEvent {
  start: number;
  end: number;
  startValue?: any;
  endValue?: any;
  xAxisIndex?: number;
  yAxisIndex?: number;
}

/**
 * In many charts, there is only one dataZoom, so the original model whas the same as GChartDataZoomItemEvent.
 * To preserve that, we add a property that handles all possible dataZooms, for cases like graph charts with multiple dataZooms.
 */
export class GChartDataZoomEvent extends GChartDataZoomItemEvent {
  items?: { [dataZoomName: string]: GChartDataZoomItemEvent };

  constructor(init: Readonly<GChartDataZoomEvent>) {
    super();
    Object.assign(this, init);
  }
}
