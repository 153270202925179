import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { SettingsService } from '../../config/settings.service';
import { APP_PERMISSIONS } from '../../core/injection-tokens/app-permissions.token';
import { CURRENT_ENVIRONMENT } from '../../core/injection-tokens/current-environment.token';
import { ProfileDialogsPathsDto } from '../../model/roles/profileDialogsPaths.dto';
import { RolesService } from '../../roles/roles.service';
import { LicenceModules } from '../models/licence-modules.enum';
import { PermissionsRequest } from '../models/permissions-request';
import { AppPermissionsSettings } from './app-permissions-settings';
import { AuthenticationService } from './authentication.service';

/**
 * Check if the current logged in us -requesta profile dialog path, or permission, for certain CRUD actions.
 * @example authorize.canAccess('WLMDistributionNetworkCrud', 'cu') -> can the current user create and update in the Distribution Network section?
 * @example authorize.canAccess('WLMDistributionNetworkCrud', 'dcu') -> the "Crud" part can be set or omitted, and permissions can be disordered.
 */

export type TPermissions = [string, string][];

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  // Store the permissions in runtime.
  private _permissions: Map<string, ProfileDialogsPathsDto>;
  private _isSuperUser$ = new ReplaySubject<boolean>();
  private _isAvailable$ = new ReplaySubject<void>();
  private _firstLoad = false;
  private _environment;

  // Contains all the permissions needed for each route. Each route is identified by a nav key.
  private _routePermissions: Map<NavKeys, [string, string][]>;

  constructor(
    private _rolesService: RolesService,
    private _settingsService: SettingsService,
    private _authenticationService: AuthenticationService,
    @Inject(CURRENT_ENVIRONMENT) environment,
    @Inject(APP_PERMISSIONS) appPermissions: AppPermissionsSettings
  ) {
    this._environment = environment;
    this._routePermissions = appPermissions.routePermissions;
  }

  isSuperUser(): Observable<boolean> {
    return this._isSuperUser$.asObservable();
  }

  canAccessByLicence(modules: LicenceModules[]): Observable<boolean> {
    const enabledModules = this._authenticationService.getEnabledModulesFromToken();
    const canAccess = modules.every((module) => enabledModules.includes(module));

    return of(canAccess);
  }

  /**
   * Checks if the current user has a set of permissions for a certain access key.
   * If permissions are not loaded, load them and make them available for current and successive calls.
   * @param accessKey The profile dialog path, or permission. Example: DistributionNetwork, WLMDistributionNetworkCrud.
   * @param permissionsCode Coded permissions. Example: 'cu' -> create and update.
   */
  canAccess(accessKey: string, permissionsCode: string): Observable<boolean> {
    return this._authenticationService.accessToken$.pipe(
      take(1),
      switchMap(() => {
        if (!this._firstLoad) {
          this._firstLoad = true;
          this._loadPermissions();
        }
        return this._isAvailable$.asObservable().pipe(
          // Must use the first operator if we want to call it inside a forkJoin. This is because forkJoin waits for the last value of all observables, and
          // ReplaySubjects never have a last value. So we get only the first value with the first operator.
          take(1),
          switchMap(() => this._isSuperUser$),
          map((isSuperUser) => {
            const accessKeyCrud = `${accessKey}Crud`;
            if (!this._permissions.has(accessKey) && !this._permissions.has(accessKeyCrud)) {
              if (!this._environment.production) {
                console.error(
                  `The keys '${accessKey}' and '${accessKeyCrud}' are not valid permissions.`
                );
              }
              return false;
            }
            if (isSuperUser) {
              return true;
            }
            accessKey = this._permissions.has(accessKey) ? accessKey : accessKeyCrud;
            const selected = this._permissions.get(accessKey);
            const request = this._buildRequestedPermissions(permissionsCode);

            let result = true;
            if (request.canRead && !selected.canRead) {
              result = false;
            }
            if (request.canCreate && !selected.canCreate) {
              result = false;
            }
            if (request.canUpdate && !selected.canUpdate) {
              result = false;
            }
            if (request.canDelete && !selected.canDelete) {
              result = false;
            }
            if (!result && !this._environment.production) {
              console.log(`Auth Service: \u274C [${accessKey}, ${permissionsCode}]`);
            }

            return result;
          })
        );
      })
    );
  }

  /**
   * Equivalent to canAccess, but checks for multiple keys with permissions.
   * Will fail if any of the multiple requests fail.
   * @example authorize.canAccessMultiple([['WLMDistributionNetworkCrud', 'dcu'], ['Groups', 'r'r]])
   */
  canAccessMultiple(keysAndPermissions: TPermissions): Observable<boolean> {
    if (!keysAndPermissions || keysAndPermissions.length === 0) {
      return of(true); // If no permissions are specified, the key can be view by everyone.
    }
    const calls = keysAndPermissions.map((item) => {
      if (item.length !== 2 && !this._environment.production) {
        console.error('canAccessMultiple must receive an array of pairs.');
        return of(false);
      }
      return this.canAccess(item[0], item[1]);
    });
    return forkJoin(calls).pipe(map((results) => results.every((i) => i === true)));
  }

  /**
   * Get the permissions required for accessing a specific NavKey.
   */
  getNavLinkPermissions(navKey: NavKeys): [string, string][] {
    if (!this._routePermissions.has(navKey)) {
      throw Error(`The key ${navKey} is not a valid NavKeys option.`);
    }
    return this._routePermissions.get(navKey);
  }

  /**
   * Receives an array of nav keys and checks the permissions of all of them.
   * If navKeys is null, check permissions for all the keys.
   * @returns An Observable of an object in which keys are NavKeys, and values are if the current user has permission.
   */
  canAccessNavKeys(navKeys: NavKeys[]): Observable<any> {
    const permObs = {};
    navKeys =
      !navKeys || navKeys.length === 0 ? Array.from(this._routePermissions.keys()) : navKeys;
    navKeys.forEach((key) => {
      permObs[key] = this.canAccessMultiple(this.getNavLinkPermissions(key));
    });
    return forkJoin(permObs);
  }

  /**
   * Loads the permissions and sets the service as available.
   */
  private _loadPermissions(): void {
    this._settingsService.ready$.pipe(untilDestroyed(this)).subscribe(() => {
      this._rolesService.getProfileDialogsPathsByCurrentUser().subscribe((data) => {
        this._permissions = this._buildPermissionsMap(data.permissions);
        this._isSuperUser$.next(data.isSuperUser);
        this._isSuperUser$.complete();
        this._isAvailable$.next();
      });
    });
  }

  /**
   * Converts coded permissions, like "c", "cr" or "rud" into a permissions request.
   */
  private _buildRequestedPermissions(permissionsCode: string): PermissionsRequest {
    if (!permissionsCode) {
      return new PermissionsRequest();
    }
    permissionsCode = permissionsCode.toLowerCase();
    return {
      canCreate: permissionsCode.indexOf('c') !== -1,
      canRead: permissionsCode.indexOf('r') !== -1,
      canUpdate: permissionsCode.indexOf('u') !== -1,
      canDelete: permissionsCode.indexOf('d') !== -1,
    } as PermissionsRequest;
  }

  /**
   * Converts an array of objects into a Map, for improved performance.
   */
  private _buildPermissionsMap(
    array: ProfileDialogsPathsDto[]
  ): Map<string, ProfileDialogsPathsDto> {
    return new Map(array.map((item) => [item.dialogName, item]));
  }
}
