import { NavigationElementType } from './navigation-element-type';

export class NavigationElement {
  elementId: string;
  type: NavigationElementType;
  hierarchyElementId: string;

  constructor(init?: Partial<NavigationElement>) {
    Object.assign(this, init);
  }
}
