import { IChartDataParameters } from '../chart-data-parameters';

export class SchematicChartDataParameters implements IChartDataParameters {
  queryParams: { name: string };
  dataService: string;
  // I do not think the dates apply for schematics.
  startDate: Date;
  endDate: Date;

  public constructor(init?: Readonly<SchematicChartDataParameters>) {
    Object.assign(this, init);
  }
}
