import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-currency-column',
  templateUrl: './currency-column.component.html',
  styleUrls: ['./currency-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => CurrencyColumnComponent),
    },
  ],
})
export class CurrencyColumnComponent extends WlmColumnComponent implements OnInit {
  private _unitFormat: string;
  public get unitFormat(): string {
    return this._unitFormat;
  }
  @Input() public set unitFormat(v: string) {
    this._unitFormat = v;
    this.refreshColumnHeaderLabel();
  }

  private _baseTitle: string;
  public get baseColumnTitle(): string {
    return this._baseTitle;
  }
  @Input() public set baseColumnTitle(v: string) {
    this._baseTitle = v;
    this.refreshColumnHeaderLabel();
  }
  justifyContentStyle: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.justifyContentStyle = this._getStyleValue(this.style, 'justify-content');
  }

  refreshColumnHeaderLabel() {
    if (this.baseColumnTitle && this.unitFormat) {
      this.title = this.baseColumnTitle + ' ' + this.unitFormat;
    }
  }

  private _getStyleValue(style: any, key: string): string {
    if (style === undefined || key === undefined) {
      return undefined;
    }
    return (style as Map<string, string>).get(key);
  }
}
