import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SeasonDto } from 'src/app/common-modules/dependencies/shared/model/season.dto';
import { BaseService } from 'src/app/common-modules/shared/base.service';

@Injectable()
export class SeasonService extends BaseService {
  get apiUrl() {
    return `${this.baseUrl}/api/season`;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getSeasons(): Observable<SeasonDto[]> {
    return this.httpCacheClient.get(`${this.apiUrl}/all`, {
      avoid: true,
    });
  }

  createSeason(season: SeasonDto) {}
}
