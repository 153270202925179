import { GChartItemStyle } from './g-chart-item-style';
import { ItemStylesByKeyModes } from './item-styles-by-key-modes';

export class GChartCustomItemStyle {
  itemId: any;
  mode: ItemStylesByKeyModes;
  // If multiple instances of this object are set to the same item, they will be overriden with priority.
  // Example: { styleName: 'error', priority: 1 }, { styleName: 'edited', priority: 2 } -> error styles override edited styles
  priority: number;
  itemStyle: GChartItemStyle;

  constructor(init: GChartCustomItemStyle) {
    Object.assign(this, init);
  }
}
