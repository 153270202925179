import { Component, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { BaseDynamicLayoutHeaderComponent } from '../../base-dynamic-layout-header.component';
import { DynamicLayoutItemSettings } from '../../models/dynamic-layout-item-settings';
import { BiDefaultParamsPopupComponent } from '../bi-default-params-popup/bi-default-params-popup.component';
import { BiDefaultParamsPopupParams } from '../models/bi-default-params-popup-params';
const COMPONENT_SELECTOR = 'wlm-bi-default-params-button';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-default-params-button.component.html',
  styleUrls: ['./bi-default-params-button.component.scss'],
})
export class BiDefaultParamsButtonComponent
  extends BaseDynamicLayoutHeaderComponent
  implements OnInit
{
  widgetInstanceKey: string;
  getItemSetting: () => DynamicLayoutItemSettings;
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;

  constructor(private _dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {}

  openPopup(): void {
    const itemSetting = this.getItemSetting();
    const dialogConfig = new MatDialogConfig();
    // When using the popup during creation phase, the filters are not yet usable.
    const { widgetInstanceKey, title, baseTitle, params, paramsType, defaultFilters, lockFilters } =
      itemSetting;
    const data: BiDefaultParamsPopupParams = {
      widgetInstanceKey,
      title,
      baseTitle,
      mode: 'update',
      params,
      paramsType,
      defaultFilters,
      lockFilters,
    };
    dialogConfig.data = data;
    // dialogConfig.height = this._dynamicParamsService.getParamsPopupHeightByType(paramsType);
    const additionalSettings = {
      autoHeight: true,
    };
    this._dialogService.openComponent(
      BiDefaultParamsPopupComponent,
      dialogConfig,
      additionalSettings
    );
  }
}
