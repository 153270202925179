import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts, HttpCacheClient } from '../cache/http-cache/http-cache.client';
import { SettingsService } from './config/settings.service';

@Injectable()
export abstract class BaseService {
  protected httpClient: HttpClient;
  protected httpCacheClient: HttpCacheClient;
  protected settingsService: SettingsService;
  protected expiration: GetCacheOpts = { expiration: 'default' };
  protected avoidCache: GetCacheOpts = { avoid: true };
  protected defaultCacheOpts: GetCacheOpts = { ...this.expiration, ...this.avoidCache };

  protected get baseUrl(): string {
    return this.settingsService.apis.default.url;
  }

  protected get apiUrl(): string {
    return `${this.baseUrl}/api`;
  }

  constructor(injector: Injector) {
    // Injector can be undefined if this service is injected via a string.
    if (injector) {
      this.injectServices(injector);
    } else {
      if (this.settingsService.log) {
        console.error(
          'Services could not be injected with a null injector. Be sure to use the setInjector method after creating the instance.'
        );
      }
    }
  }

  protected setInjector(injector: Injector): void {
    this.injectServices(injector);
  }

  private injectServices(injector: Injector): void {
    this.httpClient = injector?.get(HttpClient);
    this.httpCacheClient = injector?.get(HttpCacheClient);
    this.settingsService = injector?.get(SettingsService);
  }

  protected getCached<T>(urlPath: string, expiration?: GetCacheOpts) {
    return this.httpCacheClient.get<T>(`${this.apiUrl}/${urlPath}`, expiration ?? this.expiration);
  }

  protected get<T>(urlPath: string) {
    return this.httpClient.get<T>(`${this.apiUrl}/${urlPath}`);
  }
}
