import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { SignalConfigurationCategory } from 'src/app/common-modules/dependencies/signals/models/signal-configuration-category';
import { GenericCardSettings } from 'src/app/common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { WorkspaceDto } from 'src/app/common-modules/shared/model/data-viz/workspace.dto';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { DateSelectModeEnum } from 'src/app/common-modules/shared/model/shared/date-select-mode.enum';
import { ChartWorkspaceTypeEnum } from 'src/app/common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { AlgorithmFiltrableType } from '../../../shared/model/filtrable-items/types/algorithm-filtrable-type';
import { SignalFiltrableType } from '../../../shared/model/filtrable-items/types/signal-filtrable-type';
import { WorkspacesHelperService } from '../../../shared/services/workspaces-helper.service';
import { DataVisualizationConfigFormValidators } from './data-visualization-config-form-validators';

const COMPONENT_SELECTOR = 'wlm-data-visualization-config-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-config-form.component.html',
  styleUrls: ['./data-visualization-config-form.component.scss'],
})
export class DataVisualizationConfigFormComponent implements OnInit {
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  T_SCOPE_CHARTS = `${AppModules.WlmCharts}`;

  @Input() chartConfiguration: ChartConfiguration;
  @Input() cartFiltrableItems: IFiltrableItemDto[];

  private _workspace: WorkspaceDto;
  public get workspace(): WorkspaceDto {
    return this._workspace;
  }
  @Input()
  public set workspace(value: WorkspaceDto) {
    this._workspace = value;
    if (this.form) {
      this.reloadForm();
    }
  }

  @Input() isEditMode: boolean;
  @Input() isLoadMode: boolean;
  @Input() saveConfig$: Observable<void>;
  @Input() conflictError$: Observable<void>;

  @Output() hasValidChanges = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<WorkspaceDto>();

  private readonly _offsetStartDate = 1;

  form: UntypedFormGroup;

  chartConfigTypeEnum = ChartWorkspaceTypeEnum;
  chartConfigTypes: { [key: string]: ChartWorkspaceTypeEnum } = {};

  dateSelectModeEnum = DateSelectModeEnum;
  timeModes: { [key: string]: DateSelectModeEnum } = {};

  timeUnitsAllowed = [
    TimeAggregationEnum.Daily,
    TimeAggregationEnum.Weekly,
    TimeAggregationEnum.Monthly,
  ];
  timeUnits: { [key: string]: TimeAggregationEnum } = {};

  algorithms: AlgorithmFiltrableItemDto[] = [];
  algorithmCardSettings: GenericCardSettings<AlgorithmFiltrableItemDto>;

  points: SignalFiltrableItemDto[] = [];
  pointCardSettings: GenericCardSettings<SignalFiltrableItemDto>;

  initialDateRange: DateRange;
  hasDateRangeErrors: boolean;
  resetEndDate$ = new ReplaySubject<void>();
  resetStartDate$ = new ReplaySubject<void>();

  signalHECategories: { [key: string]: string } = {};
  signalNECategories: { [key: string]: string } = {};

  // Fields
  configModeFieldName = 'workspaceTypeId';
  nameFieldName = 'workspaceName';
  descriptionFieldName = 'workspaceDescription';
  timeModeFieldName = 'timeWindowMode';
  isPublicFieldName = 'isPublic';
  isGenericDateFieldName = 'isGenericDate';

  dateRangeFieldName = 'dateRange';

  timeUnitFieldName = 'timeAggregationRolling';
  offsetFieldName = 'rollingOffset';
  periodFieldName = 'rollingWindowWidth';

  includeFlowBoundaryPointsFieldName = 'includeBoundariesSignals';
  includePressurePointsFieldName = 'includePressureSignals';
  includeLargeUsersPointsFieldName = 'includeLargeUsersSignals';
  includeInletOutletPointsFieldName = 'includeInletOutletSignals';
  includeLevelPointsFieldName = 'includeLevelSignals';
  includeLarsSworpsPointsFieldName = 'includeLarsSworpsSignals';
  includeSmartMeterPointsFieldName = 'includeSmartMetersSignals';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _sharedConstants: SharedConstantsService,
    private _localizationService: LocalizationHelperService,
    private _dateHelperService: DateHelperService,
    private _workspacesHelperService: WorkspacesHelperService,
    private _authorizeService: AuthorizeService
  ) {}

  ngOnInit(): void {
    this.bindSaveConfig();
    this.bindConflictError();

    this.initChartConfigTypes();
    this.initTimeModes();
    this.initTimeUnits();
    this.initSignalCategories();

    this.setFiltrableItemsByType(this.workspace?.workspaceTypeId);
    this.prepareAlgorithmsCardSettings();
    this.preparePointsCardSettings();

    this.createForm();
    this.setInitialDateRange();
  }

  onChartConfigTypeChange(type: ChartWorkspaceTypeEnum) {
    this.prepareAlgorithmsCardSettings(type);
    this.setFiltrableItemsByType(type);
  }

  checkDateMode(mode: DateSelectModeEnum) {
    return this.form.get(this.timeModeFieldName).value === mode;
  }

  checkConfigMode(mode: ChartWorkspaceTypeEnum) {
    return this.form.get(this.configModeFieldName).value === mode;
  }

  getIcon(item: AlgorithmFiltrableItemDto | SignalFiltrableItemDto) {
    return item?.filtrableType?.icon;
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.form.get(this.dateRangeFieldName).setValue(dateRange);
  }

  onDateRangeError(hasErrors: boolean) {
    this.hasDateRangeErrors = hasErrors;
  }

  getForm = () => {
    return this.form;
  };

  private initChartConfigTypes() {
    this.chartConfigTypes['workspace'] = ChartWorkspaceTypeEnum.Workspace;
    this.chartConfigTypes['template'] = ChartWorkspaceTypeEnum.Template;
  }

  private initTimeModes() {
    this.timeModes['fixed'] = DateSelectModeEnum.Fixed;
    this.timeModes['rolling'] = DateSelectModeEnum.Rolling;
  }

  private initTimeUnits() {
    this._sharedConstants
      .getTimeAggregationMapping()
      .pipe(untilDestroyed(this))
      .subscribe((mapping) => {
        this.timeUnitsAllowed.forEach((t) => {
          if (mapping.has(t)) {
            this.timeUnits[mapping.get(t)] = t;
          }
        });
      });
  }

  private createForm() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    const workspaceTypeId = this.workspace?.workspaceTypeId ?? ChartWorkspaceTypeEnum.Workspace;
    formControls[this.configModeFieldName] = new UntypedFormControl({
      value: workspaceTypeId,
      disabled: this.isWorkspaceTypeDisabled(),
    });
    formControls[this.configModeFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onChartConfigTypeChange(data));

    const wsName = this.workspace?.workspaceName ?? null;
    formControls[this.nameFieldName] = new UntypedFormControl(
      {
        value: wsName,
        disabled: this.isLoadMode,
      },
      [Validators.required]
    );

    const wsDescription = this.workspace?.workspaceDescription ?? null;
    formControls[this.descriptionFieldName] = new UntypedFormControl({
      value: wsDescription,
      disabled: this.isLoadMode,
    });

    const timeMode = this.workspace?.timeWindowMode ?? DateSelectModeEnum.Fixed;
    formControls[this.timeModeFieldName] = new UntypedFormControl(timeMode, [Validators.required]);
    formControls[this.timeModeFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onDateModeChange(data));

    const timeUnit = this.workspace?.timeAggregationRolling ?? null;
    formControls[this.timeUnitFieldName] = new UntypedFormControl(timeUnit);

    const offset = this.workspace?.rollingOffset ?? null;
    formControls[this.offsetFieldName] = new UntypedFormControl(offset);

    const period = this.workspace?.rollingWindowWidth ?? null;
    formControls[this.periodFieldName] = new UntypedFormControl(period);

    const isGenericDate = this.workspace?.isGenericDate ?? false;
    formControls[this.isGenericDateFieldName] = new UntypedFormControl(isGenericDate, [
      Validators.required,
    ]);

    const dateRange = this._workspacesHelperService.getDateRangeFromWorkspace(this.workspace);
    formControls[this.dateRangeFieldName] = new UntypedFormControl(dateRange);

    const isPublic = this.workspace?.isPublic ?? false;
    formControls[this.isPublicFieldName] = new UntypedFormControl(
      {
        value: isPublic,
        disabled: this.isLoadMode,
      },
      [Validators.required]
    );

    const includeFlowPoints = this.workspace?.includeBoundariesSignals ?? false;
    formControls[this.includeFlowBoundaryPointsFieldName] = new UntypedFormControl(
      includeFlowPoints
    );

    const includePressurePoints = this.workspace?.includePressureSignals ?? false;
    formControls[this.includePressurePointsFieldName] = new UntypedFormControl(
      includePressurePoints
    );

    const includeLargeUserPoints = this.workspace?.includeLargeUsersSignals ?? false;
    formControls[this.includeLargeUsersPointsFieldName] = new UntypedFormControl(
      includeLargeUserPoints
    );

    const includeInletOutletPoints = this.workspace?.includeInletOutletSignals ?? false;
    formControls[this.includeInletOutletPointsFieldName] = new UntypedFormControl(
      includeInletOutletPoints
    );

    const includeLevelSignals = this.workspace?.includeLevelSignals ?? false;
    formControls[this.includeLevelPointsFieldName] = new UntypedFormControl(includeLevelSignals);

    const includeLarsSworpsSignals = this.workspace?.includeLarsSworpsSignals ?? false;
    formControls[this.includeLarsSworpsPointsFieldName] = new UntypedFormControl(
      includeLarsSworpsSignals
    );

    const includeSmartMetersSignals = this.workspace?.includeSmartMetersSignals ?? false;
    formControls[this.includeSmartMeterPointsFieldName] = new UntypedFormControl(
      includeSmartMetersSignals
    );

    this.form = this._formBuilder.group(formControls);

    this.onDateModeChange(timeMode);

    formControls[this.isGenericDateFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onIsGenericDateChanges(data));

    formControls[this.offsetFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => formControls[this.periodFieldName]?.updateValueAndValidity());

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.onFormValueChanges());

    this.onFormValueChanges();
  }

  private setInitialDateRange() {
    const dateRangeCtrl = this.form.get(this.dateRangeFieldName)?.value;

    this.initialDateRange =
      dateRangeCtrl ?? this._dateHelperService.createDefaultDateRange(this._offsetStartDate);
  }

  private onDateModeChange(mode: DateSelectModeEnum) {
    const timeUnitCtrl = this.form.get(this.timeUnitFieldName);
    const offsetCtrl = this.form.get(this.offsetFieldName);
    const periodCtrl = this.form.get(this.periodFieldName);

    if (mode === DateSelectModeEnum.Rolling) {
      timeUnitCtrl.setValidators([Validators.required]);
      offsetCtrl.setValidators([Validators.required, Validators.min(1)]);
      periodCtrl.setValidators([
        Validators.required,
        Validators.min(1),
        DataVisualizationConfigFormValidators.maxPeriod(this.getForm, this.offsetFieldName),
      ]);
    } else {
      timeUnitCtrl.clearValidators();
      offsetCtrl.clearValidators();
      periodCtrl.clearValidators();
    }

    timeUnitCtrl.updateValueAndValidity();
    offsetCtrl.updateValueAndValidity();
    periodCtrl.updateValueAndValidity();
  }

  private onIsGenericDateChanges(isGenericDate: boolean) {
    const dateRangeCtrl = this.form.get(this.dateRangeFieldName);

    if (isGenericDate) {
      dateRangeCtrl.setValidators([Validators.required]);

      if (dateRangeCtrl.value === null) {
        dateRangeCtrl.setValue(this.initialDateRange);
      }
    } else {
      dateRangeCtrl.clearValidators();
    }

    dateRangeCtrl.updateValueAndValidity();
  }

  private onFormValueChanges() {
    const hasValidChanges = this.form.valid;
    this.hasValidChanges.emit(hasValidChanges);
  }

  private prepareAlgorithmsCardSettings(chartConfigType?: ChartWorkspaceTypeEnum) {
    const type = chartConfigType ?? this.workspace?.workspaceTypeId;
    const hideSubtitle = type === ChartWorkspaceTypeEnum.Template;

    const getAlgorithmSubtitleFn = (hideSubtitle: boolean, model: AlgorithmFiltrableType) =>
      hideSubtitle ? '' : `${model.element.typeName} - ${model.element.name}`;

    this.algorithmCardSettings = new GenericCardSettings<AlgorithmFiltrableItemDto>({
      title1Fn: (model) => model.filtrableType.algorithmShortName,
      subtitleFn: (model) => getAlgorithmSubtitleFn(hideSubtitle, model.filtrableType),
    });
  }

  private preparePointsCardSettings() {
    const getPointTitleFn = (model: SignalFiltrableType) =>
      `${model.pointId} - ${model.pointDescription}`;

    this._localizationService
      .get(`${AppModules.DataVisualization}.labels`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        const getPointSubtitleFn = (model: SignalFiltrableType) =>
          model.isConfigured ? ts['point-configured'] : ts['point-not-configured'];

        this.pointCardSettings = new GenericCardSettings<SignalFiltrableItemDto>({
          title1Fn: (model) => getPointTitleFn(model.filtrableType),
          subtitleFn: (model) => getPointSubtitleFn(model.filtrableType),
        });
      });
  }

  private reloadForm() {
    this.form = null;
    this.setFiltrableItemsByType(this.workspace?.workspaceTypeId);
    this.prepareAlgorithmsCardSettings();
    this.preparePointsCardSettings();

    this.createForm();
    this.setInitialDateRange();
  }

  private bindSaveConfig() {
    this.saveConfig$?.pipe(untilDestroyed(this)).subscribe(() => {
      const workspace = this.generateWorkspaceDto();
      this.save.emit(workspace);
    });
  }

  private bindConflictError() {
    this.conflictError$?.pipe(untilDestroyed(this)).subscribe(() => {
      const nameCtrl = this.form?.get(this.nameFieldName);

      nameCtrl?.setErrors({ notUnique: true });
      this.form?.updateValueAndValidity();
      nameCtrl?.markAsTouched();
    });
  }

  private setFiltrableItemsByType(configType?: ChartWorkspaceTypeEnum) {
    const filtrableItems = this.chartConfiguration?.seriesConfiguration?.map(
      (serie) => serie.serieDefinition
    );

    const points = filtrableItems.filter(
      (item) => item.filtrableType?.type === FiltrableItemTypeEnum.Signal
    );
    this.points = points as SignalFiltrableItemDto[];

    let algorithms = filtrableItems.filter(
      (item) => item.filtrableType?.type === FiltrableItemTypeEnum.Algorithm
    );

    // Templates will only store one filtrable item per algorithm
    if (configType === ChartWorkspaceTypeEnum.Template) {
      algorithms = asEnumerable(algorithms)
        .Distinct((a: AlgorithmFiltrableItemDto) => a.filtrableType.algorithmShortName)
        .ToArray();
    }

    this.algorithms = algorithms as AlgorithmFiltrableItemDto[];
  }

  private generateWorkspaceDto(): WorkspaceDto {
    const configMode = this.form.get(this.configModeFieldName).value;

    if (configMode === ChartWorkspaceTypeEnum.Workspace) {
      this.form.get(this.includeFlowBoundaryPointsFieldName).setValue(null);
      this.form.get(this.includePressurePointsFieldName).setValue(null);
    }

    const isFixed = this.form.get(this.timeModeFieldName).value === DateSelectModeEnum.Fixed;
    const isGenericDate = this.form.get(this.isGenericDateFieldName).value;

    const dateRangeCtrl = this.form.get(this.dateRangeFieldName)?.value as DateRange;
    const startDate = dateRangeCtrl?.start ?? null;
    const endDate = dateRangeCtrl?.end ?? null;

    const definition = this.getWorkspaceDtoDefinition(configMode, this.chartConfiguration);
    const workspaceId = this.workspace?.workspaceId ?? 0;

    const workspaceDto = new WorkspaceDto({
      ...this.form.getRawValue(),
      workspaceId: this.isEditMode || this.isLoadMode ? workspaceId : 0,
      definition,
      startDate,
      endDate,
      userCode: this.workspace?.userCode ?? null,
    });

    if (isFixed) {
      workspaceDto[this.timeUnitFieldName] = null;
      workspaceDto[this.offsetFieldName] = null;
      workspaceDto[this.periodFieldName] = null;

      if (!isGenericDate) {
        const dateRange = this._workspacesHelperService.getDateRangeFromChartConfiguration(
          this.chartConfiguration
        );
        workspaceDto.startDate = this._dateHelperService.toApiFormat(dateRange.start);
        workspaceDto.endDate = this._dateHelperService.toApiFormat(dateRange.end);
      }
    } else {
      workspaceDto[this.dateRangeFieldName] = null;
      workspaceDto[this.isGenericDateFieldName] = null;
    }

    return workspaceDto;
  }

  private getWorkspaceDtoDefinition(
    type: ChartWorkspaceTypeEnum,
    chartConfiguration: ChartConfiguration
  ) {
    let wsDefinition = {};

    switch (type) {
      case ChartWorkspaceTypeEnum.Template:
        wsDefinition = this._workspacesHelperService.getTemplateDefinition(chartConfiguration);
        break;

      case ChartWorkspaceTypeEnum.Workspace:
      default:
        wsDefinition = {
          chartConfigurations: [chartConfiguration],
          cartFiltrableItems: this.cartFiltrableItems,
        };
        break;
    }

    return JSON.stringify(wsDefinition);
  }

  private initSignalCategories() {
    const pressureCrud$ = this._authorizeService.canAccess(CrudConstants.Pressure, 'r');
    const larsSworpsCrud$ = this._authorizeService.canAccess(CrudConstants.LarsSworps, 'r');
    const smartMetersCrud$ = this._authorizeService.canAccess(CrudConstants.SmartMeters, 'r');

    forkJoin([pressureCrud$, larsSworpsCrud$, smartMetersCrud$]).subscribe(
      ([pressureCrud, larsSworpsCrud, smartMetersCrud]) => {
        this.signalHECategories[SignalConfigurationCategory.Boundary] =
          this.includeFlowBoundaryPointsFieldName;
        if (pressureCrud) {
          this.signalHECategories[SignalConfigurationCategory.Pressure] =
            this.includePressurePointsFieldName;
        }
        this.signalHECategories[SignalConfigurationCategory.LargeUserConsumption] =
          this.includeLargeUsersPointsFieldName;
        if (smartMetersCrud) {
          this.signalHECategories[SignalConfigurationCategory.SmartMeters] =
            this.includeSmartMeterPointsFieldName;
        }

        this.signalNECategories[SignalConfigurationCategory.InletOutlet] =
          this.includeInletOutletPointsFieldName;
        this.signalNECategories[SignalConfigurationCategory.Level] =
          this.includeLevelPointsFieldName;
        if (larsSworpsCrud) {
          this.signalNECategories[SignalConfigurationCategory.LarsSworps] =
            this.includeLarsSworpsPointsFieldName;
        }
      }
    );
  }

  private isWorkspaceTypeDisabled() {
    return (
      this.isEditMode ||
      this.isLoadMode ||
      !this.chartConfiguration.seriesConfiguration
        .map((s) => s.serieDefinition.filtrableType.type)
        .some((type) => type === FiltrableItemTypeEnum.Algorithm)
    );
  }
}
