import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { CurrentCalculatedValuesViewDto } from '../../../shared/model/leakage-reporting/current-cv-view.dto';
import { FlagSystemItemDto } from '../../../shared/model/leakage-reporting/flag-system-item-dto';
import { FlaggedItemsDto } from '../../../shared/model/leakage-reporting/flagged-items.dto';
import { FlaggedNotificationDto } from '../../../shared/model/leakage-reporting/flagged-notification.dto';
import { LeakageReportingShortNamesEnum } from '../../../shared/model/leakage-reporting/leakage-reporting-shortnames.enum';
import { LeakageReportingService } from '../../leakage-reporting.service';
import { FlaggingValidator } from '../flagging-validator';

const COMPONENT_SELECTOR = 'wlm-unflag-reason-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './unflag-reason-popup.component.html',
  styleUrls: ['./unflag-reason-popup.component.scss'],
})
export class UnflagReasonPopupComponent implements OnInit {
  public T_SCOPE = `${AppModules.LeakageReporting}.${COMPONENT_SELECTOR}`;
  form: UntypedFormGroup;
  currentSelectedRows: CurrentCalculatedValuesViewDto[];
  isSaving: boolean;
  hasChanges: boolean;
  algorithmShortnames = [
    LeakageReportingShortNamesEnum.DDIR.toLocaleLowerCase(),
    LeakageReportingShortNamesEnum.DLCCMNFFHR.toLocaleLowerCase(),
    LeakageReportingShortNamesEnum.MNFFHR.toLocaleLowerCase(),
    LeakageReportingShortNamesEnum.DALCCR.toLocaleLowerCase(),
  ];

  ddiRControl: AbstractControl;
  dlccmnffhRControl: AbstractControl;
  mnffhRControl: AbstractControl;
  dalccRControl: AbstractControl;
  checkEnableNotificationsControl: AbstractControl;
  notificationTitleControl: AbstractControl;
  notificationDescriptionControl: AbstractControl;

  private _ddiRFieldname = 'ddir';
  private _dlccmnffhRFieldname = 'dlccmnffhr';
  private _mnffhRFieldname = 'mnffhr';
  private _dalccRFieldname = 'dalccr';
  private _checkEnableNotificationsFieldname = 'checkEnableNotifications';
  private _notificationTitleFieldname = 'notificationTitle';
  private _notificationDescriptionFieldname = 'notificationDescription';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<UnflagReasonPopupComponent>,
    private _leakageReportingService: LeakageReportingService,
    private _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) selectedRows: CurrentCalculatedValuesViewDto[]
  ) {
    this.currentSelectedRows = selectedRows;
    this.createForm();
  }

  ngOnInit(): void {}

  save() {
    this.isSaving = true;

    const notificationData = this.prepareNotificationData();
    const elementsToUnflag = this.calculateElementsToUnflag();

    if (elementsToUnflag.length) {
      const flagData = new FlaggedItemsDto({
        flaggedItems: elementsToUnflag,
        notification: notificationData,
      });

      this._leakageReportingService.saveFlagSystemItems(flagData).subscribe({
        next: (jobId) => {
          this.isSaving = false;
          this._dialogRef.close(jobId);
        },
        error: (error) => {
          this.isSaving = false;
          const message = `${this.T_SCOPE}.messages.unflag-error`;

          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: message, icon: 'error' })
          );
        },
      });
    } else {
      this.isSaving = false;
      this._dialogRef.close(false);
    }
  }

  close() {
    this._dialogRef.close(false);
  }

  onChange($event) {
    let anySelection = false;
    Object.keys(this.form.controls).forEach((key) => {
      anySelection = anySelection || this.form.controls[key].value;
    });
    this.hasChanges = anySelection;
  }

  onEnableNotification($event: MatCheckboxChange) {
    if ($event.checked) {
      this.notificationTitleControl.enable();
      this.notificationDescriptionControl.enable();
    } else {
      this.notificationTitleControl.setValue(null);
      this.notificationDescriptionControl.setValue(null);
      this.notificationTitleControl.disable();
      this.notificationDescriptionControl.disable();
    }
  }

  private createForm() {
    this.form = this._formBuilder.group(
      {
        ddir: [false],
        dlccmnffhr: [false],
        mnffhr: [false],
        dalccr: [false],
        checkEnableNotifications: [false],
        notificationTitle: [{ value: '', disabled: true }],
        notificationDescription: [{ value: '', disabled: true }],
      },
      {
        validators: FlaggingValidator.notificationFieldsValidation(),
      }
    );

    this.setFormControlVariables();
  }

  private setFormControlVariables() {
    this.ddiRControl = this.form.controls[this._ddiRFieldname];
    this.dlccmnffhRControl = this.form.controls[this._dlccmnffhRFieldname];
    this.mnffhRControl = this.form.controls[this._mnffhRFieldname];
    this.dalccRControl = this.form.controls[this._dalccRFieldname];

    this.checkEnableNotificationsControl =
      this.form.controls[this._checkEnableNotificationsFieldname];
    this.notificationTitleControl = this.form.controls[this._notificationTitleFieldname];
    this.notificationDescriptionControl =
      this.form.controls[this._notificationDescriptionFieldname];
  }

  private calculateElementsToUnflag(): FlagSystemItemDto[] {
    const elementsToUnflag = [];

    const ddiRReasonId = this.ddiRControl.value as boolean;
    const dlccmnffhRReasonId = this.dlccmnffhRControl.value as boolean;
    const mnffhRReasonId = this.mnffhRControl.value as boolean;
    const dalccRReasonId = this.dalccRControl.value as boolean;

    this.currentSelectedRows.forEach((element) => {
      if (
        this.checkAlorithmValue(element.ddiR_V) &&
        this.checkReasonValue(ddiRReasonId, element.ddiR_R)
      ) {
        const unflagElement = this.getFlagSystemItemDto(
          element,
          LeakageReportingShortNamesEnum.DDIR,
          element.ddiR_R
        );
        elementsToUnflag.push(unflagElement);
      }
      if (
        this.checkAlorithmValue(element.dlccmnffhR_V) &&
        this.checkReasonValue(dlccmnffhRReasonId, element.dlccmnffhR_R)
      ) {
        const unflagElement = this.getFlagSystemItemDto(
          element,
          LeakageReportingShortNamesEnum.DLCCMNFFHR,
          element.dlccmnffhR_R
        );
        elementsToUnflag.push(unflagElement);
      }
      if (
        this.checkAlorithmValue(element.mnffhR_V) &&
        this.checkReasonValue(mnffhRReasonId, element.mnffhR_R)
      ) {
        const unflagElement = this.getFlagSystemItemDto(
          element,
          LeakageReportingShortNamesEnum.MNFFHR,
          element.mnffhR_R
        );
        elementsToUnflag.push(unflagElement);
      }
      if (
        this.checkAlorithmValue(element.dalccR_V) &&
        this.checkReasonValue(dalccRReasonId, element.dalccR_R)
      ) {
        const unflagElement = this.getFlagSystemItemDto(
          element,
          LeakageReportingShortNamesEnum.DALCCR,
          element.dalccR_R
        );
        elementsToUnflag.push(unflagElement);
      }
    });
    return elementsToUnflag;
  }

  private getFlagSystemItemDto(
    currentCV: CurrentCalculatedValuesViewDto,
    algorithmShortName: string,
    lastReasonId: number
  ) {
    const unflagElement = new FlagSystemItemDto({
      elementId: currentCV.elementId,
      shortName: algorithmShortName,
      date: currentCV.date,
      reasonId: null,
      lastReasonId,
    });
    return unflagElement;
  }

  private checkAlorithmValue(value: number): boolean {
    return value !== undefined && value !== null;
  }

  private checkReasonValue(formValue: boolean, previousReason: number): boolean {
    return formValue && previousReason !== null && previousReason !== undefined;
  }

  private prepareNotificationData() {
    const hasNotifications = this.checkEnableNotificationsControl.value as boolean;
    const notificationTitle = this.notificationTitleControl.value;
    const notificationDescription = this.notificationDescriptionControl.value;

    return hasNotifications
      ? new FlaggedNotificationDto({
          title: notificationTitle,
          description: notificationDescription,
        })
      : null;
  }
}
