import { CalendarExceptionTypesEnum } from './exception-list.enum';

export class DeleteCalendarExceptionDto {
  calendarId: string;
  exceptionId: string;
  type: CalendarExceptionTypesEnum;

  constructor(init: Partial<DeleteCalendarExceptionDto>) {
    Object.assign(this, init);
  }
}
