<div *ngIf="!loading && dataLoaded">
  <wlm-generic-chart
    *ngIf="genericChartSettings"
    [settings]="genericChartSettings"
    [loadingHandler$]="loadingHandler$"
    [genericChartService]="genericChartService"
    [exportChart$]="exportChart$"
    [showDataPoints$]="showDataPoints$"
    [size]="size"
    (chartLegendSelectedEvent)="onChartLegendSelected($event)"
    (chartDataZoomEvent)="onChartDataZoom($event)"
    (chartLoaded)="onChartLoaded($event)"
  ></wlm-generic-chart>
</div>

<wlm-no-results [showNoResult]="!loading && (!dataLoaded || !genericChartSettings)">
</wlm-no-results>
