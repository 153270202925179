import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrencyTypesEnum } from '../model/shared/currency-types.enum';
import { HierarchyElementUnitsQueryParameters } from '../model/uom/hierarchy-element-type-time-units-query.dto';
import { UnitTypeConversionViewDto } from '../model/uom/unit-type-conversion-view.dto';
import { UoMService } from './uom.service';

@Pipe({
  name: 'uomConversionFormattedUnit',
})
export class UomConversionFormattedUnitPipe implements PipeTransform {
  private _translationScope = 'common.unit-labels';

  private _defaultConversionFactor = new UnitTypeConversionViewDto({
    conversionFactor: 1,
    isDefaultFromUnit: true,
    dimensionTypeDescription: '',
    unitTypeFromDescription: '',
    unitTypeFromId: 0,
    dimensionTypeId: 0,
    unitTypeToId: 0,
    unitTypeToDescription: '',
  });

  transform(
    value: number,
    hierarchyElementTypeId,
    timeAggregation,
    dimensionTypeId,
    decimalPositions,
    unitFormat,
    labelKey,
    currencyTypeId: CurrencyTypesEnum
  ): Observable<string> {
    const conversionFactor = this._uomService.getByHEUnit(
      new HierarchyElementUnitsQueryParameters(
        hierarchyElementTypeId,
        dimensionTypeId,
        timeAggregation
      )
    );
    return conversionFactor.pipe(
      switchMap((defaultConversion) => {
        return this._uomService
          .getFormatedUnit(
            defaultConversion.unitTypeToDescription,
            `${this._translationScope}.${labelKey}`,
            unitFormat
          )
          .pipe(
            map((unitLabel) => {
              const conversion =
                currencyTypeId == CurrencyTypesEnum.NA
                  ? defaultConversion
                  : this._defaultConversionFactor;

              const unitFormatted = this._uomService.getConvertedValue(
                conversion,
                value,
                decimalPositions,
                false
              );

              return `${unitFormatted} ${unitLabel}`;
            })
          );
      })
    );
  }

  constructor(private _uomService: UoMService) {}
}
