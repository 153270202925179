import { GChartItemStyle } from './g-chart-item-style';
import { GChartLabelLayout } from './g-chart-label-layout';
import { GChartLineStyle } from './g-chart-line-style';
import { GChartSerieMarkArea } from './g-chart-serie-mark-area';
import { GChartSeriesEncode } from './g-chart-series-encode';
import { GChartVisualMap } from './g-chart-visual-map';

export abstract class BaseGCartesianChartSeries {
  id?: string;
  name?: string;
  abstract type;
  xAxisIndex? = 0;
  yAxisIndex? = 0;
  labelLayout?: GChartLabelLayout;
  encode?: GChartSeriesEncode;
  xAxisName?: string;
  yAxisName?: string;
  lineStyle?: GChartLineStyle;
  itemStyle?: GChartItemStyle;
  markArea?: GChartSerieMarkArea;
  // This property is then mapped outside the series.
  visualMap?: GChartVisualMap;

  constructor(init: Readonly<BaseGCartesianChartSeries>) {
    Object.assign(this, init);
  }
}
