import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { FormModes } from 'src/app/common-modules/shared/model/shared/form-mode.enum';
import { NotificationPopupDto } from 'src/app/common-modules/shared/model/shared/notification-popup.dto';
import { NotificationService } from '../../../monitoring/notification/services/notification.service';

const COMPONENT_SELECTOR = 'wlm-notification-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {
  form: UntypedFormGroup;
  selectedNotification: NotificationDto;
  isEditionMode = false;
  submit$ = new Subject<void>();
  formLoaded = false;
  formValid = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formMode: FormModes = FormModes.Create;
  canSave = false;
  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;
  createTitleKey = `${this.T_SCOPE}.create-title`;
  editTitleKey = `${this.T_SCOPE}.edit-title`;
  titleKey = this.createTitleKey;

  constructor(
    private _dialogRef: MatDialogRef<NotificationPopupComponent>,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) { notification, mode, notificationId }: NotificationPopupDto
  ) {
    this.formMode = mode;
    this.setTitle();
    if (notification) {
      this.selectedNotification = notification;
    } else if (notificationId) {
      this._notificationService.getById(notificationId).subscribe((notificationToEdit) => {
        this.selectedNotification = notificationToEdit;
      });
    }
  }

  ngOnInit(): void {}

  onFormLoad(loaded: boolean): void {
    this.formLoaded = loaded;
  }

  onFormValid(valid: boolean): void {
    this.formValid = valid;
  }

  onSubmit(): void {
    this.submit$.next();
  }

  onSubmitResult(): void {
    this.onClose(true);
  }

  onCanSave(canSave: boolean) {
    this.canSave = canSave;
  }

  setTitle(): void {
    if (this.formMode === 'create') {
      this.titleKey = this.createTitleKey;
    }
    if (this.formMode === 'edit') {
      this.titleKey = this.editTitleKey;
    }
  }

  onClose(hasSubmitted: boolean = false) {
    this._dialogRef.close(hasSubmitted);
  }
}
