import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { CustomerPremisesViewDto } from '../../shared/model/customer/customer-premises-view.dto';
import { CustomerShortDto } from '../../shared/model/customer/customer-short.dto';
import { DeleteLargeUserDto } from '../../shared/model/customer/delete-large-user.dto';
import { SaveLargeUserDto } from '../../shared/model/customer/save-large-user.dto';
import { LargeUserAssignedDto } from '../../shared/model/signals/large-user-assigned.dto';
import { LargeUserCreationResponseDto } from '../../shared/model/signals/large-user-creation-response.dto';
import { CustomerMeterDto } from '../models/customer-meter.dto';

const luApiUrl = '/api/large-user';
@Injectable({ providedIn: 'root' })
export class CustomerDetailsService extends GridODataService<CustomerPremisesViewDto> {
  constructor(injector: Injector, private _objectHelper: ObjectHelperService) {
    super(injector, '/api/customer/detail');
  }

  protected mapResponse(
    response: PagedResultDto<CustomerPremisesViewDto>
  ): PagedResultDto<CustomerPremisesViewDto> {
    return response;
  }

  saveLargeUser(
    customerId: string,
    hierarchyElementId: string
  ): Observable<LargeUserCreationResponseDto> {
    const saveLargeUser = new SaveLargeUserDto({
      customerId,
      hierarchyElementId,
    });
    return this.httpCacheClient.post(`${this.baseUrl}${luApiUrl}/save-large-user`, saveLargeUser);
  }

  deleteLargeUser(customerId: string): Observable<boolean> {
    const saveLargeUser = new DeleteLargeUserDto({
      customerId,
    });
    return this.httpCacheClient.delete(
      `${this.baseUrl}${luApiUrl}/delete-large-user`,
      {
        avoid: true,
      },
      null,
      null,
      saveLargeUser
    );
  }

  saveLargeUserConfiguration(
    largeUsersConfigurations: LargeUserAssignedDto[]
  ): Observable<boolean> {
    return this.httpCacheClient.post(`${this.baseUrl}${luApiUrl}/save`, largeUsersConfigurations);
  }

  getCustomerMeters(customerId: string): Observable<CustomerMeterDto[]> {
    return this.httpCacheClient
      .get(`${this.apiUrl}/customer/${customerId}/meters`, {
        avoid: true,
      })
      .pipe(map((data: any) => data?.customerMeters));
  }

  getCustomerByPropertyId(propertyId: string): Observable<CustomerShortDto[]> {
    return this.httpCacheClient
      .get(`${this.apiUrl}/customer/search/${propertyId}`, {
        avoid: true,
      })
      .pipe(map((data: any) => data));
  }
}
