import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';

import { UsersActivityFiltersConfiguration } from './users-activity-filters-configuration';

const COMPONENT_SELECTOR = 'wlm-users-activity-filters';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './users-activity-filters.component.html',
  styleUrls: ['./users-activity-filters.component.scss'],
})
export class UsersActivityFiltersComponent implements OnInit {
  @Output() filtersDetailParametersChanged = new EventEmitter<TabDetailPanelParameters>();
  @Output() apply = new EventEmitter<void>();
  @Output() filter = new EventEmitter<Map<string, BasicFilter>>();

  private _config: UsersActivityFiltersConfiguration;
  public get config(): UsersActivityFiltersConfiguration {
    return this._config;
  }
  @Input() public set config(value: UsersActivityFiltersConfiguration) {
    this._config = value;
  }
  @Input() clearAll$ = new Subject<void>();

  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  private _filtersParameters = new DataBindingFilters();
  private _filtersDetailParameters = new TabDetailPanelParameters();

  isReady: boolean = false;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
    disableSelectAll: true,
  };

  dateRangeItemSettings: BaseFilterItemSettings = {
    hideInputSummaryLabel: true,
  };

  bfDateSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-date-label`,
    disableSearch: true,
  };

  filterText: string;
  allFilters = new Map<string, BasicFilter>();

  constructor(private adaptersService: FiltersAdapterService) {}

  ngOnInit(): void {}

  onApplyFilters(filters): void {
    this.apply.emit();
  }

  onFilterGroupDate(filters: FiltersPayload): void {
    if (filters) {
      this.dateRangeAdapter(filters.data);
      this.appendFilters(filters.data);
    }
  }

  onFilterText(filterText: string): void {
    this.filterText = filterText;
  }

  dateRangedChanged(dateRange: DateRange) {
    this._filtersParameters.filters.set('dateRange', dateRange);
    this._filtersDetailParameters.addParameter(TabDetailParameterName.startDate, dateRange.start);
    this._filtersDetailParameters.addParameter(TabDetailParameterName.endDate, dateRange.end);
    this.emitEvents();
  }

  dateRangeAdapter(filters: Map<string, BasicFilter>): void {
    const { startDateFieldName, endDateFieldName } = this.config;
    const result = this.adaptersService.dateRangeAdapter(
      filters,
      startDateFieldName,
      endDateFieldName
    );
    if (result) {
      this.dateRangedChanged(result);
    }
  }

  /**
   * Appends all the filters.
   */
  appendFilters(filters: Map<string, BasicFilter>): void {
    this.allFilters = new Map([...this.allFilters, ...filters]);
    this.filter.emit(this.allFilters);
  }

  emitEvents() {
    this.filtersDetailParametersChanged.emit(this._filtersDetailParameters);
  }
}
