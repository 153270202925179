<div class="grid-filter-container">
  <mat-form-field appearance="outline">
    <mat-select #firstFilter [(ngModel)]="firstFilterOperator">
      <mat-option
        *ngFor="let operator of filterOperators"
        [value]="operator.value"
        (click)="preventEvent($event)"
        >{{ operator.text }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <input
      type="number"
      matInput
      [(ngModel)]="firstFilterValue"
      [disabled]="disabledFirstFilter"
      autocomplete="off"
    />
  </mat-form-field>

  <mat-radio-group class="radio-position" [(ngModel)]="unionOperator">
    <mat-radio-button color="primary" value="and">
      {{ T_SCOPE + '.filterAndLogic' | translate }}
    </mat-radio-button>
    <mat-radio-button color="primary" value="or">
      {{ T_SCOPE + '.filterOrLogic' | translate }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field appearance="outline">
    <mat-select #secondFilter [(ngModel)]="secondFilterOperator">
      <mat-option
        *ngFor="let operator of filterOperators"
        [value]="operator.value"
        (click)="preventEvent($event)"
        >{{ operator.text }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <input
      type="number"
      matInput
      [(ngModel)]="secondFilterValue"
      [disabled]="disabledSecondFilter"
      autocomplete="off"
    />
  </mat-form-field>
</div>
