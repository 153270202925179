<div class="comment-card">
  <div class="card-title">
    <div>
      {{ T_SCOPE + '.subtitle' | translate: { createdBy: authorName } }}
    </div>
    <div>
      {{ comment.lastUpdate | date }}
    </div>
  </div>
  <div class="padding-sides">
    <div [innerHtml]="comment.commentDescription"></div>
  </div>
</div>
