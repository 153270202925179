import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from '../../shared/app-modules.enum';
import { IGridSettings } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { NotificationAttributeDto } from '../../shared/model/notifications/notification-attribute.dto';
import { SimpleColumn } from '../../shared/model/shared/simple-column';

const COMPONENT_SELECTOR = 'wlm-rowdata-visualizer';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './rowdata-visualizer.component.html',
  styleUrl: './rowdata-visualizer.component.scss'
})
export class RowdataVisualizerComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  private _columnValues: SimpleColumn[];

  @Input() public set columnValues(value: SimpleColumn[]) {
    this._columnValues = value;

    if (this._columnValues) {
      this.attributeValues = this._columnValues.map(
        (sc) =>
          new NotificationAttributeDto({
            notificationAttributeId: sc.columnId,
            notificationAttributeName: sc.columnTitle,
            notificationAttributeValue: sc.value,
          })
      );
    }
  }

  gridSettings: IGridSettings;
  attributeValues: NotificationAttributeDto[];
  attributesGridName = 'ColumnValues';


  constructor(private _gridSettings: GridSettingsService) {
  }


  ngOnInit(): void {
    this._gridSettings.getGridSettingsByName(this.attributesGridName).subscribe({
      next: (gridSetting) => {
        this.gridSettings = gridSetting;
        this.gridSettings.hideHeader = true;
        this.gridSettings.gridColumnSettings[0].width = 150;
      }
    })
  }
}
