import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialSharedModule } from 'src/app/common-modules/shared/material-shared.module';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { HierarchyChildrenConfiguratorComponent } from './components/hierarchy-children-configurator/hierarchy-children-configurator.component';
import { HierarchyChildrenConfiguratorService } from './services/hierarchy-children-configurator.service';
import { HierarchyRoutingModule } from './hierarchy-routing.module';
import { HierarchyFilterWidgetComponent } from './widgets/hierarchy-filter-widget/hierarchy-filter-widget.component';
import { HierarchyChildrenConfiguratorWidgetComponent } from './widgets/hierarchy-children-configurator-widget/hierarchy-children-configurator-widget.component';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { GenericStoreService } from 'src/app/common-modules/dynamic-layout/state/generic/generic-store.service';
import { HierarchyPageComponent } from './hierarchy-page/hierarchy-page.component';
import { HierarchySelectorWidgetComponent } from './widgets/hierarchy-selector-widget/hierarchy-selector-widget.component';
import { HierarchySelectorComponent } from './components/hierarchy-selector/hierarchy-selector.component';

@NgModule({
  declarations: [
    HierarchyPageComponent,
    HierarchyChildrenConfiguratorComponent,
    HierarchyFilterWidgetComponent,
    HierarchySelectorWidgetComponent,
    HierarchyChildrenConfiguratorWidgetComponent,
    HierarchySelectorComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    MaterialSharedModule,
    HierarchyRoutingModule,
    WLMGridModule,
    TranslateModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
  ],
  providers: [
    HierarchyChildrenConfiguratorService,
    {
      provide: 'HierarchyChildrenConfiguratorService',
      useExisting: HierarchyChildrenConfiguratorService,
    },
  ],
})
export class HierarchyModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'HierarchyFilterWidgetComponent',
        widgetClass: HierarchyFilterWidgetComponent,
      },
      {
        name: 'HierarchySelectorWidgetComponent',
        widgetClass: HierarchySelectorWidgetComponent,
      },
      {
        name: 'HierarchyChildrenConfiguratorWidgetComponent',
        widgetClass: HierarchyChildrenConfiguratorWidgetComponent,
      },
    ]);
  }
}
