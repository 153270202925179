import { Component, Inject, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { DynamicFormAdditionalSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSettings } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-settings';
import { DynamicFormUIValues } from 'src/app/common-modules/dynamic-forms/models/dynamic-form-ui-values';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import {
  FormAdditionalSettingsSelector,
  FormDefinitionsSelector,
  FormUIValuesSelector,
} from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { applyOnActiveWidget } from 'src/app/common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';

const COMPONENT_SELECTOR = 'wlm-ne-config-attribute-form-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-attribute-form-widget.component.html',
  styleUrls: ['./ne-config-attribute-form-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigAttributeFormWidgetComponent extends BaseDynamicWidgetComponent {
  pageId: string;
  formSettings: DynamicFormSettings;
  values: DynamicFormUIValues;
  categoryKey: string;
  hasChanges: boolean;
  selectedNE: INetworkElementDto;
  additionalSettings: DynamicFormAdditionalSettings;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _objectHelperService: ObjectHelperService
  ) {
    super(injector, widgetSettings);
    this.categoryKey = widgetSettings.params.categoryKey;
    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.selectOwnDefinition()
      .pipe(
        applyOnActiveWidget(this, (definition: DynamicFormSettings) => {
          this.formSettings = definition;
        })
      )
      .subscribe();

    this.selectValues()
      .pipe(
        applyOnActiveWidget(this, (values: DynamicFormUIValues) => {
          this.values = values;
        })
      )
      .subscribe();

    this._state
      .select<DynamicFormAdditionalSettings>(
        new FormAdditionalSettingsSelector(this._scopeSettings)
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (additionalSettings) => {
          this.additionalSettings = globalUtilsHelper.clone(additionalSettings, true);
        },
      });
  }

  private selectOwnDefinition(): Observable<DynamicFormSettings> {
    return this._state.select(new FormDefinitionsSelector(this._scopeSettings)).pipe(
      untilDestroyed(this),
      filter((data) => data !== undefined),
      map((formDefinitions: { [categoryKey: string]: DynamicFormSettings }) => {
        return formDefinitions[this.categoryKey];
      }),
      distinctUntilChanged(this._objectHelperService.deepEqual)
    );
  }

  private selectValues(): Observable<DynamicFormUIValues> {
    return this._state.select(new FormUIValuesSelector(this._scopeSettings)).pipe(
      untilDestroyed(this),
      filter((data) => data !== undefined)
    );
  }

  get componentName(): string {
    return 'NeConfigAttributeFormWidgetComponent';
  }
}
