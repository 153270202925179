import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialogs.service';
import { WLMDialogResult } from '../../model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '../../model/dialog/wlm-dialog-setting';
import { PendingChanges } from '../models/pending-changes';
import { PendingChangesDialogSettings } from '../models/pending-changes-dialog-settings';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesManagerService {
  private _popupOpen = false;
  private _pendingChangesMap: Map<string, PendingChanges[]> = new Map<string, PendingChanges[]>();

  constructor(private _dialogService: DialogService) {}

  setPendingChanges(key: string, changes: PendingChanges) {
    if (!key) {
      return;
    }

    const pageChanges = this._pendingChangesMap.get(key) ?? [];
    const index = pageChanges.findIndex((pc) => pc.componentId === changes.componentId);

    index < 0 ? pageChanges.push(changes) : (pageChanges[index] = changes);
    this._pendingChangesMap.set(key, pageChanges);
  }

  checkPendingChanges(key: string, customDialog?: PendingChangesDialogSettings): Observable<any> {
    if (this._popupOpen) {
      return of(null);
    }

    if (this.hasPendingChanges(key)) {
      return this.confirmPopUp(key, customDialog);
    }

    return of([true]);
  }

  removePendingChangesByComponent(key: string, componentId: string) {
    if (!this._pendingChangesMap.has(key)) {
      return;
    }

    const newChanges = this._pendingChangesMap
      .get(key)
      .filter((c) => c.componentId !== componentId);

    this._pendingChangesMap.set(key, newChanges);
  }

  removePendingChangesByPage(key: string) {
    this._pendingChangesMap.delete(key);
  }

  hasPendingChanges(key: string): boolean {
    return this._pendingChangesMap.get(key)?.some((c) => c.hasValidChanges);
  }

  applyPendingChangesByDefault(key: string, result: boolean) {
    if (result) {
      return forkJoin(
        this.getPendingChanges(key).map((c) => {
          this.removePendingChangesByComponent(key, c.componentId);

          return c.saveFn();
        })
      );
    }

    this._pendingChangesMap.delete(key);
    return of([result]);
  }

  private confirmPopUp(key: string, customDialog?: PendingChangesDialogSettings) {
    this._popupOpen = true;

    const dialogSettings =
      customDialog?.dialogSettings ??
      new WlmDialogSettings({
        translateKey: `common.messages.confirm-pending-changes`,
        icon: 'warning',
      });

    return this._dialogService.showTranslatedDialogMessage(dialogSettings).pipe(
      finalize(() => (this._popupOpen = false)),
      switchMap((dialogRef: WLMDialogResult) => {
        if (typeof customDialog?.dialogFn !== 'undefined') {
          return customDialog.dialogFn(dialogRef, this.getPendingChanges(key));
        }

        return this.applyPendingChangesByDefault(key, dialogRef.result);
      })
    );
  }

  private getPendingChanges(key: string): PendingChanges[] {
    return this._pendingChangesMap.get(key)?.filter((pc) => pc.hasValidChanges);
  }
}
