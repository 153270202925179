import { Injectable, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  signalRNotifyEvent,
  signalRConnectEvent,
} from 'src/app/common-modules/shared/model/notifications/constants';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../../../common-modules/shared/auth/services/authentication.service';
import { RemoteEventPayload } from './remote-event-payload';
import { RemoteEventTypes } from './remote-event-types.enum';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class NotificationHubService implements OnDestroy {
  public hubConnection: HubConnection;
  private remoteEvent$ = new Subject<RemoteEventPayload>();

  constructor(private _authenticationService: AuthenticationService) {}

  public init() {
    const builder = new HubConnectionBuilder();

    this._authenticationService.accessToken$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((accessToken) => {
        this.hubConnection = builder
          .withUrl(`${environment.apis.default.url}/hub/common`, {
            accessTokenFactory: () => accessToken,
            transport: HttpTransportType.LongPolling,
          })
          .build();

        this.hubConnection.on(signalRNotifyEvent, this.onNotifyEvent);
        this.hubConnection.start().then(() => this.hubConnection.invoke(signalRConnectEvent));
      });
  }

  private onNotifyEvent = (event: RemoteEventTypes): void => {
    // Leave support for events with payloads.
    const payload = new RemoteEventPayload({
      type: event,
      payload: null,
    });
    this.remoteEvent$.next(payload);
  };

  public onEvent(selectedType: RemoteEventTypes): Observable<RemoteEventPayload> {
    return this.remoteEvent$.pipe(
      filter((event: RemoteEventPayload) => event.type === selectedType)
    );
  }

  ngOnDestroy(): void {
    this.hubConnection.off(signalRNotifyEvent);
    this.hubConnection.stop();
  }
}
