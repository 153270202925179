import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionalHttpOpts } from '../../cache/http-cache/additional-http-options';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { TimeControlBarDto } from '../../wlm-grid/model/time-control-bar.dto';
import { SpinnerService } from '../../wlm-spinner/spinner.service';
import { DataBindingFilters } from '../filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '../helpers/array-helper.service';
import { UtilsHelperService } from '../helpers/utils-helper.service';
import { PagedResultDto } from '../model/paged-result.dto';
import { GridODataService } from './grid-odata.service';

@UntilDestroy()
@Directive({
  selector: '[wlmGridBinding]',
})
export class GridBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private ngOnInitExecuted = false;
  private _id: string;

  private _additionalFilters: Map<string, string>;

  public get additionalFilters(): Map<string, string> {
    return this._additionalFilters;
  }
  @Input() public set additionalFilters(v: Map<string, string>) {
    this._additionalFilters = v;
    this.rebind();
  }

  private _timeControlDates: TimeControlBarDto;
  public get timeControlDates(): TimeControlBarDto {
    return this._timeControlDates;
  }
  @Input() public set timeControlDates(v: TimeControlBarDto) {
    this._timeControlDates = v;
    this.rebind();
  }

  @Input() serviceName: string;
  @Input() count: boolean;

  // Helps to match query calls with subscriptions.
  @Input() subscriptionTag = 'default';

  @Input() cacheOpts: GetCacheOpts;
  @Input() addHttpOptions: AdditionalHttpOpts = { showSpinner: true };

  @Input() columns: string[] = [];
  @Input() selectAllColumns: string[] = [];

  private _initialColumnFilter: CompositeFilterDescriptor;
  public get initialColumnFilter(): CompositeFilterDescriptor {
    return this._initialColumnFilter;
  }
  @Input() public set initialColumnFilter(v: CompositeFilterDescriptor) {
    this._initialColumnFilter = v;
    if (this.ngOnInitExecuted && v) {
      this.state.filter = this.initialColumnFilter;
      this.grid.filter = this.initialColumnFilter;
      this.rebind();
    }
  }

  @Input() ignoreCase = false;
  @Input() utcDates = false;
  @Input() isHistorical = false;

  private _filters: DataBindingFilters;
  public get filters(): DataBindingFilters {
    return this._filters;
  }
  @Input() public set filters(filters: DataBindingFilters) {
    this._filters = filters;
    if (this.ngOnInitExecuted) {
      this.rebind();
    }
  }
  @Input() columnKey: string;
  @Input() _odataService: GridODataService<any>;
  @Output() totalCountChange = new EventEmitter<number>();
  @Output() gridDataLoaded = new EventEmitter<void>();
  @Input() defaultSortDescriptors: SortDescriptor[];
  @Input() conditionalSorting: SortDescriptor[];

  private readonly _maxRecordsToTake = 100000;

  query$: Subscription;
  constructor(
    grid: GridComponent,
    private _spinnerService: SpinnerService,
    private _utilsHelper: UtilsHelperService,
    private _arrayHelper: ArrayHelperService
  ) {
    super(grid);
    this._id = this._utilsHelper.generateGuid();

    // Bind 'this' explicitly to capture the execution context of the component.
    this.allData = this.allData.bind(this);
  }

  public ngOnInit(): void {
    this.ngOnInitExecuted = true;
    this._id = this.getExtendedId();

    this._odataService?.pipe(untilDestroyed(this)).subscribe({
      next: (result) => {
        if (
          result == null ||
          (typeof result.subscriptionTag !== 'undefined' && // If no tag is specified, work as expected.
            result.subscriptionTag !== this.subscriptionTag) // If tag exists, it must match.
        ) {
          return;
        }

        this.grid.data = { data: result.items, total: result.totalCount };

        this.totalCountChange.emit(result.totalCount);
        this.gridDataLoaded.emit();
      },
      error: (err) => {
        this._spinnerService.setLoading(false, this._id);
      },
    });

    this._odataService.loadingChanged?.pipe(untilDestroyed(this)).subscribe((isLoading) => {
      this._spinnerService.setLoading(isLoading, this._id);
    });

    super.ngOnInit();

    if (this.initialColumnFilter) {
      this.state.filter = this.initialColumnFilter;
      this.grid.filter = this.initialColumnFilter;
    }

    this.rebind();
  }

  public rebind(): void {
    if (!this._odataService) {
      return;
    }

    if (this.query$ && !this.query$?.closed) {
      this.query$.unsubscribe();
    }

    this.query$ = this._odataService.query(
      this.columns,
      this.state,
      this.filters,
      this.count,
      this.additionalFilters,
      this.cacheOpts,
      this.addHttpOptions,
      this.subscriptionTag,
      this.ignoreCase,
      this.utcDates,
      this.defaultSortDescriptors,
      this.isHistorical,
      this.timeControlDates?.baseGridDate,
      this.timeControlDates?.compareDate,
      this.conditionalSorting
    );
  }

  public getQueryByFilterOnly(customFilter: DataBindingFilters): Observable<PagedResultDto<any>> {
    const newState: State = { ...this.state };
    newState.skip = 0;
    newState.take = this._maxRecordsToTake;

    const selectFilteredQuery = this._odataService.getQueryByFilterOnly(newState, customFilter);

    return selectFilteredQuery;
  }

  public getSelectAll(customFilter?: DataBindingFilters): Observable<PagedResultDto<any>> {
    const newState: State = { ...this.state };
    newState.skip = 0;
    newState.take = this._maxRecordsToTake;

    const selectAllQuery = this._odataService.getQuery(
      this.selectAllColumns,
      newState,
      customFilter ?? this._filters,
      this.count,
      this.additionalFilters,
      this.cacheOpts,
      this.addHttpOptions,
      this.ignoreCase,
      this.utcDates,
      this.defaultSortDescriptors,
      this.isHistorical,
      this.timeControlDates?.baseGridDate,
      this.timeControlDates?.compareDate,
      this.conditionalSorting
    );

    selectAllQuery.pipe(untilDestroyed(this)).subscribe((x) => {
      this.totalCountChange.emit(x.totalCount);

      this.notifyDataChange();
    });

    return selectAllQuery;
  }

  public getQueryAll(): Observable<PagedResultDto<any>> {
    return this.getSelectAll();
  }

  public clearFilters() {
    const filter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    this.state.filter = filter;
    this.grid.filter = filter;
    this.rebind();
  }

  public applyFilter(filter: CompositeFilterDescriptor) {
    this.state.filter = filter;
    this.grid.filter = filter;
    this.rebind();
  }

  public allData = (): Observable<any> => {
    return this.getQueryAll().pipe(
      map(
        (response) =>
          ({
            data: response.items,
            total: response.totalCount,
          } as GridDataResult)
      )
    );
  };

  public resetPage() {
    this.skip = 0;
  }

  private getExtendedId() {
    return `${this.serviceName}-${this._id}`;
  }

  ngOnDestroy(): void {
    this._spinnerService.setLoading(false, `${this._id}`);
  }
}
