export class IdentityUserCmd {
  userIds: string[];
  asociatedUserCode: string; // user to assign
  userCode: string; // user for audit

  constructor(userIds: string[], asociatedUserCode: string, userCode: string) {
    this.userIds = userIds;
    this.asociatedUserCode = asociatedUserCode;
    this.userCode = userCode;
  }
}
