import { GenericSelector } from 'src/app/common-modules/redux/models/generic-selector';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { NEConfigSelectorTypes } from './ne-config.selector-types';

export abstract class NEConfigSelector extends GenericSelector {
  area = StateAreas.NEConfig;
}

export class SelectedNetworkElementSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.SelectedNetworkElement;
}

export class SetAttributesSourceSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.SetAttributesSource;
}

export class BoundarySettingsSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.BoundarySettings;
}

export class FormDefinitionsSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.FormDefinitions;
}

export class FormUIValuesSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.UIValues;
}

export class PressureSettingsSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.PressureSettings;
}

export class FormAdditionalSettingsSelector extends NEConfigSelector {
  type = NEConfigSelectorTypes.FormAdditionalSettings;
}
