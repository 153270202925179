<kendo-grid-column
  [field]="field"
  [width]="width"
  [filterInput]="true"
  [format]="format"
  [locked]="locked"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="column-cell">
      {{ getColumnData(field, dataItem[field], format, type) | gridCellEmpty }}
    </span>
  </ng-template>

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-grid-date-time-filter
      [field]="column.field"
      [columnFilter]="filter"
      [filterService]="filterService"
      [format]="currentFormat"
      [showTimeSelector]="showTimeInFilter"
      [currentFilter]="filter"
      [valueDefinedAsUtc]="valueDefinedAsUtc"
      [includeNullFilterOperators]="includeNullFilterOperators"
    >
    </wlm-grid-date-time-filter>
  </ng-template>
</kendo-grid-column>
