import { HistoricalVersionDto } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { LarsAndSworpsSiteSignalsDto } from './lars-sworps-site-signal.dto';

export class LarsAndSworpsSiteSignalVersionsDto extends HistoricalVersionDto {
  siteId: string;
  signalId: number;
  pointId: string;
  pointDescription: string;
  isLars: boolean;
  isSubtraction: boolean;

  constructor(init: LarsAndSworpsSiteSignalVersionsDto) {
    super(init);
    Object.assign(this, init);
  }

  getCurrentConfig?(): LarsAndSworpsSiteSignalsDto {
    const current: LarsAndSworpsSiteSignalsDto = {
      signalId: this.signalId,
      siteId: this.siteId,
      isSubtraction: this.isSubtraction,
      isLars: this.isLars,
      pointDescription: this.pointDescription,
      pointId: this.pointId,
      title: '',
    };
    return current;
  }
}
