import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HistoricalVersionDto } from '../models/historical-chart-settings/historical-version.dto';

@Injectable()
export abstract class BaseHistoricalValidationService {
  abstract getConflictsHistoricalConfiguration(
    configurations: HistoricalVersionDto[]
  ): Observable<HistoricalVersionDto[]>;

  abstract getConflictsHistoricalConfigurationByConfiguration(
    configurations: HistoricalVersionDto[],
    configuration: HistoricalVersionDto
  ): Observable<HistoricalVersionDto[]>;
}
