<wlm-popup-wrapper
  [titleKey]="titleKey"
  [showSpinner]="isLoading"
  [styles]="styles"
  (popupClose)="onClose($event)"
>
  <ng-container popup-content>
    <wlm-bi-save-template-form
      (isValid)="onFormIsValid($event)"
      (hasSaved)="onFormHasSaved($event)"
      (isLoading)="onFormIsLoading($event)"
    >
    </wlm-bi-save-template-form>
  </ng-container>

  <ng-container popup-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="saveForm()"
      [disabled]="!isValid || isLoading"
    >
      {{ 'common.save' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
