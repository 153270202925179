import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { InformedResponse } from '../../features/shared/model/informed-response.dto';
import { SmartMeterChangesInformedDto } from '../models/smart-meter-changes-informed.dto';
import { SmartMeterConfigDto } from '../models/smart-meter-config.dto';
import { SmartMeterSaveDto } from '../models/smart-meters-save.dto';

const endpointUrl = '/api/smart-meters';

@Injectable()
export class SmartMetersService extends GridODataService<SmartMeterConfigDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<SmartMeterConfigDto>
  ): PagedResultDto<SmartMeterConfigDto> {
    return response;
  }

  getConfigurations(hierarchyElementId: string): Observable<SmartMeterConfigDto[]> {
    const url = `${this.baseUrl}${endpointUrl}/${hierarchyElementId}`;
    return this.httpCacheClient.get(url, {
      avoid: true,
    });
  }

  save(config: SmartMeterSaveDto): Observable<InformedResponse> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/save`, config);
  }

  checkBeforeSave(config: SmartMeterSaveDto): Observable<SmartMeterChangesInformedDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/check-save`, config);
  }
}
