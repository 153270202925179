import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
@Component({
  selector: 'wlm-error-window',
  templateUrl: './error-window.component.html',
  styleUrls: ['./error-window.component.scss'],
})
export class ErrorWindowComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public error: Error) {}
}
