export class HomePagesConfigurationDto {
  Pages: string[];
  constructor(init: Partial<HomePagesConfigurationDto>) {
    Object.assign(this, init);
  }
}

export class HomePageConfigurationDto {
  homePage: string;

  constructor(init: Partial<HomePageConfigurationDto>) {
    Object.assign(this, init);
  }
}
