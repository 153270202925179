<div class="buttons-row">
  <div class="col-6">
    <button mat-icon-button [class]="internalClass">
      <wlm-icon icon="lock"></wlm-icon>
    </button>
    <button mat-icon-button disabled [class]="internalClass">
      <wlm-icon icon="lock"></wlm-icon>
    </button>
  </div>
  <!-- <div class="col-4">
    <button mat-icon-button [class]="internalClass">
      <wlm-icon icon="lock"></wlm-icon> Settings
    </button>
    <button mat-icon-button disabled [class]="internalClass">
      <wlm-icon icon="lock"></wlm-icon> Settings
    </button>
  </div> -->
  <div class="col-6">
    <button mat-raised-button [class]="internalClass">Settings</button>
    <button mat-raised-button disabled [class]="internalClass">Settings</button>
  </div>
</div>
