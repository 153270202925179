import { LogScope } from './log-scope';

export class LogData {
  msg: string;
  scope? = LogScope.General;
  payload? = {};

  constructor(init: LogData) {
    Object.assign(this, init);
  }
}
