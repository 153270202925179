import { Injectable, Injector } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { INetworkElementTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-type.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ColorHelperService } from 'src/app/common-modules/shared/helpers/color-helper.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { GChartSymbolTypes } from 'src/app/common-modules/wlm-charts/core/models/g-chart-symbol-types';
import { GChartLegendItem } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-legend-item';
import { GSchematicChartSerie } from 'src/app/common-modules/wlm-charts/core/models/schematics/g-schematic-chart-serie';
import { SchematicChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/schematics/schematic-chart-data-parameters';
import {
  SchematicCategory,
  SchematicLink,
  SchematicNodeProcessed,
} from '../../../../common-modules/wlm-charts/core/models/schematics/schematic';
import { BaseSchematicChartService } from './base-schematic-chart.service';
import { SchematicsService } from './schematics.service';

@Injectable()
export class SchematicChartService extends BaseSchematicChartService {
  private _neTypes: Map<number, INetworkElementTypeDto>;
  private _translations: {
    tooltip: { [key: string]: string };
  };
  private readonly _translationsPath = `${AppModules.Schematics}.schematics-chart`;
  private readonly _defaultColor = '#000';

  constructor(
    injector: Injector,
    private _schematicsService: SchematicsService,
    private _globalsService: GlobalsService,
    private _localization: LocalizationHelperService,
    private _colorService: ColorHelperService
  ) {
    super(injector);
  }

  getData(params: SchematicChartDataParameters): Observable<any> {
    const data$ = this._schematicsService.getOne(params.queryParams.name);
    if (this._neTypes) {
      return data$;
    }

    return this.loadDependencies().pipe(switchMap(() => data$));
  }

  /**
   * Load all dependencies that should be available before the chart starts to build.
   */
  private loadDependencies(): Observable<any> {
    const dependencies$ = [
      this._globalsService.getNetworkElementTypes(),
      this._localization.get(this._translationsPath),
    ];

    return forkJoin(dependencies$).pipe(
      tap(([neTypes, translations]) => {
        this._neTypes = globalUtilsHelper.groupByFnHashSingle(
          neTypes,
          (ne: INetworkElementTypeDto) => ne.networkElementTypeId
        );

        this._translations = translations;
      })
    );
  }

  extendSerie(serie: GSchematicChartSerie): Observable<GSchematicChartSerie> {
    return of(serie);
  }

  extendNode(category: SchematicCategory, node: SchematicNodeProcessed): SchematicNodeProcessed {
    const iconPath = this.buildCategoryIcon(category?.id);
    node.symbol = iconPath;
    node.itemStyle = { color: this.buildCategoryColor(category?.id) };
    return node;
  }

  extendLegendItem(category: SchematicCategory, legendItem: GChartLegendItem): GChartLegendItem {
    const iconPath = this.buildCategoryIcon(category?.id);
    legendItem.icon = iconPath;
    legendItem.itemStyle = { color: this.buildCategoryColor(category?.id) };
    return legendItem;
  }

  extendLink(
    link: SchematicLink,
    sourceNode: SchematicNodeProcessed,
    targetNode: SchematicNodeProcessed
  ): SchematicLink {
    link.lineStyle = { color: sourceNode.itemStyle.color };
    return link;
  }

  buildNodeTooltipFormatter(
    category: SchematicCategory,
    params: { data: SchematicNodeProcessed }
  ): string {
    // Pending to translate when the actual fields are decided.
    const fields = [
      this.getTooltipRow(this._translations.tooltip.id, params.data.id),
      this.getTooltipRow(this._translations.tooltip.name, params.data.name),
      this.getTooltipRow(
        this._translations.tooltip.longitude,
        String(this.getXCoordinate(params.data))
      ),
      this.getTooltipRow(
        this._translations.tooltip.latitude,
        String(this.getYCoordinate(params.data))
      ),
    ];

    if (category) {
      const neType = this._neTypes.get(+category.id);
      fields.push(
        this.getTooltipRow(this._translations.tooltip.type, neType.networkElementTypeName)
      );
    }

    return fields.join('<br>');
  }

  buildLinkTooltipFormatter(
    category: SchematicCategory,
    params: { data: SchematicNodeProcessed }
  ): string {
    return null;
  }

  private buildCategoryIcon(categoryId: number): string {
    if (categoryId && this._neTypes.has(+categoryId)) {
      const neType = this._neTypes.get(+categoryId);
      let path;
      if (!neType.iconPath) {
        return GChartSymbolTypes.Circle;
      }
      if (neType.iconPath === 'POLYGON') {
        path = GChartSymbolTypes.Rect;
      } else if (neType.iconPath === 'LINE') {
        path = GChartSymbolTypes.Triangle;
      } else {
        path = this.ensurePathPrefix(neType.iconPath);
      }
      return path;
    }
  }

  private buildCategoryColor(categoryId: number): string {
    if (categoryId && this._neTypes.has(+categoryId)) {
      const neType = this._neTypes.get(+categoryId);
      const color = neType.iconColor ? neType.iconColor : this._defaultColor;

      return this._colorService.hexAToRGBA(color);
    }
  }
}
