<ng-template #main>
  <div class="filter-item-title">{{ T_SCOPE + '.title' | translate }}</div>

  <div *ngIf="isLoading" class="default-spinner-location is-loading">
    <span class="k-i-loading k-loading-image"></span>
  </div>
  <div *ngIf="treeIsEmpty" class="empty-tree">
    <p>{{ T_SCOPE + '.cannot-load-error' | translate }}</p>
  </div>
  <!-- <div *ngIf="!isLoading && treeSettings" class="treeview"> -->
  <div *ngIf="!isLoading">
    <kendo-treeview
      name="treeview"
      id="treeview"
      class="filter-item-treeview"
      kendoTreeViewExpandable
      kendoTreeViewFlatDataBinding
      [nodes]="filteredTreeNodes"
      textField="description"
      idField="descendant"
      parentIdField="ancestor"
      [(expandedKeys)]="expandedKeys"
      expandBy="descendant"
      [selectedKeys]="selectedKeys"
      [selectBy]="'descendant'"
      kendoTreeViewSelectable="multiple"
      (selectionChange)="handleSelection($event)"
      (nodeClick)="handleClick($event)"
      [isSelected]="isItemSelected"
    >
      <ng-template kendoTreeViewNodeTemplate let-dataItem>
        <div>
          <span
            [innerHTML]="
              (dataItem.type === 0
                ? this.algorithmFamilyTranslations.get(dataItem.descendant)
                : dataItem.type === 1
                ? this.aggregationTranslations.get(dataItem.name)
                : this.algorithmTranslations.get(dataItem.name) + ' (' + dataItem.name + ')'
              ) | highlightSearch : [filterText, false]
            "
          ></span>
        </div>
      </ng-template>
    </kendo-treeview>
  </div>
</ng-template>
<ng-template #summary>
  <div *ngIf="summaryItemsKeys && summaryItemsKeys.length !== 0">
    <div class="filter-item-title summary-main-title">{{ T_SCOPE + '.title' | translate }}</div>
    <ul class="summary-list">
      <li *ngFor="let title of summaryItemsKeys" class="summary-groups">
        <div class="summary-title">
          <div class="ellipsis-left">{{ title }}</div>
        </div>
        <div *ngFor="let item of summaryItems[title]" class="filter-select-item">
          <mat-checkbox checked="true" disabled="true"></mat-checkbox>
          <span class="filter-select-item-label"></span>{{ item.label }}
        </div>
      </li>
    </ul>
  </div>
</ng-template>
