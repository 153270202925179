<div *ngIf="layers" [ngStyle]="{ display: displayed ? '' : 'none' }" class="custom-map-filter">
  <div *ngIf="!!title" class="filter-item-title">{{ title }}</div>
  <div *ngFor="let layer of layers">
    <span class="item checkbox-list">
      <span *ngIf="showIcon && layer.iconPath">
        <wlm-svg
          class="svg-icon"
          [viewBox]="getViewBox(layer)"
          [transformClass]="getTransformClass(layer)"
          [width]="iconSize"
          [height]="iconSize"
          [path]="layer.iconPath"
          [svgFill]="layer.iconColor"
          [svgStroke]="layer.iconStrokeColor"
          [svgStrokeWidth]="getStrokeWidth(layer)"
        ></wlm-svg>
      </span>
      <span class="map-filter-label"> {{ layer | gisLayerName | async }} </span>
      <mat-checkbox
        class="checkbox"
        [checked]="selectedLayers[layer.gisLayerId] || hasAllSublayersSelected(layer)"
        [indeterminate]="!!layer.sublayers?.length && hasAnySublayerSelected(layer)"
        (change)="onSelect($event, layer)"
        [labelPosition]="labelPosition"
      >
      </mat-checkbox>
    </span>
    <span *ngIf="!!layer.sublayers?.length">
      <ul>
        <li class="item nested-checkbox-radio-list" *ngFor="let sublayer of layer.sublayers">
          <span *ngIf="showIcon && sublayer.iconPath">
            <wlm-svg
              class="svg-icon"
              [width]="iconSize"
              [height]="iconSize"
              [path]="sublayer.iconPath"
              [svgFill]="sublayer.iconColor"
              [svgStroke]="sublayer.iconStrokeColor"
            ></wlm-svg>
          </span>
          <mat-checkbox
            class="checkbox"
            [checked]="selectedLayers[sublayer.gisLayerId]"
            (change)="onSelect($event, sublayer, layer)"
            [labelPosition]="labelPosition"
          >
            {{ sublayer | gisLayerName | async }}
          </mat-checkbox>
        </li>
      </ul>
    </span>
  </div>
</div>
