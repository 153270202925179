import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../config/settings.service';
import { DateFormatsService } from './date-formats.service';

/**
 * Extends the default number pipe to link it to localization config.
 * Is if because of the refresh that this can be sync.
 */

@Pipe({
  name: 'number',
})
export class ExtendDecimalPipe implements PipeTransform {
  private _maxDecimalPositionsForGreatNumber: number;
  private _greatNumberToChangePrecision: number;
  private _decimalPositions: number;

  constructor(
    private _dateFormatsService: DateFormatsService,
    private _settingsService: SettingsService
  ) {
    this._decimalPositions = this._settingsService.maxDecimalPositions;
    this._maxDecimalPositionsForGreatNumber =
      this._settingsService.maxDecimalPositionsForGreatNumber;
    this._greatNumberToChangePrecision = this._settingsService.greatNumberToChangePrecision;
  }

  transform(value: any | null | undefined, digitsInfo?: string, disableLocale: boolean = false): string | null {
    if (value === null || value === undefined || value === '') {
      return value;
    }

    let digitsNumber = this._decimalPositions;

    if (Number.isInteger(+value)) {
      digitsNumber = 0;
    }
    else if (+value > this._greatNumberToChangePrecision) {
      digitsNumber = this._maxDecimalPositionsForGreatNumber;
    }

    let formatted;
    if (!disableLocale) {
      digitsInfo = digitsInfo ?? `.${digitsNumber}-${digitsNumber}`;

      formatted = formatNumber(
        value,
        this._dateFormatsService.currentLocale,
        digitsInfo
      );

    } else {
      formatted = +value.toFixed(digitsNumber);
    }

    return formatted;

  }
}
