export class PointReplacementDto {
  sourcePointId: string;
  destinationPointId: string;
  userCode: string;

  constructor(sourcePointId: string, destinationPointId: string, userCode: string) {
    this.sourcePointId = sourcePointId;
    this.destinationPointId = destinationPointId;
    this.userCode = userCode;
  }
}

export class PointReplacementResponseDto {
  hasErrors: boolean;
  message: string;
}
