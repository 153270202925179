<div [class]="'wb-item ' + classI18nKey" [ngClass]="{ 'wb-selected': selected }">
  <span class="wb-title">{{ T_SCOPE + '.' + classI18nKey | translate }}</span>
  <span class="wb-value">
    {{
      value
        | uomConversionFormattedUnit
          : hierarchyElementTypeId
          : timeAggregation
          : dimension
          : 3
          : unitFormat
          : labelKey
          : currencyType
        | async
    }}

    <span *ngIf="percent"> ({{ percent | number: '.2-2' }}%)</span>
  </span>
</div>
