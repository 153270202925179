import { RemoteEventTypes } from './remote-event-types.enum';

export class RemoteEventPayload {
  type: RemoteEventTypes;
  payload: any;

  constructor(init: RemoteEventPayload) {
    Object.assign(this, init);
  }
}
