import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CellEditedInfoDto } from '../model/cell-edited-info.dto';

@Injectable()
export class GridEditionService {
  // First Key: rowId
  // Second Key: fieldName
  private _trackGridChanges: Map<any, Map<string, CellEditedInfoDto>> = new Map<
    any,
    Map<string, CellEditedInfoDto>
  >();
  private _editedValuesHasChanged = new BehaviorSubject(null);

  constructor() {}

  registerCellChange(idField: string, previousDataItem: any, field: any, newValue: any): void {
    const key = previousDataItem[idField];
    const originalValue = previousDataItem[field];
    let cellChanges = this._trackGridChanges.get(key);
    let currentCellChange;

    if (cellChanges) {
      currentCellChange = cellChanges.get(field);
      if (currentCellChange) {
        // when the new new value is equal to the original one, then remove the change
        if (currentCellChange.originalValue == newValue) {
          this.removeChanges(key, field);
          return;
        }

        currentCellChange.newValue = newValue;
      } else {
        currentCellChange = new CellEditedInfoDto({
          originalValue: originalValue,
          newValue: newValue,
        });
      }
    } else {
      cellChanges = new Map<string, CellEditedInfoDto>();
      currentCellChange = new CellEditedInfoDto({
        originalValue: originalValue,
        newValue: newValue,
      });
    }

    cellChanges.set(field, currentCellChange);
    this._trackGridChanges.set(key, cellChanges);
    this._editedValuesHasChanged.next(null);
  }

  getOriginalValue(rowId: any, field: string, removeFromTrack = false): any {
    const rowChanges = this._trackGridChanges.get(rowId);

    if (!rowChanges) {
      return undefined;
    }

    const cellChange = rowChanges.get(field);

    if (!cellChange) {
      return undefined;
    }

    if (removeFromTrack) {
      this.removeChanges(rowId, field);
    }

    return cellChange.originalValue;
  }

  removeChanges(rowId: any, field: string): void {
    const rowChanges = this._trackGridChanges.get(rowId);

    if (!rowChanges) {
      return;
    }

    rowChanges.delete(field);

    if (rowChanges.size == 0) {
      this._trackGridChanges.delete(rowId);
      this._editedValuesHasChanged.next(null);
    }
  }

  removeAllChanges(): void {
    this._trackGridChanges = new Map<any, Map<string, CellEditedInfoDto>>();
    this._editedValuesHasChanged.next(null);
  }

  checkCellIsEdited(rowId: any, field: string): boolean {
    let isEdited = false;
    const rowChanges = this._trackGridChanges.get(rowId);

    if (!rowChanges) {
      return isEdited;
    }

    return rowChanges.has(field);
  }

  getRowEditedValues(key: any): Map<string, CellEditedInfoDto> {
    return this._trackGridChanges.get(key);
  }

  getCurrentChanges(): Map<any, Map<string, CellEditedInfoDto>> {
    return this._trackGridChanges;
  }

  editedValuesHasChangedObservable(): Observable<any> {
    return this._editedValuesHasChanged.asObservable();
  }
}
