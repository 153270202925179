import { Component, ElementRef, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { applyOnActiveWidget } from 'src/app/common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { SizeCalculatorService } from 'src/app/common-modules/shared/services/size-calculator.service';
import { ChartConfigurationService } from '../../../shared/charts/chart-configuration.service';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { DataVisualizationCartSelection } from '../../models/data-visualization-cart-selection';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-chart-widget';
export const DV_CHART_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-chart-widget.component.html',
  styleUrls: ['./data-visualization-chart-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationChartWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  chartConfiguration: ChartConfiguration;

  phTitleKey = `${this.T_SCOPE}.must-plot-title`;
  phSubtitleKey = `${this.T_SCOPE}.must-plot-subtitle`;
  size$: Observable<IElementSize>;

  get componentName(): string {
    return 'DataVisualizationChartWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _chartConfigurationService: ChartConfigurationService,
    private readonly _element: ElementRef,
    private readonly _sizeCalculatorService: SizeCalculatorService
  ) {
    super(injector, widgetSettings);

    this.setChartConfiguration(widgetSettings.itemSettings?.params);
  }

  onWidgetInit(): void {
    this.size$ = this._sizeCalculatorService.listenElementSize$(this._element.nativeElement);

    this._state
      .select(
        new GetValueSelector({
          fieldName: DataVisualizationStateFields.plotCartSelection,
        })
      )
      .pipe(
        untilDestroyed(this),
        applyOnActiveWidget(this, (cartSelection: DataVisualizationCartSelection) => {
          if (cartSelection) {
            this._chartConfigurationService
              .getChartConfigurationFromFiltrableItems(cartSelection.visibleItems)
              .pipe(untilDestroyed(this))
              .subscribe((chartConfiguration) => {
                this.chartConfiguration = chartConfiguration;
              });
          }
        })
      )
      .subscribe();

    this.listenState(DataVisualizationStateFields.plotChartConfiguration).subscribe(
      (chartConfiguration: ChartConfiguration) => {
        if (chartConfiguration) {
          this.chartConfiguration = globalUtilsHelper.clone(chartConfiguration);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.clearAll).subscribe((value) => {
      if (value) {
        this.chartConfiguration = null;
      }
    });
  }

  onChartConfigurationChange(chartConfiguration: ChartConfiguration) {
    this.updateItemSettings({
      title: this._settings.title,
      params: chartConfiguration,
      widgetInstanceKey: this.getWidgetInstanceKey(),
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.chartConfigurationUpdate,
        value: chartConfiguration,
      })
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private setChartConfiguration(params: any) {
    if (!params) {
      return;
    }

    const chartConfiguration = plainToClass(ChartConfiguration, params);
    this.chartConfiguration = chartConfiguration;
  }
}
