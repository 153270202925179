import { ChartSerieTypeEnum } from './chart-serie-type.enum';

export class ChartSerieType {
  value: ChartSerieTypeEnum;
  labelKey: string;
  icon?: string;

  constructor(init: Partial<ChartSerieType>) {
    Object.assign(this, init);
  }
}
