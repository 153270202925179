import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from './filter';

export class CompositeFilter implements IFilter {
  filter: CompositeFilterDescriptor;
  logic: string;

  constructor(filter: CompositeFilterDescriptor) {
    this.filter = filter;
  }
  getSelectedFilters() {
    return this.filter;
  }

  getFilters(): CompositeFilterDescriptor {
    return {
      filters: [this.filter],
      logic: 'and',
    };
  }

  getFiltersValues(): Map<string, any> {
    // return new Map<string,any>([[this.filter.filters., this.openActivitiesOnly]]);
    return new Map<string, any>();
  }
}
