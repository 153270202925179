import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BaseFilterCellComponent,
  FilterService,
  PopupCloseEvent,
  SinglePopupService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import moment from 'moment';
import { SettingsService } from '../../shared/config/settings.service';
import { SharedConstantsService } from '../../shared/constants/shared-constants.service';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';

const closest = (node: any, predicate: any): any => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

const COMPONENT_SELECTOR = 'wlm-grid-date-time-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-date-time-filter.component.html',
  styleUrls: ['./grid-date-time-filter.component.scss'],
  providers: [DatePipe],
})
export class GridDateTimeFilterComponent
  extends BaseFilterCellComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public columnFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public format: string;
  @Input() public showTimeSelector: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public valueDefinedAsUtc = true;
  @Input() public includeNullFilterOperators: string[];

  public filterOperators: Array<{ text: string; value: string }>;

  public selectedOperator = 'gte';
  public selectedDateTime: Date;
  public selectedTime: string;

  public popupSettings: any = {
    popupClass: 'grid-date-time-filter',
  };

  public get dateTimePickerDisabled(): boolean {
    return this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull';
  }

  dateInputIsDisabled = false;

  private popupSubscription: any;
  private _scheduledFilter: any;
  private _waitTimeBeforeFilter: number = 500;
  private _numberOfPartsOfADate = 3;
  private _preventClose = false;

  constructor(
    private _element: ElementRef,
    public datepipe: DatePipe,
    popupService: SinglePopupService,
    filterService: FilterService,
    sharedConstantsService: SharedConstantsService,
    private _adapter: DateAdapter<any>,
    private _localization: LocalizationHelperService,
    private _dateHelper: DateHelperService,
    private _settingsService: SettingsService
  ) {
    super(filterService);

    // Get translated filter operators
    sharedConstantsService
      .mapToArrayObservable(sharedConstantsService.getFilterOperatorsDateMapping(), 'value', 'text')
      .subscribe((array) => {
        this.filterOperators = array;
      });

    // Handle the service onClose event and prevent the menu from closing when the datepickers are still active.
    this.popupSubscription = popupService.onClose
      .pipe(untilDestroyed(this))
      .subscribe((e: PopupCloseEvent) => {
        if (
          this._preventClose ||
          (document.activeElement &&
            closest(
              document.activeElement,
              (node) =>
                node === this._element.nativeElement ||
                String(node.className).indexOf('grid-date-time-filter') >= 0 ||
                String(node.className).indexOf('mat-calendar-body-cell') >= 0 ||
                String(node.className).indexOf('mat-input-element') >= 0 ||
                String(node.className).indexOf('mat-calendar-previous-button') >= 0 ||
                String(node.className).indexOf('mat-calendar-next-button') >= 0 ||
                String(node.className).indexOf('mat-calendar-period-button') >= 0
            ))
        ) {
          e.preventDefault();
          this._preventClose = false;
        }
      });
  }

  public ngOnInit(): void {
    this._adapter.setLocale(this._localization.currentLocale);
  }

  public ngAfterViewInit() {
    let filterValue = this.currentFilter?.filters?.length > 0 ? this.getValueFromFilter() : null;

    if (this.valueDefinedAsUtc !== false && filterValue) {
      filterValue = this._dateHelper.convertUTCStoredDateToLocal(filterValue);
    }

    const filterOperator =
      this.currentFilter.filters.length > 0
        ? (this.currentFilter.filters[0] as FilterDescriptor).operator.toString()
        : 'gte';

    const isUnaryOperator = filterOperator === 'isnull' || filterOperator === 'isnotnull';
    if (isUnaryOperator) {
      filterValue = null;
    }

    this.dateInputIsDisabled = isUnaryOperator;

    this.selectedDateTime = filterValue !== null ? (filterValue as Date) : this.selectedDateTime;

    const hours = String(this.selectedDateTime?.getHours()).padStart(2, '0');
    const minutes = String(this.selectedDateTime?.getMinutes()).padStart(2, '0');

    this.selectedTime = this.selectedDateTime !== undefined ? `${hours}:${minutes}` : undefined;
    this.selectedOperator = filterOperator;
  }

  private getValueFromFilter() {
    return (this.currentFilter.filters[0] as FilterDescriptor).value;
  }

  leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(String(val).length);
  }

  public ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }

  public onDateTimeChange(value: any): void {
    this.filterDateTimes(value, this.valueDefinedAsUtc);
  }

  public filterDateTimes(dateTime: any, valueDefinedAsUtc = true): void {
    const convertBackValue =
      dateTime === null
        ? null
        : valueDefinedAsUtc
        ? this._dateHelper.convertDateToUTC(dateTime)
        : dateTime;

    this.prepareFilter(convertBackValue); // update the root filter
  }

  preventClose() {
    this._preventClose = true;
  }

  private prepareFilter(convertBackValue: any) {
    this.applyFilter(
      convertBackValue === null &&
        this.selectedOperator !== 'isnull' &&
        this.selectedOperator !== 'isnotnull' // value of the default item
        ? this.removeFilter(this.field) // remove the filter
        : this.updateFilterCustom(convertBackValue)
    );
  }

  private updateFilterCustom(convertBackValue: any): CompositeFilterDescriptor {
    const newFilter = this.updateFilter({
      field: this.field,
      operator: this.selectedOperator,
      value: convertBackValue,
    });

    if (
      this.includeNullFilterOperators?.length &&
      this.includeNullFilterOperators?.includes(this.selectedOperator)
    ) {
      const nullDatesFilter = { field: this.field, operator: 'isnull' };
      newFilter.logic = 'or';
      newFilter.filters.push(nullDatesFilter);
    }

    return newFilter;
  }

  convertTimeToUtc(dateTime: Date): any {
    if (dateTime !== undefined) {
      return new Date(dateTime.toISOString().replace('Z', ''));
    }
    return dateTime;
  }

  public onclick(event) {
    event.preventDefault();
  }

  public operatorChange(operator) {
    if (operator.value === 'isnull' || operator.value === 'isnotnull') {
      this.selectedDateTime = undefined;
      this.dateInputIsDisabled = true;

      this.filterDateTimes(null, this.valueDefinedAsUtc);
    } else {
      this.dateInputIsDisabled = false;

      this.filterDateTimes(this.selectedDateTime, this.valueDefinedAsUtc);
    }
  }

  dateInput(event) {
    const inputText = event.target.value;
    const dateSeparator = this._localization.getDateSeparator();
    const dateParts = inputText.split(dateSeparator);
    let somePartIsMissing = false;
    dateParts.forEach((part) => {
      if (part == undefined || part == '') {
        somePartIsMissing = true;
      }
    });

    if (dateParts.length == this._numberOfPartsOfADate && !somePartIsMissing) {
      moment.locale(this._localization.currentLocale);
      const momentDate = moment(inputText, 'L');
      const momentConverted = momentDate.toDate();

      if (
        momentConverted.getFullYear() > this._settingsService.minAllowedYear &&
        isFinite(+momentConverted)
      ) {
        this._scheduledFilter = setTimeout(() => {
          this.selectedDateTime = momentConverted;
          this.filterDateTimes(this.selectedDateTime, this.valueDefinedAsUtc);
        }, this._waitTimeBeforeFilter);
      }
    }
  }

  onKeyDownEvent($event: KeyboardEvent) {
    clearTimeout(this._scheduledFilter);
  }

  dateChange(event) {
    this.selectedDateTime = event.target.value;
    if (this.selectedTime !== undefined) {
      this.setTimeToSelectedDate(this.selectedTime);
    }

    this.filterDateTimes(this.selectedDateTime, this.valueDefinedAsUtc);
  }

  timeChange(event) {
    this.selectedTime = event?.target?.value;
    if (this.selectedDateTime === null || this.selectedDateTime === undefined) {
      return;
    }

    this.setTimeToSelectedDate(this.selectedTime);
    this.filterDateTimes(this.selectedDateTime, this.valueDefinedAsUtc);
  }

  setTimeToSelectedDate(timeValue: string) {
    const selectedTime = timeValue === undefined ? '00:00' : timeValue;
    const hour = +selectedTime.split(':')[0];
    const minute = +selectedTime.split(':')[1];

    (this.selectedDateTime as Date).setHours(hour, minute);
  }
}
