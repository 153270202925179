<div *ngIf="unitTypes && form" class="uom-container hide-subscript custom-small-field">
  <div *hasPerm="['WLMUnitsOfMeasurementCrud', 'u']">
    <wlm-uom-bulk-configuration
      [unitTypes]="unitTypes"
      (applyAll)="onApplyBulkConfig($event)"
    ></wlm-uom-bulk-configuration>
  </div>
  <div [formGroup]="form" class="uom-config-container">
    <div class="uom-column">
      <div class="uom-group">
        <div class="uom-component-title top-title-position">
          {{ T_SCOPE + '.hierarchy' | translate }}
        </div>
        <div class="uom-group-configuration">
          <div class="inner-border" *ngFor="let heTypeConfig of hierarchyConfigs">
            <div class="uom-item">
              <div>
                {{ heTypeConfig.hierarchyElementTypeName }}
              </div>
              <mat-form-field appearance="outline" class="uom-unit-select">
                <mat-label>{{ T_SCOPE + '.unit' | translate }}</mat-label>
                <mat-select
                  required
                  appearance="outline"
                  [formControlName]="heTypeConfig.hierarchyElementTypeTimeUnitsId"
                >
                  <mat-option *ngFor="let ut of unitTypes" [value]="ut.unitTypeId">
                    {{ ut.unitTypeDescription }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uom-column">
      <div class="uom-group">
        <div class="top-title">
          <div class="uom-component-title top-title-position">
            {{ T_SCOPE + '.telemetry' | translate }}
          </div>
          <div>
            <button
              *hasPerm="['WLMUnitsOfMeasurementCrud', 'u']"
              mat-icon-button
              class="button-margin"
              color="primary"
              [disabled]="isFormButtonDisabled()"
              [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
              (click)="onSave()"
            >
              <wlm-icon [icon]="'save'"></wlm-icon>
            </button>
            <button
              *hasPerm="['WLMUnitsOfMeasurementCrud', 'u']"
              mat-icon-button
              class="button-margin"
              color="primary"
              [disabled]="isFormButtonDisabled()"
              [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
              (click)="discardChanges()"
            >
              <wlm-icon [icon]="'discard-changes'"></wlm-icon>
            </button>
          </div>
        </div>
        <div class="uom-group-configuration">
          <div class="inner-border" *ngFor="let telemetryConfig of telemetryConfigs">
            <div class="uom-item">
              <div>
                {{ telemetryConfig.hierarchyElementTypeName }}
              </div>
              <mat-form-field appearance="outline" class="uom-unit-select">
                <mat-label>{{ T_SCOPE + '.unit' | translate }}</mat-label>
                <mat-select
                  required
                  appearance="outline"
                  [formControlName]="telemetryConfig.hierarchyElementTypeTimeUnitsId"
                >
                  <mat-option *ngFor="let ut of unitTypes" [value]="ut.unitTypeId">
                    {{ ut.unitTypeDescription }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
