import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseKeyService } from 'src/app/common-modules/shared/base-key.service';
import { IGisLayerDto } from 'src/app/common-modules/shared/model/gis/gis-layer.dto';

@Injectable({ providedIn: 'root' })
export class GisLayerService extends BaseKeyService<number, IGisLayerDto> {
  protected getKey(model: IGisLayerDto): number {
    return model.gisLayerId;
  }

  protected getElements(): Observable<IGisLayerDto[]> {
    const elements = this.getCached<IGisLayerDto[]>('gis/layer');
    return elements;
  }

  constructor(injector: Injector) {
    super(injector);
  }
}
