<div class="template-container">
  <mat-form-field appearance="outline" class="input-width cell-edit-input hide-subscript">
    <input
      matInput
      cdkFocusInitial
      #editBox="matInput"
      step="any"
      type="number"
      [formControl]="formGroup.get(field)"
      placeholder="0"
      (ngModelChange)="modelChanged($event)"
    />
  </mat-form-field>

  <button
    mat-icon-button
    color="primary"
    class="small-mat-icon-button"
    (click)="onCancel($event)"
    [disabled]="disableUndoButton"
    [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
  >
    <wlm-icon [icon]="'discard-changes'"></wlm-icon>
  </button>
</div>
