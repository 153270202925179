import { INeHeFilterSettings } from '../../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';

export class AlarmsConfigurationFilterConfiguration {
  // settings
  persistencyArea: string;
  heSettings: INeHeFilterSettings;
  neSettings: INeHeFilterSettings;

  // default values
  defaultFamilyId: string;
  defaultHETypeIds: any[];
  defaultNETypeIds: any[];

  // field names
  hierarchyElementFamilyFieldName: string;
  hierarchyElementIdFieldName: string;

  // persisted values
  initialFamilyId: string;
  initialHierarchyElementId: string[];

  constructor(init: Readonly<AlarmsConfigurationFilterConfiguration>) {
    Object.assign(this, init);
  }
}
