import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wlm-theme-test-buttons',
  templateUrl: './theme-test-buttons.component.html',
  styleUrls: ['./theme-test-buttons.component.scss'],
})
export class ThemeTestButtonsComponent implements OnInit {
  @Input() internalClass: string;

  constructor() {}

  ngOnInit(): void {}
}
