<div class="row alarm-container">
  <div class="col-12 row-bottom-separator">
    <div class="buttons-container">
      <div>
        <button
          *ngIf="!disabled"
          mat-icon-button
          [disabled]="!isValid || formIsDefault"
          color="primary"
          [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
          (click)="onSave()"
        >
          <wlm-icon [icon]="'save'"></wlm-icon>
        </button>
        <button
          *ngIf="!disabled"
          mat-icon-button
          color="primary"
          [disabled]="formIsDefault || disabled"
          (click)="onReset()"
          [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        >
          <wlm-icon [icon]="'discard-changes'"></wlm-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 row-bottom-separator">
    <div class="title date-title">{{ T_SCOPE + '.date-section-title' | translate }}</div>
    <wlm-date-range-and-mode
      class="hide-subscript"
      [apply$]="dateRangeApply$"
      [empty$]="dateRangeClearAll$"
      [settings]="dateSelectorSettings"
      [disabled]="disabled"
      (valueChanges)="onDatesChange($event)"
      (valid)="onDatesValid($event)"
    ></wlm-date-range-and-mode>
  </div>
  <div class="col-12">
    <div class="title">{{ T_SCOPE + '.calendar-section-title' | translate }}</div>
  </div>

  <!-- Uncoment this to show seasons form -->
  <!-- <div class="col">
    <wlm-base-filter
      [settings]="seasonsBaseSettings"
      (filter)="onFilterSeasons($event)"
      [apply$]="seasonsApply$"
      [clearAll$]="seasonsClearAll$"
    >
      <wlm-seasons-filter-item
        [fieldName]="seasonsName"
        [settings]="seasonsItemSettings"
        [selectedIds]="seasonsSelectedIds"
      ></wlm-seasons-filter-item>
    </wlm-base-filter>
  </div> -->
  <div class="col-12 row-bottom-separator">
    <wlm-base-filter
      [settings]="dayBaseSettings"
      (filter)="onFilterDayTypes($event)"
      [apply$]="daysApply$"
      [clearAll$]="daysClearAll$"
    >
      <wlm-day-type-filter-item
        [fieldName]="dayTypeName"
        [settings]="dayItemSettings"
        [selectedIds]="dayTypesSelectedIds"
      ></wlm-day-type-filter-item>
    </wlm-base-filter>
  </div>
  <div class="col-12 row-bottom-separator">
    <wlm-base-filter
      [settings]="calendarBaseSettings"
      (filter)="onFilterCalendar($event)"
      [apply$]="calendarsApply$"
      [clearAll$]="calendarsClearAll$"
    >
      <wlm-custom-calendar-filter-item
        [fieldName]="calendarName"
        [settings]="calendarItemSettings"
        [modeField]="calendarModeField"
        [selectedIdsWithParams]="selectedCalendarsWithParams"
      ></wlm-custom-calendar-filter-item>
    </wlm-base-filter>
  </div>
  <div class="col-12 row-bottom-separator">
    <div class="title">{{ T_SCOPE + '.envelopes-section-title' | translate }}</div>
    <wlm-envelopes-config
      (valueChanges)="onEnvelopesConfig($event)"
      (valid)="onEnvelopesValid($event)"
      (isDefault)="onEnvelopesAreDefault($event)"
      [clearAll$]="envelopesClearAll$"
      [modesToRemove]="modesToRemove"
      [disabled]="disabled"
      [model]="defaultEnvelopes"
      [defaultModel]="defaultEnvelopes"
      [uoMParams]="uoMParams"
    ></wlm-envelopes-config>
  </div>
</div>
