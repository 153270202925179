import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comparisonVisibility',
})
export class ComparisonVisibility implements PipeTransform {
  constructor() {}

  transform(value: number, compValue: number): boolean {
    const isVisible = !this.checkIfUndefined(value) && !this.checkIfUndefined(compValue);

    return isVisible;
  }

  checkIfUndefined(value) {
    return value == null || value == undefined;
  }
}
