<div id="center-page" *ngIf="!hideComponent">
  <div class="filter-buttons-container">
    <wlm-grid-buttons
      *ngIf="gridSettings && !hideFilterButtons"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="true"
      (btnClick)="onClickGridBtns($event)"
    >
    </wlm-grid-buttons>
  </div>
  <div id="center-container">
    <wlm-hierarchy-elements-selection-filter
      *ngIf="configReady"
      [ngClass]="hierarchyElementFilterConfiguration.cssClass"
      [config]="hierarchyElementFilterConfiguration"
      [clearAll$]="clearAll$"
      [apply$]="triggerApply$"
      [gridSettings]="gridSettings"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="onApplyFilters(hierarchyElementsGrid)"
      (autoLoad)="onCheckAutoload()"
      class="filters"
    >
    </wlm-hierarchy-elements-selection-filter>

    <wlm-generic-grid
      class="generic-grid"
      #hierarchyElementsGrid
      [ngClass]="gridCssClass"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [removeSelection$]="removeSelection$"
      (selectedItemsChange)="onSelectedItemsChanged($event)"
      (selectedPersistedItemsChanged)="onselectedPersistedItemsChanged()"
      [(selectedItems)]="selectedHEs"
      storageLocation="session"
    ></wlm-generic-grid>
  </div>
</div>
