import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EstimatedEditedEnum } from '../../shared/model/algorithm/estimated-edited.enum';
import { GridEditionService } from '../services/grid-edition.service';

@UntilDestroy()
@Directive({
  selector: '[wlmEstimatedEditedCellMark]',
})
export class EstimatedEditedCellMarkDirective {
  @Input() set wlmEstimatedEditedCellMark(value: number) {
    this.estimatedEditedValue = value;

    this.setEstimatedEditedMark();
  }

  rowId: any;
  field: string;
  gridEditionService: GridEditionService;
  estimatedEditedValue: number;

  private readonly _editionMarkClassName = 'edition-mark';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  setEstimatedEditedMark() {
    if (this.estimatedEditedValue && this.estimatedEditedValue == EstimatedEditedEnum.Adjusted) {
      const hasMark = Array.from(this.el.nativeElement.childNodes).some(
        (node) => node['className'] == this._editionMarkClassName
      );

      if (!hasMark) {
        const child = this.document.createElement('div');
        child.setAttribute('class', this._editionMarkClassName);
        this.renderer.appendChild(this.el.nativeElement, child);
      }
    } else {
      const nodeMarked = Array.from(this.el.nativeElement.childNodes).find(
        (f) => f['className'] == this._editionMarkClassName
      );

      if (nodeMarked) {
        this.renderer.removeChild(this.el.nativeElement.childNodes, nodeMarked);
      }
    }
  }
}
