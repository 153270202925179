<div class="grid-filter-container image-alignment">
  <div *ngFor="let element of internalElements" class="icons-container">
    <input
      type="checkbox"
      kendoCheckBox
      [value]="element.value"
      [checked]="element.checked"
      (change)="valueChange($event)"
    />

    <span *ngIf="element.text.iconName === ''">
      {{ element.text.text }}
    </span>
    <mat-icon
      *ngIf="element.text.iconName !== '' && !element.isSvg"
      [style.color]="element.text.color"
    >
      {{ element.text.iconName }}
    </mat-icon>
    <wlm-icon
      *ngIf="element.text.iconName !== '' && element.isSvg"
      [icon]="element.text.iconName"
      [size]="'lg'"
    ></wlm-icon>
  </div>
</div>
