<div
  [class]="'wrapper ' + (containerClass ?? '')"
  [ngClass]="{
    'vertical-expand': expandVertical,
    'vertical-overflow': expandVertical && useOverflow
  }"
>
  <div
    class="overlay"
    [ngClass]="{
      'overlay-opaque': settings?.isOpaque
    }"
    *ngIf="showSpinner"
  >
    <div class="spinner-wrapper">
      <wlm-spinner [mode]="spinnerMode"></wlm-spinner>
    </div>
  </div>
  <div
    class="loaded-content"
    #content
    [class.blurred]="showSpinner"
    [ngClass]="{
      'vertical-expand': expandVertical,
      'vertical-overflow': expandVertical && useOverflow,
      'horizontal-overflow': useOverflow
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
