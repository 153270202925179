<div
  class="row"
  *ngIf="form && modes && settings"
  (keydown.enter)="$event.preventDefault()"
  (keydown.shift.enter)="$event.preventDefault()"
>
  <div class="col">
    <mat-form-field [appearance]="fieldAppearance" class="field-expand">
      <mat-label>{{ T_SCOPE + '.mode' | translate }}</mat-label>
      <mat-select [(ngModel)]="mode" (ngModelChange)="onModeChange()" [disabled]="disabled">
        <mat-option *ngFor="let option of modes" [value]="option.value">{{
          option.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col" [formGroup]="form">
    <!-- Rolling mode (single number field) -->
    <div [hidden]="mode !== modeEnum.Rolling">
      <mat-form-field [appearance]="fieldAppearance" class="field-expand">
        <mat-label>{{ T_SCOPE + '.days-amount' | translate }}</mat-label>
        <input
          type="number"
          matInput
          formControlName="periodDays"
          [wlmInputValidate]="[
            [customValidators.onlyPositiveNumber, customValidators.maxDigits(5)]
          ]"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- Fixed mode (standard date range)-->
    <div [hidden]="mode !== modeEnum.Fixed">
      <wlm-base-filter
        *ngIf="settings.dateRangeSettings"
        [settings]="baseFilterSettings"
        [apply$]="apply$"
        [clearAll$]="emptyFilter$"
        (valid)="onDateRangeValid($event)"
        (filter)="updateDatesFromFilters($event)"
      >
        <wlm-date-range-filter-item
          [allowsDateNull]="settings.dateRangeSettings.allowsDateNull"
          [startFieldName]="settings.dateRangeSettings.startFieldName"
          [endFieldName]="settings.dateRangeSettings.endFieldName"
          [dateRange]="dateRange"
          [defaultDateRange]="settings.dateRangeSettings.defaultValue"
          [settings]="filterItemSettings"
        ></wlm-date-range-filter-item>
      </wlm-base-filter>
      <wlm-date-range-filter-item></wlm-date-range-filter-item>
    </div>
  </div>
</div>
