<button
  wlmCollapsibleButton
  [showLabel]="showLabel"
  [disabled]="
    (grid && !grid?.grid?.view?.length) || (localGrid && !localGrid?.grid?.view?.length) || disabled
  "
  [tooltip]="T_SCOPE + '.export-to-excel'"
  (click)="exportToExcel()"
>
  <mat-icon svgIcon="export-excel"></mat-icon>
</button>
