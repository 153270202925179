export interface IWorkspaceDto {
  workspaceId: number;
  workspaceTypeId: number;
  workspaceName: string;
  workspaceDescription: string;
  userCode: string;
  isPublic: boolean;
  includeBoundariesSignals: boolean;
  includePressureSignals: boolean;
  lastUpdate: string | null;
  timeWindowMode: number;
  isGenericDate: boolean;
  startDate: string | null;
  endDate: string | null;
  rollingWindowWidth: number | null;
  rollingOffset: number | null;
  timeAggregationRolling: number | null;
  includeLargeUsersSignals: boolean;
  timeAggregationId: number;
  timeAggregationShortCode: string;
  timeAggregationDescription: string;
  totalSeconds: number | null;
  workspaceTypeName: string;
  definition: string;
  includeLevelSignals: boolean;
  includeInletOutletSignals: boolean;
  includeLarsSworpsSignals: boolean;
  includeSmartMetersSignals: boolean;
}

export class WorkspaceDto implements IWorkspaceDto {
  workspaceId: number;
  workspaceTypeId: number;
  workspaceName: string;
  workspaceDescription: string;
  userCode: string;
  isPublic: boolean;
  includeBoundariesSignals: boolean;
  includePressureSignals: boolean;
  lastUpdate: string;
  timeWindowMode: number;
  isGenericDate: boolean;
  startDate: string;
  endDate: string;
  rollingWindowWidth: number;
  rollingOffset: number;
  timeAggregationRolling: number;
  includeLargeUsersSignals: boolean;
  timeAggregationId: number;
  timeAggregationShortCode: string;
  timeAggregationDescription: string;
  totalSeconds: number;
  workspaceTypeName: string;
  definition: string;
  includeLevelSignals: boolean;
  includeInletOutletSignals: boolean;
  includeLarsSworpsSignals: boolean;
  includeSmartMetersSignals: boolean;

  constructor(partial: IWorkspaceDto) {
    Object.assign(this, partial);
  }
}
