import { Component, Input, OnInit } from '@angular/core';
import { MenuLink } from 'src/app/common-modules/dependencies/navigation/menu-link';

const COMPONENT_SELECTOR = 'wlm-menu-link';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
})
export class MenuLinkComponent implements OnInit {
  @Input() menuLink: MenuLink;
  @Input() isExpanded = true;

  constructor() {}

  ngOnInit(): void {}
}
