import { BaseLinkConf } from './base-link-conf';
import { NavKeys } from './nav-keys.enum';

export class ModuleLinkConf extends BaseLinkConf {
  menuLinksKeys: Array<NavKeys>;

  constructor(init?: Partial<ModuleLinkConf>) {
    super(init);
    Object.assign(this, init);
  }
}
