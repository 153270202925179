import { Component, OnInit, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, map, of, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wlm-boolean-column-filter',
  templateUrl: './boolean-column-filter.component.html',
  styleUrls: ['./boolean-column-filter.component.scss'],
})
export class BooleanColumnFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() field: string;
  @Input() filterService: FilterService;
  @Input() filter: CompositeFilterDescriptor;
  @Input() elements: Map<any, string>;

  public internalElements: any[];
  public selectedOperator: string;

  selectControl = new FormControl();
  filterString: string = '';
  filteredElements: Observable<any[]>;
  selectedElements: any[];

  constructor(filterService: FilterService) {
    super(filterService);
  }


  ngOnInit(): void {
    this.internalElements = Array.from(this.elements.keys()).map((m) => ({
      value: m,
      text: this.elements.get(m).toString(),
    }));

    const valuesFiltered = this.filter?.filters?.map((m) =>
      (m as FilterDescriptor)?.value === null ? 'null' : (m as FilterDescriptor)?.value
    );
    this.selectedElements = this.internalElements.filter((f) => valuesFiltered.includes(f.value));

    this.filteredElements = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => (typeof value === 'string' ? value : this.filterString)),
      map((filter) => this.filterElements(filter))
    );
  }

  displayFn = (): string => '';

  prepareFilters(value: any[]): void {
    const currentValues: any = [];
    if (value?.length === 0) {
      this.applyFilter(this.removeFilter(this.field));
    } else {
      const filter: CompositeFilterDescriptor = {
        logic: value?.length > 1 ? 'or' : 'and',
        filters: [],
      };

      value.forEach((item) => {
        const nullOptionSelected = typeof item === 'string' || item?.value === 'null';
        currentValues.push(nullOptionSelected ? 'null' : item);
        filter.filters.push({
          field: this.field,
          operator: nullOptionSelected ? 'isnull' : 'eq',
          value: nullOptionSelected ? null : item,
        });
      });
      this.applyFilter(this.removeFilter(this.field));
      this.applyFilter(
        value.length > 1 ? filter : this.updateFilter(filter.filters[0] as FilterDescriptor)
      );
    }
  }

  removeChip(event: Event, element: any): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  toggleSelection(element: any): void {
    if (!this.isSelected(element)) {
      this.selectedElements.push(element);
    } else {
      const i = this.selectedElements.findIndex((e) => e.value === element.value);
      this.selectedElements.splice(i, 1);
    }

    this.prepareFilters(this.selectedElements.map((e) => e.value));
  }

  optionClicked(event: Event, element: any): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  isSelected(element: any) {
    return this.selectedElements.some((e) => e.value === element.value);
  }

  private filterElements(value: string): any[] {
    this.filterString = value;
    if (value) {
      return this.internalElements.filter((e) =>
        e.text.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return this.internalElements.slice();
    }
  }
}
