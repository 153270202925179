import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject, Subscriber, combineLatest } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';

import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { NotificationDetailCommentDto } from '../../models/notification-details-comment.dto';

const COMPONENT_SELECTOR = 'wlm-notification-details';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter
{
  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;

  @Input() pageId: string;

  notificationDetails: NotificationDetailCommentDto;
  isSaving = false;
  discussionId: string;

  showSaveButton = false;
  isLoaded = false;
  avoidReload = false;

  private _isValid = false;
  public get isValid() {
    return this._isValid;
  }
  public set isValid(value) {
    if (this._isValid != value) {
      this.setPendingChanges(this.pageId, this.getPendingChanges(value && this.canSave));
    }

    this._isValid = value;
  }

  private _canSave = false;
  public get canSave() {
    return this._canSave;
  }
  public set canSave(value) {
    if (this._canSave != value) {
      this.setPendingChanges(this.pageId, this.getPendingChanges(value));
    }

    this._canSave = value;
  }

  saveSubscriber$: Subscriber<boolean>;

  submit$ = new Subject<void>();
  restore$ = new Subject<void>();
  @Output() submitResult = new EventEmitter<void>();
  @Output() isLoading = new EventEmitter<boolean>();

  users: SelectOption<string>[];
  formatedText = '';
  editedComment: string;

  private _notification: NotificationDto;
  public get notification(): NotificationDto {
    return this._notification;
  }
  @Input() public set notification(v: NotificationDto) {
    this._notification = v;
    if (this.notification) {
      this.currentNotification = this.notification;
      this.discussionId = this.notification.discussionId;
      this.reloadFlags();
    }
  }

  private _currentNotification: NotificationDto;
  public get currentNotification(): NotificationDto {
    return this._currentNotification;
  }
  public set currentNotification(value: NotificationDto) {
    this.canSave = false;
    this._currentNotification = value;

    if (!value) {
      return;
    }

    const { notificationDescription } = this.currentNotification;
    this.notificationDetails = new NotificationDetailCommentDto({
      notificationDescription,
    });

    this._changeDetectorRef.detectChanges();
  }

  get componentName(): string {
    return 'NotificationDetailsComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _rightPanelService: RightPanelService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _authService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'u');
    const discussionPermission$ = this._authService.canAccess('WLMDiscussionsCrud', 'u');

    combineLatest([notificationPermission$, discussionPermission$]).subscribe({
      next: ([notificationPermission, discussionPermission]) => {
        this.showSaveButton = notificationPermission || discussionPermission;
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.pageId = parameters?.parameters.get(TabDetailParameterName.pageId);
    this.currentNotification = parameters.parameters.get(TabDetailParameterName.notification);

    this.discussionId = this.currentNotification?.discussionId;

    this.reloadFlags();
  }

  init(): void {}

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  onFormLoad(isLoaded: boolean): void {
    this.isLoaded = isLoaded;
    this.isLoading.next(!isLoaded);
  }

  onFormValid(isValid: boolean): void {
    this.isValid = isValid;
  }

  onCanSave(canSave: boolean): void {
    this.canSave = canSave;
  }

  onSubmitResult(_): void {
    this.isLoading.next(false);
    this.canSave = false;

    const map = new Map<any, string>();
    this._rightPanelService.rightPanelCallback.next(
      map.set(NotificationDetailsComponent, 'refresh')
    );
    this.submitResult.next();

    if (this.saveSubscriber$) {
      this.saveSubscriber$.next(true);
      this.saveSubscriber$.complete();
    }
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    return new Observable<boolean>((o) => {
      this.saveSubscriber$ = o;

      this.isLoading.next(true);
      this.submit$.next();
    });
  }

  resetChanges(): void {
    this.restore$.next();
    this.canSave = false;
  }

  isSaveDisabled() {
    return !(this.isValid && this.canSave);
  }

  private reloadFlags() {
    this.isLoaded = false;
    this.isValid = false;
    this.canSave = false;
    this.isLoading.next(false);
  }

  private getPendingChanges(isValid: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: isValid,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
