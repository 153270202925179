<div *ngIf="selectedNE" class="ene-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="ene-column">
    <div class="ene-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <div class="drag-list-filter">
        <wlm-ne-type-filter
          *ngIf="filterSettings"
          [settings]="filterSettings"
          [clearAll$]="clearAll$"
          [persistFilters$]="persistFilters$"
          (filtersChanged)="getDataBindingFilters($event)"
          (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
          (apply)="applyFilters()"
          (autoLoad)="onCheckAutoload()"
        ></wlm-ne-type-filter>
      </div>

      <wlm-drag-list-virtual
        class="drag-list-virtual"
        *ngIf="settings && queryParams"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedNE"
        (somethingChanged)="compareNE()"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="ene-column">
    <div class="ene-group">
      <div class="top-title">
        <div class="ne-component-title top-title-position">
          {{ T_SCOPE + '.configured-title' | translate }}
        </div>
        <div>
          <button
            [disabled]="!configurationHasChanged"
            mat-icon-button
            *hasPerm="['WLMNetworkCrud', 'u']"
            class="button-margin"
            (click)="onSave()"
            color="primary"
            [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'save'"></wlm-icon>
          </button>
          <button
            [disabled]="!configurationHasChanged"
            *hasPerm="['WLMNetworkCrud', 'u']"
            mat-icon-button
            class="button-margin"
            (click)="discard()"
            color="primary"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="configuredNE"
        [settings]="settingsCustom"
        (somethingChanged)="compareNE()"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
