<wlm-he-family-level-filter
  *ngIf="filterSettings"
  class="remove-filters-background filter-margin"
  [settings]="filterSettings"
  [clearAll$]="clearAll$"
  [persistFilters$]="persistFilters$"
  (filtersChanged)="onFiltersChanged($event)"
  (apply)="onApply()"
  (autoLoad)="onAutoload()"
></wlm-he-family-level-filter>
