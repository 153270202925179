<div *ngIf="!loading && dataLoaded">
  <wlm-generic-chart
    *ngIf="customSettings"
    [settings]="customSettings"
    [showDataPoints$]="showDataPoints$"
    [size]="size"
    (chartClickEvent)="onElementClick($event)"
    (chartDataZoomEvent)="onChartDataZoom($event)"
    (chartBrushEnd)="onChartBrushEnd($event)"
    (chartLoaded)="onChartLoaded($event)"
  ></wlm-generic-chart>
</div>

<wlm-no-results [showNoResult]="!loading && (!dataLoaded || !customSettings)"></wlm-no-results>
