import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { UtilsHelperService } from 'src/app/common-modules/shared/helpers/utils-helper.service';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { WlmElementType } from '../../shared/model/wlm/wlm-element-type';
import { MapHelperService } from '../map-helper.service';
import { KpiTranslationKeysMapping } from '../map-thematic/models/kpi-translations-mapping';
import { MapThematicKpi } from '../map-thematic/models/map-thematic-kpi';
import { KpiStep } from '../map-thematic/models/map-thematic-kpi-step';
import { KpiSettingValue } from '../map-thematic/models/map-thematic-kpi-value';
import { MapThematicTooltipInfo } from '../map-thematic/models/map-thematic-tooltip-info';

const COMPONENT_SELECTOR = 'wlm-map-thematic-legend';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-thematic-legend.component.html',
  styleUrls: ['./map-thematic-legend.component.scss'],
})
export class MapThematicLegendComponent implements OnInit {
  private _uomTs = 'common.unit-labels';

  private _visibleThematicsIds: string[];
  public get visibleThematicsIds(): string[] {
    return this._visibleThematicsIds;
  }
  @Input() public set visibleThematicsIds(value: string[]) {
    this._visibleThematicsIds = value;

    if (value.length) {
      const thematicComponents = this.visibleThematicsIds[0].split('-');

      this.selectedHeTypeAsNETypeId = thematicComponents[0];
      this.selectedKpiType = thematicComponents[1];
      this.selectedKpiProperty = thematicComponents[2];
    } else {
      this.mapThematicTooltipInfoChanges.emit(null);
    }
  }

  private _kpiInfo: MapThematicKpi;
  public get kpiInfo(): MapThematicKpi {
    return this._kpiInfo;
  }
  @Input() public set kpiInfo(value: MapThematicKpi) {
    this._kpiInfo = value;

    if (value) {
      this.kpiInfoValues = value.values[0];
      this.configureLabel();
    }
  }

  @Output() mapThematicTooltipInfoChanges = new EventEmitter<MapThematicTooltipInfo>();

  selectedHeTypeAsNETypeId: string;
  selectedHeTypeId: string;
  selectedKpiType: string;
  selectedKpiProperty: string;
  currentHeLevelSteps: KpiStep[] = [];
  unitLabel: string;
  stepsLabels: string[] = [];
  kpiInfoValues: KpiSettingValue;

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  translationMapping = KpiTranslationKeysMapping;

  private _unitConversions: UnitTypeConversionViewDto[];
  private _currentConversion: UnitTypeConversionViewDto;
  private _elementTypes: WlmElementType[];

  constructor(
    private _uomService: UoMService,
    private _mapHelperService: MapHelperService,
    private _utilsHelperService: UtilsHelperService
  ) {}

  ngOnInit(): void {
    this._uomService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (uomConversions) => {
          this._unitConversions = uomConversions;
        },
      });

    this._mapHelperService
      .getWlmElementTypes()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (elementTypes) => {
          this._elementTypes = elementTypes;
        },
      });
  }

  configureLabel() {
    if (this.selectedHeTypeAsNETypeId && this.kpiInfoValues) {
      this.currentHeLevelSteps = [];
      const kpiInfoValue = this.kpiInfoValues.steps.find(
        (f) => f.level === +this.selectedHeTypeAsNETypeId
      );
      if (kpiInfoValue) {
        this.currentHeLevelSteps = [...kpiInfoValue.steps];
      }
      this.calculateUnits();
    }
  }

  calculateUnits() {
    const currentHeId = this._elementTypes.find(
      (f) => f.networkElementTypeId === +this.selectedHeTypeAsNETypeId
    )?.hierarchyElementTypeId;

    if (currentHeId && this.selectedKpiType && this.selectedKpiProperty) {
      this._currentConversion = this._unitConversions.find(
        (f) =>
          f.hierarchyElementTypeId === currentHeId &&
          f.dimensionTypeId === this.kpiInfoValues.dimensionTypeId &&
          f.timeAggregationId === this.kpiInfoValues.timeAggregationId
      );

      this.updateStepsValues();
      this.setUnitLabel();
    }
  }

  private updateStepsValues() {
    this.stepsLabels = [];
    const conversion = this._currentConversion?.conversionFactor ?? 1;
    this.currentHeLevelSteps.forEach((step, index) => {
      const value = +this._utilsHelperService.uomMultiply(String(step.value), String(conversion));

      if (index < this.currentHeLevelSteps.length - 1) {
        const nextValue = +this._utilsHelperService.uomMultiply(
          String(this.currentHeLevelSteps[index + 1].value),
          String(conversion)
        );
        this.stepsLabels.push(`${value} - ${nextValue}`);
      } else {
        this.stepsLabels.push(`≥ ${value}`);
      }
    });
  }

  private setUnitLabel() {
    this._uomService
      .getFormatedUnit(
        this._currentConversion?.unitTypeToDescription,
        `${this._uomTs}.${this.kpiInfoValues.labelKey}`,
        this.kpiInfoValues.unitTypeFormat
      )
      .pipe(untilDestroyed(this))
      .subscribe((unitLabel) => {
        this.unitLabel = unitLabel ? ` [${unitLabel}]` : '';

        this.updateMapThematicTooltipInfo(unitLabel);
      });
  }

  private updateMapThematicTooltipInfo(selectedUnitLabel: string) {
    const conversionFactor = this._currentConversion?.conversionFactor ?? 1;
    const thematicKey = this.selectedKpiType + '-' + this.selectedKpiProperty;
    const thematicNameKey = undefined;
    const categoryNameKey = this.translationMapping[this.kpiInfo.categoryKey];
    const unitLabel = selectedUnitLabel ?? '';

    const tooltipInfo = new MapThematicTooltipInfo({
      categoryNameKey: categoryNameKey,
      conversionFactor,
      thematicNameKey,
      kpiSetting: this.kpiInfoValues,
      thematicKey,
      unitLabel,
    });

    this.mapThematicTooltipInfoChanges.emit(tooltipInfo);
  }
}
