<div class="filter-buttons-container" *ngIf="workOrderId">
  <button
    mat-icon-button
    class="button-position"
    (click)="genericGrid.clearFilters()"
    [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
  >
    <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
  </button>

  <wlm-export-excel-button
    [visibleColumns]="gridSettings?.gridColumnSettings"
    [grid]="genericGrid"
  ></wlm-export-excel-button>

  <wlm-export-pdf-button [exportPdfComponent]="genericGrid"></wlm-export-pdf-button>
</div>

<wlm-generic-grid
  *ngIf="workOrderId"
  class="wtr-fx-flex-grow"
  [gridSettings]="gridSettings"
  [gridFiltersForBinding]="filters"
></wlm-generic-grid>
