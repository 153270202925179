<div class="wtr-fx-layout-column" id="shell-container" *ngIf="configLoaded">
  <div id="wlm-top-bar-container">
    <wlm-top-bar></wlm-top-bar>
  </div>

  <div id="shell-content" class="wtr-fx-layout">
    <div id="main-content" [style.max-width]="mainContentWidthFx ? mainContentWidthFx + '%' : null">
      <wlm-nav-bar></wlm-nav-bar>
    </div>
    <div
      id="rpanel"
      [style.max-width]="rightPanelWidthFx ? rightPanelWidthFx + '%' : null"
      *ngIf="rightPanelVisible | async"
      [ngClass]="{ hovering: (rightPanelHover | async) }"
    >
      <div
        class="side-bar-box"
        cdkDragLockAxis="x"
        cdkDrag
        [cdkDragFreeDragPosition]="dragPosition"
        (cdkDragEnded)="dragEnded2($event)"
      ></div>

      <div id="rpanel-container">
        <wlm-gl-detail-panel class="wtr-fx-layout-column wtr-fx-flex-grow"></wlm-gl-detail-panel>
      </div>
    </div>
  </div>
</div>
