import { Injectable } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartHelperService } from 'src/app/common-modules/shared/charts/chart-helper.service';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from 'src/app/common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { ChartTooltipNativeParams } from '../models/chart-tooltip-native-params';
import { GCartesianChartSerieTooltip } from '../models/generic-chart-settings/g-cartesian-chart-serie-tooltip';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GChartAxis } from '../models/generic-chart-settings/g-chart-axis';
import { GChartAxisLabel } from '../models/generic-chart-settings/g-chart-axis-label';
import { GChartAxisSplitLine } from '../models/generic-chart-settings/g-chart-axis-split-line';
import { GChartAxisTick } from '../models/generic-chart-settings/g-chart-axis-tick';
import { GChartDataZoom } from '../models/generic-chart-settings/g-chart-data-zoom';
import { GChartItemStyle } from '../models/generic-chart-settings/g-chart-item-style';
import { GChartLegend } from '../models/generic-chart-settings/g-chart-legend';
import { GChartLineStyle } from '../models/generic-chart-settings/g-chart-line-style';
import { GChartSerieDataPoint } from '../models/generic-chart-settings/g-chart-serie-data-point';
import { GChartSerieDataSection } from '../models/generic-chart-settings/g-chart-serie-data-section';
import { GChartSerieLabel } from '../models/generic-chart-settings/g-chart-serie-label';
import { GChartTextStyles } from '../models/generic-chart-settings/g-chart-text-styles';
import { GChartTooltip } from '../models/generic-chart-settings/g-chart-tooltip';
import { GChartVisualMap } from '../models/generic-chart-settings/g-chart-visual-map';
import { GPieChartSerie } from '../models/generic-chart-settings/g-pie-chart-serie';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericPieChartSettings } from '../models/generic-chart-settings/generic-pie-chart-settings';
import { TooltipFilterMethodEnum } from '../models/generic-chart-settings/tooltip-filter-method-enum';
import { GenericSchematicChartSettings } from '../models/schematics/generic-schematic-chart-settings';

@Injectable({
  providedIn: 'root',
})
export class GenericChartService {
  private _offset = 45;
  private _fontSize = 9;
  // Default background with transparency
  private _defaultBackground = 'rgba(255,255,255,0.7)';

  private _serieColors = [
    '#54A71D',
    '#0BC3B4',
    '#D2D000',
    '#2724DF',
    '#00B5C2',
    '#D27B1B',
    '#FFE300',
    '#FF42D6',
    '#42F8FF',
    '#FFFD42',
    '#42FFAD',
    '#42BAFF',
    '#FFC142',
    '#FF4242',
  ];

  seriesToHide: string[] = [];

  private _seriesToHideChanged$ = new Subject<string[]>();
  readonly seriesToHideChanged$ = this._seriesToHideChanged$.asObservable();

  constructor(
    private _localization: LocalizationHelperService,
    private _objectHelper: ObjectHelperService,
    private _uomService: UoMService,
    private _arrayHelper: ArrayHelperService,
    private _dateHelper: DateHelperService,
    private _settingsService: SettingsService,
    private _chartHelperService: ChartHelperService
  ) {}

  public getPieChart(initSettings: GenericPieChartSettings): Observable<GenericPieChartSettings> {
    let initialSeries = [];
    if (initSettings) {
      initialSeries = initSettings.series;
    }
    // TODO: complete charsettings
    return this.convertSeriesPoints(initialSeries).pipe(
      map(({ series, unitTypes }) => {
        const adaptedSerie = series as GPieChartSerie[];

        adaptedSerie.forEach((serie) => {
          const total = serie.dataPoints.reduce((sum, point) => sum + point.value, 0);

          serie.dataPoints.forEach((point) => {
            const unit = unitTypes.find(
              (x) =>
                x.dimensionTypeId === serie.yAxisWLMDimensionTypeId &&
                x.unitTypeFromId === serie.yAxisWLMUnitTypeIdFrom &&
                x.unitTypeToId === serie.yAxisWLMUnitTypeIdTo &&
                x.isDefaultFromUnit === true
            );
            const percentage = ((point.value * 100) / total).toFixed(serie.decimalPositions ?? 2);

            point.label = this.getPieLabelFormatted(point, serie, unit, percentage);
          });
        });

        const chartSettings: GenericPieChartSettings = {
          type: 'pie',
          title: initSettings.title,
          series: adaptedSerie,
          bottom: 'top',
          legend: new GChartLegend({
            bottom: 'bottom',
            orient: 'horizontal',
          }),
          grid: initSettings.grid,
        };
        return chartSettings;
      })
    );
  }

  formatLabel(param) {
    return param.data.pointLabel;
  }

  public convertSeriesPoints(series: any): Observable<{
    series: GCartesianChartSerie[] | GPieChartSerie[];
    unitTypes: UnitTypeConversionViewDto[];
  }> {
    return this._uomService.getAll().pipe(
      map((unitTypes) => {
        if (series === null || series === undefined || !series.length) {
          return {
            series: null,
            unitTypes: [],
          };
        }

        let convertedSeries;
        if (series[0].type === 'pie') {
          convertedSeries = series.map((serie) => ({
            ...(serie as GPieChartSerie),
            dataPoints: this.convertSingleSeriePoints(unitTypes, serie as GPieChartSerie),
          }));
        } else {
          convertedSeries = series.map((serie) => ({
            ...(serie as GCartesianChartSerie),
            dataPoints: this.convertSingleSeriePoints(unitTypes, serie as GCartesianChartSerie),
          }));
        }

        return {
          series: convertedSeries,
          unitTypes,
        };
      })
    );
  }

  private getPieLabelFormatted(
    point: GChartSerieDataSection,
    serie: GPieChartSerie,
    unit: UnitTypeConversionViewDto,
    percentage: string
  ): GChartSerieLabel {
    const labelFormatted = `${this.formatNumber(point.value)} ${
      unit.unitTypeToDescription
    } (${percentage}%)`;
    return new GChartSerieLabel({ formatter: labelFormatted });
  }

  public getDefaultCartesianChart(
    initSettings: GenericCartesianChartSettings
  ): Observable<GenericCartesianChartSettings> {
    let initialSeries = [];
    if (initSettings) {
      initialSeries = initSettings.series;
    }

    initialSeries = this.checkShowInUtc(initialSeries);

    return this.convertSeriesPoints(initialSeries).pipe(
      map(({ series, unitTypes }) => {
        if (series === null || series === undefined || !initSettings) {
          return null;
        }

        const adaptedSerie = series as GCartesianChartSerie[];
        let verticalAxes = this.getDefaultVerticalAxes(adaptedSerie, unitTypes);
        let horizontalAxes: GChartAxis[];

        if (initSettings?.useCategoryAxis || initSettings.xAxisType == 'category') {
          horizontalAxes = this.getCategoryHorizontalAxes(adaptedSerie);
        } else if (initSettings.xAxisType == 'value') {
          horizontalAxes = this.getValueHorizontalAxes(adaptedSerie);
        } else {
          horizontalAxes = this.getDefaultHorizontalAxes(adaptedSerie);
        }

        for (const [index, serie] of adaptedSerie.entries()) {
          const xIndex = horizontalAxes.findIndex(
            (x) => x.axisWLMDimensionTypeId === serie.xAxisWLMDimensionTypeId
          );

          const yIndex = verticalAxes.findIndex(
            (y) =>
              y.axisWLMDimensionTypeId === serie.yAxisWLMDimensionTypeId &&
              y.axisWLMUnitTypeId === serie.yAxisWLMUnitTypeIdTo
          );

          serie.xAxisIndex = xIndex;
          serie.yAxisIndex = yIndex;
          serie.labelLayout = {
            hideOverlap: true,
          };

          this.setSerieColor(serie, index);

          const orderedDataPoints = !initSettings?.useCategoryAxis
            ? asEnumerable(serie.dataPoints)
                .OrderBy((x: any) => x.pointCategory)
                .ToArray()
            : serie.dataPoints;

          serie.dataPoints = orderedDataPoints;

          this.processSerieSymbol(serie);
        }

        horizontalAxes = this.setLabelFormatter(horizontalAxes, true);
        verticalAxes = this.setLabelFormatter(verticalAxes, false);

        const dataZoom = initSettings?.useDataZoom
          ? this.buildDataZoom(horizontalAxes, verticalAxes)
          : null;
        // Caution: series cannot be reordered after calling this method!
        const visualMap = this.buildVisualMaps(adaptedSerie);
        const defaultTooltip = new GChartTooltip({
          formatter: (params) => {
            return this.formatTooltipClosestPoint(
              params,
              verticalAxes,
              adaptedSerie,
              initSettings.useCategoryAxis,
              initSettings.xAxisType,
              initSettings.categoryAxisTitle
            );
          },
          backgroundColor: this._defaultBackground,
        });

        this.serieNeedsPrecision(adaptedSerie, verticalAxes);

        const chartSettings: GenericCartesianChartSettings = {
          type: 'normal',
          series: adaptedSerie,
          xAxes: horizontalAxes,
          yAxes: verticalAxes,
          tooltip: initSettings?.tooltip ?? defaultTooltip,
          dataZoom,
          visualMap,
          legend: initSettings.legend
            ? initSettings.legend
            : new GChartLegend({
                data: adaptedSerie.map((x) => x.name),
                // bottom: '-5',
                fontSize: '10',
                itemHeight: '10',
              }),
          columnCustomizations: initSettings.columnCustomizations,
        };

        return chartSettings;
      })
    );
  }

  public getCustomizableChart(initSettings: GenericCartesianChartSettings) {
    let initialSeries = [];
    if (initSettings) {
      initialSeries = initSettings.series;
    }

    initialSeries = this.checkShowInUtc(initialSeries);

    this.serieNeedsPrecision(initSettings.series, initSettings.yAxes);

    const defaultTooltip = new GChartTooltip({
      formatter: (params) => {
        return this.formatTooltipClosestPoint(
          params,
          initSettings.yAxes,
          initSettings.series,
          initSettings.useCategoryAxis,
          initSettings.xAxisType,
          initSettings.categoryAxisTitle
        );
      },
      backgroundColor: this._defaultBackground,
    });

    initSettings.tooltip = defaultTooltip;
    initSettings.series = initialSeries;

    return of(initSettings);
  }

  getNoConversionCartesianChart(
    initSettings: GenericCartesianChartSettings
  ): Observable<GenericCartesianChartSettings> {
    let series = [];
    if (initSettings) {
      series = initSettings.series;
    }

    if (series === null || series === undefined || !initSettings) {
      return null;
    }

    const adaptedSerie = series as GCartesianChartSerie[];
    let verticalAxes = this.getNoConversionVerticalAxes(adaptedSerie);
    let horizontalAxes: GChartAxis[];

    if (initSettings?.useCategoryAxis || initSettings.xAxisType == 'category') {
      horizontalAxes = this.getCategoryHorizontalAxes(adaptedSerie);
    } else if (initSettings.xAxisType == 'value') {
      horizontalAxes = this.getValueHorizontalAxes(adaptedSerie);
    } else {
      horizontalAxes = this.getDefaultHorizontalAxes(adaptedSerie);
    }

    for (const [index, serie] of adaptedSerie.entries()) {
      const xIndex = horizontalAxes.findIndex(
        (x) => x.axisWLMDimensionTypeId === serie.xAxisWLMDimensionTypeId
      );

      const yIndex = verticalAxes.findIndex((y) => y.name === serie.yAxisName);

      serie.xAxisIndex = xIndex;
      serie.yAxisIndex = yIndex;
      serie.labelLayout = {
        hideOverlap: true,
      };

      if (serie.label?.show && !serie.label.formatter) {
        serie.label.formatter = (params) => {
          return this.formatLabel(params);
        };
      }

      serie.itemStyle = new GChartItemStyle({ color: this.assignColor(index) });

      const orderedDataPoints = !initSettings?.useCategoryAxis
        ? asEnumerable(serie.dataPoints)
            .OrderBy((x: any) => x.pointCategory)
            .ToArray()
        : serie.dataPoints;

      serie.dataPoints = orderedDataPoints;

      this.processSerieSymbol(serie);
    }

    horizontalAxes = this.setLabelFormatter(horizontalAxes, true);
    verticalAxes = this.setLabelFormatter(verticalAxes, false);

    const dataZoom = initSettings?.useDataZoom
      ? this.buildDataZoom(horizontalAxes, verticalAxes, 'inside')
      : null;
    const defaultTooltip = new GChartTooltip({
      formatter: (params) => {
        return this.formatTooltipClosestPoint(
          params,
          verticalAxes,
          adaptedSerie,
          initSettings.useCategoryAxis,
          initSettings.xAxisType,
          initSettings.categoryAxisTitle
        );
      },
      backgroundColor: this._defaultBackground,
    });

    const chartSettings: GenericCartesianChartSettings = {
      type: 'normal',
      series: adaptedSerie,
      xAxes: horizontalAxes,
      yAxes: verticalAxes,
      tooltip: initSettings?.tooltip ?? defaultTooltip,
      dataZoom,
      legend: initSettings.legend
        ? initSettings.legend
        : new GChartLegend({
            data: adaptedSerie.map((x) => x.name),
            // bottom: '-5',
            fontSize: '10',
            itemHeight: '10',
          }),
    };

    if (initSettings.grid) {
      chartSettings.grid = initSettings.grid;
    }

    return of(chartSettings);
  }

  private setSerieColor(serie: GCartesianChartSerie, index: number) {
    //if serie hasn't assigned a color, use the randomly assigned color for items and lines
    var assignedColor = this.assignColor(index);

    serie.itemStyle = serie?.itemStyle?.color
      ? serie.itemStyle
      : new GChartItemStyle({ color: assignedColor });

    serie.lineStyle = serie?.lineStyle?.color
      ? serie.lineStyle
      : new GChartLineStyle({ color: assignedColor });
  }

  private serieNeedsPrecision(series: GCartesianChartSerie[], axes: GChartAxis[]) {
    const minDecimalPrecision = 1 / Math.pow(10, this._settingsService.maxDecimalPositions);

    const numberOfAxis = this._arrayHelper.onlyUnique(series.map((x) => x.yAxisIndex));

    numberOfAxis.forEach((axisIndex) => {
      const axis = axes[axisIndex];

      if (axis.type != 'value') {
        return;
      }

      const axisSerieDataPoints = asEnumerable(
        series.filter((x) => x.yAxisIndex == axisIndex)
      ).SelectMany((x) => x.dataPoints);

      const minValue = axisSerieDataPoints?.Any()
        ? axisSerieDataPoints.Min((x) => +x.pointValue)
        : null;
      const maxValue = axisSerieDataPoints?.Any()
        ? axisSerieDataPoints.Max((x) => +x.pointValue)
        : null;

      if (Math.abs(maxValue - minValue) < minDecimalPrecision) {
        axis.min = axis.min ?? minValue - minDecimalPrecision;
        axis.max = axis.max ?? maxValue + minDecimalPrecision;

        axis.interval = axis.interval ?? minDecimalPrecision;
      }
    });
  }

  getSchematicChart(
    initSettings: GenericSchematicChartSettings
  ): Observable<GenericSchematicChartSettings> {
    return of(initSettings);
  }

  setVisibleSeriesByName(seriesToHide: string[]) {
    this.seriesToHide = seriesToHide;
    this._seriesToHideChanged$.next(seriesToHide);
  }

  assignColor(serieIndex): string {
    if (this._serieColors.length <= serieIndex) {
      return this._chartHelperService.getRandomColor(); //random color;
    } else {
      return this._serieColors[serieIndex];
    }
  }

  /**
   * Check if dates must be shown in UTC, and convert them to UTC if necessary.
   */
  private checkShowInUtc(series: GCartesianChartSerie[]): GCartesianChartSerie[] {
    const result = series.map((serie) => {
      const referenceCategory =
        serie.dataPoints && serie.dataPoints.length ? serie.dataPoints[0].pointCategory : null;
      if (referenceCategory !== null && typeof referenceCategory !== 'number') {
        if (serie.showInUtc) {
          serie.dataPoints = serie.dataPoints.map((point) => ({
            ...point,
            pointCategory: this._dateHelper.convertDateToUTC(point.pointCategory as Date),
          }));
        }
      }
      return serie;
    });
    return result;
  }

  private convertSingleSeriePoints(
    unitTypes: UnitTypeConversionViewDto[],
    serie: any
  ): GChartSerieDataPoint[] | GChartSerieDataSection[] {
    if (!serie.dataPoints?.length) {
      return [];
    }

    // If the serie is already converted, just return the data.
    if (serie.yAxisWLMUnitTypeIdFrom === serie.yAxisWLMUnitTypeIdTo) {
      return this.formatSerieDatapoints(serie);
    }

    const unitTypeConversionForSerie = unitTypes.find(
      (x) =>
        x?.unitTypeFromId === serie.yAxisWLMUnitTypeIdFrom &&
        x?.unitTypeToId === serie.yAxisWLMUnitTypeIdTo &&
        x?.dimensionTypeId === serie.yAxisWLMDimensionTypeId
    );

    if (!unitTypeConversionForSerie) {
      return this.formatSerieDatapoints(serie);
    }

    return serie.dataPoints.map((serieDataPoint) => {
      const currentValue = serieDataPoint['pointValue'] ?? serieDataPoint['value'];
      const convertedValue = unitTypeConversionForSerie
        ? this._uomService.uomMultiply(
            String(currentValue),
            String(unitTypeConversionForSerie.conversionFactor),
            false,
            null,
            true
          )
        : currentValue;

      if (serieDataPoint['pointValue']) {
        serieDataPoint.pointValue = convertedValue;
      } else {
        serieDataPoint.value = convertedValue;
      }
      return serieDataPoint;
    });
  }

  private formatSerieDatapoints(serie: any) {
    return serie.dataPoints.map((serieDataPoint) => {
      const currentValue = serieDataPoint['pointValue'] ?? serieDataPoint['value'];
      const convertedValue =
        currentValue !== null && currentValue !== undefined
          ? this._localization.formatNumber(currentValue, null, true)
          : currentValue;

      if (serieDataPoint['pointValue']) {
        serieDataPoint.pointValue = convertedValue;
      } else {
        serieDataPoint.value = convertedValue;
      }
      return serieDataPoint;
    });
  }

  private toFixed(value, decimals: number): string {
    if (!value || isNaN(Number(value))) {
      return value;
    }

    let numericValue = Number(value);
    return numericValue.toFixed(decimals);
  }

  private getDefaultVerticalAxes(
    series: GCartesianChartSerie[],
    unitTypes: UnitTypeConversionViewDto[]
  ): GChartAxis[] {
    let numberYConversions = series.map((serie) => ({
      dimension: serie.yAxisWLMDimensionTypeId,
      unit: serie.yAxisWLMUnitTypeIdTo,
    }));
    numberYConversions = this.distinct(numberYConversions);

    const verticalAxes: GChartAxis[] = [];

    let offset = 0;
    let axisNumber = 1;

    for (const conversion of numberYConversions) {
      const unitTypeConversion = unitTypes.find(
        (ut) => ut.dimensionTypeId === conversion.dimension && ut.unitTypeToId === conversion.unit
      );
      const serieValues = [];
      series
        .filter(
          (y) =>
            y.yAxisWLMDimensionTypeId === conversion.dimension &&
            y.yAxisWLMUnitTypeIdTo === conversion.unit
        )
        .forEach((serie) => serieValues.push(...serie.dataPoints.map((point) => point.pointValue)));

      const sampleSerie = series[0];

      const yAxis = new GChartAxis({
        offset,
        nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
        name: this.buildYAxisName(sampleSerie, unitTypeConversion),
        show: true,
        position: this.getVerticalAxisPosition(axisNumber),
        axisWLMDimensionTypeId: conversion.dimension,
        axisWLMUnitTypeId: conversion.unit,
        type: 'value',
        axisLabel: new GChartAxisLabel({ fontSize: this._fontSize }),
      });

      axisNumber++;

      if (this.checkIfSumOffset(axisNumber)) {
        offset += this._offset;
      }

      verticalAxes.push(yAxis);
    }

    return verticalAxes;
  }

  private getNoConversionVerticalAxes(series: GCartesianChartSerie[]): GChartAxis[] {
    let numberYConversions = series.map((serie) => ({
      unit: serie.yAxisName,
      dimension: serie.yAxisCustomDimension,
    }));
    numberYConversions = this.distinct(numberYConversions);

    const verticalAxes: GChartAxis[] = [];

    let offset = 0;
    let axisNumber = 1;

    for (const conversion of numberYConversions) {
      const serieValues = [];
      series
        .filter((y) => y.yAxisCustomDimension === conversion.dimension)
        .forEach((serie) => serieValues.push(...serie.dataPoints.map((point) => point.pointValue)));

      const min = this._arrayHelper.minValue(serieValues);
      const max = this._arrayHelper.maxValue(serieValues);

      const yAxis = new GChartAxis({
        max,
        min,
        offset,
        nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
        name: conversion.unit,
        show: true,
        position: this.getVerticalAxisPosition(axisNumber),
        axisWLMDimensionTypeId: DimensionTypesEnum.NA,
        axisWLMUnitTypeId: DimensionTypesEnum.NA,
        type: 'value',
        axisLabel: new GChartAxisLabel({ fontSize: this._fontSize }),
      });

      axisNumber++;

      if (this.checkIfSumOffset(axisNumber)) {
        offset += this._offset;
      }

      verticalAxes.push(yAxis);
    }

    return verticalAxes;
  }

  /**
   * Allows to specify a custom axis name that may not be related to the uom conversions.
   */
  private buildYAxisName(
    serie: GCartesianChartSerie,
    unitTypeConversion: UnitTypeConversionViewDto
  ): string {
    if (serie?.yAxisWLMUnitCustomLabel) {
      return serie.yAxisWLMUnitCustomLabel;
    }

    const nameByUnit =
      unitTypeConversion?.dimensionTypeId === DimensionTypesEnum.NA || !unitTypeConversion
        ? ''
        : unitTypeConversion.unitTypeToDescription;
    return nameByUnit;
  }

  private getDefaultHorizontalAxes(series: GCartesianChartSerie[]): GChartAxis[] {
    let numberXDimensions = series.map((serie) => serie.xAxisWLMDimensionTypeId);
    numberXDimensions = this.distinct(numberXDimensions);

    const horizontalAxes: GChartAxis[] = [];

    let offset = 0;
    for (const dimension of numberXDimensions) {
      const dimensionSeries = series.filter((x) => x.xAxisWLMDimensionTypeId === dimension);

      // If min or max are specified, use them.
      let min = this._arrayHelper.minValue(dimensionSeries.map((s) => s.xMin).filter(Boolean));
      min = min !== null ? new Date(min) : min;

      let max = this._arrayHelper.maxValue(dimensionSeries.map((s) => s.xMax).filter(Boolean));
      max = max !== null ? new Date(max) : max;

      // If one of them is not specified, calculate them from the data.
      if (min === null || max === null) {
        const serieCategories = [];
        dimensionSeries.forEach((serie) =>
          serieCategories.push(...serie.dataPoints.map((point) => point.pointCategory))
        );

        if (min === null) {
          min = new Date(this._arrayHelper.minValue(serieCategories));
        }
        if (max === null) {
          max = new Date(this._arrayHelper.maxValue(serieCategories));
        }
      }

      const xAxis = new GChartAxis({
        offset,
        max,
        min,
        nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
        name: dimension.toString(),
        show: true,
        position: 'bottom',
        axisWLMDimensionTypeId: dimension,
        type: 'time',
        boundaryGap: false,
        splitLine: new GChartAxisSplitLine({
          show: false,
        }),
        axisTick: new GChartAxisTick({
          show: true,
          interval: 2,
          alignWithLabel: false,
          inside: false,
          lenght: 5,
        }),
        axisLabel: new GChartAxisLabel({
          // formatter: this.formatDate,
        }),
      });

      horizontalAxes.push(xAxis);

      offset += this._offset;
    }

    return horizontalAxes;
  }

  private getCategoryHorizontalAxes(series: GCartesianChartSerie[]): GChartAxis[] {
    let numberXDimensions = series.map((serie) => serie.xAxisWLMDimensionTypeId);
    numberXDimensions = this.distinct(numberXDimensions);

    const horizontalAxes: GChartAxis[] = [];

    let offset = 0;
    for (const dimension of numberXDimensions) {
      const dimensionSeries = series.filter((x) => x.xAxisWLMDimensionTypeId === dimension);

      const xAxisData = [
        ...new Set(
          dimensionSeries
            .map((x) => x.dataPoints.map((y) => y.pointCategory.toString()))
            .reduce((combined, current) => combined.concat(current), [])
        ),
      ];

      const xAxis = new GChartAxis({
        offset,
        data: xAxisData,
        nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
        name: '',
        show: true,
        position: 'bottom',
        axisWLMDimensionTypeId: dimension,
        type: 'category',
        boundaryGap: true,
        axisLabel: new GChartAxisLabel(),
      });

      horizontalAxes.push(xAxis);

      offset += this._offset;
    }

    return horizontalAxes;
  }

  private getValueHorizontalAxes(series: GCartesianChartSerie[]): GChartAxis[] {
    let numberXDimensions = series.map((serie) => serie.xAxisWLMDimensionTypeId);
    numberXDimensions = this.distinct(numberXDimensions);

    const horizontalAxes: GChartAxis[] = [];

    let offset = 0;
    for (const dimension of numberXDimensions) {
      const dimensionSeries = series.filter((x) => x.xAxisWLMDimensionTypeId === dimension);

      const serie = dimensionSeries[0];

      const xAxisData = [
        ...new Set(
          dimensionSeries
            .map((x) => x.dataPoints.map((y) => y.pointCategory))
            .reduce((combined, current) => combined.concat(current), [])
        ),
      ];

      const xAxis = new GChartAxis({
        offset,
        data: xAxisData,
        nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
        name: serie.xAxisName,
        show: true,
        position: 'bottom',
        nameLocation: 'middle',
        axisWLMDimensionTypeId: dimension,
        type: 'value',
        boundaryGap: true,
        axisLabel: new GChartAxisLabel({ show: true, align: 'center' }),
      });

      horizontalAxes.push(xAxis);

      offset += this._offset;
    }

    return horizontalAxes;
  }

  private getVerticalAxisPosition(axisNumber): 'left' | 'right' {
    const position = axisNumber % 2 != 0 ? 'left' : 'right';
    return position;
  }

  /**
   * Build a data zoom for each axis declared in the chart.
   */
  buildDataZoom(
    xAxes: GChartAxis[],
    yAxes: GChartAxis[],
    type: 'inside' | 'slider' = 'slider'
  ): GChartDataZoom[] {
    const dataZoomArrayX = xAxes.map((xAxis, index) => {
      const dataZoom: GChartDataZoom = {
        id: `x-${index}`,
        xAxisIndex: index,
        type: type,
        start: 0,
        end: 100,
        bottom: 67,
        minSpan: 0.01,
        filterMode: 'none',
        labelFormatter: xAxis?.axisLabel?.formatter ?? this.formatDate,
        textStyle: new GChartTextStyles({
          fontSize: this._fontSize,
        }),
      };
      return dataZoom;
    });

    const xAxisIndexes: number[] = [];

    for (let index = 0; index < xAxes?.length; index++) {
      xAxisIndexes.push(index);
    }

    const dataZoomInsideX: GChartDataZoom = {
      id: `x-inside`,
      xAxisIndex: xAxisIndexes,
      type: 'inside',
      start: 0,
      end: 100,
      bottom: 67,
      filterMode: 'none',
    };

    const dataZoomInsideY = yAxes.map((_, index) => {
      const dataZoom: GChartDataZoom = {
        id: `y-inside-${index}`,
        yAxisIndex: index,
        type: 'inside',
        filterMode: 'none',
        start: 0,
        end: 100,
      };
      return dataZoom;
    });

    return dataZoomArrayX.concat(dataZoomInsideY).concat(dataZoomInsideX);
  }

  /**
   * Recovers the visual maps from all the series and assigns them to their index.
   * Caution: series cannot be reordered after calling this method!
   */
  private buildVisualMaps(series: GCartesianChartSerie[]): GChartVisualMap[] {
    const visualMaps = [];
    series.forEach((serie, index) => {
      if (serie.visualMap) {
        serie.visualMap.seriesIndex = [index];
        visualMaps.push(serie.visualMap);
      }
    });
    return visualMaps;
  }

  /**
   * Format the axis labels depending on the associated WLM Dimension.
   * Improvement: Implement real behavior: formatter will change depending on the unit types.
   * Currently, it will set the x axes to date formats and the y axes to number format.
   */
  private setLabelFormatter(axes: GChartAxis[], areXAxes: boolean): GChartAxis[] {
    const result = axes.map((axis) => {
      if (!areXAxes) {
        axis.axisLabel = {
          formatter: this.formatNumber,
          fontSize: this._fontSize,
        };
      }
      return axis;
    });
    return result;
  }

  formatDate = (date: Date): string => {
    return this._localization.formatDate(date, DateFormats.Date);
  };

  formatDateTime = (date: Date): string => {
    return this._localization.formatDate(date, DateFormats.DateTime);
  };

  formatNumber = (num: number): string => {
    return this._localization.formatNumber(num);
  };

  private distinct = (values) => {
    return this._objectHelper.deepDistinct(values);
  };

  private formatTooltip(
    params,
    yAxis: GChartAxis[],
    series: GCartesianChartSerie[],
    useCategoryAxis = false,
    xAxisType: 'category' | 'value' | 'default',
    categoryAxisTitle = ''
  ) {
    if (!params?.length) {
      return;
    }

    let tooltipTitle;

    if (useCategoryAxis || xAxisType == 'category' || xAxisType == 'value') {
      tooltipTitle = `${categoryAxisTitle}: ${params[0].value[0]}`;
    } else {
      // is a date axis and it needs to be formatted
      tooltipTitle = this.formatDateTime(params[0].value[0]);
    }

    let tooltipText = this.tooltipTemplateTitle(tooltipTitle);
    params.forEach((x) => {
      const currentSerie = series[x['seriesIndex']];
      let value = this.formatNumber(x['value'][1]);
      if (
        currentSerie.decimalPositions !== null &&
        typeof currentSerie.decimalPositions !== 'undefined'
      ) {
        value = this.toFixed(value, currentSerie.decimalPositions);
      }
      const unitLabel = yAxis[currentSerie.yAxisIndex].name;
      tooltipText += this.tooltipTemplateRow(x['seriesName'], value, unitLabel, x['color']);
    });
    return tooltipText;
  }

  //Only for date chart (no category chart). We should define better its behaviour
  formatTooltipClosestPoint(
    params,
    yAxis: GChartAxis[],
    series: GCartesianChartSerie[],
    useCategoryAxis = false,
    xAxisType: 'category' | 'value' | 'default',
    categoryAxisTitle
  ) {
    if (!params?.length) {
      return;
    }

    if (useCategoryAxis || xAxisType == 'category' || xAxisType == 'value') {
      return this.formatTooltip(
        params,
        yAxis,
        series,
        useCategoryAxis,
        xAxisType,
        categoryAxisTitle
      );
    }

    const seriesToShow = series.filter(
      (x) => x.showAlwaysInTooltip && !this.seriesToHide.includes(x.name) && x.dataPoints?.length
    );
    const notMandatorySeries = series.filter(
      (x) => !x.showAlwaysInTooltip && !this.seriesToHide.includes(x.name) && x.dataPoints?.length
    );

    const xTime = new Date(params[0].axisValue);

    const tooltipsByDate = new Map<string, GCartesianChartSerieTooltip[]>();

    const seriesWithValues = [];

    notMandatorySeries.forEach((serie) => {
      const serieDataPointForTooltip = serie.dataPoints.find((x) =>
        this._dateHelper.equals(new Date(x.pointCategory), xTime)
      );
      if (serieDataPointForTooltip) {
        const serieTooltip = new GCartesianChartSerieTooltip({
          name: serie.name,
          color: serie.itemStyle.color,
          dataPointValue: this.toFixed(
            serieDataPointForTooltip.pointValue,
            serie.decimalPositions ?? this._settingsService.maxDecimalPositions
          ),
          unit: yAxis[serie.yAxisIndex].name,
        });

        seriesWithValues.push(serieTooltip);
      }
    });

    tooltipsByDate.set(this._dateHelper.toApiFormat(xTime), seriesWithValues);

    // Updates "tooltipsByDate" by reference.
    this.buildClosestPointDailySeries(seriesToShow, xTime, tooltipsByDate, yAxis);

    const tooltipText = this.parseTooltipsByDate(tooltipsByDate);

    return tooltipText;
  }

  /**
   * Generate tooltips by applying filter methods like Daily, which allow daily series to be shown in a tooltip
   * when the tooltip point is not itself daily.
   * ie: if current date is 1/1/2020 18:30 and there is a daily serie that has a point on 1/1/2020, show it in the tooltip.
   * "tooltipsByDate" contains the results.
   */
  buildClosestPointDailySeries(
    seriesToShow: GCartesianChartSerie[],
    xTime: Date,
    tooltipsByDate: Map<string, GCartesianChartSerieTooltip[]>,
    yAxis?: GChartAxis[],
    tooltipNativeParams?: ChartTooltipNativeParams[],
    getSerieLabel?: (serie) => string
  ) {
    const filterTypes = asEnumerable(seriesToShow)
      .Select((x) => x.showAlwaysFilterMethod)
      .Distinct()
      .Where((x) => !!x)
      .ToArray();

    filterTypes.forEach((filterType) => {
      switch (filterType) {
        case TooltipFilterMethodEnum.Daily:
          this.buildClosestPointProcessDailySeries(
            seriesToShow,
            xTime,
            tooltipsByDate,
            yAxis,
            tooltipNativeParams,
            getSerieLabel
          );
      }
    });
  }

  private buildClosestPointProcessDailySeries(
    seriesToShow: GCartesianChartSerie[],
    xTime: Date,
    tooltipsByDate: Map<string, GCartesianChartSerieTooltip[]>,
    yAxis?: GChartAxis[],
    tooltipNativeParams?: ChartTooltipNativeParams[],
    getSerieLabel?: (serie) => string
  ) {
    const dailySeries = asEnumerable(
      seriesToShow.filter((x) => x.showAlwaysFilterMethod == TooltipFilterMethodEnum.Daily)
    )
      ?.OrderBy((x) => asEnumerable(x?.dataPoints)?.Min((y) => new Date(y.pointCategory).getTime()))
      .ToArray();

    const tooltipDailyDate = this._dateHelper.truncateDate(xTime);

    dailySeries?.forEach((dailySerie) => {
      const serieDataPoints = dailySerie.dataPoints;
      const serieDataPointForTooltip = serieDataPoints.find((x) =>
        this._dateHelper.equals(
          this._dateHelper.truncateDate(new Date(x.pointCategory)),
          tooltipDailyDate
        )
      );

      if (serieDataPointForTooltip) {
        const dataPointDate = this._dateHelper.toApiFormat(
          this._dateHelper.ensureDateObject(serieDataPointForTooltip.pointCategory)
        );

        const series = this.buildClosestPointDailySingleSerie(
          dataPointDate,
          dailySerie,
          tooltipsByDate,
          serieDataPointForTooltip,
          yAxis,
          tooltipNativeParams,
          getSerieLabel
        );

        if (series.length) {
          tooltipsByDate.set(dataPointDate, series);
        }
      }
    });
  }

  private buildClosestPointDailySingleSerie(
    dataPointDate: string,
    dailySerie: GCartesianChartSerie,
    tooltipsByDate: Map<string, GCartesianChartSerieTooltip[]>,
    serieDataPointForTooltip: GChartSerieDataPoint,
    yAxis?: GChartAxis[],
    tooltipNativeParams?: ChartTooltipNativeParams[],
    getSerieLabel?: (serie) => string
  ): GCartesianChartSerieTooltip[] {
    let series: GCartesianChartSerieTooltip[] = [];

    if (tooltipsByDate.has(dataPointDate)) {
      series = tooltipsByDate.get(dataPointDate);
    }

    const unitName = yAxis ? yAxis[dailySerie.yAxisIndex].name : dailySerie.yAxisName;

    const nativeParamsSerie = !tooltipNativeParams
      ? null
      : tooltipNativeParams.find((params) => params.seriesName === dailySerie.name);

    const serieName = getSerieLabel ? getSerieLabel(dailySerie) : dailySerie.name;

    if (!series.find((serie) => serie.name === serieName)) {
      series.push(
        new GCartesianChartSerieTooltip({
          name: serieName,
          color:
            dailySerie.itemStyle?.color ?? dailySerie.lineStyle?.color ?? nativeParamsSerie?.color,
          dataPointValue: this.toFixed(
            serieDataPointForTooltip.pointValue,
            dailySerie.decimalPositions ?? this._settingsService.maxDecimalPositions
          ),
          unit: unitName,
        })
      );
    }

    if (series.length) {
      if (tooltipsByDate.has(dataPointDate)) {
        const seriesInDate = tooltipsByDate.get(dataPointDate);
        series.forEach((serieToAdd) => {
          const alreadyAdded = !!seriesInDate.find(
            (serieInDate) => serieInDate.name === serieToAdd.name
          );
          if (!alreadyAdded) {
            seriesInDate.push(serieToAdd);
          }
        });
        tooltipsByDate.set(dataPointDate, seriesInDate);
      } else {
        tooltipsByDate.set(dataPointDate, series);
      }
    }

    return series;
  }

  parseTooltipsByDate(tooltipsByDate: Map<string, GCartesianChartSerieTooltip[]>): string {
    let tooltipText = '';
    let sortedDates = Array.from(tooltipsByDate.keys()).map((date: string) =>
      this._dateHelper.fromApiFormat(date)
    );
    sortedDates = this._dateHelper.sortDates(sortedDates);

    sortedDates.forEach((date) => {
      const tooltips = tooltipsByDate.get(this._dateHelper.toApiFormat(date));
      const tooltipTitle = this.formatDateTime(date);

      tooltipText += this.tooltipTemplateTitle(tooltipTitle);
      tooltips.forEach((tooltip) => (tooltipText += this.parseTooltip(tooltip)));
    });

    return tooltipText;
  }

  parseTooltip(tooltip: GCartesianChartSerieTooltip): string {
    const tooltipText = this.tooltipTemplateRow(
      tooltip.name,
      this.formatNumber(+tooltip.dataPointValue),
      tooltip.unit,
      tooltip.color
    );
    return tooltipText;
  }

  public buildDefaultTooltip(): GChartTooltip {
    const defaultTooltip = new GChartTooltip({
      alwaysShowContent: false,
      hideDelay: 300,
      axisPointer: {
        type: 'line',
      },
      show: true,
      trigger: 'axis',
      triggerOn: 'mousemove',
      position: 'top',
      confine: true,
      backgroundColor: this._defaultBackground,
    });
    return defaultTooltip;
  }

  /**
   * Exposes the tooltips templates, so each chart can build tooltips with the same styles.
   */
  public tooltipTemplateTitle(title: string): string {
    return `<b>${title}</b></br>`;
  }

  public tooltipTemplateRow(label: string, value: any, uom: string, color: string): string {
    const valueStr = value ? ': ' + value : '';
    const uomStr = uom ? ' ' + uom : '';
    return `<div style="height: 10px;
              width: 10px;
              background-color: ${color};
              border-radius: 50%;
              margin-right: 3px;
              display: inline-block;">
            </div>
            <span>
              <b>${label}</b>${valueStr}${uomStr}</br>
            </span>`;
  }

  public tooltipTemplateRowDetail(label: string, value: any): string {
    const valueStr = value ? ': ' + value : '';
    return `<span style="margin-left: 6px">
              <b>${label}</b>${valueStr}
            </span><br>`;
  }

  /**
   * It is not enough to clone with spread operator, because the dataPoint array keeps the same references.
   */
  public cloneSerie(serie: GCartesianChartSerie): GCartesianChartSerie {
    const cloned = {
      ...serie,
      dataPoints: serie.dataPoints.map((point) => ({ ...point })),
    };
    return cloned;
  }

  public cloneSeries(series: GCartesianChartSerie[]): GCartesianChartSerie[] {
    return series.map(this.cloneSerie);
  }

  processSerieSymbol(serie: GCartesianChartSerie): void {
    const threshold = this._settingsService.chartDisplaySymbolThreshold;
    if (typeof threshold !== 'undefined' && serie.dataPoints.length > threshold) {
      serie.symbol = 'none';
      serie.largeNumberOfPoints = true;
    }
  }

  private checkIfSumOffset(axisNumber): boolean {
    return axisNumber % 3 == 0;
  }
}
