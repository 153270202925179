<kendo-grid-column
  [field]="field"
  [width]="width"
  [headerStyle]="headerStyle"
  [style]="style"
  [locked]="locked"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <wlm-range-chart
      *ngIf="
        dimensionTypeId &&
        timeAggregationId &&
        (hasMaxMinDefined || (!hasMaxMinDefined && attributes))
      "
      [minTitleTooltip]="minTitleTooltip"
      [maxTitleTooltip]="maxTitleTooltip"
      [pointTitleTooltip]="pointTitleTooltip"
      [field]="currentField"
      [timeAggregationId]="timeAggregationId"
      [dimensionTypeId]="dimensionTypeId"
      [hierarchyElementTypeIdField]="hierarchyElementTypeIdField"
      [hierarchyElementTypeId]="hierarchyElementTypeId"
      [hierarchyElementIdField]="hierarchyElementIdField"
      [networkElementIdField]="networkElementIdField"
      [decimalPositions]="decimalPositions"
      [translationPrefix]="translationPrefix"
      [maxFieldName]="maxFieldName"
      [minFieldName]="minFieldName"
      [maxAttributeTypeId]="maxAttributeTypeId"
      [minAttributeTypeId]="minAttributeTypeId"
      [attributes]="attributes"
      [data]="dataItem"
      (unitDescriptionChange)="unitDescriptionChange($event)"
    ></wlm-range-chart>
  </ng-template>
</kendo-grid-column>
