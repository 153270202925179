<div class="ls-top-group top-title-position custom-top-title-margin custom-button-toggle">
  <div class="toggle-button-container">
    <mat-button-toggle-group [value]="toggleViewMode" (change)="onChange($event)">
      <mat-button-toggle [value]="currentMode">{{
        T_SCOPE + '.current-selector' | translate
      }}</mat-button-toggle>
      <mat-button-toggle [value]="historicalMode">{{
        T_SCOPE + '.historical-selector' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<wlm-ne-configuration-lars-sworps
  *ngIf="toggleViewMode === currentMode"
  [isReadOnly]="isReadOnly"
  [selectedNE]="selectedNE"
  class="current-style"
  [pageId]="pageId"
  [widgetId]="widgetId"
></wlm-ne-configuration-lars-sworps>
<wlm-ne-configuration-lars-sworps-historical
  *ngIf="toggleViewMode === historicalMode"
  [isReadOnly]="isReadOnly"
  [selectedNE]="selectedNE"
  class="content-stretch scrollable"
  [pageId]="pageId"
  [widgetId]="widgetId"
></wlm-ne-configuration-lars-sworps-historical>
