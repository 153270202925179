import { Directive, ElementRef } from '@angular/core';
import IMask from 'imask';

@Directive({
  selector: '[daysMask]',
})
export class DaysMaskDirective {
  private readonly _dayTimeMask = {
    mask: 'D.HH:MM:SS', // enable date mask
    blocks: {
      D: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 365,
        maxLength: 3,
      },
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      },
      SS: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
    autofix: true, // defaults to `false`, see details
    lazy: false,
    overwrite: true, // defaults to `false`
  };

  private _maskResult;

  constructor(private _el: ElementRef) {
    const element = this._el.nativeElement;
    this._maskResult = IMask(element, this._dayTimeMask);
  }
}
