import { Inject, Injectable, Optional } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { LogService } from '../../shared/wlm-log/log.service';
import { R_STATE_SYNC_SETTINGS } from '../redux-state.module';
import { StateSyncSettings } from './state-sync-settings';
import { UpdateStateGlobalAction } from './state-sync.actions';
import { StateSyncService } from './state-sync.service';

@Injectable()
export class StateSyncEffects {
  constructor(
    private _stateSyncService: StateSyncService,
    @Optional() @Inject(R_STATE_SYNC_SETTINGS) private _settings: StateSyncSettings,
    private _logService: LogService
  ) {}

  getSyncChanges = createEffect(() =>
    this._stateSyncService.listenState$().pipe(
      filter(() => Boolean(this._settings)),
      switchMap((newState) => {
        return newState ? of(new UpdateStateGlobalAction(newState)) : of(null);
      }),
      catchError((error) => {
        const errorMessage = '[Sync State] Error';
        this._logService.error({ msg: errorMessage, payload: error });
        return of({ type: errorMessage });
      })
    )
  );
}
