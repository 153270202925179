<form [formGroup]="infoForm" class="form fixed-subscript">
  <mat-form-field appearance="outline">
    <mat-label>{{ T_SCOPE + '.labels.name' | translate }}</mat-label>
    <input matInput type="text" [formControlName]="campaignNameFieldName" autocomplete="off" />
    <mat-error *ngIf="infoForm.get(campaignNameFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ T_SCOPE + '.labels.description' | translate }}</mat-label>
    <input
      matInput
      type="text"
      [formControlName]="campaignDescriptionFieldName"
      autocomplete="off"
    />
    <mat-error *ngIf="infoForm.get(campaignDescriptionFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <wlm-hierarchy-element-tree-family-filter
    *ngIf="filterSettings"
    class="remove-filters-background"
    [settings]="filterSettings"
    (filtersChanged)="getDataBindingFilters($event)"
    (apply)="onApplyFilters(null)"
    (selectedNodesChange)="onSelectedNodesChanged($event)"
    (autoLoad)="onCheckAutoload()"
  ></wlm-hierarchy-element-tree-family-filter>

  <mat-radio-group [formControlName]="modeFieldName" class="radio-position">
    <mat-radio-button
      color="primary"
      *ngFor="let mode of calculationModes | keyvalue"
      [value]="mode.value"
      [checked]="mode.value === infoForm.controls[modeFieldName].value"
      (change)="onCalculationModesChange($event)"
    >
      {{ T_SCOPE + '.labels.' + mode.key | translate }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field appearance="outline" *ngIf="isAutomatic">
    <mat-label>{{ T_SCOPE + '.labels.reduction-component' | translate }}</mat-label>
    <mat-select [formControlName]="reductionComponentAutomaticFieldName">
      <mat-option
        *ngFor="let reductionComponent of reductionComponents"
        [value]="reductionComponent"
      >
        {{ reductionComponent.kpiLabel$ | async }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="infoForm.get(reductionComponentAutomaticFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="!isAutomatic">
    <mat-label>{{ T_SCOPE + '.labels.reduction-component' | translate }}</mat-label>
    <input
      matInput
      type="text"
      [formControlName]="reductionComponentManualFieldName"
      autocomplete="off"
    />
    <mat-error *ngIf="infoForm.get(reductionComponentManualFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ T_SCOPE + '.labels.baseline' | translate }}</mat-label>
    <input matInput type="number" [formControlName]="baseLineFieldName" autocomplete="off" />
    <mat-error *ngIf="infoForm.get(baseLineFieldName)?.errors?.required">{{
      'common.validation.required' | translate
    }}</mat-error>
  </mat-form-field>
</form>
