// prettier-ignore
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// prettier-ignore
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  CellTemplateDirective,
  ColumnBase,
  ColumnComponent,
  EditTemplateDirective,
  FilterMenuTemplateDirective,
  HeaderTemplateDirective,
} from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { Observable, of } from 'rxjs';
import { TooltipExtendedComponent } from '../../shared-component/tooltip-extended/tooltip-extended.component';
import { CommonSharedModule } from '../../shared/common-shared.module';
import { SettingsService } from '../../shared/config/settings.service';
import { ConstantsValues } from '../../shared/constants/constants-values';
import { ExtendedTooltipDimensions } from '../../shared/constants/dimensions.constants';
import { IGridSettings } from '../../shared/constants/grid.constants';
import { CellStyleService } from '../../shared/core/grid/cell-style.service';
import { ColumnEnumsService } from '../../shared/core/grid/column-enums.service';
import { GridColumnHelperService } from '../../shared/helpers/grid-column-helper.service';
import { GridHelperService } from '../../shared/helpers/grid-helper.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { GridColumnSetting } from '../../shared/model/grid/grid-column-setting';
import { ImageElement } from '../../shared/model/grid/image-element';
import { GridEditionService } from '../services/grid-edition.service';

const COMPONENT_SELECTOR = 'wlm-column';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './wlm-column.component.html',
  styleUrls: ['./wlm-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => WlmColumnComponent),
    },
  ],
})
export class WlmColumnComponent extends ColumnComponent implements OnInit, OnDestroy {
  @ViewChild(HeaderTemplateDirective) headerTemplate: HeaderTemplateDirective;
  @ViewChild(CellTemplateDirective) viewTemplate: CellTemplateDirective;
  @ViewChild(FilterMenuTemplateDirective) filterTemplate: FilterMenuTemplateDirective;
  @ViewChild(EditTemplateDirective) editViewTemplate: EditTemplateDirective;
  private _columnSettings: GridColumnSetting;

  @Input() public elements: Map<any, string>;
  @Input() public imageElements: Map<any, ImageElement>;
  @Input() gridSettings: IGridSettings;
  @Input() useCellStyle = false;
  @Input() backgroundColorMapping: Map<any, string>;
  @Input() formGroup: UntypedFormGroup;
  @Input() gridEditionService: GridEditionService;
  @Output() headerTextChanged = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<any>();

  private _comparisonIsActive: boolean;
  public get comparisonIsActive(): boolean {
    return this._comparisonIsActive;
  }
  @Input() public set comparisonIsActive(v: boolean) {
    this._comparisonIsActive = v;
    this._cd?.detectChanges();
  }

  elementsLoaded = new EventEmitter<void>();

  get comparisonField(): string {
    return this.field + 'Comp';
  }

  public get columnSettings(): GridColumnSetting {
    return this._columnSettings;
  }
  @Input() public set columnSettings(v: GridColumnSetting) {
    this._columnSettings = v;
    this.populateElements();
  }

  @Input() digitInfo: string;
  @Input() currentLocale: string;

  headerTooltip: string;
  isLongContent = false;

  private _gridHelperService: GridHelperService;
  private _columnEnumsService: ColumnEnumsService;
  private _cellStyleService: CellStyleService;
  private _settingsService: SettingsService;
  private _dialogService: MatDialog;
  private _gridColumnHelper: GridColumnHelperService;
  private _intl: IntlService;
  protected _cd: ChangeDetectorRef;
  private readonly _localization = inject(LocalizationHelperService);

  filterFormat = ConstantsValues.numericFiltersFormat;

  constructor(cd?: ChangeDetectorRef) {
    super();
    this._gridHelperService = CommonSharedModule.injector.get(GridHelperService);
    this._columnEnumsService = CommonSharedModule.injector.get(ColumnEnumsService);
    this._cellStyleService = CommonSharedModule.injector.get(CellStyleService);
    this._intl = CommonSharedModule.injector.get(IntlService);
    this._settingsService = CommonSharedModule.injector.get(SettingsService);
    this._dialogService = CommonSharedModule.injector.get(MatDialog);
    this._gridColumnHelper = CommonSharedModule.injector.get(GridColumnHelperService);

    this._cd = cd;

    Object.defineProperty(this, 'headerTemplateRef', {
      get() {
        return this.getHeaderTemplateRef();
      },
    });
    Object.defineProperty(this, 'templateRef', {
      get() {
        return this.getTemplateRef();
      },
    });
    Object.defineProperty(this, 'filterMenuTemplateRef', {
      get() {
        return this.getFilterMenuTemplateRef();
      },
    });
    Object.defineProperty(this, 'editTemplateRef', {
      get() {
        return this.getEditTemplateRef();
      },
    });
  }

  formatCell(value: any, type: string, format: any, isPercentage: boolean): string {
    if (type === 'numeric' && !this.columnSettings.disableFormat) {
      if (isPercentage === true) {
        const decimalPositions = this._settingsService.maxDecimalPositionsForGreatNumber;
        format = `.${decimalPositions}-${decimalPositions}`;
      }

      return this._localization.formatNumber(value, format);
    }

    this.isLongContent = value && value.length > 200; //this._settingsService.maxOverflowToShowExtendedTooltip;

    return value;
  }

  ngOnInit(): void {}

  private get superHeaderTemplates() {
    return super['headerTemplates'];
  }

  private get superTemplate() {
    return super['template'];
  }

  private get superFilterMenuTemplate() {
    return super['filterMenuTemplate'];
  }

  private get superEditTemplate() {
    return super['editTemplate'];
  }

  getHeaderTemplateRef(): TemplateRef<any> {
    const template =
      this.superHeaderTemplates &&
      this.superHeaderTemplates.find((x) => x.templateRef != undefined).templateRef;
    const headerTemplate = this.headerTemplate && this.headerTemplate.templateRef;
    return template
      ? this.superTemplate.templateRef
      : headerTemplate
      ? this.headerTemplate.templateRef
      : undefined;
  }

  getTemplateRef(): TemplateRef<any> {
    const template = this.superTemplate && this.superTemplate.templateRef;
    const viewTemplate = this.viewTemplate && this.viewTemplate.templateRef;
    return template
      ? this.superTemplate.templateRef
      : viewTemplate
      ? this.viewTemplate.templateRef
      : undefined;
  }

  getFilterMenuTemplateRef(): TemplateRef<any> {
    // TODO: Check that first if has sense, as it is checking super.filterMenuTemplate but returning super.template.
    const template = this.superFilterMenuTemplate && this.superFilterMenuTemplate.templateRef;
    const filterTemplate = this.filterTemplate && this.filterTemplate.templateRef;
    return template
      ? this.superTemplate.templateRef
      : filterTemplate
      ? this.filterTemplate.templateRef
      : undefined;
  }

  getEditTemplateRef(): TemplateRef<any> {
    const template = this.superEditTemplate && this.superEditTemplate.templateRef;
    const editViewTemplate = this.editViewTemplate && this.editViewTemplate.templateRef;
    return template
      ? this.superTemplate.templateRef
      : editViewTemplate
      ? this.editViewTemplate.templateRef
      : undefined;
  }

  getBackgroundColorMapping(dataItem: any) {
    if (
      this.gridSettings?.backgroundColorMethodName &&
      this.gridSettings?.backgroundColorKeyField &&
      this.useCellStyle
    ) {
      return this.backgroundColorMapping.get(dataItem[this.gridSettings?.backgroundColorKeyField]);
    } else {
      return 'transparent';
    }
  }

  getFontWeight(dataItem: any): Observable<string> {
    if (this.gridSettings?.fontWeightMethodName) {
      return this._gridHelperService.getFontWeight(
        this.gridSettings?.fontWeightMethodName,
        dataItem
      );
    }
    const fontWeight = 'normal';
    return of(fontWeight);
  }

  getStyleValue(style: any, key: string): string {
    if (style === undefined || key === undefined) {
      return;
    }
    return (style as Map<string, string>).get(key);
  }

  onEditionChange(value: any) {
    this.valueChange.emit(value);
  }

  protected populateElements() {
    if (this.columnSettings?.enumerableKey) {
      this._columnEnumsService
        .getEnum(this.columnSettings.enumerableKey)
        .pipe(untilDestroyed(this, 'ngOnDestroy'))
        .subscribe({
          next: (elements) => {
            this.elements = elements;
            this.getChangeDetector()?.detectChanges();
          },
        });
    } else if (this.columnSettings?.type === 'boolean') {
      this._columnEnumsService
        .getEnum(this.columnSettings?.enumerableKey ?? 'getBooleanColumnDefaultMapping')
        .pipe(untilDestroyed(this, 'ngOnDestroy'))
        .subscribe({
          next: (elements) => {
            this.elements = elements;
            this.getChangeDetector()?.detectChanges();
          },
        });
    } else if (this.columnSettings?.type === 'image') {
      this._columnEnumsService
        .getImageEnum(this.columnSettings.getImageMethodName)
        .pipe(untilDestroyed(this, 'ngOnDestroy'))
        .subscribe({
          next: (elements) => {
            this.imageElements = elements;
            this.getChangeDetector()?.detectChanges();
          },
        });
    }
  }

  getValueStyle(dataItem: any): string {
    if (!this.columnSettings?.getStyleMethodName) {
      return '';
    }

    return this._cellStyleService.getStyle(this.columnSettings.getStyleMethodName, dataItem);
  }

  showTooltipExtended(dataItem: any) {
    let title;
    if (this.gridSettings.excelFileName === 'Attributes') {
      // this is a special grid for row detail view
      title = dataItem.notificationAttributeName;
    } else {
      title = this._gridColumnHelper.getColumnTranslatedTitle(this._columnSettings); // common grids
    }

    const text = dataItem[this.field];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { text, title };

    this._dialogService.open(TooltipExtendedComponent, {
      height: ExtendedTooltipDimensions.Height,
      width: ExtendedTooltipDimensions.Width,
      data: dialogConfig,
    });
  }

  ngOnDestroy(): void {}

  private getChangeDetector = (): ChangeDetectorRef => this._cd;
}
