<mat-expansion-panel
  class="widget-info-expansion-panel"
  hideToggle
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
>
  <mat-expansion-panel-header>
    <mat-panel-title *ngIf="formattedDateRange">
      {{ formattedDateRange }}
    </mat-panel-title>
    <mat-panel-description>
      <span *ngIf="!panelOpenState" class="expansion-panel-header-description-section">
        {{ formattedHierarchyNames }}</span
      >
    </mat-panel-description>
  </mat-expansion-panel-header>
  <span class="widget-info-content">{{ formattedHierarchyNames }}</span>
</mat-expansion-panel>
