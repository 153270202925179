import { Component, Inject, Injector, Input, OnInit, Optional, inject } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { IExportExcelComponent } from 'src/app/common-modules/shared/exports/models/export-excel-component';
import { ExportExcelSettings } from 'src/app/common-modules/shared/exports/models/export-excel-settings';
import { IExportPdfComponent } from 'src/app/common-modules/shared/exports/models/export-pdf-component';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { GenericChartsModuleSettings, WLM_CHART_SETTINGS } from '../../../generic-charts.module';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { GenericCartesianChartSettings } from '../../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericChartSettings } from '../../models/generic-chart-settings/generic-chart-settings';
import { TrendChartDataParameters } from '../../models/trend-chart-data-parameters';
import { TrendChartSettings } from '../../models/trend-chart-settings';
import { BaseCartesianChartService } from '../../services/base-cartesian-chart.service';
import { GenericChartService } from '../../services/generic-chart.service';

const COMPONENT_SELECTOR = 'wlm-trend-chart';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.scss'],
  providers: [GenericChartService],
})
export class TrendChartComponent
  extends BaseChartComponent
  implements OnInit, IExportExcelComponent, IExportPdfComponent
{
  @Input() public disableInnerLoading = true;
  @Input() size: IElementSize;

  private _chartSettings: TrendChartSettings;
  public get chartSettings(): TrendChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(v: TrendChartSettings) {
    this._chartSettings = v;

    if (this.chartSettings) {
      this.initializeChart();
      this.loadChart();
    }
  }

  @Input() avoidConversions = false;

  private _dataService: BaseCartesianChartService;

  genericChartSettingsSubscription: Subscription;

  private readonly _dialogService: DialogService = inject(DialogService);
  constructor(
    _injector: Injector,
    @Optional()
    @Inject(WLM_CHART_SETTINGS)
    private _externalSettings: GenericChartsModuleSettings
  ) {
    super(_injector);
  }

  ngOnInit(): void {}

  protected getSerieData(params: TrendChartDataParameters): Observable<any[]> {
    return this._dataService.getData(params);
  }

  public getGenericChartSettings(
    dataParameters?: TrendChartDataParameters
  ): Observable<GenericChartSettings> {
    return this.getSerieData(dataParameters ?? this.chartSettings.dataParameters).pipe(
      switchMap((data) => {
        return this._dataService.mapDataToGenericSettings(data);
      }),
      switchMap((settings: GenericCartesianChartSettings) => {
        if (this._externalSettings?.avoidConversion) {
          //todo: new genericChartService
          return this.genericChartService.getNoConversionCartesianChart(settings);
        } else {
          return this.genericChartService.getDefaultCartesianChart(
            settings as GenericCartesianChartSettings
          );
        }
      })
    );
  }

  public setDateParams(startDate: Date, endDate: Date, dataParameters?: TrendChartDataParameters) {
    this.setDateRange(startDate, endDate);
    this.resetChart();
    this.loadChart(dataParameters ?? this.chartSettings.dataParameters);
  }

  public getParams(): TrendChartDataParameters {
    return this.chartSettings.dataParameters;
  }
  public setParams(newParams: TrendChartDataParameters) {
    this.chartSettings.dataParameters = newParams;
  }

  private instanceDataService() {
    this._dataService = this._injector.get(this.chartSettings?.dataParameters?.dataService);
    this._dataService.setGenericChartService(this.genericChartService);
  }

  private initializeChart() {
    this.resetChart();
    this.instanceDataService();
  }

  private resetChart() {
    this.genericChartSettingsSubscription?.unsubscribe();
  }

  private loadChart(dataParameters?: TrendChartDataParameters) {
    this.setLoading(true);
    this.genericChartSettingsSubscription = this.getGenericChartSettings(dataParameters).subscribe({
      next: (settings: GenericCartesianChartSettings) => {
        let hasValues = false;

        if (settings) {
          this.setExportTitle(settings);
          hasValues = asEnumerable(settings.series ?? [])
            .SelectMany((x) => x?.dataPoints)
            .Any();
          this.genericChartSettings = hasValues ? settings : null;
        }

        if (!hasValues || !settings) {
          this.chartLoaded.next(true);
        }

        this.setLoading(false);
      },
      error: (error) => {
        this.setLoading(false);
        this.genericChartSettings = null;
        this.chartLoaded.next(true);
        this._dialogService.showErrorMessage(error);
      },
    });
  }

  private setExportTitle(settings: GenericChartSettings) {
    if (settings) {
      settings.exportFileName = this._chartSettings?.exportFileName;
    }
  }

  getExportExcelSettings(): Observable<ExportExcelSettings> {
    return this.genericChart.getExportExcelSettings();
  }

  exportToPdf(): void {
    this.genericChart.exportToPdf();
  }

  isEmpty(): boolean {
    return !this.dataLoaded;
  }

  getObjectToExport() {
    this.genericChart.getObjectToExport();
  }
}
