import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { CommentDto } from '../../models/comment.dto';

const COMPONENT_SELECTOR = 'wlm-comment-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent implements OnInit {
  @Input() comment: CommentDto;
  @Input() public authorName: string;
  T_SCOPE = `${AppModules.Comments}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {}
}
