<div id="center-page">
  <div class="top-container" *ngIf="dvNavGridSetting">
    <h3>{{ titleTranslationKey | translate }}</h3>
    <div class="filter-buttons-container">
      <button
        mat-icon-button
        class="button-position mat-icon-no-overlay"
        [disabled]="!alarmsGrid?.canClearFilters()"
        (click)="alarmsGrid?.clearFilters()"
        [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
      >
        <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
      </button>
    </div>
  </div>
  <hr class="separator" />

  <div id="center-container">
    <wlm-generic-grid
      class="alarms-navigation-grid"
      *ngIf="gridFiltersForBinding"
      [gridSettings]="dvNavGridSetting"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [(selectedItems)]="selectedAlarms"
      [subscriptionTag]="dvNavGridSettingsName"
      [showSelectedList]="true"
      [showSelectedItems]="false"
      [usePersistence]="useGridPersistence"
    ></wlm-generic-grid>
    <div class="buttons-container">
      <div [matTooltip]="navigateTooltip | translate: { maxZones: maxZones } | lowercase">
        <button
          mat-raised-button
          color="primary"
          (click)="navigate()"
          [disabled]="!selectedAlarms?.length || maxItemsExceeded"
        >
          {{ navigateBtnTranslationKey | translate }}
        </button>
      </div>

      <button mat-raised-button (click)="cancel()">
        {{ 'common.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
