export class UnitTypeConversionViewDto {
  hierarchyElementTypeId?: string;
  timeAggregationId?: number;
  isDefaultFromUnit: boolean;
  dimensionTypeId: number;
  dimensionTypeDescription: string;
  conversionFactor: number;
  unitTypeFromId: number;
  unitTypeFromDescription: string;
  unitTypeToId: number;
  unitTypeToDescription: string;

  constructor(init: UnitTypeConversionViewDto) {
    Object.assign(this, init);
  }
}
