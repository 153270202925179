import { ComponentRef, Injectable, Type } from '@angular/core';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  GLEventParamsMap,
  IGoldenLayout,
  IGoldenLayoutComponent,
  NativeGLComponentItemConfig,
  NativeGLItemConfigType,
  NativeGLRenderedComponentNode,
  NativeGoldenLayoutConfig,
} from '../../golden-layout-host/golden-layout-exports';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '../../redux/models/state-widget-settings';
import {
  TravelConfigAllTypesData,
  TravelConfigData,
} from '../../right-panel/tab-panel/gl-detail-panel/gl-travel-config-data';
import { SettingsService } from '../../shared/config/settings.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';

import { GoldenLayoutComponentService } from '../../golden-layout-host/golden-layout-component.service';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { DynamicLayoutItemSettings } from '../models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { DynamicLayoutStructure } from '../models/dynamic-layout-structure';
import { GoldenLayoutNode } from '../models/golden-layout-node';
import { LayoutNodeTypes } from '../models/layout-node-types';
import { nativeLayoutHeaderSizes } from '../models/native-layout-header-sizes';
import { GoldenLayoutHelperService } from './golden-layout-helper.service';

@Injectable()
export class GoldenLayoutService {
  constructor(
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _settingsService: SettingsService,
    private readonly _glHelperService: GoldenLayoutHelperService,
    private readonly _goldenLayoutComponentService: GoldenLayoutComponentService
  ) {}

  /**
   * Make components available in the GL Registry, so they can be instantiated.
   */
  registerComponents(widgetsHash: Map<string, Type<BaseDynamicWidgetComponent>>): void {
    Array.from(widgetsHash.keys()).forEach((widgetInstanceKey) => {
      // Components must be registered in GL to be used.
      this.registerWidget({
        name: widgetInstanceKey,
        type: widgetsHash.get(widgetInstanceKey),
      });
    });
  }

  registerWidget(widget: { name: string; type: Type<BaseDynamicWidgetComponent> }): void {
    this._goldenLayoutComponentService.registerComponentType(widget.name, widget.type);
  }

  getGLInstance(glComponent: IGoldenLayoutComponent): IGoldenLayout {
    let settings;
    if (!glComponent) {
      settings = null;
    } else {
      try {
        settings = glComponent.getGoldenLayoutInstance() ?? null;
      } catch (error) {
        return null;
      }
    }
    return settings;
  }

  getGLInstanceRoot(glComponent: IGoldenLayoutComponent): GoldenLayoutNode {
    const instance = this.getGLInstance(glComponent);
    return instance?.rootItem as unknown as GoldenLayoutNode;
  }

  getGLInstanceConfig(glComponent: IGoldenLayoutComponent): any {
    try {
      const instance = this.getGLInstance(glComponent);
      const config = instance?.saveLayout() ?? null;
      return config;
    } catch (error) {
      return null;
    }
  }

  getNodeContent(node): any[] {
    return node?.root ? [node.root] : node?.content;
  }

  getNodeContentKey(glConfig): string {
    return glConfig.root ? 'root' : 'content';
  }

  getComponentRenderedNode(node): NativeGLRenderedComponentNode {
    const result = {
      state: node.container.state,
      instanceId: node.componentType,
    };
    return result;
  }

  /**
   * When no structure is defined, we generate all the components in a single root stack.
   */
  buildGlStackedConfig(settings: DynamicLayoutSettings): NativeGoldenLayoutConfig {
    const componentsContent = settings.items.map((settingItem) => {
      return this.buildNativeComponent(settingItem, settings);
    });

    const glContent: any = [
      {
        type: LayoutNodeTypes.Stack,
        content: componentsContent,
      },
    ];

    const glConfig = this.buildGLConfig(settings, glContent);
    return glConfig;
  }

  buildNativeGoldenLayoutConfig(
    settings: Partial<NativeGoldenLayoutConfig>
  ): NativeGoldenLayoutConfig {
    // In v2, we have root instead of content.
    if (settings.content && !settings.root) {
      if (settings.content.length === 1) {
        settings.root = globalUtilsHelper.clone(settings.content[0], true);
      } else {
        settings.root = globalUtilsHelper.clone(this.wrapContentInRoot(settings.content), true);
      }
      delete settings.content;
    }

    if ((settings as any).selectionEnabled) {
      (settings as any).selectionEnabled = undefined;
    }

    return settings as NativeGoldenLayoutConfig;
  }

  private wrapContentInRoot(content: any) {
    let root;
    if (!content.length) {
      root = content;
    } else if ((content as any)._isRoot) {
      root = content;
    } else {
      root = {
        type: LayoutNodeTypes.Row,
        content,
        _isRoot: true,
      } as any;
    }
    return root;
  }

  buildNativeGLItemConfigType(item: NativeGLItemConfigType): NativeGLItemConfigType {
    if (item.type === 'component') {
      const componentItem: any = item;
      // In v2 they just changed componentName to componentType, so we set both.
      componentItem.componentType = componentItem.componentType ?? componentItem.componentName;
      componentItem.componentName = componentItem.componentName ?? componentItem.componentType;
    }

    this.toggleMaximizeContentItem(true, item as any);

    return item as NativeGLItemConfigType;
  }

  toggleMaximizeContentItem(canMaximize: boolean, contentItem: NativeGLComponentItemConfig): void {
    this.toggleV2ContentItemHeader(contentItem, 'maximize', canMaximize);
  }

  private toggleV2ContentItemHeader(
    contentItem: NativeGLComponentItemConfig,
    fieldName: string,
    value
  ): void {
    if (!contentItem['header']) {
      contentItem['header'] = {};
    }
    contentItem['header'][fieldName] = value;
  }

  togglePopoutContentItem(canPopout: boolean, contentItem: NativeGLComponentItemConfig): void {
    this.toggleV2ContentItemHeader(contentItem, 'popout', canPopout);
  }

  buildGLConfig(
    settings: DynamicLayoutSettings,
    content: NativeGLItemConfigType[]
  ): NativeGoldenLayoutConfig {
    const {
      showPopoutIcon,
      showMaximiseIcon,
      showCloseIcon,
      minItemHeight,
      minItemWidth,
      hasHeaders,
      disableReorder,
    } = settings;
    const result: NativeGoldenLayoutConfig = this.buildNativeGoldenLayoutConfig({
      settings: {
        showPopoutIcon,
        showMaximiseIcon,
        showCloseIcon,
        hasHeaders,
        reorderEnabled: !disableReorder,
      },
      dimensions: {
        minItemHeight,
        minItemWidth,
        defaultMinItemWidth: String(minItemWidth) + 'px',
        borderWidth: 8,
      },
      labels: {
        close: settings.labelClose || 'close',
        maximise: settings.labelMaximise || 'maximise',
        minimise: settings.labelMinimise || 'minimise',
        popout: settings.labelPopout || 'open in new window',
      },
      content: content as any, // This is changed to root if gl version is v2
    });

    return result;
  }

  buildNativeComponent(
    settingItem: DynamicLayoutItemSettings,
    settings: DynamicLayoutSettings
  ): NativeGLItemConfigType {
    const { title, baseTitle, scopeInstanceKeys, widgetInstanceKey, targetInstances, params } =
      settingItem;

    const widgetSettings = new StateWidgetSettings({
      module: settings.widgetModule,
      page: settings.widgetPage,
      scopeInstanceKeys,
      widgetInstanceKey,
      targetInstances,
      params: this._objectHelperService.clone(params),
      hasDefaultFilters: settingItem.hasDefaultFilters,
      itemSettings: this._objectHelperService.clone(settingItem),
      baseTitle,
      title,
    });

    const reorderEnabled = this._glHelperService.getIsComponentReorderEnabled(
      settingItem.widgetInstanceKey,
      settings
    );

    const result: NativeGLItemConfigType = this.buildNativeGLItemConfigType({
      type: 'component',
      componentName: widgetInstanceKey,
      componentType: widgetInstanceKey,
      title,
      componentState: widgetSettings,
      reorderEnabled,
      // this must always be true, or else we cannot drag an single item outside inside a non-original stack.
      // to hide close icons, use showStackCloseIcon.
      // if isClosable is true, somehow popup icons still appear even if header.popout is close.
      isClosable: true,
    });

    return result;
  }

  getClosableComponentFlag(value: boolean): boolean {
    return undefined;
  }

  buildDynamicComponent(settingItem: DynamicLayoutItemSettings): DynamicLayoutStructure {
    const node: DynamicLayoutStructure = {
      type: LayoutNodeTypes.Component,
      widgetInstanceKey: settingItem.widgetInstanceKey,
    };

    return node;
  }

  /**
   * Take an array of components and resolve their inner instance promises to return the actual component objects.
   */
  resolveInstances<T>(components: ComponentRef<T>[] | GoldenLayoutNode[]): Observable<any[]> {
    const instances = components.map((item) => item.component);
    return of(instances);
  }

  /**
   * When the settings are restored, we still need to update the titles recursively to adapt to the new language.
   * Updates props by reference.
   */
  updateTranslations(
    glConfig: NativeGoldenLayoutConfig,
    itemSettings: DynamicLayoutItemSettings[]
  ): void {
    const fn = (data: TravelConfigData) => {
      const currentItem = itemSettings.find((itemSetting) => {
        return this.getWidgetInstanceKey(data) === itemSetting.widgetInstanceKey;
      });

      if (currentItem) {
        data.contentItem.title = currentItem.title;
      }
    };
    this.travelConfig(glConfig, fn);
  }

  /**
   * When settings are recovered from LS, we must remove all components that are not currently available.
   */
  removeUnusedCompoments(
    glConfig: NativeGoldenLayoutConfig,
    itemSettings: DynamicLayoutItemSettings[]
  ): void {
    const widgetInstanceKeys = itemSettings.map((item) => item.widgetInstanceKey);

    const fn = (data: TravelConfigData) => {
      const found = widgetInstanceKeys.find(
        (widgetInstanceKey) => widgetInstanceKey === this.getWidgetInstanceKey(data)
      );
      if (!found) {
        data.parent.splice(data.contentIndex, 1); // Delete by reference.
      }
    };
    this.travelConfig(glConfig, fn);
  }

  countConfiguredComponents(glConfig: NativeGoldenLayoutConfig): number {
    let count = 0;
    const fn = (_) => count++;
    this.travelConfig(glConfig, fn);
    return count;
  }

  getActiveComponents(node): any[] {
    let results = this.getActiveComponentsRecursive(node);
    results = results.filter(Boolean);
    return results; // types are lm.items.Component[] / ContentItem[]
  }

  getAllComponents(node): any[] {
    let results = this.getAllComponentsRecursive(node);
    results = results.filter(Boolean);
    return results;
  }

  getAllComponentNodes(node: GoldenLayoutNode): GoldenLayoutNode[] {
    let results = this.getAllComponentNodesRecursive(node);
    results = results.filter(Boolean);
    return results;
  }

  stateChanged$(glRoot: IGoldenLayoutComponent, debounceTimeMs = 300): Observable<any> {
    return this.listenEvent$('stateChanged', glRoot, debounceTimeMs);
  }

  itemDestroyed$(glRoot: IGoldenLayoutComponent, debounceTimeMs = 0): Observable<any> {
    return this.listenEvent$('itemDestroyed', glRoot, debounceTimeMs);
  }

  listenEvent$(
    eventName: keyof GLEventParamsMap,
    glRoot: IGoldenLayoutComponent,
    debounceTimeMs = 300,
    subject?: Subject<any>
  ): Observable<any> {
    const glInstance = this.getGLInstance(glRoot);
    const event$ = subject ?? new ReplaySubject(1);
    glInstance.on(eventName, (event) => {
      event$.next(event);
    });
    return event$.asObservable().pipe(debounceTime(debounceTimeMs));
  }

  /**
   * Check if the current GL config matches the currently declared components.
   * If not, the settings must be rebuilt from scratch.
   * This new version uses widgetInstanceKey ids instead of titles, which is more secure.
   */
  checkConfigConsistency(
    glConfig: NativeGoldenLayoutConfig,
    items: DynamicLayoutItemSettings[],
    areSameArrays: (a: string[], b: string[]) => boolean
  ): boolean {
    const currentInstances = [];
    this.travelConfig(glConfig, (data: TravelConfigData) => {
      const settings: StateWidgetSettings = data.contentItem.componentState as any;
      currentInstances.push(settings.widgetInstanceKey);
    });

    const requiredInstances = items.map((item) => item.widgetInstanceKey);
    const isConsistent = areSameArrays(currentInstances, requiredInstances);
    if (!isConsistent) {
      if (this._settingsService.log) {
        console.error(
          `The persisted layout ${currentInstances} is not consistent with ${requiredInstances}, so the layout is reset.`
        );
      }
    }
    return isConsistent;
  }

  /**
   * Layouts have a tree structure, so we obtain all active components recursively.
   */
  private getActiveComponentsRecursive(node: GoldenLayoutNode): any[] {
    if (!node) {
      return [];
    }
    const { contentItems, activeContentItem$ } = node;
    if (activeContentItem$) {
      // V1
      return activeContentItem$ ? [activeContentItem$?.value] : [];
    }
    if (node.getActiveComponentItem) {
      // V2
      const componentItem = node.getActiveComponentItem();
      return componentItem ? [componentItem] : [];
    }
    return contentItems.reduce((result, item) => {
      result = result.concat(this.getActiveComponentsRecursive(item));
      return result;
    }, []);
  }

  private getAllComponentsRecursive(node: GoldenLayoutNode): any[] {
    return this.getAllComponentNodesRecursive(node).map((x) => x.contentItems);
  }

  private getAllComponentNodesRecursive(node: GoldenLayoutNode): GoldenLayoutNode[] {
    if (!node) {
      return [];
    }

    if (node.type === 'component') {
      return [node];
    }
    if (node.contentItems) {
      return node.contentItems.reduce((result, item) => {
        result = result.concat(this.getAllComponentNodesRecursive(item));
        return result;
      }, []);
    }

    return [];
  }

  fromNativeToDynamic(
    glConfig: NativeGoldenLayoutConfig,
    settings: DynamicLayoutSettings
  ): DynamicLayoutSettings {
    let dynamicNodes = [];
    // We must access to the content of the first element
    const nativeNodes = this.getNodeContent(glConfig);
    const dynamicSettings = this._objectHelperService.serializedClone(settings);

    if (nativeNodes) {
      if (!nativeNodes.length) {
        dynamicSettings.structure = [];
        return dynamicSettings;
      }

      if (nativeNodes.length !== 1) {
        throw new Error('There must always be only one root node.');
      }

      dynamicNodes = nativeNodes.map((nativeNode) => this.fromNativeToDynamicNode(nativeNode));
      let nativeRootNode = nativeNodes[0];
      if (nativeRootNode.type === LayoutNodeTypes.Stack) {
        nativeRootNode = this.amendStack(nativeRootNode);
      }

      this.fromNativeToDynamicRecursive(
        this.getNodeContent(nativeRootNode),
        this.getNodeContent(dynamicNodes[0])
      );
    }

    dynamicSettings.structure = dynamicNodes;
    return dynamicSettings;
  }

  private fromNativeToDynamicRecursive(
    nativeNodes: NativeGLItemConfigType[],
    dynamicNodes: DynamicLayoutStructure[]
  ): void {
    nativeNodes.forEach((nativeNode: NativeGLItemConfigType) => {
      const dynamicNode = this.fromNativeToDynamicNode(nativeNode);

      dynamicNodes.push(dynamicNode);

      const nativeNodeContent = this.getNodeContent(nativeNode);
      if (nativeNodeContent) {
        this.fromNativeToDynamicRecursive(nativeNodeContent, this.getNodeContent(dynamicNode));
      }
    });
  }

  private fromNativeToDynamicNode(nativeNode: NativeGLItemConfigType): DynamicLayoutStructure {
    const dynamicNode: any = {
      type: nativeNode.type as any,
    };

    this.setNativeToDynamicNodeProperties(nativeNode, dynamicNode);

    const instanceKey = this.getComponentInstanceId(nativeNode as any);
    if (instanceKey) {
      dynamicNode['widgetInstanceKey'] = instanceKey;
    }

    if (nativeNode.type !== LayoutNodeTypes.Component && this.getNodeContentKey(nativeNode)) {
      const nativeNodeContentKey = this.getNodeContentKey(nativeNode);
      dynamicNode[nativeNodeContentKey] = [];
    }

    return dynamicNode;
  }

  setNativeToDynamicNodeProperties(
    nativeNode: NativeGLItemConfigType,
    dynamicNode: DynamicLayoutStructure
  ): void {
    this.setNodeProperties(nativeNode, dynamicNode);
  }

  setDynamicToNativeNodeProperties(
    dynamicNode: DynamicLayoutStructure,
    nativeNode: NativeGLItemConfigType
  ): void {
    this.setNodeProperties(dynamicNode, nativeNode);
  }

  private setNodeProperties(sourceNode, targetNode): void {
    const sharedProps = ['activeItemIndex'];
    const v1Props = ['height', 'width'];
    const v2Props = ['size', 'sizeUnit']; // only for > 2.6.0, not 2.4.0
    const propsToCopy = sharedProps.concat(v1Props).concat(v2Props);
    propsToCopy.forEach((prop) => this.setIfDefined(sourceNode, targetNode, prop));

    // Fix specific error in which native GLV2 is giving size as a number instead of a string with units.
    let size: any = targetNode.size;
    if (size !== null && typeof size === 'number') {
      if (targetNode.sizeUnit) {
        size = `${size}${targetNode.sizeUnit}`;
        targetNode.size = size;
      }
    }
  }

  private setIfDefined(source, target, propertyName: string): void {
    if (typeof source[propertyName] !== 'undefined') {
      target[propertyName] = globalUtilsHelper.clone(source[propertyName], true);
    }
  }

  private isGoldenLayoutConfig(settings): boolean {
    return typeof this.getNodeContent(settings) !== 'undefined';
  }

  amendStack(node): any {
    if (!node) {
      return node;
    }
    if (typeof this.getNodeContent(node) === 'undefined') {
      const contentKey = this.getNodeContentKey(node);
      node[contentKey] = [];
    }
    return node;
  }

  buildDynamicStackedStructure(settings: DynamicLayoutSettings): DynamicLayoutStructure[] {
    const componentsStructure = settings.items.map(this.buildDynamicComponent);

    const structure: DynamicLayoutStructure[] = [
      {
        type: LayoutNodeTypes.Stack,
        content: componentsStructure,
      },
    ];

    return structure;
  }

  /**
   * From dynamic settings, attempts to build a native (GL) configuration.
   * If the main one (structure) is incorrect or not defined, attempt to build it from the default structure of the settings.
   * If the default structure of the settings (defaultStructure) is not valid too, finally resort to a basic, single stack layout.
   */
  buildNativeConfig(settings: DynamicLayoutSettings): NativeGoldenLayoutConfig {
    // For the specific case that the settings were already translated to GL.
    if (this.isGoldenLayoutConfig(settings)) {
      return settings as any;
    }

    let nativeConfig;

    try {
      if (settings.structure?.length > 0) {
        nativeConfig = this.fromDynamicToNative(settings.structure, settings);
      } else {
        return this.buildNativeConfigFromDefault(settings);
      }
    } catch (exception) {
      return this.buildNativeConfigFromDefault(settings);
    }

    if (!nativeConfig) {
      return this.buildNativeConfigFromDefault(settings);
    }

    return nativeConfig;
  }

  buildNativeConfigFromDefault(settings: DynamicLayoutSettings): NativeGoldenLayoutConfig {
    let nativeConfig;
    try {
      if (settings.defaultStructure?.length > 0) {
        nativeConfig = this.fromDynamicToNative(settings.defaultStructure, settings, true);
      } else {
        nativeConfig = this.buildGlStackedConfig(settings);
      }
    } catch (exception) {
      console.error(
        'Due to the previous error, the layout was reverted to the default stack layout.'
      );
      nativeConfig = this.buildGlStackedConfig(settings);
    }

    return nativeConfig;
  }

  /**
   * Converts an initial Golden Layout (native) structure to the structure defined by the (dynamic) structure items.
   * If no structure is defined, default to a stacked config.
   * @param structure The dynamic structure.
   * @param initialConfig The initial, native structure. Components are already instantiated inside.
   * @param settings
   * @returns
   */
  fromDynamicToNative(
    dynamicNodes: DynamicLayoutStructure[],
    settings: DynamicLayoutSettings,
    showStackedOnError = false
  ): NativeGoldenLayoutConfig {
    let nativeNodes: NativeGLItemConfigType[] = [];
    if (!dynamicNodes || dynamicNodes.length === 0) {
      const fullConfig = this.buildGLConfig(settings, nativeNodes);
      return fullConfig;
    }

    try {
      if (dynamicNodes.length !== 1) {
        throw new Error('There must always be only one root node.');
      }

      nativeNodes = dynamicNodes.map((dynamicNode) =>
        this.fromDynamicToNativeNode(dynamicNode, settings)
      );

      let dynamicRootNode: any = dynamicNodes[0];
      if (dynamicRootNode.type === LayoutNodeTypes.Stack) {
        dynamicRootNode = this.amendStack(dynamicRootNode);
      }

      this.fromDynamicToNativeRecursive(
        this.getNodeContent(dynamicRootNode),
        this.getNodeContent(nativeNodes[0]),
        settings
      );

      const fullConfig = this.buildGLConfig(settings, nativeNodes);
      return fullConfig;
    } catch (exception) {
      console.error(exception);

      if (showStackedOnError) {
        // If any unexpected error is found in the layout, give at least the default stacked layout.
        console.error(
          'Due to the previous error, the layout was reverted to the default stack layout.'
        );
        const defaultConfig = this.buildGlStackedConfig(settings);
        return defaultConfig;
      }
    }
  }

  private fromDynamicToNativeRecursive(
    dynamicNodes: DynamicLayoutStructure[],
    nativeNodes: NativeGLItemConfigType[],
    settings: DynamicLayoutSettings
  ): void {
    dynamicNodes?.forEach((dynamicNode: DynamicLayoutStructure) => {
      const nativeNode = this.fromDynamicToNativeNode(dynamicNode, settings);

      nativeNodes.push(nativeNode);

      const nativeNodeContent = this.getNodeContent(nativeNode);
      if (nativeNodeContent) {
        this.fromDynamicToNativeRecursive(
          this.getNodeContent(dynamicNode),
          nativeNodeContent,
          settings
        );
      }
    });
  }

  private fromDynamicToNativeNode(
    dynamicNode: DynamicLayoutStructure,
    settings: DynamicLayoutSettings
  ): NativeGLItemConfigType {
    let nativeNode;

    if (dynamicNode.type === LayoutNodeTypes.Component) {
      const item = settings.items.find(
        (item) => item.widgetInstanceKey === dynamicNode.widgetInstanceKey
      );
      if (!item) {
        console.error({
          settings,
          dynamicNode,
        });
        throw new Error(
          `The widgets structure makes a reference to a widget ${dynamicNode.widgetInstanceKey} which is not found in the definition.`
        );
      }
      nativeNode = this.buildNativeComponent(item, settings);
    } else {
      // The case for rows, columns and stacks.
      nativeNode = {
        ...dynamicNode,
        content: [],
        isClosable: this.getClosableComponentFlag(settings.showCloseIcon),
        reorderEnabled: settings.disableReorder ? false : true,
      };

      if (dynamicNode.type === LayoutNodeTypes.Stack) {
        nativeNode.isClosable = this.getClosableComponentFlag(settings.showStackCloseIcon);
      }

      this.setDynamicToNativeNodeProperties(dynamicNode, nativeNode);
    }

    return nativeNode;
  }

  /**
   * Travels the settings tree in order to apply a function to each leaf node.
   */
  travelConfig(
    glConfig: NativeGoldenLayoutConfig,
    applyFn: (data: TravelConfigData) => void
  ): void {
    const content = this.getNodeContent(glConfig);
    if (content) {
      this.travelConfigRecursive(content, applyFn);
    }
  }

  travelConfigAllTypes(
    glConfig: NativeGoldenLayoutConfig,
    applyFn: (data: TravelConfigAllTypesData) => void
  ): void {
    const content = this.getNodeContent(glConfig);
    if (content) {
      this.travelConfigAllTypesRecursive(content, applyFn);
    }
  }

  getComponentInstanceId(contentItem: NativeGLComponentItemConfig): string {
    if (contentItem.type === 'component') {
      const componentItem: any = contentItem;
      return componentItem?.componentName ?? componentItem?.componentType;
    }
    return null;
  }

  private travelConfigRecursive(
    content: NativeGLItemConfigType[],
    applyFn: (data: TravelConfigData) => void
  ): void {
    content.forEach((contentItem: NativeGLItemConfigType, contentIndex: number) => {
      const content = this.getNodeContent(contentItem);
      if (typeof content !== 'undefined') {
        this.travelConfigRecursive(content, applyFn);
      } else {
        const componentName = this.getComponentInstanceId(
          contentItem as NativeGLComponentItemConfig
        );
        if (typeof componentName !== 'undefined') {
          // Important: all these variables must be passed as reference, so they can be updated.
          applyFn({
            componentName,
            contentItem: contentItem as NativeGLComponentItemConfig,
            contentIndex,
            parent: content,
          });
        }
      }
    });
  }

  private travelConfigAllTypesRecursive(
    content: NativeGLItemConfigType[],
    applyFn: (data: TravelConfigAllTypesData) => void
  ): void {
    content.forEach((contentItem: NativeGLItemConfigType, contentIndex: number) => {
      applyFn({
        contentItem,
        contentIndex,
        parent: content,
      });

      const contentItemContent = this.getNodeContent(contentItem);
      if (typeof contentItemContent !== 'undefined') {
        this.travelConfigAllTypesRecursive(contentItemContent, applyFn);
      }
    });
  }

  removeEmptyStacks(content: NativeGLItemConfigType[]) {
    const contentToRemove: NativeGLItemConfigType[] = [];
    content?.forEach((contentItem: NativeGLItemConfigType, contentIndex: number) => {
      const innerContent = this.getNodeContent(contentItem);
      if (contentItem.type == 'stack' && !innerContent?.length) {
        contentToRemove.push(contentItem);
      } else if (innerContent?.length) {
        const content = innerContent;
        this.removeEmptyStacks(content);
      }
    });

    content = content.filter((x) => !contentToRemove.includes(x));
  }

  /**
   * Ensures that some fixed settings that would break the theme if they were modified have their correct values.
   */
  ensureStyles(config: NativeGoldenLayoutConfig, currentLayoutLevel: number = 1): void {
    if (config) {
      if (!config.dimensions) {
        config.dimensions = {};
      }

      config.dimensions.headerHeight = nativeLayoutHeaderSizes.get(currentLayoutLevel);

      if (!config.settings) {
        config.settings = {};
      }
      config.settings.showMaximiseIcon = true;
    }
  }

  private getWidgetInstanceKey = (node: TravelConfigData): string =>
    (node.contentItem.componentState as StateWidgetSettings).widgetInstanceKey;
}
