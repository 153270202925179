import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BaseFilterCellComponent,
  FilterService,
  PopupCloseEvent,
  SinglePopupService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ConstantsValues } from '../../shared/constants/constants-values';
import { SharedConstantsService } from '../../shared/constants/shared-constants.service';

const closest = (node: any, predicate: any): any => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

const COMPONENT_SELECTOR = 'wlm-selector-column-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './selector-column-filter.component.html',
  styleUrls: ['./selector-column-filter.component.scss'],
})
export class SelectorColumnFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() public isPrimitive: boolean;
  @Input() public set currentFilter(v: CompositeFilterDescriptor) {
    this._currentFilter = v;
  }

  @Input() public filterService: FilterService;
  @Input() public mainField: string;
  @Input() public secondaryField: string;

  @Output() public valueChange = new EventEmitter<number[]>();

  public get currentFilter(): CompositeFilterDescriptor {
    return this._currentFilter;
  }

  public get numTextBoxDisabled(): boolean {
    return this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull';
  }

  public popupSettings: any = {
    popupClass: 'wlm-selector-column-filter',
  };

  private _currentFilter: CompositeFilterDescriptor;

  filterOperators: Array<{ text: string; value: string }>;
  selectedOperator: string;
  selectedValue: number;
  filterFormat = ConstantsValues.numericFiltersFormat;

  constructor(
    private element: ElementRef,
    popupService: SinglePopupService,
    private _filterService: FilterService,
    sharedConstantsService: SharedConstantsService
  ) {
    super(_filterService);
    // Get translated filter operators
    sharedConstantsService
      .mapToArrayObservable(
        sharedConstantsService.getSelectorColumnDefaultMapping(),
        'value',
        'text'
      )
      .subscribe((array) => {
        this.filterOperators = array;
      });

    // Handle the service onClose event and prevent the menu from closing when the datepickers are still active.
    popupService.onClose.pipe(untilDestroyed(this)).subscribe((e: PopupCloseEvent) => {
      if (
        document.activeElement &&
        closest(
          document.activeElement,
          (node) =>
            node === this.element.nativeElement ||
            String(node.className).indexOf(this.popupSettings) >= 0
        )
      ) {
        e.preventDefault();
      }
    });
  }

  public onValueChange(value: any): void {
    const filterToApply =
      this.numTextBoxDisabled || value === undefined || value === null
        ? this.removeFilters() // remove the filter
        : this.generateCompositeFilter(value);

    // this._filterService.filter(filterToApply); // update the root filter
    this.applyFilter(filterToApply);
  }

  private removeFilters() {
    this.removeFilter(this.mainField);
    this.removeFilter(this.secondaryField);
    return undefined;
  }

  ngOnInit(): void {
    const filterApplied = this.currentFilter?.filters[0] as FilterDescriptor;
    this.selectedOperator =
      filterApplied !== undefined ? (filterApplied.operator as string) : 'contains';
    this.selectedValue = filterApplied !== undefined ? filterApplied.value : null;
  }

  public operatorChange(operator) {
    if (operator === 'isnull' || operator === 'isnotnull') {
      this.selectedValue = null;
      this.onValueChange(null);
    } else {
      this.onValueChange(this.selectedValue);
    }
  }

  generateCompositeFilter(value: any): CompositeFilterDescriptor {
    this._filterService.filter(this.removeFilters());
    const mainFilter = this.updateFilter(this.generateFilterDescriptior(this.mainField, value));
    const secondaryFilter = this.updateFilter(
      this.generateFilterDescriptior(this.secondaryField, value)
    );
    const compositeFilter: CompositeFilterDescriptor = {
      filters: mainFilter.filters.concat(secondaryFilter.filters),
      logic: 'or',
    };

    return compositeFilter;
  }

  generateFilterDescriptior(fieldName: string, value: any): FilterDescriptor {
    const filter: FilterDescriptor = {
      field: fieldName,
      value: value,
      operator: this.selectedOperator,
    };
    return filter;
  }
}
