import { DOCUMENT } from '@angular/common';
import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { FragmentsManagerService } from '../services/fragments-manager.service';
import { CustomThemes } from './custom-themes';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly _themeKey = 'w-app-theme';
  private readonly _defaultTheme = CustomThemes.Default;
  private readonly _availableThemes = Object.values(CustomThemes);
  private _theme$ = new BehaviorSubject(CustomThemes.Default);
  // No replay or behavior subjects, we want to emit only future changes.
  private _themeChanges$ = new Subject<CustomThemes>();
  private readonly _fragmentsManagerService = inject(FragmentsManagerService);
  private _destroyRef = inject(DestroyRef);
  private readonly _fragmentIsDarkModeKey = 'darkMode';

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _localStorage: LocalStorageService
  ) {
    this.initFromStorage();
  }

  enableChangeThemeByUrl(): void {
    this._fragmentsManagerService.fragmentsData$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        if (data) {
          const isDarkMode = data[this._fragmentIsDarkModeKey];
          const theme = isDarkMode === 'true' ? CustomThemes.Dark : CustomThemes.Default;
          this.setTheme(theme);
        }
      });
  }

  get currentTheme(): CustomThemes {
    return this._theme$.value;
  }

  /**
   * Notify current theme and all next changes.
   */
  get theme$(): Observable<CustomThemes> {
    return this._theme$.asObservable();
  }

  /**
   * Notify only next theme changes, not current value.
   * Useful when current and next changes follow different flows.
   */
  get themeChanges$(): Observable<CustomThemes> {
    return this._themeChanges$.asObservable();
  }

  setTheme(theme: CustomThemes): void {
    this._theme$.next(theme);
    this._themeChanges$.next(theme);
    this.setThemeClass(theme);
    this._localStorage.addOrUpdate(this._themeKey, theme);
  }

  toggleTheme(): CustomThemes {
    const currentTheme =
      this._theme$.value === CustomThemes.Default ? CustomThemes.Dark : CustomThemes.Default;
    this.setTheme(currentTheme);
    this._themeChanges$.next(currentTheme);
    return currentTheme;
  }

  private initFromStorage(): void {
    const theme = this._localStorage.getTyped(this._themeKey, this._defaultTheme);
    if (theme) {
      this._theme$.next(theme);
      this.setThemeClass(theme);
    }
  }

  private setThemeClass(themeToSet: CustomThemes): void {
    const bodyClasses = this._document.body.classList;

    this._availableThemes.forEach((theme) => {
      bodyClasses.remove(this.buildThemeClass(theme));
    });
    bodyClasses.add(this.buildThemeClass(themeToSet));
  }

  private buildThemeClass = (themeName: CustomThemes) => `w-theme-${themeName}`;
}
