import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from './base-input.directive';

/**
 * Directive that limit the max value of a numeric input.
 */
@Directive({
  selector: 'input[wlmInputValidate]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputValidateDirective),
      multi: true,
    },
  ],
})
export class InputValidateDirective extends BaseInputDirective {
  @Input() public set wlmInputValidate(
    functions: [((data: any) => any)[], ((data: any) => any)[]]
  ) {
    if (functions[0]) {
      this.viewToFormFns = functions[0];
    }
    if (functions[1]) {
      this.formToViewFns = functions[1];
    }

    super['previousValue'] = '';
  }

  private viewToFormFns: ((data: any) => any)[] = [];
  private formToViewFns: ((data: any) => any)[] = [];

  constructor(elRef: ElementRef) {
    super(elRef);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    this.onEvent(event?.data);
  }

  protected viewToForm(data: any): any {
    const results = this.applyFunctionsArray(this.viewToFormFns, data);
    this.inputValue = results;
    return results;
  }

  protected formToView(data: any): any {
    const results = this.applyFunctionsArray(this.formToViewFns, data);
    this.inputValue = results;
    return results;
  }

  private applyFunctionsArray(fns: ((data: any) => any)[], data: any) {
    let result = data;
    fns.forEach((fn) => {
      result = fn(result);
    });
    return result;
  }
}
