import { Component, Inject, Injector, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { ElementTargetFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { HierarchyTreeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import {
  FiltersSettingsSelector,
  ResetFiltersSelector,
} from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { INeHeFilterSettings } from '../../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { NEConfigFilterConfiguration } from '../../ne-config-filter/ne-config-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-ne-config-filter-widget';

export const NEC_FILTER_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-filter-widget.component.html',
  styleUrls: ['./ne-config-filter-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigFilterWidgetComponent
  extends BaseDynamicWidgetComponent
  implements IPendingChangesChecker, OnDestroy
{
  private _filters: DataBindingFilters;
  private _neTypeFilterKey: string;
  private _heTypeFilterKey: string;
  private _isZoneFilterKey: string;
  private _familyFilterKey: string;

  private _reset$ = new Subject<void>();
  readonly reset$ = this._reset$.asObservable();

  pageId: string;
  filterConfiguration: NEConfigFilterConfiguration;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;
  }

  /**
   * Custom lifecycle method where to start using this._state.
   */
  onWidgetInit(): void {
    this._state
      .select(new FiltersSettingsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe((configuration: NEConfigFilterConfiguration) => {
        if (configuration) {
          this._isZoneFilterKey = configuration.targetSettings.fieldName;
          this._heTypeFilterKey = configuration.heSettings.elementTypeFieldName;
          this._neTypeFilterKey = configuration.neSettings.elementTypeFieldName;
          this._familyFilterKey = configuration.hierarchyElementFamilyFieldName;

          // this.filterConfiguration = this.updatePersistencyFilterValues(configuration);
          this.filterConfiguration = this.cloneFilterConfig(configuration);
        }
      });

    this._state
      .select<number>(new ResetFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (_) => this._reset$.next(),
      });
  }

  setFilters(filters: DataBindingFilters) {
    this._filters = filters;
  }

  applyFilters(): void {
    const filterIsValid = this.validateFilter(this._filters);
    if (filterIsValid) {
      this.checkPendingChanges(this.pageId).subscribe((_) =>
        this.dispatchFilters(this._filters, this._scopeSettings)
      );
    }
  }

  validateFilter(filters: DataBindingFilters): boolean {
    const iFilters = Array.from(filters.filters.values()).map((x) => x.getFiltersValues());
    if (!iFilters) {
      return false;
    }

    if (iFilters.find((x) => x.has(this._isZoneFilterKey)) === undefined) {
      return false;
    }

    const isZone = iFilters.find((x) => x.has(this._isZoneFilterKey)).get(this._isZoneFilterKey);
    const heFilter = iFilters.find((x) => x.has(this._heTypeFilterKey));
    const neFilter = iFilters.find((x) => x.has(this._neTypeFilterKey));
    const familyFilter = iFilters.find((x) => x.has(this._familyFilterKey));

    let validationResult = true;

    // Rule 1: isZone == true => must have heFilter
    if (isZone) {
      validationResult = validationResult && heFilter !== undefined;
    }

    // Rule 2: isZone == false => must have neFilter
    if (!isZone) {
      validationResult = validationResult && neFilter !== undefined;
    }

    // Rule 3: must have familyFilter
    validationResult = validationResult && familyFilter !== undefined;

    return validationResult;
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      filter((value) => value !== null)
    );
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  get componentName(): string {
    return 'NeConfigFilterWidgetComponent';
  }

  private dispatchFilters(filters: DataBindingFilters, scopeSettings: StateScopeSettings): void {
    this._state.dispatch(new ApplyFiltersAction(filters, scopeSettings));
  }

  // This is currently needed because of the inmutability of the data extracted from Reactive State.
  // It seems that cloning the object with helperService.clone is not making it mutable.
  private cloneFilterConfig(config: NEConfigFilterConfiguration) {
    const heSettings = new INeHeFilterSettings(
      config.heSettings.isNEFilter,
      config.heSettings.selectedElementIds,
      config.heSettings.elementTypeFieldName
    );
    const neSettings = new INeHeFilterSettings(
      config.neSettings.isNEFilter,
      config.neSettings.selectedElementIds,
      config.neSettings.elementTypeFieldName
    );
    const treeSettings = new HierarchyTreeFISettings(config.treeSettings);
    const targetSettings = new ElementTargetFISettings(config.targetSettings);

    const {
      persistencyArea,
      pagePersistencyArea,
      defaultFamilyId,
      defaultHETypeIds,
      defaultNETypeIds,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
      initialFamilyId,
      initialHierarchyElementId,
    } = config;

    const clonedConfig = new NEConfigFilterConfiguration({
      persistencyArea,
      pagePersistencyArea,
      defaultFamilyId,
      defaultHETypeIds,
      defaultNETypeIds,
      heSettings,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
      initialFamilyId,
      initialHierarchyElementId,
      neSettings,
      targetSettings,
      treeSettings,
    });

    return clonedConfig;
  }

  ngOnDestroy() {
    this.dispatchFilters(null, this._scopeSettings);

    super.ngOnDestroy();
  }
}
