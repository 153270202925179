<div
  [class]="'dl-container ' + versionClass + ' ' + containerClass"
  #layoutContainer
  [ngClass]="{
    'dl-enable-closable-stacks': settings?.showStackCloseIcon,
    'dl-disable-closable-stacks': !settings?.showStackCloseIcon,
    'dl-enable-closable-widgets': settings?.showCloseWidgetIcon,
    'dl-disable-closable-widgets': !settings?.showCloseWidgetIcon,
    'dl-enable-popout-icons': settings?.showPopoutIcon,
    'dl-disable-popout-icons': !settings?.showPopoutIcon,
    'dl-hide-layout': !allowEmptyRoot && !settings?.items?.length
  }"
>
  <app-golden-layout-host
    #glRoot
    [layout]="glSettings"
    [parentInjector]="injector"
  ></app-golden-layout-host>
</div>
