import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInterfacesLoggingSelector } from 'src/app/common-modules/dependencies/logs/models/interfaces-logging-selector';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { UtilsHelperService } from 'src/app/common-modules/shared/helpers/utils-helper.service';
import { JsonEditorComponent } from 'src/app/common-modules/shared/json-editor/json-editor.component';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { InterfaceRequestDto } from '../dtos/interface-request.dto';
import { InterfaceRequestService } from '../services/interface-request.service';

const COMPONENT_SELECTOR = 'wlm-send-interfaces-request-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './send-interfaces-request-popup.component.html',
  styleUrls: ['./send-interfaces-request-popup.component.scss'],
})
export class SendInterfacesRequestPopupComponent {
  @ViewChild(JsonEditorComponent) public jsonEditor: JsonEditorComponent;
  isSaving = false;
  hasErrors = false;
  readonly T_SCOPE = `${AppModules.Interfaces}.${COMPONENT_SELECTOR}`;

  private _interfaceUrl: string;
  private _interfaceName: string;

  initialData: any = "";

  private _id: string;

  constructor(
    private _dialogRef: MatDialogRef<SendInterfacesRequestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: IInterfacesLoggingSelector,
    private _dialogService: DialogService,
    private _spinnerService: SpinnerService,
    private _utilsHelper: UtilsHelperService,
    private _interfaceRequestService: InterfaceRequestService
  ) {
    this._interfaceUrl = data.url;
    this._interfaceName = data.interfaceTypeName;
    this._id = this._utilsHelper.generateGuid();
  }

  ngOnInit(): void {
    this._spinnerService.setLoading(true, this._id);
    this._interfaceRequestService.getRequestSample(this._interfaceName).subscribe({
      next: (response) => {
        if (response) {
          this.initialData = response;
        }
        else {
          this.initialData = [];
        }

        this._spinnerService.setLoading(false, this._id);
      },
      error: (error) => {
        this.manageError();
      }
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) { }
  init(): void { }

  close(isCompleted: boolean = false) {
    this._dialogRef.close(isCompleted);
  }

  onJsonHasError(hasErrors) {
    this.hasErrors = hasErrors;
  }

  sendRequest() {
    this._spinnerService.setLoading(true, this._id);
    let request = this.jsonEditor.stringifyJson;

    const interfaceRequest = new InterfaceRequestDto({
      contentRequest: JSON.parse(request),
      url: this._interfaceUrl,
    });

    this._interfaceRequestService.sendJsonRequest(this._interfaceName, interfaceRequest).subscribe({
      next: (response) => {
        const messageKey = 'interface-request-completed';
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({
            translateKey: `${this.T_SCOPE}.messages.${messageKey}`,
            params: response,
          })
        );
        this._spinnerService.setLoading(false, this._id);
        this.close(true);
      },
      error: (error) => {
        this.manageError();
      },
    });
  }

  private manageError() {
    const errorMessageKey = 'interface-request-error';
    this._dialogService.showTranslatedMessage(
      new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.${errorMessageKey}`,
        icon: 'error',
      })
    );
    this._spinnerService.setLoading(false, this._id);
  }
}
