import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { OperationResponseDto } from 'src/app/common-modules/dependencies/shared/model/responses/operation-response.dto';
import { IALCEligibilityChangeDto } from '../../../dependencies/alc/eligibility-change.data';
import { IReasonsForEligibleChangeDto } from '../../../dependencies/alc/eligibility-reasons.dto';

@Injectable({ providedIn: 'root' })
export class EligibilityService extends BaseService {
  headers: HttpHeaders;

  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/alc/eligibility`;
  }

  getAll(): Observable<IReasonsForEligibleChangeDto[]> {
    return this.httpCacheClient.get(`${this.url}/reasons/all`, {
      expiration: 'default',
      reload: true,
    });
  }

  save(eligibilityChange: IALCEligibilityChangeDto): Observable<OperationResponseDto> {
    return this.httpCacheClient.post(`${this.url}/save`, eligibilityChange, {
      avoid: true,
    });
  }

  // TODO: review with Jaime why using avoid=true not always goes to server.
  // getIneligibleZones(): Observable<string[]> {
  //   return this.httpCache.get(`${this.apiUrl}${this.baseUrl}/ineligibles`, { avoid: true });
  // }

  getIneligibleZones(cache = false): Observable<string[]> {
    const inelegibleUrl = `${this.url}/ineligibles`;

    if (cache) {
      return this.httpCacheClient.get<string[]>(inelegibleUrl);
    }

    this.headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });

    return this.httpClient.get<string[]>(inelegibleUrl, {
      headers: this.headers,
    });
  }
}
