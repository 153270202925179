// prettier-ignore
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IGisLayer } from 'src/app/common-modules/dependencies/map/gis-layer';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { FiltrableGisLayer } from '../../../../common-modules/dependencies/map/filtrable-gis-layer';
import { MapHelperService } from '../map-helper.service';
import { MapLayerSourcesService } from '../map-layer-sources.service';
import { MapParameters } from '../map-parameters';

const COMPONENT_SELECTOR = 'wlm-map-filter';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-filter.component.html',
  styleUrls: ['./map-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapFilterComponent implements OnInit {
  private _mapParameters: MapParameters;
  public get mapParameters(): MapParameters {
    return this._mapParameters;
  }
  @Input() public set mapParameters(v: MapParameters) {
    this._mapParameters = v;
    this._cd.detectChanges();
  }

  @Input() baseType: string;

  private _visibleLayersIds: number[];
  public get visibleLayersIds(): number[] {
    return this._visibleLayersIds;
  }
  @Input() public set visibleLayersIds(value: number[]) {
    this._visibleLayersIds = value;

    if (this.filtrableLayers) {
      this.setLayersSelection(this.filtrableLayers);
    }

    this._cd.detectChanges();
  }

  private _leakYears: number[];
  public get leakYears(): number[] {
    return this._leakYears;
  }
  @Input() public set leakYears(value: number[]) {
    this._leakYears = value;

    if (this.yearsSelection && this.filtrableLayers) {
      this.initializeCheckedYears(this.filtrableLayers);
    }
  }
  @Input() displayed: boolean;

  @Output() baseTypeChange = new EventEmitter<string>();
  @Output() visibleLayersIdsChange = new EventEmitter<number[]>();
  @Output() leakYearsChange = new EventEmitter<number[]>();
  @Output() onLoad = new EventEmitter<void>();

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  baseTypes: string[];
  filtrableLayers: FiltrableGisLayer;
  layersSelection: { [id: number]: boolean } = { 1: true };
  yearsSelection: { [id: number]: boolean };

  constructor(
    private _globalService: GlobalsService,
    private _mapHelperService: MapHelperService,
    private _cd: ChangeDetectorRef,
    private _objectHelper: ObjectHelperService,
    private _mapLayerSourcesService: MapLayerSourcesService
  ) {}

  ngOnInit(): void {
    this.layersSelection = {};
    this.yearsSelection = {};

    this.baseTypes = [...this._mapLayerSourcesService.getTileSetTypes()];

    this._globalService
      .getFiltrableGisLayers()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (layers) => {
          this.filtrableLayers = layers;

          this.setLayersSelection(layers);

          this.initializeCheckedYears(layers);

          this.onLoad.emit();
        },
      });
  }

  onSelectNetworkElement(selection: [IGisLayer, boolean, boolean]): void {
    const layer = selection[0];
    const checked = selection[1];

    let layerIds = [layer.gisLayerId];

    if (!!layer.sublayers?.length) {
      const sublayerIds = layer.sublayers.map((sub) => sub.gisLayerId);
      layerIds = layerIds.concat(sublayerIds);
    }

    layerIds.forEach((id: number) => {
      this.layersSelection[id] = checked;
    });

    // Remove sublayers for the cases in which there was already some selected
    this.visibleLayersIds = this.visibleLayersIds.filter((id) => !layerIds.includes(id));

    if (checked) {
      this.visibleLayersIds = this.visibleLayersIds.concat(layerIds);
    }

    this.visibleLayersIdsChange.emit(this.visibleLayersIds);
  }

  onSelectLeak(selection: [IGisLayer, boolean]): void {
    const layerId = selection[0].gisLayerId;
    const checked = selection[1];

    if (checked && !this.leakYears?.length) {
    }

    // When a leak is selected but no year, the last year is selected by default
    if (checked && !this.leakYears?.length) {
      this.checkDefaultYear();
    }

    this.onSelectLayer(layerId, checked);
  }

  onSelectYear(event: MatCheckboxChange, year: number): void {
    this.yearsSelection[year] = event.checked;

    const filtrableYearValue = this._mapHelperService.getFiltrableYearValue(year);
    if (event.checked) {
      this.leakYears = this.leakYears.concat([filtrableYearValue]);
      this.leakYearsChange.emit(this.leakYears);
    } else {
      const exists = this.leakYears.some((y) => y === filtrableYearValue);

      if (exists) {
        this.leakYears = this.leakYears.filter((y) => y !== filtrableYearValue);
        this.leakYearsChange.emit(this.leakYears);

        // If no year selected, uncheck all leaks and activities
        if (!this.leakYears?.length) {
          this.visibleLayersIds = this.visibleLayersIds.filter(
            (id) =>
              !this.filtrableLayers.closedLeaks.some((l) => l.gisLayerId === id) &&
              !this.filtrableLayers.outstandingLeaks.some((l) => l.gisLayerId === id) &&
              !this.filtrableLayers.activities.some((l) => l.gisLayerId === id)
          );
          this.visibleLayersIdsChange.emit(this.visibleLayersIds);
        }
      }
    }
  }

  onSelectBaseLayer(event: MatRadioChange): void {
    this.baseType = event.value;
    this.baseTypeChange.emit(this.baseType);
  }

  onSelectActivity(selection: [IGisLayer, boolean]): void {
    const layerId = selection[0].gisLayerId;
    const checked = selection[1];

    // When an activity is selected but no year, the last year is selected by default
    if (checked && !this.leakYears?.length) {
      this.checkDefaultYear();
    }

    this.onSelectLayer(layerId, checked);
  }

  private initializeCheckedYears(layers: FiltrableGisLayer) {
    layers.leakYears.forEach((leakYear) => {
      const selected = this.leakYears.some(
        (ly) => ly === this._mapHelperService.getFiltrableYearValue(leakYear.year)
      );
      this.yearsSelection[leakYear.year] = selected;
    });
  }

  private setLayersSelection(layers: FiltrableGisLayer) {
    FiltrableGisLayer.getLayers(layers).forEach((layer) => {
      const selected = this.visibleLayersIds.some((id) => id === layer.gisLayerId);

      this.layersSelection[layer.gisLayerId] = selected;
    });

    this.layersSelection = this._objectHelper.clone(this.layersSelection);
  }

  private onSelectLayer(layerId: number, checked: boolean) {
    this.layersSelection[layerId] = checked;

    if (checked) {
      this.visibleLayersIds = this.visibleLayersIds.concat([layerId]);
      this.visibleLayersIdsChange.emit(this.visibleLayersIds);
    } else {
      const exists = this.visibleLayersIds.some((id) => id === layerId);

      if (exists) {
        this.visibleLayersIds = this.visibleLayersIds.filter((id) => id !== layerId);
        this.visibleLayersIdsChange.emit(this.visibleLayersIds);
      }
    }
  }

  private checkDefaultYear() {
    const lastYear = this.filtrableLayers.leakYears[0].year;
    this.yearsSelection[lastYear] = true;

    const filtrableYearValue = this._mapHelperService.getFiltrableYearValue(lastYear);
    this.leakYears = this.leakYears.concat([filtrableYearValue]);
    this.leakYearsChange.emit(this.leakYears);
  }
}
