export class AlarmQueryDto {
  elementsIds: string[];
  alarmClassId: number;
  startDate?: string;
  endDate?: string;
  excludeChildren: boolean;
  isNetworkElement?: boolean;

  constructor(init: Readonly<AlarmQueryDto>) {
    Object.assign(this, init);
  }
}
