<kendo-treelist
  kendoTreeListExpandable
  class="treelist"
  [ngClass]="{ 'treelist-full-size': fullSize }"
  [kendoTreeListFlatBinding]="treeListSettings.data"
  idField="rowKey"
  [resizable]="true"
>
  <kendo-treelist-column
    [expandable]="true"
    field="rowKey"
    [title]="T_SCOPE + '.header.area' | translate"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span>
        {{ dataItem.rowKey }}
      </span>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column
    *ngFor="let columnKey of treeListSettings.columnKeys"
    [title]="columnKey"
    [width]="150"
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <mat-checkbox
        [disabled]="checkIfDisabled(dataItem, columnKey)"
        class="checkbox-crud"
        [checked]="dataItem.columnsData.get(columnKey)"
        (change)="columnValueChanged($event, dataItem, columnKey)"
      >
      </mat-checkbox>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>
