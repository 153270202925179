export class GChartTextStyles {
  color?: string;
  fontStyle?: 'normal' | 'italic' | 'oblique' = 'normal';
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
  fontFamily?: string;
  fontSize? = 9;
  lineHeight?: number;
  padding?: number;

  public constructor(init?: Readonly<GChartTextStyles>) {
    Object.assign(this, init);
  }
}
