<wlm-generic-grid
  *ngIf="gridSettings && filters"
  class="grid selector-grid"
  [subscriptionTag]="widgetInstanceKey"
  [gridSettings]="gridSettings"
  [additionalFilters]="filters"
  [gridFiltersForBinding]="emptyFilters"
  [subscriptionTag]="subscriptionTag"
  [showTooltipOnRows]="false"
  (selectedItemChanged)="setSelectedElement($event)"
  [(selectedItems)]="manySelected"
></wlm-generic-grid>
