import { Observable } from 'rxjs';
import { BaseService } from '../../shared/base.service';

import { DynamicSettings } from '../models/dynamic-settings';
import { DynamicSettingsDelete } from '../models/dynamic-settings-delete';
import { DynamicSettingsSave } from '../models/dynamic-settings-save';

export abstract class PersistLayoutService extends BaseService {
  abstract load(settings: DynamicSettings): Observable<any>;
  abstract save(settings: DynamicSettingsSave): Observable<any>;
  abstract delete(settings: DynamicSettingsDelete): Observable<any>;
}
