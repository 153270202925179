import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { UserFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/user-filter';

export class UserFAdapter extends FilterAdapterSettings {
  id: FilterAdapterEnum = FilterAdapterEnum.User;

  userCodeFieldName: string;

  constructor(init: { dataBindingField: string; userCodeFieldName: string }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const userFilter = filters.data.get(this.userCodeFieldName);
    if (userFilter) {
      const selectedIds = userFilter.value ? userFilter.value.map((item) => item.value) : [];
      const result = new UserFilter(selectedIds, userFilter.fieldName);
      return result;
    }
    return null;
  }

  buildResults(userFilter: UserFilter): FilterAdapterResult {
    if (userFilter) {
      this.filtersResult.filters.set(this.dataBindingField, userFilter);
      this.panelParams.addParameter(TabDetailParameterName.user, userFilter.userIds);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
