<wlm-spinner-wrapper
  [showSpinner]="mainChartLoading || eventChartLoading || loading"
  [useOverflow]="false"
  containerClass="time-selector-scrollable"
>
  <div class="topbar" fixedSizeElement>
    <div class="topbar-item" #buttonsFixedSizeElement>
      <ng-content select="[topbar-first]"></ng-content>
    </div>
    <div
      class="topbar-item topbar-buttons time-selector-container"
      *ngIf="timeSelectorChartSetting"
      #buttonsFixedSizeElement
    >
      <wlm-time-selector
        class="time-selector"
        [timeSelectorSettings]="timeSelectorChartSetting"
        [addTimeSelectorPeriod$]="addTimeSelectorPeriod$"
        (timeSelectionChanged)="timePeriodChanged($event)"
        [isLoading]="loading"
      ></wlm-time-selector>
    </div>
    <div class="topbar-item topbar-buttons" topbar-last>
      <ng-template #responsiveButtons>
        <button
          *ngIf="timeSelectorChartSetting"
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          [disabled]="!trendChart?.dataLoaded && !customizableChart?.isDataLoaded()"
          (click)="exportChart()"
          [matTooltip]="T_SCOPE + '.export-image' | translate | lowercase"
        >
          <mat-icon svgIcon="export-chart-image"></mat-icon>
        </button>

        <wlm-export-pdf-button
          class="topbar-button"
          *ngIf="timeSelectorChartSetting"
          [exportPdfComponent]="this"
          [disabled]="!trendChart?.dataLoaded && !customizableChart?.isDataLoaded()"
        ></wlm-export-pdf-button>

        <wlm-export-excel
          class="topbar-button"
          *ngIf="timeSelectorChartSetting"
          [exportExcelComponent]="this"
          [disabled]="!trendChart?.dataLoaded && !customizableChart?.isDataLoaded()"
        ></wlm-export-excel>

        <button
          *ngIf="timeSelectorChartSetting"
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          [disabled]="!trendChart?.dataLoaded && !customizableChart?.isDataLoaded()"
          (click)="showDataPoints()"
          [matTooltip]="T_SCOPE + '.show-hide-datapoints' | translate | lowercase"
        >
          <mat-icon svgIcon="data_points"></mat-icon>
        </button>

        <button
          *ngIf="timeSelectorChartSetting && chartType === chartTypes.customizable"
          mat-icon-button
          color="primary"
          class="topbar-button wtr-button-primary"
          [disabled]="!customizableChart?.isDataLoaded()"
          (click)="onChartConfiguration()"
          onclick="this.blur()"
          [matTooltip]="T_SCOPE_CHART_BUTTONS + '.chart-configuration' | translate | lowercase"
        >
          <mat-icon svgIcon="chart-configuration"></mat-icon>
        </button>
        <ng-content select="[topbar-last]"> </ng-content>
      </ng-template>
      <wlm-responsive-buttons-container
        [contentsTemplate]="responsiveButtons"
        [containerInstanceId]="componentInstanceId"
        [fixedSizeElements]="buttonsFixedSizeElements"
      ></wlm-responsive-buttons-container>
    </div>
  </div>

  <div class="time-selector-chart-container">
    <div class="chart" *ngIf="timeSelectorChartSetting">
      <wlm-trend-chart
        *ngIf="timeSelectorChartSetting?.chartSetting?.chartType === chartTypes.trend"
        [chartSettings]="timeSelectorChartSetting.chartSetting"
        [size]="calculatedSize$ | async"
        (loadingEvent)="setMainChartLoading($event)"
        (chartInitEvent)="onChartInit($event)"
        (chartDataZoomEvent)="onChartDataZoom($event)"
        (chartLegendSelectedEvent)="onChartLegendSelected($event)"
        (genericChartSettingsChange)="onMainChartSettingsChange($event)"
        (chartLoaded)="onChartLoaded($event)"
        (chartFinished)="onChartFinished($event)"
        (serieNamesUnselected)="onSerieNamesUnselected($event)"
      ></wlm-trend-chart>
      <wlm-custom-workspace-chart
        *ngIf="timeSelectorChartSetting?.chartSetting?.chartType === chartTypes.customizable"
        [customWorkspaceSettings]="timeSelectorChartSetting.chartSetting"
        [containerSize$]="calculatedSize$"
        (loadingEvent)="setMainChartLoading($event)"
        (chartInitEvent)="onChartInit($event)"
        (chartLegendSelectedEvent)="onChartLegendSelected($event)"
        (chartLoaded)="onChartLoaded($event)"
        (serieNamesUnselected)="onSerieNamesUnselected($event)"
      ></wlm-custom-workspace-chart>
    </div>
    <hr
      *ngIf="
        eventChartQuery &&
        eventChartQuery?.categories?.length &&
        timeSelectorChartSetting &&
        !loading &&
        chartType !== chartTypes.customizable
      "
      class="separator"
      style="margin: 5px"
    />
    <div
      class="chart events-chart"
      *ngIf="
        chartType !== chartTypes.customizable && eventChartQuery?.categories?.length && xAxisRange
      "
    >
      <wlm-events-chart
        [xAxisRange]="xAxisRange"
        [eventQuery]="eventChartQuery"
        (chartClick)="onChartClick($event)"
        (isLoading)="onEventChartLoading($event)"
        (sizeChanged)="onEventChartSizeChange($event)"
      ></wlm-events-chart>
    </div>
  </div>
</wlm-spinner-wrapper>
