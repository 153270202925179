import { Component, Injector, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ValidationHelperService } from 'src/app/common-modules/shared/helpers/validation-helper.service';
import { ICustomerClassTypeDto } from 'src/app/water-loss/features/shared/model/customer/customer-class-type.dto';
import { CustomerClassTypeFormComponent } from '../customer-class-type-form/customer-class-type-form.component';
import { CustomerClassTypesCrudService } from '../customer-class-types-crud.service';

const COMPONENT_SELECTOR = 'wlm-customer-class-type-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-class-type-crud.component.html',
  styleUrls: ['./customer-class-type-crud.component.scss']
})
export class CustomerClassTypeCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _customerClassTypesCrudService = inject(CustomerClassTypesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'CustomerClassTypesCrudService',
      grid: {
        gridSettingsName: 'CustomerClassTypesCrud',
      },
      create: {
        formComponent: CustomerClassTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: CustomerClassTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
      (model: ICustomerClassTypeDto): Observable<ICustomerClassTypeDto | null> => {
        return this._customerClassTypesCrudService.validate(model, isCreate).pipe(
          map((validation) => {
            if (validation.duplicatedId) {
              this._validationHelperService.showNotUniqueError(
                `${this.T_SCOPE}.properties.customer-class-type-id`
              );
              return null;
            }
            return model;
          })
        );
      };
}
