import { Directive, Input } from '@angular/core';

/**
 * Helps to access variables that are injected by ng-template (<ng-template let-value="myValue">)
 * inside the component class.
 * The exportAs is the key of the directive, which allows to access its value via @VieqChild.
 */

@Directive({ selector: '[exposeVariable]', exportAs: 'exposedVars' })
export class ExposeVariableDirective {
  private _varsToExpose: any;
  public get varsToExpose(): any {
    return this._varsToExpose;
  }
  @Input() public set varsToExpose(value: any) {
    this._varsToExpose = value;
  }
}
