import { EventViewCategories } from './event-view-categories';

export class EventViewCategory {
  value: EventViewCategories;
  labelKey?: string;

  constructor(init: Partial<EventViewCategory>) {
    Object.assign(this, init);
  }
}
