import { createAction, props } from '@ngrx/store';
import { GenericAction } from '../../redux/models/generic-action';
import { StateAreas } from '../../redux/models/state-areas';
import { StateFullSettings } from '../../redux/models/state-full-settings';
import { StateScopeSettings } from '../../redux/models/state-scope-settings';

import { UomActionTypes } from './uom.action-types';

export abstract class UomAction extends GenericAction {
  area = StateAreas.UoM;
}

export class SelectDimensionAction extends UomAction {
  type = UomActionTypes.SelectDimension;

  constructor(public payload: number, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetUoMStateAction extends UomAction {
  type = UomActionTypes.ResetUomState;
  payload = undefined;

  constructor(settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export const UOM_ACTION_CREATORS = [
  createAction(
    UomActionTypes.SelectDimension,
    props<{ payload: number; settings: StateFullSettings }>()
  ),
  createAction(UomActionTypes.ResetUomState, props<{ null; settings: StateFullSettings }>()),
];
