<wlm-spinner-wrapper [showSpinner]="isSaving">
  <div class="form-container">
    <h3>{{ formTitle | translate }}</h3>
    <hr class="title-separator" />

    <mat-dialog-content [formGroup]="form" style="overflow-x: hidden">
      <div class="container hide-subscript">
        <div>
          <div class="row">
            <mat-radio-group formControlName="parkOperation">
              <mat-radio-button
                color="primary"
                *ngFor="let operation of parkOperations"
                [value]="operation"
                (change)="onParkOperationChange($event)"
              >
                {{ T_SCOPE + '.' + operation | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="row">
            <wlm-date-range-filter
              [dateRange]="dateRangeValue"
              [displayHorizontal]="true"
              [disableStartDate]="disableStartDate"
              [disableEndDate]="disableEndDate"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [resetEndDate$]="resetEndDate$"
              [resetStartDate$]="resetStartDate$"
              (dateRangeChanged)="onDateRangeChanged($event)"
            ></wlm-date-range-filter>
          </div>

          <div class="row">
            <mat-checkbox
              formControlName="parkIndefinitely"
              (change)="onIndefinitelyChange($event)"
              >{{ T_SCOPE + '.park-indef-label' | translate }}</mat-checkbox
            >
          </div>

          <div class="row">
            <mat-form-field class="comment" appearance="outline">
              <mat-label>{{ T_SCOPE + '.comment-label' | translate }}</mat-label>
              <textarea
                matInput
                formControlName="parkComment"
                class="textarea-height-standard"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <div class="buttons-container">
        <button
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="!form.valid || isSaving"
        >
          Save
        </button>

        <button mat-raised-button (click)="close()">Close</button>
      </div>
    </mat-dialog-actions>
  </div>
</wlm-spinner-wrapper>
