import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AlarmsActiveDto } from 'src/app/common-modules/dependencies/alarms/alarms-active.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { ApplicationAttributes } from '../../../../common-modules/shared/constants/application-constants';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WNavigateSettings } from 'src/app/common-modules/dependencies/navigation/w-navigate-by';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { ActiveAlarmsGridService } from '../active-alarms-grid/active-alarms.service';
const COMPONENT_SELECTOR = 'wlm-dv-alarms-navigation-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dv-alarms-navigation-popup.component.html',
  styleUrls: ['./dv-alarms-navigation-popup.component.scss'],
})
export class DvAlarmsNavigationPopupComponent implements OnInit {
  @ViewChild(GenericGridComponent) public alarmsGrid: GenericGridComponent;

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;
  T_SCOPE_NAVIGATION = `${AppModules.WlmShared}.wlm-nav-dropdown`;

  gridFiltersForBinding: DataBindingFilters;
  maxItemsExceeded = false;
  navigateTooltip: string;
  dvNavGridSettingsName = 'AlarmsNavigation';
  hierarchyElements: string[];
  maxZones = ApplicationAttributes.MaxItemsForNavigation;
  useGridPersistence = false;
  private _navigateSettings: WNavigateSettings;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get navigateBtnTranslationKey(): string {
    return `${this.T_SCOPE}.btn-navigate`;
  }

  private _selectedAlarms: AlarmsActiveDto[];
  public get selectedAlarms(): AlarmsActiveDto[] {
    return this._selectedAlarms;
  }
  public set selectedAlarms(v: AlarmsActiveDto[]) {
    this._selectedAlarms = v;
    this.validateSelection();
  }
  private _dvNavGridSetting: GridSetting;

  public get dvNavGridSetting(): GridSetting {
    return this._dvNavGridSetting;
  }
  public set dvNavGridSetting(v: GridSetting) {
    this._dvNavGridSetting = v;
  }

  private _hierarchyFamilyId: string;
  public get hierarchyFamilyId(): string {
    return this._hierarchyFamilyId;
  }
  public set hierarchyFamilyId(value: string) {
    this._hierarchyFamilyId = value;
    this.loadAlarms();
  }

  constructor(
    private readonly _gridSettingsService: GridSettingsService,
    private readonly _dialogRef: MatDialogRef<DvAlarmsNavigationPopupComponent>,
    private readonly _alarmsService: ActiveAlarmsGridService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService,
    @Inject(MAT_DIALOG_DATA)
    { hierarchyElements, hierarchyFamilyId, navigateSettings }
  ) {
    this.hierarchyElements = hierarchyElements;
    this.hierarchyFamilyId = hierarchyFamilyId;
    this._navigateSettings = navigateSettings;
    this._gridSettingsService.getGridSettingsByName(this.dvNavGridSettingsName).subscribe({
      next: (gridSettings) => {
        this.dvNavGridSetting = gridSettings;
      },
    });
  }

  ngOnInit(): void {}

  loadAlarms() {
    const dataBinding = new DataBindingFilters();
    const compositeZoneFilter = this.getZoneFilter(this.hierarchyElements, 'hierarchyElementId');
    dataBinding.addOrUpdateBasicFilter('isAcknowledge', false);
    dataBinding.addOrUpdateCompositeFilter('HierarchyElementId', compositeZoneFilter);

    this.gridFiltersForBinding = dataBinding;
  }

  getZoneFilter(zones: string[], fieldName: string): CompositeFilterDescriptor {
    const compositeZoneFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    if (zones.length === 0) {
      return compositeZoneFilter;
    }

    const zoneFilterArray = [];
    zones.forEach((zone) => {
      zoneFilterArray.push({
        operator: 'eq',
        field: fieldName,
        value: zone,
      });
    });

    compositeZoneFilter.filters = zoneFilterArray;
    return compositeZoneFilter;
  }

  private validateSelection() {
    const selectionValidation = this._selectedAlarms.length > this.maxZones;
    this.maxItemsExceeded = selectionValidation;
    this.navigateTooltip = selectionValidation
      ? `${this.T_SCOPE_NAVIGATION}.max-elements-selected`
      : '';
  }

  navigate() {
    this._alarmsService.getAlarmsSeries(this._selectedAlarms.map((x) => x.alarmId)).subscribe({
      next: (alarmSeries) => {
        this._dataVisualizationNavigationService.navigateByAlarms(
          this._navigateSettings,
          alarmSeries
        );
        this.cancel();
      },
    });
  }

  cancel() {
    this._dialogRef.close(false);
  }
}
