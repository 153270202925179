<div *ngIf="node.isLeaf" class="selector-node leaf-node">
  <div class="selector-item">
    <div class="selector-icon">
      <wlm-icon-legacy class="icon" [icon]="node.icon" [isSvg]="node.isSvgIcon"></wlm-icon-legacy>
    </div>
    <div class="selector-label-container">
      <div class="selector-label title1">{{ node.label }}</div>
    </div>
    <div class="selector-button">
      <div>
        <button
          mat-icon-button
          color="primary"
          (click)="onDefinitionSelected(node.data)"
          [matTooltip]="T_SCOPE + '.add-button' | translate"
          [disableRipple]="true"
          onclick="this.blur()"
        >
          <mat-icon class="material-icon">add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!node.isLeaf"
  class="selector-node normal-node"
  [ngClass]="{
    'node-expanded': isExpanded
  }"
  (click)="onGroupToggle()"
>
  <div class="normal-node-label secondary">
    {{ node.label }}
  </div>
  <div>
    <wlm-icon *ngIf="!isExpanded" icon="arrow_drop_down"></wlm-icon>
    <wlm-icon *ngIf="isExpanded" icon="arrow_drop_up"></wlm-icon>
  </div>
</div>
