import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeavePageGuard } from './common-modules/shared/navigation/leave-page.guard';

@NgModule({
  imports: [],
  exports: [RouterModule],
  providers: [LeavePageGuard],
})
export class AppRoutingModule {}
