/**
 * Enumerates the different buttons that can be displayed inside the grid buttons component.
 */

export enum GridBtnsOptions {
  ManageColumns = 'manage_columns',
  ExportExcel = 'export_excel',
  Filter = 'filter',
  ClearFilters = 'clear_filters',
  ClearGridFilters = 'clear_grid_filters',
  EditGridSettings = 'edit_grid_settings',
}
