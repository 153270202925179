export class HierarchyElementUnitsQueryParameters {
  timeAggregationId: number;
  hierarchyElementTypeId: string;
  dimensionTypeId: number;

  constructor(hierarchyElementTypeId: string, dimensionTypeId: number, timeAggregationId: number) {
    this.hierarchyElementTypeId = hierarchyElementTypeId;
    this.dimensionTypeId = dimensionTypeId;
    this.timeAggregationId = timeAggregationId;
  }
}
