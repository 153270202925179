import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { PressureSettings } from 'src/app/common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { PressureSettingsSelector } from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { necSelectNetworkElement } from '../../helpers/select-network-element';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure-widget';
export const NEC_PRESSURE_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-pressure-widget.component.html',
  styleUrls: ['./ne-configuration-pressure-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigurationPressureWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  currentNE: INetworkElementDto;
  widgetId: string;
  pageId: string;
  pressureSettings: PressureSettings;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.widgetId = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get componentName(): string {
    return 'NeConfigurationPressureWidgetComponent';
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => {
        if (!selectedNE) {
          return;
        }

        this.currentNE = selectedNE;
      }
    );

    this._state
      .select<PressureSettings>(new PressureSettingsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pressureSettings: PressureSettings) => {
          this.pressureSettings = pressureSettings;
        },
      });
  }
}
