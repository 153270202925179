import { ChartSettings } from '../chart-settings';
import { ChartType } from '../chart-type.enum';
import { EditableChartDataParameters } from './editable-chart-data-parameters';

export class EditableChartSettings extends ChartSettings {
  chartType: ChartType = ChartType.editable;
  dataParameters: EditableChartDataParameters;

  validationService?: string;

  constructor(init: Partial<EditableChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
