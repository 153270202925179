import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-button-column';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './button-column.component.html',
  styleUrls: ['./button-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => ButtonColumnComponent),
    },
  ],
})
export class ButtonColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() iconName: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
