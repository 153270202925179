import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { WaterBalanceParams } from '../../dependencies/water-balance/water-balance-params';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { GlobalsService } from '../../shared/services/globals.service';
import { DynamicLayoutParamsType } from '../models/dynamic-layout-params-type';

@Injectable({
  providedIn: 'root',
})
export class DynamicParamsService {
  readonly T_SCOPE = `${AppModules.BI}.dynamic-params-service`;

  private readonly _titleSectionSeparator = '-';

  constructor(
    private _sharedConstantsService: SharedConstantsService,
    private _globalsService: GlobalsService
  ) {}

  getParamsPopupHeightByType(paramsType: DynamicLayoutParamsType) {
    switch (paramsType?.type) {
      case 'WaterBalance':
        return '360px';
      default:
        return '200px';
    }
  }

  getConfigurableParams(paramsType: DynamicLayoutParamsType, params: Params): Params {
    if (!params) {
      return params;
    }

    let configurableParams;
    if (paramsType?.widgetType === 'TrendChartWidgetComponent') {
      configurableParams = globalUtilsHelper.clone(params.staticQueryParams, true);
    } else {
      configurableParams = globalUtilsHelper.clone(params, true);
    }

    return configurableParams;
  }

  getWidgetTitleByParams(
    baseTitle: string,
    paramsType: DynamicLayoutParamsType,
    params: Params
  ): Observable<string> {
    if (paramsType?.type === 'WaterBalance') {
      return this.buildWaterBalanceTitle(baseTitle, paramsType, params);
    }

    return of(baseTitle);
  }

  private buildWaterBalanceTitle(
    baseTitle: string,
    paramsType: DynamicLayoutParamsType,
    params: Params
  ): Observable<string> {
    const wbParams: Partial<WaterBalanceParams> = this.getConfigurableParams(paramsType, params);

    return forkJoin([
      this._sharedConstantsService.getWaterBalanceTypesMapping(),
      this._globalsService.getDimensionTypesTranslated(),
    ]).pipe(
      map(([dataMap, dimensions]) => {
        const wbTypeLabel = dataMap.get(wbParams.waterBalanceType);
        const dimension = dimensions.find((item) => item.value === wbParams.dimensionTypeId);
        const titleParams = [baseTitle];

        if (dimension.label) {
          titleParams.push(dimension.label);
        }

        if (wbTypeLabel) {
          titleParams.push(wbTypeLabel);
        }

        return titleParams.join(` ${this._titleSectionSeparator} `);
      })
    );
  }
}
