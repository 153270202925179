import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { asEnumerable } from 'linq-es2015';
import { Observable, ReplaySubject, forkJoin, mergeMap, of, switchMap, take } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { UtilsHelperService } from 'src/app/common-modules/shared/helpers/utils-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { ICustomerClassTypeDto } from '../../features/shared/model/customer/customer-class-type.dto';
import { LNUMode } from '../../features/shared/model/ne/lnu-mode.enum';
import { SmartMeterChangesDto } from '../models/smart-meter-changes.dto';
import { SmartMeterProcessedChanges } from '../smart-meters-changes/smart-meters-change.dto';

@Injectable()
export class SmartMetersChangesService {
  private readonly _globalsService = inject(GlobalsService);
  private readonly _sharedConstantsService = inject(SharedConstantsService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _localization = inject(LocalizationHelperService);
  private readonly _uomService = inject(UoMService);
  private readonly _utilsHelperService = inject(UtilsHelperService);
  private _dependenciesReady$ = new ReplaySubject<void>(1);
  private _dependenciesReady = false;
  private _customerClassTypesHash: Map<string, ICustomerClassTypeDto>;
  private _lnuModes: Map<LNUMode, string>;
  private readonly T_SCOPE = `${AppModules.SmartMeters}.smart-meters-changes`;

  private loadDependencies(): void {
    if (this._dependenciesReady) {
      return;
    }

    forkJoin([
      this._globalsService.getCustomerClassTypes(),
      this._sharedConstantsService.getLNUModesMapping(),
    ])
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(([customerClassTypes, lnuModes]) => {
        this._customerClassTypesHash = asEnumerable(customerClassTypes)
          .GroupBy((cct) => cct.customerClassTypeId)
          .ToMap((group) => group.key);
        this._lnuModes = lnuModes;

        this._dependenciesReady$.next();
        this._dependenciesReady$.complete();
        this._dependenciesReady = true;
      });
  }

  processChanges = (
    changes: SmartMeterChangesDto[],
    hierarchyElementTypeId?: string
  ): Observable<SmartMeterProcessedChanges[]> => {
    if (!this._dependenciesReady) {
      this.loadDependencies();
    }

    if (!changes?.length) {
      return of([]);
    }

    return this._dependenciesReady$.pipe(
      take(1),
      switchMap(() => {
        const translations$ = changes.map((change) => {
          return forkJoin({
            customerClassTypeLabel: this.getCustomerClassTypeLabel$(change),
            modeLabel: this.getModeLabel$(change),
            valueLabel: this.getValueLabel$(change, hierarchyElementTypeId),
            isMeasured: of(this._customerClassTypesHash.get(change.customerClassTypeId)[0].isMeasured),
          });
        });

        return forkJoin(translations$);
      })
    );
  }

  private getCustomerClassTypeLabel$(change: SmartMeterChangesDto): Observable<string> {
    const customerClassType = this._customerClassTypesHash.get(change.customerClassTypeId);

    const customerClassTypeName = customerClassType
      ? customerClassType[0]?.customerClassTypeName
      : null;

    return of(customerClassTypeName);
  }

  private getModeLabel$(change: SmartMeterChangesDto): Observable<string> {
    const modeLabel = this._lnuModes.get(change.mode);
    return this._localization.get(`${this.T_SCOPE}.rows.mode`, { mode: modeLabel }).pipe(take(1));
  }

  private getValueLabel$(
    change: SmartMeterChangesDto,
    hierarchyElementTypeId?: string
  ): Observable<string> {
    const dimensionType =
      change.mode == LNUMode.ManualAllowancePerCustomer
        ? DimensionTypesEnum.Flow
        : change.mode == LNUMode.PercentageOfBilling
          ? DimensionTypesEnum.Percentage
          : null;

    if (!dimensionType) {
      return change.value
        ? this._localization
          .get(`${this.T_SCOPE}.rows.value`, { value: change.value })
          .pipe(take(1))
        : of(null);
    }

    return this._uomService
      .getByParams(dimensionType, TimeAggregationEnum.Daily, hierarchyElementTypeId)
      .pipe(
        mergeMap((unit) => {
          if (!change.value) {
            return of(null);
          }

          if (!unit) {
            return this._localization
              .get(`${this.T_SCOPE}.rows.value`, { value: change.value })
              .pipe(take(1));
          }

          const convertedValue = this._utilsHelperService.uomMultiply(
            change.value.toString(),
            unit.conversionFactor.toString()
          );

          return this._localization
            .get(`${this.T_SCOPE}.rows.value`, {
              value: `${convertedValue} ${unit.unitTypeToDescription}`,
            })
            .pipe(take(1));
        })
      )
      .pipe(take(1));
  }
}
