import { GenericAction } from 'src/app/common-modules/redux/models/generic-action';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { DynamicLayoutIdentity } from '../../models/dynamic-layout-identity';
import { DynamicLayoutSettings } from '../../models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '../../models/dynamic-layout-settings-load-options';
import { DynamicSettings } from '../../models/dynamic-settings';
import { WidgetDefinitionSettings } from '../../models/widget-definition-settings';
import { WidgetManagerActionTypes } from './widget-manager.action-types';

abstract class WidgetManagerAction extends GenericAction {
  area = StateAreas.WidgetManager;
}

abstract class SetLayoutAction extends WidgetManagerAction {
  payload: DynamicLayoutSettings;

  constructor(payload: DynamicLayoutSettings, settings = new StateScopeSettings({})) {
    super(settings);

    this.payload = globalUtilsHelper.serializedClone(payload);
    if (this.payload) {
      this.payload.generatedId = globalUtilsHelper.generateGuid();
    }
  }

  get generatedId(): string {
    return this.payload.generatedId;
  }
}

export class SelectWidgetDefinitionAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.SelectWidgetDefinition;

  constructor(public payload: WidgetDefinitionSettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetCurrentLayoutAction extends SetLayoutAction {
  type = WidgetManagerActionTypes.SetCurrentLayout;
}

export class SetLayoutToLoadAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.SetLayoutToLoad;

  constructor(
    public payload: DynamicLayoutSettingsLoadOptions,
    settings = new StateScopeSettings({})
  ) {
    super(settings);
  }
}

export class SetLayoutKeysAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.SetLayoutKeys;

  constructor(public payload: DynamicSettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetCurrentLayoutIdentityAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.SetCurrentLayoutIdentity;

  constructor(public payload: DynamicLayoutIdentity, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetLayoutAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.ResetLayout;

  constructor(public payload: DynamicSettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetWidgetManagerStateAction extends WidgetManagerAction {
  type = WidgetManagerActionTypes.ResetWidgetManagerState;
  payload = undefined;

  constructor(settings = new StateScopeSettings({})) {
    super(settings);
  }
}
