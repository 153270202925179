import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';

@Component({
  selector: 'wlm-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss'],
})
export class CallBackComponent implements OnInit {
  constructor(
    private rightPanelService: RightPanelService,
    private oauthService: OAuthService,
    private router: Router,
    private readonly _authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (!this.checkTokenValidity()) {
      this._authenticationService
        .customLoadDiscoveryDocumentAndTryLogin()
        .catch((exception) => {
          // TODO add error window
          const t = exception;
        })
        .then(this.loginCallBack());
    }
  }

  toggleCollapsed = () => this.rightPanelService.toggleCollapsed();
  toggleVisibility = () => this.rightPanelService.toggleVisibility();
  toggleOverlay = () => this.rightPanelService.toggleOverlay();
  toggleHover = () => this.rightPanelService.toggleHover();

  private checkTokenValidity(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  private loginCallBack(): (value: boolean) => void | PromiseLike<void> {
    return (hasLogged) => {
      if (hasLogged && this.checkTokenValidity()) {
        this.continueWithNavigation();
      }
    };
  }

  private continueWithNavigation() {
    const targetUrl = this.oauthService.state;
    if (targetUrl !== '') {
      this.router.navigate([targetUrl]);
    }
  }
}
