<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        *isSuperUser=""
        color="primary"
        (click)="goToIntegrationPage()"
        [matTooltip]="T_SCOPE + '.integration' | translate | lowercase"
      >
        <wlm-icon [icon]="'integration'"></wlm-icon>
      </button>
      <button
        *ngIf="layoutSettings?.showResetIcon"
        mat-icon-button
        color="primary"
        (click)="onClickResetLayout()"
        [matTooltip]="layoutSettings?.labelReset | lowercase"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <div id="center-page" class="nested-dynamic-layout fit-height" *ngIf="layoutSettings">
      <wlm-dynamic-layout
        [settings]="layoutSettings"
        (layoutReset)="onResetLayout()"
      ></wlm-dynamic-layout>
    </div>
  </ng-container>
</wlm-page-wrapper>
