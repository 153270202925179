<div>
  <wlm-base-filter-container
    *ngIf="adaptersReady"
    class="filters filters-background"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="onGlobalFilterResults($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <wlm-base-filter
      (filterText)="onFilterText($event)"
      [settings]="hefamilyLevelSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-hierarchy-family-filter-item
        [defaultSelectedElements]="filterConfiguration.defaultFamilyId"
        [selectedIds]="[filterConfiguration.selectedFamilyId]"
        [fieldName]="filterConfiguration.hierarchyElementFamilyFieldName"
        [filterText]="filterText"
        [restoreStatusOrder]="1"
        [disableClear]="false"
        [settings]="hierarchyFamilyFilterItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>

      <wlm-hierarchy-elements-filter-item
        [filterText]="filterText"
        [fieldName]="filterConfiguration.hierarchyElementTypeFieldName"
        [familyId]="hfSelectedId"
        [defaultSelectedElements]="filterConfiguration.defaultElementTypes"
        [restoreStatusOrder]="2"
        [selectedIds]="filterConfiguration.selectedElementTypes"
        [settings]="hierarchyLevelFilterItemSettings"
        [filterListElements]="filterConfiguration.heDisplayableLevels"
        [excludeElementTypes]="filterConfiguration.excludeElementTypes"
      ></wlm-hierarchy-elements-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
</div>
