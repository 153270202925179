import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AdditionalHttpOpts } from 'src/app/common-modules/cache/http-cache/additional-http-options';
import { ProfileResultDto } from 'src/app/common-modules/dependencies/alarms/profile-result.dto';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { GenericCartesianChartSettings } from 'src/app/common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { TrendChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { BaseCartesianChartService } from 'src/app/common-modules/wlm-charts/core/services/base-cartesian-chart.service';

import { ProfilesChartSeriesService } from './profiles-chart-series.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilesSaConfigurationChartService extends BaseCartesianChartService {
  constructor(
    injector: Injector,
    private _profilesSeries: ProfilesChartSeriesService,
    private _dateHelper: DateHelperService
  ) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/alarm/profile`;
  }

  public getData(params: TrendChartDataParameters): Observable<ProfileResultDto[]> {
    const requestDatesNoUtc = ['[].periodStartDate', '[].periodEndDate'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post<ProfileResultDto[]>(
      `${this.url}/calculate`,
      params.queryParams,
      this.avoidCache,
      addHttpOptions
    );
  }
  public mapDataToGenericSettings(
    profiles: ProfileResultDto[]
  ): Observable<GenericCartesianChartSettings> {
    return this._profilesSeries.mapProfilesToChartSettings(profiles);
  }

  protected datesToApiFormat<T>(model: T, fields: string[]): T {
    const result = { ...model };
    fields.forEach((field) => {
      if (result[field] && this._dateHelper.isDateObject(result[field])) {
        result[field] = this._dateHelper.toApiFormat(result[field]);
      }
    });
    return result;
  }
}
