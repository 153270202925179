import { IChartDataParameters } from './chart-data-parameters';
export class TrendChartDataParameters implements IChartDataParameters {
  startDate: Date;
  endDate: Date;
  queryParams: any;
  dataService: string;
  scopeKey?: string;

  public constructor(init?: Readonly<TrendChartDataParameters>) {
    Object.assign(this, init);
  }
}
