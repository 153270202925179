<wlm-ne-config-placeholder *ngIf="!currentNE"></wlm-ne-config-placeholder>
<wlm-placeholder-overlay
  *ngIf="currentNE?.isMerging"
  [titleKey]="titleKey"
  [subtitleKey]="subtitleKey"
></wlm-placeholder-overlay>
<wlm-ne-configuration-elements-network-elements
  *ngIf="currentNE"
  [selectedNE]="currentNE"
  class="full-size"
  [pageId]="pageId"
  [widgetId]="widgetId"
></wlm-ne-configuration-elements-network-elements>
