import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridColumnSetting } from '../../shared/model/grid/grid-column-setting';

@Component({
  selector: 'wlm-columns-container',
  templateUrl: './columns-container.component.html',
  styleUrls: ['./columns-container.component.scss'],
})
export class ColumnsContainerComponent implements OnInit {
  @Output() reloadGridEvent = new EventEmitter<void>();

  @Input() currencySymbol: string;
  @Input() column: GridColumnSetting;
  @Input() gridSettings: GridSetting;
  @Input() useCellStyle: boolean;
  @Input() backgroundColorMapping: Map<any, string>;

  @Input() selectedRows: any[];

  constructor() {}

  ngOnInit(): void {}

  reloadGrid() {
    this.reloadGridEvent.next();
  }

  getUnitFormat(columnSetting: GridColumnSetting): string {
    if (!columnSetting.unitFormat || !columnSetting.isCurrencyFormat) {
      return undefined;
    }

    const format = columnSetting.unitFormat.replace('{1}', this.currencySymbol);
    return format;
  }

  headerTextChanged() {}
}
