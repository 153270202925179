import { Pipe, PipeTransform } from '@angular/core';
import { ColumnStyles } from '../../constants/dimensions.constants';

@Pipe({
  name: 'columnStyle',
})
export class ColumnStylePipe implements PipeTransform {
  transform(type: string): Map<string, string> {
    const columnStyle = ColumnStyles.find((x) => x.type === type);
    if (columnStyle) {
      return columnStyle.columnStyleMap;
    } else {
      const defaultColumnStyle = ColumnStyles.find((x) => x.type === 'default')?.columnStyleMap;
      return defaultColumnStyle;
    }
  }
}
