declare type GChartBrushType = 'polygon' | 'rect' | 'lineX' | 'lineY' | 'keep' | 'clear';

export class GChartBrush {
  toolbox?: GChartBrushType[];
  xAxisIndex?: number;

  constructor(init: Readonly<GChartBrush>) {
    Object.assign(this, init);
  }
}

export enum BrushMode {
  Polygon = 'polygon',
  Rect = 'rect',
  Horizontal = 'lineX',
  Vertical = 'lineY',
}
