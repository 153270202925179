// prettier-ignore
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseService } from '../shared/base.service';
import { CT_GLOBAL_SPINNER } from '../shared/interceptors/interceptor-context-tokens';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor extends BaseService implements HttpInterceptor {
  // Disables global spinner.
  private globalDisable = false;

  constructor(injector: Injector, private _loading: SpinnerService) {
    super(injector);
  }

  /**
   * Before sending a request, if spinner overlay is enabled, show it.
   * This spinner is hidden if the request fails or is completed.
   * The spinner only really hides when all current requests are completed.
   */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.setLoading(true, request);
    return next.handle(request).pipe(
      finalize(() => {
        this.setLoading(false, request);
      })
    );
  }

  private setLoading(isLoading, request: HttpRequest<any>): void {
    if (this.globalDisable) {
      return;
    }
    const showSpinner = request.context.get(CT_GLOBAL_SPINNER);
    const isApiUrl = request.url.startsWith(this.apiUrl);
    if (showSpinner && isApiUrl) {
      this._loading.setLoading(isLoading, request.url);
    }
  }
}
