// prettier-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';

const COMPONENT_SELECTOR = 'wlm-date-time-range';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-time-range.component.html',
  styleUrls: ['./date-time-range.component.scss'],
})
export class DateTimeRangeComponent implements OnInit {
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Input() displayHorizontal = false;
  private _dateRange: DateRange;
  public get dateRange(): DateRange {
    return this._dateRange;
  }
  @Input() public set dateRange(v: DateRange) {
    this._dateRange = v;
    this.dateRangeValues = v;
    this.initializeFormGroup();
  }
  @Input() valueDebounceTime = 1000;

  @Output() dateRangeChanged = new EventEmitter<DateRange>();
  @Output() dateRangeErrors = new EventEmitter<ValidationErrors>();

  formClass: string;
  dateRangeForm: UntypedFormGroup;
  dateRangeValues: DateRange;
  rangeSeparator = '  -  ';
  disabled = false;
  showSpinners = true;
  showSeconds = false;
  touchUi = false;
  enableMeridian = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  color: ThemePalette = 'primary';

  private _startFieldName = 'start';
  private _endFieldName = 'end';

  constructor(private _formBuilder: UntypedFormBuilder, public localization: LocalizationHelperService) {}

  ngOnInit(): void {
    this.formClass = this.displayHorizontal ? 'date-range-form-horizontal' : 'date-range-form';
  }

  private initializeFormGroup() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    if (this.dateRange) {
      const startControl = this.getDateControl(this.dateRange?.start);
      const endControl = this.getDateControl(this.dateRange?.end);

      startControl.addValidators(this.validateGreaterThan(startControl, endControl));
      endControl.addValidators(this.validateGreaterThan(startControl, endControl));

      formControls[this._startFieldName] = startControl;
      formControls[this._endFieldName] = endControl;
    }

    this.dateRangeForm = this._formBuilder.group(formControls);
  }

  private validateGreaterThan(firstControl: UntypedFormControl, secondControl: UntypedFormControl) {
    return () => {
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (firstValue && secondValue && secondValue < firstValue) {
        return { invalidRangePair: true };
      }
      return null;
    };
  }

  private getDateControl(value) {
    const dateControl = new UntypedFormControl(value, [Validators.required]);

    dateControl.valueChanges
      .pipe(debounceTime(this.valueDebounceTime), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((x) => {
        this.dateRangeChange();
      });

    return dateControl;
  }

  private dateRangeChange(): void {
    const isValid = this.checkIsValid();

    const errorsObj = { ...this.startCtrl.errors, ...this.endCtrl.errors };
    const errors = Object.keys(errorsObj).length === 0 ? null : errorsObj;

    this.dateRangeErrors.emit(errors);

    if (isValid) {
      let start = this.startCtrl?.value;
      let end = this.endCtrl?.value;

      this.dateRangeValues = new DateRange(start, end);
      this.dateRangeChanged.emit(this.dateRangeValues);
    }
  }

  private checkIsValid(): boolean {
    const start = this.startCtrl;
    const end = this.endCtrl;
    start.updateValueAndValidity();
    end.updateValueAndValidity();

    return start.valid && end.valid;
  }

  private get startCtrl() {
    return this.dateRangeForm.get(this._startFieldName);
  }

  private get endCtrl() {
    return this.dateRangeForm.get(this._endFieldName);
  }
}
