import { GenericExtendedSelector } from 'src/app/common-modules/redux/models/generic-extended-selector';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';

export enum GenericSelectorTypes {
  GetValue = '[Generic] Get Value',
}

export class GetValueSelector extends GenericExtendedSelector {
  area = StateAreas.Generic;
  type = GenericSelectorTypes.GetValue;
}
