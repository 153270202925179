<div class="filters">
  <wlm-base-filter
    *ngIf="treeSettings !== null && raiseActivityTreeFilterConfiguration"
    [settings]="bfSettings"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    [persistItems$]="persistFilters$"
    (filterText)="onFilterText($event)"
    (apply)="onApplyFilters()"
    (filter)="onFilterGroup($event)"
    (clearAll)="onClearAll()"
  >
    <wlm-hierarchy-family-filter-item
      [fieldName]="raiseActivityTreeFilterConfiguration.hierarchyElementFamilyFieldName"
      [selectedIds]="[raiseActivityTreeFilterConfiguration.hierarchyElementFamily]"
      [filterText]="filterText"
      [settings]="hierarchyFamilyItemSettings"
      (select)="onFamilyIdSelect($event)"
    >
    </wlm-hierarchy-family-filter-item>
    <wlm-hierarchy-tree-filter-item
      [hierarchyElementIdFieldName]="
        raiseActivityTreeFilterConfiguration.hierarchyElementIdFieldName
      "
      [hierarchyFamilyIdFieldName]="
        raiseActivityTreeFilterConfiguration.hierarchyElementFamilyFieldName
      "
      [hierarchyFamilyId]="hierarchyElementFamilyId"
      [initialSelectedKeys]="raiseActivityTreeFilterConfiguration.hierarchyElementId"
      [filterText]="filterText"
      [(treeSettings)]="treeSettings"
      [settings]="hierarchyTreeItemSettings"
      [(selectedNodes)]="selectedNodes"
      (filterReady)="onTreeFilterReady()"
    ></wlm-hierarchy-tree-filter-item>
  </wlm-base-filter>
</div>
