import { Observable } from 'rxjs';
import { BaseService } from '../../shared/base.service';

import { DynamicFormSettings } from '../models/dynamic-form-settings';

export abstract class DynamicFormParserService extends BaseService {
  abstract parseForm(
    fields: unknown,
    dependencies: unknown,
    settings: DynamicFormSettings
  ): Observable<unknown>;
}
