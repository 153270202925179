import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { HierarchyElementPressureSignalDto } from '../../shared/model/signals/hierarchy-element-pressure-signal.dto';
import { PressureSignalConfigurationDto } from '../../shared/model/signals/pressure-signal-configuration.dto';
import { PressureSignalDto } from '../../shared/model/signals/pressure-signal.dto';

const endpointUrl = '/api/pressure';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationPressureService extends GridODataService<PressureSignalDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<PressureSignalDto>
  ): PagedResultDto<PressureSignalDto> {
    return response;
  }

  getPressureConfigurationByZone(
    elementId: string
  ): Observable<HierarchyElementPressureSignalDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${elementId}`, {
      avoid: true,
    });
  }

  savePressureConfiguration(
    pressureConfiguration: PressureSignalConfigurationDto
  ): Observable<boolean> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/save`, pressureConfiguration);
  }
}
