import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { UserDto } from 'src/app/common-modules/shared/model/roles/user.dto';
import { UsersGridODataService } from 'src/app/common-modules/shared/services/users/users.service';

const COMPONENT_SELECTOR = 'wlm-create-user';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  form: UntypedFormGroup;
  maxSize = 250;
  validationErrorMessage: string;

  public get formTitle(): string {
    return `${this.T_SCOPE}.title`;
  }

  isSaving = false;
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateUserComponent>,
    private _userService: UsersGridODataService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      userCode: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      userSurname: ['', [Validators.required]],
    });
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;

      const userCode = this.form.get('userCode').value;
      const userName = this.form.get('userName').value;
      const userSurname = this.form.get('userSurname').value;

      const newUser = new UserDto();
      (newUser.userCode = userCode), (newUser.name = userName), (newUser.surname = userSurname);

      this._userService.createUser(newUser).subscribe({
        next: (createdUser) => {
          this.isSaving = false;
          this.close();
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.user-succesfull-creation-message`,
            })
          );
        },
        error: (error: HttpErrorResponse) => {
          this.isSaving = false;
          if (error.status === 409) {
            this.form.get('userCode').setErrors({ userCodeAlreadyExists: true });
          } else {
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({
                translateKey: `${this.T_SCOPE}.user-fail-creation-message`,
                icon: 'error',
              })
            );
          }
        },
      });
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  closeWithoutSaving() {
    this.dialogRef.close(false);
  }

  removeUserValidation() {}
}
