<div class="events-container" #heightElement *ngIf="!loading && dataLoaded">
  <wlm-generic-chart
    *ngIf="customChartSettings"
    [ngStyle]="{ height: chartHeigth + 'px' }"
    [disableFixedSize]="false"
    [height]="chartHeigth"
    [settings]="customChartSettings"
    (chartClick)="onChartClick($event)"
    (chartDataZoom)="onChartDataZoom($event)"
  >
  </wlm-generic-chart>
</div>

<span #heightElement>
  <wlm-chart-legend
    *ngIf="!loading && dataLoaded && legendItems && legendItems.length"
    [items]="legendItems"
  >
  </wlm-chart-legend>
</span>

<span #heightElement>
  <wlm-no-results [showNoResult]="!loading && !dataLoaded"></wlm-no-results>
</span>
