import { WtrColors } from '../../shared/styles/wtr-colors';

export class GenericCardSettings<T> {
  title1Fn: (model: T) => string;
  title2Fn: (model: T) => string;
  subtitleFn: (model: T) => string;
  extraInfoFn: (model: T) => string;
  leftIconNameFn: (model: T) => string;
  rightIconNameFn: (model: T) => string;
  isReadOnly = false;
  useTitleCentered = false;
  clickCallback?: (selectedItem: any) => any;
  // If true, the component does not keep empty spaces for the missing properties.
  resizeToContent? = true;

  //Name of the field used to mark the card as error
  errorField?: string;

  iconColor?: WtrColors = WtrColors.NeutralColorBlack2;

  constructor(init?: Partial<GenericCardSettings<T>>) {
    Object.assign(this, init);
  }
}
