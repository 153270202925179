import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { NavKeys } from '../../../common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '../../../common-modules/shared/auth/services/auth.guard';
import { TransmissionNetworkPageComponent } from './transmission-network-page/transmission-network-page.component';

const routes: Routes = [
  {
    path: 'transmission-network/grid',
    component: TransmissionNetworkPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.TransmissionNetwork,
    },
  },
  { path: '**', redirectTo: 'transmission-network/grid' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransmissionNetworkRoutingModule {}
