import { Component, Input, OnInit } from '@angular/core';
import { HistoricalBar } from './models/historical-bar';
import { HistoricalBarSettings } from './models/historical-bar-settings';

const COMPONENT_SELECTOR = 'wlm-historical-bars';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './historical-bars.component.html',
  styleUrls: ['./historical-bars.component.scss'],
})
export class HistoricalBarsComponent implements OnInit {
  @Input() titleKey: string;
  @Input() limit: number;

  private _items: boolean[];
  public get items(): boolean[] {
    return this._items;
  }
  @Input() public set items(value: boolean[]) {
    this._items = value;
    this.generateHistoricalBars();
  }

  private _historicalSettings: HistoricalBarSettings;
  get historicalSettings(): HistoricalBarSettings {
    return this._historicalSettings;
  }
  @Input() set historicalSettings(value: HistoricalBarSettings) {
    this._historicalSettings = value;
    this.titleKey = value.titleKey;
    this.limit = value.limit;
    this.items = value.items;
  }

  private readonly _successColor = '#C2CD00';
  private readonly _errorColor = '#E70505';
  private readonly _defaultColor = '#A6B0B4';

  historicals: HistoricalBar[];

  constructor() {}

  ngOnInit(): void {
    this.generateHistoricalBars();
  }

  private generateHistoricalBars() {
    this.historicals = [];

    // Starting from zero because items are already ordered by asc,
    // so latest ones will be displayed at right
    for (let index = 0; index < this.limit; index++) {
      let color = this._defaultColor;

      switch (this.items[index]) {
        case true:
          color = this._successColor;
          this.historicals.push(new HistoricalBar({ color }));
          break;

        case false:
          color = this._errorColor;
          this.historicals.push(new HistoricalBar({ color }));
          break;

        default:
          // If no item for the index, the bar is displayed at first position
          this.historicals.unshift(new HistoricalBar({ color }));
          break;
      }
    }
  }
}
