import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BiFilters } from 'src/app/common-modules/dependencies/bi/bi-filters';
import { WaterBalanceTypesEnum } from 'src/app/common-modules/dependencies/water-balance/water-balance-types';
import { DynamicLayoutItemLockedFilters } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-item-locked-filters';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import {
  PieChartWidgetParams,
  TrendChartWidgetParams,
  WaterBalanceKpisWidgetParams,
  WidgetDefinitionSettings,
} from 'src/app/common-modules/dynamic-layout/models/widget-definition-settings';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { WaterBalanceKPIEnum } from '../../shared/model/water-balance/water-balance-kpi-types';
import { BiSaveTemplateDto } from '../models/bi-save-template.dto';
import { CreateDashboardResultDto } from '../models/create-template-result.dto';

const trendChartWidget = {
  componentName: 'TrendChartWidgetComponent',
  widgetInstanceKey: '232434345423135asd4553454',
  scopeInstanceKeys: {
    [StateAreas.Filters]: 'shared',
    [StateAreas.DefaultParams]: 'shared',
  },
  title: 'Chart',
  hasDefaultFilters: true,
  paramsType: { widgetType: 'TrendChartWidgetComponent', type: 'WaterBalance' },
};

const pieChartWidget = {
  componentName: 'PieChartWidgetComponent',
  widgetInstanceKey: '23243434sd5423135asd4553454',
  scopeInstanceKeys: {
    [StateAreas.Filters]: 'shared',
    [StateAreas.DefaultParams]: 'shared',
  },
  title: 'Pie Chart',
  hasDefaultFilters: true,
};

const mapWidget = {
  componentName: 'MapWidgetComponent',
  widgetInstanceKey: '23243434sd5423135asd45533454',
  scopeInstanceKeys: {
    [StateAreas.Filters]: 'shared',
    [StateAreas.DefaultParams]: 'shared',
  },
  title: 'Map',
  hasDefaultFilters: false,
};

const WbWidget = {
  componentName: 'WaterBalanceKpisWidgetComponent',
  widgetInstanceKey: '9392923',
  scopeInstanceKeys: {
    [StateAreas.Filters]: 'shared',
    [StateAreas.DefaultParams]: 'shared',
  },
  title: 'Water Balance',
  hasDefaultFilters: true,
  paramsType: { widgetType: 'WaterBalanceKpisWidgetComponent', type: 'WaterBalance' },
};

@Injectable()
export class BiService extends BaseService {
  // Filters fieldNames
  readonly filters = {
    fieldNames: {
      hierarchyFamilyId: 'HierarchyFamilyId',
      hierarchyElementId: 'HierarchyElementId',
      startDate: 'StartDate',
      endDate: 'EndDate',
    },
  };

  constructor(
    injector: Injector,
    private _authenticationService: AuthenticationService,
    private _objectHelperService: ObjectHelperService,
    private _localization: LocalizationHelperService
  ) {
    super(injector);
  }

  private get url() {
    return `${this.apiUrl}/bi/dashboard`;
  }

  /**
   * Obtains the current BI selected for the user.
   */
  getConfiguredDashboard(keys: DynamicSettings): Observable<DynamicLayoutSettings> {
    return this.httpCacheClient
      .get<DynamicLayoutSettings>(`${this.url}/${keys.settingArea}/${keys.settingKey}`)
      .pipe(switchMap((settings) => (settings ? of(settings) : this.getEmptyDashboard(keys))));
  }

  getHomeDashboard(keys: DynamicSettings): Observable<DynamicLayoutSettings> {
    return this.httpCacheClient
      .get<DynamicLayoutSettings>(`${this.url}/home`)
      .pipe(switchMap((settings) => (settings ? of(settings) : this.getEmptyDashboard(keys))));
  }

  getEmptyDashboard(keys: DynamicSettings): Observable<DynamicLayoutSettings> {
    const defaultSettings = new DynamicLayoutSettings({
      layoutKey: keys?.settingKey ?? 'BIWidgetsContainer',
      layoutArea: keys?.settingArea ?? 'BI',
      currentUser: this._authenticationService.userCode,
      widgetPage: 'Dashboard',
      widgetModule: 'BI',
      items: [],
      showCloseIcon: true,
      structure: [],
      showCloseWidgetIcon: true,
    });

    return of(defaultSettings);
  }

  createTemplate({
    data,
  }: {
    data: Partial<BiSaveTemplateDto>;
  }): Observable<CreateDashboardResultDto> {
    return this.httpCacheClient.post(this.url, data);
  }

  /**
   * Updates the dashboard flags, the definition, etc.
   */
  updateTemplate(templateId: number, templateDto: Partial<BiSaveTemplateDto>): Observable<void> {
    const data = {
      ...templateDto,
      templateId,
    };
    return this.httpCacheClient.post(`${this.url}/${templateId}`, data);
  }

  /**
   * Updates only the dashboard definition itself (DynamicLayoutSetting).
   */
  updateTemplateDefinition(templateId: string, template: DynamicLayoutSettings): Observable<void> {
    const templateCloned = this._objectHelperService.serializedClone(template);
    const templateDefinition = JSON.stringify(templateCloned);

    const saveTemplate = new BiSaveTemplateDto({
      bidashboardTemplateId: +templateId,
      template: templateDefinition,
      templateName: template.title,
      isDashboardDefault: false,
      isHomepageDefault: false,
      isSystemDefault: false,
      isSystemTemplate: false,
    });

    const data = {
      ...saveTemplate,
      templateId,
    };

    return this.httpCacheClient.post(`${this.url}/${+templateId}/definition`, data);
  }

  updateSystemTemplate(
    templateId: number,
    templateDto: Partial<BiSaveTemplateDto>
  ): Observable<void> {
    const data = {
      ...templateDto,
      templateId,
    };
    return this.httpCacheClient.post(`${this.url}/${templateId}/system`, data);
  }

  getTemplate(templateId: number): Observable<any> {
    return this.httpCacheClient
      .get(`${this.url}/${templateId}`)
      .pipe(map((data) => (typeof data === 'string' ? JSON.parse(data) : data)));
  }

  deleteTemplate(templateId: number): Observable<void> {
    return this.httpCacheClient.delete(`${this.url}/${templateId}`);
  }

  /**
   * Apply locks and return the actual filters to use.
   */
  applyFilterLocks(
    currentFilters: BiFilters,
    pageFilters: BiFilters,
    lockFilters: DynamicLayoutItemLockedFilters
  ): BiFilters {
    // TODO: What to do with DynamicLayoutItemSettings.hasDefaultFilters?

    const clone = globalUtilsHelper.clone;
    let resultFilters: BiFilters = clone(pageFilters, true);
    if (lockFilters) {
      if (lockFilters.dates) {
        resultFilters.selectedDateRange = clone(currentFilters.selectedDateRange, true);
      }
      if (lockFilters.hierarchy) {
        resultFilters.selectedFamily = clone(currentFilters.selectedFamily, true);
        resultFilters.selectedIds = clone(currentFilters.selectedIds, true);
        resultFilters.selectedElements = clone(currentFilters.selectedElements, true);
      }
    }

    return resultFilters;
  }

  /**
   * Obtains all KPIs that the current user can select and build.
   */
  getAvailableKpis(): Observable<WidgetDefinitionSettings[]> {
    const groupsTranslationsKey = 'bi.bi-widgets.groups';
    const widgetsTranslationsKey = 'bi.bi-widgets.widgets';

    return this._localization.get([groupsTranslationsKey, widgetsTranslationsKey]).pipe(
      map((translations) => {
        const tsWidgetLabel = (key) => {
          try {
            const ts = translations[widgetsTranslationsKey][key];
            if (!ts) {
              return key;
            }
            return translations[widgetsTranslationsKey][key]['menu-title'];
          } catch {
            return widgetsTranslationsKey + key;
          }
        };

        const tsWidgetTitle = (key) => {
          try {
            return translations[widgetsTranslationsKey][key]['tab-title'];
          } catch {
            return widgetsTranslationsKey + key;
          }
        };

        const tsGroup = (key) => {
          try {
            return translations[groupsTranslationsKey][key];
          } catch {
            return groupsTranslationsKey + key;
          }
        };

        const settings: WidgetDefinitionSettings[] = [
          {
            label: tsWidgetLabel('water-balance'),
            widgetSettings: {
              ...WbWidget,
              title: tsWidgetTitle('water-balance'),
              baseTitle: tsWidgetTitle('water-balance'),
              params: {
                dimensionTypeId: DimensionTypesEnum.Volume,
                waterBalanceType: WaterBalanceTypesEnum.TopDown,
              } as WaterBalanceKpisWidgetParams,
            },
            group: tsGroup('water-balance'),
            icon: 'view_quilt',
            isSvgIcon: false,
          },
          {
            label: tsWidgetLabel('distribution-input'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('distribution-input'),
              baseTitle: tsWidgetTitle('distribution-input'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.SystemInputVolume],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('distribution-input'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('per-capita-consumption'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('per-capita-consumption'),
              baseTitle: tsWidgetTitle('per-capita-consumption'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'elementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'MVCVChartService',
                staticQueryParams: {
                  algorithms: ['DPERCC'],
                },
              } as TrendChartWidgetParams,
              paramsType: null,
            },
            group: tsGroup('distribution-input'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('water-loss'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('water-loss'),
              baseTitle: tsWidgetTitle('water-loss'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.WaterLosses],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('water-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('water-loss-per-main-length'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('water-loss-per-main-length'),
              baseTitle: tsWidgetTitle('water-loss-per-main-length'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.WaterLossPerMainLength],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('water-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },

          {
            label: tsWidgetLabel('water-loss-per-connection'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('water-loss-per-connection'),
              baseTitle: tsWidgetTitle('water-loss-per-connection'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.WaterLossPerConnection],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('water-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('water-loss-as-of-di'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('water-loss-as-of-di'),
              baseTitle: tsWidgetTitle('water-loss-as-of-di'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Percentage,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.WaterLossPercentageDI],
                },
              } as TrendChartWidgetParams,
              paramsType: {
                ...trendChartWidget.paramsType,
                readonlyFields: ['dimensionTypeId'],
              },
            },
            group: tsGroup('water-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('hydraulic-performance'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('hydraulic-performance'),
              baseTitle: tsWidgetTitle('hydraulic-performance'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Percentage,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.HydraulicPerformance],
                },
              } as TrendChartWidgetParams,
              paramsType: {
                ...trendChartWidget.paramsType,
                readonlyFields: ['dimensionTypeId'],
              },
            },
            group: tsGroup('water-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('real-loss'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('real-loss'),
              baseTitle: tsWidgetTitle('real-loss'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.RealLosses],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('real-loss-per-main-length'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('real-loss-per-main-length'),
              baseTitle: tsWidgetTitle('real-loss-per-main-length'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.RealLossPerMainLength],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('real-loss-per-connection'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('real-loss-per-connection'),
              baseTitle: tsWidgetTitle('real-loss-per-connection'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.RealLossPerConnection],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('real-loss-as-of-di'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('real-loss-as-of-di'),
              baseTitle: tsWidgetTitle('real-loss-as-of-di'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Percentage,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.RealLossPercentageDI],
                },
              } as TrendChartWidgetParams,
              paramsType: {
                ...trendChartWidget.paramsType,
                readonlyFields: ['dimensionTypeId'],
              },
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('ili'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('ili'),
              baseTitle: tsWidgetTitle('ili'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.NA,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.ILI],
                },
              } as TrendChartWidgetParams,
              paramsType: {
                ...trendChartWidget.paramsType,
                readonlyFields: ['dimensionTypeId'],
              },
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('carl'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('carl'),
              baseTitle: tsWidgetTitle('carl'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Flow,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.CARL],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('uarl'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('uarl'),
              baseTitle: tsWidgetTitle('uarl'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Flow,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.UARL],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('real-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('apparent-loss'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('apparent-loss'),
              baseTitle: tsWidgetTitle('apparent-loss'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.AparentLosses],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('apparent-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('apparent-loss-per-main-length'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('apparent-loss-per-main-length'),
              baseTitle: tsWidgetTitle('apparent-loss-per-main-length'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.ApparentLossPerMainLength],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('apparent-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('apparent-loss-per-connection'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('apparent-loss-per-connection'),
              baseTitle: tsWidgetTitle('apparent-loss-per-connection'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.ApparentLossPerConnection],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('apparent-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('apparent-loss-as-of-di'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('apparent-loss-as-of-di'),
              baseTitle: tsWidgetTitle('apparent-loss-as-of-di'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Percentage,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.ApparentLossPercentageDI],
                },
              } as TrendChartWidgetParams,
              paramsType: {
                ...trendChartWidget.paramsType,
                readonlyFields: ['dimensionTypeId'],
              },
            },
            group: tsGroup('apparent-loss'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('non-revenue-water'),
            widgetSettings: {
              ...trendChartWidget,
              title: tsWidgetTitle('non-revenue-water'),
              baseTitle: tsWidgetTitle('non-revenue-water'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'hierarchyElementIds',
                  HierarchyFamilyId: 'familyId',
                  StartDate: 'startDate',
                  EndDate: 'endDate',
                },
                eventChartCategories: [],
                dataService: 'WaterBalanceChartService',
                staticQueryParams: {
                  dimensionTypeId: DimensionTypesEnum.Volume,
                  waterBalanceType: WaterBalanceTypesEnum.TopDown,
                  kpis: [WaterBalanceKPIEnum.NonRevenueWater],
                },
              } as TrendChartWidgetParams,
            },
            group: tsGroup('financial'),
            icon: 'chart',
            isSvgIcon: true,
          },
          {
            label: tsWidgetLabel('pipe-material'),
            widgetSettings: {
              ...pieChartWidget,
              title: tsWidgetTitle('pipe-material'),
              baseTitle: tsWidgetTitle('pipe-material'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'zoneIds',
                },
                eventChartCategories: [],
                dataService: 'MultiplePipePieChartService',
                staticQueryParams: {
                  columnName: 'materialGroup',
                  columnToOrder: 'materialGroup',
                },
              } as PieChartWidgetParams,
            },
            group: tsGroup('zone-information'),
            icon: 'pie_chart',
            isSvgIcon: false,
          },
          {
            label: tsWidgetLabel('pipe-age'),
            widgetSettings: {
              ...pieChartWidget,
              title: tsWidgetTitle('pipe-age'),
              baseTitle: tsWidgetTitle('pipe-age'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'zoneIds',
                },
                eventChartCategories: [],
                dataService: 'MultiplePipePieChartService',
                staticQueryParams: {
                  columnName: 'ageGroup',
                  columnToOrder: 'ageOrder',
                },
              } as PieChartWidgetParams,
            },
            group: tsGroup('zone-information'),
            icon: 'pie_chart',
            isSvgIcon: false,
          },
          {
            label: tsWidgetLabel('pipe-diameter'),
            widgetSettings: {
              ...pieChartWidget,
              title: tsWidgetTitle('pipe-diameter'),
              baseTitle: tsWidgetTitle('pipe-diameter'),
              params: {
                queryParamFieldsMap: {
                  HierarchyElementId: 'zoneIds',
                },
                eventChartCategories: [],
                dataService: 'MultiplePipePieChartService',
                staticQueryParams: {
                  columnName: 'diameterGroup',
                  columnToOrder: 'diameterOrder',
                },
              } as PieChartWidgetParams,
            },
            group: tsGroup('zone-information'),
            icon: 'pie_chart',
            isSvgIcon: false,
          },
          {
            label: tsWidgetLabel('map'),
            widgetSettings: {
              ...mapWidget,
              title: tsWidgetTitle('map'),
              baseTitle: tsWidgetTitle('map'),
            },
            group: tsGroup('map'),
            icon: 'map__',
            isSvgIcon: true,
          },
        ];

        return settings;
      })
    );
  }
}
