import { ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { BaseService } from '../../shared/base.service';
import { NotificationEditPopupDimensions } from '../../shared/constants/dimensions.constants';
import { NotificationPopupDto } from '../../shared/model/shared/notification-popup.dto';

@Injectable({ providedIn: 'root' })
export class NotificationPopupService extends BaseService {
  private _refreshNotifications$ = new ReplaySubject<void>(1);
  readonly refreshNotifications$ = this._refreshNotifications$.asObservable();

  constructor(injector: Injector, private _matDialog: MatDialog) {
    super(injector);
  }

  openPopup(
    popupData: NotificationPopupDto,
    component: ComponentType<any>
  ): MatDialogRef<any, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = NotificationEditPopupDimensions.Width;
    dialogConfig.height = NotificationEditPopupDimensions.Height;

    dialogConfig.data = popupData;
    const popup = this._matDialog.open(component, dialogConfig);
    popup.afterClosed().subscribe((data) => {
      if (data) {
        this._refreshNotifications$.next();
      }
    });
    return popup;
  }
}
