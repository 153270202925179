import { Component, Input, OnInit } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-collapsible-container';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './collapsible-container.component.html',
  styleUrls: ['./collapsible-container.component.scss'],
})
export class CollapsibleContainerComponent implements OnInit {
  @Input() titleKey: string;
  @Input() show = true;

  constructor() {}

  ngOnInit(): void {}

  onToggle(): void {
    this.show = !this.show;
  }
}
