import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FragmentsManagerService } from '../services/fragments-manager.service';

/**
 * Use fragments to externally triggering angular navigations from the url, so a full reload is avoided.
 * Allows forwarding query params by prefixing them with navigateWith_ inside the fragments hash.
 * Example:
 *  #navigateTo=alc/prioritisation/grid&navigateWith_hierarchyElementIds=MY_ELEMENT&navigateWith_hierarchyFamilyId=MY_ID
 */

@Injectable()
export class ExternalNavigationService {
  private readonly _navigateToKey = 'navigateTo';
  private readonly _navigateWithParamsKey = 'navigateWith_';
  private readonly _fragmentsManagerService = inject(FragmentsManagerService);
  private _router = inject(Router);
  private _destroyRef = inject(DestroyRef);

  init(): void {
    this._fragmentsManagerService.fragmentsData$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        if (data) {
          const uriToNavigate = data[this._navigateToKey];
          if (uriToNavigate) {
            const queryParams = this.getQueryParamsToForward(data);
            this._router.navigate([uriToNavigate], { queryParams });
          }
        }
      });
  }

  private getQueryParamsToForward(fragmentsHash: { [key: string]: any }) {
    let queryParams = {};

    Object.keys(fragmentsHash).forEach((key) => {
      if (key.startsWith(this._navigateWithParamsKey)) {
        queryParams[key.replace(this._navigateWithParamsKey, '')] = fragmentsHash[key];
      }
    });

    return queryParams;
  }
}
