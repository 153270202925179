import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { NotificationService } from '../../services/notification.service';

const COMPONENT_SELECTOR = 'wlm-notification-details-by-id';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-details-by-id.component.html',
  styleUrls: ['./notification-details-by-id.component.scss'],
})
export class NotificationDetailsByIdComponent extends BaseWidgetComponent implements OnInit {
  pageId: string;
  notificationId: string;
  notification: NotificationDto;

  isLoading = false;

  get componentName(): string {
    return 'NotificationDetailsByIdComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _nfService: NotificationService,
    private readonly _rightPanelService: RightPanelService
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.pageId = parameters?.parameters.get(TabDetailParameterName.pageId);
    this.notificationId = parameters.parameters.get(TabDetailParameterName.notificationId);
  }

  init(): void {
    this.loadNotification();
  }

  onSubmitResult(_): void {
    const map = new Map<any, string>();
    this._rightPanelService.rightPanelCallback.next(
      map.set(NotificationDetailsByIdComponent, 'refresh')
    );
    this.loadNotification();
  }

  private loadNotification() {
    if (this.notificationId) {
      this.isLoading = true;
      this._nfService.getById(this.notificationId).subscribe({
        next: (notification) => {
          this.notification = notification;
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      });
    } else {
      this.notification = null;
    }
  }

  onLoadedChanged(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
