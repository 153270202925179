import { TextOptionsLight } from 'jspdf';

export class ExportPdfDocument {
  items: PdfExportItem[];
  filename: string;
  title?: string;
  width: number = 180;
  height: number = 160;
  unit?: 'pt' | 'px' | 'in' | 'mm' | 'cm' | 'ex' | 'em' | 'pc' = 'mm';
  orientation: 'portrait' | 'landscape' = 'portrait';

  constructor(init: Partial<ExportPdfDocument>) {
    Object.assign(this, init);
  }
}

export class PdfExportItem {
  type: 'image' | 'text';
  data: string;
  fontSize?: number;
  xCoords?: number = 15;
  yCoords?: number = 40;
  textOptions?: TextOptionsLight;

  constructor(init: Partial<PdfExportItem>) {
    Object.assign(this, init);
  }
}
