<div class="calendar-card-container">
  <div class="col-1">
    {{ exception.title }}
  </div>
  <div class="col-2">
    {{ exception.value }}
  </div>

  <div>
    <button
      *hasPerm="['WLMCalendarsCrud', 'u']"
      [matTooltip]="T_SCOPE_TYPES + '.labels.delete-exception' | translate | lowercase"
      mat-icon-button
      color="primary"
      onclick="this.blur()"
      (click)="deleteException()"
    >
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </div>
</div>
