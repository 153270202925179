import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class HierarchyFamilyFISettings extends BaseFilterItemSettings {
  fieldName: string;
  selectedId: string;

  constructor(init?: Readonly<HierarchyFamilyFISettings>) {
    super(init);
    Object.assign(this, init);
  }
}
