<wlm-placeholder-overlay
  *ngIf="!(selectedWorkspace && chartConfiguration)"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-data-visualization-config-form
  *ngIf="selectedWorkspace && chartConfiguration"
  [isEditMode]="false"
  [isLoadMode]="true"
  [chartConfiguration]="chartConfiguration"
  [workspace]="selectedWorkspace"
  [saveConfig$]="saveConfig$"
  (hasValidChanges)="onErrorChanged($event)"
  (save)="onSave($event)"
></wlm-data-visualization-config-form>
