/*
 * Contain all the additional data required to parse the definition.
 */

import { UnitTypeConversionViewDto } from '../../shared/model/uom/unit-type-conversion-view.dto';

export class FieldParseDependencies {
  unitTypes: UnitTypeConversionViewDto[];
  hierarchyElementTypeId: string;

  constructor(init: FieldParseDependencies) {
    Object.assign(this, init);
  }
}
