import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { IAuditAttribute } from '../../models/audit-attribute';
import { AuditsService } from '../../services/audits.service';

const COMPONENT_SELECTOR = 'wlm-audit-attributes';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './audit-attributes.component.html',
  styleUrls: ['./audit-attributes.component.scss'],
})
export class AuditAttributesComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;

  private _auditId: string;
  public get auditId(): string {
    return this._auditId;
  }
  @Input()
  public set auditId(value: string) {
    this._auditId = value;

    if (value) {
      this.getAuditAttributes();
    }
  }

  get componentName(): string {
    return 'AuditAttributesComponent';
  }

  auditAttributes: IAuditAttribute[];

  constructor(private _spinnerService: SpinnerService, private _auditsService: AuditsService) {}

  ngOnInit(): void {}

  private getAuditAttributes() {
    this.setLoading(true);

    this._auditsService
      .getAuditAttributes(this.auditId)
      .pipe(
        finalize(() => this.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.auditAttributes = result;
      });
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }
}
