import { ListRange } from '@angular/cdk/collections';
import {
  CdkScrollable,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { AppModules } from '../../app-modules.enum';
import { DataBindingFilters } from '../../filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '../../helpers/array-helper.service';
import { ObjectHelperService } from '../../helpers/object-helper.service';
import { StringHelperService } from '../../helpers/string-helper.service';
import { GridODataService } from '../../odata/grid-odata.service';
import { SelectDragListSettings } from './select-drag-list-settings';

const COMPONENT_SELECTOR = 'wlm-select-drag-list-virtual';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './select-drag-list-virtual.component.html',
  styleUrls: ['./select-drag-list-virtual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDragListVirtualComponent implements OnInit {
  @ViewChild(CdkVirtualForOf, { static: true })
  private virtualForOf: CdkVirtualForOf<any[]>;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('card') card: ElementRef;

  @ContentChild('card', { static: false }) cardTemplateRef: TemplateRef<any>;

  @Input() removeSelection$ = new Subject<void>();
  @Input() refreshList$ = new Subject<void>();
  @Input() reloadList$ = new Subject<void>();

  private _settings: SelectDragListSettings;
  public get settings(): SelectDragListSettings {
    return this._settings;
  }
  @Input() public set settings(value: SelectDragListSettings) {
    if (!value) {
      return;
    }
    this._settings = value;

    if (value.oDataFilters?.size || value.oDataFiltersExtended) {
      this.populateStaticODataFilters();
    }

    this.generateDragListId();

    if (this._settings.dataService) {
      this.instanceDataService();
    }
  }

  get componentName(): string {
    return 'SelectDragListVirtualComponent';
  }

  private _queryParams: Map<string, any>;
  public get queryParams(): Map<string, any> {
    return this._queryParams;
  }
  @Input() public set queryParams(value: Map<string, any>) {
    this.resetList();
    this._queryParams = value;
    this.nextSearchPage(this._searchPageNumber);
    this._changeDetector.detectChanges();
  }

  private _excludeList: any[] = [];
  public get excludeList(): any[] {
    return this._excludeList;
  }
  @Input() public set excludeList(value: any[]) {
    if (this._objectHelper.deepEqual(value, this._excludeList)) {
      return;
    }

    this._excludeList = value;
    this.excludeListChange.emit(value);
    this.reloadWithoutQuery();
    if (this.items.length === 0) {
      this.nextSearchPage(this._searchPageNumber);
    }
  }

  private _itemsData: any[] = [];
  public get itemsData(): any[] {
    return this._itemsData;
  }
  @Input() public set itemsData(value: any[]) {
    this._itemsData = value;
    this._searchPageNumber = 0;
    this.nextSearchPage(this._searchPageNumber);
  }

  @Output() excludeListChange = new EventEmitter<any[]>();
  @Output() selectedItemChange = new EventEmitter<any>();
  @Output() listLoaded = new EventEmitter<void>();
  @Output() filterChange = new EventEmitter<void>();

  private _selectedItem: any;
  public get selectedItem(): any {
    return this._selectedItem;
  }
  @Input() public set selectedItem(value: any) {
    if (this.selectedItem === value) {
      return;
    }

    this._selectedItem = value;

    this.selectedItemChange.emit(value);
  }

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  items: any[] = [];
  includeList: any[] = [];
  height: number = 2;
  filterValue: string = '';
  previousFilterValue: string;
  filterHasChanged = false;
  searchFilter: DataBindingFilters = new DataBindingFilters();
  previousFilterApplied: DataBindingFilters;
  previousItemsCount: number;
  template: TemplateRef<any>;
  dragListId: string;

  private readonly enterKey = 'Enter';
  private _range: ListRange;
  private _searchPageNumber: number = 0;
  private _dataService: GridODataService<any>;
  private _filterOperator = 'contains';
  private _staticFilterOperator = 'eq';
  private _minLenghToSearch = 3;
  private _staticOdataFilters = new DataBindingFilters();
  private _pxToLoad = 1;
  private _allowLoad = true;
  private _scrollWaitTime = 500;
  private _isCurrentlyLoading = false;
  private _randomKey: string;

  constructor(
    private _injector: Injector,
    private _scrollDispatcher: ScrollDispatcher,
    private _changeDetector: ChangeDetectorRef,
    private _objectHelper: ObjectHelperService,
    private _spinnerService: SpinnerService,
    private _arrayHelperService: ArrayHelperService,
    private _stringHelperService: StringHelperService
  ) {
    this._randomKey = this._stringHelperService.getRandomString();
  }

  ngOnInit(): void {
    this.refreshList$?.pipe(untilDestroyed(this)).subscribe(() => this.refreshList());

    this.reloadList$?.pipe(untilDestroyed(this)).subscribe(() => this.reloadList());

    this.removeSelection$?.pipe(untilDestroyed(this)).subscribe(() => this.removeSelection());
  }

  ngAfterViewInit(): void {
    this._scrollDispatcher
      .scrolled(this._scrollWaitTime)
      .pipe(
        filter((event: CdkScrollable) => {
          const scrollId = event.getElementRef().nativeElement.id;
          if (scrollId !== this.dragListId) {
            return false;
          }

          return this.listHasToReload() && this._allowLoad;
        }),
        throttleTime(this._scrollWaitTime)
      )
      .subscribe((event) => {
        this.callNextPage();
      });
  }

  reloadList(): void {
    this.resetList();
    this.nextSearchPage(this._searchPageNumber);
    this._changeDetector.detectChanges();
  }

  refreshList(): void {
    this.items = [...this.items];
    this._changeDetector.detectChanges();
    if (this.items.length === 0) {
      this.callNextPage();
    }
  }

  removeSelection(): void {
    this.selectedItem = null;
    this._changeDetector.detectChanges();
  }

  nextSearchPage(pageNumber: number, goToPreviousPosition = false): void {
    if (!this.settings?.disableFilter) {
      this.setFilterBySearch();
    }
    const oDataFilter = this._objectHelper.clone(this.searchFilter, false);

    if (this._staticOdataFilters) {
      oDataFilter.mergeDataBindingFilter(this._staticOdataFilters);
    }

    const filterIsEqual = this._objectHelper.deepEqual(oDataFilter, this.previousFilterApplied);
    const itemsCountAreTheSame = this.items?.length === this.previousItemsCount;

    if (filterIsEqual && itemsCountAreTheSame && pageNumber > 1) {
      return;
    } else {
      this.previousFilterApplied = oDataFilter;
      this.setGlobalSpinner(true);
    }

    this.previousItemsCount = this.items?.length;

    if (this.settings.isLocal) {
      this.callLocalQuery(pageNumber, oDataFilter, goToPreviousPosition);
    } else {
      this.callApiQuery(pageNumber, oDataFilter, goToPreviousPosition);
    }
  }

  private callLocalQuery(
    pageNumber: number,
    oDataFilter: DataBindingFilters,
    goToPreviousPosition = false
  ) {
    const currentOffset = this.virtualScroll?.measureScrollOffset('top') ?? 0;
    // Apply filters to main collection
    let filteredItems = [...this.itemsData];
    const startPage = pageNumber + 1;

    oDataFilter.filters.forEach((filter) => {
      filteredItems = filterBy(filteredItems, filter.getFilters());
    });

    //Apply pagination
    if (this.settings.pageSize) {
      filteredItems = filteredItems.slice(0, startPage * this.settings.pageSize);
    }

    //Sort items
    this.items = [...this.sortListLocally(filteredItems)];

    if (
      this.selectedItem &&
      !this.items.find(
        (x) =>
          x[this.settings.selectedFieldName] == this.selectedItem[this.settings.selectedFieldName]
      )
    ) {
      this.selectedItem = null;
    }

    if (goToPreviousPosition) {
      this.scrollToOffset(currentOffset);
    }

    this._changeDetector.detectChanges();
    this.listLoaded.emit();

    this.setGlobalSpinner(false);
  }

  private callApiQuery(
    pageNumber: number,
    oDataFilter: DataBindingFilters,
    goToPreviousPosition = false
  ) {
    this._dataService
      .getQuery(
        [],
        {
          take: this.settings.pageSize,
          skip: pageNumber * this.settings.pageSize,
          sort: this.settings.orderBy,
        },
        oDataFilter,
        false,
        this.queryParams,
        this.settings.cacheOptions
      )
      .subscribe((available) => {
        const filterHasChanged = this.filterValue != this.previousFilterValue;
        if (filterHasChanged) {
          this.items = [...this.getProcessedItems(available.items)];
        } else {
          let newItems = [...this._objectHelper.getCombinedArrays(this.items, available.items)];
          newItems = [...this.getProcessedItems(newItems)];
          newItems = this.sortListLocally(newItems);
          this.items = [...newItems];
        }

        if (this.selectedItem && !this.items.includes(this.selectedItem)) {
          this.selectedItem = null;
        }

        this.previousFilterValue = this.filterValue;

        if (goToPreviousPosition) {
          this.scrollFromBottom(available.items.length);
        }

        this._changeDetector.detectChanges();
        this.listLoaded.emit();

        this.setGlobalSpinner(false);
      });
  }

  private scrollToOffset(currentOffset: number) {
    setTimeout(() => {
      this.virtualScroll.scrollToOffset(currentOffset, 'smooth');
      this._changeDetector.detectChanges();
    }, 500);
  }

  private scrollFromBottom(newElements: number) {
    setTimeout(() => {
      const pixels = newElements * this.card.nativeElement.offsetHeight;
      this.virtualScroll.scrollTo({ bottom: pixels, behavior: 'smooth' });
      this._changeDetector.detectChanges();
    }, 500);
  }

  private scrollFromTop(newElements: number, timeOut: number = 500) {
    setTimeout(() => {
      const pixels = newElements * this.card.nativeElement.offsetHeight;
      this.virtualScroll.scrollTo({ top: pixels, behavior: 'smooth' });
      this._changeDetector.detectChanges();
    }, timeOut);
  }

  onKeyDownEvent($event: KeyboardEvent) {
    if (this._isCurrentlyLoading) {
      $event.preventDefault();
    }

    if ($event.key == this.enterKey && this.filterValue.length >= this._minLenghToSearch) {
      this.prepareFilterSearch();
    }
  }

  filterList($event) {
    if (this.filterValue === '' || this.filterValue === undefined) {
      this.filterChange.emit();
      return this.clearResults();
    }
  }

  navigateByItems(event: KeyboardEvent, direction: 'Down' | 'Up') {
    event.preventDefault();
    if (this.selectedItem) {
      const currentIndex = this.items.indexOf(this.selectedItem);
      var newIndex = currentIndex + (direction == 'Down' ? 1 : -1);
      if (this.items.length > newIndex) {
        this.selectedItem = this.items[newIndex];
        this.scrollFromTop(newIndex, 100);
      }
    }
  }

  getProcessedItems(currentItems: any[]) {
    const includedListFiltered = this.getListFiltered(this.includeList);
    const excludedListFiltered = this.getListFiltered(this.excludeList);

    let processedList = this._objectHelper.getCombinedArrays(currentItems, includedListFiltered);
    const mappedExcludedList = excludedListFiltered.map((m) => m[this.settings.displayFieldName]);

    processedList = processedList.filter(
      (f) => !mappedExcludedList.includes(f[this.settings.displayFieldName])
    );

    return processedList;
  }

  getListFiltered(list: any[]) {
    if (this.filterValue.length === 0 || list.length === 0) {
      return list;
    }

    const filterTolower = this.filterValue?.toLowerCase();
    return list.filter((x) =>
      x[this.settings.displayFieldName]?.toLowerCase()?.includes(filterTolower)
    );
  }

  isSelected(item) {
    const keyName = this.settings.selectedFieldName;

    const selectedKey = this.selectedItem?.[keyName];
    const itemKey = item?.[keyName];

    return selectedKey !== undefined && selectedKey === itemKey;
  }

  onClickItem(item) {
    this.selectedItem = item;
  }

  private prepareFilterSearch() {
    this.setFilterBySearch();
    this.filterChange.emit();

    this._searchPageNumber = 0;
    this.nextSearchPage(this._searchPageNumber);
  }

  private sortListLocally = (newItems: any[]) => {
    if (this.settings.orderBy?.length && newItems?.length) {
      const sortByField = this.settings.orderBy[0].field;
      const ascending = this.settings.orderBy[0].dir == 'asc';
      newItems = [
        ...this._arrayHelperService.sortArrayObjectCaseInsensitive(
          newItems,
          sortByField,
          ascending
        ),
      ];
    }
    return newItems;
  };

  private setFilterBySearch() {
    const newFilter = new DataBindingFilters();

    const filterTolower = this.filterValue?.toLowerCase();
    const filtrableFields = this.settings?.filtrableFields;

    if (filterTolower && filtrableFields?.length) {
      const compositeFilter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: filtrableFields.map((field) => ({
          operator: this._filterOperator,
          field,
          value: filterTolower,
          ignoreCase: true,
        })),
      };

      newFilter.addOrUpdateCompositeFilter('filtrableFields', compositeFilter);
    }

    this.searchFilter = newFilter;
  }

  private instanceDataService() {
    this._dataService = this._injector.get(this.settings?.dataService);
    if (!this.settings.useQueryParams) {
      this.nextSearchPage(this._searchPageNumber);
      this._changeDetector.detectChanges();
    }
    // here we subscribe on viewChange to return ListRange object
    this.virtualForOf.viewChange.subscribe((range: ListRange) => (this._range = range));
  }

  private clearResults() {
    this.searchFilter = new DataBindingFilters();
    this._searchPageNumber = 0;
    this.nextSearchPage(this._searchPageNumber);
  }

  private populateStaticODataFilters() {
    const { oDataFilters, oDataFiltersExtended } = this.settings;

    if (oDataFiltersExtended) {
      this._staticOdataFilters = this.settings.oDataFiltersExtended;
      return;
    }

    const newFilter = new DataBindingFilters();
    oDataFilters.forEach((value, key) => {
      newFilter.addOrUpdateBasicFilter(key, value, this._staticFilterOperator, false);
    });

    this._staticOdataFilters = newFilter;
  }

  private setGlobalSpinner(isLoading: boolean) {
    this._isCurrentlyLoading = isLoading;

    if (this.settings.useGlobalSpinner) {
      this._spinnerService.setLoading(isLoading, this.dragListId);
    }
  }

  private reloadWithoutQuery() {
    this.items = [...this.getProcessedItems(this.items)];
    this._changeDetector.detectChanges();
  }

  private resetList() {
    this.items = [];
    this.previousItemsCount = undefined;
    this.includeList = [];
    this._searchPageNumber = 0;
  }

  private listHasToReload() {
    const bottomPosition = this.virtualScroll.measureScrollOffset('bottom');
    return bottomPosition <= this._pxToLoad;
  }

  private callNextPage() {
    this._searchPageNumber++;
    this.nextSearchPage(this._searchPageNumber, true);
  }

  private generateDragListId() {
    this.dragListId = `${this.componentName}-${this.settings.scrollId}-${this._randomKey}`;
  }
}
