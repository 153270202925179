import { Injectable, Injector } from '@angular/core';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { GenericExtendedSelectorPayload } from 'src/app/common-modules/redux/models/generic-extended-selector';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateSelectorBuilder } from 'src/app/common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from 'src/app/common-modules/redux/store/base.store';
import { GenericActionTypes, ResetStateAction, SetValueAction } from './generic.actions';
import { GenericSelectorTypes } from './generic.selectors';

export type GenericState = any;

@Injectable()
export class GenericStoreService extends BaseStore {
  readonly serviceName = 'GenericStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        GenericActionTypes.SetValue,
        (state: GenericState, action: SetValueAction) => {
          state[action.payload.fieldName] = action.payload.value;
        },
      ],
      [
        GenericActionTypes.ResetState,
        (state: GenericState, action: ResetStateAction) => {
          action.payload.fieldNames.forEach((fieldName) => {
            state[fieldName] = null;
          });
        },
      ],
    ]);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        GenericSelectorTypes.GetValue,
        {
          fn: (state: GenericState, payload?: GenericExtendedSelectorPayload) => {
            return state && payload?.fieldName ? state[payload.fieldName] : null;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return [
      createAction(
        GenericActionTypes.SetValue,
        props<{ payload: number; settings: StateFullSettings }>()
      ),
      createAction(
        GenericActionTypes.ResetState,
        props<{ payload: string[]; settings: StateFullSettings }>()
      ),
    ];
  }
}
