import { Action } from '@ngrx/store';
import { StateScopeSettings } from './state-scope-settings';

export abstract class GenericAction implements Action {
  abstract readonly type: string;
  abstract readonly area: string;
  abstract payload: unknown;
  settings: StateScopeSettings;

  constructor(settings = new StateScopeSettings({})) {
    this.settings = settings;
  }
}
