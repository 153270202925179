import { Component, forwardRef, Input } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { DateFormats } from '../../shared/localization/date-formats.enum';
import { DateFormatsService } from '../../shared/localization/date-formats.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';

import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-date-time-column',
  templateUrl: './date-time-column.component.html',
  styleUrls: ['./date-time-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => DateTimeColumnComponent),
    },
  ],
})
export class DateTimeColumnComponent extends WlmColumnComponent {
  @Input() type: string;
  @Input() valueDefinedAsUtc = true;
  @Input() showTimeInFilter = false;
  @Input() includeNullFilterOperators: string[];
  currentFormat: string;

  constructor(
    private localization: LocalizationHelperService,
    private _dateFormatsService: DateFormatsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.format = this.format ? this.format : DateFormats.Date;
    // this.showTime = this.format !== DateFormats.Date;
  }

  getColumnData(field: string, value: any, format: DateFormats, type: string): any {
    const dateFormat = this._dateFormatsService.getFormat(format);
    if (this.currentFormat !== dateFormat) {
      this.currentFormat = dateFormat;
    }

    return this.localization.getLocalizedDateFromApi(value, format, this.valueDefinedAsUtc);
  }
}
