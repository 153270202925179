<wlm-collapsible-container [titleKey]="T_SCOPE + '.labels.thread'">
  <mat-form-field
    *hasPerm="['WLMDiscussionsCrud', 'u']"
    appearance="outline"
    class="text-area"
    floatLabel="always"
  >
    <mat-label>{{ T_SCOPE + '.labels.comment' | translate }}</mat-label>
    <mat-quill
      [formControl]="commentDescriptionControl"
      [quillModules]="editorModules"
      [quillSanitize]="true"
      [quillTheme]="quillTheme"
      [placeholder]="placeholderTextKey | translate"
    ></mat-quill>
  </mat-form-field>
  <div class="comment-container" *ngIf="userNamesHash">
    <wlm-comment-item
      *ngFor="let comment of comments"
      [comment]="comment"
      [authorName]="userNamesHash[comment.createdBy]"
    ></wlm-comment-item>
  </div>
</wlm-collapsible-container>
