<div [formGroup]="form" class="exception-container">
  <div class="box-title">
    <mat-label>{{ T_SCOPE + '.labels.configuration-title' | translate }}</mat-label>
  </div>
  <div class="box-controls double-items calendar-exception-controls">
    <div class="common-width">
      <mat-form-field appearance="outline" class="exception-types">
        <mat-label>{{ T_SCOPE + '.labels.exception-types' | translate }}</mat-label>
        <mat-select required appearance="outline" [formControlName]="exceptionTypeFieldName">
          <mat-option *ngFor="let type of exceptionTypes | keyvalue" [value]="type.value">
            {{ T_SCOPE + '.labels.' + type.key | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="range-controls">
      <!--Day of the week-->
      <div *ngIf="dailyExceptionSelected">
        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.labels.days' | translate }}</mat-label>
          <mat-select required appearance="outline" [formControlName]="daysFieldName">
            <mat-option *ngFor="let day of daysOfWeek | keyvalue" [value]="day.value">
              {{ T_SCOPE + '.days.' + day.key | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--Days of the month (range)-->
      <div *ngIf="monthlyExceptionSelected">
        <div class="double-items exception-types">
          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.day-from' | translate }}</mat-label>
            <mat-select required appearance="outline" [formControlName]="dayFromFieldName">
              <mat-option *ngFor="let day of daysOfMonth" [value]="day">
                {{ day }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.errors?.invalidDayOfTheMonthRange">
              {{ 'common.validation.start-date-range-older' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ T_SCOPE + '.labels.day-to' | translate }}</mat-label>
            <mat-select required appearance="outline" [formControlName]="dayToFieldName">
              <mat-option *ngFor="let day of daysOfMonth" [value]="day">
                {{ day }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--Fixed interval of dates-->
      <div *ngIf="fixedExceptionSelected" class="date-range-size">
        <wlm-date-range-filter
          *ngIf="initialDateRange"
          [dateRange]="initialDateRange"
          [displayHorizontal]="true"
          [disableStartDate]="false"
          [disableEndDate]="false"
          [maxDate]="null"
          [resetEndDate$]="resetEndDate$"
          [resetStartDate$]="resetStartDate$"
          (hasError)="fixedExceptionError($event)"
          (dateRangeChanged)="onDateRangeChanged($event)"
        ></wlm-date-range-filter>
      </div>

      <div *ngIf="yearlyExceptionSelected">
        <div class="double-items exception-types">
          <wlm-day-month-filter
            [formControlName]="yearlyFromFieldName"
            [titleKey]="titleKeyFrom"
          ></wlm-day-month-filter>
          <wlm-day-month-filter
            [formControlName]="yearlyToFieldName"
            [titleKey]="titleKeyTo"
          ></wlm-day-month-filter>
        </div>

        <mat-error *ngIf="form.errors?.invalidDayOfTheYearRange" class="error-aspect">
          {{ 'common.validation.start-date-range-older' | translate }}
        </mat-error>
        <mat-error *ngIf="form.errors?.requiredDaysOfTheYear" class="error-aspect">
          {{ 'common.validation.required' | translate }}
        </mat-error>
      </div>
    </div>

    <div class="common-width button-position">
      <button
        mat-raised-button
        class="wtr-action-button-primary save-button"
        [disabled]="!anySelected || hasErrors"
        onclick="this.blur()"
        (click)="saveException()"
      >
        {{ T_SCOPE + '.labels.add-exception' | translate }}
      </button>
    </div>
  </div>
</div>
