import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CurrentDateErrorStateMatcher implements ErrorStateMatcher {
  constructor(private checkCurrentFn: (date: Date) => boolean) {}
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const value = control.value;
    if (this.checkCurrentFn(value as Date)) {
      // No error is returned when the date is current
      return false;
    } else {
      // In this specific component, touched elements that are outside the min/max validations
      // are not shown as incorrect.
      const matMaxSingleError = this.hasSingleError(control, 'matDatepickerMax');
      const matMinSingleError = this.hasSingleError(control, 'matDatepickerMin');

      const hasError = !!(
        control &&
        control.invalid &&
        (control.dirty || (control.touched && !matMaxSingleError && !matMinSingleError))
      );

      return hasError;
    }
  }

  private hasSingleError(control: AbstractControl, errorName: string): boolean {
    const result =
      control.errors && Object.keys(control.errors).length === 1 && control.errors[errorName];
    return result;
  }
}
