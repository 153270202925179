<div id="map-container" class="map-container" #mapContainer>
  <div id="map" class="map" #map></div>
  <div id="map-panel" class="map-panel" #mapPanel *ngIf="showPanel">
    <div #panelHeader id="map-panel-header">Info</div>
    <div #panelContent id="map-panel-content">
      <ng-container *ngFor="let feature of features">
        <wlm-feature-info [feature]="feature"></wlm-feature-info>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="showFilters && !!allLayers?.length" class="filters-section">
  <wlm-map-search
    [icons]="layerIcons"
    (onSearchSelection)="onSearchSelection($event)"
  ></wlm-map-search>

  <button
    mat-flat-button
    class="display-filter-button"
    [disabled]="layersFilterDisabled"
    (click)="onClickLayersFilter()"
    [matTooltip]="'common.filters' | translate | lowercase"
  >
    <mat-icon svgIcon="layers"></mat-icon>
  </button>
</div>

<div *ngIf="showFilters && !!allLayers?.length">
  <wlm-map-tab-panel
    [displayed]="displayLayersFilter"
    [(baseType)]="baseType"
    [(visibleLayersIds)]="visibleLayersIds"
    [(leakYears)]="leakYears"
    [thematics]="thematicKpis"
    [(visibleThematicsIds)]="visibleThematicsIds"
    (allFiltersLoaded)="onLoadMapFilter()"
    (kpiInfoChange)="onKpiInfoChange($event)"
  ></wlm-map-tab-panel>
</div>

<div class="legend-section">
  <wlm-map-thematic-legend
    [(visibleThematicsIds)]="visibleThematicsIds"
    [kpiInfo]="selectedKpiInfo"
    (mapThematicTooltipInfoChanges)="onMapThematicTooltipInfoChanges($event)"
  ></wlm-map-thematic-legend>
</div>

<div *ngIf="currentZoom" class="zoom-section">
  <span>
    {{ 'map.common.zoom-level' | translate }}: {{ this.currentZoom | number : '.2-2' }}
  </span>
</div>
