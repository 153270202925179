import { GenericChartSettings } from '../generic-chart-settings/generic-chart-settings';
import { HistoricalEvent } from './historical-event';

export class HistoricalChartResultSettings extends GenericChartSettings {
  events: HistoricalEvent[];
  showInUTC?: boolean = false;

  constructor(init: Readonly<HistoricalChartResultSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
