import { GChartAxis } from './g-chart-axis';
import { GenericChartSettings } from './generic-chart-settings';

export class BaseGenericCartesianChartSettings extends GenericChartSettings {
  type: 'normal' | 'graph' | 'custom';
  xAxes: GChartAxis[];
  yAxes: GChartAxis[];

  constructor(init: Partial<BaseGenericCartesianChartSettings>) {
    super(init);
    Object.assign(this, init);
  }
}
