import { NgModule } from '@angular/core';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { TranslateModule } from '@ngx-translate/core';
import { HomePageComponent } from './home-page/home-page.component';

@NgModule({
  declarations: [HomeComponent, HomePageComponent],
  imports: [WlmSharedModule, HomeRoutingModule, CommonModule, ScrollViewModule, TranslateModule],
})
export class HomeModule {}
