import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalizationHelperService } from '../../../localization/localization-helper.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
@Injectable()
export class ToActiveMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToActive;

  constructor(private _localization: LocalizationHelperService) {
    super();
  }

  map = (value: unknown): Observable<string> => {
    if (typeof value !== 'boolean') {
      return of(value as string);
    }
    return this._localization.get(value ? 'common.active' : 'common.inactive');
  };
}
