import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchyElementUnitsQueryParameters } from '../model/uom/hierarchy-element-type-time-units-query.dto';
import { HierarchyElementTypeTimeUnitsQueryDto } from '../model/uom/hierarchy-element-type-time-units.dto';
import { UoMService } from './uom.service';

@Pipe({
  name: 'uomConversionPipe',
})
export class UomConversionPipe implements PipeTransform {
  private conversionFactor: Observable<HierarchyElementTypeTimeUnitsQueryDto>;

  transform(
    value: number,
    hierarchyElementTypeId,
    timeAggregation,
    dimensionTypeId,
    decimalPositions
  ): Observable<string> {
    const conversionFactor = this.uomService.getByHEUnit(
      new HierarchyElementUnitsQueryParameters(
        hierarchyElementTypeId,
        dimensionTypeId,
        timeAggregation
      )
    );
    return conversionFactor.pipe(
      map((conv) => {
        return this.uomService.getConvertedValue(conv, value, decimalPositions);
      })
    );
  }

  constructor(private uomService: UoMService) {}
}
