import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { ReadonlyHierarchyElementTypes } from 'src/app/common-modules/dependencies/he/hierarchy.constants';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';

@Injectable()
export class HETypesCrudService
  extends BaseService
  implements GenericCrudService<IHierarchyElementTypeDto, string>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/hierarchy-element-types`;
  }

  getAll(): Observable<IHierarchyElementTypeDto[]> {
    return this.httpCacheClient.get<IHierarchyElementTypeDto[]>(this._entityUrl);
  }

  create(entity: IHierarchyElementTypeDto): Observable<IHierarchyElementTypeDto> {
    return this.httpCacheClient.post<IHierarchyElementTypeDto>(this._entityUrl, entity);
  }

  update(entity: IHierarchyElementTypeDto): Observable<IHierarchyElementTypeDto> {
    return this.httpCacheClient.put<IHierarchyElementTypeDto>(this._entityUrl, entity);
  }

  delete(id: string): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: IHierarchyElementTypeDto): string {
    return entity.hierarchyElementTypeId;
  }

  isReadonlyType = (hierarchyElementTypeId: string) =>
    !!(
      hierarchyElementTypeId &&
      ReadonlyHierarchyElementTypes.find(
        (type) => type.toLowerCase() === hierarchyElementTypeId.toLowerCase()
      )
    );
}
