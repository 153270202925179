<div class="search-container">
  <mat-form-field class="custom-search-field custom-search-field-size hide-subscript">
    <mat-label>{{ T_SCOPE + '.filter-title' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="filterValue"
      (input)="filterList($event)"
      (keydown)="onKeyDownEvent($event)"
      autocomplete="off"
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
<div class="drag-list-container">
  <cdk-virtual-scroll-viewport
    class="scroll-wrapper"
    [itemSize]="height"
    [cdkDropListData]="items"
    [id]="dragListId"
    [cdkDropListDisabled]="settings.isReadOnly"
    [cdkDropListEnterPredicate]="allowDrop"
    (cdkDropListDropped)="drop($event)"
    cdkDropList
  >
    <div
      class="list"
      *cdkVirtualFor="let item of items; let i = index"
      cdkDrag
      [cdkDragData]="i"
      (cdkDragStarted)="onDragStarted($event, item)"
      (cdkDragEnded)="onDragEnded($event)"
    >
      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
