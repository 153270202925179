import { DateRange } from '../../shared/model/date/date-range';
import { SelectOption } from '../../shared/model/shared/select-option';

export class BiFilters {
  selectedIds: string[];
  selectedElements: SelectOption<string>[];
  selectedDateRange: DateRange;
  selectedFamily?: string;

  constructor(init: BiFilters) {
    Object.assign(this, init);
  }
}
