import { Pipe, PipeTransform } from '@angular/core';
import { GridConstants } from '../../shared/constants/grid.constants';

@Pipe({
  name: 'gridCellEmpty',
})
export class GridCellEmptyPipe implements PipeTransform {
  transform(value: any): unknown {
    return value === null || value === undefined || value === ''
      ? GridConstants.emptyCellValue
      : value;
  }
}
