<div class="container">
  <div class="title-bar">
    <div>
      <mat-panel-title> {{ T_SCOPE + title | translate }} </mat-panel-title>
    </div>
    <div class="select-all-position">
      <button
        mat-icon-button
        [matTooltip]="'common.select-all' | translate | lowercase"
        (click)="selectAllClick($event)"
      >
        <mat-icon>check_box</mat-icon>
      </button>
      <!-- <input
        type="checkbox"
        [indeterminate]="selectAllIndeterminate"
        kendoCheckBox
        [(ngModel)]="selectAllChecked"
      />
      <label>Select All</label> -->
    </div>
  </div>

  <div class="check-list-border">
    <div class="items-list scrollable">
      <div *ngFor="let element of elementList" class="item">
        <input
          type="checkbox"
          kendoCheckBox
          [(ngModel)]="element.selected"
          (change)="onSelect($event, element)"
        />
        <wlm-svg
          *ngIf="element.pathIcon"
          class="filter-item-option-icon"
          width="20"
          height="20"
          [path]="element.pathIcon"
          [svgFill]="'#545c69'"
        ></wlm-svg>
        <label>{{ element.elementName }}</label>
      </div>
    </div>
  </div>
</div>
