import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { WtrColors } from '../../shared/styles/wtr-colors';
import { GenericCardSettings } from './generic-card-settings';

const COMPONENT_SELECTOR = 'wlm-generic-card';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent implements OnInit {
  @ContentChild('left', { static: false }) leftTemplateRef: TemplateRef<any>;
  @ContentChild('right', { static: false }) rightTemplateRef: TemplateRef<any>;
  @ContentChild('showMore', { static: false }) showMoreTemplateRef: TemplateRef<any>;

  private _item: any;
  public get item(): any {
    return this._item;
  }
  @Input() set item(value: any) {
    this._item = value;
    this.processItem();
  }

  private _settings: GenericCardSettings<any>;
  public get settings(): GenericCardSettings<any> {
    return this._settings;
  }
  @Input() set settings(value: GenericCardSettings<any>) {
    this._settings = value;
    this.processItem();
  }
  @Input() contentClass: string;

  title1: string;
  title2: string;
  subtitle: string;
  extraInformation: string;
  titleSeparator: string;
  leftIconName: string;
  useTitleCentered = false;
  iconColor = WtrColors.NavBarIconColor;
  // If true, the component does not keep empty spaces for the missing properties.
  resizeToContent = true;
  isShowMoreToggled = false;

  ngOnInit(): void {}

  toggleShowMore(): void {
    this.isShowMoreToggled = !this.isShowMoreToggled;
  }

  private processItem(): void {
    if (this.item && this.settings) {
      this.useTitleCentered = this.settings.useTitleCentered;
      this.title1 = this.settings.title1Fn ? this.settings.title1Fn(this.item) : '';
      this.title2 = this.settings.title2Fn ? this.settings.title2Fn(this.item) : '';
      this.titleSeparator = this.title2 === '' ? '' : '-';
      this.subtitle = this.settings.subtitleFn ? this.settings.subtitleFn(this.item) : '';
      this.extraInformation = this.settings.extraInfoFn ? this.settings.extraInfoFn(this.item) : '';
      this.leftIconName = this.settings.leftIconNameFn
        ? this.settings.leftIconNameFn(this.item)
        : null;
      this.resizeToContent = this.settings.resizeToContent;
    }
  }
}
