<div [formGroup]="form" class="exception-container">
  <div class="box-title">
    <mat-label>{{ T_SCOPE + '.labels.configuration-title' | translate }}</mat-label>
  </div>
  <div class="box-controls double-items">
    <mat-selection-list #shoes [multiple]="false" class="list-height-limit">
      <wlm-calendar-exception-card
        *ngFor="let exception of calendarExceptionList"
        [exception]="exception"
        [deleteCard]="deleteCalendarExceptionHandler"
      ></wlm-calendar-exception-card>
    </mat-selection-list>
  </div>
</div>
