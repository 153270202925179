<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'" [showPageSpinner]="isLoading">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        *hasPerm="['WLMCalendarsCrud', 'c']"
        [matTooltip]="T_SCOPE + '.buttons.create-calendar' | translate | lowercase"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="createCalendar()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        *hasPerm="['WLMCalendarsCrud', 'u']"
        [matTooltip]="T_SCOPE + '.buttons.edit-calendar' | translate | lowercase"
        mat-icon-button
        [disabled]="!selectedCalendar"
        color="primary"
        onclick="this.blur()"
        (click)="editCalendar()"
      >
        <mat-icon svgIcon="edit"></mat-icon>
      </button>
      <button
        *hasPerm="['WLMCalendarsCrud', 'd']"
        [matTooltip]="T_SCOPE + '.buttons.delete-calendar' | translate | lowercase"
        mat-icon-button
        [disabled]="!selectedCalendar"
        color="primary"
        onclick="this.blur()"
        (click)="deleteCalendar()"
      >
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
      <button
        *ngIf="dynamicLayoutSettings?.showResetIcon"
        [matTooltip]="dynamicLayoutSettings?.labelReset | lowercase"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="dynamicLayoutSettings"
      [settings]="dynamicLayoutSettings"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
