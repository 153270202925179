<div
  class="drag-list-container"
  [ngClass]="settings.hideFilter ? 'flex-style' : 'flex-style col-orientation'"
>
  <div *ngIf="!settings.hideFilter">
    <mat-form-field class="filter-full-width custom-search-field-size" appearance="outline">
      <mat-label>{{ T_SCOPE + '.filter-title' | translate }}</mat-label>
      <input matInput [(ngModel)]="filterValue" (input)="filterList($event)" autocomplete="off" />
    </mat-form-field>
  </div>

  <div
    class="flex-style"
    cdkDropList
    [cdkDropListData]="objectsToOrder"
    [cdkDropListDisabled]="settings.isReadOnly"
    [cdkDropListEnterPredicate]="allowDrop"
    [ngClass]="{
      'single-container': settings.useSingleContainer,
      'items-container': !settings.useSingleContainer,
      'remove-min-height': settings.shrinkContainerHeight,
    }"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      [ngClass]="itemClass"
      *ngFor="let item of objectsFiltered; let i = index"
      [cdkDragData]="i"
      cdkDrag
      [cdkDragPreviewClass]="previewItemClass"
    >
      <div class="item-custom-placeholder" *cdkDragPlaceholder></div>

      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{
          $implicit: item,
          index: i,
          isLastItem: objectsFiltered.length - 1 === i
        }"
      >
      </ng-container>
    </div>
    <div *ngIf="objectsFiltered.length == 0" class="empty-legend">
      {{ emptyLegendKey | translate }}
    </div>
  </div>
</div>
