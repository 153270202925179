import { untilDestroyed } from '@ngneat/until-destroy';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { SelectedNetworkElementSelector } from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { applyOnActiveWidget } from 'src/app/common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';

export function necSelectNetworkElement(
  that: BaseDynamicWidgetComponent,
  state: ReduxStateService,
  settings: StateScopeSettings,
  callbackFn: (networkElement: INetworkElementDto) => void
): void {
  state
    .select<INetworkElementDto>(new SelectedNetworkElementSelector(settings))
    .pipe(untilDestroyed(that), applyOnActiveWidget(that, callbackFn))
    .subscribe();
}
