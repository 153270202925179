<kendo-grid-column [field]="field" [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="column-cell" [style.justify-content]="justifyContentStyle">
      {{ dataItem[field] | number | gridCellEmpty }}
      <span
        class="comparison-percentage"
        *ngIf="
          !columnSettings.hideComparison &&
          gridSettings.showHistoricalComparison &&
          comparisonIsActive
        "
      >
        {{ dataItem[field] | comparisonPercentage : dataItem[comparisonField] }}</span
      >
      <wlm-icon
        class="comparison-icon"
        *ngIf="
          gridSettings.showHistoricalComparison &&
          !columnSettings.hideComparison &&
          comparisonIsActive
        "
        [icon]="dataItem[field] | comparisonImageIconName : dataItem[comparisonField]"
        [color]="dataItem[field] | comparisonImageIconColor : dataItem[comparisonField]"
      ></wlm-icon>
      &nbsp;
    </span>
  </ng-template>
  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-numeric-column-filter
      [field]="column.field"
      [filter]="filter"
      [filterService]="filterService"
    ></wlm-numeric-column-filter>
  </ng-template>
</kendo-grid-column>
