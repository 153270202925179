import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthorizeService } from './services/authorize.service';

@UntilDestroy()
@Directive({
  selector: '[isSuperUser]',
})
export class IsSuperUserDirective implements OnInit {
  constructor(
    private _authorizeService: AuthorizeService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    this._authorizeService
      .isSuperUser()
      .pipe(untilDestroyed(this))
      .subscribe((allowed) => {
        if (allowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          // If the user does not have access to the content, the HTML elements are deleted.
          this.viewContainer.clear();
        }
      });
  }
}
