<wlm-page-wrapper
  [titleKey]="T_SCOPE + (selectedTitle ? '.title-with-current' : '.title')"
  [titleKeyParams]="{
    selectedTitle: selectedTitle
  }"
>
  <ng-container page-top-buttons>
    <button
      *ngIf="settings?.showResetIcon"
      mat-icon-button
      color="primary"
      (click)="onResetLayout()"
      [matTooltip]="settings?.labelReset | lowercase"
    >
      <wlm-icon [icon]="'reset-layout'"></wlm-icon>
    </button>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="settings"
      [settings]="settings"
      [injector]="injector"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
