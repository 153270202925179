import { Type } from '@angular/core';
// prettier-ignore
import { FilterableSettings, PagerSettings, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { GridBtnsSettings } from '../grid-buttons/models/grid-btns-settings';
import { GridColumnSetting } from '../model/grid/grid-column-setting';
import { NotificationGridSetting } from '../model/grid/notification-grid-setting';
import { RightPanelHeaderGridSetting } from '../model/grid/rp-header-grid-setting';
import { GridODataService } from '../odata/grid-odata.service';

export interface IGridSettings {
  count: boolean;
  pageSize: number;
  pageable: boolean | PagerSettings;
  sortable: SortSettings;
  filterable: FilterableSettings;
  resizable: boolean;
  reorderable: boolean;
  navigatable: boolean;
  selectable: SelectableSettings | boolean;
  showZoneColumns: boolean;
  showSelectAllColumn: boolean;
  excelFileName: string;
  service?: Type<GridODataService<any>> | string;
  gridColumnSettings?: GridColumnSetting[];
  backgroundColorMethodName?: string;
  backgroundColorKeyField?: string;
  fontWeightMethodName?: string;
  selectByFieldName?: string;
  familyIdFilterName?: string;
  cacheOpts?: GetCacheOpts;
  sort?: SortDescriptor[];
  buttons?: GridBtnsSettings;
  disableAutoLoad?: boolean;
  ignoreCase?: boolean;
  selectionPersistency?: boolean;
  notificationSetting?: NotificationGridSetting;
  hideHeader?: boolean;
  enableAutoResize?: boolean;
  useSmallPager?: boolean;
  showSelectAllHeader?: boolean;
  utcDates?: boolean;
  itemLabelKey?: string;
  showHistoricalComparison?: boolean;
  rightPanelHeaderSetting?: RightPanelHeaderGridSetting;
}

export class GridSetting implements IGridSettings {
  count: boolean;
  pageSize: number;
  pageable: boolean | PagerSettings;
  sortable: SortSettings;
  filterable: FilterableSettings;
  resizable: boolean;
  reorderable: boolean;
  navigatable: boolean;
  selectable: boolean | SelectableSettings;
  showZoneColumns: boolean;
  showSelectAllColumn: boolean;
  showSelectAllHeader?: boolean;
  excelFileName: string;
  service?: Type<GridODataService<any>> | string;
  gridColumnSettings?: GridColumnSetting[];
  backgroundColorMethodName?: string;
  backgroundColorKeyField?: string;
  fontWeightMethodName?: string;
  selectByFieldName?: string;
  familyIdFilterName? = 'hierarchyElementsIds';
  cacheOpts?: GetCacheOpts;
  sort?: SortDescriptor[];
  buttons?: GridBtnsSettings;
  disableAutoLoad?: boolean;
  ignoreCase?: boolean;
  selectionPersistency = true;
  notificationSetting?: NotificationGridSetting;
  hideHeader = false;
  enableAutoResize? = true;
  useSmallPager?: boolean = false;
  utcDates = false;
  itemLabelKey?: string;
  showHistoricalComparison?: boolean = false;
  rightPanelHeaderSetting?: RightPanelHeaderGridSetting;

  constructor(
    count: boolean,
    pageSize: number,
    pageable: boolean | PagerSettings,
    sortable: SortSettings,
    filterable: FilterableSettings,
    resizable: boolean,
    reorderable: boolean,
    navigatable: boolean,
    selectable: boolean | SelectableSettings,
    showZoneColumns: boolean,
    showSelectAllColumn: boolean,
    excelFileName: string,
    service?: Type<GridODataService<any>> | string,
    backgroundColorMethodName?: string,
    backgroundColorKeyField?: string,
    fontWeightMethodName?: string,
    gridColumnSettings?: GridColumnSetting[],
    selectByFieldName?: string,
    familyIdFilterName?: string,
    cacheOpts?: GetCacheOpts,
    sort?: SortDescriptor[],
    buttons?: GridBtnsSettings,
    disableAutoLoad?: boolean,
    ignoreCase?: boolean,
    selectionPersistency?: boolean,
    notificationSetting?: NotificationGridSetting,
    hideHeader?: boolean,
    useSmallPager?: boolean,
    enableAutoResize?: boolean,
    showSelectAllHeader?: boolean,
    utcDates?: boolean,
    itemLabelKey?: string,
    showHistoricalComparison?: boolean,
    rightPanelHeaderSetting?: RightPanelHeaderGridSetting
  ) {
    this.count = count;
    this.pageSize = pageSize;
    this.pageable = pageable;
    this.sortable = sortable;
    this.filterable = filterable;
    this.resizable = resizable;
    this.reorderable = reorderable;
    this.navigatable = navigatable;
    this.selectable = selectable;
    this.showZoneColumns = showZoneColumns;
    this.showSelectAllColumn = showSelectAllColumn;
    this.excelFileName = excelFileName;
    this.service = service;
    this.backgroundColorMethodName = backgroundColorMethodName;
    this.backgroundColorKeyField = backgroundColorKeyField;
    this.fontWeightMethodName = fontWeightMethodName;
    this.gridColumnSettings = gridColumnSettings;
    this.selectByFieldName = selectByFieldName;
    this.familyIdFilterName = familyIdFilterName ?? 'hierarchyElementsIds';
    this.cacheOpts = cacheOpts;
    this.sort = sort;
    this.buttons = buttons;
    this.disableAutoLoad = disableAutoLoad;
    this.ignoreCase = ignoreCase;
    this.selectionPersistency = selectionPersistency ?? true;
    this.notificationSetting = notificationSetting;
    this.hideHeader = hideHeader;
    this.useSmallPager = useSmallPager;
    this.enableAutoResize =
      enableAutoResize === undefined || enableAutoResize === null ? false : enableAutoResize;
    this.showSelectAllHeader = showSelectAllColumn;
    this.utcDates = utcDates;
    this.itemLabelKey = itemLabelKey;
    this.showHistoricalComparison = showHistoricalComparison;
    this.rightPanelHeaderSetting = rightPanelHeaderSetting;
  }
}

export interface IZoneAlgorithmGridSettings {
  zones: string[];
  algorithms: string[];
  values: Map<string, Map<string, boolean>>;
  selectable: any;
}

export class ZoneAlgorithmGridSettings implements IZoneAlgorithmGridSettings {
  zones: string[];
  algorithms: string[];
  values: Map<string, Map<string, boolean>>;
  selectable: any;
}

export class GridConstants {
  static readonly emptyCellValue = '--';
}
