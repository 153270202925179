<div class="select" *ngIf="options && options.length !== 0">
  <div class="row space-down">
    <div class="col-6 center-label title">
      <span> {{ title }}</span>
    </div>
    <div class="col-3 check-header">
      <div>{{ labelsScope + '.inclusive' | translate }}</div>
    </div>
    <div class="col-3 check-header">
      <div>{{ labelsScope + '.exclusive' | translate }}</div>
    </div>
  </div>
  <div *ngFor="let option of options" class="row space-down">
    <div class="col-6 center-label">
      <span [innerHtml]="option.label | highlightSearch : filterText"></span>
    </div>
    <div class="col-3 check-container">
      <mat-checkbox
        [checked]="
          selected[option.value] &&
          hashedHelper.calendarModeHash[option.value] === calendarModes.Inclusive
        "
        (click)="onToggleWithMode($event, option, calendarModes.Inclusive)"
      ></mat-checkbox>
    </div>
    <div class="col-3 check-container">
      <mat-checkbox
        [checked]="
          selected[option.value] &&
          hashedHelper.calendarModeHash[option.value] === calendarModes.Exclusive
        "
        (click)="onToggleWithMode($event, option, calendarModes.Exclusive)"
      ></mat-checkbox>
    </div>
  </div>
</div>
