<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="'common.clear-filter' | translate | lowercase"
        (click)="onClickClearFilters()"
      >
        <mat-icon svgIcon="reset-filters"></mat-icon>
      </button>
      <wlm-export-as-image-button
        [elementIdToExport]="idWaterBalanceWidget"
        [fileName]="nameWaterBalanceImage"
      ></wlm-export-as-image-button>
    </div>
  </ng-container>

  <ng-container page-filters>
    <wlm-water-balance-filters
      *ngIf="filtersConfig"
      [config]="filtersConfig"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      class="filter-display"
      (apply)="onApplyFilters()"
      (filter)="onFilter($event)"
      (filtersDetailParametersChanged)="filtersParametersChanged($event)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-water-balance-filters>
  </ng-container>

  <ng-container page-content>
    <wlm-water-balance-widget
      *ngIf="wbParams"
      class="widget"
      [params]="wbParams"
      [id]="idWaterBalanceWidget"
      (sectionSelect)="onSectionSelect($event)"
    ></wlm-water-balance-widget>
  </ng-container>
</wlm-page-wrapper>
