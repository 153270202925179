import { Injectable } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { CVChartSerieDto } from 'src/app/common-modules/dependencies/cv/models/cv-chart-serie.dto';
import { BaseFiltrableItemService } from 'src/app/common-modules/shared/charts/base-filtrable-item.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import {
  ISerieDataPointDto,
  SerieDataPointDto,
} from 'src/app/common-modules/shared/model/chart/serie-data-point.dto';
import { IFiltrableItemQueryDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item-query.dto';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { EventChartQueryDto } from 'src/app/common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { EntityTypes } from 'src/app/common-modules/wlm-charts/core/models/entity-types';
import { AlgorithmFiltrableItemQueryDto } from '../algorithm-filtrable-item-query.dto';
import { AlgorithmFiltrableItemDto } from '../algorithm-filtrable-item.dto';
import { AlgorithmFiltrableType } from '../types/algorithm-filtrable-type';

@Injectable({
  providedIn: 'root',
})
export class AlgorithmFiltrableItemService extends BaseFiltrableItemService {
  readonly type: FiltrableItemTypeEnum = FiltrableItemTypeEnum.Algorithm;

  constructor(private readonly _dateHelperService: DateHelperService) {
    super();
  }

  setDateRange(filtrableItem: AlgorithmFiltrableItemDto, startDate: Date, endDate: Date): void {
    filtrableItem.startDate = startDate;
    filtrableItem.endDate = endDate;
  }

  getQuery(filtrableItem: AlgorithmFiltrableItemDto): IFiltrableItemQueryDto {
    const { startDate, endDate, filtrableType } = filtrableItem;

    const elementId =
      filtrableType.entityType === EntityTypes.larsAndSworpsSite
        ? filtrableType.element.alternativeId
        : filtrableType.element.elementId;

    return new AlgorithmFiltrableItemQueryDto(
      new Date(startDate),
      this._dateHelperService.addDays(new Date(endDate), 1),
      'cv/chart',
      [filtrableType.algorithmShortName],
      [elementId]
    );
  }

  getDataPoints(
    filtrableType: AlgorithmFiltrableType,
    data: CVChartSerieDto[]
  ): ISerieDataPointDto[] {
    const serieDataPoints = asEnumerable(data)
      .Where(
        (x) =>
          x.algorithmShortName == filtrableType.algorithmShortName &&
          this.checkElementId(x, filtrableType)
      )
      .SelectMany((cv) =>
        cv.points.map(
          (p) =>
            new SerieDataPointDto(
              filtrableType.key,
              p.referenceTimestamp,
              p.value,
              p.estimated,
              p.edited,
              p.validity,
              p.adjusted
            )
        )
      )
      .ToArray();

    return serieDataPoints;
  }

  getEventQuery(filtrableItem: AlgorithmFiltrableItemDto): EventChartQueryDto {
    const { startDate, endDate, filtrableType } = filtrableItem;

    return new EventChartQueryDto({
      elementsIds: [filtrableType.element.elementId],
      endDate: endDate,
      startDate: startDate,
      categories: [],
    });
  }

  private checkElementId(
    dataPoint: CVChartSerieDto,
    filtrableType: AlgorithmFiltrableType
  ): boolean {
    switch (filtrableType.entityType) {
      case EntityTypes.larsAndSworpsSite: {
        return (
          dataPoint.elementId?.toLocaleLowerCase() ==
          filtrableType.element.alternativeId?.toLocaleLowerCase()
        );
      }
      default: {
        return dataPoint.elementId?.toLowerCase() == filtrableType.element.elementId?.toLowerCase();
      }
    }
  }
}
