import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { ResetFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import {
  ResetStateAction,
  SetValueAction,
} from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateFullSettings } from 'src/app/common-modules/redux/models/state-full-settings';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { DataValidationStateFields } from '../state/data-validation-state-fields';
import { DATA_VALIDATION_CHART_SELECTION_COMPONENT_INSTANCE } from '../widgets/data-validation-chart-selection-widget/data-validation-chart-selection-widget.component';
import { DATA_VALIDATION_CHART_COMPONENT_INSTANCE } from '../widgets/data-validation-chart-widget/data-validation-chart-widget.component';
import { DATA_VALIDATION_FILTER_COMPONENT_INSTANCE } from '../widgets/data-validation-filter-widget/data-validation-filter-widget.component';
import { DATA_VALIDATION_POINTS_SELECTION_COMPONENT_INSTANCE } from '../widgets/data-validation-points-selection-widget/data-validation-points-selection-widget.component';

const COMPONENT_SELECTOR = 'wlm-data-validation-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-page.component.html',
  styleUrls: ['./data-validation-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class DataValidationPageComponent
  extends BasePageComponent
  implements IPendingChangesChecker, OnInit, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: 'DataValidation',
  });

  private readonly _pageSettingsKeys = {
    layoutKey: 'LayoutMain',
    layoutArea: 'DataValidation',
    widgetPage: 'dataValidationPage',
    widgetModule: 'dataValidationModule',
  };

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);

  private readonly _filtersInstance1 = 'filtersInstance1';
  private readonly _dataValidationConfigInstance1 = 'dataValidationConfigInstance1';

  private readonly _filterWidgetName = 'DataValidationFilterWidgetComponent';
  private readonly _pointsSelectionWidgetName = 'DataValidationPointsSelectionWidgetComponent';
  private readonly _rangesSelectionWidgetName = 'DataValidationChartSelectionWidgetComponent';
  private readonly _chartWidgetName = 'DataValidationChartWidgetComponent';

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._pageSettingsKeys.widgetModule,
    page: this._pageSettingsKeys.widgetPage,
    scopeInstanceKeys: {
      [StateAreas.DataValidation]: this._dataValidationConfigInstance1,
    },
  });

  T_SCOPE = `${AppModules.DataValidation}.${COMPONENT_SELECTOR}`;
  settings: DynamicLayoutSettings;
  pageId: string;

  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();

    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.pageId = this._pageSettingsKeys.widgetPage;

    this.buildTitles().subscribe((titles) => {
      this.settings = new DynamicLayoutSettings({
        layoutKey: this._pageSettingsKeys.layoutKey,
        layoutArea: this._pageSettingsKeys.layoutArea,
        widgetPage: this._pageSettingsKeys.widgetPage,
        widgetModule: this._pageSettingsKeys.widgetModule,
        currentUser: this._authenticationService.userCode,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Column,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                height: 15,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: DATA_VALIDATION_FILTER_COMPONENT_INSTANCE,
                  },
                ],
              },
              {
                type: LayoutNodeTypes.Row,
                content: [
                  {
                    type: LayoutNodeTypes.Stack,
                    height: 85,
                    width: 25,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: DATA_VALIDATION_POINTS_SELECTION_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                  {
                    type: LayoutNodeTypes.Stack,
                    height: 85,
                    width: 55,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: DATA_VALIDATION_CHART_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                  {
                    type: LayoutNodeTypes.Stack,
                    height: 85,
                    width: 20,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: DATA_VALIDATION_CHART_SELECTION_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        items: [
          {
            componentName: this._filterWidgetName,
            widgetInstanceKey: DATA_VALIDATION_FILTER_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
            },
            title: titles.get(this._filterWidgetName),
          },
          {
            componentName: this._pointsSelectionWidgetName,
            widgetInstanceKey: DATA_VALIDATION_POINTS_SELECTION_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
              [StateAreas.Generic]: this._pointsSelectionWidgetName,
            },
            title: titles.get(this._pointsSelectionWidgetName),
          },
          {
            componentName: this._chartWidgetName,
            widgetInstanceKey: DATA_VALIDATION_CHART_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Generic]: this._pointsSelectionWidgetName,
            },
            title: titles.get(this._chartWidgetName),
          },
          {
            componentName: this._rangesSelectionWidgetName,
            widgetInstanceKey: DATA_VALIDATION_CHART_SELECTION_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.DataValidation]: this._rangesSelectionWidgetName,
            },
            title: titles.get(this._rangesSelectionWidgetName),
          },
        ],
        minItemHeight: 70,
        minItemWidth: 200,
        showCloseIcon: false,
      });
      this._settings$.next(this.settings);

      this.onLayoutInit();
    });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMDataValidationCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  onLayoutInit(): void {}

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._state.dispatch(
        new SetValueAction({
          fieldName: DataValidationStateFields.selectedPoint,
          value: null,
        })
      );

      this._dynamicLayoutService.resetAll();
    });
  }

  onResetFilters(): void {
    this.checkPendingChanges(this.pageId).subscribe((_) => {
      const action = new ResetFiltersAction(true);

      const fullSettings = new StateFullSettings({
        widget: new StateWidgetSettings({
          module: this._pageSettingsKeys.widgetModule,
          scopeInstanceKeys: {
            [StateAreas.Filters]: this._filtersInstance1,
          },
          page: this._pageSettingsKeys.widgetPage,
        }),
        area: action.area,
        scope: this._scopeSettings.scope,
      });

      this._state.dispatchConfigured(action, fullSettings);
    });
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  ngOnDestroy() {
    this._state.dispatch(
      new ResetStateAction(
        { fieldNames: Object.keys(DataValidationStateFields) },
        this._scopeSettings
      )
    );

    super.ngOnDestroy();
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._filterWidgetName, ts['wlm-data-validation-filter']],
          [this._pointsSelectionWidgetName, ts['wlm-data-validation-points-selection']],
          [this._chartWidgetName, ts['wlm-data-validation-chart']],
          [this._rangesSelectionWidgetName, ts['wlm-data-validation-chart-selection-widget']],
        ]);
        return titles;
      })
    );
  }
}
