import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { ActivityCategoryTypesFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/activity-category-types-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class ActivityCategoryFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.ActivityCategory;
  repairFieldName: string;
  detectionFieldName: string;

  constructor(init: {
    dataBindingField: string;
    repairFieldName: string;
    detectionFieldName: string;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const repairFilter = filters.data.get(this.repairFieldName);
    const detectionFilter = filters.data.get(this.detectionFieldName);
    if (repairFilter && detectionFilter) {
      const actCategoryTypesFilter = new ActivityCategoryTypesFilter(
        this.repairFieldName,
        this.detectionFieldName
      );
      actCategoryTypesFilter.includeRepair = repairFilter?.value;
      actCategoryTypesFilter.includeDetection = detectionFilter?.value;
      return actCategoryTypesFilter;
    }
    return null;
  }

  buildResults(actCategory: ActivityCategoryTypesFilter): FilterAdapterResult {
    if (actCategory) {
      this.filtersResult.filters.set(this.dataBindingField, actCategory);
      this.panelParams.addParameter(TabDetailParameterName.activities, actCategory);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
