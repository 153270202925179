export class SignalsConfiguredQueryDto {
  includeBoundaries: boolean;
  includeLargeUsers: boolean;
  includePressure: boolean;
  includeInletOutlet: boolean;
  includeLevel: boolean;
  includeLarsSworps: boolean;
  includeSmartMeters: boolean;
  elementsIds: string[];

  constructor(
    elementsIds: string[],
    includeBoundaries: boolean,
    includeLargeUsers: boolean,
    includePressure: boolean,
    includeInletOutlet: boolean,
    includeLevel: boolean,
    includeLarsSworps: boolean,
    includeSmartMeters: boolean
  ) {
    this.elementsIds = elementsIds;
    this.includeBoundaries = includeBoundaries;
    this.includeLargeUsers = includeLargeUsers;
    this.includePressure = includePressure;
    this.includeInletOutlet = includeInletOutlet;
    this.includeLevel = includeLevel;
    this.includeLarsSworps = includeLarsSworps;
    this.includeSmartMeters = includeSmartMeters;
  }
}
