<wlm-popup-wrapper [titleKey]="titleKey" (popupClose)="onButtonClose($event)">
  <ng-container popup-content>
    <div class="popup-content">
      <wlm-integration-gis-layers-form></wlm-integration-gis-layers-form>
    </div>
  </ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      color="primary"
      onclick="this.blur()"
      (click)="onSubmit()"
      [disabled]="!(isValid$ | async) || !(hasChanges$ | async)"
    >
      {{ 'common.save' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
