import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EstimatedEditedEnum } from 'src/app/common-modules/shared/model/algorithm/estimated-edited.enum';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { ValidityEnum } from 'src/app/common-modules/shared/model/algorithm/validity.enum';
import { BaseChartService } from '../base-chart/base-chart.service';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { TooltipFilterMethodEnum } from '../models/generic-chart-settings/tooltip-filter-method-enum';
import { GenericChartService } from './generic-chart.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseCartesianChartService extends BaseChartService<GenericCartesianChartSettings> {
  getShowAlwaysFilterMethod(serieTimeAggregation: TimeAggregationEnum): TooltipFilterMethodEnum {
    switch (serieTimeAggregation) {
      case TimeAggregationEnum.Daily: {
        return TooltipFilterMethodEnum.Daily;
      }
      default:
        return null;
    }
  }

  getDataPointColorByEstimatedEdited(estimatedEdited?: number, validity?: number): string {
    switch (estimatedEdited) {
      case EstimatedEditedEnum.Estimated: {
        return '#146AB1';
      }
      case EstimatedEditedEnum.Edited: {
        return '#FF8000';
      }
      case EstimatedEditedEnum.Adjusted: {
        return '#812ab5';
      }
      default: {
        switch (validity) {
          case ValidityEnum.Invalid: {
            return '#c91a1a';
          }
          case ValidityEnum.Missing: {
            return '#c91a1a';
          }
          case ValidityEnum.Unknown: {
            return '#c91a1a';
          }
          default: {
            return null;
          }
        }
      }
    }
  }

  genericChartService: GenericChartService;

  _genericChartServiceLoaded$ = new Subject<boolean>();
  genericChartServiceLoaded$ = this._genericChartServiceLoaded$.asObservable();

  setGenericChartService(genericChartService: GenericChartService) {
    this.genericChartService = genericChartService;
    this._genericChartServiceLoaded$.next(true);
  }
}
