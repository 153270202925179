import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from './base-input.directive';

/**
 * Directive that makes an input to only accept integer values (no decimals).
 */

@Directive({
  selector: 'input[wlmOnlyInteger]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyIntegerDirective),
      multi: true,
    },
  ],
})
export class OnlyIntegerDirective extends BaseInputDirective implements OnInit {
  @Input() wlmOnlyInteger: 'allow-negative';

  constructor(elRef: ElementRef, private injector: Injector) {
    super(elRef);
  }

  ngOnInit(): void {
    this.onEvent(undefined);
  }

  @HostListener('input', ['$event']) onInput(event) {
    this.onEvent(event?.data);
  }

  protected viewToForm(data: any) {
    let isNegative = false;
    if (this.inputValue.startsWith('-')) {
      isNegative = true;
    }
    // Remove everything that is not an integer.
    let newValue = this.inputValue.replace(/[^0-9]*/g, '');
    if (this.wlmOnlyInteger === 'allow-negative' && isNegative && newValue.length !== 0) {
      newValue = '-' + newValue;
    }
    if (newValue !== this.inputValue) {
      this.inputValue = newValue;
    }
    return this.inputValue;
  }
}
