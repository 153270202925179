import { IFiltrableItemQueryDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item-query.dto';

export class AlgorithmFiltrableItemQueryDto implements IFiltrableItemQueryDto {
  startDate: Date;
  endDate: Date;
  apiEndpoint: string;
  extraParams: any;

  constructor(
    startDate: Date,
    endDate: Date,
    apiEndpoint: string,
    algorithms: string[],
    elementIds: string[]
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.apiEndpoint = apiEndpoint;

    this.extraParams = {
      algorithms,
      elementIds,
    };
  }
}
