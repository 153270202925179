import { Component, Input } from '@angular/core';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { FilterItemSummaryRow } from 'src/app/common-modules/common-filters/models/filter-item-summary-row';

@Component({
  selector: 'wlm-default-filter-item-summary',
  templateUrl: './default-filter-item-summary.component.html',
  styleUrls: ['./default-filter-item-summary.component.scss'],
})
export class DefaultFilterItemSummaryComponent {
  @Input() items: FilterItemSummaryRow[] = [];

  @Input() set selectItems(value: FilterItemSelectOption[]) {
    if (value) {
      this.items = value.map((item) => new FilterItemSummaryRow(item.label, item.icon));
    }
  }

  private _text: string;

  get text(): string {
    return this._text;
  }

  @Input() set text(value: string) {
    this._text = value;
    this.items = [new FilterItemSummaryRow(value)];
  }

  @Input() title: string;
}
