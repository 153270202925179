import { AxisLocation } from './axis-location';
import { IAxis } from './axis';
import { Color } from '@progress/kendo-drawing';

export interface IVerticalAxis extends IAxis {
  minimum: number;
  maximum: number;
  showUoM: boolean;
  unitTypeToId: number;
  unitTypeToDescription: string;
}

export class VerticalAxis implements IVerticalAxis {
  id: number;
  minimum: number;
  maximum: number;
  showUoM: boolean;
  dimensionTypeId: number;
  unitTypeToId: number;
  unitTypeToDescription: string;
  location: AxisLocation;
  labelFormat: string;
  labelColor: Color;

  constructor(init: Partial<VerticalAxis>) {
    Object.assign(this, init);
  }

  getName(): string {
    return this.unitTypeToDescription;
  }
}
