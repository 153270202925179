import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { globalUtilsHelper } from '../shared/helpers/global-utils-helper';

export function isSvgValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (!globalUtilsHelper.isSvgText(value)) {
      return {
        notSvg: true,
      };
    }
    return null;
  };
}
