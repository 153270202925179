import { HttpBackend } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { AppModules } from './common-modules/shared/app-modules.enum';
import { CustomMultiTranslateHttpLoader } from './custom-multi-translate-http-loader';

const buildI18nResources = (items: string[]) =>
  items.map((item) => ({ prefix: `./assets/i18n/${item}/`, suffix: '.json' }));

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpBackend) {
  return new CustomMultiTranslateHttpLoader(
    http,
    buildI18nResources([
      'common',
      'kendo',
      AppModules.ALC,
      AppModules.Configuration,
      AppModules.Customer,
      AppModules.DistributionNetwork,
      AppModules.Home,
      AppModules.RightPanel,
      AppModules.WaterBalance,
      AppModules.WlmFilters,
      AppModules.WlmGrid,
      AppModules.WlmNavigation,
      AppModules.WlmSecurity,
      AppModules.WlmShared,
      AppModules.WlmCharts,
      AppModules.Dialogs,
      AppModules.DataVisualization,
      AppModules.Alarms,
      AppModules.Algorithms,
      AppModules.Monitoring,
      AppModules.Comments,
      AppModules.LeakageReporting,
      AppModules.PressureManagement,
      AppModules.BI,
      AppModules.Map,
      AppModules.TransmissionNetwork,
      AppModules.Schematics,
      AppModules.Logs,
      AppModules.DataValidation,
      AppModules.Integration,
      AppModules.SmartMeters,
      AppModules.Interfaces,
    ])
  );
}

export const translateModuleConfigurator = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpBackend],
  },
};
