import { KpiStep } from './map-thematic-kpi-step';

export class KpiLevelSteps {
  level: number;
  description: string;
  steps: KpiStep[];

  constructor(init: Partial<KpiLevelSteps>) {
    Object.assign(this, init);
  }
}
