import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CURRENT_ENVIRONMENT } from '../core/injection-tokens/current-environment.token';
import { globalUtilsHelper } from '../helpers/global-utils-helper';
import { AppSettings } from './app-settings';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private _ready = false;
  private _ready$ = new ReplaySubject<void>(1);
  readonly ready$ = this._ready$.asObservable().pipe(tap(() => (this._ready = true)));

  private _settings$ = new BehaviorSubject<AppSettings>(null);
  private _disable = false;
  private _environment; // We CANNOT load environment from import here as this service can be used from different projects.

  constructor(private _http: HttpClient, @Inject(CURRENT_ENVIRONMENT) environment) {
    this._environment = environment;
  }

  init(): void {
    this.loadSettings();
  }

  /**
   * Config that can be fetched from remote.
   */

  get oAuthConfig() {
    return this.settings?.oAuthConfig;
  }

  get gis() {
    return this.settings.gis;
  }

  get map() {
    const mapSettings = { ...this.settings.gis.map };

    mapSettings.center = [...mapSettings.center].map((el) => +el);

    return mapSettings;
  }

  get log() {
    return this.settings.log;
  }

  get minAllowedYear() {
    return this.settings.minAllowedYear;
  }

  get uomConversionDecimals() {
    return this.settings.uomConversionDecimals;
  }

  get uomMinPositionsToKeepPrecision() {
    return this.settings.uomMinPositionsToKeepPrecision;
  }

  get maxDecimalPositions() {
    return this.settings.maxDecimalPositions;
  }

  get maxDecimalPositionsForGreatNumber() {
    return this.settings.maxDecimalPositionsForGreatNumber;
  }

  get greatNumberToChangePrecision() {
    return this.settings.greatNumberToChangePrecision;
  }

  get chartDisplaySymbolThreshold() {
    return this.settings.chartDisplaySymbolThreshold;
  }

  get companyLogo() {
    return this.settings.application.companyLogo;
  }

  get spinner() {
    return this.settings.spinner;
  }

  get telemetryCardSettings() {
    return this.settings.display.telemetry;
  }

  get hierarchyCardSettings() {
    return this.settings.display.hierarchy;
  }

  get maxOverflowToShowExtendedTooltip() {
    return this.settings.maxOverflowToShowExtendedTooltip;
  }

  /**
   * Config that is only available locally.
   */

  get production() {
    return this.settingsLocal.production;
  }

  get apis() {
    return this.settingsLocal.apis;
  }

  get localization() {
    return this.settingsLocal.localization;
  }

  get headers() {
    return this.settingsLocal.headers;
  }

  get defaultCacheDurationMinutes() {
    return this.settingsLocal.defaultCacheDurationMinutes;
  }

  get endDateLowerEqual() {
    return this.settingsLocal.endDateLowerEqual;
  }

  get increaseEndDate() {
    return this.settingsLocal.increaseEndDate;
  }

  get currentTenant() {
    const currentUrl = window.location.origin;
    const tenant = globalUtilsHelper.getFirstUrlSegment(currentUrl);
    return tenant;
  }

  get useMultitenant() {
    const value: any = this.settingsLocal.useMultitenant;
    return value === true || value === 'true';
  }

  get isReady() {
    return this._ready;
  }

  /**
   * Main method. Exposes the loaded settings in a sync way.
   */
  get settings(): AppSettings {
    if (!this._ready || !this._settings$.value) {
      // CAREFUL, do not access settings at attributes declaration or constructor time.
      // Can be avoided by declaring attributes as getters.
      console.error('Settings have been accessed before they have been loaded.');
      return null;
    }
    return this._settings$.value;
  }

  /**
   * Alternative tho the settings prop. Only must be used in parts of the app that always happen before settings load.
   */
  private get settingsLocal(): AppSettings {
    return this._environment;
  }

  private loadSettings(): void {
    if (this._disable) {
      this._settings$.next(this._environment);
      this._ready$.next();
    } else {
      this._http.get<AppSettings>(this._environment.apis.config.url).subscribe((settings) => {
        const mergedSettings = { ...this._environment, ...settings };
        this._settings$.next(mergedSettings);
        this._ready$.next();
      });
    }
  }
}
