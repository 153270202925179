// prettier-ignore
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { DateHelperService } from '../helpers/date-helper.service';
// prettier-ignore
import { CT_DATES_MAP_REQ, CT_DATES_MAP_RES, CT_DATES_REQ_NO_UTC, CT_DATES_RES_STR } from './interceptor-context-tokens';
@Injectable()
export class DatesInterceptor extends BaseService implements HttpInterceptor {
  constructor(injector: Injector, private _datesHelper: DateHelperService) {
    super(injector);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Request settings
    const mapRequest = request.context.get<boolean>(CT_DATES_MAP_REQ);
    const datesNoUtc = request.context.get<string[]>(CT_DATES_REQ_NO_UTC);
    // Response settings
    const mapResponse = request.context.get<boolean>(CT_DATES_MAP_RES);
    const datesKeepStr = request.context.get<string[]>(CT_DATES_RES_STR);

    // Map request.
    if (mapRequest) {
      const body = this._datesHelper.toApiFormatRecursive(request.body, datesNoUtc);
      request = request.clone({
        body,
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Map response.
          if (mapResponse) {
            event = event.clone({
              body: this._datesHelper.fromApiFormatRecursive(event.body, datesKeepStr),
            });
          }
          return event;
        }
      })
    );
  }
}
