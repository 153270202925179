import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { WlmSplashSettings } from './models/wlm-splash-settings';
import { SpinnerOverlayInvokerComponent } from './spinner-overlay-invoker/spinner-overlay-invoker.component';
import { SpinnerOverlayLogoComponent } from './spinner-overlay-logo/spinner-overlay-logo.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { SpinnerSplashService } from './spinner-splash.service';
import { SpinnerWrapperComponent } from './spinner-wrapper/spinner-wrapper.component';
import { SpinnerInterceptor } from './spinner.interceptor';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    SpinnerWrapperComponent,
    SpinnerOverlayComponent,
    SpinnerOverlayLogoComponent,
    SpinnerOverlayInvokerComponent,
  ],
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule],
  exports: [SpinnerComponent, SpinnerWrapperComponent, SpinnerOverlayInvokerComponent],
  providers: [SpinnerInterceptor],
})
export class WlmSpinnerModule {
  static forFeature(settings: WlmSplashSettings): ModuleWithProviders<WlmSpinnerModule> {
    return {
      ngModule: WlmSpinnerModule,
      providers: [
        {
          provide: SpinnerSplashService,
          useExisting: settings.service,
        },
      ],
    };
  }
}
