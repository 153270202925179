import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NotificationPopupService } from 'src/app/common-modules/dependencies/shared/notification-popup.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { FormModes } from 'src/app/common-modules/shared/model/shared/form-mode.enum';
import { NotificationPopupDto } from 'src/app/common-modules/shared/model/shared/notification-popup.dto';
import { NotificationPopupComponent } from '../../shared/notification/notification-popup/notification-popup.component';

const COMPONENT_SELECTOR = 'wlm-top-bar-notification-create-button';
@UntilDestroy()
@Component({
  selector: 'wlm-top-bar-notification-create-button',
  templateUrl: './top-bar-notification-create-button.component.html',
  styleUrls: ['./top-bar-notification-create-button.component.scss'],
})
export class TopBarNotificationCreateButtonComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmNavigation}.${COMPONENT_SELECTOR}`;
  constructor(private _notificationPopupService: NotificationPopupService) {}

  ngOnInit(): void {}

  createNotification() {
    const notification = new NotificationDto({
      notificationTitle: null,
      criticality: null,
      notificationDescription: null,
      to: [],
      groups: [],
    });

    const popupData = new NotificationPopupDto({
      notification,
      mode: FormModes.Create,
    });
    this._notificationPopupService.openPopup(popupData, NotificationPopupComponent);
  }
}
