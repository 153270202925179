import { Injectable } from '@angular/core';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';

/**
 * Contains additional GL utilities.
 * Used to decouple some additional services from the even bigger GoldenLayoutService.
 */

@Injectable()
export class GoldenLayoutHelperService {
  /**
   * Even if we have the itemSetting outside, we should still get it from the full settings.
   * This is because sometimes the itemSetting is missing some properties, like "disableReorder", when it
   * is retrieved from the GL node "state" property.
   */
  getIsComponentReorderEnabled(widgetInstanceKey: string, settings: DynamicLayoutSettings) {
    const itemSettings = settings.items.find(
      (item) => item.widgetInstanceKey === widgetInstanceKey
    );

    let disableReorder = false;
    if (typeof itemSettings?.disableReorder !== 'undefined') {
      disableReorder = itemSettings.disableReorder;
    } else if (typeof settings.disableReorder !== 'undefined') {
      disableReorder = settings.disableReorder;
    }
    return !disableReorder;
  }
}
