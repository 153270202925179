import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, of } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';

const COMPONENT_SELECTOR = 'wlm-family-relations-field-type';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './family-relations-field-type.component.html',
  styleUrls: ['./family-relations-field-type.component.scss'],
})
export class FamilyRelationsFieldTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  availableItems$: Observable<IHierarchyElementTypeDto[]>;
  selectedItemIds: string[];

  ngOnInit(): void {
    this.availableItems$ = Array.isArray(this.props.options)
      ? of(this.props.options)
      : this.props.options;

    this.selectedItemIds = this.formControl.value ?? [];
    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((dtos: IHierarchyElementTypeDto[]) => {
        this.selectedItemIds = dtos.map((dto) => dto.hierarchyElementTypeId);
      });
  }

  onValueChanges(items: IHierarchyElementTypeDto[]): void {
    this.formControl.setValue(items);
    this.formControl.markAsDirty();
  }
}
