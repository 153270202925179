<wlm-spinner-wrapper [showSpinner]="loading" [expandVertical]="false">
  <!--Export chart: this is only loaded when export pdf is required -->
  <div
    *ngIf="enableExportChart && nativeSettings && initSettings"
    class="export"
    echarts
    [ngStyle]="{
      height: defaultChartHeight + 'px'
    }"
    [class.chart-size]="!disableFixedSize"
    [initOpts]="initSettings"
    [options]="nativeSettings"
    [theme]="chartTheme"
    (chartInit)="onChartInitExport($event)"
    (chartFinished)="onChartRenderedExport($event)"
  ></div>
  <div
    *ngIf="nativeSettings && initSettings"
    echarts
    [ngStyle]="{
      height: disableFixedSize ? 'unset' : (height ?? defaultChartHeight) + 'px'
    }"
    [initOpts]="initSettings"
    [options]="nativeSettings"
    [theme]="chartTheme"
    (chartInit)="onChartInit($event)"
    (chartClick)="onChartClick($event)"
    (chartDataZoom)="onChartDataZoom($event)"
    (chartLegendSelectChanged)="onChartLegendSelectChanged($event)"
    (chartFinished)="onChartFinished($event)"
    (chartRendered)="onChartRendered($event)"
    (chartBrushEnd)="onChartBrushEnd($event)"
  ></div>
</wlm-spinner-wrapper>
