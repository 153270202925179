export enum NEConfigActionTypes {
  SelectNetworkElement = '[NE Config] Select Network Element',
  SetAttributesSource = '[NE Config] Set Attributes Source',
  SetBoundarySettings = '[NE Config] Set Boundary Settings',
  SetFormDefinitions = '[NE Config] Set Form Definitions',
  SetFormAdditionalSettings = '[NE Config] Set Form Additional Settings',
  SetFormUIValues = '[NE Config] Set Form UI Values',
  ResetHasChanges = '[NE Config] Reset Has Changes',
  SetPressureSettings = '[NE Config] Set Pressure Settings',
}
