import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  load(settingId: number): Observable<any> {
    return this.httpCacheClient
      .get(`${this.apiUrl}/settings/${settingId}`)
      .pipe(map((data) => (typeof data === 'string' ? JSON.parse(data) : data)));
  }

  save(settingId: number, data: any): Observable<any> {
    return this.httpCacheClient.post(`${this.apiUrl}/settings/${settingId}`, {
      data,
    });
  }

  partial(settingId: number, updates: {[key: string]: any}): Observable<any> {
    return this.httpCacheClient.put(`${this.apiUrl}/settings/${settingId}`, updates);
  }
}
