import { Injector } from '@angular/core';
import { ActionCreator, createSelector, on } from '@ngrx/store';
import { StateHelperService } from '../helpers/state-helper.service';
import { GenericAction } from '../models/generic-action';
import { GenericExtendedSelectorPayload } from '../models/generic-extended-selector';
import { StateFullSettings } from '../models/state-full-settings';
import { StateSelector } from '../models/state-selector';
import { StateSelectorBuilder } from '../models/state-selector-builder';

// TODO: Refactor types

export type GlobalState = any;

export type ReducerHandlerFn = (state: GlobalState, action: GenericAction) => GlobalState;

export const selectFeature = (state: GlobalState) => state;

export abstract class BaseStore {
  private _helperService: StateHelperService;

  constructor(injector: Injector) {
    this._helperService = injector.get(StateHelperService);
  }

  abstract readonly serviceName: string;
  protected abstract getReducer(): Map<string, ReducerHandlerFn>;
  protected abstract getSelectors(): Map<string, StateSelectorBuilder>;
  protected abstract getActionCreators(): ActionCreator<any>[];

  generateReducer(): any[] {
    const reducerMap = this.getReducer();
    const finalReducer = [];
    const actionCreators = this.generateActionCreators();
    reducerMap.forEach((handlerFn, actionName) => {
      const actionCreator = this._helperService.clone(actionCreators.get(actionName));
      const reducerCase = on(
        actionCreator,
        (...args: [globalState: GlobalState, action: GenericAction]) =>
          this.processState(handlerFn, args)
      );
      finalReducer.push(reducerCase);
    });

    return finalReducer;
  }

  generateSelectors(): Map<string, StateSelector> {
    const selectorBuilders = this.getSelectors();
    const scopedSelectors = new Map<string, StateSelector>();
    selectorBuilders.forEach((builder, selectorName) => {
      // Scope the selector fn, so it can only access to a specific part of the state.
      const buildSelector = (
        fullSettings: StateFullSettings,
        payload?: GenericExtendedSelectorPayload
      ) => {
        const path = this._helperService.buildSelectorPath(fullSettings);
        return createSelector(
          [
            (state: GlobalState) => {
              const subState = this._helperService.getSubState(state, path);
              return subState;
            },
          ],

          (subState) => {
            return builder.fn(subState, payload);
          }
        );
      };

      scopedSelectors.set(selectorName, new StateSelector(builder, buildSelector));
    });
    return scopedSelectors;
  }

  generateActionCreators(): Map<string, ActionCreator<any>> {
    const actionsCreator = this.getActionCreators();
    const actionsCreatorMap = new Map(actionsCreator.map((creator) => [creator.type, creator]));

    return actionsCreatorMap;
  }

  private processState(handlerFn: ReducerHandlerFn, [globalState, action]): GlobalState {
    const nextGlobalState = this._helperService.produce(globalState, (currentGlobalState) => {
      const path = this._helperService.buildActionPath(action.settings);
      let subState = this._helperService.getSubState(currentGlobalState, path);
      if (typeof subState === 'undefined') {
        this._helperService.setSubState(currentGlobalState, path);
        subState = this._helperService.getSubState(currentGlobalState, path);
      }
      handlerFn(subState, action);
    });

    return nextGlobalState;
  }
}
