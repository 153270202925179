import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { DynamicGridSettings } from 'src/app/common-modules/shared/model/grid/dynamic-grid-settings';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-customer-detail-meter-readings';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-detail-meter-readings.component.html',
  styleUrls: ['./customer-detail-meter-readings.component.scss'],
})
export class CustomerDetailMeterReadingsComponent implements OnInit {
  private _customerId: string;
  get customerId(): string {
    return this._customerId;
  }
  @Input() set customerId(value: string) {
    this._customerId = value;
    this.additionalFilters = new Map<string, any>();

    if (this.customerId) {
      this.additionalFilters.set('customerId', this.customerId);
      setTimeout(() => {
        this.reloadGrid$.next();
      });
    }
  }

  readonly settings: DynamicGridSettings = {
    gridSettingName: 'CustomerDetailMeterReadings',
    persistencyArea: 'CustomerDetailMeterReadings',
    usePersistence: false,
    type: 'generic',
    disableAutoLoad: false,
  };

  gridFiltersForBinding = new DataBindingFilters();
  additionalFilters: Map<string, any>;
  grid: GenericGridComponent;
  gridSettings: IGridSettings;
  reloadGrid$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {}

  onGridLoaded(grid: GenericGridComponent): void {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
  }
}
