import { Injectable, Injector } from '@angular/core';
import { ALCCampaignDto } from 'src/app/common-modules/dependencies/alc/alc-campaing.dto';
import { BaseService } from '../../base.service';
import { SharedConstantsService } from '../../constants/shared-constants.service';

@Injectable()
export class CellStyleService extends BaseService {
  constructor(injector: Injector, private _sharedConstants: SharedConstantsService) {
    super(injector);
  }

  getCampaignStatusStyle(dataItem: ALCCampaignDto): string {
    const styleMap = this._sharedConstants.getCampaignStatusStyleMapping();
    const style = styleMap.get(dataItem.campaignStatus);

    return style ?? '';
  }

  getStyle(methodName: string, dataItem): string {
    return this[methodName](dataItem);
  }
}
