export enum StateAreas {
  Filters = 'filters',
  NEConfig = 'ne-config',
  UoM = 'uom',
  Algorithms = 'algorithms',
  WidgetManager = 'widget-manager',
  DefaultParams = 'default-params',
  Generic = 'generic',
  DataValidation = 'data-validation',

  FirstFeature = 'first-feature',
  SimpleList = 'simple-list',
  SimpleFilter = 'simple-filter',
  Calendar = 'calendar'
}
