import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutItemSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { AttributeFormGroupSettings } from './attribute-form-group-settings';

const COMPONENT_SELECTOR = 'wlm-ne-config-attribute-form-group';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-attribute-form-group.component.html',
  styleUrls: ['./ne-config-attribute-form-group.component.scss'],
})
export class NeConfigAttributeFormGroupComponent implements OnInit {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  private _selectedNE: INetworkElementDto;
  get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() set selectedNE(value: INetworkElementDto) {
    this._selectedNE = value;
  }

  private _settings: AttributeFormGroupSettings;
  get settings(): AttributeFormGroupSettings {
    return this._settings;
  }
  @Input() set settings(value: AttributeFormGroupSettings) {
    this._settings = value;
    if (this.settings) {
      this.buildDynamicLayoutSettings();
    } else {
      this.layoutSettings = null;
    }
  }

  layoutSettings: DynamicLayoutSettings;
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  constructor(
    private _localization: LocalizationHelperService,
    private _authenticationService: AuthenticationService,
    private _dynamicLayoutService: DynamicLayoutService
  ) {}

  ngOnInit(): void {}

  buildDynamicLayoutSettings(): void {
    this.buildTitles().subscribe((titles) => {
      const items: DynamicLayoutItemSettings[] = this.settings.categories.map((categoryKey) => {
        const componentName = 'NeConfigAttributeFormWidgetComponent';
        const { scopeInstanceKeys } = this.settings.dynamicLayoutKeys;
        return {
          componentName,
          widgetInstanceKey: `${componentName}#${categoryKey}`,
          title: titles.get(categoryKey),
          params: {
            categoryKey,
          },
          scopeInstanceKeys,
        } as DynamicLayoutItemSettings;
      });

      const { settingKey, settingArea, widgetPage, widgetModule } = this.settings.dynamicLayoutKeys;

      this.layoutSettings = new DynamicLayoutSettings({
        layoutKey: this.buildLayoutKey(settingKey),
        layoutArea: settingArea,
        currentUser: this._authenticationService.userCode,
        widgetPage,
        widgetModule,
        items,
      });
    });
  }

  private buildLayoutKey(basicLayoutKey: string): string {
    const isHE = typeof this.selectedNE?.hierarchyElementTypeId !== 'undefined';
    return `${basicLayoutKey}_${isHE ? 'HE' : 'NE'}`;
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.forms`).pipe(
      map((ts) => {
        const titles = new Map();
        Object.entries(ts).forEach(([key, value]) => {
          titles.set(key, value);
        });
        return titles;
      })
    );
  }
}
