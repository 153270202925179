import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { ReadonlyHierarchyElementTypes } from 'src/app/common-modules/dependencies/he/hierarchy.constants';
import { INetworkElementTypeDto } from 'src/app/common-modules/dependencies/ne/network-element-type.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { NETypesCrudService } from '../../ne-types/ne-types-crud.service';

@Component({
  selector: 'wlm-he-type-form',
  templateUrl: './he-type-form.component.html',
  styleUrls: ['./he-type-form.component.scss'],
})
export class HeTypeFormComponent extends IntegrableForm {
  @Input() initialModel: INetworkElementTypeDto;
  model: INetworkElementTypeDto;

  private readonly _neTypesCrudService = inject(NETypesCrudService);
  private readonly _destroyRef = inject(DestroyRef);

  onModelChange(model: INetworkElementTypeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'hierarchyElementTypeName',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Hierarchy Element Type Name',
            required: true,
          },
        },
        {
          key: 'networkElementTypeId',
          type: 'select',
          className: 'col-12',
          props: {
            label: 'Network Element Type',
            valueProp: 'networkElementTypeId',
            labelProp: 'networkElementTypeName',
            options: this._neTypesCrudService.getAll(),
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find(
                        (option) => option[field.props.valueProp] === value
                      );
                      field.model[field.props.labelProp] = selectedOption[field.props.labelProp];
                    });
                });
            },
          },
        },
      ],
    },
  ];
}

ReadonlyHierarchyElementTypes;
