import { HierarchyElementPressureSignalDto } from './hierarchy-element-pressure-signal.dto';

export class PressureSignalConfigurationDto {
  hierarchyElementId: string;
  signals: HierarchyElementPressureSignalDto[];

  constructor(init: Readonly<PressureSignalConfigurationDto>) {
    Object.assign(this, init);
  }
}
