import { SignalSelectionListSettings } from '../../ne-signal-selection-lists/signal-selection-list-settings';

export class LarsSworpsSignalSelectionPopupSettings {
  signalsListSettings: SignalSelectionListSettings;
  title: string;
  minDate: Date;
  maxDate: Date;

  constructor(init: Readonly<LarsSworpsSignalSelectionPopupSettings>) {
    Object.assign(this, init);
  }
}
