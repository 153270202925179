import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { WorkOrderMainActivityTypeDto } from 'src/app/common-modules/dependencies/alc/alc-wo-main-activity-type.dto';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { ALCWorkOrderDto } from '../alc-workorder.dto';

@Injectable()
export class WorkOrderService extends BaseService {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/alc/wo`;
  }

  getByExternalId(id: string): Observable<ALCWorkOrderDto> {
    const wo = this.httpCacheClient.get<ALCWorkOrderDto>(`${this.url}/${id}`);
    return wo;
  }

  getWOMainActivityTypes(): Observable<WorkOrderMainActivityTypeDto[]> {
    const mainActivityTypeUrl = `${this.url}/main-activities`;

    const mainActivityTypes = this.httpCacheClient.get<WorkOrderMainActivityTypeDto[]>(
      `${mainActivityTypeUrl}`
    );
    return mainActivityTypes;
  }
}
