export enum DimensionTypesEnum {
  NA = 0,
  Flow = 1,
  Pressure = 2,
  Level = 3,
  Temperature = 4,
  Volume = 5,
  Length = 6,
  Percentage = 7,
  Diameter = 8,
}
