<div *ngIf="historicals" class="bars-container">
  <div>
    <span class="title">{{ titleKey | translate }}</span>
    <div class="content">
      <div class="bars" *ngFor="let historical of historicals">
        <div class="bar" [style.background-color]="historical.color"></div>
      </div>
    </div>
  </div>
</div>
