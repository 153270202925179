import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalizationHelperService } from '../../../localization/localization-helper.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';

@Injectable()
export class ToBooleanMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToBoolean;

  constructor(private _localization: LocalizationHelperService) {
    super();
  }

  map = (value: unknown): Observable<string> => {
    if (typeof value !== 'boolean') {
      return of(value as string);
    }
    return this._localization.get(value ? 'common.true' : 'common.false');
  };
}
