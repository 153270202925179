import { HistoricalVersionDto } from "src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto";
import { LargeUserSignalFlatDto } from "./large-user-signal-flat.dto";

export class LargeUserSignalVersionDto extends HistoricalVersionDto {

    hierarchyElementId: string;
    hierarchyElementName: string;
    propertyId: string;
    customerId: string;
    largeUserId: number;
    signalId: number;
    pointId: string;
    pointDescription: string;

    constructor(init: LargeUserSignalVersionDto) {
        super(init);
        Object.assign(this, init);
    }

    getCurrentConfig?(): LargeUserSignalFlatDto {
        const current: LargeUserSignalFlatDto = {
            propertyId: this.propertyId,
            hierarchyElementId: this.hierarchyElementId,
            hierarchyElementName: this.hierarchyElementName,
            customerId: this.customerId,
            largeUserId: this.largeUserId,
            organizationName: null,
            signalId: this.signalId,
            pointDescription: this.pointDescription,
            pointId: this.pointId
        };
        return current;
    }
}