<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div *ngIf="calendarId" class="configuration-container">
    <div *hasPerm="['WLMCalendarsCrud', 'u']">
      <wlm-calendar-exception-creator
        [calendarId]="calendarId"
        (isLoading)="onIsLoading($event)"
        (exceptionCreated)="onExceptionCreated($event)"
      ></wlm-calendar-exception-creator>
    </div>
    <div class="detail-container">
      <wlm-calendar-exception-detail
        class="calendar-area"
        [calendarId]="calendarId"
        [reloadDetails$]="reloadDetails$"
        (isLoading)="onIsLoading($event)"
      ></wlm-calendar-exception-detail>
    </div>
  </div>
</wlm-spinner-wrapper>
