<wlm-spinner-wrapper [showSpinner]="loading" [enableRelative]="true">
  <div class="main-container">
    <div class="grid-container">
      <div class="filter-buttons-container no-flex-grow">
        <button
          mat-icon-button
          class="button-position"
          (click)="localGrid.clearFilters()"
          [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
        >
          <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
        </button>
      </div>
      <div class="block-container">
        <wlm-local-grid
          *ngIf="gridSettings"
          class="generic-grid"
          [gridSettings]="gridSettings"
          [gridData]="gridData"
          [resetSelectionHandler$]="resetGridSelection$"
          (selectedItemChanged)="getSelectedItem($event)"
        ></wlm-local-grid>
      </div>
    </div>
    <div class="chart-container">
      <div class="block-container" #chartContainer>
        <wlm-trend-chart
          *ngIf="chartSettings"
          [disableInnerLoading]="true"
          [loading]="loading"
          [chartSettings]="chartSettings"
          [size]="calculatedSize$ | async"
          (loadingEvent)="onLoading($event)"
        ></wlm-trend-chart>
      </div>
    </div>

    <div class="envelope-config-container">
      <div class="block-container">
        <div class="filter-buttons-container buttons-panel-size">
          <button
            mat-raised-button
            color="primary"
            class="button-position btn-size-small"
            [disabled]="!this.isValid || (!selectedTelemetryPoint && !selectedAlgorithmTarget)"
            [matTooltip]="'common.apply' | translate | lowercase"
            (click)="refreshChart()"
            onclick="this.blur()"
          >
            {{ 'common.apply' | translate | uppercase }}
          </button>

          <button
            mat-icon-button
            class="button-position"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
            onclick="this.blur()"
            (click)="resetEnvelopeConfig()"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>

        <wlm-envelopes-config
          *ngIf="settings"
          (valueChanges)="onEnvelopesConfig($event)"
          (valid)="onEnvelopesValid($event)"
          [reset$]="resetEnvelopes$"
          [defaultModel]="settings?.defaultEnvConf"
          [modesToRemove]="envModesToRemove"
          [uoMParams]="uoMParams"
        ></wlm-envelopes-config>
      </div>
    </div>
  </div>
</wlm-spinner-wrapper>
