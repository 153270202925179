import { ListRange } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  CdkScrollable,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { AppModules } from '../../app-modules.enum';
import { DataBindingFilters } from '../../filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '../../helpers/array-helper.service';
import { ObjectHelperService } from '../../helpers/object-helper.service';
import { StringHelperService } from '../../helpers/string-helper.service';
import { GridODataService } from '../../odata/grid-odata.service';
import { DragListSettings } from './drag-list-settings';

const COMPONENT_SELECTOR = 'wlm-drag-list-virtual';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './drag-list-virtual.component.html',
  styleUrls: ['./drag-list-virtual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragListVirtualComponent implements OnInit {
  @ViewChild(CdkVirtualForOf, { static: true })
  private virtualForOf: CdkVirtualForOf<any[]>;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  @ContentChild('card', { static: false }) cardTemplateRef: TemplateRef<any>;

  @Input() refreshList$ = new Subject<void>();

  @Input() reloadList$ = new Subject<void>();

  private _settings: DragListSettings;

  public get settings(): DragListSettings {
    return this._settings;
  }
  @Input() public set settings(value: DragListSettings) {
    if (!value) {
      return;
    }
    this._settings = value;

    this.generateDragListId();
    this.instanceDataService();

    if (value.oDataFilters?.size) {
      this.populateStaticODataFilters(value.oDataFilters);
    }
  }

  get componentName(): string {
    return 'DragListVirtual';
  }

  private _queryParams: Map<string, any>;
  public get queryParams(): Map<string, any> {
    return this._queryParams;
  }
  @Input() public set queryParams(value: Map<string, any>) {
    this.resetList();
    this._queryParams = value;
    this.nextSearchPage(this._searchPageNumber);
    this._changeDetector.detectChanges();
  }

  private _excludeList: any[] = [];
  public get excludeList(): any[] {
    return this._excludeList;
  }
  @Input() public set excludeList(value: any[]) {
    if (this._objectHelper.deepEqual(value, this._excludeList)) {
      return;
    }

    this._excludeList = value;
    this.excludeListChange.emit(value);
    this.reloadWithotQuery();
    if (this.items.length === 0) {
      this.nextSearchPage(this._searchPageNumber);
    }
  }

  @Output() excludeListChange = new EventEmitter<any[]>();

  @Output() somethingChanged = new EventEmitter<void>();

  @Output() listLoaded = new EventEmitter<void>();

  @Output() filterChange = new EventEmitter<void>();

  @Output() draggedItem = new EventEmitter<any>();

  @Output() droppedElement = new EventEmitter<any>();

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  items: any[] = [];
  includeList: any[] = [];
  height: number = 2;
  filterValue: string = '';
  previousFilterValue: string;
  filterHasChanged = false;
  searchFilter: DataBindingFilters = new DataBindingFilters();
  previousFilterApplied: DataBindingFilters;
  previousItemsCount: number;
  template: TemplateRef<any>;
  dragListId: string;

  private _range: ListRange;
  private _searchPageNumber: number = 0;
  private _dataService: GridODataService<any>;
  private _filterOperator = 'contains';
  private _staticFilterOperator = 'eq';
  private _destinationIndex = 0;
  private _minLenghToSearch = 3;
  private _staticOdataFilters = new DataBindingFilters();
  private _hasBeenFiltered = false;
  private _pxToLoad = 1;
  private _allowLoad = true;
  private _scrollWaitTime = 500;
  private _isCurrentlyLoading = false;
  private _waitTimeBeforeSearch: number = 1500;
  private _scheduledSearch: any; // NodeJS.Timeout;
  private readonly enterKey = 'Enter';
  private _randomKey: string;

  constructor(
    private _injector: Injector,
    private _scrollDispatcher: ScrollDispatcher,
    private _changeDetector: ChangeDetectorRef,
    private _objectHelper: ObjectHelperService,
    private _spinnerService: SpinnerService,
    private _arrayHelperService: ArrayHelperService,
    _stringHelperService: StringHelperService
  ) {
    this._randomKey = _stringHelperService.getRandomString();
  }

  ngOnInit(): void {
    if (this.refreshList$) {
      this.refreshList$.pipe(untilDestroyed(this)).subscribe(() => this.refreshList());
    }

    if (this.reloadList$) {
      this.reloadList$.pipe(untilDestroyed(this)).subscribe(() => this.reloadList());
    }
  }

  reloadList(): void {
    this.resetList();
    this.nextSearchPage(this._searchPageNumber);
    this._changeDetector.detectChanges();
  }

  refreshList(): void {
    this.items = [...this.items];
    this._changeDetector.detectChanges();
    if (this.items.length === 0) {
      this.callNextPage();
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      const PREV_IND_WITH_OFFSET = this._range.start + event.previousIndex;
      const CUR_IND_WITH_OFFSET = this._range.start + event.currentIndex;

      moveItemInArray(event.container.data, PREV_IND_WITH_OFFSET, CUR_IND_WITH_OFFSET);
    } else {
      const itemTransferred = event.previousContainer.data[event.item.data];
      if (!this.settings.disableDropItems) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.item.data,
          this._destinationIndex
        );

        this.manageNewItemDropped(itemTransferred);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          [],
          event.item.data,
          this._destinationIndex
        );
      }
    }
    this.refreshList();
    this.somethingChanged.emit();
  }

  manageNewItemDropped(itemTransferred: any) {
    const currentlyExcluded = this.excludeList
      .map((m) => m[this.settings.displayFieldName])
      .some((x) => x === itemTransferred[this.settings.displayFieldName]);

    if (currentlyExcluded) {
      this.excludeList = [
        ...this.excludeList.filter(
          (f) =>
            f[this.settings.displayFieldName] != itemTransferred[this.settings.displayFieldName]
        ),
      ];
    } else {
      this.includeList.push(itemTransferred);
    }
    this.droppedElement.emit(itemTransferred);
  }

  ngAfterViewInit(): void {
    this._scrollDispatcher
      .scrolled(this._scrollWaitTime)
      .pipe(
        filter((event: CdkScrollable) => {
          const scrollId = event.getElementRef().nativeElement.id;
          if (scrollId !== this.dragListId) {
            return false;
          }

          return this.listHasToReload() && this._allowLoad;
        }),
        throttleTime(this._scrollWaitTime)
      )
      .subscribe((event) => {
        this.callNextPage();
      });
  }

  nextSearchPage(pageNumber: number): void {
    const oDataFilter = this._objectHelper.clone(this.searchFilter, false);

    if (this._staticOdataFilters) {
      oDataFilter.mergeDataBindingFilter(this._staticOdataFilters);
    }

    const filterIsEqual = this._objectHelper.deepEqual(oDataFilter, this.previousFilterApplied);
    const itemsCountAreTheSame = this.items?.length === this.previousItemsCount;

    if (filterIsEqual && itemsCountAreTheSame && pageNumber > 1) {
      return;
    } else {
      this.previousFilterApplied = oDataFilter;
      this.setGlobalSpinner(true);

      this.previousItemsCount = this.items?.length;
      this._dataService
        .getQuery(
          [],
          {
            take: this.settings.pageSize,
            skip: pageNumber * this.settings.pageSize,
            sort: this.settings.orderBy,
          },
          oDataFilter,
          true,
          this.queryParams,
          this.settings.cacheOptions
        )
        .subscribe((available) => {
          const filterHasChanged = this.filterValue != this.previousFilterValue;

          if (filterHasChanged) {
            this.items = [...this.getProcessedItems(available.items)];
          } else {
            let newItems = [...this._objectHelper.getCombinedArrays(this.items, available.items)];
            newItems = [...this.getProcessedItems(newItems)];
            newItems = this.sortListLocally(newItems);
            this.items = [...newItems];
          }

          this.previousFilterValue = this.filterValue;

          this._changeDetector.detectChanges();
          this.listLoaded.emit();

          this.setGlobalSpinner(false);
        });
    }
  }

  onKeyDownEvent($event: KeyboardEvent) {
    if (this._isCurrentlyLoading) {
      $event.preventDefault();
    }

    //Commented functionality
    //clearTimeout(this._scheduledSearch);
    if ($event.key == this.enterKey && this.filterValue.length >= this._minLenghToSearch) {
      this.prepareFilterSearch();
    }
  }

  filterList($event) {
    if (this.filterValue === '' || this.filterValue === undefined) {
      this.filterChange.emit();
      return this.clearResults();
    }

    //Commented functionality
    // if (this.filterValue.length >= this._minLenghToSearch) {
    //   this._scheduledSearch = setTimeout(() => {
    //     this.prepareFilterSearch();
    //   }, this._waitTimeBeforeSearch);
    // }
  }

  private prepareFilterSearch() {
    const filterTolower = this.filterValue?.toLowerCase();
    const newFilter = new DataBindingFilters();
    newFilter.addOrUpdateBasicFilter(
      this.settings?.displayFieldName,
      filterTolower,
      this._filterOperator,
      true
    );
    this.searchFilter = newFilter;
    this.filterChange.emit();
    this._searchPageNumber = 0;
    this.nextSearchPage(this._searchPageNumber);
    this._hasBeenFiltered = true;
  }

  getProcessedItems(currentItems: any[]) {
    const includedListFiltered = this.getListFiltered(this.includeList);
    const excludedListFiltered = this.getListFiltered(this.excludeList);

    let processedList = this._objectHelper.getCombinedArrays(currentItems, includedListFiltered);
    const mappedExcludedList = excludedListFiltered.map(this.getItemKey);

    processedList = processedList.filter((f) => !mappedExcludedList.includes(this.getItemKey(f)));

    return processedList;
  }

  getItemKey = (item) => item[this.settings.idFieldName] ?? item[this.settings.displayFieldName];

  getListFiltered(list: any[]) {
    if (this.filterValue.length === 0 || list.length === 0) {
      return list;
    }

    const filterTolower = this.filterValue?.toLowerCase();
    return list.filter((x) =>
      x[this.settings.displayFieldName]?.toLowerCase()?.includes(filterTolower)
    );
  }

  allowDrop = () => {
    return this.settings.allowDrop;
  };

  onDragStarted($event, item) {
    this.draggedItem.emit(item);
    this._allowLoad = false;
  }

  onDragEnded($event) {
    this._allowLoad = true;
  }

  private sortListLocally = (newItems: any[]) => {
    if (this.settings.orderBy?.length && newItems?.length) {
      const sortByField = this.settings.orderBy[0].field;
      const ascending = this.settings.orderBy[0].dir == 'asc';
      newItems = [
        ...this._arrayHelperService.sortArrayObjectCaseInsensitive(
          newItems,
          sortByField,
          ascending
        ),
      ];
    }
    return newItems;
  };

  private instanceDataService() {
    this._dataService = this._injector.get(this.settings?.dataService);
    if (!this.settings.useQueryParams) {
      this.nextSearchPage(this._searchPageNumber);
      this._changeDetector.detectChanges();
    }
    // here we subscribe on viewChange to return ListRange object
    this.virtualForOf.viewChange.subscribe((range: ListRange) => (this._range = range));
  }

  private clearResults() {
    this._hasBeenFiltered = false;
    this.searchFilter = new DataBindingFilters();
    this._searchPageNumber = 0;
    this.nextSearchPage(this._searchPageNumber);
  }

  private populateStaticODataFilters(staticFilters: Map<string, any>) {
    const newFilter = new DataBindingFilters();
    staticFilters.forEach((value, key) => {
      newFilter.addOrUpdateBasicFilter(key, value, this._staticFilterOperator, true);
    });

    this._staticOdataFilters = newFilter;
  }

  private setGlobalSpinner(isLoading: boolean) {
    this._isCurrentlyLoading = isLoading;

    if (this.settings.useGlobalSpinner) {
      this._spinnerService.setLoading(isLoading, this.dragListId);
    }
  }

  private reloadWithotQuery() {
    this.items = [...this.getProcessedItems(this.items)];
    this._changeDetector.detectChanges();
  }

  private resetList() {
    this.items = [];
    this.previousItemsCount = undefined;
    this.includeList = [];
    this._searchPageNumber = 0;
  }

  private listHasToReload() {
    const bottomPosition = this.virtualScroll.measureScrollOffset('bottom');
    return bottomPosition <= this._pxToLoad;
  }

  private callNextPage() {
    this._searchPageNumber++;
    this.nextSearchPage(this._searchPageNumber);
  }

  private generateDragListId() {
    this.dragListId = `${this.componentName}-${this.settings.scrollId}-${this._randomKey}`;
  }
}
