import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from 'src/app/common-modules/shared/auth/services/auth.guard';
import { LeavePageGuard } from 'src/app/common-modules/shared/navigation/leave-page.guard';
import { DataValidationPageComponent } from './data-validation-page/data-validation-page.component';

const routes: Routes = [
  {
    path: '',
    component: DataValidationPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.DataValidation,
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DataValidationRoutingModule {}
