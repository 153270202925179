<wlm-spinner-wrapper [showSpinner]="isLoading" [enableRelative]="true">
  <wlm-popup-wrapper
    [showCloseButton]="false"
    [showSpinner]="false"
    [titleKey]="T_SCOPE + '.title'"
  >
    <ng-container popup-content>
      <div class="popup-container">
        <div class="main-container">
          <div class="selector">
            <wlm-local-grid
              class="selector-grid grid"
              *ngIf="gridSettings && gisLayerSelectorItems"
              [gridSettings]="gridSettings"
              [gridData]="gisLayerSelectorItems"
              (selectedItemChanged)="getSelectedRow($event)"
            ></wlm-local-grid>
          </div>
          <div class="configurator">
            <div
              class="no-result"
              *ngIf="!selectedLayerSetting?.layerId && gridSettings && gisLayerSelectorItems"
            >
              <wlm-placeholder-overlay
                [titleKey]="noSelectionTitleKey"
                [subtitleKey]="noSelectionSubtitleKey"
              ></wlm-placeholder-overlay>
            </div>
            <wlm-map-zoom-configuration
              *ngIf="selectedLayerSetting?.layerId"
              [layerSetting]="selectedLayerSetting"
              [saveConfig$]="saveConfig$"
              (isLoadingChanges)="onIsLoadingChanges($event)"
              (saveFinished)="onSaveFinished($event)"
              (hasValidChanges)="onHasValidChanges($event)"
            ></wlm-map-zoom-configuration>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container popup-actions>
      <div class="action-buttons-container">
        <div class="button-container-padding">
          <button
            mat-button
            class="wtr-action-button-primary btn-size"
            [disabled]="!hasValidChanges"
            (click)="save(false)"
          >
            {{ 'common.save-user' | translate | uppercase }}
          </button>
        </div>
        <div *isSuperUser class="button-container-padding">
          <button
            mat-button
            class="wtr-action-button-primary btn-size"
            [disabled]="!hasValidChanges"
            (click)="save(true)"
          >
            {{ 'common.save-system' | translate | uppercase }}
          </button>
        </div>
        <div>
          <button mat-button class="wtr-action-button-regular btn-size" (click)="close()">
            {{ 'common.close' | translate | uppercase }}
          </button>
        </div>
      </div>
    </ng-container>
  </wlm-popup-wrapper>
</wlm-spinner-wrapper>
