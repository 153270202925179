import { FilterAdapterEnum } from './filter-adapter.enum';

export class BaseFilterItemSettings {
  formMode? = false;
  hideInputSummaryLabel?: boolean;
  hideInputSummary?: boolean;
  required?: boolean;
  maxTemplateSummaryItems?: number;
  storageLocation?: 'local' | 'session' | 'none' = 'local';
  // Only applies in multiselect fields.
  hideSelectAllCheckbox?: boolean;
  allowEmptySelect?: boolean;

  // limits the number of elements
  itemsLimit?: number;

  defaultValue?: any;
  id?: FilterAdapterEnum;

  constructor(data: Readonly<BaseFilterItemSettings>) {
    Object.assign(this, data);
  }
}
