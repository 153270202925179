<div class="main-container">
  <h3 class="title">{{ T_SCOPE + '.title' | translate }}</h3>
  <div class="column-list-container" cdkDropListGroup>
    <div class="column-title-list-container">
      <h3>{{ T_SCOPE + '.hidden-columns-title' | translate }}</h3>

      <div class="columns-container">
        <wlm-drag-list
          class="drag-list"
          [objectsToOrder]="hiddenColumns"
          [itemsSettings]="dragListHiddenItemsSettings"
          (objectsToOrderChanged)="onHiddenColumnsChanged($event)"
          [(selectedObject)]="selectedHiddenColumn"
          (droppedElement)="onDropHiddenList($event)"
          (somethingChanged)="compareVisibleColumns()"
          [titlePipe]="columnTitlePipe"
        ></wlm-drag-list>
      </div>
    </div>
    <div class="move-column-buttons-container">
      <button
        mat-icon-button
        class="move-column-button"
        (click)="setColumnToVisible()"
        [disabled]="!selectedHiddenColumn"
        matTooltip="{{ T_SCOPE + '.set-to-visible' | translate }}"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <button
        mat-icon-button
        class="move-column-button"
        (click)="setAllColumnsToVisible()"
        matTooltip="{{ T_SCOPE + '.set-all-to-visible' | translate }}"
      >
        <mat-icon>last_page</mat-icon>
      </button>
      <button
        mat-icon-button
        class="move-column-button"
        (click)="setColumnToHidden()"
        [disabled]="!selectedVisibleColumn"
        matTooltip="{{ T_SCOPE + '.set-to-hidden' | translate }}"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button
        mat-icon-button
        class="move-column-button"
        (click)="setAllColumnsToHidden()"
        matTooltip="{{ T_SCOPE + '.set-all-to-hidden' | translate }}"
      >
        <mat-icon>first_page</mat-icon>
      </button>
    </div>
    <div class="column-title-list-container">
      <h3>{{ T_SCOPE + '.visible-columns-title' | translate }}</h3>
      <div class="columns-container">
        <wlm-drag-list
          class="drag-list right-component-padding"
          [titlePipe]="columnTitlePipe"
          [itemsSettings]="dragListVisibleItemsSettings"
          [objectsToOrder]="visibleColumns"
          (objectsToOrderChanged)="onVisibleColumnsChanged($event)"
          [(selectedObject)]="selectedVisibleColumn"
          (droppedElement)="onDropVisibleList($event)"
          (somethingChanged)="compareVisibleColumns()"
          (clickButton)="onClickLock($event)"
        ></wlm-drag-list>

        <div *ngIf="!isValidConfiguration()" class="validation-message">
          {{ T_SCOPE + '.messages.columns-unlocked-mandatory-error' | translate }}
        </div>
      </div>

      <div class="right-component-padding row-display" *ngIf="gridSettings?.pageable">
        <span>{{ T_SCOPE + '.pagesize-show' | translate }}</span>

        <mat-select
          class="pagesize-selector"
          [(value)]="pageSize"
          (selectionChange)="compareVisibleColumns()"
        >
          <mat-option *ngFor="let page of pagesizes" [value]="page">
            {{ page }}
          </mat-option>
        </mat-select>
        <span>{{ T_SCOPE + '.pagesize-rows-page' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="action-buttons-container" [class]="currentLocaleCss">
    <button
      mat-raised-button
      onclick="this.blur()"
      (click)="saveUserGridSettings()"
      [disabled]="!isValidConfiguration()"
    >
      {{ T_SCOPE + '.save' | translate }}
    </button>

    <button
      mat-raised-button
      onclick="this.blur()"
      (click)="loadUserGridSettings()"
      [disabled]="!gridSettingSaveState?.hasUserSettings"
    >
      {{ T_SCOPE + '.load' | translate }}
    </button>

    <button
      mat-raised-button
      onclick="this.blur()"
      (click)="saveGlobalGridSettings()"
      *isSuperUser=""
      [disabled]="!isValidConfiguration()"
    >
      {{ T_SCOPE + '.save-template' | translate }}
    </button>

    <button
      mat-raised-button
      onclick="this.blur()"
      (click)="loadGlobalGridSettings()"
      [disabled]="!gridSettingSaveState?.hasSystemSettings"
    >
      {{ T_SCOPE + '.load-template' | translate }}
    </button>

    <button mat-raised-button class="close-button" (click)="close()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</div>
