import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { DelegatePersistLayoutParams } from './delegate-persist-layout-params';

export abstract class DelegatePersistLayoutService {
  abstract config(data: any): void;

  /**
   * Delegate some settings so other component can persist them.
   * @param delegatedId The id of the widget that is delegating the settings.
   * @param handlerId The id of the component that will persist the settings.
   * @param settings The settings to delegate.
   */
  abstract delegate(delegateToId: string, settings: DynamicLayoutSettings): void;

  /**
   * Get all the delegated settings that correspond to a handler id.
   * @param handlerId The id of the component that is managing the persistency.
   */
  protected abstract getDelegated(delegateToId: string): Observable<DynamicLayoutSettings>;

  /**
   * Remove delegated settings that have been completed.
   * @param handlerId The id of the component that is managing the persistency.
   */
  protected abstract removeDelegated(delegateToId: string): void;

  /**
   * Listen if any component has designated this component to handle their persistency
   */
  listenDelegated(
    getSettingsFn: () => DynamicLayoutSettings,
    options: { debounceTime: number } = { debounceTime: 200 }
  ): Observable<DynamicLayoutSettings> {
    const currentId = getSettingsFn().delegatePersistency?.currentId;
    if (currentId) {
      return this.getDelegated(currentId).pipe(
        debounceTime(options.debounceTime),
        map((delegatedSettings: DynamicLayoutSettings) => {
          if (
            delegatedSettings &&
            delegatedSettings.delegatePersistency.delegateToId ==
              getSettingsFn().delegatePersistency.currentId
          ) {
            const updatedSettings = globalUtilsHelper.clone(getSettingsFn(), true);

            // Find which one of the items was the one that delegated its settings.
            const itemSettings = updatedSettings.items.find((item) => {
              return item.widgetInstanceKey === delegatedSettings.delegatePersistency.currentId;
            });

            if (itemSettings) {
              // Update itemSettings by reference.
              (itemSettings.params as DelegatePersistLayoutParams).innerLayout =
                globalUtilsHelper.clone(delegatedSettings, true);
              // Remove delegated because it has already been updated.
              this.removeDelegated(currentId);

              return updatedSettings;
            }
          }
          return null;
        })
      );
    }
  }

  logNestedSettings(settings: DynamicLayoutSettings): any {
    const simplified: any = {
      ids: settings.delegatePersistency,
    };

    const innerLayouts = settings.items
      .map((item) => (item.params as DelegatePersistLayoutParams)?.innerLayout)
      .filter(Boolean);

    simplified.children = innerLayouts.map((innerLayout) => this.logNestedSettings(innerLayout));

    return simplified;
  }
}
