export enum CalendarExceptionTypesEnum {
  Single = 1,
  DaysOfWeek = 2,
  Month = 3,
  Yearly = 4,
}

export enum DaysOfWeekEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
