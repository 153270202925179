<div [ngClass]="hideFilter ? 'flex-style' : 'flex-style col-orientation'">
  <div *ngIf="!hideFilter">
    <mat-form-field
      class="filter-full-width custom-search-field-size drag-list-filter"
      appearance="outline"
    >
      <mat-label>{{ T_SCOPE + '.filter-title' | translate }}</mat-label>
      <input matInput [(ngModel)]="filterValue" (input)="filterList($event)" autocomplete="off" />
    </mat-form-field>
  </div>
  <div
    cdkDropList
    [cdkDropListData]="objectsToOrder"
    class="items-container flex-style"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let item of objectsFiltered"
      [ngClass]="getItemStyle(item)"
      [cdkDragData]="item"
      (click)="selectItem(item)"
      cdkDrag
    >
      <div class="item-custom-placeholder" *cdkDragPlaceholder></div>
      <!-- {{ titlePipe ? titlePipe.transform(item) : item?.title }} -->
      {{ titlePipe.transform(item) | async }}
      <button
        *ngIf="itemsSettings?.showButton"
        mat-icon-button
        [class]="'item-button ' + (item.iconClass ?? '')"
        onclick="this.blur()"
        (click)="onClickItemButton($event, item)"
      >
        <mat-icon class="icon-alignment">{{ item.iconName }}</mat-icon>
      </button>
    </div>
  </div>
</div>
