import { Component, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateRangeFISettings } from 'src/app/common-modules/common-filters/models/date-range-fi-settings';
import { EnvelopeMode } from 'src/app/common-modules/dependencies/alarms/envelope-modes.enum';
import { EnvelopesConfiguration } from 'src/app/common-modules/dependencies/alarms/envelopes-configuration';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import * as constants from 'src/app/common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { RouteNameHelper } from 'src/app/common-modules/dependencies/navigation/route-name-helper';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NetworkElementWizardStepConfiguration } from 'src/app/common-modules/dependencies/shared/ne-wizard-step-configuration';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { AlgorithmFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/algorithm-fi-settings';
import { ElementTargetFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { HierarchyFamilyFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { HierarchyTreeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { NeHeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { TimeAggregationFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/time-aggregation-fi-settings';
import { HierarchyElementsSelectionFilterConfiguration } from 'src/app/common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-filter-configuration';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { ConstantsValues } from 'src/app/common-modules/shared/constants/constants-values';
import { TelemetryDimensionTypes } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { ElementTargetsEnum } from 'src/app/common-modules/shared/model/shared/element-targets.enum';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesDialogSettings } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-dialog-settings';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { DateRangeAndModeSettings } from '../../../../common-modules/common-filters/components/derivated/date-range-and-mode/date-range-and-mode.settings';
import { AlgorithmWizardStepSettings } from '../../../../common-modules/common-filters/filters/algorithm-selection-grid-filter/algorithm-wizard-step-configuration';
import { SACalendarSelectorSettings } from '../../alarms/sa-wizard-components/models/sa-calendar-selector-settings';
import { SAEnvelopesConfigurationSettings } from '../../alarms/sa-wizard-components/models/sa-envelopes-configuration-settings';
import { SANameGeneratorSettings } from '../../alarms/sa-wizard-components/models/sa-name-generator-settings';
import { SATelemetrySelectorSettings } from '../../alarms/sa-wizard-components/models/sa-telemetry-selector-settings';
import { SATimeFrameSelectorSettings } from '../../alarms/sa-wizard-components/models/sa-time-frame-selector-settings';
import { SAWizardMode } from '../../alarms/sa-wizard-components/models/sa-wizard-mode.enum';
import { SAWizardSettings } from '../../alarms/sa-wizard-components/models/sa-wizard-settings';
import { AlarmNameSegment } from '../../shared/model/alarms/alarm-name-part';
import { TelemetryGridFilterConfiguration } from '../../wlm-filters/telemetry-filter/telemetry-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-alarm-wizard-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-wizard-page.component.html',
  styleUrls: ['./sa-wizard-page.component.scss'],
})
export class SAWizardPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker
{
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'AlarmWizardCrud';
  }
  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  resetWizard$: Observable<boolean>;

  private _resetWizardHandler$ = new Subject<boolean>();
  public get resetWizardHandler$(): Subject<boolean> {
    return this._resetWizardHandler$;
  }
  public set resetWizardHandler$(v: Subject<boolean>) {
    this._resetWizardHandler$ = v;
    if (v) {
      this.resetWizard$ = v.asObservable();
    }
  }

  pageId: string = 'SAWizardPage';
  wizardMode = SAWizardMode.Telemetry;

  defaultFamily: string;
  saWizardSettings: SAWizardSettings;

  hierarchyElementIdFieldName = 'hierarchyElementId';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  networkElementTypeIdFieldName = 'networkElementTypeId';
  dimensionTypeIdFieldName = 'dimensionTypeId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  algorithmShortNameFieldName = 'algorithmShortName';
  targetFieldName = 'targetIds';
  timeAggregationFieldName = 'timeAggregationIds';

  persistencyAreaTelemetry = 'telemetry';
  persistencyAreaAlgorithm = 'algorithm';
  persistencyAreaNetworkElement = 'ne-target';
  persistencyAreaHierarchyElement = 'he-target';

  defaultHETypeIds: string[];
  heTypesDisplayableLevels: string[];

  // Profile Name Settings
  telemetrySegments = [
    new AlarmNameSegment({
      field: 'pointId',
      fieldNameKey: 'alarms.wlm-sa-wizard.name-segments.pointId',
    }),
    new AlarmNameSegment({
      field: 'dimensionType',
      fieldNameKey: 'alarms.wlm-sa-wizard.name-segments.dimensionType',
    }),
  ];

  algorithmSegments = [
    new AlarmNameSegment({
      field: 'targetName',
      fieldNameKey: 'alarms.wlm-sa-wizard.name-segments.target-name',
    }),
    new AlarmNameSegment({
      field: 'algorithmShortName',
      fieldNameKey: 'alarms.wlm-sa-wizard.name-segments.algorith-short-name',
    }),
  ];

  showSpinner = false;

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  constructor(
    private _globalsService: GlobalsService,
    private _route: ActivatedRoute,
    private _datesHelper: DateHelperService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
    this.hasRightPanel = false;
    this.resetWizardHandler$ = new Subject<boolean>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getNavigateFrom();

    this.initRightPanel();
    const panelSettings = new TabDetailPanelSettings();
    this.rightPanelService.setTabSettings(panelSettings);

    this.loadDefaultFamily().subscribe({
      next: (family) => {
        this.defaultFamily = family;

        this._globalsService.getHierarchyElementTypes(this.defaultFamily).subscribe({
          next: (hierarchies) => {
            this.heTypesDisplayableLevels = this.getDisplayableHELevels(hierarchies);

            if (!this.defaultHETypeIds) {
              this.defaultHETypeIds = hierarchies
                .filter((he) => he.hierarchyElementTypeName === constants.hierarchyElementTypes.DMA)
                ?.map((x) => x.hierarchyElementTypeId);
            }
            this.initializeSettings();
          },
        });
      },
    });
  }

  checkPendingChanges(
    key: string,
    customDialog?: PendingChangesDialogSettings
  ): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key, customDialog).pipe(
      untilDestroyed(this),
      map((result) => (Array.isArray(result) ? result?.[0] : result))
    );
  }

  canLeavePage(): Observable<boolean> {
    const customDialog = this.getPendingChangesCustomDialog();

    return this.checkPendingChanges(this.pageId, customDialog);
  }

  getDisplayableHELevels(hierarchies: IHierarchyElementTypeDto[]) {
    return hierarchies
      .filter(
        (f) =>
          f.hierarchyElementTypeName === constants.hierarchyElementTypes.DMA ||
          f.hierarchyElementTypeName === constants.hierarchyElementTypes.DZ
      )
      .map((m) => m.hierarchyElementTypeId);
  }

  private getNavigateFrom() {
    const path = this._route.routeConfig.path;
    this.wizardMode =
      RouteNameHelper.alarmsCreateTelemetry === path
        ? SAWizardMode.Telemetry
        : SAWizardMode.Algorithm;
  }

  private loadDefaultFamily(): Observable<string> {
    return this._globalsService.getDefaultHierarchyFamilyId();
  }

  private initRightPanel() {
    this.rightPanelService.setTabSettings(new TabDetailPanelSettings());
  }

  private initializeSettings() {
    const mode = this.wizardMode;

    const itemSettings = new BaseFilterItemSettings({
      hideInputSummaryLabel: true,
      storageLocation: 'none',
      required: true,
      hideSelectAllCheckbox: true,
    });

    const lastMonth = this._datesHelper.getDateByMonthAgo(1);
    const defaultDateRange = new DateRange(lastMonth, new Date());
    const dateRangeSettings = new DateRangeFISettings({
      ...(itemSettings as any),
      dateRange: defaultDateRange,
      storageLocation: 'none',
      defaultValue: defaultDateRange,
    });

    const dateRangeAndModeSettings = new DateRangeAndModeSettings({
      dateRangeSettings,
      periodDays: 10,
      periodIsFixed: true,
    });

    const timeFrameSettings = new SATimeFrameSelectorSettings({
      dateRangeAndModeSettings,
    });

    const pointsSelectionSettings = new SATelemetrySelectorSettings({
      telemetryFilterSettings: new TelemetryGridFilterConfiguration({
        persistencyArea: `${this.persistencyArea}-${this.persistencyAreaTelemetry}`,
        hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
        hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
        networkElementTypeIdFieldName: this.networkElementTypeIdFieldName,
        hierarchyElementFamily: this.defaultFamily,
        hierarchyElementId: [],
        defaultFamilyId: this.defaultFamily,
        neTypeMode: 'single',
        dimensionTypeIdFieldName: this.dimensionTypeIdFieldName,
        storageLocation: 'none',
        dimensionTypeIds: TelemetryDimensionTypes,
      }),
    });

    const envelopesConfigurationSettings = new SAEnvelopesConfigurationSettings({
      defaultEnvConf: new EnvelopesConfiguration({
        mode: EnvelopeMode.Adaptative,
        hiHiPersistency: ConstantsValues.secondsInADay,
        hiPersistency: ConstantsValues.secondsInADay,
        lowLowPersistency: ConstantsValues.secondsInADay,
        lowPersistency: ConstantsValues.secondsInADay,
      }),
      gridName: this.getEnvelopesGridName(mode),
    });

    const map: { [key: string]: boolean } = {};
    // map['94519863-97d8-4527-b173-9bbb8d4edf19'] = true;

    const calendarSettings = new SACalendarSelectorSettings({
      calendarIsWeek: false, // true,
      calendarIsWeekend: false,
      calendars: map,
      seasons: [], // ['6c36feba-ac2c-437d-933d-7cb4ac50fb45'],
    });

    const nameSegments = this.getAlarmNameSegments(mode);

    const alarmNameSettings = new SANameGeneratorSettings({
      alarmNameSegments: nameSegments,
    });

    const neConfiguration = this.getNetworkElementFilterConfiguration(this.defaultFamily);
    const heConfiguration = this.getHierarchyElementFilterConfiguration(this.defaultFamily);
    const targetSettings = { neConfiguration, heConfiguration };

    const algorithmSettings = new AlgorithmWizardStepSettings({
      enableAdditionalFilters: true,
      algorithmSettings: new AlgorithmFISettings({
        fieldName: this.algorithmShortNameFieldName,
        selectedIds: this.algStepPersistedAlgorithms,
        storageLocation: 'none',
      }),
      targetSettings: new ElementTargetFISettings({
        fieldName: this.targetFieldName,
        selectedIds: [ElementTargetsEnum.HierarchyElements], // TODO: recover persistency?
        defaultValue: [ElementTargetsEnum.HierarchyElements],
        storageLocation: 'none',
      }),
      timeAggregationSettings: new TimeAggregationFISettings({
        fieldName: this.timeAggregationFieldName,
        selectedIds: [TimeAggregationEnum.Base], // TODO: recover persistency?
        defaultValue: [TimeAggregationEnum.Base],
        storageLocation: 'none',
      }),
    });

    this.saWizardSettings = new SAWizardSettings({
      timeFrameSettings,
      pointsSelectionSettings,
      envelopesConfigurationSettings,
      calendarSettings,
      alarmNameSettings,
      targetSettings,
      algorithmSettings,
      mode,
    });
  }

  private getNetworkElementFilterConfiguration(
    defaultFamilyId: string
  ): NetworkElementWizardStepConfiguration {
    return {
      persistencyArea: null,
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
      networkElementTypeIdFieldName: this.networkElementTypeIdFieldName,
      defaultNetworkElementId: [],
      defaultNetworkElementTypeId: [],
      defaultFamilyId,
      initialFamilyId: defaultFamilyId,
      initialNetworkElementId: [],
      initialNetworkElementTypeId: [],
      storageLocation: 'none',
    };
  }

  private getHierarchyElementFilterConfiguration(
    defaultFamilyId: string
  ): HierarchyElementsSelectionFilterConfiguration {
    return new HierarchyElementsSelectionFilterConfiguration({
      persistencyArea: null,
      familySettings: new HierarchyFamilyFISettings({
        fieldName: this.hierarchyElementFamilyFieldName,
        selectedId: defaultFamilyId,
        storageLocation: 'none',
      }),
      treeSettings: new HierarchyTreeFISettings({
        fieldName: this.hierarchyElementIdFieldName,
        selectedIds: [],
        storageLocation: 'none',
      }),
      heTypeSettings: new NeHeFISettings({
        fieldName: this.hierarchyElementTypeFieldName,
        selectedIds: this.defaultHETypeIds,
        defaultValue: this.defaultHETypeIds,
        isNEFilter: false,
        storageLocation: 'none',
      }),
      heDisplayableLevels: this.heTypesDisplayableLevels,
    });
  }

  resetWizard() {
    const customDialog = this.getPendingChangesCustomDialog();

    return this.checkPendingChanges(this.pageId, customDialog).subscribe((result) => {
      if (result) {
        this.resetWizardHandler$.next(true);
      }
    });
  }

  onShowSpinner(show: boolean) {
    this.showSpinner = show;
  }

  private getEnvelopesGridName(mode: SAWizardMode): string {
    switch (mode) {
      case SAWizardMode.Algorithm:
        return 'EnvelopeConfigurationAlgorithm';
      case SAWizardMode.Telemetry:
        return 'EnvelopeConfiguration';
      default:
        return 'EnvelopeConfiguration';
    }
  }

  private getAlarmNameSegments(mode: SAWizardMode): AlarmNameSegment[] {
    switch (mode) {
      case SAWizardMode.Algorithm:
        return this.algorithmSegments;
      case SAWizardMode.Telemetry:
        return this.telemetrySegments;
      default:
        return this.telemetrySegments;
    }
  }

  // Persistency getters from Algorithm step
  private get algStepPersistedAlgorithms(): string[] {
    const result = this.getPersisted(
      `${this.persistencyAreaAlgorithm}-${this.algorithmShortNameFieldName}`,
      [],
      null,
      false
    );
    return result?.value ? result?.value : undefined;
  }

  private getPendingChangesCustomDialog(): PendingChangesDialogSettings {
    const customDialog: PendingChangesDialogSettings = {
      dialogSettings: new WlmDialogSettings({
        translateKey: `common.messages.confirm-pending-changes-lost`,
        icon: 'warning',
      }),
      dialogFn: (dialogRef: WLMDialogResult) => of(dialogRef.result),
    };

    return customDialog;
  }
}
