import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { HierarchyElementPathFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from 'src/app/common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from 'src/app/common-modules/shared/component/base-page-filters.component';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { PersistencyService } from 'src/app/common-modules/shared/persistency.service';
import { TreeSettingsService } from 'src/app/common-modules/shared/services/tree-settings.service';
import { DateRangeFAdapter } from '../../../../../common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { ElementTypeFAdapter } from '../../../../../common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { ITreeSettings } from '../../../../../common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { LeakageReportingFilterConfiguration } from './leakage-reporting-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-leakage-reporting-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './leakage-reporting-filter.component.html',
  styleUrls: ['./leakage-reporting-filter.component.scss'],
})
export class LeakageReportingFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _leakageReportingFilterConfig: LeakageReportingFilterConfiguration;
  public get leakageReportingFilterConfig(): LeakageReportingFilterConfiguration {
    return this._leakageReportingFilterConfig;
  }
  @Input() public set leakageReportingFilterConfig(value: LeakageReportingFilterConfiguration) {
    this._leakageReportingFilterConfig = value;
    if (value) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  @Input() disableAutoload = false;

  private _disablefilters = false;
  public get disablefilters() {
    return this._disablefilters;
  }

  @Input() public set disablefilters(value) {
    this._disablefilters = value;

    this.dateFilterSettings.disableFilter = this._disablefilters;
    this.hierarchyElementGroupSettings.disableFilter = this._disablefilters;
    this.treeGroupSettings.disableFilter = this._disablefilters;
  }

  T_SCOPE = `${AppModules.LeakageReporting}.${COMPONENT_SELECTOR}`;

  rebuildTreeFilter$ = new Subject<void>();
  clearHEFilter$ = new Subject<void>();

  maxDateRangeDays = 31;

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  familyIdSelected: string;
  treeFamilyId: string;
  mustPersistFilters = false;
  loaded = false;
  useInitialTreeValues = true;
  treeFilterReadyToDisplay = false;
  filterHasChanged = false;
  previousFamilyId: string;
  selectedHETypeIds: string[];
  oldHELevelSelection: string[];
  oldFamilyIdSelected: string;

  heTypes = ['6828e991-8326-4e7e-b244-a026b67ac867', 'efd92639-f72a-464a-aaba-53e78e0ba129'];
  disabledNodeTypes = [
    // 'Company',
    // 'East-West',
    // 'Operational Area',
    // 'Planning Zone',
    // 'Water Resource Zone',
    // 'Group',
  ];

  dateFilterSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-daterange-label`,
  };

  hierarchyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  familyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  treeGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-tree-label`,
  };

  hierarchyElementGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-he-label`,
  };

  dateRangeItemSettings = new BaseFilterItemSettings({
    storageLocation: 'session',
    hideInputSummaryLabel: true,
  });

  private _hierarchyElementTypesLevelList: IHierarchyElementTypeDto[];
  private _disabledhierarchyElementTypes: string[];

  constructor(
    private _adapterService: FiltersAdapterService,
    private _treeSettingsService: TreeSettingsService,
    private _arrayHelperService: ArrayHelperService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
    this.initHierarchyFamily();
    this.setInitialHETypeIds();
    this.loaded = true;
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const {
      startDateFieldName,
      endDateFieldName,
      dateRange,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    } = this.leakageReportingFilterConfig;

    const adapters = [
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        startDateFieldName,
        endDateFieldName,
        inclusive: dateRange?.inclusive,
        allowsNullDate: dateRange?.allowsNullDate,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.familyIdSelected,
      }),
      new ElementTypeFAdapter({
        dataBindingField: 'hierarchyElementType',
        elementTypeFieldName: this.leakageReportingFilterConfig.heSettings.elementTypeFieldName,
        neheFilterSettings: new NeHeFISettings({
          isNEFilter: false,
          selectedIds: this.selectedHETypeIds,
          fieldName: this.leakageReportingFilterConfig.heSettings.elementTypeFieldName,
        }),
      }),
    ];

    return adapters;
  };

  protected setFiltersPersistencyArea(): void {
    this.dateFilterSettings.persistencyArea = this.leakageReportingFilterConfig.persistencyArea;
    this.hierarchyElementGroupSettings.persistencyArea =
      this.leakageReportingFilterConfig.persistencyArea;
    this.treeGroupSettings.persistencyArea = this.leakageReportingFilterConfig.persistencyArea;
  }

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.leakageReportingFilterConfig;
    this.keysToComplete = [
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
      this.leakageReportingFilterConfig.heSettings.elementTypeFieldName,
    ];
  }

  private setInitialHETypeIds() {
    this.selectedHETypeIds =
      this.leakageReportingFilterConfig?.heSettings?.selectedElementIds ??
      this.leakageReportingFilterConfig.defaultHETypeIds;
  }

  initHierarchyFamily(): void {
    this.familyIdSelected =
      this.leakageReportingFilterConfig.initialFamilyId ??
      this.leakageReportingFilterConfig.defaultFamilyId;
    this.previousFamilyId = this.familyIdSelected;
  }

  calculateDisabledNodes(selectedElements: any[]) {
    this._disabledhierarchyElementTypes = [];

    let disabledNodes = [];

    if (selectedElements?.length > 0 && this._hierarchyElementTypesLevelList) {
      const index = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeId)
        .indexOf(selectedElements[0]);
      disabledNodes = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeName)
        .slice(index + 1);
    }
    this._disabledhierarchyElementTypes = disabledNodes.concat(this.disabledNodeTypes);
  }

  /**
   * Only used to save the selected family id.
   * Will allways contain an array of one element, as it is single select.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.leakageReportingFilterConfig.hierarchyElementFamilyFieldName;
    const defaultId = this.leakageReportingFilterConfig.defaultFamilyId;
    this.familyIdSelected = this._adapterService.getFamilyId(filters, familyFieldName, defaultId);

    // Assign the selected family to the tree at least the first time, so the tree can be loaded.
    // If the tree is not loaded, the container cannot coordinate its events.
    if (!this.treeFamilyId) {
      this.rebuildTree(this.selectedHETypeIds ?? []);
    }
  }

  buildTreeSettings(): void {
    this._treeSettingsService
      .buildTreeSettings({
        unselectableTypes: this._disabledhierarchyElementTypes,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  // This is necessary to persist the tree values
  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  processFilterChanges(results: FilterAdapterResult) {
    this.onGlobalFilterResults(results);
  }

  onhierarchyLevelsListChanged(hierarchyLevelList: IHierarchyElementTypeDto[]) {
    this._hierarchyElementTypesLevelList = hierarchyLevelList;
  }

  onApplyHEFilter(filters: FiltersPayload) {
    const selectedElements = filters.getSelectedIds(
      this.leakageReportingFilterConfig.heSettings.elementTypeFieldName
    ) as string[];

    this.oldHELevelSelection =
      this.oldHELevelSelection ?? this.leakageReportingFilterConfig.defaultHETypeIds;

    if (!this._arrayHelperService.areSame(selectedElements, this.oldHELevelSelection)) {
      this.oldHELevelSelection = [...selectedElements];
      this.rebuildTree(selectedElements);
    }
  }

  private rebuildTree(selectedElementTypes: string[]): void {
    this.treeFamilyId = this.familyIdSelected;

    this.calculateDisabledNodes(selectedElementTypes);
    this.buildTreeSettings();
    this.rebuildTreeFilter$.next();
  }
}
