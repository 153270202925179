import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { LarsSworpsConfigurationDto } from '../../shared/model/signals/lars-sworps-configuration.dto';
import { LarsAndSworpsSiteSignalsDto } from '../../shared/model/signals/lars-sworps-site-signal.dto';
import { SaveLarsAndSworpsSiteSignalResponseDto } from '../../shared/model/signals/save-lars-and-sworps-site-signal-response.dto';

const endpointUrl = '/api/larssworps';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationLarsSworpsService extends GridODataService<LarsAndSworpsSiteSignalsDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<LarsAndSworpsSiteSignalsDto>
  ): PagedResultDto<LarsAndSworpsSiteSignalsDto> {
    return response;
  }

  getLarsSworpsSignalsByNE(networkElementId: string): Observable<LarsAndSworpsSiteSignalsDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${networkElementId}`, {
      avoid: true,
    });
  }

  saveLarsAndSworpsConfiguration(
    larsSworpsConfiguration: LarsSworpsConfigurationDto
  ): Observable<SaveLarsAndSworpsSiteSignalResponseDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/save`, larsSworpsConfiguration);
  }
}
