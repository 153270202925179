import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subscription, startWith } from 'rxjs';
import { CVShortQueryDto } from '../../dependencies/shared/model/cv-short-query.dto';
import { BaseCalculateResizableChildComponent } from '../../shared/core/responsive/base-calculate-resizable-child.component';
import { DateFormats } from '../../shared/localization/date-formats.enum';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { DateRange } from '../../shared/model/date/date-range';
import { IElementSize } from '../../shared/model/element-size';
import { MVQueryDto } from '../../shared/model/mv/mv-query.dto';
import { LogService } from '../../shared/wlm-log/log.service';
import { CustomWorkspaceChartComponent } from '../core/custom-workspace-chart/custom-workspace-chart.component';
import { EventsChartComponent } from '../core/events-chart/events-chart.component';
import { EventChartPosition } from '../core/events-chart/models/event-chart-position';
import { EventChartQueryDto } from '../core/events-chart/models/events-chart-query.dto';
import { ChartType } from '../core/models/chart-type.enum';
import { DynamicChartSettings } from '../core/models/dynamic-chart-settings';
import { GenericCartesianChartSettings } from '../core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { GChartClickEvent } from '../core/models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from '../core/models/generic-events/g-chart-data-zoom-event';
import { GChartInitEvent } from '../core/models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from '../core/models/generic-events/g-chart-legend-selected-event';
import { TimeSelectorPeriod } from '../core/models/time-selector-period';
import { TrendChartDataParameters } from '../core/models/trend-chart-data-parameters';
import { TrendChartSettings } from '../core/models/trend-chart-settings';
import { TrendChartComponent } from '../core/trend-chart/trend-chart/trend-chart.component';

const COMPONENT_SELECTOR = 'wlm-dynamic-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dynamic-chart.component.html',
  styleUrls: ['./dynamic-chart.component.scss'],
})
export class DynamicChartComponent extends BaseCalculateResizableChildComponent implements OnInit {
  @Input() public loading = false;
  @Input() size: IElementSize;
  @Output() chartInitEvent = new EventEmitter<GChartInitEvent>();
  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();
  @Output() chartLegendSelectedEvent = new EventEmitter<GChartLegendSelectedEvent>();
  @Output() chartLoaded = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();

  dimensionToCalculate: 'height' | 'width' = 'height';

  private _dateParamsChanged$: Observable<DateRange>;
  get dateParamsChanged$(): Observable<DateRange> {
    return this._dateParamsChanged$;
  }
  @Input() set dateParamsChanged$(value: Observable<DateRange>) {
    this._dateParamsChanged$ = value;

    if (this._dateParamsChangedSubs && !this._dateParamsChangedSubs.closed) {
      this._dateParamsChangedSubs.unsubscribe();
    }
    this._dateParamsChangedSubs = this.dateParamsChanged$
      .pipe(untilDestroyed(this))
      .subscribe((dateRange) => {
        this.setSelectedDateRange(dateRange);
        this.setDataParams(dateRange);
      });
  }

  private _selectedPeriod: TimeSelectorPeriod;
  public get selectedPeriod(): TimeSelectorPeriod {
    return this._selectedPeriod;
  }
  @Input() public set selectedPeriod(v: TimeSelectorPeriod) {
    this._selectedPeriod = v;
    if (v) {
      this.setSelectedDateRange(new DateRange(v.startDate, v.endDate));
      this.loadChart();
    }
  }

  private _customizableChart: CustomWorkspaceChartComponent;
  public get customizableChart(): CustomWorkspaceChartComponent {
    return this._customizableChart;
  }
  @ViewChild(CustomWorkspaceChartComponent) public set customizableChart(
    v: CustomWorkspaceChartComponent
  ) {
    this._customizableChart = v;
    this.loadChart();
  }

  private _trendChart: TrendChartComponent;
  public get trendChart(): TrendChartComponent {
    return this._trendChart;
  }
  @ViewChild(TrendChartComponent) public set trendChart(v: TrendChartComponent) {
    this._trendChart = v;
    this.loadChart();
  }

  @ViewChild(EventsChartComponent) eventChart: EventsChartComponent;

  private _dynamicChartSettings: DynamicChartSettings;
  public get dynamicChartSettings(): DynamicChartSettings {
    return this._dynamicChartSettings;
  }
  @Input() public set dynamicChartSettings(v: DynamicChartSettings) {
    this._dynamicChartSettings = v;
    if (v) {
      this.chartType = v.chartSettings.chartType;
      this.setChartSettings(v);
    }
  }

  eventChartSettings: EventChartQueryDto;

  chartTypes = ChartType;
  chartSettings: TrendChartSettings;
  chartType: ChartType;

  selectedRange: DateRange;

  // Specify the lower and upper boundaries of the main X Axis.
  xAxisMin: Date;
  xAxisMax: Date;
  xAxisRange: DateRange;

  mainChartLoading = false;
  eventChartLoading = false;

  private _dateParamsChangedSubs: Subscription;
  private readonly _eventsChartSizeChanges$ = new ReplaySubject<IElementSize>(1);

  constructor(
    private _localization: LocalizationHelperService,
    private _logService: LogService,
    private readonly _cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {}

  setMainChartLoading(isLoading: boolean) {
    this.mainChartLoading = isLoading;
  }

  onChartInit = (event) => this.chartInitEvent.next(event);
  onChartClick = (event) => this.chartClickEvent.next(event);

  onChartDataZoom(chartDataZoom: GChartDataZoomEvent): void {
    if (!this.eventChart) {
      return;
    }
    const { start, end } = chartDataZoom;
    this.eventChart.position = new EventChartPosition({
      start,
      end,
    });
  }

  onChartLegendSelected = (event) => this.chartLegendSelectedEvent.emit(event);
  onChartLoaded = (event) => this.chartLoaded.emit(event);
  onChartFinished = (event) => this.chartFinished.emit(event);

  /**
   * Specify the min and max values of the main X axis, so the event chart can adjust to them.
   * Assumes the first x axis in the axis array is the main one.
   */
  onMainChartSettingsChange(settings: GenericCartesianChartSettings): void {
    if (!settings || !settings.xAxes.length || !settings?.useDataZoom) {
      this.chartLoaded.emit(true);
      return;
    }

    const min = settings.xAxes[0].min;
    const max = settings.xAxes[0].max;
    if (typeof (min as Date).getMonth === 'function') {
      this.xAxisMin = min as Date;
      this.xAxisMax = max as Date;
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else if (typeof min === 'string') {
      this.xAxisMin = this._localization.localStrToDate(min as string, DateFormats.DateTime);
      this.xAxisMax = this._localization.localStrToDate(max as string, DateFormats.DateTime);
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else {
      this._logService.error({
        msg: 'Min and max properties of the main chart are not bindable to the events chart.',
      });
    }
  }

  onEventChartLoading(loading): void {
    this.eventChartLoading = false;
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [this._eventsChartSizeChanges$.asObservable().pipe(startWith(null))];
  }

  private loadChart() {
    this.setLoadings(false);
    if (this.selectedRange && (this.customizableChart || this.trendChart)) {
      this.setDataParams(new DateRange(this.selectedPeriod.startDate, this.selectedPeriod.endDate));
    }
  }

  private setLoadings(isLoading: boolean) {
    this.loading = isLoading;
    this.setMainChartLoading(isLoading);
    this.onEventChartLoading(isLoading);
    this._cd.detectChanges();
  }

  private setTimeSelectorDateParams(period: DateRange) {
    if (!this.dynamicChartSettings?.chartSettings?.dataParameters || !period) {
      return;
    }
    this.dynamicChartSettings.chartSettings.dataParameters.endDate = period.end;
    this.dynamicChartSettings.chartSettings.dataParameters.startDate = period.start;
  }

  private setDatesQueryParams(
    startDate: Date,
    endDate: Date,
    params: TrendChartDataParameters
  ): TrendChartDataParameters {
    const trendChartParams = params;
    const queryParams = params.queryParams as (MVQueryDto | CVShortQueryDto)[];

    if (!queryParams.length) {
      return;
    }

    queryParams.forEach((x) => {
      x.endDate = endDate;
      x.startDate = startDate;
    });

    trendChartParams.queryParams = queryParams;

    return trendChartParams;
  }

  private setChartSettings(settings: DynamicChartSettings) {
    this.eventChartSettings = settings.eventChartSettings;
  }

  private setDataParams(period: DateRange) {
    this.setTimeSelectorDateParams(period);
    if (this.dynamicChartSettings?.chartSettings?.chartType === ChartType.customizable) {
      this.customizableChart.setDateParams(period.start, period.end);
    } else if (this.dynamicChartSettings?.chartSettings?.chartType === ChartType.trend) {
      const queryParams = this.setDatesQueryParams(
        period.start,
        period.end,
        this.dynamicChartSettings.chartSettings.dataParameters as TrendChartDataParameters
      );
      this.trendChart.setDateParams(period.start, period.end, queryParams);
    }

    if (this.trendChart || this.customizableChart) {
      this.xAxisRange = null;
    } else {
      this.xAxisRange = new DateRange(period.start, period.end);
    }

    //Reset Event chart
    this.eventChartSettings = null;
    if (this.dynamicChartSettings?.eventChartSettings) {
      this.eventChartSettings = {
        ...this.dynamicChartSettings.eventChartSettings,
        startDate: period.start,
        endDate: period.end,
      };
    }
  }

  private setSelectedDateRange(dateRange: DateRange) {
    this.selectedRange = dateRange;
  }
}
