import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialogs.service';

export function handleValidationError(dialogsService: DialogService) {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      catchError((error) => {
        if (error.status === 400) {
          dialogsService.showTranslatedMessage({
            translateKey: 'common.messages.validation',
            icon: 'error',
          });
        }
        throw error;
      })
    );
  };
}
