// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { IHierarchyElementTypeDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-type.dto';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';

const COMPONENT_SELECTOR = 'wlm-hierarchy-elements-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-elements-filter-item.component.html',
  styleUrls: ['./hierarchy-elements-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => HierarchyElementsFilterItemComponent),
    },
  ],
})
export class HierarchyElementsFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AdaptedFilterItem
{
  // Higher Order Observable.
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);

  familyHasChanged = false;
  private _familyId: string;
  @Input() public set familyId(value: string) {
    if (!value) {
      return;
    }
    this._familyId = value;
    this.familyHasChanged = true;
    this.data$$.next(this.getData$(this._familyId));

    if (!this.restoreSelectedItemsHasBeenDone) {
      this.clearAll$.next();
    } else {
      this.restoreSelectedItemsHasBeenDone = false;
      this.familyHasChanged = false;
    }
  }

  @Input() mode: 'single' | 'multiple' = 'single';

  @Input() public set hideFilter(value: boolean) {
    this.hide = value;
    this.selectedIds = [];
  }

  @Input() set restoreStatusOrder(value: number) {
    this.restoreOrder = value;
  }

  @Input() filterListElements: any[];

  // Exclude by HierarchyElementTypeName ('DMA', 'DZ', etc...)
  @Input() excludeElementTypes: any[];

  @Input() defaultSelectedElements: any[];

  @Output() hierarchyLevelsListChanged = new EventEmitter<IHierarchyElementTypeDto[]>();

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  private defaultSettings = new BaseFilterItemSettings({
    required: true,
  });
  set settings(value: BaseFilterItemSettings) {
    if (value) {
      const settings = Object.assign(this.defaultSettings, value);
      super.settings = Object.assign(super.settings, settings);
    }
  }
  get settings(): BaseFilterItemSettings {
    return super.settings;
  }
  loaded = false;
  restoreSelectedItemsHasBeenDone = false;

  constructor(private globalsService: GlobalsService) {
    super();
  }

  ngOnInit(): void {
    super.onInit();
  }

  /**
   * Builds an observable that obtains the required data for the select.
   */
  getData$(familyId?: string): Observable<FilterItemSelectOption[]> {
    this.loaded = true;

    return this.globalsService.getHierarchyElementTypes(familyId).pipe(
      map((items) => {
        const hasDisplayableFilterDefined =
          this.filterListElements && this.filterListElements.length > 0;

        let itemsFiltered = items.filter(
          (f) =>
            f.networkElementTypeId !== undefined &&
            (!hasDisplayableFilterDefined ||
              (hasDisplayableFilterDefined &&
                this.filterListElements.includes(f.hierarchyElementTypeId)))
        );

        if (this.excludeElementTypes) {
          itemsFiltered = itemsFiltered.filter(
            (x) => !this.excludeElementTypes.includes(x.hierarchyElementTypeName)
          );
        }

        const hierarchyLevels = itemsFiltered.map(
          (item) =>
            new FilterItemSelectOption(item.hierarchyElementTypeId, item.hierarchyElementTypeName)
        );

        this.hierarchyLevelsListChanged.emit(itemsFiltered);

        return hierarchyLevels;
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.isValidSelect;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  onInitRestore() {
    if (this.familyHasChanged) {
      this.restoreSelectedItemsHasBeenDone = true;
    }
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.ElementTypeFilter;
  }
}
