import {
  ComponentFactoryResolver,
  Injectable,
  Injector,
  StaticProvider,
  Type,
} from '@angular/core';
import { ComponentContainer, JsonValue } from 'golden-layout';
import { WidgetSettingsToken } from '../dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from '../redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '../redux/models/state-widget-settings';
import { globalUtilsHelper } from '../shared/helpers/global-utils-helper';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { GLContainerInjectionToken } from './golden-layout-exports';

@Injectable()
export class GoldenLayoutComponentService {
  private _componentsByInstanceKey = new Map<string, Type<BaseDynamicWidgetComponent>>();

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _widgetRegistry: WidgetRegistryService
  ) {}

  registerComponentType(instanceKey: string, componentType: Type<BaseDynamicWidgetComponent>) {
    this._componentsByInstanceKey.set(instanceKey, componentType);
  }

  getRegisteredComponentTypeNames(): string[] {
    const result = Array.from(this._componentsByInstanceKey.keys());
    return result;
  }

  isRegistered(instanceKey: string): boolean {
    return this._componentsByInstanceKey.has(instanceKey);
  }

  createComponent(
    componentTypeJsonValue: JsonValue,
    container: ComponentContainer,
    parentInjector: Injector = null
  ) {
    let componentType = this._componentsByInstanceKey.get(componentTypeJsonValue as string);
    if (!componentType) {
      componentType = this._widgetRegistry.get(
        (container.state as StateWidgetSettings).itemSettings.componentName
      );
    }
    if (!componentType) {
      // If the widget is not registered, try getting it by actual component name.
      console.error({
        byKeys: this._componentsByInstanceKey,
        container,
      });
      throw new Error('Unknown component type');
    } else {
      const containerProvider: StaticProvider = {
        provide: GLContainerInjectionToken,
        useValue: container,
      };
      // New_GL_Version
      const state = globalUtilsHelper.clone(container.state ?? container.initialState, true);
      const stateProvider: StaticProvider = {
        provide: WidgetSettingsToken,
        useValue: state, // This was different in the previous version, the state was not extracted from container.
      };
      const injector = Injector.create({
        providers: [containerProvider, stateProvider],
        parent: parentInjector,
      });
      const componentFactoryRef =
        this._componentFactoryResolver.resolveComponentFactory<BaseDynamicWidgetComponent>(
          componentType
        );
      return componentFactoryRef.create(injector);
    }
  }
}
