import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from '../../../shared/filters/component-filters/filter';
import { ActivityCategoryTypesEnum } from './activity-category-types';

export class ActivityCategoryTypesFilter implements IFilter {
  includeRepair: boolean;
  includeDetection: boolean;
  repairFieldName: string;
  detectionFieldName: string;

  constructor(repairFieldName: any = null, detectionFieldName: any = null) {
    this.repairFieldName = repairFieldName;
    this.detectionFieldName = detectionFieldName;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([
      [this.repairFieldName, this.includeRepair],
      [this.detectionFieldName, this.includeDetection],
    ]);
  }

  getSelectedFilters(): ActivityCategoryTypesEnum[] {
    const activitiesSelected: ActivityCategoryTypesEnum[] = [];
    if (this.includeDetection) {
      activitiesSelected.push(ActivityCategoryTypesEnum.IsDetection);
    }
    if (this.includeRepair) {
      activitiesSelected.push(ActivityCategoryTypesEnum.IsRepair);
    }
    return activitiesSelected;
  }

  getFilters(): CompositeFilterDescriptor {
    if (!this.includeDetection && !this.includeRepair) {
      return null as any;
    }

    const compositeFilter: CompositeFilterDescriptor = {
      filters: [],
      logic: 'or',
    };

    if (this.includeRepair) {
      const filterIncludeRepair: FilterDescriptor = {
        field: this.repairFieldName,
        operator: 'eq',
        value: this.includeRepair,
      };
      compositeFilter.filters.push(filterIncludeRepair);
    }

    if (this.includeDetection) {
      const filterIncludeDetection: FilterDescriptor = {
        field: this.detectionFieldName,
        operator: 'eq',
        value: this.includeDetection,
      };
      compositeFilter.filters.push(filterIncludeDetection);
    }

    return compositeFilter;
  }
}
