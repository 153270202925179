import { PeriodTypesEnum } from './period-types.enum';

export class TimeSelectorPeriod {
  startDate!: Date;
  endDate!: Date;
  keyLabel!: string;
  numberParam?: number;
  periodType!: PeriodTypesEnum;

  public constructor(init?: Partial<TimeSelectorPeriod>) {
    Object.assign(this, init);
  }
}
