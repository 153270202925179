import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FieldValidatorUITypes } from '../models/field-validator-ui-types';

export class TranslateExtension {
  constructor() {}

  prePopulate(field: FormlyFieldConfig) {}
}

function buildMessage(
  translate: TranslateService,
  validationName: FieldValidatorUITypes,
  translateKey: string
) {
  return {
    name: validationName,
    message(currentError, field: FormlyFieldConfig) {
      // For this to work, the interpolation variable in the translation JSON
      // must be the same key as the validator key.
      if (currentError) {
        return translate.get(translateKey, currentError);
      }
      return of(null);
    },
  };
}

function buildMessageComplex(
  translate: TranslateService,
  validationName: FieldValidatorUITypes,
  valueValidationTranslateKey: string,
  modelValidationTranslateKey: string
) {
  return {
    name: validationName,
    message(currentError, currentField: FormlyFieldConfig) {
      // For this to work, the interpolation variable in the translation JSON
      // must be the same key as the validator key.
      if (currentError) {
        const relatedFieldKey = currentError['model'];
        if (typeof relatedFieldKey !== 'undefined') {
          const relatedField = currentField.parent.fieldGroup.find(
            (field) => field.key === relatedFieldKey
          );
          if (relatedField && relatedField.templateOptions._fullLabelKey) {
            return translate.get(relatedField.templateOptions._fullLabelKey).pipe(
              switchMap((translatedLabel) => {
                const translatedError = { ...currentError, model: translatedLabel };
                return translate.get(modelValidationTranslateKey, translatedError);
              })
            );
          } else {
            return translate.get(modelValidationTranslateKey, currentError);
          }
        }
        return translate.get(valueValidationTranslateKey, currentError);
      }
      return of(null);
    },
  };
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    validationMessages: [
      buildMessage(translate, FieldValidatorUITypes.Required, 'common.validation.required'),
      buildMessageComplex(
        translate,
        FieldValidatorUITypes.Min,
        'common.validation.min-value',
        'common.validation.min-value-model'
      ),
      buildMessageComplex(
        translate,
        FieldValidatorUITypes.Max,
        'common.validation.max-value',
        'common.validation.max-value-model'
      ),
    ],
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(),
      },
    ],
  };
}
