import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DynamicSettingsSave } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings-save';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from 'src/app/common-modules/shared/config/dynamic-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { MapLayerSetting } from '../map-zoom-configuration-popup/models/map-layer-setting';
import { MapLayerSettingValue } from '../map-zoom-configuration-popup/models/map-layer-setting-value';
import { MapLayerZoom } from '../map-zoom-configuration-popup/models/map-layer-zoom';
import { MapZoomConfigurationValidators } from './map-zoom-configuration-validators';

const COMPONENT_SELECTOR = 'wlm-map-zoom-configuration';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-zoom-configuration.component.html',
  styleUrls: ['./map-zoom-configuration.component.scss'],
})
export class MapZoomConfigurationComponent implements OnInit {
  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  readonly minZoom = 6;
  readonly maxZoom = 22;

  private _layerSetting: MapLayerSetting;
  public get layerSetting(): MapLayerSetting {
    return this._layerSetting;
  }
  @Input()
  public set layerSetting(value: MapLayerSetting) {
    this._layerSetting = value;

    if (value && this.form) {
      this.reloadForm();
    }
  }

  @Input() saveConfig$: Observable<boolean>;

  @Output() saveFinished = new EventEmitter<boolean>();
  @Output() isLoadingChanges = new EventEmitter<boolean>();
  @Output() hasValidChanges = new EventEmitter<boolean>();

  form: FormGroup;

  // Fields
  minZoomFieldName = 'min';
  maxZoomFieldName = 'max';

  constructor(
    private _formBuilder: FormBuilder,
    private _dynamicSettingsService: DynamicSettingsService,
    private _dialogsService: DialogService
  ) {}

  ngOnInit(): void {
    this.bindSaveConfig();
    this.buildForm();
  }

  getFormGroup = () => this.form;

  private bindSaveConfig() {
    this.saveConfig$?.pipe(untilDestroyed(this)).subscribe((saveAsSystem) => {
      this.save(saveAsSystem);
    });
  }

  save(saveAsSystem: boolean) {
    this.setIsLoading(true);

    const settingZoom = new MapLayerZoom({
      min: this.form.get(this.minZoomFieldName).value,
      max: this.form.get(this.maxZoomFieldName).value,
    });

    const settingToSave = new DynamicSettingsSave({
      settingArea: 'MapSetting',
      settingKey: this.layerSetting.layerId.toString(),
      settingValue: JSON.stringify(new MapLayerSettingValue({ zoom: settingZoom })),
      componentTypeId: 9,
      createComponentIfNoExists: true,
      saveAsDefault: saveAsSystem,
    });

    this._dynamicSettingsService
      .saveDynamicSettings(settingToSave)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this._dialogsService.showEntityActionSnackBar('save', 'configuration');
          this.setIsLoading(false);
          this.saveFinished.emit(true);
        },
        error: (error) => {
          this.displayMessage('common.messages.saved-error', 'error');
          this.setIsLoading(false);
        },
      });
  }

  private buildForm() {
    const formControls: { [key: string]: FormControl } = {};

    const { min, max } = this.layerSetting.value.zoom;

    formControls[this.minZoomFieldName] = new FormControl(min, [
      Validators.required,
      Validators.min(this.minZoom),
      MapZoomConfigurationValidators.greaterThan(
        this.getFormGroup,
        this.maxZoomFieldName,
        this.maxZoom
      ),
    ]);

    formControls[this.maxZoomFieldName] = new FormControl(max, [
      Validators.required,
      MapZoomConfigurationValidators.lowerThan(
        this.getFormGroup,
        this.minZoomFieldName,
        this.minZoom
      ),
      Validators.max(this.maxZoom),
    ]);

    this.form = this._formBuilder.group(formControls);
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.onFormValueChanges());

    this.onFormValueChanges();
  }

  private onFormValueChanges() {
    this.form.get(this.minZoomFieldName).updateValueAndValidity({ emitEvent: false });
    this.form.get(this.maxZoomFieldName).updateValueAndValidity({ emitEvent: false });

    const hasValidChanges = this.form.valid && this.form.dirty;
    this.hasValidChanges.emit(hasValidChanges);
  }

  private reloadForm() {
    this.form = null;
    this.buildForm();
  }

  private setIsLoading(isLoading: boolean) {
    this.isLoadingChanges.emit(isLoading);
  }

  private displayMessage(messageKey: string, icon: 'error' | 'success') {
    let dialogSettings = new WlmDialogSettings({ translateKey: messageKey, icon: icon });

    this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
