<div class="signal-container">
  <div class="signal-column">
    <div class="signal-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedSignals"
        (filterChange)="onfilterChange()"
        (listLoaded)="onAvailableSignalsLoaded()"
        (somethingChanged)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="signal-column assigned-column">
    <div class="signal-group">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.configured-points' | translate }}
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="configuredSignals"
        [settings]="settingsCustom"
        (objectsToOrderChanged)="onSignalsChanged($event)"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
