<wlm-popup-wrapper [showCloseButton]="false" [showSpinner]="!isFormReady">
  <ng-container popup-content [formGroup]="form">
    <div class="config-container">
      <div class="header-container main-header">
        <div class="header-title">
          <h3 class="title">{{ T_SCOPE + '.chart-configuration' | translate }}</h3>
        </div>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.chart-name' | translate }}</mat-label>
        <input matInput [formControlName]="chartNameFieldName" />
      </mat-form-field>

      <hr class="separator" />

      <div class="series-container">
        <div
          class="serie-config-group"
          *ngFor="let serie of chartConfiguration.seriesConfiguration; index as i"
        >
          <mat-form-field appearance="outline" class="serie-name-field">
            <mat-label>{{ T_SCOPE + '.labels.serie-name' | translate }}</mat-label>
            <input matInput [formControlName]="serieNameFieldName + i" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="serie-type-field">
            <mat-label>{{ T_SCOPE + '.labels.serie-type' | translate }}</mat-label>
            <mat-select required appearance="outline" [formControlName]="serieTypeFieldName + i">
              <mat-option *ngFor="let type of serieTypes" [value]="type.value">
                {{ type.labelKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="color-block">
            <!-- This style needs to be applied in the html because if we use a class the input goes wrong -->
            <div
              style="width: 32px; height: 32px; margin: 8px; border-radius: 8px"
              [colorPicker]="getFormColor(serieColorFieldName + i)"
              (colorPickerChange)="setColor($event, serieColorFieldName + i)"
              [style.background]="getFormColor(serieColorFieldName + i)"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="config-container">
      <div class="header-container">
        <div class="header-title">
          <h3 class="title">{{ T_SCOPE + '.event-configuration' | translate }}</h3>
        </div>
      </div>

      <div class="events-container">
        <div>
          <mat-slide-toggle
            class="main-slide custom-slide"
            [labelPosition]="'before'"
            [matTooltip]="T_SCOPE + '.labels.show-all-events' | translate"
            (change)="onShowAllEventsChange($event)"
            [checked]="showAllEventsCheck"
          >
            {{ T_SCOPE + '.labels.show-all-events' | translate }}
          </mat-slide-toggle>
        </div>

        <hr class="separator" />

        <div *ngFor="let category of eventCategories; last as isLast">
          <mat-slide-toggle
            class="custom-slide"
            [labelPosition]="'before'"
            [matTooltip]="T_SCOPE + '.labels.' + category.value | translate"
            [checked]="isCategoryChecked(category)"
            (change)="onShowCategoryChange($event, category)"
          >
            {{ T_SCOPE + '.labels.' + category.value | translate }}
          </mat-slide-toggle>

          <hr *ngIf="!isLast" class="separator secondary" />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container popup-actions>
    <div class="action-buttons-container">
      <div class="cancel-button-container">
        <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
      </div>
      <button
        mat-button
        class="wtr-action-button-primary btn-size"
        [disabled]="!(form.dirty && form.valid)"
        (click)="apply()"
      >
        {{ 'common.apply' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
