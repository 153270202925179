<div *ngIf="form" [formGroup]="form" class="form-container">
  <wlm-he-tree-family-form-element
    [filterSettings]="treeFilterSettings"
    [fieldLabelKey]="T_SCOPE + '.labels.hierarchy-element-parent-id'"
    [heCtrl]="heCtrl"
    [familyCtrl]="familyCtrl"
    [disabled]="isUpdate"
  >
  </wlm-he-tree-family-form-element>

  <mat-form-field class="field-stretch">
    <mat-label>{{ T_SCOPE + '.labels.hierarchy-element-id' | translate }}</mat-label>
    <input matInput formControlName="hierarchyElementId" />
    <mat-error>
      <wlm-validation-messages
        [control]="form.controls.hierarchyElementId"
      ></wlm-validation-messages>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="field-stretch">
    <mat-label>{{ T_SCOPE + '.labels.hierarchy-element-name' | translate }}</mat-label>
    <input matInput formControlName="hierarchyElementName" />
    <mat-error>
      <wlm-validation-messages
        [control]="form.controls.hierarchyElementName"
      ></wlm-validation-messages>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="field-stretch merged-zone-comment-field">
    <mat-label>{{ T_SCOPE + '.labels.comment' | translate }}</mat-label>
    <textarea matInput formControlName="comment" row="4"></textarea>
    <mat-error>
      <wlm-validation-messages [control]="form.controls.comment"></wlm-validation-messages>
    </mat-error>
  </mat-form-field>
</div>
