export class UsersActivityChartConfiguration {
  startDate: Date;
  endDate: Date;
  elementIds: string[];
  algorithms: string[];

  public constructor(init?: Partial<UsersActivityChartConfiguration>) {
    Object.assign(this, init);
  }
}
