export class ExportExcelSettings {
  fileName: string;
  columns: ExportExcelColumnSettings[];
  data: any[];

  constructor(init: Partial<ExportExcelSettings>) {
    Object.assign(this, init);
  }
}

export class ExportExcelColumnSettings {
  fieldName: string;
  title: string;
  format?: string;
  locked?: boolean;

  constructor(init: Partial<ExportExcelColumnSettings>) {
    Object.assign(this, init);
  }
}
