export interface IChartCustomization {
  name: string;
  title: string;
  showLegend: boolean;
  showLabels: boolean;
  showTimeBar: boolean;
  isEventChartExpanded: boolean;
  timeBarMinSelectionRange: number;
  timeBarSelectedPeriodMode: number;
  timeBarVisiblePeriodMode: number;
  isPopup: boolean;
  showChart: boolean;
}

export class ChartCustomization implements IChartCustomization {
  name: string;
  title: string;
  showLegend: boolean;
  showLabels: boolean;
  showTimeBar: boolean;
  isEventChartExpanded: boolean;
  timeBarMinSelectionRange: number;
  timeBarSelectedPeriodMode: number;
  timeBarVisiblePeriodMode: number;
  isPopup: boolean;
  showChart: boolean;

  constructor(init: Partial<ChartCustomization>) {
    Object.assign(this, init);
  }
}
