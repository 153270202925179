import { IChartDataParameters } from '../chart-data-parameters';

export class HistoricalChartDataParameters implements IChartDataParameters {
  queryParams: any;
  dataService: string;

  startDate: Date;
  endDate: Date;

  constructor(init: Readonly<HistoricalChartDataParameters>) {
    Object.assign(this, init);
  }
}
