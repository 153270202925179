<div [matMenuTriggerFor]="menu" [matTooltip]="moduleLink.title" class="menu-button">
  <div *ngIf="moduleLink.useModuleDot" class="module-dot">
    <span class="short-title">{{ moduleLink.shortTitle }}</span>
  </div>
  <div *ngIf="!moduleLink.useModuleDot">
    <wlm-icon [icon]="moduleLink.svgIcon"></wlm-icon>
  </div>
</div>

<mat-menu #menu="matMenu" class="menu-position-right nav-menu-link-list-panel">
  <div class="size-expander"></div>
  <div
    [routerLink]="menuLink.urlPath"
    *ngFor="let menuLink of moduleLink?.menuLinks"
    routerLinkActive="active-link"
  >
    <wlm-menu-link [menuLink]="menuLink"></wlm-menu-link>
  </div>
</mat-menu>
