import { LarsAndSworpsSiteSignalVersionOperationDto } from './lars-and-sworps-sites-signal-version-operation.dto';
import { LarsAndSworpsSiteSignalsDto } from './lars-sworps-site-signal.dto';

export class LarsAndSworpsSiteSignalVersionsSaveDto {
  networkElementId: string;
  networkElementName: string;
  minDate: string;
  larsSworpsSignalVersionOperations: LarsAndSworpsSiteSignalVersionOperationDto[];
  larsSworpSignals: LarsAndSworpsSiteSignalsDto[];
  trackedDates: string[];
  trackedSignals: number[];

  constructor(init: LarsAndSworpsSiteSignalVersionsSaveDto) {
    Object.assign(this, init);
  }
}
