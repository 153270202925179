import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { globalUtilsHelper } from '../helpers/global-utils-helper';

@Injectable({ providedIn: 'root' })
export class FragmentsManagerService {
  private readonly _route = inject(ActivatedRoute);
  private readonly _destroyRef = inject(DestroyRef);

  readonly fragmentsData$ = this._route.fragment.pipe(
    takeUntilDestroyed(this._destroyRef),
    map((fragment) => (fragment ? globalUtilsHelper.queryStringToJson(fragment) : null))
  );

  mergeExportFragments(newFragments: { [key: string]: any }): void {
    const currentFragmentsStr = this.getUrlFragments();
    const currentFragments = globalUtilsHelper.queryStringToJson(currentFragmentsStr);

    const mergedFragments = {
      ...currentFragments,
      ...newFragments,
    };

    const mergedFragmentsStr = globalUtilsHelper.jsonToQueryString(mergedFragments);
    this.setUrlFragments(mergedFragmentsStr);
  }

  private setUrlFragments(fragments: string): void {
    window.location.hash = fragments;
  }

  private getUrlFragments(): string {
    return window.location.hash;
  }
}
