<div class="filter-container">
  <wlm-base-filter-container
    *ngIf="adaptersReady"
    class="filters filters-background"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="processFilterChanges($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <!-- DATE FILTER-->
    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="dateFilterSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-date-range-filter-item
        [maxRangeSelected]="maxDateRangeDays"
        [startFieldName]="leakageReportingFilterConfig.startDateFieldName"
        [endFieldName]="leakageReportingFilterConfig.endDateFieldName"
        [dateRange]="leakageReportingFilterConfig.dateRange"
        [defaultDateRange]="leakageReportingFilterConfig.defaultDateRange"
        [settings]="dateRangeItemSettings"
      ></wlm-date-range-filter-item>
    </wlm-base-filter>
    <!-- HIERARCHY LEVELS FILTER -->
    <wlm-base-filter
      *ngIf="loaded"
      (filterText)="onFilterText($event)"
      (apply)="onApplyHEFilter($event)"
      [clearAll$]="clearHEFilter$"
      [settings]="hierarchyElementGroupSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-hierarchy-family-filter-item
        *ngIf="loaded"
        [selectedIds]="[familyIdSelected]"
        [fieldName]="leakageReportingFilterConfig.hierarchyElementFamilyFieldName"
        [filterText]="filterText"
        [restoreStatusOrder]="1"
        [settings]="familyFilterItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>

      <wlm-hierarchy-elements-filter-item
        [filterText]="filterText"
        [fieldName]="leakageReportingFilterConfig.heSettings.elementTypeFieldName"
        [familyId]="familyIdSelected"
        [defaultSelectedElements]="leakageReportingFilterConfig.defaultHETypeIds"
        [restoreStatusOrder]="2"
        [selectedIds]="leakageReportingFilterConfig.heSettings?.selectedElementIds"
        [settings]="hierarchyFilterItemSettings"
        [filterListElements]="heTypes"
        (hierarchyLevelsListChanged)="onhierarchyLevelsListChanged($event)"
      ></wlm-hierarchy-elements-filter-item>
    </wlm-base-filter>

    <!-- TREE WITHOUT FAMILY FILTER  -->
    <wlm-base-filter
      *ngIf="loaded && treeSettings !== null"
      [settings]="treeGroupSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="leakageReportingFilterConfig.hierarchyElementIdFieldName"
        [hierarchyFamilyIdFieldName]="leakageReportingFilterConfig.hierarchyElementFamilyFieldName"
        [hierarchyFamilyId]="familyIdSelected"
        [initialSelectedKeys]="leakageReportingFilterConfig.initialHierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [rebuildTree$]="rebuildTreeFilter$"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
</div>
