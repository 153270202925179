import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, map } from 'rxjs';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import { ResetFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ActivitiesGridFilterValidations } from '../../../alc/activities-grid/activities-grid-filter-validations';

const COMPONENT_SELECTOR = 'wlm-merged-zones-filter-widget';
export const MZ_FILTER_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-filter-widget.component.html',
  styleUrls: ['./merged-zones-filter-widget.component.scss'],
  providers: [ReduxStateService],
})
export class MergedZonesFilterWidgetComponent extends BaseDynamicWidgetComponent implements OnInit {
  get componentName(): string {
    return 'MergedZonesFilterWidgetComponent';
  }

  filterSettings: FilterGroupSettings;
  clearAll$ = new Subject<void>();
  persistFilters$ = new Subject<void>();
  hierarchyLevelFieldName = 'HierarchyElementTypeIds';
  hierarchyFamilyFieldName = 'HierarchyFamilyId';

  filters: DataBindingFilters;
  gridFilters: Map<string, any>;
  canLoad: boolean;
  disableAutoload = false;

  pageId: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;

    this.setPersistencyArea(widgetSettings.widgetInstanceKey);
  }

  onWidgetInit(): void {
    this.setFilterSettings(null);

    this._state
      .select<number>(new ResetFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (_) => this.clearAll$.next(),
      });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  onFiltersChanged(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(['hierarchyFamily', 'hierarchyLevel'], filtersParameters)
    ) {
      this.filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  onApply() {
    if (this.canLoad) {
      this.checkPendingChanges(this.pageId).subscribe((_) => {
        this._state.dispatch(new ApplyFiltersAction(this.filters));

        this.persistFilters$.next();
      });
    }
  }

  onAutoload(): void {
    if (!this.disableAutoload && this.filters?.filters) {
      this.disableAutoload = true;
      this.onApply();
    }
  }

  private setFilterSettings(params: Params) {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyFamilyFieldName,
      }),
      hierarchyLevel: new FilterGroupFieldSettings({
        fieldName: this.hierarchyLevelFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
    });
  }

  private setPersistencyArea(widgetId: string) {
    this.persistencyArea = this.componentName + widgetId;
  }
}
