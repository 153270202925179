<mat-autocomplete #autocomplete="matAutocomplete">
  <mat-option
    *ngFor="let element of elements ?? []"
    (click)="onOptionClicked($event, element)"
    [matTooltip]="labelFn(element)"
    [matTooltipShowDelay]="matTooltipShowDelay"
  >
    <div [class]="isSelected(element) ? 'selected' : ''">
      <span class="option-text">{{ labelFn(element) }}</span>
    </div>
  </mat-option>
</mat-autocomplete>
