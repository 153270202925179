<wlm-page-wrapper [titleKey]="titleTranslationKey">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [grid]="genericGrid"
      [gridName]="gridName"
      [disable$]="gridBtnsDisable$"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'c']"
          [tooltip]="T_SCOPE + '.create-group'"
          [showLabel]="isContainerCollapsed"
          (click)="addGroup()"
        >
          <mat-icon svgIcon="create-group"></mat-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'd']"
          [disabled]="!selectedGroup"
          [tooltip]="T_SCOPE + '.remove-group'"
          [showLabel]="isContainerCollapsed"
          (click)="deleteGroup()"
        >
          <mat-icon svgIcon="remove-group"></mat-icon>
        </button>
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMAdministrationCrud', 'u']"
          [disabled]="!selectedGroup"
          [tooltip]="T_SCOPE + '.edit-group'"
          [showLabel]="isContainerCollapsed"
          (click)="editSelectedGroup()"
        >
          <mat-icon svgIcon="edit"></mat-icon>
        </button>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-content>
    <wlm-generic-grid
      #genericGrid
      class="groups-grid"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      (selectedItemChanged)="getSelectedGroup($event)"
    ></wlm-generic-grid>

    <div>
      <h3>{{ T_SCOPE + '.permissions-title' | translate }}</h3>
    </div>
    <hr class="local-separator" />

    <wlm-profile-dialogs-hierarchy-grid
      class="dialogs-grid"
      *ngIf="selectedGroup"
      [group]="selectedGroup"
      fullSize="true"
    ></wlm-profile-dialogs-hierarchy-grid>
  </ng-container>
</wlm-page-wrapper>
