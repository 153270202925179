import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class ElementTargetFISettings extends BaseFilterItemSettings {
  fieldName: string;
  selectedIds?: number[];

  constructor(init: ElementTargetFISettings) {
    super(init);
    Object.assign(this, init);
  }
}
