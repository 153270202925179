// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuillModules } from 'ngx-quill';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { StringHelperService } from 'src/app/common-modules/shared/helpers/string-helper.service';
import { HighlightSearchPipe } from 'src/app/common-modules/shared/pipes/highlight-search.pipe';
import { CommentDto } from '../../models/comment.dto';
import { EditorMention } from '../../models/editor-mention';
import { CommentsService } from '../../services/comments.service';

const COMPONENT_SELECTOR = 'wlm-comment-thread';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './comment-thread.component.html',
  styleUrls: ['./comment-thread.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentThreadComponent),
      multi: true,
    },
  ],
})
export class CommentThreadComponent implements OnInit, ControlValueAccessor {
  private _discussionId: string;
  get discussionId(): string {
    return this._discussionId;
  }
  @Input() set discussionId(value: string) {
    this._discussionId = value;
    this.loadComments();
  }
  private _usersToMention: EditorMention[] = [];
  public get usersToMention(): EditorMention[] {
    return this._usersToMention;
  }
  @Input() public set usersToMention(value: EditorMention[]) {
    this._usersToMention = value;
    if (value) {
      this.userNamesHash = {};
      this.usersToMention.forEach((mention) => {
        this.userNamesHash[mention.id] = mention.label;
      });
    }
  }
  @Output() taggedUsers = new EventEmitter<string[]>();

  comments: CommentDto[];
  commentDescriptionControl = new UntypedFormControl();
  isDisabled = false;
  quillTheme = 'snow';
  editorModules: QuillModules;
  userNamesHash: { [key: string]: string };
  T_SCOPE = `${AppModules.Comments}.${COMPONENT_SELECTOR}`;
  placeholderTextKey = `${this.T_SCOPE}.placeholder`;

  private nativeOnChange: (selection: string) => void;
  private mentionsDebouncer$ = new Subject();
  private debounceMentionsTime = 200;
  private currentTaggedUsers: string[] = [];
  // "?" is to avoid greedy search and stop in the first " it founds.
  private extractMentionRegex = new RegExp('data-id="(.*?)"', 'gm');

  constructor(
    private _commentsService: CommentsService,
    private _stringHelper: StringHelperService
  ) {
    this.buildEditorConfig();
  }

  ngOnInit(): void {
    this.listenUserMentionsSource();
    this.taggedUsers.emit(this.currentTaggedUsers);
  }

  private loadComments(): void {
    if (this.discussionId) {
      this._commentsService
        .getByDiscussion(this._discussionId)
        .subscribe((comments) => (this.comments = comments));
    } else {
      this.comments = [];
    }
  }

  refreshComments = (): void => {
    this.loadComments();
  };

  /**
   * Receive the value that comes from the generic control.
   */
  writeValue(value: string): void {
    this.commentDescriptionControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;

    this.commentDescriptionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((text) => {
      this.updateMentions(text);
      this.nativeOnChange(text);
    });
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (!this.isDisabled && isDisabled) {
      this.commentDescriptionControl.disable();
    }
    if (this.isDisabled && !isDisabled) {
      this.commentDescriptionControl.enable();
    }
    this.isDisabled = isDisabled;
  }

  private buildEditorConfig(): void {
    this.editorModules = {
      toolbar: false,
      mention: {
        mentionDenotationChars: ['@'],
        source: this.usersMentionSource,
        renderItem: this.renderMentionItem,
      },
    };
  }

  /**
   * Delegates the filtering logic to a debouncer, which will limit the amount or searchs.
   */
  private usersMentionSource = (searchTerm: string, renderList, mentionChar: string) => {
    this.mentionsDebouncer$.next({
      searchTerm,
      renderList,
      mentionChar,
    });
  };

  /**
   * Listen to the events and perform the actual search.
   */
  private listenUserMentionsSource = () => {
    this.mentionsDebouncer$
      .pipe(untilDestroyed(this), debounceTime(this.debounceMentionsTime))
      .subscribe(({ searchTerm, renderList, mentionChar }) => {
        const options = this.usersToMention.filter(
          (mention) => !this.currentTaggedUsers.find((userCode) => userCode === mention.id)
        );
        let matches;
        if (searchTerm.length === 0) {
          matches = options;
        } else {
          matches = options.filter((user) =>
            this._stringHelper.localeIncludes(user.value, searchTerm)
          );
        }
        renderList(matches, searchTerm);
      });
  };

  private renderMentionItem = (item, searchTerm): Node => {
    const result = new HighlightSearchPipe().transform(item.label, [searchTerm]);
    const element = document.createElement('span');
    element.innerHTML = globalUtilsHelper.purifyHtml(result);
    return element;
  };

  private updateMentions(text: string): void {
    if (text) {
      const matches = Array.from(text.matchAll(this.extractMentionRegex));
      this.currentTaggedUsers = matches.map(([_, groupValue]) => groupValue);
      this.taggedUsers.emit(this.currentTaggedUsers);
    }
  }
}
