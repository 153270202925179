import { Injectable, Injector } from '@angular/core';
import { ISummaryDistancePerMaterialDto } from '../../dependencies/shared/model/pipes/summaryDistancePerMaterial.dto';
import { PagedResultDto } from '../model/paged-result.dto';
import { GridODataService } from '../odata/grid-odata.service';

@Injectable()
export class PipeGridODataService extends GridODataService<ISummaryDistancePerMaterialDto> {
  constructor(injector: Injector) {
    super(injector, '/api/pipes/summary');
  }

  protected mapResponse(
    response: PagedResultDto<ISummaryDistancePerMaterialDto>
  ): PagedResultDto<ISummaryDistancePerMaterialDto> {
    return response;
  }
}
