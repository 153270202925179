/**
 * Helper enum that enumerates all available modules in the app.
 * The values of this enum matches the module scopes inside the json translation files.
 */

export enum AppModules {
  App = 'app',
  ALC = 'alc',
  Cache = 'cache',
  Customer = 'customer',
  Configuration = 'configuration',
  DistributionNetwork = 'dn',
  TransmissionNetwork = 'tn',
  Home = 'home',
  Map = 'map',
  WlmNavigation = 'wlm-navigation',
  RightPanel = 'right-panel',
  WlmShared = 'wlm-shared',
  WlmGrid = 'wlm-grid',
  WlmSecurity = 'wlm-security',
  WlmFilters = 'wlm-filters',
  WaterBalance = 'water-balance',
  DataVisualization = 'dv',
  WlmCharts = 'wlm-charts',
  Dialogs = 'dialogs',
  Alarms = 'alarms',
  Algorithms = 'algorithms',
  Monitoring = 'monitoring',
  Comments = 'comments',
  LeakageReporting = 'leakage-reporting',
  PressureManagement = 'pressure-management',
  BI = 'bi',
  Schematics = 'schematics',
  Logs = 'logs',
  DataValidation = 'data-validation',
  Integration = 'integration',
  SmartMeters = 'smart-meters',
  Interfaces = 'interfaces',
}
