export interface BaseFilterSettingsDefault {
  collapsedMinWidth: string;
  expandedMaxWidth: string;
  expandedMaxHeight: string;
}

export const baseFilterSettingsDefaults: BaseFilterSettingsDefault = {
  collapsedMinWidth: '100%',
  expandedMaxWidth: '500px',
  expandedMaxHeight: 'unset',
};

export class BaseFilterSettings implements BaseFilterSettingsDefault {
  // Form mode disables other settings automatically.
  formMode? = false;
  persistencyArea?: string;
  inputLabelKey?: string;
  inputPlaceholderKey?: string;
  inputSummarySeparator?: string; // Default: ';'
  inputDebounceTime?: number; // Default: 500 ms
  isRequired? = false;
  disableErrorStyle? = false;

  // Sizing config
  collapsedMinWidth: string;
  collapsedMaxWidth?: string;
  expandedMinWidth?: string;
  expandedMaxWidth: string;
  expandedMinHeight?: string;
  expandedMaxHeight: string;

  // Main buttons config
  disableClear?: boolean;
  disableSelectAll?: boolean;
  disableApplyFilters?: boolean;
  disableSearch?: boolean;
  disableFilter? = false;
  clearAllTextKey?: string;
  selectAllTextKey?: string;
  applyFiltersTextKey?: string;

  constructor(init?: Partial<BaseFilterSettings>) {
    Object.assign(this, baseFilterSettingsDefaults);
    Object.assign(this, init);
  }
}
