<div>
    <kendo-dropdownlist
      *ngIf="filterOperators"
      [data]="filterOperators"
      [textField]="'text'"
      [valueField]="'value'"
      [valuePrimitive]="true"
      [(value)]="selectedOperator"
      (valueChange)="operatorChange($event)"
    >
    </kendo-dropdownlist>
    <kendo-textbox
      [disabled]="numTextBoxDisabled"
      (valueChange)="onValueChange($event)"
      [(value)]="selectedValue"
    >
    </kendo-textbox>
  </div>