import { IGisElementDto } from 'src/app/common-modules/shared/model/gis/gis-element.dto';
import { NavigationElement } from './navigation-element';

export class MapParameters {
  baseType?: string | null;
  visibleLayersIds?: number[] | null;
  visibleThematicsIds?: string[] | null;
  center?: number[] | null;
  zoom?: number | null;
  showPanel?: boolean | null;
  showFilters?: boolean | null;
  zoomEnabled?: boolean | null;
  panningEnabled?: boolean | null;
  hierarchyElements?: string[] | null;
  networkElements?: string[];
  networkElementTypes?: number[];
  leakYears?: number[] | null;
  navigatedElement?: IGisElementDto | null;
  settingKey?: string;
  settingArea?: string;
  navigationParam?: NavigationElement;
  loadFromPersistency?: boolean = true;
  geojsonFeatures?: GeoJSON.FeatureCollection;

  public static getparameter(p: MapParameters): MapParameters {
    p.hierarchyElements = p.hierarchyElements ?? [];
    p.networkElements = p.networkElements ?? [];

    if (
      (p.hierarchyElements.length === 1 && p.hierarchyElements[0] === undefined) ||
      p.hierarchyElements[0] === null
    ) {
      p.hierarchyElements = [];
    }

    if (
      (p.networkElements.length === 1 && p.networkElements[0] === undefined) ||
      p.networkElements[0] === null
    ) {
      p.networkElements = [];
    }

    p.baseType = p.baseType ?? '';
    p.visibleLayersIds = p.visibleLayersIds ?? [];
    p.visibleThematicsIds = p.visibleThematicsIds ?? [];
    p.leakYears = p.leakYears ?? [];

    p.showPanel = p.showPanel ?? false;
    p.showFilters = p.showFilters ?? false;
    p.zoomEnabled = p.zoomEnabled ?? true;
    p.panningEnabled = p.panningEnabled ?? true;
    p.geojsonFeatures = p.geojsonFeatures;

    return p;
  }
}
