import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { SharedConstantsService } from '../../shared/constants/shared-constants.service';
import { MatSelectChange } from '@angular/material/select';

const COMPONENT_SELECTOR = 'wlm-text-column-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './text-column-filter.component.html',
  styleUrls: ['./text-column-filter.component.scss'],
})
export class TextColumnFilterComponent
  extends BaseFilterCellComponent
  implements OnInit, AfterViewInit
{
  @Input() public filter: any;
  @Input() public filterService: FilterService;
  @Input() public field: string;

  //operator selectors
  private _firstFilterOperator: string;
  public get firstFilterOperator(): string {
    return this._firstFilterOperator;
  }
  public set firstFilterOperator(value: string) {
    this._firstFilterOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  private _secondFilterOperator: string;
  public get secondFilterOperator(): string {
    return this._secondFilterOperator;
  }
  public set secondFilterOperator(value: string) {
    this._secondFilterOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  //input values
  private _firstFilterValue: string;
  public get firstFilterValue(): string {
    return this._firstFilterValue;
  }
  public set firstFilterValue(value: string) {
    if (this._firstFilterValue === value) {
      return;
    }

    this._firstFilterValue = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  private _secondFilterValue: string;
  public get secondFilterValue(): string {
    return this._secondFilterValue;
  }
  public set secondFilterValue(value: string) {
    if (this._secondFilterValue === value) {
      return;
    }

    this._secondFilterValue = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  // main union operator
  private _unionOperator: 'and' | 'or';
  public get unionOperator(): 'and' | 'or' {
    return this._unionOperator;
  }
  public set unionOperator(value: 'and' | 'or') {
    this._unionOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  filterOperators: Array<{ text: string; value: string }>;
  T_SCOPE = 'kendo.grid';

  disabledFirstFilter = false;
  disabledSecondFilter = false;

  private _canUpdateFilter: boolean = false;

  private readonly _firstFilterIndex = 0;
  private readonly _secondFilterIndex = 1;
  private readonly _defaultOperator = 'contains';

  constructor(filterService: FilterService, sharedConstantsService: SharedConstantsService) {
    super(filterService);
    // Get translated filter operators
    sharedConstantsService
      .mapToArrayObservable(sharedConstantsService.getFilterOperatorsTextMapping(), 'value', 'text')
      .subscribe((array) => {
        this.filterOperators = array;
      });
  }

  ngOnInit(): void {
    this.unionOperator = this.getAppliedMainOperator();

    const firstFilter = this.getAppliedFilter(this._firstFilterIndex);
    this.firstFilterOperator = (firstFilter?.operator as string) ?? this._defaultOperator;
    this.firstFilterValue = firstFilter?.value ?? '';

    const secondFilter = this.getAppliedFilter(this._secondFilterIndex);
    this.secondFilterOperator = (secondFilter?.operator as string) ?? 'contains';
    this.secondFilterValue = secondFilter?.value ?? '';

    this.checkDisabledOnUnaryOperator();
  }

  ngAfterViewInit(): void {
    this._canUpdateFilter = true;
  }

  preventEvent(event: PointerEvent): void {
    event.stopPropagation();
  }

  private getAppliedMainOperator(): 'and' | 'or' {
    let mainOperator: 'and' | 'or' = 'and';

    if (!this.filter || this.filter.filters.length === 0) {
      return mainOperator;
    }

    if (this.filter) {
      mainOperator = this.filter.logic;
    }

    return mainOperator;
  }

  private getAppliedFilter(index: number): FilterDescriptor {
    if (!this.filter || this.filter.filters.length === 0) {
      return undefined;
    }

    const internalFilters = this.filter.filters as FilterDescriptor[];

    if (!internalFilters || internalFilters.length === 0) {
      return undefined;
    }

    return internalFilters[index];
  }

  private updateCurrentFilter(): void {
    const newFirstFilter = this.buildFilter(this.firstFilterValue, this.firstFilterOperator);

    const newSecondFilter = this.buildFilter(this.secondFilterValue, this.secondFilterOperator);
    const filters = [newFirstFilter, newSecondFilter].filter((f) => f !== null);

    this.checkDisabledOnUnaryOperator();

    if (!filters.length && this.filter.filters.length) {
      this.removeFilter(this.field);
    } else {
      if (filters.length > 0) {
        const operator = filters.length > 1 ? this.unionOperator : 'and';

        const columnFilter: CompositeFilterDescriptor = {
          logic: operator,
          filters: filters,
        };

        this.applyFilter(columnFilter);
      }
    }
  }

  private buildFilter(filterValue: string, filterOperator: string): FilterDescriptor {
    const operatorIsUnary = this.checkIsUnaryOperator(filterOperator);

    if (!operatorIsUnary && (filterValue === '' || filterValue === null)) {
      return null;
    }

    const value = this.getProperValueForFilter(filterOperator, filterValue);

    return {
      field: this.field,
      operator: filterOperator,
      value,
    } as FilterDescriptor;
  }

  private checkIsUnaryOperator(filterOperator: string) {
    return (
      filterOperator === 'isnull' ||
      filterOperator === 'isnotnull' ||
      filterOperator === 'isempty' ||
      filterOperator === 'isnotempty'
    );
  }

  private getProperValueForFilter(filterOperator: string, filterValue: string) {
    if (filterOperator === 'isnull' || filterOperator === 'isnotnull') {
      return 'null';
    }

    if (filterOperator === 'isempty' || filterOperator === 'isnotempty') {
      return '';
    }

    return filterValue;
  }

  private checkUnaryOperatorInputs(index: number, filterOperator: string, filterValue: string) {
    const operatorIsUnary = this.checkIsUnaryOperator(filterOperator);

    if (index === this._firstFilterIndex) {
      this.firstFilterValue = operatorIsUnary ? null : filterValue;
      this.disabledFirstFilter = operatorIsUnary ? true : false;
    } else {
      this.secondFilterValue = operatorIsUnary ? null : filterValue;
      this.disabledSecondFilter = operatorIsUnary ? true : false;
    }
  }

  private checkDisabledOnUnaryOperator() {
    this.checkUnaryOperatorInputs(
      this._firstFilterIndex,
      this.firstFilterOperator,
      this.firstFilterValue
    );

    this.checkUnaryOperatorInputs(
      this._secondFilterIndex,
      this.secondFilterOperator,
      this.secondFilterValue
    );
  }
}
