import { Pipe, PipeTransform } from '@angular/core';

/**
 * Set to uppercase only the first letter of the string.
 * For titles, also consider the native TitleCasePipe.
 */

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !value.length) {
      return value;
    }
    // Slice(1) handles the case when the index does not exists.
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
