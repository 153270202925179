import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DynamicSettingsService } from '../../shared/config/dynamic-settings.service';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { DynamicSettings } from '../models/dynamic-settings';
import { DynamicSettingsDelete } from '../models/dynamic-settings-delete';
import { DynamicSettingsSave } from '../models/dynamic-settings-save';
import { PersistLayoutService } from './persist-layout.service';

@Injectable()
export class PersistLayoutBackendService extends PersistLayoutService {
  constructor(injector: Injector, private _dynamicSettingsService: DynamicSettingsService) {
    super(injector);
  }

  load(settings: DynamicSettings): Observable<DynamicLayoutSettings> {
    return this._dynamicSettingsService.loadDynamicSettings(settings).pipe(
      tap((result: DynamicLayoutSettings) => {
        if (result && !result.items) {
          console.error(
            `The settings recovered for `,
            settings,
            ' are not valid for this version of Dynamic Layout. Please delete them before.'
          );
        }
      })
    );
  }
  save(settings: DynamicSettingsSave): Observable<void> {
    return this._dynamicSettingsService.saveDynamicSettings(settings);
  }
  delete(settings: DynamicSettingsDelete): Observable<any> {
    return this._dynamicSettingsService.deleteDynamicSettings(settings);
  }
}
