import { Component } from '@angular/core';
import { AppModules } from '../../shared/app-modules.enum';

const COMPONENT_SELECTOR = 'wlm-grid-in-the-past-info';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-in-the-past-info.component.html',
  styleUrls: ['./grid-in-the-past-info.component.scss'],
})
export class GridInThePastInfoComponent {
  readonly T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;
}
