import { Injectable, Type } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DynamicLayoutItemSettings } from '../../dynamic-layout/models/dynamic-layout-item-settings';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { ArrayHelperService } from '../../shared/helpers/array-helper.service';
import { PageBuilderSettings } from '../models/page-builder-settings';
import { PageBuilderStructureLabels } from '../models/page-builder-structure-labels';

/**
 * Service that manages how widgets are instantiated inside the Dynamic Layout.
 * Acts as a bridge between Dynamic Laout and Reactive State modules.
 * Must be instantiated at Dynamic layout level, which be propagated to all widgets (children).
 */

@Injectable()
export class WidgetManagerService {
  private _availableWidgetTypes = new Map<string, Type<BaseDynamicWidgetComponent>>();
  private _widgetSettings: DynamicLayoutItemSettings[];
  private _initialized$ = new ReplaySubject(1);
  readonly initialized$ = this._initialized$.asObservable();
  private readonly _settingsLabelSeparator = '#';

  constructor(private _arrayHelperService: ArrayHelperService) {}

  /**
   * From the configured settings, get which components are going to be instantiated.
   */
  getWidgetTypes(): Type<BaseDynamicWidgetComponent>[] {
    if (!this._widgetSettings) {
      throw new Error('Must register widgets before getting widget types.');
    }
    const componentNames = this._arrayHelperService.onlyUnique(
      this._widgetSettings.map((item) => item.componentName)
    );
    const widgetTypes = componentNames
      .map((componentName) => this._availableWidgetTypes.get(componentName))
      .filter(Boolean);

    return widgetTypes;
  }

  readBuilderSettings(settings: PageBuilderSettings): void {
    for (let moduleSection in settings) {
      this.buildModule(moduleSection, settings[moduleSection]);
      // const module = settings[moduleKey];
      // for (let pageLevel in module) {
      //   const page = settings[moduleKey][pageKey];
      //   for (let pageLevel in module) {
      //     const page = settings[moduleKey][pageKey];
      //   }
      // }
    }
  }

  /**
   * Currently, only one module name is supported.
   * Retrieve it and inject them to widgetSettings.
   */
  private buildModule(section: string, moduleSettings): void {
    const moduleKey = this.getKeyFromSection(section, PageBuilderStructureLabels.Module);
    const pageSettings = moduleSettings[section];
    for (let pageSection in pageSettings) {
      this.buildPage(pageSection, moduleKey, pageSettings);
    }
  }

  /**
   * Currently, only one page can be created per dynamic layout instance.
   * Retrieve it and inject them to widgetSettings.
   */
  private buildPage(section: string, moduleKey: string, pageSettings): void {
    const pageKey = this.getKeyFromSection(section, PageBuilderStructureLabels.Page);
    const areaSettings = pageSettings[section];
    for (let areaSection in areaSettings) {
      this.buildArea(areaSection, moduleKey, pageKey, areaSettings);
    }
  }

  /**
   * Just for ordering the structure.
   */
  private buildArea(section: string, moduleKey: string, pageKey: string, areaSettings): void {
    const areaKey = this.getKeyFromSection(section, PageBuilderStructureLabels.Area);
    const componentNameSettings = areaSettings[section];
    for (let componentNameSection in componentNameSettings) {
      this.buildComponentName(
        componentNameSection,
        moduleKey,
        pageKey,
        areaKey,
        componentNameSettings
      );
    }
  }

  /**
   * Specifies what components will be loaded in GL.
   */
  private buildComponentName(
    section: string,
    moduleKey: string,
    pageKey: string,
    areaKey: string,
    componentNameSettings
  ): void {
    const componentNameKey = this.getKeyFromSection(
      section,
      PageBuilderStructureLabels.ComponentName
    );
  }

  /**
   * Specifies how many instances of the components in the upper level must be loaded.
   */
  private buildComponentInstance(): void {}

  private getKeyFromSection(section: string, requestedLabel: PageBuilderStructureLabels): string {
    const pieces = section.split('#');
    if (pieces.length !== 2) {
      throw new Error(`The section ${section} does not have a valid format.`);
    }
    const key = pieces[0];
    const label = pieces[1];
    if (requestedLabel !== label) {
      throw new Error(`Expected to parse the label ${requestedLabel}, but got ${label}.`);
    }

    return key;
  }
}
