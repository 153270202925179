import { KpiVisibilityEnum } from '../../../shared/model/kpi/kpi-visibility.enum';
import { KpiLevelSteps } from './kpi-level-steps';

export class KpiSettingValue {
  kpiType: string;
  kpiProperty: string;
  steps: KpiLevelSteps[];
  timeAggregationId: number;
  dimensionTypeId?: number;
  labelKey?: string;
  unitTypeFormat?: string;
  visibility?: KpiVisibilityEnum[];

  constructor(init?: Readonly<KpiSettingValue>) {
    Object.assign(this, init);
  }
}
