import { MapLayerSettingValue } from './map-layer-setting-value';

export class MapLayerSetting {
  layerId: number;
  value: MapLayerSettingValue;

  constructor(init: Readonly<MapLayerSetting>) {
    Object.assign(this, init);
  }
}
