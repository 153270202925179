import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, of } from 'rxjs';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseCalculateResizableChildComponent } from 'src/app/common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { UsersActivityChartConfiguration } from 'src/app/common-modules/shared/model/roles/users-activity-chart-configuration';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { TrendChartSettings } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-settings';
import { UsersActivityFiltersConfiguration } from '../users-activity-filters/users-activity-filters-configuration';

const COMPONENT_SELECTOR = 'wlm-users-activity-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './users-activity-popup.component.html',
  styleUrls: ['./users-activity-popup.component.scss'],
})
export class UsersActivityPopupComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit
{
  dimensionToCalculate: 'height' | 'width' = 'height';
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  usersActivityChartConfiguration: UsersActivityChartConfiguration;
  chartSettings: TrendChartSettings;

  filterDetailsParameters: TabDetailPanelParameters;

  filtersConfig: UsersActivityFiltersConfiguration;
  filters = new Map<string, BasicFilter>();
  clearAll$ = new Subject<void>();
  private readonly _extraMarginsHeight = 24;
  load = false;

  constructor(
    private dialogRef: MatDialogRef<UsersActivityPopupComponent>,
    @Inject(MAT_DIALOG_DATA) parameters: UsersActivityChartConfiguration
  ) {
    super();
    this.usersActivityChartConfiguration = parameters;
  }

  ngOnInit(): void {
    this.populateFilters();
    this.popuplateCharts();
    this.listenSelfContainerSize();
  }

  popuplateCharts(): void {
    this.chartSettings = {
      dataParameters: {
        dataService: 'UsersActivityChartService',
        startDate: this.usersActivityChartConfiguration.startDate,
        endDate: this.usersActivityChartConfiguration.endDate,

        queryParams: this.usersActivityChartConfiguration,
      },
      chartType: ChartType.trend,
    };
  }

  populateFilters(): void {
    const config: UsersActivityFiltersConfiguration = {
      startDateFieldName: 'StartDate',
      endDateFieldName: 'EndDate',
      dateRange: new DateRange(
        this.usersActivityChartConfiguration.startDate,
        this.usersActivityChartConfiguration.endDate
      ),
      defaultDateRange: new DateRange(
        this.usersActivityChartConfiguration.startDate,
        this.usersActivityChartConfiguration.endDate
      ),
    };
    this.filtersConfig = config;
  }

  filtersParametersChanged(parameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = parameters;

    this.usersActivityChartConfiguration.startDate = this.filterDetailsParameters?.parameters.get(
      TabDetailParameterName.startDate
    );
    this.usersActivityChartConfiguration.endDate = this.filterDetailsParameters?.parameters.get(
      TabDetailParameterName.endDate
    );
  }

  onFilter(filters: FiltersPayload): void {
    this.filters = filters.data;
  }

  onApplyFilters(): void {
    this.popuplateCharts();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [
      of({
        height: this._extraMarginsHeight,
        width: null,
      }),
    ];
  }
}
