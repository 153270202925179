<div class="navigation-container">
  <span
    class="menu-link-indicator"
    [routerLinkActive]="
      menuLink.urlPath === '' || menuLink.urlPath === '/' ? '' : 'menu-link-indicator-active'
    "
  >
  </span>
  <span class="menu-link-container">
    <wlm-icon [icon]="menuLink.svgIcon"></wlm-icon>
    <span *ngIf="isExpanded" class="nav-link-text">{{ menuLink.title }}</span>
  </span>
</div>
