import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialSharedModule } from 'src/app/common-modules/shared/material-shared.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { WaterBalanceChartComponent } from './water-balance-chart/water-balance-chart.component';
import { WaterBalanceChartService } from './water-balance-chart/water-balance-chart.service';
import { WaterBalanceFiltersComponent } from './water-balance-filters/water-balance-filters.component';
import { WaterBalancePageComponent } from './water-balance-page/water-balance-page.component';
import { WaterBalanceService } from './water-balance-page/water-balance.service';
import { WaterBalanceRoutingModule } from './water-balance-routing.module';
import { WaterBalanceWidgetItemComponent } from './water-balance-widget-item/water-balance-widget-item.component';
import { WaterBalanceWidgetComponent } from './water-balance-widget/water-balance-widget.component';

@NgModule({
  declarations: [
    WaterBalancePageComponent,
    WaterBalanceWidgetComponent,
    WaterBalanceFiltersComponent,
    WaterBalanceWidgetItemComponent,
    WaterBalanceChartComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WLMGridModule,
    WaterBalanceRoutingModule,
    TranslateModule,
    WlmFiltersModule,
    WlmChartsModule,
    MaterialSharedModule,
  ],
  exports: [WaterBalanceWidgetComponent],
  providers: [
    WaterBalanceService,
    WaterBalanceChartService,
    { provide: 'WaterBalanceService', useExisting: WaterBalanceService },
    { provide: 'WaterBalanceChartService', useExisting: WaterBalanceChartService },
  ],
})
export class WaterBalanceModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      { name: 'WaterBalanceChartComponent', widgetClass: WaterBalanceChartComponent },
    ]);
  }
}
