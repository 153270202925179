import { COMMA, ENTER } from '@angular/cdk/keycodes';
// prettier-ignore
import { Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseComponent } from 'src/app/common-modules/shared/component/base.component';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';

const COMPONENT_SELECTOR = 'wlm-related-element-list';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './related-element-list.component.html',
  styleUrls: ['./related-element-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RelatedElementListComponent),
      multi: true,
    },
  ],
})
export class RelatedElementListComponent
  extends BaseComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  // Important: must set useExternalExclude BEFORE allElements and excludeValues, otherwise the flag will be undefined.
  @Input() useExternalExclude = false;
  // elements list
  @Input() allElements: SelectOption<string>[];
  @Input() excludeValues: string[];
  @Input() title: string;
  @Input() required = false;
  @Input() placeHolderText: string;
  @Input() hasFocus: boolean;
  @Input() fieldAppearance = 'outline';

  private elementInput: ElementRef<HTMLInputElement>;
  @ViewChild('elementInput') public set queryElementInput(value: ElementRef<HTMLInputElement>) {
    this.elementInput = value;
    if (this.hasFocus) {
      setTimeout(() => {
        this.elementInput.nativeElement.focus();
      });
    }
  }

  elements: SelectOption<string>[] = [];
  // All elements except the selected ones.
  private _remainingElements: SelectOption<string>[];
  public get remainingElements(): SelectOption<string>[] {
    return this._remainingElements;
  }
  public set remainingElements(value: SelectOption<string>[]) {
    this._remainingElements = value;
    if (this.remainingElements) {
      this.filteredElements = this.remainingElements.slice();
    }
  }
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new UntypedFormControl();
  filteredElements: SelectOption<string>[];
  isDisabled: boolean;
  inputEvent$ = new Subject<string>();
  private nativeOnChange: (selection: string[]) => void;

  constructor() {
    super();
  }

  init(): void {}

  ngOnInit(): void {
    // Listen to "input" event rather than to valueChanges, as the latter also emits with the value after we click in a select option.
    this.inputEvent$
      .pipe(
        startWith(null),
        map((filterLabel) => this.filter(filterLabel))
      )
      .subscribe((filteredElements) => {
        this.filteredElements = filteredElements;
      });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  /**
   * We use onChanges because, if we used setters along with the "useExternalExclude" setter, the order of inputs in the HTML could
   * lead to "useExternalExclude" being initialized after it is used.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.allElements &&
      changes.allElements?.currentValue?.length !== changes.allElements?.previousValue?.length &&
      !this.useExternalExclude
    ) {
      this.remainingElements = this.allElements.slice();
    }

    if (changes.excludeValues && this.excludeValues && this.useExternalExclude) {
      this.remainingElements = this.allElements.filter(
        (element) => !this.excludeValues.find((excludeId) => excludeId === element.value)
      );
    }
  }

  onInputChange(event): void {
    this.inputEvent$.next(event.target.value);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const selected = this.allElements.find((element) => element.value === value);

    if (selected) {
      this.elements.push(selected);
      this.update();
    }
    this.inputCtrl.setValue(null);
  }

  remove(elementValue: string): void {
    const index = this.elements.findIndex((element) => element.value === elementValue);

    if (index >= 0) {
      this.elements.splice(index, 1);
      this.update();
    }
    this.inputCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selected = this.allElements.find((element) => element.value === event.option.value);

    if (selected) {
      this.elements.push(selected);
      this.update();
    }
    this.elementInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  /**
   * Propagates the changes so that the binded form or ngModel can receive them.
   */
  private update(): void {
    this.nativeOnChange(this.elements.map((element) => element.value));

    if (!this.useExternalExclude) {
      this.remainingElements = this.allElements.filter(
        (elementA) => !this.elements.find((elementB) => elementA.value === elementB.value)
      );
    }
  }

  /**
   * Receive the value that comes from the generic control.
   */
  writeValue(valueIds: string[]): void {
    this.elements = this.allElements.filter(
      (element) => valueIds?.findIndex((value) => value === element.value) !== -1
    );
  }

  registerOnChange(fn: any): void {
    this.nativeOnChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private filter(filterLabel: string): SelectOption<string>[] {
    const elementsToFilter = this.remainingElements
      ? this.remainingElements.slice()
      : this.allElements;
    if (filterLabel) {
      const filterLabelLC = filterLabel.toLowerCase();
      return elementsToFilter.filter((element) =>
        element.label.toLowerCase().includes(filterLabelLC)
      );
    }
    return elementsToFilter;
  }
}
