import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseService } from '../../shared/base.service';
import { DynamicSettingsService } from '../../shared/config/dynamic-settings.service';
import { customTrace } from '../../shared/rxjs/operators/custom-trace.operator';

import { DynamicFormSave } from '../models/dynamic-form-save';
import { DynamicFormUIValues, DynamicFormUIValuesPayload } from '../models/dynamic-form-ui-values';
import { FieldDataType } from '../models/field-data-type';
import { FieldDefinition } from '../models/field-definition';
import { FieldParseDependencies } from '../models/field-parse-dependencies';
import { FieldsByCategory } from '../models/fields-by-category';
import { FormGlobalValues } from '../models/form-values-data-source';
import { SettingsEntitySubtype } from '../models/settings-entity-subtype';
import { SettingsEntityType } from '../models/settings-entity-type';
import { DynamicFormHelperService } from './dynamic-form-helper.service';

@Injectable()
export class DynamicFormService extends BaseService {
  private readonly _globalValues$ = new ReplaySubject<FormGlobalValues>();
  readonly globalValues$ = this._globalValues$.asObservable();

  constructor(
    injector: Injector,
    private _helperService: DynamicFormHelperService,
    private _dynamicSettingsService: DynamicSettingsService
  ) {
    super(injector);
  }

  getFormByEntityType(
    entityType: SettingsEntityType,
    elementTypeId: string,
    entitySubtypes: SettingsEntitySubtype[]
  ): Observable<FieldsByCategory[]> {
    return this._dynamicSettingsService
      .getFormsCustomSettings(entityType, elementTypeId, entitySubtypes)
      .pipe(
        customTrace('Dynamic Form - Get Forms By Entity Type In Group Widget'),
        map((data) => {
          return data as FieldsByCategory[];
        })
      );
  }

  getValues(
    entityType: SettingsEntityType,
    elementId: string,
    fieldsHash: { [key: string]: FieldDefinition[] },
    dependencies: FieldParseDependencies
  ): Observable<DynamicFormUIValues> {
    const fieldsByName = this._helperService.groupFieldsByName(fieldsHash);
    return this._dynamicSettingsService.getValues(entityType, elementId).pipe(
      map((data: DynamicFormUIValuesPayload) => {
        this._globalValues$.next(data.globalValues);

        const mappedModel = this._helperService.fromHashedDataSourceToName(data.values, fieldsHash);
        return mappedModel;
      }),
      map((model: DynamicFormUIValues) => {
        const convertedModel = this._helperService.convertModelToDataTypes(model, fieldsByName);
        return convertedModel;
      }),
      switchMap((model: DynamicFormUIValues) => {
        const convertedModelUoM$ = this._helperService.allToUoM(model, fieldsByName, dependencies);
        return convertedModelUoM$;
      }),
      customTrace('Dynamic Form - Get Values')
    );
  }

  save(
    saveDto: DynamicFormSave,
    fieldsHash: { [key: string]: FieldDefinition[] },
    dependencies: FieldParseDependencies
  ): Observable<any> {
    return this.preprocessSave(saveDto, fieldsHash, dependencies).pipe(
      switchMap((mappedSaveDto) => {
        return this.httpCacheClient.post(
          `${this.apiUrl}/configuration/entity`,
          mappedSaveDto,
          this.avoidCache
        );
      }),
      customTrace('Dynamic Form - Save Form')
    );
  }

  private preprocessSave(
    inputSaveDto: DynamicFormSave,
    fieldsHash: { [key: string]: FieldDefinition[] },
    dependencies: FieldParseDependencies
  ): Observable<any> {
    let saveDto = this._helperService.clone(inputSaveDto);
    const fieldsByName = this._helperService.groupFieldsByName(fieldsHash);

    this.nullPlaceholderToReal(saveDto, fieldsByName);

    const previousValuesHash = {};
    saveDto.metadata.forEach((item) => {
      previousValuesHash[item.name] = item.previous;
    });

    return forkJoin([
      this._helperService.allFromUoM(saveDto.values, fieldsByName, dependencies),
      this._helperService.allFromUoM(previousValuesHash, fieldsByName, dependencies),
    ]).pipe(
      map(([convertedModel, convertedPreviousValues]) => {
        saveDto.values = this._helperService.fromNameToHashedDataSource(convertedModel, fieldsHash);

        saveDto.metadata.forEach((item) => {
          item.previous = convertedPreviousValues[item.name];
        });

        return saveDto;
      })
    );
  }

  /**
   * In the case a non-string attribute has a null value as a "null" placeholder, change it to a real null.
   */
  private nullPlaceholderToReal(
    inputSaveDto: DynamicFormSave,
    fieldsByName: { [name: string]: FieldDefinition }
  ): void {
    Object.keys(inputSaveDto.values).forEach((fieldName) => {
      if (
        inputSaveDto.values[fieldName] === 'null' &&
        fieldsByName[fieldName]?.dataType !== FieldDataType.String
      ) {
        inputSaveDto.values[fieldName] = null;
      }
    });
  }
}
