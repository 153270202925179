import { Injectable } from '@angular/core';
import { ExportExcelSettings } from '../models/export-excel-settings';

export class ExcelWorkbook {
  sheets: {
    rows: {
      cells: {
        value: any;
      }[];
    }[];
  }[];
}

@Injectable()
export class ExcelHelperService {
  private readonly _invalidCharsRegex = /^[+|\-|=|@]|(\t)|(\r).*/;

  constructor() {}

  sanitizeWorkbook(workbook: ExcelWorkbook): void {
    for (const sheet of workbook.sheets) {
      for (const row of sheet.rows) {
        for (const cell of row.cells) {
          cell.value = this.sanitizeSingle(cell.value);
        }
      }
    }
  }

  sanitizeSettings(settings: ExportExcelSettings): void {
    const fieldNames = settings.columns.map((column) => column.fieldName);
    settings.data.forEach((item) => {
      fieldNames.forEach((fieldName) => {
        item[fieldName] = this.sanitizeSingle(item[fieldName]);
      });
    });
  }

  sanitizeSingle(data): any {
    if (data === null || typeof data === 'undefined') {
      return null;
    }

    // If it's a boolean or number, no need to sanitize
    if (typeof data === 'boolean' || typeof data === 'number' || !isNaN(data)) {
      return data;
    }

    if (Array.isArray(data) || typeof data === 'object') {
      data = JSON.stringify(data);
    }

    const matches = (data as string).match(this._invalidCharsRegex);
    if (matches?.length > 1) {
      /** 
      * Apply the following sanitization to each field of the CSV, so that their content will be read as text by the spreadsheet editor:
        * Wrap each cell field in double quotes
        * Prepend each cell field with a single quote
        * Escape every double quote using an additional double quote
      * Examples
        =1+2";=1+2       -->    "'=1+2"";=1+2"
        =1+2'" ;,=1+2    -->    "'=1+2'"" ;,=1+2"
    */

      data = (data as string).replace('"', '""');
      data = `"'${data}"`;
    }
    return data;
  }
}
