import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { LeaksService } from 'src/app/common-modules/dependencies/alc/services/alc.leaks.service';
import { WorkOrderService } from 'src/app/common-modules/dependencies/alc/services/work-order.service';
import { networkElementTypesDVWizardSet } from 'src/app/common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { DetectionStatusText } from 'src/app/common-modules/shared/constants/eligibility.constants';
import { BaseService } from '../../base.service';
import { SharedConstantsService } from '../../constants/shared-constants.service';

import { ObjectHelperService } from '../../helpers/object-helper.service';
import { LocalizationHelperService } from '../../localization/localization-helper.service';
import { ImageElement } from '../../model/grid/image-element';
import { AlarmsService } from '../../services/alarms.service';
import { GlobalsService } from '../../services/globals.service';

@Injectable()
export class ColumnEnumsService extends BaseService {
  expiration: GetCacheOpts = { expiration: 'default' };
  dimensionsInFilterColumn = [0, 1, 2, 3, 4, 5, 6, 7];

  constructor(
    injector: Injector,
    private _globalService: GlobalsService,
    private sharedConstants: SharedConstantsService,
    private _workOrderService: WorkOrderService,
    private _leaksService: LeaksService,
    private _objectHelperService: ObjectHelperService,
    private _alarmsService: AlarmsService,
    private _localizationService: LocalizationHelperService
  ) {
    super(injector);
  }

  getInelegibleReasonsMapping(): Observable<Map<any, string>> {
    const reasonsMap = this._globalService.getAllReasons().pipe(
      map((reasons) => {
        const inelegibleReasons = new Map<any, string>();
        reasons
          .filter((x) => !x.isEligible)
          .forEach((reason) => {
            inelegibleReasons.set(
              reason.reasonForEligibleChangeId,
              reason.reasonForEligibleChangeDescription
            );
          });
        return inelegibleReasons;
      })
    );

    return reasonsMap;
  }

  getElegibleReasonsMapping(): Observable<Map<any, string>> {
    const reasonsMap = this._globalService.getAllReasons().pipe(
      map((reasons) => {
        const inelegibleReasons = new Map<any, string>();
        reasons
          .filter((x) => x.isEligible !== false)
          .forEach((reason) => {
            inelegibleReasons.set(
              reason.reasonForEligibleChangeId,
              reason.reasonForEligibleChangeDescription
            );
          });
        return inelegibleReasons;
      })
    );

    return reasonsMap;
  }

  getEligibleUserMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getEligibleUserMapping();
  }

  getReasonForDropMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getReasonForDropMapping();
  }

  getSapStatusMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getSapStatusMapping();
  }

  getSeveritiesMapping(): Observable<Map<number, string>> {
    return this.sharedConstants.getSeveritiesMapping();
  }

  getBooleanColumnDefaultMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getBooleanColumnDefaultMapping();
  }

  getActivityTypeMapping() {
    const activityTypeMap = this._globalService.getActivityTypes().pipe(
      map((reasons) => {
        const activityTypes = new Map<any, string>();
        reasons.forEach((act) => {
          activityTypes.set(act.activityTypeId, `${act.shortCode} - ${act.activityTypeName}`);
        });
        return activityTypes;
      })
    );

    return activityTypeMap;
  }

  getCriticalityReasonMapping() {
    const criticalityReasonMap = this._globalService.getAllCriticalityReasons().pipe(
      map((reasons) => {
        const criticalityReasons = new Map<any, string>();
        reasons.forEach((reason) => {
          criticalityReasons.set(reason.reasonId, reason.reasonDescription);
        });
        return criticalityReasons;
      })
    );

    return criticalityReasonMap;
  }

  getDetectionStatuMapping(): Observable<Map<any, string>> {
    const detectionStatusMap = new Map<any, string>();
    for (const item in DetectionStatusText) {
      if (isNaN(Number(item))) {
        detectionStatusMap.set(DetectionStatusText[item], item);
      }
    }
    return of(detectionStatusMap);
  }

  getEnum(methodName: string): Observable<Map<any, string>> {
    return this[methodName]();
  }

  getEnums(methodNames: string[]): Observable<Map<string, Map<any, string>>> {
    if (!methodNames.length) {
      return of(new Map<string, Map<any, string>>());
    }
    const enumsObj = {};
    methodNames.forEach((methodName) => {
      enumsObj[methodName] = this.getEnum(methodName);
    });

    const joined$ = forkJoin(enumsObj).pipe(
      map((result: any) => {
        const enumMap = new Map<string, Map<any, string>>();

        // tslint:disable-next-line: forin
        for (const key in result) {
          enumMap.set(key, result[key]);
        }
        return enumMap;
      })
    );
    return joined$;
  }

  getImageEnum(methodName: string): Observable<Map<any, ImageElement>> {
    return this[methodName]();
  }

  getLeakageAlarmStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.sharedConstants.getTsLeakageAlarmStatusMapping();
  }

  getPressureAlarmStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.sharedConstants.getPressureMonitoringAlarmStatusMapping();
  }

  getOtherActiveAlarmsMapping(): Observable<Map<any, ImageElement>> {
    return this.sharedConstants.getTsOtherActiveAlarmsMapping();
  }

  getUnitTypeIdMapping(): Observable<Map<any, string>> {
    const unitsMap = this._globalService.getUnitTypes().pipe(
      map((units) => {
        const inelegibleReasons = new Map<any, string>();
        units.forEach((unit) => {
          inelegibleReasons.set(unit.unitTypeId, unit.unitTypeDescription);
        });
        return inelegibleReasons;
      })
    );

    return unitsMap;
  }

  getSourceSystemsMapping(): Observable<Map<any, string>> {
    const ssMap = this._globalService.getSourceSystems().pipe(
      map((sourceSystems) => {
        const sourceSystemsReasons = new Map<any, string>();
        sourceSystems.forEach((ss) => {
          sourceSystemsReasons.set(ss.sourceSystemId, ss.sourceSystemDescription);
        });
        return sourceSystemsReasons;
      })
    );

    return ssMap;
  }

  getDLCEMapping(): Observable<Map<any, ImageElement>> {
    return this.sharedConstants.getTsDLCEMapping();
  }

  getZoneMergingStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.sharedConstants.getTsZoneMergingStatusMapping();
  }

  getIsProactiveMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsProactiveMapping();
  }

  getIsRepairActivityMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsRepairActivityMapping();
  }

  getIsDetectionActivityMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsDetectionActivityMapping();
  }

  getIsOperableMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsOperableMapping();
  }

  getFaultyMeterStatusMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getFaultyMeterStatusMapping();
  }

  getLargeUsersFaultsMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getLargeUsersFaultsMapping();
  }

  getIsDryHoleMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsDryHoleMapping();
  }

  getIsPointOfInterestMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsPointOfInterestMapping();
  }

  getPointCategoryMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getPointCategoryMapping();
  }

  getIsActiveMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsActiveMapping();
  }

  getIsSuperUserMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsSuperUserMapping();
  }

  getFaultyMeterMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getFaultyMeterMapping();
  }

  getMVValidityMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getMVValidityMapping();
  }

  getMVEstimationFunctionsMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getMVEstimationFunctionsMapping();
  }
  getMVValidationFunctionsMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getMVValidationFunctionsMapping();
  }

  getEstimatedEditedMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getEstimatedEditedMapping();
  }

  getCommentIdMapping(): Observable<Map<any, string>> {
    const commentsMap = this._alarmsService.getAlarmCommentTypes().pipe(
      map((commentTypes) => {
        const comments = new Map<any, string>();
        commentTypes.forEach((comment) => {
          comments.set(comment.commentId, comment.comment);
        });
        return comments;
      })
    );

    return commentsMap;
  }

  getNetworkElementTypesMapping(): Observable<Map<any, string>> {
    const networkElementTypesMap = this._globalService.getNetworkElementTypes().pipe(
      map((units) => {
        const types = new Map<any, string>();
        units
          .sort((a, b) => b.networkElementTypeName.localeCompare(a.networkElementTypeName))
          .reverse()
          .forEach((type) => {
            types.set(type.networkElementTypeId, type.networkElementTypeName);
          });
        return types;
      })
    );

    return networkElementTypesMap;
  }

  getTransmissionNetworkElementTypesMapping(): Observable<Map<any, string>> {
    const networkElementTypesMap = this._globalService.getTransmissionNetworkElementTypes().pipe(
      switchMap((units) => {
        return this._localizationService.get('common.network-element-types').pipe(
          map((ts) => {
            const types = new Map<any, string>();
            units
              .sort((a, b) => b.networkElementTypeName.localeCompare(a.networkElementTypeName))
              .reverse()
              .forEach((type) => {
                types.set(type.networkElementTypeId, ts[type.networkElementTypeId]);
              });
            return types;
          })
        );
      })
    );

    return networkElementTypesMap;
  }

  getNetworkElementTypesDVSelectionMapping(): Observable<Map<any, string>> {
    const networkElementTypesMap = this._globalService.getNetworkElementTypes().pipe(
      map((units) => {
        units = units.filter((f) =>
          networkElementTypesDVWizardSet.includes(f.networkElementTypeId)
        );
        const types = new Map<any, string>();
        units
          .sort((a, b) => b.networkElementTypeName.localeCompare(a.networkElementTypeName))
          .reverse()
          .forEach((type) => {
            types.set(type.networkElementTypeId, type.networkElementTypeName);
          });
        return types;
      })
    );

    return networkElementTypesMap;
  }

  getHierarchyElementTypesDVSelectionMapping(): Observable<Map<any, string>> {
    const hierarchyElementTypesMap = this._globalService.getHierarchyElementTypes().pipe(
      map((heTypes) => {
        const types = new Map<any, string>();
        heTypes
          .sort((a, b) => b.hierarchyElementTypeName.localeCompare(a.hierarchyElementTypeName))
          .reverse()
          .forEach((type) => {
            types.set(type.hierarchyElementTypeId, type.hierarchyElementTypeName);
          });
        return types;
      })
    );

    return hierarchyElementTypesMap;
  }

  getAlarmClassTypesMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getAlarmClassTypesMapping();
  }

  getActivityCodeMapping(): Observable<Map<any, string>> {
    const activityCodeMap = this._globalService.getActivityTypes().pipe(
      map((activityTypes) => {
        const typesMap = new Map<any, string>();
        activityTypes = this._objectHelperService.sortObjectArray(
          activityTypes.filter((x) => x.shortCode),
          'shortCode'
        );
        activityTypes.forEach((type) => {
          typesMap.set(type.shortCode, type.shortCode);
        });
        return typesMap;
      })
    );

    return activityCodeMap;
  }

  getActivityTypeNameMapping() {
    const activityTypeMap = this._globalService.getActivityTypes().pipe(
      map((types) => {
        const activityTypes = new Map<any, string>();
        types = this._objectHelperService.sortObjectArray(types, 'activityTypeName');
        types.forEach((act) => {
          activityTypes.set(act.activityTypeId, act.activityTypeName);
        });
        return activityTypes;
      })
    );

    return activityTypeMap;
  }

  getSapCodeMapping() {
    const sapCodesMap = this._globalService.getSapCodes().pipe(
      map((codes) => {
        const sapCodes = new Map<any, string>();
        codes = this._objectHelperService.sortObjectArray(codes, 'codeValue');
        codes.forEach((code) => {
          sapCodes.set(code.codeId, code.codeValue);
        });
        return sapCodes;
      })
    );

    return sapCodesMap;
  }

  getSapCodeGroupMapping() {
    const sapCodeGroupsMap = this._globalService.getSapCodeGroups().pipe(
      map((codeGroups) => {
        const sapCodeGroups = new Map<any, string>();
        codeGroups = this._objectHelperService.sortObjectArray(codeGroups, 'codeGroupValue');
        codeGroups.forEach((code) => {
          sapCodeGroups.set(code.codeGroupId, code.codeGroupValue);
        });
        return sapCodeGroups;
      })
    );

    return sapCodeGroupsMap;
  }

  getMainActivityTypesMapping() {
    const mainActivityTypesMap = this._workOrderService.getWOMainActivityTypes().pipe(
      map((mainActivityTypes) => {
        const mainActivityTypesMapping = new Map<any, string>();
        mainActivityTypes = this._objectHelperService.sortObjectArray(
          mainActivityTypes,
          'mainActivityType'
        );
        mainActivityTypes.forEach((type) => {
          mainActivityTypesMapping.set(type.mainActivityType, type.mainActivityType);
        });
        return mainActivityTypesMapping;
      })
    );

    return mainActivityTypesMap;
  }

  getLeakageTypeMapping() {
    const leakageTypesMap = this._leaksService.getLeakTypes().pipe(
      map((leakageTypes) => {
        const typesMap = new Map<any, string>();
        leakageTypes = this._objectHelperService.sortObjectArray(leakageTypes, 'leakageTypeName');
        leakageTypes.forEach((type) => {
          typesMap.set(type.leakageTypeId, type.leakageTypeName);
        });
        return typesMap;
      })
    );

    return leakageTypesMap;
  }

  getLeakageTypeGroupMapping() {
    const leakageTypesMap = this._leaksService.getLeakTypeGroups().pipe(
      map((leakageTypes) => {
        const typesMap = new Map<any, string>();
        leakageTypes = this._objectHelperService.sortObjectArray(
          leakageTypes,
          'leakageTypeGroupName'
        );
        leakageTypes.forEach((type) => {
          typesMap.set(type.leakageTypeGroupId, type.leakageTypeGroupName);
        });
        return typesMap;
      })
    );

    return leakageTypesMap;
  }

  getDryHoleTypeMapping() {
    const dryHoleTypesMap = this._leaksService.getDryHoleTypes().pipe(
      map((dryHoleTypes) => {
        const typesMap = new Map<any, string>();
        dryHoleTypes = this._objectHelperService.sortObjectArray(dryHoleTypes, 'dryHoleType');
        dryHoleTypes.forEach((type) => {
          typesMap.set(type.dryHoleTypeId, type.dryHoleType);
        });
        return typesMap;
      })
    );

    return dryHoleTypesMap;
  }

  getAlarmCategoriesMapping() {
    return this.sharedConstants.getAlarmCategoriesMapping();
  }

  getAlcCampaignStatus(): Observable<Map<number, string>> {
    return this.sharedConstants.getCampaignStatusMapping();
  }

  getProfileTypesMapping() {
    return this.sharedConstants.getProfileTypesMapping();
  }

  getDimensionTypesMapping() {
    const dimensionTypesMap = this._globalService.getDimensionTypes().pipe(
      map((dimensionTypes) => {
        const dimensionTypesMapping = new Map<any, string>();
        dimensionTypes = this._objectHelperService.sortObjectArray(
          dimensionTypes.filter((f) => this.dimensionsInFilterColumn.includes(f.dimensionTypeId)),
          'dimensionTypeDescription'
        );
        dimensionTypes.forEach((dimension) => {
          dimensionTypesMapping.set(dimension.dimensionTypeId, dimension.dimensionTypeDescription);
        });
        return dimensionTypesMapping;
      })
    );

    return dimensionTypesMap;
  }

  getTimeAggregationsMapping(): Observable<Map<any, string>> {
    return this._globalService.getTimeAggregations().pipe(
      map((aggregations) => {
        const aggregationsMap = new Map<any, string>();
        aggregations.forEach((aggregation) => {
          aggregationsMap.set(
            aggregation.timeAggregationId,
            aggregation.timeAggregationDescription
          );
        });
        return aggregationsMap;
      })
    );
  }

  getEntityTypesMapping(): Observable<Map<any, string>> {
    return this._globalService.getEntityTypes().pipe(
      map((entityTypes) => {
        const entityTypesMap = new Map<any, string>();
        entityTypes.forEach((entityType) => {
          entityTypesMap.set(entityType.entityTypeId, entityType.entityTypeName);
        });
        return entityTypesMap;
      })
    );
  }

  getNotificationTypesMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getNotificationTypesMapping();
  }

  getNotificationStatusMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getNotificationStatusMapping();
  }

  getParkingReasonTypesMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getParkingReasonTypesMapping();
  }

  getLeakageReportingReasons(): Observable<Map<any, string>> {
    return this.sharedConstants.getLeakageReportingFlaggingReasons();
  }

  getIsSuccessMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getIsSuccessMapping();
  }

  getCustomerClassTypesMapping(): Observable<Map<any, string>> {
    return this._globalService.getCustomerClassTypes().pipe(
      map((data) => {
        const types = new Map<any, string>();

        data.forEach((t) => types.set(t.customerClassTypeId, t.customerClassTypeName));

        return types;
      })
    );
  }

  getBillingClassesMapping(): Observable<Map<any, string>> {
    return this._globalService.getBillingClasses().pipe(
      map((data) => {
        const types = new Map<any, string>();

        data.forEach((t) => types.set(t.billingClassId, t.billingClassName));

        return types;
      })
    );
  }

  getCustomerClassCategoriesMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getCustomerClassCategoriesMapping();
  }

  getCustomerClassSuperTypesMapping(): Observable<Map<any, string>> {
    return this.sharedConstants.getCustomerClassSuperTypesMapping();
  }
}
