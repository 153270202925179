import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { HistoricalVersionDto } from '../models/historical-chart-settings/historical-version.dto';
import { BaseHistoricalValidationService } from './base-historical-validations.service';

@Injectable()
export abstract class DefaultHistoricalValidationService<
  TCurrentConfig
> extends BaseHistoricalValidationService {
  private readonly _dateHelper: DateHelperService = inject(DateHelperService);
  private readonly _arrayHelper: ArrayHelperService = inject(ArrayHelperService);

  abstract validateCurrentConfiguration(config1: TCurrentConfig, config2: TCurrentConfig): boolean;

  abstract getCurrentFromHistorical(config: HistoricalVersionDto): TCurrentConfig;

  getConflictsCurrentConfiguration(configurations: TCurrentConfig[]): TCurrentConfig[] {
    const configurationErrors = [];

    configurations.forEach((configuration1) => {
      configurations.forEach((configuration2) => {
        if (configuration1 !== configuration2 &&
          this.validateCurrentConfiguration(configuration1, configuration2)) {
          configurationErrors.push(configuration1);
          configurationErrors.push(configuration2);
        }
      });
    });

    return this._arrayHelper.onlyUnique(configurationErrors);
  }

  getConflictsHistoricalConfiguration(
    configurations: HistoricalVersionDto[]
  ): Observable<HistoricalVersionDto[]> {
    const configurationErrors = [];
    configurations.forEach((configuration1) => {
      configurations.forEach((configuration2) => {
        if (configuration1.id !== configuration2.id &&
          this.validateHistoricalConfiguration(configuration1, configuration2)) {
          configurationErrors.push(configuration1);
          configurationErrors.push(configuration2);

        }
      });
    });

    return of(this._arrayHelper.onlyUnique(configurationErrors));
  }

  getConflictsHistoricalConfigurationByConfiguration(
    configurations: HistoricalVersionDto[],
    configuration: HistoricalVersionDto
  ) {
    const configurationErrors: Set<HistoricalVersionDto> = new Set();
    configurations.forEach((configuration1) => {
      if (this.validateHistoricalConfiguration(configuration1, configuration)) {
        configurationErrors.add(configuration1);
        configurationErrors.add(configuration);
      }
    });

    return of(Array.from(configurationErrors.values()));
  }

  private validateHistoricalConfiguration(
    configuration1: HistoricalVersionDto,
    configuration2: HistoricalVersionDto
  ): boolean {
    if (configuration1.id == configuration2.id) {
      return false;
    }
    const hasDateCollision = this._dateHelper.checkDateCollision(
      new DateRange(configuration1.validFrom, configuration1.validTo),
      new DateRange(configuration2.validFrom, configuration2.validTo)
    );
    const hasValidationConflict = this.validateCurrentConfiguration(
      this.getCurrentFromHistorical(configuration1),
      this.getCurrentFromHistorical(configuration2)
    );

    return hasDateCollision && hasValidationConflict;
  }
}
