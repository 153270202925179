import { Injectable, Injector } from '@angular/core';
import { IAlarmHistoryDto } from '../../dependencies/alarms/alarm-history.dto';
import { PagedResultDto } from '../../shared/model/paged-result.dto';
import { GridODataService } from '../../shared/odata/grid-odata.service';

@Injectable()
export class HistoricalAlarmsGridService extends GridODataService<IAlarmHistoryDto> {
  constructor(injector: Injector) {
    super(injector, '/api/alarm/historical');
  }

  protected mapResponse(
    response: PagedResultDto<IAlarmHistoryDto>
  ): PagedResultDto<IAlarmHistoryDto> {
    return response;
  }
}
