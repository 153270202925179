<wlm-base-filter-container
  *ngIf="adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [filtersOverrides]="filtersOverrides"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background">
    <div
      [ngClass]="{
        'levels-group': treeFilterReadyToDisplay,
        'hide-filters': !treeFilterReadyToDisplay
      }"
    >
      <wlm-base-filter
        *ngIf="loaded"
        (filterText)="onFilterText($event)"
        (apply)="onApplyFamilyHEFilter($event)"
        [settings]="bf1Settings"
        [persistItems$]="persistFilters$"
      >
        <wlm-hierarchy-family-filter-item
          *ngIf="loaded"
          [selectedIds]="[hfSelectedId]"
          [fieldName]="hfFieldName"
          [filterText]="filterText"
          [restoreStatusOrder]="1"
          [settings]="familyFilterItemSettings"
          (select)="onFamilyIdSelect($event)"
        >
        </wlm-hierarchy-family-filter-item>

        <wlm-hierarchy-elements-filter-item
          *ngIf="!isNEFilter"
          [filterText]="filterText"
          [fieldName]="elementTypeFieldName"
          [familyId]="hfSelectedId"
          [defaultSelectedElements]="defaultElementTypes"
          [hideFilter]="!hfSelectedId"
          [restoreStatusOrder]="2"
          [selectedIds]="selectedElementTypes"
          [settings]="hierarchyFilterItemSettings"
          [filterListElements]="config.heDisplayableLevels"
          (hierarchyLevelsListChanged)="onhierarchyLevelsListChanged($event)"
        ></wlm-hierarchy-elements-filter-item>
      </wlm-base-filter>
    </div>

    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bf2Settings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="config.treeSettings.fieldName"
        [hierarchyFamilyIdFieldName]="config.familySettings.fieldName"
        [hierarchyFamilyId]="treeFamilyId"
        [initialSelectedKeys]="config.treeSettings.selectedIds"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        [rebuildTree$]="rebuildTreeFilter$"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>
  </div>
</wlm-base-filter-container>
