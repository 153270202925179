import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { MenuLink } from 'src/app/common-modules/dependencies/navigation/menu-link';
import { NavItem } from 'src/app/common-modules/dependencies/navigation/nav-item';
import { NavigationMode } from 'src/app/common-modules/dependencies/navigation/navigation-mode.enum';
import {
  WlmNavigationService,
  navigationMustOpenNewTab,
} from 'src/app/common-modules/dependencies/navigation/wlm-navigation.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { NavigationsConstants } from 'src/app/common-modules/shared/constants/navigation-constants';
import { LocalStorageService } from 'src/app/common-modules/shared/local-storage.service';

const COMPONENT_SELECTOR = 'wlm-nav-dropdown';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './nav-dropdown.component.html',
  styleUrls: ['./nav-dropdown.component.scss'],
})
export class NavDropdownComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  menuFormControl = new UntypedFormControl();

  defaultLabel = `${this.T_SCOPE}.navigate`;
  defaultIcon = 'navigate';

  private _navigationsItems: DropdownNavigationItem[];

  @Input() label: string;
  @Input() showLabel: boolean = false;
  @Input() showTooltip: boolean = true;
  @Input() icon: string;
  @Input() isContainerCollapsed: boolean = false;

  @Input() public set navigationsItems(v: DropdownNavigationItem[]) {
    if (!v) {
      return;
    }
    this._navigationsItems = v;
    this.validatePermissions();
  }

  public menuLinks: MenuLink[];
  isMenuDisabled: boolean;
  isMenuOpen: boolean;

  public selectedMenu = 2;
  constructor(
    private localStorageService: LocalStorageService,
    private navService: WlmNavigationService,
    private router: Router,
    private authorizeService: AuthorizeService
  ) {}

  ngOnInit(): void {}

  onMenuOpen($event) {
    this.isMenuOpen = true;
  }

  onMenuClose($event) {
    this.isMenuOpen = false;
  }

  clicked(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  menuClick(menuLink: MenuLink, event: MouseEvent) {
    const openInNewTab = navigationMustOpenNewTab(event);

    if (menuLink.customNavMethod !== null) {
      menuLink.customNavMethod({ openInNewTab });
    } else {
      if (openInNewTab) {
        const openInNewTab = true;
        this.setNavigation(menuLink, openInNewTab);
      } else {
        this.setNavigation(menuLink);
      }
    }
    this.menuTrigger.closeMenu();
  }

  getItemTooltip(menuLink: MenuLink): string {
    if (menuLink.disabled) {
      return menuLink.disabledKeyTooltip;
    }
    return '';
  }

  setNavigation(menuLink: MenuLink, openNewTab: boolean = false): void {
    let queryParams;

    if (this.isQueryParamsNavigation(menuLink)) {
      this.setNavigationMode(NavigationMode.Querystring);
      queryParams = menuLink.queryParams;
    } else {
      this.setNavigationMode(NavigationMode.Backend);
      this.localStorageService.addOrUpdate(
        NavigationsConstants.QueryParamskey,
        menuLink.queryParams,
        true
      );
    }

    const { urlPath, customNavMethod } = menuLink;
    this.navService.navigate({
      urlPath,
      queryParams,
      customNavMethod,
      openInNewTab: openNewTab,
    });
  }

  isQueryParamsNavigation(menuLink: MenuLink): boolean {
    if (!menuLink.queryParams) {
      return false;
    }

    let isQueryParamsNavigation = Object.values(menuLink.queryParams).some((value) => {
      const params = value?.length;
      return !(
        Array.isArray(value) &&
        params &&
        params >= NavigationsConstants.MaxQueryParamsByUrl
      );
    });

    return isQueryParamsNavigation;
  }

  setNavigationMode(navigationMode: NavigationMode): void {
    this.localStorageService.addOrUpdate(
      NavigationsConstants.NavigationModeKey,
      navigationMode,
      true
    );
  }

  private validatePermissions() {
    if (!this._navigationsItems.length) {
      return;
    }

    const pageCanAccess$ = {};
    const moduleCanAccess$ = {};
    this._navigationsItems.forEach((navigation) => {
      const pagePermissions = this.navService.getNavLinkPermissions(navigation.key);
      const modulePermissions = this.navService.getNavLinkModulePermissions(navigation.key);
      pageCanAccess$[navigation.key] = this.authorizeService.canAccessMultiple(pagePermissions);
      moduleCanAccess$[navigation.key] = this.authorizeService.canAccessMultiple(modulePermissions);
    });

    const dependencies$ = forkJoin({
      pageAccess: forkJoin(pageCanAccess$),
      moduleAccess: forkJoin(moduleCanAccess$),
    });

    dependencies$.pipe(untilDestroyed(this)).subscribe((dependencies) => {
      const validatedLinks = [];
      this._navigationsItems.forEach((navigationsItem) => {
        if (
          dependencies.pageAccess[navigationsItem.key] &&
          dependencies.moduleAccess[navigationsItem.key]
        ) {
          validatedLinks.push(navigationsItem);
        }
      });

      this._navigationsItems = validatedLinks;
      this.buildMenuElements();
    });
  }

  private buildMenuElements() {
    this.isMenuDisabled = this._navigationsItems.every((x) => x.disabled === true);

    const navItems = this._navigationsItems.map(
      (item) =>
        new NavItem(
          item.key,
          item.disabled,
          item.params,
          item.disabledKeyTooltip,
          item.customNavMethod,
          item.titleKey
        )
    );

    this.navService.generateMenuLinks(navItems).subscribe((menuLinks: MenuLink[]) => {
      this.menuLinks = menuLinks;
    });
  }
}
