<div *ngIf="selectedHE" class="hierarchy-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="hierarchy-column">
    <div class="hierarchy-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-children' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedElements"
        (somethingChanged)="compareHE()"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="hierarchy-column">
    <div class="hierarchy-group">
      <div class="top-title">
        <div class="ne-component-title top-title-position">
          {{ T_SCOPE + '.parent' | translate }}
        </div>
        <div>
          <button
            [disabled]="!configurationHasChanged"
            mat-icon-button
            *hasPerm="['WLMHierarchyCrud', 'u']"
            class="button-margin"
            (click)="onSave()"
            color="primary"
            [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'save'"></wlm-icon>
          </button>
          <button
            [disabled]="!configurationHasChanged"
            *hasPerm="['WLMHierarchyCrud', 'u']"
            mat-icon-button
            class="button-margin"
            (click)="discard()"
            color="primary"
            [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
          >
            <wlm-icon [icon]="'discard-changes'"></wlm-icon>
          </button>
        </div>
      </div>
      <div class="parent-info">
        <wlm-drag-list-card
          [item]="selectedHE"
          [settings]="parentCardSettings"
        ></wlm-drag-list-card>
      </div>
      <div class="ne-component-title middle-title">
        {{ T_SCOPE + '.children' | translate }}
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="children"
        [settings]="settingsCustom"
        (somethingChanged)="compareHE()"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
