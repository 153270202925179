<div class="main-bi-container nested-dynamic-layout bi-widget">
  <wlm-dynamic-layout
    *ngIf="settingsToLoad"
    [allowEmptyRoot]="allowEmptyRoot"
    [class.hide-layout]="syncedSettings?.items.length === 0"
    [featureInjector]="injector"
    [settingsToLoad]="settingsToLoad"
    (layoutLoading)="onLayoutLoading($event)"
    (settingsChanged)="onLayoutSettingsChanged($event)"
    (currentLayoutIdentity)="onLayoutIdentityChanged($event)"
    (resized)="onResize($event)"
  ></wlm-dynamic-layout>
  <wlm-placeholder-overlay
    *ngIf="!syncedSettings || syncedSettings?.items.length === 0"
    [titleKey]="T_SCOPE + '.placeholder-title'"
    [subtitleKey]="T_SCOPE + '.placeholder-subtitle'"
  ></wlm-placeholder-overlay>
</div>
