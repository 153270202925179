import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as constants from 'src/app/common-modules/dependencies/shared/hierarchy-tree-filter-constants';
import { ITreeSettings } from 'src/app/common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { LocalizationHelperService } from '../localization/localization-helper.service';

@Injectable({ providedIn: 'root' })
export class TreeSettingsService {
  constructor(private localization: LocalizationHelperService) {}

  buildTreeSettings(overrideSettings: Partial<ITreeSettings> = {}): Observable<ITreeSettings> {
    return this.localization.get(['common.search', 'common.search-placeholder']).pipe(
      map((ts) => {
        const settings = {
          selectionMode: constants.treeSelectionMode.multiple,
          aoiList: [],
          unselectableTypes: [],
          minLenghtToSearch: 2,
          autocompleteSetting: {
            itemSource: [],
            placeHolderText: ts['common.search-placeholder'],
            searchBoxTitle: ts['common.search'],
          },
          displayableField: constants.hierarchyElementPathFields.Descendant,
          focusDelay: 4000,
          showRecursiveToggle: false,
          showSelectedNodesChips: true,
          ...overrideSettings,
        };
        return settings;
      })
    );
  }
}
