<div *ngIf="selectedNE" class="boundary-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="boundary-column">
    <div class="boundary-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [excludeList]="excludedSignals"
        (excludeListChange)="onExcludedListChange($event)"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElementInSource($event)"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="boundary-column">
    <div class="top-title" *ngIf="!disableButtons">
      <div>
        <button
          [disabled]="!configurationHasChanged"
          mat-icon-button
          *hasPerm="[permissionsCrud, 'u']"
          class="button-margin"
          (click)="onSave()"
          color="primary"
          [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
        >
          <wlm-icon [icon]="'save'"></wlm-icon>
        </button>
        <button
          [disabled]="!configurationHasChanged"
          *hasPerm="[permissionsCrud, 'u']"
          mat-icon-button
          class="button-margin"
          (click)="discard()"
          color="primary"
          [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        >
          <wlm-icon [icon]="'discard-changes'"></wlm-icon>
        </button>
      </div>
    </div>
    <div class="boundary-group">
      <div *ngFor="let customerClassType of customerClassTypes">
        <div class="ne-component-title top-title-position">
          {{ customerClassType.customerClassTypeName }}
        </div>
        <wlm-drag-list-custom
          class="drag-list"
          [objectsToOrder]="
            configurationsHash[customerClassType.customerClassTypeId]
              ? [configurationsHash[customerClassType.customerClassTypeId]]
              : []
          "
          [settings]="settingsCustom"
          (somethingChanged)="compareSignals()"
          (droppedElement)="onDroppedElement(customerClassType, $event)"
        >
          <ng-template let-rowDetail #card>
            <wlm-drag-list-card
              class="card-width"
              [item]="rowDetail"
              [settings]="cardSettings"
            ></wlm-drag-list-card>
          </ng-template>
        </wlm-drag-list-custom>
      </div>
    </div>
  </div>
</div>
