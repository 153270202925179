import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { SharedConstantsService } from '../../shared/constants/shared-constants.service';

@Component({
  selector: 'wlm-numeric-column-filter',
  templateUrl: './numeric-column-filter.component.html',
  styleUrls: ['./numeric-column-filter.component.scss'],
})
export class NumericColumnFilterComponent
  extends BaseFilterCellComponent
  implements OnInit, AfterViewInit
{
  @Input() public filter: any;
  @Input() public filterService: FilterService;
  @Input() public field: string;

  filterOperators: Array<{ text: string; value: string }>;
  T_SCOPE = 'kendo.grid';

  //operator selectors
  private _firstFilterOperator: string;
  public get firstFilterOperator(): string {
    return this._firstFilterOperator;
  }
  public set firstFilterOperator(value: string) {
    this._firstFilterOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  private _secondFilterOperator: string;
  public get secondFilterOperator(): string {
    return this._secondFilterOperator;
  }
  public set secondFilterOperator(value: string) {
    this._secondFilterOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  //input values
  private _firstFilterValue: number;
  public get firstFilterValue(): number {
    return this._firstFilterValue;
  }
  public set firstFilterValue(value: number) {
    if (this._firstFilterValue === value) {
      return;
    }

    this._firstFilterValue = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  private _secondFilterValue: number;
  public get secondFilterValue(): number {
    return this._secondFilterValue;
  }
  public set secondFilterValue(value: number) {
    if (this._secondFilterValue === value) {
      return;
    }

    this._secondFilterValue = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  // main union operator
  private _unionOperator: 'and' | 'or';
  public get unionOperator(): 'and' | 'or' {
    return this._unionOperator;
  }
  public set unionOperator(value: 'and' | 'or') {
    this._unionOperator = value;
    if (this._canUpdateFilter) {
      this.updateCurrentFilter();
    }
  }

  disabledFirstFilter = false;
  disabledSecondFilter = false;

  private _canUpdateFilter: boolean = false;

  private readonly _firstFilterIndex = 0;
  private readonly _secondFilterIndex = 1;
  private readonly _defaultOperator = 'gte';

  constructor(filterService: FilterService, sharedConstantsService: SharedConstantsService) {
    super(filterService);
    // Get translated filter operators
    sharedConstantsService
      .mapToArrayObservable(sharedConstantsService.getFilterOperatorsMapping(), 'value', 'text')
      .subscribe((array) => {
        this.filterOperators = array;
      });
  }

  ngOnInit(): void {
    this.unionOperator = this.getAppliedMainOperator();

    const firstFilter = this.getAppliedFilter(this._firstFilterIndex);
    this.firstFilterOperator = (firstFilter?.operator as string) ?? this._defaultOperator;
    this.firstFilterValue = firstFilter?.value ?? undefined;

    const secondFilter = this.getAppliedFilter(this._secondFilterIndex);
    this.secondFilterOperator = (secondFilter?.operator as string) ?? 'gte';
    this.secondFilterValue = secondFilter?.value ?? undefined;

    this.checkDisabledOnUnaryOperator();
  }

  ngAfterViewInit(): void {
    this._canUpdateFilter = true;
  }

  preventEvent(event: PointerEvent): void {
    event.stopPropagation();
  }

  private getAppliedMainOperator(): 'and' | 'or' {
    let mainOperator: 'and' | 'or' = 'and';

    if (!this.filter || this.filter.filters.length === 0) {
      return mainOperator;
    }

    if (this.filter) {
      mainOperator = this.filter.logic;
    }

    return mainOperator;
  }

  private getAppliedFilter(index: number): FilterDescriptor {
    if (!this.filter || this.filter.filters.length === 0) {
      return undefined;
    }

    const internalFilters = this.filter.filters as FilterDescriptor[];

    if (!internalFilters || internalFilters.length === 0) {
      return undefined;
    }

    return internalFilters[index];
  }

  private updateCurrentFilter(): void {
    const newFirstFilter = this.buildFilter(
      this.firstFilterValue,
      this.firstFilterOperator,
      this._firstFilterIndex
    );

    const newSecondFilter = this.buildFilter(
      this.secondFilterValue,
      this.secondFilterOperator,
      this._secondFilterIndex
    );
    const filters = [newFirstFilter, newSecondFilter].filter((f) => f !== null);

    this.checkDisabledOnUnaryOperator();

    if (!filters.length && this.filter.filters.length) {
      this.removeFilter(this.field);
    } else {
      if (filters.length > 0) {
        const operator = filters.length > 1 ? this.unionOperator : 'and';

        const columnFilter: CompositeFilterDescriptor = {
          logic: operator,
          filters: filters,
        };

        this.applyFilter(columnFilter);
      }
    }
  }

  private buildFilter(
    filterValue: number,
    filterOperator: string,
    index: number
  ): FilterDescriptor {
    const operatorIsUnary = filterOperator === 'isnull' || filterOperator === 'isnotnull';

    if (!operatorIsUnary && (filterValue === undefined || filterValue === null)) {
      return null;
    }

    const value = operatorIsUnary ? 'null' : filterValue;

    return {
      field: this.field,
      operator: filterOperator,
      value,
    } as FilterDescriptor;
  }

  private checkUnaryOperatorInputs(index: number, filterOperator: string, filterValue: number) {
    const operatorIsUnary = filterOperator === 'isnull' || filterOperator === 'isnotnull';

    if (index === this._firstFilterIndex) {
      this.firstFilterValue = operatorIsUnary ? null : filterValue;
      this.disabledFirstFilter = operatorIsUnary ? true : false;
    } else {
      this.secondFilterValue = operatorIsUnary ? null : filterValue;
      this.disabledSecondFilter = operatorIsUnary ? true : false;
    }
  }

  private checkDisabledOnUnaryOperator() {
    this.checkUnaryOperatorInputs(
      this._firstFilterIndex,
      this.firstFilterOperator,
      this.firstFilterValue
    );

    this.checkUnaryOperatorInputs(
      this._secondFilterIndex,
      this.secondFilterOperator,
      this.secondFilterValue
    );
  }
}
