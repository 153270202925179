import { Observable, of } from 'rxjs';

export class ButtonTogglerSettings {
  buttons: ButtonTogglerItemSettings[];

  constructor(init: ButtonTogglerSettings) {
    Object.assign(this, init);
  }
}

export class ButtonTogglerItemSettings {
  value: string;
  labelKey: string;
  beforeToggle?: (selectedValue: string) => Observable<boolean> = (_) => of(true);

  constructor(init: ButtonTogglerItemSettings) {
    Object.assign(this, init);
  }
}
