import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-alarm-activations-grid';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-activations-grid.component.html',
  styleUrls: ['./alarm-activations-grid.component.scss'],
})
export class AlarmActivationsGridComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(GenericGridComponent) alarmsActivationGrid: GenericGridComponent;
  alarmTypeId: string;
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  gridSettings: IGridSettings;
  filters: DataBindingFilters;
  isReady = false;

  get componentName(): string {
    return 'AlarmActivationsGridComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gridSettingsService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getGridSettings();
  }

  getGridSettings() {
    this._gridSettingsService.getGridSettingsByName('AlarmActivations').subscribe({
      next: (setting) => {
        this.gridSettings = setting;
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.isReady = false;
    this.alarmTypeId = parameters.parameters.get(TabDetailParameterName.alarmTypeId);
  }

  init(): void {
    this._setDataBidingFilters();
  }

  private _setDataBidingFilters() {
    if (this.alarmTypeId) {
      const filter = new DataBindingFilters();
      filter.addOrUpdateBasicFilter('alarmTypeId', this.alarmTypeId);
      this.filters = filter;
    }
  }
}
