import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DateValidators {
  static dateLessThan(
    dateField1: string,
    dateField2: string,
    ensureDateObject: (date: any) => Date,
    disableEqualDates = false
  ): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1Ctrl: AbstractControl = c?.get(dateField1);
      const date2Ctrl: AbstractControl = c?.get(dateField2);
      const date1 = date1Ctrl?.value;
      const date2 = date2Ctrl?.value;
      if (date1 !== '' && date2 !== '' && date1 !== null && date2 !== null) {
        const date1Obj = ensureDateObject(date1);
        const date2Obj = ensureDateObject(date2);

        let error;
        if (!disableEqualDates && date1Obj > date2Obj) {
          // If equal dates are allowed, only show error if d1 > d2.
          error = { dateOlderThanError: true };
        } else if (disableEqualDates && date1Obj >= date2Obj) {
          // If equal dates are disabled, must show an error when d1 > d2 or d1 == d2.
          error = { dateOlderEqualThanError: true };
        }

        if (error) {
          this.setControlErrors(date1Ctrl, error);
          this.setControlErrors(date2Ctrl, error);
          return error;
        }
      }

      this.updateValidityControls(date1Ctrl, date2Ctrl);

      return null;
    };
  }

  static datePeriodMaximumRange(
    dateField1: string,
    dateField2: string,
    maxPeriod: number,
    ensureDateObject: (date: any) => Date
  ): ValidatorFn {
    return (c: AbstractControl) => {
      var date1 = c.get(dateField1);
      var date2 = c.get(dateField2);

      if (
        !date1.value ||
        !date2.value ||
        !ensureDateObject(date2.value) ||
        !ensureDateObject(date1.value)
      ) {
        this.setControlErrors(date1, { invalidDateRangeError: true });
        this.setControlErrors(date2, { invalidDateRangeError: true });

        return { invalidDateRangeError: true };
      }

      var diff = Math.abs(
        ensureDateObject(date1.value).getTime() - ensureDateObject(date2.value).getTime()
      );
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      if (diffDays > maxPeriod) {
        this.setControlErrors(date1, { dateMaximumPeriodError: true });
        this.setControlErrors(date2, { dateMaximumPeriodError: true });
        return { dateMaximumPeriodError: true };
      }

      this.updateValidityControls(date1, date2);

      return null;
    };
  }

  static updateValidityControls(control1: AbstractControl, control2: AbstractControl) {
    if (
      !(control1?.errors && control2.errors) ||
      !(Object.keys(control2.errors)?.length && Object.keys(control1.errors)?.length)
    ) {
      control1.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      control2.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  static setControlErrors(control: AbstractControl, errors): void {
    const previousErrors = control.errors || {};
    control.setErrors({ ...previousErrors, ...errors });
  }

  static dateOlderEqThanValue(dateField: string, date2: Date): ValidatorFn {
    return DateValidators.validateDateAndValue(
      dateField,
      date2,
      'dateOlderEqThanError',
      (d1: Date, d2: Date) => d1 <= d2
    );
  }

  static dateNewerEqThanValue(dateField: string, date2: Date): ValidatorFn {
    return DateValidators.validateDateAndValue(
      dateField,
      date2,
      'dateNewerEqThanError',
      (d1: Date, d2: Date) => d1 >= d2
    );
  }

  static validateDateAndValue(
    dateField: string,
    date2: Date,
    errorName: string,
    isValidFn: (d: Date, d2: Date) => boolean
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const dateControl = c?.get(dateField);
      const date1 = DateValidators.resetTime(dateControl.value);
      date2 = DateValidators.resetTime(date2);
      if (date1 !== null && date2 !== null && !isValidFn(date1, date2)) {
        const error = {
          [errorName]: {
            date: date2,
          },
        };
        dateControl.setErrors(error);
        return error;
      }

      dateControl.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      return null;
    };
  }

  static resetTime(date: Date): Date {
    if (!date) {
      return null;
    }
    const newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }
}

export const dateLessThanValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const date1 = control?.get('start');
  const date2 = control?.get('end');
  if (date1.value !== null && date2.value !== null && date1.value > date2.value) {
    date1.setErrors({ dateOlderThanError: true });
    date2.setErrors({ dateOlderThanError: true });
    return { dateOlderThanError: true };
  } else {
    return null;
  }
};
