import { Injectable, Injector } from '@angular/core';
import { ActionCreator } from '@ngrx/store';
import { StateSelectorBuilder } from '../../redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from '../../redux/store/base.store';
import { UomState } from './uom-state';
import { ResetUoMStateAction, SelectDimensionAction, UOM_ACTION_CREATORS } from './uom.action';
import { UomActionTypes } from './uom.action-types';
import { UomSelectorTypes } from './uom.selector-types';

@Injectable()
export class UomStoreService extends BaseStore {
  readonly serviceName = 'UomStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        UomActionTypes.SelectDimension,
        (state: UomState, action: SelectDimensionAction) => {
          state.selectedDimension = action.payload;
        },
      ],
      [
        UomActionTypes.ResetUomState,
        (state: UomState, _: ResetUoMStateAction) => {
          state.selectedDimension = undefined;
        },
      ],
    ]);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        UomSelectorTypes.SelectedDimension,
        {
          fn: (state: UomState) => {
            return state?.selectedDimension;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return UOM_ACTION_CREATORS;
  }
}
