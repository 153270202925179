<wlm-generic-grid
  class="generic-grid"
  *ngIf="gridSettings && dynamicGridSettings?.type == 'generic'"
  [persistencyArea]="persistencyArea"
  [usePersistence]="usePersistency"
  [entityPermission]="entityPermission"
  [gridSettings]="gridSettings"
  [gridFiltersForBinding]="gridFilters"
  [additionalFilters]="additionalFilters"
  (selectedItemChanged)="getSelectedItem($event)"
  [(selectedItems)]="selectedItems"
  [removeSelectionPersisted$]="removeSelectionPersisted$"
  [removeSelection$]="removeSelection$"
  [showSelectedList]="showSelectedList"
  [showSelectedItems]="showSelectedItems"
  (gridDataLoaded)="onGridDataLoaded()"
  (isInThePastChange)="onIsInThePastChange($event)"
>
</wlm-generic-grid>
