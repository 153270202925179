import { Component, Input } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-placeholder-overlay';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './placeholder-overlay.component.html',
  styleUrls: ['./placeholder-overlay.component.scss'],
})
export class PlaceholderOverlayComponent {
  @Input() titleKey: string;
  @Input() subtitleKey: string;

  constructor() {}
}
