import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutIdentity } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-identity';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings-load-options';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import { WidgetDefinitionSettings } from 'src/app/common-modules/dynamic-layout/models/widget-definition-settings';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  SetCurrentLayoutAction,
  SetCurrentLayoutIdentityAction,
  SetLayoutKeysAction,
} from 'src/app/common-modules/dynamic-layout/state/widget-manager/widget-manager.actions';
import {
  LayoutKeysSelector,
  LayoutToLoadSelector,
  ResetLayoutSelector,
  SelectedWidgetDefinitionSelector,
} from 'src/app/common-modules/dynamic-layout/state/widget-manager/widget-manager.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';

import { WidgetsContainerComponent } from '../../../widgets-container/widgets-container.component';

const COMPONENT_SELECTOR = 'wlm-bi-container-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-container-widget.component.html',
  styleUrls: ['./bi-container-widget.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class BiContainerWidgetComponent extends BaseDynamicWidgetComponent implements OnInit {
  @ViewChild(WidgetsContainerComponent) widgetsContainerComponent: WidgetsContainerComponent;

  settingsKeys: DynamicSettings;
  settingsToLoad: DynamicLayoutSettingsLoadOptions;
  widgetInstanceKey: string;
  newDefinition: WidgetDefinitionSettings;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
  }

  onWidgetInit(): void {
    // First flow: The page has just loaded.
    this._state
      .select<DynamicSettings>(new LayoutKeysSelector())
      .pipe(untilDestroyed(this))
      .subscribe((settingsKeys) => {
        if (settingsKeys) {
          this.settingsKeys = settingsKeys;
        }
      });

    // Second flow. Any component has loaded a specific dashboard to show.
    this._state
      .select<DynamicLayoutSettingsLoadOptions>(new LayoutToLoadSelector())
      .pipe(untilDestroyed(this))
      .subscribe((settingsToLoad) => {
        if (settingsToLoad) {
          this.settingsToLoad = settingsToLoad;
        }
      });

    this._state
      .select<WidgetDefinitionSettings>(new SelectedWidgetDefinitionSelector())
      .pipe(untilDestroyed(this))
      .subscribe((newDefinition) => {
        if (newDefinition) {
          this.newDefinition = newDefinition;
        }
      });

    this._state
      .select<DynamicSettings>(new ResetLayoutSelector())
      .pipe(
        untilDestroyed(this),
        filter((keys) => {
          const hasBeenReset =
            keys &&
            this.settingsKeys &&
            keys.settingArea === this.settingsKeys.settingArea &&
            keys.settingKey === this.settingsKeys.settingKey;
          return hasBeenReset;
        })
      )
      .subscribe((settings) => {
        this.widgetsContainerComponent?.resetToDefault();
        // Restart the main flow
        this._state.dispatch(new SetLayoutKeysAction(null));
        this._state.dispatch(new SetLayoutKeysAction(settings));
      });
  }

  onSettingsChanged(settings: DynamicLayoutSettings): void {
    this._state.dispatch(new SetCurrentLayoutAction(settings));
  }

  onLayoutIdentityChanged(identity: DynamicLayoutIdentity): void {
    this._state.dispatch(new SetCurrentLayoutIdentityAction(null));
    this._state.dispatch(new SetCurrentLayoutIdentityAction(identity));
  }

  get componentName(): string {
    return 'BiContainerWidgetComponent';
  }
}
