import { BoundarySignalVersionOperationDto } from './boundary-signal-version-operation.dto';
import { BoundarySignalDto } from './boundary-signal.dto';

export class BoundarySignalVersionSaveDto {
  hierarchyElementId: string;
  hierarchyElementName: string;
  minDate: string;
  boundarySignalVersionOperations: BoundarySignalVersionOperationDto[];
  boundarySignals: BoundarySignalDto[];
  trackedDates: string[];
  trackedSignals: number[];

  constructor(init: BoundarySignalVersionSaveDto) {
    Object.assign(this, init);
  }
}
