import { Component, Input, OnInit, PipeTransform } from '@angular/core';
import { GridColumnHelperService } from '../../shared/helpers/grid-column-helper.service';
import { ColumnTitlePipe } from '../pipes/column-title.pipe';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-column-header-template',
  templateUrl: './column-header-template.component.html',
  styleUrls: ['./column-header-template.component.scss'],
})
export class ColumnHeaderTemplateComponent implements OnInit {


  private _titleSuffix: string;
  public get titleSuffix(): string {
    return this._titleSuffix;
  }
  @Input() public set titleSuffix(value: string) {
    this._titleSuffix = value;
    this.buildTitle();
  }

  @Input() public set columnTitle(columnInfo: WlmColumnComponent) {
    if (columnInfo) {
      this.titlePipe.transform(columnInfo.columnSettings).subscribe({
        next: (result) => {
          this.baseColumnTitle = result;
          this.buildTitle();
        }
      })
    }
  }

  baseColumnTitle: string;
  processedColumnTitle: string;
  titlePipe: PipeTransform;

  constructor(private _gridColumnHelper: GridColumnHelperService) {
    this.titlePipe = new ColumnTitlePipe(this._gridColumnHelper);
  }

  ngOnInit(): void { }

  private buildTitle(): void {
    this.processedColumnTitle = `${this.baseColumnTitle}${this.titleSuffix ? ' ' + this.titleSuffix : ''}`;
  }
}
