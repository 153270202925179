import { Observable } from 'rxjs';
import { KpiSettingValue } from '../map-thematic/models/map-thematic-kpi-value';

export class ThematicKPI extends KpiSettingValue {
  kpiLabel?: string | Observable<string>;
  categoryKey: string;
  categoryLabel: string;
  kpiLabel$?: Observable<string>;

  constructor(init: Partial<ThematicKPI>) {
    const superInit = new KpiSettingValue({
      kpiProperty: init.kpiProperty,
      kpiType: init.kpiType,
      steps: init.steps,
      dimensionTypeId: init.dimensionTypeId,
      timeAggregationId: init.timeAggregationId,
      labelKey: init.labelKey,
      unitTypeFormat: init.unitTypeFormat,
    });

    super(superInit);

    Object.assign(this, init);

    init.kpiLabel$?.subscribe((x) => {
      this.kpiLabel = x;
    });
  }
}
