<div class="ranges-container" *ngIf="showCart">
  <div class="title-container">
    <mat-icon svgIcon="select-vertically"></mat-icon>
    <span class="title">{{ T_SCOPE + '.title' | translate }}</span>
  </div>

  <form [formGroup]="rangeForm">
    <div *ngIf="!rangeIds.length" class="ranges-cart message-container no-data-label message">
      {{ T_SCOPE + '.messages.cart-empty-text' | translate }}
    </div>
    <div *ngIf="rangeIds.length" class="ranges-cart">
      <div class="cart-item mf-small-infix" *ngFor="let rangeId of rangeIds">
        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.fields.start-value' | translate }}</mat-label>

          <input
            matInput
            type="number"
            [formControlName]="rangeStartControlPrefix + rangeId"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ T_SCOPE + '.fields.end-value' | translate }}</mat-label>

          <input
            matInput
            type="number"
            [formControlName]="rangeEndControlPrefix + rangeId"
            autocomplete="off"
          />
        </mat-form-field>
        <div>
          <button
            mat-icon-button
            (click)="onRemoveRange(rangeId)"
            onclick="this.blur()"
            [matTooltip]="'common.remove' | translate | lowercase"
          >
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="errors-container">
      <div *ngFor="let error of errorList">
        <mat-error *ngIf="error === 'required'">
          * {{ 'common.validation.required' | translate }}
        </mat-error>
        <mat-error *ngIf="error === 'greaterThan'">
          * {{ 'common.validation.invalid-range-pair' | translate }}
        </mat-error>
      </div>
    </div>

    <div class="functions-container">
      <div class="title-container">
        <span class="title">{{ T_SCOPE + '.estimation-functions' | translate }}</span>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.fields.estimation-function' | translate }}</mat-label>
        <mat-select [formControlName]="estimationFunctionFieldName">
          <mat-option *ngFor="let fn of estimationFunctions" [value]="fn.type">
            {{ fn.labelKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="fixedEstimationSelected">
        <mat-form-field appearance="outline">
          <mat-label>{{ fixedValueLabel$ | async }}</mat-label>

          <input
            matInput
            type="number"
            [formControlName]="fixedValueFieldName"
            autocomplete="off"
          />
          <mat-error *ngIf="rangeForm.get(fixedValueFieldName)?.errors?.required">
            {{ 'common.validation.required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="action-buttons-container">
    <button
      mat-button
      class="wtr-action-button-primary btn-size"
      (click)="onApplyFunction()"
      [disabled]="isLoading || rangeForm?.pristine || !rangeForm?.valid || !valueRanges?.length"
    >
      {{ 'common.apply' | translate | uppercase }}
    </button>
  </div>
</div>
