<wlm-popup-wrapper [titleKey]="titleKey" [showCloseButton]="false" (popupClose)="onClose($event)">
  <ng-container popup-content>
    <wlm-merged-zones-form
      [isUpdate]="isUpdate"
      [formControl]="control"
      [conflictError$]="conflictError$"
      [conflictNameError$]="conflictNameError$"
    ></wlm-merged-zones-form>
  </ng-container>

  <ng-container popup-actions>
    <div class="action-buttons-container">
      <button mat-raised-button class="wtr-action-button-error" (click)="onClose()">
        {{ 'common.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        class="wtr-action-button-primary"
        [disabled]="!isValid"
        (click)="onSave()"
      >
        {{ 'common.save' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
