<div class="points-selection-container" cdkDropListGroup>
  <wlm-select-drag-list-virtual
    class="select-drag-list-virtual"
    [settings]="settings"
    [queryParams]="queryParams"
    [refreshList$]="refreshList$"
    [reloadList$]="reloadList$"
  >
    <ng-template let-rowDetail #card>
      <wlm-generic-card [item]="rowDetail" [settings]="cardSettings">
        <ng-template #right>
          <div class="add-button-container">
            <button
              mat-icon-button
              color="primary"
              (click)="onSelectItem(rowDetail)"
              [matTooltip]="'common.add' | translate | lowercase"
              [disableRipple]="true"
              onclick="this.blur()"
            >
              <mat-icon class="material-icon">add</mat-icon>
            </button>
          </div>
        </ng-template>
      </wlm-generic-card>
    </ng-template>
  </wlm-select-drag-list-virtual>
</div>
