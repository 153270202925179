import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class AlgorithmFilter implements IFilter {
  elementIds: string[];
  elementIdFieldName: string;

  constructor(elementIds: string[], elementIdFieldName: string) {
    this.elementIds = elementIds;
    this.elementIdFieldName = elementIdFieldName;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.elementIdFieldName, this.elementIds]]);
  }

  getSelectedFilters() {
    return;
  }

  getFilters(): CompositeFilterDescriptor {
    const filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

    const algorithmFilter: CompositeFilterDescriptor = { logic: 'or', filters: [] };

    this.elementIds.forEach((id) =>
      algorithmFilter.filters.push({ field: this.elementIdFieldName, operator: 'eq', value: id })
    );

    filter.filters.push(algorithmFilter);

    return filter;
  }
}
