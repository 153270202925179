import { Injectable, Injector } from '@angular/core';
import { ActionCreator, Creator } from '@ngrx/store';
import { StateSelectorBuilder } from 'src/app/common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from 'src/app/common-modules/redux/store/base.store';

@Injectable()
export class DataValidationStoreService extends BaseStore {
  readonly serviceName = 'DataValidationStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>();
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>();
  }

  protected getActionCreators(): ActionCreator<any, Creator<any[], object>>[] {
    return [] as ActionCreator<any, Creator<any[], object>>[];
  }
}
