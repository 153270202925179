<wlm-no-results [showNoResult]="!isLoading && !data"></wlm-no-results>

<wlm-spinner-wrapper
  [showSpinner]="isLoading"
  [enableRelative]="true"
  class="wb-container"
  [ngStyle]="{
    height: heightPx,
    width: widthPx
  }"
>
  <div class="wb-widget" *ngIf="!isLoading && data" data-observe-resizes>
    <div class="wb-column wb-fixed-column">
      <div class="wb-group">
        <wlm-water-balance-widget-item
          [value]="data.systemInputVolume"
          [selected]="selectedHash[kpiValues.SystemInputVolume]"
          [dimension]="data.dimensionTypeId"
          [timeAggregation]="data.timeAggregationId"
          [hierarchyElementTypeId]="data.hierarchyElementTypeId"
          [unitFormat]="data.unitFormat"
          [currencyType]="data.currencyType"
          classI18nKey="kpi-0-system-input-volume"
          (click)="onSelect(kpiValues.SystemInputVolume)"
        ></wlm-water-balance-widget-item>
      </div>
    </div>
    <div class="wb-column" style="flex: 3 1 0px">
      <div class="wb-row">
        <div class="wb-group">
          <wlm-water-balance-widget-item
            [value]="data.authorizedConsumption"
            [percent]="data.authorizedConsumptionPercentage"
            [selected]="selectedHash[kpiValues.AuthorizedConsumption]"
            [dimension]="data.dimensionTypeId"
            [timeAggregation]="data.timeAggregationId"
            [hierarchyElementTypeId]="data.hierarchyElementTypeId"
            [unitFormat]="data.unitFormat"
            [currencyType]="data.currencyType"
            classI18nKey="kpi-1-authorized-consumption"
            (click)="onSelect(kpiValues.AuthorizedConsumption)"
          ></wlm-water-balance-widget-item>
        </div>
        <div class="wb-group" style="flex: 3 1 0px">
          <div class="wb-row">
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.billedAuthorizedConsumption"
                [percent]="data.billedAuthorizedConsumptionPercentage"
                [selected]="selectedHash[kpiValues.BilledAuthorizedConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-3-billed-authorized-consumption"
                (click)="onSelect(kpiValues.BilledAuthorizedConsumption)"
              ></wlm-water-balance-widget-item>
            </div>
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.billedMeteredConsumption"
                [percent]="data.billedMeteredConsumptionPercentage"
                [selected]="selectedHash[kpiValues.BilledMeteredConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-7-billed-metered-consumption"
                (click)="onSelect(kpiValues.BilledMeteredConsumption)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.billedUnmeteredConsumption"
                [percent]="data.billedUnmeteredConsumptionPercentage"
                [selected]="selectedHash[kpiValues.BilledUnmeteredConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-8-billed-unmetered-consumption"
                (click)="onSelect(kpiValues.BilledUnmeteredConsumption)"
              ></wlm-water-balance-widget-item>
            </div>
          </div>

          <div class="wb-row">
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.unbilledAuthorizedConsumption"
                [percent]="data.unbilledAuthorizedConsumptionPercentage"
                [selected]="selectedHash[kpiValues.UnbilledAuthorizedConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-4-unbilled-authorized-consumption"
                (click)="onSelect(kpiValues.UnbilledAuthorizedConsumption)"
              ></wlm-water-balance-widget-item>
            </div>
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.unbilledMeteredConsumption"
                [percent]="data.unbilledMeteredConsumptionPercentage"
                [selected]="selectedHash[kpiValues.UnbilledMeteredConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-9-unbilled-metered-consumption"
                (click)="onSelect(kpiValues.UnbilledMeteredConsumption)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.unbilledUnmeteredConsumption"
                [percent]="data.unbilledUnmeteredConsumptionPercentage"
                [selected]="selectedHash[kpiValues.UnbilledUnmeteredConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-10-unbilled-unmetered-consumption"
                (click)="onSelect(kpiValues.UnbilledUnmeteredConsumption)"
              ></wlm-water-balance-widget-item>
            </div>
          </div>
        </div>
      </div>
      <div class="wb-row" style="flex: 1.5 1 0px">
        <div class="wb-group">
          <wlm-water-balance-widget-item
            [value]="data.waterLosses"
            [percent]="data.waterLossesPercentage"
            [selected]="selectedHash[kpiValues.WaterLosses]"
            [dimension]="data.dimensionTypeId"
            [timeAggregation]="data.timeAggregationId"
            [hierarchyElementTypeId]="data.hierarchyElementTypeId"
            [unitFormat]="data.unitFormat"
            [currencyType]="data.currencyType"
            classI18nKey="kpi-2-water-losses"
            (click)="onSelect(kpiValues.WaterLosses)"
          ></wlm-water-balance-widget-item>
        </div>
        <div class="wb-group" style="flex: 3 1 0px">
          <div class="wb-row">
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.aparentLosses"
                [percent]="data.aparentLossesPercentage"
                [selected]="selectedHash[kpiValues.AparentLosses]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-5-apparent-losses"
                (click)="onSelect(kpiValues.AparentLosses)"
              ></wlm-water-balance-widget-item>
            </div>
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.unauthorizedConsumption"
                [percent]="data.unauthorizedConsumptionPercentage"
                [selected]="selectedHash[kpiValues.UnauthorizedConsumption]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-11-unauthorized-consumption"
                (click)="onSelect(kpiValues.UnauthorizedConsumption)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.customerMeteringInaccuracies"
                [percent]="data.customerMeteringInaccuraciesPercentage"
                [selected]="selectedHash[kpiValues.CustomerMeteringInaccuracies]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-12-customer-metering-inaccuracies"
                (click)="onSelect(kpiValues.CustomerMeteringInaccuracies)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.dataHandlingErrors"
                [percent]="data.dataHandlingErrorsPercentage"
                [selected]="selectedHash[kpiValues.DataHandlingErrors]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-13-data-handling-errors"
                (click)="onSelect(kpiValues.DataHandlingErrors)"
              ></wlm-water-balance-widget-item>
            </div>
          </div>
          <div class="wb-row">
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.realLosses"
                [percent]="data.realLossesPercentage"
                [selected]="selectedHash[kpiValues.RealLosses]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-6-real-losses"
                (click)="onSelect(kpiValues.RealLosses)"
              ></wlm-water-balance-widget-item>
            </div>
            <div class="wb-group">
              <wlm-water-balance-widget-item
                [value]="data.leakageTransmissionMains"
                [percent]="data.leakageTransmissionMainsPercentage"
                [selected]="selectedHash[kpiValues.LeakageTransmissionMains]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-14-leakage-on-transmission-mains"
                (click)="onSelect(kpiValues.LeakageTransmissionMains)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.leakageDistributionNetwork"
                [percent]="data.leakageDistributionNetworkPercentage"
                [selected]="selectedHash[kpiValues.LeakageDistributionNetwork]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-15-leakage-on-distribution-network"
                (click)="onSelect(kpiValues.LeakageDistributionNetwork)"
              ></wlm-water-balance-widget-item>
              <wlm-water-balance-widget-item
                [value]="data.leakageOverflowsUtilityStorageTanks"
                [percent]="data.leakageOverflowsUtilityStorageTanksPercentage"
                [selected]="selectedHash[kpiValues.LeakageOverflowsUtilityStorageTanks]"
                [dimension]="data.dimensionTypeId"
                [timeAggregation]="data.timeAggregationId"
                [hierarchyElementTypeId]="data.hierarchyElementTypeId"
                [unitFormat]="data.unitFormat"
                [currencyType]="data.currencyType"
                classI18nKey="kpi-16-leakage-and-overflows-at-utilitys-storage-tanks"
                (click)="onSelect(kpiValues.LeakageOverflowsUtilityStorageTanks)"
              ></wlm-water-balance-widget-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wb-column wb-fixed-column">
      <div class="wb-group">
        <wlm-water-balance-widget-item
          [value]="data.revenueWater"
          [percent]="data.revenueWaterPercentage"
          [selected]="selectedHash[kpiValues.RevenueWater]"
          [dimension]="data.dimensionTypeId"
          [timeAggregation]="data.timeAggregationId"
          [hierarchyElementTypeId]="data.hierarchyElementTypeId"
          [unitFormat]="data.unitFormat"
          [currencyType]="data.currencyType"
          classI18nKey="kpi-17-revenue-water"
          (click)="onSelect(kpiValues.RevenueWater)"
        ></wlm-water-balance-widget-item>
      </div>
      <div class="wb-group" style="flex: 4 1 0px">
        <wlm-water-balance-widget-item
          [value]="data.nonRevenueWater"
          [percent]="data.nonRevenueWaterPercentage"
          [selected]="selectedHash[kpiValues.NonRevenueWater]"
          [dimension]="data.dimensionTypeId"
          [timeAggregation]="data.timeAggregationId"
          [hierarchyElementTypeId]="data.hierarchyElementTypeId"
          [unitFormat]="data.unitFormat"
          [currencyType]="data.currencyType"
          classI18nKey="kpi-18-non-revenue-water"
          (click)="onSelect(kpiValues.NonRevenueWater)"
        ></wlm-water-balance-widget-item>
      </div>
    </div>
  </div>
</wlm-spinner-wrapper>
