import { Directive, Inject, Injector } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetSettingsToken } from '../../dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from '../../dynamic-layout/state/generic/generic.actions';
import { IPendingChangesChecker } from '../../shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '../../shared/pending-changes/services/pending-changes-manager.service';
import { StateWidgetSettings } from '../models/state-widget-settings';
import { BaseDynamicWidgetComponent } from './base-dynamic-widget.component';

@Directive()
export abstract class BaseSelectorDynamicWidgetComponent
  extends BaseDynamicWidgetComponent
  implements IPendingChangesChecker
{
  private _widgetInitialized$ = new ReplaySubject<void>(1);
  widgetInstanceKey: string;
  pageId: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    protected pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  abstract get selectedFieldName(): string;

  emitWidgetInit(): void {
    this._widgetInitialized$.next();
    this._widgetInitialized$.complete();
  }

  setSelected = (value): void => {
    this._widgetInitialized$.subscribe(() => {
      this._state.dispatch(
        new SetValueAction({
          fieldName: this.selectedFieldName,
          value,
        })
      );
    });
  };

  checkPendingChanges(key: string): Observable<boolean> {
    return this.pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }
}
