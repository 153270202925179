import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BasicFilter } from './basic-filter';
import { CompositeFilter } from './composite-filter';
import { IFilter } from './filter';

export class DataBindingFilters {
  private _filters: Map<string, IFilter>;

  exportableFilter: { [x: string]: IFilter };

  constructor() {
    this._filters = new Map<string, IFilter>();
    this.exportableFilter = {};
  }

  public reloadFromSerialization() {
    if (this.exportableFilter) {
      Object.keys(this.exportableFilter).forEach((columnName) => {
        this.filters.set(columnName, this.exportableFilter[columnName]);
      });
    }
  }
  public get filters(): Map<string, IFilter> {
    return this._filters;
  }
  public set filters(map: Map<string, IFilter>) {
    this._filters = map;
    if (this._filters) {
      this._filters.forEach((value, key) => (this.exportableFilter[key] = value));
    }
  }
  addOrUpdateBasicFilter(
    columnName: string,
    value: any,
    operator: string = 'eq',
    ignoreCase: boolean = false
  ) {
    this._filters.set(columnName, new BasicFilter(columnName, value, null, operator, ignoreCase));
    this.exportableFilter[columnName] = this.filters.get(columnName);
  }

  addOrUpdateFullBasicFilter(filter: BasicFilter): void {
    this.addOrUpdateBasicFilter(filter.fieldName, filter.value, filter.operator, filter.ignoreCase);
  }

  addOrUpdateCompositeFilter(columnName: string, compositeFilter: CompositeFilterDescriptor) {
    this._filters.set(columnName, new CompositeFilter(compositeFilter));
    this.exportableFilter[columnName] = this.filters.get(columnName);
  }

  mergeDataBindingFilter(newDataBindingFilter: DataBindingFilters){
    newDataBindingFilter.filters.forEach((value, key)=>{
      this._filters.set(key, value);
      this.exportableFilter[key]=value;
    });
  }
}
