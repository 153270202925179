import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { GridEditionService } from '../../services/grid-edition.service';

const COMPONENT_SELECTOR = 'wlm-uom-edit-template';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-edit-template.component.html',
  styleUrls: ['./uom-edit-template.component.scss'],
})
export class UomEditTemplateComponent implements OnInit {
  @ViewChild('editBox') editBox: MatInput;
  private _formGroup: UntypedFormGroup = new UntypedFormGroup({});
  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }
  @Input() public set formGroup(value: UntypedFormGroup) {
    if (value) {
      this.originalValue = value.get(this.field).value as number;
      this.valueChange.emit(this.originalValue);

      const convertedValue = this.getRoundedValue(this.originalValue * this.conversionFactor);

      value.get(this.field).setValue(convertedValue);
      this._formGroup = value;
    }
  }
  @Input() field: string;
  @Input() rowIdField: string;
  @Input() dataItem: any;
  @Input() conversionFactor: number;
  @Input() gridEditionService: GridEditionService;
  @Output() valueChange = new EventEmitter<any>();

  originalValue: any;
  disableUndoButton = false;

  private _editionDecimalPositions = 7;

  constructor() {}

  ngOnInit(): void {
    this.checkUndoState();
  }

  private checkUndoState() {
    const key = this.dataItem[this.rowIdField];
    const originalValue = this.gridEditionService.getOriginalValue(key, this.field, false);

    const currentValue = this.dataItem[this.field];

    this.disableUndoButton = originalValue === currentValue || originalValue == undefined;
  }

  onCancel($event) {
    const key = this.dataItem[this.rowIdField];
    const originalValue =
      this.gridEditionService.getOriginalValue(key, this.field, true) ?? this.originalValue;

    const convertedValue = originalValue * this.conversionFactor;

    this.formGroup.get(this.field).setValue(convertedValue);
    this.dataItem[this.field] = originalValue;
    this.disableUndoButton = true;

    this.valueChange.emit(originalValue);
    this.editBox.focus();
  }

  modelChanged(value) {
    let informedValue = this.originalValue;
    this.disableUndoButton = false;

    if (value == null || value == undefined) {
      const key = this.dataItem[this.rowIdField];
      informedValue =
        this.gridEditionService.getOriginalValue(key, this.field, true) ?? this.originalValue;

      this.dataItem[this.field] = informedValue;
    } else {
      informedValue = value / this.conversionFactor;
    }

    this.valueChange.emit(this.getRoundedValue(informedValue));
  }

  private getRoundedValue(value: number) {
    return Number(value.toFixed(this._editionDecimalPositions));
  }
}
