import { Injectable, Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseService } from '../../shared/base.service';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { TimeAggregationEnum } from '../../shared/model/algorithm/time-aggregation.enum';
import { UoMService } from '../../shared/uom/uom.service';
import { CVShortQueryDto } from '../shared/model/cv-short-query.dto';
import { CVChartSerieDto } from './models/cv-chart-serie.dto';
import { ICVShortDto } from './models/cv-short-view.dto';
import { CVThresholdDto } from './models/cv-threshold.dto';

@Injectable({ providedIn: 'root' })
export class CVService extends BaseService {
  private readonly _naUnitTypeId = 50;
  private readonly _algorithmsToConvert = [
    'MNF',
    'DAZNP',
    'DALCMNF',
    'MNFByB',
    'DAZNPALC',
    'MNFNLU',
    'DMNFNMIFDMA',
  ];

  constructor(
    injector: Injector,
    private _dateHelper: DateHelperService,
    private _uomService: UoMService,
    private _utilsHelperService: UtilsHelperService
  ) {
    super(injector);
  }

  public getShort(query: CVShortQueryDto): Observable<ICVShortDto[]> {
    const result$ = this.httpCacheClient.post<ICVShortDto[]>(`${this.apiUrl}/cv/short`, query);

    const updatedDate$ = result$.pipe(
      map((x) => {
        x.forEach((y) => (y.referenceDate = this.getDate(y)));
        return x;
      })
    );

    return updatedDate$;
  }

  public getShortConverted(query: CVShortQueryDto): Observable<ICVShortDto[]> {
    return this.getChart(query).pipe(
      switchMap((algorithmSeries) => {
        if (!algorithmSeries.length) {
          return of([]);
        }
        const algorithmsValuesConverted$: Observable<ICVShortDto[]>[] = [];

        algorithmSeries.forEach((algorithmSerie) => {
          const showInUTC = this.showInUTC(algorithmSerie);
          const algorithmValues$ = this._uomService
            .getByAlgorithmShortName(
              algorithmSerie.algorithmShortName,
              algorithmSerie.hierarchyElementTypeId
            )
            .pipe(
              take(1),
              map((conversion) => {
                const algorithmValues = algorithmSerie.points.map((value) => {
                  const date = this._dateHelper.fromApiFormat(value.referenceTimestamp, !showInUTC);

                  const cvshort: ICVShortDto = {
                    elementName: algorithmSerie.elementName,
                    elementDescription: algorithmSerie.elementDescription,
                    algorithmShortName: algorithmSerie.algorithmShortName,
                    hierarchyElementTypeId: algorithmSerie.hierarchyElementTypeId,
                    dimensionTypeDescription: algorithmSerie.dimensionTypeDescription,
                    edited: value.edited,
                    estimated: value.estimated,
                    referenceDate: date,
                    validity: value.validity,
                    value: +this._utilsHelperService.uomMultiply(
                      String(value.value),
                      String(conversion?.conversionFactor ?? 1)
                    ),
                    dimensionTypeId: algorithmSerie.dimensionTypeId,
                    unitTypeId: conversion?.unitTypeToId ?? this._naUnitTypeId,
                    elementId: algorithmSerie.elementId,
                    networkElementTypeId: algorithmSerie.networkElementTypeId,
                    referenceTimestamp: date.toString(),
                  };
                  return cvshort;
                });

                return algorithmValues;
              })
            );
          algorithmsValuesConverted$.push(algorithmValues$);
        });

        return forkJoin(algorithmsValuesConverted$).pipe(
          map((algorithmValuesConverted) => {
            return asEnumerable(algorithmValuesConverted)
              .SelectMany((x) => x)
              .ToArray();
          })
        );
      })
    );
  }

  public getChart(query: CVShortQueryDto): Observable<CVChartSerieDto[]> {
    const queryParams = {
      startDate: this._dateHelper.toApiFormat(query.startDate),
      endDate: this._dateHelper.toApiFormat(query.endDate),
      elementIds: query.elementIds,
      algorithms: query.algorithms,
    };
    const result$ = this.httpCacheClient.post<CVChartSerieDto[]>(
      `${this.apiUrl}/cv/chart`,
      queryParams
    );
    return result$;
  }

  public getThresholds = (
    alarmTypeId: string,
    startDate: string,
    endDate: string
  ): Observable<CVThresholdDto[]> => {
    const query = {
      alarmTypeId,
      startDate,
      endDate,
    };
    return this.httpCacheClient.post(`${this.apiUrl}/cv/threshold`, query);
  };

  private getDate(cv: ICVShortDto): Date {
    if (cv.timeAggregationId === TimeAggregationEnum.Daily) {
      cv.referenceTimestamp = `${cv.referenceTimestamp}Z`;
    }
    return new Date(cv.referenceTimestamp);
  }

  /**
   * Only for CV charts. Checks if dates must be shown in UTC.
   */
  public showInUTC(serie: CVChartSerieDto): boolean {
    return this.showAlgorithmInUTC(serie.timeAggregationId, serie.algorithmShortName);
  }

  public showAlgorithmInUTC(timeAggregationId: number, algorithmShortName: string) {
    const hasToShow =
      timeAggregationId !== TimeAggregationEnum.Base &&
      timeAggregationId !== TimeAggregationEnum.Hourly &&
      !this._algorithmsToConvert.includes(algorithmShortName);

    return hasToShow;
  }
}
