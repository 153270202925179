import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { FormModes } from 'src/app/common-modules/shared/model/shared/form-mode.enum';

export class NotificationPopupDto {
  notification?: NotificationDto;
  notificationId?: string;
  mode: FormModes;

  constructor(init?: Partial<NotificationPopupDto>) {
    Object.assign(this, init);
  }
}
