export class CVShortQueryDto {
  startDate!: Date;
  endDate!: Date;
  elementIds!: string[];
  algorithms!: string[];

  get isValid(): boolean {
    const empty =
      this.startDate == null ||
      this.endDate == null ||
      this.elementIds == null ||
      this.algorithms == null;

    if (empty) {
      return false;
    }

    if (this.endDate <= this.startDate) {
      return false;
    }

    return this.elementIds.length > 0 && this.algorithms.length > 0;
  }

  constructor(startDate: Date, endDate: Date, elementIds: string[], algorithms: string[]) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.elementIds = elementIds;
    this.algorithms = algorithms;
  }
}
