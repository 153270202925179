export const gChartGeoMainMapName = 'fullMap';

export class GChartGeo {
  map?: string = gChartGeoMainMapName;
  layoutSize?: string = '100%';
  geoIndex? = 0;

  constructor(init: GChartGeo) {
    Object.assign(this, init);
  }
}
