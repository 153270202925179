<div *ngFor="let error of errorList">
  <mat-error *ngIf="error === 'required'"
    >* {{ 'common.validation.required' | translate }}</mat-error
  >
  <mat-error *ngIf="error?.toString() === 'invalidRangePair'"
    >* {{ 'common.validation.end-date-minor' | translate }}</mat-error
  >
  <mat-error *ngIf="error === 'matDatetimePickerMin'"
    >*
    {{ 'common.validation.start-date-min' | translate : { min: minDate | date : 'dateTime' } }}
  </mat-error>
  <mat-error *ngIf="error === 'matDatetimePickerMax'"
    >*
    {{
      'common.validation.end-date-max' | translate : { max: maxDate | date : 'dateTime' }
    }}</mat-error
  >
</div>
