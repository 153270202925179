import { Component, Injector, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ValidationHelperService } from 'src/app/common-modules/shared/helpers/validation-helper.service';
import { IBillingClassDto } from '../../../shared/model/customer/billing-class.dto';
import { BillingClassFormComponent } from '../billing-class-form/billing-class-form.component';
import { BillingClassesCrudService } from '../billing-classes-crud.service';

const COMPONENT_SELECTOR = 'wlm-billing-class-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './billing-class-crud.component.html',
  styleUrls: ['./billing-class-crud.component.scss'],
})
export class BillingClassCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _billingClassesCrudService = inject(BillingClassesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'BillingClassesCrudService',
      grid: {
        gridSettingsName: 'BillingClassesCrud',
      },
      create: {
        formComponent: BillingClassFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: BillingClassFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: IBillingClassDto): Observable<IBillingClassDto | null> => {
      return this._billingClassesCrudService.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.duplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.billing-class-id`
            );
            return null;
          }
          return model;
        })
      );
    };
}
