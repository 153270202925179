<wlm-popup-wrapper
  [showCloseButton]="false"
  [showSpinner]="!configReady"
  [titleKey]="T_SCOPE + '.title'"
>
  <ng-container popup-content>
    <div class="main-container">
      <div class="radio-button-container">
        <mat-radio-group
          aria-label="Is Activer or Deleted"
          class="radio-position"
          [value]="toggleViewMode"
          (change)="onChangeView($event)"
        >
          <mat-radio-button [value]="activeZonesMode">{{
            T_SCOPE + '.active' | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="deletedZonesMode">{{
            T_SCOPE + '.deleted' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <wlm-hierarchy-elements-selection-grid
        [ngStyle]="{ display: toggleViewMode === activeZonesMode ? '' : 'none' }"
        class="form-control"
        [hierarchyElementFilterConfiguration]="heSelectionFilterConfig"
        [hideComponent]="false"
        [gridCssClass]="'none-shadow'"
        (selectedHeElementsChange)="onHierarchyElementsChanged($event)"
      >
      </wlm-hierarchy-elements-selection-grid>

      <wlm-deleted-zones-grid
        [ngStyle]="{ display: toggleViewMode === deletedZonesMode ? '' : 'none' }"
        class="form-control"
        [persistencyKey]="componentName"
        [filterCssClass]="filterCssClass"
        (selectedElementsChange)="onDeletedZonesChanged($event)"
      >
      </wlm-deleted-zones-grid>
    </div>
  </ng-container>
  <ng-container popup-actions>
    <div class="action-buttons-container">
      <div class="cancel-button-container">
        <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
      </div>
      <button
        mat-button
        class="wtr-action-button-primary btn-size"
        [disabled]="!selectedElements?.length && !selectedDeletedElements?.length"
        (click)="add()"
      >
        {{ 'common.add' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
