import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
// prettier-ignore

import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { Images } from 'src/assets/icons/previous-icons/images';
import { ActivityOperationService } from '../../activity-operation.service';

const COMPONENT_SELECTOR = 'wlm-operations';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-operation-grid.component.html',
  styleUrls: ['./activity-operation-grid.component.scss'],
})
export class ActivityOperationGridComponent
  extends BaseWidgetComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;
  private _workOrderId = '';
  public get workOrderId(): string {
    return this._workOrderId;
  }
  @Input()
  public set workOrderId(v: string) {
    this._workOrderId = v;
    this.init();
  }
  public clearFiltersIcon: string = Images.IconClearFilter;
  public filters: DataBindingFilters;
  public filter: CompositeFilterDescriptor;
  gridSettings: IGridSettings;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    public readonly operationService: ActivityOperationService,
    private readonly _gridService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.endLoading();
    this._gridService.getGridSettingsByName('Operations').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
    this.init();
  }
  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.workOrderId = parameters.parameters.get(TabDetailParameterName.workOrderId);
  }
  init(): void {
    this.setDataBindingFilter();
  }

  // private setColumnsStyle() {
  //   // this.columnStyle.set('max-width', ColumnStyles.MaxWidth);
  //   this.columnStyle.set('border-style', 'solid');
  //   this.columnStyle.set('border-width', '0 1px 1px 0');
  // }

  private setDataBindingFilter() {
    if (!this.workOrderId) return;
    const auxFilter = new DataBindingFilters();
    auxFilter.addOrUpdateBasicFilter(TabDetailParameterName.workOrderId, this.workOrderId);
    this.filters = auxFilter;
  }
  ngAfterViewInit(): void {}

  showGridHeaderTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TH' ||
        (element.nodeName === 'SPAN' && element.offsetParent.nodeName === 'TH')) &&
      !element.firstElementChild?.classList?.contains('k-checkbox')
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  get componentName() {
    return 'ActivityOperationGridComponent';
  }
}
