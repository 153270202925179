export class NodeFormDefinition {
  titleKey: string;
  gisIdFieldName: string;
  nameFieldName: string;
  latitudeFieldName: string;
  longitudeFieldName: string;
  networkElementFieldName: string;

  constructor(init: Partial<NodeFormDefinition>) {
    Object.assign(this, init);
  }
}
