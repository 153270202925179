// prettier-ignore
import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IconLoaderService } from '../icon-loader.service';
import { StylesService } from '../styles/styles.service';
import { WtrColors } from '../styles/wtr-colors';

export type WtrIconSize = 'md' | 'sm' | 'xs' | 'lg';

const COMPONENT_SELECTOR = 'wlm-icon';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  host: {
    class: 'wtr-icon',
  },
})
export class IconComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() color: WtrColors = WtrColors.NeutralColorBlack2;
  @Input() size: WtrIconSize;

  @HostBinding('class.wtr-icon-md-size') get iconMediumSize() {
    return this.size === 'md';
  }
  @HostBinding('class.wtr-icon-sm-size') get iconSmallSize() {
    return this.size === 'sm';
  }
  @HostBinding('class.wtr-icon-xs-size') get iconExtraSmallSize() {
    return this.size === 'xs';
  }
  @HostBinding('class.wtr-icon-lg-size') get iconLargeSize() {
    return this.size === 'lg';
  }

  iconName: string;
  svgIcon: SVGElement;
  colorClass: string;

  constructor(
    private _iconLoaderService: IconLoaderService,
    private _stylesService: StylesService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.loadIcon(changes.icon.currentValue);
    }
    if (changes.color) {
      this.loadColor(changes.color.currentValue);
    }
  }

  private loadIcon(iconName: string): void {
    this._iconLoaderService.getNamedSvgIcon(iconName).subscribe({
      next: (svgIcon) => {
        this.iconName = iconName;
        this.svgIcon = svgIcon;
      },
      error: (_) => {
        this.iconName = iconName;
        this.svgIcon = null;
      },
    });
  }

  private loadColor(color: WtrColors): void {
    this.colorClass = this._stylesService.getColorClass(color);
  }
}
