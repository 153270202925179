import { Injectable, inject } from '@angular/core';
import { DialogService } from '../dialogs/dialogs.service';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { WlmDialogSettings } from '../model/dialog/wlm-dialog-setting';

@Injectable({
  providedIn: 'root',
})
export class ValidationHelperService {
  private readonly _localization = inject(LocalizationHelperService);
  private readonly _dialogService = inject(DialogService);

  onlyPositiveNumber = (value: any) => {
    if (!value) {
      return value;
    }
    const strValue = value.toString();
    const result = strValue ? strValue.replace(/[^0-9\.]*/g, '') : strValue;
    return result;
  };

  onlyNumeric = (value: any) => {
    if (!value) {
      return value;
    }
    const strValue = value.toString();
    const result = strValue ? strValue.replace(/[^0-9\.\-]*/g, '') : strValue;
    return result;
  };

  onlyNegativeNumber = (value: any) => {
    if (!value || value.startsWith('-')) {
      return value;
    }
    const numValue = +value;
    const result = numValue < 0 ? value : null;
    return result;
  };

  noZero = (value: any) => {
    if (!value) {
      return value;
    }
    const numValue = +value;
    const result = numValue === 0 ? null : value;
    return result;
  };

  onlyInteger = (value: any) => {
    if (!value) {
      return value;
    }
    let strValue = value.toString();
    strValue = this.limitMinusSign(strValue);

    let isNegative = false;
    if (strValue.startsWith('-')) {
      isNegative = true;
    }
    let newValue = this.onlyPositiveNumber(strValue);
    if (isNegative) {
      newValue = '-' + newValue;
    }
    return newValue;
  };

  maxDigits = (max: number) => {
    return (value: any) => {
      if (!value) {
        return value;
      }
      let strValue = value.toString();
      strValue = this.limitMinusSign(strValue);

      let isNegative = false;
      if (strValue.startsWith('-')) {
        isNegative = true;
      }
      const unsignedValue = strValue.replace(/-/g, '');

      const trimmedValue =
        unsignedValue.length <= max ? unsignedValue : unsignedValue.substring(0, max);
      const result = isNegative ? '-' + trimmedValue : trimmedValue;
      return result;
    };
  };

  showNotUniqueError(propertyKey: string): void {
    this._localization.get(propertyKey).subscribe((propertyName) => {
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({
          icon: 'error',
          translateKey: `common.validation.not-unique`,
          params: { field: propertyName },
        })
      );
    });
  }

  /**
   * Ensure only one minus sign is available
   */
  private limitMinusSign(value: string) {
    return value.replace(/--/g, '-');
  }
}
