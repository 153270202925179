import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateAreas } from '../../redux/models/state-areas';
import { AuthenticationService } from '../../shared/auth/services/authentication.service';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { DynamicLayoutItemSettings } from '../models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { SimpleDynamicLayoutSettings } from './simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from './simple-dynamic-layout-settings-item';

export class DynamicLayoutBuilderOptions {
  generateWidgetIds?: boolean;
}

@Injectable()
export class DynamicLayoutBuilderService {
  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService
  ) {}

  /**
   * Builds main dynamic layout settings from a simplified version.
   * Does not include structure generation, because that would need knowledge of the actual widgets.
   */
  build(
    simpleSettings: SimpleDynamicLayoutSettings,
    builderOptions?: DynamicLayoutBuilderOptions
  ): Observable<DynamicLayoutSettings> {
    return this.buildItems(simpleSettings, builderOptions).pipe(
      map((items) => {
        const settings = new DynamicLayoutSettings({
          layoutArea: simpleSettings.layoutArea,
          layoutKey: simpleSettings.layoutKey,
          widgetModule: simpleSettings.layoutArea,
          widgetPage: simpleSettings.layoutKey,
          currentUser: simpleSettings.currentUser ?? this._authenticationService.userCode,
          structure: [],
          defaultStructure: [],
          items,
        });
        return settings;
      })
    );
  }

  /**
   * Use the context of current settings to map a single item, which may not be part of simpleSettings.items.
   */
  buildExternalItem(
    simpleSettings: SimpleDynamicLayoutSettings,
    item: SimpleDynamicLayoutSettingsItem,
    builderOptions?: DynamicLayoutBuilderOptions
  ): Observable<DynamicLayoutItemSettings> {
    const simpleSettingsCopy = globalUtilsHelper.clone(simpleSettings, true);
    simpleSettingsCopy.items = [item];
    return this.buildItems(simpleSettingsCopy, builderOptions).pipe(
      map((items) => {
        return items[0];
      })
    );
  }

  private buildItems(
    settings: SimpleDynamicLayoutSettings,
    builderOptions?: DynamicLayoutBuilderOptions
  ): Observable<DynamicLayoutItemSettings[]> {
    const items$: Observable<DynamicLayoutItemSettings>[] = settings.items.map((simpleItem) => {
      const title$ = simpleItem.titleKey ? this._localization.get(simpleItem.titleKey) : of(null);
      return title$.pipe(
        map((title) => {
          const item: DynamicLayoutItemSettings = {
            componentName: simpleItem.widgetName,
            widgetInstanceKey: this.getWidgetInstanceKey(simpleItem, builderOptions),
            scopeInstanceKeys: settings.scopeInstanceKeys ?? {
              [StateAreas.Generic]: 'shared',
            },
            params: simpleItem.params ?? {},
            title: simpleItem.title ?? title ?? null,
          };
          return item;
        })
      );
    });

    if (items$.length) {
      return forkJoin(items$);
    } else {
      return of([]);
    }
  }

  private getWidgetInstanceKey(
    item: SimpleDynamicLayoutSettingsItem,
    builderOptions?: DynamicLayoutBuilderOptions
  ): string {
    const widgetInstanceKey =
      item.id ??
      (builderOptions?.generateWidgetIds ? globalUtilsHelper.generateGuid() : item.widgetName);
    return widgetInstanceKey;
  }
}
