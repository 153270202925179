import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { HierarchyElementUnitsQueryParameters } from '../../shared/model/uom/hierarchy-element-type-time-units-query.dto';
import { UnitTypeConversionViewDto } from '../../shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '../../shared/uom/uom.service';

import { GridColumnHelperService } from '../../shared/helpers/grid-column-helper.service';
import { ColumnTitlePipe } from '../pipes/column-title.pipe';
import { UomColumnComponent } from '../uom-column-component/uom-column.component';

const COMPONENT_SELECTOR = 'wlm-uom-column-helper';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-column-helper.component.html',
  styleUrls: ['./uom-column-helper.component.scss'],
})
export class UomColumnHelperComponent implements OnInit {
  private _dataItem;
  public get dataItem() {
    return this._dataItem;
  }
  @Input() public set dataItem(value) {
    this._dataItem = value;
    this._processData();
  }
  @Input() hierarchyElementTypeId: string;
  @Input() hierarchyElementTypeIdField: string;
  @Input() baseTitle?: string;
  @Input() unitFormat?: string;
  @Input() timeAggregationId?: number;
  @Input() dimensionTypeId?: number;
  @Input() decimalPositions?: number;
  @Output() conversionFactorChange = new EventEmitter<number>();
  @Input() comparisonField: string;
  @Input() comparisonIsActive: boolean;

  @Output() uomUnitCalculated = new EventEmitter<string>();

  messageMissingHEType = 'Missing hierarchyElementTypeId';
  messageErrorUomService = 'Error accessing uom service';
  columnUnitLabel: string;

  dataProcessed = false;
  // Processed fields
  convertedValue$: Observable<string>;
  justifyContentStyle: string;
  backgroundStyle: string;
  fontWeigthStyle$: Observable<string>;
  tooltip: string;
  field: string;
  titlePipe: PipeTransform;

  hideComparison = true;
  showHistorical = false;
  currentTitle: string;

  constructor(
    private uomService: UoMService,
    private localization: LocalizationHelperService,
    private column: UomColumnComponent,
    private _utilsHelperService: UtilsHelperService,
    private _gridColumnHelper: GridColumnHelperService,
    private _cd: ChangeDetectorRef
  ) {
    this.field = this.column.field;
    this.hideComparison = this.column.columnSettings.hideComparison;
    this.showHistorical = this.column.gridSettings.showHistoricalComparison;
    this.titlePipe = new ColumnTitlePipe(this._gridColumnHelper);
  }

  ngOnInit(): void { }

  private _processData(): void {
    this.convertedValue$ = this._getConvertedValue(this.dataItem);
    this.justifyContentStyle = this._getStyleValue(this.column.style, 'justify-content');
    this.backgroundStyle = this.column.getBackgroundColorMapping(this.dataItem);
    this.fontWeigthStyle$ = this.calculateFontWeight(this.dataItem);
    this.convertedValue$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.tooltip = this._buildTooltip(value);
      this.dataProcessed = true;
    });
  }

  private calculateFontWeight(dataItem: any): Observable<string> {
    if (this.column?.gridEditionService && this.column.gridSettings?.selectByFieldName) {
      const rowId = dataItem[this.column.gridSettings.selectByFieldName];

      const isEdited = this.column?.gridEditionService?.checkCellIsEdited(rowId, this.column.field);

      return isEdited ? of('bold') : of('normal');
    }

    return this.column.getFontWeight(this.dataItem);
  }

  private _getConvertedValue(data): Observable<string> {
    const value = data[this.column.field] as number;
    if (value === undefined || value === null) {
      return of(undefined);
    }

    const heTypeId = this.setAndGetHierarchyElementTypeId(data);

    if (!heTypeId) {
      this.getDisplayValue(this.messageMissingHEType);
    }

    const queryParams = this.getHierarchyUnitQueryParameters();

    if (!queryParams || !this.uomService) {
      this.getDisplayValue(this.messageErrorUomService);
    }

    return this.getConversionFactor(queryParams, value);
  }

  getDisplayValue(value: string): Observable<string> {
    return of(value?.toString());
  }

  private setAndGetHierarchyElementTypeId(data: any) {
    if (
      this.hierarchyElementTypeId === undefined ||
      (this.hierarchyElementTypeIdField &&
        this.hierarchyElementTypeId !== data[this.hierarchyElementTypeIdField])
    ) {
      this.hierarchyElementTypeId = data[this.hierarchyElementTypeIdField];
    }
    return this.hierarchyElementTypeId;
  }

  private getConversionFactor(
    query: HierarchyElementUnitsQueryParameters,
    value: number
  ): Observable<string> {
    return this.uomService.getByHEUnit(query).pipe(
      map((conversion) => {
        if (value === undefined || conversion === undefined || conversion == null) {
          return '';
        }

        this.setColumnHeaderLabel(conversion);
        this.column.currentConversionFactor = conversion.conversionFactor;
        this.conversionFactorChange.emit(conversion.conversionFactor);

        return this._utilsHelperService.uomMultiply(
          String(value ?? 0),
          String(conversion?.conversionFactor ?? 1),
          null,
          12
        );
      })
    );
  }

  private setColumnHeaderLabel(conversion: UnitTypeConversionViewDto) {
    if (!conversion?.unitTypeToDescription || !this.baseTitle) {
      return;
    }
    this.columnUnitLabel =
      conversion === undefined
        ? ''
        : this.unitFormat
          ? ` ${this.unitFormat.replace('{0}', conversion.unitTypeToDescription)}`
          : ` [${conversion.unitTypeToDescription}]`;
    this.refreshColumnHeaderLabel();
  }

  private getHierarchyUnitQueryParameters(): HierarchyElementUnitsQueryParameters {
    return {
      hierarchyElementTypeId: this.hierarchyElementTypeId,
      timeAggregationId: this.timeAggregationId,
      dimensionTypeId: this.dimensionTypeId,
    };
  }

  refreshColumnHeaderLabel() {
    this.titlePipe.transform(this.column.columnSettings).subscribe({
      next: (processedTitle) => {
        const newHeader = processedTitle + this.columnUnitLabel;
        if (this.currentTitle !== newHeader) {
          this.currentTitle = newHeader;
          this.column.title = this.currentTitle;
          this.uomUnitCalculated.emit(this.columnUnitLabel);
          this.column.headerTextChanged.emit();
        }
      }
    })
  }

  private _getStyleValue(style: any, key: string): string {
    if (style === undefined || key === undefined) {
      return;
    }
    return (style as Map<string, string>).get(key);
  }

  private _buildTooltip(value: string): string {
    if (!value) {
      return '';
    }
    return `${this.localization.formatNumber(+value, '1.0-20')} ${this.columnUnitLabel}`;
  }
}
