<wlm-ne-configuration-historical-switcher
  [selectedNE]="currentNE"
  class="switcher"
  [widgetId]="widgetId"
  [pageId]="pageId"
>
  <ng-template #currentTemplate>
    <wlm-ne-configuration-boundary-no-boundary
      #controlledComponent
      [isHierarchyElement]="isHierarchyElement"
      [selectedNE]="currentNE"
      class="historical-component"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [noBoundarySettings]="boundarySettings"
    >
    </wlm-ne-configuration-boundary-no-boundary>
  </ng-template>
  <ng-template #historicalTemplate>
    <wlm-ne-configuration-boundary-historical
      #controlledComponent
      [selectedNE]="currentNE"
      class="historical-component"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [isReadOnly]="false"
    >
    </wlm-ne-configuration-boundary-historical>
  </ng-template>
</wlm-ne-configuration-historical-switcher>
