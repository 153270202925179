import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BaseFormComponent } from 'src/app/common-modules/shared/component/base-form.component';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { UsersGridODataService } from 'src/app/common-modules/shared/services/users/users.service';
import { CommentThreadComponent } from 'src/app/water-loss/features/comments/components/comment-thread/comment-thread.component';
import { CommentDto } from 'src/app/water-loss/features/comments/models/comment.dto';
import { EditorMention } from 'src/app/water-loss/features/comments/models/editor-mention';
import { CommentsService } from '../../../../comments/services/comments.service';
import { CommentCreateDto } from '../../models/comment-create.dto';
import { NotificationDetailFormResult } from '../../models/notification-detail-form-result';
import { NotificationDetailCommentDto } from '../../models/notification-details-comment.dto';
import { NotificationDiscussionLinkDto } from '../../models/notification-discussion-link.dto';
import { NotificationService } from '../../services/notification.service';

const COMPONENT_SELECTOR = 'wlm-notification-details-form';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-details-form.component.html',
  styleUrls: ['./notification-details-form.component.scss'],
})
export class NotificationDetailsFormComponent
  extends BaseFormComponent<NotificationDetailCommentDto, NotificationDetailFormResult>
  implements OnInit
{
  @ViewChild(CommentThreadComponent) commentThreadComponent: CommentThreadComponent;

  @Input() discussionId: string;

  private _notification: NotificationDto;
  public get notification(): NotificationDto {
    return this._notification;
  }
  @Input() public set notification(value: NotificationDto) {
    this._notification = value;
    if (this.notification) {
      this.model = new NotificationDetailCommentDto({
        notificationDescription: this.notification.notificationDescription,
      });
    }
  }

  usersToMention: EditorMention[];
  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;
  canEditDescription = false;

  private taggedUsers: string[];

  constructor(
    private _fb: UntypedFormBuilder,
    private _usersService: UsersGridODataService,
    private _commentsService: CommentsService,
    private _notificationsService: NotificationService,
    private _dialogService: DialogService,
    private _authService: AuthorizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this._authService.canAccess('WLMNotificationsCrud', 'u').subscribe((canEdit) => {
      this.canEditDescription = canEdit;
      this.initForm();
    });
  }

  protected createForm(): void {
    const V = Validators;

    this.form = this._fb.group({
      notificationDescription: [
        { value: '', disabled: !this.canEditDescription },
        [V.required, V.maxLength(1000)],
      ],
      commentText: [''],
    });
  }

  protected loadDependencies(): Observable<void> {
    return forkJoin([this._usersService.getUsersSelectOptions()]).pipe(
      tap(([users]) => {
        this.usersToMention = users.map(
          (u) =>
            new EditorMention({
              id: u.value,
              value: u.label,
              label: u.label,
            })
        );
      }),
      map((_) => null)
    );
  }

  protected preprocessModel(model: NotificationDetailCommentDto): NotificationDetailCommentDto {
    return model;
  }

  protected assignModelToForm(): Observable<void> {
    this.form.patchValue(this.model);
    this.form.get('commentText').reset();
    return of();
  }

  protected checkValid(): Observable<boolean> {
    return of(this.form.valid);
  }

  protected beforeSubmit(): Observable<NotificationDetailCommentDto> {
    return of(this.form.getRawValue());
  }

  protected submitFn(
    formData: NotificationDetailCommentDto
  ): Observable<NotificationDetailFormResult> {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const requests: {
      notificationToUpdate: Observable<NotificationDto>;
      commentToCreate: Observable<CommentDto | null>;
    } = {
      notificationToUpdate: of(this.notification),
      commentToCreate: of(null),
    };

    if (
      formData.notificationDescription &&
      formData.notificationDescription !== this.notification.notificationDescription
    ) {
      const notificationToUpdate = {
        ...this.notification,
        notificationDescription: formData.notificationDescription,
      };
      requests['notificationToUpdate'] = this._notificationsService.update(notificationToUpdate);
    }

    if (formData.commentText) {
      const commentToCreate = new CommentCreateDto({
        discussionId: this.discussionId,
        commentDescription: formData.commentText,
      });
      requests['commentToCreate'] = this._commentsService.create(commentToCreate);
    }

    return forkJoin(requests).pipe(
      map(({ notificationToUpdate, commentToCreate }) => {
        return new NotificationDetailFormResult({
          notification: notificationToUpdate,
          comment: commentToCreate,
        });
      }),
      switchMap((results: NotificationDetailFormResult) => {
        let link$: Observable<any> = of(results);
        if (results.comment?.discussionId) {
          link$ = this._notificationsService.linkComment(
            new NotificationDiscussionLinkDto({
              discussionId: results.comment.discussionId,
              notificationId: results.notification.notificationId,
              taggedUsers: this.taggedUsers,
            })
          );
        }
        // We want to link the entities but then return the formResults.
        return link$.pipe(map(() => results));
      }),
      tap((_) => {
        this.loading = false;
      })
    );
  }

  protected afterSubmit(result: NotificationDetailFormResult): Observable<void> {
    this._dialogService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
    this.form.get('commentText').reset();
    // This triggers comments load.
    if (result.comment?.discussionId) {
      this.discussionId = result.comment.discussionId;
    }
    this.commentThreadComponent?.refreshComments();
    this.submitResult.emit();

    return of();
  }

  onTaggedUsers(userCodes: string[]): void {
    this.taggedUsers = userCodes;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
