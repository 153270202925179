import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignalInfoDto } from 'src/app/common-modules/dependencies/shared/model/signal-info.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { UtilsHelperService } from 'src/app/common-modules/shared/helpers/utils-helper.service';
import { CanSaveHistoricalForm } from '../../../shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '../../../shared/historical/historical-form-settings';
import { BoundarySignalVersionDto } from '../../../shared/model/signals/boundary-signal-version.dto';
import { BoundarySignalSelectionPopupResponse } from './boundary-signal-selection-popup-response';
import { BoundarySignalSelectionPopupSettings } from './boundary-signal-selection-popup-settings';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-boundary-historical-creation-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-boundary-historical-creation-popup.component.html',
  styleUrls: ['./ne-configuration-boundary-historical-creation-popup.component.scss'],
})
export class NeConfigurationBoundaryHistoricalCreationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isLoading = false;
  canSave = true;

  popupSettings: BoundarySignalSelectionPopupSettings;
  formSettings: HistoricalFormSettings = {};
  formModel: BoundarySignalVersionDto;
  initialFormModel: BoundarySignalVersionDto;

  configuredSignals: SignalInfoDto[] = [];

  constructor(
    private dialogRef: MatDialogRef<NeConfigurationBoundaryHistoricalCreationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupSettings: BoundarySignalSelectionPopupSettings,
    private _utilsService: UtilsHelperService
  ) {
    this.popupSettings = popupSettings;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.buildFormSettings();
  }

  close() {
    this.dialogRef.close();
  }

  saveConfiguration() {
    const configurations: BoundarySignalVersionDto[] = [];
    this.configuredSignals.forEach((signal) => {
      const configuration = new BoundarySignalVersionDto({
        signalId: signal.signalId,
        pointDescription: signal.pointDescription,
        pointId: signal.pointId,
        validFrom: this.formModel.validFrom,
        validTo: this.formModel.validTo,
        hierarchyElementId: this.formModel.hierarchyElementId,
        hierarchyElementTypeId: this.formModel.hierarchyElementTypeId,
        isIn: this.formModel.isIn,
        id: this._utilsService.generateGuid(),
      });

      configurations.push(configuration);
    });

    const popupResponse = new BoundarySignalSelectionPopupResponse({ configurations });
    this.dialogRef.close(popupResponse);
  }

  onLoadingChanged(isLoading) {
    this.isLoading = isLoading;
  }

  onSignalsChange(signals: SignalInfoDto[]) {
    this.configuredSignals = signals;
  }

  onCanSaveChanged(data: CanSaveHistoricalForm) {
    this.canSave = data.isValid;
  }

  onIsValidChanged(isValid: boolean) {
    this.canSave = isValid;
  }

  onEdit(editedModel: BoundarySignalVersionDto): void {
    this.formModel = new BoundarySignalVersionDto(editedModel);
  }

  private buildFormSettings(): void {
    this.formSettings = new HistoricalFormSettings({
      minDate: this.popupSettings.minDate,
      maxDate: this.popupSettings.maxDate,
      editOnlyDates: false,
      emitAtFormInitialization: true,
    });

    this.initialFormModel = new BoundarySignalVersionDto({
      validFrom: this.popupSettings.minDate,
      validTo: this.popupSettings.maxDate,
      isIn: true,
      signalId: null,
      pointId: null,
      pointDescription: null,
      id: null,
      hierarchyElementId: null,
      hierarchyElementTypeId: null
    });
  }
}
