<wlm-spinner-wrapper [showSpinner]="loading">
  <form
    novalidate
    [formGroup]="form"
    *ngIf="form"
    class="wtr-fx-flex-grow fix-field-label-top-overlap fixed-subscript"
  >
    <wlm-collapsible-container [titleKey]="T_SCOPE + '.labels.description'">
      <mat-form-field appearance="outline" class="text-area-field">
        <mat-label>{{ T_SCOPE + '.labels.description' | translate }}</mat-label>
        <textarea
          class="text-area"
          matInput
          rows="5"
          formControlName="notificationDescription"
        ></textarea>
        <mat-error>
          <wlm-validation-messages
            [control]="form.controls.notificationDescription"
          ></wlm-validation-messages>
        </mat-error>
      </mat-form-field>
    </wlm-collapsible-container>
    <wlm-comment-thread
      *hasPerm="['WLMDiscussionsCrud', 'r']"
      [discussionId]="discussionId"
      [usersToMention]="usersToMention"
      (taggedUsers)="onTaggedUsers($event)"
      formControlName="commentText"
    ></wlm-comment-thread>
  </form>
</wlm-spinner-wrapper>
