import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { ActivityCategoryTypesFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/activity-category-types-filter';
import { ActivityStatusFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/activity-status-filter';
import { HierarchyElementPathFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import {
  FlagReasonPopupDimensions,
  UnflagReasonPopupDimensions,
} from 'src/app/common-modules/shared/constants/dimensions.constants';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-disable';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { ICanLeavePage } from 'src/app/common-modules/shared/navigation/can-component-deactivate';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { PeriodTypesEnum } from 'src/app/common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from 'src/app/common-modules/wlm-charts/core/models/time-selector-settings';
import { GridPersistedElements } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { LocalGridComponent } from 'src/app/common-modules/wlm-grid/local-grid/local-grid.component';
import { CellEditedInfoDto } from 'src/app/common-modules/wlm-grid/model/cell-edited-info.dto';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { INeHeFilterSettings } from '../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { AlcLeaksActivitiesPanelComponent } from '../../alc/alc-leaks-page/alc-leaks-activities-panel/alc-leaks-activities-panel.component';
import { NotificationDetailsByIdComponent } from '../../monitoring/notification/notification-components/notification-details-by-id/notification-details-by-id.component';
import { CurrentCalculatedValuesViewDto } from '../../shared/model/leakage-reporting/current-cv-view.dto';
import { CVRecalculationJobStateResponseDto } from '../../shared/model/leakage-reporting/flag-system-response.dto';
import { LeakageReportingShortNamesEnum } from '../../shared/model/leakage-reporting/leakage-reporting-shortnames.enum';

import { WlmElementExtended } from 'src/app/common-modules/shared/charts/model/elements/element-extended';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesDialogSettings } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-dialog-settings';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from 'src/app/common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { TimeSelectorChartWidgetComponent } from 'src/app/common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { FlagReasonPopupComponent } from '../leakage-reporting-components/flag-reason-popup/flag-reason-popup.component';
import { LeakageReportingFilterConfiguration } from '../leakage-reporting-components/leakage-reporting-filter/leakage-reporting-filter-configuration';
import { SaveEditedValuesDto } from '../leakage-reporting-components/save-edited-values.dto';
import { UnflagReasonPopupComponent } from '../leakage-reporting-components/unflag-reason-popup/unflag-reason-popup.component';
import { LeakageReportingService } from '../leakage-reporting.service';

const COMPONENT_SELECTOR = 'wlm-leakage-reporting-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './leakage-reporting-page.component.html',
  styleUrls: ['./leakage-reporting-page.component.scss'],
})
export class LeakageReportingPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy, ICanLeavePage
{
  @ViewChild(LocalGridComponent) public leakageReportingGrid: LocalGridComponent;

  public T_SCOPE = `${AppModules.LeakageReporting}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMLeakageReportingCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  get componentName(): string {
    return 'LeakageReportingPageComponent';
  }

  pageId: string = 'LeakageReportingPage';
  filters: Map<string, any>;
  gridFilters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: GridSetting;
  gridName = 'LeakageReporting';
  canLoad: boolean;
  isFirstLoad = true;
  currentCVs: CurrentCalculatedValuesViewDto[];

  leakageReportingCVs$: Subscription;
  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  removeSelection$ = new ReplaySubject<boolean>();
  gridBtnsDisable$ = new ReplaySubject<GridBtnsDisable>();
  private _hasEditionChanges: boolean;
  public get hasEditionChanges(): boolean {
    return this._hasEditionChanges;
  }
  public set hasEditionChanges(value: boolean) {
    this._hasEditionChanges = value;
    this.disablePageElements(value);
  }
  undoEditedValues$ = new ReplaySubject<boolean>();
  clearPendingEdition$ = new ReplaySubject<boolean>();
  leakageReportingFilterConfig: LeakageReportingFilterConfiguration;
  selectedRows: CurrentCalculatedValuesViewDto[];

  disableElements = false;
  showUndoSuccessMessage = false;
  readonly entityPermission = 'WLMLeakageReportingCrud';

  private _selectedRow: CurrentCalculatedValuesViewDto;
  public get selectedRow(): CurrentCalculatedValuesViewDto {
    return this._selectedRow;
  }
  public set selectedRow(value: CurrentCalculatedValuesViewDto) {
    const customDialog: PendingChangesDialogSettings = {
      dialogSettings: new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.confirm-pending-right-panel`,
        icon: 'warning',
      }),
      dialogFn: (dialogRef: WLMDialogResult, customDialog) =>
        this._pendingChangesService
          .applyPendingChangesByDefault(this.pageId, dialogRef.result)
          .pipe(map((result) => result[0])),
    };

    this.checkPendingChanges(this.pageId, customDialog).subscribe((result) => {
      if (!result) {
        return;
      }
      this._selectedRow = value;
      this.sendParameters(this.selectedRow);
    });
  }

  private get persistedFamilyId(): string {
    const persistedFamily = this.getPersisted(this._hierarchyElementFamilyFieldName, undefined);
    return (persistedFamily as BasicFilter)?.value?.shift()?.value;
  }

  private get persistedHETypeIds(): string[] {
    const heTypesPersisted = this.getPersisted(this._hierarchyElementTypeFieldName, undefined);
    return (heTypesPersisted as BasicFilter)?.value?.map((x) => x.value);
  }

  private get persistedHEIds(): string[] {
    return (
      this.getPersisted(this._hierarchyElementIdFieldName, [
        { value: '', label: '' },
      ]) as BasicFilter
    ).value;
  }

  private _editionChanges: Map<any, Map<string, CellEditedInfoDto>> = new Map<
    any,
    Map<string, CellEditedInfoDto>
  >();

  private readonly _columnAlgorithm: Map<string, LeakageReportingShortNamesEnum> = new Map<
    string,
    LeakageReportingShortNamesEnum
  >([
    ['ddiR_V', LeakageReportingShortNamesEnum.DDIR],
    ['dlccmnffhR_V', LeakageReportingShortNamesEnum.DLCCMNFFHR],
    ['mnffhR_V', LeakageReportingShortNamesEnum.MNFFHR],
    ['dalccR_V', LeakageReportingShortNamesEnum.DALCCR],
  ]);

  private _hierarchyElementIdFieldName = 'hierarchyElementId';
  private _hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  private _hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  private _startDateFieldName = 'startDate';
  private _endDateFieldName = 'endDate';
  private _activitiesStartDateFieldName = 'startDate';
  private _activitiesEndDateFieldName = 'endDate';

  private _repairFieldName = 'IsRepairActivity';
  private _detectionFieldName = 'IsDetectionActivity';
  private _statusFieldName = 'ilpmsStatus';
  private _chartWorkspaceName = 'LeakageReportingDetailsChart';

  private _defaultFamilyId: string;
  private _defaultHETypeIds = ['efd92639-f72a-464a-aaba-53e78e0ba129'];

  private _daysInAWeek = 7;
  private _dateRangeDefaultDaysOffset = 31;

  private _saveEditionSuccessMsg = `${this.T_SCOPE}.messages.save-edition-success`;
  private _saveEditionErrorMsg = `${this.T_SCOPE}.messages.save-edition-error`;
  private _undoEditionSuccessMsg = `${this.T_SCOPE}.messages.undo-edition-success`;
  private _algRecalcSuccessMsg = `${this.T_SCOPE}.messages.algorithm-success`;
  private _algRecalcErrorMsg = `${this.T_SCOPE}.messages.algorithm-error`;

  constructor(
    private _globalsService: GlobalsService,
    private _dateHelperService: DateHelperService,
    private _flaggingReasonDialog: MatDialog,
    private _cacheService: HttpCacheService,
    private _gridSettingsService: GridSettingsService,
    private _authService: AuthorizeService,
    private _localizationHelperService: LocalizationHelperService,
    private _leakageReportingService: LeakageReportingService,
    private _objectHelperService: ObjectHelperService,
    private _spinnerService: SpinnerService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadState();
    this.loadGridSettings();
    this.initializeRightPanelComponents();
  }

  loadState() {
    this.clearPendingEdition$.next(true);
    this._globalsService.getDefaultHierarchyFamilyId().subscribe((familyId) => {
      this._defaultFamilyId = familyId;
      this.loadFilterConfiguration();
    });
  }

  loadFilterConfiguration() {
    const defaultDateRange = this._dateHelperService.createDefaultDateRangeByDays(
      this._dateRangeDefaultDaysOffset
    );

    const persistedStartDate = this.loadPersistedDate(
      this._startDateFieldName,
      defaultDateRange.start,
      false,
      true
    );

    const hasEndDatePersisted =
      this.getPersisted(this._endDateFieldName, null, null, false) != null;

    let persistedEndDate = this.loadPersistedDate(
      this._endDateFieldName,
      defaultDateRange.end,
      true,
      true
    );

    if (!hasEndDatePersisted) {
      persistedEndDate = this.dateHelperService.addDays(persistedEndDate, 1);
    }

    const heFilterSettings = new INeHeFilterSettings(
      false,
      this.persistedHETypeIds ?? this._defaultHETypeIds,
      this._hierarchyElementTypeFieldName
    );

    this.leakageReportingFilterConfig = new LeakageReportingFilterConfiguration({
      persistencyArea: this.persistencyArea,
      defaultFamilyId: this._defaultFamilyId,
      defaultHETypeIds: this._defaultHETypeIds,
      heSettings: heFilterSettings,
      endDateFieldName: this._endDateFieldName,
      startDateFieldName: this._startDateFieldName,
      hierarchyElementFamilyFieldName: this._hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName: this._hierarchyElementIdFieldName,
      initialFamilyId: this.persistedFamilyId,
      initialHierarchyElementId: this.persistedHEIds,
      dateRange: new DateRange(persistedStartDate, persistedEndDate),
      defaultDateRange: defaultDateRange,
      initialHeTypeIds: this.persistedHETypeIds,
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.removeSelectionPersisted$.next();
    this.removeSelection$.next(true);
    const dbFilters = this.getDataBindingFiltersFromGridRef(gridFiltersRef);
    this.setFiltersMap(dbFilters);
    this.loadGrid();
  }

  getDataBindingFiltersFromGridRef(gridFiltersRef: any): DataBindingFilters {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    return newGridFilters;
  }

  setFiltersMap(dataBindingFilters: DataBindingFilters) {
    const iFilters = Array.from(dataBindingFilters.filters.values()).map((x) =>
      x.getFiltersValues()
    );
    const filtersToApply = this._objectHelperService.joinMaps(iFilters);
    this.filters = this._objectHelperService.clone(filtersToApply);
  }

  loadGrid() {
    if (this.leakageReportingCVs$ && !this.leakageReportingCVs$.closed) {
      this.leakageReportingCVs$.unsubscribe();
    }

    const startDate = this.filters.get(this._startDateFieldName);
    const endDate = this.filters.get(this._endDateFieldName);
    const hierarchyFamilyId = this.filters.get(this._hierarchyElementFamilyFieldName);
    const hierarchyElementTypeId = this.filters.get(this._hierarchyElementTypeFieldName)[0];
    const ancestors = this.filters.get(this._hierarchyElementIdFieldName);

    this._spinnerService.setLoading(true, this.pageCrud);

    this.leakageReportingCVs$ = this._leakageReportingService
      .getCurrentCVs(startDate, endDate, ancestors, hierarchyElementTypeId, hierarchyFamilyId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (cvs) => {
          this.currentCVs = cvs;

          this._spinnerService.setLoading(false, this.pageCrud);
        },
      });
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    this.gridFilters = filtersParameters;
    this.persistFilters$.next();
  }

  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        if (this.gridFilters?.filters) {
          this.setFiltersMap(this.gridFilters);
          this.loadGrid();
        }
      }
    });
  }

  getSelectedRow(selectedRow: CurrentCalculatedValuesViewDto) {
    this.selectedRow = selectedRow;
  }

  sendParameters(selectedRow: CurrentCalculatedValuesViewDto) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = new TabDetailPanelParameters();
      const selectedDate = selectedRow?.date
        ? this._localizationHelperService.formatDate(selectedRow?.date, DateFormats.Date)
        : '';

      const defaultDateRange = this.getDefaultDatePeriod();
      const activitiesDateRange = this.getActivitiesDatePeriod();

      parameters.addParameter(TabDetailParameterName.pageId, this.pageId);

      parameters.addParameter(
        TabDetailParameterName.elementName,
        selectedRow
          ? `${ts['zone-title']}: ${selectedRow?.hierarchyElementId} - ${ts['date-title']}: ${selectedDate} `
          : null
      );

      parameters.addParameter(
        TabDetailParameterName.dataBindingFilter,
        selectedRow
          ? this.getActivityFilterParameters(
              selectedRow?.hierarchyElementId,
              selectedRow?.hierarchyFamilyId,
              activitiesDateRange.start,
              activitiesDateRange.end
            )
          : null
      );

      const timeSelectorChartSetting = selectedRow
        ? this.getTimeSelectorChartSetting(selectedRow)
        : null;

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      parameters.addParameter(TabDetailParameterName.notificationId, selectedRow?.notificationId);

      this.rightPanelService.setTabParameters(parameters);
      this.rightPanelService.setCurrentPageId(this.pageId);
    });
  }

  openUnflagPopup() {
    this.openGenericPopup(
      UnflagReasonPopupComponent,
      UnflagReasonPopupDimensions,
      `${this.T_SCOPE}.messages.unflag-success`,
      `${this.T_SCOPE}.messages.unflag-success-no-recalc`,
      `${this.T_SCOPE}.messages.algorithm-success`,
      `${this.T_SCOPE}.messages.algorithm-error`
    );
  }

  openFlaggingPopup() {
    this.openGenericPopup(
      FlagReasonPopupComponent,
      FlagReasonPopupDimensions,
      `${this.T_SCOPE}.messages.flag-success`,
      `${this.T_SCOPE}.messages.flag-success-no-recalc`,
      `${this.T_SCOPE}.messages.algorithm-success`,
      `${this.T_SCOPE}.messages.algorithm-error`
    );
  }

  undoEdit() {
    this.showUndoSuccessMessage = true;
    this.undoEditedValues$.next(true);
  }

  saveEdition() {
    this.processSaveEdition();
  }

  onEditedValuesChange(editionChanges: Map<any, Map<string, CellEditedInfoDto>>) {
    this._editionChanges = editionChanges;
    this.hasEditionChanges = editionChanges.size > 0;

    if (this.showUndoSuccessMessage && !this.hasEditionChanges) {
      const dialogSettings = new WlmDialogSettings({
        translateKey: this._undoEditionSuccessMsg,
        icon: 'success',
      });
      this.dialogService.showTranslatedMessageInSnackBar(dialogSettings);
      this.showUndoSuccessMessage = false;
    }
  }

  checkPendingChanges(
    key: string,
    customDialog?: PendingChangesDialogSettings
  ): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key, customDialog).pipe(
      untilDestroyed(this),
      map((result) => result !== null)
    );
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private cleanCacheAndReload(removeSelection = true) {
    this._cacheService.clearContainsInUrl('/api/leakagereporting').then(() => {
      this.loadGrid();
      if (removeSelection) {
        this.removeSelectionPersisted$.next();
        this.removeSelection$.next(true);
      }
    });
  }

  private initializeRightPanelComponents() {
    const activityPermission$ = this._authService.canAccess('ActivitiesCrud', 'r');
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
    combineLatest([activityPermission$, notificationPermission$, localization$]).subscribe({
      next: ([activityPermission, notificationPermission, ts]) => {
        const panelSettings = new TabDetailPanelSettings();

        if (activityPermission) {
          panelSettings.addComponent(AlcLeaksActivitiesPanelComponent, ts.activities);
        }

        panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);

        if (notificationPermission) {
          panelSettings.addComponent(NotificationDetailsByIdComponent, ts.discussion);
        }

        this.rightPanelService.setTabSettings(panelSettings);
        this.rightPanelService.setTabParameters(new TabDetailPanelParameters());
      },
    });
  }

  private getActivityFilterParameters(
    hierarchyElementId: string,
    hierarchyFamilyId: string,
    startDate: Date,
    endDate: Date
  ): DataBindingFilters {
    if (!hierarchyElementId) {
      return null;
    }
    const activityGridFiltersForBinding = new DataBindingFilters();

    const dateRange = new DateRange(
      startDate,
      endDate,
      this._activitiesStartDateFieldName,
      this._activitiesEndDateFieldName,
      true
    );

    activityGridFiltersForBinding.filters.set('dateRange', dateRange);

    const activityCategory = new ActivityCategoryTypesFilter(
      this._repairFieldName,
      this._detectionFieldName
    );
    activityCategory.includeDetection = true;
    activityCategory.includeRepair = true;

    activityGridFiltersForBinding.filters.set('category', activityCategory);

    const heIds = new HierarchyElementPathFilter(
      hierarchyFamilyId,
      [hierarchyElementId],
      this._hierarchyElementFamilyFieldName,
      this._hierarchyElementIdFieldName
    );

    const activityStatus = new ActivityStatusFilter([true, true], this._statusFieldName);
    activityGridFiltersForBinding.filters.set('status', activityStatus);

    activityGridFiltersForBinding.filters.set('hierarchyElementsIds', heIds);

    return activityGridFiltersForBinding;
  }

  private getTimeSelectorChartSetting(selectedRow: CurrentCalculatedValuesViewDto) {
    let timeSelectorChartSetting = null;

    if (selectedRow?.hierarchyElementId) {
      const wlmElement = this.getWlmElement(selectedRow);

      timeSelectorChartSetting = new TimeSelectorChartSettings({
        chartSetting: new CustomWorkspaceChartSettings({
          chartType: ChartType.customizable,
          dataParameters: new CustomWorkspaceChartDataParameters({
            startDate: new Date(),
            endDate: new Date(),
            workspaceName: this._chartWorkspaceName,
            elements: [wlmElement],
          }),
          exportFileName: `${this.titleService.getTitle()} ${selectedRow?.hierarchyElementId}`,
        }),
        includeDefaultPeriods: true,
        defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
      });
    }
    return timeSelectorChartSetting;
  }

  private loadGridSettings() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridSettingsService
          .getGridSettingsByName(this.gridName)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (gridSettings) => {
              if (gridSettings) {
                this.setGridSettings(gridSettings);
              }
            },
          });
      }
    }
  }

  // Returns an year as default period
  private getDefaultDatePeriod() {
    const monthsAgo = 12;
    const defaultDateRange = this._dateHelperService.createDefaultDateRange(monthsAgo);
    return defaultDateRange;
  }

  private getActivitiesDatePeriod() {
    const startDate = new Date(this.filters?.get(this._startDateFieldName));
    const endDate = new Date(this.filters?.get(this._endDateFieldName));
    startDate?.setDate(startDate?.getDate() - this._daysInAWeek);
    endDate?.setDate(endDate?.getDate() + this._daysInAWeek);

    const dateRange = new DateRange(startDate, endDate);

    return dateRange;
  }

  private openGenericPopup(
    popupComponent: any,
    dimensions: any,
    successMsg: string,
    successNoRecalcMsg: string,
    algMsg: string,
    errorAlgMsg: string
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = dimensions.Width;
    dialogConfig.height = dimensions.Height;
    dialogConfig.data = this.selectedRows;
    const popup = this._flaggingReasonDialog.open(popupComponent, dialogConfig);
    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((jobId) => {
        if (jobId) {
          this._spinnerService.setLoading(true, this.pageCrud);
          this.cleanCacheAndReload();

          this.dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: successMsg, icon: 'success' })
          );

          this._leakageReportingService.getAlgorithmsRecalculationJobState(jobId).subscribe({
            next: (response: CVRecalculationJobStateResponseDto) => {
              if (response.response) {
                this.dialogService.showTranslatedMessageInSnackBar(
                  new WlmDialogSettings({ translateKey: algMsg, icon: 'success' })
                );
              }
            },
            error: (error) => {
              this.dialogService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({ translateKey: errorAlgMsg, icon: 'error' })
              );
            },
          });
        } else if (jobId == null || jobId == undefined) {
          this._spinnerService.setLoading(true, this.pageCrud);
          this.cleanCacheAndReload();
          this.dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: successNoRecalcMsg, icon: 'success' })
          );
        }
      });
  }

  private disablePageElements(value: boolean) {
    this.disableElements = value;
    for (const buttonKey in GridBtnsOptions) {
      const button = GridBtnsOptions[buttonKey] as GridBtnsOptions;

      if (button !== GridBtnsOptions.ClearGridFilters) {
        this.gridBtnsDisable$.next(new GridBtnsDisable(button, value));
      }
    }
  }

  private processSaveEdition() {
    const valuesToSave = this.getValuesToSave();

    if (valuesToSave.length > 0) {
      this._spinnerService.setLoading(true, this.pageCrud);
      this._leakageReportingService.saveEditedValues(valuesToSave).subscribe({
        next: (jobId) => {
          if (jobId) {
            this.clearPendingEdition$.next(true);
            this.hasEditionChanges = false;

            this.cleanCacheAndReload();

            this.dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: this._saveEditionSuccessMsg, icon: 'success' })
            );

            this.callRecalculation(jobId, this._algRecalcSuccessMsg, this._algRecalcErrorMsg);
          }
        },
        error: (error) => {
          this._spinnerService.setLoading(false, this.pageCrud);
          const message = this._saveEditionErrorMsg;

          this.dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: message, icon: 'error' })
          );
        },
      });
    }
  }

  private callRecalculation(jobId: string, algMsg: string, errorAlgMsg: string) {
    this._leakageReportingService.getAlgorithmsRecalculationJobState(jobId).subscribe({
      next: (response: CVRecalculationJobStateResponseDto) => {
        if (response?.response) {
          this.dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: algMsg, icon: 'success' })
          );
        }
      },
      error: (error) => {
        this.dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({ translateKey: errorAlgMsg, icon: 'error' })
        );
      },
    });
  }

  private getValuesToSave(): SaveEditedValuesDto[] {
    const valuesToSave = [];
    this._editionChanges.forEach((cellsEditedMap, key) => {
      const row = this.currentCVs.find((f) => f.id == key);

      if (row) {
        cellsEditedMap.forEach((cellInfo, field) => {
          const algorithm = this._columnAlgorithm.get(field);

          const saveItemDto = new SaveEditedValuesDto({
            date: row.date,
            elementId: row.elementId,
            shortName: algorithm.toString(),
            value: cellInfo.newValue,
            oldValue: cellInfo.originalValue,
          });

          valuesToSave.push(saveItemDto);
        });
      }
    });
    return valuesToSave;
  }

  canLeavePage(): Observable<boolean> {
    const hasPendingChanges = this._pendingChangesService.hasPendingChanges(this.pageId);

    if (this.hasEditionChanges || hasPendingChanges) {
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.messages.confirm-save-pending-changes`,
        icon: 'warning',
      });

      return this.dialogService.showTranslatedDialogMessage(dialogSettings).pipe(
        map((payload: WLMDialogResult) => {
          return payload.result;
        })
      );
    }
    return of(true);
  }

  saveAndReprocess(): Observable<boolean> {
    const valuesToSave = this.getValuesToSave();

    if (valuesToSave.length > 0) {
      this._spinnerService.setLoading(true, this.pageCrud);
      const save$ = this._leakageReportingService.saveEditedValues(valuesToSave);

      const saveResult$ = save$.pipe(
        switchMap((jobId) => {
          this.cleanCacheAndReload();
          return this._leakageReportingService.getAlgorithmsRecalculationJobState(jobId);
        })
      );

      return saveResult$.pipe(
        map((response: CVRecalculationJobStateResponseDto) => {
          let result = false;
          if (response.response) {
            this.dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: this._algRecalcSuccessMsg, icon: 'success' })
            );

            result = true;
          }

          this._spinnerService.setLoading(false, this.pageCrud);
          return result;
        })
      );
    } else {
      return of(false);
    }
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }

  private getWlmElement(selectedElement: CurrentCalculatedValuesViewDto): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null
    );
  }

  ngOnDestroy(): void {
    this._pendingChangesService.removePendingChangesByPage(this.pageId);
    super.ngOnDestroy();
  }
}
