<div id="center-page">
  <div class="top-button-container">
    <button
      mat-icon-button
      [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
      color="primary"
      (click)="restore()"
    >
      <wlm-icon [icon]="'discard-changes'"></wlm-icon>
    </button>
  </div>
  <div class="row">
    <!-- Uncoment this to show seasons form -->
    <!-- <div class="col-6">
      <wlm-base-filter
        [settings]="seasonsBaseSettings"
        (filter)="onFilterSeasons($event)"
        [apply$]="seasonsApply$"
        [clearAll$]="seasonsClearAll$"
      >
        <wlm-seasons-filter-item
          [fieldName]="seasonsName"
          [settings]="seasonsItemSettings"
          [selectedIds]="seasonsSelectedIds"
        ></wlm-seasons-filter-item>
      </wlm-base-filter>
    </div> -->
    <div class="col-12 field">
      <wlm-base-filter
        [settings]="dayBaseSettings"
        (filter)="onFilterDayTypes($event)"
        [apply$]="daysApply$"
        [clearAll$]="daysClearAll$"
      >
        <wlm-day-type-filter-item
          [fieldName]="dayTypeName"
          [settings]="dayItemSettings"
          [selectedIds]="dayTypesSelectedIds"
        ></wlm-day-type-filter-item>
      </wlm-base-filter>
    </div>
    <div class="col-12 field">
      <wlm-base-filter
        [settings]="calendarBaseSettings"
        (filter)="onFilterCalendar($event)"
        [apply$]="calendarsApply$"
        [clearAll$]="calendarsClearAll$"
      >
        <wlm-custom-calendar-filter-item
          [fieldName]="calendarName"
          [settings]="calendarItemSettings"
          [modeField]="calendarModeField"
          [selectedIdsWithParams]="selectedCalendarsWithParams"
        ></wlm-custom-calendar-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</div>
