<div class="top-container">
  <div class="top-title" [matTooltip]="fullElementName" matTooltipClass="large-tooltip">
    <span class="display">
      <h4>
        {{ title$ | async }}&nbsp;
        <span *ngFor="let item of titleSidePills" class="title-side-pill title-primary-pill"
          >{{ item | uppercase }}
        </span>
        <span
          *ngFor="let item of stylizedTitleSidePills"
          class="title-side-pill side-pill-min-separation"
          [class]="item.class"
          >{{ (item.hasToTranslate ? (item.label | translate) : item.label) | uppercase }}
        </span>
      </h4>
      <div *ngIf="isSelectedElement" class="buttons-with-details-container">
        <span *ngFor="let buttonWithDetailsItem of buttonWithDetails">
          <button mat-icon-button [matMenuTriggerFor]="buttonWithDetailsMenu">
            <wlm-icon [icon]="buttonWithDetailsItem.icon"></wlm-icon>
          </button>
          <mat-menu #buttonWithDetailsMenu="matMenu">
            <wlm-component-renderer
              [componentToRender]="buttonWithDetailsItem"
            ></wlm-component-renderer>
          </mat-menu>
        </span>
      </div>
    </span>
    <div *ngIf="subtitle" class="top-subtitle">{{ subtitle }}&nbsp;</div>
  </div>
  <div class="top-buttons-container">
    <div class="top-button">
      <button
        mat-icon-button
        color="primary"
        (click)="backToInitialState()"
        *ngIf="layoutSettingsLoadOptions"
        class="wtr-button-primary"
        [matTooltip]="T_SCOPE + '.layout-reset' | translate | lowercase"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </div>
</div>

<div class="top-attributes" *ngIf="topAttributes && topValues">
  <wlm-label-value-list [properties]="topAttributes" [values]="topValues"></wlm-label-value-list>
</div>

<hr class="separator" />

<div id="rpanel-content" #layoutContainer [class]="additionalContainerClass ?? ''">
  <div *ngIf="isLoading" class="spinner-full is-loading">
    <span class="k-i-loading k-loading-image"></span>
  </div>
  <div class="spawn-new"></div>
  <wlm-dynamic-layout
    *ngIf="layoutSettingsLoadOptions"
    [specificLevel]="specificLevel"
    [settingsToLoad]="layoutSettingsLoadOptions"
    (resized)="onLayoutResized()"
  ></wlm-dynamic-layout>
</div>
