<wlm-placeholder-overlay
  *ngIf="!(hierarchyElement && hierarchyFamilyId)"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-hierarchy-children-configurator
  *ngIf="hierarchyElement && hierarchyFamilyId"
  class="configurator"
  [pageId]="pageId"
  [selectedFamilyId]="hierarchyFamilyId"
  [selectedHE]="hierarchyElement"
></wlm-hierarchy-children-configurator>
