import { GChartLegendItem } from './g-chart-legend-item';

export class GChartLegend {
  data?: GChartLegendItem[] | string[];
  bottom? = '20';
  itemHeight? = '10';
  fontSize? = '10';
  formatter?: string | Function;
  orient?: string;
  type?: 'scroll' | 'plain';
  backgroundColor?: string;
  top?: 'bottom' | 'top' = 'bottom';
  textPadding?: number[];

  constructor(init: Readonly<GChartLegend>) {
    Object.assign(this, init);
  }
}
