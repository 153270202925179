import { FlagSystemItemDto } from './flag-system-item-dto';
import { FlaggedNotificationDto } from './flagged-notification.dto';

export class FlaggedItemsDto {
  notification: FlaggedNotificationDto;
  flaggedItems: FlagSystemItemDto[];

  constructor(init?: Partial<FlaggedItemsDto>) {
    Object.assign(this, init);
  }
}
