import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { CurrentDateErrorStateMatcher } from 'src/app/common-modules/shared/date-range-filter/current-date-error-state-manager';
import { DateRangeFilterSettings } from 'src/app/common-modules/shared/date-range-filter/date-range-filter-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { CanSaveHistoricalForm } from '../../shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '../../shared/historical/historical-form-settings';
import { LarsAndSworpsSiteSignalVersionsDto } from '../../shared/model/signals/lars-sworps-site-signal-versions.dto';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-lars-sworps-historical-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-lars-sworps-historical-form.component.html',
  styleUrls: ['./ne-configuration-lars-sworps-historical-form.component.scss'],
})
export class NeConfigurationLarsSworpsHistoricalFormComponent implements OnInit {
  @Input() set formModel(value: LarsAndSworpsSiteSignalVersionsDto) {
    this.initialModel = new LarsAndSworpsSiteSignalVersionsDto(value);
    const initializing = true;
    this.fillForm(this.initialModel, initializing);
  }

  private _settings: HistoricalFormSettings;
  get settings(): HistoricalFormSettings {
    return this._settings;
  }
  @Input() set settings(value: HistoricalFormSettings) {
    this._settings = value;

    this.canBeDisabledFields.forEach((field) => {
      const ctrl = this.form.get(field);
      this.settings.editOnlyDates ? ctrl.disable() : ctrl.enable();
    });
  }

  @Output() formChanges = new EventEmitter<LarsAndSworpsSiteSignalVersionsDto>();
  @Output() canSave = new EventEmitter<CanSaveHistoricalForm>();
  @Output() canReset = new EventEmitter<boolean>();
  @Output() onIsValidChanged = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  initialModel: LarsAndSworpsSiteSignalVersionsDto;
  dateRangeValueSettings: { value: DateRange; settings?: DateRangeFilterSettings };
  readonly dateRangeSettings = {
    displayHorizontal: true,
    smallFields: false,
  };
  readonly fieldAppearance = 'outline';
  readonly disableAutoTruncate = true;
  readonly fields = {
    pointId: 'pointId',
    pointDescription: 'pointDescription',
    validFrom: 'validFrom',
    validTo: 'validTo',
    isLars: 'isLars',
    isSubtraction: 'isSubtraction',
  };
  // Fields that can be disabled by settings.
  readonly canBeDisabledFields = [this.fields.isLars, this.fields.isSubtraction];

  private _resetDates$ = new Subject();
  readonly resetDates$ = this._resetDates$.asObservable();
  private _hasChanges = false;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _isValid: boolean;
  // Whether the startDate can be equal to the endDate
  private _disableEqualDates = true;

  constructor(
    private _fb: UntypedFormBuilder,
    private _objectHelperService: ObjectHelperService,
    private _dateHelperService: DateHelperService,
    private _dialogService: DialogService
  ) {
    this.buildForm();
    this.bindEvents();
  }

  ngOnInit(): void {}

  onDatesHasError(hasError) {
    if (hasError) {
      this.canSave.emit(new CanSaveHistoricalForm({ isValid: false, hasChanges: true }));
    }

    this.onIsValidChanged.emit(!hasError);
  }

  onDateRangeChanged(dateRange: DateRange): void {
    // Do not update if dates are the same as the form.
    if (
      this.dateEquals(dateRange.start, this.validFromCtrl.value) &&
      this.dateEquals(dateRange.end, this.validToCtrl.value)
    ) {
      return;
    }

    // Detect that the endDate has changed.
    const initialWasCurrent =
      this.validToCtrl.value.getFullYear() == this._dateHelperService.currentRangeEndYear;

    if (
      !dateRange.end ||
      this.dateEquals(dateRange.end, this.validToCtrl.value) ||
      !initialWasCurrent ||
      dateRange.end >= new Date()
    ) {
      this.updateDates(dateRange);
    } else {
      // The config was initially current but we are trying to decrease the date until the past.
      this._dialogService
        .showTranslatedDialogMessage(
          new WlmDialogSettings({
            translateKey: `${this.T_SCOPE}.messages.remove-current-config`,
            icon: 'warning',
          })
        )
        .subscribe((result) => {
          const isConfirmed = result.result;
          if (isConfirmed) {
            this.updateDates(dateRange);
          } else {
            // The value was already setted inside the datepicker, so we restore it to the form values.
            // This will trigger another call to this method, but the endDate should not have changed.
            this.dateRangeValueSettings = {
              value: new DateRange(this.validFromCtrl.value, this.validToCtrl.value),
              settings: this.buildDateRangeFilterSettings(true),
            };
          }
        });
    }
  }

  private buildDateRangeFilterSettings(
    disableValidationUntilTouched: boolean
  ): DateRangeFilterSettings {
    return new DateRangeFilterSettings({
      disableValidationUntilTouched,
      isReadOnly: this.settings.isReadOnly,
      disableEqualDates: this._disableEqualDates,
      startDateErrorStateMatcher: new CurrentDateErrorStateMatcher(
        this._dateHelperService.isCurrentStartDate
      ),
      endDateErrorStateMatcher: new CurrentDateErrorStateMatcher(
        this._dateHelperService.isCurrentEndDate
      ),
    });
  }

  private dateEquals = (date1: Date, date2: Date) => +date1 === +date2;

  private get validFromCtrl() {
    return this.form.get(this.fields.validFrom);
  }

  private get validToCtrl() {
    return this.form.get(this.fields.validTo);
  }

  private updateDates(dateRange: DateRange): void {
    this.validFromCtrl.setValue(dateRange.start, { emitEvent: false });
    this.validToCtrl.setValue(dateRange.end, { emitEvent: false });
    this.form.updateValueAndValidity();
  }

  private buildForm(): void {
    const V = Validators;
    this.form = this._fb.group({
      [this.fields.pointId]: [{ value: null, disabled: true }, [V.required]],
      [this.fields.pointDescription]: [{ value: null, disabled: true }, [V.required]],
      [this.fields.validFrom]: [null, [V.required]],
      [this.fields.validTo]: [null, [V.required]],
      [this.fields.isLars]: [null, [V.required]],
      [this.fields.isSubtraction]: [null, [V.required]],
    });
  }

  /**
   * Fill the form with initial data. If being used to initialize the form, the special condition
   * disableValidationUntilTouched will be set.
   */
  private fillForm(model: LarsAndSworpsSiteSignalVersionsDto, initializing: boolean): void {
    // Assign this way because form.setValues does not ignore non-form values.
    Object.entries(this.form.controls).forEach(([key, formControl]) => {
      formControl.setValue(model[key], { emitEvent: false });
    });

    this.form.updateValueAndValidity();

    const validFrom = this._dateHelperService.ensureDateObject(model.validFrom);
    const validTo = this._dateHelperService.ensureDateObject(model.validTo);
    this.dateRangeValueSettings = {
      value: new DateRange(validFrom, validTo),
      settings: this.buildDateRangeFilterSettings(initializing),
    };

    if (this.settings.emitAtFormInitialization) {
      this.emitSaveAtInitialiation(model);
    }
  }

  private emitSaveAtInitialiation(model: LarsAndSworpsSiteSignalVersionsDto) {
    this._hasChanges = true;
    this.checkCanSave(model);
    this._hasChanges = false;
  }

  private bindEvents(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const modelResult = new LarsAndSworpsSiteSignalVersionsDto({
        ...this.initialModel,
        ...value,
      });
      this.formChanges.emit(modelResult);
      this.checkEvents(modelResult);
    });
  }

  private checkEvents(modelResult): void {
    this.checkHasChanges(modelResult);
    this.checkCanSave(modelResult);
    this.checkCanReset();
  }

  private checkHasChanges(model: LarsAndSworpsSiteSignalVersionsDto): void {
    const diffObject = this._objectHelperService.deepDiff(this.initialModel, model);
    this._hasChanges = Object.keys(diffObject).length !== 0;
  }

  private checkCanSave(model: LarsAndSworpsSiteSignalVersionsDto): void {
    this._isValid = this.form.status === 'VALID';
    // If we check for chenges here, we can no longer be able to change ie isSubtraction and the change it back, because
    // hasChanges would be false and the chart would not update.

    if (this._hasChanges) {
      this.initialModel = new LarsAndSworpsSiteSignalVersionsDto({ ...model });
      this.canSave.emit(
        new CanSaveHistoricalForm({
          isValid: this._isValid,
          hasChanges: this._hasChanges,
        })
      );
    }
  }

  private checkCanReset(): void {
    const canReset = this._hasChanges;
    this.canReset.emit(canReset);
  }
}
