import { Injectable, Injector } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { LogsInterfacesPopupDimensions } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { LogsInterfacesPopupComponent } from 'src/app/common-modules/wlm-grid/popups/logs-interfaces-popup/logs-interfaces-popup.component';
import { BaseService } from '../../base.service';
import { DialogService } from '../../dialogs/dialogs.service';

@Injectable()
export class ButtonColumnService extends BaseService {
  expiration: GetCacheOpts = { expiration: 'default' };

  constructor(injector: Injector, private _dialogService: DialogService) {
    super(injector);
  }

  applyFn(methodName: string, dataItem?: any): Observable<any> {
    return this[methodName](dataItem);
  }

  interfacesLoggingHasMessage(dataItem: any): Observable<boolean> {
    return of(!dataItem.success && !!dataItem.exception);
  }

  openLogsInterfacesPopup(dataItem: any): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = LogsInterfacesPopupDimensions.Width;
    dialogConfig.height = LogsInterfacesPopupDimensions.Height;
    dialogConfig.data = dataItem;

    this._dialogService.openComponent(LogsInterfacesPopupComponent, dialogConfig);
  }
}
