<wlm-collapsible-summary-container class="meter-attributes">
  <div collapsed>
    <wlm-label-value-list [properties]="collapsedFields" [values]="meter"></wlm-label-value-list>
  </div>
  <div opened>
    <wlm-label-value-list [properties]="openedFields" [values]="meter"></wlm-label-value-list>
  </div>
</wlm-collapsible-summary-container>

<div class="meter-readings" fixedSizeElement>
  <wlm-customer-detail-meter-readings
    class="meter-grid"
    [customerId]="customerId"
  ></wlm-customer-detail-meter-readings>
</div>
