import { BaseFilterItemSettings } from '../base-filter-item-settings';

export class AlgorithmFISettings extends BaseFilterItemSettings {
  fieldName: string;
  selectedIds?: string[];

  constructor(init: AlgorithmFISettings) {
    super(init);
    Object.assign(this, init);
  }
}
