import { Type } from 'class-transformer';
import { ISignalFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/signal-filtrable-item.dto';
import { SignalFiltrableType } from './types/signal-filtrable-type';

export class SignalFiltrableItemDto implements ISignalFiltrableItemDto {
  @Type(() => Date)
  startDate: Date;
  @Type(() => Date)
  endDate: Date;
  filtrableType: SignalFiltrableType;

  constructor(init: Partial<SignalFiltrableItemDto>) {
    Object.assign(this, init);
  }
}
