import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { CurrencyTypesEnum } from 'src/app/common-modules/shared/model/shared/currency-types.enum';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';

const COMPONENT_SELECTOR = 'wlm-water-balance-widget-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-widget-item.component.html',
  styleUrls: ['./water-balance-widget-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WaterBalanceWidgetItemComponent implements OnInit {
  @Input() value: number;
  @Input() percent: number;
  @Input() classI18nKey: string;
  @Input() selected: boolean;
  @Input() dimension: DimensionTypesEnum;
  @Input() timeAggregation: number;
  @Input() labelKey: string;
  @Input() unitFormat: string;
  @Input() hierarchyElementTypeId: string;
  @Input() currencyType: CurrencyTypesEnum;

  uomKey: string;

  T_SCOPE = `${AppModules.WaterBalance}.kpis`;

  ngOnInit(): void {}
}
