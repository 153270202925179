import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BaseComponent } from 'src/app/common-modules/shared/component/base.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupDto } from 'src/app/common-modules/shared/model/roles/group.dto';
import { GroupUsersCmdDto } from 'src/app/common-modules/shared/model/roles/groupUsersCmd.dto';
import { UsersGridODataService } from 'src/app/common-modules/shared/services/users/users.service';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

import { GroupUsersPopupComponent } from '../../groups-components/group-users-popup/group-users-popup.component';

const COMPONENT_SELECTOR = 'wlm-user-groups-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './user-groups-popup.component.html',
  styleUrls: ['./user-groups-popup.component.scss'],
})
export class UserGroupsPopupComponent extends BaseComponent implements OnInit {
  @ViewChild(GenericGridComponent) public groupsGrid: GenericGridComponent;

  gridFiltersForBinding: DataBindingFilters;
  additionalFilters: Map<string, string>;
  groupGridSetting: GridSetting;
  selectedGroups: GroupDto[];
  groupGridSettingsName = 'UserGroupsPopup';
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  private _userCode: string;
  public get userCode(): string {
    return this._userCode;
  }
  public set userCode(v: string) {
    this._userCode = v;
    this.loadGrid();
  }

  isSaving = false;

  constructor(
    _gridSettingsService: GridSettingsService,
    private dialogRef: MatDialogRef<GroupUsersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { userCode },
    private _userService: UsersGridODataService,
    private _dialogService: DialogService,
    private _authenticationService: AuthenticationService
  ) {
    super();

    _gridSettingsService.getGridSettingsByName(this.groupGridSettingsName).subscribe({
      next: (gridSettings) => {
        this.groupGridSetting = gridSettings;
      },
    });
    this.userCode = userCode;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    throw new Error('Method not implemented.');
  }
  init(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {}

  loadGrid() {
    const additionalFilters = new Map<string, string>();
    additionalFilters.set('userCode', this.userCode);
    additionalFilters.set('exclude', 'true');

    this.additionalFilters = additionalFilters;
    this.gridFiltersForBinding = new DataBindingFilters();
  }

  saveGroupUsers() {
    this.isSaving = true;
    this.groupsGrid.grid.loading = true;

    const groupUsersCmd = new GroupUsersCmdDto(
      this.selectedGroups.map((x) => x.groupId),
      [this.userCode],
      this._authenticationService.userCode
    );

    const isPlural = groupUsersCmd.groupsIds.length > 1;
    this._userService.insertGroupUsers(groupUsersCmd).subscribe({
      next: (response) => {
        if (response.errors.length === 0) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.add-groups-success' : '.add-group-success'
              }`,
            })
          );

          this.dialogRef.close(true);
        } else {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.add-groups-error' : '.add-group-error'
              }`,
              icon: 'error',
            })
          );
        }
        this.groupsGrid.grid.loading = false;
        this.isSaving = false;
      },
      error: (error) => {
        this.isSaving = false;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
