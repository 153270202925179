import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { IMaskModule } from 'angular-imask';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonSharedModule } from 'src/app/common-modules/shared/common-shared.module';
import { matDateFormatsProvider } from 'src/app/common-modules/shared/constants/custom-providers';
import { DataVisualizationSharedModule } from '../data-visualization-shared/data-visualization-shared.module';
import { RelatedElementListComponent } from '../monitoring/notification/notification-components/related-element-list/related-element-list.component';
import { AlgorithmSelectionService } from '../shared-component/algorithm-selection-grid/algorithm-selection.service';
import { AlarmSeverityNamePipe } from './alarms/alarm-severity.pipe';
import { CallBackComponent } from './auth/callback/call-back/call-back.component';
import { IsNormalUserDirective } from './auth/permission/is-normal-user.directive';
import { ChartConfigurationService } from './charts/chart-configuration.service';
import { NavDropdownComponent } from './core/nav-dropdown/nav-dropdown.component';
import { TreelistComponent } from './core/treelist/treelist.component';
import { HierarchyFamilyFilterComponent } from './filters/hierarchy/hierarchy-family-filter/hierarchy-family-filter.component';
import { HierarchyTreeFilterComponent } from './filters/hierarchy/hierarchy-tree-filter/hierarchy-tree-filter.component';
import { NeHeFilterComponent } from './filters/nehe-filter/nehe-filter.component';
import { CoordinatesSystemService } from './gis/coordinates-system.service';
import { GisService } from './gis/gis.service';
import { SignalHistoricalFormComponent } from './historical/signal-historical-form/signal-historical-form.component';
import { IconLegacyComponent } from './icon-legacy/icon-legacy.component';
import { AlgorithmFiltrableItemService } from './model/filtrable-items/services/algorithm-filtrable-item.service';
import { SignalFiltrableItemService } from './model/filtrable-items/services/signal-filtrable-item.service';
import { KpiTitlePipe } from './pipes/kpi-title-pipe';
import { FiltrableItemMapperService } from './services/filtrable-item-mapper.service';
import { HomePageService } from './services/home-page.service';
import { LargeUserAvailableSignalsService } from './services/large-user-available-signals.service';
import { MVCVChartService } from './services/mv-cv-chart.service';
import { ServerModulesService } from './services/server-modules.service';
import { WlmSpinnerSplashService } from './services/wlm-spinner-splash.service';
import { WorkspacesHelperService } from './services/workspaces-helper.service';
import { AvailableSignalsVersionService } from './signals/available-signals-version.service';
import { AvailableSignalsService } from './signals/available-signals.service';

@NgModule({
  declarations: [
    HierarchyTreeFilterComponent,
    HierarchyFamilyFilterComponent,
    AlarmSeverityNamePipe,
    CallBackComponent,
    NeHeFilterComponent,
    NavDropdownComponent,
    IsNormalUserDirective,
    IconLegacyComponent,
    RelatedElementListComponent,
    KpiTitlePipe,
    TreelistComponent,
    SignalHistoricalFormComponent,
  ],
  imports: [
    CommonSharedModule.forFeature({
      filtrableItemServices: [AlgorithmFiltrableItemService, SignalFiltrableItemService],
    }),
    DataVisualizationSharedModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OAuthModule,
    ColorPickerModule,
    HierarchyTreeFilterComponent,
    HierarchyFamilyFilterComponent,
    AutoCompleteComponent,
    CallBackComponent,
    IMaskModule,
    NeHeFilterComponent,
    NavDropdownComponent,
    IsNormalUserDirective,
    IconLegacyComponent,
    RelatedElementListComponent,
    KpiTitlePipe,
    TreelistComponent,
    CommonSharedModule,
    DataVisualizationSharedModule,
    SignalHistoricalFormComponent,
  ],
  providers: [
    GisService,
    CoordinatesSystemService,
    matDateFormatsProvider,
    LargeUserAvailableSignalsService,
    { provide: 'LargeUserAvailableSignalsService', useExisting: LargeUserAvailableSignalsService },
    MVCVChartService,
    { provide: 'MVCVChartService', useExisting: MVCVChartService },
    WlmSpinnerSplashService,
    { provide: 'SpinnerSplashService', useExisting: WlmSpinnerSplashService },
    FiltrableItemMapperService,
    ChartConfigurationService,
    AlgorithmFiltrableItemService,
    SignalFiltrableItemService,
    WorkspacesHelperService,
    ServerModulesService,
    AlgorithmSelectionService,
    HomePageService,
    AvailableSignalsService,
    { provide: 'AvailableSignalsService', useExisting: AvailableSignalsService },
    AvailableSignalsVersionService,
    { provide: 'AvailableSignalsVersionService', useExisting: AvailableSignalsVersionService },
  ],
})
export class WlmSharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    WlmSharedModule.injector = injector;
  }
}
