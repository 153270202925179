import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable, ReplaySubject, Subject, of, startWith } from 'rxjs';
import { CVShortQueryDto } from 'src/app/common-modules/dependencies/shared/model/cv-short-query.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { IExportExcelComponent } from 'src/app/common-modules/shared/exports/models/export-excel-component';
import { ExportExcelSettings } from 'src/app/common-modules/shared/exports/models/export-excel-settings';
import { IExportPdfComponent } from 'src/app/common-modules/shared/exports/models/export-pdf-component';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { MVQueryDto } from 'src/app/common-modules/shared/model/mv/mv-query.dto';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
// prettier-ignore
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseCalculateResizableChildComponent } from 'src/app/common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { ResponsiveManagerService } from 'src/app/common-modules/shared/services/responsive-manager.service';
import { CustomWorkspaceChartComponent } from '../custom-workspace-chart/custom-workspace-chart.component';
import { EventsChartComponent } from '../events-chart/events-chart.component';
import { EventChartPosition } from '../events-chart/models/event-chart-position';
import { EventChartQueryDto } from '../events-chart/models/events-chart-query.dto';
import { ChartType } from '../models/chart-type.enum';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericChartUnselectedSeries } from '../models/generic-chart-unselected-series';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from '../models/generic-events/g-chart-data-zoom-event';
import { GChartInitEvent } from '../models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from '../models/generic-events/g-chart-legend-selected-event';
import { PeriodTypesEnum } from '../models/period-types.enum';
import { TimeSelectorPeriod } from '../models/time-selector-period';
import { TimeSelectorChartSettings } from '../models/time-selector-settings';
import { TrendChartDataParameters } from '../models/trend-chart-data-parameters';
import { TrendChartSettings } from '../models/trend-chart-settings';
import { TrendChartComponent } from '../trend-chart/trend-chart/trend-chart.component';

const COMPONENT_SELECTOR = 'wlm-time-selector-chart';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './time-selector-chart.component.html',
  styleUrls: ['./time-selector-chart.component.scss'],
})
export class TimeSelectorChartComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit, IExportExcelComponent, IExportPdfComponent
{
  dimensionToCalculate: 'height' | 'width' = 'height';
  public T_SCOPE = `${AppModules.WlmCharts}.${COMPONENT_SELECTOR}`;
  public T_SCOPE_CHART_BUTTONS = `${AppModules.WlmCharts}.buttons`;

  @Input() public loading = false;
  @Output() chartInitEvent = new EventEmitter<GChartInitEvent>();
  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();
  @Output() chartLegendSelectedEvent = new EventEmitter<GChartLegendSelectedEvent>();
  @Output() chartLoaded = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();
  @Output() serieNamesUnselected = new EventEmitter<GenericChartUnselectedSeries>();

  @ViewChild(EventsChartComponent) eventChart: EventsChartComponent;

  @ViewChildren('buttonsFixedSizeElement') set queryButtonsFixedSizeElements(
    elements: ElementRef<HTMLElement>[]
  ) {
    if (elements) {
      this.buttonsFixedSizeElements = Array.from(elements);
    }
  }
  buttonsFixedSizeElements: ElementRef<any>[] = [];

  private _trendChart: TrendChartComponent;
  public get trendChart(): TrendChartComponent {
    return this._trendChart;
  }
  @ViewChild(TrendChartComponent) public set trendChart(v: TrendChartComponent) {
    this._trendChart = v;
    if (this.chartType === ChartType.trend) {
      this.loadChart();
    }
  }

  private _customizableChart: CustomWorkspaceChartComponent;
  public get customizableChart(): CustomWorkspaceChartComponent {
    return this._customizableChart;
  }
  @ViewChild(CustomWorkspaceChartComponent) public set customizableChart(
    v: CustomWorkspaceChartComponent
  ) {
    this._customizableChart = v;
    if (this.chartType === ChartType.customizable) {
      this.loadChart();
    }
  }

  private _selectedPeriod: TimeSelectorPeriod;
  public get selectedPeriod(): TimeSelectorPeriod {
    return this._selectedPeriod;
  }
  public set selectedPeriod(v: TimeSelectorPeriod) {
    this._selectedPeriod = v;
    this.loadChart();
  }

  private _timeSelectorChartSetting: TimeSelectorChartSettings;
  public get timeSelectorChartSetting(): TimeSelectorChartSettings {
    return this._timeSelectorChartSetting;
  }
  @Input() public set timeSelectorChartSetting(v: TimeSelectorChartSettings) {
    if (v) {
      this.chartType = v.chartSetting?.chartType;
      if (this.chartType === ChartType.customizable) {
        this.selectedPeriod = null;
      }
    }
    this._timeSelectorChartSetting = v;
    this.loadChart();
  }

  @Input() eventChartQuery: EventChartQueryDto;

  private _addTimeSelectorPeriod$ = new Subject<TimeSelectorPeriod>();
  readonly addTimeSelectorPeriod$ = this._addTimeSelectorPeriod$.asObservable();

  chartTypes = ChartType;

  trendChartSettings: TrendChartSettings;

  chartSettings: TrendChartSettings;
  chartType: ChartType;

  // Specify the lower and upper boundaries of the main X Axis.
  xAxisMin: Date;
  xAxisMax: Date;
  xAxisRange: DateRange;

  mainChartLoading = false;
  eventChartLoading = false;

  settings: ExportExcelSettings;
  exportExcelComponent: IExportExcelComponent;
  chartSize$: Observable<IElementSize>;
  private readonly _eventsChartSizeChanges$ = new ReplaySubject<IElementSize>(1);

  componentInstanceId: string;

  constructor(
    private readonly _localization: LocalizationHelperService,
    private readonly _logService: LogService,
    private readonly _responsiveManagerService: ResponsiveManagerService
  ) {
    super();
    this.componentInstanceId = globalUtilsHelper.generateGuid();
  }

  ngOnInit(): void {
    this.propagateResize();
  }

  timePeriodChanged(period: TimeSelectorPeriod) {
    this.selectedPeriod = period;
  }

  setDataParams(period: TimeSelectorPeriod) {
    this.setTimeSelectorDateParams(period);

    switch (this.chartType) {
      case ChartType.trend:
        const queryParams = this.setDatesQueryParams(
          period.startDate,
          period.endDate,
          this.timeSelectorChartSetting.chartSetting.dataParameters as TrendChartDataParameters
        );
        this.trendChart.setDateParams(period.startDate, period.endDate, queryParams);
        break;

      case ChartType.customizable:
        this.customizableChart.setDateParams(period.startDate, period.endDate);
        break;

      default:
        break;
    }

    if (this.trendChart) {
      this.xAxisRange = null;
    } else {
      this.xAxisRange = new DateRange(period.startDate, period.endDate);
    }

    // Reset Event chart
    this.eventChartQuery = null;

    // CustomizableChart has the event chart integrated
    if (
      this.chartType !== ChartType.customizable &&
      this.timeSelectorChartSetting?.eventChartSetting
    ) {
      this.eventChartQuery = {
        ...this.timeSelectorChartSetting.eventChartSetting,
        startDate: period.startDate,
        endDate: period.endDate,
      };
    }
  }

  setMainChartLoading(isLoading: boolean) {
    this.mainChartLoading = isLoading;
  }

  onChartInit = (event) => this.chartInitEvent.next(event);
  onChartClick = (event) => this.chartClickEvent.next(event);

  onChartDataZoom(chartDataZoom: GChartDataZoomEvent): void {
    if (!this.eventChart) {
      return;
    }
    const { start, end } = chartDataZoom;
    this.eventChart.position = new EventChartPosition({
      start,
      end,
    });
  }

  onChartLegendSelected = (event) => this.chartLegendSelectedEvent.emit(event);
  onChartFinished = (event) => this.chartFinished.emit(event);
  onSerieNamesUnselected = (event) => this.serieNamesUnselected.emit(event);

  onChartLoaded(event) {
    if (event && this._customizableChart) {
      const customDateRange = this._customizableChart.initialDateRange;
      if (customDateRange) {
        const customPeriod = new TimeSelectorPeriod({
          startDate: customDateRange.start,
          endDate: customDateRange.end,
          keyLabel: 'auto',
          periodType: PeriodTypesEnum.customFromDateRange,
        });

        this._addTimeSelectorPeriod$.next(customPeriod);
      }
    }

    this.chartLoaded.emit(event);
  }

  /**
   * Specify the min and max values of the main X axis, so the event chart can adjust to them.
   * Assumes the first x axis in the axis array is the main one.
   */
  onMainChartSettingsChange(settings: GenericCartesianChartSettings): void {
    if (!settings || !settings.xAxes.length) {
      this.chartLoaded.emit(true);
      return;
    }

    const min = settings.xAxes[0].min;
    const max = settings.xAxes[0].max;
    if (typeof (min as Date).getMonth === 'function') {
      this.xAxisMin = min as Date;
      this.xAxisMax = max as Date;
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else if (typeof min === 'string') {
      this.xAxisMin = this._localization.localStrToDate(min as string, DateFormats.DateTime);
      this.xAxisMax = this._localization.localStrToDate(max as string, DateFormats.DateTime);
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else {
      this._logService.error({
        msg: 'Min and max properties of the main chart are not bindable to the events chart.',
      });
    }
  }

  onEventChartLoading(loading): void {
    this.eventChartLoading = false;
  }

  onChartConfiguration() {
    this._customizableChart.updateChartConfiguration();
  }

  onEventChartSizeChange(size: IElementSize): void {
    this._eventsChartSizeChanges$.next(size);
  }

  private loadChart() {
    this.setLoadings(false);
    if (
      this.selectedPeriod &&
      this.timeSelectorChartSetting &&
      (this.trendChart || this.customizableChart)
    ) {
      this.setDataParams(this.selectedPeriod);
    }
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [this._eventsChartSizeChanges$.asObservable().pipe(startWith(null))];
  }

  private setLoadings(isLoading: boolean) {
    this.loading = isLoading;
    this.setMainChartLoading(isLoading);
    this.onEventChartLoading(isLoading);
  }

  private setTimeSelectorDateParams(period: TimeSelectorPeriod) {
    if (!this.timeSelectorChartSetting?.chartSetting?.dataParameters || !period) {
      return;
    }
    this.timeSelectorChartSetting.chartSetting.dataParameters.endDate = period.endDate;
    this.timeSelectorChartSetting.chartSetting.dataParameters.startDate = period.startDate;
  }

  private setDatesQueryParams(
    startDate: Date,
    endDate: Date,
    params: TrendChartDataParameters
  ): TrendChartDataParameters {
    const trendChartParams = params;
    const queryParams = params.queryParams as (MVQueryDto | CVShortQueryDto)[];

    if (!queryParams.length) {
      return;
    }

    queryParams.forEach((x) => {
      x.endDate = endDate;
      x.startDate = startDate;
    });

    trendChartParams.queryParams = queryParams;

    return trendChartParams;
  }

  exportChart() {
    this.trendChart?.exportChart();
    this.customizableChart?.exportChart();
  }

  showDataPoints() {
    this.trendChart?.showDataPoints();
    this.customizableChart?.showDataPoints();
  }

  getExportExcelSettings(): Observable<ExportExcelSettings> {
    switch (this.chartType) {
      case ChartType.trend:
        return this.trendChart?.getExportExcelSettings();

      case ChartType.customizable:
        return this.customizableChart?.getExportExcelSettings();

      default:
        return of(null);
    }
  }

  exportToPdf(): void {
    switch (this.chartType) {
      case ChartType.trend:
        this.trendChart?.exportToPdf();

      case ChartType.customizable:
        this.customizableChart?.exportToPdf();

      default:
        break;
    }
  }

  isEmpty(): boolean {
    if (this.timeSelectorChartSetting.chartSetting.chartType == ChartType.trend) {
      return this.trendChart?.isEmpty();
    }
  }

  getObjectToExport() {
    if (this.timeSelectorChartSetting.chartSetting.chartType == ChartType.trend) {
      return this.trendChart?.getObjectToExport();
    }
  }

  private propagateResize(): void {
    this.calculatedSize$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((size) => {
      this._responsiveManagerService.emitSizeChanged(this.componentInstanceId, size);
    });
  }
}
