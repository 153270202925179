import { ChartConfiguration } from '../../../../water-loss/features/shared/charts/model/chart-configuration';
import { IChartDataParameters } from './chart-data-parameters';

export class CustomizableChartParameters implements IChartDataParameters {
  startDate: Date;
  endDate: Date;
  chartConfiguration: ChartConfiguration;

  constructor(init: Partial<CustomizableChartParameters>) {
    Object.assign(this, init);
  }
}
