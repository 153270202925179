import { Component, Inject, Injector, Input, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '@progress/kendo-angular-dialog';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import {
  ExportPdfDocument,
  PdfExportItem,
} from 'src/app/common-modules/shared/exports/models/export-pdf-document';
import { GenericExportService } from 'src/app/common-modules/shared/exports/service/generic-export.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { PieChartSettings } from 'src/app/common-modules/wlm-charts/core/models/pie-chart-settings';
import { PieChartComponent } from 'src/app/common-modules/wlm-charts/core/pie-chart/pie-chart.component';
import { AppModules } from '../../../../../common-modules/shared/app-modules.enum';
import { PipeChartService } from '../pipe-chart.service';

const COMPONENT_SELECTOR = 'wlm-pipe-pie-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './pipe-pie-chart.component.html',
  styleUrls: ['./pipe-pie-chart.component.scss'],
})
export class PipePieChartComponent extends BaseWidgetComponent {
  @ViewChild('materialChart') public pieMaterialChart: PieChartComponent;
  @ViewChild('diameterChart') public pieDiameterChart: PieChartComponent;
  @ViewChild('ageChart') public pieAgeChart: PieChartComponent;
  private _elementId: string;

  public get elementId(): string {
    return this._elementId;
  }
  @Input() public set elementId(v: string) {
    this._elementId = v;
    this.showMaterialChart = true;
    this.showDiameterChart = true;
    this.showAgeChart = true;
  }

  get componentName() {
    return 'PipePieChartComponent';
  }

  materialChartHasData = false;
  diameterChartHasData = false;
  ageChartHasData = false;
  showMaterialChart = true;
  showDiameterChart = true;
  showAgeChart = true;

  filters: DataBindingFilters;

  chartSettingMaterial!: PieChartSettings;
  chartSettingDiameter!: PieChartSettings;
  chartSettingAge!: PieChartSettings;

  chartsLoading: boolean[] = [];
  allChartsLoading = false;
  elementName: string;
  genericFilename = 'pie-chart';
  disableFixedSize = false;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  private _pdfItems: PdfExportItem[];
  private readonly _documentWidth = 150;
  private readonly _documentHeight = 100;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    public readonly pipeChartService: PipeChartService,
    public readonly dialogService: DialogService,
    public readonly uomService: UoMService,
    public readonly localization: LocalizationHelperService,
    private readonly _genericExportService: GenericExportService
  ) {
    super(injector, widgetSettings);
  }

  init(): void {
    if (this.elementId) {
      this.loadCharts();
    }
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.elementId = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
    this.elementName = parameters.parameters.get(TabDetailParameterName.elementName);
  }

  loadCharts() {
    this.loadLengthByMaterial();
    this.loadLengthByDiameter();
    this.loadLengthByAge();
  }

  loadLengthByMaterial() {
    const params = {
      zoneId: this.elementId,
      columnName: 'materialGroup',
      columnToOrder: 'materialGroup',
    };
    this.chartSettingMaterial = {
      dataParameters: {
        queryParams: params,
        dataService: 'PipePieChartService',
        startDate: null,
        endDate: null,
      },
      chartType: ChartType.pie,
    };
  }

  loadLengthByDiameter() {
    const params = {
      zoneId: this.elementId,
      columnName: 'diameterGroup',
      columnToOrder: 'diameterOrder',
    };
    this.chartSettingDiameter = {
      dataParameters: {
        queryParams: params,
        dataService: 'PipePieChartService',
        startDate: null,
        endDate: null,
      },
      chartType: ChartType.pie,
    };
  }

  loadLengthByAge() {
    const params = {
      zoneId: this.elementId,
      columnName: 'ageGroup',
      columnToOrder: 'ageOrder',
    };
    this.chartSettingAge = {
      dataParameters: {
        queryParams: params,
        dataService: 'PipePieChartService',
        startDate: null,
        endDate: null,
      },
      chartType: ChartType.pie,
    };
  }

  onMaterialChartHasData(hasData: boolean) {
    this.materialChartHasData = hasData;
    this.showMaterialChart = hasData;
  }

  onDiameterChartHasData(hasData: boolean) {
    this.diameterChartHasData = hasData;
    this.showDiameterChart = hasData;
  }

  onAgeChartHasData(hasData: boolean) {
    this.ageChartHasData = hasData;
    this.showAgeChart = hasData;
  }

  onChartLoaded(isLoading, chartIndex): void {
    this.chartsLoading[chartIndex] = isLoading;
    this.allChartsLoading = this.chartsLoading.find((loading) => loading);
  }

  exportToPdf() {
    const ts$ = this.localization.get(this.T_SCOPE);
    const pieMaterialChart$ = this.pieMaterialChart.getObjectToExportObservable();
    const pieDiameterChart$ = this.pieDiameterChart.getObjectToExportObservable();
    const pieAgeChart$ = this.pieAgeChart.getObjectToExportObservable();

    forkJoin([
      ts$.pipe(take(1)),
      pieMaterialChart$.pipe(take(1)),
      pieDiameterChart$.pipe(take(1)),
      pieAgeChart$.pipe(take(1)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([ts, pieMaterialChart, pieDiameterChart, pieAgeChart]) => {
        this._pdfItems = [];

        let yCoords = 40;
        if (this.showMaterialChart) {
          this.setChartItem(ts['length-pipes-material-groups'], pieMaterialChart, yCoords);
          yCoords += 120;
        }

        if (this.showDiameterChart) {
          this.setChartItem(ts['length-pipes-diameter-groups'], pieDiameterChart, yCoords);
          yCoords += 120;
        }

        if (this.showAgeChart) {
          this.setChartItem(ts['length-pipes-age-groups'], pieAgeChart, yCoords);
        }

        const fileName = this.elementName
          ? `${this.elementName}.pdf`
          : `${this.genericFilename}.pdf`;

        const document = new ExportPdfDocument({
          filename: fileName,
          title: this.elementName,
          items: this._pdfItems,
          height: this._documentHeight,
          width: this._documentWidth,
          unit: 'mm',
        });

        this._genericExportService.exportPdfDocument(document);
      });
  }

  private setChartItem(chartTitle: any, chartObject: string, yCoords: number): void {
    //title line
    this._pdfItems.push(
      new PdfExportItem({
        data: chartTitle,
        type: 'text',
        yCoords,
        fontSize: 10,
      })
    );

    //chart line
    this._pdfItems.push(
      new PdfExportItem({
        data: chartObject,
        type: 'image',
        yCoords: yCoords + 5,
        xCoords: 40,
      })
    );
  }
}
