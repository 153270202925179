import { Injectable, Type } from '@angular/core';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { BaseWidgetComponent } from '../component/base-widget.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentHelperService {
  static getWidgetName(component: Type<BaseWidgetComponent>): string {
    return ComponentHelperService.getName(component);
  }

  static getDynamicWidgetName(component: Type<BaseDynamicWidgetComponent>): string {
    return ComponentHelperService.getName(component);
  }

  static getServiceName<T>(service: T): string {
    return this.getName(service, 'serviceName');
  }

  private static getName(component, fieldName = 'componentName'): string {
    if (component) {
      const name = (component as any)[fieldName] ?? component.prototype[fieldName];
      if (name) {
        return name;
      }
    }
    return null;
  }
}
