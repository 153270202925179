import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoldenLayoutComponentService } from './golden-layout-component.service';
import { GoldenLayoutHostComponent } from './golden-layout-host.component';

// Adaptation from https://github.com/golden-layout/golden-layout-ng-app

@NgModule({
  declarations: [GoldenLayoutHostComponent],
  imports: [CommonModule],
  exports: [GoldenLayoutHostComponent],
  providers: [GoldenLayoutComponentService],
})
export class GoldenLayoutHostModule {}
