import { StateWidgetSettings } from './state-widget-settings';

export class StateFullSettings {
  widget: StateWidgetSettings;
  area: string;
  scope? = 'shared';

  constructor(init: StateFullSettings) {
    Object.assign(this, init);
  }
}
