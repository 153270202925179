export enum KpiTranslationKeysMapping {
  //Groups
  'DistributionInput' = 'bi.bi-widgets.groups.distribution-input',
  'Operability' = 'bi.bi-widgets.groups.operability',
  'WaterLoss' = 'bi.bi-widgets.groups.water-loss',
  'RealLoss' = 'bi.bi-widgets.groups.real-loss',
  'ApparentLoss' = 'bi.bi-widgets.groups.apparent-loss',
  'Financial' = 'bi.bi-widgets.groups.financial',

  //KPIs
  'DZO' = 'bi.bi-widgets.widgets.algorithms.dzo',

  //WaterBalanceKpis
  'AuthorizedConsumption' = 'bi.bi-widgets.widgets.authorized-consumption',
  'BilledAuthorizedConsumption' = 'bi.bi-widgets.widgets.billed-authorized-consumption',
  'UnbilledAuthorizedConsumption' = 'bi.bi-widgets.widgets.unbilled-authorized-consumption',
  'BilledMeteredConsumption' = 'bi.bi-widgets.widgets.billed-metered-consumption',
  'BilledUnmeteredConsumption' = 'bi.bi-widgets.widgets.billed-unmetered-consumption',
  'UnbilledMeteredConsumption' = 'bi.bi-widgets.widgets.unbilled-metered-consumption',
  'UnbilledUnmeteredConsumption' = 'bi.bi-widgets.widgets.unbilled-unmetered-consumption',
  'UnauthorizedConsumption' = 'bi.bi-widgets.widgets.unauthorized-consumption',
  'CustomerMeteringInaccuracies' = 'bi.bi-widgets.widgets.customer-metering-inaccuracies',
  'DataHandlingErrors' = 'bi.bi-widgets.widgets.data-handling-errors',
  'LeakageTransmissionMains' = 'bi.bi-widgets.widgets.leakage-on-transmission-mains',
  'LeakageDistributionNetwork' = 'bi.bi-widgets.widgets.leakage-on-distribution-network',
  'LeakageOverflowsUtilityStorageTanks' = 'bi.bi-widgets.widgets.leakage-and-overflows-at-utilitys-storage-tanks',
  'RevenueWater' = 'bi.bi-widgets.widgets.revenue-water',
  'NonRevenueWater' = 'bi.bi-widgets.widgets.non-revenue-water',

  'WaterLosses' = 'bi.bi-widgets.widgets.water-loss',
  'RealLosses' = 'bi.bi-widgets.widgets.real-loss',
  'ApparentLosses' = 'bi.bi-widgets.widgets.apparent-loss',
  'SystemInputVolume' = 'bi.bi-widgets.widgets.distribution-input',
  'WaterLossPerMainLength' = 'bi.bi-widgets.widgets.water-loss-per-main-length',
  'WaterLossPerConnection' = 'bi.bi-widgets.widgets.water-loss-per-connection',
  'WaterLossPercentageDI' = 'bi.bi-widgets.widgets.water-loss-as-of-di',
  'RealLossPerMainLength' = 'bi.bi-widgets.widgets.real-loss-per-main-length',
  'RealLossPerConnection' = 'bi.bi-widgets.widgets.real-loss-per-connection',
  'RealLossPercentageDI' = 'bi.bi-widgets.widgets.real-loss-as-of-di',
  'ApparentLossPerMainLength' = 'bi.bi-widgets.widgets.apparent-loss-per-main-length',
  'ApparentLossPerConnection' = 'bi.bi-widgets.widgets.apparent-loss-per-connection',
  'ApparentLossPercentageDI' = 'bi.bi-widgets.widgets.apparent-loss-as-of-di',
  'HydraulicPerformance' = 'bi.bi-widgets.widgets.hydraulic-performance',
  'ILI' = 'bi.bi-widgets.widgets.ili',
  'CARL' = 'bi.bi-widgets.widgets.carl',
  'UARL' = 'bi.bi-widgets.widgets.uarl',

  //WaterBalanceType
  'WaterBalanceTopDown' = 'common.water-balance-types.top-down',
  'WaterBalanceBottomUp' = 'common.water-balance-types.bottom-up',

  //Dimensions
  'Volume' = 'common.dimension-types.volume',
  'Flow' = 'common.dimension-types.flow',
}
