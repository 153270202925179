<div class="top-buttons">
  <wlm-export-pdf-button [exportPdfComponent]="baseMap"></wlm-export-pdf-button>
</div>

<wlm-base-map
  class="map-position"
  [mapParameters]="mapParameters"
  [displayMessage$]="displayMapMessage$"
  (coordinatesClicked)="onCoordinatesClicked($event)"
></wlm-base-map>
