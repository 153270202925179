<mat-toolbar color="primary" class="top-bar" [class.hide-top-bar]="hideTopBar$ | async">
  <mat-toolbar-row>
    <a [routerLink]="''" class="app-title" title="Water Loss">Water Loss</a>
    <div *ngIf="companyLogo" class="top-bar-logo">
      <img [src]="companyLogo" height="32px" alt="logo" />
    </div>

    <div class="top-bar-button">
      <span [hidden]="!rightPanelVisibility">
        <button
          mat-icon-button
          [disabled]="disabledRightPanelButtons"
          color="secondary"
          [matTooltip]="T_SCOPE + '.change-details-visibility' | translate"
          (click)="toggleVisibility()"
        >
          <mat-icon class="top-bar-icon" svgIcon="view-split-vertical-2"></mat-icon>
        </button>
      </span>

      <span [hidden]="rightPanelVisibility">
        <button
          mat-icon-button
          color="secondary"
          [disabled]="disabledRightPanelButtons"
          [matTooltip]="T_SCOPE + '.change-details-visibility' | translate"
          (click)="toggleVisibility()"
        >
          <mat-icon class="top-bar-icon" svgIcon="view-split-vertical"></mat-icon>
        </button>
      </span>

      <!-- Disabled by the moment 
      <span [hidden]="!rightPanelHover">
        <button
          mat-icon-button
          color="secondary"
          [disabled]="disabledRightPanelButtons"
          [matTooltip]="T_SCOPE + '.change-hover' | translate"
          [disabled]="true || !rightPanelVisibility"
          (click)="toggleHover()"
        >
          <mat-icon class="top-bar-icon" svgIcon="wrap"></mat-icon>
        </button>
      </span>

      <span [hidden]="rightPanelHover">
        <button
          mat-icon-button
          color="secondary"
          [matTooltip]="T_SCOPE + '.change-hover' | translate"
          [disabled]="true || !rightPanelVisibility"
          (click)="toggleHover()"
        >
          <mat-icon class="top-bar-icon" svgIcon="wrap-disabled"></mat-icon>
        </button>
      </span>
      -->
      <wlm-top-bar-notification-create-button
        *hasPerm="['WLMNotificationsCrud', 'c']"
      ></wlm-top-bar-notification-create-button>
      <wlm-top-bar-notification-unread-button
        *hasPerm="['WLMNotificationsCrud', 'r']"
      ></wlm-top-bar-notification-unread-button>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        color=""
        [matTooltip]="T_SCOPE + '.user-actions' | translate"
      >
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>

    <div class="menuItems">
      <mat-menu #menu="matMenu" class="account-card-menu">
        <div class="mat-menu-content-x">
          <wlm-top-bar-account-card> </wlm-top-bar-account-card>
        </div>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
