// prettier-ignore
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { IGisElementDto } from 'src/app/common-modules/shared/model/gis/gis-element.dto';
import { SvgIcon } from 'src/app/common-modules/shared/model/shared/svg-icon';
import { CoordinatesSystemService } from '../../shared/gis/coordinates-system.service';
import { GisService } from '../../shared/gis/gis.service';
import { mapDefaults } from '../map-defaults';
import { GisElementKey } from './models/gis-element-key';

const COMPONENT_SELECTOR = 'wlm-map-search';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent implements OnInit {
  @ViewChild('auto') autocomplete: MatAutocomplete;

  private readonly _searchFieldname = 'search';
  private readonly _minLengthToSearch = 3;
  private readonly _enterKey = 'Enter';

  private _isLoading: boolean;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this._cd.detectChanges();
  }

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;
  iconSize = '20';

  form: UntypedFormGroup;
  changeBySelection = false;
  coordinatesDetected = false;
  coordinatesFormatted: string;
  searchControl: AbstractControl;
  gisElements: GisElementKey[];
  defaultIconPath = mapDefaults.iconImgPath;

  @Input() icons: Map<number, SvgIcon> = new Map<number, SvgIcon>();

  @Output()
  public onSearchSelection = new EventEmitter<{
    value: IGisElementDto | [number, number];
    coordinates: boolean;
  }>();

  constructor(
    private _gisService: GisService,
    private _coordinatesSystemService: CoordinatesSystemService,
    private _formBuilder: UntypedFormBuilder,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  onKeyDownEvent($event: KeyboardEvent) {
    if (this.isLoading) {
      $event.preventDefault();
    }

    if ($event.key == this._enterKey && this.isValidSearch()) {
      this.searchGisElement(this.searchControl.value);
    }
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    this.changeBySelection = true;
    this.coordinatesDetected = false;

    // Navigate by coordinates
    if (event.option.value == this.coordinatesFormatted) {
      return this.navigateByCoordinates();
    }

    // Navigate by GisElement
    return this.navigateByGisElement(event.option.value);
  }

  navigateByCoordinates() {
    this._coordinatesSystemService
      .getConvertedCoordinates(this.coordinatesFormatted)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (!result) {
          return;
        }

        // Mapbox uses longitude first
        this.onSearchSelection.emit({
          value: [result.longitude, result.latitude],
          coordinates: true,
        });
      });
  }

  navigateByGisElement(gisElementKey: GisElementKey) {
    const selected = this.gisElements.find(
      (element) => element.gisElementId === gisElementKey?.gisElementId
    );

    if (selected) {
      this._gisService
        .getById(selected.gisElementId)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result) {
            this.onSearchSelection.emit({ value: result, coordinates: false });
          }

          this.gisElements = null;
        });
    }
  }

  displaySearchOption(matOption: any) {
    const gisElement = matOption as GisElementKey;

    if (gisElement?.gisElementId) {
      return gisElement?.gisElementKey;
    }

    return matOption;
  }

  onClose() {
    this.gisElements = null;
    this.coordinatesDetected = false;
  }

  getIcon(gisElement: GisElementKey) {
    if (gisElement.networkElementSubTypeId) {
      return this.icons.get(gisElement.networkElementSubTypeId);
    } else if (gisElement.networkElementTypeId) {
      return this.icons.get(gisElement.networkElementTypeId);
    }

    return false;
  }

  private createForm() {
    this.form = this._formBuilder.group({
      search: [null, []],
    });

    this.setFormControlVariables();
  }

  private setFormControlVariables() {
    this.searchControl = this.form.controls[this._searchFieldname];
    this.searchControl.setValue('');

    this.searchControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(1000))
      .subscribe(() => this.onSearchValueChange());
  }

  private onSearchValueChange() {
    if (this.isLoading) {
      return;
    }

    this.coordinatesDetected = false;

    if (this.changeBySelection) {
      this.changeBySelection = false;
      return;
    }

    const searchText = this.searchControl.value;

    if (this.gisElements?.some((ge) => ge.gisElementKey === searchText)) {
      return;
    }

    if (this.isValidSearch()) {
      this.searchGisElement(searchText);
    }
  }

  private isValidSearch() {
    const searchText = this.searchControl.value?.trim();

    return searchText?.length >= this._minLengthToSearch;
  }

  private searchGisElement(searchText: string) {
    this.isLoading = true;

    // Coordinates
    this.checkForCoordinates();

    // Gis Elements
    this._gisService
      .getByInputSearch(searchText)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.gisElements = result;
        this.isLoading = false;
      });
  }

  private checkForCoordinates() {
    const coordinates = this._coordinatesSystemService.getCoordinatesFormatted(
      this.searchControl.value
    );

    if (!coordinates) {
      return;
    }

    this.coordinatesFormatted = coordinates;
    this.coordinatesDetected = true;
  }
}
