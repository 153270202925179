import { Params } from '@angular/router';
import { NavKeys } from './nav-keys.enum';

export class DropdownNavigationItem {
  key: NavKeys;
  params: Params;
  disabled = false;
  disabledKeyTooltip?: string;
  customNavMethod? = null;
  titleKey?: string;
  avoidParams?: boolean;

  constructor(data: Readonly<DropdownNavigationItem>) {
    Object.assign(this, data);
  }
}
