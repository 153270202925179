export class UtmCoordinates {
  easting: number;
  northing: number;
  formattedValue: string;

  constructor(easting: number, northing: number) {
    this.easting = easting;
    this.northing = northing;
    this.formattedValue = `${easting} - ${northing}`;
  }
}
