<wlm-spinner-overlay-invoker [showSpinner]="isLoading"> </wlm-spinner-overlay-invoker>

<div class="wtr-fx-flex-grow" id="center-page">
  <div class="top-container">
    <h1>{{ T_SCOPE + '.title' | translate }}</h1>
  </div>

  <hr class="separator" />

  <div [ngClass]="{ 'no-pointer-events': isLoading }" class="activity-page"></div>

  <div id="center-container" class="main-container">
    <div class="activity-page-content" *ngIf="raiseActivityTreeFilterConfiguration">
      <form
        novalidate
        [formGroup]="newActivityFormGroup"
        class="form-structure"
        (ngSubmit)="onSave()"
      >
        <div class="tree-content">
          <div>
            <wlm-raise-activity-tree-filter
              [raiseActivityTreeFilterConfiguration]="raiseActivityTreeFilterConfiguration"
              [(selectedNodes)]="selectedNodes"
              [treeSettings]="treeSettings"
              [clearAll$]="clearAll$"
              (filter)="getFiltersDetailsParameters($event)"
              (apply)="onApplyFilter()"
            ></wlm-raise-activity-tree-filter>

            <mat-error
              class="tree-error-text mat-error mat-form-field-subscript-wrapper mat-form-field"
              *ngIf="isMaximumZonesExceeded"
            >
              {{
                T_SCOPE + '.form.maximum-exceeded-message'
                  | translate : { value: maximumSelectedZones }
              }}
            </mat-error>
          </div>
        </div>
        <span></span>
        <div class="form-columns">
          <div class="form-column">
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.house-number' | translate }}</mat-label>
                <input matInput formControlName="houseNumberControl" autocomplete="off" />
                <mat-error *ngIf="newActivityFormGroup.get('houseNumberControl').invalid">
                  {{ 'common.validation.max-length' | translate : { max: houseNumberMaxLength } }}
                </mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.street' | translate }}</mat-label>
                <input matInput formControlName="streetControl" autocomplete="off" />
                <mat-error *ngIf="newActivityFormGroup.get('streetControl').invalid">
                  {{ 'common.validation.max-length' | translate : { max: streetMaxLength } }}
                </mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.street2' | translate }}</mat-label>
                <input matInput formControlName="street2Control" autocomplete="off" />
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.district' | translate }}</mat-label>
                <input matInput formControlName="districtControl" autocomplete="off" />
                <mat-error *ngIf="newActivityFormGroup.get('districtControl').invalid">
                  {{ 'common.validation.max-length' | translate : { max: districtMaxLength } }}
                </mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.city' | translate }}</mat-label>
                <input matInput formControlName="cityControl" autocomplete="off" />
                <mat-error *ngIf="newActivityFormGroup.get('cityControl').invalid">
                  {{ 'common.validation.max-length' | translate : { max: cityMaxLength } }}
                </mat-error>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.propertyId' | translate }}</mat-label>
                <input
                  matInput
                  formControlName="customerSearchControl"
                  (keydown)="onKeyDownEvent($event)"
                  [matAutocomplete]="auto"
                  [placeholder]="'common.search' | translate"
                />
                <mat-icon matSuffix class="default-icon-color">search</mat-icon>
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="onSelectOption($event)"
                  class="autocomplete-options"
                >
                  <mat-option
                    *ngFor="let customer of customersLocated"
                    [value]="customer.propertyId"
                  >
                    <span>{{ customer.propertyId }}</span>
                  </mat-option>
                  <mat-option *ngIf="searchingCustomer">
                    <span class="option-message">{{ 'common.loading' | translate }}</span>
                  </mat-option>
                </mat-autocomplete>

                <mat-error *ngIf="newActivityFormGroup.get('customerSearchControl').invalid">
                  {{ 'common.validation.max-length' | translate : { max: cityMaxLength } }}
                </mat-error>
              </mat-form-field>
            </p>
          </div>
          <div class="form-column">
            <p *ngIf="coordinatesSystem === eastingNorthingSystem">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-icon
                  class="error-icon"
                  color="warn"
                  matSuffix
                  *ngIf="
                    (newActivityFormGroup.get('eastingNorthingControl').touched ||
                      newActivityFormGroup.get('eastingNorthingControl').dirty) &&
                    !newActivityFormGroup.get('eastingNorthingControl').valid &&
                    !isLoading
                  "
                  >error</mat-icon
                >
                <mat-label>{{ T_SCOPE + '.form.easting-northing' | translate }}</mat-label>
                <input
                  [imask]="mask"
                  [unmask]="true"
                  matInput
                  formControlName="eastingNorthingControl"
                  autocomplete="off"
                />
                <mat-error *ngIf="newActivityFormGroup.get('eastingNorthingControl').invalid">
                  {{ 'common.validation.incomplete' | translate }}
                </mat-error>
              </mat-form-field>
            </p>
            <p *ngIf="coordinatesSystem === latLongSystem" class="long-lat">
              <mat-form-field class="" appearance="outline" floatLabel="always">
                <mat-icon
                  class="error-icon"
                  color="warn"
                  matSuffix
                  *ngIf="
                    (newActivityFormGroup.get('longitudeControl').touched ||
                      newActivityFormGroup.get('longitudeControl').dirty) &&
                    !newActivityFormGroup.get('longitudeControl').valid &&
                    !isLoading
                  "
                  >error</mat-icon
                >
                <mat-label>{{ T_SCOPE + '.form.longitude' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="XXX.YYYYY"
                  formControlName="longitudeControl"
                  autocomplete="off"
                  [imask]="maskLong"
                />
                <mat-error *ngIf="newActivityFormGroup.get('longitudeControl').errors?.min">
                  {{ 'common.validation.min-value' | translate : { min: -longitudeMaxValue } }}
                </mat-error>
                <mat-error *ngIf="newActivityFormGroup.get('longitudeControl').errors?.max">
                  {{ 'common.validation.max-value' | translate : { max: longitudeMaxValue } }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" floatLabel="always">
                <mat-icon
                  class="error-icon"
                  color="warn"
                  matSuffix
                  *ngIf="
                    (newActivityFormGroup.get('latitudeControl').touched ||
                      newActivityFormGroup.get('latitudeControl').dirty) &&
                    !newActivityFormGroup.get('latitudeControl').valid &&
                    !isLoading
                  "
                  >error</mat-icon
                >
                <mat-label>{{ T_SCOPE + '.form.latitude' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="XX.YYYYY"
                  formControlName="latitudeControl"
                  autocomplete="off"
                  [imask]="maskLat"
                />
                <mat-error *ngIf="newActivityFormGroup.get('latitudeControl').errors?.min">
                  {{ 'common.validation.min-value' | translate : { min: -latitudeMaxValue } }}
                </mat-error>
                <mat-error *ngIf="newActivityFormGroup.get('latitudeControl').errors?.max">
                  {{ 'common.validation.max-value' | translate : { max: latitudeMaxValue } }}
                </mat-error>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.order-number' | translate }}</mat-label>
                <input matInput formControlName="orderNumberControl" autocomplete="off" />
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.sap-notification-number' | translate }}</mat-label>
                <input matInput formControlName="notificationNumberControl" autocomplete="off" />
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.activity-type' | translate }}</mat-label>
                <mat-select
                  [(value)]="activityTypeId"
                  required
                  formControlName="activityTypeControl"
                >
                  <mat-option
                    *ngFor="let activityType of activityTypes"
                    [value]="activityType.activityTypeId"
                  >
                    {{ activityType.activityTypeName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="newActivityFormGroup.get('activityTypeControl').invalid">
                  {{ 'common.validation.required' | translate }}
                </mat-error>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.sap-code' | translate }}</mat-label>
                <mat-select required formControlName="sapCodeControl">
                  <mat-option *ngFor="let sapCode of sapCodes" [value]="sapCode.codeValue">
                    {{ sapCode.codeValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
          <div class="form-column">
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.sap-code-group' | translate }}</mat-label>
                <mat-select required formControlName="sapCodeGroupControl">
                  <mat-option
                    *ngFor="let sapCodeGroup of sapCodeGroups"
                    [value]="sapCodeGroup.codeGroupValue"
                  >
                    {{ sapCodeGroup.codeGroupValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline">
                <mat-label>{{ T_SCOPE + '.form.notification-text' | translate }}</mat-label>
                <input matInput formControlName="notificationShortTextControl" />
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline" required>
                <mat-icon
                  class="error-icon"
                  color="warn"
                  matSuffix
                  *ngIf="
                    (newActivityFormGroup.get('notificationLongTextControl').touched ||
                      newActivityFormGroup.get('notificationLongTextControl').dirty) &&
                    !newActivityFormGroup.get('notificationLongTextControl').valid &&
                    !isLoading
                  "
                  >error</mat-icon
                >
                <mat-label>{{ T_SCOPE + '.form.notification-long-text' | translate }}</mat-label>
                <textarea
                  matInput
                  formControlName="notificationLongTextControl"
                  required
                  class="not-long-text"
                ></textarea>
                <mat-error *ngIf="newActivityFormGroup.get('notificationLongTextControl').invalid">
                  {{ 'common.validation.required' | translate }}
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div class="ar-buttons-container">
          <button
            *hasPerm="['ActivityRaisingCrud', 'u']"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="isSaveDisabled()"
          >
            {{ 'common.save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
