export class ChartCustomLegendItem {
  label?: string;
  labelKey?: string;
  color?: string;
  icon: string;
  isSvgIcon: boolean;

  constructor(init: Readonly<ChartCustomLegendItem>) {
    Object.assign(this, init);
  }
}
