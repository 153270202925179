import { BaseLink } from './base-link';
import { MenuLink } from './menu-link';

/**
 * The ModuleLink, as it is consumed by Kendo Drawer.
 */
export class ModuleLink extends BaseLink {
  menuLinks: Array<MenuLink>;
  icon: string;
  shortTitle: string;
  useModuleDot = false;

  constructor(init?: Partial<ModuleLink>) {
    super(init);
    Object.assign(this, init);
  }
}
