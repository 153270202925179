import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateRangeFISettings } from 'src/app/common-modules/common-filters/models/date-range-fi-settings';
import { FilterItemSummaryRow } from 'src/app/common-modules/common-filters/models/filter-item-summary-row';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SettingsService } from 'src/app/common-modules/shared/config/settings.service';
import { DateValidators } from 'src/app/common-modules/shared/date-range-filter/date-validator';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';
import { AdaptedFilterItem } from '../../components/core/hooks/adapted-filter-item';
import { FilterHookAfterParentListening } from '../../components/core/hooks/filter-hook-after-parent-listening';
import { BaseFilterItemComponent } from '../../core/base-filter-item/base-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-date-range-filter-item';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-range-filter-item.component.html',
  styleUrls: ['./date-range-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => DateRangeFilterItemComponent),
    },
  ],
})
export class DateRangeFilterItemComponent
  extends BaseFilterItemComponent
  implements OnInit, FilterHookAfterParentListening, AdaptedFilterItem
{
  @Input() startFieldName: string;
  @Input() endFieldName: string;
  @Input() allowsDateNull: boolean;
  @Input() displayHorizontal = false;
  @Input() defaultDateRange: DateRange;
  @Input() maxRangeSelected: number;

  private _clearAll$: Observable<void>;
  public get clearAll$(): Observable<void> {
    return this._clearAll$;
  }
  @Input() public set clearAll$(value: Observable<void>) {
    this._clearAll$ = value;
    if (this._clearAll$) {
      this._clearAll$.pipe(untilDestroyed(this)).subscribe(() => this.setClear());
    }
  }

  dateRangeValues: DateRange;
  filtered$ = new Subject<FiltersPayload>();
  summaryItems: FilterItemSummaryRow[] = [];

  private _dateRange: DateRange;

  public get dateRange(): DateRange {
    return this._dateRange;
  }

  @Input() public set dateRange(v: DateRange) {
    this._dateRange = v;
    this.dateRangeValues = v;
    this.initializeFormGroup();
  }

  private defaultSettings = new BaseFilterItemSettings({});
  @Input() public set settings(value: BaseFilterItemSettings) {
    if (value) {
      const settings = Object.assign(this.defaultSettings, value);
      super.settings = Object.assign(super.settings, settings);
    }
  }

  dateRangeForm: UntypedFormGroup;

  minDate = new Date(1990, 1, 1);
  maxDate = new Date();
  formClass: string;
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  valid = false;
  private initialStatus: DateRange;

  constructor(
    private _adapter: DateAdapter<any>,
    private localization: LocalizationHelperService,
    private filtersAdapterService: FiltersAdapterService,
    private dateHelperService: DateHelperService,
    private settingsService: SettingsService
  ) {
    super();
    this._adapter.setLocale(this.localization.currentLocale);
  }

  ngOnInit(): void {
    this.formClass = this.displayHorizontal ? 'date-range-form-horizontal' : 'date-range-form';
  }

  private initializeFormGroup() {
    this.dateRangeForm = new UntypedFormGroup(
      {
        start: new UntypedFormControl(this.initialStartDate(), [Validators.required]),
        end: new UntypedFormControl(this.initialEndDate()),
      },
      {
        validators: DateValidators.dateLessThan(
          'start',
          'end',
          this.dateHelperService.ensureDateObject
        ),
      }
    );

    if (this.maxRangeSelected) {
      this.dateRangeForm.addValidators(
        DateValidators.datePeriodMaximumRange(
          'start',
          'end',
          this.maxRangeSelected,
          this.dateHelperService.ensureDateObject
        )
      );
    }

    this.dateRangeForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.dateRangeChange();
      },
    });

    this.dateRangeChange(FiltersPayloadStatus.Initial);
  }

  public dateRangeChange(status = FiltersPayloadStatus.Normal): void {
    if (!this.dateRangeForm) {
      this.filtered$.next(null);
      return;
    }

    if (this.dateRangeForm.valid) {
      this.valid = true;
      const start = this.dateRangeForm.get('start')?.value;
      const end = this.dateRangeForm.get('end')?.value;
      const truncateStart = this.dateHelperService.truncateDate(start);
      const truncateEndDate = this.dateHelperService.truncateDate(end);

      this.dateRangeValues = new DateRange(
        truncateStart,
        truncateEndDate,
        this.startFieldName,
        this.endFieldName,
        this.allowsDateNull
      );

      const dateRange = this.dateRangeValues.clone();
      if (this.settingsService.increaseEndDate && dateRange?.end) {
        dateRange.end.setDate(dateRange.end.getDate() + 1);
      }
      const payload = this.buildFilter(dateRange, status);
      this.filtered$.next(payload);

      // Build the summary item like the input summary.
      this.updateItemSummary();
    } else {
      this.valid = false;
      // If not valid, must emit empty filters in order to reset them.
      this.dateRangeValues = new DateRange(
        null,
        null,
        this.startFieldName,
        this.endFieldName,
        this.allowsDateNull
      );
      const payload = this.buildFilter(this.dateRangeValues, status);
      this.filtered$.next(payload);
    }
  }

  cloneDate(aDate: Date) {
    return new Date(aDate);
  }

  private updateItemSummary(): void {
    this.getFormattedRange().subscribe((result) => {
      this.summaryItems = [new FilterItemSummaryRow(result)];
    });
  }

  /**
   * Converts a valid date range to an array of BasicFilter.
   */
  buildFilter(range: DateRange, status = FiltersPayloadStatus.Normal): FiltersPayload {
    const basicFilters = this.filtersAdapterService.convertDateRangeToBasicFilters(range);
    const payload = this.buildPayload(basicFilters, status);
    return payload;
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  setClear(): void {
    if ((this.settings as DateRangeFISettings)?.disableRestoreOnFixed) {
      const emptyDateRange = this.defaultDateRange.cloneWithValues(null, null);
      this.loadDateModel(emptyDateRange);
      this.dateRangeValues = emptyDateRange;
    } else {
      this.loadDateModel(this.defaultDateRange);
    }
    this.updateItemSummary();
  }

  private loadDateModel(dateModel: DateRange) {
    const start = dateModel?.start ?? null;
    const end = dateModel?.end ?? null;
    this.dateRangeForm.get('start').setValue(start);
    this.dateRangeForm.get('end').setValue(end);
    this.dateRangeChange();
    this.summaryItems = [];
  }

  setSelectAll(): void {}

  getFiltered$(): Observable<FiltersPayload> {
    return this.filtered$.asObservable();
  }

  fhAfterParentListening(): void {
    this.dateRangeChange(FiltersPayloadStatus.Initial);
  }

  /**
   * Builds the 2/10/20 - 3/10/20 part of the date summary.
   */
  getFormattedRange(): Observable<string> {
    return this.localization.get(`${this.T_SCOPE}.empty-date`).pipe(
      switchMap((emptyLabel) => {
        const start = this.dateRangeValues?.start
          ? this.localization.formatDate(this.dateRangeValues?.start, DateFormats.Date)
          : emptyLabel;
        const end = this.dateRangeValues?.end
          ? this.localization.formatDate(this.dateRangeValues?.end, DateFormats.Date)
          : emptyLabel;
        return this.localization.get(`${this.T_SCOPE}.date-range`, { start, end });
      })
    );
  }

  getStateFormatted(): Observable<string> {
    if (super.settings?.hideInputSummary) {
      return of('');
    }
    if (super.settings?.hideInputSummaryLabel) {
      return this.getFormattedRange();
    }
    return forkJoin([this.localization.get(this.inputSummaryKey), this.getFormattedRange()]).pipe(
      map(([inputSummay, dateRange]) => {
        const result = `${inputSummay}${dateRange}`;
        return result;
      })
    );
  }

  isValid(): boolean {
    return this.valid;
  }

  setRestoreState(): void {
    this.loadDateModel(this.initialStatus);
    this.getFormattedRange().subscribe((result) => {
      this.summaryItems = [new FilterItemSummaryRow(result)];
    });
  }

  setInitialState(): void {
    this.initialStatus = new DateRange(
      this.dateRangeValues.start,
      this.dateRangeValues.end,
      this.dateRangeValues.startFieldName,
      this.dateRangeValues.endFieldName,
      null,
      this.dateRangeValues.inclusive
    );
  }

  getFieldNames(): string[] {
    if (this.startFieldName === this.endFieldName) {
      return [`${this.startFieldName}#StartDate`, `${this.endFieldName}#EndDate`];
    }
    return [this.startFieldName, this.endFieldName];
  }

  private initialStartDate(): Date {
    let start = this.dateHelperService.ensureDateObject(this.dateRangeValues?.start);
    if (start && start < this.minDate) {
      start = this.minDate;
    }
    return start;
  }

  private initialEndDate(): Date {
    let end = this.dateHelperService.ensureDateObject(this.dateRangeValues?.end);
    if (end?.toString() === new Date(null).toString()) {
      end = null; // Was '' before.
    }
    if (end > this.maxDate) {
      end = this.maxDate;
    }
    return end;
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.DateRange;
  }
}
