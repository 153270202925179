<div *ngIf="selectedNotification" class="grid-container">
  <div class="filter-buttons-container">
    <button
      mat-icon-button
      class="button-position"
      (click)="genericGrid.clearFilters()"
      [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
    >
      <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
    </button>

    <wlm-export-excel-button
      [visibleColumns]="gridSettings?.gridColumnSettings"
      [grid]="genericGrid"
    ></wlm-export-excel-button>

    <wlm-export-pdf-button [exportPdfComponent]="genericGrid"></wlm-export-pdf-button>
  </div>

  <wlm-generic-grid
    class="wtr-fx-flex-grow height-minus-buttons"
    *ngIf="additionalFilters"
    [gridSettings]="gridSettings"
    [gridFiltersForBinding]="filters"
    [additionalFilters]="additionalFilters"
  ></wlm-generic-grid>
</div>
