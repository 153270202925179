<wlm-map-tooltip-item *ngIf="currentModel" [model]="currentModel">
  <div paginator class="map-tooltip-paginator">
    <button mat-icon-button (click)="setPrevious()">
      <wlm-icon icon="drop-left" size="xs"></wlm-icon>
    </button>
    <div>{{ currentModelIndex + 1 }} / {{ models?.length }}</div>
    <button mat-icon-button (click)="setNext()">
      <wlm-icon icon="drop-right" size="xs"></wlm-icon>
    </button>
  </div>
</wlm-map-tooltip-item>
