import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { ALCActivityDto } from 'src/app/water-loss/features/shared/model/alc/alc-activity.dto';
import { DryHoleTypesDto } from '../dry-hole-types.dto';
import { ILeakageTypeGroupDto } from '../leakage-type-group.dto';
import { LeakageTypesDto } from '../leakage-type.dto';
import { ALCLeaksDto } from '../leaks.dto';

const url = '/api/alc/leaks';
@Injectable()
export class LeaksService extends GridODataService<ALCLeaksDto> {
  constructor(injector: Injector) {
    super(injector, url);
  }

  protected mapResponse(response: PagedResultDto<ALCLeaksDto>): PagedResultDto<ALCLeaksDto> {
    return response;
  }

  getLeakTypes(): Observable<LeakageTypesDto[]> {
    const leakageTypesUrl = `${url}/types`;

    const leakageTypes = this.httpCacheClient.get<LeakageTypesDto[]>(
      `${this.baseUrl}${leakageTypesUrl}`
    );
    return leakageTypes;
  }

  getLeakTypeGroups(): Observable<ILeakageTypeGroupDto[]> {
    const leakageTypesUrl = `/api/integration/leak-type-groups`;
    return this.httpCacheClient.get<ILeakageTypeGroupDto[]>(`${this.baseUrl}${leakageTypesUrl}`);
  }

  getDryHoleTypes(): Observable<DryHoleTypesDto[]> {
    const dryHoleTypesUrl = `${url}/dry-hole-types`;

    const dryHoleTypes = this.httpCacheClient.get<DryHoleTypesDto[]>(
      `${this.baseUrl}${dryHoleTypesUrl}`
    );
    return dryHoleTypes;
  }

  getParentActivity(activityId: string): Observable<ALCActivityDto> {
    if (!activityId) {
      return null;
    }

    return this.httpCacheClient.get(`${this.baseUrl}${url}/${activityId}`, {
      avoid: true,
    });
  }
}
