import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { TabDetailPanelParameters } from '../navigation/tab-detail-component';
import { FiltersPayload } from './filters-payload';

export class FilterAdapterResult {
  dbFilters: DataBindingFilters;
  tabParams: TabDetailPanelParameters;
  payload: FiltersPayload;
  changedFields: Map<string, boolean>;
  completed: boolean;

  constructor(init: {
    dbFilters: DataBindingFilters;
    tabParams: TabDetailPanelParameters;
    changedFields?: Map<string, boolean>;
    payload?: FiltersPayload;
    completed?: boolean;
  }) {
    Object.assign(this, init);
    this.payload = init.payload ?? new FiltersPayload({});
    this.changedFields = init.changedFields ?? new Map<string, boolean>();
    this.completed = init.completed === undefined ?? false;
  }
}
