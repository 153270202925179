import { Component, Input, OnInit } from '@angular/core';
import { ButtonTogglerItemSettings, ButtonTogglerSettings } from './button-toggler-settings';

const COMPONENT_SELECTOR = 'wlm-button-toggler';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './button-toggler.component.html',
  styleUrls: ['./button-toggler.component.scss'],
})
export class ButtonTogglerComponent implements OnInit {
  @Input() settings: ButtonTogglerSettings;

  constructor() {}

  ngOnInit(): void {}

  onToggle(event: MouseEvent, item: ButtonTogglerItemSettings) {
    event.preventDefault();
  }
}
