<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="'common.clear-filter' | translate | lowercase"
        onclick="this.blur()"
        (click)="onResetFilters()"
      >
        <mat-icon svgIcon="reset-filters"></mat-icon>
      </button>
      <button
        *ngIf="settings?.showResetIcon"
        mat-icon-button
        color="primary"
        onclick="this.blur()"
        (click)="onResetLayout()"
        [matTooltip]="settings?.labelReset | lowercase"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout [settings]="settings"></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
