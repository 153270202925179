<div id="center-page" *ngIf="!hideComponent">
  <div class="filter-buttons-container">
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [gridSettings]="gridSettings && !hideFilterButtons"
      (gridSettingsChange)="onGridSettingsChange($event)"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="true"
      (btnClick)="onClickGridBtns($event)"
    >
    </wlm-grid-buttons>
  </div>
  <div id="center-container">
    <wlm-ne-selection-grid-filter
      *ngIf="neSelectionConfiguration"
      [ngClass]="neSelectionConfiguration.cssClass"
      [filterConfiguration]="neSelectionConfiguration"
      [clearAll$]="clearAll$"
      (filtersChanged)="onFiltersChange($event)"
      (apply)="onApply()"
      (autoLoad)="onCheckAutoload()"
      class="grid-filters"
    ></wlm-ne-selection-grid-filter>
    <wlm-generic-grid
      id="genericGrid"
      [ngStyle]="{ display: neTypeFilterIsValid ? '' : 'none' }"
      class="generic-grid"
      [ngClass]="gridCssClass"
      [persistencyArea]="persistencyArea"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [removeSelection$]="removeSelection$"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      (selectedPersistedItemsChanged)="onselectedPersistedItemsChanged()"
      [(selectedItems)]="selectedNEs"
      [showSelectedList]="true"
      [showSelectedItems]="false"
      storageLocation="session"
    ></wlm-generic-grid>
  </div>
</div>
