<wlm-base-filter-container
  *ngIf="treeSettings && adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  class="filter-container"
  (filterResults)="filtersResultChanged($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters filters-background filter-alignment">
    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bfTreeSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-family-filter-item
        [fieldName]="config.hierarchyElementFamilyFieldName"
        [selectedIds]="[config.hierarchyElementFamily]"
        [filterText]="filterText"
        [settings]="hierarchyFamilyItemSettings"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>
      <wlm-hierarchy-tree-filter-item
        [hierarchyElementIdFieldName]="config.hierarchyElementIdFieldName"
        [hierarchyFamilyIdFieldName]="config.hierarchyElementFamilyFieldName"
        [hierarchyFamilyId]="hierarchyElementFamilyId"
        [initialSelectedKeys]="config.hierarchyElementId"
        [filterText]="filterText"
        [(treeSettings)]="treeSettings"
        [settings]="hierarchyTreeItemSettings"
        (filterReady)="onTreeFilterReady()"
      ></wlm-hierarchy-tree-filter-item>
    </wlm-base-filter>

    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bfDateSettings"
      [persistItems$]="persistFilters$"
    >
      <wlm-date-range-filter-item
        [startFieldName]="config.startDateFieldName"
        [endFieldName]="config.endDateFieldName"
        [dateRange]="config.dateRange"
        [defaultDateRange]="config.defaultDateRange"
        [allowsDateNull]="config.allowsDateNull"
        [settings]="dateRangeItemSettings"
      ></wlm-date-range-filter-item>
    </wlm-base-filter>

    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bfTypeSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-water-balance-types-filter-item
        [fieldName]="config.waterBalanceTypeFieldName"
        [selectedId]="config.waterBalanceType"
        [defaultSelectedId]="config.defaultWaterBalanceType"
        [filterText]="filterText"
        [settings]="wbTypeItemSettings"
      ></wlm-water-balance-types-filter-item>
    </wlm-base-filter>

    <wlm-base-filter
      *ngIf="treeSettings !== null"
      [settings]="bfDimensionSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-dimension-types-filter-item
        [includeOnlyTypeIds]="config.wbDimensionTypeIds"
        [fieldName]="config.waterBalanceDimensionFieldName"
        [selectedId]="config.waterBalanceDimension"
        [defaultSelectedId]="config.defaultWaterBalanceDimension"
        [filterText]="filterText"
        [settings]="wbDimensionItemSettings"
      ></wlm-dimension-types-filter-item>
    </wlm-base-filter>
  </div>
</wlm-base-filter-container>
