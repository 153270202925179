import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { NetworkElementsSignalsDto } from '../../shared/model/signals/network-elements-signals.dto';
import { NoBoundaryConfigurationDto } from '../../shared/model/signals/no-boundary-configuration.dto';

const endpointUrl = '/api/no-boundary';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationNoBoundaryService extends GridODataService<NetworkElementsSignalsDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<NetworkElementsSignalsDto>
  ): PagedResultDto<NetworkElementsSignalsDto> {
    return response;
  }

  getBoundarySignalsByElement(networkElementId: string): Observable<NetworkElementsSignalsDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${networkElementId}`, {
      avoid: true,
    });
  }

  saveBoundaryConfiguration(
    noBoundaryConfiguration: NoBoundaryConfigurationDto
  ): Observable<boolean> {
    return this.httpCacheClient
      .post(`${this.baseUrl}${endpointUrl}/save`, noBoundaryConfiguration)
      .pipe(map(() => true));
  }
}
