<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="emptyFilters"
      [grid]="grid"
      [gridName]="gridName"
      [disable$]="gridBtnsDisable$"
      (gridSettingsChange)="onGridSettingsChanged($event)"
    >
    </wlm-grid-buttons>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings"
      [dynamicGridSettings]="dynamicGridSettings"
      (selectedItemChanged)="getSelectedElement($event)"
      (genericGridLoaded)="getGenericGridInstance($event)"
      (gridSettingsLoaded)="getGridSettings($event)"
      [dataBindingFilters]="emptyFilters"
      [reloadGrid$]="reloadGrid$"
      [removeSelection$]="removeSelection$"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [additionalFilters]="additionalFilters"
      [gridSettingsChanged$]="gridSettingsChanged$"
      [showSelectedList]="false"
      [showSelectedItems]="false"
    ></wlm-dynamic-grid>
  </ng-container>
</wlm-page-wrapper>
