import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';

export class HierarchyElementPathFilter implements IFilter {
  hierarchyFamilyId: string;
  elementIds: string[];
  hierarchyFamilyIdFieldName: string;
  elementIdFieldName: string;
  elementsWithNames?: SelectOption<string>[];

  constructor(
    hierarchyFamilyId: string,
    elementIds: string[],
    hierarchyFamilyIdFieldName: string,
    elementIdFieldName: string,
    elementsWithNames?: SelectOption<string>[]
  ) {
    this.hierarchyFamilyId = hierarchyFamilyId;
    this.elementIds = elementIds;
    this.hierarchyFamilyIdFieldName = hierarchyFamilyIdFieldName;
    this.elementIdFieldName = elementIdFieldName;
    this.elementsWithNames = elementsWithNames;
  }

  getSelectedFilters() {
    return;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([
      [this.hierarchyFamilyIdFieldName, this.hierarchyFamilyId],
      [this.elementIdFieldName, this.elementIds],
    ]);
  }

  getFilters(): CompositeFilterDescriptor {
    const filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

    if (this.hierarchyFamilyIdFieldName) {
      filter.filters.push({
        field: this.hierarchyFamilyIdFieldName,
        operator: 'eq',
        value: this.hierarchyFamilyId,
      });
    }

    // Just return the family filter if no he filter applied.
    if (!this.elementIds.length) {
      return filter;
    }

    const heFilter: CompositeFilterDescriptor = { logic: 'or', filters: [] };

    this.elementIds.forEach((id) =>
      heFilter.filters.push({ field: this.elementIdFieldName, operator: 'eq', value: id })
    );

    filter.filters.push(heFilter);

    return filter;
  }
}
