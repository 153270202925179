<div *ngIf="rangeColors?.length">
  <form [formGroup]="colorForm" *ngIf="colorForm">
    <div class="range-container">
      <div class="range" *ngFor="let rangeId of rangeIds">
        <!-- This style needs to be applied in the html because if we use a class the input goes wrong -->
        <div class="color-block">
          <div
            style="width: 32px; height: 32px; margin: 8px; border-radius: 8px"
            [colorPicker]="getFormColor(rangeId)"
            (colorPickerChange)="setColor($event, rangeId)"
            [style.background]="getFormColor(rangeId)"
          ></div>
          <button
            mat-icon-button
            onclick="this.blur()"
            [disabled]="rangeIds?.length <= minRanges"
            (click)="removeRangeColor(rangeId)"
            [matTooltip]="T_SCOPE + '.delete-range' | translate | lowercase"
          >
            <mat-icon class="material-icons-round">close</mat-icon>
          </button>
        </div>

        <mat-form-field class="range-input" appearance="outline">
          <mat-label>{{ rangeLabel | async }}</mat-label>
          <input
            matInput
            type="number"
            [formControlName]="rangeControlPrefix + rangeId"
            autocomplete="off"
            (input)="onRangeChanged(rangeId)"
          />
          <mat-error *ngIf="colorForm.get(rangeControlPrefix + rangeId)?.errors?.required">{{
            'common.validation.required' | translate
          }}</mat-error>
          <mat-error
            *ngIf="
              !colorForm.get(rangeControlPrefix + rangeId)?.errors?.required &&
              colorForm.get(rangeControlPrefix + rangeId)?.errors?.rangesOrder
            "
            >{{ 'common.validation.invalid-range-value' | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <button
        mat-icon-button
        class="add-button"
        color="primary"
        [disabled]="rangeIds?.length >= maxRanges"
        onclick="this.blur()"
        (click)="addRange()"
        [matTooltip]="T_SCOPE + '.add-range' | translate | lowercase"
      >
        <mat-icon class="material-icons-round">add</mat-icon>
      </button>
    </div>
  </form>
</div>
