import { Injector } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { BaseService } from '../../base.service';
import { SettingsService } from '../../config/settings.service';

/**
 * Implements a cache that works at execution time, by using observables.
 * It can coexist with other methods of caching.
 */

export abstract class RuntimeCacheService<T> extends BaseService {
  defaultRefreshInterval = 8.64e7; // One day.
  cacheSize = 1; // n values to replay.

  // Contains the cached data at runtime.
  private _cache$: Observable<T>;

  protected settingsService: SettingsService;

  constructor(injector: Injector) {
    super(injector);
    this.settingsService = injector.get(SettingsService);
  }

  /**
   * Specify how the data to cache is obtained. Usually will be an HttpClient.get.
   */
  protected abstract requestData(): Observable<T>;

  /**
   * Access the cached data.
   */
  getCachedData(): Observable<T> {
    if (!this._cache$) {
      // The timer controls cache expiration, forcing to rebuild the shareReplay.
      const timer$ = timer(0, this.getRefreshInterval());

      // sharedReplay handles a ReplaySubject internally, so it will always return the same subscription until the cache is expired.
      this._cache$ = timer$.pipe(
        switchMap((_) => this.requestData()),
        shareReplay(this.cacheSize)
      );
    }
    return this._cache$;
  }

  private getRefreshInterval(): number {
    const { defaultCacheDurationMinutes } = this.settingsService;
    let refreshIntervalMs = this.defaultRefreshInterval;
    if (defaultCacheDurationMinutes !== null && defaultCacheDurationMinutes !== undefined) {
      refreshIntervalMs = defaultCacheDurationMinutes * 60 * 1000;
    }
    return refreshIntervalMs;
  }
}
