// prettier-ignore
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GridDataResult, PagerInputComponent } from '@progress/kendo-angular-grid';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { asEnumerable } from 'linq-es2015';
import { Observable, Subscription } from 'rxjs';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { GridHelperService } from '../../shared/helpers/grid-helper.service';
import { GridColumnSetting } from '../../shared/model/grid/grid-column-setting';
import { GridBindingDirective } from '../../shared/odata/grid-binding.directive';
import { GridODataService } from '../../shared/odata/grid-odata.service';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { TimeControlBarDto } from '../model/time-control-bar.dto';
import { GridEditionService } from '../services/grid-edition.service';
import { GridPersistedElements } from './generic-grid-constants';

@UntilDestroy()
@Component({
  selector: 'wlm-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss'],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericGridComponent extends BaseGridComponent implements OnInit {
  @ViewChild(GridBindingDirective) public gridBinding: GridBindingDirective;
  @ViewChild('gridContainer') set onGridContainerElement(gridContainerElement: ElementRef) {
    this._gridHelperService.attachGridHeaderButtons(gridContainerElement);
  }

  @Output() gridFiltersChanged = new EventEmitter<DataBindingFilters>();
  @Output() isGridReady = new EventEmitter<boolean>();

  service: GridODataService<any>;
  oldGridFilters: DataBindingFilters;
  heColumns: GridColumnSetting[];
  isProcessingSelectAll = false;
  gridSelectionCacheDuration = 1440; // default 1 day
  resetPageOnReloadGrid = true;
  mustRestoreSelectedRow = false;
  previousClickedRow: any;
  previousCurrentSelection: string[];
  defaultDigitsInfo: string;
  currentLocale: string;

  private _gridSettings: GridSetting;

  public get gridSettings(): GridSetting {
    return this._gridSettings;
  }
  @Input() public set gridSettings(v: GridSetting) {
    if (v) {
      this._gridSettings = v;
      this._gridSettings.selectionPersistency = this._gridSettings.selectionPersistency ?? true;

      this.initializeService();
      this.setTimeControlDates();

      this.setBackgroundColorMapping();
      this.initColumns();
      this.persistGridSettings(v);
    }
  }

  private _removeSelectionPersisted$: Observable<void>;
  public get removeSelectionPersisted$(): Observable<void> {
    return this._removeSelectionPersisted$;
  }
  @Input() public set removeSelectionPersisted$(value: Observable<void>) {
    this._removeSelectionPersisted$ = value;

    if (this.removeSelectionPersisted$) {
      this.removeSelectionPersisted$.pipe(untilDestroyed(this)).subscribe(() => {
        this.clearSelectionPersisted();
      });
    }
  }

  _removeSelectionSub$: Subscription;

  private _removeSelection$: Observable<void>;
  public get removeSelection$(): Observable<void> {
    return this._removeSelection$;
  }
  @Input() public set removeSelection$(value: Observable<void>) {
    this._removeSelection$ = value;

    if (this._removeSelectionSub$) {
      this._removeSelectionSub$.unsubscribe();
    }

    this._removeSelectionSub$ = this.removeSelection$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearSelectAllSelection();
    });
  }

  private _additionalFilters: Map<string, string>;
  public get additionalFilters(): Map<string, string> {
    return this._additionalFilters;
  }
  @Input() public set additionalFilters(v: Map<string, string>) {
    this._additionalFilters = v;
  }

  private _gridFiltersForBinding: DataBindingFilters;
  public get gridFiltersForBinding(): DataBindingFilters {
    return this._gridFiltersForBinding;
  }
  @Input() public set gridFiltersForBinding(v: DataBindingFilters) {
    if (v && !this.areFiltersEquals(v)) {
      this._gridFiltersForBinding = v;

      if (this.resetPageOnReloadGrid) {
        this.clearRowSelected();
        this.resetSelectedPage();
      }
      this.gridFiltersChanged.emit(this.gridFiltersForBinding);
      this.updateHEColumns(this._gridFiltersForBinding);
      this.isGridReady.emit(true);
    }
  }

  @ViewChild(PagerInputComponent) set queryPagerInputComponent(value: PagerInputComponent) {
    if (value) {
      value.numericInput.format = {
        useGrouping: false,
      };
    }
  }

  constructor(
    injector: Injector,
    gridEditionService: GridEditionService,
    private readonly _gridHelperService: GridHelperService
  ) {
    super(injector, gridEditionService);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}
  endLoading(): void {}
  startLoading(): void {}
  setNavigationParameters(parameters: TabDetailPanelParameters): void {}

  ngOnInit(): void {
    const decimalPositions = this._settingsService.maxDecimalPositions;
    this.defaultDigitsInfo = `.${decimalPositions}-${decimalPositions}`;
    this.currentLocale = this._dateFormatsService.currentLocale;
  }

  initializeService() {
    this.service = this._injector.get(this.gridSettings?.service);
  }

  initColumns() {
    this.setCurrencySymbol();
    this.setGridSorting();
    this.setGridFilters();
    this.setGridPagesize();
    this.setInitialPagesize();
    this.columns = [...this.gridSettings?.gridColumnSettings];
    this.loadHEColumns();
    this.updateHEColumns(this.gridFiltersForBinding);
  }

  setTimeControlDates() {
    if (this.gridSettings?.showHistoricalComparison) {
      this.service['getLatestCalculationDay']?.().subscribe((date: Date) => {
        this.initialControlDates = new TimeControlBarDto({
          baseGridDate: date,
        });

        // this notifies that the grid is using the latest calculation date initially
        this.notifyUseLatestCalculationDay.emit(this.useLatestCalculationDay);
        this._cd.detectChanges();
      });
    }
  }

  loadHEColumns() {
    if (
      this.gridSettings?.showZoneColumns &&
      this.gridSettings?.gridColumnSettings?.filter((x) => x.type === 'hierarchy').length === 0
    ) {
      this.gridHelperService.getHierarchyColumns().subscribe((col) => {
        this.columns = this.columns.concat(col);
      });
    }
  }

  updateHEColumns(gridFilters: DataBindingFilters) {
    if (gridFilters && this.gridSettings?.showZoneColumns) {
      const familyId = this.getFamilyIdFromFilter(gridFilters);
      if (familyId) {
        this.columns = this.gridHelperService.getVisibleColumnsByFamily(
          familyId,
          this.gridSettings.gridColumnSettings
        );
      }
    }
  }

  getFamilyIdFromFilter(gridFilters: DataBindingFilters): string {
    const filter = gridFilters?.filters?.get(this.gridSettings?.familyIdFilterName);

    if (!filter) {
      return '';
    }

    return filter[this.defaultFamilyFieldName] ?? filter['familyId'] ?? '';
  }

  reloadGrid() {
    if (this.gridFiltersForBinding) {
      this.regenerateBinding();
      this.resetSelectedPage();
      this._cd?.detectChanges();
    }
  }

  reloadAndSelectGrid() {
    if (this.gridFiltersForBinding) {
      //this flags are used to keep the row selected
      this.mustRestoreSelectedRow = true;
      this.resetPageOnReloadGrid = false;

      this.regenerateBinding();

      this.resetPageOnReloadGrid = true;

      this._cd?.detectChanges();
    }
  }

  ongridDataLoaded() {
    if (this.mustRestoreSelectedRow) {
      this.restoreSelectedRow();
    }
    this.gridDataLoaded.emit();

    this._cd?.detectChanges();
  }

  private restoreSelectedRow() {
    const selectField = this.gridSettings?.selectByFieldName;

    if (selectField && this.previousCellClickHandlerParams) {
      this.previousCellClickHandlerParams.dataItem = (this.grid?.data as GridDataResult).data.find(
        (item) => item[selectField] === this.previousCellClickHandlerParams?.dataItem?.[selectField]
      );

      this.cellClickHandler(this.previousCellClickHandlerParams);
    }

    this.mustRestoreSelectedRow = false;
  }

  private regenerateBinding() {
    const newGridFilters = new DataBindingFilters();
    newGridFilters.filters = this.gridFiltersForBinding.filters;
    this._gridFiltersForBinding = null;
    this.gridFiltersForBinding = newGridFilters;
  }

  public loadSelectedItemsByKeys(filter: DataBindingFilters) {
    this.gridBinding?.getQueryByFilterOnly(filter).subscribe((data) => {
      this.selectedItems = data.items;
      this.selectedRows = data.items;

      const ids = data.items.map((m) => m[this.gridSettings?.selectByFieldName]);
      this.currentSelection = ids;
      this._cd?.detectChanges();
    });
  }

  public onSelectAllChange(checkedState) {
    if (!this.gridSettings) {
      return;
    }

    const checked = checkedState?.target?.checked;
    this.isProcessingSelectAll = true;
    const currentPageData = (this.grid?.data as GridDataResult)?.data ?? [];
    this._spinnerService.setLoading(true, this.gridSettings.service as string);
    this.selectAllValue = checked;

    if (checked) {
      this.selectAllProcess(currentPageData);
    } else {
      this.unselectAllProcess(currentPageData);
    }
  }

  private unselectAllProcess(currentPageData: any[]) {
    // first remove the elements of the current page before call getSelectAll() method.
    this.selectedRows = this.removeElementsFromSelectedRows(currentPageData, this.selectedRows);
    this.currentSelection = this.selectedRows.map((m) => m[this.gridSettings.selectByFieldName]);

    this.gridBinding.getSelectAll().subscribe((dataItems) => {
      // remove all the items returned
      this.selectedRows = this.removeElementsFromSelectedRows(dataItems.items, this.selectedRows);

      this.processSelectedRows();
      this._spinnerService.setLoading(false, this.gridSettings.service as string);
    });
  }

  private removeElementsFromSelectedRows(elementsToRemove: any[], currentSelection: any[]) {
    const elementsToRemoveKeys = elementsToRemove.map(
      (m) => m[this.gridSettings.selectByFieldName]
    );
    return currentSelection.filter(
      (f) => !elementsToRemoveKeys.includes(f[this.gridSettings.selectByFieldName])
    );
  }

  private selectAllProcess(currentPageData: any[]) {
    // first we add the elements of the current page
    this.selectedRows = this.addNewElementsToSelectedRows(currentPageData, this.selectedRows);
    this.currentSelection = this.selectedRows.map(
      (item) => item[this.gridSettings.selectByFieldName]
    );

    this.gridBinding.getSelectAll().subscribe((dataItems) => {
      // here we add all the rest of the elements returned
      this.selectedRows = asEnumerable(
        this.addNewElementsToSelectedRows(dataItems.items, this.selectedRows)
      )
        .Distinct((x) => x[this.gridSettings.selectByFieldName])
        .ToArray();
      this.processSelectedRows();

      this._spinnerService.setLoading(false, this.gridSettings.service as string);
    });
  }

  private addNewElementsToSelectedRows(elementsToAdd: any[], currentSelection: any[]) {
    // elementsToAdd array is filtered to avoid duplicated when combined with the current selectedRows
    const filteredElementsToAdd = elementsToAdd.filter(
      (f) =>
        !currentSelection
          .map((x) => x[this.gridSettings.selectByFieldName])
          .includes(f[this.gridSettings.selectByFieldName])
    );

    return this.objectHelperService.getCombinedArrays(filteredElementsToAdd, this.selectedRows);
  }

  private processSelectedRows() {
    this.persistSelectedRows();
    this.selectedItemsChange.emit(this.selectedRows);
    const itemsFiltered = this.selectedRows.map(
      (item) => item[this.gridSettings.selectByFieldName]
    );
    this.ngZone.run(() => {
      this.isProcessingSelectAll = false;
      this.currentSelection = itemsFiltered;
      this.calculateSelectAllState();
    });
  }

  setTotalCount(count: number) {
    if (this.gridFiltersForBinding !== this.oldGridFilters) {
      this.oldGridFilters = this.gridFiltersForBinding;
    }
    this.fitColumns();
    this.totalCount = count;
    this.setSelectedRows();
  }

  clearFilters() {
    if (this.gridBinding) {
      this.gridBinding.sort = this.gridSettings?.sort;
      this.gridBinding.clearFilters();
    }

    if (this.gridSettings) {
      this.persistGridSettings(this.gridSettings);
    }

    this.clearRowSelected();
    this.removeStorage(GridPersistedElements.Filter);
    this.removeStorage(GridPersistedElements.Sort);
    this.removeStorage(GridPersistedElements.Pagesize);

    this.fitColumns();
  }

  canClearFilters() {
    return this.gridFiltersForBinding;
  }

  private resetSelectedPage() {
    this.gridBinding?.resetPage();
  }

  private areFiltersEquals(newFilters: DataBindingFilters): boolean {
    return this.objectHelperService.deepEqual(newFilters, this._gridFiltersForBinding);
  }
}
