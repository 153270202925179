import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatsService } from './date-formats.service';

/**
 * Extends the default date pipe to link it to localization config.
 * Is if because of the refresh that this can be sync.
 */

@Pipe({
  name: 'date',
})
export class ExtendDatePipe implements PipeTransform {
  constructor(private dateFormatsService: DateFormatsService) {}

  transform(
    value: string | number | Date | null,
    formatKey?: string, // DateFormats = DateFormats.DateTime,
    timezone?: string
  ): string | null {
    const format = this.dateFormatsService.getFormat(formatKey);
    const transformed = formatDate(value, format, this.dateFormatsService.currentLocale, timezone);
    return transformed;
  }
}
