import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-image-column',
  templateUrl: './image-column.component.html',
  styleUrls: ['./image-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => ImageColumnComponent),
    },
  ],
})
export class ImageColumnComponent extends WlmColumnComponent implements OnInit {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {}
}
