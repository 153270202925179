export class NotificationFilterConfigurationDto {
  // User filter
  userFieldName: string;
  currentUser: string; // user logged
  selectedUser?: string[]; // users filtered
  defaultSelectedUser?: string[];
  persistencyArea?: string;

  constructor(init?: Partial<NotificationFilterConfigurationDto>) {
    Object.assign(this, init);
  }
}
