import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGridSettings } from '../constants/grid.constants';
import { GridColumnHelperService } from '../helpers/grid-column-helper.service';
import { GridHelperService } from '../helpers/grid-helper.service';
import { GridColumnSetting } from '../model/grid/grid-column-setting';
import { NotificationAttributeDto } from '../model/notifications/notification-attribute.dto';
import { NotificationDetailsDto } from '../model/notifications/notification-details.dto';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NotificationSelectionService {
  private zonesSelected = new BehaviorSubject<string[]>([]);
  private alarmsSelected = new BehaviorSubject<string[]>([]);

  private _lastRowSelected: any;
  private _lastGridSettings: IGridSettings;
  private _lastColumnsToShow: GridColumnSetting[];

  private _notificationDetails = new BehaviorSubject<NotificationDetailsDto>(null);

  constructor(
    private _gridColumnHelper: GridColumnHelperService,
    private _gridHelperService: GridHelperService
  ) {}

  setZonesSelected(value: string[]) {
    this.zonesSelected.next(value);
  }

  setAlarmsSelected(value: string[]) {
    this.alarmsSelected.next(value);
  }

  getZonesSelected(): Observable<any> {
    return this.zonesSelected.asObservable();
  }

  getAlarmsSelected(): Observable<any> {
    return this.alarmsSelected.asObservable();
  }

  getCurrentSelection(): Observable<NotificationDetailsDto> {
    return this._notificationDetails.asObservable();
  }

  cleanNotificationDetails() {
    this._notificationDetails.next(null);
  }

  setNotificationDetailsFromGrid(rowData: any, gridSettings: IGridSettings) {
    const excludedTypes = this._gridHelperService.getColumnTypesNonNotificable();
    this._lastRowSelected = rowData;
    this._lastGridSettings = gridSettings;

    // This removes the non-exportable columns
    this._lastGridSettings.gridColumnSettings = this._lastGridSettings.gridColumnSettings.filter(
      (f) => !excludedTypes.includes(f.type)
    );

    this._lastColumnsToShow = this._lastGridSettings.gridColumnSettings.filter(
      (x) => x.isNotificationDetail && x.visible
    );

    if (!this._lastColumnsToShow?.length || !rowData) {
      this.cleanNotificationDetails();
    } else {
      this._gridColumnHelper
        .processRowDataValues(this._lastRowSelected, this._lastColumnsToShow)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (simpleColumns) => {
            const attributes = simpleColumns.map(
              (sc) =>
                new NotificationAttributeDto({
                  notificationAttributeId: sc.columnId,
                  notificationAttributeName: sc.columnTitle,
                  notificationAttributeValue: sc.value,
                })
            );
            const notificationDetails = new NotificationDetailsDto({
              attributes,
              zoneId:
                this._lastRowSelected[this._lastGridSettings.notificationSetting.zoneIdColumnName],
              fromNavKey: this._lastGridSettings.notificationSetting.navKey,
            });
            this._notificationDetails.next(notificationDetails);
          },
        });
    }
  }
}
