import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseKeyService } from '../../shared/base-key.service';
import { IHierarchyElementDto } from './hierarchy-element.dto';

@Injectable({
  providedIn: 'root',
})
export class HierarchyService extends BaseKeyService<string, IHierarchyElementDto> {
  protected getElements(): Observable<IHierarchyElementDto[]> {
    const elements = this.getCached<IHierarchyElementDto[]>('he/all');
    return elements;
  }

  protected getKey(model: IHierarchyElementDto): string {
    return model.hierarchyElementId;
  }

  constructor(injector: Injector) {
    super(injector);
  }
}
