import { Injectable } from '@angular/core';
import { DateFormats } from './date-formats.enum';

/**
 * Helper service that helps with the communication between LocalizationHelperService and ExtendDatePipe.
 * Contains the corresponding formats of the DateFormats enum keys, which values are setted depending on the specified locale.
 */

@Injectable({
  providedIn: 'root',
})
export class DateFormatsService {
  private formats: Map<string, string> = new Map<string, string>();
  // Also save current locale because ExtendDatePipe needs it.
  public currentLocale: string;

  /**
   * Sets one of the available date formats. The formats are different for every locale, so they must be changed at runtime.
   */
  setFormat(key: string, format: string): void {
    if (!this.validFormat(key)) {
      console.error(`'${key}' is not a valid date format key specified in the DateFormats enum.`);
      return;
    }
    this.formats.set(key, format);
  }

  /**
   * Get an available date format, if is already configured.
   */
  getFormat(key: string = DateFormats.DateTime): string {
    if (!this.validFormat(key)) {
      console.error(`'${key}' is not a valid date format key specified in the DateFormats enum.`);
      return null;
    }
    const format = this.formats.get(key);
    if (!format) {
      console.error(`The format '${key}' is valid, but it has not been configured.`);
      return null;
    }
    return format;
  }

  private validFormat = (key) => Object.values(DateFormats).includes(key);
}
