import { AfterViewInit, Component, Inject, Injector, Input, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
// prettier-ignore

import { Images } from 'src/assets/icons/previous-icons/images';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '../../dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '../../redux/models/state-widget-settings';
import { BaseWidgetComponent } from '../../shared/component/base-widget.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-pipe-grid';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './pipe-grid.component.html',
  styleUrls: ['./pipe-grid.component.scss'],
})
export class PipeGridComponent extends BaseWidgetComponent implements AfterViewInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  clearFiltersIcon: string = Images.IconClearFilter;
  filters: DataBindingFilters;
  filter: CompositeFilterDescriptor;
  UOMfield = 'distance';
  actualWorkUnit: number;
  reasonForVariance: string | null;
  columnStyle = new Map<string, string>();
  gridSettings: GridSetting;

  private _elementId: string;
  public get elementId(): string {
    return this._elementId;
  }
  @Input() public set elementId(v: string) {
    this._elementId = v;
    this.decimalPositions = 2;
    this.init();
  }

  public distanceUomTitle;
  public conversionFactors: number[];
  public decimalPositions?: number;
  public distanceBaseTitle: 'Distance';
  public columnKey = 'id';

  @ViewChild(GenericGridComponent) public grid: GenericGridComponent;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _gridSettingsService: GridSettingsService
  ) {
    super(injector, widgetSettings);
    this._gridSettingsService.getGridSettingsByName('Pipes').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.elementId = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
  }

  init(): void {
    this.setDataBindingFilter();
  }

  ngAfterViewInit(): void {}

  private setDataBindingFilter() {
    if (!this.elementId) return;
    const auxFilter = new DataBindingFilters();
    auxFilter.addOrUpdateBasicFilter('elementId', this.elementId);
    this.filters = auxFilter;
  }

  showGridHeaderTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TH' ||
        (element.nodeName === 'SPAN' && element.offsetParent.nodeName === 'TH')) &&
      !element.firstElementChild?.classList?.contains('k-checkbox')
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  get componentName() {
    return 'PipeGridComponent';
  }
}
