import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

const COMPONENT_SELECTOR = 'wlm-custom-input';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  host: { class: 'custom-formly-field' },
})
export class CustomInputComponent extends FieldType<any> implements OnInit {
  private readonly _zeroCharCode = 48;
  private readonly _nineCharCode = 57;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  onKeyPress = ({ charCode }): boolean => {
    return charCode >= this._zeroCharCode && charCode <= this._nineCharCode;
  };
}
