export enum SettingsDataSource {
  HierarchyElementAttribute = 1,
  NetworkElementAttribute = 2,
  AlgorithmAttribute = 3,
  HierarchyCustomerAttribute = 4,
  Reservoir = 11,
}

export const settingsDataSourceStrMapping = new Map<string, SettingsDataSource>([
  ['HierarchyElementAttribute', SettingsDataSource.HierarchyElementAttribute],
  ['NetworkElementAttribute', SettingsDataSource.NetworkElementAttribute],
  ['AlgorithmAttribute', SettingsDataSource.AlgorithmAttribute],
  ['HierarchyCustomerAttribute', SettingsDataSource.HierarchyCustomerAttribute],
  ['Reservoir', SettingsDataSource.Reservoir],
]);
