import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { IHierarchyElementPathDto } from 'src/app/common-modules/dependencies/he/hierarchy-element-path.dto';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FiltersAdapterService } from 'src/app/common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from 'src/app/common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';

import { RaiseActivityTreeFilterConfiguration } from './raise-activity-tree-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-raise-activity-tree-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './raise-activity-tree-filter.component.html',
  styleUrls: ['./raise-activity-tree-filter.component.scss'],
})
export class RaiseActivityTreeFilterComponent implements OnInit {
  @Input() raiseActivityTreeFilterConfiguration: RaiseActivityTreeFilterConfiguration;
  @Input() selectedNodes: IHierarchyElementPathDto[];
  @Input() treeSettings: ITreeSettings;
  @Input() clearAll$ = new Subject<void>();
  @Input() persistFilters$ = new ReplaySubject<void>();

  @Output() applySelection = new EventEmitter<IHierarchyElementPathDto[]>();
  @Output() errorChanged = new EventEmitter<boolean>();
  @Output() selectedNodesChange = new EventEmitter<IHierarchyElementPathDto[]>();
  @Output() apply = new EventEmitter<void>();
  @Output() filter = new EventEmitter<Map<string, BasicFilter>>();

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
    applyFiltersTextKey: `${this.T_SCOPE}.apply-button-text`,
    isRequired: false,
  };

  bfSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-label`,
    disableSelectAll: true,
    clearAllTextKey: `${this.T_SCOPE}.clear-all-text`,
  };

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
    storageLocation: 'session',
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'session',
  });

  filterText: string;
  mustPersistFilters = false;
  isFirstLoad = true;
  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;
  apply$ = new Subject<void>();

  constructor(private adaptersService: FiltersAdapterService) {}

  ngOnInit(): void {
    this.bfSettings.persistencyArea = this.raiseActivityTreeFilterConfiguration.persistencyArea;
  }

  onFilterText(filterText: string): void {
    this.filterText = filterText;
  }

  onApplyFilters(): void {
    this.selectedNodesChange.emit(this.selectedNodes);
    this.mustPersistFilters = true;
    this.apply.emit();
  }

  /**
   * Saves the family Id so the tree filter can obtain it.
   * Only contains the activity Id filter.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName =
      this.raiseActivityTreeFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.raiseActivityTreeFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onFilterGroup(filters: FiltersPayload) {
    this.filter.emit(filters.data);
    this.selectedNodesChange.emit(this.selectedNodes ?? []);
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters || this.isFirstLoad) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
      this.isFirstLoad = false;
    }
  }

  /**
   * When clear all is triggered, trigger the apply method.
   */
  onClearAll(): void {
    this.apply$.next();
  }
}
