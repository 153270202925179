import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { IDeletedZonesDto } from 'src/app/water-loss/features/shared/model/deleted-zones/deleted-zones.dto';

@Injectable({
  providedIn: 'root',
})
export class DeletedZonesService extends GridODataService<IDeletedZonesDto> {
  constructor(injector: Injector) {
    super(injector, '/api/deleted-zones');
  }

  protected mapResponse(
    response: PagedResultDto<IDeletedZonesDto>
  ): PagedResultDto<IDeletedZonesDto> {
    return response;
  }
}
