import { AbstractControl } from '@angular/forms';

export class DataVisualizationConfigFormValidators {
  static maxPeriod(getForm: () => AbstractControl, maxFieldName: string) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const form = getForm();

      const maxValue = form.get(maxFieldName)?.value ?? null;

      if (isNaN(maxValue) || maxValue === null) {
        return null;
      }

      if (+c.value > +maxValue) {
        c.setErrors({ maxPeriod: true });
        return { maxPeriod: true };
      }

      return null;
    };
  }
}
