export class GroupDto {
  groupId?: number;
  groupName: string;
  groupDescription: string;

  constructor(groupName: string, groupDescription: string, groupId?: number) {
    this.groupName = groupName;
    this.groupDescription = groupDescription;
    this.groupId = groupId;
  }
}
