<wlm-spinner-wrapper [showSpinner]="loading">
  <div [formGroup]="form" class="fixed-subscript">
    <wlm-collapsible-container [titleKey]="T_SCOPE + '.target-title'" *ngIf="allUsers">
      <div class="row">
        <div class="col-xs-12">
          <wlm-related-element-list
            [allElements]="allUsers"
            [excludeValues]="selectedUserIds"
            [useExternalExclude]="true"
            formControlName="to"
            [title]="fieldScope + '.users' | translate"
            [required]="true"
            [placeHolderText]="T_SCOPE + '.add-user-placeholder' | translate"
          ></wlm-related-element-list>
        </div>
        <!-- Do not delete -->
        <!-- <div class="col-xs-12 col-sm-4">
          <wlm-related-element-list
            [allElements]="allGroups"
            [useExternalExclude]="true"
            formControlName="groups"
            [title]="fieldScope + '.groups' | translate"
            [placeHolderText]="T_SCOPE + '.add-group-placeholder' | translate"
          ></wlm-related-element-list>
        </div> -->
      </div>
    </wlm-collapsible-container>
    <wlm-collapsible-container [titleKey]="T_SCOPE + '.info-title'">
      <div class="row">
        <div class="col-xs-6 col-sm-4">
          <mat-form-field [appearance]="fieldAppearance">
            <mat-label>{{ fieldScope + '.title' | translate }}</mat-label>
            <input matInput formControlName="notificationTitle" autocomplete="off" />
            <mat-error>
              <wlm-validation-messages
                [control]="form.controls.notificationTitle"
              ></wlm-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-6 col-sm-4">
          <mat-form-field [appearance]="fieldAppearance">
            <mat-label>{{ fieldScope + '.criticality' | translate }}</mat-label>
            <mat-select formControlName="criticality">
              <mat-option *ngFor="let severity of severities" [value]="severity.value">
                {{ severity.label }}
              </mat-option>
            </mat-select>
            <mat-error>
              <wlm-validation-messages
                [control]="form.controls.criticality"
              ></wlm-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-6 col-sm-4">
          <mat-form-field [appearance]="fieldAppearance">
            <mat-label>{{ fieldScope + '.target-date' | translate }}</mat-label>
            <input
              matInput
              [min]="defaultTargetDate"
              [matDatepicker]="targetDatePicker"
              formControlName="targetDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle matSuffix [for]="targetDatePicker"> </mat-datepicker-toggle>
            <mat-datepicker #targetDatePicker></mat-datepicker>
            <mat-error>
              <wlm-validation-messages
                [control]="form.controls.targetDate"
              ></wlm-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field [appearance]="fieldAppearance">
            <mat-label>{{ fieldScope + '.description' | translate }}</mat-label>
            <textarea
              matInput
              rows="4"
              formControlName="notificationDescription"
              autocomplete="off"
            ></textarea>
            <mat-error>
              <wlm-validation-messages
                [control]="form.controls.notificationDescription"
              ></wlm-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </wlm-collapsible-container>
    <wlm-collapsible-container [titleKey]="T_SCOPE + '.details-title'">
      <wlm-local-grid
        *ngIf="attributesGridSettings && attributes?.length"
        class="generic-grid kendo-grid-no-overflow"
        [gridSettings]="attributesGridSettings"
        [gridData]="attributes"
      ></wlm-local-grid>
    </wlm-collapsible-container>
  </div>
</wlm-spinner-wrapper>
