import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';

@Component({
  selector: 'wlm-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent implements OnInit {
  overlay: Observable<string>;
  visibility: Observable<boolean>;
  collapsed: Observable<boolean>;

  constructor(private rightPanelService: RightPanelService) {
    this.overlay = this.rightPanelService.overlayObservable();
    this.visibility = this.rightPanelService.visibilityObservable();
    this.collapsed = this.rightPanelService.collapsedObservable();
  }

  ngOnInit(): void {}
}
