import { GChartTextStyles } from './g-chart-text-styles';

export class GChartSerieMarkAreaItem {
  xAxis: any;
  name?: string;
  itemStyle?: GChartTextStyles;

  constructor(init?: Readonly<GChartSerieMarkAreaItem>) {
    Object.assign(this, init);
  }
}
