export class MapperFunctions {
  static readonly ToBoolean = 'toBoolean';
  static readonly ToUoMName = 'toUoMName';
  static readonly ToReadableDate = 'toReadableDate';
  static readonly ToUoM = 'ToUoM';
  static readonly ToAnyUoM = 'ToAnyUoM';
  static readonly ToActive = 'ToActive';
  static readonly ToFixed = 'ToFixed';
  static readonly ToJointStrings = 'ToJointStrings';
  static readonly ToAlgorithmUoM = 'ToAlgorithmUoM';
  static readonly ToTranslatedString = 'ToTranslatedString';
}
