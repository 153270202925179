import { Component, Input, inject } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ISapCodeDto } from 'src/app/common-modules/dependencies/alc/sap-code.dto';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { ActivityTypesCrudService } from '../../activity-types/activity-types-crud.service';
import { SapCodeGroupsCrudService } from '../sap-code-groups-crud.service';

@Component({
  selector: 'wlm-sap-codes-form',
  templateUrl: './sap-codes-form.component.html',
  styleUrls: ['./sap-codes-form.component.scss'],
})
export class SapCodesFormComponent extends IntegrableForm {
  @Input() initialModel: ISapCodeDto;
  model: ISapCodeDto;

  private readonly _activityTypesService = inject(ActivityTypesCrudService);
  private readonly _sapCodeGroupsService = inject(SapCodeGroupsCrudService);

  onModelChange(model: ISapCodeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'codeId',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Code Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'codeValue',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Code Value',
            required: true,
          },
          validators: {
            validation: [{ name: 'maxLength', options: { maxLength: 4 } }],
          },
        },
        {
          key: 'codeGroupId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Code Group',
            required: true,
            valueProp: 'codeGroupId',
            labelProp: 'codeGroupValue',
            options: this._sapCodeGroupsService.getAll(),
          },
        },
        {
          key: 'activityTypeId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Activity Type',
            valueProp: 'activityTypeId',
            labelProp: 'activityTypeName',
            options: this._activityTypesService.getAll(),
          },
        },
        {
          key: 'isProactive',
          type: 'checkbox',
          className: 'col-12 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Proactive?',
            required: true,
          },
        },
      ],
    },
  ];
}
