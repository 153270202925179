import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { EditableChartDataParameters } from '../models/editable-chart/editable-chart-data-parameters';
import { EditableChartPointDto } from '../models/editable-chart/editable-chart-point.dto';
import { EditableChartSelection } from '../models/editable-chart/editable-chart-selection';
import { EditableChartSerieDto } from '../models/editable-chart/editable-chart-serie.dto';
import { EditableChartSettings } from '../models/editable-chart/editable-chart-settings';
import { BrushMode } from '../models/generic-chart-settings/g-chart-brush';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericChartSettings } from '../models/generic-chart-settings/generic-chart-settings';
import {
  GChartBrushArea,
  GChartBrushEndEvent,
} from '../models/generic-events/g-chart-brush-end-event';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { BaseEditableChartService } from '../services/base-editable-chart.service';
import { GenericChartService } from '../services/generic-chart.service';

const COMPONENT_SELECTOR = 'wlm-editable-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './editable-chart.component.html',
  styleUrls: ['./editable-chart.component.scss'],
  providers: [GenericChartService],
})
export class EditableChartComponent extends BaseChartComponent implements OnInit {
  @ViewChild(GenericChartComponent) genericChart: GenericChartComponent;

  @Input() public disableInnerLoading = true;

  private _chartSettings: EditableChartSettings;
  public get chartSettings(): EditableChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(value: EditableChartSettings) {
    this._chartSettings = value;

    if (value) {
      this.initializeChart();
      this.loadChart();
    }
  }

  @Input() activateBrushMode$: Observable<BrushMode>;
  @Input() updatePoints$: Observable<EditableChartPointDto[]>;
  @Input() discardAll$: Observable<void>;
  @Input() saveAll$: Observable<any>;
  @Input() updateRange$: Observable<GChartBrushArea[]>;
  @Input() applyValidationFunction$: Observable<EditableChartSelection>;
  @Input() size: IElementSize;

  @Output() saveCompleted = new EventEmitter<boolean>();
  @Output() hasChanges = new EventEmitter<boolean>();
  @Output() selectPoint = new EventEmitter<EditableChartPointDto>();
  @Output() selectRange = new EventEmitter<GChartBrushArea[]>();
  @Output() chartLoaded = new EventEmitter<boolean>();

  private _dataService: BaseEditableChartService;

  private _currentChartPoints: EditableChartPointDto[];
  private _originalChartPoints: EditableChartPointDto[];
  private _selectedPoint: EditableChartPointDto;

  genericChartSettingsSubscription: Subscription;
  customSettings: GenericCartesianChartSettings;
  dataLoaded: boolean;

  constructor(
    _injector: Injector,
    private _objectHelperService: ObjectHelperService,
    private _arrayHelperService: ArrayHelperService,
    private _dialogService: DialogService
  ) {
    super(_injector);
  }

  ngOnInit(): void {
    this.bindActivateBrushMode();
    this.bindUpdateBrushAreas();
    this.bindUpdatePointsEvent();
    this.bindDiscardAllEvent();
    this.bindSaveAllEvent();
    this.bindApplyValidationFunction();
  }

  protected getSerieData(params: EditableChartDataParameters): Observable<any> {
    return this._dataService.getData(params);
  }

  public getGenericChartSettings(
    dataParameters?: EditableChartDataParameters
  ): Observable<GenericCartesianChartSettings> {
    return this.getSerieData(dataParameters ?? this.chartSettings.dataParameters).pipe(
      switchMap((data: EditableChartSerieDto) => {
        this._currentChartPoints = data?.points;
        this._originalChartPoints = this._objectHelperService.clone(data?.points);

        return this._dataService.mapDataToGenericSettings(data);
      }),
      switchMap((settings) =>
        this.genericChartService.getDefaultCartesianChart(settings as GenericCartesianChartSettings)
      ),
      map((defaultSettings) => {
        this._dataService.setSettings(defaultSettings);
        return this._dataService.extendsGenericCartesianChartSettings(defaultSettings);
      })
    );
  }

  public setDateParams(
    startDate: Date,
    endDate: Date,
    dataParameters?: EditableChartDataParameters
  ) {
    this.setDateRange(startDate, endDate);
    this.resetChart();
    this.loadChart(dataParameters ?? this.chartSettings.dataParameters);
  }

  public getParams(): EditableChartDataParameters {
    return this.chartSettings.dataParameters;
  }

  public setParams(newParams: EditableChartDataParameters) {
    this.chartSettings.dataParameters = newParams;
  }

  onElementClick(event: GChartClickEvent): void {
    const selectedKey = event?.data?.extraProperties['id'];
    if (!selectedKey) {
      return;
    }

    const selectedPoint = this._currentChartPoints.find((p) => p.id === selectedKey);
    this.setSelectedPoint(selectedPoint);
  }

  onChartBrushEnd(event: GChartBrushEndEvent) {
    this.selectRange.emit(event.areas);
  }

  getCurrentSerie = () => {
    return this.customSettings.series?.[0];
  };

  private setSelectedPoint(point: EditableChartPointDto) {
    this._selectedPoint = point;
    this.selectPoint.emit(point);
  }

  private instanceDataService() {
    this._dataService = this._injector.get(this.chartSettings?.dataParameters?.dataService);
  }

  private initializeChart() {
    this.resetChart();
    this.instanceDataService();
  }

  private resetChart() {
    this.genericChartSettingsSubscription?.unsubscribe();

    this.customSettings = null;
    this._selectedPoint = null;
    this.dataLoaded = false;
  }

  private loadChart(dataParameters?: EditableChartDataParameters) {
    this.setLoading(true);

    this.genericChartSettingsSubscription = this.getGenericChartSettings(dataParameters)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (settings) => {
          this.hasChanges.emit(false);
          this.setExportTitle(settings);
          this.customSettings = settings;

          this.dataLoaded = true;
          this.setLoading(false);
        },
        error: (error) => {
          this.hasChanges.emit(false);
          this.customSettings = null;

          this.dataLoaded = true;
          this.setLoading(false);
          throw error;
        },
      });
  }

  private setExportTitle(settings: GenericChartSettings) {
    if (settings) {
      settings.exportFileName = this._chartSettings?.exportFileName;
    }
  }

  private bindActivateBrushMode() {
    this.activateBrushMode$.pipe(untilDestroyed(this)).subscribe((mode: BrushMode) => {
      const echartInstance = this.genericChart?.getChartInstance();
      echartInstance?.dispatchAction({
        type: 'brush',
        command: 'clear',
        areas: [],
      });

      echartInstance?.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: mode ?? false,
          brushMode: 'multiple',
        },
      });
    });
  }

  private bindUpdateBrushAreas() {
    this.updateRange$.pipe(untilDestroyed(this)).subscribe((areas: GChartBrushArea[]) => {
      this.clearSelectedAreas();

      const echartInstance = this.genericChart?.getChartInstance();
      const nativeAreaDefinition = [];
      areas.forEach((area) => {
        if (area.brushType === BrushMode.Horizontal) {
          nativeAreaDefinition.push({
            brushType: area.brushType,
            xAxisIndex: 0,
            coordRange: area.range,
          });
        } else {
          nativeAreaDefinition.push({
            brushType: area.brushType,
            yAxisIndex: 0,
            coordRange: area.range,
          });
        }
      });

      echartInstance?.dispatchAction({
        type: 'brush',
        areas: nativeAreaDefinition,
      });
    });
  }

  private bindUpdatePointsEvent() {
    this.updatePoints$
      .pipe(untilDestroyed(this))
      .subscribe((points: EditableChartPointDto[]) => this.updateChartData(points));
  }

  private bindDiscardAllEvent() {
    this.discardAll$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.reloadChart();
    });
  }

  private bindSaveAllEvent() {
    this.saveAll$.pipe(untilDestroyed(this)).subscribe((element) => {
      this.setLoading(true);

      this._dataService
        .saveData(this._currentChartPoints, this._originalChartPoints, element)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            if (response) {
              this.reloadChart();
            }

            this.setLoading(false);
            this.saveCompleted.next(response);
          },
          error: (error) => {
            this.setLoading(false);
            this._dialogService.showErrorMessage(error);
          },
        });
    });
  }

  private bindApplyValidationFunction() {
    this.applyValidationFunction$
      .pipe(untilDestroyed(this))
      .subscribe((validationFunction: EditableChartSelection) => {
        this.clearSelectedAreas();

        this.setLoading(true);

        this._dataService
          .getDataByRangeAndFunction(
            this._currentChartPoints,
            validationFunction,
            validationFunction.element
          )
          .subscribe((points: EditableChartPointDto[]) => {
            this.updateChartData(points);

            this.setLoading(false);
          });
      });
  }

  private clearSelectedAreas() {
    const echartInstance = this.genericChart?.getChartInstance();
    if (echartInstance) {
      echartInstance?.dispatchAction({
        type: 'brush',
        command: 'clear',
        areas: [],
      });
    }
  }

  private reloadChart() {
    this.resetChart();
    this.setSelectedPoint(null);
    this.loadChart();
    this.hasChanges.emit(false);
  }

  private checkHasChanges(): void {
    const getKey = (item: EditableChartPointDto) => item.id;

    const originalSorted = this._arrayHelperService.sortObjects(this._originalChartPoints, getKey);
    const currentSorted = this._arrayHelperService.sortObjects(this._currentChartPoints, getKey);

    const hasChanges = !this._objectHelperService.deepEqual(originalSorted, currentSorted);

    this.hasChanges.emit(hasChanges);
  }

  private updateChartData(points: EditableChartPointDto[]) {
    // Set as edited
    points.forEach((point) => {
      const index = this._currentChartPoints.findIndex((p) => p.id == point.id);

      index >= 0 ? (this._currentChartPoints[index] = point) : this._currentChartPoints.push(point);
    });

    this.checkHasChanges();

    const serie = this.getCurrentSerie();

    // Update chart serie
    this._dataService
      .updateChartSerieByPoints(serie, points)
      .pipe(untilDestroyed(this))
      .subscribe((dataPoints) => {
        serie.dataPoints = dataPoints;

        this._dataService.updateVerticalAxisByDataPoints(this.customSettings, dataPoints);
        this.genericChart.updateSeries(this.customSettings.series);
      });
  }
}
