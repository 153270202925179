import { Injectable, Injector } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { Observable, combineLatest, map } from 'rxjs';
import { CVChartSerieDto } from 'src/app/common-modules/dependencies/cv/models/cv-chart-serie.dto';
import { BaseFiltrableItemService } from 'src/app/common-modules/shared/charts/base-filtrable-item.service';
import { FiltrableItemManagerService } from 'src/app/common-modules/shared/charts/filtrable-item-manager.service';
import { ChartSerie } from 'src/app/common-modules/shared/charts/model/chart/chart-serie';
import { IPlotableSerie } from 'src/app/common-modules/shared/charts/model/series/plotable-serie';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { ChartConfiguration } from 'src/app/water-loss/features/shared/charts/model/chart-configuration';
import { AlgorithmFiltrableItemQueryDto } from 'src/app/water-loss/features/shared/model/filtrable-items/algorithm-filtrable-item-query.dto';
import { SignalFiltrableItemQueryDto } from 'src/app/water-loss/features/shared/model/filtrable-items/signal-filtrable-item-query.dto';
import { AlgorithmFiltrableType } from 'src/app/water-loss/features/shared/model/filtrable-items/types/algorithm-filtrable-type';
import { SignalFiltrableType } from 'src/app/water-loss/features/shared/model/filtrable-items/types/signal-filtrable-type';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { BaseCartesianChartService } from '../services/base-cartesian-chart.service';
import { CustomizableChartMapperService } from './customizable-chart-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class CustomizableChartService extends BaseCartesianChartService {
  constructor(
    injector: Injector,
    private _filtrableItemManagerService: FiltrableItemManagerService,
    private _chartSettingsMapperService: CustomizableChartMapperService,
    private _dateHelperService: DateHelperService
  ) {
    super(injector);
  }

  public getData(chartConfiguration: ChartConfiguration): Observable<any> {
    const series = chartConfiguration.seriesConfiguration;
    const serieQueries$: Observable<ChartSerie[]>[] = [];

    this.getAlgorithmQueries(series, serieQueries$);
    this.getSignalQueries(series, serieQueries$);

    return combineLatest(serieQueries$).pipe(
      map((series) => {
        return { chartConfiguration, series: asEnumerable(series).SelectMany().ToArray() };
      })
    );
  }

  private getAlgorithmQueries(series: IPlotableSerie[], serieQueries$: Observable<ChartSerie[]>[]) {
    var groupedAlgorithms = asEnumerable(series)
      .Select((x) => x)
      .Where((x) => x.serieDefinition.filtrableType.type == FiltrableItemTypeEnum.Algorithm)
      .GroupBy(
        (x) =>
          `${new Date(x.serieDefinition.endDate).toDateString()}-${new Date(
            x.serieDefinition.startDate
          ).toDateString()}-${
            (x.serieDefinition.filtrableType as AlgorithmFiltrableType).element.elementId
          }`
      )
      .ToDictionary(
        (x) => x.key,
        (x) => Array.from(x.values())
      );

    type AlgorithmPayload = {
      query: AlgorithmFiltrableItemQueryDto;
      service: BaseFiltrableItemService;
      type: AlgorithmFiltrableType;
      series: IPlotableSerie[];
    };
    var algorithmQueries: AlgorithmPayload[] = [];

    Array.from(groupedAlgorithms.values()).forEach((plotableSeries: IPlotableSerie[]) => {
      let query: AlgorithmPayload;
      plotableSeries.forEach((plotableSerie) => {
        if (!query) {
          const service = this._filtrableItemManagerService.getService(
            plotableSerie.serieDefinition.filtrableType.type
          );
          query = {
            query: service.getQuery(plotableSerie.serieDefinition),
            service,
            type: plotableSerie.serieDefinition.filtrableType as AlgorithmFiltrableType,
            series: [],
          };
        } else {
          query.query.extraParams.algorithms.push(
            (plotableSerie.serieDefinition.filtrableType as AlgorithmFiltrableType)
              .algorithmShortName
          );
        }
      });

      query.series = plotableSeries;

      algorithmQueries.push(query);
    });

    algorithmQueries.forEach((algorithmQuery) => {
      const queryParams = {
        ...algorithmQuery.query.extraParams,
        startDate: this._dateHelperService.ensureApiFormat(algorithmQuery.query.startDate),
        endDate: this._dateHelperService.ensureApiFormat(algorithmQuery.query.endDate),
      };

      const query$ = this.httpCacheClient
        .post(`${this.apiUrl}/${algorithmQuery.query.apiEndpoint}`, queryParams)
        .pipe(
          map((dataPoints: CVChartSerieDto[]) => {
            const serie = algorithmQuery.series.map((serie) => {
              const serieValues = algorithmQuery.service.getDataPoints(
                serie.serieDefinition.filtrableType,
                dataPoints
              );
              const chartSerie = new ChartSerie(serie, serieValues);
              return chartSerie;
            });

            return serie;
          })
        );

      serieQueries$.push(query$);
    });
  }

  private getSignalQueries(series: IPlotableSerie[], serieQueries$: Observable<ChartSerie[]>[]) {
    var groupedSignals = asEnumerable(series)
      .Select((x) => x)
      .Where((x) => x.serieDefinition.filtrableType.type == FiltrableItemTypeEnum.Signal)
      .GroupBy(
        (x) =>
          `${new Date(x.serieDefinition.endDate).toDateString()}-${new Date(
            x.serieDefinition.startDate
          ).toDateString()}`
      )
      .ToDictionary(
        (x) => x.key,
        (x) => Array.from(x.values())
      );

    type SignalPayload = {
      query: SignalFiltrableItemQueryDto;
      service: BaseFiltrableItemService;
      type: SignalFiltrableType;
      series: IPlotableSerie[];
    };
    var signalQueries: SignalPayload[] = [];

    Array.from(groupedSignals.values()).forEach((plotableSeries: IPlotableSerie[]) => {
      let query: SignalPayload;
      plotableSeries.forEach((plotableSerie) => {
        if (!query) {
          const service = this._filtrableItemManagerService.getService(
            plotableSerie.serieDefinition.filtrableType.type
          );
          query = {
            query: service.getQuery(plotableSerie.serieDefinition),
            service,
            type: plotableSerie.serieDefinition.filtrableType as SignalFiltrableType,
            series: [],
          };
        } else {
          query.query.extraParams.signalIds.push(
            (plotableSerie.serieDefinition.filtrableType as SignalFiltrableType).signalId
          );
        }
      });

      query.series = plotableSeries;

      signalQueries.push(query);
    });

    signalQueries.forEach((signalQuery) => {
      const queryParams = {
        ...signalQuery.query.extraParams,
        startDate: this._dateHelperService.ensureApiFormat(signalQuery.query.startDate),
        endDate: this._dateHelperService.ensureApiFormat(signalQuery.query.endDate),
      };

      const query$ = this.httpCacheClient
        .post(`${this.apiUrl}/${signalQuery.query.apiEndpoint}`, queryParams)
        .pipe(
          map((dataPoints: CVChartSerieDto[]) => {
            const serie = signalQuery.series.map((serie) => {
              const serieValues = signalQuery.service.getDataPoints(
                serie.serieDefinition.filtrableType,
                dataPoints
              );
              const chartSerie = new ChartSerie(serie, serieValues);
              return chartSerie;
            });

            return serie;
          })
        );

      serieQueries$.push(query$);
    });
  }

  public mapDataToGenericSettings(data: {
    chartConfiguration: ChartConfiguration;
    series: ChartSerie[];
  }): Observable<GenericCartesianChartSettings> {
    return this._chartSettingsMapperService.loadUoM().pipe(
      map(() => {
        const chartTitle = this._chartSettingsMapperService.mapChartTitle(
          data.chartConfiguration.customization
        );

        const verticalAxes = data.chartConfiguration.verticalAxis.map((x) =>
          this._chartSettingsMapperService.mapVerticalAxis(x)
        );

        this._chartSettingsMapperService.setVerticalAxesOffset(verticalAxes);

        const horizontalAxes = data.chartConfiguration.horizontalAxis.map((x) =>
          this._chartSettingsMapperService.mapHorizontalAxis(x)
        );
        const series: GCartesianChartSerie[] = [];

        data.series.forEach((serie) => {
          const verticalAxisUnit = data.chartConfiguration.verticalAxis.find(
            (x) => x.id == serie.serieDefinition.serieCustomization.verticalAxisId
          ).unitTypeToId;
          const gCartesianSerie = this._chartSettingsMapperService.mapSerie(
            serie,
            verticalAxisUnit
          );
          series.push(gCartesianSerie);
        });

        const dataZoom = this._chartSettingsMapperService.mapDataZoom(
          horizontalAxes,
          verticalAxes,
          data.chartConfiguration.customization.showTimeBar
        );
        const legend = data.chartConfiguration.customization.showLegend
          ? this._chartSettingsMapperService.getSeriesLegend(series)
          : null;

        const settings = new GenericCartesianChartSettings({
          chartTitle: chartTitle,
          series,
          xAxes: horizontalAxes,
          yAxes: verticalAxes,
          dataZoom: dataZoom,
          legend: legend,
        });
        if (chartTitle) {
          settings.chartTitle = chartTitle;
        }
        return settings;
      })
    );
  }
}
