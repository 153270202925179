import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalInfoDto } from 'src/app/common-modules/dependencies/shared/model/signal-info.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { SignalSelectionListSettings } from './signal-selection-list-settings';

const COMPONENT_SELECTOR = 'wlm-ne-signal-selection-lists';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-signal-selection-lists.component.html',
  styleUrls: ['./ne-signal-selection-lists.component.scss'],
})
export class NeSignalSelectionListsComponent implements OnInit {
  private _signalListSettings: SignalSelectionListSettings;
  public get signalListSettings(): SignalSelectionListSettings {
    return this._signalListSettings;
  }
  @Input() public set signalListSettings(v: SignalSelectionListSettings) {
    this._signalListSettings = v;
    this.prepareListsSettings();
  }

  @Output() configuredSignalsChanged = new EventEmitter<any[]>();
  @Output() onLoadingChanged = new EventEmitter<boolean>();

  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  configuredSignals: SignalInfoDto[] = [];
  excludedSignals: SignalInfoDto[] = [];
  refreshList$ = new Subject<void>();
  private _isLoading = false;
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
    this.onLoadingChanged.emit(this._isLoading);
  }
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  isFirstLoad = true;

  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _pagesize = 50;

  constructor(private _arrayHelperService: ArrayHelperService) { }

  ngOnInit(): void { }

  onDroppedElement(configuredSignal: SignalInfoDto) {
    const sortedConfiguredSignals = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      this.configuredSignals,
      this._pointDescriptionFieldName
    );

    this.excludedSignals = [...this.configuredSignals];

    this.configuredSignalsChanged.emit(sortedConfiguredSignals);
  }

  onfilterChange() {
    this.isLoading = true;
  }
  onAvailableSignalsLoaded() {
    this.isLoading = false;
  }

  onSignalsChanged($event) {
    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      return;
    }

    this.refreshList$.next();
  }

  saveConfiguration() {
    const sortedConfiguredSignals = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      this.configuredSignals,
      this._pointDescriptionFieldName
    );

    this.configuredSignalsChanged.emit(sortedConfiguredSignals);
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();

    if (this.signalListSettings.queryFieldNames?.length && this.signalListSettings.selectedItem) {
      this.signalListSettings.queryFieldNames.forEach((fieldName) => {
        newParams.set(fieldName, this.signalListSettings.selectedItem[fieldName]);
      });
    }

    this.queryParams = newParams;
  }

  prepareListsSettings() {
    this.updateQueryParams();
    this.settings = new DragListSettings({
      dataService: this.signalListSettings.oDataService,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      oDataFilters: this.signalListSettings.oDataFilters,
      useGlobalSpinner: false,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: `${this.T_SCOPE}.messages.drag-list-empty-message`,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
    });
  }
}
