export class ProfileCrudDto {
  profileId: number;
  dialogId: number;
  canRead?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  userName: string;
  dialogName: string;
  groupName: string;

  constructor(
    profileId: number,
    dialogId: number,
    fieldName: string,
    crudValue: boolean,
    userName: string,
    dialogName: string,
    groupName: string
  ) {
    this.profileId = profileId;
    this.dialogId = dialogId;
    this[fieldName] = crudValue;
    this.userName = userName;
    this.dialogName = dialogName;
    this.groupName = groupName;
  }
}
