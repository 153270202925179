import { Injector, NgModule } from '@angular/core';
import { GenericStoreService } from 'src/app/common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from 'src/app/common-modules/redux/redux-state.module';
import { DataVisualizationNavigationService } from './services/data-visualization-navigation.service';

@NgModule({
  declarations: [],
  imports: [
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
  ],
  providers: [
    DataVisualizationNavigationService,
    {
      provide: 'DataVisualizationNavigationService',
      useExisting: DataVisualizationNavigationService,
    },
  ],
})
export class DataVisualizationSharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    DataVisualizationSharedModule.injector = injector;
  }
}
