import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin } from 'rxjs';
import { MenuLink } from 'src/app/common-modules/dependencies/navigation/menu-link';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import { WlmNavigationService } from 'src/app/common-modules/dependencies/navigation/wlm-navigation.service';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from 'src/app/common-modules/shared/config/dynamic-settings.service';
import { NavigationsConstants } from 'src/app/common-modules/shared/constants/navigation-constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';

import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { HomePageService } from '../../shared/services/home-page.service';
import {
  HomePageConfigurationDto,
  HomePagesConfigurationDto,
} from './home-pages-configuration.dto';

const COMPONENT_SELECTOR = 'wlm-home-configuration-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './home-configuration-popup.component.html',
  styleUrls: ['./home-configuration-popup.component.scss'],
})
export class HomeConfigurationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;

  form: UntypedFormGroup;
  navOptions: MenuLink[];

  configurablePages = [NavKeys.Activities, NavKeys.Prioritisation, NavKeys.DistributionNetwork];

  public get titleTranslationKey(): string {
    return 'HomeConfigurationPopupComponent';
  }

  public get pageCrud(): string {
    return 'HomeConfigurationPopupComponent';
  }

  private _saveErrorMsg = 'common.messages.saved-error';
  private _settingArea = 'HomePage';
  private _pagesSettingKey = 'SelectionPages';
  private _defaultPageSettingKey = 'DefaultPage';

  private T_SCOPE_NAVIGATION = `${AppModules.WlmNavigation}.wlm-navigation-service`;

  private _isSuperUser: boolean;

  formControlKey = 'homePageControl';

  constructor(
    private _spinnerService: SpinnerService,
    private _dialogRef: MatDialogRef<HomeConfigurationPopupComponent>,
    private _dynamicSettingsService: DynamicSettingsService,
    private _navService: WlmNavigationService,
    private fb: UntypedFormBuilder,
    private _dialogService: DialogService,
    private _logService: LogService,
    private _authorizeService: AuthorizeService,
    private _localization: LocalizationHelperService,
    private readonly _homePageService: HomePageService
  ) {}

  ngOnInit(): void {
    this._authorizeService.isSuperUser().subscribe((isSuperUser) => {
      this._isSuperUser = isSuperUser;
      this.loadConfiguration();
    });
  }

  saveConfiguration(isSuperUser: boolean) {
    if (this.form.valid) {
      const selectedPage = this.form.get(this.formControlKey).value;

      this.setSpinner(true);
      this._homePageService.change(selectedPage, isSuperUser).subscribe({
        next: () => {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
          this._dialogRef.close();
          this.setSpinner(false);
        },
        error: (error) => {
          this._logService.error({
            msg: `Error saving home page configuration`,
            payload: error,
          });
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: this._saveErrorMsg, icon: 'error' })
          );
          this.setSpinner(false);
        },
      });
    }
  }

  close() {
    this._dialogRef.close();
  }

  private setSpinner(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, this.pageCrud);
  }

  private loadConfiguration() {
    this.setSpinner(true);

    this._dynamicSettingsService
      .loadDynamicSettings(
        new DynamicSettings({ settingArea: this._settingArea, settingKey: this._pagesSettingKey })
      )
      .subscribe({
        next: (configuration: HomePagesConfigurationDto) => {
          if (!configuration?.Pages?.length) {
            this.setSpinner(false);
            return;
          }

          const navKeys = configuration.Pages.map((x) => x as NavKeys);
          const menuLinks$ = this._navService.generateMenuLinksByNavKeys(navKeys);
          const configuredHomePage$ = this.getUserHomePage();
          const integrationTitle$ = this._localization.get(
            `${this.T_SCOPE_NAVIGATION}.${NavKeys.Integration}`
          );

          forkJoin([menuLinks$, configuredHomePage$, integrationTitle$])
            .pipe(untilDestroyed(this))
            .subscribe({
              next: ([menuLinks, configuredHomePage, integrationTitle]) => {
                if (this._isSuperUser) {
                  menuLinks.push(
                    new MenuLink({ key: NavKeys.Integration, title: integrationTitle })
                  );
                }

                this.navOptions = asEnumerable(menuLinks)
                  .OrderBy((ml) => ml.title)
                  .ToArray();

                const homeKey = configuredHomePage?.homePage
                  ? configuredHomePage.homePage
                  : NavigationsConstants.defaultHomeNavKey;

                this.loadForm(homeKey as NavKeys);
                this.setSpinner(false);
              },
              error: (error) => {
                this.logError(error);
                this.setSpinner(false);
              },
            });
        },
        error: (error) => {
          this.logError(error);
          this.setSpinner(false);
        },
      });
  }

  private logError(error: any) {
    this._logService.error({
      msg: `Error getting home page configuration`,
      payload: error,
    });
  }

  private loadForm(currentPage: NavKeys) {
    this.form = this.fb.group({
      [this.formControlKey]: [currentPage ? currentPage : null, [Validators.required]],
    });
  }

  private getUserHomePage(): Observable<HomePageConfigurationDto> {
    return this._dynamicSettingsService.loadDynamicSettings(
      new DynamicSettings({
        settingArea: this._settingArea,
        settingKey: this._defaultPageSettingKey,
      })
    );
  }
}
