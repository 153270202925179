import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { CalendarExceptionCardDto } from '../../models/calendar-exception-card.dto';
import { CalendarExceptionTypesEnum } from '../../models/exception-list.enum';

const COMPONENT_SELECTOR = 'wlm-calendar-exception-card';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-exception-card.component.html',
  styleUrls: ['./calendar-exception-card.component.scss'],
})
export class CalendarExceptionCardComponent implements OnInit {
  @Input() exception: CalendarExceptionCardDto;
  @Input() deleteCard?: (exception: CalendarExceptionCardDto) => void;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  T_SCOPE_TYPES = `${AppModules.Configuration}.wlm-calendar-exception-creator`;

  allCalendarTypes = CalendarExceptionTypesEnum;

  constructor() {}

  deleteException() {
    this.deleteCard(this.exception);
  }

  ngOnInit(): void {}
}
