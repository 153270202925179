<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div class="popup-container" cdkDropListGroup>
    <div class="header-container">
      <h3>{{ T_SCOPE + '.title' | translate }}</h3>

      <div>
        <button
          mat-icon-button
          class="close-top-button"
          (click)="close()"
          onclick="this.blur()"
          color="primary"
          [matTooltip]="'common.close' | translate | lowercase"
        >
          <mat-icon class="material-icons" color="primary">close</mat-icon>
        </button>
      </div>
    </div>

    <wlm-signal-historical-form
      *ngIf="formSettings && datesFromModel"
      [settings]="formSettings"
      [formModel]="datesFromModel"
      (formChanges)="onHistoricalFormChanges($event)"
    >
    </wlm-signal-historical-form>
    <wlm-smart-meters-current-config
      [disableButtons]="true"
      [disableInitialLoad]="true"
      [historicalMode]="true"
      [selectedNE]="popupSettings.networkElement"
      [pageId]="popupSettings.pageId"
      (formChanges)="onSmartMetersCurrentConfigChanges($event)"
    ></wlm-smart-meters-current-config>

    <div class="button-container">
      <button mat-raised-button color="warn" class="cancel-button" (click)="close()">
        {{ 'common.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!isValid || !canSave"
        (click)="saveConfiguration()"
      >
        {{ 'common.apply' | translate | uppercase }}
      </button>
    </div>
  </div>
</wlm-spinner-wrapper>
