<wlm-popup-wrapper
  [showCloseButton]="false"
  [showSpinner]="!configReady"
  [titleKey]="T_SCOPE + '.title'"
>
  <ng-container popup-content>
    <wlm-ne-selection-grid
      class="form-control"
      [neSelectionConfiguration]="filterConfig"
      [gridName]="gridName"
      [gridCssClass]="'none-shadow'"
      (selectedNetworkElementsChange)="onNetworkElementsChanged($event)"
    ></wlm-ne-selection-grid>
  </ng-container>
  <ng-container popup-actions>
    <div class="action-buttons-container">
      <div class="cancel-button-container">
        <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
      </div>
      <button
        mat-button
        class="wtr-action-button-primary btn-size"
        [disabled]="!selectedElements?.length"
        (click)="add()"
      >
        {{ 'common.add' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
