import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export const popupWrapperExtraHeight = 100;

const COMPONENT_SELECTOR = 'wlm-popup-wrapper';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.scss'],
})
export class PopupWrapperComponent implements OnInit {
  @Input() titleKey: string;
  @Input() titleKeyParams: { [id: string]: string };
  @Input() title: string;
  @Input() showSpinner = false;
  @Input() styles: any;
  @Input() showCloseButton = true;
  @Input() actionsContainerClass = '';
  @Output() popupClose = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClose(data: any): void {
    this.popupClose.emit(data);
  }
}
