import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'wlm-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
})
export class JsonEditorComponent implements OnInit {
  public editorOptions: JsonEditorOptions;

  @Input() set validationSchema(schema: any) {
    if (schema) {
      this.editorOptions.schema = schema;
      // Refresh view
      this.reloadEditor();
    }
  }

  private _initialData: any;
  public get initialData(): any {
    return this._initialData;
  }
  @Input() public set initialData(value: any) {
    this._initialData = value;
    if (value) {
      this.setJsonData(value);
      this.reloadEditor();
    }
  }
  public visibleData: any;
  stringifyJson: string = "[]";

  @Output() onJsonHasError = new EventEmitter<boolean>();

  showEditor = false;

  constructor() {
    const editorOptions = new JsonEditorOptions();
    editorOptions.modes = ['code', 'tree', 'view'];
    editorOptions.mode = 'code';
    editorOptions.onValidationError = (event) => this.onValidityChanged(event);
    editorOptions.onChangeText = (event) => this.onChangeJSON(event);

    this.editorOptions = editorOptions;
  }

  onValidityChanged(event) {
    const hasErrors = !!Array.from(event)?.length;
    this.onJsonHasError.next(hasErrors);
  }

  showJson(d: Event) {
    this.visibleData = d;
  }

  onChangeJSON(stringifyJson: string) {
    this.stringifyJson = stringifyJson;
  }

  ngOnInit(): void { }

  setJsonData(jsonData: any) {
    let data = jsonData;
    if (typeof jsonData === 'string') {
      data = JSON.parse(jsonData);
    }
    this.visibleData = jsonData;
  }

  private reloadEditor(): void {
    this.showEditor = false;
    setTimeout(() => (this.showEditor = true));
  }
}
