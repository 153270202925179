import { Injectable, Injector } from '@angular/core';
import {
  DynamicPageResultDto,
  PagedResultDto,
} from 'src/app/common-modules/shared/model/paged-result.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';
import { IDistributionNetworkDto } from '../../shared/model/distribution-network/distribution-network.dto';
import { IDynamicSummaryApi } from 'src/app/common-modules/shared/model/dynamic-summary/dynamic-summary-api';
import { Observable, map } from 'rxjs';
import { SummaryLatestCalculationDayDto } from 'src/app/common-modules/shared/model/dynamic-summary/summary-latest-calculation-day.dto';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';

@Injectable()
export class DistributionNetworkService
  extends GridODataService<IDistributionNetworkDto>
  implements IDynamicSummaryApi
{
  constructor(injector: Injector, private readonly _dateHelperService: DateHelperService) {
    super(injector, '/api/summary/dynamic/DistributionNetwork');
  }

  protected mapResponse(
    dynamicResponse: DynamicPageResultDto<IDistributionNetworkDto>
  ): PagedResultDto<IDistributionNetworkDto> {
    const responseItems = dynamicResponse.items.map((x) => x.properties);
    const pageResponse: PagedResultDto<IDistributionNetworkDto> = {
      totalCount: dynamicResponse.totalCount,
      items: responseItems,
    };
    return pageResponse;
  }

  getLatestCalculationDay(): Observable<Date> {
    return this.httpCacheClient
      .get<SummaryLatestCalculationDayDto>(`${this.baseUrl}${this.api}/latestcalculationday`, {
        avoid: true,
      })
      .pipe(map((result) => this._dateHelperService.fromApiFormat(result.latestCalculationDay)));
  }
}
