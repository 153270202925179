import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { GetCacheOpts } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { SetValueAction } from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { BaseSelectorDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-selector-dynamic-widget.component';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { SchematicStateFields } from 'src/app/common-modules/wlm-charts/core/models/schematics/schematic-state-fields';
import { SchematicBasic } from '../../../../../common-modules/wlm-charts/core/models/schematics/schematic';

const COMPONENT_SELECTOR = 'wlm-schematics-selector-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematics-selector-widget.component.html',
  styleUrls: ['./schematics-selector-widget.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class SchematicsSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit, OnDestroy
{
  private pendingChangesSubscription: Subscription;

  filters = new DataBindingFilters();
  cacheOpts: GetCacheOpts = { avoid: true };

  onWidgetInit(): void {
    this.emitWidgetInit();

    this._state
      .select(new AppliedFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dbFilter: DataBindingFilters) => {
          if (!dbFilter) {
            return;
          }
          this.filters = dbFilter;
        },
      });
  }

  onSelectedRow(data: SchematicBasic): void {
    if (this.pendingChangesSubscription) {
      this.pendingChangesSubscription.unsubscribe();
    }

    this.pendingChangesSubscription = this.checkPendingChanges(this.pageId).subscribe((_) => {
      this.setSelected(data);
      if (data) {
        this.dispatchGlobalLoading(true);
      }
      // Close edit mode
      this.dispatchEditMode(false);
    });
  }

  onSelectedManyRows(schematics: SchematicBasic[]): void {
    if (this.pendingChangesSubscription) {
      this.pendingChangesSubscription.unsubscribe();
    }

    this.pendingChangesSubscription = this.checkPendingChanges(this.pageId).subscribe((_) => {
      this._state.dispatch(
        new SetValueAction({
          fieldName: SchematicStateFields.multiselectedSchematic,
          value: schematics,
        })
      );
      this.dispatchEditMode(false);
    });
  }

  dispatchEditMode(editMode: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: SchematicStateFields.editMode,
        value: editMode,
      })
    );
  }

  dispatchGlobalLoading(isLoading: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: SchematicStateFields.isGlobalLoading,
        value: isLoading,
      })
    );
  }

  get selectedFieldName(): string {
    return SchematicStateFields.selectedSchematic;
  }

  get componentName(): string {
    return 'SchematicsSelectorWidgetComponent';
  }

  ngOnDestroy(): void {
    this.setSelected(null);
  }
}
