export const lsHierarchyElementsTree = 'hierarchyElementsTree';
export const lsAoiList = 'aoiList';
export const lsHierarchyFamilyList = 'hierarchyFamilyList';
export const disableNodeClassName = 'disabled-node';
export const highlightedNodeClassName = 'highlighted-node';
export const minLengthToSearchTree = 2;

export enum treeSelectionMode {
  multiple = 'multiple',
  single = 'single',
}
export enum hierarchyElementTypes {
  Company = 'company',
  DMA = 'DMA',
  DZ = 'DZ',
  EastWest = 'eastWest',
  Group = 'group',
  NetworkManagerArea = 'networkManagerArea',
  OperationalArea = 'operationalArea',
  SignalsData = 'signalsData',
  SodconBaseType = 'sodconBaseType',
  SodconLNU = 'sodconLNU',
  SodconPCC = 'sodconPCC',
  SodconPHC = 'sodconPHC',
  WaterResourceZone = 'waterResourceZone',
}

export enum hierarchyElementPathFields {
  Descendant = 'descendant',
  DescendantHierarchyElementName = 'descendantHierarchyElementName',
}

export enum searchDirection {
  up = 'up',
  down = 'down',
}

export enum networkElementTypesMapping {
  DMA = 1,
  DZ = 2,
  PZ = 3,
  OperationalArea = 4,
  NetworkManagerArea = 5,
  WaterResourceZone = 6,
  EastWest = 7,
  Company = 8,
  Group = 9,
  Pipeline = 10,
  Reservoir = 11,
  WaterTreatmentPlant = 12,
  Customer = 13,
  FlowMeter = 14,
  WaterSource = 15,
  PumpingStation = 16,
  Valve = 17,
  Hydrant = 18,
  AirValve = 19,
  PressureGauge = 20,
  LevelSensor = 21,
  Comment = 22,
  ServiceConnection = 23,
  WaterJoint = 24,
  RegionalSupplyArea = 27,
  SupplyArea = 28,
  SupplyZoneArea = 29,
  ServicePipe = 30,
  WaterModelBoundaries = 31,
  PressureManagedArea = 32,
  PressureRecordingPoint = 33,
  LargeUsers = 40,
  Others = 255,
  TemporarySimplifiedPipes = 256,
  TemporaryEndpointPipe = 257,
}

export const networkElementTypesDVWizardSet = [
  networkElementTypesMapping.FlowMeter,
  networkElementTypesMapping.LargeUsers,
  networkElementTypesMapping.LevelSensor,
  networkElementTypesMapping.Pipeline,
  networkElementTypesMapping.PressureGauge,
  networkElementTypesMapping.PumpingStation,
  networkElementTypesMapping.Reservoir,
  networkElementTypesMapping.Valve,
  networkElementTypesMapping.WaterSource,
  networkElementTypesMapping.WaterTreatmentPlant,
];

export const networkElementTypesNEConfigurationSet = [
  networkElementTypesMapping.FlowMeter,
  networkElementTypesMapping.LevelSensor,
  networkElementTypesMapping.Pipeline,
  networkElementTypesMapping.PressureGauge,
  networkElementTypesMapping.PumpingStation,
  networkElementTypesMapping.Reservoir,
  networkElementTypesMapping.WaterSource,
  networkElementTypesMapping.WaterTreatmentPlant,
];

export const networkElementTypesDvAlgorithms = [
  networkElementTypesMapping.FlowMeter,
  networkElementTypesMapping.LargeUsers,
  networkElementTypesMapping.LevelSensor,
  networkElementTypesMapping.Pipeline,
  networkElementTypesMapping.PressureGauge,
  networkElementTypesMapping.PumpingStation,
  networkElementTypesMapping.Reservoir,
  networkElementTypesMapping.WaterSource,
  networkElementTypesMapping.WaterTreatmentPlant,
];

export const networkElementTypesDvLarsSworpsAlgorithms = [
  networkElementTypesMapping.WaterSource,
  networkElementTypesMapping.WaterTreatmentPlant,
];
