import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { IAlarmSeverityTypeDto } from '../../dependencies/alarms/alarm-severity-type.dto';
import { AlarmsService } from '../../shared/services/alarms.service';

import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-alarm-severity-column';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-severity-column.component.html',
  styleUrls: ['./alarm-severity-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => AlarmSeverityColumnComponent),
    },
  ],
})
export class AlarmSeverityColumnComponent extends WlmColumnComponent implements OnInit {
  defaultSeverityColor: string = '#000000';
  severities: IAlarmSeverityTypeDto[] = [];

  constructor(private _alarmsService: AlarmsService, cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this._alarmsService.getAlarmSeverityTypes().subscribe({
      next: (severities) => {
        this.severities = severities;
      },
    });
  }

  getSeverityColor(dataItem: any): string {
    const severityTypeId = dataItem[this.field];

    if (severityTypeId === null || severityTypeId === undefined || !this.severities.length) {
      return this.defaultSeverityColor;
    }

    const severityColor = this.severities.find(
      (x) => x.severityId === severityTypeId
    )?.severityColor;

    return severityColor ? severityColor : this.defaultSeverityColor;
  }
}
