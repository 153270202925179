import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from 'src/app/common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NotificationRelatedComponent } from 'src/app/common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { NotificationCloseDto } from 'src/app/common-modules/shared/model/notifications/notification-close.dto';
import { NotificationFollowDto } from 'src/app/common-modules/shared/model/notifications/notification-follow.dto';
import { NotificationStatus } from 'src/app/common-modules/shared/model/notifications/notification-status.enum';
import { NotificationUserReadDto } from 'src/app/common-modules/shared/model/notifications/notification-user-read.dto';
import { NotificationDto } from 'src/app/common-modules/shared/model/notifications/notification.dto';
import { UserDto } from 'src/app/common-modules/shared/model/roles/user.dto';
import { FormModes } from 'src/app/common-modules/shared/model/shared/form-mode.enum';
import { ICanLeavePage } from 'src/app/common-modules/shared/navigation/can-component-deactivate';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { GridPersistedElements } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { NotificationPopupService } from '../../../../../common-modules/dependencies/shared/notification-popup.service';
import { UserFilter } from '../../../../../common-modules/dependencies/wlm-filters/i-filters/user-filter';
import { NotificationPopupDto } from '../../../../../common-modules/shared/model/shared/notification-popup.dto';
import { NotificationHubService } from '../../../shared/notification/notification-hub.service';
import { NotificationPopupComponent } from '../../../shared/notification/notification-popup/notification-popup.component';
import { RemoteEventTypes } from '../../../shared/notification/remote-event-types.enum';
import { NotificationFilterConfigurationDto } from '../models/notification-filter-configuration.dto';
import { NotificationPageParams } from '../models/notification-page-params';
import { NotificationAttributesComponent } from '../notification-components/notification-attributes/notification-attributes.component';
import { NotificationDetailsComponent } from '../notification-components/notification-details/notification-details.component';
import { NotificationService } from '../services/notification.service';

const COMPONENT_SELECTOR = 'wlm-notification-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss'],
})
export class NotificationPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy, ICanLeavePage
{
  @ViewChild(GenericGridComponent) grid: GenericGridComponent;
  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;

  private _selectedNotification: NotificationDto;
  public get selectedNotification(): NotificationDto {
    return this._selectedNotification;
  }
  public set selectedNotification(value: NotificationDto) {
    this.checkPendingChanges(this.pageId).subscribe((result) => {
      if (!result) {
        return;
      }

      this._selectedNotification = value;

      if (this.reloadRightPanel) {
        this.sendParameters(this.selectedNotification);
      }
      this.reloadRightPanel = true;
    });
  }

  pageId: string = 'NotificationPage';

  gridSettings: GridSetting;
  notificationFilterConfig: NotificationFilterConfigurationDto;
  gridName = 'Notifications';
  readFieldName = 'read';
  gridFilters: DataBindingFilters;

  removeSelectionPersisted$ = new ReplaySubject<void>();
  removeSelection$ = new ReplaySubject<void>();

  gridFiltersForBinding: DataBindingFilters;

  userCode: string;
  isSuperUser: boolean;

  disableCloseButton = true;
  disableUnfollowButton = true;

  userCodesToFilter: string[];
  detailsTitle: string;

  additionalFilters: Map<string, string>;
  userCodeFieldName = 'UserCode';
  additionalFilterName = 'userCodes';

  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  reloadRightPanel = true;

  private _selectedNotifications: NotificationDto[];
  public get selectedNotifications(): NotificationDto[] {
    return this._selectedNotifications;
  }
  public set selectedNotifications(v: NotificationDto[]) {
    this._selectedNotifications = v;
    this.toogleTRButtons(v);
    this._spinnerService.setLoading(false, this.gridName);
  }

  public get isAnyUnfollow(): boolean {
    return this.selectedNotifications?.some((x) => x.follow === false);
  }

  public get isAnyUnread(): boolean {
    return this.selectedNotifications?.some((x) => x.read === false);
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'NotificationCrud';
  }

  private get getPersistedUserIds(): string[] {
    if (!this.isSuperUser) {
      return [];
    }
    const userIdsPersisted = this.getPersisted(this.userCodeFieldName, undefined);

    return (userIdsPersisted as BasicFilter)?.value?.map((x) => x.value);
  }

  public get navigations(): DropdownNavigationItem[] {
    return [
      new DropdownNavigationItem({
        key: NavKeys.DistributionNetwork,
        disabled: false,
        params: null,
      }),
      new DropdownNavigationItem({
        key: NavKeys.ActivityRaising,
        disabled: false,
        params: null,
      }),
      new DropdownNavigationItem({
        key: NavKeys.Activities,
        disabled: false,
        params: null,
      }),
      new DropdownNavigationItem({
        key: NavKeys.Leaks,
        disabled: false,
        params: null,
      }),
      new DropdownNavigationItem({
        key: NavKeys.WaterBalance,
        disabled: false,
        params: null,
      }),
    ];
  }

  constructor(
    private _route: ActivatedRoute,
    private _nfService: NotificationService,
    private _globalsService: GlobalsService,
    private _gridSettingsService: GridSettingsService,
    private _notificationPopupService: NotificationPopupService,
    private _notificationHubService: NotificationHubService,
    private _spinnerService: SpinnerService,
    private _authorizeService: AuthorizeService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  ngOnInit(): void {
    super.ngOnInit();

    const currentUser$ = this.loadCurrentUser();
    const isSuperUser$ = this._authorizeService.isSuperUser().pipe(untilDestroyed(this));
    const gridSettings$ = this.getGridSettings();
    const queryParams$ = this._route.queryParams.pipe(untilDestroyed(this));

    combineLatest([currentUser$, isSuperUser$, gridSettings$, queryParams$]).subscribe({
      next: ([user, isSuperUser, settings, params]) => {
        this.isSuperUser = isSuperUser;
        this.userCode = user.userCode;
        this.initializeFilters();
        this.setGridSettings(settings, isSuperUser);
        const queryParams = this.getQueryParams(params) as NotificationPageParams;

        if (queryParams?.onlyUnread) {
          this.grid.clearFilters();
          this.grid.applyColumnFilter(this.getFilterOnlyUnread());
        }

        this.loadNotifications();
      },
      error: (error) => {},
    });

    this._notificationPopupService.refreshNotifications$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reloadGridAndRemoveSelection();
      });

    this._notificationHubService
      .onEvent(RemoteEventTypes.UpdateNotificationsCount)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reloadGridAndRemoveSelection(false);
      });

    this.loadRightPanelComponents();
    this.subscribeRightPanelCallback();
  }

  subscribeRightPanelCallback() {
    this.rightPanelService
      .rightPanelCallbackObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (payload) => {
          if (
            payload?.has(NotificationDetailsComponent) &&
            payload?.get(NotificationDetailsComponent) === 'refresh'
          ) {
            this.reloadGridFromFilters(false);
          }
        },
      });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((result) => result !== null)
    );
  }

  getFilterOnlyUnread(): FilterDescriptor[] {
    const filter = { field: this.readFieldName, operator: 'eq', value: false };
    return [filter];
  }

  getGridSettings(): Observable<GridSetting> {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        return of(persistedSettings).pipe(untilDestroyed(this));
      } else {
        return this._gridSettingsService
          .getGridSettingsByName(this.gridName)
          .pipe(untilDestroyed(this));
      }
    }
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  getSelectedNotification(notification: NotificationDto) {
    this.selectedNotification = notification;
  }

  editNotification() {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      if (this.selectedNotification) {
        const popupData = new NotificationPopupDto({
          notificationId: this.selectedNotification.notificationId,
          mode: FormModes.Edit,
        });
        const popup = this._notificationPopupService.openPopup(
          popupData,
          NotificationPopupComponent
        );
        popup.afterClosed().subscribe((data) => {
          if (data) {
            this.reloadGridAndRemoveSelection();
          }
        });
      }
    });
  }

  readUnreadNotifications() {
    const notifications = globalUtilsHelper.clone(this.selectedNotifications, true);

    this.checkPendingChanges(this.pageId).subscribe(() => {
      if (!notifications) {
        return;
      }

      this._spinnerService.setLoading(true, this.gridName);
      const isPlural = notifications.length > 1;
      const successKey = isPlural
        ? '.messages.read-unread.multiple-success'
        : '.messages.read-unread.single-success';
      const errorKey = isPlural
        ? '.messages.read-unread.multiple-error'
        : '.messages.read-unread.single-error';

      const readStatus: { [key: string]: boolean } = {};

      notifications.forEach((nf) => {
        readStatus[nf.notificationId] = nf.read;
      });

      const notificationToUpdate = new NotificationUserReadDto({
        readStatus,
      });

      this._nfService.readUnread(notificationToUpdate).subscribe({
        next: (result) => {
          this.reloadGridAndRemoveSelection();
          this.showSuccessMessage(successKey);
        },
        error: (error) => {
          this.showErrorMessage(errorKey);
        },
      });
    });
  }

  unfollowNotifications() {
    const notifications = globalUtilsHelper.clone(this.selectedNotifications, true);

    this.checkPendingChanges(this.pageId).subscribe(() => {
      if (!notifications) {
        return;
      }
      this._spinnerService.setLoading(true, this.gridName);
      const isPlural = notifications.length > 1;
      const successKey = isPlural
        ? '.messages.unfollow.multiple-success'
        : '.messages.unfollow.single-success';
      const errorKey = isPlural
        ? '.messages.unfollow.multiple-error'
        : '.messages.unfollow.single-error';

      const notificationIds = notifications.map((n) => n.notificationId);
      const currentOperation = this._nfService.unfollow(
        new NotificationFollowDto({ notificationIds })
      );
      this.applySelectedFollowing(currentOperation, successKey, errorKey);
    });
  }

  followNotifications() {
    const notifications = globalUtilsHelper.clone(this.selectedNotifications, true);

    this.checkPendingChanges(this.pageId).subscribe(() => {
      if (!notifications) {
        return;
      }

      this._spinnerService.setLoading(true, this.gridName);
      const isPlural = notifications.length > 1;
      const successKey = isPlural
        ? '.messages.follow.multiple-success'
        : '.messages.follow.single-success';
      const errorKey = isPlural
        ? '.messages.follow.multiple-error'
        : '.messages.follow.single-error';

      const notificationIds = notifications.map((n) => n.notificationId);
      const currentOperation = this._nfService.follow(
        new NotificationFollowDto({ notificationIds })
      );
      this.applySelectedFollowing(currentOperation, successKey, errorKey);
    });
  }

  closeNotifications() {
    const notifications = globalUtilsHelper.clone(this.selectedNotifications, true);

    this.checkPendingChanges(this.pageId).subscribe(() => {
      if (!notifications) {
        return;
      }

      this._spinnerService.setLoading(true, this.gridName);
      const notificationCount = notifications.length;
      const isPlural = notificationCount > 1;
      const closeKey = isPlural ? '.messages.close.multiple' : '.messages.close.single';
      const errorKey = isPlural ? '.messages.close.multiple-error' : '.messages.close.single-error';
      const successKey = isPlural
        ? '.messages.close.multiple-success'
        : '.messages.close.single-success';

      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}${closeKey}`,
        params: { count: notificationCount },
      });

      this.dialogService
        .showTranslatedDialogMessage(dialogSettings)
        .subscribe((dialogRef: WLMDialogResult) => {
          if (dialogRef.result) {
            const notificationIds = notifications.map((n) => n.notificationId);

            this._nfService.close(new NotificationCloseDto({ notificationIds })).subscribe({
              next: (result) => {
                this.reloadGridAndRemoveSelection();
                this.showSuccessMessage(successKey);
              },
              error: (error) => {
                this.showErrorMessage(errorKey);
              },
            });
          } else {
            this._spinnerService.setLoading(false, this.gridName);
          }
        });
    });
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const selectedUsers = (
      filtersParameters.filters.get(this.userCodeFieldName) as UserFilter
    ).getElementIds();

    const isFilteringByCurrentUserOnly =
      selectedUsers?.length === 1 && selectedUsers.includes(this.userCode);

    this.userCodesToFilter = isFilteringByCurrentUserOnly ? [] : selectedUsers;
  }

  getFiltersDetailsParameters($event) {}

  onCheckAutoload() {}

  getZoneFromNotification(notification: NotificationDto) {
    const zoneKey = TabDetailParameterName.hierarchyElementId;
    return this.getAttributeFromNotification(zoneKey, notification);
  }

  getNetworkElementFromNotification(notification: NotificationDto): string {
    const neKey = TabDetailParameterName.networkElementId;
    return this.getAttributeFromNotification(neKey, notification);
  }

  getAuditFromNotification(notification: NotificationDto): string {
    const auditKey = TabDetailParameterName.audit;
    return this.getAttributeFromNotification(auditKey, notification);
  }

  getAttributeFromNotification(attributeId: string, notification: NotificationDto) {
    const attributeIdUpper = attributeId.toUpperCase();
    const attribute = notification?.attributes.find(
      (x) => x.notificationAttributeId.toUpperCase() === attributeIdUpper
    );
    const value = attribute ? attribute.notificationAttributeValue : undefined;
    return value;
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  private applySelectedFollowing(
    operation: Observable<void>,
    successKey: string,
    errorKey: string
  ) {
    operation.subscribe({
      next: (result) => {
        this.reloadGridAndRemoveSelection();
        this.showSuccessMessage(successKey);
      },
      error: (error) => {
        this.showErrorMessage(errorKey);
      },
    });
  }

  private sendParameters(notification: NotificationDto) {
    const parameters = new TabDetailPanelParameters();

    parameters.addParameter(TabDetailParameterName.pageId, this.pageId);
    parameters.addParameter(TabDetailParameterName.notification, notification);

    const title = notification ? `${this.detailsTitle}: ${notification.notificationTitle}` : '';
    parameters.addParameter(TabDetailParameterName.elementName, title);

    const zone = this.getZoneFromNotification(notification);
    const networkElementId = this.getNetworkElementFromNotification(notification);
    const audit = this.getAuditFromNotification(notification);
    if (zone) {
      parameters.addParameter(TabDetailParameterName.hierarchyElementId, zone);
    } else if (networkElementId) {
      parameters.addParameter(TabDetailParameterName.networkElementId, networkElementId);
    } else if (audit) {
      parameters.addParameter(TabDetailParameterName.audit, audit);
    }

    this.rightPanelService.setTabParameters(parameters);
  }

  public loadNotifications() {
    this.gridFiltersForBinding = null;
    const dataBinding = new DataBindingFilters();
    this.filterByUserCode();
    this.gridFiltersForBinding = dataBinding;
    this.reloadGridFromFilters();
  }

  private filterByUserCode() {
    const additionalFilters = new Map<string, string>();

    if (this.userCodesToFilter?.length) {
      additionalFilters.set(this.additionalFilterName, this.userCodesToFilter.join(','));
    }

    this.additionalFilters = additionalFilters;
  }

  private initializeFilters() {
    this.userCodesToFilter = this.getPersistedUserIds ?? [];

    const config = new NotificationFilterConfigurationDto({
      currentUser: this.userCode,
      userFieldName: this.userCodeFieldName,
      defaultSelectedUser: [this.userCode],
      selectedUser: this.getPersistedUserIds ?? [this.userCode],
      persistencyArea: this.persistencyArea,
    });

    this.notificationFilterConfig = config;
  }

  private loadRightPanelComponents() {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(NotificationDetailsComponent, ts.details);
      panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
      panelSettings.addComponent(NotificationAttributesComponent, ts.attributes);
      this.detailsTitle = ts['details-title'];

      this.rightPanelService.setTabSettings(panelSettings);
    });
  }

  private loadCurrentUser(): Observable<UserDto> {
    return this._globalsService.getCurrentUser().pipe(untilDestroyed(this));
  }

  private toogleTRButtons(notifications: NotificationDto[]) {
    this.toogleCloseButton(notifications);
    this.toogleUnfollowButton(notifications);
  }

  private toogleCloseButton(notifications: NotificationDto[]) {
    this.disableCloseButton = !notifications?.length || !this.anyIsOpen(notifications);
  }

  private toogleUnfollowButton(notifications: NotificationDto[]) {
    this.disableUnfollowButton = !notifications?.length;
  }

  private anyIsOpen(notifications: NotificationDto[]): boolean {
    return notifications.findIndex((n) => n.notificationStatus === NotificationStatus.Open) !== -1;
  }

  private showErrorMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}${messageKey}`,
      icon: 'error',
    });
    this.dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private showSuccessMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}${messageKey}`,
      icon: 'success',
    });
    this.dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private reloadGridAndRemoveSelection(resetPage: boolean = true) {
    this.removeSelectionPersisted$.next();
    this.removeSelection$.next();
    this.processGridReload(resetPage);
  }

  private processGridReload(resetPage: boolean) {
    resetPage ? this.grid?.reloadGrid() : this.grid?.reloadAndSelectGrid();
  }

  private reloadGridFromFilters(resetPage: boolean = true) {
    this.loading = true;
    this.processGridReload(resetPage);
    this.loading = false;
  }

  private setGridSettings(settings: GridSetting, isSuperUser: boolean) {
    if (!isSuperUser && settings?.buttons?.show?.length > 0) {
      const availableGridButtons = settings.buttons.show.filter(
        (b) => b !== GridBtnsOptions.ClearFilters
      );

      settings.buttons.show = availableGridButtons;
    }

    this.gridSettings = settings;
  }
}
