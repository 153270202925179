import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ObservablesService } from '../../services/observables.service';
import { WlmResizeObserverService } from '../../services/resize-observer.service';

const COMPONENT_SELECTOR = 'wlm-page-wrapper';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild('topcontainer') topcontainer: ElementRef;
  @ViewChild('buttoncontainer') buttoncontainer: ElementRef;

  @Input() titleKey: string;
  @Input() titleKeyParams: { [id: string]: string };
  @Input() title: string;
  @Input() showPageSpinner = false;

  private readonly _responsiveElement = 'buttoncontainer';
  private readonly _responsiveKey = 'page-header-responsive';

  constructor(
    private _resizeObserverService: WlmResizeObserverService,
    private _observablesService: ObservablesService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const initialWidth = 0;
    this._observablesService.emit(this._responsiveKey, initialWidth);
    this._resizeObserverService.observeChildren(
      this.topcontainer.nativeElement,
      this._responsiveElement,
      this._responsiveKey
    );
  }
}
