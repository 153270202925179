export class DynamicGridSettings {
  gridSettingName: string;
  persistencyArea?: string;
  usePersistence: boolean;
  type: 'local' | 'generic';
  disableAutoLoad: boolean;

  constructor(init: DynamicGridSettings) {
    Object.assign(this, init);
  }
}
