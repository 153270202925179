import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseMapper } from './base-mapper';
import { GenericMappersModule } from './generic-mappers.module';
import { MapperFunctions } from './mapper-functions';

type MapFn$ = (
  value: unknown,
  model: unknown,
  params: { [mapperId: string]: unknown }
) => Observable<unknown>;

@Injectable({
  providedIn: GenericMappersModule,
})
export class MappersService {
  readonly _mappers: Map<MapperFunctions, BaseMapper>;

  constructor(@Inject(BaseMapper) mappers: BaseMapper[]) {
    this._mappers = this.hashMappers(mappers);
  }

  apply = (mappersIds: MapperFunctions[]): MapFn$ => {
    return (value, model, params) => this.applyFns$(value, model, params, mappersIds);
  };

  private applyFns$(
    value: unknown,
    model: unknown,
    params: { [mapperId: string]: unknown },
    mappersIds: MapperFunctions[]
  ): Observable<unknown> {
    if (!mappersIds?.length) {
      return of(value);
    }

    const mappers = this.getSelectedMappers(mappersIds);

    const initialMapper = mappers[0];
    const remainingMappers = mappers.slice(1, mappers.length);

    const currentParams = params ? params[initialMapper.mapperId as string] : null;
    let applied$ = initialMapper.map(value, model, currentParams);

    remainingMappers.forEach((currentMapper) => {
      applied$ = applied$.pipe(
        switchMap((currentValue) => currentMapper.map(currentValue, model, currentParams))
      );
    });

    return applied$;
  }

  private getSelectedMappers(mapperIds: MapperFunctions[]): BaseMapper[] {
    const mappers = [];
    mapperIds.forEach((mapperId) => {
      if (!this._mappers.has(mapperId)) {
        throw new Error(`Could not find mapper with id ${mapperId}.`);
      }
      const mapper = this._mappers.get(mapperId);
      mappers.push(mapper);
    });
    return mappers;
  }

  private hashMappers(mappers: BaseMapper[]): Map<MapperFunctions, BaseMapper> {
    const mapped = new Map<MapperFunctions, BaseMapper>();
    mappers.forEach((mapper) => {
      mapped.set(mapper.mapperId, mapper);
    });
    return mapped;
  }
}
