<div class="container-fluid">
  <div class="row">
    <div *ngFor="let item of processedItems" [class]="'lbl-row ' + (item.cssClass ?? ' col-12 ')">
      <div [class]="item.labelCssClass ?? 'lbl-label'">
        {{ item.label$ | async }}
      </div>
      <span *ngIf="!item.hideSparator" class="lbl-separator">: </span>
      <div [class]="item.valueCssClass ?? 'lbl-value'">
        {{ item.value$ | async }}
      </div>
    </div>
  </div>
</div>
