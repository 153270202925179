import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

const COMPONENT_SELECTOR = 'wlm-date-time-range-errors';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-time-range-errors.component.html',
  styleUrls: ['./date-time-range-errors.component.scss'],
})
export class DateTimeRangeErrorsComponent implements OnInit {
  @Input() errorList: Set<string>;
  @Input() set validationErrors(value: ValidationErrors) {
    this.buildErrorList(value);
  }
  @Input() minDate: Date;
  @Input() maxDate: Date;

  constructor() {}

  ngOnInit(): void {}

  private buildErrorList(errors: ValidationErrors): void {
    this.errorList = new Set<string>();

    if (errors) {
      const errorKeys = Object.keys(errors)?.map((key) => key.toString());
      this.errorList = new Set(errorKeys);
    }
  }
}
