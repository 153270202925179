<wlm-spinner-wrapper [showSpinner]="isLoading" [enableRelative]="true">
  <div class="points-selection-container" cdkDropListGroup>
    <wlm-select-drag-list-virtual
      class="select-drag-list-virtual"
      *ngIf="settings"
      [settings]="settings"
      [itemsData]="availableWorkspaces"
      [queryParams]="queryParams"
      [selectedItem]="selectedWorkspace"
      [removeSelection$]="removeSelection$"
      (selectedItemChange)="onSelectItem($event)"
    >
      <ng-template let-rowDetail #card>
        <wlm-generic-card [item]="rowDetail" [settings]="cardSettings"></wlm-generic-card>
      </ng-template>
    </wlm-select-drag-list-virtual>
  </div>
</wlm-spinner-wrapper>
