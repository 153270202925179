import { Component, Input, OnInit } from '@angular/core';
import { networkElementTypesDVWizardSet } from 'src/app/common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { UserFAdapter } from 'src/app/common-modules/dependencies/wlm-filters/adapters/user-f-adapter';
import { BaseFilterItemSettings } from 'src/app/common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from 'src/app/common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from 'src/app/common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { PersistencyService } from 'src/app/common-modules/shared/persistency.service';
import { NotificationFilterConfigurationDto } from '../../models/notification-filter-configuration.dto';

const COMPONENT_SELECTOR = 'wlm-notification-grid-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-grid-filter.component.html',
  styleUrls: ['./notification-grid-filter.component.scss'],
})
export class NotificationGridFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _filterConfiguration: NotificationFilterConfigurationDto;
  public get filterConfiguration(): NotificationFilterConfigurationDto {
    return this._filterConfiguration;
  }
  @Input() public set filterConfiguration(value: NotificationFilterConfigurationDto) {
    this._filterConfiguration = value;
    if (value) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  T_SCOPE = `${AppModules.Monitoring}.${COMPONENT_SELECTOR}`;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,

    disableSelectAll: true,
  };

  bfUserSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-user-label`,
  };

  userItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    itemsLimit: 10, // limit the number of users in the filter list
    required: true,
  });

  mode = 'single';
  filterText: string;
  filterListElements = networkElementTypesDVWizardSet;
  allFilters = new Map<string, BasicFilter>();

  constructor(persistencyService: PersistencyService) {
    super(persistencyService);
  }

  buildKeysToComplete(): void {
    const { userFieldName } = this.filterConfiguration;
    this.keysToComplete = [userFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { userFieldName } = this.filterConfiguration;
    const adapters = [
      new UserFAdapter({
        dataBindingField: userFieldName,
        userCodeFieldName: userFieldName,
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea(): void {
    this.bfUserSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
  }
}
