import { HistoricalVersionDto } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';

export class SignalHistoricalVersionDto extends HistoricalVersionDto {
  hierarchyElementId: string;
  signalId: number;
  pointId: string;
  pointDescription: string;

  constructor(init: SignalHistoricalVersionDto) {
    super(init);
    Object.assign(this, init);
  }

  getCurrentConfig?() {
    const current = {
      signalId: this.signalId,
      hierarchyElementId: this.hierarchyElementId,
      pointId: this.pointId,
      pointDescription: this.pointDescription,
    };
    return current;
  }
}
