export class UsersIdentityMappingDto {
  userId: string;
  userCode: string;
  userName: string;
  email: string;

  constructor(userId: string, userCode: string, userName: string, email: string) {
    this.userId = userId;
    this.userCode = userCode;
    this.userName = userName;
    this.email = email;
  }
}
