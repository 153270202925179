import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { IActivityClosureData } from 'src/app/water-loss/features/shared/model/alc/activities-closure-data';
import { ALCActivityDto } from 'src/app/water-loss/features/shared/model/alc/alc-activity.dto';
import { ICloseActivities } from 'src/app/water-loss/features/shared/model/alc/close-activities.data';
import { ActivitiesGridService } from '../../../activities-grid/activities-grid.service';

const COMPONENT_SELECTOR = 'wlm-activity-manual-closure';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-manual-closure.component.html',
  styleUrls: ['./activity-manual-closure.component.scss'],
})
export class ActivityManualClosureComponent implements OnInit {
  form: UntypedFormGroup;
  selectedActivities: ALCActivityDto[];
  formTitle: string;
  dryHoleTypeDisabled: boolean;

  activityCloseTypes: { value: string; name: string }[] = [];
  dryHoleTypes: { value: number; name: string }[] = [];

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  constructor(
    private dialogRef: MatDialogRef<ActivityManualClosureComponent>,
    @Inject(MAT_DIALOG_DATA) { activities, user }: IActivityClosureData,
    private activityService: ActivitiesGridService,
    private dialogService: DialogService,
    private _authenticationService: AuthenticationService,
    private sharedConstants: SharedConstantsService
  ) {
    this.selectedActivities = activities;

    this.form = new UntypedFormGroup({
      closeTypeControl: new UntypedFormControl({ value: '' }),
      commentControl: new UntypedFormControl({ value: '', disabled: true }),
      dryHoleTypeControl: new UntypedFormControl(
        { value: '', disabled: true },
        Validators.required
      ),
    });
  }

  ngOnInit() {
    this.onChanges();
  }

  /**
   * Load translated options for the selects, and maps them to arrays.
   */
  loadSelectOptions(): void {
    forkJoin([
      this.sharedConstants.mapToArrayObservable(
        this.sharedConstants.getActivityCloseType(),
        'value',
        'name'
      ),
      this.sharedConstants.mapToArrayObservable(
        this.sharedConstants.getDryHoleTypes(),
        'value',
        'name'
      ),
    ]).subscribe(([activityCloseTypes, dryHoleTypes]) => {
      this.activityCloseTypes = activityCloseTypes;
      this.dryHoleTypes = dryHoleTypes;
    });
  }

  onChanges() {
    this.form
      .get('closeTypeControl')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((closeTypeSelected) => {
        switch (closeTypeSelected) {
          case 'ConfirmedLeak': {
            this.form.get('commentControl').enable();
            this.form.get('dryHoleTypeControl').disable();
            this.form.get('dryHoleTypeControl').reset();
            break;
          }
          case 'DryHole': {
            this.form.get('commentControl').disable();
            this.form.get('commentControl').reset();
            this.form.get('dryHoleTypeControl').enable();
            break;
          }
          case 'Duplicated': {
            this.form.get('commentControl').disable();
            this.form.get('dryHoleTypeControl').disable();
            this.form.get('commentControl').reset();
            this.form.get('dryHoleTypeControl').reset();
          }
        }
      });
  }
  save() {
    if (this.form.valid) {
      const closeActivityData: ICloseActivities = {
        activityIds: this.selectedActivities.map((x) => x.activityId),
        closureType: this.form.controls.closeTypeControl.value,
        dryHoleType: this.form.controls.dryHoleTypeControl.value,
        comment: this.form.controls.commentControl.value,
        userCode: this._authenticationService.userCode ?? 'UnkownUser',
      };
      this.activityService.closeActivities(closeActivityData).subscribe({
        next: (value) => {
          this.dialogRef.close(value);

          if (value) {
            if (closeActivityData?.activityIds.length > 1) {
              this.dialogService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({
                  translateKey: `${this.T_SCOPE}.success-message-n`,
                  params: {
                    amount: this.selectedActivities.length,
                  },
                })
              );
            } else {
              this.dialogService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.success-message` })
              );
            }
          } else {
            if (closeActivityData?.activityIds?.length > 1) {
              this.dialogService.showTranslatedMessage(
                new WlmDialogSettings({
                  translateKey: `${this.T_SCOPE}.error-message-n`,
                  icon: 'error',
                })
              );
            } else {
              this.dialogService.showTranslatedMessage(
                new WlmDialogSettings({
                  translateKey: `${this.T_SCOPE}.error-message`,
                  icon: 'error',
                })
              );
            }
          }
        },
        error: (error) => {
          this.dialogRef.close(false);
        },
      });
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}
