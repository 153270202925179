import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-settings';
import { PersistLayoutBackendService } from 'src/app/common-modules/dynamic-layout/services/persist-layout-backend.service';

/**
 * This needs providedIn root because the BiModule is lazy loaded, and this service can be used in other modules.
 */
@Injectable()
export class BiPersistLayoutService extends PersistLayoutBackendService {
  load(settings: DynamicSettings): Observable<any> {
    return this.httpCacheClient.get(
      `${this.apiUrl}/bi/dashboard/${settings.settingArea}/${settings.settingKey}`
    );
  }
}
