import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subject } from 'rxjs';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import {
  PointCreationPopupDimensions,
  TelemetryDimensionTypes,
} from 'src/app/common-modules/shared/constants/dimensions.constants';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { BasicFilter } from 'src/app/common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { DimensionTypesEnum } from 'src/app/common-modules/shared/model/shared/dimension-types';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { GridPersistedElements } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { TelemetryGridFilterConfiguration } from '../../wlm-filters/telemetry-filter/telemetry-filter-configuration';
import { PointConfigurationComponent } from '../point-configuration/point-configuration.component';
import { PointCreationPopupComponent } from '../point-creation-popup/point-creation-popup.component';
import { ReplaceSignalPopupComponent } from '../replace-signal-popup/replace-signal-popup.component';

const COMPONENT_SELECTOR = 'wlm-telemetry-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './telemetry-page.component.html',
  styleUrls: ['./telemetry-page.component.scss'],
})
export class TelemetryPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'TelemetryPointsPageCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  gridFiltersForBinding: DataBindingFilters;
  gridFilters: DataBindingFilters;
  gridSettings: GridSetting;
  selectedTelemetryPoint: ISignalTelemetryNullableViewDto;
  autoLoad: boolean;
  canLoad = true;
  gridName = 'TelemetryPoints';
  configReady = false;
  telemetryFilterConfiguration: TelemetryGridFilterConfiguration;
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  // Filters fieldNames
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  networkElementTypeIdFieldName = 'NetworkElementTypeId';
  dimensionTypeIdFieldName = 'DimensionTypeId';
  selectedHierarchyElements: any;
  selectedHierarchyFamilyId: any;
  selectedDimensionTypeId: DimensionTypesEnum;
  selectedNetworkElementTypeIds: number[];
  defaultHierarchyElementId = '';
  pointDescriptionPrefix: string;

  storageLocation = 'local';
  useLocalStorage = this.storageLocation === 'local';

  constructor(
    private _gridSettingService: GridSettingsService,
    private replaceSignalDialog: MatDialog,
    private cacheService: HttpCacheService,
    private _globalsService: GlobalsService,
    private _spinnerService: SpinnerService,
    private _dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.init();
  }

  ngOnDestroy() {
    this.persist(TabDetailParameterName.dataBindingFilter, this.gridFiltersForBinding);

    super.ngOnDestroy();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {
    this.initGrid();

    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(PointConfigurationComponent, ts['point-configuration']);

      this.pointDescriptionPrefix = ts['point-description-title'];

      this.rightPanelService.setTabSettings(panelSettings);
      this.loadState();
    });
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            this.setGridSettings(gridSettings);
          },
        });
      }
    }
  }

  loadState() {
    const gridFiltersForBinding = this.getPersisted(
      TabDetailParameterName.dataBindingFilter,
      undefined
    ) as DataBindingFilters;

    let dataBindingCasted = new DataBindingFilters();
    if (gridFiltersForBinding?.exportableFilter) {
      dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
      dataBindingCasted.reloadFromSerialization();
    } else {
      dataBindingCasted = null;
    }

    this.selectedDimensionTypeId = this.getPersisted(
      this.dimensionTypeIdFieldName,
      undefined,
      false,
      this.useLocalStorage
    )?.value[0]?.value;

    const neTypes = this.getPersisted(
      this.networkElementTypeIdFieldName,
      undefined,
      false,
      this.useLocalStorage
    )?.value[0]?.value;

    if (neTypes !== undefined || neTypes !== null) {
      this.selectedNetworkElementTypeIds = [neTypes];
    }

    // Get HEIds persisted
    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = (
        this.getPersisted(
          this.hierarchyElementIdFieldName,
          [{ value: this.defaultHierarchyElementId, label: '' }],
          false,
          this.useLocalStorage
        ) as BasicFilter
      )?.value;
    }
    // Get Family persisted
    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.getPersisted(
        this.hierarchyElementFamilyFieldName,
        undefined,
        false,
        this.useLocalStorage
      );
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }

      // get default family from service if previous attemps fail
      this._globalsService
        .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
        .subscribe((familyId) => {
          this.selectedHierarchyFamilyId = familyId;

          this.loadPage(dataBindingCasted);
        });
    } else {
      this.loadPage(dataBindingCasted);
    }
  }

  private loadPage(gridFiltersForBinding: DataBindingFilters) {
    const configuration = new TelemetryGridFilterConfiguration({
      persistencyArea: this.persistencyArea,
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
      networkElementTypeIdFieldName: this.networkElementTypeIdFieldName,
      hierarchyElementId: this.selectedHierarchyElements,
      hierarchyElementFamily: this.selectedHierarchyFamilyId,
      neTypeMode: 'single',
      dimensionTypeIdFieldName: this.dimensionTypeIdFieldName,
      dimensionTypeId: this.selectedDimensionTypeId,
      networkElementTypeIds: this.selectedNetworkElementTypeIds,
      dimensionTypeIds: TelemetryDimensionTypes,
    });

    this.telemetryFilterConfiguration = configuration;
    this.configReady = true;
  }

  loadGrid() {
    const newGridFilters = new DataBindingFilters();
    newGridFilters.filters = this.gridFilters.filters;
    this.gridFiltersForBinding = newGridFilters;
  }

  getSelectedTelemetryPoint(selectedTelemetryPoint: ISignalTelemetryNullableViewDto) {
    this.selectedTelemetryPoint = selectedTelemetryPoint;
    this.SendParameters(selectedTelemetryPoint, null);
  }

  private SendParameters(
    selectedElement: ISignalTelemetryNullableViewDto,
    filter: TabDetailPanelParameters
  ) {
    const parameters = filter ?? new TabDetailPanelParameters();
    const title = selectedElement
      ? `${this.pointDescriptionPrefix}: ${selectedElement?.pointDescription}`
      : '';

    parameters.addParameter(TabDetailParameterName.signalId, selectedElement?.signalId);
    parameters.addParameter(TabDetailParameterName.elementName, title);

    this.rightPanelService.setTabParameters(parameters);
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    if (filtersParameters && this.validateFilters(this.filterFields, filtersParameters)) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  validateFilters(filterFields: string[], filterParameters: DataBindingFilters): boolean {
    let filtersAreValid = true;
    filterFields.forEach((filterName) => {
      filtersAreValid = filtersAreValid && filterParameters?.filters?.get(filterName) !== undefined;
    });

    return filtersAreValid;
  }

  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        this.loadGrid();
      }
    });
  }

  openAddPopup() {
    this.preparePopup();
  }

  openEditPopup() {
    this.preparePopup(this.selectedTelemetryPoint);
  }

  openReplaceSignalPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '520px';
    dialogConfig.data = { signal: this.selectedTelemetryPoint, user: '' };
    const popup = this.replaceSignalDialog.open(ReplaceSignalPopupComponent, dialogConfig);
    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data && !data.hasErrors) {
          this._spinnerService.setLoading(true, this.pageCrud);
          const signal$ = this.cacheService.clearContainsInUrl('telemetry/signal');
          const configuration$ = this.cacheService.clearContainsInUrl(
            'telemetry/signal/configuration'
          );
          const validation$ = this.cacheService.clearContainsInUrl(
            'telemetry/signal/validation/group'
          );

          Promise.all([signal$, configuration$, validation$]).then(() => {
            this.dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.replace-success` })
            );
            this.genericGrid.reloadGrid();
            this.SendParameters(this.selectedTelemetryPoint, null);
            this._spinnerService.setLoading(false, this.pageCrud);
          });
        }
      });
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  private preparePopup(point: ISignalTelemetryNullableViewDto = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = PointCreationPopupDimensions.Width;
    dialogConfig.height = PointCreationPopupDimensions.Height;

    dialogConfig.data = point;

    const createPopup = this._dialogService.openComponent(
      PointCreationPopupComponent,
      dialogConfig
    );

    createPopup.afterClosed().subscribe((result) => {
      if (result) {
        this.genericGrid.reloadGrid();
      }
    });
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }
}
