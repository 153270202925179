<input
  type="number"
  class="custom-input hide-numeric-input-styles"
  matInput
  [formControl]="formControl"
  [formlyAttributes]="field"
  min="1"
  step="1"
  (keypress)="onKeyPress($event)"
/>
