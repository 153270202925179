<wlm-boolean-column [columnSettings]="columnSettings">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="elegibility-button-position">
      <button
        mat-stroked-button
        (click)="updateEligibility(dataItem)"
        onclick="this.blur()"
        [disabled]="isDisabled"
        [style]="buttonStyleWidth"
      >
        {{ getLabelText(dataItem, columnSettings.field) }}
      </button>
    </div>
  </ng-template>
  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-boolean-column-filter
      [field]="column.field"
      [filterService]="filterService"
      [filter]="filter"
      [elements]="elements"
    ></wlm-boolean-column-filter>
  </ng-template>
</wlm-boolean-column>
