import { Component, DestroyRef, Inject, Injector, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';

import { IntegrationGisLayersMappingComponent } from '../../integration-gis-layers-mapping/integration-gis-layers-mapping.component';

import { IntegrationGisLayersService } from '../../integration-gis-layers.service';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-mapping-widget';

export const INTEGRATION_GIS_LAYER_MAPPING_WIDGET_INSTANCE = COMPONENT_SELECTOR + '#1';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers-mapping-widget.component.html',
  styleUrls: ['./integration-gis-layers-mapping-widget.component.scss'],
  providers: [ReduxStateService],
})
export class IntegrationGisLayersMappingWidgetComponent extends BaseDynamicWidgetComponent {
  @ViewChild(IntegrationGisLayersMappingComponent) child: IntegrationGisLayersMappingComponent;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _service: IntegrationGisLayersService,
    private readonly _destroyRef: DestroyRef
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {
    this._service.resetAll$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.child?.clear();
    });
  }

  setMappingsFormValid(isValid: boolean): void {
    this._service.setMappingsFormValid(isValid);
  }

  get componentName(): string {
    return 'IntegrationGisLayersMappingWidgetComponent';
  }
}
