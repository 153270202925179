import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { LocalizationHelperService } from '../../localization/localization-helper.service';
import { MappersService } from '../../services/generic-mappers/mappers.service';
import { LabelValueListItem } from './label-value-list-item';

const COMPONENT_SELECTOR = 'wlm-label-value-list';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './label-value-list.component.html',
  styleUrls: ['./label-value-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelValueListComponent {
  private _properties: LabelValueListItem[];
  get properties(): LabelValueListItem[] {
    return this._properties;
  }
  @Input() set properties(value: LabelValueListItem[]) {
    this._properties = value;
    this.processItems();
  }

  private _values: any;
  get values(): any {
    return this._values;
  }
  @Input() set values(value: any) {
    this._values = value;
    this.processItems();
  }

  processedItems: { value$: Observable<any>; label$: Observable<any> }[];

  constructor(
    private _mappersService: MappersService,
    private _localization: LocalizationHelperService
  ) {}

  private processItems(): void {
    if (!this.properties || !this.values) {
      return;
    }

    this.processedItems = this.properties.map((property) => {
      const label = property.label ?? property.propertyKey;
      const value = this.getValue(property);

      let value$ = property.mappers
        ? this._mappersService.apply(property.mappers)(value, this.values, property.mappersParams)
        : of(value);

      if ((value as Observable<any>)?.subscribe) {
        value$ = (value as Observable<any>).pipe(
          switchMap((v) =>
            property.mappers
              ? this._mappersService.apply(property.mappers)(v, this.values, property.mappersParams)
              : of(v)
          )
        );
      }

      const processed = {
        ...property,
        label$: this._localization.get(label),
        value$,
      };
      return processed;
    });
  }

  private getValue(property: LabelValueListItem): any {
    if (!this.values) {
      return null;
    }

    let value;
    if (typeof property.propertyFn !== 'undefined') {
      value = property.propertyFn(this.values);
    } else if (typeof property.propertyKey !== 'undefined') {
      value = typeof this.values[property.propertyKey];
    } else {
      throw new Error(
        `The property ${property.label} must implement either propertyFn or propertyKey.`
      );
    }

    return value;
  }
}
