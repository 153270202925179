import { Component, Injector, inject } from '@angular/core';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { FamiliesAndRelationsFormComponent } from '../families-and-relations-form/families-and-relations-form.component';

@Component({
  selector: 'wlm-families-and-relations-crud',
  templateUrl: './families-and-relations-crud.component.html',
  styleUrls: ['./families-and-relations-crud.component.scss'],
})
export class FamiliesAndRelationsCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'FamiliesAndRelationsCrudService',
      grid: {
        gridSettingsName: 'FamiliesAndRelationsCrud',
      },
      create: {
        formComponent: FamiliesAndRelationsFormComponent,
      },
      update: {
        formComponent: FamiliesAndRelationsFormComponent,
      },
      delete: {},
    });
  }
}
