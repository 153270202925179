<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [localGrid]="leakageReportingGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      [disable$]="gridBtnsDisable$"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <button
          wlmCollapsibleButton
          *hasPerm="['WLMLeakageReportingCrud', 'u']"
          [disabled]="!selectedRows?.length || hasEditionChanges"
          [tooltip]="T_SCOPE + '.flag-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="openFlaggingPopup()"
        >
          <mat-icon svgIcon="flag"></mat-icon>
        </button>

        <button
          wlmCollapsibleButton
          *hasPerm="['WLMLeakageReportingCrud', 'u']"
          [disabled]="!selectedRows?.length || hasEditionChanges"
          [tooltip]="T_SCOPE + '.unflag-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="openUnflagPopup()"
        >
          <mat-icon svgIcon="unflag"></mat-icon>
        </button>

        <button
          wlmCollapsibleButton
          *hasPerm="['WLMLeakageReportingCrud', 'u']"
          [disabled]="!hasEditionChanges"
          [tooltip]="T_SCOPE + '.save-edit-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="saveEdition()"
        >
          <mat-icon svgIcon="save"></mat-icon>
        </button>

        <button
          wlmCollapsibleButton
          *hasPerm="['WLMLeakageReportingCrud', 'u']"
          [disabled]="!hasEditionChanges"
          [tooltip]="'common.discard-button-tooltip'"
          [showLabel]="isContainerCollapsed"
          (click)="undoEdit()"
        >
          <wlm-icon [icon]="'discard-changes'"></wlm-icon>
        </button>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-leakage-reporting-filter
      *ngIf="leakageReportingFilterConfig"
      [leakageReportingFilterConfig]="leakageReportingFilterConfig"
      [clearAll$]="clearAll$"
      [apply$]="triggerApply$"
      [persistFilters$]="persistFilters$"
      [disableAutoload]="gridSettings?.disableAutoLoad"
      [disablefilters]="disableElements"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="onApplyFilters($event)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-leakage-reporting-filter>
  </ng-container>
  <ng-container page-content>
    <wlm-local-grid
      class="wtr-fx-flex-grow generic-grid"
      [persistencyArea]="persistencyArea"
      [entityPermission]="entityPermission"
      [gridSettings]="gridSettings"
      [gridData]="currentCVs"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [resetSelectionHandler$]="removeSelection$"
      (selectedItemChanged)="getSelectedRow($event)"
      [(selectedItems)]="selectedRows"
      [showSelectedList]="true"
      [showSelectedItems]="true"
      (editedValuesChange)="onEditedValuesChange($event)"
      [undoEditedValues$]="undoEditedValues$"
      [clearPendingEdition$]="clearPendingEdition$"
    >
    </wlm-local-grid>
  </ng-container>
</wlm-page-wrapper>
