// Attributes needed to obtain the form settings from DB.

import { SettingsEntitySubtype } from './settings-entity-subtype';
import { SettingsEntityType } from './settings-entity-type';

export class DynamicFormSourceSettings {
  sourceKey?: string;
  entityType?: SettingsEntityType;
  elementTypeId?: any;
  entitySubtypes?: SettingsEntitySubtype[];
  categoryKey?: string;
  categoriesLabelsPath: string;
  attributesLabelsPath: string;
  entityNameKey?: string;
  labelOverwrites?: {
    onRegex: string;
    useKey: string;
    labelParam: string;
  }[];

  cleanCachedUrl?: string;

  // The data this object contains is made available for all formly expressions.
  formState: { [key: string]: any };

  constructor(init: DynamicFormSourceSettings) {
    Object.assign(this, init);
  }
}
