<wlm-page-wrapper
  [titleKey]="T_SCOPE + (selectedSchematic ? '.title-with-current' : '.title')"
  [titleKeyParams]="{
    schematicTitle: currentSchematicTitle
  }"
>
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.create' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="editMode"
        *hasPerm="['WLMSchematicsCrud', 'c']"
        (click)="onOpenImportPopup()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.edit' | translate | lowercase"
        onclick="this.blur()"
        *hasPerm="['WLMSchematicsCrud', 'u']"
        [disabled]="!selectedSchematic || editMode"
        (click)="onOpenEditMode()"
      >
        <mat-icon svgIcon="edit"></mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.delete' | translate | lowercase"
        onclick="this.blur()"
        [disabled]="!selectedSchematic || editMode"
        *hasPerm="['WLMSchematicsCrud', 'd']"
        (click)="onDeleteSchematics()"
      >
        <mat-icon class="material-icon" svgIcon="delete"></mat-icon>
      </button>
      <button
        *ngIf="settings?.showResetIcon"
        mat-icon-button
        [matTooltip]="'common.reset-layout' | translate | lowercase"
        onclick="this.blur()"
        (click)="onResetLayout()"
      >
        <wlm-icon [icon]="'reset-layout'"></wlm-icon>
      </button>
    </div>
  </ng-container>
  <ng-container page-content>
    <wlm-selector-dynamic-layout
      class="schematics-main-layout"
      [settings]="settings"
      (stateLoaded)="onStateLoaded($event)"
      (selected)="onSelected($event)"
      (manySelected)="onSelectedMany($event)"
    ></wlm-selector-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
