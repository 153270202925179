import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LocalizationHelperService } from './localization-helper.service';

/**
 * Change how Kendo translates components.
 * Does not consider language changes because the page reloads in that case.
 */

@UntilDestroy()
@Injectable()
export class KendoMessageService extends MessageService {
  private kendoTranslateKey = 'kendo';
  // Get all kendo translations in runtime, so the "get" method can work in sync.
  // Example { "kendo": {"grid": { "groupPanelEmpty": "" }}}
  // Important: Key names must not be modified because Kendo expects them like that.
  private translations = {};

  constructor(private localization: LocalizationHelperService) {
    super();

    this.localization.isAvailable$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateTranslations();
    });
  }

  updateTranslations(): void {
    this.localization.get(this.kendoTranslateKey).subscribe((translations) => {
      // Scope them the way Kendo expects them.
      this.translations = {
        [this.kendoTranslateKey]: translations,
      };
      this.notify(); // The get method is called again, and now the translations are updated.
    });
  }

  /**
   * The method is not async because, when notify is called, Kendo calls it again.
   */
  public get(key: string): string {
    const translated = this.getPropertyByPath(this.translations, key);
    return translated;
  }

  /**
   * Access nested properties in an object by path, like 'kendo.grid.groupPanelEmpty'.
   * Does not support brackets.
   */
  private getPropertyByPath(obj: any, path: string) {
    return path.split('.').reduce((p, c) => (p && p[c]) || null, obj);
  }
}
