import { HistoricalVersionDto } from '../../wlm-charts/core/models/historical-chart-settings/historical-version.dto';

export class HistoricalChartEditedEvent {
  editedHistoricalEvent: HistoricalVersionDto;
  hasChanges: boolean;

  constructor(init: HistoricalChartEditedEvent) {
    Object.assign(this, init);
  }
}
