<div class="grid-filter-container">
  <mat-form-field appearance="outline">
    <mat-select [(value)]="selectedOperator" (selectionChange)="operatorChange($event)">
      <mat-option *ngFor="let operator of filterOperators" [value]="operator.value">
        {{ operator.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="date-time-container">
    <mat-form-field
      *ngIf="!showTimeSelector"
      appearance="outline"
      class="date-time-picker-align-text"
    >
      <input
        matInput
        [matDatepicker]="picker"
        autocomplete="off"
        class="date-position"
        [value]="selectedDateTime"
        [disabled]="dateInputIsDisabled"
        (dateChange)="dateChange($event)"
        (input)="dateInput($event)"
        (keydown)="onKeyDownEvent($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        [touchUi]="false"
        (yearSelected)="preventClose()"
        (monthSelected)="preventClose()"
      ></mat-datepicker>
    </mat-form-field>
    <mat-form-field
      *ngIf="showTimeSelector"
      appearance="outline"
      class="date-time-size date-time-picker-align-text"
    >
      <input
        matInput
        [matDatepicker]="picker"
        autocomplete="off"
        class="date-position"
        [value]="selectedDateTime"
        (dateChange)="dateChange($event)"
        (input)="dateInput($event)"
        (keydown)="onKeyDownEvent($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [touchUi]="false"></mat-datepicker>
    </mat-form-field>
    <mat-form-field *ngIf="showTimeSelector" appearance="outline" class="time-size">
      <input
        matInput
        class="time-picker"
        type="time"
        autocomplete="off"
        (change)="timeChange($event)"
        [value]="selectedTime"
      />
    </mat-form-field>
  </div>
</div>
