// prettier-ignore
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicRenderizerComponentService {
  private compRef: ComponentRef<any>;

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  injectComponent<T>(component: Type<T>, propertySetter?: (type: T) => void): HTMLDivElement {
    // Remove the component if it already exists
    if (this.compRef) {
      this.compRef.destroy();
    }

    // Resolve and create
    const compFactory = this.resolver.resolveComponentFactory(component);
    this.compRef = compFactory.create(this.injector);

    // Properties setter
    if (propertySetter) {
      propertySetter(this.compRef.instance);
    }

    this.appRef.attachView(this.compRef.hostView);

    // Create a wrapper div and inject into the Html
    let div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    return div;
  }

  injectComponentWithoutDestroyIt<T>(
    component: Type<T>,
    containerElement: 'div' | 'span',
    propertySetter?: (type: T, componentRef: ComponentRef<T>) => void,
    featureInjector?: Injector,
    containerClasses?: string[]
  ): HTMLDivElement | HTMLSpanElement {
    // Remove the component if it already exists

    // Resolve and create
    const compFactory = this.resolver.resolveComponentFactory(component);
    const compRef = compFactory.create(featureInjector ?? this.injector);

    // Properties setter
    if (propertySetter) {
      propertySetter(compRef.instance, compRef);
    }

    this.appRef.attachView(compRef.hostView);

    // Create a wrapper div and inject into the Html
    let div = document.createElement(containerElement);
    div.classList.add(...(containerClasses ?? []));
    div.appendChild(compRef.location.nativeElement);

    return div;
  }

  injectComponentIntoViewContainer<T>(
    component: Type<T>,
    viewContainerRef: ViewContainerRef,
    propertySetter?: (type: T) => void,
    featureInjector?: Injector
  ): ComponentRef<T> {
    // Remove the component if it already exists
    viewContainerRef.clear();

    // Resolve and create
    const componentFactory = this.resolver.resolveComponentFactory(component);
    const injectIndex = 0;
    const componentRef = viewContainerRef.createComponent(
      componentFactory,
      injectIndex,
      featureInjector
    );

    // Properties setter
    if (propertySetter) {
      propertySetter(componentRef.instance);
    }

    return componentRef;
  }
}
