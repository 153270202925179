import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-selector-column',
  templateUrl: './selector-column.component.html',
  styleUrls: ['./selector-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => SelectorColumnComponent),
    },
  ],
})
export class SelectorColumnComponent extends WlmColumnComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
