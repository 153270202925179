<wlm-grid-buttons
  *ngIf="gridSettings"
  [(gridSettings)]="gridSettings"
  [gridFilters]="gridFiltersForBinding"
  [grid]="grid"
  [responsive]="false"
>
</wlm-grid-buttons>
<wlm-dynamic-grid
  class="generic-grid"
  *ngIf="settings"
  [dynamicGridSettings]="settings"
  [dataBindingFilters]="gridFiltersForBinding"
  [reloadGrid$]="reloadGrid$"
  [additionalFilters]="additionalFilters"
  (genericGridLoaded)="onGridLoaded($event)"
  (gridSettingsLoaded)="getGridSettings($event)"
></wlm-dynamic-grid>
