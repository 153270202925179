<div [formGroup]="form" class="bi-template-form">
  <mat-form-field [appearance]="fieldAppearance">
    <mat-label>{{ T_SCOPE + '.fields.template-name' | translate }}</mat-label>
    <input matInput formControlName="templateName" autocomplete="off" />
  </mat-form-field>
  <div *isSuperUser class="check-option">
    <mat-checkbox formControlName="isSystemTemplate" class="system-template-check">
      <span class="check-label">{{
        T_SCOPE + '.fields.is-system-template' | translate
      }}</span></mat-checkbox
    >
  </div>
  <div class="check-option">
    <mat-checkbox formControlName="isDashboardDefault"
      ><span class="check-label">{{
        T_SCOPE + '.fields.is-dashboard-default' | translate
      }}</span></mat-checkbox
    >
  </div>
</div>
