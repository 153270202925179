export class GroupCmdDto {
  groupId: number;
  groupName: string;
  groupDescription: string;
  userCode: string;

  constructor(groupId: number, groupName: string, groupDescription: string, userCode: string) {
    this.groupId = groupId;
    this.groupName = groupName;
    this.groupDescription = groupDescription;
    this.userCode = userCode;
  }
}
