export class LargeUserAssignedCardSettings {
  fieldLabels: { [key: string]: string };
  fields: string[];
  detailFields: string[];
  isReadOnly = false;
  deleteCallback?: (selectedItem: any) => void;
  editCallback?: (selectedItem: any) => void;

  constructor(init?: Partial<LargeUserAssignedCardSettings>) {
    Object.assign(this, init);
  }
}
