import { GChartTextStyles } from './g-chart-text-styles';

export class GChartAxisLabel extends GChartTextStyles {
  show?: boolean;
  formatter?: string | Function;
  color?: string;
  inside?: boolean;
  align?: 'left' | 'center' | 'right';
  interval?: number | 'auto' | Function;

  public constructor(init?: Readonly<GChartAxisLabel>) {
    super(init);
    Object.assign(this, init);
  }
}
