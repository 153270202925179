import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LogScope } from '../shared/wlm-log/log-scope';
import { LogService } from '../shared/wlm-log/log.service';
import { StateHelperService } from './helpers/state-helper.service';
import { GenericAction } from './models/generic-action';
import { GenericExtendedSelector } from './models/generic-extended-selector';
import { GenericSelector } from './models/generic-selector';
import { StateFullSettings } from './models/state-full-settings';
import { StateWidgetSettings } from './models/state-widget-settings';
import { GlobalStoreService } from './store/global-store.service';

/**
 * Facade service. Allows to dispatch actions while encapsulating NgRx implementations.
 */
@Injectable()
export class ReduxStateService {
  private _widgetSettings: StateWidgetSettings;
  private _configured = false;
  // TODO: delete after debug in QA
  private readonly _log = false;
  private readonly _logActions = []; // [GenericActionTypes.SetValue];
  private readonly _logSelectors = []; // [GenericSelectorTypes.GetValue];

  constructor(
    private _store: Store<any>,
    private _globalStoreService: GlobalStoreService,
    private _helperService: StateHelperService,
    private _logService: LogService
  ) {}

  configure(settings: StateWidgetSettings): void {
    if (!this._configured) {
      this._widgetSettings = this._helperService.clone(settings);

      if (!settings.module || !settings.page) {
        throw new Error(`RDX008: Some required params for redux initialization are missing.`);
      }
      this._configured = true;
    }
  }

  dispatch(action: GenericAction): void {
    if (!this._configured) {
      throw new Error(
        `RDX001: Attempting to dispatch the action "${action.type}" without configuring the service.`
      );
    }

    if (!action.settings) {
      throw new Error(`RDX002: The action "${action.type}" must specify its scope settings.`);
    }

    const fullSettings = new StateFullSettings({
      widget: this._widgetSettings,
      area: action.area,
      scope: action.settings.scope,
    });

    this.dispatchConfigured(action, fullSettings);
  }

  dispatchConfigured(action: GenericAction, fullSettings: StateFullSettings) {
    const actionCreator = this._globalStoreService.getActionCreators().get(action.type);
    if (!actionCreator) {
      throw new Error(`RDX003: No Action Creator was found for type "${action.type}".`);
    }

    // Sync the scope so the action does not have the default scope anymore.
    if (fullSettings.scope) {
      action.settings.scope = fullSettings.scope;
    }

    if (this._log) {
      this.logActions(action, fullSettings);
    }

    this._store.dispatch(
      actionCreator({ settings: fullSettings, payload: action.payload }) as Action
    );
  }

  select<T>(selector: GenericSelector | GenericExtendedSelector): Observable<T> {
    if (!this._configured) {
      throw new Error(
        `RDX004: Attempting to dispatch the selector "${selector.type}" without configuring the service.`
      );
    }

    if (!selector.settings) {
      throw new Error(`RDX005: The selector "${selector.type}" must specify its scope settings.`);
    }

    const fullSettings = new StateFullSettings({
      widget: this._widgetSettings,
      area: selector.area,
      scope: selector.settings.scope,
    });

    if (this._log) {
      this.logSelector(selector, fullSettings);
    }

    const payload = (selector as GenericExtendedSelector).payload;
    const selectorCreator = this._globalStoreService.getSelector<T>(
      selector,
      fullSettings,
      payload
    );

    return this._store
      .select(selectorCreator)
      .pipe(distinctUntilChanged(this._helperService.deepEqual));
  }

  cloneSettings(
    settings: StateWidgetSettings,
    mergeSettings: Partial<StateWidgetSettings> = {}
  ): StateWidgetSettings {
    const clonedSettings = this._helperService.clone(settings);
    const clonedMergeSettings = this._helperService.clone(mergeSettings);
    const newSettings = new StateWidgetSettings({ ...clonedSettings, ...clonedMergeSettings });
    return newSettings;
  }

  private logActions(action: GenericAction, fullSettings: StateFullSettings): void {
    if (!this._logActions.length || this._logActions.find((type) => type === action.type)) {
      const path = this._helperService.buildSelectorPath(fullSettings);

      const fieldName = (action?.payload as any)?.fieldName;
      const fieldNameStr = fieldName ? ` with field name: ${fieldName}, ` : '';

      this._logService.info({
        msg: `ACTION emitted: '${action.type}', ${fieldNameStr} with path: '${path}'`,
        scope: LogScope.ReactiveState,
        payload: {
          fullSettings,
          payload: action?.payload,
        },
      });
    }
  }

  private logSelector(selector: GenericSelector, fullSettings: StateFullSettings): void {
    if (!this._logSelectors.length || this._logSelectors.find((type) => type === selector.type)) {
      const path = this._helperService.buildSelectorPath(fullSettings);

      const fieldName = (selector as any)?.payload?.fieldName;
      const fieldNameStr = fieldName ? ` with field name: ${fieldName}, ` : '';
      this._logService.info({
        msg: `SELECTOR emitted: '${selector.type}', ${fieldNameStr} with path: '${path}'`,
        scope: LogScope.ReactiveState,
        payload: {
          fullSettings,
          payload: (selector as GenericExtendedSelector)?.payload,
        },
      });
    }
  }
}
