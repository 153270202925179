import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from 'src/app/common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutHandlers } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-events';
import { DynamicLayoutKeys } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-keys';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import { SelectNetworkElementConfigAction } from 'src/app/common-modules/dynamic-layout/state/ne-config/ne-config.actions';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { NetworkElementsService } from 'src/app/common-modules/shared-component/combined-grids/ne-selection-grid/ne-selection.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { NEC_SIGNALS_GROUP_COMPONENT_INSTANCE } from '../ne-config-signals-group/ne-config-signals-group.component';
import { NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE } from '../widgets/ne-config-attribute-form-group-widget/ne-config-attribute-form-group-widget.component';
import { NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE } from '../widgets/ne-configuration-elements-network-elements-widget/ne-configuration-elements-network-elements-widget.component';

const COMPONENT_SELECTOR = 'wlm-ne-details-config-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-details-page.component.html',
  styleUrls: ['./ne-config-details-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class NeConfigDetailsPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  settings: DynamicLayoutSettings;
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  selectedNe: INetworkElementDto;
  selectedTitle: string;

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);
  private _mainLayoutHandlers: DynamicLayoutHandlers;

  private readonly _layoutKey = 'LayoutDetails';
  private readonly _layoutArea = 'NetworkElementConfiguration';
  private readonly _widgetModule = 'necModule';
  private readonly _widgetPage = 'necDetailsPage';
  private readonly _necInstance1 = 'instance1';

  private readonly _attributesFormGroupWidgetName = 'NeConfigAttributeFormGroupWidgetComponent';
  private readonly _signalsGroupWidgetName = 'NeConfigSignalsGroupWidgetComponent';
  private readonly _eneGroupWidgetName = 'NeConfigurationElementsNetworkElementsWidgetComponent';

  private _necScopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  pageSettingsKeys = {
    layoutKey: this._layoutKey,
    layoutArea: this._layoutArea,
    widgetPage: this._widgetPage,
    widgetModule: this._widgetModule,
  };

  private _stateWidgetSettings = new StateWidgetSettings({
    module: this.pageSettingsKeys.widgetModule,
    page: this.pageSettingsKeys.widgetPage,
    scopeInstanceKeys: {
      [StateAreas.NEConfig]: this._necInstance1,
    },
  });

  constructor(
    public readonly injector: Injector,
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _route: ActivatedRoute,
    private _neService: NetworkElementsService
  ) {
    super();
    this.hasRightPanel = false;
    this._state.configure(this._stateWidgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const ne$ = this._route.queryParams.pipe(untilDestroyed(this)).pipe(
      mergeMap((params) => {
        const elementId =
          params?.elementId ?? params?.hierarchyElementIds ?? params?.networkElementId;
        this.selectedTitle =
          params?.elementId ?? params?.hierarchyElementIds ?? params?.networkElementName;

        if (elementId) {
          return this._neService.getNeById(elementId);
        }
      })
    );

    const titles$ = this.buildTitles();

    combineLatest([ne$, titles$]).subscribe(([ne, titles]) => {
      this.selectedNe = ne;
      this.settings = new DynamicLayoutSettings({
        layoutKey: this._layoutKey,
        layoutArea: this._layoutArea,
        currentUser: this._authenticationService.userCode,
        widgetPage: this._widgetPage,
        widgetModule: this._widgetModule,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Row,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                width: 70,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE,
                  },
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: NEC_SIGNALS_GROUP_COMPONENT_INSTANCE,
                  },
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE,
                  },
                ],
              },
            ],
          },
        ],
        // We do not specify the class here because we would like to be able to parse the whole "items" section from JSON.
        items: [
          {
            componentName: this._attributesFormGroupWidgetName,
            widgetInstanceKey: NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            params: {
              innerLayoutKeys: {
                widgetModule: this._widgetModule,
                widgetPage: this._widgetPage,
                settingArea: this._layoutArea,
                settingKey: 'LayoutAttributesGroup',
                scopeInstanceKeys: {
                  [StateAreas.NEConfig]: this._necInstance1,
                },
              } as DynamicLayoutKeys,
            },
            title: titles.get(this._attributesFormGroupWidgetName),
          },
          {
            componentName: this._signalsGroupWidgetName,
            widgetInstanceKey: NEC_SIGNALS_GROUP_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            params: {
              innerLayoutKeys: {
                widgetModule: this._widgetModule,
                widgetPage: this._widgetPage,
                settingArea: this._layoutArea,
                settingKey: 'LayoutSignalsGroup',
                scopeInstanceKeys: {
                  [StateAreas.NEConfig]: this._necInstance1,
                },
              } as DynamicLayoutKeys,
            },
            title: titles.get(this._signalsGroupWidgetName),
          },
          {
            componentName: this._eneGroupWidgetName,
            widgetInstanceKey: NEC_ELEMENT_NETWORK_ELEMENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.NEConfig]: this._necInstance1,
            },
            title: titles.get(this._eneGroupWidgetName),
          },
        ],
        minItemHeight: 70,
        minItemWidth: 200,
        showCloseIcon: false,
      });
      this._settings$.next(this.settings);

      this.onLayoutInit();
    });
  }

  onLayoutInit(): void {
    this.dispatchSelectedNetworkElement();
  }

  private dispatchSelectedNetworkElement(): void {
    this._state.dispatch(
      new SelectNetworkElementConfigAction(this.selectedNe, this._necScopeSettings)
    );
  }

  onResetLayout(): void {
    this._dynamicLayoutService.resetAll();
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'ConfigurationNetworkElementsCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._attributesFormGroupWidgetName, ts['wlm-ne-configuration-attributes-group']],
          [this._signalsGroupWidgetName, ts['wlm-ne-configuration-signals-group']],
          [this._eneGroupWidgetName, ts['wlm-ne-configuration-elements-network-elements']],
        ]);
        return titles;
      })
    );
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
