<wlm-telemetry-filter
  *ngIf="configReady"
  [storageLocation]="storageLocation"
  [telemetryFilterConfiguration]="telemetryFilterConfiguration"
  [clearAll$]="clearAll$"
  [persistFilters$]="persistFilters$"
  (filtersChanged)="setDataBindingFilters($event)"
  (filtersDetailParametersChanged)="setFiltersDetailsParameters($event)"
  (apply)="applyFilters()"
  (autoLoad)="onCheckAutoload()"
></wlm-telemetry-filter>
