import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorHelperService {
  constructor() {}

  hexAToRGBA(h: string): string {
    // if there is no alpha on the color, return the HEX
    if (h.length <= 7) {
      return h;
    }

    if (h.startsWith('#FF')) {
      return h.replace('#FF', '#');
    }

    let r: any = 0,
      g: any = 0,
      b: any = 0,
      a: any = 1;

    if (h.length == 5) {
      r = '0x' + h[1] + h[1];
      g = '0x' + h[2] + h[2];
      b = '0x' + h[3] + h[3];
      a = '0x' + h[4] + h[4];
    } else if (h.length == 9) {
      r = '0x' + h[1] + h[2];
      g = '0x' + h[3] + h[4];
      b = '0x' + h[5] + h[6];
      a = '0x' + h[7] + h[8];
    }
    a = +(a / 255).toFixed(3);

    return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
  }

  getPrimaryColor(): string {
    return '#1E6AB1';
  }
  getSecondaryColor(): string {
    return '#015691';
  }
  getErrorColor(): string {
    return '#AB1313';
  }
  getWarningColor(): string {
    return '#D9950B';
  }
}
