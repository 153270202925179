<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <span *hasPerm="['WLMCustomerDetailsCrud', 'u']">
          <button
            wlmCollapsibleButton
            *ngIf="!selectedCustomer?.isLargeUser"
            [disabled]="!selectedCustomer"
            [tooltip]="T_SCOPE + '.save-large-user'"
            [showLabel]="isContainerCollapsed"
            (click)="showSetCustomerAsLargeUserConfirmWindow()"
          >
            <mat-icon svgIcon="set-customer-as-large-user"></mat-icon>
          </button>
          <button
            wlmCollapsibleButton
            *ngIf="selectedCustomer?.isLargeUser"
            [disabled]="!selectedCustomer"
            [tooltip]="T_SCOPE + '.remove-large-user'"
            [showLabel]="isContainerCollapsed"
            (click)="showSetCustomerAsRegularConfirmWindow()"
          >
            <mat-icon svgIcon="set-customer-as-regular-user"></mat-icon>
          </button>
        </span>
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-hierarchy-element-tree-family-filter
      *ngIf="filterSettings"
      [settings]="filterSettings"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="onApplyFilters(null)"
      (valid)="onValidityChange($event)"
      (autoLoad)="onCheckAutoload()"
    ></wlm-hierarchy-element-tree-family-filter>
  </ng-container>
  <ng-container page-content>
    <wlm-generic-grid
      class="wtr-fx-flex-grow generic-grid"
      #genericGrid
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [gridSettings]="gridSettings"
      [applyConditionalSorting]="applyConditionalSorting"
      [conditionalSorting]="conditionalSorting"
      [gridFiltersForBinding]="gridFiltersForBinding"
      (selectedItemChanged)="getSelectedCustomer($event)"
      [(selectedItems)]="selectedCustomers"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [removeSelection$]="removeSelection$"
      [showSelectedList]="true"
      [showSelectedItems]="false"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
