import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyFiltersAction } from 'src/app/common-modules/dynamic-layout/state/filters/filters.actions';
import { ResetFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from 'src/app/common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { IPendingChangesChecker } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';

const COMPONENT_SELECTOR = 'wlm-data-validation-filter-widget';
export const DATA_VALIDATION_FILTER_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-filter-widget.component.html',
  styleUrls: ['./data-validation-filter-widget.component.scss'],
})
export class DataValidationFilterWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit, IPendingChangesChecker
{
  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: 'DataValidation',
  });

  private _reset$ = new Subject<void>();
  readonly reset$ = this._reset$.asObservable();

  pageId: string;
  widgetInstanceKey: string;

  get componentName(): string {
    return 'DataValidationFilterWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    this._state
      .select(new ResetFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))

      .subscribe({
        next: () => {
          this._reset$.next();
        },
      });
  }

  applyFilters(filters: DataBindingFilters): void {
    this.checkPendingChanges(this.pageId).subscribe((_) =>
      this._state.dispatch(new ApplyFiltersAction(filters, this._scopeSettings))
    );
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(untilDestroyed(this));
  }
}
