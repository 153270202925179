import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LogScope } from '../../../wlm-log/log-scope';
import { LogService } from '../../../wlm-log/log.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToFixedMapperParams } from '../mapper-params/to-fixed-mapper-params';

@Injectable()
export class ToFixedMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToFixed;

  constructor(private _logService: LogService) {
    super();
  }

  map = (value, model, params: ToFixedMapperParams): Observable<string> => {
    if (!params || typeof params.decimals === 'undefined') {
      this._logService.error({
        scope: LogScope.Mappers,
        msg: 'To apply toFixed mapper, the "decimals" setting must be set.',
        payload: params,
      });
      return of(null);
    }

    const { decimals } = params;
    let numericValue: number;

    if (typeof value === 'number') {
      numericValue = value;
    } else if (typeof value === 'string') {
      numericValue = Number(value);
      if (isNaN(numericValue)) {
        this._logService.error({
          scope: LogScope.Mappers,
          msg: 'To apply toFixed mapper, the value must be convertible to number.',
          payload: value,
        });
        return of(null);
      }
    }

    const result = numericValue.toFixed(decimals);
    return of(result);
  };
}
