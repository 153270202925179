<form *ngIf="form" [formGroup]="form">
  <div class="schematic-import-container">
    <div class="schematic-import-name">
      <mat-form-field [appearance]="fieldAppearance" class="field-stretch">
        <mat-label>{{ fieldScope + '.name' | translate }}</mat-label>
        <input matInput formControlName="name" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="schematic-import-file-container">
      <div class="schematic-import-file">
        <wlm-file-upload
          formControlName="nodes"
          [labelKey]="fieldScope + '.nodes'"
        ></wlm-file-upload>
      </div>
      <div class="schematic-import-template">
        <button
          mat-raised-button
          class="wtr-button-primary download-template-icon"
          (click)="onDownloadNodesTemplate()"
        >
          <mat-icon>get_app</mat-icon>
          {{ T_SCOPE + '.buttons.nodes-template' | translate }}
        </button>
      </div>
    </div>
    <div class="schematic-import-file-container">
      <div class="schematic-import-file">
        <wlm-file-upload
          formControlName="links"
          [labelKey]="fieldScope + '.links'"
        ></wlm-file-upload>
      </div>
      <div class="schematic-import-template">
        <button
          mat-raised-button
          class="wtr-button-primary download-template-icon"
          (click)="onDownloadLinksTemplate()"
        >
          <mat-icon>get_app</mat-icon>
          {{ T_SCOPE + '.buttons.links-template' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
