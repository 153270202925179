<div class="dvi-skeleton-container" [class.hide]="dataLoaded">
  <ngx-skeleton-loader [theme]="skeletonTheme" />
  <ngx-skeleton-loader [theme]="skeletonTheme" />
  <ngx-skeleton-loader [theme]="skeletonTheme" />
</div>
<div class="dvi-details-container" [class.hide]="!dataLoaded">
  <div *ngIf="!details?.length">
    {{ 'common.messages.no-results' | translate }}
  </div>
  <div *ngIf="details?.length">
    <div *ngFor="let category of categoriesOrder" class="dvi-details-group">
      <span *ngIf="detailsHash[category]">
        <div class="dvi-details-title">
          {{ 'common.signals.config.categories.' + category | translate }}
        </div>
        <span *ngIf="subcategoriesOrder[category]">
          <div *ngFor="let subcategory of subcategoriesOrder[category]">
            <div *ngIf="detailsHash[category][subcategory]" class="dvi-details-content">
              <div class="dvi-details-left-space"></div>
              <wlm-data-visualization-item-details-row
                [category]="category"
                [subcategory]="subcategory"
                [items]="detailsHash[category][subcategory]"
              >
              </wlm-data-visualization-item-details-row>
            </div>
          </div>
        </span>
        <div *ngIf="!subcategoriesOrder[category] && detailsHash[category]">
          <div
            *ngFor="let subcategory of getKeys(detailsHash[category])"
            class="dvi-details-content"
          >
            <div class="dvi-details-left-space"></div>
            <wlm-data-visualization-item-details-row
              [category]="category"
              [subcategory]="subcategory"
              [items]="detailsHash[category][subcategory] ?? []"
            >
            </wlm-data-visualization-item-details-row>
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
