import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthenticationService } from 'src/app/common-modules/shared/auth/services/authentication.service';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupCmdDto } from 'src/app/common-modules/shared/model/roles/group-cmd.dto';
import { GroupDto } from 'src/app/common-modules/shared/model/roles/group.dto';
import { RolesService } from 'src/app/common-modules/shared/roles/roles.service';
import { GroupUsersPopupComponent } from '../group-users-popup/group-users-popup.component';

const COMPONENT_SELECTOR = 'wlm-group-edition-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './group-edition-popup.component.html',
  styleUrls: ['./group-edition-popup.component.scss'],
})
export class GroupEditionPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  isSaving = false;
  form: UntypedFormGroup;
  group: GroupDto;

  public get titleTranslationKey(): string {
    return this.group.groupId === null ? `${this.T_SCOPE}.title-new` : `${this.T_SCOPE}.title-edit`;
  }

  constructor(
    _gridSettingsService: GridSettingsService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GroupUsersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { groupId, groupName, groupDescription }: GroupDto,
    private _groupService: RolesService,
    private _dialogService: DialogService,
    private _authenticationService: AuthenticationService
  ) {
    this.group = new GroupDto(groupName, groupDescription, groupId);
    this.createForm();
  }

  ngOnInit(): void {}

  private createForm() {
    this.form = this.fb.group({
      groupName: [this.group.groupName, [Validators.required]],
      groupDescription: [this.group.groupDescription],
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    this.isSaving = true;
    const groupCmd = new GroupCmdDto(
      this.group?.groupId,
      this.form.get('groupName').value,
      this.form.get('groupDescription').value,
      this._authenticationService.userCode
    );

    this._groupService.saveGroup(groupCmd).subscribe({
      next: (response) => {
        this.isSaving = false;
        if (response.errors.length === 0) {
          this.dialogRef.close(true);
          if (this.group?.groupId) {
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({
                translateKey: `${this.T_SCOPE}.messages.update-success`,
                params: { groupName: groupCmd.groupName },
              })
            );
          } else {
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({
                translateKey: `${this.T_SCOPE}.messages.create-success`,
                params: { groupName: groupCmd.groupName },
              })
            );
          }
        } else {
          if (response.hasSameNameError) {
            this.raiseSameNameErrorValidation();
          }
        }
      },
    });
  }

  private raiseSameNameErrorValidation() {
    this.form.get('groupName').setErrors({ nameAlreadyExists: true });
  }
}
