import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
// IMPORTANT: This should always be imported from material, not core.
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'wlm-file-upload-type',
  templateUrl: './file-upload-type.component.html',
  styleUrls: ['./file-upload-type.component.scss'],
})
export class FileUploadTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  readonly innerControl = new FormControl();

  ngOnInit(): void {
    this.innerControl.valueChanges.subscribe((value) => {
      this.formControl.setValue(value);
    });
  }
}
