<wlm-page-wrapper [titleKey]="T_SCOPE + '.title' | translate" [showPageSpinner]="!settingsLoaded">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        *hasPerm="['WLMMapCrud', 'u']"
        color="primary"
        onclick="this.blur()"
        (click)="openZoomsConfigurator()"
        [matTooltip]="T_SCOPE + '.open-zoom-configuration' | translate | lowercase"
      >
        <wlm-icon [icon]="'zoom-configuration'"></wlm-icon>
      </button>
      <button
        mat-icon-button
        *hasPerm="['WLMMapCrud', 'u']"
        color="primary"
        onclick="this.blur()"
        (click)="openThematicConfigurator()"
        [matTooltip]="T_SCOPE + '.open-thematic-configuration' | translate | lowercase"
      >
        <mat-icon class="material-icons-outlined">settings</mat-icon>
      </button>
      <wlm-export-pdf-button [exportPdfComponent]="baseMap"></wlm-export-pdf-button>
    </div>
  </ng-container>
  <ng-container *ngIf="settingsLoaded" page-content>
    <wlm-base-map
      class="map-resize"
      [mapParameters]="mapParameters"
      [initializeMap$]="initializeMap$"
      [displayMessage$]="displayMapMessage$"
    ></wlm-base-map>
  </ng-container>
</wlm-page-wrapper>
