import { GenericSelector } from './generic-selector';
import { StateScopeSettings } from './state-scope-settings';

export class GenericExtendedSelectorPayload {
  fieldName?: string;
}

export abstract class GenericExtendedSelector extends GenericSelector {
  payload: GenericExtendedSelectorPayload;

  constructor(payload: GenericExtendedSelectorPayload, settings = new StateScopeSettings({})) {
    super(settings);
    this.payload = payload;
  }
}
