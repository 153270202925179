import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alarmTriggerPoint',
})
export class AlarmTriggerPointPipe implements PipeTransform {
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(value: string): string {
    const pattern = new RegExp(/[()]/gi);
    const triggerPoint = value.replace('[True]', '').replace(pattern, '');
    return triggerPoint;
  }
}
