<wlm-placeholder-overlay
  *ngIf="!(selectedMergedZone && selectedHeFamilyId)"
  [titleKey]="phTitleKey"
  [subtitleKey]="phSubtitleKey"
></wlm-placeholder-overlay>
<wlm-merged-zones-configurator
  *ngIf="selectedMergedZone && selectedHeFamilyId"
  class="configurator"
  [pageId]="pageId"
  [mergedZone]="selectedMergedZone"
  (onMergedSaved)="onMergedZoneUpdated()"
  [heFamilyId]="selectedHeFamilyId"
></wlm-merged-zones-configurator>
