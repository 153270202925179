<kendo-grid-column
  [field]="field"
  [width]="width"
  [format]="format"
  [headerStyle]="headerStyle"
  [style]="style"
  [locked]="locked"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="cell-container">
      <span [style.justify-content]="getStyleValue(style, 'justify-content')" class="ellipsis-text">
        {{
          formatCell(dataItem[field], columnSettings.type, format, columnSettings.isPercentage)
            | gridCellEmpty
        }}
        <span
          class="comparison-percentage"
          *ngIf="
            !columnSettings.hideComparison &&
            gridSettings.showHistoricalComparison &&
            columnSettings.type === 'numeric' &&
            comparisonIsActive
          "
        >
          {{ dataItem[field] | comparisonPercentage : dataItem[comparisonField] }}</span
        >
        <wlm-icon
          class="comparison-icon"
          *ngIf="
            columnSettings.type === 'numeric' &&
            gridSettings.showHistoricalComparison &&
            !columnSettings.hideComparison &&
            comparisonIsActive
          "
          [icon]="dataItem[field] | comparisonImageIconName : dataItem[comparisonField]"
          [color]="dataItem[field] | comparisonImageIconColor : dataItem[comparisonField]"
        ></wlm-icon>

        &nbsp;
      </span>
      <button
        *ngIf="isLongContent"
        mat-icon-button
        class="expand-button"
        (click)="showTooltipExtended(dataItem)"
      >
        <mat-icon class="material-icons" color="primary">info</mat-icon>
      </button>
    </div>
  </ng-template>
  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-numeric-column-filter
      *ngIf="columnSettings.type === 'numeric'"
      [field]="column.field"
      [filter]="filter"
      [filterService]="filterService"
    ></wlm-numeric-column-filter>
    <wlm-text-column-filter
      *ngIf="columnSettings.type === 'text' || columnSettings.type === 'hierarchy'"
      [field]="column.field"
      [filter]="filter"
      [filterService]="filterService"
    ></wlm-text-column-filter>
  </ng-template>
</kendo-grid-column>
