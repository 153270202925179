import { Component, OnInit } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-buttons-group';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './buttons-group.component.html',
  styleUrls: ['./buttons-group.component.scss'],
})
export class ButtonsGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
