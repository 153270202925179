<wlm-spinner-wrapper [showSpinner]="isLoading">
  <div class="grid-container">
    <div class="topbar">
      <wlm-grid-buttons
        *ngIf="gridSettings"
        [(gridSettings)]="gridSettings"
        [localGrid]="dataVisualizationGrid"
      />
    </div>

    <wlm-local-grid
      *ngIf="gridSettings"
      class="wtr-fx-flex-grow generic-grid"
      [persistencyArea]="persistencyArea"
      [gridSettings]="gridSettings"
      [gridData]="gridData"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [resetSelectionHandler$]="removeSelection$"
      (selectedItemChanged)="getSelectedRow($event)"
      [showSelectedList]="true"
      [showSelectedItems]="true"
    >
    </wlm-local-grid>
  </div>
</wlm-spinner-wrapper>
