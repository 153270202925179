import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { BaseWidgetComponent } from 'src/app/common-modules/shared/component/base-widget.component';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { NoBoundarySettings } from '../../../../common-modules/dependencies/ne-configuration/no-boundary-settings';
import { NetworkElementsSignalsDto } from '../../shared/model/signals/network-elements-signals.dto';
import { NoBoundaryConfigurationDto } from '../../shared/model/signals/no-boundary-configuration.dto';
import { NeConfigurationNoBoundaryService } from './no-configuration-no-boundary.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-no-boundary';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-no-boundary.component.html',
  styleUrls: ['./ne-configuration-no-boundary.component.scss'],
})
export class NeConfigurationNoBoundaryComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter
{
  private _selectedNE: INetworkElementDto;
  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.updateQueryParams();
      this.loadSignals();
    }
  }

  @Input() widgetId: string;
  @Input() pageId: string;
  @Input() noBoundarySettings: NoBoundarySettings;
  @Output() hasChangesEvent = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'NeConfigurationNoBoundaryComponent';
  }

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.hasChangesEvent.emit(this.configurationHasChanged);
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  reloadList$ = new Subject<void>();
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  signalsIn: NetworkElementsSignalsDto[] = [];
  signalsOut: NetworkElementsSignalsDto[] = [];
  signalsNo: NetworkElementsSignalsDto[] = [];
  excludedSignals: NetworkElementsSignalsDto[] = [];
  refreshList$ = new Subject<void>();
  isReadOnly = true;

  private _originalSignalsIn: NetworkElementsSignalsDto[] = [];
  private _originalSignalsOut: NetworkElementsSignalsDto[] = [];
  private _originalSignalsNo: NetworkElementsSignalsDto[] = [];
  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _networkElementIdParamName = 'networkElementId';
  private _serviceName = 'NeConfigurationNoBoundaryService';
  private _pagesize = 50;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _noBoundaryService: NeConfigurationNoBoundaryService,
    private readonly _dialogService: DialogService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  onSave() {
    this.save().subscribe(() => {});
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    this._spinnerService.setLoading(true, this.componentName);
    networkElement = networkElement === undefined ? this.selectedNE : networkElement;
    this.signalsIn.forEach((signal) => {
      signal.networkElementId = networkElement.elementId;
      signal.isIn = true;
    });

    this.signalsOut.forEach((signal) => {
      signal.networkElementId = networkElement.elementId;
      signal.isIn = false;
    });

    this.signalsNo.forEach((signal) => {
      signal.networkElementId = networkElement.elementId;
      signal.isIn = null;
    });

    const allSignals = this.signalsIn.concat(this.signalsOut.concat(this.signalsNo));

    const newConfiguration = new NoBoundaryConfigurationDto({
      networkElementId: networkElement.elementId,
      signals: allSignals,
    });

    return this._noBoundaryService.saveBoundaryConfiguration(newConfiguration).pipe(
      tap((result) => {
        let dialogSettings = new WlmDialogSettings({ translateKey: '' });
        if (!result) {
          dialogSettings.translateKey = 'common.messages.saved-error';
          dialogSettings.icon = 'error';

          this._dialogService.showTranslatedMessage(dialogSettings);

          return;
        } else {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
        }

        this._spinnerService.setLoading(false, this.componentName);
        this.updateOriginalLists();
        this.reloadList$.next();
      }),
      map((_) => true)
    );
  }

  discard() {
    this.signalsIn = [...this._originalSignalsIn];
    this.signalsOut = [...this._originalSignalsOut];
    this.signalsNo = [...this._originalSignalsNo];
    this.excludedSignals = [];
    this.reloadList$.next();

    this.configurationHasChanged = false;
  }

  onDroppedElement($event) {
    const newExcludedSignals = [...this.excludedSignals];
    newExcludedSignals.push($event);
    this.excludedSignals = [...newExcludedSignals];
  }

  compareSignals() {
    if (this.listHasChanged(this.signalsIn, this._originalSignalsIn)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.signalsOut, this._originalSignalsOut)) {
      this.configurationHasChanged = true;
      return;
    }

    if (this.listHasChanged(this.signalsNo, this._originalSignalsNo)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  private prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.widgetId,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  private updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._networkElementIdParamName, this.selectedNE?.networkElementId);

    this.queryParams = newParams;
  }

  private loadSignals() {
    this._spinnerService.setLoading(true, this.componentName);
    this._noBoundaryService
      .getBoundarySignalsByElement(this.selectedNE.networkElementId)
      .subscribe({
        next: (boundaryConfiguration) => {
          if (boundaryConfiguration) {
            const sortedBoundaryConfiguration =
              this._arrayHelperService.sortArrayObjectCaseInsensitive(
                boundaryConfiguration,
                this._pointDescriptionFieldName
              );

            this.signalsIn = sortedBoundaryConfiguration.filter((x) => x.isIn === true);
            this._originalSignalsIn = sortedBoundaryConfiguration.filter((x) => x.isIn === true);
            this.signalsOut = sortedBoundaryConfiguration.filter((x) => x.isIn === false);
            this._originalSignalsOut = sortedBoundaryConfiguration.filter((x) => x.isIn === false);
            this.signalsNo = sortedBoundaryConfiguration.filter(
              (x) => x.isIn === null || x.isIn === undefined
            );
            this._originalSignalsNo = sortedBoundaryConfiguration.filter(
              (x) => x.isIn === null || x.isIn === undefined
            );

            this.configurationHasChanged = false;
            this.excludedSignals = [];
          }
          this._spinnerService.setLoading(false, this.componentName);
        },
      });
  }

  private updateOriginalLists() {
    this._originalSignalsIn = [...this.signalsIn];
    this._originalSignalsOut = [...this.signalsOut];
    this._originalSignalsNo = [...this.signalsNo];
    this.configurationHasChanged = false;
  }

  private listHasChanged(
    current: NetworkElementsSignalsDto[],
    original: NetworkElementsSignalsDto[]
  ) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
