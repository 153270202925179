import { IFiltrableItemDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { DataVisualizationCartSettings } from './data-visualization-cart-settings';

export class DataVisualizationCartSelection {
  visibleItems: IFiltrableItemDto[];
  items: IFiltrableItemDto[];
  cartSettings: DataVisualizationCartSettings;

  constructor(
    visibleItems: IFiltrableItemDto[],
    items: IFiltrableItemDto[],
    cartSettings?: DataVisualizationCartSettings
  ) {
    this.visibleItems = visibleItems ?? [];
    this.items = items ?? [];
    this.cartSettings = cartSettings;
  }
}
