<wlm-spinner-wrapper [showSpinner]="loading" [useOverflow]="true" class="mf-small-infix">
  <div *ngIf="saveOnChanges === false" class="button-container">
    <div>
      <button
        mat-icon-button
        color="primary"
        [disabled]="saveDisabled()"
        [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
        (click)="onSubmit()"
      >
        <wlm-icon [icon]="'save'"></wlm-icon>
      </button>
    </div>
    <div>
      <button
        mat-icon-button
        color="primary"
        [disabled]="resetDisabled()"
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        (click)="onReset()"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>
  </div>
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    *ngIf="fields && model && additionalOptions"
    class="form-container fixed-subscript"
  >
    <formly-form
      class="dynamic-form-theme form-fields-space"
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="additionalOptions"
    ></formly-form>
  </form>
</wlm-spinner-wrapper>
