import { BasicFilter } from '../../shared/filters/component-filters/basic-filter';
import { FiltersPayloadStatus } from './filters-payload-status.enum';

export class FiltersPayload {
  data: Map<string, BasicFilter>;
  status: Map<string, FiltersPayloadStatus>;
  lastChange: Map<string, number>;

  constructor(init: Partial<FiltersPayload>) {
    this.data = init.data ?? new Map<string, BasicFilter>();
    this.status = init.status ?? new Map<string, FiltersPayloadStatus>();
    this.lastChange = init.lastChange ?? new Map<string, number>();
  }

  public static joinMany(payloads: FiltersPayload[]): FiltersPayload {
    const allData = this.joinMaps(payloads.map((f) => f.data));
    const allStatus = this.joinMaps(payloads.map((f) => f.status));
    const allChanges = this.joinMaps(payloads.map((f) => f.lastChange));
    const result = new FiltersPayload({
      data: allData,
      status: allStatus,
      lastChange: allChanges,
    });
    return result;
  }

  private static joinMaps<T1, T2>(maps: Map<T1, T2>[]): Map<T1, T2> {
    const joined = maps.reduce((accum, current) => accum.concat([...current]), []);
    const joinedInMap = new Map<T1, T2>(joined);
    return joinedInMap;
  }

  public clone(): FiltersPayload {
    return new FiltersPayload({
      data: new Map<string, BasicFilter>(this.data),
      status: new Map<string, FiltersPayloadStatus>(this.status),
      lastChange: new Map<string, number>(this.lastChange),
    });
  }

  public addField(
    fieldName: string,
    filter: BasicFilter,
    status = FiltersPayloadStatus.Normal,
    lastChanged = +new Date()
  ): void {
    this.data.set(fieldName, filter);
    this.status.set(fieldName, status);
    this.lastChange.set(fieldName, lastChanged);
  }

  public getSelectedIds<T>(fieldName: string): T[] {
    const filter = this.data.get(fieldName) as BasicFilter;
    const selectedIds = filter.value?.map((item) =>
      typeof item.value !== 'undefined' ? item.value : item
    );
    return selectedIds;
  }

  public log() {
    const keys = [];
    const values = [];
    const statuses = [];
    Array.from(this.data.keys()).forEach((key) => {
      keys.push(key);
      values.push(JSON.stringify(this.data.get(key) ? this.data.get(key).value : null));
      statuses.push(this.status.get(key));
    });
    return [keys, values, statuses];
  }
}
