<div class="svg-editor-container">
  <div class="svg-editor-field">
    <mat-form-field>
      <mat-label>{{ label | translate }}</mat-label>
      <textarea
        matInput
        [placeholder]="placeholder | translate"
        [formControl]="unsecuredCtrl"
      ></textarea>
      <mat-error>
        <wlm-validation-messages
          [addMarginBottom]="false"
          [control]="unsecuredCtrl"
        ></wlm-validation-messages>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="svg-editor-preview-container">
    <h4 class="svg-editor-preview-title">{{ previewLabel | translate }}</h4>
    <div class="svg-editor-preview">
      <p *ngIf="!sanitizedValue">{{ previewPlaceholder | translate }}</p>
      <div #svgContent></div>
    </div>
  </div>
</div>
