import { Component, Input } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-icon-legacy';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './icon-legacy.component.html',
  styleUrls: ['./icon-legacy.component.scss'],
})
export class IconLegacyComponent {
  @Input() icon: string;
  @Input() isSvg = false;
  @Input() color: string;
}
