import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export class MapZoomConfigurationValidators {
  static greaterThan(
    getFormGroup: () => FormGroup,
    compareToField: string,
    defaultMax: number
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const formGroup = getFormGroup();
      const compareToValue = formGroup?.get(compareToField)?.value ?? 0;

      const maxValue = compareToValue < defaultMax ? +compareToValue : defaultMax;

      if (+c.value >= maxValue) {
        const error = {
          invalidRange: true,
        };

        c.setErrors(error);
        return error;
      }

      return null;
    };
  }

  static lowerThan(
    getFormGroup: () => FormGroup,
    compareToField: string,
    defaultMin: number
  ): ValidatorFn {
    return (c: AbstractControl) => {
      const formGroup = getFormGroup();
      const compareToValue = formGroup?.get(compareToField)?.value ?? 0;

      const minValue = compareToValue >= defaultMin ? +compareToValue : defaultMin;

      if (+c.value <= minValue) {
        const error = {
          invalidRange: true,
        };

        c.setErrors(error);
        return error;
      }

      return null;
    };
  }
}
