import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GenericCardSettings } from 'src/app/common-modules/shared-component/generic-card/generic-card-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from 'src/app/common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { MergedZonesDto } from '../../models/merged-zones.dto';

const COMPONENT_SELECTOR = 'wlm-merged-zones-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-selector.component.html',
  styleUrls: ['./merged-zones-selector.component.scss'],
})
export class MergedZonesSelectorComponent implements OnInit {
  private _filters: Map<string, any>;
  get filters(): Map<string, any> {
    return this._filters;
  }
  @Input() set filters(value: Map<string, any>) {
    this._filters = value;

    this.prepareListsSettings();
  }

  @Input() widgetInstanceKey: string;
  @Input() gridName: string;
  @Input() subscriptionTag = 'default';

  @Output() selectedElementChange = new EventEmitter<MergedZonesDto>();

  private readonly _heFieldName = 'hierarchyElementId';
  private readonly _selectedFieldName = 'hierarchyElementId';
  private readonly _serviceName = 'MergedZonesSelectionService';
  private readonly _pagesize = 20;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<MergedZonesDto>;
  queryParams: Map<string, any>;

  emptyFilters: DataBindingFilters;

  constructor() {}

  ngOnInit(): void {}

  onSelectItem(selectedItem: MergedZonesDto) {
    this.selectedElementChange.emit(selectedItem);
  }

  reload(): void {
    this.queryParams = new Map(this.filters);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._heFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.emptyFilters,
      useQueryParams: true,
      displayFieldName: this._heFieldName,
      isReadOnly: false,
      scrollId: 'mergedZonesSelector',
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._heFieldName],
    });

    this.cardSettings = new GenericCardSettings<MergedZonesDto>({
      title1Fn: (model) => model.hierarchyElementName,
      subtitleFn: (model) =>
        model.hierarchyElementsMergedZones.map((he) => he.hierarchyElementId).join(', '),
      extraInfoFn: (model) => model.hierarchyElementId,
    });

    this.queryParams = this.filters;
  }
}
