import { Injectable } from '@angular/core';
import Big from 'big.js';
import * as Color from 'color';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from '../config/settings.service';
import { ApplicationAttributes } from '../constants/application-constants';
import { GlobalsService } from '../services/globals.service';

import { LogService } from '../wlm-log/log.service';
import { globalUtilsHelper } from './global-utils-helper';

// Strict mode is what makes the library use precise operation.
Big.strict = true;

@Injectable({
  providedIn: 'root',
})
export class UtilsHelperService {
  constructor(
    private _settings: SettingsService,
    private _logService: LogService,
    private _globalsService: GlobalsService
  ) {}

  lightenColor(hexColor: string, percent: number): string {
    const result = Color(hexColor).lighten(percent).hex();
    return result;
  }

  fadeColor(hexColor: string, percent: number): string {
    const color = Color(hexColor).fade(percent);
    const result = `rgba(${color.color[0]}, ${color.color[1]}, ${color.color[2]}, ${color.valpha})`;
    return result;
  }

  // TODO: If getting it from backend, be sure to cache it, as this method is called many times.
  currentCurrency(): Observable<string> {
    return this._globalsService.getApplicationAttributesById(ApplicationAttributes.Currency).pipe(
      map((attribute) => {
        return attribute?.attributeValue;
      })
    );
  }

  generateGuid = () => globalUtilsHelper.generateGuid();

  /**
   * Multiplies string-parsed numbers with precission.
   * WARNING: values of type "number" will give a type errors, as it is not a precise representation.
   */
  precissionMultiply = (values: string[]): string => {
    try {
      const fn = this.precissionOperationFnBuilder((a, b) => a.times(b), new Big('1'));
      let result = fn(values);
      result = result.toPrecision();
      return result;
    } catch (exception) {
      const message = `Error multiplying '${values.join(', ')}'.`;
      this.logMathOperationError(message, values, [], exception);
      return null;
    }
  };

  /**
   * Divides string-parsed numbers with precission.
   * WARNING: values of type "number" will give a type errors, as it is not a precise representation.
   */
  precissionDivide = (valueA: string, valueB: string): string => {
    try {
      const bigA = new Big(valueA);
      const bigB = new Big(valueB);
      const result = bigA.div(bigB).toPrecision();
      return result;
    } catch (exception) {
      const message = `Error dividing '${valueA}' / '${valueB}'.`;
      this.logMathOperationError(message, valueA, valueB, exception);
      return null;
    }
  };

  uomMultiply(value: string, factor: string, strict = true, decimalPrecision?: number): string {
    try {
      Big.strict = strict;

      const bigValue = value === 'null' ? new Big(0) : new Big(value);
      const bigFactor = new Big(factor);
      let result = bigValue.times(bigFactor);
      result = result.toFixed(decimalPrecision ?? this._settings.uomMinPositionsToKeepPrecision);

      result = new Big(result);
      result = result.toString();

      Big.strict = false;

      return result;
    } catch (exception) {
      Big.strict = false;
      const message = `Error multiplying UOM '${value}' * '${factor}'.`;
      this.logMathOperationError(message, value, factor, exception);
      return null;
    }
  }

  uomDivide(value: string, factor: string, strict = true, decimalPrecision?: number): string {
    try {
      Big.strict = strict;

      const bigValue = value === 'null' ? new Big(0) : new Big(value);
      const bigFactor = new Big(factor);

      let result = bigValue.div(bigFactor);
      result = result.toString();

      Big.strict = false;

      return result;
    } catch (exception) {
      Big.strict = false;
      const message = `Error dividing UOM '${value}' / '${factor}'.`;
      this.logMathOperationError(message, value, factor, exception);
      return null;
    }
  }

  private precissionOperationFnBuilder = (
    combineFn: (value1: Big, value2: Big) => Big,
    initialValue: Big
  ) => {
    return (values: string[]): Big => {
      values.forEach((value) => {
        if (typeof value !== 'string') {
          console.error(
            'Precission operations can only be done with string-formatted numbers. Got ',
            value
          );
        }
      });

      const result = values
        .map((value) => new Big(value))
        .reduce((total, current) => combineFn(total, current), initialValue);
      return result;
    };
  };

  private logMathOperationError(msg, value, factor, exception): void {
    this._logService.error({
      msg,
      payload: {
        value,
        factor,
        exception,
      },
    });
  }

  decimalPlaces = (inputNumericStr: string): number => {
    const numericStr =
      typeof inputNumericStr === 'string' ? inputNumericStr : new String(inputNumericStr);

    let match = numericStr.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    const places = Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
    return places;
  };

  getClosestNumber(val1: number, val2: number, target: number) {
    if (target - val1 >= val2 - target) return val2;
    else return val1;
  }

  findClosestNumberFromArray(arr: number[], target: number) {
    let n = arr.length;

    // Corner cases
    if (target <= arr[0]) return arr[0];
    if (target >= arr[n - 1]) return arr[n - 1];

    // Doing binary search
    let i = 0,
      j = n,
      mid = 0;
    while (i < j) {
      mid = (i + j) / 2;

      if (arr[mid] == target) return arr[mid];

      // If target is less than array
      // element,then search in left
      if (target < arr[mid]) {
        // If target is greater than previous
        // to mid, return closest of two
        if (mid > 0 && target > arr[mid - 1])
          return this.getClosestNumber(arr[mid - 1], arr[mid], target);

        // Repeat for left half
        j = mid;
      }

      // If target is greater than mid
      else {
        if (mid < n - 1 && target < arr[mid + 1])
          return this.getClosestNumber(arr[mid], arr[mid + 1], target);
        i = mid + 1; // update i
      }
    }

    // Only single element left after search
    return arr[mid];
  }
}
