<h3 mat-dialog-title>{{ 'common.error-window.error-information' | translate }}</h3>
<mat-dialog-content class="mat-typography">
  <h4>{{ error.name }}</h4>
  <p>
    {{ error.message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [cdkCopyToClipboard]="error.message">
    {{ 'common.error-window.copy-to-clipboard' | translate }}
  </button>
  <button mat-button mat-dialog-close>{{ 'common.accept' | translate }}</button>
</mat-dialog-actions>
