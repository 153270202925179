<mat-form-field
  [appearance]="fieldAppearance"
  [class]="fieldClass"
  [floatLabel]="floatLabel"
  class="field-stretch"
  [ngClass]="{
    'items-selected': selectedOptions.length,
    'enhanced-field-disabled': isExternalDisabled
  }"
  (click)="onFieldClick($event)"
>
  <mat-label>{{ label }}</mat-label>

  <mat-select
    [formControl]="selectCtrl"
    multiple
    [placeholder]="placeholder"
    panelClass="enhanced-input-select-panel"
    readonly
  >
    <mat-option *ngFor="let option of options" [value]="option.value">
      <ng-container
        *ngIf="optionTemplateRef"
        [ngTemplateOutlet]="optionTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: option, isSummary: false }"
      >
      </ng-container>
    </mat-option>
  </mat-select>

  <div class="selection-container" (click)="openSelect()">
    <div class="selection-container-item" *ngFor="let selectedOption of selectedOptions">
      <ng-container
        *ngIf="optionTemplateRef"
        [ngTemplateOutlet]="optionTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: selectedOption, isSummary: true }"
      >
      </ng-container>
    </div>
  </div>
</mat-form-field>
