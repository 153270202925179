<div id="center-page">
  <div class="filter-buttons-container">
    <button
      mat-icon-button
      class="button-position"
      (click)="localGrid.clearFilters()"
      [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
    >
      <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
    </button>
  </div>
  <div id="center-container">
    <wlm-local-grid
      *ngIf="gridSettings"
      class="generic-grid"
      [persistencyArea]="persistencyArea"
      [gridSettings]="gridSettings"
      [gridData]="profileSummary"
      storageLocation="session"
    ></wlm-local-grid>
  </div>
</div>
