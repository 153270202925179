import { Directive } from '@angular/core';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { IBaseComponent } from './base-component.interface';
@Directive()
export abstract class BaseComponent implements IBaseComponent {
  loading = false;

  abstract mapInitParameters(parameters: TabDetailPanelParameters): any;
  abstract init(): void;

  setNavigationParameters(parameters: TabDetailPanelParameters) {
    this.mapInitParameters(parameters);
    this.init();
  }

  startLoading(): void {
    this.loading = true;
  }

  endLoading(): void {
    this.loading = false;
  }
}
