import { Component, Input, OnInit } from '@angular/core';
import { IAlarmSeverityTypeDto } from 'src/app/common-modules/dependencies/alarms/alarm-severity-type.dto';

const COMPONENT_SELECTOR = 'wlm-alarm-counter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-counter.component.html',
  styleUrls: ['./alarm-counter.component.scss'],
})
export class AlarmCounterComponent implements OnInit {
  @Input() criticalAlarmsCounter: number;
  @Input() highAlarmsCounter: number;
  @Input() mediumAlarmsCounter: number;
  @Input() lowAlarmsCounter: number;

  private _alarmSeverities: IAlarmSeverityTypeDto[];
  public get alarmSeverities(): IAlarmSeverityTypeDto[] {
    return this._alarmSeverities;
  }
  @Input() public set alarmSeverities(v: IAlarmSeverityTypeDto[]) {
    this._alarmSeverities = v;
    if (v?.length) {
      this.setAlarmSeverityColors();
    }
  }

  private _criticalityTypeId = 4;
  private _highTypeId = 3;
  private _mediumTypeId = 2;
  private _lowTypeId = 1;

  severities: IAlarmSeverityTypeDto[];

  criticalColor: string;
  highColor: string;
  mediumColor: string;
  lowColor: string;
  totalColor = '#FFFFFF';

  constructor() {}

  ngOnInit(): void {}

  setAlarmSeverityColors() {
    this.criticalColor = this.alarmSeverities.find(
      (x) => x.severityId === this._criticalityTypeId
    ).severityColor;
    this.highColor = this.alarmSeverities.find(
      (x) => x.severityId === this._highTypeId
    ).severityColor;
    this.mediumColor = this.alarmSeverities.find(
      (x) => x.severityId === this._mediumTypeId
    ).severityColor;
    this.lowColor = this.alarmSeverities.find(
      (x) => x.severityId === this._lowTypeId
    ).severityColor;
  }
}
