import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { GridSetting } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridBtnsDisable } from 'src/app/common-modules/shared/grid-buttons/models/grid-btns-disable';
import { LocalGridComponent } from 'src/app/common-modules/wlm-grid/local-grid/local-grid.component';

const COMPONENT_SELECTOR = 'wlm-data-visualization-grid';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-grid.component.html',
  styleUrls: ['./data-visualization-grid.component.scss'],
})
export class DataVisualizationGridComponent {
  @ViewChild(LocalGridComponent) public dataVisualizationGrid: LocalGridComponent;

  public T_SCOPE = `${AppModules.LeakageReporting}.${COMPONENT_SELECTOR}`;

  public get persistencyArea(): string {
    return DataVisualizationGridComponent.name;
  }

  @Input() gridSettings: GridSetting;
  @Input() gridData: any[];
  @Input() isLoading = false;

  removeSelectionPersisted$ = new ReplaySubject<void>();
  removeSelection$ = new ReplaySubject<boolean>();
  gridBtnsDisable$ = new ReplaySubject<GridBtnsDisable>();

  selectedRow: any;

  constructor() {}

  getSelectedRow(selectedRow: any) {
    this.selectedRow = selectedRow;
  }
}
