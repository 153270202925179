import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
export class RangeColorValidators {
  static maxRanges(maxRanges: number): ValidatorFn {
    return (c: UntypedFormGroup): { [key: string]: boolean } | null => {
      if (Object.keys(c.controls).length > maxRanges * 2) {
        return { maxRanges: true };
      } else {
        return null;
      }
    };
  }

  static rangesOrder(
    getRanges: () => string[],
    getForm: () => UntypedFormGroup,
    rangeId: string,
    rangePrefix: string
  ) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const ranges = getRanges();
      const formGroup = getForm();

      const controlIndex = ranges.findIndex((x) => x == rangeId);

      if (controlIndex == 0) {
        return null;
      }

      const previousRangeId = ranges[controlIndex - 1];
      const previousRangeControl = formGroup.get(rangePrefix + previousRangeId);

      if (+c.value <= +previousRangeControl.value) {
        c.setErrors({ rangesOrder: true });
        return { rangesOrder: true };
      }

      return null;
    };
  }
}
