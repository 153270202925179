<wlm-page-wrapper>
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="emptyFilters"
      [grid]="grid"
      [gridName]="gridName"
      [disable$]="gridBtnsDisable$"
      (gridSettingsChange)="onGridSettingsChanged($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <button
          wlmCollapsibleButton
          [disabled]="downloadDisabled()"
          [tooltip]="COMMON_SCOPE + '.download'"
          [showLabel]="isContainerCollapsed"
          (click)="onClickDownload()"
        >
          <!-- TODO: Change for correct icon when available -->
          <mat-icon class="material-icons-outlined">download</mat-icon>
        </button>
        <button
          *ngIf="selectedInterface.isApi && !isGisInterfaceSelected"
          wlmCollapsibleButton
          [tooltip]="COMMON_SCOPE + '.buttons.send-api-request'"
          [showLabel]="isContainerCollapsed"
          (click)="openRequestPopup()"
        >
          <mat-icon svgIcon="send-json-request"></mat-icon>
        </button>
        <button
          *ngIf="selectedInterface.isFile && !isGisInterfaceSelected"
          wlmCollapsibleButton
          [tooltip]="COMMON_SCOPE + '.buttons.send-csv-request'"
          [showLabel]="isContainerCollapsed"
          (click)="onOpenCsvRequestPopup()"
        >
          <mat-icon svgIcon="send-csv-request"></mat-icon>
        </button>
        <button
          *ngIf="isGisInterfaceSelected"
          wlmCollapsibleButton
          [tooltip]="COMMON_SCOPE + '.buttons.navigate-to-integration'"
          [showLabel]="isContainerCollapsed"
          (click)="navigateToIntegration()"
        >
          <wlm-icon icon="integration"></wlm-icon>
        </button> </ng-template
    ></wlm-grid-buttons>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-grid
      class="generic-grid"
      *ngIf="dynamicGridSettings"
      [dynamicGridSettings]="dynamicGridSettings"
      (genericGridLoaded)="getGenericGridInstance($event)"
      (gridSettingsLoaded)="getGridSettings($event)"
      [dataBindingFilters]="emptyFilters"
      [reloadGrid$]="reloadGrid$"
      [removeSelection$]="removeSelection$"
      [removeSelectionPersisted$]="removeSelectionPersisted$"
      [additionalFilters]="additionalFilters"
      [gridSettingsChanged$]="gridSettingsChanged$"
      [showSelectedList]="true"
      [showSelectedItems]="false"
    ></wlm-dynamic-grid>
  </ng-container>
</wlm-page-wrapper>
