import { Component, Injector, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ILeakageTypeGroupDto } from 'src/app/common-modules/dependencies/alc/leakage-type-group.dto';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ValidationHelperService } from 'src/app/common-modules/shared/helpers/validation-helper.service';
import { LeakTypeGroupsCrudService } from '../leak-type-groups-crud.service';
import { LeakTypeGroupsFormComponent } from '../leak-type-groups-form/leak-type-groups-form.component';

const COMPONENT_SELECTOR = 'wlm-leak-type-groups-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './leak-type-groups-crud.component.html',
  styleUrls: ['./leak-type-groups-crud.component.scss'],
})
export class LeakTypeGroupsCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _service = inject(LeakTypeGroupsCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'LeakTypeGroupsCrudService',
      grid: {
        gridSettingsName: 'LeakTypeGroupsCrud',
      },
      create: {
        formComponent: LeakTypeGroupsFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: LeakTypeGroupsFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: ILeakageTypeGroupDto): Observable<ILeakageTypeGroupDto | null> => {
      return this._service.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.leak-type-group-id`
            );
            return null;
          }
          return model;
        })
      );
    };
}
