<wlm-spinner-wrapper [showSpinner]="mainChartLoading || eventChartLoading || loading">
  <div class="chart-container">
    <div class="chart generic-chart">
      <wlm-custom-workspace-chart
        *ngIf="dynamicChartSettings?.chartSettings?.chartType === chartTypes.customizable"
        [customWorkspaceSettings]="dynamicChartSettings.chartSettings"
        [containerSize$]="calculatedSize$"
        (loadingEvent)="setMainChartLoading($event)"
        (chartInitEvent)="onChartInit($event)"
        (chartLegendSelectedEvent)="onChartLegendSelected($event)"
        (chartLoaded)="onChartLoaded($event)"
      ></wlm-custom-workspace-chart>
      <wlm-trend-chart
        *ngIf="dynamicChartSettings?.chartSettings?.chartType === chartTypes.trend"
        [chartSettings]="dynamicChartSettings.chartSettings"
        [size]="calculatedSize$ | async"
        (loadingEvent)="setMainChartLoading($event)"
        (chartInitEvent)="onChartInit($event)"
        (chartDataZoomEvent)="onChartDataZoom($event)"
        (chartLegendSelectedEvent)="onChartLegendSelected($event)"
        (genericChartSettingsChange)="onMainChartSettingsChange($event)"
        (chartLoaded)="onChartLoaded($event)"
        (chartFinished)="onChartFinished($event)"
      ></wlm-trend-chart>
    </div>
    <hr
      fixedSizeElement
      *ngIf="
        eventChartSettings &&
        dynamicChartSettings?.chartSettings &&
        !loading &&
        chartType !== chartTypes.customizable
      "
      class="separator"
      style="margin: 5px"
    />
    <div
      class="chart events-chart"
      *ngIf="chartType !== chartTypes.customizable && eventChartSettings && xAxisRange"
    >
      <wlm-events-chart
        [xAxisRange]="xAxisRange"
        [eventQuery]="eventChartSettings"
        (chartClick)="onChartClick($event)"
        (isLoading)="onEventChartLoading($event)"
      ></wlm-events-chart>
    </div>
  </div>
</wlm-spinner-wrapper>
