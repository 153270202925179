import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LogService } from '../../../wlm-log/log.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToJointStringsMapperParams } from '../mapper-params/to-joint-strings-mapper-params';

@Injectable()
export class ToJointStringsMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToJointStrings;

  constructor(private _logService: LogService) {
    super();
  }

  map = (value, model, params: ToJointStringsMapperParams): Observable<string> => {
    const { stringSeparator, maxCharacters } = params;

    if (!value?.length) {
      return of('');
    }

    const stringsToJoint = value as string[];

    let result = stringsToJoint.join(stringSeparator ?? ', ');

    if (maxCharacters && result.length > maxCharacters) {
      result = `${result.substring(0, maxCharacters)}...`;
    }

    return of(result);
  };
}
