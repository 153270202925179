import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DateHelperService } from '../../../helpers/date-helper.service';
import { DateFormats } from '../../../localization/date-formats.enum';
import { LocalizationHelperService } from '../../../localization/localization-helper.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToReadableDateMapperParams } from '../mapper-params/to-readable-date-mapper-params';

@Injectable()
export class ToReadableDateMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToReadableDate;

  constructor(
    private _localization: LocalizationHelperService,
    private _dateHelperService: DateHelperService
  ) {
    super();
  }

  map = (
    inputDate: string | Date,
    model: unknown,
    params?: ToReadableDateMapperParams
  ): Observable<string | null> => {
    if (!inputDate) {
      return of(inputDate as any);
    }

    let date: Date;
    if (typeof inputDate === 'string') {
      date = this._dateHelperService.fromApiFormat(inputDate);
    } else if (this._dateHelperService.isDateObject(inputDate)) {
      date = inputDate;
    } else {
      throw new Error(`Value is not a valid date (${inputDate}).`);
    }

    let { format, toUtc } = params ?? { format: DateFormats.Date, toUtc: false };
    if (!format) {
      format = DateFormats.Date;
    }

    if (!toUtc) {
      toUtc = false;
    }

    const formatted = this._localization.formatDate(date, format, toUtc);

    return of(formatted);
  };
}
