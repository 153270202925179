import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { HistoricalVersionDto } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { ISaveHistoricalVersionResponse } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/save-historical-version';

@Injectable()
export abstract class BaseHistoricalMessagesService {
  T_SCOPE = `${AppModules.Configuration}.base-messages-service`;
  private _configTitleMsg = `${this.T_SCOPE}.error-title`;
  private _configValuesMsg = `${this.T_SCOPE}.validation.values`;
  private _currentDatesMsg = 'common.current-dates';
  private _fieldsDictionary: { [key: string]: string } = {
    "HierarchyElementId": "zone",
    "Category": "category",
    "NetworkElementName": "ne-name"
  };

  private _newLine = of('\n');

  constructor(
    private _localizationService: LocalizationHelperService,
    private _dateHelper: DateHelperService,
    private _dialogService: DialogService
  ) { }

  abstract additionalErrorFields(error: HistoricalVersionDto): Observable<string>;

  showValidationErrorsPopup(response: ISaveHistoricalVersionResponse): void {
    const ts$ = [this._configTitleMsg, this._configValuesMsg, this._currentDatesMsg].map((msg) =>
      this._localizationService.get(msg)
    );

    let errorList = this.combineErrorLists(response.historicalEventErrors, response.relatedErrors, response.otherEntityErrors);

    forkJoin(ts$).subscribe(([titleTs, valuesTs, currentDatesTs]) => {
      let finalMessage = '';
      const errorMessages$ = [];

      if (Object.keys(errorList).length > 0) {
        this.generateMessageForErrors(errorList, currentDatesTs, errorMessages$);
      }

      forkJoin(errorMessages$).subscribe((messages) => {
        finalMessage += messages.join('\n');

        this._dialogService.showMessage(finalMessage, 'error', {
          popup: 'cross-site-errors-popup',
        });
      });
    });
  }

  private generateMessageForErrors(errors: any, currentDatesTs: any, errorMessages$: any[]) {
    var conflictedPoints = Object.keys(errors);

    conflictedPoints.forEach((pointId) => {

      const pointHeaderTs = this.getPopupErrorField('point-info-header', { ['value']: pointId });
      errorMessages$.push(pointHeaderTs);

      if (errors.hasOwnProperty(pointId)) {

        const errorList = errors[pointId];

        errorList.forEach((error) => {
          var errorFields$ = [];
          const { entityErrorInformation, pointDescription, networkElementName, hierarchyElementId, validFrom, validTo } = error;

          if (entityErrorInformation) {
            this.processOtherFieldsMessageLines(entityErrorInformation, errorFields$);
          }

          const networkElementNameTs = networkElementName
            ? this.getPopupErrorField('element-name', { ['value']: networkElementName })
            : of(null);
          const hierarchyElementIdTs = hierarchyElementId
            ? this.getPopupErrorField('zone', { ['value']: hierarchyElementId })
            : of(null);
          const validFromTs = validFrom
            ? this.getPopupErrorField(`valid-from`, {
              ['value']: this.formatDate(validFrom, currentDatesTs),
            })
            : of(null);
          const validToTs = validTo
            ? this.getPopupErrorField(`valid-to`, {
              ['value']: this.formatDate(validTo, currentDatesTs),
            })
            : of(null);

          const additionalTs = this.additionalErrorFields(error as HistoricalVersionDto);

          errorFields$ = errorFields$.concat([
            networkElementNameTs,
            hierarchyElementIdTs,
            validFromTs,
            validToTs,
            additionalTs
          ]);

          const errorMessage$ = forkJoin(errorFields$).pipe(map((items) => items.filter(Boolean).join(', ')));

          errorMessages$.push(errorMessage$);
        });

        errorMessages$.push(this._newLine);
      }
    });
  }

  processOtherFieldsMessageLines(entityErrorInformation: any, errorFields$: any[]) {
    entityErrorInformation.forEach(property => {
      const propertyEntry = Object.values(property) as string[];

      if (propertyEntry?.length) {

        const key = this.getProperFieldName(propertyEntry[0]);
        const value = propertyEntry[1];

        const propertyFieldTs = key
          ? this.getPopupErrorField(key, { value })
          : of(null);

        errorFields$.push(propertyFieldTs);
      }
    });
  }

  private combineErrorLists(historicalErrors: { [key: string]: any[] }, relatedErrors: { [key: string]: any[] },
    otherErrors: { [key: string]: any[] }): { [key: string]: any[] } {

    const combinedErrorList: { [key: string]: any[] } = {};

    for (const key in historicalErrors) {
      combinedErrorList[key] = historicalErrors[key];
    }

    for (const key in relatedErrors) {
      if (combinedErrorList[key]) {
        combinedErrorList[key] = combinedErrorList[key].concat(relatedErrors[key]);
      } else {
        combinedErrorList[key] = relatedErrors[key];
      }
    }

    for (const key in otherErrors) {
      if (combinedErrorList[key]) {
        combinedErrorList[key] = combinedErrorList[key].concat(otherErrors[key]);
      } else {
        combinedErrorList[key] = otherErrors[key];
      }
    }

    return combinedErrorList;
  }

  getProperFieldName(fieldName: string) {
    return this._fieldsDictionary[fieldName];
  }

  private getPopupErrorField(fieldKey: string, params: any): Observable<string> {
    return this._localizationService.get(`${this.T_SCOPE}.validation.fields.${fieldKey}`, params);
  }

  private formatDate(date: Date, currentDatesTs): string {
    if (this._dateHelper.isCurrentStartDate(date)) {
      return currentDatesTs.start;
    }

    if (this._dateHelper.isCurrentEndDate(date)) {
      return currentDatesTs.end;
    }

    return this._localizationService.formatDate(
      this._dateHelper.ensureDateObject(date),
      DateFormats.Date
    );
  }
}
