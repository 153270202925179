import { Inject, Injectable, Optional } from '@angular/core';
import { Action, ActionReducer, State } from '@ngrx/store';
import { R_STATE_SYNC_SETTINGS } from '../redux-state.module';
import { BaseGlobalReducer } from '../store/base-global.reducer';
import { StateSyncSettings } from './state-sync-settings';
import { SyncStateActionTypes, UpdateStateGlobalAction } from './state-sync.actions';
import { StateSyncService } from './state-sync.service';

@Injectable()
export class SyncStateGlobalReducer extends BaseGlobalReducer {
  constructor(
    private _stateSyncService: StateSyncService,
    @Optional() @Inject(R_STATE_SYNC_SETTINGS) private _syncSettings: StateSyncSettings
  ) {
    super();
  }

  createReducer(reducer: ActionReducer<State<any>>): ActionReducer<State<any>> {
    return (state: State<any> | undefined, action: Action) => {
      if (state === undefined && this._syncSettings) {
        // If a persisted state was available, use it instead.
        // Explanation of non-async method in method signature.
        const persistedState = this._stateSyncService.getState();
        return persistedState ?? reducer(state, action);
      }

      if (action.type === SyncStateActionTypes.UpdateState) {
        // Replace the current state with the new state.
        return (action as UpdateStateGlobalAction).payload;
      }

      // Continue the reducers chain.
      const newState = reducer(state, action);

      if (this._syncSettings) {
        this._stateSyncService.sendState(newState);
      }

      return newState;
    };
  }
}
