import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpCacheService } from 'src/app/common-modules/cache/http-cache/http-cache.service';
import { IHierarchyElementDto } from 'src/app/common-modules/dependencies/he/hierarchy-element.dto';
import { CrudConstants } from 'src/app/common-modules/dependencies/shared/crud-constants';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { AuthorizeService } from 'src/app/common-modules/shared/auth/services/authorize.service';
import { DragListCardSettings } from 'src/app/common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from 'src/app/common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from 'src/app/common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from 'src/app/common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from 'src/app/common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { HierarchyChildrenConfigurationDto } from '../../models/hierarchy-children-configuration.dto';
import { HierarchyChildrenConfiguratorService } from '../../services/hierarchy-children-configurator.service';

const COMPONENT_SELECTOR = 'wlm-hierarchy-children-configurator';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-children-configurator.component.html',
  styleUrls: ['./hierarchy-children-configurator.component.scss'],
})
export class HierarchyChildrenConfiguratorComponent implements OnInit, IPendingChangesEmitter {
  private _selectedHE: IHierarchyElementDto;
  public get selectedHE(): IHierarchyElementDto {
    return this._selectedHE;
  }
  @Input() public set selectedHE(value: IHierarchyElementDto) {
    this._selectedHE = value;
    if (value) {
      this.updateQueryParams();
      this.loadChildren();
    }
  }

  @Input() pageId: string;
  @Input() selectedFamilyId: string;

  public T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  reloadList$ = new Subject<void>();
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  parentCardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  children: IHierarchyElementDto[] = [];
  excludedElements: IHierarchyElementDto[] = [];
  refreshList$ = new Subject<void>();
  isReadOnly = true;
  componentName = 'HierarchyChildrenConfiguratorComponent';

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  private _originalChildren: IHierarchyElementDto[] = [];
  private _titleFieldName = 'title';
  private _hierarchyElementNameFieldName = 'hierarchyElementName';
  private _serviceName = 'HierarchyChildrenConfiguratorService';
  private _pagesize = 50;

  private _hierarchyElementTypeIdFieldName = 'hierarchyElementTypeId';
  private _hierarchyElementIdFieldName = 'hierarchyElementId';
  private _hierarchyFamilyIdFieldName = 'hierarchyFamilyId';

  constructor(
    private _dialogService: DialogService,
    private _spinnerService: SpinnerService,
    private _heChildrenService: HierarchyChildrenConfiguratorService,
    private _arrayHelperService: ArrayHelperService,
    private _authorizeService: AuthorizeService,
    private _pendingChangesService: PendingChangesManagerService,
    private _cacheService: HttpCacheService
  ) {}

  ngOnInit(): void {
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  checkReadOnlyAccess() {
    this._authorizeService.canAccess(CrudConstants.HierarchyCrud, 'u').subscribe((canAccess) => {
      this.isReadOnly = !canAccess;
    });
  }

  prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._hierarchyElementIdFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.componentName,
    });
    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._hierarchyElementIdFieldName, this._hierarchyElementNameFieldName],
      fieldLabels: {
        hierarchyElementId: this._hierarchyElementIdFieldName,
        hierarchyElementName: this._hierarchyElementNameFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
    this.parentCardSettings = {
      ...this.cardSettings,
      isReadOnly: true,
    };
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();
    newParams.set(this._hierarchyFamilyIdFieldName, this.selectedFamilyId);
    newParams.set(this._hierarchyElementTypeIdFieldName, this.selectedHE?.hierarchyElementTypeId);

    this.queryParams = newParams;
  }

  compareHE() {
    if (this.listHasChanged(this.children, this._originalChildren)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  onSave(hierarchyElement?: IHierarchyElementDto) {
    this.save(hierarchyElement).subscribe(() => {});
  }

  save(hierarchyElement?: IHierarchyElementDto): Observable<boolean> {
    this.setLoading(true);
    hierarchyElement = hierarchyElement === undefined ? this.selectedHE : hierarchyElement;
    var descendantTypeId = this.children.length ? this.children[0].hierarchyElementTypeId : null;

    const newConfiguration = new HierarchyChildrenConfigurationDto({
      hierarchyFamilyId: this.selectedFamilyId,
      ancestor: hierarchyElement.hierarchyElementId,
      ancestorTypeId: hierarchyElement.hierarchyElementTypeId,
      descendantTypeId,
      descendants: this.children?.map((m) => m.hierarchyElementId),
    });

    return this._heChildrenService.saveHierarchyChildrenConfiguration(newConfiguration).pipe(
      tap((result) => {
        let dialogSettings = new WlmDialogSettings({ translateKey: '' });

        if (!result) {
          dialogSettings.translateKey = `${this.T_SCOPE}.messages.save-error`;
          dialogSettings.icon = 'error';
          this._dialogService.showTranslatedMessage(dialogSettings);
          return;
        } else {
          const clearhierarchy$ = this._cacheService.clearContainsInUrl('he');

          Promise.all([clearhierarchy$]).then(() => {
            this._dialogService.showEntityActionSnackBar('save', 'configuration');

            this.setLoading(false);
            this.updateOriginalLists();
            this.reloadList$.next();
          });
        }
      }),
      catchError((error) => {
        this.displayErrorMessage(`${this.T_SCOPE}.messages.save-error`);
        this.setLoading(false);

        return of(null);
      }),
      map((_) => true)
    );
  }

  discard() {
    this.children = [...this._originalChildren];
    this.excludedElements = [...this._originalChildren];
    this.reloadList$.next();
    this.configurationHasChanged = false;
  }

  onDroppedElement($event) {
    const newExcludedElements = [...this.excludedElements];
    newExcludedElements.push($event);
    this.excludedElements = [...newExcludedElements];
  }

  private listHasChanged(current: IHierarchyElementDto[], original: IHierarchyElementDto[]) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  private updateOriginalLists() {
    this._originalChildren = [...this.children];
    this.configurationHasChanged = false;
  }

  private loadChildren() {
    this.setLoading(true);
    this._heChildrenService
      .getChildrenByElementId(this.selectedHE.hierarchyElementId, this.selectedFamilyId)
      .subscribe({
        next: (childrenConfiguration) => {
          if (childrenConfiguration) {
            const sortedChildrenConfiguration =
              this._arrayHelperService.sortArrayObjectCaseInsensitive(
                childrenConfiguration,
                this._hierarchyElementNameFieldName
              );
            this.children = [...sortedChildrenConfiguration];
            this._originalChildren = [...sortedChildrenConfiguration];
            this.excludedElements = [...sortedChildrenConfiguration];
            this.configurationHasChanged = false;
          }

          this.setLoading(false);
        },
        error: (err) => {
          this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
          this.setLoading(false);
        },
      });
  }

  private displayErrorMessage(messageKey: string) {
    let dialogSettings = new WlmDialogSettings({ translateKey: '' });
    dialogSettings.translateKey = messageKey;
    dialogSettings.icon = 'error';
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
