import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ColorHelperService } from '../helpers/color-helper.service';
import { globalUtilsHelper } from '../helpers/global-utils-helper';

@Component({
  selector: 'wlm-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit, AfterViewChecked {
  private _viewBoxAttributeName = 'viewBox';
  private _strokeWidthAttributeName = 'stroke-width';

  private _path: string;
  private _init: boolean = false;
  private _viewBox: string;
  private _transformClass: string;
  private _svgFill: string;
  private _svgStroke: string;
  private _svgStrokeWidth: number;

  private colorService = new ColorHelperService();

  public get viewBox(): string {
    return this._viewBox;
  }
  @Input()
  public set viewBox(value) {
    this._viewBox = value;
  }

  public get transformClass(): string {
    if (!this._transformClass) {
      return '';
    }
    return this._transformClass;
  }
  @Input()
  public set transformClass(value) {
    this._transformClass = value;
  }

  public get path(): string {
    return this._path;
  }
  @Input()
  public set path(value) {
    if (globalUtilsHelper.isSvgText(value)) {
      this.fullSvg = value;
    } else {
      this._path = value;
      this.resize();
    }
  }

  private _fullSvg: string;
  get fullSvg(): string {
    return this._fullSvg;
  }
  set fullSvg(value: string) {
    this._fullSvg = value;
    this.renderFullSvg();
  }

  public get svgFill(): string {
    if (this._svgFill) {
      return this._svgFill;
    } else {
      return '#000';
    }
  }
  @Input()
  public set svgFill(value) {
    this._svgFill = this.colorService.hexAToRGBA(value);
  }

  public get svgStroke(): string {
    if (this._svgStroke) {
      return this._svgStroke;
    } else {
      return '';
    }
  }
  @Input()
  public set svgStroke(value) {
    this._svgStroke = this.colorService.hexAToRGBA(value);
  }

  public get svgStrokeWidth(): number {
    return this._svgStrokeWidth;
  }
  @Input()
  public set svgStrokeWidth(value) {
    this._svgStrokeWidth = value;
  }

  @Input() public width: number;
  @Input() public height: number;

  @ViewChild('pathContainer') pathContainer: ElementRef;
  @ViewChild('svgElement') svgElement: ElementRef;

  private _svgContainer: ElementRef;
  public get svgContainer(): ElementRef {
    return this._svgContainer;
  }
  @ViewChild('svgContainer') set svgContainer(value: ElementRef) {
    this._svgContainer = value;
    this.renderFullSvg();
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this._init = true;

    if (this.pathContainer) {
      this.resize();
    }
  }

  resize() {
    if (!this._init) {
      return;
    }

    const dimension = this.pathContainer.nativeElement.getBBox();

    const viewBoxValue = this._viewBox
      ? this._viewBox
      : `${dimension.x} ${dimension.y} ${dimension.width} ${dimension.height}`;

    this.svgElement?.nativeElement?.setAttribute(this._viewBoxAttributeName, viewBoxValue);

    if (!isNaN(this._svgStrokeWidth)) {
      this.svgElement?.nativeElement?.setAttribute(
        this._strokeWidthAttributeName,
        this._svgStrokeWidth
      );
    }
  }

  renderFullSvg(): void {
    if (this.fullSvg && this.svgContainer) {
      this.svgContainer.nativeElement.innerHTML = this.fullSvg;
    }
  }
}
