<wlm-base-filter-container
  *ngIf="adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters" [ngClass]="containerClass ?? ''">
    <div class="pickers-container">
      <ng-content select="[date-filter-button]"> </ng-content>
      <wlm-base-filter [settings]="dateRangeGroupSettings" [persistItems$]="persistFilters$">
        <wlm-date-range-filter-item
          [maxRangeSelected]="maxRangeSelected"
          [startFieldName]="filterConfiguration.startDateFieldName"
          [endFieldName]="filterConfiguration.endDateFieldName"
          [dateRange]="filterConfiguration.dateRange"
          [defaultDateRange]="filterConfiguration.defaultDateRange"
          [settings]="dateRangeItemSettings"
        ></wlm-date-range-filter-item>
      </wlm-base-filter>
    </div>
  </div>
</wlm-base-filter-container>
