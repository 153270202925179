import { BaseGCartesianChartSeries } from './base-g-cartesian-chart-series';
import { GChartCustomItemStyle } from './g-chart-custom-item-style';

export class GCartesianCustomChartSerie extends BaseGCartesianChartSeries {
  type = 'custom';
  // Rows have an any[] type, described by dimensions.
  data: any[][];
  // Specify the fields that each row has, and their order in the any[] array.
  dimensions: string[];
  // Specifies how each item is rendered.
  renderItem: Function;

  selectedMode?: boolean;

  // The dimension that will be used to extract the key from an item. Necessary for using itemStyleByKey.
  itemDimensionKey?: string;

  // Specifies the styles of a single item of the serie. The key corresponds to the dimension used as key/id.
  // Not directly mapped to native.
  itemStyleByKey?: Map<string, Map<string, GChartCustomItemStyle>>;

  constructor(init: Readonly<GCartesianCustomChartSerie>) {
    super(init);
    Object.assign(this, init);
  }
}
