import { Directive, ElementRef } from '@angular/core';
import IMask from 'imask';

@Directive({
  selector: '[timeMask]',
})
export class TimeMaskDirective {
  private readonly _timeMask = {
    mask: 'HH:MM:SS', // enable date mask
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      },
      SS: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
    autofix: true, // defaults to `false`, see details
    lazy: false,
    overwrite: true, // defaults to `false`
  };

  constructor(private _el: ElementRef) {
    const element = this._el.nativeElement;
    IMask(element, this._timeMask);
  }
}
