import { TabDetailParameterName } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from 'src/app/common-modules/dependencies/wlm-filters/filters-payload';
import { ActivityStatusFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/activity-status-filter';
import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';

export class ActivityStatusFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.ActivityStatus;
  activityStatusFieldName: string;

  constructor(init: { dataBindingField: string; activityStatusFieldName: string }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const actStatusFilter = filters.data.get(this.activityStatusFieldName);
    if (actStatusFilter) {
      if (actStatusFilter.value) {
        const openIsSetted = actStatusFilter.value?.some((x) => x.value === 1);
        const closedIsSetted = actStatusFilter.value?.some((x) => x.value === 0);

        const result = new ActivityStatusFilter(
          [openIsSetted, closedIsSetted],
          this.activityStatusFieldName
        );
        return result;
      }
    }
    return null;
  }

  buildResults(actStatus: ActivityStatusFilter): FilterAdapterResult {
    if (actStatus) {
      this.filtersResult.filters.set(this.dataBindingField, actStatus);
      this.panelParams.addParameter(TabDetailParameterName.openActivities, actStatus);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
