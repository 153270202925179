export class FlagSystemItemDto {
  shortName: string;
  date: Date;
  elementId: string;
  reasonId?: number;
  lastReasonId?: number;

  constructor(init?: Partial<FlagSystemItemDto>) {
    Object.assign(this, init);
  }
}
