<div>
  <wlm-base-filter-container
    *ngIf="adaptersReady"
    class="filters filters-background"
    [provideAdapters]="provideAdapters"
    [keysToComplete]="keysToComplete"
    [clearAll$]="clearAll$"
    [apply$]="apply$"
    (filterResults)="onGlobalFilterResults($event)"
    (applyResults)="onGlobalApplyResults($event)"
    (filterComplete)="onGlobalComplete()"
  >
    <wlm-base-filter
      [settings]="baseFamilyFilterSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-hierarchy-family-filter-item
        [defaultSelectedElements]="[filterConfiguration.defaultFamilyId]"
        [settings]="hierarchyFamilyFilterItemSettings"
        [selectedIds]="[selectedFamilyId]"
        [fieldName]="filterConfiguration.hierarchyFamilyFieldName"
        [filterText]="filterText"
        [disableClear]="false"
        (select)="onFamilyIdSelect($event)"
      >
      </wlm-hierarchy-family-filter-item>
    </wlm-base-filter>
  </wlm-base-filter-container>
</div>
