import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppModules } from '../../shared/app-modules.enum';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { ValidationHelperService } from '../../shared/helpers/validation-helper.service';
import { RangeColor } from './range-color';
import { RangeColorValidators } from './range-color-validators';

const COMPONENT_SELECTOR = 'wlm-range-color';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './range-color.component.html',
  styleUrls: ['./range-color.component.scss'],
})
export class RangeColorComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  private _rangeColors: RangeColor[];

  public get rangeColors(): RangeColor[] {
    return this._rangeColors;
  }
  @Input() public set rangeColors(v: RangeColor[]) {
    this._rangeColors = v;
    if (v) {
      this.createForm();
    }
  }

  @Input() rangeLabel: Observable<string>;

  colorControlPrefix = 'color%%';
  rangeControlPrefix = 'range%%';

  colorForm: UntypedFormGroup;
  colorForm1: UntypedFormArray;

  defaultRange = 0;
  defaultColor = '#000000';

  @Output() canSave = new EventEmitter<boolean>();
  @Output() isValidChanged = new EventEmitter<boolean>();
  @Output() valueChanged = new EventEmitter<RangeColor[]>();

  rangeIds: string[] = [];

  maxRanges = 8;
  minRanges = 2;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public customValidators: ValidationHelperService
  ) {}

  ngOnInit(): void {}

  removeRangeColor(id: string) {
    this.rangeIds = this.rangeIds.filter((x) => x !== id);
    this.colorForm.removeControl(this.rangeControlPrefix + id, { emitEvent: false });
    this.colorForm.removeControl(this.colorControlPrefix + id, { emitEvent: false });
    this.validateAllRanges();
  }

  setColor(color: string, id: string) {
    this.colorForm.get(this.colorControlPrefix + id).setValue(color);
  }

  addRange() {
    const newId = globalUtilsHelper.generateGuid();
    this.rangeIds.push(newId);

    this.colorForm.addControl(
      this.rangeControlPrefix + newId,
      this._formBuilder.control(this.defaultRange, [
        Validators.required,
        RangeColorValidators.rangesOrder(
          this.getRanges,
          this.getFormGroup,
          newId,
          this.rangeControlPrefix
        ),
      ]),
      { emitEvent: false }
    );
    this.colorForm.addControl(
      this.colorControlPrefix + newId,
      this._formBuilder.control(this.defaultColor, [Validators.required]),
      { emitEvent: false }
    );

    this.validateAllRanges();
    this.colorForm.updateValueAndValidity();
  }

  onRangeChanged(id: string) {
    this.validateAllRanges();
  }

  getFormColor(id) {
    return this.colorForm.get(this.colorControlPrefix + id).value;
  }

  getRanges = () => {
    return this.rangeIds;
  };

  getFormGroup = () => {
    return this.colorForm;
  };

  private validateAllRanges() {
    this.rangeIds.forEach((x) => {
      const rangeControlKey = this.rangeControlPrefix + x;
      this.colorForm.get(rangeControlKey).updateValueAndValidity();
    });
  }

  private createForm() {
    const colorControls: { [key: string]: UntypedFormControl } = {};
    this.rangeIds = [];

    for (const element of this.rangeColors) {
      const id = globalUtilsHelper.generateGuid();
      this.rangeIds.push(id);

      const rangeColor = element;
      const colorControl = new UntypedFormControl(rangeColor.color, [Validators.required]);
      const rangeControl = new UntypedFormControl(rangeColor.range, [Validators.required]);

      colorControls[`${this.colorControlPrefix}${id}`] = colorControl;
      colorControls[`${this.rangeControlPrefix}${id}`] = rangeControl;
    }

    this.colorForm = this._formBuilder.group(colorControls);

    this.rangeIds.forEach((key) => {
      this.colorForm
        .get(this.rangeControlPrefix + key)
        .addValidators(
          RangeColorValidators.rangesOrder(
            this.getRanges,
            this.getFormGroup,
            key,
            this.rangeControlPrefix
          )
        );
    });

    this.colorForm.valueChanges.subscribe((x) => {
      const rangeColors: RangeColor[] = [];
      this.rangeIds.forEach((id) => {
        const colorControl = this.colorForm.get(`${this.colorControlPrefix}${id}`);
        const rangeControl = this.colorForm.get(`${this.rangeControlPrefix}${id}`);

        rangeColors.push(
          new RangeColor({ color: colorControl?.value, range: +rangeControl?.value })
        );
      });

      this.valueChanged.emit(rangeColors);
    });

    this.colorForm.statusChanges.subscribe((x) => {
      this.isValidChanged.emit(this.colorForm.valid);
    });
  }
}
