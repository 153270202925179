import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from 'src/app/common-modules/shared/model/paged-result.dto';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { GridODataService } from 'src/app/common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class TelemetryPointsService extends GridODataService<ISignalTelemetryNullableViewDto> {
  constructor(injector: Injector) {
    super(injector, '/api/telemetry/signal');
  }

  protected mapResponse(
    response: PagedResultDto<ISignalTelemetryNullableViewDto>
  ): PagedResultDto<ISignalTelemetryNullableViewDto> {
    return response;
  }
}
