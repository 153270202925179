import { IFiltrableItemDto } from '../../../model/filtrable-items/filtrable-item.dto';
import { ISerieCustomization } from './serie-customization';

export interface IPlotableSerie {
  serieCustomization: ISerieCustomization;
  serieDefinition: IFiltrableItemDto;
}

export class PlotableSerie implements IPlotableSerie {
  serieCustomization: ISerieCustomization;
  serieDefinition: IFiltrableItemDto;

  constructor(serieCustomization: ISerieCustomization, serieDefinition: IFiltrableItemDto) {
    this.serieCustomization = serieCustomization;
    this.serieDefinition = serieDefinition;
  }
}
