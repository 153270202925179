import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
// prettier-ignore
import { BaseMap } from '../base-map';
import { MapParameters } from '../map-parameters';

@Component({
  selector: 'wlm-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.scss'],
})
export class MiniMapComponent extends BaseMap implements OnInit, AfterViewInit {
  public mapParameters: MapParameters;

  get componentName() {
    return 'MiniMapComponent';
  }

  constructor() {
    super();
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.mapParameters = parameters.parameters.get(TabDetailParameterName.mapParameters);
  }
}
