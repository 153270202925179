import { Component } from '@angular/core';
import { BaseDynamicLayoutHeaderComponent } from '../../base-dynamic-layout-header.component';

const COMPONENT_SELECTOR = 'wlm-before-tab-title';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './before-tab-title.component.html',
  styleUrls: ['./before-tab-title.component.scss'],
})
export class BeforeTabTitleComponent extends BaseDynamicLayoutHeaderComponent {}
