import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { IFilter } from '../../shared/filters/component-filters/filter';
import { TabDetailPanelParameters } from '../navigation/tab-detail-component';
import { FilterAdapterResult } from './filter-adapter-result';
import { FilterAdapterEnum } from './filter-adapter.enum';
import { FiltersPayload } from './filters-payload';

export abstract class FilterAdapterSettings {
  abstract id: FilterAdapterEnum;

  protected filtersResult = new DataBindingFilters();
  protected panelParams = new TabDetailPanelParameters();

  constructor(protected dataBindingField: string) {}

  abstract adapt(filters: FiltersPayload): IFilter;
  abstract buildResults(filters: IFilter): FilterAdapterResult;

  process(filters: FiltersPayload): FilterAdapterResult {
    const adapted = this.adapt(filters);
    const results = this.buildResults(adapted);
    return results;
  }
}
