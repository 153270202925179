<wlm-popup-wrapper [showCloseButton]="false" [showSpinner]="isLoading" [titleKey]="titleKey">
  <ng-container popup-content>
    <form [formGroup]="form" class="form">
      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.point-id' | translate }}</mat-label>
        <input matInput [formControlName]="pointIdFieldName" />

        <mat-error *ngIf="form.get(pointIdFieldName)?.errors?.notUnique">{{
          T_SCOPE + '.validation.notUnique' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.point-description' | translate }}</mat-label>
        <input matInput [formControlName]="pointDescriptionFieldName" />
      </mat-form-field>

      <div class="dimension-group-wrapper">
        <mat-form-field class="dimension-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.labels.dimension' | translate }}</mat-label>
          <mat-select
            [formControlName]="dimensionTypeIdFieldName"
            (selectionChange)="onSelectDimension($event.value)"
          >
            <mat-option
              *ngFor="let dimensionType of dimensionTypes"
              [value]="dimensionType.dimensionTypeId"
            >
              {{ dimensionType.dimensionTypeDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="unit-field" appearance="outline">
          <mat-label>{{ T_SCOPE + '.labels.unit' | translate }}</mat-label>
          <mat-select
            [formControlName]="unitTypeIdFieldName"
            [disabled]="!unitTypesByDimension?.length"
          >
            <mat-option *ngFor="let unitType of unitTypesByDimension" [value]="unitType.unitTypeId">
              {{ unitType.unitTypeDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ T_SCOPE + '.labels.source' | translate }}</mat-label>
        <mat-select [formControlName]="sourceSystemIdFieldName">
          <mat-option
            *ngFor="let sourceSystem of sourceSystems"
            [value]="sourceSystem.sourceSystemId"
          >
            {{ sourceSystem.sourceSystemDescription }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get(sourceSystemIdFieldName)?.errors?.notUnique">{{
          T_SCOPE + '.validation.notUnique' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-checkbox [formControlName]="isPointOfInterestFieldName">{{
        T_SCOPE + '.labels.point-of-interest' | translate
      }}</mat-checkbox>
    </form>
  </ng-container>
  <ng-container popup-actions>
    <div class="action-buttons-container">
      <div class="cancel-button-container">
        <button mat-button class="wtr-action-button-error btn-size" (click)="cancel()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
      </div>
      <button
        mat-button
        class="wtr-action-button-primary btn-size"
        (click)="save()"
        [disabled]="isLoading || form.pristine || !form.valid"
      >
        {{ 'common.save' | translate | uppercase }}
      </button>
    </div>
  </ng-container>
</wlm-popup-wrapper>
