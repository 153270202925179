<wlm-spinner-wrapper [showSpinner]="isLoading" class="pie-chart">
  <wlm-widget-info
    [filters]="filters"
    [paramsTitle]="paramsTitle"
    [baseTitle]="baseTitle"
    (sizeChanged)="onWidgetInfoSizeChanged($event)"
  ></wlm-widget-info>
  <wlm-no-results [showNoResult]="!chartHasData"></wlm-no-results>
  <div fxFlex *ngIf="chartHasData">
    <wlm-pie-chart
      [size]="calculatedSize$ | async"
      [chartSettings]="chartSettings"
      (hasData)="onChartHasData($event)"
      (loadingEvent)="onChartLoaded($event)"
    ></wlm-pie-chart>
  </div>
</wlm-spinner-wrapper>
