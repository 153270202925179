import { ComponentRef, Injectable } from '@angular/core';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { DynamicRenderizerComponentService } from '../../shared/services/dynamic-renderizer-component.service';
import { LogService } from '../../shared/wlm-log/log.service';
import { BaseDynamicLayoutHeaderComponent } from '../base-dynamic-layout-header.component';
import { BeforeTabTitleComponent } from '../components/before-tab-title/before-tab-title.component';
import { DynamicLayoutExtendHeadersSettings } from '../dynamic-layout-external-settings';
import { DynamicLayoutComponent } from '../dynamic-layout/dynamic-layout.component';
import { DynamicLayoutItemSettings } from '../models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { GoldenLayoutNode } from '../models/golden-layout-node';
import { GoldenLayoutHelperService } from './golden-layout-helper.service';

/**
 * Default headers configuration. We want this headers to appear in every dynamic layout,
 * but we still want to be able to disable it or reconfigure it.
 * To do so, in the module create a new configuration with the same flagName, and this
 * default one will be overriden.
 */

export enum DynamicLayoutDefaultHeaders {
  BeforeTabTitle = 'before-tab-title-area',
}

@Injectable()
export class DynamicLayoutHeadersService {
  private readonly _headerContainerClasses = ['dl-header-extend-container'];
  private readonly _singleTabOverflow = 'dl-single-tab-overflow';

  private readonly dynamicLayoutDefaultHeaders: DynamicLayoutExtendHeadersSettings[] = [
    {
      component: BeforeTabTitleComponent,
      shouldRender: (itemSettings, settings) =>
        this.shouldRenderDefaultBeforeTabTitle(itemSettings, settings),
      flagName: DynamicLayoutDefaultHeaders.BeforeTabTitle,
      position: 'before',
    },
  ];

  // Keep titles because we need to reset width if they are updated.
  // private _expandedTitles = new Map<string, string>();
  // private _expandedTitleWidths = new Map<string, number>();

  constructor(
    private readonly _dynamicRenderizerService: DynamicRenderizerComponentService,
    private readonly _goldenLayoutHelperService: GoldenLayoutHelperService,
    private readonly _log: LogService
  ) {}

  /**
   * Mark the Golden Layout HTML elements that contain widgets, so their headers
   * can be extended by injecting configured components.
   */
  preconfigureHeaderExtends(
    dlComponent: DynamicLayoutComponent,
    currentNodes: GoldenLayoutNode[]
  ): void {
    currentNodes.forEach((node: any) => {
      const genericNode = dlComponent.glService.getComponentRenderedNode(node);
      if (node?.tab?.element) {
        if (node.tab.element.length) {
          Array.from(node.tab.element).forEach((elementItem: any) => {
            this.extendTabHeader(dlComponent, elementItem, genericNode);
          });
        } else {
          this.extendTabHeader(dlComponent, node.tab.element, genericNode);
        }
      }
    });
  }

  /**
   * If they were configured to do so, extend tab headers by injecting N configured components.
   */
  extendHeaders(dlComponent: DynamicLayoutComponent): void {
    const extendHeaders = dlComponent.externalSettings?.extendHeaders ?? [];
    this.mergeWithDefaultHeaders(extendHeaders);

    const tabs = this.getAllCurrentTabs();
    tabs.forEach((tab) => {
      extendHeaders?.forEach((extendHeaderItem) => {
        const { flagName, position } = extendHeaderItem;

        const shouldRender = this.shouldRender(tab, flagName);
        const alreadySet = this.isAlreadySet(tab, flagName);

        if (shouldRender && !alreadySet) {
          const titleTab = this.getTitleTab(tab);
          if (titleTab) {
            const component = this.injectComponent(dlComponent, extendHeaderItem, tab);

            this.setAlreadySet(tab, flagName);

            if (!position || position === 'after') {
              this.insertAfterTitle(titleTab, component);
            } else {
              this.insertBeforeTitle(titleTab, component);
            }
          }
        }
      });
    });
  }

  handleSingleTabOverflow(
    expandedTitles: Map<string, string>,
    expandedTitleWidths: Map<string, number>
  ): void {
    const tabs = this.getAllCurrentTabs();

    tabs.forEach((tab) => {
      const tabHeader = tab.closest('.lm_header');
      if (tabHeader) {
        const headerSize = tabHeader.getBoundingClientRect();
        const tabSize = tab.getBoundingClientRect();
        const tabKey = this.getTabWidgetKey(tab);
        const tabTitle = (tab as HTMLElement).title;

        // Must reset the main with if it is the first iteration (title cannot yet be resized) or the title text has changed.
        if (!expandedTitleWidths.has(tabKey) || expandedTitles.get(tabKey) !== tabTitle) {
          expandedTitleWidths.set(tabKey, tabSize.width);
          expandedTitles.set(tabKey, tabTitle);
        }

        if (headerSize.width !== 0 && expandedTitleWidths.get(tabKey) > headerSize.width) {
          tab.classList.add(this._singleTabOverflow);
        } else {
          tab.classList.remove(this._singleTabOverflow);
        }
      }
    });
  }

  private extendTabHeader(
    dlComponent: DynamicLayoutComponent,
    element: any,
    genericNode: any
  ): void {
    this.setTabWidgetKey(element, genericNode.instanceId);

    const extendHeaders = dlComponent.externalSettings?.extendHeaders ?? [];
    this.mergeWithDefaultHeaders(extendHeaders);

    extendHeaders.forEach((headerSettings) => {
      // If shouldRender is not specified, render by default.
      const applyHeaderExtend =
        !headerSettings.shouldRender ||
        headerSettings.shouldRender(genericNode.state, dlComponent.settings);
      if (applyHeaderExtend) {
        this.setShouldRender(element, headerSettings.flagName);
      }
    });
  }

  private injectComponent(
    dlComponent: DynamicLayoutComponent,
    extendHeaderItem: DynamicLayoutExtendHeadersSettings,
    row
  ) {
    const widgetInstanceKey = this.getTabWidgetKey(row);

    let featureInjector;
    if (extendHeaderItem.featureInjectorFn) {
      featureInjector = extendHeaderItem.featureInjectorFn();
    }

    return this._dynamicRenderizerService.injectComponentWithoutDestroyIt(
      extendHeaderItem.component,
      'span',
      (
        component: BaseDynamicLayoutHeaderComponent,
        componentRef: ComponentRef<BaseDynamicLayoutHeaderComponent>
      ) => {
        if (!component.hasManagedWidth) {
          this._log.error({
            msg: `A component that extends dynamic layout headers is missing a superclass that manages its width.`,
            payload: component,
          });
        }

        component.widgetInstanceKey = widgetInstanceKey;

        component.getItemSetting = () => {
          const settings = globalUtilsHelper.clone(
            dlComponent.settings.items.find((x) => x.widgetInstanceKey == widgetInstanceKey),
            true
          );
          return settings;
        };

        component.notifyWidthChanges = () => {
          dlComponent.resizeRoot();
        };

        dlComponent
          .getComponentInstanceByKey(widgetInstanceKey)
          .subscribe((widgetComponent: BaseDynamicWidgetComponent) => {
            widgetComponent.setToDestroy(globalUtilsHelper.generateGuid(), componentRef);
          });

        component.injected$?.next();
      },
      featureInjector,
      this._headerContainerClasses
    );
  }

  private mergeWithDefaultHeaders(headersSettings: DynamicLayoutExtendHeadersSettings[]): void {
    this.dynamicLayoutDefaultHeaders.forEach((defaultSetting) => {
      if (
        !headersSettings.find(
          (currentSetting) => currentSetting.flagName === defaultSetting.flagName
        )
      ) {
        headersSettings.push(defaultSetting);
      }
    });
  }

  private shouldRenderDefaultBeforeTabTitle = (
    itemSettings: DynamicLayoutItemSettings,
    settings: DynamicLayoutSettings
  ) => {
    const reorderEnabled = this._goldenLayoutHelperService.getIsComponentReorderEnabled(
      itemSettings.widgetInstanceKey,
      settings
    );
    return reorderEnabled;
  };

  private getAllCurrentTabs = () => Array.from(document.getElementsByClassName('lm_tab'));

  private buildShouldRenderFlagName = (flagName: string): string => `show-${flagName}`;

  private buildAlreadySetFlagName = (flagName: string): string => `has-${flagName}`;

  private getTitleTab = (element) => {
    const tabs = element.getElementsByClassName('lm_title');
    return tabs?.length > 0 ? tabs[0] : null;
  };

  private getTabWidgetKey = (element) => element.getAttribute('widgetInstanceKey');
  private setTabWidgetKey = (element, key) => element.setAttribute('widgetInstanceKey', key);

  private shouldRender = (element, flagName: string) => {
    const shouldRenderFlagName = this.buildShouldRenderFlagName(flagName);
    const showCurrent = element.getAttribute(shouldRenderFlagName);
    return showCurrent == 'true';
  };

  private isAlreadySet = (element, flagName: string) => {
    const alreadySetFlagName = this.buildAlreadySetFlagName(flagName);
    const alreadySet = element.getAttribute(alreadySetFlagName);
    return alreadySet == 'true';
  };

  setShouldRender = (element, flagName: string) => {
    const shouldRenderFlagName = this.buildShouldRenderFlagName(flagName);
    element.setAttribute(shouldRenderFlagName, 'true');
  };

  private setAlreadySet(element, flagName: string): void {
    const alreadySetFlagName = this.buildAlreadySetFlagName(flagName);
    element.setAttribute(alreadySetFlagName, 'true');
  }

  private insertBeforeTitle(titleTab, component): void {
    titleTab.parentNode.insertBefore(component, titleTab);
  }

  private insertAfterTitle(titleTab, component): void {
    titleTab.parentNode.insertBefore(component, titleTab.nextSibling);
  }
}
