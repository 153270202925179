<wlm-page-wrapper>
  <ng-container page-top-buttons>
    <button wlmCollapsibleButton [tooltip]="'common.add'" (click)="onOpenUpload($event)">
      <wlm-icon icon="add"></wlm-icon>
    </button>
    <button
      wlmCollapsibleButton
      [tooltip]="T_SCOPE + '.buttons.send-request'"
      (click)="onSendRequest()"
      [disabled]="!(isMappingsFormValid$ | async)"
    >
      <wlm-icon icon="file_upload"></wlm-icon>
    </button>

    <button
      mat-icon-button
      color="primary"
      [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
      (click)="discardChanges()"
    >
      <wlm-icon [icon]="'discard-changes'"></wlm-icon>
    </button>
  </ng-container>
  <ng-container page-content>
    <wlm-dynamic-layout
      *ngIf="dynamicLayoutSettings"
      [settings]="dynamicLayoutSettings"
    ></wlm-dynamic-layout>
  </ng-container>
</wlm-page-wrapper>
