<div *ngIf="filters" class="points-selection-container" cdkDropListGroup>
  <wlm-select-drag-list-virtual
    class="select-drag-list-virtual"
    [settings]="settings"
    [queryParams]="queryParams"
    [refreshList$]="refreshList$"
    (selectedItemChange)="onSelectItem($event)"
    [reloadList$]="reloadList$"
  >
    <ng-template let-rowDetail #card>
      <wlm-generic-card [item]="rowDetail" [settings]="cardSettings"></wlm-generic-card>
    </ng-template>
  </wlm-select-drag-list-virtual>
</div>
