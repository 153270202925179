<mat-horizontal-stepper *ngIf="sAWizardSettings" [linear]="true" #stepper class="wizard-position">
  <!-- DATE RANGE STEP -->
  <mat-step
    [stepControl]="timeFrameForm"
    [completed]="timeFrameForm?.valid"
    [aria-label]="profilesAreSaved"
  >
    <form [formGroup]="timeFrameForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.time-frame-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-sa-time-frame-selection
          class="form-control"
          [settings]="sAWizardSettings?.timeFrameSettings"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          (timeFrameChanged)="timeFrameChanged($event)"
          (isValidChanged)="timeFrameValidChanged($event)"
        ></wlm-sa-time-frame-selection>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" (click)="closeWizard()">
          {{ 'common.cancel' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!timeFrameIsValid"
          (click)="applyTimeFrame()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- POINTS STEP -->
  <mat-step
    *ngIf="sAWizardSettings?.mode === telemetryMode"
    [stepControl]="pointsForm"
    [completed]="pointsForm?.valid"
    role="tab"
    [aria-label]="blockAlgTelemetryStep"
  >
    <form [formGroup]="pointsForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.points-frame-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-sa-point-selection
          class="form-control"
          [settings]="sAWizardSettings?.pointsSelectionSettings"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          (selectedPointsChange)="pointsChanged($event)"
        ></wlm-sa-point-selection>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!points?.length"
          (click)="applyPoints()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- ALGORITHMS STEP -->
  <mat-step
    #algorithm
    *ngIf="sAWizardSettings?.mode === algorithmMode"
    [stepControl]="algorithmsForm"
    [completed]="algorithmsForm?.valid"
    role="tab"
    [aria-label]="blockAlgTelemetryStep"
  >
    <form [formGroup]="algorithmsForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.algorithms-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-algorithm-selection-grid
          class="form-control"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          [applyPersistedValue$]="algorithmStepApplyPersistedValue$"
          [algorithmFilterConfiguration]="sAWizardSettings.algorithmSettings"
          (selectedAlgorithmsChange)="onAlgorithmsChanged($event)"
          (selectedTargetChange)="onTargetChanged($event)"
        ></wlm-algorithm-selection-grid>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!algorithms?.length"
          (click)="applyAlgorithms()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- TARGETS STEP -->
  <mat-step
    [completed]="targetsForm?.valid"
    [aria-label]="blockTargetsStep"
    *ngIf="sAWizardSettings?.mode === algorithmMode"
    [stepControl]="targetsForm"
    [completed]="targetsForm?.valid"
    role="tab"
    [aria-label]="blockTargetsStep"
  >
    <form [formGroup]="targetsForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.targets-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <!-- ADD TARGETS COMPONENT -->
        <wlm-sa-target-selection
          class="form-control"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          [heTypeSelected]="heAlgorithmTypeSelected"
          [targetSettings]="sAWizardSettings.targetSettings"
          [persistencyAreaHE]="persistencyAreaHE"
          [persistencyAreaNE]="persistencyAreaNE"
          (selectedTargetChange)="onSelectedTargetChange($event)"
        ></wlm-sa-target-selection>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!targets?.length"
          (click)="applyTargetElements()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- CALENDAR STEP -->
  <mat-step
    [stepControl]="calendarForm"
    [completed]="calendarForm.valid"
    role="tab"
    [aria-label]="blockCalendarStep"
  >
    <form [formGroup]="calendarForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.calendar-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-sa-calendar-selection
          [settings]="sAWizardSettings?.calendarSettings"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          (selectedCalendarChange)="calendarChanged($event)"
        ></wlm-sa-calendar-selection>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button mat-button class="wtr-button-primary" [disabled]="false" (click)="applyCalendar()">
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- ENVELOPE STEP -->
  <mat-step
    [stepControl]="envConfigForm"
    [completed]="envConfigForm.valid"
    role="tab"
    [aria-label]="blockEnvelopesStep"
  >
    <form [formGroup]="envConfigForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.envs-conf-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-sa-envelopes-configuration
          class="form-control"
          [settings]="sAWizardSettings?.envelopesConfigurationSettings"
          [telemetryPoints]="pointsToCalculate"
          [profileConfiguration]="profileConfiguration"
          [algorithmElements]="targetsByAlgorithms"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          [stepApplied$]="stepApplied$"
          (envelopesChanged)="envConfChanged($event)"
          (envelopesAreValid)="envIsValidChanged($event)"
        ></wlm-sa-envelopes-configuration>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!envsIsValid"
          (click)="applyEnvConf()"
        >
          {{ T_SCOPE + '.apply' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- ALARM NAME GENERATION STEP -->
  <mat-step
    [stepControl]="alarmNameForm"
    [completed]="alarmNameForm.valid"
    role="tab"
    [aria-label]="blockProfileNameStep"
  >
    <form [formGroup]="alarmNameForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.name-configuration-step' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-alarm-name-generator
          [alarmNameSegments]="sAWizardSettings?.alarmNameSettings?.alarmNameSegments"
          [resetHandler$]="resetHandler$"
          [resetCompleteHandler$]="resetCompleteHandler$"
          (alarmNameSegmentsChange)="onalarmNameSegmentsChange($event)"
          (subscriptionChange)="onSubscriptionChange($event)"
        ></wlm-alarm-name-generator>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" matStepperPrevious>
          {{ T_SCOPE + '.back' | translate }}
        </button>
        <button
          mat-button
          class="wtr-button-primary"
          [disabled]="!alarmNameForm"
          (click)="applyAlarmName()"
        >
          {{ T_SCOPE + '.save' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
  <!-- SUMMARY STEP -->
  <mat-step
    [stepControl]="summaryForm"
    [completed]="summaryForm.valid"
    role="tab"
    [aria-label]="!alarmNameForm?.valid"
  >
    <form [formGroup]="summaryForm">
      <ng-template matStepLabel
        ><div class="title-container">
          {{ T_SCOPE + '.summary' | translate }}
        </div></ng-template
      >
      <div class="step-form">
        <wlm-sa-summary [profileSummary]="profileSummary" class="height-limit-3"></wlm-sa-summary>
      </div>
      <div>
        <button mat-button class="wtr-button-primary" (click)="closeWizard()">
          {{ 'common.close' | translate }}
        </button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
