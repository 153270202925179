import { IFilter } from 'src/app/common-modules/shared/filters/component-filters/filter';
import { DateRange } from 'src/app/common-modules/shared/model/date/date-range';

export class LeaksGridFilterConfiguration {
  persistencyArea: string;
  hierarchyElementIdFieldName: string;
  hierarchyElementIds: string[];
  hierarchyElementFamilyFieldName: string;
  hierarchyElementFamily: string;
  startDateFieldName: string;
  endDateFieldName: string;
  dateRange: DateRange;
  defaultDateRange?: DateRange;
  filters: IFilter[];

  constructor(
    persistencyArea: string,
    hierarchyElementIdFieldName: string,
    hierarchyElementFamilyFieldName: string,
    startDateFieldName: string,
    endDateFieldName: string,
    hierarchyElementIds?: string[],
    hierarchyFamilyId?: string,
    dateRange?: DateRange,
    defaultDateRange?: DateRange,
    filters?: IFilter[]
  ) {
    this.persistencyArea = persistencyArea;
    this.hierarchyElementIdFieldName = hierarchyElementIdFieldName;
    this.hierarchyElementFamilyFieldName = hierarchyElementFamilyFieldName;
    this.startDateFieldName = startDateFieldName;
    this.endDateFieldName = endDateFieldName;

    this.hierarchyElementIds = hierarchyElementIds;
    this.hierarchyElementFamily = hierarchyFamilyId;
    this.dateRange = dateRange;
    this.defaultDateRange = defaultDateRange;
    this.filters = filters;
  }
}
