import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { FilterValidation } from '../../shared/filters/component-filters/filter-validation';

export class ActivitiesGridFilterValidations implements FilterValidation {
  validateFilters(filterFields: string[], filterParameters: DataBindingFilters): boolean {
    let filtersAreValid = true;
    filterFields.forEach((filterName) => {
      filtersAreValid = filtersAreValid && filterParameters.filters.get(filterName) !== undefined;
    });

    return filtersAreValid;
  }
}
