import { Observable } from 'rxjs';
import { IChartConfiguration } from './model/chart/chart-configuration';
import { WlmElementExtended } from './model/elements/element-extended';

export abstract class BaseCustomizableChartService {
  abstract getChartConfiguration(
    workspaceName: string,
    elements: WlmElementExtended[]
  ): Observable<IChartConfiguration>;

  abstract updateChartConfiguration(
    chartConfiguration: IChartConfiguration
  ): Observable<IChartConfiguration>;
}
