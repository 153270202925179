export class DataVisualizationStateFields {
  static readonly selectedAlgorithms = 'selectedAlgorithms';
  static readonly selectedWorkspace = 'selectedWorkspace';
  static readonly removedWorkspace = 'removedWorkspace';
  static readonly cleanWorkspaceSelector = 'cleanWorkspaceSelector';
  static readonly editedWorkspace = 'editedWorkspace';
  static readonly editedWorkspaceHasError = 'editedWorkspaceHasError';
  static readonly loadWorkspace = 'loadWorkspace';
  static readonly selectedPoint = 'selectedPoint';
  static readonly selectedCartWorkspace = 'selectedCartWorkspace';
  static readonly plotCartSelection = 'plotCartSelection';
  static readonly plotChartConfiguration = 'plotChartConfiguration';
  static readonly chartConfigurationUpdate = 'chartConfigurationUpdate';
  static readonly cartSelectionUpdate = 'cartSelectionUpdate';
  static readonly selectedPoints = 'selectedPoints';
  static readonly clearAll = 'clearAll';
}
