import { GChartSerieMarkAreaItem } from './g-chart-serie-mark-area-item';
import { GChartTextStyles } from './g-chart-text-styles';

export class GChartSerieMarkArea {
  itemStyle?: GChartTextStyles;
  data: [GChartSerieMarkAreaItem, GChartSerieMarkAreaItem][];

  constructor(init?: Readonly<GChartSerieMarkArea>) {
    Object.assign(this, init);
  }
}
