<div
  [ngClass]="{
    'messages-container-margin-bottom': addMarginBottom
  }"
>
  <span *ngIf="control?.touched && control?.errors?.custom && translatedCustomField">{{
    control.errors.message.key | translate : { field: translatedCustomField }
  }}</span>
  <span *ngIf="(control?.touched && control?.errors?.required) || showErrors['required']">{{
    'common.validation.required' | translate
  }}</span>
  <span *ngIf="(control?.touched && control?.errors?.min) || showErrors['min']">
    {{ 'common.validation.min-value' | translate : { min: control.errors.min.min } }}
  </span>
  <span *ngIf="(control?.touched && control?.errors?.max) || showErrors['max']">
    {{ 'common.validation.max-value' | translate : { min: control.errors.max.max } }}
  </span>
  <span *ngIf="(control?.touched && control?.errors?.minlength) || showErrors['minLength']">
    {{
      'common.validation.min-length' | translate : { min: control.errors.minlength.requiredLength }
    }}
  </span>
  <span *ngIf="(control?.touched && control?.errors?.maxlength) || showErrors['maxLength']">
    {{
      'common.validation.max-length' | translate : { max: control.errors.maxlength.requiredLength }
    }}
  </span>
  <span
    *ngIf="
      (control?.touched && control?.errors?.dateOlderEqThanError) ||
      showErrors['dateOlderEqThanError']
    "
  >
    {{
      'common.validation.date-newer'
        | translate : { date: (control.errors.dateOlderEqThanError.date | date : 'date') }
    }}
  </span>
  <span
    *ngIf="
      (control?.touched && control?.errors?.dateNewerEqThanError) ||
      showErrors['dateNewerEqThanError']
    "
  >
    {{
      'common.validation.date-older'
        | translate : { date: (control.errors.dateNewerEqThanError.date | date : 'date') }
    }}
  </span>
  <span *ngIf="(control?.touched && control?.errors?.notSvg) || showErrors['notSvg']">
    {{ 'common.validation.not-svg' | translate }}
  </span>
</div>
