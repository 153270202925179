<wlm-popup-wrapper [titleKey]="T_SCOPE + '.title'" [showSpinner]="isSaving" (popupClose)="close()">
  <ng-container popup-content>
    <form *ngIf="form" [formGroup]="form">
      <div class="file-import-container">
        <div class="file-import-file-container">
          <div class="file-import-file">
            <wlm-file-upload
              formControlName="file"
              [labelKey]="fieldScope + '.file'"
            ></wlm-file-upload>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container popup-actions>
    <button
      mat-raised-button
      class="wtr-action-button-primary"
      onclick="this.blur()"
      [disabled]="hasErrors"
      (click)="sendRequest()"
    >
      {{ T_SCOPE + '.buttons.send-request' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
