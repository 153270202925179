import { Params } from '@angular/router';
import { NavKeys } from './nav-keys.enum';

/**
 * Associates a translated, permission-managed route (NavKeys) with dynamic data, like query params.
 */

export class NavItem {
  public constructor(
    public key: NavKeys,
    public disable: boolean,
    public queryParams?: Params,
    public disabledKeyTooltip?: string,
    public customNavMethod?: any,
    public titleKey?: string
  ) {}
}
