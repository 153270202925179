import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { IInterfacesLoggingSelector } from 'src/app/common-modules/dependencies/logs/models/interfaces-logging-selector';
import { IInterfacesLogging } from '../../dependencies/interfaces/interfaces-logging';
import { PagedResultDto } from '../model/paged-result.dto';
import { GridODataService } from '../odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class LogsInterfacesService extends GridODataService<IInterfacesLogging> {
  get apiUrl() {
    return `${this.baseUrl}/api/log/interface`;
  }

  constructor(injector: Injector) {
    super(injector, '/api/log/interface');
  }

  protected mapResponse(
    response: PagedResultDto<IInterfacesLogging>
  ): PagedResultDto<IInterfacesLogging> {
    return response;
  }

  getSelectorItems(): Observable<IInterfacesLoggingSelector[]> {
    return this.httpCacheClient.get(`${this.apiUrl}/selector`, {
      avoid: true,
    });
  }

  download(interfaceName: string, fileNames: string[]): Observable<any> {
    return this.httpCacheClient.post(
      `${this.apiUrl}/${interfaceName}/download`,
      { fileNames },
      { avoid: true },
      { responseType: 'blob' }
    );
  }
}
