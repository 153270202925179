import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AdditionalHttpOpts } from 'src/app/common-modules/cache/http-cache/additional-http-options';
import { WaterBalanceParams } from 'src/app/common-modules/dependencies/water-balance/water-balance-params';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { WaterBalanceDto } from '../../shared/model/water-balance/water-balance.dto';

@Injectable()
export class WaterBalanceService extends BaseService {
  constructor(injector: Injector, private dateHelperService: DateHelperService) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/water-balance`;
  }

  public getWaterBalance(params: WaterBalanceParams): Observable<WaterBalanceDto> {
    const requestDatesNoUtc = ['startDate', 'endDate'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });
    const queryParams = {
      ...params,
      startDate: this.dateHelperService.toApiFormatNoUTC(params.startDate),
      endDate: this.dateHelperService.toApiFormatNoUTC(params.endDate),
    };

    // TODO: Uncomment this to use the date interceptor when is ready for get petitions
    return this.httpCacheClient.get(this.url, this.avoidCache, queryParams /*, addHttpOptions*/);
  }
}
