import { Injectable } from '@angular/core';
import { LogService } from '../../shared/wlm-log/log.service';
import { DynamicLayoutComponentApi } from '../dynamic-layout/dynamic-layout-component-api';
import { DynamicSettings } from '../models/dynamic-settings';

/**
 * Service used to implement trasversal actions that should happen between all nested dynamic layouts.
 * It should be provided at the top dynamic layout.
 */

@Injectable()
export class DynamicLayoutService {
  private _registeredLayouts = new Map<string, DynamicLayoutComponentApi>();

  constructor(private _logService: LogService) {}

  registerLayout(components: DynamicLayoutComponentApi[]): void {
    components.forEach((component) => {
      const hashKey = this.buildKeyFromComponent(component);
      this._registeredLayouts.set(hashKey, component);
    });
  }

  resetAll(): void {
    // List in reverse because children are registered after the parents, but they must be reset first.
    const components = Array.from(this._registeredLayouts.values()).reverse();
    this.reset(components);
  }

  resetOne(settings: DynamicSettings): void {
    if (!settings) {
      return;
    }

    const hashKey = this.buildKeyFromSettings(settings);

    if (!hashKey) {
      return;
    }

    const component = this._registeredLayouts.get(hashKey);
    if (component) {
      this.reset([component]);
    }
  }

  /**
   * With this approach, the DynamicLayoutComponent has the responsibility of resetting its layout
   * and update the persistence.
   */
  private reset(components: DynamicLayoutComponentApi[]): void {
    components.forEach((component) => {
      component.resetToDefault();
    });
  }

  private buildKeyFromComponent(component: DynamicLayoutComponentApi): string {
    const settings = component?.settings;
    if (!settings) {
      this._logService.error({
        msg: 'Could not add the component to the DynamicLayoutService for resetting',
        payload: component,
      });
      return null;
    }
    const key = this.buildKey(settings.layoutArea, settings.layoutKey);
    return key;
  }

  private buildKeyFromSettings(settings: DynamicSettings): string {
    if (!settings) {
      return null;
    }
    const key = this.buildKey(settings.settingArea, settings.settingKey);
    return key;
  }

  private buildKey(settingArea, settingKey): string {
    const key = [settingArea, settingKey].join('-');
    return key;
  }
}
