<wlm-spinner-wrapper
  [showSpinner]="chartLoading || formLoading || loading"
  [expandVertical]="false"
>
  <div class="top-buttons" fixedSizeElement>
    <wlm-export-excel
      class="topbar-button"
      *ngIf="chartSettings"
      [exportExcelComponent]="trendChart"
      [disabled]="trendChart && !trendChart?.dataLoaded"
    ></wlm-export-excel>

    <wlm-export-pdf-button
      *ngIf="chartSettings"
      [exportPdfComponent]="trendChart"
    ></wlm-export-pdf-button>

    <button
      *ngIf="chartSettings"
      mat-icon-button
      color="primary"
      class="topbar-button wtr-button-primary"
      [disabled]="trendChart && !trendChart?.dataLoaded"
      (click)="exportChart()"
      [matTooltip]="exportTooltipPath + '.export-image' | translate | lowercase"
    >
      <mat-icon svgIcon="export-chart-image"></mat-icon>
    </button>
  </div>

  <wlm-trend-chart
    *ngIf="chartSettings"
    [chartSettings]="chartSettings"
    [size]="calculatedSize$ | async"
    (loadingEvent)="onChartLoading($event)"
  ></wlm-trend-chart>

  <wlm-alarm-config
    *ngIf="defaultModel"
    fixedSizeElement
    [pageId]="pageId"
    [defaultModel]="defaultModel"
    [disabled]="disabled"
    (configSave)="onConfigSave($event)"
    (loading)="onFormLoading($event)"
  ></wlm-alarm-config>
</wlm-spinner-wrapper>
