<span class="circle-container">
  <span class="dots-container">
    <wlm-alarm-severity-counter
      [alarmCounter]="
        criticalAlarmsCounter + highAlarmsCounter + mediumAlarmsCounter + lowAlarmsCounter
      "
      [severityColor]="totalColor"
    ></wlm-alarm-severity-counter>
    <span class="vl"></span>
    <wlm-alarm-severity-counter
      [alarmCounter]="criticalAlarmsCounter"
      [severityColor]="criticalColor"
      [lightText]="true"
    ></wlm-alarm-severity-counter>
    <wlm-alarm-severity-counter
      [alarmCounter]="highAlarmsCounter"
      [severityColor]="highColor"
    ></wlm-alarm-severity-counter>
    <wlm-alarm-severity-counter
      [alarmCounter]="mediumAlarmsCounter"
      [severityColor]="mediumColor"
    ></wlm-alarm-severity-counter>
    <wlm-alarm-severity-counter
      [alarmCounter]="lowAlarmsCounter"
      [severityColor]="lowColor"
    ></wlm-alarm-severity-counter>
  </span>
</span>
