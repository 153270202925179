<wlm-widget-info
  [filters]="filters"
  [paramsTitle]="paramsTitle"
  [baseTitle]="baseTitle"
></wlm-widget-info>
<div class="wb-container">
  <wlm-water-balance-widget
    *ngIf="wbParams"
    class="widget"
    [params]="wbParams"
  ></wlm-water-balance-widget>
</div>
