import { Type } from 'class-transformer';
import {
  HorizontalAxis,
  IHorizontalAxis,
} from 'src/app/common-modules/shared/charts/model/axes/horizontal-axis';
import {
  IVerticalAxis,
  VerticalAxis,
} from 'src/app/common-modules/shared/charts/model/axes/vertical-axis';
import { IChartConfiguration } from 'src/app/common-modules/shared/charts/model/chart/chart-configuration';
import { IChartCustomization } from 'src/app/common-modules/shared/charts/model/chart/chart-customization';
import { IEventChartQuery } from 'src/app/common-modules/shared/charts/model/events/event-chart-query';
import { IPlotableSerie } from 'src/app/common-modules/shared/charts/model/series/plotable-serie';
import { IEventsControlSettings } from 'src/app/common-modules/shared/model/data-viz/events-control-settings.dto';
import { EventChartQueryDto } from 'src/app/common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';

export class ChartConfiguration implements IChartConfiguration {
  eventsControlSettings: IEventsControlSettings;
  @Type(() => HorizontalAxis)
  horizontalAxis: IHorizontalAxis[];
  @Type(() => VerticalAxis)
  verticalAxis: IVerticalAxis[];
  seriesConfiguration: IPlotableSerie[];
  customization: IChartCustomization;
  @Type(() => EventChartQueryDto)
  eventsQuery?: IEventChartQuery;

  constructor(init: Partial<ChartConfiguration>) {
    Object.assign(this, init);
  }
}
