import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from '../../app-modules.enum';
import { DragListCardSettings } from './drag-list-card-settings';

const COMPONENT_SELECTOR = 'wlm-drag-list-card';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './drag-list-card.component.html',
  styleUrls: ['./drag-list-card.component.scss'],
})
export class DragListCardComponent implements OnInit {
  @Input() item: any;
  @Input() settings: DragListCardSettings;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {}
}
