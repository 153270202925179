import { Component, Input, OnDestroy, inject } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IFamilyAndRelationsDto } from '../family-and-relations.dto';

import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { DefaultHierarchyElementTypes } from 'src/app/common-modules/dependencies/he/hierarchy.constants';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';

@UntilDestroy()
@Component({
  selector: 'wlm-families-and-relations-form',
  templateUrl: './families-and-relations-form.component.html',
  styleUrls: ['./families-and-relations-form.component.scss'],
})
export class FamiliesAndRelationsFormComponent extends IntegrableForm implements OnDestroy {
  @Input() initialModel: IFamilyAndRelationsDto;
  model: IFamilyAndRelationsDto;
  private readonly _globalsService = inject(GlobalsService);
  private readonly _spinnerService = inject(SpinnerService);
  setLoading: (isLoading: boolean) => void;

  constructor() {
    super();
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  onModelChange(model: IFamilyAndRelationsDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  ngOnDestroy(): void {}

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'hierarchyFamilyId',
          type: 'input',
          expressions: {
            hide: 'true',
          },
        },
        {
          key: 'hierarchyFamilyName',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Hierarchy Family Name',
            required: true,
          },
        },
        {
          key: 'hierarchyElementTypes',
          type: 'het-sorter-selector',
          className: 'col-12',
          defaultValue: DefaultHierarchyElementTypes,
          props: {
            label: 'Hierarchy Element Types',
            required: true,
            options: this._globalsService
              .getHierarchyElementTypes()
              .pipe(map((elements) => elements.filter((element) => element.networkElementTypeId))),
          },
        },
      ],
    },
  ];
}
