import { Injectable, Injector } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { delay, filter, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { AdditionalHttpOpts } from 'src/app/common-modules/cache/http-cache/additional-http-options';
import { ProfileConfigurationDto } from 'src/app/common-modules/dependencies/alarms/profile-configuration.dto';
import { ProfileResultDto } from 'src/app/common-modules/dependencies/alarms/profile-result.dto';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { UoMService } from 'src/app/common-modules/shared/uom/uom.service';
import { LogData } from 'src/app/common-modules/shared/wlm-log/log-data';
import { LogService } from 'src/app/common-modules/shared/wlm-log/log.service';
import { EnvelopeMode } from '../../../../common-modules/dependencies/alarms/envelope-modes.enum';
import { AuthenticationService } from '../../../../common-modules/shared/auth/services/authentication.service';
import { SaveProfileDto } from '../models/save-profile.dto';
import { SAProfileSummary } from '../sa-wizard-components/models/sa-profile-summary';
import { HttpContext } from '@angular/common/http';
import { CT_HTTP_CACHE } from 'src/app/common-modules/shared/interceptors/interceptor-context-tokens';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService extends BaseService {
  static readonly envelopeNames = ['hiHi', 'hi', 'low', 'lowLow'];
  private _noContentStatus = 204;

  protected get url() {
    return `${this.apiUrl}/alarm/profile`;
  }

  constructor(
    injector: Injector,
    private _objectHelper: ObjectHelperService,
    private _uomService: UoMService,
    private _logService: LogService,
    private _auth: AuthenticationService
  ) {
    super(injector);
  }

  saveProfilesConfigurations(saveProfileDto: SaveProfileDto): Observable<string> {
    const requestDatesNoUtc = ['[].periodStartDate', '[].periodEndDate'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });
    return this.httpCacheClient.post<string>(
      `${this.url}/background/save`,
      saveProfileDto,
      this.avoidCache,
      addHttpOptions
    );
  }

  saveProfileConfiguration(profConfig: ProfileConfigurationDto): Observable<ProfileResultDto> {
    const requestDatesNoUtc = ['periodStartDate', 'periodEndDate'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });
    return this.httpCacheClient.post<ProfileResultDto>(
      `${this.url}/save/configuration`,
      profConfig,
      this.avoidCache,
      addHttpOptions
    );
  }

  getSavedProfiles(id: string): Observable<SAProfileSummary[]> {
    return this._auth.getBearerToken().pipe(
      switchMap((token) => {
        const context = new HttpContext();
        context.set(CT_HTTP_CACHE, false);

        const result$ = this.httpClient.get<SAProfileSummary[]>(
          `${this.url}/background/save/${id}`,
          {
            observe: 'response',
            headers: { Authorization: token },
            context,
          }
        );

        const retry$ = result$.pipe(
          map((response) => {
            if (response.status === this._noContentStatus) {
              // If no content, raise an error so retryWhen catches it.
              throw response.status;
            }
            return response;
          }),
          // RetryWhen executes when any error is raised (404, 500...).
          retryWhen((error$) =>
            error$.pipe(
              // Only delay and take when our specific error is thrown.
              filter((error) => error === this._noContentStatus),
              delay(2500),
              take(30)
            )
          )
        );

        const mapped$ = retry$.pipe(map((x) => x.body));
        return mapped$;
      })
    );
  }

  /**
   * If flat absolute, converts the values to or from the default values.
   */
  convertDefaultUnits(
    sourceProfile: ProfileConfigurationDto,
    convertMode: 'from' | 'to'
  ): Observable<ProfileConfigurationDto> {
    if (sourceProfile.mode !== EnvelopeMode.FlatAbsolute) {
      return of(sourceProfile);
    }
    const profile = this._objectHelper.clone(sourceProfile);
    return this.getConversionFactor(profile).pipe(
      map((factor) => {
        ProfilesService.envelopeNames.map(this.getMainValueKey).forEach((envelopeKey) => {
          if (convertMode === 'from' && profile[envelopeKey]) {
            // Convert from default units to current units.
            profile[envelopeKey] = profile[envelopeKey] * factor;
          }
          if (convertMode === 'to' && profile[envelopeKey]) {
            // Convert from current units to default units.
            if (!factor) {
              this._logService.error(
                new LogData({ msg: 'Attempting to divide profile value by zero.' })
              );
            }
            profile[envelopeKey] = profile[envelopeKey] / factor;
          }
        });
        return profile;
      })
    );
  }

  convertDefaultUnitsMany(
    profiles: ProfileConfigurationDto[],
    convertMode: 'from' | 'to'
  ): Observable<ProfileConfigurationDto[]> {
    return forkJoin(profiles.map((profile) => this.convertDefaultUnits(profile, convertMode)));
  }

  private getConversionFactor(profile: ProfileConfigurationDto): Observable<number> {
    return (
      this._uomService
        .getBySignal(profile.dimensionTypeId)
        // Must use take(1) because getBySignal result does not complete after first emission.
        .pipe(
          take(1),
          map((uom) => uom.conversionFactor)
        )
    );
  }

  private getMainValueKey = (envelopeName: string) => envelopeName;
}
