import { Injectable } from '@angular/core';
import produce from 'immer';
import { ArrayHelperService } from '../../shared/helpers/array-helper.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';

import { REACTIVE_STATE_ROOT } from '../models/state-constants';
import { StateFullSettings } from '../models/state-full-settings';
import { GlobalState } from '../store/base.store';

/**
 * Encapsulate utility functions that are third party of from other modules.
 * Using this service, we remove a direct dependency of ObjectHelperService.
 */

@Injectable()
export class StateHelperService {
  private readonly _defaultScopeInstanceKey = 'shared';

  constructor(
    private _objectHelperService: ObjectHelperService,
    private _arrayHelperService: ArrayHelperService
  ) {}

  getSubState = (state: GlobalState, path: string): any => {
    let subState = this.deepGet(state, path);
    return subState;
  };

  setSubState = (state: GlobalState, path: string, subState = {}): void => {
    if (typeof subState === 'undefined') {
      this.deepSet(state, path);
    } else {
      this.deepSet(state, path, subState);
    }
  };

  buildActionPath = (settings: StateFullSettings): string => {
    const { area, scope, widget } = settings;
    if (!widget) {
      throw new Error('There are no widget settings, the path cannot be built.');
    }

    const scopeInstanceKey = widget.scopeInstanceKeys[area] ?? this._defaultScopeInstanceKey;

    if (!scopeInstanceKey) {
      throw new Error('The selected area does not have an associated scopeInstance key.');
    }

    const result = this.joinPath([
      settings.widget.module,
      widget.page,
      area,
      scope,
      scopeInstanceKey,
    ]);
    return result;
  };

  buildSelectorPath = (settings: StateFullSettings): string => {
    const actionPath = this.buildActionPath(settings);
    const result = this.joinPath([REACTIVE_STATE_ROOT, actionPath]);
    return result;
  };

  private joinPath(values: string[]): string {
    return values.filter(Boolean).join('.');
  }

  deepGet = this._objectHelperService.deepGet;
  deepSet = this._objectHelperService.deepSet;
  joinMaps = this._objectHelperService.joinMaps;
  clone = this._objectHelperService.clone;
  deepEqual = this._objectHelperService.deepEqual;
  onlyUniqueByRef = this._arrayHelperService.onlyUnique;
  getDuplicated = this._arrayHelperService.getDuplicated;
  produce = produce;
}
