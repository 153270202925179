import { SignalHistoricalVersionDto } from '../../features/shared/model/signals/signal-historical-version-dto';
import { SmartMeterConfigDto } from './smart-meter-config.dto';

export class SmartMeterSignalVersionDto extends SignalHistoricalVersionDto {
  title: string;
  customerClassTypeId: string;
  customerClassTypeName: string;

  constructor(init: SmartMeterSignalVersionDto) {
    super(init);
    Object.assign(this, init);
  }

  getCurrentConfig?(): SmartMeterConfigDto {
    const current = {
      ...super.getCurrentConfig(),
      customerClassTypeId: this.customerClassTypeId,
      customerClassTypeName: this.customerClassTypeName,
    };

    return current;
  }
}
