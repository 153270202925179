import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLayoutModule } from 'src/app/common-modules/dynamic-layout/dynamic-layout.module';
import { CollapsibleSummaryContainerModule } from 'src/app/common-modules/shared/component/collapsible-summary-container/collapsible-summary-container.module';
import { LabelValueListModule } from 'src/app/common-modules/shared/core/label-value-list/label-value-list.module';
import { WidgetRegistryService } from 'src/app/common-modules/widget-registry/widget-registry';
import { WLMGridModule } from 'src/app/common-modules/wlm-grid/wlm-grid.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { CustomerDetailMeterReadingsComponent } from './customer-detail-meter-readings/customer-detail-meter-readings.component';
import { CustomerDetailSingleRpWidgetComponent } from './customer-detail-single-rp-widget/customer-detail-single-rp-widget.component';
import { CustomerDetailSingleComponent } from './customer-detail-single/customer-detail-single.component';
import { CustomerDetailsMetersComponent } from './customer-details-meters/customer-details-meters.component';
import { CustomerDetailsPageComponent } from './customer-details-page/customer-details-page.component';
import { CustomerGroupComponent } from './customer-group/customer-group.component';
import { CustomerRoutingModule } from './customer-routing.module';
import { MeterDetailsComponent } from './meter-details/meter-details.component';
import { CustomerDetailsService } from './services/customer-details.service';
import { CustomerGroupService } from './services/customer-group.service';
import { CustomerReadingsGridService } from './services/customer-readings-grid.service';
import { MeterDetailsWidgetComponent } from './widgets/meter-details-widget/meter-details-widget.component';

@NgModule({
  declarations: [
    CustomerGroupComponent,
    CustomerDetailsPageComponent,
    CustomerDetailsMetersComponent,
    CustomerDetailMeterReadingsComponent,
    CustomerDetailSingleComponent,
    CustomerDetailSingleRpWidgetComponent,
    MeterDetailsWidgetComponent,
    MeterDetailsComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    CustomerRoutingModule,
    WLMGridModule,
    TranslateModule,
    WlmFiltersModule,
    WlmSharedComponentModule,
    DynamicLayoutModule,
    LabelValueListModule,
    CollapsibleSummaryContainerModule,
  ],
  exports: [CustomerGroupComponent],
  providers: [
    CustomerGroupService,
    CustomerDetailsService,
    CustomerReadingsGridService,
    { provide: 'CustomerDetailsService', useExisting: CustomerDetailsService },
  ],
})
export class CustomerModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    CustomerModule.injector = injector;

    widgetRegistry.register([
      { name: 'MeterDetailsWidgetComponent', widgetClass: MeterDetailsWidgetComponent },
      {
        name: 'CustomerDetailSingleRpWidgetComponent',
        widgetClass: CustomerDetailSingleRpWidgetComponent,
      },
      {
        name: 'CustomerGroupComponent',
        widgetClass: CustomerGroupComponent,
      },
    ]);
  }
}
