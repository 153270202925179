export class SignalSelectionListSettings {
  //     currentConfiguration: LargeUserSignalDto[];
  //   excludedConfiguration: LargeUserSignalDto[];
  selectedItem: any;
  queryFieldNames: string[];
  oDataService: string;
  oDataFilters?: Map<string, any>;

  constructor(init: Readonly<SignalSelectionListSettings>) {
    Object.assign(this, init);
  }
}
