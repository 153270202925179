import { ChartSettings } from '../chart-settings';
import { ChartType } from '../chart-type.enum';
import { SchematicChartDataParameters } from './schematic-chart-data-parameters';

export class SchematicChartSettings extends ChartSettings {
  dataParameters: SchematicChartDataParameters;
  chartType = ChartType.schematic;

  constructor(init: SchematicChartSettings) {
    super(init);
    Object.assign(this, init);
  }
}
