<wlm-popup-wrapper
  [titleKey]="T_SCOPE + '.form-title'"
  [showSpinner]="isSaving"
  (popupClose)="close($event)"
>
  <ng-container popup-content>
    <mat-dialog-content [formGroup]="form">
      <mat-form-field appearance="outline" class="reason-style">
        <mat-label>{{ T_SCOPE + '.ack-comment-label' | translate }}</mat-label>
        <mat-select formControlName="ackCommentControl" [(value)]="ackCommentId">
          <mat-option
            *ngFor="let alarmComment of alarmCommentTypes"
            [value]="alarmComment.commentId"
          >
            {{ alarmComment.comment }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('ackCommentControl').invalid">
          {{ 'common.validation.required' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
  </ng-container>

  <ng-container popup-actions>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid || isSaving">
      {{ 'common.save' | translate }}
    </button>
  </ng-container>
</wlm-popup-wrapper>
