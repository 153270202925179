import { Pipe, PipeTransform } from '@angular/core';
import { ImageElement } from '../../shared/model/grid/image-element';

@Pipe({
  name: 'imageIconIsSvg',
})
export class ImageIconIsSvgPipe implements PipeTransform {
  constructor() {}

  transform(imageElements: Map<any, ImageElement>, cellValue: any): boolean {
    const value = cellValue as string;
    return imageElements?.get(value) === undefined
      ? false
      : (imageElements?.get(value) as ImageElement).isSvg;
  }
}
