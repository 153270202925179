import { NavItemsConfiguration } from './navitem-configuration';

export class NavMenuConfiguration {
  selectedElements: any[];
  items: NavItemsConfiguration[];

  constructor(data: Readonly<NavMenuConfiguration>) {
    Object.assign(this, data);
  }
}
