import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LogService } from '../../../wlm-log/log.service';
import { AlgorithmsService } from '../../algorithms.service';
import { BaseMapper } from '../base-mapper';
import { MapperFunctions } from '../mapper-functions';
import { ToAlgorithmUoMMapperParams } from '../mapper-params/to-algorithm-uom-mapper-params';
import { ToUomMapperParams } from '../mapper-params/to-uom-mapper-params';
import { ToUoMMapper } from './to-uom.mapper';

@Injectable()
export class ToAlgorithmUoMMapper extends BaseMapper {
  readonly mapperId = MapperFunctions.ToAlgorithmUoM;
  params: ToAlgorithmUoMMapperParams;

  constructor(
    private _algorithmsService: AlgorithmsService,
    private _logService: LogService,
    private _toUomMapper: ToUoMMapper
  ) {
    super();
  }

  map = (value: number, model: any, params?: ToAlgorithmUoMMapperParams): Observable<unknown> => {
    if (isNaN(value)) {
      return of(value);
    }

    if (!params?.algorithmShortName) {
      this._logService.error({ msg: 'Must specify algorithmShortName to convert to UoM.' });
      return of(value);
    }

    return this._algorithmsService.getAlgorithm(params.algorithmShortName).pipe(
      switchMap((algorithm) => {
        if (!algorithm) {
          return of(null);
        }
        const hierarchyElementTypeId = this.getHierarchyElementTypeId(params, model);
        const toUomMapperParams: ToUomMapperParams = {
          hierarchyElementTypeId,
          dimensionTypeId: algorithm.dimensionTypeId,
          timeAggregationId: algorithm.timeAggregationId,
        };
        return this._toUomMapper.map(value, model, toUomMapperParams);
      })
    );
  };

  private getHierarchyElementTypeId(params: ToAlgorithmUoMMapperParams, model: any): string {
    if (!params.hierarchyElementTypeId && !params.hierarchyElementTypeIdField) {
      this._logService.error({
        msg: 'Must specify either "hierarchyElementTypeId" or a field reference to convert to UoM.',
      });
      return null;
    }

    let hierarchyElementTypeId = params.hierarchyElementTypeId;
    if (!hierarchyElementTypeId) {
      hierarchyElementTypeId = model[params.hierarchyElementTypeIdField];
    }

    if (!hierarchyElementTypeId) {
      this._logService.error({
        msg: 'Field reference for "hierarchyElementTypeId" was empty',
      });
    }

    return hierarchyElementTypeId;
  }
}
