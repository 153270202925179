import { DataBindingFilters } from '../../filters/component-filters/data-binding-filters';
import { DragListSettings } from '../drag-list-virtual/drag-list-settings';

export class SelectDragListSettings extends DragListSettings {
  selectedFieldName: string;
  isLocal = false;
  filtrableFields?: string[];
  oDataFiltersExtended?: DataBindingFilters;

  constructor(init?: Partial<SelectDragListSettings>) {
    super();

    Object.assign(this, init);
  }
}
