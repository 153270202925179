import { GenericSelector } from 'src/app/common-modules/redux/models/generic-selector';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { FiltersSelectorTypes } from './filters.selector-types';

export abstract class FiltersSelector extends GenericSelector {
  area = StateAreas.Filters;
}

export class AppliedFiltersSelector extends FiltersSelector {
  type = FiltersSelectorTypes.AppliedFilters;
}

export class AppliedExtendedFiltersSelector extends FiltersSelector {
  type = FiltersSelectorTypes.AppliedExtendedFilters;
}

export class FiltersSettingsSelector extends FiltersSelector {
  type = FiltersSelectorTypes.GetSettings;
}

export class ResetFiltersSelector extends FiltersSelector {
  type = FiltersSelectorTypes.ResetFilters;
}
