import { MAT_DATE_FORMATS } from '@angular/material/core';

const valueFormat = {
  parse: {
    dateInput: ['L'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const matDateFormatsProvider = {
  provide: MAT_DATE_FORMATS,
  useValue: valueFormat,
};

export const ngxMatDateFormatsProvider = {
  valueFormat,
};
