import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { DynamicLayoutSettings } from './dynamic-layout-settings';
import { LayoutLoadOptions } from './layout-load-options';

/**
 * Wrapper of DynamicLayoutSettings which also adds options about how the settings will be loaded.
 */
export class DynamicLayoutSettingsLoadOptions {
  settings: DynamicLayoutSettings;
  loadOptions? = new LayoutLoadOptions();

  constructor(init: DynamicLayoutSettingsLoadOptions) {
    Object.assign(this, globalUtilsHelper.serializedClone(init));
  }
}
