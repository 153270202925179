export enum GridColumnTypes {
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Date = 'date',
  Uom = 'uom',
  FormatedText = 'formatted-text',
  Enumerable = 'enumerable',
  Custom = 'custom',
  Currency = 'currency',
  Image = 'image',
  Maxmin = 'maxmin',
  Hierarchy = 'hierarchy',
  AlarmCounter = 'alarm-counter',
  ExportOnly = 'export-only',
  AlarmSeverity = 'alarm-severity',
  Duration = 'duration',
  User = 'user',
  Internal = 'internal',
  Button = 'button',
}
