import { ErrorStateMatcher } from '@angular/material/core';

export class DateRangeFilterSettings {
  disableValidationUntilTouched?: boolean;
  isReadOnly?: boolean;
  // Whether the startDate can be equal to the endDate
  disableEqualDates: boolean;
  startDateErrorStateMatcher: ErrorStateMatcher;
  endDateErrorStateMatcher: ErrorStateMatcher;

  constructor(init: DateRangeFilterSettings) {
    Object.assign(this, init);
  }
}
