import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map } from 'rxjs';
import { DropdownNavigationItem } from 'src/app/common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { CalendarDto } from 'src/app/common-modules/dependencies/shared/model/calendar.dto';
import { DynamicLayoutComponent } from 'src/app/common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutBuilderService } from 'src/app/common-modules/dynamic-layout/layouts/dynamic-layout-builder-service';
import { SimpleDynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from 'src/app/common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings-item';
import { DynamicLayoutSettings } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutStructure } from 'src/app/common-modules/dynamic-layout/models/dynamic-layout-structure';
import { LayoutNodeTypes } from 'src/app/common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from 'src/app/common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetStateAction,
  SetValueAction,
} from 'src/app/common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from 'src/app/common-modules/dynamic-layout/state/generic/generic.selectors';
import { StateAreas } from 'src/app/common-modules/redux/models/state-areas';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BasePageComponent } from 'src/app/common-modules/shared/component/base-page.component';
import { CalendarPopupDimensions } from 'src/app/common-modules/shared/constants/dimensions.constants';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { CalendarCreationPopupComponent } from '../components/calendar-creation-popup/calendar-creation-popup.component';
import { CalendarStateFields } from '../models/calendar-state-fields';
import { CalendarService } from '../service/calendar.service';
import { CALENDAR_CONFIGURATION_COMPONENT_INSTANCE } from '../widgets/calendar-configuration-widget/calendar-configuration-widget.component';
import { CALENDAR_SELECTOR_COMPONENT_INSTANCE } from '../widgets/calendar-selector-widget/calendar-selector-widget.component';

const COMPONENT_SELECTOR = 'wlm-calendar-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class CalendarPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }
  dynamicLayoutSettings: DynamicLayoutSettings;
  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private readonly _simpleSettings: SimpleDynamicLayoutSettings = {
    layoutArea: 'LayoutMain',
    layoutKey: 'Calendars',
    scopeInstanceKeys: {
      [StateAreas.Generic]: 'shared',
    },
  };

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._simpleSettings.layoutArea,
    page: this._simpleSettings.layoutKey,
    scopeInstanceKeys: this._simpleSettings.scopeInstanceKeys,
  });

  readonly _layoutKey = 'MainGroup';

  private readonly _calendarWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'CalendarSelectorWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-calendar-selector`,
    id: CALENDAR_SELECTOR_COMPONENT_INSTANCE,
  };
  private readonly _configurationWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'CalendarConfigurationWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.wlm-calendar-configuration`,
    id: CALENDAR_CONFIGURATION_COMPONENT_INSTANCE,
  };
  private readonly _defaultWidgets = [this._calendarWidget, this._configurationWidget];

  private readonly _defaultStructure: DynamicLayoutStructure[] = [
    {
      type: LayoutNodeTypes.Row,
      content: [
        {
          type: LayoutNodeTypes.Column,
          content: [
            {
              type: LayoutNodeTypes.Stack,
              height: 100,
              width: 30,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: CALENDAR_SELECTOR_COMPONENT_INSTANCE, //calendars
                },
              ],
            },
          ],
        },
        {
          type: LayoutNodeTypes.Column,
          content: [
            {
              type: LayoutNodeTypes.Stack,
              height: 100,
              width: 70,
              content: [
                {
                  type: LayoutNodeTypes.Component,
                  widgetInstanceKey: CALENDAR_CONFIGURATION_COMPONENT_INSTANCE, //configuration
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  pageId: string;

  selectedCalendar: CalendarDto;
  isLoading = false;

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'WLMCalendarsCrud';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  constructor(
    private _dynamicLayoutBuilderService: DynamicLayoutBuilderService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _pendingChangesService: PendingChangesManagerService,
    private readonly _calendarService: CalendarService,
    private readonly _dialogService: DialogService,
    private readonly _state: ReduxStateService
  ) {
    super();
    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initialLoad();

    this._state
      .select<CalendarDto>(
        new GetValueSelector({
          fieldName: CalendarStateFields.selectedCalendar,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.selectedCalendar = value;
      });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) { }
  init(): void { }

  private initialLoad(): void {
    this.buildDynamicLayoutSettings().subscribe((settings) => {
      this.dynamicLayoutSettings = settings;
    });
  }

  buildDynamicLayoutSettings(): Observable<DynamicLayoutSettings> {
    const simpleSettings = globalUtilsHelper.clone(this._simpleSettings, true);
    simpleSettings.items = this._defaultWidgets;

    return this._dynamicLayoutBuilderService.build(simpleSettings).pipe(
      map((dynamicLayoutSettings) => {
        dynamicLayoutSettings.showResetIcon = true;
        dynamicLayoutSettings.defaultStructure = this._defaultStructure;
        return dynamicLayoutSettings;
      })
    );
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._state.dispatch(new ResetStateAction({ fieldNames: Object.keys(CalendarStateFields) }));
      this._dynamicLayoutService.resetAll();
    });
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  createCalendar() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      isEditMode: false,
    };

    dialogConfig.height = CalendarPopupDimensions.Height;
    dialogConfig.width = CalendarPopupDimensions.Width;

    this._dialogService
      .openComponent(CalendarCreationPopupComponent, dialogConfig)
      .afterClosed()
      .subscribe((reload) => {
        if (reload) {
          this.realoadSelectors();
        }
      });
  }

  editCalendar() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      isEditMode: true,
      selectedCalendar: this.selectedCalendar,
    };

    dialogConfig.height = CalendarPopupDimensions.Height;
    dialogConfig.width = CalendarPopupDimensions.Width;

    this._dialogService
      .openComponent(CalendarCreationPopupComponent, dialogConfig)
      .afterClosed()
      .subscribe((reload) => {
        if (reload) {
          this.realoadSelectors();
        }
      });
  }

  deleteCalendar() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.delete-warning`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.deleteCalendarAction();
        }
      });
  }

  private deleteCalendarAction() {
    this.isLoading = true;
    this._calendarService.deleteCalendar(this.selectedCalendar.calendarId).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.showSuccessMessage('messages.delete-success');
        this.realoadSelectors();
      },
      error: () => {
        this.isLoading = false;
        this.showErrorMessage('messages.delete-error');
      },
    });
  }

  private showErrorMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: 'error',
    });
    this._dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private showSuccessMessage(messageKey: string) {
    const dialogSetting = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: 'success',
    });
    this._dialogService.showTranslatedMessageInSnackBar(dialogSetting);
  }

  private realoadSelectors() {
    this._state.dispatch(
      new SetValueAction({
        fieldName: CalendarStateFields.reloadCalendarSelector,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: CalendarStateFields.reloadCalendarSelector,
        value: true,
      })
    );
  }

  ngOnDestroy(): void {
    this._state.dispatch(new ResetStateAction({ fieldNames: Object.keys(CalendarStateFields) }));

    super.ngOnDestroy();
  }
}
