<wlm-column
  *ngIf="
    column.type !== 'date' &&
    column.type !== 'uom' &&
    column.type !== 'boolean' &&
    column.type !== 'enumerable' &&
    column.type !== 'custom' &&
    column.type !== 'currency' &&
    column.type !== 'image' &&
    column.type !== 'maxmin' &&
    column.type !== 'alarm-counter' &&
    column.type !== 'export-only' &&
    column.type !== 'duration' &&
    column.type !== 'alarm-severity' &&
    column.visible
  "
  [field]="column.field"
  [title]="column.title"
  [format]="column.format"
  [width]="column.width"
  [filter]="column.type === 'hierarchy' ? 'text' : column.type"
  [backgroundColorMapping]="backgroundColorMapping"
  [gridSettings]="gridSettings"
  [useCellStyle]="useCellStyle"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
  [columnSettings]="column"
>
</wlm-column>

<wlm-date-time-column
  *ngIf="column.type == 'date' && column.visible"
  [field]="column.field"
  [title]="column.title"
  [width]="column.width"
  [format]="column.format"
  [useCellStyle]="useCellStyle"
  [backgroundColorMapping]="backgroundColorMapping"
  [type]="column.type"
  [gridSettings]="gridSettings"
  [columnSettings]="column"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
>
</wlm-date-time-column>

<wlm-currency-column
  *ngIf="column.type === 'currency' && column.visible"
  [field]="column.field"
  [format]="column.format"
  [width]="column.width"
  filter="numeric"
  [backgroundColorMapping]="backgroundColorMapping"
  [gridSettings]="gridSettings"
  [useCellStyle]="useCellStyle"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
  [unitFormat]="getUnitFormat(column)"
  [baseColumnTitle]="column.title"
  [columnSettings]="column"
>
</wlm-currency-column>

<wlm-uom-column-component
  *ngIf="column.type === 'uom' && column.visible"
  [field]="column.field"
  [format]="column.format"
  [filter]="column.type"
  [useCellStyle]="useCellStyle"
  [columnSettings]="column"
  [gridSettings]="gridSettings"
  [unitFormat]="getUnitFormat(column)"
  [width]="250"
  [hierarchyElementTypeIdField]="column.uomHierarchyElementTypeIdField"
  [algorithmShortName]="column.algorithmShortName"
  [timeAggregationId]="column.timeAggregationId"
  [dimensionTypeId]="column.dimensionTypeId"
  [decimalPositions]="column.uomDecimalPositions"
  baseTitle="{{ column.title }}"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
  (headerTextChanged)="headerTextChanged()"
></wlm-uom-column-component>

<wlm-boolean-column
  *ngIf="column.type == 'boolean' && column.visible"
  [field]="column.field"
  [title]="column.title"
  [gridSettings]="gridSettings"
  [backgroundColorMapping]="backgroundColorMapping"
  [useCellStyle]="useCellStyle"
  [width]="column.width"
  [useImages]="column.useImages"
  [columnSettings]="column"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
>
</wlm-boolean-column>

<wlm-enumerable-column
  *ngIf="column.type == 'enumerable' && column.visible"
  [field]="column.field"
  [title]="column.title"
  [useCellStyle]="useCellStyle"
  [gridSettings]="gridSettings"
  [backgroundColorMapping]="backgroundColorMapping"
  [width]="column.width"
  [columnSettings]="column"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
>
</wlm-enumerable-column>

<wlm-image-column
  *ngIf="column.type == 'image' && column.visible"
  [field]="column.field"
  [title]="column.title"
  [gridSettings]="gridSettings"
  [backgroundColorMapping]="backgroundColorMapping"
  [useCellStyle]="useCellStyle"
  [width]="column.width"
  [columnSettings]="column"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
></wlm-image-column>

<wlm-maxmin-column-component
  *ngIf="column.type === 'maxmin' && column.visible"
  [field]="column.field"
  [format]="column.format"
  [filter]="column.type"
  [title]="column.title"
  [useCellStyle]="useCellStyle"
  [columnSettings]="column"
  [gridSettings]="gridSettings"
  [unitFormat]="getUnitFormat(column)"
  [width]="250"
  [algorithmShortName]="column.algorithmShortName"
  [hierarchyElementTypeIdField]="column.uomHierarchyElementTypeIdField"
  [hierarchyElementIdField]="column.maxminHierarchyElementIdField"
  [timeAggregationId]="column.timeAggregationId"
  [dimensionTypeId]="column.dimensionTypeId"
  [decimalPositions]="column.uomDecimalPositions"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
  (headerTextChanged)="headerTextChanged()"
></wlm-maxmin-column-component>

<wlm-eligibility-column
  *ngIf="column.type === 'custom' && column.field === 'isEligibleByUser' && column.visible"
  [columnSettings]="column"
  [selectedRows]="selectedRows"
  [gridSettings]="gridSettings"
  [field]="column.field"
  [title]="column.title"
  [width]="column.width"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
  (eligbilityPopupClosed)="reloadGrid()"
>
</wlm-eligibility-column>

<kendo-grid-column
  *ngIf="column.type === 'custom' && column.field === 'firstDayStatusChanged' && column.visible"
  [field]="column.field"
  [title]="column.title"
  [format]="column.format"
  [width]="column.width"
  filter="date"
  [style]="column.type | columnStyle"
  [headerStyle]="'default' | columnStyle"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span>
      {{ dataItem[column.field] }}
      <!-- | date: column.format -->
    </span>
  </ng-template>
</kendo-grid-column>

<wlm-alarm-counter-column
  *ngIf="column.type === 'alarm-counter'"
  [field]="column.field"
  [format]="column.format"
  [title]="column.title"
  [width]="column.width"
  [filterable]="false"
  [sortable]="gridSettings.sortable"
  [headerStyle]="'default' | columnStyle"
  [style]="column.type | columnStyle"
  [alarmCriticalCounterField]="column.alarmCriticalCounterField"
  [alarmHighCounterField]="column.alarmHighCounterField"
  [alarmMediumCounterField]="column.alarmMediumCounterField"
  [alarmLowCounterField]="column.alarmLowCounterField"
>
</wlm-alarm-counter-column>

<wlm-alarm-severity-column
  *ngIf="column.type === 'alarm-severity'"
  [format]="column.format"
  [title]="column.title"
  [field]="column.field"
  [columnSettings]="column"
  [gridSettings]="gridSettings"
  [width]="column.width"
  [filterable]="true"
  [headerStyle]="'default' | columnStyle"
  [style]="column.type | columnStyle"
>
</wlm-alarm-severity-column>
<wlm-date-duration-column
  *ngIf="column.type === 'duration'"
  [format]="column.format"
  [title]="column.title"
  [field]="column.field"
  [columnSettings]="column"
  [gridSettings]="gridSettings"
  [width]="column.width"
  [filterable]="false"
  [sortable]="false"
  [startDateFieldName]="column.durationStartDateField"
  [endDateFieldName]="column.durationEndDateField"
  [headerStyle]="'default' | columnStyle"
  [style]="column.type | columnStyle"
>
</wlm-date-duration-column>
