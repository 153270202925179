<wlm-base-filter-container
  *ngIf="adaptersReady"
  [provideAdapters]="provideAdapters"
  [keysToComplete]="keysToComplete"
  [clearAll$]="clearAll$"
  [apply$]="apply$"
  (filterResults)="onGlobalFilterResults($event)"
  (applyResults)="onGlobalApplyResults($event)"
  (filterComplete)="onGlobalComplete()"
>
  <div class="filters-background">
    <wlm-base-filter
      [settings]="bfUserSettings"
      [persistItems$]="persistFilters$"
      (filterText)="onFilterText($event)"
    >
      <wlm-user-filter-item
        [currentUser]="filterConfiguration.currentUser"
        [fieldName]="filterConfiguration.userFieldName"
        [selectedId]="filterConfiguration.selectedUser"
        [defaultSelectedId]="filterConfiguration.defaultSelectedUser"
        [filterText]="filterText"
        [settings]="userItemSettings"
      ></wlm-user-filter-item>
    </wlm-base-filter>
  </div>
</wlm-base-filter-container>
