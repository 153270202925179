import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalsService } from 'src/app/common-modules/shared/services/globals.service';
import { ElementTypeFilter } from '../../../../../common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { AppModules } from '../../../../../common-modules/shared/app-modules.enum';
import { ElementTypeCheckDto } from '../../model/ne/element-type-check.dto';
import { INeHeFilterSettings } from '../../../../../common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';

const COMPONENT_SELECTOR = 'wlm-nehe-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './nehe-filter.component.html',
  styleUrls: ['./nehe-filter.component.scss'],
})
export class NeHeFilterComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  private _selectedElements: any[];
  public get selectedElements(): any[] {
    return this._selectedElements;
  }
  public set selectedElements(v: any[]) {
    this._selectedElements = v;
    this.elementTypesFilterChanged.emit(
      new ElementTypeFilter(this.filterSettings.elementTypeFieldName, this.selectedElements)
    );
  }

  @Input() filterSettings: INeHeFilterSettings;
  @Input() elementTypesFilter: ElementTypeFilter;
  @Output() elementTypesFilterChanged = new EventEmitter<ElementTypeFilter>();

  private _selectAllChecked: boolean;
  public get selectAllChecked(): boolean {
    return this._selectAllChecked;
  }
  public set selectAllChecked(value: boolean) {
    this.selectAllIndeterminate = false;
    this.elementList.forEach((e) => (e.selected = value));
    this.selectedElements = value ? this.elementList.map((m) => m.elementId) : [];
    this._selectAllChecked = value;
    this.elementTypesFilterChanged.emit(
      new ElementTypeFilter(this.filterSettings.elementTypeFieldName, this.selectedElements)
    );
  }

  public elementList: ElementTypeCheckDto[] = [];
  public selectAllIndeterminate: boolean;
  public title: string;

  constructor(private globalsService: GlobalsService) {}

  ngOnInit(): void {
    // const settings: INeHeFilterSettings = {
    //   isNEFilter: true,
    //   selectedElementIds: [1, 2, 3],
    // };
    // this.filterSettings = new INeHeFilterSettings();
    // this.filterSettings = settings;

    this.initializeFilter();
  }

  initializeFilter() {
    if (this.filterSettings.isNEFilter) {
      this.loadNeTypes();
      this.title = '.netitle';
    } else {
      this.loadHeTypes();
      this.title = '.hetitle';
    }
  }
  loadHeTypes() {
    this.globalsService.getHierarchyElementTypes().subscribe({
      next: (heTypes) => {
        const items = heTypes.filter((f) => f.networkElementTypeId !== undefined);
        items.forEach((i) =>
          this.elementList.push(
            new ElementTypeCheckDto(
              i.hierarchyElementTypeName,
              i.hierarchyElementTypeId,
              this.filterSettings.selectedElementIds.includes(i.hierarchyElementTypeId)
            )
          )
        );
        this.selectedElements = items
          .filter((f) => this.filterSettings.selectedElementIds.includes(f.hierarchyElementTypeId))
          .map((m) => m.hierarchyElementTypeId);
        this.calculateIndeterminateState(this.selectedElements.length, this.elementList.length);
      },
    });
  }

  loadNeTypes() {
    this.globalsService.getNetworkElementTypes().subscribe({
      next: (neTypes) => {
        const items = neTypes.filter((f) => f.isZone === false && f.isNetworkElement === true);
        items.forEach((i) =>
          this.elementList.push(
            new ElementTypeCheckDto(
              i.networkElementTypeName,
              i.networkElementTypeId,
              this.filterSettings.selectedElementIds.includes(i.networkElementTypeId),
              i.iconPath
            )
          )
        );
        this.selectedElements = items
          .filter((f) => this.filterSettings.selectedElementIds.includes(f.networkElementTypeId))
          .map((m) => m.networkElementTypeId);
        this.calculateIndeterminateState(this.selectedElements.length, this.elementList.length);
      },
    });
  }

  onSelect($event, elementType): void {
    if (this.selectedElements.includes(elementType.elementId)) {
      this.selectedElements = this.selectedElements.filter((f) => f !== elementType.elementId);
    } else {
      this.selectedElements = this.selectedElements.concat([elementType.elementId]);
    }
    this.calculateIndeterminateState(this.selectedElements.length, this.elementList.length);
  }

  private calculateIndeterminateState(selectedLength, elementsLength) {
    this.selectAllIndeterminate = selectedLength > 0 && selectedLength !== elementsLength;
    if (selectedLength > 0 && selectedLength === elementsLength) {
      this.selectAllChecked = true;
    }
  }

  selectAllClick($event) {
    const allElementsAreSelected = this.selectedElements.length === this.elementList.length;

    this.selectedElements = allElementsAreSelected ? [] : this.elementList.map((m) => m.elementId);
    this.elementList.forEach(
      (element) => (element.selected = allElementsAreSelected ? false : true)
    );
  }
}
