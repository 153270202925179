<wlm-page-wrapper [titleKey]="titleTranslationKey" [showPageSpinner]="showSpinner">
  <ng-container page-top-buttons>
    <div class="buttons-background button-position">
      <button
        mat-icon-button
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        color="primary"
        onclick="this.blur()"
        (click)="resetWizard()"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>
  </ng-container>

  <ng-container page-content>
    <wlm-sa-wizard
      [pageId]="pageId"
      [sAWizardSettings]="saWizardSettings"
      [resetHandler$]="resetWizard$"
      (showSpinner)="onShowSpinner($event)"
    ></wlm-sa-wizard>
  </ng-container>
</wlm-page-wrapper>
