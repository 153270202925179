import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppModules } from '../../../../../common-modules/shared/app-modules.enum';
import { TreelistChange } from './treelist-change';
import { TreelistData } from './treelist-data';
import { TreelistSettings } from './treelist-settings';

const COMPONENT_SELECTOR = 'wlm-treelist';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './treelist.component.html',
  styleUrls: ['./treelist.component.scss'],
})
export class TreelistComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  fullSize: boolean = true;

  @Input() treeListSettings: TreelistSettings;

  @Output() valueChanged = new EventEmitter<TreelistChange>();

  constructor() {}

  ngOnInit(): void {}

  columnValueChanged(event: MatCheckboxChange, dataItem: TreelistData, columnKey: string) {
    const change = new TreelistChange({ columnKey, rowKey: dataItem.rowKey, value: event.checked });
    this.valueChanged.emit(change);
  }

  checkIfDisabled(dataItem, columnKey): boolean {
    if (!this.treeListSettings.disabledNodes.has(dataItem.rowKey)) {
      return false;
    }

    const isDisabledColumn = this.treeListSettings.disabledNodes
      .get(dataItem.rowKey)
      .includes(columnKey);

    return isDisabledColumn;
  }
}
