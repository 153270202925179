import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IHierarchyElementDto } from 'src/app/common-modules/dependencies/he/hierarchy-element.dto';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from 'src/app/common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseSelectorDynamicWidgetComponent } from 'src/app/common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from 'src/app/common-modules/redux/redux-state.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { PendingChangesManagerService } from 'src/app/common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { HierarchyStateFields } from '../../models/hierarchy-state-fields';

const COMPONENT_SELECTOR = 'wlm-hierarchy-selector-widget';
export const HIERARCHY_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-selector-widget.component.html',
  styleUrls: ['./hierarchy-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class HierarchySelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  filters: Map<string, any>;

  get selectedFieldName(): string {
    return HierarchyStateFields.selectedHierarchyElement;
  }

  get componentName(): string {
    return 'HierarchySelectorWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService,
    private readonly _objectHelperService: ObjectHelperService
  ) {
    super(injector, widgetSettings, pendingChangesService);

    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.emitWidgetInit();

    this._state
      .select(new AppliedFiltersSelector())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dbFilter: DataBindingFilters) => {
          if (!dbFilter) {
            return;
          }
          this.filters = this.setFiltersMap(dbFilter);
        },
      });
  }

  onSelectedRow(data: IHierarchyElementDto): void {
    this.checkPendingChanges(this.pageId).subscribe((_) => {
      this.setSelected(data);
    });
  }

  private setFiltersMap(dataBindingFilters: DataBindingFilters): Map<string, any> {
    const iFilters = Array.from(dataBindingFilters.filters.values()).map((x) =>
      x.getFiltersValues()
    );

    const filtersToApply = this._objectHelperService.joinMaps(iFilters);

    return this._objectHelperService.clone(filtersToApply);
  }
}
