<wlm-spinner-wrapper [showSpinner]="!widgetReady && !chartUpdating && elementId">
  <div class="main-container">
    <wlm-time-selector-chart
      [timeSelectorChartSetting]="timeSelectorChartSettings"
      [loading]="chartUpdating"
      [containerSize$]="size$"
      (chartInitEvent)="onChartInit($event)"
      (chartLegendSelectedEvent)="onChartLegendSelected($event)"
      (chartLoaded)="onChartLoaded($event)"
      (chartFinished)="onChartFinished($event)"
      (serieNamesUnselected)="onSerieNamesUnselected($event)"
    >
      <div topbar-first>
        <wlm-alarms-tab-widget
          class="widget-position"
          *ngIf="params?.elementId"
          [excludeChildren]="true"
          [disabledIfEmpty]="true"
          [isNetworkElement]="isNetworkElement"
          [elementId]="elementId"
          (filter)="onTabFilter($event)"
          (ready)="onWidgetReady($event)"
        ></wlm-alarms-tab-widget>
      </div>
    </wlm-time-selector-chart>
  </div>
</wlm-spinner-wrapper>
