export class GChartGrid {
  y? = '30';
  y2? = '45';

  left?: any;
  right?: any;
  top?: any;
  bottom?: any;

  constructor(init: Readonly<GChartGrid>) {
    Object.assign(this, init);
  }
}
