import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { EnvelopesConfiguration } from 'src/app/common-modules/dependencies/alarms/envelopes-configuration';
import { ProfileConfigurationDto } from 'src/app/common-modules/dependencies/alarms/profile-configuration.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ConstantsValues } from 'src/app/common-modules/shared/constants/constants-values';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { BaseCalculateResizableChildComponent } from 'src/app/common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { ArrayHelperService } from 'src/app/common-modules/shared/helpers/array-helper.service';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { SaAlgorithmElementDto } from 'src/app/common-modules/shared/model/algorithm/sa-algorithm-element.dto';
import { TimeAggregationEnum } from 'src/app/common-modules/shared/model/algorithm/time-aggregation.enum';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { ISignalTelemetryNullableViewDto } from 'src/app/common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { TrendChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { TrendChartSettings } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-settings';
import { LocalGridComponent } from 'src/app/common-modules/wlm-grid/local-grid/local-grid.component';
import { EnvelopeMode } from '../../../../../common-modules/dependencies/alarms/envelope-modes.enum';
import { ProfilesService } from '../../profiles-chart/profiles.service';
import { SAEnvelopesConfigurationSettings } from '../models/sa-envelopes-configuration-settings';

const COMPONENT_SELECTOR = 'wlm-sa-envelopes-configuration';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-envelopes-configuration.component.html',
  styleUrls: ['./sa-envelopes-configuration.component.scss'],
})
export class SAEnvelopesConfigurationComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit
{
  @ViewChild(LocalGridComponent) localGrid: LocalGridComponent;
  @Output() envelopesChanged = new EventEmitter<EnvelopesConfiguration>();
  @Output() envelopesAreValid = new EventEmitter<boolean>();
  @Input() profileConfiguration: ProfileConfigurationDto;

  private readonly _signalTypeId = ConstantsValues.emptyGuid;

  private _stepApplied$: Observable<string>;
  public get stepApplied$(): Observable<string> {
    return this._stepApplied$;
  }
  @Input() public set stepApplied$(v: Observable<string>) {
    this._stepApplied$ = v;
    this.subscribeToPreviousAppliedSteps(this.stepApplied$);
  }

  private _settings: SAEnvelopesConfigurationSettings;
  public get settings(): SAEnvelopesConfigurationSettings {
    return this._settings;
  }
  @Input() public set settings(v: SAEnvelopesConfigurationSettings) {
    this._settings = v;
  }

  private _telemetryPoints: ISignalTelemetryNullableViewDto[];
  public get telemetryPoints(): ISignalTelemetryNullableViewDto[] {
    return this._telemetryPoints;
  }
  @Input() public set telemetryPoints(v: ISignalTelemetryNullableViewDto[]) {
    this._telemetryPoints = v;
    this.gridData = this.telemetryPoints;
    this.initEnvelopeModes();
    if (v?.length) {
      const defaultElement = v[0];
      this.setUoMParams(
        defaultElement.dimensionTypeId,
        TimeAggregationEnum.Base,
        this._signalTypeId
      );
    }
  }

  private _algorithmElements: SaAlgorithmElementDto[];
  public get algorithmElements(): SaAlgorithmElementDto[] {
    return this._algorithmElements;
  }

  @Input() public set algorithmElements(value: SaAlgorithmElementDto[]) {
    this._algorithmElements = value;
    this.gridData = this.algorithmElements;
    this.initEnvelopeModes();
    if (value?.length) {
      const defaultElement = value[0];
      this.setUoMParams(
        defaultElement.dimenstionTypeId,
        defaultElement.timeAggregationId,
        defaultElement.hierarchyElement?.hierarchyElementTypeId
      );
    }
  }

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input()
  public set resetHandler$(value: Observable<boolean>) {
    this._resetHandler$ = value;
    if (value) {
      this.subscribeToResetWizard(this.resetHandler$);
    }
  }
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  private _chartContainer: ElementRef<HTMLElement>;
  get chartContainer(): ElementRef<HTMLElement> {
    return this._chartContainer;
  }
  @ViewChild('chartContainer') set chartContainer(value: ElementRef<HTMLElement>) {
    this._chartContainer = value;

    this.containerSize$ = this._sizeCalculatorService.listenElementSize$(
      this._chartContainer.nativeElement
    );
  }

  dimensionToCalculate: 'height' | 'width' = 'height';
  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;
  resetEnvelopes$ = new Subject<void>();
  chartSettings: TrendChartSettings;
  gridSettings: IGridSettings;
  envelopesValid: boolean;
  isValid: boolean;
  envModesToRemove: EnvelopeMode[];
  envelopesConfiguration: EnvelopesConfiguration;
  selectedTelemetryPoint: ISignalTelemetryNullableViewDto;
  selectedAlgorithmTarget: SaAlgorithmElementDto;
  gridSettingSignals = 'EnvelopeConfiguration';
  gridSettingAlgorithms = 'EnvelopeConfigurationAlgorithm';
  profileTitle: string;
  gridData: any[];
  loading = false;
  resetGridSelection$ = new Subject<boolean>();

  uoMParams: { dimensionTypeId: number; timeAggregationId: number; hierarchyElementTypeId: string };

  constructor(
    private readonly _arrayHelper: ArrayHelperService,
    private readonly _gridService: GridSettingsService,
    private readonly _localization: LocalizationHelperService,
    private readonly _profileService: ProfilesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getGridSettings();
    this.getTranslations();
  }

  getTranslations() {
    this._localization.get(`${this.T_SCOPE}.profile-name`).subscribe((ts) => {
      this.profileTitle = ts;
    });
  }

  resetEnvelopeConfiguration() {
    this.envelopesConfiguration.mode = EnvelopeMode.Adaptative;

    this.envelopesConfiguration.hiHi = null;
    this.envelopesConfiguration.hi = null;
    this.envelopesConfiguration.lowLow = null;
    this.envelopesConfiguration.low = null;

    this.envelopesConfiguration.hiHiSeverity = null;
    this.envelopesConfiguration.hiSeverity = null;
    this.envelopesConfiguration.lowLowSeverity = null;
    this.envelopesConfiguration.lowSeverity = null;

    this.envelopesConfiguration.hiHiRelative = null;
    this.envelopesConfiguration.hiRelative = null;
    this.envelopesConfiguration.lowLowRelative = null;
    this.envelopesConfiguration.lowRelative = null;

    this.envelopesConfiguration.hiHiPersistency = null;
    this.envelopesConfiguration.hiPersistency = null;
    this.envelopesConfiguration.lowLowPersistency = null;
    this.envelopesConfiguration.lowPersistency = null;

    const tempProfileConfiguration = this.profileConfiguration;

    Object.assign(tempProfileConfiguration, this.envelopesConfiguration);
    this.setChartSettings(tempProfileConfiguration);
  }

  onEnvelopesConfig(data: EnvelopesConfiguration): void {
    this.envelopesConfiguration = data;
    this.envelopesChanged.emit(data);
  }

  onEnvelopesValid = (valid) => {
    this.envelopesValid = valid;
    this.notifyChanges();
  };

  notifyChanges(): void {
    this.isValid = this.checkValid();
    this.envelopesAreValid.emit(this.isValid);
  }

  checkValid(): boolean {
    return this.envelopesValid;
  }

  refreshChart() {
    if (this.selectedTelemetryPoint || this.selectedAlgorithmTarget) {
      this.profileConfiguration.signalId = this.selectedTelemetryPoint?.signalId;
      this.profileConfiguration.profileName = this.profileTitle;
      this.profileConfiguration.dimensionTypeId =
        this.selectedTelemetryPoint?.dimensionTypeId ??
        this.selectedAlgorithmTarget.dimenstionTypeId;

      this.profileConfiguration.timeAggregationId =
        this.selectedAlgorithmTarget?.timeAggregationId ?? (TimeAggregationEnum.Base as number);

      this.profileConfiguration.algorithmId = this.selectedAlgorithmTarget?.algorithmId;
      this.profileConfiguration.networkElementId =
        this.selectedAlgorithmTarget?.networkElement?.networkElementId;
      this.profileConfiguration.hierarchyElementId =
        this.selectedAlgorithmTarget?.hierarchyElement?.hierarchyElementId;
      this.profileConfiguration.hierarchyElementTypeId =
        this.selectedAlgorithmTarget?.hierarchyElement?.hierarchyElementTypeId;

      if (this.isValid) {
        Object.assign(this.profileConfiguration, this.envelopesConfiguration);
      }
      this._profileService
        .convertDefaultUnits(this.profileConfiguration, 'to')
        .subscribe((convertedProfile) => this.setChartSettings(convertedProfile));
    }
  }

  getSelectedItem(selectedItem: ISignalTelemetryNullableViewDto | SaAlgorithmElementDto) {
    if (!selectedItem || selectedItem === null) {
      return;
    }

    this.loading = true;

    // tslint:disable-next-line: no-string-literal
    if (selectedItem['signalId']) {
      this.selectedTelemetryPoint = selectedItem as ISignalTelemetryNullableViewDto;
    }
    // tslint:disable-next-line: no-string-literal
    else if (selectedItem['algorithmShortName']) {
      this.selectedAlgorithmTarget = selectedItem as SaAlgorithmElementDto;
    } else {
      this.selectedAlgorithmTarget = null;
      this.selectedTelemetryPoint = null;
    }

    this.refreshChart();
  }

  onLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  resetEnvelopeConfig() {
    this.resetEnvelopes$.next();
    this.resetEnvelopeConfiguration();
  }

  private getGridSettings() {
    this._gridService.getGridSettingsByName(this.settings.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  private setChartSettings(pointConfiguration: ProfileConfigurationDto) {
    this.chartSettings = new TrendChartSettings({
      dataParameters: new TrendChartDataParameters({
        dataService: 'ProfilesSaConfigurationChartService',
        queryParams: [pointConfiguration],
        startDate: null,
        endDate: null,
      }),
      chartType: ChartType.trend,
    });
  }

  subscribeToPreviousAppliedSteps(stepApplied$: Observable<string>) {
    if (stepApplied$) {
      stepApplied$.pipe(untilDestroyed(this)).subscribe((stepApplied) => {
        if (stepApplied) {
          this.selectedAlgorithmTarget = null;
          this.selectedTelemetryPoint = null;
          this.profileConfiguration.algorithmId = null;
          this.profileConfiguration.signalId = null;
          this.resetGridSelection$.next(true);

          this.setChartSettings(this.profileConfiguration);
        }
      });
    }
  }

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this.telemetryPoints = [];
          this.envelopesValid = false;
          this.localGrid?.clearFilters();
          this.resetEnvelopeConfig();
          this.chartSettings = null;
          this.notifyChanges();
          this.resetCompleteHandler$.next();
        }
      });
    }
  }

  // This is to remove the FlatAbsolute mode when the signals have different dimensions
  private initEnvelopeModes() {
    if (this.hasToRemoveFlatAbsoluteMode()) {
      this.envModesToRemove = [EnvelopeMode.FlatAbsolute];
    } else {
      this.envModesToRemove = null;
    }
  }

  private hasToRemoveFlatAbsoluteMode() {
    return (
      (this.algorithmElements?.length &&
        (this.hasMoreThanOne(this.algorithmElements?.map((x) => x.dimenstionTypeId)) ||
          this.hasMoreThanOne(
            this.algorithmElements?.map((x) => x.hierarchyElement?.hierarchyElementTypeId)
          ))) ||
      (this.telemetryPoints?.length &&
        this.hasMoreThanOne(this.telemetryPoints?.map((x) => x.dimensionTypeId)))
    );
  }

  private setUoMParams(
    dimensionTypeId: number,
    timeAggregationId: number,
    hierarchyElementTypeId: string
  ) {
    this.uoMParams = { dimensionTypeId, timeAggregationId, hierarchyElementTypeId };
  }

  private hasMoreThanOne(list: any[]): boolean {
    return this._arrayHelper.onlyUnique(list)?.length > 1;
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [];
  }
}
