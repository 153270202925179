import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription, of } from 'rxjs';
import { ProfileConfigurationDto } from 'src/app/common-modules/dependencies/alarms/profile-configuration.dto';
import { ProfileResultDto } from 'src/app/common-modules/dependencies/alarms/profile-result.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from 'src/app/common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from 'src/app/common-modules/redux/models/state-widget-settings';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { BaseResponsiveWidgetComponent } from 'src/app/common-modules/shared/component/base-responsive-widget.component';
import { ConstantsValues } from 'src/app/common-modules/shared/constants/constants-values';
import { IElementSize } from 'src/app/common-modules/shared/model/element-size';
import { RightPanelService } from 'src/app/common-modules/shared/navigation/right-panel.service';
import { ChartType } from 'src/app/common-modules/wlm-charts/core/models/chart-type.enum';
import { TrendChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { TrendChartSettings } from 'src/app/common-modules/wlm-charts/core/models/trend-chart-settings';
import { TrendChartComponent } from 'src/app/common-modules/wlm-charts/core/trend-chart/trend-chart/trend-chart.component';
import { AuthorizeService } from '../../../../common-modules/shared/auth/services/authorize.service';
import { AlarmsService } from '../../../../common-modules/shared/services/alarms.service';

const COMPONENT_SELECTOR = 'wlm-profile-configuration';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './profile-configuration.component.html',
  styleUrls: ['./profile-configuration.component.scss'],
})
export class ProfileConfigurationComponent extends BaseResponsiveWidgetComponent implements OnInit {
  @ViewChild(TrendChartComponent) public trendChart: TrendChartComponent;
  dimensionToCalculate: 'height' | 'width' = 'height';

  exportTooltipPath = `${AppModules.WlmCharts}.wlm-time-selector-chart`;

  pageId: string;

  profileId: string;
  defaultModel: ProfileConfigurationDto;

  chartLoading = false;
  formLoading = false;

  chartSettings: TrendChartSettings;
  profileConfig$: Subscription;
  disabled = false;
  elementName: string;

  private readonly _defaultButtonBarHeight = 36;

  get componentName(): string {
    return 'ProfileConfigurationComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _alarmsService: AlarmsService,
    private readonly _rightPanelService: RightPanelService,
    private readonly _authService: AuthorizeService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initWidgetResponsive();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.pageId = parameters.parameters.get(TabDetailParameterName.pageId);
    this.elementName = parameters.parameters.get(TabDetailParameterName.elementName);

    const profileId = parameters.parameters.get(TabDetailParameterName.profileConfiguration);
    this.profileId = profileId;

    this.profileConfig$?.unsubscribe();
  }

  init(): void {
    if (!this.profileId || this.profileId === ConstantsValues.emptyGuid) {
      this.resetComponent();
      return;
    }

    this.profileConfig$ = this._alarmsService
      .getProfileConfig(this.profileId)
      .pipe(untilDestroyed(this))
      .subscribe((config) => {
        this.defaultModel = config;
      });

    this.chartSettings = this.buildChartSettings({
      profileIds: [this.profileId],
    });

    this._authService.canAccess('WLMAlarmsCrud', 'u').subscribe((alarmPermission) => {
      this.disabled = !alarmPermission;
    });
  }

  private buildChartSettings(queryParams): TrendChartSettings {
    return new TrendChartSettings({
      dataParameters: new TrendChartDataParameters({
        dataService: 'ProfilesConfigurationChartService',
        queryParams,
        startDate: null,
        endDate: null,
      }),
      chartType: ChartType.trend,
      exportFileName: this.elementName,
    });
  }

  onConfigSave({ result, hasToReload = true }): void {
    this.chartSettings = this.buildChartSettings({
      profileResults: [result as ProfileResultDto],
    });

    const map = new Map<any, string>();
    if (hasToReload) {
      this._rightPanelService.rightPanelCallback.next(
        map.set(ProfileConfigurationComponent, 'refresh')
      );
    }
  }

  onChartLoading(isLoading: boolean): void {
    this.chartLoading = isLoading;
  }

  onFormLoading(isLoading: boolean): void {
    this.formLoading = isLoading;
  }

  exportChart() {
    this.trendChart?.exportChart();
  }

  private resetComponent() {
    this.defaultModel = null;
    this.chartSettings = null;

    this.loading = false;
    this.chartLoading = false;
    this.formLoading = false;
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [
      of({
        height: this._defaultButtonBarHeight,
        width: null,
      }),
    ];
  }
}
