<wlm-page-wrapper [titleKey]="T_SCOPE + '.title'">
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="canLoad"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template wlmGridButtonsThirdSlot let-isContainerCollapsed="isContainerCollapsed">
        <wlm-nav-dropdown
          class="nav-dropdown"
          [isContainerCollapsed]="isContainerCollapsed"
          [navigationsItems]="navigations"
        ></wlm-nav-dropdown>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-filters>
    <wlm-activity-grid-filter
      *ngIf="configReady"
      [activityFilterConfiguration]="activityGridFilterConfiguration"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (filtersDetailParametersChanged)="getFiltersDetailsParameters($event)"
      (apply)="loadGrid()"
      (autoLoad)="onCheckAutoload()"
    ></wlm-activity-grid-filter>
  </ng-container>
  <ng-container page-content>
    <wlm-generic-grid
      #genericGrid
      class="wtr-fx-flex-grow generic-grid"
      [persistencyArea]="persistencyArea"
      [usePersistence]="useGridPersistence"
      [gridSettings]="gridSettings"
      [gridFiltersForBinding]="gridFiltersForBinding"
      (selectedItemChanged)="getSelectedActivity($event)"
      [(selectedItems)]="selectedActivities"
    ></wlm-generic-grid>
  </ng-container>
</wlm-page-wrapper>
