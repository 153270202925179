<mat-form-field appearance="fill">
  <mat-label class="filter-title">{{ T_SCOPE + '.label' | translate }}</mat-label>
  <mat-select
    class="activity-picker"
    [formControl]="activityTypesForm"
    [ngModel]="selectedActivities"
    multiple
  >
    <mat-option *ngFor="let activityType of activityTypes" [value]="activityType.id">{{
      activityType?.name
    }}</mat-option>
  </mat-select>
</mat-form-field>
