import { Injectable } from '@angular/core';
import { asEnumerable } from 'linq-es2015';
import { BaseFiltrableItemService } from 'src/app/common-modules/shared/charts/base-filtrable-item.service';
import { DateHelperService } from 'src/app/common-modules/shared/helpers/date-helper.service';
import { EstimatedEditedEnum } from 'src/app/common-modules/shared/model/algorithm/estimated-edited.enum';
import {
  ISerieDataPointDto,
  SerieDataPointDto,
} from 'src/app/common-modules/shared/model/chart/serie-data-point.dto';
import { IFiltrableItemQueryDto } from 'src/app/common-modules/shared/model/filtrable-items/filtrable-item-query.dto';
import { FiltrableItemTypeEnum } from 'src/app/common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { MVChartSerieDto } from 'src/app/common-modules/shared/model/mv/mv-chart-serie.dto';
import { EventChartQueryDto } from 'src/app/common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { SignalFiltrableItemQueryDto } from '../signal-filtrable-item-query.dto';
import { SignalFiltrableItemDto } from '../signal-filtrable-item.dto';
import { SignalFiltrableType } from '../types/signal-filtrable-type';

@Injectable({
  providedIn: 'root',
})
export class SignalFiltrableItemService extends BaseFiltrableItemService {
  readonly type: FiltrableItemTypeEnum = FiltrableItemTypeEnum.Signal;

  constructor(private readonly _dateHelperService: DateHelperService) {
    super();
  }

  setDateRange(filtrableItem: SignalFiltrableItemDto, startDate: Date, endDate: Date): void {
    filtrableItem.startDate = startDate;
    filtrableItem.endDate = endDate;
  }

  getQuery(filtrableItem: SignalFiltrableItemDto): IFiltrableItemQueryDto {
    const { startDate, endDate, filtrableType } = filtrableItem;

    return new SignalFiltrableItemQueryDto(
      new Date(startDate),
      this._dateHelperService.addDays(new Date(endDate), 1),
      'mv/chart',
      [filtrableType.signalId]
    );
  }

  getDataPoints(filtrableType: SignalFiltrableType, data: MVChartSerieDto[]): ISerieDataPointDto[] {
    const serieDataPoints = asEnumerable(data)
      .Where((x) => x.signalId == filtrableType.signalId)
      .SelectMany((mv) =>
        mv.points.map(
          (p) =>
            new SerieDataPointDto(
              mv.signalId.toString(),
              p.measureTimestamp,
              p.value,
              p.estimatedEdited === EstimatedEditedEnum.Estimated,
              p.estimatedEdited === EstimatedEditedEnum.Edited,
              p.validity,
              p.estimatedEdited === EstimatedEditedEnum.Adjusted
            )
        )
      )
      .ToArray();

    return serieDataPoints;
  }

  getEventQuery(filtrableItem: SignalFiltrableItemDto): EventChartQueryDto {
    const { startDate, endDate, filtrableType } = filtrableItem;

    return new EventChartQueryDto({
      signalIds: [filtrableType.signalId],
      endDate: endDate,
      startDate: startDate,
      categories: [],
    });
  }
}
