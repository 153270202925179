import { DynamicFormSourceSettings } from './dynamic-form-source-settings';
import { FieldsByCategory } from './fields-by-category';

/** This model can either receive the fields to fetch the settings or the fieldsByCategory directly. */

export class DynamicFormSettings extends DynamicFormSourceSettings {
  // If receiving fieldsByCategory directly, do not use the previous attributes.
  fieldsByCategory?: FieldsByCategory;

  saveOnChanges? = false;

  constructor(init: DynamicFormSettings) {
    super(init);
    Object.assign(this, init);
  }
}
