import { Action, State } from '@ngrx/store';

export enum SyncStateActionTypes {
  UpdateState = '[Sync State] Update State',
}

/**
 * Action for updating the whole state after a sync event. Does not extend GenericAction
 * because is not a scoped action defined in a Store Service.
 */
export class UpdateStateGlobalAction implements Action {
  type = SyncStateActionTypes.UpdateState;
  payload: State<any>;

  constructor(payload: State<any>) {
    this.payload = payload;
  }
}
