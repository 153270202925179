<h3>{{ title }}</h3>
<hr class="title-separator" />
<mat-dialog-content>
  <div class="notif-d-textarea-container wtr-textarea-editor">
    <textarea disabled class="notif-d-textarea" [innerHTML]="text | safeHtml"></textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons-container">
    <button mat-raised-button class="mat-raised-button" (click)="close()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
