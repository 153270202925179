<wlm-ne-configuration-boundary
  *ngIf="isHierarchyElement"
  [selectedNE]="selectedNE"
  class="bnb-item"
  [widgetId]="widgetId"
  [pageId]="pageId"
  (hasChangesEvent)="onHasChanges($event)"
></wlm-ne-configuration-boundary>
<wlm-ne-configuration-no-boundary
  *ngIf="!isHierarchyElement && noBoundarySettings"
  [selectedNE]="selectedNE"
  class="bnb-item"
  [widgetId]="widgetId"
  [pageId]="pageId"
  [noBoundarySettings]="noBoundarySettings"
  (hasChangesEvent)="onHasChanges($event)"
></wlm-ne-configuration-no-boundary>
