<div class="events-container" *ngIf="!loading && dataLoaded">
  <wlm-generic-chart
    *ngIf="customSettings"
    [settings]="customSettings"
    [ngStyle]="{ height: chartHeigth + 'px' }"
    [disableFixedSize]="false"
    [height]="chartHeigth"
    (chartClickEvent)="onElementClick($event)"
    (chartDataZoom)="onChartDataZoom($event)"
  >
  </wlm-generic-chart>
</div>

<wlm-no-results [showNoResult]="!loading && (!dataLoaded || !customSettings)"></wlm-no-results>
