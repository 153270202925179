<kendo-grid-column
  [title]="title"
  [field]="field"
  [style]="style"
  [width]="width"
  [locked]="locked"
  matTooltipDisabled="true"
>
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="dots-container">
      <span class="dot" [style.background-color]="getSeverityColor(dataItem)"></span>
    </div>
  </ng-template>
  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-enumerable-column-filter
      [field]="column.field"
      [filterService]="filterService"
      [filter]="filter"
      [elements]="elements"
    ></wlm-enumerable-column-filter>
  </ng-template>
</kendo-grid-column>
