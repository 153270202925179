<div *ngIf="selectedNE" class="boundary-container" cdkDropListGroup>
  <div *ngIf="!isReadOnly" class="boundary-column">
    <div class="boundary-group-available">
      <div class="ne-component-title top-title-position">
        {{ T_SCOPE + '.available-title' | translate }}
      </div>
      <wlm-drag-list-virtual
        class="drag-list-virtual"
        [settings]="settings"
        [queryParams]="queryParams"
        [refreshList$]="refreshList$"
        [(excludeList)]="excludedSignals"
        (somethingChanged)="compareSignals()"
        [reloadList$]="reloadList$"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card [item]="rowDetail" [settings]="cardSettings"></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-virtual>
    </div>
  </div>
  <div class="boundary-column data-column-position">
    <div class="boundary-button-group">
      <button
        [disabled]="!configurationHasChanged"
        mat-icon-button
        *hasPerm="['WLMNetworkCrud', 'u']"
        (click)="onSave()"
        color="primary"
        [matTooltip]="T_SCOPE + '.save-button-tooltip' | translate | lowercase"
      >
        <wlm-icon [icon]="'save'"></wlm-icon>
      </button>
      <button
        [disabled]="!configurationHasChanged"
        *hasPerm="['WLMNetworkCrud', 'u']"
        mat-icon-button
        (click)="discard()"
        color="primary"
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>
    <div class="boundary-group" *ngIf="noBoundarySettings.hasBoundaryIn">
      <div class="ne-component-title bottom-title-position">
        {{ T_SCOPE + '.boundary-in-title' | translate }}
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="signalsIn"
        [settings]="settingsCustom"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <div class="boundary-group" *ngIf="noBoundarySettings.hasBoundaryOut">
      <div class="ne-component-title bottom-title-position">
        {{ T_SCOPE + '.boundary-out-title' | translate }}
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="signalsOut"
        [settings]="settingsCustom"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
    <div class="boundary-group" *ngIf="noBoundarySettings.hasNoBoundary">
      <div class="ne-component-title bottom-title-position">
        {{ T_SCOPE + '.no-boundary-title' | translate }}
      </div>
      <wlm-drag-list-custom
        class="drag-list"
        [objectsToOrder]="signalsNo"
        [settings]="settingsCustom"
        (somethingChanged)="compareSignals()"
        (droppedElement)="onDroppedElement($event)"
      >
        <ng-template let-rowDetail #card>
          <wlm-drag-list-card
            class="card-width"
            [item]="rowDetail"
            [settings]="cardSettings"
          ></wlm-drag-list-card>
        </ng-template>
      </wlm-drag-list-custom>
    </div>
  </div>
</div>
