<div *ngIf="selectedPoint">
  <wlm-data-validation-value-ranges
    [valueRanges]="valueRanges"
    [showCart]="valueCartToggle"
    (valueRangesChanged)="onValueRangesChanged($event)"
    (applyValueSelection)="onApplySelection($event)"
  ></wlm-data-validation-value-ranges>

  <wlm-data-validation-date-ranges
    [minDate]="chartStartDate"
    [maxDate]="chartEndDate"
    [dateRanges]="dateRanges"
    [showCart]="dateCartToggle"
    [selectedPoint]="selectedPoint"
    (dateRangesChanged)="onDateRangesChanged($event)"
    (applyDateSelection)="onApplySelection($event)"
  ></wlm-data-validation-date-ranges>
</div>
