import { Injectable, Injector, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { GlobalSettingsService } from '../shared/global-settings/global-settings.service';
import { WlmGlobalSettings } from '../shared/global-settings/wlm-global-settings.enum';

@Injectable()
export class InterfacesService extends BaseService {
  private _globalSettingsService = inject(GlobalSettingsService);

  constructor(injector: Injector) {
    super(injector);
  }

  getInterfaceTypes(): Observable<any> {
    return this.httpCacheClient.get(`${this.apiUrl}/settings/Interfaces/Types`, { avoid: true });
  }

  getInterfaces(): Observable<any> {
    return this._globalSettingsService.load(WlmGlobalSettings.Interfaces);
  }

  getInterfaceGis(): Observable<any> {
    return this._globalSettingsService.load(WlmGlobalSettings.InterfaceGis);
  }

  getInterfacesSchema(): Observable<any> {
    return this._globalSettingsService.load(WlmGlobalSettings.InterfacesSchema);
  }

  getInterfaceGisSchema(): Observable<any> {
    return this._globalSettingsService.load(WlmGlobalSettings.InterfacesGisSchema);
  }

  getInterfacesValidation(): Observable<any> {
    return this._globalSettingsService.load(WlmGlobalSettings.InterfacesValidation);
  }

  saveInterfaces(interfaces: string | any): Observable<void> {
    const data = typeof interfaces === 'string' ? JSON.parse(interfaces) : interfaces;
    return this._globalSettingsService.save(WlmGlobalSettings.Interfaces, data);
  }
}
