<div id="center-page">
  <div class="filter-buttons-container">
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [(gridSettings)]="gridSettings"
      [gridFilters]="gridFilters"
      [grid]="genericGrid"
      [gridName]="gridName"
      [canLoad]="true"
      (btnClick)="onClickGridBtns($event)"
    >
    </wlm-grid-buttons>
  </div>
  <div id="center-container">
    <wlm-telemetry-filter
      *ngIf="telemetryFilterConfiguration"
      [telemetryFilterConfiguration]="telemetryFilterConfiguration"
      [storageLocation]="telemetryFilterConfiguration.storageLocation"
      [clearAll$]="clearAll$"
      [persistFilters$]="persistFilters$"
      (filtersChanged)="getDataBindingFilters($event)"
      (apply)="loadGrid()"
    ></wlm-telemetry-filter>
    <wlm-generic-grid
      class="generic-grid"
      *ngIf="gridSettings"
      [gridSettings]="gridSettings"
      [usePersistence]="false"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [removeSelection$]="removeSelection$"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      [(selectedItems)]="selectedPoints"
      (isGridReady)="onIsGridReady($event)"
    ></wlm-generic-grid>
  </div>
</div>
