<div class="cart-group-container">
  <div *ngIf="title" class="cart-group-title top-title-position">{{ title }}</div>
  <div class="cart-group-items">
    <div class="list" *ngFor="let item of items; let i = index">
      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-container>
    </div>
  </div>
</div>
