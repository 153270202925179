<div id="center-page">
  <div *ngIf="groupGridSetting" class="top-container">
    <h3>{{ T_SCOPE + '.title' | translate }}</h3>
    <div class="filter-buttons-container">
      <button
        mat-icon-button
        class="button-position mat-icon-no-overlay"
        [disabled]="!groupsGrid?.canClearFilters()"
        (click)="groupsGrid?.clearFilters()"
        [matTooltip]="'common.clear-filter' | translate | lowercase"
      >
        <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
      </button>
    </div>
  </div>
  <hr class="separator" />

  <div id="center-container" class="groups-popup-container">
    <wlm-generic-grid
      class="generic-grid"
      *ngIf="gridFiltersForBinding"
      [additionalFilters]="additionalFilters"
      [gridSettings]="groupGridSetting"
      [gridFiltersForBinding]="gridFiltersForBinding"
      [(selectedItems)]="selectedGroups"
      [subscriptionTag]="groupGridSettingsName"
      [showSelectedList]="true"
      [showSelectedItems]="false"
    ></wlm-generic-grid>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="saveGroupUsers()"
        [disabled]="!selectedGroups?.length || isSaving"
      >
        {{ 'common.save' | translate }}
      </button>

      <button mat-raised-button [disabled]="isSaving" (click)="cancel()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </div>
</div>
