export class SignalTelemetryDto {
  signalId?: number;
  unitTypeId: string;
  sourceSystemId?: number;
  pointId: string;
  pointDescription: string;
  isPointOfInterest: boolean;
  dimensionTypeId?: number;

  constructor(init: SignalTelemetryDto) {
    Object.assign(this, init);
  }
}
