import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';

// prettier-ignore

import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import {
  CT_DATES_MAP_REQ,
  CT_DATES_MAP_RES,
  CT_DATES_REQ_NO_UTC,
  CT_DATES_RES_STR,
  CT_GLOBAL_SPINNER,
  CT_HTTP_CACHE,
  CT_HTTP_CACHE_ABSOLUTE_EXPIRATION,
  CT_HTTP_CACHE_DURATION,
  CT_HTTP_CACHE_RELOAD,
  DEFAULT_CACHE_ABSOLUTE_EXPIRATION,
  DEFAULT_CACHE_DURATION,
} from '../../shared/interceptors/interceptor-context-tokens';
import { LogScope } from '../../shared/wlm-log/log-scope';
import { LogService } from '../../shared/wlm-log/log.service';
import { AdditionalHttpOpts } from './additional-http-options';

export type DurationOpts = { reload?: boolean; minutes: number | 'default' };
export type AbsoluteOpts = { reload?: boolean; expiration: Date | 'default' };
export type AvoidCacheOpts = { avoid: boolean };
export type GetCacheOpts = DurationOpts | AbsoluteOpts | AvoidCacheOpts;

@Injectable({ providedIn: 'root' })
export class HttpCacheClient {
  cacheDuration = DEFAULT_CACHE_DURATION; // Duration In minutes
  cacheAbsoluteExpiration: Date = DEFAULT_CACHE_ABSOLUTE_EXPIRATION; // Tomorrow 2AM;
  constructor(
    private client: HttpClient,
    private _logService: LogService,
    private _objectHelper: ObjectHelperService
  ) {}

  get<T>(
    url: string,
    opts: GetCacheOpts = { avoid: true },
    params: any = null,
    addHttpOptions = new AdditionalHttpOpts({})
  ) {
    const config = this.getConfig(opts, addHttpOptions, url);
    this.handleCustomWarnings(params, addHttpOptions);
    return this.client.get<T>(url, { ...config, params });
  }

  getWithOpts<T>(data: {
    url: string;
    opts?: GetCacheOpts;
    params?: any;
    additionalOpts?: AdditionalHttpOpts;
  }) {
    const { url, params } = data;
    const opts = data.opts ?? { avoid: true };
    const additionalOpts = data.additionalOpts ?? new AdditionalHttpOpts({});

    return this.get<T>(url, opts, params, additionalOpts);
  }

  post<T>(
    url: string,
    body: any,
    opts: GetCacheOpts = { avoid: true },
    addHttpOptions = new AdditionalHttpOpts({})
  ) {
    const config = this.getConfig(opts, addHttpOptions);

    this.handleCustomWarnings(body, addHttpOptions);
    return this.client.post<T>(url, body, config);
  }

  put<T>(
    url: string,
    body: any,
    opts: GetCacheOpts = { avoid: true },
    addHttpOptions = new AdditionalHttpOpts({})
  ) {
    const config = this.getConfig(opts, addHttpOptions);
    this.handleCustomWarnings(body, addHttpOptions);
    return this.client.put<T>(url, body, config);
  }

  patch<T>(
    url: string,
    body: any,
    opts: GetCacheOpts = { avoid: true },
    addHttpOptions = new AdditionalHttpOpts({})
  ) {
    const config = this.getConfig(opts, addHttpOptions);
    this.handleCustomWarnings(body, addHttpOptions);
    return this.client.patch<T>(url, body, config);
  }

  delete<T>(
    url: string,
    opts: GetCacheOpts = { avoid: true },
    params: any = null,
    addHttpOptions = new AdditionalHttpOpts({}),
    body: any = null
  ) {
    const config = this.getConfig(opts, addHttpOptions);
    return this.client.delete<T>(url, { ...config, params, body });
  }

  getConfig(opts: GetCacheOpts, addHttpOptions: AdditionalHttpOpts, url?: string) {
    const context = new HttpContext();

    this.addCacheContextTokens(context, opts);

    if (addHttpOptions?.showSpinner) {
      context.set(CT_GLOBAL_SPINNER, true);
    }

    if (addHttpOptions) {
      this.setContext(context, CT_DATES_MAP_REQ, addHttpOptions.mapRequestDates);
      this.setContext(context, CT_DATES_MAP_RES, addHttpOptions.mapResponseDates);
      this.setContext(context, CT_DATES_REQ_NO_UTC, addHttpOptions.requestDatesNoUtc);
      this.setContext(context, CT_DATES_RES_STR, addHttpOptions.responseDatesKeepString);
    }

    let responseType;

    if (addHttpOptions?.responseType) {
      responseType = addHttpOptions?.responseType;
    }

    if (url?.endsWith('.svg')) {
      responseType = 'text';
    }

    return {
      context,
      responseType,
    };
  }

  private addCacheContextTokens(context: HttpContext, opts: GetCacheOpts): void {
    if ((opts as AvoidCacheOpts)?.avoid) {
      context.set(CT_HTTP_CACHE, false);
      return;
    }

    context.set(CT_HTTP_CACHE, true);

    const absolute = opts as AbsoluteOpts;

    if (absolute?.expiration) {
      const expiration =
        absolute.expiration === 'default' ? this.cacheAbsoluteExpiration : absolute.expiration;

      context.set(CT_HTTP_CACHE_ABSOLUTE_EXPIRATION, expiration.toUTCString());

      if (absolute.reload) {
        context.set(CT_HTTP_CACHE_RELOAD, true);
      }
    } else {
      const duration = opts as DurationOpts;

      const minutes =
        duration?.minutes === 'default'
          ? this.cacheDuration
          : duration?.minutes ?? this.cacheDuration;

      if (duration?.reload) {
        context.set(CT_HTTP_CACHE_RELOAD, true);
      }

      context.set(CT_HTTP_CACHE_DURATION, minutes);
    }
  }

  private handleCustomWarnings(body: any, addHttpOptions: AdditionalHttpOpts): void {
    if (addHttpOptions && addHttpOptions.warnIfMissing) {
      addHttpOptions.warnIfMissing.forEach((fieldPath) => {
        const value = this._objectHelper.deepGet(body, fieldPath);
        if (typeof value === 'undefined' || value === null) {
          this._logService.warn({
            msg: `Property ${fieldPath} is not set. Either it is optional, or the HTTP request is misconfigured.`,
            scope: LogScope.HttpRequest,
          });
        }
      });
    }
  }

  // Angular 12 feature.
  private setContext<T>(context: HttpContext, token: HttpContextToken<T>, value: T): void {
    if (typeof value !== 'undefined') {
      context.set(token, value);
    }
  }
}
