import { Component, Input, OnInit } from '@angular/core';

const COMPONENT_SELECTOR = 'wlm-logs-system-message';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-system-message.component.html',
  styleUrls: ['./logs-system-message.component.scss'],
})
export class LogsSystemMessageComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}
