export enum IntegrationWizardSteps {
  AppAttributes,
  HierarchyElementTypes,
  NetworkElementTypes,
  CustomerClassTypes,
  BillingClasses,
  MaterialTypes,
  ActivityTypes,
  SapCodeGroups,
  SapCodes,
  LeakTypeGroups,
  LeakTypes,
  FamiliesAndRelations,
  GisLayers,
}
