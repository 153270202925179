import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
// IMPORTANT: This should always be imported from material, not core.
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'wlm-switcher-type',
  templateUrl: './switcher-type.component.html',
  styleUrl: './switcher-type.component.scss',
})
export class SwitcherTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  isChecked: boolean;
  private readonly _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      this.isChecked = value;
    });
  }

  onChanged(value: MatSlideToggleChange): void {
    this.formControl.setValue(value.checked);
    this.formControl.markAsDirty();
  }
}
