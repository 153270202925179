import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { WLMDialogResult } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from 'src/app/common-modules/shared/model/dialog/wlm-dialog-setting';
import { CalendarDayOfWeekExceptionDto } from '../../models/calendar-day-of-week-exception.dto';
import { CalendarExceptionCardDto } from '../../models/calendar-exception-card.dto';
import { CalendarIntervalOfMonthExceptionDto } from '../../models/calendar-interval-of-month-exception.dto';
import { CalendarIntervalOfYearExceptionDto } from '../../models/calendar-interval-of-year-exception.dto';
import { CalendarSingleIntervalExceptionDto } from '../../models/calendar-single-interval-exception.dto';
import { DeleteCalendarExceptionDto } from '../../models/delete-calendar-exception.dto';
import { CalendarExceptionTypesEnum } from '../../models/exception-list.enum';
import { CalendarService } from '../../service/calendar.service';

const COMPONENT_SELECTOR = 'wlm-calendar-exception-detail';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-exception-detail.component.html',
  styleUrls: ['./calendar-exception-detail.component.scss'],
})
export class CalendarExceptionDetailComponent implements OnInit {
  @ContentChild('card', { static: false }) cardTemplateRef: TemplateRef<any>;
  private _calendarId: string;
  get calendarId(): string {
    return this._calendarId;
  }
  @Input() set calendarId(value: string) {
    this._calendarId = value;
    this.loadExceptions(value);
  }
  @Input() reloadDetails$: Observable<void>;

  @Output() isLoading = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  T_SCOPE_CREATOR = `${AppModules.Configuration}.wlm-calendar-exception-creator`;
  form: FormGroup;

  calendarExceptionList: CalendarExceptionCardDto[] = [];
  exceptionTypesMap: { [key: number]: string } = {};

  constructor(
    private _formBuilder: FormBuilder,
    private _calendarService: CalendarService,
    private _localization: LocalizationHelperService,
    private _dialogService: DialogService
  ) {
    this.createForm();
    this.exceptionTypesMap[CalendarExceptionTypesEnum.DaysOfWeek] = 'daily';
    this.exceptionTypesMap[CalendarExceptionTypesEnum.Month] = 'monthly';
    this.exceptionTypesMap[CalendarExceptionTypesEnum.Yearly] = 'yearly';
    this.exceptionTypesMap[CalendarExceptionTypesEnum.Single] = 'fixed';
  }

  ngOnInit(): void {
    this.reloadDetails$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.calendarId) {
        this.loadExceptions(this.calendarId);
      }
    });
  }

  private createForm() {
    const formControls: { [key: string]: FormControl } = {};
    this.form = this._formBuilder.group(formControls);
  }

  private loadExceptions(calendarId: string) {
    this.isLoading.emit(true);
    const calendarExceptions$ = this._calendarService.getCalendarExceptions(calendarId);
    const ts$ = this._localization.get(this.T_SCOPE_CREATOR);

    combineLatest([calendarExceptions$, ts$])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([calendarExceptions, ts]) => {
          this.calendarExceptionList = [];

          calendarExceptions.forEach((exception) => {
            const newException = new CalendarExceptionCardDto({
              calendarId: exception.calendarId,
              exceptionId: exception.exceptionId,
              title: ts.labels[this.exceptionTypesMap[exception.type]],
              type: exception.type,
            });

            let exceptionCast;
            switch (exception.type) {
              case CalendarExceptionTypesEnum.DaysOfWeek:
                exceptionCast = exception as CalendarDayOfWeekExceptionDto;
                newException.value = ts.days[exceptionCast.dayOfWeek];
                break;

              case CalendarExceptionTypesEnum.Single:
                exceptionCast = exception as CalendarSingleIntervalExceptionDto;
                const from = new Date(
                  exceptionCast.startYear,
                  exceptionCast.startMonth - 1,
                  exceptionCast.startDay
                );
                const to = new Date(
                  exceptionCast.endYear,
                  exceptionCast.endMonth - 1,
                  exceptionCast.endDay
                );

                newException.value = `${this._localization.formatDate(
                  from,
                  DateFormats.Date
                )} - ${this._localization.formatDate(to, DateFormats.Date)}`;

                break;

              case CalendarExceptionTypesEnum.Month:
                exceptionCast = exception as CalendarIntervalOfMonthExceptionDto;
                newException.value = `${exceptionCast.startDay} - ${exceptionCast.endDay}`;
                break;

              case CalendarExceptionTypesEnum.Yearly:
                exceptionCast = exception as CalendarIntervalOfYearExceptionDto;
                newException.value = `${exceptionCast.startDay}/${
                  ts.months[exceptionCast.startMonth]
                } - ${exceptionCast.endDay}/${ts.months[exceptionCast.endMonth]}`;
                break;
              default:
                break;
            }

            this.calendarExceptionList.push(newException);
          });
          this.isLoading.emit(false);
        },
        error: (error) => {
          this.isLoading.emit(false);

          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.load-exception-error`,
              icon: 'error',
            })
          );
        },
      });
  }

  deleteCalendarExceptionHandler = (exception: CalendarExceptionCardDto): void => {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.confirm-delete-exception`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.isLoading.emit(true);

          this._calendarService
            .deleteCalendarException(
              new DeleteCalendarExceptionDto({
                calendarId: exception.calendarId,
                exceptionId: exception.exceptionId,
                type: exception.type,
              })
            )
            .pipe(untilDestroyed(this))
            .subscribe({
              next: (result) => {
                this._dialogService.showTranslatedMessageInSnackBar(
                  new WlmDialogSettings({
                    translateKey: `${this.T_SCOPE}.messages.delete-exception-success`,
                    icon: 'success',
                  })
                );
                this.loadExceptions(this.calendarId);
                this.isLoading.emit(false);
              },
              error: (error) => {
                this.isLoading.emit(false);
                this._dialogService.showTranslatedMessageInSnackBar(
                  new WlmDialogSettings({
                    translateKey: `${this.T_SCOPE}.messages.delete-exception-error`,
                    icon: 'error',
                  })
                );
              },
            });
        }
      });
  };
}
