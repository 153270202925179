import { InjectionToken, Type } from '@angular/core';
import {
  ComponentContainer,
  ComponentItemConfig,
  EventEmitter,
  LayoutConfig,
  RowOrColumnItemConfig,
  StackItemConfig,
} from 'golden-layout';
import { GoldenLayoutNode } from '../dynamic-layout/models/golden-layout-node';
import { BaseDynamicWidgetComponent } from '../redux/components/base-dynamic-widget.component';

// Seach for "New_GL_Version" to check all migration changes.

export interface IGoldenLayoutV2 {
  rootItem: GoldenLayoutNode;
  layoutConfig: any;
  updateRootSize(force?: boolean): void;
  saveLayout(): any;
}

export interface IGoldenLayout extends IGoldenLayoutV2 {
  // This "on" fn has v2 types but it is retrocompatible with v1
  on<K extends keyof GLEventParamsMap>(eventName: K, callback: EventEmitter.Callback<K>): void;
}

export type GLEventParamsMap = EventEmitter.EventParamsMap;

export interface IGoldenLayoutComponent {
  getGoldenLayoutInstance(): IGoldenLayout;
  // Only in v2
  initialize(): void;
  loadLayout(layout: NativeGoldenLayoutConfig): void;
  get isSubWindow(): boolean;
}

export type NativeGoldenLayoutConfig = LayoutConfig;

type ItemConfigTypeV2 = RowOrColumnItemConfig | StackItemConfig | ComponentItemConfig;
export type NativeGLItemConfigType = ItemConfigTypeV2;

export type NativeGLComponentItemConfig = ComponentItemConfig;

export type NativeGLStackItemConfig = StackItemConfig;

const GLContainerTokenName = 'GoldenLayoutContainer';
export const GLContainerInjectionToken = new InjectionToken<ComponentContainer>(
  GLContainerTokenName
);

export const detachedLayoutQueryParam = 'gl-window';

export class NativeGLRenderedComponentNode {
  state;
  instanceId;
}

export type WidgetRegisterData = {
  instanceKey: string;
  name: string;
  type: Type<BaseDynamicWidgetComponent>;
};

// In new versions of golden layout, ComponentItemConfig must use componentType instead of componentName.

/**
 * The type of the component.
 * @deprecated use {@link (ComponentItemConfig:interface).componentType} instead
 */
// componentName?: string;
/**
 * The type of the component.
 * `componentType` must be of type `string` if it is registered with any of the following functions:
 * * {@link (GoldenLayout:class).registerComponent} (deprecated)
 * * {@link (GoldenLayout:class).registerComponentConstructor}
 * * {@link (GoldenLayout:class).registerComponentFactoryFunction}
 */
//componentType: JsonValue;
