import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionalHttpOpts } from 'src/app/common-modules/cache/http-cache/additional-http-options';
import { HttpCacheClient } from 'src/app/common-modules/cache/http-cache/http-cache.client';
import { INetworkElementDto } from 'src/app/common-modules/dependencies/ne/network-element.dto';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { ObjectHelperService } from 'src/app/common-modules/shared/helpers/object-helper.service';
import { DateFormats } from 'src/app/common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from 'src/app/common-modules/shared/localization/localization-helper.service';
import { HistoricalChartDataParameters } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartResultSettings } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalEvent } from 'src/app/common-modules/wlm-charts/core/models/historical-chart-settings/historical-event';
import {
  BaseHistoricalChartService,
  Translations,
} from 'src/app/common-modules/wlm-charts/core/services/base-historical-chart.service';
import { SaveSmartMeterSignalVersionResponseDto } from '../models/save-smart-meter-signal-version-response-dto';
import { SmartMeterConfigDto } from '../models/smart-meter-config.dto';
import { SmartMeterSignalVersionDto } from '../models/smart-meter-signal-version-dto';
import { SmartMeterSignalVersionOperationDto } from '../models/smart-meter-signal-version-operation';
import { SmartMeterSignalVersionSaveDto } from '../models/smart-meter-signal-version-save-dto';

@Injectable()
export class SmartMetersHistoricalService extends BaseHistoricalChartService {
  protected get url() {
    return `${this.apiUrl}/smart-meter/historical`;
  }

  private readonly _seriesInUtc = false;
  readonly T_SCOPE = `${AppModules.SmartMeters}.historical`;

  constructor(
    injector: Injector,
    private _localizationService: LocalizationHelperService,
    private _http: HttpCacheClient,
    private _objectHelper: ObjectHelperService
  ) {
    super(injector);
  }

  saveHistoricalConfiguration(
    operations: SmartMeterSignalVersionOperationDto[],
    currentConfigurations: SmartMeterSignalVersionDto[],
    originalConfigurations: SmartMeterSignalVersionDto[],
    element: INetworkElementDto
  ): Observable<SaveSmartMeterSignalVersionResponseDto> {
    const signals = operations
      .filter((x) => x.originalWasCurrent)
      .map((x) => this.getCurrentFromHistorical(x.originalConfiguration));
    const trackedDates = this.getDaysToRecalculate(currentConfigurations, originalConfigurations);
    const trackedDatesNoUtc = trackedDates.map((date) =>
      this.dateHelperService.toApiFormatNoUTC(date)
    );

    operations.forEach((config) => {
      config.configuration.hierarchyElementId = element.hierarchyElementId;
      config.originalConfiguration.hierarchyElementId = element.hierarchyElementId;
    });

    const saveCmd = new SmartMeterSignalVersionSaveDto({
      smartMeterSignalVersionOperations: operations,
      minDate: this.dateHelperService.toApiFormatNoUTC(this.minDate),
      smartMeterSignals: signals,
      hierarchyElementId: element.hierarchyElementId,
      hierarchyElementName: element.hierarchyElementName ?? element.networkElementName,
      trackedSignals: this.arrayHelperService.onlyUnique(
        operations.map((x) => x.configuration.signalId)
      ),
      trackedDates: trackedDatesNoUtc,
    });

    const operationsPath = 'operations[]';
    const requestDatesNoUtc = [
      'minDate',
      `${operationsPath}.configuration.validFrom`,
      `${operationsPath}.configuration.validTo`,
      `${operationsPath}.originalConfiguration.validFrom`,
      `${operationsPath}.originalConfiguration.validTo`,
      'trackedDates[]',
    ];
    const additionalHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post<SaveSmartMeterSignalVersionResponseDto>(
      `${this.url}`,
      saveCmd,
      this.avoidCache,
      additionalHttpOptions
    );
  }

  cloneConfiguration(configuration: SmartMeterSignalVersionDto): SmartMeterSignalVersionDto {
    const cloned = new SmartMeterSignalVersionDto({ ...configuration });
    return cloned;
  }

  getData(params: HistoricalChartDataParameters): Observable<SmartMeterSignalVersionDto[]> {
    this.setDatesThreshold(params);

    const queryParams = {
      hierarchyElementId: params.queryParams['hierarchyElementId'],
      minDate: this.dateHelperService.toApiFormatNoUTC(params.startDate),
    };

    return this.httpCacheClient
      .get<SmartMeterSignalVersionDto[]>(this.url, this.avoidCache, queryParams)
      .pipe(
        map((x) =>
          x.map((y) => {
            return new SmartMeterSignalVersionDto({
              ...y,
              validFrom: this.preprocessEventDate(y.validFrom.toString()),
              validTo: this.preprocessEventDate(y.validTo.toString()),
            });
          })
        )
      );
  }

  private preprocessEventDate(dateStr: string): Date {
    const isUTC = false;
    const date = this.dateHelperService.fromApiFormat(dateStr, isUTC);
    return date;
  }

  mapDataToGenericSettings(
    data: SmartMeterSignalVersionDto[]
  ): Observable<HistoricalChartResultSettings> {
    const events$ = data.map((versionDto) => {
      const event = this.getEvent(versionDto);
      return event;
    });

    if (events$.length === 0) {
      const settings = new HistoricalChartResultSettings({
        events: [],
        showInUTC: this._seriesInUtc,
      });
      return of(settings);
    }

    return forkJoin(events$).pipe(
      map((events) => {
        const sortedEvents = this._objectHelper.sortObjectArray(events, 'group', 'ascending');
        const settings = new HistoricalChartResultSettings({
          events: sortedEvents,
          showInUTC: this._seriesInUtc,
        });
        return settings;
      })
    );
  }

  getEvent(versionDto: SmartMeterSignalVersionDto): Observable<HistoricalEvent> {
    return this._localizationService.get(this.getTranslationKey()).pipe(
      map((ts) => {
        const additionalParams = {
          ...versionDto,
        };

        const endDate = this.dateHelperService.fromApiFormat(versionDto.validTo.toString());
        const startDate = this.dateHelperService.fromApiFormat(versionDto.validFrom.toString());
        const visibleEndDate = endDate > this.maxDate ? this.maxDate : new Date(endDate);
        const visibleStartDate = startDate < this.minDate ? this.minDate : new Date(startDate);

        const id = versionDto.id;
        const group = this.getGroup(versionDto);
        const label = this.getLabel(versionDto, ts);
        const tooltip = this.getTooltip(versionDto, ts);

        const event = new HistoricalEvent({
          additionalParams,
          endDate,
          startDate,
          visibleEndDate,
          visibleStartDate,
          id,
          group,
          label,
          originalEndDate: new Date(endDate),
          originalStartDate: new Date(startDate),
          tooltip,
        });

        return event;
      })
    );
  }

  saveConfiguration(data: SmartMeterSignalVersionSaveDto): Observable<void> {
    return this._http.post(`${this.url}/save`, data);
  }

  protected getGroup(configuration: SmartMeterSignalVersionDto): string {
    return configuration.customerClassTypeName;
  }

  protected getLabel(config: SmartMeterSignalVersionDto, ts: Translations): string {
    return `${config.pointDescription} ${config.customerClassTypeName}`;
  }

  protected getTooltip(config: SmartMeterSignalVersionDto, ts: Translations): string {
    let startDate;
    if (this.dateHelperService.isCurrentStartDate(config.validFrom)) {
      startDate = (ts.current as any).start;
    } else {
      startDate = this._localizationService.formatDate(config.validFrom, DateFormats.Date);
    }

    let endDate;
    if (this.dateHelperService.isCurrentEndDate(config.validTo)) {
      endDate = (ts.current as any).end;
    } else {
      endDate = this._localizationService.formatDate(config.validTo, DateFormats.Date);
    }

    let tooltip = [
      this.getTooltipRow(ts.pointId, config.pointId),
      this.getTooltipRow(ts.pointDescription, config.pointDescription),
      this.getTooltipRow(ts.customerClassType, config.customerClassTypeName),
      this.getTooltipRow(ts.startDate, startDate),
      this.getTooltipRow(ts.endDate, endDate),
    ];

    return tooltip.join('<br>');
  }

  private getTooltipRow = (header: string, value: string): string =>
    `<strong>${header}:</strong> ${value}`;

  protected getTranslationKey(): string {
    return this.T_SCOPE;
  }

  getCurrentFromHistorical(config: SmartMeterSignalVersionDto): SmartMeterConfigDto {
    if (!config) {
      return null;
    }
    const current: SmartMeterConfigDto = {
      signalId: config.signalId,
      customerClassTypeName: config.customerClassTypeName,
      customerClassTypeId: config.customerClassTypeId,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      hierarchyElementId: config.hierarchyElementId,
    };
    return current;
  }

  configurationsAreEqual<SmartMeterConfigDto>(
    config1: SmartMeterConfigDto,
    config2: SmartMeterConfigDto
  ) {
    return this._objectHelper.deepEqual(config1, config2);
  }

  recalculateDatesGroupBy(configuration: SmartMeterSignalVersionDto): string {
    if (configuration.pointId === undefined) {
      throw new Error('Could not get pointId. Maybe the configuration is not a SmartMeter one?');
    }
    const { pointId, customerClassTypeId } = configuration;
    return [pointId, customerClassTypeId].join('#');
  }
}
