import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RepaceSignalValidator {
  static pointIdValidation(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const pointIdResponse = c.get('pointIdResponse')?.value;
      const pointDestinationControl = c.get('pointIdDestination');
      if (pointIdResponse?.hasErrors) {
        pointDestinationControl.setErrors({ pointReplacement: true });
        return { pointReplacement: true };
      }

      pointDestinationControl.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      return null;
    };
  }
}
