<kendo-grid-column [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>

  <ng-template kendoGridCellTemplate let-dataItem>
    <wlm-button-column-helper
      [title]="title"
      [iconName]="iconName"
      [visibilityMethod]="columnSettings?.buttonVisibleMethodName"
      [clickMethod]="columnSettings?.buttonClickedMethodName"
      [dataItem]="dataItem"
    >
    </wlm-button-column-helper>
  </ng-template>
</kendo-grid-column>
