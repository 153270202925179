import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TabDetailPanelParameters } from 'src/app/common-modules/dependencies/navigation/tab-detail-component';
import { IGridSettings } from 'src/app/common-modules/shared/constants/grid.constants';
import { GridSettingsService } from 'src/app/common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from 'src/app/common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-grid-selector';

type SelectedElement = any;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-selector.component.html',
  styleUrls: ['./grid-selector.component.scss'],
})
export class GridSelectorComponent implements OnInit, OnChanges {
  @ViewChild(GenericGridComponent) gridComponent: GenericGridComponent;

  @Input() filters: Map<string, any>;
  @Input() widgetInstanceKey: string;
  @Input() gridName: string;
  @Input() subscriptionTag = 'default';

  @Output() selectedElementChange = new EventEmitter<SelectedElement>();
  @Output() selectedManyChange = new EventEmitter<SelectedElement[]>();

  private _manySelected: SelectedElement[];
  get manySelected(): SelectedElement[] {
    return this._manySelected;
  }
  set manySelected(value: SelectedElement[]) {
    this._manySelected = value;
    this.selectedManyChange.emit(this.manySelected);
  }
  gridSettings: IGridSettings;

  emptyFilters: DataBindingFilters;

  constructor(private _gridSettingService: GridSettingsService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridName?.currentValue) {
      this.initializeGrid();
    }
  }

  private initializeGrid() {
    if (this.gridName && !this.gridSettings) {
      this.setGridSettings();
    }
    this.emptyFilters = new DataBindingFilters();
    this.gridComponent?.clearFilters();
    this.gridComponent?.reloadGrid();
  }

  private setGridSettings() {
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        this.gridSettings = gridSettings;
      },
    });
  }

  setSelectedElement(selectedElement: SelectedElement): void {
    this.selectedElementChange.emit(selectedElement);
  }

  get componentName(): string {
    return 'GridSelectorComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
