import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from '../../shared/app-modules.enum';

const COMPONENT_SELECTOR = 'wlm-tooltip-extended';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './tooltip-extended.component.html',
  styleUrl: './tooltip-extended.component.scss'
})
export class TooltipExtendedComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  text: string;
  title: string
  form: UntypedFormGroup;
  height: string;


  constructor(
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<TooltipExtendedComponent>,

    @Inject(MAT_DIALOG_DATA) value: any) {
    this.text = value.data.text;
    this.title = value.data.title;
    this.height = value.height;
  }

  ngOnInit(): void {
  }

  close() {
    this._dialogRef.close(false);
  }
}
