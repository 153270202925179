import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataItem } from '@progress/kendo-angular-grid';
import { combineLatest, of } from 'rxjs';
import { AppModules } from '../../shared/app-modules.enum';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { ElementAttributeDto } from '../../shared/model/shared/element-attribute.dto';
import { HierarchyElementUnitsQueryParameters } from '../../shared/model/uom/hierarchy-element-type-time-units-query.dto';
import { UnitTypeConversionViewDto } from '../../shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '../../shared/uom/uom.service';

const COMPONENT_SELECTOR = 'wlm-range-chart';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './range-chart.component.html',
  styleUrls: ['./range-chart.component.scss'],
})
export class RangeChartComponent implements OnInit {
  @Input() field: string;
  @Input() hierarchyElementTypeIdField: string;
  @Input() hierarchyElementTypeId: string;
  @Input() hierarchyElementIdField: string;
  @Input() networkElementIdField: string;
  @Input() timeAggregationId?: number;
  @Input() dimensionTypeId?: number;
  @Input() unitFormat?: string;
  @Input() decimalPositions;
  @Input() translationPrefix?: string;
  @Input() maxFieldName?: string;
  @Input() minFieldName?: string;
  @Input() minTitleTooltip: string;
  @Input() maxTitleTooltip: string;
  @Input() pointTitleTooltip: string;
  @Input() attributes: ElementAttributeDto[];
  @Input() maxAttributeTypeId?: number;
  @Input() minAttributeTypeId?: number;

  @Output() unitDescriptionChange = new EventEmitter<string>();

  private _data: DataItem;
  private readonly rangeTolerance = 2;

  private readonly pointColor = 'rgba(0,0,0,1)';
  private _rangeColor = 'rgba(178, 193, 236, 1)';
  private _rangeColorInverted = 'rgba(198, 40, 40,1)'; // used when range is inverted: min > max
  convertedMinTooltipValue: string;
  convertedMaxTooltipValue: string;

  public get rangeColor() {
    return this.convertedMinValue <= this.convertedMaxValue
      ? this._rangeColor
      : this._rangeColorInverted;
  }

  public T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;

  private readonly decimalPositionsTolerance = 3;

  public get data(): DataItem {
    return this._data;
  }
  @Input() public set data(v: DataItem) {
    if (this.data !== v) {
      this.chartReady = false;
      this.calculateData(v);
    }
    this._data = v;
  }

  convertedMinValue: number;
  convertedMaxValue: number;
  convertedPointValue: number;
  legendMinValue: string;
  legendMaxValue: string;

  convertedTooltipValue: string;

  rangeStyle: string;
  leftMarginMax: string;
  pointStyle: string;

  pointTooltip: string;
  minTooltip: string;
  maxTooltip: string;
  rangeTooltip: string;

  pointValue: number;
  chartReady: boolean;
  hasMaxMinDefined: boolean;

  private _currentConversionFactor: number;

  public get currentConversionFactor(): number {
    return this._currentConversionFactor;
  }
  public set currentConversionFactor(v: number) {
    this._currentConversionFactor = v;
  }

  constructor(
    private uomService: UoMService,
    private localization: LocalizationHelperService,
    private _utilsHelper: UtilsHelperService
  ) {}

  ngOnInit(): void {}

  calculateData(data: DataItem) {
    const valueFromRowData = data[this.field] as number;
    const heTypeId = this.getProvidedHETypeId(data);
    const heId = data[this.hierarchyElementIdField] as string;

    this.pointValue = null;

    if (valueFromRowData === undefined || heTypeId === undefined) {
      this.setEmptyValue();
      return;
    }

    const queryParams = this.getHierarchyUnitQueryParameters();
    if (!queryParams || !this.uomService) {
      this.setEmptyValue();
      return;
    }

    this.pointValue = valueFromRowData;

    const conversion$ = this.uomService.getByHEUnit(queryParams);
    let attributes$ = of([]);

    this.hasMaxMinDefined = this.maxFieldName != undefined && this.minFieldName != undefined;
    if (this.hasMaxMinDefined) {
      attributes$ = of([data[this.minFieldName], data[this.maxFieldName]]);
    } else {
      const key = this.networkElementIdField ? (data[this.networkElementIdField] as string) : heId;
      const min = this.getAttributeValue(key, this.minAttributeTypeId);
      const max = this.getAttributeValue(key, this.maxAttributeTypeId);

      attributes$ = of([min, max]);
    }

    combineLatest([conversion$, attributes$]).subscribe({
      next: ([conversion, attributes]) => {
        this.currentConversionFactor = conversion.conversionFactor;
        this.unitDescriptionChange.emit(conversion.unitTypeToDescription);

        const min = attributes[0];
        const max = attributes[1];

        const minValue = this.multiplyUoM(min);
        this.convertedMinValue = minValue ? +minValue : null;
        this.convertedMinTooltipValue = this.localization.formatNumber(
          this.convertedMinValue,
          '1.0-20'
        );

        const maxValue = this.multiplyUoM(max);
        this.convertedMaxValue = maxValue ? +maxValue : null;
        this.convertedMaxTooltipValue = this.localization.formatNumber(
          this.convertedMaxValue,
          '1.0-20'
        );

        const convertedTooltipValue = this._utilsHelper.uomMultiply(
          String(this.pointValue ?? 0),
          String(this.currentConversionFactor ?? 1),
          null,
          12
        );
        this.convertedPointValue = +convertedTooltipValue;
        this.convertedTooltipValue = this.localization.formatNumber(
          this.convertedPointValue,
          '1.0-20'
        );

        this.generateTooltips(conversion);

        const allValuesAreEqual =
          this.convertedMinValue === this.convertedMaxValue &&
          this.convertedMaxValue === this.convertedPointValue;

        // if min or max are null we only paint the point value
        if (!min || !max || allValuesAreEqual) {
          const definition = this.getSinglePointDefinition();
          this.rangeStyle = this.getRangeStyle(definition);
        } else {
          // if min and max are defined, we paint the full range
          this.chartWithMinMaxValuesDefined();
        }
        this.chartReady = true;
      },
    });
  }

  private multiplyUoM(value: number): string {
    return value != null && value != undefined
      ? this._utilsHelper.uomMultiply(
          String(value ?? 0),
          String(this.currentConversionFactor ?? 1),
          null,
          12
        )
      : null;
  }

  private getAttributeValue(key: string, attributeId: number) {
    return this.attributes?.find((x) => x.elementId == key && x.attributeTypeId == attributeId)
      ?.attributeValue;
  }

  private chartWithMinMaxValuesDefined() {
    let convertedMaxValueAdjusted = this.convertedMaxValue;

    convertedMaxValueAdjusted =
      this.convertedMinValue === this.convertedMaxValue ? null : this.convertedMaxValue;

    // This is done to remove the posible null values from the comparison
    const pointValues = [
      this.convertedMinValue,
      convertedMaxValueAdjusted,
      this.convertedPointValue,
    ].filter((x) => x !== null && x !== undefined);

    const max = Math.max(...pointValues);
    const min = Math.min(...pointValues);

    const middle = pointValues.filter((x) => x !== min && x !== max)[0] ?? max;

    // formula to calculate the proportion of the middle value
    const middlePercentage = !middle ? middle : ((middle - min) * 100) / (max - min);

    const definition = this.getPointStyleDefinition(max, middlePercentage);

    this.rangeStyle = this.getRangeStyle(definition);
  }

  private getPointStyleDefinition(convertedMaxValueAdjusted: number, middlePercentage: number) {
    let definition = [];

    if (middlePercentage < this.rangeTolerance) {
      middlePercentage = this.rangeTolerance;
    }

    const needToAdjustMiddlePercentage = this.convertedMaxValue === this.convertedMinValue;

    // point is in the right
    if (
      this.convertedPointValue >= convertedMaxValueAdjusted &&
      this.convertedPointValue >= this.convertedMinValue
    ) {
      middlePercentage = needToAdjustMiddlePercentage ? this.rangeTolerance : middlePercentage;
      definition = this.getRightPointDefinition(middlePercentage);
    }

    // point is in the left
    if (
      this.convertedPointValue <= this.convertedMinValue &&
      this.convertedPointValue <= convertedMaxValueAdjusted
    ) {
      middlePercentage = needToAdjustMiddlePercentage
        ? 100 - this.rangeTolerance
        : middlePercentage;
      definition = this.getLeftPointDefinition(middlePercentage);
    }

    // point is in the middle
    if (
      (this.convertedPointValue > this.convertedMinValue &&
        this.convertedPointValue < convertedMaxValueAdjusted) ||
      (this.convertedMaxValue <= this.convertedPointValue &&
        this.convertedPointValue <= this.convertedMinValue)
    ) {
      definition = this.getMiddlePointDefinition(middlePercentage);
    }
    return definition;
  }

  private getSinglePointDefinition(): string[] {
    const singlePointDefinition = [
      `transparent 50%`,
      `${this.pointColor} 50%`,
      `${this.pointColor} 52%`,
      `transparent 52%`,
    ];
    return singlePointDefinition;
  }

  private getMiddlePointDefinition(percentage: number): string[] {
    const middlePointDefinition = [
      `${this.rangeColor} ${percentage}%`,
      `${this.pointColor} ${percentage}%`,
      `${this.pointColor} ${percentage + 2}%`,
      `${this.rangeColor} ${percentage + 2}%`,
    ];
    return middlePointDefinition;
  }

  private getLeftPointDefinition(percentage: number): string[] {
    const leftPointDefinition = [
      `${this.pointColor} 2%`,
      `transparent 2%`,
      `transparent ${percentage}%`,
      `${this.rangeColor} ${percentage}%`,
    ];
    return leftPointDefinition;
  }

  private getRightPointDefinition(percentage: number): string[] {
    const rightPointDefinition = [
      `${this.rangeColor} 0%`,
      `${this.rangeColor} ${percentage}%`,
      `transparent ${percentage}%`,
      'transparent 98%',
      `${this.pointColor} 98%`,
    ];
    return rightPointDefinition;
  }

  // This method receive an array of the segments to build the gradient clause
  private getRangeStyle(definition: string[]): string {
    let styleDefinition = 'linear-gradient(90deg,';
    definition.forEach((item) => (styleDefinition += `${item}, `));
    styleDefinition = styleDefinition.slice(0, -2); // remove the last comma
    return (styleDefinition += ')');
  }

  private generateTooltips(conversion: UnitTypeConversionViewDto) {
    const formatedValue = this.getFormatedValue(this.convertedPointValue);

    [this.pointTooltip, this.minTooltip, this.maxTooltip] = this.getPointTooltip(
      this.convertedTooltipValue,
      this.convertedMinTooltipValue,
      this.convertedMaxTooltipValue,
      conversion
    );
  }

  // Build the tooltip
  private getPointTooltip(
    formatedValue: string,
    min: string,
    max: string,
    conversion: UnitTypeConversionViewDto
  ): string[] {
    const minValue =
      min != null && min != undefined ? `${min} [${conversion.unitTypeToDescription}]` : '';
    const maxValue =
      max != null && max != undefined ? `${max} [${conversion.unitTypeToDescription}]` : '';

    const pointTooltip = `${this.pointTitleTooltip}: ${formatedValue} [${conversion.unitTypeToDescription}]`;
    const minTooltip = `${this.minTitleTooltip}: ${minValue}`;
    const maxTooltip = `${this.maxTitleTooltip}: ${maxValue}`;
    return [pointTooltip, minTooltip, maxTooltip];
  }

  private setEmptyValue() {
    this.pointTooltip = '';
  }

  private getProvidedHETypeId(data: DataItem): string {
    if (
      this.hierarchyElementTypeId === undefined ||
      (this.hierarchyElementTypeIdField &&
        this.hierarchyElementTypeId !== data[this.hierarchyElementTypeIdField])
    ) {
      this.hierarchyElementTypeId = data[this.hierarchyElementTypeIdField];
    }
    return this.hierarchyElementTypeId;
  }

  private getHierarchyUnitQueryParameters(): HierarchyElementUnitsQueryParameters {
    return {
      hierarchyElementTypeId: this.hierarchyElementTypeId,
      timeAggregationId: this.timeAggregationId,
      dimensionTypeId: this.dimensionTypeId,
    };
  }

  // if decimal positions are defined, the value is rounded, else the value is checked to exponential
  private getFormatedValue(value: number): string {
    if (this.decimalPositions === undefined || this.decimalPositions === null) {
      return this.checkExponencial(value);
    }
    return parseFloat(value.toString()).toFixed(this.decimalPositions);
  }

  // This method checks if the decimal part of a number exceeds a tolerance. In that case returns the number in scientific notation
  private checkExponencial(value: number): string {
    if (isNaN(value)) {
      return null;
    }

    const decimalPart = Math.abs(value) % 1; // gets the decimal part of a number

    if (decimalPart.toString().length > this.decimalPositionsTolerance) {
      return value.toExponential(this.decimalPositionsTolerance);
    }

    return value.toString();
  }
}
