import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterItemSelectOption } from 'src/app/common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from 'src/app/common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { SharedConstantsService } from 'src/app/common-modules/shared/constants/shared-constants.service';
import { SelectOption } from 'src/app/common-modules/shared/model/shared/select-option';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';

const COMPONENT_SELECTOR = 'wlm-time-aggregation-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: '../../core/default/templates/filter-item-select.template.html',
  styleUrls: ['./time-aggregation-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => TimeAggregationFilterItemComponent),
    },
  ],
})
export class TimeAggregationFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AdaptedFilterItem
{
  @Input() allowedIds: number[];
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  @Input() defaultSelectedIds: number[] = [];
  mode = 'single';

  constructor(private sharedConstants: SharedConstantsService) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this.sharedConstants.getTimeAggregationArray().pipe(
      map((data: SelectOption<number>[]) => {
        let results = data.map((item) => new FilterItemSelectOption(item.value, item.label));

        if (this.allowedIds && this.allowedIds.length) {
          results = results.filter((item) =>
            this.allowedIds.find((allowedId) => allowedId === item.value)
          );
        }
        return results;
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.selectedElements && this.selectedElements.length !== 0;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.TimeAggregation;
  }
}
