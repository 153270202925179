<wlm-spinner-wrapper [showSpinner]="isLoading || isSaving">
  <div *ngIf="hierarchyElementId && !isLoading" class="wtr-fx-layout-column container">
    <div class="filter-buttons-container">
      <button
        [disabled]="this.isEditButtonDisabled"
        *hasPerm="['WLMDistributionNetworkCrud', 'u']"
        mat-icon-button
        (click)="edit()"
        class="button-position"
        [matTooltip]="'common.edit-button-tooltip' | translate | lowercase"
      >
        <wlm-icon [icon]="'edit'"></wlm-icon>
      </button>

      <button
        [disabled]="!this.valueHasChanged"
        *hasPerm="['WLMDistributionNetworkCrud', 'u']"
        mat-icon-button
        (click)="onSave()"
        class="button-position"
        [matTooltip]="'common.save-button-tooltip' | translate | lowercase"
      >
        <wlm-icon [icon]="'save'"></wlm-icon>
      </button>

      <button
        [disabled]="!this.isEditButtonDisabled"
        *hasPerm="['WLMDistributionNetworkCrud', 'u']"
        mat-icon-button
        (click)="resetChanges()"
        class="button-position"
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>

    <form novalidate [formGroup]="form" (ngSubmit)="save()" class="wtr-fx-flex-grow">
      <mat-form-field appearance="outline" class="stretch-height wtr-textarea-editor">
        <textarea matInput formControlName="dmaCommentsControl"></textarea>
      </mat-form-field>
    </form>
  </div>
</wlm-spinner-wrapper>
