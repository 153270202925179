import { Injectable } from '@angular/core';
import { DefaultHistoricalValidationService } from 'src/app/common-modules/wlm-charts/core/services/default-historical-validations.service';
import { SmartMeterConfigDto } from '../models/smart-meter-config.dto';
import { SmartMeterSignalVersionDto } from '../models/smart-meter-signal-version-dto';

@Injectable()
export class SmartMetersHistoricalValidationService extends DefaultHistoricalValidationService<SmartMeterConfigDto> {
  validateCurrentConfiguration(
    config1: SmartMeterConfigDto,
    config2: SmartMeterConfigDto
  ): boolean {
    if (config1 === config2) {
      return false;
    }

    if (
      config1.pointId !== config2.pointId &&
      config1.customerClassTypeId !== config2.customerClassTypeId
    ) {
      return false;
    }

    return true;
  }

  getCurrentFromHistorical(config: SmartMeterSignalVersionDto): SmartMeterConfigDto {
    if (!config) {
      return null;
    }
    const current: SmartMeterConfigDto = {
      signalId: config.signalId,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      customerClassTypeId: config.customerClassTypeId,
      customerClassTypeName: config.customerClassTypeName,
      hierarchyElementId: config.hierarchyElementId,
    };
    return current;
  }
}
