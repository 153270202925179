import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonSharedModule } from '../shared/common-shared.module';
import { WLMGridModule } from '../wlm-grid/wlm-grid.module';
import { GenericCrudPopupContentHostDirective } from './generic-crud-popup/generic-crud-popup-content.directive';
import { GenericCrudPopupComponent } from './generic-crud-popup/generic-crud-popup.component';
import { GenericCrudComponent } from './generic-crud/generic-crud.component';

@NgModule({
  declarations: [
    GenericCrudComponent,
    GenericCrudPopupComponent,
    GenericCrudPopupContentHostDirective,
  ],
  imports: [CommonModule, CommonSharedModule, WLMGridModule],
  exports: [GenericCrudComponent],
})
export class GenericCrudModule {}
