<div
  *ngIf="menuLinks?.length"
  [ngClass]="{
    'nav-menu-base': !isMenuOpen,
    'nav-menu-container-collapsed': isContainerCollapsed
  }"
>
  <button
    mat-icon-button
    class="nav-button"
    [matMenuTriggerFor]="menu"
    [disableRipple]="true"
    aria-label="Navigations menu"
    (onMenuOpen)="onMenuOpen($event)"
    (onMenuClose)="onMenuClose($event)"
    (click)="clicked($event)"
    [disabled]="isMenuDisabled"
    onclick="this.blur()"
    [matTooltip]="showTooltip ? (label ?? defaultLabel | translate | lowercase) : ''"
  >
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <mat-icon *ngIf="!icon" [svgIcon]="defaultIcon"></mat-icon>
    <span class="nav-menu-title"
      >{{ showLabel || isContainerCollapsed ? (label ?? defaultLabel | translate) : '' }}
    </span>
  </button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let menuLink of menuLinks" matTooltip="{{ getItemTooltip(menuLink) }}">
      <button mat-menu-item (mouseup)="menuClick(menuLink, $event)" [disabled]="menuLink.disabled">
        <wlm-icon
          [icon]="menuLink.svgIcon"
          [ngClass]="{ 'wtr-nav-link-icon-disabled': menuLink.disabled }"
          class="wtr-nav-link-icon"
        ></wlm-icon>
        <span class="nav-link-label">{{ menuLink.title }}</span>
      </button>
    </div>
  </mat-menu>
</div>
